import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getMembershipCancellationReasons } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useMembershipCancellationReasons() {
  const actions = useAccountActions();
  const membershipCancellationReasons = useSelector(
    getMembershipCancellationReasons
  );

  useEffect(() => {
    if (!membershipCancellationReasons.networkStatus.isUpToDate) {
      actions.loadMembershipCancellationReasons();
    }
  }, [actions, membershipCancellationReasons.networkStatus.isUpToDate]);

  return membershipCancellationReasons;
}

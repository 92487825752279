import React from 'react';

import PropTypes from 'prop-types';

import { useImageBrowser } from '../ImageBrowserContext';
import Picture from '../Picture';

const ImageBrowserHeroImage = React.forwardRef(
  ({ aspectRatio, breakpoints, ...rest }, ref) => {
    const imageBrowserCtx = useImageBrowser();
    const { activeImage } = imageBrowserCtx;
    return (
      <Picture
        sources={activeImage}
        aspectRatio={aspectRatio}
        breakpoints={breakpoints}
        ref={ref}
        {...rest}
      />
    );
  }
);

ImageBrowserHeroImage.displayName = 'ImageBrowserHeroImage';
ImageBrowserHeroImage.propTypes = {
  aspectRatio: PropTypes.number,
  breakpoints: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ImageBrowserHeroImage;

import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { FaMinus, FaPlus } from 'react-icons/fa';
import styled from 'styled-components';

import {
  FormattedMessage,
  useIntl,
  defineMessages,
} from '../../../../techstyle-shared/react-intl';
import Button from '../Button';

// styled components for QuantitySelector hierarchy
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SelectorLabelWrapper = styled.div`
  color: #888888;
  display: flex;
  margin-bottom: 8px;
  ${({ categoryLabelStyle }) => categoryLabelStyle}
`;
const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  ${({ buttonWrapperStyle }) => buttonWrapperStyle}
`;
const QuantityText = styled.span`
  width: 40px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  ${({ currentQuantityStyle }) => currentQuantityStyle}
`;
const QuantityTextNearLabel = styled.span`
  color: #333333;
  margin-left: 4px;

  ${({ quantityTextStyle }) => quantityTextStyle}
`;
const BaseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 28px;
  background-color: #eeeeee;
  color: #555555;
  border: none;
  box-shadow: 0 0 0 3px rgba(19, 101, 197, 0.32);

  &:disabled {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.04);
    opacity: 0.5;
  }
`;
const MinusIcon = styled(FaMinus)`
  display: block;
  ${({ $iconStyle }) => $iconStyle}
`;
const PlusIcon = styled(FaPlus)`
  display: block;
  ${({ $iconStyle }) => $iconStyle}
`;
const IncrementerButton = styled(BaseButton)`
  ${({ incrementButtonStyle }) => incrementButtonStyle}
`;
const DecrementerButton = styled(BaseButton)`
  ${({ decrementButtonStyle }) => decrementButtonStyle}
`;

// text for aria-labels of decrement/increment buttons
const messages = defineMessages({
  increment: {
    id: 'site_product_page.quantity_increment',
    defaultMessage: 'Increment quantity',
  },
  decrement: {
    id: 'site_product_page.quantity_decrement',
    defaultMessage: 'Decrement quantity',
  },
});

function QuantitySelector({
  buttonWrapperStyle,
  categoryLabelStyle,
  quantityTextStyle,
  currentQuantityStyle,
  decrementButtonStyle,
  decrementLabel,
  disabled,
  iconStyle,
  incrementButtonStyle,
  incrementLabel,
  initialQuantity = 1,
  label,
  maxQuantity = 5,
  onChange,
  showQuantityNearLabel,
  value: valueFromProps,
  ...rest
}) {
  const [quantity, setQuantity] = useState(initialQuantity);
  const intl = useIntl();
  const incrementAriaLabel = intl.formatMessage(messages.increment);
  const decrementAriaLabel = intl.formatMessage(messages.decrement);
  const isControlled = valueFromProps != null;
  const value = valueFromProps != null ? valueFromProps : quantity;
  const isIncrementerDisabled = value >= maxQuantity;
  const isDecrementerDisabled = value <= 1 || maxQuantity < 1;

  const handleChange = useCallback(
    (value) => {
      if (isControlled && onChange) {
        onChange(value);
      } else {
        setQuantity(value);
      }
    },
    [isControlled, onChange]
  );

  const handleIncrement = () => {
    handleChange(value + 1);
  };

  const handleDecrement = () => {
    handleChange(value - 1);
  };

  useEffect(() => {
    if (value > maxQuantity && maxQuantity >= 1) {
      handleChange(maxQuantity);
    }
  }, [handleChange, maxQuantity, value]);

  return (
    <Wrapper {...rest}>
      <SelectorLabelWrapper categoryLabelStyle={categoryLabelStyle}>
        {label || (
          <FormattedMessage
            id="site_product_page.quantity"
            defaultMessage="Quantity"
          />
        )}
        {': '}
        {showQuantityNearLabel && (
          <QuantityTextNearLabel quantityTextStyle={quantityTextStyle}>
            {value}
          </QuantityTextNearLabel>
        )}
      </SelectorLabelWrapper>
      <ButtonWrapper
        buttonWrapperStyle={buttonWrapperStyle}
        data-autotag="pdp-choose-quantity"
      >
        <DecrementerButton
          data-autotag="pdp-decrease-quantity"
          data-testid="decrement-button"
          decrementButtonStyle={decrementButtonStyle}
          disabled={isDecrementerDisabled || disabled}
          onClick={handleDecrement}
        >
          {decrementLabel || (
            <MinusIcon aria-label={decrementAriaLabel} $iconStyle={iconStyle} />
          )}
        </DecrementerButton>
        <QuantityText
          currentQuantityStyle={currentQuantityStyle}
          data-autotag="pdp-total-quantity"
          data-testid="quantity"
        >
          {' '}
          {value}{' '}
        </QuantityText>
        <IncrementerButton
          data-autotag="pdp-increase-quantity"
          data-testid="increment-button"
          disabled={isIncrementerDisabled || disabled}
          incrementButtonStyle={incrementButtonStyle}
          onClick={handleIncrement}
        >
          {decrementLabel || (
            <PlusIcon aria-label={incrementAriaLabel} $iconStyle={iconStyle} />
          )}
        </IncrementerButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

QuantitySelector.propTypes = {
  /**
   * Styles to apply to the quantity selector button wrapper.
   */
  buttonWrapperStyle: PropTypes.any,
  /**
   * Styles to apply to the quantity selector category label.
   */
  categoryLabelStyle: PropTypes.any,
  /**
   * Styles to apply to the quanity text span that indicates default/current item quantity.
   */
  currentQuantityStyle: PropTypes.any,
  /**
   * Styles to apply to the quantity selector decrementer button.
   */
  decrementButtonStyle: PropTypes.any,
  /**
   * Optional decrement prop that will be used in place of default decrement icon.
   * If provided, include aria-label specific to icon and it's purpose, i.e., "increment", "decrement"
   */
  decrementLabel: PropTypes.node,
  /**
   * Disabled prop passed to increment/decrement buttons. Allows for external conditions to dicate whether buttons are enabled/disable
   */
  disabled: PropTypes.bool,
  /**
   * Styles to apply to the +/- icons inside of the buttons.
   */
  iconStyle: PropTypes.any,
  /**
   * Styles to apply to the quantity selector incrementer button.
   */
  incrementButtonStyle: PropTypes.any,
  /**
   * Optional increment prop that will be used in place of default increment icon
   */
  incrementLabel: PropTypes.node,
  /**
   * Initial/default quantity of QuantitySelector
   */
  initialQuantity: PropTypes.number,
  /**
   * Optional label that can be passed to populate selector
   */
  label: PropTypes.node,
  /**
   * Maximum amount of units a customer can add to cart.
   */
  maxQuantity: PropTypes.number,
  /**
   * onChange handler to be used as a means of controlling this component and maintaining awareness of current quantity, as onChange receives current quantity as an argument
   */
  onChange: PropTypes.func,
  /**
   * Styles to apply to the quantity selector value.
   */
  quantityTextStyle: PropTypes.any,
  /**
   * Boolean that will dictate whether current quantity value is displayed near the default 'Quantity' label or whatever label is passed via props
   */
  showQuantityNearLabel: PropTypes.bool,
  /**
   * The value to be used inside of QuantitySelector, as opposed to an internally managed value. If this prop is provided, QuantitySelector will behave as a controlled component. If not passed, QuantitySelector is capable of managing/controlling it's own state internally.
   */
  value: PropTypes.number,
};

export default QuantitySelector;

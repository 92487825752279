import React from 'react';

import dynamic from 'next/dynamic';

import { AdminGuard } from '../../../techstyle-shared/react-admin';

import featuresModule, { loadFeatures } from './featuresModule';
import logger from './logger';

const debug = logger.extend('featuresExtension');

const FeaturesAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './FeaturesAdminPanel')
);

export default function featuresExtension({ globalPrefetchFeatures } = {}) {
  return (registry) => ({
    id: 'features',
    redux: {
      modules: [featuresModule],
    },
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <AdminGuard>
              <FeaturesAdminPanel />
            </AdminGuard>
          </>
        );
      },
    },
    page({ prefetchFeatures = [] }) {
      const getFeatures = registry.utils.getMergedArrayOption({
        globalPrefetchFeatures,
        prefetchFeatures,
      });

      return {
        async getInitialProps(ctx) {
          const [features, meta] = await getFeatures(ctx);
          if (features.length) {
            debug(
              'Loading %s %s added by %s.',
              features.length,
              features.length === 1 ? 'feature' : 'features',
              meta.sources.join(', ')
            );
            await ctx.store.dispatch(loadFeatures(features));
          }
        },
      };
    },
  });
}

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getLoyaltyPlans } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useLoyaltyPlans() {
  const actions = useAccountActions();
  const loyaltyPlans = useSelector((state) => getLoyaltyPlans(state));

  useEffect(() => {
    if (!loyaltyPlans.networkStatus.isUpToDate) {
      actions.loadLoyaltyPlans();
    }
  }, [actions, loyaltyPlans.networkStatus.isUpToDate]);

  return loyaltyPlans;
}

import config from 'config';
import decode from 'decode-html';
import isEmpty from 'lodash/isEmpty';

const SUPPORTS_RAF = !!(
  process.browser &&
  window.requestAnimationFrame &&
  window.performance.now
);

/**
 * A helper that uses requestAnimationFrame (if supported) to achieve a
 * `setTimeout`-like result, since the browser deprioritizes `setTimeout`
 * callbacks during scrolling, which can add seconds of delay.
 *
 * Instead of returning a handle ID, you get a function that will cancel the
 * timeout. (This is due to the fact that if `requestAnimationFrame` is used,
 * it will generate an ID for each frame.) Call the returned function with no
 * arguments to cancel.
 */
export function setAnimationTimeout(fn, timeout, ...args) {
  if (SUPPORTS_RAF) {
    let id;
    const startTime = window.performance.now();
    const tick = (timestamp) => {
      const elapsedTime = timestamp - startTime;
      if (elapsedTime >= timeout) {
        fn(...args);
      } else {
        id = window.requestAnimationFrame(tick);
      }
    };
    id = window.requestAnimationFrame(tick);
    return () => window.cancelAnimationFrame(id);
  } else {
    const id = window.setTimeout(fn, timeout, ...args);
    return () => window.clearTimeout(id);
  }
}

/**
 * A helper to format the incoming mustache variables {{ SPECIAL_URL }} in
 * `htmlContent` from direct marketing sites (dms).
 */
export function replaceDmgHtml(htmlContent, contentObject) {
  if (!htmlContent || !contentObject) {
    return null;
  }

  // replace {{cdn}}
  const bracketReplacedHtmlContent = htmlContent.replace(
    /\{\{(?:\s+|)(\w+)(?:\s+|)\}\}/g,
    (match, name) => contentObject[name]
  );

  // replace #cdn#
  const hashReplacedHtmlContent = bracketReplacedHtmlContent.replace(
    /#(?:\s+|)(\w+)(?:\s+|)#/g,
    (match, name) => contentObject[name]
  );

  return hashReplacedHtmlContent;
}

/**
 * localStorage doesn't work on SSR and it's annoying to
 * constantly check if it's there, so import this to avoid that.
 */
export const localStorage = process.browser
  ? window.localStorage
  : { getItem: () => null, setItem: () => null, removeItem: () => null };

/**
 * This is a static method so others can use it easily by importing this
 * component (if they want to render a custom asset component, for example).
 */
export function decodeHTML(escapedHTML) {
  // Let's just say assets have a very special idea about HTML escaping.
  return decode(escapedHTML.replace(/(\\r\\n|\\r|\\n|\\t)/g, ' '));
}

/**
 * This is a function that check if the user is in a mobile/tablet device
 */
export function isMobile(navigator) {
  const userAgent = navigator.userAgent.toLowerCase();
  const height = window.screen.availHeight;
  const width = window.screen.availWidth;

  // We check if the userAgent contains mobi for mobile and tablet string
  // Checking for `mobi` anywhere in userAgent is recommended.
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  if (userAgent.includes('mobi') || userAgent.includes('tablet')) {
    return true;
  }

  if (userAgent.includes('android')) {
    if (height > width && width < 800) {
      // The user is in portrait mode
      return true;
    }

    if (width > height && height < 800) {
      // The user is in landscape mode
      return true;
    }
  }

  return false;
}
/**
 * @function getObjectSubset
 * Returns a subset of source by key
 * @param keys type:Array (e.g. ['a', 'b', 'c'])
 * @param source type:Object (e.g. {a: 'one', b: 'two', c: 'three', d: 'exludeThisValue'}
 * @returns {{}} (e.g. {a: 'one', b: 'two', c: 'three'})
 */
export const getObjectSubset = (keys, source) => {
  const newObject = {};
  keys.forEach((key) => (newObject[key] = source[key]));
  return newObject;
};
/**
 * @function subsetDiffersFromObject
 * Provided subset has keys contained in object, will perform a check for any difference in values.
 * @param subset type:Object
 * @param object type:Object
 * @returns {boolean} If subset has different values than provided object's values, returns true
 */
export const subsetDiffersFromObject = (subset, object) => {
  return Object.entries(subset).some(([key, value]) => {
    return value !== object[key];
  });
};

export const deepArrayFlatten = (arr) => {
  return arr.map((x) => (Array.isArray(x) ? deepArrayFlatten(x) : x));
};

/**
 * @function isShippingDefaultByState
 * Passing states as string or array and addresses as an array of objectives.
 * Return False if addresses has array of objectives and has  obj value of isDefault
 * Return True if caters the Array | string of states base of the default shipping address
 * @param {Array | String} states
 * @param {Array} addresses
 * @returns {Boolean} return as True if shipping
 * @example
 * values: ('ca',[{state:'CA',isDefault:false},{state:'AZ',isDefault:true}])
 * return: ture | false
 * @example
 * values: (['ca','AZ'] ,[{state:'CA',isDefault:false},{state:'AZ',isDefault:true}])
 * return: ture | false
 */
export const isShippingDefaultByState = (states, addresses = []) => {
  if (addresses.length > 0) {
    const defaultAddress = addresses.find((shipping) => shipping.isDefault);
    const state = defaultAddress?.state || '';

    if (Array.isArray(states)) {
      const statesToUpperCase = states.map((item) => item.toUpperCase());
      return statesToUpperCase.includes(state.toUpperCase());
    } else {
      return state.toUpperCase() === states.toUpperCase();
    }
  }
  return false;
};

/**
 * @function isLocale
 * Passing domain as string or array and customerStoreGroupId as numeric.
 * Return False if customerStoreGroupId is not numeric
 * Return True if caters the Array | string of domain base on the store group id of the customer matches the
 * store default store group id of each domain
 * @param {Array | String} countryCode
 * @values list are as follow NA,CA,UK,DE,FR,ES,NL,DK,SE
 * @param {string} domain
 * @values values can be set by importing useDomain from techstyle/redux-core and initialize the tld
 * @returns {Boolean} return as True if shipping
 * @example
 * values: ('US','.com')
 * return: true | false
 * @example
 * values: (['ca','US'] ,'.com')
 * return: true | false
 */
export const isLocale = (countryCode, domain) => {
  const defaultCountryCode = config.get('public.intl.defaultCountryCodes');
  if (domain.length > 0) {
    if (Array.isArray(countryCode)) {
      const domainToUpperCase = countryCode.map((item) => item.toUpperCase());
      const convertUStoNAIndex = domainToUpperCase.indexOf('US');
      if (~convertUStoNAIndex) {
        domainToUpperCase[convertUStoNAIndex] = 'NA';
      }
      return domainToUpperCase.includes(
        defaultCountryCode[domain].toUpperCase()
      );
    } else {
      return defaultCountryCode[domain] === countryCode.toUpperCase();
    }
  }
  return false;
};

/**
 * This is a a function to check if the One Trust 'accept cookies' box is currently open.
 * Used in Modal components to toggle the focus trap so that a user can interact with the 'accept cookies'
 * button.
 */
export const isOneTrustAlertBoxOpen = () => {
  return typeof window !== 'undefined' &&
    window.OneTrust &&
    typeof window.OneTrust.IsAlertBoxClosed === 'function'
    ? !window.OneTrust.IsAlertBoxClosed()
    : false;
};

/**
 * Checks if a video has ended based on the played and duration seconds.
 * @param {Object} videoStats - An object containing the video statistics.
 * @param {number} videoStats.playedSeconds - The number of seconds that have been played.
 * @param {number} videoStats.videoDuration - The duration of the video in seconds.
 * @returns {boolean} - Returns true if the played seconds are greater than or equal to the duration, indicating the video has ended.
 */
export const isReactVideoEnded = (playedSeconds, videoDuration) => {
  const played = Math.ceil(playedSeconds);
  const loaded = Math.floor(videoDuration);

  if (played >= loaded) {
    return true;
  }

  return false;
};

/**
 * Turns snake-case strings to camelCase. Does not respect capitalization, ie
 * "cRaZy-StRiNg" will turn into "crazyString", or "fooBar" will turn into "foobar"
 * @param {String} str - string to be turned into camel case
 * @returns {String} camelCase version of string inputted
 */
export const snakeToCamel = (str) => {
  return str
    .toLowerCase()
    .replace(/[-_][a-z0-9]/g, (group) => group.slice(-1).toUpperCase());
};

/**
 * get the correct discount order from the order line discount and order line via
 * ID
 * @param {String} orderLineItemId - order line id
 * @param {Object} orderDiscountsList - object of order line discounts
 */
export const getOrderDiscountsToOrderItem = (
  orderLineItemId,
  orderDiscountsList
) => {
  if (!isEmpty(orderDiscountsList)) {
    return orderDiscountsList.find((items) => {
      if (items?.orderLineId) {
        return orderLineItemId === items.orderLineId;
      }
    });
  }
};

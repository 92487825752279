import config from 'config';

import logger from './logger';

const debug = logger.extend('sendToRaygun');

let raygunClient;

export default function sendToRaygun(error, options = {}) {
  if (!config.has('public.raygun.apiKey')) {
    return;
  }

  options = {
    ...options,
    customData: {
      ...error.customData,
      ...options.customData,
    },
  };

  if (!process.browser) {
    const raygun = require('raygun');
    if (!raygunClient) {
      debug('Creating Raygun client.');
      const apiKey = config.get('public.raygun.apiKey');
      raygunClient = new raygun.Client().init({ apiKey });
    }
    debug('Error sent to Raygun: %s', error);
    raygunClient.send(error, options.customData);
  } else {
    if (global.rg4js) {
      debug('Error sent to Raygun: %s', error);
      global.rg4js('send', { error, ...options });
    } else {
      debug('Raygun was not found, error will not be sent: %s', error);
    }
  }
}

import React, { useMemo, useCallback, useContext, useReducer } from 'react';

import config from 'config';
import styled from 'styled-components';

import { Selector } from '../GenericProductAttributeSelector';
import { useProductContext } from '../ProductContext';

type Swatches = {
  productImages?: Object;
  swatchUrl?: string;
  permalink?: string;
  itemNumber?: string;
  color?: string;
  masterProductId?: string | number;
  dateExpected?: Date;
  tagIdList?: Array<number>;
  isWarehouseProduct?: Boolean;
};

type GridColorSwatchesContext = {
  swatchData?: Swatches[];
  activeProduct: any;
  handleColorChange: (value: Swatches) => void;
};

type RootProps = React.PropsWithChildren<{ product?: any }>;
const Context = React.createContext<GridColorSwatchesContext | undefined>(
  undefined
);

const getSwatchUrlFromItem = (
  masterProductId: number,
  baseItem: any,
  inputItem: any
): string => {
  let swatch;
  if (masterProductId && !inputItem?.swatchUrl && baseItem?.swatches) {
    swatch = baseItem.swatches.find(
      (swatch: any) => swatch.productId === masterProductId
    );
  }

  let swatchUrl;
  if (!swatch && config.get('public.brand.name') === 'savage') {
    const sku = inputItem.itemNumber?.replace(/-00$/, '');
    swatchUrl = `https://cdn.savagex.com/media/images/products/${sku}/${sku}-SWATCH.jpg`;
  } else {
    swatchUrl = swatch?.url;
  }
  return swatchUrl;
};

export const useGridSwatchesContext = () => {
  return useContext(Context);
};

const getSwatchItem = (inputItem: any, baseItem?: any): Swatches => {
  const masterProductId =
    inputItem.relatedProductId || inputItem.masterProductId;
  // look up the swatch url for the related product from the main item swatches
  const swatchUrl = getSwatchUrlFromItem(masterProductId, baseItem, inputItem);

  return {
    productImages: inputItem.productImages ||
      inputItem.images || {
        default: [],
        plus: [],
      },
    swatchUrl: inputItem.swatchUrl || swatchUrl || '',
    permalink: inputItem.permalink || '',
    itemNumber: inputItem.itemNumber || '',
    color: inputItem.color || '',
    masterProductId,
    dateExpected: inputItem.dateExpected,
    tagIdList: inputItem.tagIdList,
    isWarehouseProduct: inputItem.isWarehouseProduct,
  };
};

const getSwatchData = (product: any) => {
  const swatchData: Swatches[] = [];
  if (!product) {
    return swatchData;
  }
  // Add parent/current product data as first item to array
  swatchData.push(getSwatchItem(product, product));
  if (
    product.relatedProductIdObjectList &&
    product.relatedProductIdObjectList.length
  ) {
    product.relatedProductIdObjectList.forEach((relatedProduct: any) => {
      if (
        !relatedProduct.isSelf &&
        relatedProduct.isAvailable &&
        relatedProduct.dateExpected
      ) {
        swatchData.push(getSwatchItem(relatedProduct, product));
      }
    });
  }
  return swatchData;
};

const SwatchChangeStateAction = {
  SWATCH_CHANGE: 'swatchChange',
};

const gridColorSwatchesReducer = (
  state: any,
  action: {
    type: typeof SwatchChangeStateAction.SWATCH_CHANGE;
    payload: Swatches;
  }
) => {
  if (
    action.type === 'SWATCH_CHANGE' &&
    state.masterProductId !== action.payload.masterProductId
  ) {
    return { ...state, ...action.payload };
  }
  return state;
};

const GridColorSwatches = ({
  children,
  product: productFromProps,
}: RootProps) => {
  const productFromContext = useProductContext();
  const product = productFromProps || productFromContext;
  const [activeProduct, dispatch] = useReducer(
    gridColorSwatchesReducer,
    product
  );

  const handleColorChange = useCallback((option: any) => {
    dispatch({ type: 'SWATCH_CHANGE', payload: option });
  }, []);

  const context = useMemo(() => {
    return {
      activeProduct,
      handleColorChange,
    };
  }, [activeProduct, handleColorChange]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

const SelectorList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

GridColorSwatches.Context = GridColorSwatches;
GridColorSwatches.useContext = useGridSwatchesContext;
GridColorSwatches.SelectorList = SelectorList;
GridColorSwatches.Selector = Selector;
GridColorSwatches.getSwatchData = getSwatchData;

export default GridColorSwatches;

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getLinkedProfiles } from './linkedProfilesModule';
import useLinkedProfilesActions from './useLinkedProfilesActions';

export default function useLinkedProfiles() {
  const actions = useLinkedProfilesActions();
  const linkedProfiles = useSelector(getLinkedProfiles);

  useEffect(() => {
    if (!linkedProfiles.networkStatus.isUpToDate) {
      actions.loadProfiles();
    }
  }, [actions, linkedProfiles.networkStatus.isUpToDate]);

  return linkedProfiles;
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 1.5rem;
  font-size: ${12 / 16}rem;
  background: #ddd;
  border-radius: 3px;
  overflow: hidden;
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  background: blue;
  user-select: none;
`;

/*
         +------------------------------------------------+
         |#################|   center                 end |
         +------------------------------------------------+
          ^       ^       ^ ^
          |       |       | |
          * start |       | * afterBar
                  |       * barEnd
                  * barCenter
 */

function getLabelAlignment({ alignLabel }) {
  switch (alignLabel) {
    case 'start':
      return { color: 'white' };
    case 'barCenter':
      return {
        textAlign: 'center',
        color: 'white',
      };
    case 'barEnd':
      return { color: 'white' };
    case 'center':
      return {
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'white',
      };
    case 'end':
      return { right: 0, color: 'white' };
  }
}

const Label = styled.div`
  position: absolute;
  padding: 0 0.25rem;
  ${getLabelAlignment}
`;

export default function ProgressBar({
  alignLabel,
  children,
  max,
  value,
  ...rest
}) {
  if (value > max) {
    value = max;
  } else if (value < 0) {
    value = 0;
  }
  const percentage = (100 * value) / max;
  const percentageString = `${percentage}%`;
  const barStyle = { width: percentageString };
  let labelStyle;
  switch (alignLabel) {
    case 'barCenter':
      labelStyle = barStyle;
      break;
    case 'barEnd':
      labelStyle = { right: percentageString };
      break;
    case 'afterBar':
      labelStyle = { left: percentageString };
  }

  return (
    <Wrapper
      alignLabel={alignLabel}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={value}
      {...rest}
    >
      {typeof children === 'function' ? (
        children({
          alignLabel,
          barStyle,
          percentage,
          percentageString,
        })
      ) : (
        <>
          <Label alignLabel={alignLabel} style={labelStyle} aria-hidden>
            {percentage}%
          </Label>
          <Bar style={barStyle}>
            <Label alignLabel={alignLabel} style={labelStyle}>
              {percentage}%
            </Label>
          </Bar>
        </>
      )}
    </Wrapper>
  );
}

ProgressBar.propTypes = {
  alignLabel: PropTypes.oneOf([
    'start',
    'barCenter',
    'barEnd',
    'center',
    'end',
  ]),
  children: PropTypes.any,
  max: PropTypes.number,
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  alignLabel: 'barCenter',
  max: 1,
  value: 0,
};

ProgressBar.Label = Label;
ProgressBar.Bar = Bar;

import { schema } from '../../../techstyle-shared/redux-core';

export const FeatureErrorType = {
  NOT_FOUND: 'NOT_FOUND',
  OTHER: 'OTHER',
};

export const Feature = new schema.Entity(
  'Feature',
  {},
  {
    idAttribute: 'handle',
    processStrategy(input, parent, key) {
      const { valueType, value, ...rest } = input;
      let error;
      let parsedValue = {};
      try {
        parsedValue = valueType === 'json' ? JSON.parse(value) : value;
      } catch (err) {
        parsedValue = value;
        error = err;
      }

      return { ...rest, error, valueType, value: parsedValue };
    },
  }
);

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import ReviewRatingBar from '../ReviewRatingBar';

const OverallRatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 24px 16px;
  box-shadow: inset 0 -1px 0 0 #eeeeee;
  ${({ overallRatingWrapperStyle }) => overallRatingWrapperStyle};
`;

const OverallRatingTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  text-align: left;
  margin-bottom: 16px;
  color: #333333;
  ${({ overallRatingTitleStyle }) => overallRatingTitleStyle};
`;

const OverallRating = ({
  activeRating,
  barContainerStyle,
  barStyle,
  barFillStyle,
  children,
  counts = {},
  onRatingBarClickHandler,
  overallRatingTitleStyle,
  overallRatingWrapperStyle,
  ratingCountStyle,
  ratingNumberStyle,
  starIcon,
  starIconStyle,
  ...rest
}) => {
  const {
    count,
    rating5Count,
    rating4Count,
    rating3Count,
    rating2Count,
    rating1Count,
  } = counts;

  const ratingCounts = [
    rating5Count,
    rating4Count,
    rating3Count,
    rating2Count,
    rating1Count,
  ];

  return (
    <OverallRatingWrapper
      overallRatingWrapperStyle={overallRatingWrapperStyle}
      {...rest}
    >
      <OverallRatingTitle overallRatingTitleStyle={overallRatingTitleStyle}>
        <FormattedMessage
          id="site_pdp.overall_rating_header"
          defaultMessage="Overall Rating"
        />
      </OverallRatingTitle>

      {children ||
        ratingCounts.map((ratingCount, index) => (
          <ReviewRatingBar
            key={index}
            isActive={5 - index === activeRating}
            starRating={5 - index}
            ratingItemCount={ratingCount}
            reviewCount={count}
            {...{
              barContainerStyle,
              barFillStyle,
              barStyle,
              onRatingBarClickHandler,
              ratingCountStyle,
              ratingNumberStyle,
              starIcon,
              starIconStyle,
            }}
          />
        ))}
    </OverallRatingWrapper>
  );
};

OverallRating.propTypes = {
  /**
   * The currently active rating that is being used as a filter
   */
  activeRating: PropTypes.number,
  /**
   * Custom styles for the the bar container
   */
  barContainerStyle: PropTypes.any,
  /**
   * Custom styles for the filled part of the rating bar
   */
  barFillStyle: PropTypes.any,

  /**
   * Custom styles for the base of the rating bar
   */
  barStyle: PropTypes.any,

  /**
   * Allows the ability to override the rendering of default review rating bars
   */
  children: PropTypes.node,

  /**
   * An object containing the information about individual rating count and total reviewCount
   */
  counts: PropTypes.object,

  /**
   * Click handler when a rating bar is clicked
   */
  onRatingBarClickHandler: PropTypes.func,

  /**
   * Custom styles for the title of the Overall rating section
   */
  overallRatingTitleStyle: PropTypes.any,

  /**
   * Custom styles for the wrapper div of the Overall rating section
   */
  overallRatingWrapperStyle: PropTypes.any,

  /**
   * Custom styles for the rating count at the end of each rating bar
   */
  ratingCountStyle: PropTypes.any,

  /**
   * Custom styles for the rating number at the start of each rating bar
   */
  ratingNumberStyle: PropTypes.any,

  /**
   * Star icon to be used in ReviewRating bar, overrides the default
   */
  starIcon: PropTypes.node,

  /**
   * Custom styles for the star icon in each rating bar
   */
  starIconStyle: PropTypes.any,
};

export default OverallRating;

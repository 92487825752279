import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import SkeletonPulse from '../SkeletonPulse';

/**
 * SkeletonLine renders a line that simulates text loading.
 * Check RecentReviews for a complete example.
 */

const Skeleton = styled(SkeletonPulse)`
  ${({ $width, $height }) => css`
    ${$width &&
    $width.toString().length > 0 &&
    css`
      width: ${typeof $width === 'number' ? `${$width}px` : $width};
    `}
    ${$height &&
    $height.toString().length > 0 &&
    css`
      height: ${typeof $height === 'number' ? `${$height}px` : $height};
    `}
  `};
  border-radius: 3px;

  /* This will add a blank space inside the element so that the
   * gray pulse takes up the line height of the parent
   * without needing to explicitly set the height of the element.
   */
  &::before {
    content: '${'\u2003'}';
  }
`;

export default function SkeletonLine({ width, height, ...rest }) {
  return <Skeleton $width={width} $height={height} {...rest} />;
}

SkeletonLine.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

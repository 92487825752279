export enum OrderStatus {
  /**
   * `processingStatusCode` = 2335
   */
  Test = 'Test Order',
  /**
   * `processingStatusCode` between 2200 and 2299
   * Used for RMA exchange orders. An order will be on hold until the return is completed.
   */
  Hold = 'On Hold',
  /**
   * `processingStatusCode` between 2300 and 2399
   * Used for RMA exchange orders. An order will be on hold until the return is completed.
   */
  Cancelled = 'Cancelled',
  /**
   * `processingStatusCode` between 2100 and 2199
   */
  Shipped = 'Shipped',
  /**
   * Fall back for all other `processingStatusCode` values
   */
  Pending = 'Pending',
  /**
   * `processingStatusCode` = 2110
   *  Seems to be used for membership token orders */
  Complete = 'Complete',
}

export const getOrderStatusFromCode = ({
  processingStatusCode,
}: {
  processingStatusCode?: number;
}) => {
  if (!processingStatusCode) {
    return undefined;
  }

  if (processingStatusCode === 2335) {
    return OrderStatus.Test;
  } else if (processingStatusCode >= 2300) {
    return OrderStatus.Hold;
  } else if (processingStatusCode >= 2200) {
    return OrderStatus.Cancelled;
  } else if (processingStatusCode === 2110) {
    return OrderStatus.Complete;
  } else if (processingStatusCode >= 2100) {
    return OrderStatus.Shipped;
  } else {
    return OrderStatus.Pending;
  }
};

import config from 'config';

import logger from './logger';
import navModule, { loadNav } from './navModule';

const debug = logger.extend('navExtension');

export default function navExtension({ globalPrefetchNavs } = {}) {
  return (registry) => ({
    id: 'navigation',
    server: process.browser
      ? {}
      : {
          init() {
            require('./config');
          },
        },
    page({ prefetchNavs = [] }) {
      if (config.has('public.navigation.globalPrefetchNavs')) {
        // eslint-disable-next-line no-console
        console.warn(
          'Config option public.navigation.globalPrefetchNavs has been deprecated, please migrate to the globalPrefetchNavs option on navExtension.'
        );
      }

      const getPrefetchNavs = registry.utils.getMergedArrayOption({
        globalPrefetchNavs,
        prefetchNavs,
      });

      return {
        async getInitialProps(ctx) {
          const [prefetchNavs, meta] = await getPrefetchNavs(ctx);
          if (prefetchNavs.length) {
            debug(
              'Prefetching %s %s added by %s.',
              prefetchNavs.length,
              prefetchNavs.length === 1 ? 'nav' : 'navs',
              meta.sources.join(', ')
            );
            await ctx.store.dispatch(loadNav(prefetchNavs));
          }
        },
      };
    },
    redux: {
      modules: [navModule],
    },
  });
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import { ProductType } from '../../../../techstyle-shared/react-products';
import { useProductContext } from '../ProductContext';
import useTabIndex from '../useTabIndex';

const Wrapper = styled.span`
  display: inline-block;
  font-size: 12px;
  color: #4d74a0;

  a:link &,
  &:link {
    text-decoration: underline;
  }
`;

const eligibleProductTypes = new Set([
  ProductType.NORMAL,
  ProductType.MEMBERSHIP_REWARD_POINTS_ITEM,
  ProductType.BUNDLE_COMPONENT,
]);

const MoreColorsLabel = React.forwardRef(
  (
    {
      className,
      children = (
        <FormattedMessage
          id="product_cell.more_colors_label"
          defaultMessage="+ more colors"
        />
      ),
      href,
      as = href ? 'a' : undefined,
      product: productFromProps,
      tabIndex,
      maxProductCount = 1,
      ...rest
    },
    ref
  ) => {
    const productFromContext = useProductContext();
    const product = productFromProps || productFromContext;

    const relatedProducts =
      product?.relatedProductIdObjectList?.filter(
        (product) => product.isAvailable && !product.isSelf
      ) || [];
    const isEligible = eligibleProductTypes.has(product.productTypeId);

    tabIndex = useTabIndex(tabIndex);

    return isEligible && relatedProducts.length >= maxProductCount ? (
      <Wrapper
        as={as}
        className={className}
        data-autotag="grid-product-more-colors"
        href={href}
        tabIndex={tabIndex}
        ref={ref}
        {...rest}
      >
        {children}
      </Wrapper>
    ) : null;
  }
);

MoreColorsLabel.displayName = 'MoreColorsLabel';
MoreColorsLabel.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  maxProductCount: PropTypes.number,
  product: PropTypes.object,
  tabIndex: PropTypes.number,
};

export default MoreColorsLabel;

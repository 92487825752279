import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  loadProfiles,
  updateActiveLinkedProfileByGender,
  updateActiveLinkedProfile,
} from './linkedProfilesModule';

export default function useLinkedProfilesActions() {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          loadProfiles,
          updateActiveLinkedProfileByGender,
          updateActiveLinkedProfile,
        },
        dispatch
      ),
    [dispatch]
  );

  return actions;
}

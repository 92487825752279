import React from 'react';

import dynamic from 'next/dynamic';

import { AdminGuard } from '../../../techstyle-shared/react-admin';

import cartModule, { loadCart } from './cartModule';

const CartAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './CartAdminPanel')
);

export default function cartExtension({ defaultCartRequired = false } = {}) {
  return {
    id: 'cart',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <AdminGuard>
              <CartAdminPanel />
            </AdminGuard>
          </>
        );
      },
    },
    page(options) {
      const { cartRequired = defaultCartRequired } = options;

      return {
        async getInitialProps(ctx) {
          if (cartRequired) {
            await ctx.store.dispatch(loadCart());
          }
        },
      };
    },
    redux: {
      modules: [cartModule],
    },
  };
}

export type TLDS =
  | '.ca'
  | '.de'
  | '.dk'
  | '.es'
  | '.fr'
  | '.se'
  | '.com'
  | '.co.uk'
  | '.nl';

type RouteIntlData = {
  page: string;
  intl: {
    [tld in TLDS]?: string;
  };
  aliases?: string[];
  pattern?: string;
  isExternal?: boolean;
};

export type RouteMap = RouteIntlData[];

const addSlash = (path: string) => path.replace(/^\/?/, '/');

// TODO:
// add support for parameterized URLs
// example: https://brand.com?color=red&size=L&sort=best_sellers
export default function getLocalizedPath({
  path,
  tld,
  routeMappings,
}: {
  path: string;
  tld: TLDS;
  routeMappings: RouteMap;
}) {
  path = addSlash(path);
  const foundMapping = routeMappings.find(
    (mapping) => addSlash(mapping.page) === path
  );

  if (!foundMapping) {
    return path;
  }

  const tldPath = foundMapping.intl[tld];

  if (!tldPath) {
    return path;
  }

  return tldPath;
}

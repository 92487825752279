import React, { useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

import useCategoryLabels from './useCategoryLabels';
import useFilterFieldLabels from './useFilterFieldLabels';
import useOptionLabels from './useOptionLabels';
import useReviewSortLabels from './useReviewSortLabels';

export default function createLabelProvider(
  displayName = 'LabelProvider',
  useDefaultLabels
) {
  const Context = React.createContext({
    useLabels: useDefaultLabels,
  });

  function Provider({ children, useLabels }) {
    const value = useMemo(() => ({ useLabels }), [useLabels]);
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  Provider.displayName = `${displayName}.Provider`;
  Provider.propTypes = {
    children: PropTypes.node,
    useLabels: PropTypes.func,
  };

  const useLabels = () => {
    const { useLabels } = useContext(Context);
    // If you want to wrap the defaults, those are provided to the custom hook.
    return useLabels({ useDefaultLabels });
  };

  Provider.Context = Context;
  Provider.useLabels = useLabels;

  return Provider;
}

export const CategoryLabelProvider = createLabelProvider(
  'CategoryLabels',
  useCategoryLabels
);

export const FilterFieldLabelProvider = createLabelProvider(
  'FilterFieldLabels',
  useFilterFieldLabels
);

export const OptionLabelProvider = createLabelProvider(
  'OptionLabels',
  useOptionLabels
);

export const ReviewSortLabelProvider = createLabelProvider(
  'ReviewSortLabels',
  useReviewSortLabels
);

import React, { useMemo } from 'react';

import { useReturnSettings } from '../../../../techstyle-shared/react-accounts';
import type { ReturnsSettingsDto } from '../../../../techstyle-shared/react-accounts';
import { createContext } from '../utils/createContext';

type RMAOptionsContext = {
  itemConditions?: ReturnsSettingsDto['itemConditions'];
  restockingFees?: ReturnsSettingsDto['restockingFees'];
  returnActions?: ReturnsSettingsDto['returnActions'];
  returnReasons?: ReturnsSettingsDto['returnReasons'];
};

const [useContext, Context] = createContext<RMAOptionsContext>('RMAOptions');

type RMAOptionsProps = {
  /**
   * In some instances we need to override the label for a return reason.
   * For example, we need to remove the "(see comment)" part of the "Other" reason.
   */
  returnReasonsLabelOverrides?: Record<number, string>;
};

function Root({
  children,
  returnReasonsLabelOverrides,
}: React.PropsWithChildren<RMAOptionsProps>) {
  const { settings } = useReturnSettings();

  const returnReasons = useMemo(() => {
    if (!settings) {
      return undefined;
    }

    return settings.returnReasons.map((reason) => ({
      ...reason,
      label: returnReasonsLabelOverrides?.[reason.reasonId] || reason.label,
    }));
  }, [returnReasonsLabelOverrides, settings]);

  const contextValue = useMemo(
    () => ({
      itemConditions: settings?.itemConditions,
      restockingFees: settings?.restockingFees,
      returnActions: settings?.returnActions,
      returnReasons,
    }),
    [returnReasons, settings]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export { Root, useContext };

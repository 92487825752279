import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateSessionDetail,
  loadSessionDetails,
  trackSessionQuizStart,
} from './bentoApiModule';

export default function useSessionActions() {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      bindActionCreators(
        { updateSessionDetail, loadSessionDetails, trackSessionQuizStart },
        dispatch
      ),
    [dispatch]
  );
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.color || props.fill || 'black'};
  vertical-align: top;
`;

const FableticsLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      // Let `aria-label` take control if present. Consumers are also free to
      // override with their own `aria-labelledby`.
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 244 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={titleId}>Fabletics</title>
      <g data-word="fabletics">
        <path
          data-letter="F"
          d="M 62.8 10.7 L 62.6 10.7 L 62.8 10.7 L 62.9 14.9 L 51.8 14.9 L 51.8 19.3 L 61.6 19.3 L 61.6 23.4 L 51.8 23.4 L 51.8 31.3 L 47.3 31.3 L 47.3 14.4 C 47.3 12.3 49 10.7 51 10.7 L 62.8 10.7 Z"
        />
        <path
          data-letter="A"
          d="M 76.6 22.7 L 82 22.7 L 79.3 16 L 76.6 22.7 Z M 77.3 10.7 L 81.7 10.7 L 90.4 31.2 L 85.7 31.2 L 83.8 26.6 L 75.1 26.6 L 73.2 31.2 L 68.6 31.2 L 77.3 10.7 Z"
        />
        <path
          data-letter="B"
          d="M 101.7 14.7 L 101.7 19.1 L 105.9 19.1 C 107.9 19.1 109.2 18.4 109.2 16.9 L 109.2 16.8 C 109.2 15.5 108.1 14.7 106.2 14.7 L 101.7 14.7 Z M 110.3 25.1 C 110.3 23.6 109.3 22.8 106.9 22.8 L 101.7 22.8 L 101.7 27.3 L 107.1 27.3 C 109.1 27.3 110.3 26.6 110.3 25.1 Z M 110.9 20.5 C 113.3 21.4 114.8 22.8 114.8 25.5 L 114.8 25.6 C 114.8 29.3 111.7 31.2 107.1 31.2 L 97.3 31.2 L 97.3 14.4 C 97.3 13.419 97.69 12.478 98.384 11.784 C 99.078 11.09 100.019 10.7 101 10.7 L 106.9 10.7 C 111 10.7 113.6 12.8 113.6 16 L 113.6 16.1 C 113.6 18.4 112.4 19.7 110.9 20.5 Z"
        />
        <path
          data-letter="L"
          d="M 126.3 14.9 L 126.3 27.1 L 136.5 27.1 L 136.5 31.2 L 121.8 31.2 L 121.8 14.4 C 121.8 12.357 123.457 10.7 125.5 10.7 L 126.3 10.7 L 126.3 14.9 Z"
        />
        <path
          data-letter="E"
          d="M 148 22.9 L 148 27.2 L 159.1 27.2 L 159.1 31.2 L 143.5 31.2 L 143.5 14.4 C 143.5 12.357 145.157 10.7 147.2 10.7 L 159 10.7 L 159 14.7 L 148 14.7 L 148 18.9 L 157.7 18.9 L 157.7 22.9 L 148 22.9 Z"
        />
        <path
          data-letter="T"
          d="M 181.8 10.7 L 183.1 10.7 L 183.1 14.9 L 176.9 14.9 L 176.9 31.2 L 172.4 31.2 L 172.4 14.8 L 166.2 14.8 L 166.2 14.4 C 166.2 12.357 167.857 10.7 169.9 10.7 L 181.8 10.7 Z"
        />
        <path
          data-letter="I"
          d="M 190.2 14.5 C 190.2 12.4 191.9 10.7 193.9 10.7 L 194.7 10.7 L 194.7 31.2 L 190.2 31.2 L 190.2 14.5 Z"
        />
        <path
          data-letter="C"
          d="M 212.1 27.2 C 208.7 27.2 206.3 24.4 206.3 21 L 206.3 20.9 C 206.3 17.5 208.7 14.7 212.1 14.7 C 214.1 14.7 215.6 15.6 217.2 16.9 L 220 13.7 C 218.2 11.9 215.9 10.6 212.2 10.6 C 206.1 10.6 201.9 15.2 201.9 20.9 L 201.9 21 C 201.845 23.722 202.902 26.348 204.827 28.273 C 206.752 30.198 209.378 31.255 212.1 31.2 C 215.9 31.2 218.2 29.8 220.2 27.7 L 217.4 24.9 C 215.8 26.3 214.4 27.2 212.1 27.2 Z"
        />
        <path
          data-letter="S"
          d="M 236.7 19 C 233.2 18.1 232.4 17.7 232.4 16.4 L 232.4 16.3 C 232.4 15.3 233.3 14.6 235 14.6 C 236.7 14.6 238.4 15.3 240.2 16.6 L 242.5 13.3 C 240.5 11.7 238 10.8 235.1 10.8 C 231 10.8 228.1 13.2 228.1 16.8 L 228.1 16.9 C 228.1 20.9 230.7 22 234.7 23 C 238 23.8 238.7 24.4 238.7 25.5 L 238.7 25.6 C 238.7 26.8 237.6 27.5 235.8 27.5 C 233.5 27.5 231.6 26.6 229.9 25.1 L 227.3 28.2 C 229.7 30.3 232.7 31.4 235.8 31.4 C 240.1 31.4 243.1 29.2 243.1 25.2 L 243.1 25 C 243 21.5 240.7 20.1 236.7 19 Z"
        />
      </g>
      <path
        data-icon=""
        d="M23.8,18.7 C19.3,20.6 11.1,20.3 9.5,20.6 C8,20.9 4.2,20.5 1.5,26 C2.1,25.3 3,24.8 4,24.8 C5.8,24.8 7.2,26.2 7.2,28 C7.2,29.7 5.8,31.2 4,31.2 C2.7,31.2 1.6,30.4 1.1,29.3 C1,29.2 1,29 0.9,28.9 L0.9,28.8 C0.9,28.7 0.9,28.6 0.8,28.5 L0.8,28.1 L0.8,27.8 C0.8,27.4 0.9,27 0.9,26.6 L0.9,26.5 C1.3,24.1 2.1,21.6 2.1,21.6 C2.5,20.2 2.8,19.6 2.8,19.5 C3.7,17.4 5.2,15.1 7.5,14.3 C7.5,14.3 9.4,13.5 14.1,13.6 L15.1,13.6 C19.8,13.7 23,13.3 23,13.3 C26.7,13 28.9,12.3 30.8,11.1 C30.5,12.4 29,16.5 23.8,18.7 Z M35.2,2.4 C34.9,3.4 34.3,4.7 33.2,5.8 C33.2,5.8 31.8,7.7 28.5,9 C28.5,9 26.4,9.9 22.7,10.2 C22.7,10.2 19.6,10.5 14.8,10.5 L13.8,10.5 C9.1,10.5 7.2,11.2 7.2,11.2 C4.9,12 3.4,14.3 2.5,16.4 C2.5,16.5 1.4,19.1 0.4,23.8 L0.4,13.1 C0.4,12.6 0.4,12 0.5,11.5 C0.9,8.1 2.9,5.2 5.8,3.7 C6.4,3.3 9.2,2.2 13.6,2.1 C14.9,2.1 22.4,2 25.7,1.9 C33.4,1.6 35.6,0.6 35.6,0.6 C35.4,1.8 35.2,2.4 35.2,2.4 Z"
      />
    </Svg>
  );
});

FableticsLogo.displayName = 'FableticsLogo';

FableticsLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   * default multi-tone treatment.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default FableticsLogo;

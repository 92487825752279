import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import Overflow from 'react-overflow-indicator';
import styled, { css } from 'styled-components';

import {
  FormattedMessage,
  useIntl,
  defineMessages,
} from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import Drawer from '../Drawer';
import FilterTags from '../FilterTags';
import { ProductFilters } from '../ProductBrowser';
import { useProductBrowser } from '../ProductBrowserContext';
import ProductFilterContext, {
  useProductFilters,
} from '../ProductFilterContext';
import useFilterTagValues from '../useFilterTagValues';

const messages = defineMessages({
  filterDrawerTitle: {
    id: 'site_product_grid.filter_drawer_title',
    defaultMessage: 'Product Filters',
  },
});

const FiltersOverflow = styled(Overflow)`
  flex: 1 1 auto;
  overflow: auto;
`;

const AllFiltersWrapper = styled.div`
  padding-bottom: 16px;
`;

const ApplyFiltersWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  padding: 16px;
  padding-top: 0;
`;

const ApplyFilters = styled(Button).attrs({
  'data-autotag': 'filter-apply',
  variant: 'applyFilters',
})`
  min-width: 248px;
  height: 48px;
  border: none;
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: ${-0.4 / 16}em;
  text-align: center;

  :disabled {
    color: #999;
    background-color: #ddd;
  }

  ${({ buttonStyle }) => buttonStyle};
`;

const getDialogStyle = (additionalStyles) => css`
  background-color: #ffffff;
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.04), inset 1px 0 0 0 #eeeeee;
  width: 280px;
  max-width: calc(100vw - 44px);
  overflow: visible;

  ${additionalStyles}
`;

const OverflowFade = styled.div`
  box-sizing: content-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 16px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #ffffff 20%
  );
  pointer-events: none;
`;

// Needs a z-index of 1 because the shadow won't display over the checkmark areas
const OverflowShadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  pointer-events: none;
  z-index: 1;
`;

const getOverlayStyle = (additionalStyles) => css`
  background-color: rgba(0, 0, 0, 0.5);
  will-change: background-color;

  ${additionalStyles}
`;

const CloseButton = styled(Button).attrs({
  variant: 'closeFilterDrawer',
})`
  position: absolute;
  right: 100%;
  width: 44px;
  height: auto;
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-family: inherit;
  line-height: 1;
  letter-spacing: ${-0.3 / 12}em;
  color: #ffffff;
  text-align: center;
  padding: 10px 4px;

  ${({ closeButtonStyle }) => closeButtonStyle};
`;

const TimesIcon = styled(MdClose)`
  font-size: 16px;
  color: inherit;
  vertical-align: middle;
`;

const FilterTagsWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  padding: 13px 16px;

  ${({ wrapperStyle }) => wrapperStyle};
`;

const NoFilterAppliedMessage = styled.p`
  font-size: 16px;
  line-height: ${22 / 16};
  letter-spacing: ${-0.4 / 16}em;
  color: #999999;

  ${({ noFiltersAppliedStyle }) => noFiltersAppliedStyle};
`;

function ApplyFiltersButton({
  buttonStyle,
  children,
  onClickCallback: onClickFromProps,
  ...rest
}) {
  const { applySelectedFilters, isDirty, selectedFilters } =
    useProductFilters();

  const onApplyFilters = useCallback(() => {
    if (onClickFromProps) {
      onClickFromProps(selectedFilters);
      applySelectedFilters();
    } else {
      applySelectedFilters();
    }
  }, [applySelectedFilters, onClickFromProps, selectedFilters]);

  return (
    <ApplyFilters
      onClick={onApplyFilters}
      disabled={!isDirty}
      buttonStyle={buttonStyle}
      {...rest}
    >
      {children || (
        <FormattedMessage
          id="site_product_grid.apply_filters"
          defaultMessage="Apply Filters"
        />
      )}
    </ApplyFilters>
  );
}

ApplyFiltersButton.propTypes = {
  buttonStyle: PropTypes.any,
  children: PropTypes.node,
  /**
   * An additional `onClick` that is only called here, allowing the
   * existing onClick to be overridded for backward compatibility.
   */
  onClickCallback: PropTypes.func,
};

// We will always get categoryFilter from FilterDrawer either from
// props or from ProductBrowserContext
const FilterDrawerTags = ({
  aggregations: aggregationsFromProps,
  applyOnClearAll = false,
  as: ElementType = FilterTags,
  avgReviewFilter: avgReviewFilterFromProps,
  baseFilters: baseFiltersFromProps,
  categoryFilter: categoryFilterFromProps,
  noFiltersAppliedStyle,
  priceFilter: priceFilterFromProps,
  wrapperStyle,
  ...rest
}) => {
  const {
    applyFilters,
    selectedFilters,
    setSelectedFilters,
    toggleCollapsibleFilterIsOpen,
  } = useProductFilters();
  const productBrowserCtx = useProductBrowser();
  let aggregations = aggregationsFromProps;
  let baseFilters = baseFiltersFromProps;
  let categoryFilter = categoryFilterFromProps;
  let avgReviewFilter = avgReviewFilterFromProps;
  let priceFilter = priceFilterFromProps;

  if (productBrowserCtx) {
    const {
      aggregations: aggregationsFromCtx,
      baseFilters: baseFiltersFromCtx,
      categoryFilter: categoryFilterFromCtx,
      avgReviewFilter: avgReviewFilterFromCtx,
      priceFilter: priceFilterFromCtx,
    } = productBrowserCtx;

    if (!aggregationsFromProps) {
      aggregations = aggregationsFromCtx;
    }
    if (!baseFiltersFromProps) {
      baseFilters = baseFiltersFromCtx;
    }
    if (!categoryFilterFromProps) {
      categoryFilter = categoryFilterFromCtx;
    }
    if (!avgReviewFilterFromProps) {
      avgReviewFilter = avgReviewFilterFromCtx;
    }
    if (!priceFilterFromProps) {
      priceFilter = priceFilterFromCtx;
    }
  }

  const clearAllFilters = useCallback(() => {
    if (applyOnClearAll) {
      applyFilters(baseFilters);
    } else {
      setSelectedFilters(baseFilters);
      // Close all filter drawers;
      toggleCollapsibleFilterIsOpen({ type: 'reset' });
    }
  }, [
    applyFilters,
    applyOnClearAll,
    baseFilters,
    setSelectedFilters,
    toggleCollapsibleFilterIsOpen,
  ]);

  const filterTagValues = useFilterTagValues({
    aggregations,
    avgReviewFilter,
    categoryFilter,
    filters: selectedFilters,
    priceFilter,
    setFilters: setSelectedFilters,
  });

  return (
    <FilterTagsWrapper wrapperStyle={wrapperStyle}>
      {filterTagValues.length ? (
        <ElementType
          selectedFilters={filterTagValues}
          onClearAll={clearAllFilters}
          {...rest}
        />
      ) : (
        <NoFilterAppliedMessage noFiltersAppliedStyle={noFiltersAppliedStyle}>
          <FormattedMessage
            id="site_product_grid.no_filter_applied"
            defaultMessage="No filters applied"
          />
        </NoFilterAppliedMessage>
      )}
      <Overflow.Indicator direction="up">
        <OverflowShadow />
      </Overflow.Indicator>
    </FilterTagsWrapper>
  );
};

FilterDrawerTags.propTypes = {
  aggregations: PropTypes.object,
  applyOnClearAll: PropTypes.bool,
  as: PropTypes.elementType,
  avgReviewFilter: PropTypes.object,
  baseFilters: PropTypes.object,
  categoryFilter: PropTypes.object,
  noFiltersAppliedStyle: PropTypes.any,
  priceFilter: PropTypes.object,
  wrapperStyle: PropTypes.any,
};

const OverflowFilterContent = ({ children, ...rest }) => {
  return (
    <Overflow.Content>
      <AllFiltersWrapper {...rest}>{children}</AllFiltersWrapper>
    </Overflow.Content>
  );
};

OverflowFilterContent.propTypes = {
  children: PropTypes.node,
};

const ApplyFiltersSection = ({
  buttonStyle,
  onClickCallback: onClickFromProps,
  ...rest
}) => (
  <ApplyFiltersWrapper {...rest}>
    <Overflow.Indicator direction="down">
      <OverflowFade />
    </Overflow.Indicator>
    <ApplyFiltersButton
      buttonStyle={buttonStyle}
      onClickCallback={onClickFromProps}
    />
  </ApplyFiltersWrapper>
);

ApplyFiltersSection.propTypes = {
  buttonStyle: PropTypes.any,
  onClickCallback: PropTypes.func,
};

const FilterDrawer = React.forwardRef(
  (
    {
      aggregations: aggregationsFromProps,
      baseFilters: baseFiltersFromProps,
      categoryFilter: categoryFilterFromProps,
      children,
      closeButtonContent,
      closeButtonStyle,
      colorSwatches: colorSwatchesFromProps,
      dialogStyle: dialogStyleFromProps,
      filters: filtersFromProps,
      isOpen: isOpenFromProps,
      onExit: onExitFromProps,
      onSubmit: onSubmitFromProps,
      overlayStyle: overlayStyleFromProps,
      productFilterOptions,
      ...rest
    },
    ref
  ) => {
    const productBrowserCtx = useProductBrowser();
    const dialogStyle = useMemo(
      () => getDialogStyle(dialogStyleFromProps),
      [dialogStyleFromProps]
    );
    const overlayStyle = useMemo(
      () => getOverlayStyle(overlayStyleFromProps),
      [overlayStyleFromProps]
    );

    let aggregations = aggregationsFromProps;
    let baseFilters = baseFiltersFromProps;
    let categoryFilter = categoryFilterFromProps;
    let isOpen = isOpenFromProps;

    if (productBrowserCtx) {
      const {
        aggregations: aggregationsFromCtx,
        baseFilters: baseFiltersFromCtx,
        categoryFilter: categoryFilterFromCtx,
        isFilterDrawerOpen: isOpenFromCtx,
      } = productBrowserCtx;
      if (aggregationsFromProps == null) {
        aggregations = aggregationsFromCtx;
      }
      if (baseFiltersFromProps == null) {
        baseFilters = baseFiltersFromCtx;
      }
      if (categoryFilterFromProps == null) {
        categoryFilter = categoryFilterFromCtx;
      }
      if (isOpenFromProps == null) {
        isOpen = isOpenFromCtx;
      }
    }
    const { appliedFilters } = useProductFilters();
    const intl = useIntl();

    const onExit = useCallback(() => {
      if (onExitFromProps) {
        onExitFromProps();
      } else if (productBrowserCtx) {
        productBrowserCtx.setFilterDrawerOpen(false);
      }
    }, [onExitFromProps, productBrowserCtx]);

    const handleSubmit = useCallback(
      (newFilters) => {
        if (onSubmitFromProps) {
          onSubmitFromProps(newFilters);
        } else if (productBrowserCtx) {
          productBrowserCtx.setFilters(newFilters);
        }
        onExit();
      },
      [onExit, onSubmitFromProps, productBrowserCtx]
    );

    return (
      <Drawer
        data-autotag="filter-overlay"
        dialogStyle={dialogStyle}
        overlayStyle={overlayStyle}
        isOpen={isOpen}
        onExit={onExit}
        position="right"
        ref={ref}
        title={intl.formatMessage(messages.filterDrawerTitle)}
        {...rest}
      >
        <ProductFilterContext
          appliedFilters={appliedFilters}
          onApply={handleSubmit}
        >
          {isOpen && (
            <CloseButton onClick={onExit} closeButtonStyle={closeButtonStyle}>
              {closeButtonContent || (
                <>
                  <TimesIcon />
                  <br />
                  <FormattedMessage
                    id="site_product_grid.close_filters"
                    defaultMessage="close"
                  />
                </>
              )}
            </CloseButton>
          )}

          {children == null ? (
            <FiltersOverflow>
              <FilterDrawerTags
                aggregations={aggregations}
                baseFilters={baseFilters}
                categoryFilter={categoryFilter}
              />
              <OverflowFilterContent>
                <ProductFilters
                  aggregations={aggregations}
                  categoryFilter={categoryFilter}
                  colorSwatches={colorSwatchesFromProps}
                  {...productFilterOptions}
                />
              </OverflowFilterContent>
              <ApplyFiltersWrapper>
                <Overflow.Indicator direction="down">
                  <OverflowFade />
                </Overflow.Indicator>
                <ApplyFiltersButton />
              </ApplyFiltersWrapper>
            </FiltersOverflow>
          ) : (
            children
          )}
        </ProductFilterContext>
      </Drawer>
    );
  }
);

FilterDrawer.displayName = 'FilterDrawer';

FilterDrawer.propTypes = {
  aggregations: PropTypes.array,
  avgReviewFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  baseFilters: PropTypes.object,
  categoryFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  /**
   * children can either be a function or node. If children is a function,
   * you'll need to render FilterDrawer.Content with FilterDrawer.FilterTags,
   * FilterDrawer.Filters wrapping the filters you want to display, and
   * FilterDrawer.ApplyFilters as FilterDrawer.Content's children.
   * The function is passed the FilterDrawer's: aggregations, baseFilters, and categoryFilter.
   */
  children: PropTypes.node,
  closeButtonContent: PropTypes.any,
  closeButtonStyle: PropTypes.any,
  colorSwatches: PropTypes.object,
  dialogStyle: PropTypes.any,
  filters: PropTypes.object,
  isOpen: PropTypes.bool,
  /**
   * Callback function when the filters change.
   */
  onChange: PropTypes.func,
  onExit: PropTypes.func,
  /**
   * Callback function when user clicks "Apply Filters" button.
   */
  onSubmit: PropTypes.func,
  overlayStyle: PropTypes.any,
  priceFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  /**
   * Options to pass as props to ProductFilters
   */
  productFilterOptions: PropTypes.object,
};

export default FilterDrawer;

FilterDrawer.Content = FiltersOverflow;
FilterDrawer.FilterTags = FilterDrawerTags;
FilterDrawer.Filters = OverflowFilterContent;
FilterDrawer.ApplyFilters = ApplyFiltersSection;

import config from 'config';

import { useMembership } from '../../techstyle-shared/react-accounts';

export default function useUserBrands() {
  const BRAND_NAMES_BY_MEMBERSHIP_BRAND_ID = {
    1: 'fabletics',
    2: 'yitty',
  };

  const storefrontBrandName =
    BRAND_NAMES_BY_MEMBERSHIP_BRAND_ID[config.get('public.brand.id')];
  const { signupDateFabletics, signupDateYitty } = useMembership();

  const brandSignupState = {
    fabletics: Boolean(signupDateFabletics),
    yitty: Boolean(signupDateYitty),
  };

  const isSignedUpHere = brandSignupState[storefrontBrandName] ?? false;
  // If account is signed up with multiple brands, current storefront is sorted to top of list
  const userBrands = Object.keys(brandSignupState)
    .filter((key) => brandSignupState[key])
    .sort((a, b) => {
      if (a === storefrontBrandName) {
        return -1;
      }
      if (b === storefrontBrandName) {
        return 1;
      }
      return 0;
    });

  // find the earliest signup date for user in order to determine where the user initially onboarded
  const brandSignupDates = {
    yitty: signupDateYitty
      ? Date.parse(signupDateYitty)
      : Number.MAX_SAFE_INTEGER,
    fabletics: signupDateFabletics
      ? Date.parse(signupDateFabletics)
      : Number.MAX_SAFE_INTEGER,
  };

  const brandSignupDatesSorted = Object.entries(brandSignupDates).sort(
    function (a, b) {
      return a[1] - b[1];
    }
  );

  const originalSignupBrand = brandSignupDatesSorted[0][0];

  return {
    BRAND_NAMES_BY_MEMBERSHIP_BRAND_ID,
    isSignedUpHere,
    originalSignupBrand,
    storefrontBrandName,
    userBrands,
  };
}

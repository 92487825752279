import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import useSessionActions from './useSessionActions';

export default function useSessionDetails() {
  const actions = useSessionActions();

  const sessionDetails = useSelector((state) => state.sessionDetails);

  // Intentionally fire this effect always (no dependenceis). The
  // `loadSessionDetails` action already checks whether it should run or not.
  useEffect(() => {
    actions.loadSessionDetails();
  });

  return sessionDetails;
}

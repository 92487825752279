import React from 'react';

import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import { globalFontFaces, globalBorderBox, globalReset } from '../styles';

const CombinedGlobalStyles = createGlobalStyle`
  ${(props) => props.enableGlobalFontFaces && globalFontFaces};
  ${globalBorderBox};
  ${globalReset};
  ${(props) => props.theme && props.theme.globalStyle};
`;

/**
 * Styles to apply globally (across the entire site).
 * This component will be rendered by the `<App>` and thus remain mounted for
 * the entire lifetime of the application (instead of following each page’s
 * lifecycle).
 *
 * ### Theming
 *
 * Extend the default global styles in your theme object by defining  the
 * `globalStyle` property.
 *
 * ### Fonts
 *
 * `@font-face` definitions will be generated for you automatically if you
 * include an array of font definitions on the `fonts` property of your theme.
 * See [`fontFace` from `polished`](https://polished.js.org/docs/#fontface)
 * for the expected format:
 * ```js
 * {
 *   fonts: [
 *     {
 *       fontFamily: 'Proxima Nova',
 *       fontWeight: 400,
 *       // Do not include a file extension.
 *       fontFilePath: '/static/fonts/ProximaNova-Regular',
 *       // ...it will be added automatically based on this:
 *       fileFormats: ['woff2', 'woff', 'ttf']
 *     }
 *   ]
 * }
 * ```
 * The following defaults are included for each font. Specify your own values to
 * override:
 *
 * - `fileFormats: ['woff2', 'woff']`
 * - `fontDisplay: 'fallback'`
 *
 * The `fonts` array will also be used to preload fonts automatically, if you
 * don’t specify an explicit `preloadFonts` property. The first file format of
 * each font will be preloaded.
 */
export default function GlobalStyle({ enableGlobalFontFaces }) {
  return <CombinedGlobalStyles enableGlobalFontFaces={enableGlobalFontFaces} />;
}

GlobalStyle.propTypes = {
  enableGlobalFontFaces: PropTypes.bool,
};

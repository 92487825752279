import React from 'react';

import styled from 'styled-components';

import {
  FormattedMessage,
  useIntl,
  useCurrency,
  defineMessages,
} from '../../../../techstyle-shared/react-intl';
import { ProductSelectorType } from '../GenericProductAttributeSelector/constants';
import { ProductListingFilterStyle } from '../ProductListingFilter/constants';
import ReviewStars from '../ReviewStars';
import slugify from '../utils/slugify';

import { LabelType } from './constants';
import useCategoryLabels from './useCategoryLabels';
import useReviewSortLabels from './useReviewSortLabels';

const DynamicFormattedMessage = FormattedMessage;

const optionLabels = defineMessages({
  avgReviewMin: {
    id: 'site_product_filter.avg_review_min_value',
    defaultMessage: '{rating} & Up',
  },
});

const AvgRatingStars = styled(ReviewStars)`
  /* FIXME: We should fix alignment in ReviewStars so this isn't necessary. */
  vertical-align: -1px;
`;

// transform passed in label string to add in zero width space
const addZeroWidthSpaces = (label) => {
  return label.replace(/\//g, '/\u200B');
};

/**
 * Option Label Renderers
 */
function getOptionLabelMessage(filterField, option, labelOptions = {}) {
  const { field } = filterField;
  const label =
    option.label || option.defaultMessage || option.value.toString();
  const { filterStyle, selectorType } = labelOptions;

  return {
    id: `site_product_filter.${slugify(field)}_${slugify(label)}`,
    defaultMessage:
      filterStyle === ProductListingFilterStyle.GRID ||
      selectorType === ProductSelectorType.GRID
        ? addZeroWidthSpaces(label)
        : label,
  };
}

export default function useOptionLabels() {
  const intl = useIntl();
  const { formatCurrency } = useCurrency();
  const categoryLabels = useCategoryLabels();
  const reviewSortLabels = useReviewSortLabels();

  // Doesn't use RBs, so no need to have distinct renderLabel and
  // renderLabelString functions.
  const renderPriceRangeString = (option) => {
    const formatOptions = { trimZeros: true };
    // We expect option to look like { min, max }.
    const { min, max } = option.value;
    if (min != null) {
      if (max != null) {
        return `${formatCurrency(min, formatOptions)}–${formatCurrency(
          max,
          formatOptions
        )}`;
      } else {
        return `${formatCurrency(min, formatOptions)}+`;
      }
    }
    if (max != null) {
      return `${formatCurrency(0, formatOptions)}–${formatCurrency(
        max,
        formatOptions
      )}`;
    }
    return '';
  };

  return {
    // eslint-disable-next-line react/display-name
    renderLabel: (filterField, option, labelOptions = {}) => {
      const { labelType, whiteSpace } = labelOptions;
      if (
        labelType === LabelType.CATEGORY_FILTER ||
        labelType === LabelType.CATEGORY_FILTER_TAG
      ) {
        // It's a category.
        return categoryLabels.renderLabel(option, labelOptions);
      } else if (labelType === LabelType.REVIEW_SORT_DROPDOWN) {
        return reviewSortLabels.renderLabel(option, labelOptions);
      }
      switch (filterField.field) {
        case 'band_size':
        case 'cup_size':
          return option.label || option.value;
        case 'avgReviewMin':
          return (
            <DynamicFormattedMessage
              {...optionLabels.avgReviewMin}
              values={{
                rating:
                  labelType === LabelType.PRODUCT_FILTER ? (
                    <AvgRatingStars rating={option.value} />
                  ) : (
                    option.value.toFixed(1)
                  ),
              }}
            />
          );
        case 'price':
          return renderPriceRangeString(option);
      }
      return (
        <DynamicFormattedMessage
          {...getOptionLabelMessage(filterField, option, labelOptions)}
          whiteSpace={whiteSpace}
        />
      );
    },
    renderLabelString: (filterField, option, labelOptions = {}) => {
      const { labelType } = labelOptions;
      if (
        labelType === LabelType.CATEGORY_FILTER ||
        labelType === LabelType.CATEGORY_FILTER_TAG
      ) {
        // It's a category.
        return categoryLabels.renderLabelString(option, labelOptions);
      } else if (labelType === LabelType.REVIEW_SORT_DROPDOWN) {
        return reviewSortLabels.renderLabelString(option, labelOptions);
      }
      switch (filterField.field) {
        case 'band_size':
        case 'cup_size':
          return option.value;
        case 'avgReviewMin':
          return intl.formatMessage(optionLabels.avgReviewMin, {
            rating: option.value.toFixed(1),
          });
        case 'price':
          return renderPriceRangeString(option);
      }
      return intl.formatMessage(
        getOptionLabelMessage(filterField, option, labelOptions)
      );
    },
  };
}

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useAdminTools } from '../../../techstyle-shared/react-admin';

import { getTest } from './abTestModule';
import useABTestActions from './useABTestActions';

export default function useABTest(campaignCode, inParams = {}) {
  const actions = useABTestActions();
  const { enabled: isAdmin } = useAdminTools();
  const testData = useSelector((state) =>
    campaignCode ? getTest(state, campaignCode) : null
  );

  useEffect(() => {
    if (campaignCode) {
      actions.splitTest(campaignCode, { ...inParams, explain: isAdmin });
    }
  });

  return testData;
}

// By default, start the count at 1 and increment by 1 on each additional page.
const defaultPageCountReducer = (pageCount) =>
  pageCount == null ? 1 : pageCount + 1;

/**
 * A hook that returns the default page count reducer for `PageCountProvider`.
 * Why is this a hook if it just returns a static value and doesn't use any
 * other hooks? Because the user can override this, and their custom reducer
 * might make use of hooks.
 */
export default function useDefaultPageCountReducer() {
  return defaultPageCountReducer;
}

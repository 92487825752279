import config from 'config';

import { Gender } from './constants';
import useCustomerDetails from './useCustomerDetails';
import useMembership from './useMembership';

export default function useIsPostReg({ hours } = {}) {
  const membership = useMembership();
  const [gender] = useCustomerDetails(['gender']);
  let isPostReg;

  const postRegHours = hours || config.get('public.postReg.hours');
  const postRegHoursMale = hours || config.get('public.postReg.hoursMale');

  if (gender === Gender.MALE) {
    isPostReg = membership.getCurrentPostRegHours() <= postRegHoursMale;
  } else {
    isPostReg = membership.getCurrentPostRegHours() <= postRegHours;
  }

  return isPostReg;
}

import styled from 'styled-components';

import { desktop, mobile } from '../styles';

const DesktopWrapper = styled.div`
  display: grid;
  column-gap: 40px;
  grid-template-columns: 1fr 360px;
  max-width: 1152px;
  margin: 0 auto;
  padding: 0 16px;

  ${mobile`
    display: none;
   `}
`;

const DesktopLeft = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const DesktopRight = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 0 auto;
  padding: 0 16px;

  ${desktop`
    display: none;
  `}
`;

export default {
  DesktopWrapper,
  DesktopLeft,
  DesktopRight,
  MobileWrapper,
};

export function getCombinedFilterSizeData(data) {
  const sizeObject = {
    field: 'size',
    label: 'Size',
    nestedFilters: [],
    totalAggregationCount: 0,
  };

  // if we already have a size filter field, skip this extra logic
  if (data.some((filterField) => filterField.field === 'size')) {
    return data;
  }

  const firstSizeIndex = data.findIndex((filterField) =>
    filterField.field.startsWith('size_')
  );

  // Handle case where there are no size filters.
  if (firstSizeIndex === -1) {
    return data;
  }

  const transformedData = [];

  data.forEach((filterField) => {
    if (filterField.field.startsWith('size_')) {
      sizeObject.nestedFilters.push(filterField);
      sizeObject.totalAggregationCount += filterField.totalAggregationCount;
    } else {
      transformedData.push(filterField);
    }
  });

  transformedData.splice(firstSizeIndex, 0, sizeObject);

  return transformedData;
}

export function getSizesFromAggregationFilter(
  aggregationFilters,
  currentSizes
) {
  // Create a copy of `currentSizes` so we aren't modifying the reference.
  const selectedSizes = currentSizes ? { ...currentSizes } : {};

  Object.entries(aggregationFilters).forEach(([field, value]) => {
    if (field.startsWith('size')) {
      if (Array.isArray(value) && value.length >= 1) {
        selectedSizes[field] = value;
      } else {
        delete selectedSizes[field];
      }
    }
  });

  if (Object.keys(selectedSizes).length === 0) {
    return undefined;
  }

  return selectedSizes;
}

export function getActiveSizes(profileSizes, sessionSizes) {
  const activeSizes = {};
  const sharedSizes = [];

  if (profileSizes) {
    Object.entries(profileSizes).forEach(([field, value]) => {
      // Attempt to use the session size
      if (sessionSizes?.[field]) {
        sharedSizes.push(field);
      } else {
        // When there is no matching `field` in `sessionSizes` use the profile size
        // Use the first profile size if this is an array
        if (value) {
          activeSizes[field] = Array.isArray(value) ? value[0] : value;
        }
      }
    });
  }

  if (sessionSizes) {
    Object.entries(sessionSizes).forEach(([field, value]) => {
      if (!sharedSizes.includes(field)) {
        const size = Array.isArray(value) ? value[0] : value;

        activeSizes[field] = size;
      } else {
        if (Array.isArray(value)) {
          // When there is only a singular `sessionSize`, set that to the session size
          if (value.length === 1) {
            activeSizes[field] = value[0];
          } else {
            // When there are multiple `sessionSize` values, fall back to the profile size
            activeSizes[field] = Array.isArray(profileSizes[field])
              ? profileSizes[field][0]
              : profileSizes[field];
          }
        } else if (typeof value === 'string') {
          activeSizes[field] = value;
        }
      }
    });
  }

  return activeSizes;
}

/**
 * @description Parses a bra size string into its band size and cup size components.
 *
 * @param {string} braSize - The bra size string to parse (e.g., "34B"), specifically in order of band size and then cup size.
 * @returns {Array<string>} An array containing the band size and cup size.
 *    If the cup size is not present, an empty string is returned as the second element.
 *
 * @example
 * parseBraSize("34B"); // Returns ["34", "B"]
 * parseBraSize("30 DD"); // Returns ["30", "DD"]
 */
export function parseBraSize(braSize) {
  const defaultReturn = ['', ''];
  // check that the input is valid
  if (!braSize || typeof braSize !== 'string') {
    return defaultReturn;
  }
  const braSizeRegExReturn = braSize.match(/[a-z]+|[^a-z]+/gi);

  // check that the regex returned a valid array
  // and that it has at least two elements
  if (!braSizeRegExReturn || braSizeRegExReturn.length < 2) {
    return defaultReturn;
  }

  const [bandSize, cupSize] = braSizeRegExReturn;
  // for cases such as "S" or "L", we don't want to return undefined on the cupSize
  return [bandSize, cupSize || ''];
}

export function parseBraSizes(braSizes) {
  return braSizes.reduce((cupSizesByBand, braSize) => {
    const [bandSize, cupSize] = parseBraSize(braSize);
    const prevCupSizes = cupSizesByBand[bandSize] || [];
    cupSizesByBand[bandSize] = [...prevCupSizes, cupSize];
    return cupSizesByBand;
  }, {});
}

export function isStandardSelectorVariation(value = '') {
  return /regular|normal|normaal|standard|estándar/gi.test(value);
}

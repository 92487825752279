import CheckoutContext, {
  useCheckoutContext,
  getNumberOfSoldOutItems,
} from './CheckoutContext';
import Layout from './Layout';
import OrderSummary, { useOrderSummaryContext } from './OrderSummary';
import PaymentInfo from './PaymentInfo';
import PlaceOrderButton from './PlaceOrderButton';
import RedeemRewards from './RedeemRewards';
import Section from './Section';
import ShippingAddress from './ShippingAddress';
import ShippingMethod from './ShippingMethod';
import ShippingThreshold from './ShippingThreshold';

export default {
  CheckoutContext,
  useCheckoutContext,
  Layout,
  OrderSummary,
  useOrderSummaryContext,
  PaymentInfo,
  PlaceOrderButton,
  RedeemRewards,
  ShippingAddress,
  ShippingMethod,
  ShippingThreshold,
  Section,
  getNumberOfSoldOutItems,
};

import React, { useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();
export const useProductReviews = () => {
  return useContext(Context);
};

// TODO: Remove anything sort/filter related, use what is in the ReviewsDrawerProvider instead
export default function ProductReviewsContext({
  children,
  reviews,
  counts,
  sizes,
}) {
  const context = useMemo(
    () => ({
      reviews,
      counts,
      sizes,
    }),
    [reviews, counts, sizes]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

ProductReviewsContext.propTypes = {
  children: PropTypes.node,
  counts: PropTypes.object,
  reviews: PropTypes.array,
  sizes: PropTypes.array,
};

import React from 'react';

import { IntlProvider as ReactIntlProvider, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { getServerRenderDate } from '../../../../techstyle-shared/redux-core';
import { getLocale, getMessages } from '../intlModule';
import TextComponent from '../TextComponent';

// A version of `react-intl` with modern context and hooks support does not yet
// exist, so set it up ourselves for now.
const Context = React.createContext();

const IntlContextProvider = injectIntl(Context.Provider, {
  intlPropName: 'value',
});

function onError(err) {
  // Ignore annoying 'missing message' warnings because we already have the
  // `highlightBundles` debug option for that.
  if (/Missing message:/.test(err)) {
    return;
  }

  console.error(err); // eslint-disable-line no-console
}

/**
 * A wrapped version of `IntlProvider` from `react-intl` that also wraps its
 * children in `IntlContextProvider` so we can support the `useIntl` hook.
 */
function IntlProvider({ children, ...props }) {
  return (
    <ReactIntlProvider {...props}>
      <IntlContextProvider>{children}</IntlContextProvider>
    </ReactIntlProvider>
  );
}

IntlProvider.propTypes = ReactIntlProvider.propTypes;

IntlProvider.defaultProps = {
  onError,
  textComponent: TextComponent,
};

IntlProvider.Context = Context;

export function mapStateToProps(state, ownProps) {
  return {
    locale: getLocale(state, ownProps),
    messages: getMessages(state, ownProps),
    initialNow: getServerRenderDate(state).getTime(),
  };
}

export default connect(mapStateToProps)(IntlProvider);

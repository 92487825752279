import { useMemo } from 'react';

import useBreakpointSelector from './useBreakpointSelector';
import useTheme from './useTheme';

/**
 * @param {string} [query] - Optional query param
 * @returns {{isMobile: boolean, isDesktop: boolean, mobile: boolean, desktop: boolean, matches: boolean}}
 */
export default function useBreakpoint(query) {
  const theme = useTheme();

  if (!theme) {
    throw new Error(
      'Could not read theme. Have you wrapped your app in a <ThemeProvider>?'
    );
  }

  if (!theme.breakpoints) {
    throw new Error(
      'No breakpoints found in theme; add a `breakpoints` section.'
    );
  }

  const mobileQuery = theme.breakpoints.mobile || null;

  if (!mobileQuery) {
    throw new Error('You must define a `breakpoints.mobile` media query.');
  }

  // Defining a specific `desktop` breakpoint is optional; by default it will be
  // the inverse of `mobile`.
  const desktopQuery = theme.breakpoints.desktop || null;

  const themeBreakpoints = useMemo(
    () => (desktopQuery ? [mobileQuery, desktopQuery] : [mobileQuery]),
    [desktopQuery, mobileQuery]
  );

  const [mobileBreakpoint, desktopBreakpoint] = themeBreakpoints;
  const themeBreakpoint = useBreakpointSelector(
    themeBreakpoints,
    null,
    mobileBreakpoint
  );
  const isMobile = themeBreakpoint === mobileBreakpoint;
  const isDesktop =
    desktopBreakpoint == null
      ? !isMobile
      : themeBreakpoint === desktopBreakpoint;

  if (typeof query === 'string' && theme.breakpoints[query] != null) {
    query = theme.breakpoints[query];
  }

  const queryCacheKey = JSON.stringify(query);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customBreakpoints = useMemo(
    () => (query ? [query] : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryCacheKey]
  );

  const breakpoint = useBreakpointSelector(customBreakpoints);
  const matches = breakpoint != null;

  return {
    isMobile,
    isDesktop,
    mobile: isMobile,
    desktop: isDesktop,
    matches,
  };
}

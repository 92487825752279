import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useGridState } from '../GridProvider';
import { parseAspectRatio } from '../utils/images';

const Wrapper = styled.div`
  --rows: 0;
  --columns: 2;
  --chinHeight: 0px;
  --columnGap: 8px;
  --rowGap: 8px;
  --imageAspectRatio: 1;
  --cellColor: #f2f2f2;
  --imageColor: #ddd;
  --gapColor: #fff;

  --gapWidthTotal: calc((var(--columns) - 1) * var(--columnGap));
  --cellWidth: calc((100% - var(--gapWidthTotal)) / var(--columns));

  --chinHeightTotal: calc(var(--rows) * var(--chinHeight));
  --gapHeightTotal: calc((var(--rows) - 1) * var(--rowGap));
  --imageHeightRelativeToGridHeight: calc(
    (100% - var(--chinHeightTotal) - var(--gapHeightTotal)) / var(--rows)
  );

  --aspectRatioTotal: calc(
    var(--rows) * var(--cellWidth) / var(--imageAspectRatio)
  );
  --totalHeight: calc(
    var(--aspectRatioTotal) + var(--chinHeightTotal) + var(--gapHeightTotal)
  );

  position: relative;
  height: 0;
  padding-bottom: var(--totalHeight);
  background-image: repeating-linear-gradient(
      90deg,
      transparent,
      transparent var(--cellWidth),
      var(--gapColor) var(--cellWidth),
      var(--gapColor) calc(var(--cellWidth) + var(--columnGap))
    ),
    repeating-linear-gradient(
      180deg,
      var(--imageColor),
      var(--imageColor) var(--imageHeightRelativeToGridHeight),
      var(--cellColor) var(--imageHeightRelativeToGridHeight),
      var(--cellColor)
        calc(var(--imageHeightRelativeToGridHeight) + var(--chinHeight)),
      var(--gapColor)
        calc(var(--imageHeightRelativeToGridHeight) + var(--chinHeight)),
      var(--gapColor)
        calc(
          var(--imageHeightRelativeToGridHeight) + var(--chinHeight) +
            var(--rowGap)
        )
    );
`;

/**
 * A single element that renders a grid visually, to be used while the real grid
 * items are still loading. While several options can be passed via props, the
 * typical configuration is expected to look like:
 *
 * - `itemCount` supplied via props.
 * - `columns` and `gap` supplied via `<GridProvider>` context.
 * - Everything else configured via CSS variables in a `styled(PlaceholderGrid)`.
 *
 * The available CSS variables are:
 *
 * - `--rows`: The number of rows to render. If `columns` and `itemCount` are
 *   already supplied, then this is determined automatically.
 * - `--columns`: The number of columns to render.
 * - `--chinHeight`: The height of the cell area below the image (where text
 *   usually goes). Must be in CSS length units.
 * - `--columnGap`: The width between columns. Must be in CSS length units.
 * - `--rowGap`: The height between rows. Must be in CSS length units.
 * - `--imageAspectRatio`: The aspect ratio of the image area. Must be a number.
 * - `--cellColor`: The color of the cell area.
 * - `--imageColor`: The color of the image area.
 * - `--gapColor`: The background color of the grid (used to draw the grid
 *   lines).
 */
export default function PlaceholderGrid({
  children,
  columns,
  gap,
  rowGap: rowGapFromProps,
  columnGap: columnGapFromProps,
  imageAspectRatio,
  itemCount,
  style: styleFromProps,
  totalHeight,
  ...rest
}) {
  const parentGridState = useGridState();

  if (columns == null) {
    if (parentGridState && parentGridState.breakpoint) {
      columns = parentGridState.breakpoint.columns;
    }
  }

  if (gap == null) {
    if (parentGridState && parentGridState.breakpoint) {
      gap = parentGridState.breakpoint.gap;
    }
  }

  if (rowGapFromProps == null) {
    if (parentGridState && parentGridState.breakpoint) {
      rowGapFromProps = parentGridState.breakpoint.rowGap;
    }
  }

  if (columnGapFromProps == null) {
    if (parentGridState && parentGridState.breakpoint) {
      columnGapFromProps = parentGridState.breakpoint.columnGap;
    }
  }

  imageAspectRatio = useMemo(() => {
    return typeof imageAspectRatio === 'string'
      ? parseAspectRatio(imageAspectRatio)
      : imageAspectRatio;
  }, [imageAspectRatio]);

  const style = useMemo(() => {
    // It's a crazy, mixed-up world; maybe the consumer is planning to specify
    // all these via CSS variables? If so, allow them to be undefined in the
    // `style` prop here.
    const rows =
      itemCount != null && columns != null
        ? Math.ceil(itemCount / columns)
        : undefined;
    const gapSize = typeof gap === 'number' ? `${gap}px` : gap;
    const rowGap =
      typeof rowGapFromProps === 'number'
        ? `${rowGapFromProps}px`
        : rowGapFromProps;
    const columnGap =
      typeof columnGapFromProps === 'number'
        ? `${columnGapFromProps}px`
        : columnGapFromProps;
    return {
      '--columns': columns,
      '--rows': rows,
      '--rowGap': rowGap || gapSize,
      '--columnGap': columnGap || gapSize,
      '--imageAspectRatio': imageAspectRatio,
      '--totalHeight': totalHeight == null ? undefined : `${totalHeight}px`,
      ...styleFromProps,
    };
  }, [
    columns,
    gap,
    rowGapFromProps,
    columnGapFromProps,
    imageAspectRatio,
    itemCount,
    styleFromProps,
    totalHeight,
  ]);

  return (
    <Wrapper {...rest} style={style}>
      {children}
    </Wrapper>
  );
}

PlaceholderGrid.propTypes = {
  /**
   * Content to render inside the placeholder; used for adding loading
   * indicators.
   */
  children: PropTypes.node,
  columnGap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The number of columns in the grid. If not specified, it is retrieved from
   * the context provided by `GridProvider` or the `--columns` CSS variable.
   */
  columns: PropTypes.number,
  /**
   * The gap size between columns and rows in the grid. If not specified, it is
   * retrieved from the context provided by `GridProvider`, or the `--rowGap`
   * and `--columnGap` CSS variables.
   */
  gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The image aspect ratio given as a string like `"4:3"` or a precomputed
   * number like `4 / 3`. If not specified, it is retrived from the
   * `--imageAspectRatio` CSS variable, which must be a number.
   */
  imageAspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The number of items in the grid; this is used along with `columns` to
   * automatically determine the number of rows to render. If not provided, the
   * number of rows must be specified in the `--rows` CSS variable.
   */
  itemCount: PropTypes.number,
  rowGap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Style attributes to add in addition to the CSS variable definitions.
   */
  style: PropTypes.object,
  /**
   * An exact pixel height for the placeholder, in case you know what the
   * height should be instead of relying on the automatic estimated height.
   */
  totalHeight: PropTypes.number,
};

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import Router from '../Router';
import { createClickHandler } from '../utils';

export default function CatchLinks({ children }) {
  const handleClick = useMemo(() => {
    return createClickHandler((event, link) => {
      event.preventDefault();
      Router.push(link.href);
    });
  }, []);

  // This ESLint rule makes sure you don't make inaccessible buttons with divs
  // and the like. But in this case, we're intentionally using `onClick` to
  // catch bubbled events, and also don't need a corresponding key event.
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
  return <div onClick={handleClick}>{children}</div>;
}

CatchLinks.propTypes = {
  children: PropTypes.node,
};

import React from 'react';

import PropTypes from 'prop-types';

import useId from '../useId';
import useTheme from '../useTheme';

export default function DownChevron({
  color = 'currentColor',
  size = '1em',
  title,
  ...rest
}) {
  const theme = useTheme();
  const titleId = useId();
  const fill = theme.colors[color] || color;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {title && <title id={titleId}> {title} </title>}
      <path
        d="M14.2812 1.25L13.6875 0.625C13.5312 0.46875 13.2812 0.46875 13.1562 0.625L7.5 6.28125L1.8125 0.625C1.6875 0.46875 1.4375 0.46875 1.28125 0.625L0.6875 1.25C0.53125 1.375 0.53125 1.625 0.6875 1.78125L7.21875 8.3125C7.375 8.46875 7.59375 8.46875 7.75 8.3125L14.2812 1.78125C14.4375 1.625 14.4375 1.375 14.2812 1.25Z"
        fill={fill}
      />
    </svg>
  );
}

DownChevron.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
};

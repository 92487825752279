import React, { useCallback } from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import { Script } from '../../../../techstyle-shared/react-marketing';
import { useDomain } from '../../../../techstyle-shared/redux-core';

const SearchSpringTracking = ({ onReady }) => {
  return (
    <Script
      async
      globalScriptKey="search-spring-js"
      src="//cdn.searchspring.net/intellisuggest/is.min.js"
      onReady={onReady}
      skipServerRender
    />
  );
};

SearchSpringTracking.propTypes = {
  onReady: PropTypes.func,
};

function onReadyPDP({ itemNumber, tld }) {
  const siteIds = config.get('public.search.siteIds');
  const { [tld]: siteId } = siteIds;
  const sku = itemNumber.replace(/-00$/, '');
  try {
    window.IntelliSuggest.init({
      siteId: siteId,
      context: 'Product/' + sku,
      seed: [sku],
    });
    window.IntelliSuggest.viewItem({ sku });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error();
  }
}

export const PDPSearchSpringTracking = ({ itemNumber }) => {
  const { tld } = useDomain();

  const onReady = useCallback(() => {
    onReadyPDP({ itemNumber, tld });
  }, [itemNumber, tld]);

  return <SearchSpringTracking onReady={onReady} />;
};

PDPSearchSpringTracking.propTypes = {
  itemNumber: PropTypes.string,
};

export default SearchSpringTracking;

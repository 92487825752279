import { useMemo } from 'react';

import { ProductType } from '../../../techstyle-shared/react-products';

import { useProductContext } from './ProductContext';
import { findImageInImageSets } from './utils/images';

export default function useProductDetailSchema({
  additionalFields,
  brand,
  canonicalUrl,
  currency,
  product: productFromProps,
}) {
  const productFromContext = useProductContext();
  const product = productFromProps || productFromContext;
  const {
    averageReview,
    defaultUnitPrice,
    description,
    imageSets,
    isAvailable,
    label,
    permalink,
    reviewCount,
    promos,
  } = product;

  const isBundle = useMemo(
    () =>
      product.typeId === ProductType.BUNDLE ||
      product.productTypeId === ProductType.BUNDLE,
    [product]
  );
  const mainPdpImage = useMemo(
    () => findImageInImageSets({ imageSets }),
    [imageSets]
  );
  const availability = isAvailable
    ? 'https://schema.org/InStock'
    : 'https://schema.org/OutOfStock';

  const schemaContent = {
    '@context': 'https://schema.org',
    '@type': isBundle ? 'ProductGroup' : 'Product',
    name: label,
    image: mainPdpImage?.url,
    description,
    offers: {
      '@type': 'Offer',
      url: canonicalUrl,
      availability: availability,
      priceCurrency: currency,
      itemCondition: 'https://schema.org/NewCondition',
      price: promos?.promoGuestPrice || defaultUnitPrice,
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      reviewCount: reviewCount,
      ratingValue: averageReview,
      bestRating: 5,
    },
    sku: permalink,
    brand: {
      '@type': 'Brand',
      name: brand,
    },
    ...(additionalFields || {}),
  };

  return { schemaContent };
}

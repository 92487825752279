import { useMemo } from 'react';

import { bindActionCreators } from 'redux';

import {
  addMembershipSignupRecord,
  addPayment,
  addWishlistItem,
  addWaitlistItem,
  addWaitlistBundle,
  activateTrialToMembership,
  autoLoginPasswordless,
  cancelMembership,
  updatePassword,
  createAddress,
  deleteAddress,
  deleteWishlistItem,
  facebookLogIn,
  forceRegistrationDate,
  forceLoyaltyPointsForAction,
  forceSkipAllowed,
  forgotPassword,
  loginMethods,
  loginMethodsFromEmail,
  requestMultifactorPin,
  submitMultifactorPin,
  getIsMemberFromState,
  loadMembershipCancellationReasons,
  loadBouncebackEndowmentHistory,
  initiateReturn,
  loadAddresses,
  loadCustomerDetails,
  loadOrders,
  loadEmailPreferences,
  loadForeignMembership,
  loadLoyaltyDetails,
  loadLoyaltyPlans,
  loadLoyaltyPointsForActionsProgress,
  loadLoyaltyRewardPromos,
  loadMembership,
  loadMembershipCredits,
  loadMembershipDetails,
  loadMembershipPeriod,
  loadOrderDetails,
  loadPayments,
  loadPhoneNumber,
  loadProfile,
  loadRetailStores,
  loadReturnSettings,
  loadWishlist,
  loadWishlistIds,
  loadWaitlist,
  loadWaitlistIds,
  logIn,
  logOut,
  purchaseMembershipPeriod,
  resetPassword,
  setDefaultAddress,
  signUp,
  skipMembershipPeriod,
  snoozeMembership,
  submitCcpa,
  tokenizeCreditCard,
  updateAddress,
  updateCustomerDetail,
  updateEmailPreferences,
  updateMembershipDetail,
  updateMembershipStatus,
  updatePayment,
  updateProfile,
  verifyPassword,
  loadReviewableProducts,
  loadReviewableProductDetails,
  saveProductReview,
  saveProductReviewImage,
  validateAddress,
  loadShippingLocations,
  verifyIdmeCustomer,
  loadMembershipSuggestedProducts,
  loadHappyReturnBars,
  loadCopsSegmentTraits,
  sendPointsForActionsEvent,
} from './accountsModule';
import {
  invalidateOrderDetails,
  loadRMADetails,
  useModuleDispatch,
  loadLoyaltyHistory,
} from './accountsModuleTyped';

export default function useAccountActions() {
  const dispatch = useModuleDispatch();
  const actions = useMemo(
    () => ({
      ...bindActionCreators(
        {
          activateTrialToMembership,
          addMembershipSignupRecord,
          addPayment,
          addWishlistItem,
          addWaitlistItem,
          addWaitlistBundle,
          autoLoginPasswordless,
          cancelMembership,
          updatePassword,
          createAddress,
          deleteAddress,
          deleteWishlistItem,
          facebookLogIn,
          forceLoyaltyPointsForAction,
          forceRegistrationDate,
          forceSkipAllowed,
          forgotPassword,
          loginMethods,
          loginMethodsFromEmail,
          requestMultifactorPin,
          submitMultifactorPin,
          getIsMemberFromState,
          loadMembershipCancellationReasons,
          loadBouncebackEndowmentHistory,
          initiateReturn,
          loadAddresses,
          loadCustomerDetails,
          loadEmailPreferences,
          loadForeignMembership,
          loadLoyaltyDetails,
          loadLoyaltyPlans,
          loadLoyaltyPointsForActionsProgress,
          loadLoyaltyRewardPromos,
          loadMembership,
          loadMembershipCredits,
          loadMembershipDetails,
          loadMembershipPeriod,
          loadOrderDetails,
          loadPayments,
          loadPhoneNumber,
          loadProfile,
          loadRetailStores,
          loadReturnSettings,
          loadWishlist,
          loadWishlistIds,
          loadWaitlist,
          loadWaitlistIds,
          logIn,
          logOut,
          purchaseMembershipPeriod,
          resetPassword,
          sendPointsForActionsEvent,
          setDefaultAddress,
          signUp,
          skipMembershipPeriod,
          snoozeMembership,
          submitCcpa,
          tokenizeCreditCard,
          updateAddress,
          updateCustomerDetail,
          updateEmailPreferences,
          updateMembershipDetail,
          updateMembershipStatus,
          updatePayment,
          updateProfile,
          verifyPassword,
          loadReviewableProducts,
          loadReviewableProductDetails,
          saveProductReview,
          saveProductReviewImage,
          validateAddress,
          loadShippingLocations,
          verifyIdmeCustomer,
          loadMembershipSuggestedProducts,
          loadHappyReturnBars,
          loadCopsSegmentTraits,
        },
        dispatch
      ),
      loadOrders: (...args: Parameters<typeof loadOrders>) =>
        dispatch(loadOrders(...args)),
      loadRMADetails: (...args: Parameters<typeof loadRMADetails>) =>
        dispatch(loadRMADetails(...args)),
      invalidateOrderDetails: (
        ...args: Parameters<typeof invalidateOrderDetails>
      ) => dispatch(invalidateOrderDetails(...args)),
      loadLoyaltyHistory: (...args: Parameters<typeof loadLoyaltyHistory>) =>
        dispatch(loadLoyaltyHistory(...args)),
    }),
    [dispatch]
  );

  return actions;
}

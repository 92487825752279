import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import styled from 'styled-components';

import TextField from '../TextField';

const ToggleButton = styled.button.attrs({
  type: 'button',
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  font-size: 16px;
`;

function defaultVisibilityToggle(inputType, label) {
  return (
    <ToggleButton aria-label={label}>
      {inputType === 'password' ? <IoMdEyeOff /> : <IoMdEye />}
    </ToggleButton>
  );
}

/**
 * A component that renders a `TextField` with the `password` type, and can
 * optionally include a toggle button to switch to `text` in order to view the
 * currently entered value.
 */
export default function PasswordField({
  visibilityToggle,
  visibilityToggleLabel,
  ...rest
}) {
  const [inputType, setInputType] = useState('password');

  const handleToggle = useCallback((event) => {
    if (event) {
      event.stopPropagation();
    }
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  }, []);

  if (typeof visibilityToggleLabel === 'function') {
    visibilityToggleLabel = visibilityToggleLabel(inputType);
  }

  if (visibilityToggle) {
    if (visibilityToggle === true) {
      visibilityToggle = defaultVisibilityToggle;
    }

    if (typeof visibilityToggle === 'function') {
      visibilityToggle = visibilityToggle(inputType, visibilityToggleLabel);
    }

    if (visibilityToggle) {
      visibilityToggle = React.cloneElement(visibilityToggle, {
        onClick: handleToggle,
      });

      return (
        <TextField type={inputType} afterInput={visibilityToggle} {...rest} />
      );
    }
  }

  return <TextField {...rest} type="password" />;
}

PasswordField.propTypes = {
  /**
   * The password visibility toggle button to render. It accepts several types:
   *
   * - If `true`, the default toggle button will be rendered.
   * - If `false` or `null`, no toggle button will be rendered.
   * - If given a React element, it will be passed an additional `onClick` prop
   *   and used as the toggle button.
   * - If given a function, it accepts the current input type (`password` or
   *   `text`) and the resolved `visibilityToggleLabel` and should return a
   *   React element to render.
   */
  visibilityToggle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.element,
  ]),
  /**
   * The label to apply to the visibility toggle button. The value of
   * `visibilityToggle` can decide what to do with this (such as placing it
   * in `aria-label` vs. on the button itself). If given a function, it will be
   * called with the current input type (`password` or `text`).
   */
  visibilityToggleLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

PasswordField.defaultProps = {
  visibilityToggle: true,
  visibilityToggleLabel: (type) => (type === 'password' ? 'Show' : 'Hide'),
};

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  defineMessages,
  useIntl,
  FormattedMessage,
  FormattedNumber,
} from '../../../../techstyle-shared/react-intl';
import { useProductContext } from '../ProductContext';
import ReviewStars from '../ReviewStars';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
`;

const Count = styled.span`
  color: #888888;
  font-size: 12px;
  line-height: ${16 / 12};
  margin-left: 2px;

  ${({ countStyle }) => countStyle};
`;

const ProductReviewStars = styled(ReviewStars)`
  color: #333333;
  ${({ ratingStyle }) => ratingStyle};
`;

const ariaLabel = defineMessages({
  reviewCount: {
    id: 'review_rating.aria_review_count',
    defaultMessage: '{reviewCount, number} total reviews',
  },
});

const defaultRatingFields = [
  'averageOverallReviewFilteredStores',
  'averageReviewAllStores',
  'averageReview',
];

export const ProductReview = React.forwardRef(
  (
    {
      countAriaLabel,
      countLabel,
      countStyle,
      hideReviewCount = false,
      minReviewCount = 25,
      minReviewRating = 1,
      product: productFromProps,
      ratingAriaLabel,
      ratingFields = defaultRatingFields,
      ratingStyle,
      emptyIcon,
      fullIcon,
      ...rest
    },
    ref
  ) => {
    const productFromContext = useProductContext();
    const product = productFromProps || productFromContext;
    const { reviewCount } = product;
    const intl = useIntl();

    if (!reviewCount) {
      return null; // bail out if no reviews
    }

    let productRating;
    for (const ratingField of ratingFields) {
      const value = product[ratingField];
      if (value != null && value >= minReviewRating) {
        productRating = value;
        break;
      }
    }

    if (productRating == null) {
      // None of the `ratingFields` contained a valid populated value.
      return null;
    }

    const showReviewCount = reviewCount >= minReviewCount && !hideReviewCount;

    if (typeof countAriaLabel === 'function') {
      countAriaLabel = countAriaLabel({ reviewCount });
    }
    if (typeof countAriaLabel === 'undefined') {
      countAriaLabel = intl.formatMessage(ariaLabel.reviewCount, {
        reviewCount,
      });
    }

    if (typeof countLabel === 'function') {
      countLabel = countLabel({ reviewCount });
    }
    if (typeof countLabel === 'undefined') {
      countLabel = (
        <FormattedMessage
          id="review_rating.review_count"
          defaultMessage="({reviewCount})"
          values={{ reviewCount: <FormattedNumber value={reviewCount} /> }}
        />
      );
    }

    return (
      <Wrapper ref={ref} data-autotag="grid-product-rating" {...rest}>
        <ProductReviewStars
          rating={productRating}
          ratingStyle={ratingStyle}
          ratingAriaLabel={ratingAriaLabel}
          emptyIcon={emptyIcon}
          fullIcon={fullIcon}
        />
        {showReviewCount ? (
          <>
            {' '}
            <Count
              aria-label={countAriaLabel}
              countStyle={countStyle}
              data-autotag="grid-product-reviews-count"
            >
              {countLabel}
            </Count>
          </>
        ) : null}
      </Wrapper>
    );
  }
);

ProductReview.displayName = 'ProductReview';
ProductReview.propTypes = {
  /**
   * Represents the aria-label of the review count
   */
  countAriaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * Label of the Product Count. If it's a function, it will pass the reviewCount and return the function.
   */
  countLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * Styles passed to the review count
   */
  countStyle: PropTypes.any,
  /**
   * Star empty icon
   */
  emptyIcon: PropTypes.node,
  /**
   * Star full icon
   */
  fullIcon: PropTypes.node,
  /**
   * Override review count and hide them
   */
  hideReviewCount: PropTypes.bool,
  /**
   * Number that the review count must be greater than or equal to for the count to be displayed
   */
  minReviewCount: PropTypes.number,
  /**
   * Number that the review rating must be greater than or equal to for the rating to be displayed
   */
  minReviewRating: PropTypes.number,
  /**
   * Product information that can either be passed in as a prop or gathered from ProductContext
   */
  product: PropTypes.object,
  /**
   * Represents the aria-label of the rating
   * ex: Rating: 3.3 out of 5
   */
  ratingAriaLabel: PropTypes.string,
  /**
   * Names of the normalized ElasticSearch product fields to use for rating
   * value, in order of preference. If an earlier field in the list has no
   * value, the next one will be used instead.
   */
  ratingFields: PropTypes.string,
  /**
   * Styles to passed to the ReviewStars
   */
  ratingStyle: PropTypes.any,
};

export default ProductReview;

import {
  getLocalizedPath,
  normalizeUrl,
  RouteMap,
  TLDS,
} from '../../../../techstyle-shared/react-navigation';

export default function getNavItemHref({
  url,
  tld,
  routeMappings,
}: {
  routeMappings?: RouteMap;
  url?: string;
  tld?: TLDS;
}) {
  if (!url) {
    return undefined;
  }
  if (!routeMappings || !tld) {
    return normalizeUrl(url);
  }
  const localizedUrl = getLocalizedPath({ path: url, tld, routeMappings });
  return normalizeUrl(localizedUrl);
}

import { css } from 'styled-components';

import { desktop, mobile } from '../../techstyle-shared/react-components';

const defaultVariant = {
  labelPosition: 'floating',
  errorStyle: css`
    ${({ theme }) => theme.errorInput.defaultStyle}
    line-height: unset;
    position: absolute;
    top: 0;
    right: 12px;
    font-size: 14px;
    transform-origin: top right;
    transform: scale(0.75);
    margin-top: 7px;
  `,
  wrapperStyle: css`
    position: relative;
  `,
  inputStyle: css`
    color: ${({ theme }) => theme.colors.textDefault};
    font-size: 14px;
    // prevents the mobile browser auto zoom on focus
    ${mobile`
      font-size: 16px;
    `}
  `,
  inputBoxStyle: css`
    border-radius: ${({ theme }) => theme.borderRadiusInput};
    // 2px bottom padding plus 1px border
    // aligns with the 3px border on error (with no padding in those cases)
    padding: 1px 9px 2px;
    background: ${({ theme }) => theme.colors.backgroundLight};
    border: 1px solid ${({ theme }) => theme.colors.borderLight};
    position: relative;
    transition: border-color 0.2s ease-in-out;

    &[data-has-focus] {
      // x - 1px since focus border from react-components is 2px (for a11y)
      padding: 0px 8px;

      label {
        ${({ hasError: errorProp }) =>
          !errorProp &&
          css`
            color: ${({ theme }) => theme.colors.textEmphasized};
          `}
      }
    }

    ${({ hasError: errorProp }) =>
      errorProp &&
      css`
        border-bottom: 2px solid ${({ theme }) => theme.colors.error};
        padding-bottom: 0;
        &[data-has-focus] {
          padding: 1px 8px 0;
          border-color: ${({ theme }) => theme.colors.error};
        }
      `};

    input {
      padding-left: 8px;
      padding-right: 8px;
    }
  `,
  labelStyle: css`
    && {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.textDeemphasized};
      margin-left: 8px;
    }
  `,
};

const standardVariant = {
  ...defaultVariant,
  inputBoxStyle: css`
    border-radius: 0;
    // 2px bottom padding plus 1px border
    // aligns with the 3px border on error (with no padding in those cases)
    padding: 1px 9px 2px;
    background: ${({ theme }) => theme.colors.backgroundLight};
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
    position: relative;

    ${({ hasError: errorProp }) =>
      errorProp &&
      css`
        border-bottom: 2px solid ${({ theme }) => theme.colors.error};
        // no padding-bottom, instead border takes up 3px.
        padding-bottom: 0;
        padding-top: 1px;
      `};

    input {
      padding-left: 8px;
      padding-right: 8px;
    }

    &[data-has-focus] {
      // -1px since focus border from react-components is 2px (for a11y)
      // top and sides remain same padding on standard since it's bottom-only
      padding: 1px 9px 1px;
      border-top: none;
      border-right: none;
      border-left: none;
      ${mobile`
        ${({ hasError: errorProp }) =>
          errorProp &&
          css`
            padding: 1px 9px 0;
          `}
      `}
      ${({ hasError: errorProp, theme }) =>
        errorProp &&
        css`
          border-color: ${theme.colors.error};
        `}

      label {
        ${({ hasError: errorProp }) =>
          !errorProp &&
          css`
            color: ${({ theme }) => theme.colors.textEmphasized};
          `}
      }
    }
  `,
};

const groupedVariant = {
  inputBoxStyle: css`
    border-radius: 0;
    margin-top: -1px;
    border: 1px solid ${({ theme }) => theme.colors.borderLight};
    outline: 1px solid transparent;
    &[data-has-focus] {
      padding: 1px;
      border-width: 1px;
    }
    &:has(:user-invalid) {
      border-color: ${({ theme }) => theme.colors.error};
      outline: 1px solid ${({ theme }) => theme.colors.error};
    }
  `,
};

const groupedFirst = {
  inputBoxStyle: css`
    ${groupedVariant.inputBoxStyle}
    border-radius: 12px 12px 0 0;
  `,
};

const groupedLast = {
  inputBoxStyle: css`
    ${groupedVariant.inputBoxStyle}
    border-radius: 0 0 12px 12px;
  `,
};

export const textField = {
  variants: {
    // an input with borders on all sides
    default: defaultVariant,
    // An input with only a bottom border
    standard: standardVariant,
    // An input that is default on desktop, standard on mobile
    collapse: {
      labelPosition: 'floating',
      errorStyle: css`
        ${mobile`
          ${standardVariant.errorStyle};
        `}

        ${desktop`
          ${defaultVariant.errorStyle};
        `}
      `,
      wrapperStyle: css`
        ${mobile`
          ${standardVariant.wrapperStyle};
        `}

        ${desktop`
          ${defaultVariant.wrapperStyle};
        `}
      `,
      inputStyle: css`
        ${mobile`
          ${standardVariant.inputStyle};
        `}

        ${desktop`
          ${defaultVariant.inputStyle};
        `}
      `,
      inputBoxStyle: css`
        ${mobile`
          ${standardVariant.inputBoxStyle};
        `}

        ${desktop`
          ${defaultVariant.inputBoxStyle};
        `}
      `,
      labelStyle: css`
        ${mobile`
          ${standardVariant.labelStyle};
        `}

        ${desktop`
          ${defaultVariant.labelStyle};
        `}
      `,
    },
    grouped: groupedVariant,
    'grouped:first': groupedFirst,
    'grouped:last': groupedLast,
  },
};

export const checkbox = {
  defaultStyle: css`
    background-color: transparent;
  `,
  variants: {
    default: {
      inputStyle: css`
        font-size: 12px;
        margin: 0;
        border-color: ${({ theme }) => theme.colors.checkboxInputBorder};
        padding: 4px;
        border-radius: 4px;

        input:checked + && {
          background-color: ${({ theme }) => theme.colors.textEmphasized};
          color: ${({ theme }) => theme.colors.textLight};
          border-color: ${({ theme }) => theme.colors.textEmphasized};
        }

        input:disabled + && {
          background-color: ${({ theme }) => theme.colors.checkboxDisabled};
        }
      `,
    },
    purple: {
      inputStyle: css`
        font-size: 12px;
        margin: 0;
        border-color: ${({ theme }) => theme.colors.checkboxInputBorder};
        padding: 4px;
        border-radius: 4px;

        input:checked + && {
          background-color: ${({ theme }) => theme.colors.backgroundDark};
          color: ${({ theme }) => theme.colors.palette.white};
          border-color: ${({ theme }) => theme.colors.backgroundDark};
        }

        input:disabled + && {
          background-color: ${({ theme }) => theme.colors.checkboxDisabled};
        }
      `,
    },
    black: {
      inputStyle: css`
        font-size: 12px;
        margin: 0;
        border-color: ${({ theme }) => theme.colors.checkboxInputBorder};
        padding: 4px;

        input:checked + && {
          background-color: ${({ theme }) => theme.colors.palette.black};
          color: ${({ theme }) => theme.colors.palette.white};
          border-color: ${({ theme }) => theme.colors.textDefault};
        }

        input:disabled + && {
          background-color: ${({ theme }) => theme.colors.checkboxDisabled};
        }
      `,
    },
    light: {
      inputStyle: css`
        font-size: 12px;
        margin: 0;
        border-color: transparent;
        padding: 4px;

        input:checked + && {
          background-color: ${({ theme }) => theme.colors.backgroundLight};
          color: ${({ theme }) => theme.colors.textDefault};
          border-color: transparent;
        }

        input:disabled + && {
          background-color: ${({ theme }) =>
            theme.colors.backgroundDisabledLight};
        }
      `,
    },
    toggle: {
      checkmarkStyle: css`
        background: #dfdfdf;
        border-radius: 99999px; // fully rounded
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 10px;
        left: 0;
        top: 50%;
        margin-top: -3px; // center within parent

        ${({ $on }) =>
          $on &&
          css`
            background: #e04f39;
          `}
      `,
      inputStyle: css`
        &&& {
          border: none;
          background: none;
          margin: 0;
          padding: 4px 0;
        }
        box-sizing: initial;
        display: inline-block;
        width: 32px;
        height: 20px;
        position: relative;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;

        [data-checkmark] {
          display: none;
        }

        &::before {
          content: '';
          height: 10px;
          background: #dfdfdf;
          border-radius: 99999px; // fully rounded
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 10px;
          left: 0;
          top: 50%;
          margin-top: -3px; // center within parent
        }

        &::after {
          left: 0;
          position: relative;
          display: block;
          content: '';
          width: 20px;
          height: 20px;
          border-radius: 99999px; // full circle
          margin-top: 1.5px; // center within parent
          background: #fbfbfb;
          transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
            padding 0.3s ease, margin 0.3s ease;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        }

        [data-hidden-input]:checked + & {
          &:after {
            left: 40%;
            background: ${({ theme }) => theme.colors.toggleEnabledForeground};
          }

          &:active {
            box-shadow: none;
          }

          &:before {
            background: ${({ theme }) => theme.colors.toggleEnabledBackground};
          }
        }
      `,
    },
  },
};

export const radioButton = {
  defaultStyle: css`
    background-color: transparent;
  `,
  variants: {
    default: {
      inputStyle: css`
        margin: 0;
        border: 1px solid ${({ theme }) => theme.colors.radioButtonInputBorder};
        padding: 7px;

        input:checked + && {
          padding: 7px;
          background-color: ${({ theme }) => theme.colors.backgroundDark};
          color: ${({ theme }) => theme.colors.inputCheckedColor};
          border-color: ${({ theme }) => theme.colors.backgroundDark};
        }
      `,
      inputDisabledStyle: css`
        background-color: ${({ theme }) => theme.colors.radioButtonDisabled};
        opacity: 0.5;
      `,
    },
    orange: {
      inputStyle: css`
        margin: 0;
        border: 1px solid ${({ theme }) => theme.colors.radioButtonInputBorder};
        padding: 7px;

        input:checked + && {
          padding: 7px;
          background-color: ${({ theme }) => theme.colors.backgroundDark};
          color: ${({ theme }) => theme.colors.inputCheckedColor};
          border-color: ${({ theme }) => theme.colors.backgroundDark};
        }
      `,
      inputDisabledStyle: css`
        background-color: ${({ theme }) => theme.colors.radioButtonDisabled};
        opacity: 0.5;
      `,
    },
  },
};

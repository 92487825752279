const assistant = 'Assistant';
const montserrat = 'Montserrat';
// `neue-haas-grotesk-display` is pointing at the “Neue Haas Grotesk Display Pro” typeface under the hood
// 'Neue Haas' font family loaded in DefaultHead component via Adobe Fonts:
const neueHaasDisplay = 'neue-haas-grotesk-display';
const neueHaasText = 'neue-haas-grotesk-text';

const gtPressuraStandard = 'GTPressura';
const gtPressuraExtended = 'GTPressura-Extended';
const playfairDisplay = 'Playfair-Display';

export const fonts = [
  {
    fontFamily: montserrat,
    fontWeight: 700,
    fontFilePath: '/static/fonts/montserrat-v13-latin/montserrat-v13-latin-700',
  },
  {
    fontFamily: assistant,
    fontWeight: 400,
    fontFilePath: '/static/fonts/assistant-v4-latin/assistant-v4-latin-regular',
  },
  {
    fontFamily: assistant,
    fontWeight: 600,
    fontFilePath: '/static/fonts/assistant-v4-latin/assistant-v4-latin-600',
  },
  {
    fontFamily: assistant,
    fontWeight: 700,
    fontFilePath: '/static/fonts/assistant-v4-latin/assistant-v4-latin-700',
  },
  {
    fontFamily: montserrat,
    fontWeight: 700,
    fontFilePath: '/static/fonts/montserrat-v13-latin/montserrat-v13-latin-700',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 300,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Text',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 300,
    fontStyle: 'Italic',
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Text-Italic',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 400,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Regular',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 400,
    fontStyle: 'Italic',
    fontFilePath:
      '/static/fonts/gt-pressura/GT-Pressura-Standard-Regular-Italic',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 600,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Medium',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 600,
    fontStyle: 'Italic',
    fontFilePath:
      '/static/fonts/gt-pressura/GT-Pressura-Standard-Medium-Italic',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 700,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Bold',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 700,
    fontStyle: 'Italic',
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Bold-Italic',
  },
  {
    fontFamily: gtPressuraExtended,
    fontWeight: 400,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Extended-Regular',
  },
  {
    fontFamily: gtPressuraExtended,
    fontWeight: 400,
    fontStyle: 'Italic',
    fontFilePath:
      '/static/fonts/gt-pressura/GT-Pressura-Extended-Regular-Italic',
  },
  {
    fontFamily: playfairDisplay,
    fontWeight: 400,
    fontFilePath: '/static/fonts/temp/PlayfairDisplay-VariableFont_wght',
  },
];

const stacks = {
  baseFamily: `${neueHaasDisplay}, "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif`,
  textFamily: `${neueHaasText}, "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif`,
};

const gtPressuraFamily = {
  standard: `${gtPressuraStandard}, ${stacks.baseFamily}`,
  extended: `${gtPressuraExtended}, ${stacks.baseFamily}`,
};

export const fontStacks = {
  ...stacks,
  alternateFamily: `${montserrat}, ${stacks.baseFamily}`,
  standardGtPressuraFamily: `${gtPressuraFamily.standard}, ${stacks.baseFamily}`,
  extendedGtPressuraFamily: `${gtPressuraFamily.extended}, ${stacks.baseFamily}`,
  tempFamily: `${playfairDisplay}, ${stacks.baseFamily}`,
  navBarFamily: `${assistant}, "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif`,
  vipHomepageFamily: `${stacks.baseFamily}`,
  condensedVipHomepageFamily: `${stacks.baseFamily}`,
};

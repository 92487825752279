import { useCallback, useEffect, useReducer, useRef } from 'react';

import {
  mergeNetworkStatus,
  outOfDateStatus,
  upToDateStatus,
  NetworkStatus,
} from '../../../techstyle-shared/redux-core';

import { LoyaltyHistoryReducerAction } from './constants';
import { LoyaltyTransactionLogDto } from './types';
import useAccountActions from './useAccountActions';

const initialState = {
  pageCount: null,
  pages: {},
  networkStatus: outOfDateStatus(),
};

const initialPageState = {
  historyData: [],
  networkStatus: outOfDateStatus(),
  error: null,
};

type LoyaltyHistoryPage = {
  historyData?: LoyaltyTransactionLogDto[];
  networkStatus: NetworkStatus;
  error?: any;
  page?: number;
};

type LoyaltyHistoryState = {
  pageCount?: null | number;
  pages: Record<string | number, LoyaltyHistoryPage>;
  networkStatus: NetworkStatus;
};

const createInitialPageState = (state: Partial<LoyaltyHistoryPage>) => {
  return {
    ...initialPageState,
    ...state,
  };
};

type ActionType =
  | {
      type: typeof LoyaltyHistoryReducerAction.REQUEST;
      payload: {
        page: number;
        count: number;
      };
    }
  | {
      type: typeof LoyaltyHistoryReducerAction.SUCCESS;
      payload: {
        pageNumber: number;
        pageCount: number;
        page: number;
        count?: number;
        historyData: LoyaltyTransactionLogDto[] | [];
      };
    }
  | {
      type: typeof LoyaltyHistoryReducerAction.RESET;
      payload: {
        page: number;
        count: number;
      };
    }
  | {
      type: typeof LoyaltyHistoryReducerAction.ERROR;
      payload: {
        page: number;
        count: number;
      };
    }
  | {
      type: typeof LoyaltyHistoryReducerAction.CANCEL;
      payload: {
        page: number;
        count: number;
      };
    };

const LoyaltyHistoryReducer = (
  state: LoyaltyHistoryState,
  action: ActionType
): LoyaltyHistoryState => {
  const { page } = action.payload || {};
  switch (action.type) {
    case LoyaltyHistoryReducerAction.REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.page]: createInitialPageState({
            networkStatus: {
              ...state.networkStatus,
              isLoading: true,
            },
          }),
        },
      };
    case LoyaltyHistoryReducerAction.SUCCESS:
      return {
        ...state,
        pageCount: action.payload.pageCount,
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            historyData: action.payload.historyData ?? [],
            error: null,
            networkStatus: upToDateStatus(),
          },
        },
      };
    case LoyaltyHistoryReducerAction.RESET:
      return initialState;
    case LoyaltyHistoryReducerAction.ERROR:
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            networkStatus: outOfDateStatus(),
          },
        },
      };
    case LoyaltyHistoryReducerAction.CANCEL:
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            error: null,
            networkStatus: outOfDateStatus(),
          },
        },
      };
    default:
      return state;
  }
};

type loadLoyaltyHistoryParams = {
  page?: number;
  count?: number;
};

export default function useLoyaltyHistory({
  page = 1,
  count = 3,
}: loadLoyaltyHistoryParams) {
  const actions = useAccountActions();
  const [state, dispatch] = useReducer(LoyaltyHistoryReducer, initialState);
  const firstRun = useRef(true);

  const currentPage = state.pages[page];
  const needCurrentPage =
    !currentPage ||
    (!currentPage.networkStatus.isUpToDate &&
      !currentPage.networkStatus.isLoading);

  const load = useCallback(async () => {
    try {
      dispatch({
        type: LoyaltyHistoryReducerAction.REQUEST,
        payload: { page, count },
      });
      const result = await actions.loadLoyaltyHistory({
        page,
        count,
      });
      if (result.payload) {
        dispatch({
          type: LoyaltyHistoryReducerAction.SUCCESS,
          payload: { page, count, ...result.payload },
        });
      }
    } catch (err) {
      dispatch({
        type: LoyaltyHistoryReducerAction.ERROR,
        payload: { page, count },
      });
    }
  }, [page, count, actions]);

  useEffect(() => {
    if (needCurrentPage) {
      load();
    }
  }, [load, needCurrentPage]);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    dispatch({
      type: LoyaltyHistoryReducerAction.RESET,
      payload: { page, count },
    });
  }, [page, count]);

  const loadedLoyaltyHistory = Object.values(state.pages).reduce(
    (previous: LoyaltyTransactionLogDto[], current) => {
      if (current.historyData) {
        return previous.concat(current.historyData);
      }
      return previous;
    },
    []
  );

  return {
    loadedLoyaltyHistory,
    networkStatus: mergeNetworkStatus(
      Object.values(state.pages).map((page) => page.networkStatus)
    ),
    history: state,
    page:
      currentPage ||
      createInitialPageState({
        page,
      }),
    retry: load,
  };
}

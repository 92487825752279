import React, { createContext, useContext, useState } from 'react';

import {
  TransitionGroup,
  Transition,
  TransitionStatus,
} from 'react-transition-group';
import styled, { CSSProp, keyframes } from 'styled-components';

import withThemeProps from '../withThemeProps';

type ToastMessage = {
  message: React.ReactNode;
  isOpen: boolean;
  duration: number;
  id: number;
};

const DEFAULT_MESSAGE_DURATION = 3000; // 3 seconds
const ANIMATION_DURATION = 300; // 0.3 seconds

type ReplaceMessageType = {
  id: number;
  message: React.ReactNode;
};

type MultiToastMessageContextType = {
  messages: ToastMessage[];

  /**
   * Adds a message to the list of messages.
   * Returns message ID.
   */
  addMessage: ({
    message,
    duration,
    position,
  }: {
    /**
     * The message to be displayed. Either a ReactNode or a function which
     * returns a ReactNode. The function provides helpers for interacting with
     * the toast.
     */
    message:
      | ((helpers: { removeMessage: () => Promise<void> }) => React.ReactNode)
      | React.ReactNode;
    duration?: number;
    position?: 'start' | 'end';
  }) => ToastMessage;

  /**
   * Removes a message by its `ToastMessage.id`
   */
  removeMessage: (id: number) => Promise<void>;
  replaceMessage: (messageDetails: ReplaceMessageType) => void;
};

const MultiToastMessageContext = createContext<MultiToastMessageContextType>({
  messages: [],
  addMessage: () => ({
    message: null,
    isOpen: false,
    duration: 0,
    id: 0,
  }),
  removeMessage: async () => {},
  replaceMessage: () => {},
});

type MultiToastMessageProviderProps = React.PropsWithChildren<{}>;
const MultiToastMessageProvider = ({
  children,
}: MultiToastMessageProviderProps) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const removeMessage = async (id: number) => {
    setMessages((messages) =>
      messages.map((m) => {
        if (m.id === id) {
          return { ...m, isOpen: false };
        }
        return m;
      })
    );

    setTimeout(() => {
      setMessages((messages) => messages.filter((m) => m.id !== id));
      return Promise.resolve();
    }, ANIMATION_DURATION);
  };

  const addMessage = ({
    message,
    duration = DEFAULT_MESSAGE_DURATION,
    position = 'start',
  }: {
    message:
      | ((helpers: { removeMessage: () => Promise<void> }) => React.ReactNode)
      | React.ReactNode;
    duration?: number;
    onHide?: () => void;
    position?: 'start' | 'end';
  }) => {
    const id = Date.now();
    let messageText: React.ReactNode;

    if (typeof message === 'function') {
      messageText = message({ removeMessage: () => removeMessage(id) });
    } else {
      messageText = message;
    }

    const toastMessage = { message: messageText, duration, id, isOpen: true };

    if (position === 'start') {
      setMessages((messages) => [...messages, toastMessage]);
    } else {
      setMessages((messages) => [toastMessage, ...messages]);
    }

    if (duration !== 0) {
      setTimeout(() => removeMessage(id), duration);
    }

    return toastMessage;
  };

  const replaceMessage = ({ id, message }: ReplaceMessageType) => {
    setMessages((messages) => {
      return messages.map((m) => {
        if (m.id === id) {
          return { ...m, message: message };
        }
        return m;
      });
    });
  };

  return (
    <MultiToastMessageContext.Provider
      value={{
        messages,
        addMessage,
        removeMessage,
        replaceMessage,
      }}
    >
      {children}
    </MultiToastMessageContext.Provider>
  );
};

const useMultiToastMessageContext = () => useContext(MultiToastMessageContext);

const appear = keyframes`
  from  {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const disappear = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
    transform: translateY(100%);
  }
`;

const MessageGroup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
`;

type ToastMessageProp = {
  variant?: string;
  $toastMessageStyle?: CSSProp;
  $transitionState: TransitionStatus;
};

const StyledToastMessage = styled.div<ToastMessageProp>`
  width: 95%;
  max-width: 720px;
  margin: 10px auto;
  line-height: 1.5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  align-items: center;
  background-color: white;
  padding: 16px;
  opacity: 1;
  animation: ${(props) =>
      props.$transitionState === 'exited' ||
      props.$transitionState === 'exiting'
        ? disappear
        : appear}
    300ms ease-in-out;
  display: ${(props) =>
    props.$transitionState === 'exited' ? 'none' : 'block'};
  ${({ $toastMessageStyle }) => $toastMessageStyle};
`;

const MultiToastMessage = () => {
  const { messages } = useMultiToastMessageContext();

  return (
    <MessageGroup>
      <TransitionGroup>
        {messages.map((message) => (
          <Transition
            key={message.id}
            in={message.isOpen}
            timeout={ANIMATION_DURATION}
          >
            {(state) => {
              if (React.isValidElement(message.message)) {
                return React.cloneElement(message.message, {
                  // @ts-ignore
                  $transitionState: state,
                });
              } else {
                return (
                  <StyledToastMessage $transitionState={state}>
                    {message.message}
                  </StyledToastMessage>
                );
              }
            }}
          </Transition>
        ))}
      </TransitionGroup>
    </MessageGroup>
  );
};

MultiToastMessage.Provider = MultiToastMessageProvider;

const ToastMessageContent = withThemeProps<
  React.ComponentPropsWithRef<typeof StyledToastMessage>
>(StyledToastMessage, {
  section: 'toastMessage',
  defaultVariant: 'default',
});

export default MultiToastMessage;
export { useMultiToastMessageContext, ToastMessageContent };

import React from 'react';

import styled from 'styled-components';

import { AdminPanel } from '../../../../techstyle-shared/react-admin';
import useCustomer from '../useCustomer';
import useEmailPreferences from '../useEmailPreferences';

const EmailSettingsHeader = styled.h2`
  font-size: inherit;
  font-weight: bold;
  margin: 0 0 10px 0;
`;

const Content = styled.section`
  padding: 20px;
`;

export default function CustomerEmailAdminPanel() {
  const { email: emailAddress } = useCustomer();
  const emailPreferences = useEmailPreferences();

  return (
    <AdminPanel id="emailSettings" label="Email Settings">
      <Content>
        <EmailSettingsHeader>
          Email settings for: {emailAddress}
        </EmailSettingsHeader>

        <AdminPanel.DataTable title="" data={emailPreferences} />
      </Content>
    </AdminPanel>
  );
}

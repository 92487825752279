import React, { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

function saveScrollPosition(url, scrollY) {
  const existingItem = sessionStorage.getItem(url);
  const scrollPosition = { y: scrollY };

  if (existingItem) {
    const parsedItem = JSON.parse(existingItem);
    if (scrollY !== 0) {
      parsedItem.y = scrollY;
    }
    sessionStorage.setItem(url, JSON.stringify(parsedItem));
  } else {
    sessionStorage.setItem(url, JSON.stringify(scrollPosition));
  }
}

function scrollToPosition(targetPosition) {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();
    const intervalId = setInterval(() => {
      const currentScrollPosition = window.scrollY;

      if (
        currentScrollPosition !== targetPosition &&
        Date.now() - startTime < 4000
      ) {
        window.scrollTo(0, targetPosition);
      } else {
        clearInterval(intervalId);
        resolve();
      }
    }, 16);
  });
}

async function waitForElementToLoad(mpid) {
  return new Promise((resolve) => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const element = document.querySelector(`[data-mpid="${mpid}"]`);
          if (element) {
            resolve(element);
            observer.disconnect();
          }
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
  });
}

async function scrollElementIntoView(mpid) {
  const endTime = Date.now() + 3000; // Set end time to 3 seconds from now
  let userInteractionDetected = false;
  const element = await waitForElementToLoad(mpid);

  if (!element) {
    return;
  }

  // Function to handle user interactions
  function handleUserInteraction(event) {
    userInteractionDetected = true;
    window.removeEventListener('scroll', handleUserInteraction);
  }

  do {
    window.addEventListener('scroll', handleUserInteraction);

    await new Promise((resolve) => setTimeout(resolve, 500));
    element.scrollIntoView({ block: 'center' });

    window.removeEventListener('scroll', handleUserInteraction);
  } while (!userInteractionDetected && Date.now() < endTime);
}

async function restoreScrollPosition(url) {
  const scrollPosition = JSON.parse(sessionStorage.getItem(url));

  if (scrollPosition) {
    const { mpid, y } = scrollPosition;
    if (y) {
      await scrollToPosition(y);
    }

    if (mpid) {
      await scrollElementIntoView(mpid);
    }
  }
}

const PageScrollRestoration = () => {
  const router = useRouter();
  const shouldScrollRestore = useRef(false);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';

      const onBeforeUnload = (event) => {
        saveScrollPosition(router.asPath, window.scrollY);
        delete event.returnValue;
      };

      const onRouteChangeStart = () => {
        saveScrollPosition(router.asPath, window.scrollY);
      };

      const onRouteChangeComplete = (url) => {
        const shouldRestore =
          shouldScrollRestore.current ||
          sessionStorage.getItem('shouldScrollRestore') === 'true';

        if (shouldRestore) {
          shouldScrollRestore.current = false;
          sessionStorage.removeItem('shouldScrollRestore');
          restoreScrollPosition(url);
        }
      };

      const handleBeforePopState = () => {
        shouldScrollRestore.current = true;
        sessionStorage.setItem('shouldScrollRestore', 'true');
        return true;
      };

      const onLoad = () => {
        const url = window.location.pathname + window.location.search;
        restoreScrollPosition(url);
      };

      window.addEventListener('beforeunload', onBeforeUnload);
      window.addEventListener('load', onLoad);
      router.events.on('routeChangeStart', onRouteChangeStart);
      router.events.on('routeChangeComplete', onRouteChangeComplete);
      const originalHandler = window.onpopstate;
      router.beforePopState(handleBeforePopState);
      window.onpopstate = handleBeforePopState;

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
        window.removeEventListener('load', onLoad);
        router.events.off('routeChangeStart', onRouteChangeStart);
        router.events.off('routeChangeComplete', onRouteChangeComplete);
        // Remove beforePopState event listener when component unmounts
        router.beforePopState(() => true);
        window.onpopstate = originalHandler;
      };
    }
  }, [router]);

  return <></>;
};

export default function PageScrollRestorationExtension() {
  return {
    id: 'pageScrollRestoration',
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <PageScrollRestoration />
          </>
        );
      },
    },
  };
}

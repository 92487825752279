import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  loadByoConfigs,
  loadProducts,
  loadProductsByIds,
  loadProduct,
  loadProductBundle,
  loadProductsByGeoLocation,
  loadRecommendedProducts,
  loadCustomerRecommendedProducts,
  loadCustomerRecommendedProductsV2,
  loadRelatedProducts,
  loadSubcoItems,
  loadProductPersonalizationOptions,
  loadCustomizationPreview,
  trackProductClicked,
  trackProductListViewed,
  trackProductViewed,
  trackProductPriceViewed,
} from './productsModule';

export default function useProductActions() {
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return bindActionCreators(
      {
        loadByoConfigs,
        loadProducts,
        loadProductsByIds,
        loadProduct,
        loadProductBundle,
        loadProductsByGeoLocation,
        loadRecommendedProducts,
        loadCustomerRecommendedProducts,
        loadCustomerRecommendedProductsV2,
        loadRelatedProducts,
        loadSubcoItems,
        loadProductPersonalizationOptions,
        loadCustomizationPreview,
        trackProductClicked,
        trackProductListViewed,
        trackProductViewed,
        trackProductPriceViewed,
      },
      dispatch
    );
  }, [dispatch]);
  return actions;
}

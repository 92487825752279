import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getRequestedTests } from './abTestModule';

/**
 * Return all the requested A/B tests loaded in the Redux store.
 */
export default function useRequestedABTests() {
  const requestedTests = useSelector(getRequestedTests);

  const requestedTestsByCampaignCode = useMemo(() => {
    const tests = {};
    requestedTests.forEach((test) => {
      tests[test.campaignCode] = test;
    });
    return tests;
  }, [requestedTests]);

  return useMemo(
    () => ({ requestedTests, requestedTestsByCampaignCode }),
    [requestedTests, requestedTestsByCampaignCode]
  );
}

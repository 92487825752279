import React, { useMemo } from 'react';

import styled from 'styled-components';

import {
  AddressDto,
  usePayments,
} from '../../../../techstyle-shared/react-accounts';
import { PaymentResponseInfoDto } from '../../../../techstyle-shared/react-types';
import PaymentTypeIcons from '../PaymentTypeIcons';
import { PaymentMethod } from '../types';
import { createContext } from '../utils/createContext';

const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoItemLine = styled.div``;

const DefaultPaymentLabel = styled.span`
  border: 1px solid;
  border-radius: 10px;
  padding: 2px 8px;
  width: fit-content;
`;

const PaymentWrapper = styled.div`
  display: flex;
`;

const getPaymentInfoItems = ({
  address,
  paymentMethod,
  expMessage,
}: {
  address: AddressDto;
  paymentMethod: PaymentResponseInfoDto;
  expMessage: string;
}) => {
  const { PAYMENT_TYPES } = PaymentTypeIcons;

  const { cardType, nameOnCard, lastFourDigits, expMonth, expYear } =
    paymentMethod;

  const cardFourDigits = `**** ${lastFourDigits}`;
  const cardExpiration = `${expMessage} ${expMonth}/${expYear}`;

  const address2 = `${address.city}, ${address.state} ${address.zip}`;

  switch (cardType) {
    case PAYMENT_TYPES.AFTERPAY:
      return [];
    case PAYMENT_TYPES.APPLPAY:
    case PAYMENT_TYPES.AP_AMEX:
    case PAYMENT_TYPES.AP_DISCOVER:
    case PAYMENT_TYPES.AP_MASTERCARD:
    case PAYMENT_TYPES.AP_VISA:
    case PAYMENT_TYPES.SEPA:
      return [nameOnCard];
    case PAYMENT_TYPES.MC:
    case PAYMENT_TYPES.MASTERCARD:
    case PAYMENT_TYPES.DISCOVER:
    case PAYMENT_TYPES.AMEX:
    case PAYMENT_TYPES.VISA:
    case PAYMENT_TYPES.VI:
    case PAYMENT_TYPES.IDEAL:
      return [nameOnCard, cardFourDigits, cardExpiration];
    case PAYMENT_TYPES.PAYPAL_LITLE:
    case PAYMENT_TYPES.PAYPAL:
    case PAYMENT_TYPES.PAYPAL_BRAINTREE:
      return [nameOnCard, address.address1, address2];
    default:
      return [];
  }
};

export type PaymentMethodContextValue = {
  paymentMethods: PaymentMethod[];
  defaultPaymentMethod: PaymentMethod;
};

const [usePaymentMethods, Context] =
  createContext<PaymentMethodContextValue>('PaymentMethods');

const Root = ({
  children,
  removeDefaultPayment = false,
}: React.PropsWithChildren<{ removeDefaultPayment?: boolean }>) => {
  const { paymentMethods, defaultPaymentMethod }: PaymentMethodContextValue =
    usePayments();

  const value = useMemo(
    () => ({
      paymentMethods: removeDefaultPayment
        ? paymentMethods.filter(({ isDefault }) => !isDefault)
        : paymentMethods,
      defaultPaymentMethod,
    }),
    [paymentMethods, defaultPaymentMethod, removeDefaultPayment]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export {
  PaymentInfo,
  PaymentWrapper,
  InfoItemLine,
  DefaultPaymentLabel,
  Root,
  usePaymentMethods as useContext,
  getPaymentInfoItems,
};

import camelcaseKeys from 'camelcase-keys';

import { schema } from '../../../techstyle-shared/redux-core';

const emptyNullValues = new Set([
  'groupCode',
  'maxProductQuantity',
  'maxPurchaseUnitPrice',
  'maxRequiredPurchaseUnitPrice',
  'membershipPromoCurrentPeriodOnly',
  'membershipPromoExpirationDays',
  'membershipRewardMultiplier',
  'requiredProductQuantity',
  'timeEnd',
  'timeStart',
]);

const booleanValues = new Set([
  'addProductAutomatically',
  'exchangesAllowed',
  'restrictCodeAccessToSpecificMemberships',
  'rushShippingDiscountAllowed',
]);

function processInput(input, parent) {
  const output = {};

  for (let key in input) {
    let value = input[key];
    if (value === '' && (emptyNullValues.has(key) || key.endsWith('Id'))) {
      value = null;
    } else if (booleanValues.has(key)) {
      value = value === 1;
    } else {
      switch (key) {
        case 'dateEnd':
          // FIXME: This works around an API bug with `dateEnd` serialization.
          // Remove when API is fixed.
          if (value && typeof value === 'object') {
            value = value.date_end || null;
          }
          break;
        case 'discounts':
          value = value.map((discount) => {
            const { rate, percentage, ...rest } = camelcaseKeys(discount);
            return {
              ...rest,
              rate: rate === '' ? null : rate,
              percentage: percentage === '' ? null : percentage,
            };
          });
          break;
        case 'statuscode':
          key = 'statusCode';
          break;
        case 'timeStart':
          if (value === '1970-01-01') {
            value = null;
          }
          break;
      }
    }
    output[key] = value;
  }

  return output;
}

export const Promos = new schema.Entity(
  'Promos',
  {},
  {
    idAttribute: 'promoId',
    processStrategy: processInput,
  }
);

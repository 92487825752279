import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { CurrencyFormatContext } from '../useCurrency';

/**
 * Fallback provider (used to override the styling of the currency prefix/suffix labels).
 * Implementation should be handled by brand. This fallback provider ensures that useContext
 * hooks will succeed and defaults to renderable string vals ''Î
 */
export default function CurrencyFormatProvider({
  children,
  currencyDisplay,
  locale,
  maximumFractionDigits,
  minimumFractionDigits,
  prefix,
  prefixString,
  suffix,
  suffixString,
  trimZeros = false,
  useGrouping,
}) {
  if (prefix == null) {
    prefix = '';
  }
  if (prefixString == null) {
    prefixString = typeof prefix === 'string' ? prefix : '';
  }
  if (suffix == null) {
    suffix = '';
  }
  if (suffixString == null) {
    suffixString = typeof suffix === 'string' ? suffix : '';
  }

  const value = useMemo(
    () => ({
      locale,
      prefix,
      prefixString,
      suffix,
      suffixString,
      currencyDisplay,
      minimumFractionDigits,
      maximumFractionDigits,
      useGrouping,
      trimZeros,
    }),
    [
      locale,
      prefix,
      prefixString,
      suffix,
      suffixString,
      currencyDisplay,
      minimumFractionDigits,
      maximumFractionDigits,
      useGrouping,
      trimZeros,
    ]
  );

  return (
    <CurrencyFormatContext.Provider value={value}>
      {children}
    </CurrencyFormatContext.Provider>
  );
}

CurrencyFormatProvider.Context = CurrencyFormatContext;

CurrencyFormatProvider.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * The `Intl.NumberFormat` option of the same name, see:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  currencyDisplay: PropTypes.string,
  /**
   * The locale to use specifically when using the `formatCurrency` API, if
   * different from the site locale.
   */
  locale: PropTypes.string,
  /**
   * The `Intl.NumberFormat` option of the same name, see:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  maximumFractionDigits: PropTypes.number,
  /**
   * * The `Intl.NumberFormat` option of the same name, see:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  minimumFractionDigits: PropTypes.number,
  /**
   * Content to add before the currency value when rendering `<Currency>`.
   */
  prefix: PropTypes.node,
  /**
   * String content to add before the currency value when calling
   * `formatCurrency`. If your `prefix` is a React element rather than a string,
   * you should provide this in case currencies need to be put in places where
   * elements are not supported, like `aria-label`, etc.
   */
  prefixString: PropTypes.string,
  /**
   * Content to add after the currency value when rendering `<Currency>`.
   */
  suffix: PropTypes.node,
  /**
   * String content to add after the currency value when calling
   * `formatCurrency`. If your `prefix` is a React element rather than a string,
   * you should provide this in case currencies need to be put in places where
   * elements are not supported, like `aria-label`, etc.
   */
  suffixString: PropTypes.string,
  /**
   * When true, the `minimumFractionDigits` will automatically be set to zero
   * for whole-number amounts, where there are no fractional digits.
   */
  trimZeros: PropTypes.bool,
  /**
   * The `Intl.NumberFormat` option of the same name, see:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
   */
  useGrouping: PropTypes.bool,
};

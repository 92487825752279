import { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import useABTestActions from './useABTestActions';
import useRequestedABTests from './useRequestedABTests';

/**
 * Fetch all A/B tests the user is in. Only intended for use in the admin panel.
 * Or when using `shouldUseActivatedTests` in the `abTestExtension`.
 */
export default function useActivatedABTests(enabled = true) {
  const { loadActivatedTests } = useABTestActions();
  const {
    networkStatus: { isLoading, isUpToDate },
  } = useSelector((state) => state.abTest.activatedTests);

  // If we split or force a new A/B test, we'd want to invalidate the existing
  // data since it wouldn't include any newly activated tests.
  const { requestedTests, requestedTestsByCampaignCode } =
    useRequestedABTests();

  useEffect(() => {
    if (enabled && !isUpToDate && !isLoading) {
      loadActivatedTests();
    }
  }, [loadActivatedTests, enabled, isLoading, isUpToDate]);

  return useMemo(
    () => ({
      userTests: requestedTests,
      userTestsByCampaignCode: requestedTestsByCampaignCode,
      isLoading,
    }),
    [isLoading, requestedTests, requestedTestsByCampaignCode]
  );
}

export const passwordLengthCheck = (password, requiredLength = 6) => {
  const trimmedValue = password.trim();
  return trimmedValue.length >= requiredLength;
};

export function emailAddressIsValid(string) {
  const emailFormat =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  if (typeof string !== 'string') {
    return false;
  }

  return emailFormat.test(string);
}

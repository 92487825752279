import { VisitorStatus } from '../../../../techstyle-shared/redux-core';

import { createProductDetailViewedEvent } from './createProductDetailViewedEvent';

export const productDetailViewed =
  ({ getContext, createEvent }) =>
  (action = {}, prevState, nextState) => {
    const { payload } = action;

    if (!payload?.product) {
      return;
    }

    const { cookies } = getContext();
    const {
      product,
      bundleProducts,
      selectedProductInformation,
      additionalProperties,
      customerGender,
      options,
    } = payload;
    const {
      storeGroup: { storeId },
    } = nextState;

    let currentProfile;
    let currentGender;

    if (nextState.linkedProfiles) {
      currentProfile =
        nextState.linkedProfiles.allProfiles.find(
          (profile) => profile.isCurrent
        ) || null;

      currentGender = currentProfile ? currentProfile.gender : customerGender;
    }

    const extraValues = {
      automated_test: Boolean(cookies.get('automated_test')) || false, // Whether or not the behavior is driven by automated testing
      customer_gender:
        nextState.customerDetails.gender?.value || currentGender || ' ', // The gender of the person based on their profile
      feature: 'react', // Whether or not the hit is on coldfusion or react
      customer_id: nextState.customer.id?.toString() || '', // The customer_id of the user
      label: product.masterProductId?.toString() || '', // 	master_product_id (product_id associated with style/color - not down to size
      loggedin_status:
        nextState.session.visitorStatus === VisitorStatus.LOGGED_IN, // Whether or not the page or event is viewed logged in or logged out
      product_id: product.masterProductId?.toString() || '', // master_product_id (product_id associated with style/color - not down to size
      session_id: nextState.session.sessionId?.toString() || '', // The internal session id of the person's session
      store_group_id: nextState.session.storeGroup?.toString() || '', // The store_group_id associated with this particular brand/store
      store_id: storeId,
      ...additionalProperties,
    };

    if (bundleProducts?.length) {
      return bundleProducts.map((bundleProduct, index) => {
        return createProductDetailViewedEvent({
          createEvent,
          getContext,
          product: bundleProduct,
          index,
          selectedProductInformation,
          extraValues: {
            bundle_alias: product.alias,
            bundle_index: index,
            bundle_name: product.label,
            bundle_quantity: bundleProducts.length?.toString(),
            bundle_retail_price: product.retailUnitPrice?.toString(),
            bundle_product_id: product.masterProductId?.toString(),
            bundle_sale_price: product.saleUnitPrice?.toString(),
            bundle_url: window.location.href,
            bundle_vip_price: product.defaultUnitPrice?.toString(),
            is_bundle: true,
            ...extraValues,
            product_id: bundleProduct.masterProductId?.toString(),
          },
          options,
        });
      });
    }

    return createProductDetailViewedEvent({
      createEvent,
      getContext,
      extraValues,
      product,
      index: 0,
      selectedProductInformation,
      options,
    });
  };

export default function searchExtension() {
  return {
    id: 'search',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
  };
}

import React, { useContext, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { useProductContext } from '../ProductContext';
import { useProductDetailContext } from '../ProductDetail/ProductDetailContext';

const Context = React.createContext();

export function useModelImageContext() {
  return useContext(Context);
}

/**
 * @function isTargetSetDefaultModelSet
 * @description Determines if a given modelSet and targetSet are equivalent by ID (using model data
 * for standard products) or the associated images (for unisex products lacking model data).
 * @param {Object} modelSet The model set to compare with
 * @param {Object} targetSet The target set used to diff with the modelSet when looking for a match
 * @returns {boolean} If the targetSet matches the modelSet
 */
function isTargetSetDefaultModelSet({ modelSet, targetSet }) {
  return (
    (!!modelSet?.model?.id &&
      !!targetSet?.model?.id &&
      modelSet?.model?.id === targetSet?.model?.id) ||
    JSON.stringify(modelSet?.images) === JSON.stringify(targetSet?.images)
  );
}

export default function ModelImageProvider({
  children,
  isPlusSize: isPlusSizeFromProps,
}) {
  const productDetailFromCtx = useProductDetailContext();
  const { isPlusSizeSelected: isPlusSizeFromCtx } = productDetailFromCtx;
  const isPlusSize = isPlusSizeFromProps ?? isPlusSizeFromCtx;
  const productFromCtx = useProductContext();
  const { imageSets } = productFromCtx;
  const defaultModelSet = useMemo(() => {
    if (isPlusSize) {
      const modelSet = imageSets.plus;

      if (modelSet?.images.length) {
        return modelSet;
      }
    }
    // In rare instances `default` does not exist.
    return imageSets.default || [];
  }, [isPlusSize, imageSets]);

  /**
   * @function modelSets
   * @description Provides the associated modelSets for a given product. This supports plus and standard default
   * models as well as any additionally defined member models.
   * @returns {Array} Collection of member model data for a given product
   */
  const modelSets = useMemo(() => {
    if (
      isTargetSetDefaultModelSet({
        modelSet: defaultModelSet,
        targetSet: imageSets?.plus,
      })
    ) {
      /**
       * `defaultModelSet` is plus, include `imageSets.default` (standard) if available + spread in
       * remainder of `imageSets.memberModels`
       */
      return imageSets.default
        ? [defaultModelSet, imageSets.default, ...imageSets.memberModels]
        : [defaultModelSet, ...imageSets.memberModels];
    }

    if (
      isTargetSetDefaultModelSet({
        modelSet: defaultModelSet,
        targetSet: imageSets?.default,
      })
    ) {
      /**
       * `defaultModelSet` is standard, include `imageSets.plus` (if available) + spread in
       * remainder of `imageSets.memberModels`
       */
      return imageSets.plus
        ? [defaultModelSet, imageSets.plus, ...imageSets.memberModels]
        : [defaultModelSet, ...imageSets.memberModels];
    }

    /**
     * `defaultModelSet` is standard and there is no plus default
     */
    return [defaultModelSet, ...imageSets.memberModels];

    // added eslint exhaustive-deps thrown for `imageSets` so we can
    // continue to be explicit in the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultModelSet,
    imageSets.memberModels,
    imageSets.plus,
    imageSets.default,
  ]);

  const [activeModelIndex, setActiveModelIndex] = useState(0);

  // Set the active model to the default whenever the image set changes
  // (includes switching between plus/default sizes)
  useEffect(() => {
    setActiveModelIndex(0);
  }, [modelSets]);

  const contextValue = useMemo(() => {
    const activeModelSet = modelSets[activeModelIndex] ?? defaultModelSet;

    return {
      activeModel: activeModelSet.model,
      activeModelImages: activeModelSet.images,
      activeModelIndex,
      modelSets,
      setActiveModelIndex,
    };
  }, [modelSets, activeModelIndex, defaultModelSet]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

ModelImageProvider.propTypes = {
  children: PropTypes.node,
  isPlusSize: PropTypes.bool,
};

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AssetContainer,
  ImageAsset,
} from '../../../../techstyle-shared/react-assets';
import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import Heading from '../Heading';
import Section from '../Section';
import { desktop } from '../styles';

const Wrapper = styled(Section)``;

const TextWrapper = styled.div`
  ${(props) => props.textWrapperStyle};
`;

const Title = styled(Heading)`
  ${(props) => props.titleStyle};
`;

const Description = styled.p`
  ${(props) => props.descriptionStyle};
`;

const FilterButton = styled(Button)`
  ${desktop`display: none;`};
  ${(props) => props.filterButtonStyle};
`;

const EmptyGridMessage = ({
  assetContainerName,
  buttonLabel,
  buttonStyle,
  buttonVariant,
  description,
  descriptionStyle,
  onClickButton,
  textWrapperStyle,
  title,
  titleStyle,
  ...rest
}) => {
  const text = (
    <TextWrapper
      textWrapperStyle={textWrapperStyle}
      data-autotag="grid-empty-msg"
    >
      <Title titleStyle={titleStyle}>
        {title || (
          <FormattedMessage
            id="site_product_grid.no_products_title"
            defaultMessage="Hmmm..."
          />
        )}
      </Title>
      <Description descriptionStyle={descriptionStyle}>
        {description || (
          <FormattedMessage
            id="site_product_grid.no_products_description"
            defaultMessage="We couldn't find any matching items"
          />
        )}
      </Description>
      <FilterButton
        filterButtonStyle={buttonStyle}
        data-autotag="filter-change-cta"
        onClick={onClickButton}
        variant={buttonVariant}
      >
        {buttonLabel || (
          <FormattedMessage
            id="site_product_grid.no_products_cta_label"
            defaultMessage="Change Filters"
          />
        )}
      </FilterButton>
    </TextWrapper>
  );

  if (assetContainerName) {
    return (
      <Wrapper {...rest}>
        <AssetContainer name={assetContainerName}>
          {({ data }) =>
            data ? (
              <ImageAsset asset={data} background>
                {text}
              </ImageAsset>
            ) : (
              text
            )
          }
        </AssetContainer>
      </Wrapper>
    );
  } else {
    return <Wrapper {...rest}>{text}</Wrapper>;
  }
};
export default EmptyGridMessage;

EmptyGridMessage.propTypes = {
  /**
   * The Asset Containers name.
   */
  assetContainerName: PropTypes.string,
  /**
   * FormattedMessage to pass in custom rb tags by brand. CTA button text.
   */
  buttonLabel: PropTypes.node,
  /**
   * Styles to pass to the button element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  buttonStyle: PropTypes.any,
  /**
   * The theme variant to render. Defaults to `primary`.
   */
  buttonVariant: PropTypes.string,
  /**
   * FormattedMessage to pass in custom rb tags by brand. Main content text
   */
  description: PropTypes.node,
  /**
   * Styles to pass to the main text. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  descriptionStyle: PropTypes.any,
  /**
   * Function to handle onClick event.
   */
  onClickButton: PropTypes.func,
  /**
   * Styles to pass to the wrapper around the text. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  textWrapperStyle: PropTypes.any,
  /**
   * FormattedMessage to pass in custom rb tags by brand. Header text.
   */
  title: PropTypes.node,
  /**
   * Styles to pass to the "title" or "header" text. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  titleStyle: PropTypes.any,
};

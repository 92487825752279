import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import config from 'config';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import {
  useCookies,
  useSession,
} from '../../../../techstyle-shared/redux-core';
import adminReducer, { initialState } from '../adminReducer';

const ADMIN_COOKIES = {
  ADMIN_JF: 'ADMIN_JF',
  DEBUG: 'ENABLE_DEBUG_V3',
  SECRET_KEY: 'ADMINISTRATOR_SESSION_KEY',
};

// Helper for calling `useLayoutEffect` that shuts up the annoying server
// warning.
const useServerSafeLayoutEffect = process.browser ? useLayoutEffect : () => {};

export const AdminContext = React.createContext({
  // Supply a default context value where `enabled` is false, so that other
  // extensions can safely render `<AdminGuard>` even if the app doesn't have
  // the `adminExtension` itself enabled. Otherwise, the context value would be
  // undefined and thus accessing `enabled` would throw an error.
  enabled: false,
});

export default function AdminProvider({ children, enableCFOverride }) {
  const { session } = useSession();
  const [cookies, setCookie, removeCookie] = useCookies([
    ADMIN_COOKIES.DEBUG,
    ADMIN_COOKIES.SECRET_KEY,
  ]);
  const hash = config.get('public.admin.hash');

  const { isAdmin } = session;
  const router = useRouter();
  // Allow forcing admin tools open in dev mode by adding `?admin=1` or `?admin=true`.
  const [forceAdmin, setForceAdmin] = useState(
    () =>
      config.get('public.admin.allowOverride') &&
      /^(true|1)$/.test(router.query.admin)
  );
  const [activePanelNode, setActivePanelNode] = useState(null);
  const [state, dispatch] = useReducer(adminReducer, initialState);

  const enabled =
    isAdmin ||
    forceAdmin ||
    // The following cookies are added by CF when you enable the debug panel.
    // For now we're going to enable the react panel if they're present.
    (cookies[ADMIN_COOKIES.SECRET_KEY] &&
      cookies[ADMIN_COOKIES.DEBUG] === 'on' &&
      enableCFOverride);

  useEffect(() => {
    if (enableCFOverride) {
      if (enabled) {
        setCookie(ADMIN_COOKIES.DEBUG, 'on', { secure: true });
        setCookie(ADMIN_COOKIES.ADMIN_JF, hash, { secure: true });
      } else {
        removeCookie(ADMIN_COOKIES.DEBUG);
      }
    }
  }, [enabled, enableCFOverride, hash, removeCookie, setCookie]);

  const value = useMemo(() => {
    return {
      ...state,
      dispatch,
      enabled,
      isAdmin,
      forceAdmin,
      setForceAdmin,
      activePanelNode,
      setActivePanelNode,
    };
  }, [enabled, state, isAdmin, forceAdmin, activePanelNode]);

  useServerSafeLayoutEffect(() => {
    state.effects.forEach((effect) => effect());
  }, [state.effects]);

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
}

AdminProvider.Context = AdminContext;

AdminProvider.propTypes = {
  children: PropTypes.node,
  enableCFOverride: PropTypes.bool,
};

import React from 'react';

import Script from 'next/script';
import PropTypes from 'prop-types';

import config from '../../config/default';

/**
 * Render a JavaScript snippet that loads Cognigy Web Messenger.
 */
const defaultCustomerDetail = {};
const defaultCallback = () => {};
const defaultOptions = {};
const src = config.public.webchatScriptUrl;

export default function CognigyWebChatSnippet({
  buCode = '',
  callback = defaultCallback,
  cogRegion = '',
  cognigyEndpointKey = '',
  customerDetail = defaultCustomerDetail,
  options = defaultOptions,
}) {
  return (
    <>
      <Script
        src={src}
        strategy="lazyOnload"
        onReady={() => {
          if (window?.initWebchat && !window.webchat) {
            window
              .initWebchat(
                `https://endpoint-app-us.cognigy.ai/${cognigyEndpointKey}`,
                {
                  settings: {
                    startBehavior: {
                      startBehavior: 'injection',
                      getStartedText: 'Get Started',
                      getStartedData: {
                        name: customerDetail?.name,
                        customerId: customerDetail?.customerId,
                        buCode: buCode,
                        cogRegion: cogRegion,
                      },
                    },
                    ...options,
                  },
                }
              )
              .then((webchat) => {
                window.webchat = webchat;
                callback();
              });
          }
        }}
      />
    </>
  );
}

CognigyWebChatSnippet.propTypes = {
  buCode: PropTypes.string,
  callback: PropTypes.func,
  cogRegion: PropTypes.string,
  cognigyEndpointKey: PropTypes.string,
  customerDetail: PropTypes.object,
  options: PropTypes.object,
};

import {
  gql,
  useLazyQuery,
  useQuery,
} from '../../../techstyle-shared/react-graphql';

export const INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT = gql`
  fragment IndividualProductMainParts on IndividualProduct {
    permalink
    masterProductId
    groupCode
    label: title
    type
    productTypeId: typeId
    isWaitlistEligible
    productCategoryIdList: categoryIds
    wearItWithProductIds
    featuredProductLocationIdList
    tagIdList: tagIds
    dateExpected
    description
    metadata {
      description
      title
    }
    isAvailable
  }
`;

export const INDIVIDUAL_PRODUCT_PRICE_FRAGMENT = gql`
  fragment IndividualProductPriceParts on IndividualProduct {
    retailUnitPrice: regularPrice
    defaultUnitPrice: vipPrice
    saleUnitPrice: salePrice
    tokenRedemptionValue
  }
`;

export const INDIVIDUAL_PRODUCT_PROMOS_FRAGMENT = gql`
  fragment IndividualProductPromosParts on IndividualProduct {
    promos: promo {
      promoTypeId: typeId
      promoPrice: price
      promoLabel: label
      promoCode: code
      displayOnPdp: displayOnPDP
    }
  }
`;

export const IMAGE_SET_FRAGMENT = gql`
  fragment ProductImageSetParts on ImageSet {
    images {
      isVideo
      videoPath
      angle
      sizes {
        height
        width
        url
      }
    }
    model {
      firstName
      lastName
      heightInInches
      id
      braSize
      topSize
      bottomSize
    }
  }
`;

export const INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT = gql`
  ${IMAGE_SET_FRAGMENT}
  fragment IndividualProductImagesParts on IndividualProduct {
    imageSets {
      default {
        ...ProductImageSetParts
      }
      plus {
        ...ProductImageSetParts
      }
      memberModels {
        ...ProductImageSetParts
      }
    }
  }
`;

export const INDIVIDUAL_PRODUCT_VIDEO_FRAGMENT = gql`
  fragment IndividualProductVideoParts on IndividualProduct {
    video {
      url
      source
      sourceId
    }
  }
`;

export const INDIVIDUAL_PRODUCT_REVIEW_SUMMARY_FRAGMENT = gql`
  fragment IndividualProductReviewSummaryParts on IndividualProduct {
    reviewCount
    averageReview
  }
`;

export const INDIVIDUAL_PRODUCT_SELECTORS_FRAGMENT = gql`
  fragment IndividualProductSelectorsParts on IndividualProduct {
    swatches {
      color
      url
    }
    attributes {
      label
      field
      options {
        label
        value
        alias
      }
    }
    skus {
      requiredUserStatus
      dateAvailablePreorder
      masterProductId
      productId
      availableQuantity
      isPreorder
      permalink
      options {
        attribute
        value
      }
    }
  }
`;

export const INDIVIDUAL_PRODUCT_ALL_FRAGMENT = gql`
  ${INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PROMOS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRICE_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_VIDEO_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_REVIEW_SUMMARY_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_SELECTORS_FRAGMENT}
  fragment IndividualProductAllParts on IndividualProduct {
    ...IndividualProductMainParts
    ...IndividualProductPromosParts
    ...IndividualProductPriceParts
    ...IndividualProductImagesParts
    ...IndividualProductVideoParts
    ...IndividualProductReviewSummaryParts
    ...IndividualProductSelectorsParts
  }
`;

export const BUNDLE_PRODUCT_MAIN_DETAILS_FRAGMENT = gql`
  fragment BundleProductMainParts on BundleProduct {
    label: title
    masterProductId
    permalink
    wearItWithProductIds
    featuredProductLocationIdList
    productTypeId: typeId
    dateExpected
    tagIdList: tagIds
    description
    metadata {
      description
      title
    }
  }
`;

export const BUNDLE_PRODUCT_PRODUCT_IMAGES_FRAGMENT = gql`
  ${IMAGE_SET_FRAGMENT}
  fragment BundleProductImagesParts on BundleProduct {
    imageSets {
      default {
        ...ProductImageSetParts
      }
      plus {
        ...ProductImageSetParts
      }
      memberModels {
        ...ProductImageSetParts
      }
    }
  }
`;

export const BUNDLE_PRODUCT_VIDEO_FRAGMENT = gql`
  fragment BundleProductVideoParts on BundleProduct {
    video {
      url
      source
      sourceId
    }
  }
`;

export const BUNDLE_PRODUCT_PRICE_FRAGMENT = gql`
  fragment BundleProductPriceParts on BundleProduct {
    retailUnitPrice: regularPrice
    defaultUnitPrice: vipPrice
    saleUnitPrice: salePrice
    tokenRedemptionValue
  }
`;

export const BUNDLE_PRODUCT_PROMOS_FRAGMENT = gql`
  fragment BundleProductPromosParts on BundleProduct {
    promos: promo {
      promoTypeId: typeId
      promoPrice: price
      promoLabel: label
      promoCode: code
      displayOnPdp: displayOnPDP
    }
  }
`;

export const BUNDLE_PRODUCT_REVIEW_SUMMARY_FRAGMENT = gql`
  fragment BundleProductReviewSummaryParts on BundleProduct {
    reviewCount
    averageReview
  }
`;

export const BUNDLE_PRODUCT_ALL_FRAGMENT = gql`
  ${BUNDLE_PRODUCT_MAIN_DETAILS_FRAGMENT}
  ${BUNDLE_PRODUCT_PRODUCT_IMAGES_FRAGMENT}
  ${BUNDLE_PRODUCT_VIDEO_FRAGMENT}
  ${BUNDLE_PRODUCT_PRICE_FRAGMENT}
  ${BUNDLE_PRODUCT_PROMOS_FRAGMENT}
  ${BUNDLE_PRODUCT_REVIEW_SUMMARY_FRAGMENT}
  fragment BundleProductAllParts on BundleProduct {
    ...BundleProductMainParts
    ...BundleProductImagesParts
    ...BundleProductVideoParts
    ...BundleProductPriceParts
    ...BundleProductPromosParts
    ...BundleProductReviewSummaryParts
  }
`;

export const GET_PRODUCT_BY_IDENTIFIER_QUERY = gql`
  ${INDIVIDUAL_PRODUCT_ALL_FRAGMENT}
  ${BUNDLE_PRODUCT_ALL_FRAGMENT}
  query ProductByIdentifier($permalink: String, $productId: Int) {
    product(permalink: $permalink, productId: $productId) {
      ... on IndividualProduct {
        ...IndividualProductAllParts
      }
      ... on BundleProduct {
        ...BundleProductAllParts
        bundleComponentProducts {
          ...IndividualProductAllParts
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_PERMALINK_QUERY = gql`
  ${INDIVIDUAL_PRODUCT_ALL_FRAGMENT}
  ${BUNDLE_PRODUCT_ALL_FRAGMENT}
  query ProductByPermalink($permalink: String, $fetchRelatedProducts: Boolean) {
    product: productByPermalink(
      permalink: $permalink
      fetchRelatedProducts: $fetchRelatedProducts
    ) {
      ... on IndividualProduct {
        ...IndividualProductAllParts
      }
      ... on BundleProduct {
        ...BundleProductAllParts
        bundleComponentProducts {
          ...IndividualProductAllParts
        }
      }
    }
  }
`;

export const GET_PRODUCT_BY_PRODUCT_ID_QUERY = gql`
  ${INDIVIDUAL_PRODUCT_ALL_FRAGMENT}
  ${BUNDLE_PRODUCT_ALL_FRAGMENT}
  query ProductByProductId($productId: Int, $fetchRelatedProducts: Boolean) {
    product: productByProductId(
      productId: $productId
      fetchRelatedProducts: $fetchRelatedProducts
    ) {
      ... on IndividualProduct {
        ...IndividualProductAllParts
      }
      ... on BundleProduct {
        ...BundleProductAllParts
        bundleComponentProducts {
          ...IndividualProductAllParts
        }
      }
    }
  }
`;

export const useProductQuery = (
  { permalink, productId },
  options = {},
  query
) => {
  return useQuery(query ?? GET_PRODUCT_BY_IDENTIFIER_QUERY, {
    ...options,
    variables: {
      permalink,
      productId,
      ...options.variables,
    },
  });
};

export const useLazyProductQuery = (
  { permalink, productId },
  options = {},
  query
) => {
  return useLazyQuery(query ?? GET_PRODUCT_BY_IDENTIFIER_QUERY, {
    ...options,
    variables: {
      permalink,
      productId,
      ...options.variables,
    },
  });
};

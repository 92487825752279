import React from 'react';

import { IoMdClose } from 'react-icons/io';
import styled, { css } from 'styled-components';

import { useAccountActions } from '../../../../techstyle-shared/react-accounts';
import {
  useCartActions,
  isEchoCartLineId,
} from '../../../../techstyle-shared/react-cart';
import { Currency } from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import {
  OrderLineItemSubset,
  CartItemDiscount,
} from '../Checkout/checkoutTypes';
import Picture from '../Picture';
import { createContext } from '../utils/createContext';
import getCartItemSoldOutStatus from '../utils/getCartItemSoldOutStatus';

import OrderLineItemDiscounts from './OrderLineItemDiscounts';

export type OrderLineItemType = OrderLineItemSubset & {
  discount?: Partial<CartItemDiscount>;
  bundleItems?: OrderLineItemSubset[];
};

type OrderLineItemContextValueType = {
  orderLineItem: OrderLineItemType;
  thumbnailSrc: {};
  isBundle: boolean;
  isSoldOut: boolean;
};

type RootProps = React.PropsWithChildren<{
  orderLineItem: OrderLineItemType;
}>;

const [useOrderLineItemContext, OrderLineItemContext] =
  createContext<OrderLineItemContextValueType>('OrderLineItemContext');

const getThumbnailImageSrc = (orderLineItem: OrderLineItemType) => {
  if (!orderLineItem) {
    return {};
  }
  const thumbnailImageSrc = orderLineItem.thumbnailImageSrc
    ? orderLineItem.thumbnailImageSrc
    : '';
  const thumbnailImageHeight = orderLineItem.thumbnailImageHeight
    ? orderLineItem.thumbnailImageHeight
    : '';
  const thumbnailImageWidth = orderLineItem.thumbnailImageWidth
    ? orderLineItem.thumbnailImageWidth
    : '';
  const url = thumbnailImageSrc?.includes('http:')
    ? thumbnailImageSrc.replace('http:', '')
    : thumbnailImageSrc;

  return [
    {
      images: [
        {
          height: Number(thumbnailImageHeight),
          width: Number(thumbnailImageWidth),
          url: url,
        },
      ],
    },
  ];
};

const OrderLineItem = ({ children, orderLineItem }: RootProps) => {
  const thumbnailSrc = getThumbnailImageSrc(orderLineItem);
  const isBundle = !!orderLineItem?.bundleItems?.length;
  const soldOutStatus = getCartItemSoldOutStatus(orderLineItem);
  const context = {
    orderLineItem: orderLineItem,
    thumbnailSrc: thumbnailSrc,
    isBundle: isBundle,
    isSoldOut: soldOutStatus,
  };

  return (
    <OrderLineItemContext.Provider value={context}>
      {children}
    </OrderLineItemContext.Provider>
  );
};

// Content
const OrderLineItemContent = styled.div`
  display: flex;
`;

// Picture
type OrderLineItemPictureProps = React.PropsWithRef<any>;

const OrderLineItemPicture = (props: OrderLineItemPictureProps) => {
  const { thumbnailSrc } = useOrderLineItemContext();
  return <Picture sources={thumbnailSrc} {...props} />;
};

// Item Name
const OrderLineItemName = styled.div``;

type OrderLineItemProductNameProps = React.ComponentPropsWithRef<
  typeof OrderLineItemName
>;

const OrderLineItemProductName = (props: OrderLineItemProductNameProps) => {
  const { orderLineItem } = useOrderLineItemContext();
  return (
    <OrderLineItemName {...props}>
      {orderLineItem?.masterLabel}
    </OrderLineItemName>
  );
};

// Promo Message
const OrderLineItemPromoMsg = styled.div``;

type OrderLineItemPromoMessageProps = React.ComponentPropsWithRef<
  typeof OrderLineItemPromoMsg
>;

const OrderLineItemPromoMessage = (props: OrderLineItemPromoMessageProps) => {
  const { orderLineItem } = useOrderLineItemContext();
  if (!orderLineItem?.discount?.promoLabel) {
    return null;
  }
  return (
    <OrderLineItemPromoMsg {...props}>
      {orderLineItem.discount.promoLabel}
    </OrderLineItemPromoMsg>
  );
};

// Item size/color Value
const SizeOrColorItemValue = styled.span``;

interface PriceProps {
  $showStrikethrough?: boolean;
  $strikethroughColor?: string;
}

const Price = styled(Currency)<PriceProps>`
  ${({ $showStrikethrough, $strikethroughColor, theme }) =>
    $showStrikethrough &&
    css`
      color: ${$strikethroughColor || theme.colors.textDisabled};
      text-decoration: line-through;
      font-weight: normal;
    `};
`;

const StyledDeleteButton = styled(Button).attrs({
  variant: 'unstyled',
})`
  cursor: pointer;
  background: none;
  border: none;
`;

type DeleteButtonProps = React.ComponentPropsWithRef<
  typeof StyledDeleteButton
> & {
  onSuccess?: () => void;
  onError?: () => void;
  onClick?: (cancelDelete: () => void) => void;
  onClickDeleteDelay?: number;
};

const DeleteButton = ({
  onSuccess,
  onError,
  onClick,
  onClickDeleteDelay = 0,
  children = <IoMdClose />,
  ...rest
}: DeleteButtonProps) => {
  const { orderLineItem, isBundle } = useOrderLineItemContext();
  const cartActions = useCartActions();

  const performDelete = async () => {
    try {
      let response: ReturnType<typeof cartActions.removeCartLineItem>;
      if (isEchoCartLineId(orderLineItem.cartLineId)) {
        response = await cartActions.removeCartLineItem(
          orderLineItem.cartLineId
        );
      } else if (isBundle) {
        response = await cartActions.removeBundleFromCart(
          orderLineItem.groupKey
        );
      } else if (orderLineItem.lineId) {
        response = await cartActions.removeCartLineItem(orderLineItem.lineId);
      } else {
        response = await cartActions.removeProductFromCart(
          orderLineItem.productId
        );
      }

      if (
        [
          'cart/removeCartLineItemSuccess',
          'cart/removeProductFromCartSuccess',
          'cart/removeBundleFromCartSuccess',
        ].includes(response.type)
      ) {
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (onError) {
          onError();
        }
      }
    } catch (e) {
      if (onError) {
        onError();
      }
    }
  };

  const handleClick = async () => {
    let isCancelled = false;
    if (onClick) {
      onClick(() => (isCancelled = true));
    }

    setTimeout(async () => {
      if (!isCancelled) {
        await performDelete();
      }
    }, onClickDeleteDelay);
  };

  return (
    <StyledDeleteButton onClick={handleClick} {...rest}>
      {children}
    </StyledDeleteButton>
  );
};

const StyledSaveForLaterButton = styled(Button).attrs({
  variant: 'unstyled',
})`
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  line-height: 16px;
  font-size: 14px;
`;

type SaveForLaterButtonProps = React.ComponentPropsWithRef<
  typeof StyledSaveForLaterButton
> & {
  onSuccess?: () => void;
  onError?: (e?: any) => void;
};

const SaveForLaterButton = ({
  onSuccess,
  onError,
  children,
  ...rest
}: SaveForLaterButtonProps) => {
  const { orderLineItem } = useOrderLineItemContext();
  const cartActions = useCartActions();
  const accountActions = useAccountActions();

  const handleClick = async () => {
    try {
      // add product to wish list
      const responseWishList = await accountActions.addWishlistItem(
        orderLineItem.masterProductId
      );
      if (responseWishList) {
        // then delete product from cart
        let response: ReturnType<typeof cartActions.removeCartLineItem>;
        if (orderLineItem.cartLineId) {
          response = await cartActions.removeCartLineItem(
            orderLineItem.cartLineId
          );
        } else {
          response = await cartActions.removeProductFromCart(
            orderLineItem.productId
          );
        }

        if (
          response.type === 'cart/removeCartLineItemSuccess' ||
          response.type === 'cart/removeProductFromCartSuccess'
        ) {
          onSuccess && onSuccess();
        } else {
          onError && onError();
        }
      }
    } catch (e) {
      onError && onError(e);
    }
  };

  return (
    <StyledSaveForLaterButton onClick={handleClick} {...rest}>
      {children}
    </StyledSaveForLaterButton>
  );
};

const Message = styled.div``;

type MessageProps = React.ComponentPropsWithRef<typeof Message> & {
  children: React.ReactNode;
};

const SoldOutMessage = ({ children, ...rest }: MessageProps) => {
  const { isSoldOut } = OrderLineItem.useContext();
  if (!isSoldOut) {
    return null;
  } else {
    return <Message {...rest}>{children}</Message>;
  }
};

OrderLineItem.Context = OrderLineItem;
OrderLineItem.useContext = useOrderLineItemContext;
OrderLineItem.Content = OrderLineItemContent;
OrderLineItem.Picture = OrderLineItemPicture;
OrderLineItem.ProductName = OrderLineItemProductName;
OrderLineItem.PromoMessage = OrderLineItemPromoMessage;
OrderLineItem.SizeOrColorItemValue = SizeOrColorItemValue;
OrderLineItem.Price = Price;
OrderLineItem.getThumbnailImageSrc = getThumbnailImageSrc;
OrderLineItem.DeleteButton = DeleteButton;
OrderLineItem.SaveForLaterButton = SaveForLaterButton;
OrderLineItem.SoldOutMessage = SoldOutMessage;
OrderLineItem.Discounts = OrderLineItemDiscounts;

export default OrderLineItem;

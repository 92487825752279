import React from 'react';

import TrackPackage from '../TrackPackage';
import { OrderStatus } from '../utils/getOrderStatusFromCode';

import AccountOrderDetails from './index';

const Link = ({
  children,
  ...rest
}: React.ComponentPropsWithRef<typeof TrackPackage.Link>) => {
  const { orderData } = AccountOrderDetails.useContext();

  return (
    <TrackPackage.Link tracking={orderData?.tracking} {...rest}>
      {children}
    </TrackPackage.Link>
  );
};

const Disclaimer = ({
  children,
  ...rest
}: React.ComponentPropsWithRef<typeof TrackPackage.Disclaimer>) => {
  const { orderData } = AccountOrderDetails.useContext();
  const hasOrderShipped = orderData?.orderStatus === OrderStatus.Shipped;

  return !hasOrderShipped ? (
    <TrackPackage.Disclaimer {...rest}>{children}</TrackPackage.Disclaimer>
  ) : null;
};

export { Link, Disclaimer };

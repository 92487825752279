import Cookies from 'universal-cookie';

/**
 * Create a `universal-cookie` Cookies instance, but with the `set` and `remove`
 * methods overridden to include the passed `domain` and `path` defaults.
 */
export default function createCookieInstanceWithDefaultOptions({
  domain: defaultDomain,
  path: defaultPath,
  secure: defaultSecure,
  initialCookieString,
}: {
  domain?: string;
  path?: string;
  secure?: boolean;
  initialCookieString?: string;
}) {
  const cookiesInstance = new Cookies(initialCookieString);
  const _originalSet = cookiesInstance.set;
  cookiesInstance.set = function (name, value, options) {
    const {
      domain = defaultDomain,
      path = defaultPath,
      secure = defaultSecure,
      ...restOptions
    } = options || {};

    // Avoid setting a cookie with undefined name
    if (!name) {
      return;
    }

    return _originalSet.call(this, name, value, {
      domain,
      path,
      secure,
      ...restOptions,
    });
  };

  const _originalRemove = cookiesInstance.remove;
  cookiesInstance.remove = function (name, options) {
    const {
      domain = defaultDomain,
      path = defaultPath,
      secure = defaultSecure,
      ...restOptions
    } = options || {};
    return _originalRemove.call(this, name, {
      domain,
      path,
      secure,
      ...restOptions,
    });
  };

  return cookiesInstance;
}

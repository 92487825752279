import { useEffect, useState } from 'react';

import {
  upToDateStatus,
  outOfDateStatus,
} from '../../../techstyle-shared/redux-core';

import useAccountActions from './useAccountActions';

const initialState = {
  reviewPages: null,
  networkStatus: outOfDateStatus(),
};

const useReviewableProductDetails = (masterProductId) => {
  const actions = useAccountActions();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const loadProductDetails = async () => {
      if (masterProductId) {
        const result = await actions.loadReviewableProductDetails(
          masterProductId
        );
        setState({
          reviewPages: result.payload,
          networkStatus: upToDateStatus(),
        });
      }
    };
    loadProductDetails();
  }, [masterProductId, setState, actions]);

  return state;
};

export default useReviewableProductDetails;

import {
  loadRouteTypesSuccess,
  loadRewritesSuccess,
} from './dynamicRoutesModule';
import logger from './logger';

const debug = logger.extend('assetRoutesModule');

function initAssetRoutes() {
  return (dispatch, getState, context) => {
    if (!process.browser && context.req && !context.hasPreloadedState) {
      const { rewritesFromAssets, rewritesEnabled } = context.req.context;

      if (rewritesEnabled && rewritesFromAssets) {
        const existingRouteTypeByPath =
          getState().dynamicRoutes.routeTypeByPath;

        const routeTypes = {};
        const routeInfos = {};

        Object.values(rewritesFromAssets).forEach((routeInfo) => {
          // FIXME: Filter out ColdFusion paths that are invalid for the React
          // app. For now, ignore anything that was normalized to `/`.
          if (routeInfo.canonicalPath !== '/') {
            // Don't replace existing rewrite info; real rewrites should take
            // precedence over asset rewrites.
            if (!existingRouteTypeByPath[routeInfo.canonicalPath]) {
              routeTypes[routeInfo.canonicalPath] = routeInfo.routeType;
              routeInfos[routeInfo.canonicalPath] = routeInfo;
            }
          }
        });

        const filteredCount = Object.keys(routeTypes).length;
        debug(
          'Adding %d asset %s to store (after filtering).',
          filteredCount,
          filteredCount === 1 ? 'rewrite' : 'rewrites'
        );

        dispatch(loadRouteTypesSuccess(routeTypes));
        dispatch(
          loadRewritesSuccess(null, {
            entities: {
              Rewrite: routeInfos,
            },
          })
        );
      }
    }
  };
}

export default {
  id: 'assetRoutes',
  reducerMap: {},
  initialActions: [initAssetRoutes()],
};

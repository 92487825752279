import React, { useCallback, useEffect, useRef } from 'react';

import { TransitionGroup, Transition } from 'react-transition-group';
import type { TransitionStatus } from 'react-transition-group';
import styled, { CSSProp } from 'styled-components';

import Untabbable from '../Untabbable';
import useSizeTransition from '../useSizeTransition';

const Wrapper = styled.div``;
const Slider = styled.div<{
  transitionDuration: number;
  transitionTimingFunction: string;
  sliderStyle?: CSSProp;
}>`
  position: relative;
  overflow: hidden;
  transition-property: height;
  transition-duration: ${(props) => props.transitionDuration}ms;
  transition-timing-function: ${(props) => props.transitionTimingFunction};
  will-change: height;
  ${(props) => props.sliderStyle};
`;
const Panel = styled.div<{
  indexDelta: number;
  transitionDuration: number;
  transitionTimingFunction: string;
  panelStyle?: CSSProp;
  isActive: boolean;
}>`
  position: relative;
  width: 100%;
  transition-property: transform;
  transition-duration: ${(props) => props.transitionDuration}ms;
  transition-timing-function: ${(props) => props.transitionTimingFunction};
  transform: translate3d(0%, 0, 0);
  will-change: transform;
  &[aria-hidden='true'] {
    pointer-events: none;
  }
  &[data-transition-state='exiting'],
  &[data-transition-state='exited'] {
    position: absolute;
    top: 0;
    transform: translate3d(${(props) => props.indexDelta * 100}%, 0, 0);
    overflow: hidden;
  }
  ${(props) => props.panelStyle};
`;
type SlidingPanelsProps = {
  /**
   * The index of the currently active panel. If not provided, the last panel
   * will automatically be activated. This is useful for drill-down style menus:
   * you can render new levels onto the end of the stack and they will
   * automatically become active, then unmount them to navigate back up.
   */
  activeIndex?: number;
  /**
   * The panels. Only the active one will remain mounted. Others will only
   * be mounted if they are actively transitioning.
   */
  children: React.ReactNode;
  /**
   * Callback function fired once the panel has changed.
   */
  onPanelChanged?: () => void;
  /**
   * Styles to pass to the panel element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  panelStyle?: CSSProp;
  /**
   * Styles to pass to the slider element wrapping the panels. It will be
   * included along with the base styles and can be anything styled-components
   * supports in its tagged template literals, including strings, objects, and
   * functions.
   */
  sliderStyle?: CSSProp;
  /**
   * The number of milliseconds a panel should take to slide in and the height
   * should take to transition.
   */
  transitionDuration?: number;
  /**
   * The CSS transition timing function to use for both the sliding panels and
   * the smooth height transition.
   */
  transitionTimingFunction?: string;
};
export default function SlidingPanels({
  activeIndex,
  children,
  onPanelChanged,
  panelStyle,
  sliderStyle,
  transitionDuration = 600,
  transitionTimingFunction = 'easeOutQuint',
  ...rest
}: SlidingPanelsProps) {
  transitionTimingFunction =
    (SlidingPanels.customEasing as any)[transitionTimingFunction] ||
    transitionTimingFunction;
  // Force children to an array so we can count them and access the active one.
  const panels = React.Children.toArray(children);
  // If `activeIndex` is not controlled, it's always the last item.
  // if (activeIndex == null) {
  //   activeIndex = panels.length - 1;
  // }
  const currentIndex = activeIndex ?? panels.length - 1;
  const sliderRef = useRef<HTMLDivElement>(null);
  const sizeTransition = useSizeTransition(sliderRef, { height: true });
  const handlePanelChanged = useCallback(() => {
    sizeTransition.cleanup();
    if (onPanelChanged) {
      onPanelChanged();
    }
  }, [onPanelChanged, sizeTransition]);
  const previousRef = useRef(currentIndex);
  const previousIndex = previousRef.current;
  const activePanel = panels[currentIndex];
  useEffect(() => {
    previousRef.current = currentIndex;
  }, [currentIndex]);
  return (
    <Wrapper {...rest}>
      {/* @ts-ignore */}
      <Slider
        aria-live="polite"
        data-slider=""
        ref={sliderRef}
        sliderStyle={sliderStyle}
        transitionDuration={transitionDuration}
        transitionTimingFunction={transitionTimingFunction}
      >
        {/* The types for TransitionGroup don't like having the `null` as a child,
          however without that this does not function properly. */}
        {/* @ts-ignore */}
        <TransitionGroup
          component={React.Fragment}
          childFactory={(child: any) => {
            const { panelIndex } = child.props;
            const indexDelta =
              panelIndex === currentIndex
                ? panelIndex - previousIndex
                : panelIndex - currentIndex;
            // Whatever panel is active will take control of the height
            // transition.
            const enterProps =
              panelIndex === currentIndex
                ? {
                    onEnter: sizeTransition.prepare,
                    onEntering: sizeTransition.run,
                    onEntered: handlePanelChanged,
                  }
                : {};
            // If there is no active panel, we need the last exiting panel to
            // take control of the height transition instead.
            const exitProps =
              currentIndex < 0 && panelIndex === previousIndex
                ? {
                    onExit: sizeTransition.prepare,
                    onExiting: sizeTransition.run,
                    onExited: handlePanelChanged,
                  }
                : {};
            // Some props must be passed to child Transition instances here in
            // order for them to see the latest value (e.g. `currentIndex`,
            // `previousIndex`) because they might already be unmounted. We're
            // seeing old/unmounting instances of those children here.
            return React.cloneElement(child, {
              currentIndex,
              indexDelta,
              panelStyle,
              previousIndex,
              ...enterProps,
              ...exitProps,
            });
          }}
        >
          {currentIndex < 0 ? null : (
            <Transition
              key={currentIndex}
              panel={activePanel}
              panelIndex={currentIndex}
              timeout={transitionDuration}
            >
              {/* @ts-ignore */}
              {(
                // Current transition state for this panel (entering, exiting,
                // etc.)
                transitionState: TransitionStatus,
                // Any extra props passed to the Transition.
                {
                  currentIndex,
                  indexDelta,
                  // previousIndex,
                  panel,
                  panelIndex,
                  panelStyle,
                }: {
                  currentIndex: number;
                  indexDelta: number;
                  panel: React.ReactNode;
                  panelIndex: number;
                  panelStyle: CSSProp;
                }
              ) => (
                <Untabbable active={panelIndex !== currentIndex}>
                  {/* @ts-ignore */}
                  <Panel
                    aria-hidden={panelIndex !== currentIndex}
                    data-panel={panelIndex}
                    data-transition-state={transitionState}
                    indexDelta={indexDelta}
                    isActive={panelIndex === currentIndex}
                    panelStyle={panelStyle}
                    transitionDuration={transitionDuration}
                    transitionTimingFunction={transitionTimingFunction}
                  >
                    {panel}
                  </Panel>
                </Untabbable>
              )}
            </Transition>
          )}
        </TransitionGroup>
      </Slider>
    </Wrapper>
  );
}
SlidingPanels.defaultProps = {
  transitionDuration: 600,
  transitionTimingFunction: 'easeOutQuint',
};
// TODO: Give themes an `easing` section and include a handful of defaults?
SlidingPanels.customEasing = {
  // https://easings.net/en#easeOutQuint
  easeOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
  bounce: 'cubic-bezier(0.45, 1.5, 0.56, 0.87)',
};

import { ProductUserStatus } from './constants';

// Returns boolean indicating whether a product is available for the
// user. This is determined by comparing the provided membership
// status(es) against the provided `requiredUserStatus` array from
// GraphQL Product query. e.g. `['vip']`, `['postReg']`. The ultimate
// source is from a product's "inventory classification" tags in Console.
export const getProductAvailabilityForUser = ({
  currentUserStatus,
  requiredUserStatus = [],
}) => {
  const { isElite, isLead, isVip, isPostReg, membershipLevelGroup } =
    currentUserStatus;

  if (!requiredUserStatus.length) {
    return true;
  }

  const isProductAvailableForUser = requiredUserStatus.some(
    (requiredStatus) => {
      let isAvailable = false;

      switch (requiredStatus) {
        case ProductUserStatus.LEAD_ONLY:
          if (isLead || membershipLevelGroup === 'visitor') {
            isAvailable = true;
          }
          break;
        case ProductUserStatus.VIP_ONLY:
        case ProductUserStatus.VIP_EXCLUSIVE_ONLY:
          if (isVip) {
            isAvailable = true;
          }
          break;
        case ProductUserStatus.VIP_ELITE_ONLY:
          if (isVip || isElite) {
            isAvailable = true;
          }
          break;
        case ProductUserStatus.ELITE_ONLY:
          if (isElite) {
            isAvailable = true;
          }
          break;
        case ProductUserStatus.POSTREG_ONLY:
          if (isPostReg) {
            isAvailable = true;
          }
      }
      return isAvailable;
    }
  );

  return isProductAvailableForUser;
};

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getRetailStores } from './accountsModule';
import useAccountActions from './useAccountActions';

export const useRetailStores = (params = {}) => {
  const actions = useAccountActions();
  const { results, networkStatus } = useSelector((state) =>
    getRetailStores(state, params)
  );
  useEffect(() => {
    if (!networkStatus.isUpToDate) {
      actions.loadRetailStores(params);
    }
  }, [actions, networkStatus.isUpToDate, params]);

  return {
    results,
    ...networkStatus,
  };
};

export default useRetailStores;

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import FocusTrap from 'focus-trap-react';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { Transition, animated } from 'react-spring';
import styled from 'styled-components';

import AppHidden from '../AppHidden';
import useMounted from '../useMounted';
import withThemeProps from '../withThemeProps';

const DrawerContext = React.createContext();

const ToggleVisibilityContent = styled.div`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

function ToggleVisibility({ children, ...rest }) {
  const { isVisible } = useContext(DrawerContext);
  return (
    <ToggleVisibilityContent
      aria-hidden={!isVisible}
      isVisible={isVisible}
      {...rest}
    >
      {children}
    </ToggleVisibilityContent>
  );
}

ToggleVisibility.propTypes = {
  children: PropTypes.node,
};

function getWrapperPosition({ position }) {
  switch (position) {
    case 'left':
      return {
        top: 0,
        left: 0,
        bottom: 0,
      };
    case 'right':
      return {
        top: 0,
        right: 0,
        bottom: 0,
      };
    case 'top':
      return {
        top: 0,
        left: 0,
        right: 0,
        flexDirection: 'column',
        alignItems: 'center',
      };
    case 'bottom':
      return {
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: 'column',
        alignItems: 'center',
      };
  }
}

function getHiddenTransform({ position }) {
  switch (position) {
    case 'top':
      return 'translate3d(0, -100%, 0)';
    case 'bottom':
      return 'translate3d(0, 100%, 0)';
    case 'left':
      return 'translate3d(-100%, 0, 0)';
    case 'right':
      return 'translate3d(100%, 0, 0)';
    default:
      return 'none';
  }
}

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  ${getWrapperPosition};
  max-width: 100%;
  max-height: 100%;
  outline: 0;
  pointer-events: ${({ isModal }) => (isModal ? 'auto' : 'none')};
`;

const Overlay = animated(styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  ${(props) => props.overlayStyle};
`);

const Dialog = animated(styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  pointer-events: auto;

  > * {
    flex-shrink: 0;
    flex-grow: 1;
  }

  ${(props) => props.dialogStyle};
`);

/**
 * @component
 *
 * An accessible drawer component for making content appear above the main page,
 * anchored to one side of the viewport.
 *
 * The drawer will be open by default if the component is mounted, or you can
 * keep it mounted and use the `isOpen` prop. The advantage of using the
 * `isOpen` prop is that the exit animation will be allowed to complete before
 * the drawer’s elements are unmounted; if the entire `<Drawer>` is unmounted
 * then there is no way to delay their disapperance.
 *
 * The drawer can be either modal or non-modal. Non-modal means that the rest of
 * the app is still visible, interactive, and not covered by an overlay.
 *
 * You can use the `[data-drawer-overlay]` and `[data-drawer-dialog]` selectors
 * to target the overlay and dialog, respectively. However, this should rarely
 * be necessary. Most of the time, you’ll want to style your dialog content
 * directly instead, and potentially pass `overlayColor`. The dialog has no
 * default width or color; it adapts to the content you pass as a child. Do not
 * style `[data-drawer-dialog]` just to change the appearance of your content.
 */
export const Drawer__ = () => {
  return null;
};
export const Drawer = React.forwardRef(
  (
    {
      afterDialog,
      alert,
      animationDisabled,
      beforeDialog,
      children,
      className,
      dialogStyle,
      escapeExits,
      focusTrapConfig,
      initialFocus,
      isModal,
      isOpen,
      onEnter,
      onExit,
      overlayClickExits,
      overlayColor,
      overlayStyle,
      position,
      scrollDisabled = isModal,
      springConfig,
      title,
      titleId,
      transitionEnter,
      transitionFrom,
      transitionLeave,
      trapFocus = isModal,
      unmountOnExit = true,
    },
    ref
  ) => {
    const mounted = useMounted();
    const dialogRef = useRef();

    useEffect(() => {
      if (isOpen && onEnter) {
        onEnter();
      }
    }, [isOpen, onEnter]);

    const focusTrapOptions = useMemo(() => {
      return {
        escapeDeactivates: false,
        initialFocus:
          initialFocus || (beforeDialog ? () => dialogRef.current : undefined),
        fallbackFocus: () => dialogRef.current,
        ...focusTrapConfig,
      };
    }, [beforeDialog, focusTrapConfig, initialFocus]);

    const handleKeyDown = useCallback(
      (event) => {
        if (onExit && event.keyCode === 27) {
          onExit(event);
        }
      },
      [onExit]
    );

    const hiddenColor = useMemo(
      () => transparentize(1, overlayColor),
      [overlayColor]
    );

    if (!mounted) {
      return null;
    }

    if (!title && !titleId) {
      throw new Error(
        `You must supply either the title or titleId prop for accessibility.`
      );
    }

    const hiddenTransform = getHiddenTransform({ position });
    const visibleTransform = 'translate3d(0, 0, 0)';

    const fromStyle = {
      overlayColor: hiddenColor,
      transform: hiddenTransform,
      ...transitionFrom,
    };
    const enterStyle = {
      overlayColor,
      transform: unmountOnExit || isOpen ? visibleTransform : hiddenTransform,
      ...transitionEnter,
    };
    const leaveStyle = {
      overlayColor: hiddenColor,
      transform: hiddenTransform,
      ...transitionLeave,
    };
    let updateStyle;
    if (!unmountOnExit) {
      updateStyle = isOpen ? enterStyle : leaveStyle;
    }

    return ReactDOM.createPortal(
      <Transition
        items={isOpen}
        from={fromStyle}
        enter={enterStyle}
        leave={leaveStyle}
        update={updateStyle}
        // When `unmountOnExit` is false, we're not actually performing a
        // "transition" (in terms of mounting/unmounting an element), so we
        // override `keys` to return the same constant value so that nothing is
        // ever considered to be entering or leaving. If this ever causes
        // problems, an alternative might be to dynamically switch between
        // Spring and Transition based on `unmountOnExit` instead, since in one
        // case this no longer qualifies as a "transition."
        keys={unmountOnExit ? undefined : true}
        immediate={animationDisabled}
        config={springConfig}
        native
        unique
      >
        {(styles, isVisible) => {
          const { overlayColor, ...dialogTransition } = styles;
          return isVisible || !unmountOnExit ? (
            <FocusTrap
              // Don't waste the user's time with animation: disable the focus
              // trap as soon as the drawer starts closing, instead of when it's
              // done animating.
              active={trapFocus && isOpen}
              // Making it paused will still perform the initial focus when the
              // drawer opens, but won't trap it.
              paused={!trapFocus}
              focusTrapOptions={focusTrapOptions}
            >
              <RemoveScroll
                forwardProps
                allowPinchZoom
                enabled={isOpen && scrollDisabled}
              >
                <Wrapper
                  className={className}
                  onKeyDown={escapeExits ? handleKeyDown : undefined}
                  position={position}
                  isVisible={isVisible}
                  isModal={isModal}
                  isOpen={isOpen}
                  ref={ref}
                  tabIndex={-1}
                >
                  <DrawerContext.Provider value={{ isVisible, isOpen }}>
                    {isModal && isOpen ? <AppHidden /> : null}
                    {isModal && isVisible ? (
                      <Overlay
                        data-drawer-overlay=""
                        isOpen={isOpen}
                        onClick={overlayClickExits ? onExit : undefined}
                        overlayStyle={overlayStyle}
                        style={{
                          // Don't waste the user's time with animation: allow
                          // clicking through the overlay as soon as the drawer
                          // starts closing, instead of when it's done animating.
                          pointerEvents: isOpen ? undefined : 'none',
                          backgroundColor: overlayColor,
                        }}
                      />
                    ) : null}
                    {beforeDialog}
                    <Dialog
                      aria-label={title}
                      aria-labelledby={titleId}
                      data-drawer-dialog=""
                      dialogStyle={dialogStyle}
                      position={position}
                      ref={dialogRef}
                      role={alert ? 'alertdialog' : 'dialog'}
                      style={dialogTransition}
                      tabIndex={-1}
                    >
                      {children}
                    </Dialog>
                    {afterDialog}
                  </DrawerContext.Provider>
                </Wrapper>
              </RemoveScroll>
            </FocusTrap>
          ) : null;
        }}
      </Transition>,
      document.body
    );
  }
);

Drawer.displayName = 'Drawer';

Drawer.propTypes = {
  /**
   * Content to render after the dialog element inside the same parent wrapper.
   * This allows you to offset the drawer or render additional content within
   * the same focus trap (like a header).
   */
  afterDialog: PropTypes.node,
  /**
   * Whether the dialog is showing an alert. This affects whether the ARIA role
   * is `dialog` or `alertdialog`.
   */
  alert: PropTypes.bool,
  /**
   * Whether to disable animation and hide/show the drawer immediately.
   */
  animationDisabled: PropTypes.bool,
  /**
   * Content to render before the dialog element inside the same parent wrapper.
   * This allows you to offset the drawer or render additional content within
   * the same focus trap (like a header).
   */
  beforeDialog: PropTypes.node,
  /**
   * Your dialog content. Style this when you want to change the drawer’s width.
   */
  children: PropTypes.node,
  /**
   * The class applied to the element wrapping the dialog and overlay.
   */
  className: PropTypes.string,
  /**
   * Styles to pass to the dialog element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  dialogStyle: PropTypes.any,
  /**
   * Whether the <kbd>Escape</kbd> key closes the drawer.
   */
  escapeExits: PropTypes.bool,
  /**
   * Custom options for `focus-trap`.
   * See:[focus-trap-react](https://github.com/focus-trap/focus-trap-react).
   */
  focusTrapConfig: PropTypes.object,
  /**
   * An element to focus when the drawer opens, or a function that returns an
   * element. Passed to `focus-trap`, see:
   * https://github.com/davidtheclark/focus-trap#usage
   */
  initialFocus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /**
   * Whether the drawer is modal or non-modal. Modal drawers will render an
   * overlay, trap focus within the drawer, and set `aria-hidden` on the main
   * app content. Non-modal drawers will not.
   */
  isModal: PropTypes.bool,
  /**
   * Whether the drawer is open or closed. You can also choose to mount or
   * unmount the entire `<Drawer>` element instead, but it’s better to use the
   * `isOpen` prop to allow exit animations to run before the drawer’s elements
   * are unmounted.
   */
  isOpen: PropTypes.bool,
  /**
   * A function called when the drawer is opened.
   */
  onEnter: PropTypes.func,
  /**
   * A function called when the drawer should close.
   */
  onExit: PropTypes.func,
  /**
   * Whether clicking the overlay should close the drawer.
   */
  overlayClickExits: PropTypes.bool,
  /**
   * The background color of the overlay.
   */
  overlayColor: PropTypes.string,
  /**
   * Styles to pass to the overlay element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  overlayStyle: PropTypes.any,
  /**
   * The side of the viewport on which the drawer appears.
   */
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  /**
   * Whether to disable scrolling the page behind the drawer while it is open.
   * By default it mirrors the `isModal` prop.
   */
  scrollDisabled: PropTypes.bool,
  /**
   * Control the transition speed by configuring the spring used to animate the
   * drawer. See the [react-spring docs](https://www.react-spring.io/docs/hooks/api).
   */
  springConfig: PropTypes.object,
  /**
   * The dialog title. You must supply either this or `titleId`. The title
   * must be a string since it is placed in the `aria-label` attribute; it
   * cannot be a React element.
   */
  title: PropTypes.string,
  /**
   * The ID of an element that contains the title of the dialog. This will be
   * used to populate the `aria-labelledby` attribute. You must supply either
   * this or `title`.
   */
  titleId: PropTypes.string,
  /**
   * An object to pass to [react-spring’s Transition component](https://www.react-spring.io/docs/props/transition).
   */
  transitionEnter: PropTypes.object,
  /**
   * An object to pass to [react-spring’s Transition component](https://www.react-spring.io/docs/props/transition).
   */
  transitionFrom: PropTypes.object,
  /**
   * An object to pass to [react-spring’s Transition component](https://www.react-spring.io/docs/props/transition).
   */
  transitionLeave: PropTypes.object,
  /**
   * Whether to trap focus inside the drawer while it is open. By default it
   * mirrors the `isModal` prop.
   */
  trapFocus: PropTypes.bool,
  /**
   * Whether to keep Drawer contents unmounted while it's closed. When true,
   * prevents contents from mounting until Drawer is openend.
   */
  unmountOnExit: PropTypes.bool,
  /**
   * The theme variant to render. Defaults to `default`.
   */
  variant: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
};

Drawer.defaultProps = {
  alert: false,
  animationDisabled: false,
  escapeExits: true,
  isModal: true,
  isOpen: true,
  overlayClickExits: true,
  overlayColor: 'rgba(0, 0, 0, 0.4)',
  position: 'left',
  springConfig: {
    tension: 500,
    friction: 40,
  },
};

Drawer.ToggleVisibility = ToggleVisibility;

export default withThemeProps(Drawer, {
  section: 'drawer',
  defaultVariant: 'default',
});

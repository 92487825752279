export const MIN_AGE = 18; // must be 18 or older

export const getBirthdayYearList = () => {
  const birthYears = [];
  const startYear = new Date().getFullYear() - MIN_AGE;
  const endYear = 1900;

  for (let i = startYear; i >= endYear; i--) {
    birthYears.push(i);
  }

  return birthYears;
};

export const getDaysInMonth = (year, month) =>
  new Date(year, month, 0).getDate();

export const getBirthdayDaysList = (year, month) => {
  const daysInMonth = month ? getDaysInMonth(year, month) : 31;
  const days = [];

  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  return days;
};

export const getBirthdayMonthList = () => {
  const months = [];
  for (let i = 1; i <= 12; i++) {
    months.push(i);
  }
  return months;
};

import React, { useCallback, useEffect, useState } from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import SiteLogo from '../../../builder/components/header/components/SiteLogo';
import Text from '../../../shared/components/Text';
import useUserBrands from '../../../shared/utils/useUserBrands';
import {
  useAccountActions,
  useMembership,
} from '../../../techstyle-shared/react-accounts';
import { AssetContainer } from '../../../techstyle-shared/react-assets';
import {
  desktop,
  Button,
  Modal as BaseModal,
} from '../../../techstyle-shared/react-components';
import {
  FormattedMessage,
  useIntl,
} from '../../../techstyle-shared/react-intl';
import AttentiveTag, { triggerRender } from '../../utils/AttentiveTag';

const dialogStyle = css`
  ${({ theme: { colors, sizes } }) => `
    border-radius: ${sizes.sm}px;
    background-color: ${colors.palette.softSilk};
    margin: ${sizes.lg}px;
    padding: 20px ${sizes.xxs}px 38px;
    max-width: 541px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
  ${desktop`
    padding: ${({ theme }) => `${theme.sizes.md}px ${theme.sizes.xxxl}px 60px`};
  `};
  width: 100%;
  position: relative;
`;

const YittyLogo = styled(SiteLogo)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: ${({ theme }) => theme.sizes.xxxl}px;
  svg {
    max-width: 64px;
  }
`;

const DesktopAsset = styled(AssetContainer)`
  ${({ theme: { sizes } }) => `
    margin-top: ${sizes.sm}px;
    margin-bottom: ${sizes.lg}px;
  `}
`;

const Modal = styled(BaseModal).attrs({
  dialogStyle,
})``;

const CloseButton = styled(Button).attrs({ variant: 'unstyled' })`
  position: absolute;
  ${({ theme: { colors, sizes } }) => `
    color: ${colors.palette.deepBrown};
    height: ${sizes.xxl}px;
    width: ${sizes.xxl}px;
    top: ${sizes.sm}px;;
    right: ${sizes.sm}px;
  `}

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CtaButton = styled(Button)`
  font-weight: 400;
  line-height: 20px;
  width: 203px;
  max-height: 53px;
  display: block;
  ${({ theme: { sizes, colors } }) => `
    font-size: ${sizes.sm}px;
    color: ${colors.palette.white};
    background-color: ${colors.palette.deepBrown};
    padding: 14px ${sizes.md}px;
  `}

  margin-bottom: 20px;
  ${desktop`
    margin-bottom: 24px;
  `}
`;

const LegalLanguage = styled(Text)`
  text-align: center;

  max-width: 276px;
  ${desktop`
    max-width: 410px;
  `}
`;

export default function WelcomeModal({ isLead, isVip }) {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const accountActions = useAccountActions();
  const membership = useMembership();
  const intl = useIntl();
  const { originalSignupBrand, isSignedUpHere, storefrontBrandName } =
    useUserBrands();

  const addMembershipSignupRecord = useCallback(async () => {
    return accountActions.addMembershipSignupRecord({
      membershipId: membership.membershipId,
      membershipBrandId: config.has('public.brand.id')
        ? config.get('public.brand.id')
        : 2,
    });
  }, [accountActions, membership.membershipId]);

  const closeModal = async () => {
    try {
      addMembershipSignupRecord();
    } catch {
      // allow this to fail silently
    }
    setModalIsOpen(false);
  };

  let attentiveCreativeIdString = '';

  if (isVip) {
    // in case of yitty, if original sign up brand is storefrontBrandName, it's a yitty user
    // there are currently no fabletics vip attentive popup
    if (originalSignupBrand === storefrontBrandName) {
      attentiveCreativeIdString = `public.brand.attentive.${originalSignupBrand}VipCreative`;
    }
  } else {
    attentiveCreativeIdString = `public.brand.attentive.${originalSignupBrand}LeadCreative`;
  }

  let attentiveCreativeId = null;

  if (
    attentiveCreativeIdString !== '' &&
    config.has(attentiveCreativeIdString)
  ) {
    attentiveCreativeId = config.get(attentiveCreativeIdString);
  }

  const [attentiveContentTriggered, setAttentiveContentTriggered] =
    useState(false);

  useEffect(() => {
    let interval = null;

    if (!attentiveCreativeId) {
      return;
    }

    function triggerAttentiveRender() {
      triggerRender(attentiveCreativeId);
      setAttentiveContentTriggered(true);
      if (interval) {
        clearInterval(interval);
      }
    }

    // If attentive trigger exists, trigger render
    if (
      !attentiveContentTriggered &&
      interval === null &&
      window?.__attentive?.trigger
    ) {
      triggerAttentiveRender();
      return;
    }

    // Otherwise, poll for its existence until true, then trigger render
    interval = setInterval(() => {
      if (!attentiveContentTriggered && window?.__attentive?.trigger) {
        triggerAttentiveRender();
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [attentiveCreativeId, isLead, attentiveContentTriggered]);

  useEffect(() => {
    if (isLead && !isSignedUpHere) {
      try {
        addMembershipSignupRecord();
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [addMembershipSignupRecord, isLead, isSignedUpHere]);

  // if user is VIP and the DB does not have signup date, show the custom popup,
  // in all other cases show attentive popup
  if (isVip && !isSignedUpHere) {
    return (
      <Modal
        title={intl.formatMessage({
          id: 'site_account_vip.membership_vip',
          defaultMessage: 'Welcome',
        })}
        isOpen={modalIsOpen}
        onExit={closeModal}
      >
        <CloseButton
          aria-label={intl.formatMessage({
            id: 'global_cta.close',
            defaultMessage: 'Close',
          })}
          onClick={closeModal}
        >
          <FaTimes aria-hidden size={24} />
        </CloseButton>
        <YittyLogo />
        <DesktopAsset
          aspectRatio="auto"
          name="fl_vip_popup_copy_and_graphic_d"
        />
        <CtaButton onClick={closeModal}>
          <FormattedMessage id="fl_vip_popup.cta" defaultMessage="Got it!" />
        </CtaButton>

        <LegalLanguage variant="welcomePopupLegalLanguage">
          <FormattedMessage
            id="fl_vip_popup.legal_language"
            defaultMessage="By visiting Yitty's website, you will now be receiving email communications from Yitty. Update your preferences or unsubscribe with your account settings."
          />
        </LegalLanguage>
      </Modal>
    );
  } else if (attentiveCreativeId) {
    return <AttentiveTag />;
  } else {
    return null;
  }
}

WelcomeModal.propTypes = {
  isLead: PropTypes.bool.isRequired,
  isVip: PropTypes.bool.isRequired,
};

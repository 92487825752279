import { Observable, ApolloError } from '@apollo/client';

import { errorCodeRedirect } from '../../../techstyle-shared/next-server';

import logger from './logger';

const debug = logger.extend('handleApolloErrors');

// TODO: move to `~/techstyle-shared/redux-core` since that's where BentoAPI-related code is?
export const GraphQLError = {
  BENTOERROR: 'Bento Error',
};

export const BentoErrorCode = {
  GENERIC: 0,
  SEARCHSERVICE: 1029,
};

export const getErrorCodeFromApolloError = ({ graphQLErrors }) => {
  if (graphQLErrors) {
    const statusError = graphQLErrors.find((item) => item.extensions?.code);
    const { code } = statusError.extensions;

    if (code) {
      return code;
    }
  }
};

export const getRetryableErrorDefault = ({
  graphQLErrors,
  networkError,
  ctx,
}) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    for (const graphQLError of graphQLErrors) {
      const { code: gqlCode, error } = graphQLError.extensions;

      if (gqlCode === GraphQLError.BENTOERROR) {
        const { errorData } = error;
        const bentoError = errorData?.[0];
        // console.log({errorData});

        if (bentoError?.code === BentoErrorCode.SEARCHSERVICE) {
          return new ApolloError({
            graphQLErrors,
            networkError: new Error(errorData?.message || gqlCode, error),
            errorMessage: bentoError.message,
            extraInfo: errorData,
          });
        }
      }

      // TODO: update to also trigger retry after Bento Errors are cleaned up in GraphQL server.
      // Currently, "product not found" also returns "Bento Error" so we don't want to retry.
      // switch (gqlCode) {
      //   case 'Bento Error':
      //     debug('Generic Bento Error', statusError.extensions?.error);
      //     return true;
      //   default:
      //     return false;
      // }
    }
  }

  return null;
};

export const handleApolloErrors = ({
  graphQLErrors,
  networkError,
  operation,
  forward,
  ctx,
  errorCodeRules,
  getRetryableError = getRetryableErrorDefault,
}) => {
  const retryableError = getRetryableError({
    graphQLErrors,
    networkError,
    operation,
    forward,
    ctx,
  });
  if (retryableError) {
    // Triggering an error here will cause the request to be retried via RetryLink.
    // TODO: update to continue to `errorCodeRedirect` below after all retry attempts.
    return new Observable((observer) => {
      observer.error(retryableError);
    });
  }

  if (graphQLErrors) {
    // TODO: Log errors to error logging service?
    const code = getErrorCodeFromApolloError({ graphQLErrors });

    if (code) {
      // Client side handle errors (e.g. redirect) immediately
      if (process.browser) {
        return errorCodeRedirect({ code, errorCodeRules });
        // during SSR add the error codes into a Set to be used later
      } else if (ctx) {
        if (!ctx.errorCodes) {
          ctx.errorCodes = new Set([code]);
        } else {
          ctx.errorCodes.add(code);
        }
      }
    }
  }

  if (networkError) {
    // We're using RetryLink to retry on network errors, so don't do anything here.
    // TODO: Log error to logging service?
    debug('[Network error]:', networkError);
  }
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import Overflow from 'react-overflow-indicator';
import styled, { css } from 'styled-components';

import { useFilterFieldLabels, useOptionLabels } from '../LabelProvider';
import { useProductAttributeSelectorContext } from '../ProductAttributeSelector';
import { useProductContext } from '../ProductContext';
import RadioButton from '../RadioButton';
import SkeletonLine from '../SkeletonLine';
import { visuallyHidden } from '../styles';
import useId from '../useId';
import slugify from '../utils/slugify';

import { ProductSelectorType } from './constants';

const Wrapper = styled.div`
  position: relative;
`;

const SelectorCategoryWrapper = styled.div`
  text-align: left;
  ${({ categoryStyle }) => categoryStyle};
`;

const SelectorCategoryLabelText = styled.div`
  color: #888888;
  font-size: 16px;
  ${({ labelStyle }) => labelStyle};
`;

const SelectedLabelText = styled.span`
  text-transform: capitalize;
  color: #333333;
  ${({ selectedItemLabelStyle }) => selectedItemLabelStyle};
`;

const SelectorGroupWrapper = styled.div``;

const StyledSelectorList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 4px -4px 0;

  ${({ selectorScrollEnabled }) =>
    selectorScrollEnabled &&
    css`
      flex-wrap: unset;
      overflow-x: auto;
      margin: 0;
    `}

  ${({ listStyle }) => listStyle};
`;

const SelectorGroupLabel = styled.h6`
  text-align: left;
  text-transform: capitalize;
  ${({ selectorGroupLabelStyle }) => selectorGroupLabelStyle};
`;

const StyledOverflow = styled(Overflow)`
  overflow-x: auto;
  margin: 4px -4px 0;

  ${({ $scrollStyles }) => $scrollStyles}
`;

const swatchItemStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4px;
`;

const gridItemStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4px;
`;

function getItemStyle({ selectorType, ...rest }) {
  switch (selectorType) {
    case ProductSelectorType.GRID:
      return gridItemStyle;
    case ProductSelectorType.SWATCH:
      return swatchItemStyle;
  }
}

const SelectorItem = styled.li`
  display: block;
  ${getItemStyle}

  ${({ itemStyle }) => itemStyle};
`;

const checkmarkCheckedStyle = css`
  display: none;
`;

const checkmarkCheckingStyle = css`
  display: none;
`;

// The current width of diagonal is 3px.
// To update the diagonal width to 1px, change all 1px to 0.5px as override inside itemSoldOutStyle
const selectorSoldOutItemStyle = css`
  ${({ isSoldOut }) =>
    isSoldOut &&
    css`
      overflow: hidden;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to top left,
          transparent calc(50% - 1.5px),
          #eee calc(50% - 1.5px),
          #eee calc(50% + 1.5px),
          transparent calc(50% + 1.5px),
          transparent
        );
      }
      ${({ itemSoldOutStyle }) => itemSoldOutStyle};
    `}
`;

function getBackgroundUrl({ backgroundUrl, backgroundUrls }) {
  const urls = backgroundUrls || [backgroundUrl];
  return css`
    background-image: ${urls.map((url) => `url("${url}")`).join(',')};
  `;
}

const swatchInputStyle = css`
  height: 48px;
  width: 48px;
  border: none;
  padding: 0;
  margin: 0;

  ${getBackgroundUrl};

  input:checked + && {
    ${getBackgroundUrl};
    border: 3px solid #333333;
    box-shadow: inset 0 0 0 3px #ffffff;
  }

  input:checked:focus + && {
    box-shadow: inset 0 0 0 3px #ffffff,
      0 0 0 3px ${({ theme }) => theme.colors.focusColor};
  }
`;

const gridInputStyle = css`
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  border: none;
  background-color: #eeeeee;
  line-height: 1;
  box-sizing: border-box;

  ${({ isSoldOut }) =>
    isSoldOut &&
    css`
      && {
        border: 3px solid #eeeeee;
        background-color: white;
      }
    `};

  input:checked + && {
    border: solid 3px #333333;
    background-color: #eee;
  }

  ${({ isSoldOut }) =>
    isSoldOut &&
    css`
      input:checked + && {
        background-color: white;
      }
    `};
`;

const OverflowRightFade = styled.div`
  box-sizing: content-box;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #ffffff 100%
  );
`;

const OverflowLeftFade = styled.div`
  box-sizing: content-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    #ffffff 100%
  );
`;

function getInputStyle({ selectorType }) {
  switch (selectorType) {
    case ProductSelectorType.GRID:
      return gridInputStyle;
    case ProductSelectorType.SWATCH:
      return swatchInputStyle;
  }
}

const SelectorRadioButton = styled(RadioButton).attrs(function (props) {
  return {
    checkmarkCheckedStyle: props.checkmarkCheckedStyle || checkmarkCheckedStyle,
    checkmarkCheckingStyle:
      props.checkmarkCheckingStyle || checkmarkCheckingStyle,
  };
})`
  ${getInputStyle};
  ${selectorSoldOutItemStyle};

  && {
    ${(props) => props.customInputStyle};
  }
`;

const SelectorItemLabel = styled.label`
  font-size: 16px;
  text-align: center;
  position: absolute;
  ${({ hideLabel }) =>
    hideLabel &&
    css`
      ${visuallyHidden}
    `}
  ${({ itemLabelStyle }) => itemLabelStyle};
`;

const SelectorLoading = styled(SkeletonLine)`
  ${getInputStyle};

  && {
    ${(props) => props.customInputStyle};
  }
`;

export const Selector = ({
  ariaPrefix,
  hideLabel,
  inputStyle,
  itemLabelStyle,
  itemSoldOutStyle,
  itemStyle,
  selectorType = ProductSelectorType.GRID,
  name,
  value,
  option,
  onChange,
  onMouseEnter,
  radioButtonProps,
  getRadioButtonProps,
  isSoldOut = false,
  swatchImageUrl,
  swatchImageData,
  swatchImageKeysToUse,
  layerSwatchImages,
  label,
  title,
  isChecked = false,
  autoTag,
  ...rest
}) => {
  const radioButtonId = useId();
  const dataAutoTag = autoTag
    ? `${slugify(autoTag, '-')}-${radioButtonId}`
    : `${slugify(label, '-')}-${radioButtonId}`;

  const handleChange = useCallback(() => {
    onChange(option);
  }, [onChange, option]);

  const handleOnMouseEnter = (option) => {
    onMouseEnter(option);
  };

  let backgroundUrls =
    swatchImageData && swatchImageKeysToUse?.length
      ? swatchImageKeysToUse.map((x) => swatchImageData[x])
      : [swatchImageUrl];

  if (!layerSwatchImages && swatchImageKeysToUse?.length) {
    backgroundUrls = [backgroundUrls.find((x) => x)];
  }

  return (
    <SelectorItem
      itemStyle={itemStyle}
      data-autotag={dataAutoTag}
      selectorType={selectorType}
      data-masterproductid={option.masterProductId}
      data-productid={option.productId}
      {...rest}
    >
      <SelectorRadioButton
        aria-label={ariaPrefix ? `${ariaPrefix}: ${title}` : title}
        id={radioButtonId}
        name={name}
        value={value}
        title={title}
        selectorType={selectorType}
        checked={isChecked}
        onChange={handleChange}
        onMouseEnter={onMouseEnter && (() => handleOnMouseEnter(option))}
        isSoldOut={isSoldOut}
        customInputStyle={inputStyle}
        backgroundUrl={backgroundUrls[0]}
        backgroundUrls={backgroundUrls}
        itemSoldOutStyle={itemSoldOutStyle}
        {...radioButtonProps}
        {...(getRadioButtonProps && getRadioButtonProps(option))}
      />
      <SelectorItemLabel
        htmlFor={radioButtonId}
        hideLabel={hideLabel}
        itemLabelStyle={itemLabelStyle}
        isChecked={isChecked}
        isSoldOut={isSoldOut}
      >
        {label}
      </SelectorItemLabel>
    </SelectorItem>
  );
};

Selector.displayName = 'Selector';

Selector.propTypes = {
  ariaPrefix: PropTypes.string,
  autoTag: PropTypes.string,
  getRadioButtonProps: PropTypes.func,
  hideLabel: PropTypes.bool,
  inputStyle: PropTypes.any,
  isChecked: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  itemLabelStyle: PropTypes.any,
  itemSoldOutStyle: PropTypes.any,
  itemStyle: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  layerSwatchImages: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func,
  option: PropTypes.object,
  radioButtonProps: PropTypes.object,
  selectorType: PropTypes.string,
  swatchImageData: PropTypes.object,
  swatchImageKeysToUse: PropTypes.array,
  swatchImageUrl: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any,
};

export default function GenericProductAttributeSelector({
  ariaPrefix,
  categoryStyle,
  displaySoldOutItem = true,
  displayCheckedAndSoldOut = true,
  displayCheckedAndUnavailable = false,
  displayUnavailableItem = false,
  hideLabel = false,
  hideOverflowIndicators = false,
  selectorGroupLabelStyle,
  selectorScrollEnabled = false,
  selectorOverflowScrollEnabledStyle,
  inputStyle,
  itemLabelStyle,
  itemSoldOutStyle,
  itemStyle,
  selectorType = ProductSelectorType.GRID,
  labelStyle,
  listStyle,
  onChange: onChangeFromProps,
  radioButtonProps,
  getRadioButtonProps,
  selectedItemLabelStyle,
  renderSummary,
  displayLoadingState = false,
  swatchType,
  swatchImageKeysToUse = ['url'],
  layerSwatchImages = false,
  ...rest
}) {
  const { attribute, options, selectedOption, isLoading, onChange } =
    useProductAttributeSelectorContext();
  const selectorFieldLabelCtx = useFilterFieldLabels();
  const selectorOptionLabelCtx = useOptionLabels();
  const { renderLabel, renderLabelString } = selectorOptionLabelCtx;
  const { swatches } = useProductContext();
  const selectorGroupId = useId();
  const name = `${slugify(attribute.field, '-')}-${selectorGroupId}`;

  // `selectorScrollEnabled` refs + state
  const selectorRefs = useRef([]);
  const [selectorListRef, setSelectorListRef] = useState();
  const [initialSelectionLoaded, setInitialSelectionLoaded] = useState(false);

  // option groups e.g. "Toddler", "Little Kid"
  const optionGroups = useMemo(() => {
    const calculatedGroups = {};

    options.forEach((option) => {
      const { group } = option;
      if (group) {
        if (!calculatedGroups[group]) {
          calculatedGroups[group] = [];
        }
        calculatedGroups[group].push(option);
      }
    });

    return calculatedGroups;
  }, [options]);

  const optionGroupsKeys = useMemo(
    () => Object.keys(optionGroups),
    [optionGroups]
  );

  const swatchImages = {};
  if (swatches && selectorType === ProductSelectorType.SWATCH) {
    swatches.forEach(({ color, ...rest }) => {
      swatchImages[color] = { ...rest };
    });
  }

  if (!renderSummary) {
    // eslint-disable-next-line react/display-name
    renderSummary = () => {
      return (
        <SelectorCategoryLabelText labelStyle={labelStyle}>
          {selectorFieldLabelCtx.renderLabel(attribute)}
          {': '}
          <SelectedLabelText selectedItemLabelStyle={selectedItemLabelStyle}>
            {selectedOption?.label &&
              renderLabel(attribute, selectedOption, {
                selectorType: selectorType,
              })}
          </SelectedLabelText>
        </SelectorCategoryLabelText>
      );
    };
  }

  const getSelectorRefs = useCallback((element) => {
    selectorRefs.current.push(element);
  }, []);

  // scroll selector into view when selectorScrollEnabled prop is enabled
  useEffect(() => {
    if (
      !isLoading &&
      selectorScrollEnabled &&
      selectedOption &&
      !initialSelectionLoaded
    ) {
      const selectedRef = selectorRefs?.current?.find((selector) => {
        return selector?.value === selectedOption?.value;
      });
      if (selectedRef && selectorListRef?.current) {
        setInitialSelectionLoaded(true);
        const selectorRect = selectedRef.getBoundingClientRect();
        const listRect = selectorListRef?.current.getBoundingClientRect();

        // check if the default selected option is out of view
        // if it is, adjust scroll position so that the default selected option is centered in view
        if (selectorRect.right > listRect.right) {
          const selectedOptionPosition =
            selectorRect.right - (selectorRect.right - selectorRect.left) / 2;
          const scrollAmount =
            selectedOptionPosition - listRect.right + listRect.width / 2;
          selectorListRef.current.scrollLeft += scrollAmount;
        }
      }
    }
  }, [
    selectorScrollEnabled,
    selectedOption,
    selectorListRef,
    initialSelectionLoaded,
    isLoading,
  ]);

  const handleOverflowStateChange = (p, x) => {
    setSelectorListRef(x.viewport);
  };

  radioButtonProps = useMemo(
    () => ({
      ref: getSelectorRefs,
      ...radioButtonProps,
    }),
    [getSelectorRefs, radioButtonProps]
  );

  const handleChange = (option) => {
    // onChange updates `selectedOption` of useProductAttributeSelectorContext.
    onChange(option);

    // onChangeFromProps is a custom user provided function
    if (onChangeFromProps) {
      onChangeFromProps(option);
    }
  };

  const renderSelectorList = (option, index) => {
    if (
      isLoading &&
      displayLoadingState &&
      option.value !== selectedOption.value
    ) {
      return (
        <SelectorItem
          itemStyle={itemStyle}
          selectorType={selectorType}
          key={option.value}
        >
          <SelectorLoading
            selectorType={selectorType}
            customInputStyle={inputStyle}
          />
        </SelectorItem>
      );
    }
    const isSoldOut = !option.isAvailable || !option.isAvailableForUser;
    const label = renderLabel(attribute, option, {
      selectorType: selectorType,
    });
    const title = renderLabelString(attribute, option);
    const isChecked = option.value === selectedOption?.value;
    let isCorrectType = true;
    if (
      selectorType === ProductSelectorType.SWATCH &&
      swatchType &&
      option.swatchType !== swatchType
    ) {
      isCorrectType = false;
    }
    const isAvailableForUser =
      selectorType === ProductSelectorType.SWATCH
        ? option.isAvailableForUser ||
          displayUnavailableItem ||
          (isChecked && displayCheckedAndUnavailable)
        : true;
    // Display items when below condition satisfies
    const displayItem =
      (displaySoldOutItem ||
        option.isAvailable ||
        (isChecked && displayCheckedAndSoldOut)) &&
      isCorrectType &&
      isAvailableForUser;

    if (!displayItem) {
      return null;
    }

    return (
      <Selector
        ariaPrefix={ariaPrefix}
        key={option.value}
        option={option}
        name={name}
        itemStyle={itemStyle}
        inputStyle={inputStyle}
        selectorType={selectorType}
        onChange={handleChange}
        hideLabel={hideLabel}
        itemLabelStyle={itemLabelStyle}
        itemSoldOutStyle={itemSoldOutStyle}
        swatchImageData={swatchImages[option.value]}
        isSoldOut={isSoldOut}
        label={label}
        title={title}
        isChecked={isChecked}
        autoTag={option.label}
        value={option.value}
        radioButtonProps={radioButtonProps}
        getRadioButtonProps={getRadioButtonProps}
        swatchImageKeysToUse={swatchImageKeysToUse}
        layerSwatchImages={layerSwatchImages}
      />
    );
  };

  const selectorGroupList = optionGroupsKeys?.length
    ? optionGroupsKeys.map((groupKey, index) => {
        return (
          <SelectorGroupWrapper key={`${groupKey}_fragment`}>
            <SelectorGroupLabel
              key={`${groupKey}_${index}_label`}
              selectorGroupLabelStyle={selectorGroupLabelStyle}
            >
              {groupKey}
            </SelectorGroupLabel>
            <StyledSelectorList
              key={`${groupKey}_${index}_list`}
              listStyle={listStyle}
              selectorScrollEnabled={selectorScrollEnabled}
            >
              {optionGroups[groupKey].map((option, index) =>
                renderSelectorList(option, index)
              )}
            </StyledSelectorList>
          </SelectorGroupWrapper>
        );
      })
    : null;

  const selectorList = !optionGroupsKeys?.length ? (
    <StyledSelectorList
      listStyle={listStyle}
      selectorScrollEnabled={selectorScrollEnabled}
    >
      {options.map((option, index) => renderSelectorList(option, index))}
    </StyledSelectorList>
  ) : null;

  return (
    <Wrapper {...rest}>
      <SelectorCategoryWrapper categoryStyle={categoryStyle}>
        {renderSummary()}
      </SelectorCategoryWrapper>
      {selectorScrollEnabled ? (
        <StyledOverflow
          onStateChange={handleOverflowStateChange}
          $scrollStyles={
            selectorScrollEnabled && selectorOverflowScrollEnabledStyle
          }
        >
          {!hideOverflowIndicators && (
            <Overflow.Indicator direction="left">
              <OverflowLeftFade />
            </Overflow.Indicator>
          )}
          <Overflow.Content>
            {selectorGroupList && selectorGroupList}
            {selectorList && selectorList}
          </Overflow.Content>
          {!hideOverflowIndicators && (
            <Overflow.Indicator direction="right">
              <OverflowRightFade />
            </Overflow.Indicator>
          )}
        </StyledOverflow>
      ) : (
        <>
          {selectorGroupList && selectorGroupList}
          {selectorList && selectorList}
        </>
      )}
    </Wrapper>
  );
}

GenericProductAttributeSelector.propTypes = {
  /**
   * A string prefix for the Selector radio button's aria-label. This is used to enhance screen reader
   * descriptions by specifying the type of the button (for example, 'size:' or 'color:').
   * This provides a more detailed context to assistive technologies about the purpose of the button.
   */
  ariaPrefix: PropTypes.string,
  /**
   * Styles to apply to the Category label.
   */
  categoryStyle: PropTypes.any,
  /**
   * Styles to apply to the checkmark element when the input is checked.
   */
  checkmarkCheckedStyle: PropTypes.any,
  /**
   * Styles to apply to the checkmark element when the input is disabled.
   */
  checkmarkDisabledStyle: PropTypes.any,
  /**
   * flag to display/hide sold out and currently selected items.
   */
  displayCheckedAndSoldOut: PropTypes.bool,
  /**
   * flag to display/hide unavailable and currently selected items.
   */
  displayCheckedAndUnavailable: PropTypes.bool,
  /**
   * flag to display loading state of selectors
   */
  displayLoadingState: PropTypes.bool,
  /**
   * flag to display/hide sold out items.
   */
  displaySoldOutItem: PropTypes.bool,
  /**
   * flag to display/hide unavailable items.
   */
  displayUnavailableItem: PropTypes.bool,
  /**
   * Callback to get Radio Button Props with option context.  Properties from here will override same named properties from radioButtonProps.
   */
  getRadioButtonProps: PropTypes.func,
  /**
   * flag to display/hide input label on screen.
   */
  hideLabel: PropTypes.bool,
  /**
   * flag to display/hide overflow indicators.
   */
  hideOverflowIndicators: PropTypes.bool,
  /**
   * Styles to always apply to the custom input element.
   * These are applied in CheckBoxRadioBase.
   */
  inputStyle: PropTypes.any,
  /**
   * Styles to apply to the Input Label.
   */
  itemLabelStyle: PropTypes.any,
  /**
   * Styles to apply to sold out item.
   */
  itemSoldOutStyle: PropTypes.any,
  /**
   * Styles to apply to list item.
   */
  itemStyle: PropTypes.any,
  /**
   * Header label style.
   */
  labelStyle: PropTypes.any,
  /**
   * Whether to layer swatch keys or just use highest available
   */
  layerSwatchImages: PropTypes.bool,
  /**
   * Options list style.
   */
  listStyle: PropTypes.any,
  /**
   * Input Onchange handler function.
   */
  onChange: PropTypes.func,
  /**
   * Object to pass rest of the props to CheckboxRadioBase component.
   */
  radioButtonProps: PropTypes.object,
  /**
   * function to display any custom header/label .
   */
  renderSummary: PropTypes.func,
  /**
   * styles to display currently selected option in header/summary.
   */
  selectedItemLabelStyle: PropTypes.any,
  /**
   * Selector group label style.
   */
  selectorGroupLabelStyle: PropTypes.any,
  /**
   * Styling to apply to selector overflow if scrolling is enabled
   */
  selectorOverflowScrollEnabledStyle: PropTypes.any,
  /**
   * Flag to enable horizontal scrolling of selectors
   * If there is a pre-selected option it adjusts scroll position so option is in view
   */
  selectorScrollEnabled: PropTypes.bool,
  /**
   * Selector style indicating GRID/SWATCH.
   */
  selectorType: PropTypes.string,
  /**
   * Which swatch images and in which priority order to render
   * these are keys (property names) in the swatches product object, if no array is passed
   * then it defaults to just the 'url' property
   */
  swatchImageKeysToUse: PropTypes.array,
  /**
   * Indicates the swatch type to display
   */
  swatchType: PropTypes.string,
};

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getDirectMarketingDisposition } from './marketingModule';
import useMarketingActions from './useMarketingActions';

export default function useMarketingDisposition(code, options) {
  const actions = useMarketingActions();
  const disposition = useSelector(getDirectMarketingDisposition);

  useEffect(() => {
    if (
      (code && disposition.gateway !== code) ||
      (!disposition.networkStatus.isUpToDate &&
        !disposition.networkStatus.isLoading)
    ) {
      actions.loadDirectMarketingDisposition(code, options);
    }
  }, [
    actions,
    disposition.gateway,
    disposition.networkStatus.isLoading,
    disposition.networkStatus.isUpToDate,
    code,
    options,
  ]);

  return disposition;
}

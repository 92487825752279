import { differenceInCalendarDays, differenceInMilliseconds } from 'date-fns';

import { parseDate } from '../../../techstyle-shared/redux-core';

/**
 * @typedef {Object} ProductAge
 * @property {number} ageMs - product age in ms
 * @property {number} days - product age in calendar days
 * @property {boolean} isReleased - If product has been released (product's dateExpected is today or in the past)
 */

/**
 * Returns the age of the product in days. Gets the difference in calendar days
 * between current date and the product's expected date.
 * @param {{ currentDate: Date|Number, product: Object }}
 *
 * @returns {ProductAge} Object containing product age in different formats
 */
export default function getProductAge({ currentDate, product }) {
  const { dateExpected } = product;

  if (!dateExpected || !currentDate) {
    throw new Error(
      'Missing currentDate parameter or dateExpected field in product.'
    );
  }

  const productDateExpected = parseDate(dateExpected);
  const days = differenceInCalendarDays(currentDate, productDateExpected);
  const ageMs = differenceInMilliseconds(currentDate, productDateExpected);
  const isReleased = days >= 0;

  return { ageMs, days, isReleased };
}

import React, { useCallback, useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

import { useProductContext } from '../ProductContext';
import {
  useProductDetailContext,
  useProductDetailSelectionState,
} from '../ProductDetail';

const Context = React.createContext();

export function useProductAttributeSelectorContext() {
  return useContext(Context);
}

export default function ProductAttributeSelector({
  field,
  isLoading,
  children,
}) {
  const product = useProductContext();
  const { productIndex } = product;
  const productDetail = useProductDetailContext();
  const { skus: formattedSKUs, selectedAttributes } =
    useProductDetailSelectionState(productIndex);
  const attribute = useMemo(
    () => product.attributes.find((attribute) => attribute.field === field),
    [field, product.attributes]
  );

  const isBundleItemMembershipFilterEnabled =
    productDetail?.enableBundleItemMembershipFilter;

  // Create `options` array from `attribute.options`, adding on availability based on already selected attributes
  const options = useMemo(() => {
    const isAvailable = {};
    const attributesToCheck = {};
    const swatchType = {};
    const isAvailableForUser = {};
    const masterProductIds = {};
    const productIds = {};

    Object.entries(selectedAttributes).forEach(([singleField, { value }]) => {
      if (singleField !== field) {
        attributesToCheck[singleField] = value;
      }
    });

    if (formattedSKUs) {
      formattedSKUs.forEach((singleSKU) => {
        const attributeValue = singleSKU.optionValuesByAttribute[field];
        // Determine if a SKU matches the currently selected attributes
        // e.g. if a color is already selected we don't care about SKUs that don't match that color
        const isMatchingSKU = Object.entries(attributesToCheck).every(
          ([singleField, value]) => {
            const option = singleSKU.optionValuesByAttribute[singleField];
            // If the SKU doesn't have the specific attribute at all, we can keep it as a
            // match since we'll want to unselect the attribute if we select a combination
            // of attributes where this attribute makes it so there are no SKUs available.
            if (typeof option === 'undefined') {
              return true;
            }

            return option === value;
          }
        );

        let masterProductId = productDetail.masterProductId;
        let productId = productDetail.product_id;
        // Skip over attributes that are already known to be available
        if (isMatchingSKU && !isAvailable[attributeValue]) {
          let isProductAvailableForUser =
            productDetail.isProductAvailableForUser;
          const isCurrentProductSelected =
            singleSKU.permalink === product.permalink;

          if (!productDetail.isBundle || isBundleItemMembershipFilterEnabled) {
            isProductAvailableForUser = singleSKU.isProductAvailableForUser;
            masterProductId = singleSKU.masterProductId;
            productId = singleSKU.productId;
          }

          if (
            !productDetail.isBundle &&
            isCurrentProductSelected &&
            productDetail.isForcedSoldOut
          ) {
            isAvailable[attributeValue] = false;
          } else {
            isAvailable[attributeValue] =
              singleSKU.isPreorder || singleSKU.availableQuantity > 0;
            masterProductId = singleSKU.masterProductId;
            productId = singleSKU.productId;
          }

          isAvailableForUser[attributeValue] = isProductAvailableForUser;
          masterProductIds[attributeValue] = masterProductId;
          productIds[attributeValue] = productId;
        }

        if (singleSKU.swatchType !== undefined && field === 'Color') {
          swatchType[attributeValue] = singleSKU.swatchType ?? 'default';
          masterProductIds[attributeValue] = singleSKU.masterProductId;
          productIds[attributeValue] = singleSKU.productId;
        }
      });
    }

    return attribute.options.map((singleOption) => {
      const { value } = singleOption;

      return {
        ...singleOption,
        ...(swatchType[value] && { swatchType: swatchType[value] }),
        isAvailable: isAvailable[value] ?? false,
        isAvailableForUser: isAvailableForUser[value] ?? false,
        masterProductId: masterProductIds[value] ?? null,
        productId: productIds[value] ?? null,
      };
    });
  }, [
    isBundleItemMembershipFilterEnabled,
    selectedAttributes,
    formattedSKUs,
    product,
    productDetail,
    attribute.options,
    field,
  ]);

  const selectedOption = useMemo(() => {
    const option = selectedAttributes[attribute.field];

    return options.find(({ value }) => value === option?.value);
  }, [selectedAttributes, attribute.field, options]);

  const onChange = useCallback(
    (option) => {
      productDetail.onSelectorChange({
        product,
        attribute,
        option,
      });
    },
    [attribute, product, productDetail]
  );

  const context = useMemo(() => {
    return {
      attribute,
      isLoading,
      selectedOption,
      options,
      onChange,
    };
  }, [attribute, isLoading, selectedOption, options, onChange]);

  return (
    <Context.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </Context.Provider>
  );
}

ProductAttributeSelector.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  // The attribute field name this selector will control.
  field: PropTypes.string,
  isLoading: PropTypes.bool,
};

ProductAttributeSelector.Context = Context;

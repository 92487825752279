import React, { useState } from 'react';

import config from 'config';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import DenimInterestModal from '../layout/components/DenimInterestModal';
import { DenimInterestPages } from '../shared/constants/denimInterestPages';
import useHasFeature from '../shared/utils/useHasFeature';
import {
  loadCustomerDetails,
  useCustomerDetails,
} from '../techstyle-shared/react-accounts';
import {
  loadSessionDetails,
  useSessionDetail,
} from '../techstyle-shared/redux-core';

const DenimInterestModalWrapper = () => {
  const router = useRouter();
  const denimInterest = useHasFeature('denim_interest');
  const [activePage, setActivePage] = useState(DenimInterestPages.INTEREST);

  const {
    value: popupShownThisSession,
    networkStatus: { isLoading, isUpToDate },
  } = useSessionDetail('denim_popup_shown');

  const [interest, popupShownCount] = useCustomerDetails([
    'denim_interest',
    'denim_popup_shown_count',
  ]);

  const popupEnabledRoutes = config.get('public.brand.denimInterestRoutes');

  // After login, a 'redirecting' component is rendered while the app remounts.
  const pageWillUnmount = useSelector((state) => state.app.pageWillUnmount);

  const shouldShow =
    popupEnabledRoutes.includes(router.route) &&
    Number(popupShownCount) <= 3 &&
    (!interest ||
      activePage === DenimInterestPages.SIZE ||
      activePage === DenimInterestPages.SUCCESS) &&
    !popupShownThisSession &&
    denimInterest;

  if (pageWillUnmount || isLoading || !isUpToDate || !shouldShow) {
    return null;
  }
  return (
    <DenimInterestModal
      popupShownCount={popupShownCount}
      activePage={activePage}
      setActivePage={setActivePage}
    />
  );
};

export default function denimInterestModalExtension() {
  return {
    id: 'denimInterest',
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <DenimInterestModalWrapper {...props} />
          </>
        );
      },
    },
    page() {
      return {
        async getInitialProps(ctx) {
          await ctx.store.dispatch(loadSessionDetails());
          await ctx.store.dispatch(
            loadCustomerDetails([
              'denim_popup_shown_count',
              'denim_womens_waist',
              'denim_womens_inseam',
              'denim_mens_waist',
              'denim_mens_inseam',
            ])
          );
        },
      };
    },
  };
}

import { useMemo } from 'react';

import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';

import { useRenderTime } from '../../../techstyle-shared/redux-core';

import { PaymentMethodStatus } from './constants';
import usePayments from './usePayments';

export default function usePaymentMethodStatus({
  expiringThresholdMonths = 1,
  expiringThresholdDays,
  paymentMethod,
  getCurrentDate,
} = {}) {
  const getTime = useRenderTime();

  const date = useMemo(() => {
    return getCurrentDate ? getCurrentDate() : new Date(getTime());
  }, [getCurrentDate, getTime]);

  const [currentYear, currentMonth] = useMemo(() => {
    return [date.getFullYear(), date.getMonth() + 1];
  }, [date]);

  const { defaultPaymentMethod } = usePayments();

  if (!paymentMethod) {
    paymentMethod = defaultPaymentMethod;
  }

  const [
    expiringInDays,
    expiringInMonths,
    expirationMonth,
    expirationFullYear,
  ] = useMemo(() => {
    if (paymentMethod) {
      const { expMonth, expFullYear } = paymentMethod;
      if (expMonth != null && expFullYear != null) {
        const expDate = new Date(expFullYear, expMonth, 0);
        return [
          differenceInCalendarDays(expDate, date),
          (expFullYear - currentYear) * 12 + (expMonth - currentMonth),
          expMonth,
          expFullYear,
        ];
      }
    }
    return [null, null, null, null];
  }, [paymentMethod, date, currentYear, currentMonth]);

  const paymentMethodStatus = useMemo(() => {
    if (expiringInMonths == null) {
      return PaymentMethodStatus.NONE;
    }
    // An expiration month of 10 means it's valid through the end of October,
    // not that it expires on October 1st. So expired cards will have a negative
    // value, and 0 is valid but expiring.
    if (expiringInMonths < 0) {
      return PaymentMethodStatus.EXPIRED;
    }
    if (expiringThresholdDays != null) {
      if (expiringInDays <= expiringThresholdDays) {
        return PaymentMethodStatus.EXPIRING;
      }
    } else if (expiringThresholdMonths != null) {
      if (expiringInMonths <= expiringThresholdMonths) {
        return PaymentMethodStatus.EXPIRING;
      }
    }
    return PaymentMethodStatus.OK;
  }, [
    expiringInDays,
    expiringInMonths,
    expiringThresholdDays,
    expiringThresholdMonths,
  ]);

  return useMemo(
    () => ({
      paymentMethodStatus,
      expirationFullYear,
      expirationMonth,
      expiringInDays,
      expiringInMonths,
      hasExpiration: paymentMethodStatus !== PaymentMethodStatus.NONE,
      isExpired: paymentMethodStatus === PaymentMethodStatus.EXPIRED,
      isExpiring: paymentMethodStatus === PaymentMethodStatus.EXPIRING,
    }),
    [
      expirationFullYear,
      expirationMonth,
      expiringInDays,
      expiringInMonths,
      paymentMethodStatus,
    ]
  );
}

import { useCallback, useEffect, useMemo } from 'react';

import Router, { useRouter } from 'next/router';

import {
  createProductListingQueryParams,
  parseProductListingQueryParams,
} from './productListingParamsCustomFilters';

/**
 * This hook will update the current URL based on changes to the filter or sort
 * state.
 */
export default function useProductListingQueryParams({
  autoAppliedFilters,
  baseFilters,
  filters,
  filterSettings,
  sortOption,
  sortOptions,
  onQueryChange,
  enabled = true,
  mySize = true,
}) {
  const router = useRouter();
  const { aggregations } = baseFilters;

  const productListingParamKeys = useMemo(() => {
    // * Calculate what keys relate to the filtering/sorting and should
    // * be modified
    return new Set([
      'defaultTagId', // Added for Curvy page on SXF.
      'category_ids',
      'avg_review_min',
      'price_min',
      'price_max',
      'sale_price_min',
      'sale_price_max',
      'my_size',
      'sort',
      ...aggregations.map((aggregation) => aggregation.field),
    ]);
  }, [aggregations]);

  const updateRoute = useCallback(
    (newProductListingParams) => {
      // TODO: Technically we should merge both the `Router.query` and
      // params from `Router.asPath` separately, as they can be different.
      // The current code will clobber any `query` fields that don't appear in
      // the `asPath` (such as those parsed from routes.js patterns, for
      // example).

      // Strip off any fragment first. Presumably the user has already been
      // scrolled to whatever fragment was there initially, and now it's no
      // longer necessary to persist.
      const [currentUrl] = Router.asPath.split('#', 1);
      const [currentPathname, currentParams] = currentUrl.split('?');

      const newParams = new URLSearchParams(currentParams);

      // * Set the new values (existing ones will be overwritten)
      for (const [paramKey, paramVal] of newProductListingParams) {
        newParams.set(paramKey, paramVal);
      }

      // * Remove params that no longer exist
      for (const [paramKey] of newParams) {
        // * Only modify it if the current param is related to filtering/sort
        if (
          productListingParamKeys.has(paramKey) &&
          !newProductListingParams.has(paramKey)
        ) {
          newParams.delete(paramKey);
        }
      }

      const newQueryString = newParams.toString();

      const asPath = newQueryString
        ? `${currentPathname}?${newQueryString}`
        : currentPathname;

      // Convert from URLSearchParams to support older versions of Nextjs (SXF)
      // TODO: Remove when all reliant versions are upgraded to >= 9.5.0
      // TODO: https://github.com/vercel/next.js/commit/3369d67bd1bc495462c587ec12c346c3172b0359
      const query = {};
      for (const [key, value] of newParams) {
        query[key] = value;
      }
      Router.replace(
        {
          pathname: Router.pathname,
          query: query,
        },
        asPath,
        // * Change the location without actually reloading the route
        { shallow: true }
      );
    },
    [productListingParamKeys]
  );

  // * Update the URL based on the filter/sort state (or vice versa)
  useEffect(() => {
    // Send the current URL params through a parse + create round-trip: what
    // params do we end up with?
    if (enabled) {
      const currentParamFilters = parseProductListingQueryParams(router.query, {
        baseFilters,
        filterSettings,
        sortOptions,
      });
      const currentParams = createProductListingQueryParams({
        baseFilters,
        filters: currentParamFilters.filters,
        filterSettings,
        sortOption: currentParamFilters.sortOption,
        mySize: currentParamFilters.mySize,
      });
      // Now create params for the current filters...
      const currentFilterParams = createProductListingQueryParams({
        autoAppliedFilters,
        baseFilters,
        filterSettings,
        filters,
        sortOption,
        mySize,
      });
      // If the current filter params don't match the current URL params,
      // update the URL!
      if (currentParams.toString() !== currentFilterParams.toString()) {
        if (onQueryChange) {
          onQueryChange(currentFilterParams);
        }
        // * The route update here is needed for non filter
        updateRoute(currentFilterParams);
      }
    }
  }, [
    autoAppliedFilters,
    baseFilters,
    enabled,
    filters,
    filterSettings,
    mySize,
    onQueryChange,
    router.query,
    sortOption,
    sortOptions,
    updateRoute,
  ]);
}

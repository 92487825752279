import { useReducer, useMemo, useCallback, useRef } from 'react';

import useId from '../useId';
import useLayoutEffect from '../useLayoutEffect';

// Find the selected item index in the available option, with the currently active value
const findSelectedIndex = (options, value) => {
  return Math.max(
    0,
    options.findIndex((option, index) => option.value === value)
  );
};

export const useDropdown = ({ value, options, onChange, onOpen, onClose }) => {
  const dropdownReducer = useCallback(
    (prevState, action) => {
      const state = { ...prevState };

      switch (action.type) {
        case 'TOGGLE_OPENED':
          state.isOpened = !state.isOpened;
          // If the user clicks the button to close it, treat it as an
          // escape/close, not the same as confirm.
          state.highlightedIndex = findSelectedIndex(options, value);
          break;

        case 'CONFIRM':
          state.isOpened = false;
          if (prevState.highlightedIndex !== state.highlightedIndex) {
            state.hasChanged = true;
          }
          break;

        case 'CLOSE': {
          state.isOpened = false;
          state.highlightedIndex = findSelectedIndex(options, value);
          break;
        }

        case 'SELECT_PREVIOUS': {
          state.highlightedIndex = Math.max(state.highlightedIndex - 1, 0);
          state.isOpened = true;
          break;
        }

        case 'SELECT_NEXT': {
          state.highlightedIndex = Math.min(
            state.highlightedIndex + 1,
            options.length - 1
          );
          state.isOpened = true;
          break;
        }

        case 'SELECT_FIRST': {
          state.highlightedIndex = 0;
          state.isOpened = true;
          break;
        }

        case 'SELECT_LAST': {
          state.highlightedIndex = options.length - 1;
          state.isOpened = true;
          break;
        }

        case 'SELECT_VALUE': {
          // When an item is manually selected (e.g. using clicking an item) we immediately
          // select it and close the dropdown
          const index = options.findIndex(
            (option) => option.value === action.value
          );
          state.highlightedIndex = index > -1 ? index : state.highlightedIndex;
          state.isOpened = false;
          if (prevState.highlightedIndex !== state.highlightedIndex) {
            state.hasChanged = true;
          }
          break;
        }

        default:
          return prevState;
      }

      // When the dropdown is opened, we actively update the current
      // index on each action, so it's up-to-date when the dropdown is opened
      if (!prevState.isOpened && state.isOpened) {
        if (prevState.highlightedIndex === state.highlightedIndex) {
          state.highlightedIndex = findSelectedIndex(options, value);
        } else {
          state.highlightedIndex = Math.max(
            0,
            Math.min(options.length - 1, state.highlightedIndex)
          );
        }
      }

      return state;
    },
    [value, options]
  );

  const id = useId('dropdown-');

  const [state, dispatch] = useReducer(dropdownReducer, {
    id,
    highlightedIndex: 0,
    isOpened: false,
    hasChanged: false,
  });

  const wasOpened = useRef(state.isOpened);

  useLayoutEffect(() => {
    if (state.isOpened && !wasOpened.current && onOpen) {
      onOpen();
    } else if (!state.isOpened && wasOpened.current && onClose) {
      onClose();
    }
  }, [state.isOpened, onOpen, onClose]);

  useLayoutEffect(() => {
    const selectedOption = options[state.highlightedIndex];
    if (
      !state.isOpened &&
      wasOpened.current &&
      selectedOption &&
      selectedOption.value !== value &&
      onChange
    ) {
      onChange(selectedOption);
    }

    wasOpened.current = state.isOpened;
  }, [onChange, options, value, state.isOpened, state.highlightedIndex]);

  const actions = useMemo(
    () => ({
      onButtonClick(event) {
        event.preventDefault();
        dispatch({ type: 'TOGGLE_OPENED' });
      },

      onButtonKey(event) {
        switch (event.keyCode) {
          case 38: // Up
            event.preventDefault();
            dispatch({ type: 'SELECT_PREVIOUS' });
            break;
          case 40: // Down
            event.preventDefault();
            dispatch({ type: 'SELECT_NEXT' });
            break;
        }
      },

      onListBlur(event) {
        event.preventDefault();
        dispatch({ type: 'CLOSE' });
      },

      onListKey(event) {
        switch (event.keyCode) {
          case 13: // Enter
            event.preventDefault();
            dispatch({ type: 'CONFIRM' });
            break;
          case 27: // Escape
            dispatch({ type: 'CLOSE' });
            break;
          case 36: // Home
            event.preventDefault();
            dispatch({ type: 'SELECT_FIRST' });
            break;
          case 35: // End
            event.preventDefault();
            dispatch({ type: 'SELECT_LAST' });
            break;
          case 38: // Up
            event.preventDefault();
            dispatch({ type: 'SELECT_PREVIOUS' });
            break;
          case 40: // Down
            event.preventDefault();
            dispatch({ type: 'SELECT_NEXT' });
            break;
        }
      },

      onClickItem(event, value) {
        event.preventDefault();
        dispatch({ type: 'SELECT_VALUE', value });
      },
    }),
    [dispatch]
  );

  const output = useMemo(() => {
    const highlightedOption = options[state.highlightedIndex];
    const highlightedValue = highlightedOption ? highlightedOption.value : null;
    const selectedOption = options[findSelectedIndex(options, value)];

    return {
      ...state,
      options,
      highlightedOption,
      highlightedValue,
      selectedOption,
      value,
    };
  }, [state, options, value]);

  return [output, actions];
};

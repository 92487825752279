import React, { useCallback, useRef, MutableRefObject } from 'react';

import { createContext } from '../utils/createContext';

type AutoIdContextValueType = () => number;

const [useAutoIdContext, Context] =
  createContext<AutoIdContextValueType>('AutoId');

export { Context, useAutoIdContext };

type AutoIdCounterType = MutableRefObject<number>;

type AutoIdProviderProps = React.PropsWithChildren<{
  /**
   * A ref that contains a number. Use this if you need multiple
   * `AutoIdProvider` elements to share a counter. If not provided, a default
   * counter that starts at 0 will be used.
   */
  counter: AutoIdCounterType;
}>;

/**
 * A provider for use with the `useId` hook. It supplies a function that can
 * be called to increment and return a numeric ID.
 */
export default function AutoIdProvider({
  children,
  counter,
}: AutoIdProviderProps) {
  const defaultCounter = useRef(0);
  if (!counter) {
    counter = defaultCounter;
  }
  const getId = useCallback(() => ++counter.current, [counter]);
  return <Context.Provider value={getId}>{children}</Context.Provider>;
}

AutoIdProvider.Context = Context;

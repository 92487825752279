// WIP/Work in Progress.
// TODO: Add Adyen check and authorization check
import React, { useCallback, useMemo, useState } from 'react';

import {
  useMembership,
  usePayments,
  usePaymentMethodStatus,
} from '../../../../techstyle-shared/react-accounts';
import { useCartActions } from '../../../../techstyle-shared/react-cart';
import {
  OrderDetailDto,
  CartOrderSubmitRequestDto,
} from '../../../../techstyle-shared/react-types';
import { Cart } from '../Checkout/checkoutTypes';
import PaymentTypeIcons from '../PaymentTypeIcons';
import { PaymentMethod } from '../types';
import { createContext } from '../utils/createContext';

const { PAYMENT_TYPES } = PaymentTypeIcons;
const PAYMENT_METHOD_TYPES = {
  PSP: 'psp',
  CC: 'creditcard',
  SEPA: 'adyensepa',
};

type PaymentMethodStatus = {
  paymentMethodStatus: 'NONE' | 'OK' | 'EXPIRING' | 'EXPIRED';
  expirationFullYear: number;
  expirationMonth: number;
  expiringInDays: number | null;
  expiringInMonths: number | null;
  hasExpiration: boolean;
  isExpired: boolean;
  isExpiring: boolean;
};

type PaymentContextValueType = {
  paymentMethods?: PaymentMethod[];
  defaultPaymentMethod?: PaymentMethod;
  canVipPlaceOrder: boolean;
  cartPaymentMethod?: PaymentMethod;
  cartPaymentMethodStatus: PaymentMethodStatus;
  isPayPalEnabled: boolean;
  isApplePayEnabled: boolean;
  orderData?: OrderDetailDto;
  placeOrderAndSubmit: (
    paymentMethod: PaymentMethod,
    payload?: CartOrderSubmitRequestDto
  ) => Promise<void>;
};

type RootProps = React.PropsWithChildren<{
  cart: Cart;
  paymentOptions: PaymentOptionsType;
  paymentMethods?: PaymentMethod[];
}>;

type PaymentOptionsType = {
  paymentTypes?: string[] | undefined;
  paymentSettings?: any[] | undefined;
  paymentProvider?: string | undefined;
};

export const [usePaymentContext, Context] =
  createContext<PaymentContextValueType>('PaymentContext');

export const getCartPaymentMethod = (
  cart: Cart,
  paymentMethods: PaymentMethod[]
) => {
  // method: cart.paymentMethod,
  const cartPaymentMethod = paymentMethods.find((item) => {
    return (
      item.paymentServiceProviderId === cart.paymentServiceProviderId ||
      item.creditCardId === cart.creditCardId
    );
  });

  return cartPaymentMethod;
};

export const isVipEligibleToPlaceOrder = (
  cart: Cart,
  isVip: boolean,
  cartPaymentMethod: PaymentMethod
): boolean => {
  const isShippingAddressIdPresent = !!cart.shippingAddressId;
  const isPaymentMethodExisting = Object.keys(cartPaymentMethod).length > 0;
  return isVip && isShippingAddressIdPresent && isPaymentMethodExisting;
};

/**
 * Get Payment Method Type
 * Gets the payment method based on the card type.
 */
export const getPaymentIdAndMethod = (paymentMethod: PaymentMethod) => {
  if (
    paymentMethod.cardType === PAYMENT_TYPES.PAYPAL_LITLE ||
    paymentMethod.cardType === PAYMENT_TYPES.PAYPAL_BRAINTREE ||
    !paymentMethod.creditCardId
  ) {
    return {
      id: paymentMethod.paymentServiceProviderId,
      method: PAYMENT_METHOD_TYPES.PSP,
    };
  }

  return { id: paymentMethod.creditCardId, method: PAYMENT_METHOD_TYPES.CC };
};

export const getIsPaypalEnabled = (
  paymentOptions: PaymentOptionsType
): boolean => {
  return !!(
    paymentOptions &&
    paymentOptions?.paymentTypes &&
    paymentOptions.paymentTypes?.find((type) => type === 'paypal')
  );
};

export const getIsApplePayEnabled = () => {
  // TODO : TEST ONLY. Change the logic once apple pay is integrated.
  return false;
};

export default function PaymentContext({
  children,
  cart,
  paymentOptions,
  paymentMethods: paymentMethodsFromProps,
}: RootProps) {
  const paymentMethodData = usePayments();
  const cartActions = useCartActions();
  const [orderData, setOrderData] = useState<any>();
  const { paymentMethods, defaultPaymentMethod } = useMemo(() => {
    if (paymentMethodsFromProps) {
      return {
        defaultPaymentMethod: paymentMethodsFromProps.filter(
          ({ isDefault }) => isDefault === true
        ),
        paymentMethods: paymentMethodsFromProps,
      };
    } else {
      return paymentMethodData;
    }
  }, [paymentMethodsFromProps, paymentMethodData]);
  const { isVip } = useMembership();
  const cartPaymentMethod = getCartPaymentMethod(cart, paymentMethods);
  const isCartPaymentMethodExisting = !!cartPaymentMethod;

  const paymentMethodStatusParams = useMemo(() => {
    if (isCartPaymentMethodExisting) {
      /* Typescript was throwing an error saying expiringThresholdMonths,expiringThresholdDays,getCurrentDate
      cannot be left null and we have pass all of these to usePaymentMethodStatus hook  */
      return {
        paymentMethod: cartPaymentMethod,
        expiringThresholdMonths: undefined,
        expiringThresholdDays: undefined,
        getCurrentDate: undefined,
      };
    }
    return undefined;
  }, [isCartPaymentMethodExisting, cartPaymentMethod]);

  const cartPaymentMethodStatus = usePaymentMethodStatus(
    paymentMethodStatusParams
  );

  const canVipPlaceOrder = cartPaymentMethod
    ? isVipEligibleToPlaceOrder(cart, isVip, cartPaymentMethod)
    : false;

  const isApplePayEnabled = getIsApplePayEnabled();
  const isPayPalEnabled = getIsPaypalEnabled(paymentOptions);

  // TODO: 1. Determine if it's Adyen. 2. Call cartActions.completeOrder if authorization is needed
  // (see SavageX implementation)
  const placeOrderAndSubmit = useCallback(
    async (
      paymentMethod: PaymentMethod,
      payload?: CartOrderSubmitRequestDto
    ) => {
      const { id, method } = getPaymentIdAndMethod(paymentMethod);
      if (id && method) {
        // call 'cart/submitCartOrder' api with payload
        const order = await cartActions.submitOrder({
          ...payload,
          paymentId: id,
          paymentMethod: method,
        });
        setOrderData(order.payload);
      }
    },
    [cartActions]
  );

  const context = useMemo(
    () => ({
      paymentMethods,
      defaultPaymentMethod,
      cartPaymentMethod,
      canVipPlaceOrder,
      cartPaymentMethodStatus,
      isPayPalEnabled,
      isApplePayEnabled,
      placeOrderAndSubmit,
      orderData,
    }),
    [
      paymentMethods,
      defaultPaymentMethod,
      cartPaymentMethod,
      canVipPlaceOrder,
      cartPaymentMethodStatus,
      isPayPalEnabled,
      isApplePayEnabled,
      placeOrderAndSubmit,
      orderData,
    ]
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

import logger from './logger';
import useByoConfigs from './useByoConfigs';

const debug = logger.extend('useByoConfigById');

export default function useByoConfigById(byoProductId = null) {
  const byoConfigs = useByoConfigs();

  if (!byoConfigs[byoProductId]) {
    debug('Zero BYO configs found for byoProductId: %s', byoProductId);

    return undefined;
  }

  return byoConfigs[byoProductId];
}

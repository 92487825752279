export function getMergedArrayOption(sources) {
  return async (ctx) => {
    const items = [];
    const meta = { sources: [] };

    const addFromSource = (source, array) => {
      if (array) {
        const filtered = array.filter(Boolean);
        if (filtered.length) {
          items.push(...filtered);
          meta.sources.push({
            ...source,
            toString() {
              return `${source.name} ${source.type}`;
            },
          });
        }
      }
    };

    for (const name in sources) {
      const value = sources[name];
      if (typeof value === 'function') {
        addFromSource({ name, type: 'function' }, await value(ctx));
      } else if (value) {
        addFromSource({ name, type: 'array' }, value);
      }
    }

    const uniqueItems = new Set(items);
    return [Array.from(uniqueItems), meta];
  };
}

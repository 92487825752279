import React, { useEffect, useState } from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import { Script } from '../../../techstyle-shared/react-marketing';
import { useOneTrustConsent } from '../../utils/useOneTrustConsent';

export default function ConstructorIoBeaconSnippet({
  scriptSrc = config.get('public.constructorio.scriptSrc'),
}) {
  const consentList = useOneTrustConsent();
  const [hasConsent, setHasConsent] = useState(false);

  useEffect(() => {
    setHasConsent(consentList.includes('C0003'));
  }, [consentList]);

  return (
    <>
      {hasConsent ? (
        <Script
          globalScriptKey="constructorio-script"
          skipServerRender
          src={scriptSrc}
          defer
        />
      ) : null}
    </>
  );
}

ConstructorIoBeaconSnippet.propTypes = {
  /**
   * Script src value, the default value comes from global configs
   */
  scriptSrc: PropTypes.string,
};

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import type { NetworkStatus } from '../../../techstyle-shared/redux-core';

import { getAddresses } from './accountsModule';
import { AccountsModuleInitialState, AddressDto } from './types';
import useAccountActions from './useAccountActions';

type AddressesState = {
  addresses: AddressDto[];
  networkStatus: NetworkStatus;
};

export default function useAddresses() {
  const actions = useAccountActions();
  const addresses: AddressesState = useSelector<
    AccountsModuleInitialState,
    AddressesState
  >(getAddresses);

  useEffect(() => {
    if (!addresses.networkStatus.isUpToDate) {
      actions.loadAddresses();
    }
  }, [actions, addresses.networkStatus.isUpToDate]);

  return addresses;
}

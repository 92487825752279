import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMembership } from '../../../../techstyle-shared/react-accounts';
import {
  useCategoryBreadcrumbs,
  useCategoryBreadcrumbHierarchy,
  useProductListingQueryParams,
} from '../../../../techstyle-shared/react-products';
import BaseBreadcrumbs from '../Breadcrumbs';
import BaseFilterTags from '../FilterTags';
import { LabelType, useCategoryLabels } from '../LabelProvider';
import ProductBrowserContext, {
  useProductBrowser,
} from '../ProductBrowserContext';
import ProductCategoryFilter from '../ProductCategoryFilter';
import ProductFilterContext, {
  useProductFilters,
} from '../ProductFilterContext';
import ProductListingFilter from '../ProductListingFilter';
import ProductSizeFilter from '../ProductSizeFilter';
import { sortOptionPropType, individualSortOption } from '../SortDropdown';
import { mobile, desktop } from '../styles';
import useFilterTagValues from '../useFilterTagValues';
import {
  categoryBreadcrumbToFilterCategory,
  getTotalFilterCount,
} from '../utils/category';
import { getNormalizedFilters } from '../utils/filters';
import { getCombinedFilterSizeData } from '../utils/sizes';

export function getDefaultSortOption(sortOptions) {
  return (
    sortOptions.find((option) => option.isDefault) || sortOptions[0] || null
  );
}

const MobileNavigation = styled.div`
  position: relative;
  z-index: 2;

  ${desktop`display: none;`};
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  ${desktop`
    padding: 16px 24px 16px 32px;
  `};
`;

const Body = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 16px 32px 16px;
  z-index: 0;

  ${desktop`
    padding: 0 32px 32px 32px;
  `};
`;

const Breadcrumbs = styled(BaseBreadcrumbs)`
  font-size: 14px;
  line-height: ${24 / 14};

  ${mobile`
    display: none;
  `}
`;

const FilterTags = styled(BaseFilterTags)`
  margin-bottom: 8px;
`;

function selectAggregationFilter(selectedFilters) {
  return selectedFilters.aggregationFilter;
}

const categoryBreadcrumbLabelOptions = {
  labelType: LabelType.CATEGORY_BREADCRUMBS,
};

const CategoryBreadcrumbs = (props) => {
  const { breadcrumbs } = useProductBrowser();
  const categoryLabelCtx = useCategoryLabels();

  const breadcrumbLabels = useMemo(
    () =>
      breadcrumbs
        ? breadcrumbs.map((breadcrumb) =>
            categoryLabelCtx.renderLabel(
              breadcrumb,
              categoryBreadcrumbLabelOptions
            )
          )
        : [],
    [breadcrumbs, categoryLabelCtx]
  );
  return (
    <Breadcrumbs activeElementType="h1" labels={breadcrumbLabels} {...props} />
  );
};

const defaultGetFilterFieldProps = () => {};

export const ProductFilters = ({
  aggregations: aggregationsFromProps,
  avgReviewFilter: avgReviewFilterFromProps,
  categoryFilter: categoryFilterFromProps,
  colorSwatches: colorSwatchesFromProps,
  combineFilterSizeData = true,
  getFilterFieldProps = defaultGetFilterFieldProps,
  hideCategoryFilter,
  listingFilterComponent: ListingFilter = ProductListingFilter,
  priceFilter: priceFilterFromProps,
  priceFilterComponent: PriceFilter = ListingFilter,
  sizeFilterComponent: SizeFilter = ProductSizeFilter,
  ...rest
}) => {
  const productBrowserCtx = useProductBrowser();
  const { setSelectedFilters } = useProductFilters();
  const { isVip } = useMembership();

  let aggregations = aggregationsFromProps;
  let colorSwatches = colorSwatchesFromProps;
  let categoryFilter = categoryFilterFromProps;
  let avgReviewFilter = avgReviewFilterFromProps;
  let priceFilter = priceFilterFromProps;

  if (productBrowserCtx) {
    const {
      aggregations: aggregationsFromCtx,
      avgReviewFilter: avgReviewFilterFromCtx,
      categoryFilter: categoryFilterFromCtx,
      colorSwatches: colorSwatchesFromCtx,
      priceFilter: priceFilterFromCtx,
    } = productBrowserCtx;

    aggregations = aggregations || aggregationsFromCtx;
    avgReviewFilter = avgReviewFilter || avgReviewFilterFromCtx;
    categoryFilter = categoryFilter || categoryFilterFromCtx;
    colorSwatches = colorSwatches || colorSwatchesFromCtx;
    priceFilter = priceFilter || priceFilterFromCtx;
  }

  const aggregationSizeData = useMemo(
    () =>
      combineFilterSizeData
        ? getCombinedFilterSizeData(aggregations)
        : aggregations,
    [aggregations, combineFilterSizeData]
  );

  const handleCategoryFilterChange = ({ filterField, newSelectedFilters }) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      categoryIds: newSelectedFilters,
    }));
  };

  const handleAggregationFilterChange = ({
    filterField,
    newSelectedFilters,
  }) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      aggregationFilter: {
        ...prevFilters.aggregationFilter,
        [filterField.field]: newSelectedFilters,
      },
    }));

    const { [filterField.field]: autoApplied, ...autoAppliedFilters } =
      productBrowserCtx.autoAppliedFilters || {};

    // If we don't see the auto-applied value in our new list of filters, it has been
    // removed and is no longer considered to be auto-applied.
    if (
      autoApplied &&
      !autoApplied.includes((auto) => newSelectedFilters.includes(auto))
    ) {
      productBrowserCtx.setAutoAppliedFilters(autoAppliedFilters);
    }
  };

  const handleReviewFilterChange = ({ filterField, newSelectedFilters }) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      avgReviewMin: newSelectedFilters,
    }));
  };

  const handlePriceFilterChange = ({ filterField, newSelectedFilters }) => {
    const priceFilterOptionMin = isVip
      ? 'saleUnitPriceMinimum'
      : 'vipUnitPriceMinimum';
    const priceFilterOptionMax = isVip
      ? 'saleUnitPriceMaximum'
      : 'vipUnitPriceMaximum';
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [priceFilterOptionMin]: newSelectedFilters.min,
      [priceFilterOptionMax]: newSelectedFilters.max,
    }));
  };

  return (
    <>
      {categoryFilter && !hideCategoryFilter ? (
        <ProductCategoryFilter
          as={ListingFilter}
          filterField={categoryFilter}
          onChange={handleCategoryFilterChange}
        />
      ) : null}

      {aggregationSizeData
        .filter((filterField) => filterField.totalAggregationCount !== 0)
        .map((filterField) => {
          const FilterComponent = filterField.field.startsWith('size')
            ? SizeFilter
            : ListingFilter;
          return (
            <FilterComponent
              key={filterField.field}
              filterField={filterField}
              colorSwatches={colorSwatches}
              selectedFilters={selectAggregationFilter}
              onChange={handleAggregationFilterChange}
              gridCheckboxProps={{
                ...getFilterFieldProps({ filterField: filterField }),
              }}
              checkboxProps={{
                ...getFilterFieldProps({ filterField: filterField }),
              }}
              {...rest}
            />
          );
        })}

      {avgReviewFilter ? (
        <ListingFilter
          filterField={avgReviewFilter}
          onChange={handleReviewFilterChange}
        />
      ) : null}

      {priceFilter ? (
        <PriceFilter
          filterField={priceFilter}
          onChange={handlePriceFilterChange}
          listingFilterComponent={ListingFilter}
        />
      ) : null}
    </>
  );
};

ProductFilters.propTypes = {
  aggregations: PropTypes.array,
  avgReviewFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  /**
   * Category object to pass to ProductCategoryFilter
   */
  categoryFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  colorSwatches: PropTypes.object,
  /**
   * Boolean to show combined filter size data, or to show them as separate filters
   */
  combineFilterSizeData: PropTypes.bool,
  /**
   * Callback function passed through to spread specific props to a particular filter field like type, etc
   */
  getFilterFieldProps: PropTypes.func,
  hideCategoryFilter: PropTypes.bool,
  /**
   * Component to render in place for ProductListingFilter.
   */
  listingFilterComponent: PropTypes.elementType,
  priceFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  priceFilterComponent: PropTypes.elementType,
  /**
   * Component to render in place for ProductSizeFilter.
   */
  sizeFilterComponent: PropTypes.elementType,
};

const SideBarFilterTags = (props) => {
  const {
    aggregations,
    avgReviewFilter,
    baseFilters,
    categoryFilter,
    filters,
    priceFilter,
    setFilters,
  } = useProductBrowser();
  const { toggleCollapsibleFilterIsOpen } = useProductFilters();

  const clearAllFilters = useCallback(() => {
    setFilters(baseFilters);
    // Close all filter drawers;
    toggleCollapsibleFilterIsOpen({ type: 'reset' });
  }, [baseFilters, setFilters, toggleCollapsibleFilterIsOpen]);

  const filterTagValues = useFilterTagValues({
    aggregations,
    avgReviewFilter,
    categoryFilter,
    filters,
    priceFilter,
    setFilters,
  });

  return filterTagValues.length ? (
    <FilterTags
      selectedFilters={filterTagValues}
      onClearAll={clearAllFilters}
      {...props}
    />
  ) : null;
};

export default function ProductBrowser({
  activeCategory: inputActiveCategory,
  avgReviewFilter,
  autoAppliedFilters: initialAutoAppliedFilters,
  breadcrumbs: inputBreadcrumbs,
  baseFilters: inputBaseFilters,
  children,
  colorSwatches,
  initialAggregations = [],
  initialCount = 0,
  initialFilters = inputBaseFilters,
  priceFilter,
  sortOptions,
  initialSortOption,
  mySize = true,
  enableQueryParams = true,
}) {
  // This will be used to determine whether the grid parameters are changing
  // (most likely from `getInitialProps` routing to a new page).
  const baseFiltersRef = useRef(inputBaseFilters);
  const isGridChanging = inputBaseFilters !== baseFiltersRef.current;

  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(initialCount);
  const [overlayVisible, setOverlayVisible] = useState(false);

  // Aggregation data comes from the products API response.
  const [aggregationsFromState, setAggregations] =
    useState(initialAggregations);
  const aggregations = isGridChanging
    ? initialAggregations
    : aggregationsFromState;

  initialFilters = { aggregations: [], ...initialFilters };

  // TODO: Handle 'isChanging' case
  const [autoAppliedFiltersFromState, setAutoAppliedFilters] = useState(
    initialAutoAppliedFilters
  );

  const autoAppliedFilters = isGridChanging
    ? initialAutoAppliedFilters
    : autoAppliedFiltersFromState;

  // Filters are initialized based on the rewrite `productJson` combined with
  // URL parameters, and they are updated by adjusting the filters in various
  // components.
  const [filtersFromState, setFilters] = useState(initialFilters);
  const filters = isGridChanging ? initialFilters : filtersFromState;

  const normalizedFilters = useMemo(
    () => getNormalizedFilters(filters),
    [filters]
  );
  const baseFilters = useMemo(
    () => getNormalizedFilters(inputBaseFilters),
    [inputBaseFilters]
  );

  initialSortOption = initialSortOption || getDefaultSortOption(sortOptions);

  // * If the sort is to be displayed, initialSortOption should always be provided
  const [sortOptionFromState, setSortOption] = useState(initialSortOption);
  const sortOption = isGridChanging ? initialSortOption : sortOptionFromState;

  // The grid may be changing, in which case we should sync the internal state
  // back up with the incoming props.
  useEffect(() => {
    baseFiltersRef.current = inputBaseFilters;
    setAggregations(aggregations);
    setFilters(filters);
    setSortOption(sortOption);
  }, [aggregations, inputBaseFilters, filters, sortOption]);

  useProductListingQueryParams({
    autoAppliedFilters,
    baseFilters,
    filters: normalizedFilters,
    sortOptions,
    sortOption,
    mySize,
    enabled: enableQueryParams,
  });

  const primaryCategoryId = (baseFilters.backgroundCategoryIds ||
    baseFilters.categoryIds ||
    [])[0];

  const activeCategoryFromState = useCategoryBreadcrumbs(primaryCategoryId);
  const breadcrumbsFromState =
    useCategoryBreadcrumbHierarchy(primaryCategoryId);

  const activeCategory = inputActiveCategory || activeCategoryFromState;
  const breadcrumbs = inputBreadcrumbs || breadcrumbsFromState;

  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const totalFilterCount = useMemo(
    () => getTotalFilterCount(normalizedFilters),
    [normalizedFilters]
  );

  const categoryFilter = useMemo(
    () =>
      activeCategory
        ? categoryBreadcrumbToFilterCategory(activeCategory)
        : null,
    [activeCategory]
  );

  const toggleOverlay = useCallback(() => {
    setOverlayVisible((prevState) => !prevState);
  }, []);

  const openOverlay = useCallback(() => {
    setOverlayVisible(true);
  }, []);

  const closeOverlay = useCallback(() => {
    setOverlayVisible(false);
  }, []);

  const value = useMemo(
    () => ({
      activeCategory,
      aggregations,
      avgReviewFilter,
      autoAppliedFilters,
      setAutoAppliedFilters,
      breadcrumbs,
      setAggregations,
      baseFilters,
      categoryFilter,
      colorSwatches,
      filters: normalizedFilters,
      mySize,
      setFilters,
      isFilterDrawerOpen,
      setFilterDrawerOpen,
      sort: sortOption,
      setSort: setSortOption,
      sortOptions,
      priceFilter,
      primaryCategoryId,
      totalFilterCount,
      isLoading,
      setIsLoading,
      overlayVisible,
      toggleOverlay,
      openOverlay,
      closeOverlay,
      totalCount,
      setTotalCount,
    }),
    [
      activeCategory,
      aggregations,
      avgReviewFilter,
      autoAppliedFilters,
      breadcrumbs,
      baseFilters,
      categoryFilter,
      colorSwatches,
      normalizedFilters,
      mySize,
      isFilterDrawerOpen,
      sortOption,
      sortOptions,
      priceFilter,
      primaryCategoryId,
      totalFilterCount,
      isLoading,
      overlayVisible,
      toggleOverlay,
      openOverlay,
      closeOverlay,
      totalCount,
    ]
  );

  return (
    <ProductBrowserContext value={value}>
      <ProductFilterContext
        appliedFilters={normalizedFilters}
        // TODO: Debounce filter changes with a delay.
        onChange={setFilters}
      >
        {typeof children === 'function' ? children(value) : children}
      </ProductFilterContext>
    </ProductBrowserContext>
  );
}

ProductBrowser.propTypes = {
  /**
   * Custom category hierarchy to use instead of category-based breadcrumbs
   * from Redux store
   */
  activeCategory: PropTypes.object,
  /**
   * Aggregation filters that have been automatically applied (ie profile sizes).
   * These are not added to the URL params until a new filter is added to that field
   * or the auto-applied value is removed.
   */
  autoAppliedFilters: PropTypes.object,
  /**
   * A filter field object describing the options for filtering by
   * `avgReviewMin`.
   */
  avgReviewFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  /**
   * The filter settings that cannot be unapplied; if the user resets/clears all
   * filters, this is what they will reset to.
   */
  baseFilters: PropTypes.object,
  /**
   * Custom breadcrumbs to use instead of category-based breadcrumbs from
   * Redux store
   */
  breadcrumbs: PropTypes.array,
  /**
   * Grid area content.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * A color swatch mapping to supply via context to filters that render colors.
   */
  colorSwatches: PropTypes.object,
  /**
   * Determines whether url params should be added when filtering.
   */
  enableQueryParams: PropTypes.bool,
  /**
   * Aggregations array from any initial products API response that may be
   * available on the first render.
   */
  initialAggregations: PropTypes.array,
  /**
   * Initial "total products" count for a paged product result.
   */
  initialCount: PropTypes.number,
  /**
   * The initial filter settings. It's possible for this to be different from
   * `baseFilters`, because other filter settings can be initialized via URL
   * parameters.
   */
  initialFilters: PropTypes.object,
  /**
   * Initial sort to be used.
   */
  initialSortOption: individualSortOption,
  /**
   * Determines whether the grid should be filtered by the user's profile
   * sizes (true = filtered).
   */
  mySize: PropTypes.bool,
  /**
   * A filter field object describing the options for filtering by price.
   */
  priceFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  /**
   * Sort options to display in the `SortDropdown`.
   */
  sortOptions: sortOptionPropType,
};

ProductBrowser.Body = Body;
ProductBrowser.CategoryBreadcrumbs = CategoryBreadcrumbs;
ProductBrowser.FilterTags = SideBarFilterTags;
ProductBrowser.Header = Header;
ProductBrowser.MobileNavigation = MobileNavigation;
ProductBrowser.ProductFilters = ProductFilters;

import { StatusCodes } from 'http-status-codes';
import { NextPageContext } from 'next';
import Router from 'next/router';

import isomorphicRedirect from './isomorphicRedirect';

export type RedirectRule = {
  redirectPath: string;
  statusCode: StatusCodes;
  disabledClientSide?: boolean;
  disabledServerSide?: boolean;
};

export type RedirectRulesByCode = Record<string, RedirectRule>;

export type ErrorCodeRulesByPage = Record<string, RedirectRulesByCode>;

export type HandleErrorCodeOptions = {
  code: string;
  errorCodeRules?: ErrorCodeRulesByPage;
  ctx?: NextPageContext;
};

export const errorCodeRedirect = ({
  code,
  errorCodeRules,
  ctx,
}: HandleErrorCodeOptions) => {
  let currentPage;

  if (ctx) {
    currentPage = ctx.pathname;
  } else if (process.browser) {
    currentPage = Router.route;
  }

  if (currentPage) {
    const errorRulesForPage = errorCodeRules
      ? errorCodeRules[currentPage]
      : undefined;

    if (!errorRulesForPage) {
      return;
    }

    const currentErrorCode = errorRulesForPage[code];

    if (currentErrorCode) {
      isomorphicRedirect({
        ...currentErrorCode,
        ctx,
      });
    }
  }
};

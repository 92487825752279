import { useCallback, useEffect, useRef } from 'react';

export default function useTextSelectionToggle() {
  const listenerRef = useRef(null);

  const disableTextSelection = useCallback(() => {
    if (!listenerRef.current) {
      listenerRef.current = (event) => event.preventDefault();
      document.addEventListener('selectstart', listenerRef.current);
    }
  }, []);

  const enableTextSelection = useCallback(() => {
    if (listenerRef.current) {
      document.removeEventListener('selectstart', listenerRef.current);
      listenerRef.current = null;
    }
  }, []);

  useEffect(() => {
    // When unmounting, re-enable text selection.
    return () => {
      enableTextSelection();
    };
  }, [enableTextSelection]);

  return { enableTextSelection, disableTextSelection };
}

import config from 'config';

import {
  logInSuccess,
  signUpSuccess,
  updateMembershipStatusSuccess,
} from '../../../techstyle-shared/react-accounts';
import {
  createAction,
  createReducer,
  createSelector,
  createCachedSelector,
  getDateNowFunction,
  loadSessionSuccess,
  outOfDateStatus,
  parseDate,
  startLoading,
  stopLoading,
  upToDateStatus,
} from '../../../techstyle-shared/redux-core';

import logger from './logger';
import { NavSchema, NavErrorType } from './schema';

const debug = logger.extend('nav');
export const initialState = {
  containers: {},
};

export const loadNavRequest = createAction('nav/loadNavRequest');
export const loadNavSuccess = createAction('nav/loadNavSuccess');
export const loadNavFailure = createAction('nav/loadNavFailure');
export const invalidateNavs = createAction('nav/invalidateNavs');

export function loadNav(navHandles, meta = {}) {
  const compatibilityMode = config.get('public.navigation.compatibilityMode');
  const maxAge = config.get('public.navigation.maxAge');

  if (navHandles && typeof navHandles === 'string') {
    navHandles = [navHandles];
  } else if (!navHandles || !navHandles.length) {
    throw new Error(
      `loadNav must be called with at least one navigation handle`
    );
  }

  // Nav handles are returned lowercase from API so we want to
  // make sure we are handling them internally in lowercase as well
  navHandles = navHandles.map((navHandle) => navHandle.toLowerCase());

  return (dispatch, getState) => {
    const state = getState();
    const { navs, intl, i18n } = state;
    const navsToFetch = [];
    const navsFromCache = [];
    const now = getDateNowFunction(state)();
    const allSegmentIds = getAllSegmentIds(state);

    navHandles.forEach((handle) => {
      const container = navs.containers[handle];
      let shouldFetch = false;
      if (container) {
        let isExpired = false;
        if (container.fetchedDate) {
          const fetchedDate = parseDate(container.fetchedDate);
          const age = now - fetchedDate.getTime();
          isExpired = age >= maxAge;
        }
        if (container.networkStatus.isLoading) {
          // Already loading, do nothing.
        } else if (isExpired) {
          debug('Nav container “%s” is expired; refetching.', handle);
          shouldFetch = true;
        } else if (container.error) {
          // Don't attempt to fetch if there was an error last time! Only when
          // the container expires (above) will it be retried.
          debug('Nav container “%s” has error; skipping refetch.', handle);
        } else if (!container.networkStatus.isUpToDate) {
          debug('Nav container “%s” has been invalidated; refetching.', handle);
          shouldFetch = true;
        }
      } else {
        shouldFetch = true;
      }
      if (shouldFetch) {
        navsToFetch.push(handle);
      } else {
        navsFromCache.push(handle);
      }
    });

    // FIXME: remove compatibilityMode when Savage X's codebase is up to date with BMIG
    if (navsToFetch.length) {
      // FIXME: remove `meta` object when Savage X is up to date with BMIG
      const fetchedDate = new Date(now);

      return dispatch({
        [compatibilityMode === 'SX' ? 'api' : 'bentoApi']: {
          endpoint: 'navs',
          [compatibilityMode === 'SX' ? 'params' : 'searchParams']: {
            handles: navsToFetch.join(' '),
          },
          requestKey: JSON.stringify(['loadNavs', navsToFetch]),
          headers: {
            'Accept-Language': intl ? intl.locale : i18n.locale,
          },
          schema: NavSchema,
          [compatibilityMode === 'SX' ? 'types' : 'actions']:
            compatibilityMode === 'SX'
              ? [
                  {
                    type: loadNavRequest.toString(),
                    meta: {
                      fetchedDate: fetchedDate.toISOString(),
                      navsToFetch,
                    },
                  },
                  {
                    type: loadNavSuccess.toString(),
                    meta: {
                      fetchedDate: fetchedDate.toISOString(),
                      allSegmentIds,
                    },
                  },
                  {
                    type: loadNavFailure.toString(),
                    meta: {
                      fetchedDate: fetchedDate.toISOString(),
                      navsToFetch,
                    },
                  },
                ]
              : [
                  (payload, meta) =>
                    loadNavRequest(payload, {
                      ...meta,
                      navsToFetch,
                    }),
                  (payload, meta) =>
                    loadNavSuccess(payload, {
                      ...meta,
                      allSegmentIds,
                    }),
                  (payload, meta) =>
                    loadNavFailure(payload, { ...meta, navsToFetch }),
                ],
        },
      });
    }
  };
}

function invalidateSegmentedNavContainers(state, action) {
  const navHandles = Object.keys(state.containers);
  const detectSegmentation = config.get('public.navigation.detectSegmentation');

  navHandles.forEach((navHandle) => {
    if (!detectSegmentation || state.containers[navHandle].isSegmented) {
      state.containers[navHandle].networkStatus = outOfDateStatus();
    }
  });
}

export const navReducer = createReducer(initialState, {
  [invalidateNavs]: invalidateSegmentedNavContainers,
  [logInSuccess]: invalidateSegmentedNavContainers,
  [signUpSuccess]: invalidateSegmentedNavContainers,
  [loadSessionSuccess]: (state, action) => {
    if (action.meta.customerDidChange) {
      invalidateSegmentedNavContainers(state, action);
    }
  },
  [updateMembershipStatusSuccess]: invalidateSegmentedNavContainers,
  [loadNavRequest]: (state, action) => {
    action.meta.navsToFetch.forEach((navHandle) => {
      let nav = state.containers[navHandle];
      if (!nav) {
        nav = state.containers[navHandle] = {
          fetchedDate: action.meta.fetchedDate,
          networkStatus: outOfDateStatus(),
        };
      }
      startLoading(nav);
    });
  },
  [loadNavFailure]: (state, action) => {
    action.meta.navsToFetch.forEach((navHandle) => {
      const nav = state.containers[navHandle];
      if (nav) {
        // TODO: Detect different error types here?
        nav.error = NavErrorType.OTHER;
        stopLoading(nav);
      }
    });
  },
  [loadNavSuccess]: (state, action) => {
    const { fetchedDate, entities, allSegmentIds } = action.meta;

    const { NavContainer: navContainers } = entities;

    Object.keys(navContainers).forEach((navHandle) => {
      const nav = navContainers[navHandle];
      let error = null;
      // Assume it's segmented unless we have enough info to determine
      // otherwise.
      let isSegmented = true;

      if (nav) {
        if (allSegmentIds && nav.segmentationIds) {
          isSegmented = nav.segmentationIds.some(
            (id) => !allSegmentIds.has(id)
          );
        }
      } else {
        error = NavErrorType.NOT_FOUND;
        isSegmented = false;
      }

      state.containers[navHandle] = {
        data: nav,
        error,
        fetchedDate,
        isSegmented,
        networkStatus: upToDateStatus(),
      };
    });
  },
});

const getAllSegmentIds = createSelector(
  [(state) => state.domain.tld],
  (tld) => {
    const allSegmentIds = config.get('public.segmentation.allSegmentIds');
    if (allSegmentIds && allSegmentIds[tld]) {
      return new Set(allSegmentIds[tld]);
    }
  }
);

export const getNavContainer = createCachedSelector(
  [
    (state, handle) => state.navs.containers[handle.toLowerCase()],
    (state, handle) => handle,
  ],
  (nav, handle) => {
    if (nav) {
      return { handle, ...nav };
    }

    return {
      handle,
      fetchedDate: null,
      networkStatus: outOfDateStatus(),
    };
  }
)((state, handle) => handle);

export default {
  id: 'navigation',
  reducerMap: {
    navs: navReducer,
  },
  sagas: [],
  initialActions: [],
};

import { useEffect, useState } from 'react';

export default function useMounted(didMount) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (didMount && !mounted) {
      didMount();
    }
  }, [didMount, mounted]);

  return mounted;
}

import React from 'react';

import { FaPercent } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AdminPanel } from '../../../../techstyle-shared/react-admin';
import { useMarketingActions } from '../../../../techstyle-shared/react-marketing';
import {
  getNewDateFunction,
  useSessionActions,
} from '../../../../techstyle-shared/redux-core';
import { MembershipPromoType } from '../constants';
import usePromoActions from '../usePromoActions';
import usePromos from '../usePromos';

const Columns = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MainColumn = styled.div`
  flex: 1 1 auto;
  padding: 20px;
  overflow: auto;
`;

const PromosIcon = styled(FaPercent)`
  font-size: 17px;
`;

const AddAPromo = styled.div`
  margin-top: 15px;
`;

const CancelAPromo = styled.div`
  margin-top: 15px;
`;

const ForceFunnel = styled.div`
  margin-top: 15px;
`;

const PromoActions = styled.div`
  padding: 15px;
`;

const PromoForm = styled.form`
  margin: 0 0 15px 0;
`;

const ActionButton = styled(AdminPanel.Button)`
  flex: 0 0 auto;
`;

const ActionInput = styled.input`
  flex: 1 1 auto;
  border: 1px solid #bbb;
  margin: 5px 10px 5px 0;
  padding: 3px 5px;
  font-size: 13px;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1);
`;

const ActionSelect = styled.select`
  width: 100%;
  margin: 5px 0;
  height: 24px;
`;

const HStack = styled.div`
  display: flex;
  align-items: center;
`;

export default function PromosAdminPanel() {
  const data = usePromos();
  const actions = usePromoActions();
  const sessionActions = useSessionActions();
  const marketingActions = useMarketingActions();
  const getDate = useSelector(getNewDateFunction);

  const refreshPromos = () => {
    actions.loadPromos();
  };

  const cancelMemberPromo = (event) => {
    event.preventDefault();
    const form = event.target;
    const { promoCode } = form.elements;
    actions.cancelMemberPromo(promoCode.value);
  };

  const addMemberPromo = (event) => {
    event.preventDefault();
    const form = event.target;
    const { promoTypeId, promoCode } = form.elements;
    const promoData = {
      promoCode: promoCode.value,
      promoTypeId: parseInt(promoTypeId.value),
      startDate: getDate().toISOString(),
    };
    actions.addMemberPromo(promoData);
  };

  const forceFunnel = (event) => {
    event.preventDefault();
    const form = event.target;
    const { funnelPromo, funnelTheme } = form.elements;
    const promo = funnelPromo.value;
    const theme = funnelTheme.value;

    if (!promo.trim() || !theme.trim()) {
      throw new Error(
        'Please provide valid values for both the funnel promo & the funnel theme.'
      );
    }

    sessionActions.updateSessionDetail({
      name: 'origin',
      value: JSON.stringify({
        promo,
        theme,
      }),
    });

    marketingActions.loadFunnel(promo);
  };

  return (
    <AdminPanel id="promos" label="Promos" icon={PromosIcon}>
      <Columns>
        <AdminPanel.Sidebar title="Promo Actions" width={350}>
          <PromoActions>
            <AdminPanel.Button type="button" onClick={refreshPromos}>
              Load Promos
            </AdminPanel.Button>
            <AddAPromo>
              <AdminPanel.Field label="Add a promo:">
                <PromoForm name="addMemberPromo" onSubmit={addMemberPromo}>
                  <ActionSelect name="promoTypeId">
                    <option value="">Select a Membership Promo Type ID…</option>
                    <option value={MembershipPromoType.CONVERSION}>
                      (1) Conversion (LEAD, VIP Purchases)
                    </option>
                    <option value={MembershipPromoType.EARLY_CONVERSION}>
                      (2) Early Conversion (LEAD Post Reg, VIP Purchases)
                    </option>
                    <option value={MembershipPromoType.RETENTION}>
                      (3) Retention (VIP, VIP Purchases)
                    </option>
                    <option value={MembershipPromoType.SALE_VIP}>
                      (4) Sale (VIP/LEADS, VIP Purchases)
                    </option>
                    <option value={MembershipPromoType.PURCHASING}>
                      (5) Purchasing (Retail, Retail Purchases)
                    </option>
                    <option value={MembershipPromoType.SALE_ALL}>
                      (6) Sale (ALL Members, All Purchases)
                    </option>
                    <option value={MembershipPromoType.NO_SKIP_SUBSCRIBER}>
                      (7) No Skip Subscriber (SD Classic VIPS, VIP Purchase)
                    </option>
                    <option value={MembershipPromoType.SALE_AUTO_INSERT}>
                      (8) Sale Auto-Insert (CMS User List, SailThru List)
                    </option>
                    <option value={MembershipPromoType.SALE_PAYG}>
                      (9) Sale (PAYG Downgrade, VIP Purchases)
                    </option>
                    <option value={MembershipPromoType.DEV_DEFINED}>
                      (10) Any Manual-Insert (Dev Defined)
                    </option>
                  </ActionSelect>
                  <HStack>
                    <ActionInput
                      name="promoCode"
                      size={6}
                      placeholder="Promo Code"
                    />
                    <ActionButton type="submit">Add Promo</ActionButton>
                  </HStack>
                </PromoForm>
              </AdminPanel.Field>
            </AddAPromo>
            <CancelAPromo>
              <AdminPanel.Field label="Cancel a promo:">
                <PromoForm
                  name="cancelMemberPromo"
                  onSubmit={cancelMemberPromo}
                >
                  <HStack>
                    <ActionInput
                      name="promoCode"
                      size={6}
                      placeholder="Promo Code"
                    />
                    <ActionButton type="submit">Cancel Promo</ActionButton>
                  </HStack>
                </PromoForm>
              </AdminPanel.Field>
            </CancelAPromo>
            <ForceFunnel>
              <AdminPanel.Field label="Force a funnel:">
                <PromoForm name="forceFunnel" onSubmit={forceFunnel}>
                  <HStack>
                    <ActionInput
                      name="funnelPromo"
                      size={6}
                      placeholder="Funnel Promo"
                    />
                  </HStack>
                  <HStack>
                    <ActionInput
                      name="funnelTheme"
                      size={6}
                      placeholder="Funnel Theme"
                    />
                  </HStack>
                  <ActionButton type="submit"> Force Funnel </ActionButton>
                </PromoForm>
              </AdminPanel.Field>
            </ForceFunnel>
          </PromoActions>
        </AdminPanel.Sidebar>
        <MainColumn>
          <AdminPanel.DataTable title="Member Promos" data={data} />
        </MainColumn>
      </Columns>
    </AdminPanel>
  );
}

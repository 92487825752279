import React from 'react';

import PropTypes from 'prop-types';

import { LabelType } from '../LabelProvider';
import ProductListingFilter, {
  ProductListingFilterStyle,
} from '../ProductListingFilter';

function ProductCategoryFilter({
  as: ElementType = ProductListingFilter,
  filterField,
  ...rest
}) {
  return (
    <ElementType
      data-autotag="grid-category-dd"
      filterField={filterField}
      filterStyle={ProductListingFilterStyle.DEFAULT}
      labelType={LabelType.CATEGORY_FILTER}
      {...rest}
    />
  );
}

ProductCategoryFilter.propTypes = {
  as: PropTypes.elementType,
  filterField: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
};

export default ProductCategoryFilter;

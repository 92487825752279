// eslint-disable-next-line no-unused-vars
import NextRouter from 'next/router';

import { getResolveRoute } from './dynamicRoutesModule';
import logger from './logger';

const debug = logger.extend('Router');

/**
 * @type {NextRouter}
 */
const Router = {
  ...NextRouter,
  push(href, asPath, options) {
    if (options == null && typeof asPath !== 'string') {
      options = asPath;
      asPath = undefined;
    }

    const route = Router.resolve(href, asPath);

    //  avoid scroll for urls with anchor refs
    let { scroll } = options || {};
    if (scroll == null) {
      const asPath = route.asPath || route.href;
      if (typeof asPath === 'string') {
        scroll = asPath.indexOf('#') < 0;
      } else {
        scroll = Boolean(route.href.hash);
      }
    }

    return NextRouter.push(route.href, route.asPath, options).then(
      (success) => {
        if (scroll && success) {
          window.scrollTo(0, 0);
          document.body.focus();
        }

        return success;
      }
    );
  },
  replace(href, asPath, options) {
    if (options == null && typeof asPath !== 'string') {
      options = asPath;
      asPath = undefined;
    }
    const route = Router.resolve(href, asPath);
    return NextRouter.replace(route.href, route.asPath, options);
  },
  resolve(href, asPath) {
    if (process.browser && window.__NEXT_REDUX_STORE__) {
      const resolveRoute = window.__NEXT_REDUX_STORE__.dispatch(
        getResolveRoute()
      );
      const route = resolveRoute(href, asPath);
      debug('Resolved %o to: %o', href, route.href);
      return route;
    }
    return { href, asPath };
  },
};

export default Router;

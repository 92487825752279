import { useContext } from 'react';

import SectionContext from './SectionContext';

export default function useSectionLevel() {
  // Default to level 2 if there is no `<Section>` ancestor. You'll only be able
  // to render a level 1 heading if it is explicitly requested via `level` on
  // `<Section>` or `<Heading>`.
  return useContext(SectionContext) || 2;
}

import React, { useMemo } from 'react';

import config from 'config';
import { differenceInMonths } from 'date-fns/differenceInMonths';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  useMembership,
  useCustomer,
  useCustomerDetails,
  useEmailPreferences,
  useLinkedProfiles,
} from '../../../../techstyle-shared/react-accounts';
import {
  getPageRenderDate,
  parseDate,
  toPacificDateString,
  useRenderTime,
  useSession,
} from '../../../../techstyle-shared/redux-core';
import GoogleTagManagerSnippet from '../GoogleTagManagerSnippet';

const EMPTY_ARRAY = [];

export function ABTestVariables({ dataLayerName }) {
  // We could use the `getRequestedTests` selector from
  // `@techstyle/react-abtest`, but this should work even if the A/B test
  // extension isn't added, so for now we'll just deal with the raw state data
  // instead.
  const requestedTests = useSelector(
    (state) => state.abTest && state.abTest.requestedTests
  );
  if (requestedTests) {
    return Object.keys(requestedTests).map((singleCampaignCode) => {
      const test = requestedTests[singleCampaignCode];
      if (!test.data || test.data.cohortNumber == null) {
        return null;
      }

      const { campaignCodeKey, cohortNumber } = test.data;

      // `campaignCodeKey` (added in SAPI-149)might not be available
      // in other brands yet, so we can fall back to the old behavior
      // when it's not available.
      const campaignCode = campaignCodeKey ?? singleCampaignCode;
      const eventLabel = `${
        campaignCodeKey ? `${campaignCodeKey}:` : ''
      }${cohortNumber}`;

      const data = {
        event: 'trackTest',
        eventCategory: 'AB Test Key',
        eventAction: campaignCode,
        eventLabel,
      };

      return (
        <GoogleTagManagerSnippet.Push
          key={campaignCode}
          dataLayerName={dataLayerName}
          data={data}
        />
      );
    });
  }
  return null;
}

ABTestVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

export function MembershipVariables({ dataLayerName }) {
  const { isFirstTimeVisitor, isReturningVisitor } = useSession();
  const membership = useMembership();
  const {
    customerId,
    hashedUserEmail,
    isClassicVip,
    isElite,
    isLead,
    isHardCancel,
    isPassiveCancel,
    isVip,
    membershipCredits,
    membershipId,
    membershipLevelGroupId,
    availableTokenQuantity,
    networkStatus,
    persona,
    personaTagId,
    price,
    shaEmail,
  } = membership;

  const getTime = useRenderTime();
  const now = getTime(); // Update on every render so `isM2PlusVIP` is fresh.

  const dateTimeAdded = useMemo(
    () =>
      membership.dateTimeAdded
        ? parseDate(membership.dateTimeAdded)
        : undefined,
    [membership.dateTimeAdded]
  );

  const dateActivated = useMemo(
    () =>
      membership.dateActivated
        ? parseDate(membership.dateActivated)
        : undefined,
    [membership.dateActivated]
  );

  const personaValue = useMemo(
    () => ({
      label: persona || '',
      tag_id: personaTagId == null ? '' : personaTagId,
    }),
    [persona, personaTagId]
  );

  const isM2PlusVIP = useMemo(() => {
    if (!isVip) {
      return 0;
    }
    const currentDate = new Date(now);
    return dateActivated && differenceInMonths(currentDate, dateActivated) >= 1
      ? 1
      : 0;
  }, [dateActivated, isVip, now]);

  const dateActivatedString = useMemo(
    () => dateActivated && toPacificDateString(dateActivated, 'YYYYMMDD'),
    [dateActivated]
  );

  const dateTimeAddedString = useMemo(
    () => dateTimeAdded && toPacificDateString(dateTimeAdded, 'YYYYMMDD'),
    [dateTimeAdded]
  );

  let segment;
  if (isHardCancel) {
    segment = 'Hard Cancel';
  } else if (isPassiveCancel) {
    segment = 'Passive Cancel';
  } else if (isLead) {
    segment = 'Lead';
  } else if (isFirstTimeVisitor) {
    segment = 'First Time Visitor';
  } else if (isReturningVisitor) {
    segment = 'Visitor';
  } else if (isClassicVip) {
    segment = 'VIP Classic';
  } else if (isElite) {
    segment = 'VIP Elite';
  } else if (isVip) {
    segment = 'VIP';
  } else {
    segment = 'Client';
  }

  const creditValue = availableTokenQuantity || membershipCredits;

  const data = networkStatus.isUpToDate
    ? {
        customer_bucket_group: customerId ? (customerId % 20) + 1 : '',
        customer_id: customerId || '',
        hashed_user_email: hashedUserEmail || '',
        isLead: isLead ? 1 : 0,
        isM2PlusVIP: isM2PlusVIP,
        isVIP: isVip ? 1 : 0,
        membership_activation_date: dateActivatedString || '',
        membership_credits: creditValue == null ? '' : creditValue,
        membership_id: membershipId || '',
        membership_level_group_id: membershipLevelGroupId || '',
        membership_price: price == null ? '' : price,
        persona: personaValue,
        segment: segment || '',
        sha_email: shaEmail || '',
        userid: customerId || '',
        user_registered_at: dateTimeAddedString || '',
        available_token_quantity: availableTokenQuantity || '',
      }
    : null;

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

MembershipVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

export function EmailPreferencesVariables({ dataLayerName }) {
  const { networkStatus, optOutStatus } = useEmailPreferences();
  const data = networkStatus.isUpToDate
    ? { newsletter_optin: optOutStatus || '' }
    : null;

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

EmailPreferencesVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

function LinkedProfilesSizeVariables({
  dataLayerName,
  customerSize,
  profileNetworkStatus,
}) {
  const { allProfiles, networkStatus } = useLinkedProfiles();

  const childSizes = useMemo(
    () =>
      allProfiles.map(({ bottomSize, topSize }) => ({
        bottom_size: bottomSize == null ? '' : bottomSize,
        top_size: topSize == null ? '' : topSize,
      })),
    [allProfiles]
  );

  const customerSizeWithChildren = useMemo(
    () => ({ ...customerSize, child: childSizes }),
    [childSizes, customerSize]
  );

  const data =
    profileNetworkStatus.isUpToDate && networkStatus.isUpToDate
      ? { customer_size: customerSizeWithChildren }
      : null;

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

LinkedProfilesSizeVariables.propTypes = {
  customerSize: PropTypes.object,
  dataLayerName: PropTypes.string,
  profileNetworkStatus: PropTypes.object,
};

export function CustomerSizeVariables({ dataLayerName }) {
  const { networkStatus, profile } = useCustomer();
  const isLinkedProfilesEnabled = useSelector(
    (state) => state.linkedProfiles != null
  );

  const customerSize = useMemo(
    () =>
      profile && Object.keys(profile).length !== 0
        ? {
            bottom_size: profile['bottom-size'],
            bra_size: profile['bra-size'],
            bralette_size: profile['bralette-size'],
            child: EMPTY_ARRAY,
            clothing_size: profile['clothing-size'],
            dress_size: profile['dress-size'],
            shoe_size: profile['shoe-size'],
            top_size: profile['top-size'],
          }
        : {},
    [profile]
  );

  const data =
    !isLinkedProfilesEnabled && networkStatus.isUpToDate
      ? {
          customer_size: customerSize,
        }
      : null;

  return isLinkedProfilesEnabled ? (
    <LinkedProfilesSizeVariables
      dataLayerName={dataLayerName}
      customerSize={customerSize}
      profileNetworkStatus={networkStatus}
    />
  ) : (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

CustomerSizeVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

export function CustomerVariables({ dataLayerName }) {
  const pageRenderDate = useSelector(getPageRenderDate);
  const { lastLogin, sha1HashedUserEmail, networkStatus, shaPhone } =
    useCustomer();

  const differenceInDays = useMemo(() => {
    if (!lastLogin) {
      return '';
    }
    const differenceInDates = pageRenderDate - parseDate(lastLogin);
    // round down the difference in days
    // will not show 1 until 24hrs diff
    return Math.floor(differenceInDates / (1000 * 3600 * 24));
  }, [lastLogin, pageRenderDate]);

  const data = networkStatus.isUpToDate
    ? {
        days_since_last_login: differenceInDays,
        sha_phone: shaPhone,
        sha1_hashed_user_email: sha1HashedUserEmail,
      }
    : null;

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

CustomerVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

/**
 * Variables from the `gender` customer detail. This is NOT included in the
 * `GoogleTagManagerVariables` component below; instead it must be imported
 * specifically on apps that use gender, to prevent other apps from
 * automatically requesting this customer detail unnecessarily.
 */
export function GenderCustomerDetailVariables({ dataLayerName }) {
  const [gender] = useCustomerDetails(['gender']);
  const data = { gender: gender || '' };

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

GenderCustomerDetailVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

export function SessionVariables({ dataLayerName }) {
  const membershipBrandId = config.has('public.brand.id')
    ? config.get('public.brand.id')
    : null;
  const pageRenderDate = useSelector((state) => getPageRenderDate(state));
  const {
    geoCountry,
    locale,
    gender,
    customerId,
    espId,
    shaEmail,
    domainName,
    domainTld,
  } = useSelector((state) => ({
    geoCountry: state.intl.geoCountry,
    locale: state.intl.locale,
    gender: state.customerDetails?.gender?.value,
    customerId: state.customer?.id,
    espId: state.customer?.espId,
    shaEmail: state.customer?.shaEmail,
    domainName: state.domain?.name,
    domainTld: state.domain?.tld,
  }));
  const {
    dmGatewayCode,
    sessionId,
    isFirstTimeVisitor,
    isReturningVisitor,
    isLoggedIn,
    isServerOnly,
  } = useSession();
  const { storeGroupId, storeCode, storeId } = useSelector(
    (state) => state.storeGroup
  );

  // Convert page render date to string in Pacific timezone.
  const [dateString, timeString] = useMemo(
    () => toPacificDateString(pageRenderDate, 'YYYYMMDD HHmm').split(' '),
    [pageRenderDate]
  );

  const membership = useMembership();
  const { isElite, isLead, isHardCancel, isVip, networkStatus } = membership;

  let membershipStatus = '';
  if (isHardCancel) {
    membershipStatus = 'hard cancel';
  } else if (isLead) {
    membershipStatus = 'lead';
  } else if (isFirstTimeVisitor) {
    membershipStatus = 'first time visitor';
  } else if (isReturningVisitor) {
    membershipStatus = 'visitor';
  } else if (isElite) {
    membershipStatus = 'vip elite';
  } else if (isVip) {
    membershipStatus = 'vip';
  } else {
    membershipStatus = 'client';
  }
  const data = networkStatus.isUpToDate
    ? {
        category_page: false, // FL only, if page has product grid
        cookie_customer: '', // doesn't exist in react
        cookie_visitor: '', // doesn't exist in react
        country_code: geoCountry || '',
        dmg_code: dmGatewayCode || '',
        feature: 'react',
        first_visit: isFirstTimeVisitor ? 1 : 0,
        language: locale.replace('-', '_'),
        member_logged_in: isLoggedIn ? 1 : 0,
        membership_brand_id: membershipBrandId,
        membership_status:
          !isServerOnly && membershipStatus ? membershipStatus : '',
        page_load_date: dateString,
        page_load_time: timeString,
        session_id: !isServerOnly && sessionId ? sessionId : '',
        store_code: storeCode || '',
        customer_gender: gender || '',
        store_group_id: storeGroupId || '',
        store_id: storeId || '',
        store_type: 1,
        traffic_type: 'brand',
        customer_id: customerId ? customerId.toString() : null,
        esp_id: espId,
        sha_email: shaEmail,
        page_hostname: domainName?.concat(domainTld),
        // TODO: Figure out what the actual full set of values is for `visit_type`.
        visit_type: isLoggedIn ? 'Logged In' : 'Logged Out',
        // TODO: need to add /accounts/me/addresses endpoint to shared package
        zip_code: '',
      }
    : null;

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

SessionVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

export default function GoogleTagManagerVariables({ dataLayerName }) {
  return (
    <>
      <SessionVariables dataLayerName={dataLayerName} />
      <CustomerSizeVariables dataLayerName={dataLayerName} />
      <CustomerVariables dataLayerName={dataLayerName} />
      <MembershipVariables dataLayerName={dataLayerName} />
      <EmailPreferencesVariables dataLayerName={dataLayerName} />
      <ABTestVariables dataLayerName={dataLayerName} />
    </>
  );
}

GoogleTagManagerVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

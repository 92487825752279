import styled from 'styled-components';

/**
 * Component to insert text invisible to sighted users that will provide extra
 * context to screen reader users
 */
const VisuallyHidden = styled.span`
  ${({ theme }) => theme.styles.visuallyHidden};
  text-transform: none;
`;

export default VisuallyHidden;

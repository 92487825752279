import { useEffect, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Component as AssetProvider } from './AssetProvider';
import { getAssetContainer } from './assetsModule';
import useAssetActions from './useAssetActions';
import { filterAssets } from './utils';

export default function useAssets(
  names,
  {
    multi = false,
    filter: filterFromComponent,
    forceStoreGroup,
    batchKey = forceStoreGroup,
  } = {}
) {
  const actions = useAssetActions();

  // Allow passing a null `name` to conditionally fetch assets. We can't bail
  // out early though due to the rules of hooks.
  const assetContainers = useSelector((state) => {
    const filteredNames = names.filter((n) => n);
    return filteredNames && filteredNames.length > 0
      ? filteredNames.map((n) => getAssetContainer(state, n))
      : null;
  });

  const { filter: filterFromContext } = useContext(AssetProvider.Context);

  const filter = useMemo(() => {
    return filterFromComponent
      ? filterFromComponent(filterFromContext)
      : filterFromContext;
  }, [filterFromComponent, filterFromContext]);

  useEffect(() => {
    actions.loadAssets(names, {
      forceStoreGroup,
      batchKey,
    });
  });

  return useMemo(() => {
    if (assetContainers) {
      const result = assetContainers.map((assetContainer) => {
        if (!(assetContainer && assetContainer.data) || !filter) {
          return assetContainer;
        }
        return {
          ...assetContainer,
          data: {
            ...assetContainer.data,
            assets: filterAssets(assetContainer.data.assets, filter, multi),
          },
        };
      });
      return result;
    }
    return null;
  }, [assetContainers, filter, multi]);
}

import React, {
  useEffect,
  useLayoutEffect as useRealLayoutEffect,
  useMemo,
  useRef,
  useContext,
} from 'react';

import PropTypes from 'prop-types';

export const Context = React.createContext();

export const useListViewed = () => {
  return useContext(Context);
};

if (process.browser) {
  require('intersection-observer');
}

const useLayoutEffect = process.browser ? useRealLayoutEffect : useEffect;

const ListViewedProvider = React.memo(function ListViewedProvider({
  children,
  root,
  rootMargin = '0px 0px 0px 0px',
  id,
  threshold,
  onItemViewed,
}) {
  const observer = useRef();
  const itemsMap = useRef();
  if (itemsMap.current == null) {
    itemsMap.current = new Map();
  }

  useLayoutEffect(() => {
    let disconnecting = false;

    const handleChange = (entries) => {
      if (disconnecting) {
        return;
      }
      entries.forEach((entry) => {
        const isIntersecting =
          entry?.isIntersecting && entry.intersectionRatio > 0;
        if (isIntersecting) {
          const itemInfo = itemsMap.current.get(entry.target);
          if (itemInfo) {
            if (onItemViewed && !itemInfo.isViewed) {
              const itemInfoViewed = {
                ...itemInfo,
                isViewed: true,
              };
              itemsMap.current.set(entry.target, itemInfoViewed);
              onItemViewed({ itemInfo: itemInfoViewed, id, entry });
            }
          }
        }
      });
    };

    observer.current = new IntersectionObserver(handleChange, {
      root,
      rootMargin,
      threshold,
    });

    return () => {
      disconnecting = true;
      observer.current.disconnect();
    };
  }, [id, root, rootMargin, threshold, onItemViewed]);

  const context = useMemo(() => {
    return {
      observe(node, itemInfo) {
        itemsMap.current.set(node, itemInfo);
        return observer.current.observe(node);
      },
      unobserve(node) {
        itemsMap.current.delete(node);
        return observer.current.unobserve(node);
      },
    };
  }, []);

  return <Context.Provider value={context}>{children}</Context.Provider>;
});

ListViewedProvider.propTypes = {
  /**
   * The `action` option to dispatch when an item is in view
   */
  children: PropTypes.node,
  id: PropTypes.string,
  /**
   * The `onItemViewed` option is a callback to be triggered once the item is on view.
   */
  onItemViewed: PropTypes.func.isRequired,
  /**
   * The `root` option to pass to the IntersectionObserver. It must be a DOM
   * node, not a ref (so that updating it triggers a re-render). If not passed,
   * the browser viewport is used.
   */
  root: PropTypes.object,
  /**
   * The `rootMargin` option to pass to the IntersectionObserver. It can be
   * used to extend or contract the area that triggers what gets set as seen.
   */
  rootMargin: PropTypes.string,
  /**
   * The `threshold` option to pass to the IntersectionObserver. An array of
   * values (between 0 and 1.0). Represents distance needed to cover to trigger callback.
   */
  threshold: PropTypes.array,
};

ListViewedProvider.Context = Context;

const ListViewedProviderEntry = ({ children, data }) => {
  const { observe, unobserve } = useListViewed();
  const ref = useRef();

  useEffect(() => {
    const node = ref.current;
    if (node && data) {
      observe(node, data);
    }
    return () => unobserve(node);
  }, [observe, unobserve, data]);

  return <div ref={ref}>{children}</div>;
};

ListViewedProviderEntry.propTypes = {
  children: PropTypes.node,
  data: PropTypes.any,
};

ListViewedProvider.Entry = ListViewedProviderEntry;

export default ListViewedProvider;

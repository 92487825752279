import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getPromos } from './promosModule';
import usePromoActions from './usePromoActions';

export default function usePromos() {
  const promos = useSelector(getPromos);

  const { networkStatus } = promos;
  const { loadPromos } = usePromoActions();

  useEffect(() => {
    if (!networkStatus.isUpToDate && !networkStatus.isLoading) {
      loadPromos();
    }
  }, [loadPromos, networkStatus.isUpToDate, networkStatus.isLoading]);

  return promos;
}

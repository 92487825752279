import config from 'config';
import { normalize, denormalize, Schema } from 'normalizr';
import { AnyAction, Dispatch, Middleware } from 'redux';

import { loadSession, VisitorStatus } from './bentoApiModule';
import createAction, { Action, ActionCreator } from './createAction';
import { HTTPError } from './fetch';
import logger from './logger';
import { getNewDateFunction, isTimeTravelActive } from './timeModule';
import { getHeaderValuesByKeys, errorResponseHeadersToTrack } from './utils';

const debug = logger.extend('bentoApiReduxMiddleware');

export const BENTO_API = 'bentoApi';

export type BentoApiAction<Payload = any, Meta = any> = {
  type: string;
  [BENTO_API]: BentoApiCall<Payload, Meta>;
  payload?: any;
};

// TODO: Separate into Request, Success, Failure?
type ActionTypes<Payload = any, Meta = any> =
  | ActionCreator<Payload, Meta>
  | ((payload: Payload, meta: Meta) => Action<Payload, Meta>);

export type BentoApiCall<Payload = any, Meta = any> = {
  endpoint: string;
  type?: string;
  sessionRequired?: boolean;
  loginRequired?: boolean;
  requestKey: string;
  searchParams?: any;
  schema?: Schema;
  actions: [
    ActionTypes<Payload, Meta>,
    ActionTypes<Payload, Meta>,
    ActionTypes<Payload, Meta>
  ];
  method?: 'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS';
  headers?: HeadersInit;
  failureRate?: number;
  failureType?: string;
};

type ValidAction<Payload = never, Meta = never> = {
  type: string | symbol;
  error?: false;
} & ([Payload] extends [never] ? {} : { payload: Payload }) & // Can't do it with just `Payload extends never`. // The `[Payload] extends [never]` is required to check if generic type is never.
  ([Meta] extends [never] ? {} : { meta: Meta });

export type BentoApiResultAction<Payload = never, Meta = never> = ValidAction<
  Payload,
  Meta
>;

export class BentoApiError extends Error {
  name: string;
  action: any;
  originalError: any;
  statusCode?: any;
  customData?: any;

  constructor(action: any) {
    super(action.payload.toString());

    const { payload } = action;
    this.name = 'BentoApiError';
    this.action = action;
    this.originalError = payload;
    this.statusCode =
      payload && payload.response ? payload.response.status : undefined;

    if (payload.response) {
      const { endpoint, fetchOptions, responseBody } = action?.meta || {};
      const headerValues = getHeaderValuesByKeys(
        payload.response.headers,
        errorResponseHeadersToTrack
      );

      // Validate that this is a T1 Error via response headers
      const isTier1Request =
        Object.keys(headerValues).includes('x-tfg-tier1-server');

      if (isTier1Request) {
        this.customData = {
          ...headerValues,
          'x-tier1-endpoint': endpoint,
          'x-tier1-fetch-options': JSON.stringify(fetchOptions),
          'x-tier1-message': responseBody?.message,
          'x-tier1-node-version': responseBody?.error?.process?.version,
          'x-tier1-stack':
            responseBody?.error?.stack?.join('\n') ||
            JSON.stringify(responseBody?.error?.stack),
        };
      }
    }
  }
}

export function toActionCreator(typeDescriptor: any) {
  switch (typeof typeDescriptor) {
    case 'function':
      return typeDescriptor;
    case 'object':
      return (payload: any, meta: any) => ({
        payload,
        meta,
        ...typeDescriptor,
      });
    default:
      return createAction(typeDescriptor);
  }
}

async function handleBentoAction(
  store: any,
  next: Dispatch<AnyAction>,
  action: BentoApiAction,
  context: any
) {
  const client = context[BENTO_API];

  const {
    endpoint,
    sessionRequired = true,
    loginRequired = endpoint.startsWith('accounts/me/'),
    requestKey,
    schema,
    actions,
    ...fetchOptions
  } = action[BENTO_API];

  if (!endpoint) {
    throw new Error(`Missing 'endpoint' option in 'bentoApi'.`);
  }

  if (!actions) {
    throw new Error(`Missing 'actions' option in 'bentoApi'.`);
  }

  const [createRequest, createSuccess, createFailure] =
    actions.map(toActionCreator);

  if (sessionRequired) {
    const sessionResult = await store.dispatch(loadSession());
    if (sessionResult && sessionResult.error) {
      throw sessionResult.payload;
    }
  }

  if (loginRequired) {
    const { session } = store.getState();
    if (session.visitorStatus !== VisitorStatus.LOGGED_IN) {
      debug('Skipping “%s” request due to visitor status.', endpoint);
      return;
    }
  }

  const executeRequest = async () => {
    const state = store.getState();
    const newDate = getNewDateFunction(state);
    const fetchedDate = newDate();

    let meta = {
      endpoint,
      sessionRequired,
      requestKey,
      schema,
      fetchOptions,
      fetchedDate: fetchedDate.toISOString(),
      isServer: context.isServer,
    } as any;

    const requestAction = createRequest(null, meta);
    await store.dispatch(requestAction);

    const { contentPreview, requestFailureRate, requestFailureType } =
      state[BENTO_API];

    if (requestFailureRate) {
      fetchOptions.failureRate = requestFailureRate;
    }
    if (requestFailureType) {
      fetchOptions.failureType = requestFailureType;
    }

    // Time Travel
    if (isTimeTravelActive(state)) {
      fetchOptions.headers = {
        'x-tfg-effective-date': fetchedDate.toISOString(),
        ...fetchOptions.headers,
      };
    }

    // tos_token security management to allow admin sessions to work across builder.io and other tabs
    // Tells API to loosen SameSite security setting for tos_token session cookie when responding with updated/new session cookie.
    const { session } = state;
    const isAdmin = Boolean(session.isAdmin);
    if (isAdmin || contentPreview) {
      fetchOptions.headers = {
        'x-tfg-content-preview': 'true',
        ...fetchOptions.headers,
      };
    }

    let resultAction;

    try {
      const response = await client(endpoint, fetchOptions);
      let payload = null;
      if (response.status !== 204) {
        payload = await response.json();
        if (schema) {
          const { result, entities } = normalize(payload, schema);
          meta = { ...meta, result, entities };
          payload = denormalize(result, schema, entities);
        }
      }
      resultAction = createSuccess(payload, meta);
    } catch (err) {
      if (!(err instanceof Error)) {
        throw err;
      }

      if (
        typeof window !== 'undefined' &&
        err.name === 'TypeError' &&
        err.message === 'cancelled'
      ) {
        // The request was cancelled, most likely due to the location changing.
        // Not worth dispatching an error. Let's just never resolve?
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {});
      }

      let responseBody;
      if (err instanceof HTTPError) {
        if (err.response) {
          // Preserve original response body for deeper error logging
          const clonedResponse = err.response.clone();
          const text = await clonedResponse.text();
          try {
            responseBody = text ? JSON.parse(text) : undefined;
          } catch (e) {
            responseBody = undefined;
          }
        }
      }

      resultAction = createFailure(
        err,
        responseBody ? { ...meta, responseBody } : meta
      );

      // Just like we return the result of `store.dispatch(resultAction)` below
      // instead of `resultAction` directly, we should be consistent and get the
      // final dispatched result of the failure action, too. This allows other
      // middleware to make further decisions on the action.
      const finalAction = await store.dispatch(resultAction);
      // Maybe some other middleware handled the failure in a graceful way?
      // Make sure it's still an error action.

      if (finalAction.error) {
        if (!context.isServer) {
          const isUnauthorized = finalAction.payload?.response?.status === 401;
          const reloadUnauthorized = config.get(
            'public.bentoApi.reloadUnauthorized'
          );
          if (isUnauthorized && reloadUnauthorized) {
            const url = new URL(window.location.href);
            // Detect if we've already reloaded; don't want to cause a loop.
            const isForcedReloadPage =
              url.searchParams.get('reload') === 'true';

            if (!isForcedReloadPage) {
              url.searchParams.set('reload', 'true');
              window.location.href = url.toString();
            }
          }
        }

        throw new BentoApiError(finalAction);
      } else {
        // Well, we already dispatched `resultAction`. No need to dispatch it
        // again below.
        return finalAction;
      }
    }

    return store.dispatch(resultAction);
  };

  let promise;

  if (requestKey) {
    promise = context.inflight.get(requestKey);
    if (promise) {
      debug(
        'Skipping “%s” request due to matching inflight key “%s”.',
        endpoint,
        requestKey
      );
      return promise;
    }
  }

  promise = executeRequest();

  if (requestKey) {
    const onSuccess = (result: any) => {
      context.inflight.delete(requestKey);
      return result;
    };
    const onError = (err: any) => {
      context.inflight.delete(requestKey);
      throw err;
    };
    promise = promise.then(onSuccess, onError);
    context.inflight.set(requestKey, promise);
  }

  return promise;
}

export function createBentoAction<Payload = unknown, Meta = unknown>(
  clientCall: BentoApiCall<Payload, Meta>
): BentoApiAction<Payload, Meta> {
  return {
    type: 'BENTO_API',
    [BENTO_API]: clientCall,
  };
}

export default function createBentoApiReduxMiddleware(
  context: any
): Middleware<{
  <Payload, Meta>(action: BentoApiAction<Payload, Meta>): Promise<
    BentoApiResultAction<Payload, Meta>
  >;
  // `Promise<undefined> is returned in case of RSAA validation errors or user bails out
  (action: BentoApiAction): Promise<undefined>;
}> {
  return (store) => (next) => (action) => {
    if ((!action.type && action[BENTO_API]) || action.type === 'BENTO_API') {
      return handleBentoAction(store, next, action, context);
    }
    return next(action);
  };
}

import React, { useCallback, useMemo, useState } from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { MdCallSplit } from 'react-icons/md';
import { TiWarning } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { AdminPanel } from '../../../../techstyle-shared/react-admin';
import { getTestsUsedOnPage } from '../abTestModule';
import useABTestActions from '../useABTestActions';
import useActivatedABTests from '../useActivatedABTests';
import useAllABTests from '../useAllABTests';
import useRequestedABTests from '../useRequestedABTests';

const Columns = styled.div`
  display: flex;
  height: 100%;
`;

const MainColumn = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;

const Faded = styled.span`
  opacity: 0.3;
`;

const InlineHint = styled.span`
  margin-left: 0.25em;
  opacity: 0.7;
`;

const WarningIcon = styled(TiWarning)`
  vertical-align: -3px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};
`;

const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.errorText};
`;

const Actions = styled.div`
  padding: 12px;

  form {
    display: flex;
    align-items: center;
    margin: 10px 0;

    > :not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const StatusFlag = styled.span`
  display: inline-block;
  border-radius: 3px;
  padding: 4px 8px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  background: ${({ color = '#aaa' }) => color};
  color: #fff;
`;

const ActionButton = styled(AdminPanel.Button)`
  flex: 0 0 auto;
`;

const ActionInput = styled.input`
  width: 100%;
  border: 1px solid #bbb;
  padding: 3px 5px;
  font-size: 13px;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1);
`;

const InlineLoadingIndicator = styled(AdminPanel.SpinnerLoadingIndicator).attrs(
  {
    innerRadius: 3,
    spokeCount: 9,
    spokeLength: 4,
  }
)`
  margin-left: 0.25em;
  margin-right: 0.25em;
  vertical-align: -0.25em;
`;

function ActivateVariantControls({ test }) {
  const { campaignCode } = test;
  const actions = useABTestActions();

  const handleForceControl = () => {
    actions.forceTestCohort(campaignCode, { cohortNumber: 1 });
  };

  const handleForceVariant = () => {
    actions.forceTestCohort(campaignCode, { cohortNumber: 2 });
  };

  const handleSplit = () => {
    actions.splitTest(campaignCode, {
      explain: true,
      forceRequest: true,
      usedOnPage: false,
    });
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const form = event.target;
      const cohortNumber = parseInt(form.elements.cohortNumber.value, 10);
      if (!Number.isNaN(cohortNumber)) {
        actions.forceTestCohort(campaignCode, {
          cohortNumber,
          explain: true,
          expireCache: true,
        });
      }
    },
    [actions, campaignCode]
  );

  return (
    <>
      <div
        css={`
          display: flex;
          align-items: center;
          > * {
            margin-right: 8px;
          }
        `}
      >
        <ActionButton
          type="button"
          color="#d85a39"
          onClick={handleForceControl}
        >
          Control
        </ActionButton>
        <ActionButton
          type="button"
          onClick={handleForceVariant}
          color="#09b65c"
        >
          Variant
        </ActionButton>
        <ActionButton type="button" onClick={handleSplit}>
          Auto
        </ActionButton>
      </div>
      <form
        onSubmit={handleSubmit}
        css={`
          display: flex;
          align-items: center;
          margin-top: 8px;
        `}
      >
        <ActionInput
          name="cohortNumber"
          placeholder="Cohort #"
          size={7}
          css={`
            width: auto;
            margin-right: 5px;
            text-align: right;
          `}
        />
        <ActionButton type="submit">Force Cohort</ActionButton>
      </form>
    </>
  );
}

ActivateVariantControls.propTypes = {
  test: PropTypes.object,
};

const Title = styled.h1`
  grid-column: 1 / -1;
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
`;

const Details = styled.details`
  grid-column: 1 / -1;
`;

const Summary = styled.summary`
  margin: 15px 0 5px 0;
  padding: 5px 0;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  color: rgb(93, 87, 102);
  opacity: 0.8;
  cursor: pointer;
`;

const HelpText = styled.div`
  border-radius: 8px;
  margin: 10px auto 0 auto;
  padding: 10px 15px;
  max-width: 52em;
  background: rgba(255, 255, 255, 0.5);
`;

const emptyDash = <Faded>&mdash;</Faded>;

function TestDetails({
  isLoadingTestConfig,
  onToggleTestConfig,
  test,
  testConfig,
  testConfigFetchedDate,
}) {
  const forceAllControl = useSelector((state) => state.abTest.forceAllControl);

  const isUsedOnPage = useSelector((state) =>
    state.abTest.testsUsedOnPage.includes(test.campaignCode)
  );

  const renderCohort = () => {
    const { cohortNumber, group } = test.data || {};
    if (forceAllControl) {
      return (
        <>
          1 <InlineHint>(via local “force all to control” override)</InlineHint>
        </>
      );
    }
    if (cohortNumber != null) {
      return cohortNumber;
    }
    if (group > 1) {
      return (
        <>
          <WarningIcon />{' '}
          <ErrorText>
            Missing cohort, cannot determine activation status.
          </ErrorText>
        </>
      );
    }
    return emptyDash;
  };

  const renderGroup = () => {
    const { group } = test.data || {};
    if (forceAllControl) {
      return (
        <>
          1 <InlineHint>(via local “force all to control” override)</InlineHint>
        </>
      );
    }
    if (group != null) {
      return group;
    }
    return emptyDash;
  };

  const renderExplanation = () => {
    if (test.data && test.data.explanation) {
      // FIXME: It's odd that the API returns a success response and fake data with
      // this explanation rather than an error code.
      if (test.data.explanation === 'Test does not exist.') {
        return (
          <>
            <WarningIcon /> <ErrorText>{test.data.explanation}</ErrorText>
          </>
        );
      }
      return test.data.explanation;
    }
    return emptyDash;
  };

  const renderStatus = () => {
    const { enabled, group, cohortNumber } = test.data || {};

    if (forceAllControl) {
      return <StatusFlag color="#e0724f">Control</StatusFlag>;
    }
    if (enabled === false) {
      return <StatusFlag>Test Disabled</StatusFlag>;
    }
    if (group === 0) {
      return <StatusFlag>Excluded From Test</StatusFlag>;
    }
    if (cohortNumber === 1 || group === 1) {
      return <StatusFlag color="#e0724f">Control</StatusFlag>;
    }
    if (cohortNumber != null) {
      return (
        <StatusFlag color="#5fbf8b">Variant {cohortNumber - 1}</StatusFlag>
      );
    }
    return null;
  };

  const renderTestConfig = () => {
    if (testConfig) {
      return <AdminPanel.DataTable data={testConfig} sortKeys />;
    }
    if (isLoadingTestConfig) {
      return <InlineLoadingIndicator />;
    }
    if (testConfigFetchedDate) {
      return (
        <>
          <WarningIcon /> <ErrorText>Test does not exist.</ErrorText>
        </>
      );
    }
    return emptyDash;
  };

  return (
    <AdminPanel.Fields
      rowGap={4}
      style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}
    >
      <Title>
        {test.campaignCode} {renderStatus()}
      </Title>
      <AdminPanel.Field label="Cohort:">{renderCohort()}</AdminPanel.Field>
      <AdminPanel.Field label="Group:">{renderGroup()}</AdminPanel.Field>
      <AdminPanel.Field label="Explanation:">
        {renderExplanation()}
      </AdminPanel.Field>
      <AdminPanel.Field label="Fetched:">
        {test.fetchedDate ? (
          <AdminPanel.DateTime value={test.fetchedDate} />
        ) : (
          emptyDash
        )}
      </AdminPanel.Field>
      <AdminPanel.Field label="Used on this page:">
        {isUsedOnPage ? 'Yes' : 'No'}
      </AdminPanel.Field>
      <AdminPanel.Field label="Activate:">
        <ActivateVariantControls test={test} />
      </AdminPanel.Field>
      <Details>
        <Summary onClick={onToggleTestConfig}>Test Configuration</Summary>
        <>
          <HelpText>
            <p>
              This is the full test configuration from CMS. None of the fields
              below (including{' '}
              <AdminPanel.InlineCode>activated</AdminPanel.InlineCode>,{' '}
              <AdminPanel.InlineCode>group</AdminPanel.InlineCode>, etc.)
              represent the test status for the current user or session &ndash;
              only how the test is defined.
            </p>
          </HelpText>
          <AdminPanel.Fields>
            <AdminPanel.Field label="Fetched:">
              {testConfigFetchedDate ? (
                <AdminPanel.DateTime value={testConfigFetchedDate} />
              ) : (
                <Faded>&mdash;</Faded>
              )}
            </AdminPanel.Field>
            <AdminPanel.Field label="Data:">
              {renderTestConfig()}
            </AdminPanel.Field>
          </AdminPanel.Fields>
        </>
      </Details>
    </AdminPanel.Fields>
  );
}

TestDetails.propTypes = {
  isLoadingTestConfig: PropTypes.bool,
  onToggleTestConfig: PropTypes.func,
  test: PropTypes.object,
  testConfig: PropTypes.object,
  testConfigFetchedDate: PropTypes.string,
};

const TestFilter = {
  PAGE: 'page',
  REQUESTED: 'requested',
  USER: 'user',
  ALL: 'all',
};

export function TestBrowser() {
  const [testFilter, setTestFilter] = useState(TestFilter.PAGE);
  // Test data from the "all tests" endpoint is the test configuration; it
  // doesn't tell us about the user's status but merely how the test is
  // configured in CMS. We only need to fetch this if the user wants to view
  // that info, which isn't shown by default.
  const [needTestConfig, setNeedTestConfig] = useState(false);

  const {
    allTests,
    testConfigByCampaignCode,
    fetchedDate: testConfigFetchedDate,
    isLoading: isLoadingAllTests,
    // We only need to fetch all tests if the user wants to show test config, or
    // if the `ALL` filter is selected.
  } = useAllABTests(needTestConfig || testFilter === TestFilter.ALL);

  const {
    userTests,
    userTestsByCampaignCode,
    isLoading: isLoadingUserTests,
  } = useActivatedABTests(
    // In addition to the `USER` filter, we also need to fetch this info if
    // `ALL` is selected, because there may be tests in the list that we
    // haven't requested (in `requestedTests`) yet. So, in order to show the
    // user's status for those tests, we'd need to load it from the "activated"
    // endpoint.
    testFilter === TestFilter.USER || testFilter === TestFilter.ALL
  );

  const { requestedTests, requestedTestsByCampaignCode } =
    useRequestedABTests();
  const testsUsedOnPage = useSelector(getTestsUsedOnPage);

  const { tests } = useMemo(() => {
    switch (testFilter) {
      case TestFilter.PAGE:
        return { tests: testsUsedOnPage, isLoading: false };
      case TestFilter.REQUESTED:
        return { tests: requestedTests, isLoading: false };
      case TestFilter.USER:
        // If the tests are coming from this endpoint, we don't have as much
        // info as if they were requested via `split`. We also want to show the
        // test status from Redux and not straight from the endpoint, because
        // that's where the `useAbTest` hook and `ABTest` component get their
        // data from. So, prefer the data from `requestedTests` if available.
        return {
          tests: userTests.map(
            (test) => requestedTestsByCampaignCode[test.campaignCode] || test
          ),
          isLoading: isLoadingUserTests,
        };
      case TestFilter.ALL:
        // If the tests are coming from this endpoint, we only have the list of
        // tests and the test configuration, but not the test status for the
        // current user/session. So, if available, replace the tests from the
        // `allTests` array with the data from `requestedTests` or `userTests`,
        // depending on which is available. `requestedTests` is preferable since
        // that is the data the `useABTest` hook and `ABTest` component use, so
        // the info shown in the admin panel will be in sync with those.
        // (Otherwise, it would be possible for the `split` result at time T to
        // be different from the `activated` result at time T+1, and it would
        // be confusing to show a status in the admin panel that differs from
        // what the components are doing.)
        return {
          tests: allTests.map(
            (test) =>
              requestedTestsByCampaignCode[test.campaignCode] ||
              userTestsByCampaignCode[test.campaignCode] ||
              test
          ),
          isLoading: isLoadingAllTests,
        };
    }
  }, [
    allTests,
    isLoadingAllTests,
    isLoadingUserTests,
    requestedTests,
    requestedTestsByCampaignCode,
    testFilter,
    testsUsedOnPage,
    userTests,
    userTestsByCampaignCode,
  ]);

  const sortedTests = useMemo(
    () => orderBy(tests, [(test) => test.campaignCode.toLowerCase()]),
    [tests]
  );

  const handleChangeTestFilter = (event) => {
    setTestFilter(event.target.value);
  };

  const isLoading =
    (testFilter === TestFilter.USER && isLoadingUserTests) ||
    (testFilter === TestFilter.ALL && isLoadingAllTests);

  return (
    <>
      <AdminPanel.Fields>
        <AdminPanel.Field label="Filter:">
          <AdminPanel.Fields columnGap={6} rowGap={4} style={{ padding: 0 }}>
            <AdminPanel.Field
              type="checkbox"
              label={
                <label htmlFor="adminTestFilterPage">
                  Tests used on current page
                </label>
              }
            >
              <input
                type="radio"
                name="adminTestFilter"
                id="adminTestFilterPage"
                value={TestFilter.PAGE}
                checked={testFilter === TestFilter.PAGE}
                onChange={handleChangeTestFilter}
              />
            </AdminPanel.Field>
            <AdminPanel.Field
              type="checkbox"
              label={
                <label htmlFor="adminTestFilterRequested">
                  Tests used on any page (since last reload)
                </label>
              }
            >
              <input
                type="radio"
                name="adminTestFilter"
                id="adminTestFilterRequested"
                value={TestFilter.REQUESTED}
                checked={testFilter === TestFilter.REQUESTED}
                onChange={handleChangeTestFilter}
              />
            </AdminPanel.Field>
            <AdminPanel.Field
              type="checkbox"
              label={
                <label htmlFor="adminTestFilterUser">
                  Tests this user or session is in (including control group)
                </label>
              }
            >
              <input
                type="radio"
                name="adminTestFilter"
                id="adminTestFilterUser"
                value={TestFilter.USER}
                checked={testFilter === TestFilter.USER}
                onChange={handleChangeTestFilter}
              />
            </AdminPanel.Field>
            <AdminPanel.Field
              type="checkbox"
              label={<label htmlFor="adminTestFilterAll">All tests</label>}
            >
              <input
                type="radio"
                name="adminTestFilter"
                id="adminTestFilterAll"
                value={TestFilter.ALL}
                checked={testFilter === TestFilter.ALL}
                onChange={handleChangeTestFilter}
              />
            </AdminPanel.Field>
          </AdminPanel.Fields>
        </AdminPanel.Field>
        <AdminPanel.Field label="Results:">
          {isLoading ? (
            <InlineLoadingIndicator />
          ) : (
            `${sortedTests.length} matching ${
              sortedTests.length === 1 ? 'test' : 'tests'
            }`
          )}
        </AdminPanel.Field>
      </AdminPanel.Fields>
      <ul>
        {sortedTests.map((test) => (
          <li key={test.campaignCode}>
            <TestDetails
              test={test}
              testConfig={testConfigByCampaignCode[test.campaignCode]}
              testConfigFetchedDate={testConfigFetchedDate}
              onToggleTestConfig={() => setNeedTestConfig(true)}
              isLoadingTestConfig={isLoadingAllTests}
            />
          </li>
        ))}
      </ul>
    </>
  );
}

export default function ABTestAdminPanel() {
  const actions = useABTestActions();

  const forceAllControlEnabled = useSelector(
    (state) => state.abTest.forceAllControl
  );

  const splitTest = (event) => {
    event.preventDefault();
    const form = event.target;
    const campaignCode = form.elements.campaignCode.value.trim();
    if (campaignCode) {
      actions.splitTest(campaignCode, {
        explain: true,
        forceRequest: true,
        usedOnPage: false,
      });
    }
  };

  return (
    <AdminPanel id="abtest" label="A/B Tests" icon={MdCallSplit}>
      <Columns>
        <AdminPanel.Sidebar title="A/B Test Actions" width={240}>
          <Actions>
            <AdminPanel.Fields style={{ padding: 0 }}>
              <AdminPanel.Field
                type="checkbox"
                label={
                  <label htmlFor="adminForceAllControl">
                    Force all to control
                  </label>
                }
              >
                <input
                  type="checkbox"
                  checked={forceAllControlEnabled}
                  onChange={(e) =>
                    actions.setForceAllTestsControl(e.target.checked)
                  }
                  id="adminForceAllControl"
                />
              </AdminPanel.Field>
            </AdminPanel.Fields>
            <form name="splitTest" onSubmit={splitTest}>
              <ActionInput
                name="campaignCode"
                size={6}
                placeholder="Campaign Code"
              />
              <ActionButton type="submit">Activate</ActionButton>
            </form>
          </Actions>
        </AdminPanel.Sidebar>

        <MainColumn>
          <TestBrowser />
        </MainColumn>
      </Columns>
    </AdminPanel>
  );
}

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { FaStar, FaRegStar } from 'react-icons/fa';
import styled from 'styled-components';

import {
  useIntl,
  defineMessages,
} from '../../../../techstyle-shared/react-intl';

// Accesibility with star ratings based on
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Role_Img Star Rating Role example

const ariaLabel = defineMessages({
  reviewRating: {
    id: 'review_rating.star_rating',
    defaultMessage: 'Rating: {rating} out of {maxRating}',
  },
  noRating: {
    id: 'review_rating.no_rating',
    defaultMessage: 'Rating: Not rated',
  },
});

const PartialRating = styled.span`
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  ${({ percent }) => percent && `width: ${percent}%`};
`;

const IconWrapper = styled.span.attrs({ 'aria-hidden': true })`
  position: relative;
  display: inline-flex;
  margin-right: 1px;
`;

const Wrapper = styled.span`
  display: inline-flex;
`;

export const ReviewStars = React.forwardRef(
  (
    {
      roundToDecimal = 1,
      emptyIcon = <FaRegStar />,
      fullIcon = <FaStar />,
      rating,
      ratingAriaLabel,
      maxRating = 5,
      ...rest
    },
    ref
  ) => {
    // Check to make sure rating isn't larger than total possible
    if (rating > maxRating) {
      rating = maxRating;
    }

    const intl = useIntl();

    const roundedRating = useMemo(() => {
      if (rating == null) {
        return null;
      }
      if (roundToDecimal == null) {
        return rating;
      }
      return parseFloat(rating.toFixed(roundToDecimal));
    }, [rating, roundToDecimal]);

    const stars = useMemo(() => {
      const emptyStar = (
        <IconWrapper data-autotag="grid-product-rating-star-empty">
          {emptyIcon}
        </IconWrapper>
      );
      const fullStar = (
        <IconWrapper data-autotag="grid-product-rating-star-filled-percent-100">
          {fullIcon}
        </IconWrapper>
      );

      const allStars = new Array(maxRating).fill(emptyStar);

      if (roundedRating == null) {
        return allStars; // return array of empty stars
      }

      for (let i = 0; i < maxRating; i++) {
        const wholeNumber = Math.floor(roundedRating);
        const fraction = roundedRating % 1;
        if (i < wholeNumber) {
          allStars[i] = fullStar;
        } else if (i === wholeNumber && fraction !== 0) {
          // Fractional Rating
          const percent = Math.round(fraction * 100);
          // Rounded to keep from getting huge decimal places
          allStars[i] = (
            <IconWrapper
              data-autotag={`grid-product-rating-star-filled-percent-${percent}`}
            >
              {emptyIcon}
              <PartialRating percent={percent}>{fullIcon}</PartialRating>
            </IconWrapper>
          );
        }
      }
      return allStars;
    }, [emptyIcon, fullIcon, maxRating, roundedRating]);

    if (typeof ratingAriaLabel === 'function') {
      ratingAriaLabel = ratingAriaLabel({ rating: roundedRating, maxRating });
    }
    if (typeof ratingAriaLabel === 'undefined') {
      ratingAriaLabel =
        roundedRating == null
          ? intl.formatMessage(ariaLabel.noRating)
          : intl.formatMessage(ariaLabel.reviewRating, {
              rating: roundedRating,
              maxRating: maxRating,
            });
    }

    // To add a pause when reading the aria-label among other text, always add
    // a comma.
    if (typeof ratingAriaLabel === 'string') {
      ratingAriaLabel += ',';
    }

    return (
      <Wrapper aria-label={ratingAriaLabel} role="img" ref={ref} {...rest}>
        {stars.map((star, index) => React.cloneElement(star, { key: index }))}
      </Wrapper>
    );
  }
);

ReviewStars.displayName = 'ReviewStars';

ReviewStars.propTypes = {
  /**
   * Empty icon for ratings less than total.
   */
  emptyIcon: PropTypes.node,
  /**
   * Full icon to indicate filled rating.
   */
  fullIcon: PropTypes.node,
  /**
   * Total possible rating.
   */
  maxRating: PropTypes.number,
  /**
   * Rating to show in stars. If null or undefined, it will render only empty icons.
   */
  rating: PropTypes.number,
  /**
   * aria-label to pass to Ratings. Used if you need to specify a message
   * different than the default rating aria-label. Can be a string or function
   * for use with FormattedMessage.
   */
  ratingAriaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * Decimal point to round to.
   */
  roundToDecimal: PropTypes.number,
};

export default ReviewStars;

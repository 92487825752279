import { schema } from '../../../techstyle-shared/redux-core';

export const CartDiscount = new schema.Entity(
  'CartDiscount',
  {},
  {
    idAttribute: 'cartDiscountId',
  }
);

export const LineItem = new schema.Entity(
  'CartLineItem',
  {},
  {
    idAttribute: 'lineId',
  }
);

export const Cart = new schema.Object('Cart', {
  discounts: [CartDiscount],
  items: [LineItem],
});

// Since Cart is not an entity, need to run this in the success action creator.
export function convertEmptyStringsToNull(obj) {
  const newObj = { ...obj };
  for (const key in newObj) {
    if (newObj[key] === '') {
      newObj[key] = null;
    }
  }
  return newObj;
}

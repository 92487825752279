import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.fill || props.color || '#1E2548'};
  vertical-align: top;
`;

const BrunoMarcLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 255 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={titleId}>Bruno Marc</title>
      <path
        data-letter="B"
        d="M0 23.6859V22.8998C0.425243 22.8998 2.07306 22.8998 2.23252 21.6422V2.30598C2.12622 1.15315 0.903644 0.99595 0.212623 0.99595H0V0.262314H15.7872C18.9764 0.262314 21.5811 2.7776 21.5811 5.9217C21.5811 7.91296 20.518 9.64229 18.9233 10.6378C21.3685 11.6858 23.1226 14.0963 23.1226 16.8736C23.1226 20.6465 20.0928 23.6859 16.2656 23.6859H0ZM16.9035 5.9217C16.9035 3.61602 14.9898 1.67716 12.5978 1.67716H6.96336V10.1139H12.5978C14.9898 10.1139 16.9035 8.22737 16.9035 5.9217ZM18.4981 16.8736C18.4981 13.9391 16.0529 11.5812 13.0762 11.5812H6.96336V22.2186H13.0762C16.0529 22.2186 18.4981 19.8605 18.4981 16.8736Z"
      />
      <path
        data-letter="R"
        d="M40.5576 23.6857C40.5576 23.6857 40.6639 23.0046 40.6639 22.0089C40.6639 19.3887 40.026 14.3058 35.0825 13.7295H30.1392V21.5896C30.2455 22.8997 31.9465 22.8997 32.4248 22.8997V23.6857H23.1226V22.8997C23.6541 22.8997 25.2489 22.8997 25.4615 21.5896V2.25346C25.302 1.10061 24.0262 0.943416 23.3884 0.943416L23.1226 0.99581V0.157387H39.4945C43.2686 0.157387 46.3515 3.24908 46.3515 6.96961C46.3515 10.2185 44.0128 12.9434 40.8766 13.6246C44.3316 15.0919 45.0759 18.3932 45.448 20.0177C45.7669 21.9565 46.2984 23.1093 48.4778 23.1093V23.6857H40.5576ZM41.6207 6.96961C41.6207 4.03511 39.1756 1.62464 36.1989 1.62464H30.1392V12.3146H36.1989C39.1756 12.3146 41.6207 9.90412 41.6207 6.96961Z"
      />
      <path
        data-letter="U"
        d="M49.0625 12.8911V2.30598C48.9029 1.15315 47.6804 0.99595 47.0425 0.99595H46.7767V0.262314H56.0258V0.99595H55.7601C55.1222 0.99595 53.8995 1.15315 53.7401 2.30598V13.0483C53.7401 18.3933 55.6537 21.9042 59.9594 21.9042C65.7533 21.9042 68.1983 18.0265 68.1983 12.1574V2.04398C68.0921 0.891146 67.0821 0.786344 66.3912 0.786344V0.262314H71.5472V0.786344C70.803 0.786344 69.7931 0.891146 69.6868 2.04398V11.843C69.6868 18.6553 66.6569 23.6859 59.9594 23.6859C53.3149 23.6859 49.0625 20.0177 49.0625 12.8911Z"
      />
      <path
        data-letter="N"
        d="M96.5834 23.6859L77.3942 5.18808V21.9042C77.5538 23.0046 78.5637 23.1095 79.2548 23.1095V23.6859H74.0986V23.1095C74.7897 23.1095 75.7996 23.0046 75.9592 21.9042V3.61602C75.9592 1.83437 73.5671 0.99595 72.5572 0.99595V0.262314H79.2015L96.5834 16.926V2.04398C96.4771 0.891146 95.4671 0.786344 94.7761 0.786344V0.262314H99.9322V0.786344C99.2412 0.786344 98.1781 0.891146 98.0717 2.04398V23.6859H96.5834Z"
      />
      <path
        data-letter="O"
        d="M100.995 11.8952C100.995 5.92139 105.142 0 113.646 0C122.098 0 126.244 5.92139 126.244 11.8952C126.244 17.9214 121.992 24 113.646 24C105.248 24 100.995 17.9214 100.995 11.8952ZM121.567 11.9999C121.567 6.65502 118.005 1.57206 113.646 1.57206C109.181 1.57206 105.673 6.65502 105.673 11.9999C105.673 17.3449 109.181 22.5327 113.646 22.5327C118.058 22.5327 121.567 17.3449 121.567 11.9999Z"
      />
      <path
        data-letter="M"
        d="M165.207 23.6859V22.8998C165.632 22.8998 167.28 22.8998 167.493 21.6422V3.72083C158.349 25.1007 159.201 23.1095 159.041 23.6859H156.649L146.124 3.19681V21.9042C146.23 23.0046 147.24 23.1095 147.985 23.1095V23.6859H142.829V23.1095C143.52 23.1095 144.529 23.0046 144.689 21.9042V2.30598C144.529 1.15315 143.254 0.99595 142.616 0.99595H142.35V0.262314H149.314L159.253 19.5985L167.493 0.262314H174.457V0.99595H174.19C173.553 0.99595 172.276 1.15315 172.17 2.30598V21.5898C172.33 22.8998 173.978 22.8998 174.457 22.8998V23.6859H165.207Z"
      />
      <path
        data-letter="A"
        d="M195.771 23.6859V23.1095C196.569 23.1095 197.153 22.7951 197.153 22.271L197.1 22.0614L194.761 16.8736H180.888L178.496 22.0614V22.271C178.496 22.7951 179.028 23.1095 179.878 23.1095V23.6859H174.774V23.1095C175.466 23.1095 176.688 22.7951 177.061 21.9566L187.107 0.262314H191.784L201.778 21.9566C202.203 22.7951 203.373 23.1095 204.063 23.1095V23.6859H195.771ZM187.851 1.83437L181.367 15.8256H194.282L187.851 1.83437Z"
      />
      <path
        data-letter="R"
        d="M222.19 23.6857C222.19 23.6857 222.296 23.0046 222.296 22.0089C222.296 19.3887 221.658 14.3058 216.715 13.7295H211.771V21.5896C211.877 22.8997 213.578 22.8997 214.057 22.8997V23.6857H204.755V22.8997C205.232 22.8997 206.88 22.8997 207.094 21.5896V2.25346C206.934 1.10061 205.658 0.943416 205.021 0.943416L204.755 0.99581V0.157387H221.127C224.9 0.157387 227.984 3.24908 227.984 6.96961C227.984 10.2185 225.644 12.9434 222.455 13.6246C225.963 15.0919 226.708 18.3932 227.027 20.0177C227.398 21.9565 227.931 23.1093 230.11 23.1093V23.6857H222.19ZM223.252 6.96961C223.252 4.03511 220.807 1.62464 217.831 1.62464H211.771V12.3146H217.831C220.807 12.3146 223.252 9.90412 223.252 6.96961Z"
      />
      <path
        data-letter="C"
        d="M230.004 11.9999C230.004 4.97816 236.435 0 243.717 0C246.64 0 249.671 0.78603 252.488 2.51529C252.542 2.56768 252.648 2.56768 252.648 2.56768C253.127 2.56768 253.179 1.10044 253.179 0.471621V0.157212H254.242L255.306 8.85585L253.977 8.90835C252.86 5.08296 249.883 1.62445 244.515 1.62445L243.717 1.67686C239.359 1.88647 235.213 6.1834 235.213 11.9999C235.213 17.5021 238.508 22.2707 243.717 22.2707C249.086 22.2707 252.754 18.2881 254.083 14.9345L255.412 14.9868C253.233 21.0655 248.288 23.6856 243.346 23.6856C236.702 23.6856 230.004 19.0218 230.004 11.9999Z"
      />
    </Svg>
  );
});

BrunoMarcLogo.displayName = 'BrunoMarcLogo';

BrunoMarcLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default BrunoMarcLogo;

import {
  gql,
  useLazyQuery,
  useQuery,
  QueryHookOptions,
  LazyQueryHookOptions,
} from '../../../techstyle-shared/react-graphql';

type ReviewsSortBy =
  | 'HighestRated'
  | 'MostRecent'
  | 'LowestRated'
  | 'WithPhotos';

interface PaginatedReviewsVars {
  groupCode: string;
  storeGroupId?: number;
  sizes?: string[];
  sortBy?: ReviewsSortBy;
  rating?: number;
  limit?: number;
  offset?: number;
}

interface ReviewData {
  reviewerName: string;
  reviewerCity: string;
  reviewerState: string;
  reviewerMembershipLevel: string;
  productId: number;
  productSize: string;
  productColor: string;
  reviewRating: string;
  reviewTitle: string;
  reviewContent: string;
  reviewerPhoto: string;
  reviewTimestamp: string;
  reviewSourceId: number;
  reviewSourceLabel: string;
}

interface PaginatedReviewsData {
  paginatedReviews: ReviewData[];
}

export const GET_PAGINATED_REVIEWS = gql`
  query PaginatedReviews(
    $groupCode: String!
    $limit: Int
    $offset: Int
    $sortBy: ReviewsSortBy
    $rating: Int
    $sizes: [String!]
  ) {
    paginatedReviews(
      groupCode: $groupCode
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      rating: $rating
      sizes: $sizes
    ) {
      reviewerPhoto
      reviewRating
      productSize
      productColor
      reviewTimestamp
      reviewerMembershipLevel
      reviewerName
      reviewSourceId
      reviewSourceLabel
      reviewContent
      reviewTitle
    }
  }
`;

export const usePaginatedReviewsQuery = (
  { groupCode, limit, offset, sortBy, rating, sizes }: PaginatedReviewsVars,
  options: QueryHookOptions<PaginatedReviewsData, PaginatedReviewsVars> = {}
) => {
  return useQuery<PaginatedReviewsData, PaginatedReviewsVars>(
    GET_PAGINATED_REVIEWS,
    {
      ...options,
      variables: {
        groupCode,
        limit,
        offset,
        sortBy,
        rating,
        sizes,
      },
    }
  );
};

export const useLazyPaginatedReviewsQuery = (
  { groupCode, limit, offset, sortBy, rating, sizes }: PaginatedReviewsVars,
  options: LazyQueryHookOptions<PaginatedReviewsData, PaginatedReviewsVars> = {}
) => {
  return useLazyQuery<PaginatedReviewsData, PaginatedReviewsVars>(
    GET_PAGINATED_REVIEWS,
    {
      ...options,
      variables: {
        groupCode,
        limit,
        offset,
        sortBy,
        rating,
        sizes,
      },
    }
  );
};

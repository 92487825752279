/**
 * This is the conventional Picture component for CDN images.
 * All it currently does is ensure consistent aspect-ratio handling,
 * breakpoint handling, and source conversion.
 * NOTE: Currently we're not adding support for different file formats,
 * or preloading.
 */
import React, { useCallback, useMemo } from 'react';

import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

import { useCarouselContext } from '../CarouselContext';
import { useGridState } from '../GridProvider';
import { BreakPoint } from '../types';
import { toMediaQuery } from '../useBreakpointSelector';
import { parseAspectRatio } from '../utils/images';

type AspectRatio = number | string;

type HTMLLoadingAttribute = 'lazy' | 'eager';

type HTMLObjectFit = React.CSSProperties['objectFit'];

type ImageElementProps = {
  aspectRatio?: AspectRatio;
  objectFit: HTMLObjectFit;
  loading: HTMLLoadingAttribute;
};

const ImageElement = styled.img<ImageElementProps>`
  width: 100%;
  height: auto;
  display: block;

  ${(props) =>
    props.aspectRatio &&
    props.aspectRatio !== 'auto' &&
    css`
      object-fit: ${props.objectFit || 'cover'};

      &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${100 / Number(props.aspectRatio)}%;
      }
    `}
`;

const PictureElement = styled.picture`
  display: block;
  width: 100%;
`;

// NOTE: The breakpoint format allows us to pass a raw mediaQuery string,
// which means we'll have to make a best effort to determine the max-width sometimes
const mediaQueryMaxWidthRe = /max-width:\s+(\d+)px/;
const mediaQueryMinWidthRe = /min-width:\s+(\d+)px/;

const getMaxWidth = (breakpoint: BreakPoint, nextBreakpoint: BreakPoint) => {
  let match;
  if (breakpoint.maxWidth) {
    return breakpoint.maxWidth;
  } else if (nextBreakpoint && nextBreakpoint.minWidth) {
    return nextBreakpoint.minWidth - 1;
  } else if (
    breakpoint.mediaQuery &&
    (match = breakpoint.mediaQuery.match(mediaQueryMaxWidthRe))
  ) {
    return parseInt(match[1], 10);
  } else if (
    nextBreakpoint &&
    nextBreakpoint.mediaQuery &&
    (match = nextBreakpoint.mediaQuery.match(mediaQueryMinWidthRe))
  ) {
    return parseInt(match[1], 10);
  }

  return null;
};

const useSizes = (breakpoints?: BreakPoint[], containerMaxWidth?: number) => {
  const grid = useGridState();
  const breakpointsToUse = breakpoints || grid?.breakpoints || [];

  return useMemo(() => {
    const pictureBreakpoints = breakpointsToUse
      .map((breakpoint, i, array) => {
        const nextBreakpoint = array[i + 1];

        let pictureWidth = '';
        if (breakpoint.pictureWidth) {
          pictureWidth = `${breakpoint.pictureWidth}px`;
        } else if (breakpoint.columns) {
          const maxWidth =
            getMaxWidth(breakpoint, nextBreakpoint) ||
            containerMaxWidth ||
            null;

          const gapsWidth =
            typeof breakpoint.gap === 'number'
              ? breakpoint.gap * (breakpoint.columns - 1)
              : 0;

          if (maxWidth !== null) {
            pictureWidth = `${(maxWidth - gapsWidth) / breakpoint.columns}px`;
          } else {
            // NOTE: If we don't have an overall `maxWidth` then we assume that the Picture
            // will fill the viewport eventually
            pictureWidth = gapsWidth
              ? `calc((100vw - ${gapsWidth}px) / ${breakpoint.columns})`
              : `calc(100vw / ${breakpoint.columns})`;
          }
        }

        return pictureWidth
          ? `${toMediaQuery(breakpoint, nextBreakpoint)} ${pictureWidth}`
          : '';
      })
      .filter(Boolean);

    if (!pictureBreakpoints.length) {
      throw new Error(
        'Could not generate Picture sizes.' +
          ' <Picture> must either be placed inside a <Grid> with valid max-width information' +
          ' or must be passed a `breakpoints` list with `pictureWidth` attributes.'
      );
    }

    return pictureBreakpoints.join(', ');
  }, [breakpointsToUse, containerMaxWidth]);
};

/**
 * Each image from the CDN, by convention, carries the image's URL
 * and width.
 */
type Image = {
  width: number;
  height: number;
  url: string;
};

/* Source set with image array and MIME type. */
type SrcSet = {
  images: Image[];
  type?: string;
};

type PictureProps = {
  /**
   * The `alt` property of the img element.
   * All props, not only including alt, will be forwarded.
   */
  alt?: string;
  /**
   * A constant ratio that should be maintained, e.g. "4:3", regardless of the image's size.
   * "auto" is used to let the Picture element adopt the images' aspect ratio automatically,
   * but may lead to layout shifting.
   */
  aspectRatio?: AspectRatio;
  /**
   * An array of breakpoints, which should contain both the viewport properties
   * (e.g. `minWidth`) and the approximate Picture width as `pictureWidth` for each
   * breakpoint.
   */
  breakpoints?: BreakPoint[];
  className?: string;
  /**
   * The maximum width of a container element that the Picture will be displayed
   * in. This can be added in conjunction with a `GridProvider`, to let the
   * `Picture` know what the Grid container's maximum width is for the
   * largest breakpoint. By default, the Picture will assume that it fills the entire
   * viewport at the highest breakpoint.
   */
  containerMaxWidth?: number;
  /**
   * `hidden` is the built-in attribute to indicate that the element is currently not
   * visible. This will force the `loading` attribute to be set to `lazy`.
   */
  hidden?: boolean;
  imgStyle?: React.CSSProperties;
  /**
   * The built-in `loading` attribute should be used to indicate the loading
   * priority of the image. A hidden image should always use 'lazy'.
   */
  loading?: HTMLLoadingAttribute;
  /**
   * The value to which `object-fit` should be set to. By default `cover` will be used.
   */
  objectFit?: HTMLObjectFit;
  /**
   * Callback to call when the image loads.
   */
  onLoad?: (...args: any[]) => void;
  /**
   * Margin around the root. Can have values similar to the CSS margin property,
   * e.g. "10px 20px 30px 40px" (top, right, bottom, left) or "100px".
   * default value is "100px".
   */
  rootMargin?: string;
  /**
   * An array of objects where each object has `images` and optional `type`.
   * `images` is also an array with intrinsic `width` and the `url`
   */
  sources: SrcSet[];
  style?: React.CSSProperties;
};

const Picture = React.forwardRef<HTMLImageElement, PictureProps>(
  (
    {
      sources,
      aspectRatio: aspectRatioFromProps,
      objectFit,
      breakpoints,
      containerMaxWidth,
      hidden,
      style,
      className,
      imgStyle,
      onLoad,
      loading = 'eager',
      rootMargin = '400px',
      ...props
    }: PictureProps,
    ref
  ) => {
    const carousel = useCarouselContext();

    const lazyLoad = loading === 'lazy';
    const supportsLazyLoading = useNativeLazyLoading();
    const [imageRef, imageIsInView] = useInView({
      triggerOnce: true,
      rootMargin,
      skip: !lazyLoad || supportsLazyLoading,
    });
    const loadImage = supportsLazyLoading || imageIsInView || !lazyLoad;

    const handleLoad = useCallback(
      (...args: any[]) => {
        if (carousel) {
          carousel.notifyImageLoaded();
        }
        if (onLoad) {
          onLoad(...args);
        }
      },
      [carousel, onLoad]
    );

    const aspectRatio = useMemo(() => {
      return typeof aspectRatioFromProps === 'string'
        ? parseAspectRatio(aspectRatioFromProps)
        : aspectRatioFromProps;
    }, [aspectRatioFromProps]);

    const sizes = useSizes(breakpoints, containerMaxWidth);

    if (!sources.length) {
      return null;
    }

    const pictureSources = sources.map((source, index) => {
      const srcSet = source.images
        .map((image) => `${image.url} ${image.width}w`)
        .join(', ');
      return (
        <source srcSet={srcSet} sizes={sizes} type={source.type} key={index} />
      );
    });

    // First image of the last source set, so that fallback URL is `.jpg` or
    // whatever the lowest priority (probably most compatible) image is.
    const fallback = sources[sources.length - 1].images[0];

    return (
      <PictureElement
        style={style}
        className={className}
        hidden={hidden}
        key={fallback.url}
        onLoad={handleLoad}
        ref={imageRef}
      >
        {loadImage ? pictureSources : null}
        {loadImage && fallback ? (
          <ImageElement
            // Allow explicit props to override width and height. Note that
            // these won't set the actual rendered size since it is overridden
            // with CSS above, but it does give the browser an idea of the
            // aspect ratio while the image is still loading *if* both width and
            // height are actually defined, while the :before pseudo-element
            // does not.
            width={fallback.width}
            height={fallback.height}
            loading={loading}
            {...props}
            style={imgStyle || style}
            objectFit={objectFit}
            aspectRatio={aspectRatio}
            className={className}
            ref={ref}
            onLoad={handleLoad}
            src={fallback.url}
          />
        ) : null}
      </PictureElement>
    );
  }
);

Picture.displayName = 'Picture';

export default Picture;

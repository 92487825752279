import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.color || props.fill || 'black'};
  vertical-align: top;
`;

const FableticsMenLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      // Let `aria-label` take control if present. Consumers are also free to
      // override with their own `aria-labelledby`.
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 800 166.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={titleId}>Fabletics Men</title>
      <path
        data-word="fabletics"
        d="M260.6,50.8l9,21.9h-18L260.6,50.8z M253.7,33.4l-28.8,67.6h15.2l6.2-15.1h28.6l6.2,15.1h15.6l-28.8-67.6H253.7z M206,33.4
    h-12.3h-16h-10.8c-6.7,0-12.2,5.4-12.3,12v0.1c0,0.1,0,0.1,0,0.2V70c0,0.1,0,0.2,0,0.2v30.9h14.9V74.9h32.2V61.4h-32.2V47h36.6
    L206,33.4l-0.6,0C205.8,33.4,206,33.4,206,33.4 M415.3,47.2V33.4l0,0l0,0h-2.6c-6.8,0-12.3,5.5-12.3,12.3V47c0,0,0,0,0,0v0.1v53.9
    H449V87.6h-33.7V47.2z M486.9,73.6h31.9V60.4h-31.9V46.7h36.2V33.4h-12l0,0h-16h-10.8c-6.8,0-12.3,5.5-12.3,12.3v1c0,0,0,0,0,0v54.3
    h51.5V87.9h-36.7V73.6z M779.2,60.8c-11.4-2.9-14.2-4.3-14.2-8.7V52c0-3.2,2.9-5.7,8.5-5.7c5.5,0,11.3,2.4,17.1,6.5l7.5-10.9
    c-6.6-5.4-14.8-8.4-24.4-8.4c-13.4,0-23,7.9-23,19.8v0.2c0,13,8.6,16.7,21.8,20.1c11,2.8,13.3,4.7,13.3,8.3v0.2
    c0,3.8-3.6,6.2-9.5,6.2c-7.5,0-13.7-3.1-19.6-7.9L748,90.6c7.9,7.1,17.9,10.5,27.9,10.5c14.2,0,24.1-7.3,24.1-20.4v-0.2
    C800,69,792.5,64.3,779.2,60.8 M698.2,87.8c-11.2,0-19-9.4-19-20.5v-0.2c0-11.2,7.9-20.4,19-20.4c6.6,0,11.7,2.8,16.7,7.4l9.1-10.6
    c-6.1-6-13.4-10.1-25.8-10.1c-20.1,0-34.1,15.2-34.1,33.8v0.2c0,18.8,14.3,33.6,33.5,33.6c12.6,0,20.1-4.5,26.8-11.7l-9.1-9.2
    C710.3,84.8,705.7,87.8,698.2,87.8 M362.5,80.7c0,5-4,7.3-10.5,7.3h-17.7V73.2h17.2c7.6,0,11,2.8,11,7.3V80.7z M334.3,46.5H349
    c6.3,0,9.7,2.5,9.7,6.9v0.2c0,5-4.2,7.1-10.7,7.1h-13.7V46.5z M364.6,65.7c4.8-2.7,8.9-7,8.9-14.6V51c0-10.6-8.4-17.4-22-17.5l0,0
    h-0.3h-8.3h-10.8h0c-6.8,0-12.3,5.5-12.3,12.3v0.3l0,0v55h32.2c15.3,0,25.3-6.2,25.3-18.5v-0.2C377.2,73.3,372.4,68.7,364.6,65.7
     M598.3,33.4h-12.3h-16h-10.8c-6.8,0-12.3,5.5-12.3,12.3h0v1.4h20.6v53.9h14.9V47.2h20.6V33.4H598.3z M625.9,45.8V70
    c0,0.1,0,0.2,0,0.3v30.8h14.9V47.2V33.4l-2.6,0C631.4,33.4,625.9,39,625.9,45.8 M260.6,50.9l9,21.9h-18L260.6,50.9z M253.7,33.5
    l-28.8,67.6h15.2l6.2-15.1h28.6l6.2,15.1h15.6l-28.8-67.6H253.7z"
      />
      <path
        data-icon=""
        d="M114.9,5.9c-1.1,3.4-3.1,7.7-6.7,11.3c0,0-4.5,6.2-15.4,10.5c0,0-6.9,2.9-19.1,4c0,0-10.4,1.1-26,0.8
    c-1.1,0-3.4-0.1-3.5-0.1c0,0,0,0-0.1,0c-15.5-0.1-21.8,2.4-21.8,2.4C14.7,37.4,9.8,45.3,6.9,52.1c0,0,0,0,0,0
    C6.7,52.4,3.1,60.9,0,76.5c0,0,0,0.1,0,0.1V41.4c0.1-1.8,0.2-3.6,0.3-5.4c1.3-11.2,8-20.7,17.5-25.9c2-1.3,11.1-5,25.6-5.2
    c4.4,0,29-0.3,40-0.6C108.8,3.5,116.1,0,116.1,0C115.6,4,114.9,5.9,114.9,5.9 M77.3,59.6c-14.9,6.2-42,5.4-47,6.3
    c-5,0.9-17.4-0.4-26.2,17.8c1.9-2.3,4.8-3.7,8-3.7c5.8,0,10.4,4.7,10.4,10.4c0,5.8-4.7,10.4-10.4,10.4c-4.2,0-7.9-2.5-9.5-6.1
    c0,0,0-0.1,0-0.1c-0.2-0.4-0.3-0.9-0.5-1.3c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.3-0.1-0.7-0.2-1c-0.1-0.4-0.1-0.9-0.1-1.4v0v0
    c0-0.4,0-0.7,0-1.1c0.1-1.3,0.2-2.6,0.4-4v-0.1C2,85.1,2,85,2,84.9C3.2,77,5.9,68.6,5.9,68.6l0,0.1c1.4-4.8,2.3-6.6,2.4-6.8
    c0,0,0,0,0,0c2.9-6.8,7.8-14.7,15.4-17.2c0,0,6.4-2.6,21.9-2.4c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c1.1,0,2.2,0,3.3,0.1
    c15.7,0.3,26-0.8,26-0.8c12.2-1.1,19.6-3.3,25.6-7.2C99.6,39.1,94.6,52.4,77.3,59.6"
      />
      <path
        data-word="men"
        d="M664,166.1h-6.8l0-18.7l-9.2,15.4h-3.2l-9.1-15v18.3h-6.8v-25.5c0-3.1,2.5-5.7,5.7-5.7h0.3l0,0l0.3,0.5l11.4,18.9l11.5-19.3
    h5.9L664,166.1z M711.8,160.3v-7.2h14.5v-5.6h-14.5v-6.8h16.4v-5.8h-17.9c-3.1,0-5.7,2.5-5.7,5.7v25.5h24.1v-5.8H711.8z M794.8,135
    c-3.1,0-5.6,2.4-5.7,5.5v0.2v13.2L773.6,135h-6v31.1h7.1v-18.9l15.5,18.9h5.9V135H794.8z"
      />
    </Svg>
  );
});

FableticsMenLogo.displayName = 'FableticsMenLogo';

FableticsMenLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   * default multi-tone treatment.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default FableticsMenLogo;

/* eslint-disable no-console */
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export const useToastContext = () => {
  return useContext(Context);
};

export default function ToastContext({
  children,
  delay: delayFromProps = 4000,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const timeout = useRef(null);

  const hideMessage = useCallback(() => {
    clearTimeout(timeout.current);
    setIsOpen(false);
  }, []);

  const showMessage = useCallback(
    (delay = delayFromProps) => {
      clearTimeout(timeout.current);
      setIsOpen(true);
      timeout.current = setTimeout(hideMessage, delay);
    },
    [delayFromProps, hideMessage]
  );
  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  const value = useMemo(
    () => ({
      isOpen,
      showMessage,
      hideMessage,
    }),
    [isOpen, hideMessage, showMessage]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ToastContext.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
};
ToastContext.Context = Context;

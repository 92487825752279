import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { AssetContainer } from '../../../../techstyle-shared/react-assets';
import FabKidsLogo from '../FabKidsLogo';
import FableticsLogo from '../FableticsLogo';
import JustFabLogo from '../JustFabLogo';
import ShoeDazzleLogo from '../ShoeDazzleLogo';
import { mobile, desktop } from '../styles';

export const Wrapper = styled.nav`
  text-align: center;
  font-size: ${(props) => props.size}px;
`;

export const SiteList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

const inactiveLogoStyle = css`
  opacity: 0.6;
`;
const activeLogoStyle = css`
  opacity: 1;
`;
const hoverLogoStyle = css`
  opacity: 0.7;
`;
export const SiteListItem = styled.li`
  ${mobile`
    flex: 0 1 100%;
  `}
`;
export const SiteLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 1em;
  text-align: center;
  background: ${(props) => (props.isActive ? 'white' : 'transparent')};
  height: 4em;
  min-width: 9em;

  svg {
    ${inactiveLogoStyle}
  }

  ${(props) =>
    props.isActive &&
    `
    svg {
      ${activeLogoStyle}
    }
  `}

  ${desktop`
    &:hover, &:focus {
      svg {
        ${hoverLogoStyle}
      }
    }
  `}
`;

// Even if each logo canvas were exactly the same dimensions, the shape and
// baseline of each individual logo would cause them to be misaligned and
// unevenly weighted. Below we style each one to have equal visual weight and
// stick to an approximate baseline.

const FableticsNavLogo = styled(FableticsLogo)`
  height: 1.4em;
  margin-top: -0.2em;
`;

const JustFabNavLogo = styled(JustFabLogo)`
  height: 1em;
  margin-bottom: -0.1em;

  [data-word='fab'] {
    fill: ${(props) => (props.color || props.fill ? 'inherit' : '#666')};
  }
`;

const FabKidsNavLogo = styled(FabKidsLogo)`
  height: 1.7em;
  margin-bottom: -0.2em;
`;

const ShoeDazzleNavLogo = styled(ShoeDazzleLogo)`
  height: 1.9em;
  margin-top: -0.55em;
`;

const logos = {
  justfab: <JustFabNavLogo />,
  shoedazzle: <ShoeDazzleNavLogo />,
  fabletics: <FableticsNavLogo />,
  fabkids: <FabKidsNavLogo />,
};

export const HoverImage = styled.img`
  display: none;

  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  height: 128px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);

  ${desktop`
    a:hover > &,
    a:focus > & {
      display: block;
    }
  `}
`;

const MetaNav = React.forwardRef(
  (
    {
      activeBrand,
      className,
      size,
      color,
      fill,
      name,
      target,
      showPromoBanner = true,
      ...rest
    },
    ref
  ) => {
    const handleLinkClick = useCallback((event) => {
      event.currentTarget.blur();
    }, []);

    return (
      <Wrapper className={className} ref={ref} size={size} {...rest}>
        <SiteList>
          <AssetContainer name={name} multi>
            {(state) =>
              state.data.map((asset) => {
                const { brand, altText, autoTag } = asset.options.customVars;
                const isActive = brand === activeBrand;
                const logo = logos[brand];
                if (!logo) {
                  return null;
                }
                return (
                  <SiteListItem
                    data-brand={brand}
                    key={asset.id}
                    isActive={isActive}
                  >
                    <SiteLink
                      href={asset.options.targetUrl}
                      isActive={isActive}
                      data-autotag={autoTag}
                      target={target}
                      onClick={handleLinkClick}
                    >
                      {color || fill
                        ? React.cloneElement(logo, { color, fill })
                        : logo}
                      {showPromoBanner && asset.imageFilename && !isActive ? (
                        <HoverImage
                          src={asset.imageFilename}
                          data-autotag={`${autoTag}_asset`}
                          alt={altText}
                        />
                      ) : null}
                    </SiteLink>
                  </SiteListItem>
                );
              })
            }
          </AssetContainer>
        </SiteList>
      </Wrapper>
    );
  }
);

MetaNav.displayName = 'MetaNav';

MetaNav.Wrapper = Wrapper;
MetaNav.SiteList = SiteList;
MetaNav.SiteListItem = SiteListItem;
MetaNav.HoverImage = HoverImage;
MetaNav.SiteLink = SiteLink;
MetaNav.inactiveLogoStyle = inactiveLogoStyle;
MetaNav.activeLogoStyle = activeLogoStyle;

MetaNav.propTypes = {
  /**
   * The identifier of the site that should appear as "active."
   */
  activeBrand: PropTypes.string,
  /**
   * Class to apply to the root element.
   */
  className: PropTypes.string,
  /**
   * Render the logos monochromatically in the given color rather than their
   * default multi-tone treatment.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
  /**
   * The asset container name in which the meta nav is found.
   */
  name: PropTypes.string,
  /**
   * specifies whether to show the hover image (promo banner) or not
   */
  showPromoBanner: PropTypes.bool,
  /**
   * A relative measure of size for the spacing & height
   */
  size: PropTypes.number.isRequired,
  /**
   * specifies where to open the SiteLink url
   */
  target: PropTypes.string,
};

MetaNav.defaultProps = {
  name: 'meta_nav_image_offer',
  size: 10,
  target: null,
};

export default MetaNav;

import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { useCookies } from '../../../../techstyle-shared/redux-core';

export const ReferringPageContext = React.createContext();

export default function ReferringPageProvider({ children, cookieName }) {
  const [, setCookie, removeCookie] = useCookies([cookieName]);

  const setReferringPage = useCallback(
    (gaLabel) => {
      if (gaLabel) {
        setCookie(cookieName, gaLabel.trim(), { secure: true });
      } else {
        removeCookie(cookieName);
      }
    },
    [cookieName, removeCookie, setCookie]
  );

  const value = {
    cookieName,
    setReferringPage,
  };

  return (
    <ReferringPageContext.Provider value={value}>
      {children}
    </ReferringPageContext.Provider>
  );
}

ReferringPageProvider.propTypes = {
  children: PropTypes.node,
  cookieName: PropTypes.string,
};

ReferringPageProvider.Context = ReferringPageContext;

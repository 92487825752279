import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AdminPanel } from '../../../../techstyle-shared/react-admin';
import { setHighlightBundles } from '../intlModule';

export default function AdminHighlightToggle() {
  const dispatch = useDispatch();
  const highlightBundles = useSelector((state) => state.intl.highlightBundles);

  return (
    <AdminPanel.Field
      type="checkbox"
      label={
        <label htmlFor="adminHighlightBundles">
          Highlight resource bundles
        </label>
      }
    >
      <input
        type="checkbox"
        checked={highlightBundles}
        onChange={(e) => dispatch(setHighlightBundles(e.target.checked))}
        id="adminHighlightBundles"
      />
    </AdminPanel.Field>
  );
}

import { createReducer } from '@reduxjs/toolkit';
import ParkMiller from 'park-miller';

import createAction from './createAction';

export const HydrationStatus = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
};

export const initialState = {
  hydrationStatus: HydrationStatus.NOT_STARTED,
  pageWillUnmount: false,
  origin: null,
  randomSeed: 0,
};

export const setHydrationStatus = createAction('app/setHydrationStatus');
export const setOrigin = createAction('app/setOrigin');
export const setRandomSeed = createAction('app/setRandomSeed');
export const setPageWillUnmount = createAction('app/setPageWillUnmount');

// Get a random integer with which to seed the `ParkMiller` PRNG.
// See: https://github.com/sindresorhus/park-miller
function getRandomSeed() {
  return Math.floor(Math.random() * 2147483647);
}

export function initApp() {
  return (dispatch, getState, context) => {
    let randomSeed;
    if (context.isServer && !context.hasPreloadedState) {
      dispatch(setOrigin(context.req.context.url.origin));

      // Save a random seed in the store for use during rendering, but use a
      // different one right now (during `getInitialProps`). Due to hydration,
      // the PRNG state must be the same when rendering starts on both the
      // server and client, meaning we can't advance it during `getInitialProps`.
      const randomSeedForRendering = getRandomSeed();
      dispatch(setRandomSeed(randomSeedForRendering));
      randomSeed = getRandomSeed();
    } else {
      randomSeed = getState().app.randomSeed;
    }
    context.random = new ParkMiller(randomSeed);

    if (!context.isServer) {
      dispatch(setHydrationStatus(HydrationStatus.IN_PROGRESS));
    }
  };
}

export function getRandomNumberGenerator() {
  return (dispatch, getState, context) => context.random;
}

export function getCookies() {
  return (dispatch, getState, context) => context.cookies;
}

export const appReducer = createReducer(initialState, {
  [setHydrationStatus]: (state, action) => {
    state.hydrationStatus = action.payload;
  },
  [setOrigin]: (state, action) => {
    state.origin = action.payload;
  },
  [setRandomSeed]: (state, action) => {
    state.randomSeed = action.payload;
  },
  [setPageWillUnmount]: (state, action) => {
    state.pageWillUnmount = action.payload;
  },
  'accounts/logInSuccess': (state, action) => {
    if (action.meta.pageWillUnmount) {
      state.pageWillUnmount = true;
    }
  },
  'accounts/signUpSuccess': (state, action) => {
    if (action.meta.pageWillUnmount) {
      state.pageWillUnmount = true;
    }
  },
});

export default {
  id: 'app',
  reducerMap: {
    app: appReducer,
  },
  initialActions: [initApp()],
};

/**
 * When an action is dispatched with a `channel` property, return a Promise that
 * waits for its `payload` to eventually be dispatched. The Promise will be
 * resolved with the result of dispatching that payload.
 */
export default function createChannelMiddleware() {
  const pending = new Map();

  return (store) => (next) => (action) => {
    const promise = pending.get(action);

    if (promise) {
      pending.delete(action);
    }

    const handleAction = action.channel
      ? () =>
          new Promise((resolve, reject) => {
            pending.set(action.payload, { resolve, reject });
            next(action);
          })
      : () => next(action);

    let result;

    if (promise) {
      // Resolve or reject the promise that was originally created above by
      // the action that had the `channel` property.
      try {
        result = handleAction();
      } catch (err) {
        promise.reject(err);
        throw err;
      }
      promise.resolve(result);
    } else {
      result = handleAction();
    }

    return result;
  };
}

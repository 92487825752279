/* eslint-disable react/prop-types */
import React from 'react';

import {
  from,
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import config from 'config';
import createWithApollo from 'next-with-apollo';

import {
  createBentoApiClient,
  getNewDateFunction,
  isTimeTravelActive,
} from '../../../techstyle-shared/redux-core';

import { handleApolloErrors } from './handleApolloErrors';
import logger from './logger';

const debug = logger.extend('apolloClient');

export const createApolloClient =
  (options) =>
  ({ initialState, ctx, ...rest }) => {
    const {
      cacheOptions,
      errorCodeRules,
      getCustomLink,
      getRetryableError,
      onApolloClientCreated,
    } = options;
    const isServer = !process.browser;
    const store = isServer ? ctx.store : window.__NEXT_REDUX_STORE__;
    const apiClientOptions = {
      isServer,
      req: ctx?.req,
      res: ctx?.res,
      trueClientIp: store.getState().bentoApi?.trueClientIp,
      storeDomain: store.getState().storeGroup?.storeDomain,
    };

    const bentoApiClient = createBentoApiClient(apiClientOptions);

    const fetch = (input, options) =>
      bentoApiClient(input, { ...options, prefixUrl: null });

    const applicationName = isServer
      ? config.get('server.graphql.applicationName')
      : config.get('public.graphql.applicationName');
    const applicationVersion = isServer
      ? config.get('server.graphql.applicationVersion')
      : config.get('public.graphql.applicationVersion');

    const headers = {};
    let gatewayToken;
    if (isServer && config.has('server.graphql.gatewayToken')) {
      gatewayToken = config.get('server.graphql.gatewayToken');
    } else if (!isServer && config.has('public.graphql.gatewayToken')) {
      gatewayToken = config.get('public.graphql.gatewayToken');
    }
    if (gatewayToken) {
      headers['x-gateway-token'] = gatewayToken;
    }

    // Time Travel
    // TODO: this requires a reload in order to take. We should investigate
    // how to do this without needing a reload
    if (isTimeTravelActive(store.getState())) {
      const newDate = getNewDateFunction(store.getState());
      const fetchedDate = newDate();

      headers['x-tfg-effective-date'] = fetchedDate.toISOString();
    }

    const retryLink = new RetryLink({
      delay: {
        initial: isServer
          ? config.get('server.graphql.retry.initialDelay')
          : config.get('public.graphql.retry.initialDelay'),
        max: isServer
          ? config.get('server.graphql.retry.maxDelay')
          : config.get('public.graphql.retry.maxDelay'),
        jitter: isServer
          ? config.get('server.graphql.retry.randomize')
          : config.get('public.graphql.retry.randomize'),
      },
      attempts: {
        max: isServer
          ? config.get('server.graphql.retry.maxAttempts', 3)
          : config.get('public.graphql.retry.maxAttempts', 3),
        retryIf: (error, _operation) => {
          const shouldRetry = !!error;
          debug(
            'RetryLink: Error during request. shouldRetry: %s',
            shouldRetry,
            error
          );
          return shouldRetry;
        },
      },
    });

    // GraphQL Service: Strapi (SXF CMS via Tier1)
    const strapiHttpLink = new BatchHttpLink({
      uri: isServer
        ? config.get('server.graphql.strapi.gateway')
        : config.get('public.graphql.strapi.gateway'),
      credentials: 'same-origin',
      headers,
      fetch,
    });

    // GraphQL Service: Legacy PDP (product-gql repo)
    const httpLink = new HttpLink({
      uri: isServer
        ? config.get('server.graphql.gateway')
        : config.get('public.graphql.gateway'),
      credentials: 'same-origin',
      headers,
      fetch,
    });

    const batchHttpLink = new BatchHttpLink({
      uri: isServer
        ? config.get('server.graphql.gateway')
        : config.get('public.graphql.gateway'),
      credentials: 'same-origin',
      headers,
      fetch,
    });

    const shouldBatchLink = split(
      (operation) => !!operation.getContext().skipBatch,
      httpLink,
      batchHttpLink
    );

    const createHandleError = (args) =>
      handleApolloErrors({ ...args, ctx, errorCodeRules, getRetryableError });

    const errorLink = onError(createHandleError);

    const shouldRetryLink = split(
      (operation) =>
        !operation.getContext().skipRetry ||
        (isServer
          ? config.get('server.graphql.retry.enabled')
          : config.get('public.graphql.retry.enabled')),
      retryLink
    );

    const links = [shouldRetryLink, errorLink, shouldBatchLink];
    const strapiLinks = [errorLink, strapiHttpLink];

    const supergraphContext = config.has(
      'public.graphql.supergraph.contextName'
    )
      ? config.get('public.graphql.supergraph.contextName')
      : 'supergraph';

    let supergraphKey;
    if (isServer && config.has('server.graphql.supergraph.key')) {
      supergraphKey = config.get('server.graphql.supergraph.key');
    } else if (!isServer && config.has('public.graphql.supergraph.key')) {
      supergraphKey = config.get('public.graphql.supergraph.key');
    }

    // GraphQL Service: Legacy PDP (product-gql repo)
    const supergraphHttpLink = new HttpLink({
      uri: isServer
        ? config.get('server.graphql.supergraph.gateway')
        : config.get('public.graphql.supergraph.gateway'),
      headers: {
        ...headers,
        ...(supergraphKey
          ? { 'x-api-graphkey': `Bearer ${supergraphKey}` }
          : {}),
      },
      fetch,
    });

    const supergraphHttpLinks = [
      shouldRetryLink,
      errorLink,
      supergraphHttpLink,
    ];

    // The only way to have multiple link splits is to nest them.
    const defaultLink = split(
      (operation) => operation.getContext().clientName === supergraphContext,
      from(supergraphHttpLinks),
      split(
        (operation) => operation.getContext().clientName === 'strapi',
        from(strapiLinks), // <= apollo will send to this if clientName is "strapi"
        from(links) // <= otherwise will send to this
      )
    );

    let link = defaultLink;

    // Allow brands to add additional "links" (GraphQL services/URLs)
    if (typeof getCustomLink === 'function') {
      try {
        const { link: customLink, splitOperation: customSplitOperation } =
          getCustomLink({
            applicationName,
            applicationVersion,
            apolloClientOptions: options,
            defaultLink,
            errorLink,
            fetch,
            split,
            BatchHttpLink,
            HttpLink,
            RetryLink,
          }) || {};
        if (customLink && typeof customSplitOperation === 'function') {
          link = split(customSplitOperation, customLink, defaultLink);
        }
      } catch (error) {
        debug('Error in getCustomLink');
      }
    }

    const apolloClient = new ApolloClient({
      link,
      // `apollographql-client-name` header:
      name: applicationName,
      // `apollographql-client-version` header:
      version: applicationVersion,
      ssrMode: isServer,
      cache: new InMemoryCache(cacheOptions).restore(initialState || {}),
    });

    if (process.browser && typeof onApolloClientCreated === 'function') {
      onApolloClientCreated(apolloClient);
    }

    return apolloClient;
  };

export const withApollo = (options) =>
  createWithApollo(createApolloClient(options), {
    // eslint-disable-next-line react/display-name
    render: ({ Page, props }) => {
      return (
        <ApolloProvider client={props.apollo}>
          <Page {...props} />
        </ApolloProvider>
      );
    },
  });

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export default function useLocalStorage(
  key,
  {
    encode = JSON.stringify,
    decode = JSON.parse,
    defaultValue = undefined,
    enableSessionStorage = false,
  } = {}
) {
  const getStorage = useCallback(() => {
    return enableSessionStorage ? sessionStorage : localStorage;
  }, [enableSessionStorage]);

  const getDecodedValue = useCallback(
    (storedValue) => {
      if (storedValue) {
        try {
          // If the format changes or someone fiddles with the value, and it
          // no longer decodes properly, fallback to the default.
          return decode(storedValue);
        } catch (err) {
          return defaultValue;
        }
      }
      return defaultValue;
    },
    [decode, defaultValue]
  );

  const getEncodedValue = useCallback(
    (value) => encode(value) || null,
    [encode]
  );

  const [initialized, setInitialized] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const lastStoredValue = useRef(null);

  const valueToStore = useMemo(
    () => getEncodedValue(value),
    [getEncodedValue, value]
  );

  useEffect(() => {
    try {
      const storage = getStorage();
      const storedValue = storage.getItem(key);
      lastStoredValue.current = storedValue;
      setValue(getDecodedValue(storedValue));
    } catch (err) {
      // OK, just stick with the current value then.
    }
    setInitialized(true);
  }, [getDecodedValue, getStorage, key]);

  useEffect(() => {
    // Wait for `value` to be initialized from storage before attempting to
    // sync anything.
    if (!initialized) {
      return;
    }
    if (!lastStoredValue.current && value === defaultValue) {
      // The most common case will be no existing value in localStorage and the
      // value being default. No need to waste time writing in this case.
      return;
    }
    if (valueToStore !== lastStoredValue.current) {
      const storage = getStorage();
      // Track what we're trying to set (even if it doesn't succeed in the
      // try/catch below) so we don't just keep trying again if it failed.
      lastStoredValue.current = valueToStore;
      if (!valueToStore) {
        storage.removeItem(key);
      } else {
        try {
          storage.setItem(key, valueToStore);
        } catch (err) {
          // Disallowed by browser (probably due to quota or permissions).
        }
      }
    }
  }, [defaultValue, getStorage, initialized, key, value, valueToStore]);

  return [value, setValue, initialized];
}

export const createProductDetailViewedEvent = ({
  createEvent,
  extraValues,
  index = 0,
  product,
  selectedProductInformation = {},
  options,
}) => {
  const selectedProduct =
    selectedProductInformation.selectionStateByProductIndex?.[index];
  const selectedAttributes = selectedProduct?.selectedAttributes || {};

  const sizeKey = Object.keys(selectedAttributes).filter((key) =>
    /size/.test(key)
  )?.[0];

  const size = selectedAttributes[sizeKey]?.label || '';
  const color = selectedAttributes.Color?.label || '';

  const colorSkuNumber = selectedAttributes.Color?.value || '';

  const totalProductInventory = product.skus?.reduce(
    (total, current) => total + current.availableQuantity,
    0
  );

  return createEvent({
    name: 'Product Viewed',
    properties: {
      bundle_alias: null, // alias of the bundle
      bundle_index: null, // An integer to empower media pixels only to fire once per bundle/set
      bundle_name: null, // name of bundle
      bundle_quantity: null, // quantity of items in the bundle
      bundle_product_id: null, // product_id associated with a bundle
      bundle_retail_price: null, // retail price of the bundle
      bundle_sale_price: null, // sale price of the bundle
      bundle_url: null, // url of bundle
      bundle_vip_price: null, // vip price of bundle
      is_bundle: false, // Whether or not this is a bundle

      category: product.defaultCategoryLabel || '', // The category of the product
      inventory_availability: totalProductInventory, // Total Product Inventory (not by size), but by Product/Color
      list: selectedProductInformation.psrc || '', // what we think of as psource - unique name per grid
      name: product.label || '', // product name
      position: index, // position of the product on grid - carried through
      quantity: selectedProduct.selectedSKU?.availableQuantity ?? null, // The number of products in the
      size, // size of the product
      sized_product_id:
        selectedProduct.selectedSKU?.productId?.toString() || '', // product_id associated with style/color/size Optional because we only have this if the grid is pre-filtered
      // sized_sku this is not present in graphql data
      sized_sku: selectedProduct.selectedSKU?.itemNumber || '', // sku full sku associated with style/color/size optional b/c we only know size for this event if the grid is pre-filtered
      sku: `${product.groupCode}-${colorSkuNumber}`, // product sku (sku component for style/color)
      variant: color, // variant

      retail_price: product.retailUnitPrice || null, // retail price
      price: product.defaultUnitPrice || null, // price after discounts
      sale_price: product.saleUnitPrice || null, // sale_price
      vip_price: product.defaultUnitPrice || null, // vip price

      products: {
        has_looks: !!product.associatedBundleProductIdsList?.length, // Whether product has looks
        has_reviews: !!product.reviewCount, //	Whether product has reviews
        has_video: !!product.video, // Whether product has videos
        review_num: product.reviewCount ?? null, // Number of reviews
        review_rating: product.averageReview ?? null, // Avg. Rating
      },

      ...extraValues,
    },
    options,
  });
};

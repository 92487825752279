import React, { useEffect, useState } from 'react';

import Head from 'next/head';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ReferringPageProvider from '../ReferringPageProvider';

/**
 * A utility component that will wait until after mounting to render its
 * children.
 */
function AfterMount({ children }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? children : null;
}

AfterMount.propTypes = {
  children: PropTypes.node,
};

export default function MarketingSnippets({
  children,
  referringPageCookieName,
  requestInfo,
  snippets,
}) {
  const pageWillUnmount = useSelector((state) => state.app.pageWillUnmount);
  let { afterContent, afterMount, beforeContent, head } = snippets;

  if (typeof afterContent === 'function') {
    afterContent = afterContent(requestInfo);
  }
  if (typeof afterMount === 'function') {
    afterMount = afterMount(requestInfo);
  }
  if (typeof beforeContent === 'function') {
    beforeContent = beforeContent(requestInfo);
  }
  if (typeof head === 'function') {
    head = head(requestInfo);
  }

  const content = referringPageCookieName ? (
    <ReferringPageProvider cookieName={referringPageCookieName}>
      {children}
    </ReferringPageProvider>
  ) : (
    children
  );

  return pageWillUnmount ? (
    content
  ) : (
    <>
      {head ? <Head>{head}</Head> : null}
      {beforeContent}
      {content}
      {afterContent}
      {afterMount ? <AfterMount>{afterMount}</AfterMount> : null}
    </>
  );
}

MarketingSnippets.propTypes = {
  children: PropTypes.node,
  referringPageCookieName: PropTypes.string,
  requestInfo: PropTypes.object,
  snippets: PropTypes.object,
};

import React, { useContext } from 'react';

import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { Component as AssetProvider } from '../AssetProvider';
import logger from '../logger';
import { colorStyles, highlightStyles } from '../styles';
import { assetPropType } from '../utils';

const debug = logger.extend('HTMLAsset');

const AssetInspector = dynamic(
  () => import(/* webpackChunkName: "AssetInspector" */ '../AssetInspector'),
  { loading: () => null }
);

const Wrapper = styled.div`
  position: relative;
  display: flex;
  ${colorStyles}
  ${highlightStyles}
`;

const Content = styled.div`
  width: 100%;
`;

export default function HTMLAsset({ asset, ...rest }) {
  const { highlightAssets } = useContext(AssetProvider.Context);
  const backgroundColor = asset.options.bgColor || undefined;
  const textColor =
    // Encourage migration to `textColor` spelling instead of `textcolor`, but
    // support both.
    asset.options.customVars.textColor ||
    asset.options.customVars.textcolor ||
    undefined;
  const title = highlightAssets
    ? `${asset.container.name}: ${asset.label || asset.id}`
    : undefined;

  if (debug.enabled) {
    const invalidProp = ['background', 'aspectRatio'].find(
      (prop) => rest[prop] != null
    );
    if (invalidProp) {
      debug(
        'HTML asset %c%s%c rendered with prop %c%s%c,%c but this only applies to image assets.',
        'font-weight: bold',
        asset.container.name,
        '',
        'font-weight: bold; white-space: nowrap',
        invalidProp,
        'font-weight: normal',
        ''
      );
    }
  }

  return (
    <Wrapper
      backgroundColor={backgroundColor}
      highlightAssets={highlightAssets}
      textColor={textColor}
      title={title}
      {...rest}
    >
      <Content
        // Use an attribute selector with this to target the HTML with CSS.
        data-asset-html=""
        dangerouslySetInnerHTML={{ __html: asset.options.htmlText }}
      />
      {highlightAssets ? <AssetInspector asset={asset} /> : null}
    </Wrapper>
  );
}

HTMLAsset.propTypes = {
  asset: assetPropType,
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import type { RmaResponseDto } from '../../../../techstyle-shared/react-accounts';
import {
  ReturnAction,
  ReturnCondition,
  ReturnReason,
  useAccountActions,
} from '../../../../techstyle-shared/react-accounts';
import {
  OrderRmaDto,
  RmaItemDto,
} from '../../../../techstyle-shared/react-types';
import {
  createAction,
  createReducer,
} from '../../../../techstyle-shared/redux-core';
import AccountOrderDetails from '../AccountOrderDetails';
import type { SerializedOrderLineItem } from '../AccountOrderDetails';
import scrollToRef from '../scrollToRef';
import { createContext } from '../utils/createContext';

import { CancelButton, NextButton, PrevButton, SubmitButton } from './Buttons';
import * as RMAOptions from './RMAOptions';
import * as RMAOptionsItem from './RMAOptionsItem';
import * as RMASelectionItem from './RMASelectionItem';

enum RMA_STEPS {
  INACTIVE,
  SELECT_ITEMS,
  SELECT_OPTIONS,
  CONFIRMATION,
}

enum RMA_PROCESSES {
  EXCHANGE = 'EXCHANGE',
  RETURN = 'RETURN',
}

export type RMAAction = (typeof ReturnAction)[keyof typeof ReturnAction];
export type RMAReason = (typeof ReturnReason)[keyof typeof ReturnReason];

export type PartialSKU = {
  availableQuantity: number;
  masterProductId: number;
  productId: number;
};

const setRMAStepAction = createAction<RMA_STEPS, 'setRMAStep'>('setRMAStep');
const selectRMAItemAction = createAction<
  SerializedOrderLineItem,
  'selectRMAItem'
>('selectRMAItem');
const unselectRMAItemAction = createAction<number, 'unselectRMAItem'>(
  'unselectRMAItem'
);
const selectRMAProcessAction = createAction<
  { orderLineId: number; rmaProcess: RMA_PROCESSES },
  'selectRMAProcess'
>('selectRMAProcess');
const selectRMAActionAction = createAction<
  { orderLineId: number; actionId: RMAAction },
  'selectRMAAction'
>('selectRMAAction');
const selectRMAReasonAction = createAction<
  { orderLineId: number; reasonId: RMAReason },
  'selectRMAReason'
>('selectRMAReason');
const selectRMASKUAction = createAction<
  { orderLineId: number; sku: PartialSKU },
  'selectRMASKU'
>('selectRMASKU');
const setRMADataAction = createAction<RmaResponseDto, 'setRMAData'>(
  'setRMAData'
);

type RMAItemState = {
  rmaProcess?: RMA_PROCESSES;
  orderLineId: number;
  conditionId: (typeof ReturnCondition)[keyof typeof ReturnCondition];
  actionId?: RMAAction;
  reasonId?: RMAReason;
  data: SerializedOrderLineItem;
  selectedSKU?: PartialSKU;
};

export type RMAItem = RMAItemState;

type RMAItems = Record<number, RMAItem>;

type RMAFlowContext = {
  step: RMA_STEPS;
  reset: () => void;
  setStep: (step: RMA_STEPS) => void;
  selectRMAItem: (item: SerializedOrderLineItem) => void;
  unselectRMAItem: (orderLineId: number) => void;
  selectRMAProcess: (orderLineId: number, rmaProcess: RMA_PROCESSES) => void;
  selectRMAAction: (orderLineId: number, actionId: RMAAction) => void;
  selectRMAReason: (orderLineId: number, reasonId: RMAReason) => void;
  selectRMASKU: (orderLineId: number, sku: PartialSKU) => void;
  submitRMA: (options?: { onError?: (error: any) => void }) => void;
  submitting: boolean;
  submissionError?: any;
  rmaData?: RmaResponseDto;
  submitAllowed: boolean;
  rmaEligible: boolean;
  rmaItems: RMAItems;
  returnByDate: Date | null;
  isWithinReturnWindow: boolean;
  isRefundToOriginalPaymentMethodEligible: boolean;
};

const [useContext, Context] = createContext<RMAFlowContext>('RMAFlow');

type RMAReducerState = {
  step: RMA_STEPS;
  rmaData?: RmaResponseDto;
  rmaItems: RMAItems;
};

const initialRMAReducerState: RMAReducerState = {
  step: RMA_STEPS.INACTIVE,
  rmaItems: {} as RMAItems,
};

const rmaReducer = createReducer(initialRMAReducerState, (builder) =>
  builder
    .addCase(setRMAStepAction, (state, action) => {
      if (action.payload in RMA_STEPS) {
        // Reset back to the initial state when going back to "inactive"
        // That way any selection of items to RMA is not kept when going back to order
        // detail view then back again to RMA flow
        if (action.payload === RMA_STEPS.INACTIVE) {
          return initialRMAReducerState;
        }
        // When pressing the back button on the select option steps, the options should be reset, but
        // the selection state should be preserved
        if (
          state.step === RMA_STEPS.SELECT_OPTIONS &&
          action.payload === RMA_STEPS.SELECT_ITEMS
        ) {
          Object.keys(state.rmaItems).forEach((orderLineId) => {
            const orderId = parseInt(orderLineId, 10);
            state.rmaItems[orderId].actionId = undefined;
            state.rmaItems[orderId].reasonId = undefined;
            state.rmaItems[orderId].selectedSKU = undefined;
            state.rmaItems[orderId].rmaProcess = undefined;
          });
          state.step = action.payload;
        }
        state.step = action.payload;
      }
    })
    .addCase(selectRMAItemAction, (state, action) => {
      state.rmaItems[action.payload.orderLineId] = {
        orderLineId: action.payload.orderLineId,
        conditionId: ReturnCondition.NEW,
        data: action.payload,
      };
    })
    .addCase(unselectRMAItemAction, (state, action) => {
      delete state.rmaItems[action.payload];
    })
    .addCase(selectRMAProcessAction, (state, action) => {
      const hasItem = state.rmaItems[action.payload.orderLineId];
      if (hasItem) {
        state.rmaItems[action.payload.orderLineId].rmaProcess =
          action.payload.rmaProcess;
        state.rmaItems[action.payload.orderLineId].actionId = undefined;
        if (action.payload.rmaProcess === RMA_PROCESSES.RETURN) {
          state.rmaItems[action.payload.orderLineId].selectedSKU = undefined;
        }
      }
    })
    .addCase(selectRMAActionAction, (state, action) => {
      const hasItem = state.rmaItems[action.payload.orderLineId];
      if (hasItem) {
        state.rmaItems[action.payload.orderLineId].actionId =
          action.payload.actionId;
      }
    })
    .addCase(selectRMAReasonAction, (state, action) => {
      const hasItem = state.rmaItems[action.payload.orderLineId];
      if (hasItem) {
        state.rmaItems[action.payload.orderLineId].reasonId =
          action.payload.reasonId;
      }
    })
    .addCase(selectRMASKUAction, (state, action) => {
      if (
        state.rmaItems[action.payload.orderLineId].rmaProcess ===
        RMA_PROCESSES.EXCHANGE
      ) {
        const hasItem = state.rmaItems[action.payload.orderLineId];
        if (hasItem) {
          const { productId: currentProductId } =
            state.rmaItems[action.payload.orderLineId].data;
          const { productId, availableQuantity } = action.payload.sku;

          state.rmaItems[action.payload.orderLineId].selectedSKU =
            action.payload.sku;

          if (availableQuantity > 0) {
            if (productId === currentProductId) {
              state.rmaItems[action.payload.orderLineId].actionId =
                ReturnAction.EXCHANGE_RESHIP_SAME;
            } else {
              state.rmaItems[action.payload.orderLineId].actionId =
                ReturnAction.EXCHANGE_CHANGE_COLOR_SIZE;
            }
          } else {
            state.rmaItems[action.payload.orderLineId].actionId = undefined;
          }
        }
      }
    })
    .addCase(setRMADataAction, (state, action) => {
      state.rmaData = action.payload;
    })
);

type RMAFlowProps = {
  daysToReturn?: number;
  daysForOriginalPaymentMethodReturn?: number;
  onRMAComplete?: (rmaData: RmaResponseDto) => void;
};

function Root({
  children,
  daysToReturn = 60,
  daysForOriginalPaymentMethodReturn = daysToReturn,
  onRMAComplete,
}: React.PropsWithChildren<RMAFlowProps>) {
  const accountActions = useAccountActions();
  const [{ step, rmaItems, rmaData }, dispatch] = useReducer(
    rmaReducer,
    initialRMAReducerState
  );
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<any>();
  const { orderData } = AccountOrderDetails.useContext();
  const topRef = useRef<HTMLDivElement>(null);

  // Scroll to top when the step changes
  useEffect(() => {
    if (topRef.current) {
      scrollToRef(topRef);
    }
  }, [step]);

  const returnByDate = useMemo(() => {
    if (!orderData) {
      return null;
    }
    const returnBy = new Date(orderData.datePlaced);
    returnBy.setDate(returnBy.getDate() + daysToReturn);
    return returnBy;
  }, [orderData, daysToReturn]);

  const isWithinReturnWindow = useMemo(() => {
    if (!returnByDate) {
      return true;
    }

    return returnByDate && returnByDate > new Date();
  }, [returnByDate]);

  const isRefundToOriginalPaymentMethodEligible = useMemo(() => {
    if (!orderData) {
      return false;
    }
    const returnBy = new Date(orderData.datePlaced);
    returnBy.setDate(returnBy.getDate() + daysForOriginalPaymentMethodReturn);
    return returnBy > new Date();
  }, [orderData, daysForOriginalPaymentMethodReturn]);

  const rmaEligible = useMemo(() => {
    if (!orderData?.orderLines?.length) {
      return false;
    }

    return orderData?.orderLines.some((item) => {
      if (item.bundleItems) {
        return item.bundleItems.some((bundleItem) => bundleItem.isReturnable);
      }

      return item.isReturnable;
    });
  }, [orderData]);

  const setStep = useCallback((step: RMA_STEPS) => {
    dispatch({
      type: setRMAStepAction,
      payload: step,
    });
  }, []);

  const reset = useCallback(() => {
    setStep(RMA_STEPS.INACTIVE);
  }, [setStep]);

  const selectRMAItem = useCallback((item: SerializedOrderLineItem) => {
    dispatch({
      type: selectRMAItemAction,
      payload: item,
    });
  }, []);

  const unselectRMAItem = useCallback((orderLineId: number) => {
    dispatch({
      type: unselectRMAItemAction,
      payload: orderLineId,
    });
  }, []);

  const selectRMAProcess = useCallback(
    (orderLineId: number, rmaProcess: RMA_PROCESSES) => {
      dispatch({
        type: selectRMAProcessAction,
        payload: {
          orderLineId,
          rmaProcess,
        },
      });
    },
    []
  );

  const selectRMAAction = useCallback(
    (orderLineId: number, actionId: RMAAction) => {
      dispatch({
        type: selectRMAActionAction,
        payload: {
          orderLineId,
          actionId,
        },
      });
    },
    []
  );

  const selectRMAReason = useCallback(
    (orderLineId: number, reasonId: RMAReason) => {
      dispatch({
        type: selectRMAReasonAction,
        payload: {
          orderLineId,
          reasonId,
        },
      });
    },
    []
  );

  const selectRMASKU = useCallback((orderLineId: number, sku: PartialSKU) => {
    dispatch({
      type: selectRMASKUAction,
      payload: {
        orderLineId,
        sku,
      },
    });
  }, []);

  const submitAllowed = useMemo(() => {
    if (step !== RMA_STEPS.SELECT_OPTIONS || submitting) {
      return false;
    }
    return Object.values(rmaItems).every((item) => {
      if (item.rmaProcess === RMA_PROCESSES.RETURN) {
        return item.actionId !== undefined && item.reasonId !== undefined;
      } else if (item.rmaProcess === RMA_PROCESSES.EXCHANGE) {
        const { selectedSKU, actionId, reasonId } = item;
        return actionId !== undefined &&
          reasonId !== undefined &&
          selectedSKU?.availableQuantity
          ? selectedSKU.availableQuantity > 0
          : false;
      }

      return false;
    });
  }, [rmaItems, step, submitting]);

  const submitRMA: RMAFlowContext['submitRMA'] = useCallback(
    async (options = {}) => {
      const { onError } = options;

      if (!submitAllowed || !orderData) {
        return;
      }

      setSubmitting(true);

      const items = Object.values(rmaItems).map((item) => {
        const { actionId, conditionId, orderLineId, reasonId, rmaProcess } =
          item;
        const itemData = {
          actionId,
          conditionId,
          orderLineId,
          reasonId,
        } as RmaItemDto;

        if (rmaProcess === RMA_PROCESSES.EXCHANGE && item.selectedSKU) {
          itemData.exchangeProductId = item.selectedSKU?.productId;
        }

        return itemData;
      });

      try {
        const returnData = (await accountActions.initiateReturn({
          orderId: orderData.orderId,
          items,
        } as OrderRmaDto)) as ReturnType<
          typeof accountActions.initiateReturn
        > & {
          payload:
            | (RmaResponseDto & { success: true })
            | {
                success: false;
                errorMessage: string;
                errors: Record<string, any>;
              };
        };

        if (returnData.payload) {
          if (returnData.payload.success === false) {
            setSubmissionError(returnData.payload);
            if (onError) {
              onError(returnData.payload);
            }
          } else {
            accountActions.invalidateOrderDetails(orderData.orderId);

            dispatch({
              type: setRMADataAction,
              payload: returnData.payload,
            });
            setStep(RMA_STEPS.CONFIRMATION);
            if (onRMAComplete) {
              onRMAComplete(returnData.payload);
            }
          }
        }
      } catch (error) {
        setSubmissionError(error);
        if (onError) {
          onError(error);
        }
      } finally {
        setSubmitting(false);
      }
    },
    [accountActions, onRMAComplete, orderData, rmaItems, setStep, submitAllowed]
  );

  const contextValue = useMemo(
    () => ({
      rmaEligible,
      rmaItems,
      reset,
      setStep,
      selectRMAItem,
      unselectRMAItem,
      selectRMAProcess,
      selectRMAAction,
      selectRMAReason,
      selectRMASKU,
      submitAllowed,
      submitRMA,
      submitting,
      submissionError,
      step,
      returnByDate,
      isWithinReturnWindow,
      isRefundToOriginalPaymentMethodEligible,
      rmaData,
    }),
    [
      rmaEligible,
      rmaItems,
      reset,
      setStep,
      selectRMAItem,
      unselectRMAItem,
      selectRMAProcess,
      selectRMAAction,
      selectRMAReason,
      selectRMASKU,
      submitAllowed,
      submitRMA,
      submitting,
      submissionError,
      step,
      returnByDate,
      isWithinReturnWindow,
      isRefundToOriginalPaymentMethodEligible,
      rmaData,
    ]
  );

  return (
    <Context.Provider value={contextValue}>
      <div ref={topRef} />
      {children}
    </Context.Provider>
  );
}

Root.displayName = 'RMAFlow.Root';

export {
  Root,
  useContext,
  CancelButton,
  NextButton,
  PrevButton,
  SubmitButton,
  RMASelectionItem,
  RMAOptions,
  RMAOptionsItem,
  RMA_STEPS,
  RMA_PROCESSES,
};

import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useMembership } from '../../../../techstyle-shared/react-accounts';
import {
  getSession,
  getSessionVisitorId,
} from '../../../../techstyle-shared/redux-core';
import GoogleTagManagerSnippet from '../GoogleTagManagerSnippet';
import { getMarketingInfo } from '../marketingModule';

// sha_email *
// page_type TODO: Need to figure out where we get this
// product_category TODO: This is PDP only, update when we complete PDP
// customer_gender *
// customer_id *
// customer_bucket_group *
// visitor_group *

export function SessionVariables({ dataLayerName }) {
  const visitorId = useSelector(getSessionVisitorId);
  const session = useSelector(getSession);
  // visitorID mod 20 groups
  const visitorGroup = (visitorId % 20) + 1;

  const data = !session.isServerOnly
    ? {
        visitor_id: visitorId,
        visitor_group: visitorGroup,
      }
    : null;

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

SessionVariables.propTypes = { dataLayerName: PropTypes.string };

export function MembershipVariables({ dataLayerName }) {
  const membership = useMembership();
  const { customerId, networkStatus, shaEmail } = membership;

  const data = networkStatus.isUpToDate
    ? {
        customer_bucket_group: customerId ? (customerId % 20) + 1 : null,
        customer_id: customerId || null,
        sha_email: shaEmail || null,
      }
    : null;

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

MembershipVariables.propTypes = { dataLayerName: PropTypes.string };

const MarketingDetailVariables = ({ dataLayerName }) => {
  const { pageInfo } = useSelector(getMarketingInfo);
  const data = {
    page_type: pageInfo.segmentCategory || null,
    product_category: null, // TODO: update when PDP is up.
  };

  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
};

MarketingDetailVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

// These variables need to be loaded within the dataLayer on page load for all pages
export default function SegmentGTMVariables({ dataLayerName }) {
  return (
    <>
      <SessionVariables dataLayerName={dataLayerName} />
      <MembershipVariables dataLayerName={dataLayerName} />
      <MarketingDetailVariables dataLayerName={dataLayerName} />
    </>
  );
}

SegmentGTMVariables.propTypes = { dataLayerName: PropTypes.string };

import React from 'react';

import PageTransitionProgressBar from '../layout/components/PageTransitionProgressBar';
import ErrorBoundary from '../shared/components/ErrorBoundary';

export default function pageTransitionExtension() {
  return {
    id: 'pageTransition',
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <ErrorBoundary>
              <PageTransitionProgressBar {...props} />
            </ErrorBoundary>
          </>
        );
      },
    },
  };
}

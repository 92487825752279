const supportsRaf =
  typeof window !== 'undefined' &&
  typeof window.requestAnimationFrame === 'function' &&
  typeof window.cancelAnimationFrame === 'function';

/**
 * A helper that uses `requestAnimationFrame` (if supported) to achieve the same
 * API as `setTimeout`. Some browsers deprioritize `setTimeout` callbacks during
 * certain events like scrolling, adding a great amount of delay (several
 * seconds). Thus using `requestAnimationFrame` for timeouts needed by UI
 * interactions is preferred.
 *
 * Instead of returning a handle ID, you get a function that will cancel the
 * timeout. Call the returned function with no arguments to cancel.
 */
export default function setAnimationTimeout(callback, delay, ...args) {
  const startTime = Date.now();
  if (supportsRaf) {
    let handle;

    const scheduleTick = () => {
      handle = window.requestAnimationFrame(tick);
    };

    const tick = () => {
      const elapsed = Date.now() - startTime;
      if (elapsed >= delay) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(...args);
      } else {
        scheduleTick();
      }
    };

    scheduleTick();

    return () => {
      window.cancelAnimationFrame(handle);
      return Date.now() - startTime;
    };
  } else {
    const handle = setTimeout(callback, delay, ...args);

    return () => {
      clearTimeout(handle);
      return Date.now() - startTime;
    };
  }
}

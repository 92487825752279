import { useEffect, useReducer, useMemo } from 'react';

import {
  MembershipCreditReducerAction,
  MembershipCreditReducerStatus,
} from './constants';
import useAccountActions from './useAccountActions';

const defaultInitialState = {
  data: {},
  idsByPage: {},
  status: MembershipCreditReducerStatus.IDLE,
  isInitialized: false,
};

const creditsReducer = (state, action) => {
  switch (action.type) {
    case MembershipCreditReducerAction.REQUEST:
      return {
        ...state,
        isInitialized: true,
        status: MembershipCreditReducerStatus.LOADING,
      };
    case MembershipCreditReducerAction.SUCCESS: {
      const tokensById = action.payload.tokenDetails.reduce(
        (previous, current) => {
          previous[current.membershipTokenId] = current;
          return previous;
        },
        {}
      );
      const hasNewKey = state.pageKey !== action.payload.pageKey;

      return {
        ...state,
        isInitialized: true,
        status: MembershipCreditReducerStatus.SUCCESS,
        pageCount: action.payload.pageCount,
        data: {
          ...(hasNewKey ? {} : state.data),
          ...tokensById,
        },
        pageKey: action.payload.pageKey,
        idsByPage: {
          ...(hasNewKey ? {} : state.idsByPage),
          [action.payload.page]: action.payload.tokenDetails.map(
            ({ membershipTokenId }) => membershipTokenId
          ),
        },
      };
    }
    case MembershipCreditReducerAction.ERROR:
      return {
        ...state,
        isInitialized: true,
        status: MembershipCreditReducerStatus.FAILURE,
        error: action.error,
      };
    case MembershipCreditReducerAction.CANCEL:
      return {
        ...state,
        isInitialized: true,
        status: MembershipCreditReducerStatus.IDLE,
      };
    default:
      return state;
  }
};

export default function useMembershipCredits({
  page = 1,
  pageSize = 15,
  sortDirection,
  date,
  initialState = defaultInitialState,
}) {
  const actions = useAccountActions();
  const [state, dispatch] = useReducer(creditsReducer, initialState);
  const pageSettings = useMemo(() => {
    return {
      pageSize,
      sortDirection,
      date,
    };
  }, [date, pageSize, sortDirection]);
  const pageKey = useMemo(() => JSON.stringify(pageSettings), [pageSettings]);
  const needCurrentPage = !state.idsByPage[page] || state.pageKey !== pageKey;

  useEffect(() => {
    const load = async () => {
      try {
        dispatch({ type: MembershipCreditReducerAction.REQUEST });
        const result = await actions.loadMembershipCredits({
          page,
          ...pageSettings,
        });
        dispatch({
          type: MembershipCreditReducerAction.SUCCESS,
          payload: { ...result.payload, page, pageKey },
        });
      } catch (err) {
        dispatch({ type: MembershipCreditReducerAction.ERROR });
      }
    };

    if (needCurrentPage) {
      load();
    }

    return () => {
      dispatch({ type: MembershipCreditReducerAction.CANCEL });
    };
  }, [actions, needCurrentPage, page, pageSettings, pageKey]);

  return { membershipCredits: state, dispatch };
}

import React, { useContext } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export const useImageBrowser = () => {
  return useContext(Context);
};

export default function ImageBrowserContext({ children, value }) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ImageBrowserContext.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};

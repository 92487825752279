import { format } from 'url';

import {
  createAction,
  createReducer,
  createSelector,
  createCachedSelector,
} from '../../../techstyle-shared/redux-core';
// The legacy `url` format/parse API is what Next.js itself uses, so it's what
// we must use too.
// eslint-disable-next-line node/no-deprecated-api

import logger from './logger';
import { normalizePath, RewriteMapping } from './rewritesSchema';

// we can use a placeholder here since we're only consuming parts of the url
const ORIGIN_PLACEHOLDER = 'https://example.tld';
const debug = logger.extend('dynamicRoutesModule');

export const loadRewritesRequest = createAction('routes/loadRewritesRequest');
export const loadRewritesSuccess = createAction('routes/loadRewritesSuccess');
export const loadRewritesFailure = createAction('routes/loadRewritesFailure');

export const loadRouteTypesSuccess = createAction(
  'routes/loadRouteTypesSuccess'
);

const initialState = {
  enabled: false,
  routeInfoByPath: {},
  routeTypeByPath: {},
};

function initDynamicRoutes({ routeTypePageMap, builderRoutePageMap }) {
  return (dispatch, getState, context) => {
    if (!process.browser && context.req && !context.hasPreloadedState) {
      const { rewrites, rewritesEnabled, routeInfo, builderPageModelRoutes } =
        context.req.context;

      if (rewritesEnabled && (rewrites || builderPageModelRoutes)) {
        let routeTypes = {};

        // create the routeTypes lookup which is a mapping of route to the corresponding
        // type in rewrites or builder
        if (rewrites || builderPageModelRoutes) {
          // a helper function to retrieve the routes map from our processed rewrites and builderPageModelRoutes
          const getRoutesMap = (obj) => {
            if (!obj) {
              return {};
            } // Return an empty object if obj is null or undefined.
            return Object.values(obj).reduce(
              (routes, { canonicalPath, routeType }) => {
                // FIXME: Filter out ColdFusion paths that are invalid for the React
                // app. For now, ignore anything that was normalized to `/`.
                if (canonicalPath !== '/') {
                  routes[canonicalPath] = routeType;
                }
                return routes;
              },
              {}
            );
          };

          // allow rewrites to still trump builder
          // actively deactivate rewrite routes to allow builder to trump them
          const routes = {
            ...getRoutesMap(builderPageModelRoutes),
            ...getRoutesMap(rewrites),
          };

          routeTypes = { ...routeTypes, ...routes };
        }

        dispatch(loadRouteTypesSuccess(routeTypes));
        if (routeInfo) {
          dispatch(
            loadRewritesSuccess(null, {
              entities: {
                Rewrite: {
                  [routeInfo.canonicalPath]: routeInfo,
                },
              },
            })
          );
        }
      }
    }
    if (getState().dynamicRoutes.enabled) {
      context.routeResolvers = context.routeResolvers || [];
      context.routeResolvers.push(
        createDynamicRoutesResolver({
          getState,
          routeTypePageMap,
          builderRoutePageMap,
        })
      );
    }
  };
}

export function createDynamicRoutesResolver({
  getState,
  routeTypePageMap,
  builderRoutePageMap,
}) {
  return (href, asPath) => {
    const state = getState();
    if (typeof href === 'string') {
      let url = {};

      try {
        url = new URL(href, ORIGIN_PLACEHOLDER);
      } catch (err) {
        debug('Error parsing href: %s', href);
      }

      const routeType = getRouteType(state, url.pathname);
      if (routeType) {
        const pathname =
          routeTypePageMap[routeType] || builderRoutePageMap[routeType];
        if (pathname && pathname !== url.pathname) {
          return {
            href: {
              pathname,
              hash: url.hash || undefined,
              query: Object.fromEntries(url.searchParams.entries()),
            },
            asPath: href,
          };
        }
      }
    } else {
      const { routeType, ...rest } = href;
      if (routeType) {
        // The asPath for this route type.
        const canonicalPath = getRoutePathByType(state, routeType);
        // The Next.js pathname for this route type.
        const pathname =
          routeTypePageMap[routeType] || builderRoutePageMap[routeType];
        if (canonicalPath && pathname) {
          href = { ...rest, pathname };
          if (!asPath) {
            asPath = format({ ...rest, pathname: canonicalPath });
          }
          return { href, asPath };
        } else {
          throw new Error(`No route found for routeType: ${routeType}`);
        }
      }
    }
  };
}

export function getResolveRoute() {
  return (dispatch, getState, context) => {
    const { routeResolvers = [] } = context;
    return (href, asPath) => {
      // Check if each resolver can resolve the route, then fall back to handing
      // it to Next.js as-is.
      for (const resolveRoute of routeResolvers) {
        const result = resolveRoute(href, asPath);
        if (result) {
          return result;
        }
      }
      return { href, asPath };
    };
  };
}

/**
 * Loads dynamic URL rewrite data.
 * @param {Array} pathnames The URL paths to load
 */
export function loadRewrites(pathnames = [], options = { force: false }) {
  return (dispatch, getState) => {
    const { dynamicRoutes } = getState();
    // Normalize given pathnames
    let paths = pathnames.map(normalizePath);
    // Filter out paths that have been requested previously unless any have
    // a feature that is missing feature data.
    if (!options.force) {
      paths = paths.filter((path) => {
        const hasFeature = dynamicRoutes.routeInfoByPath[path]?.label;
        const isMissingFeatureData =
          hasFeature && !dynamicRoutes.routeInfoByPath[path]?.feature;

        return !dynamicRoutes.routeInfoByPath[path] || isMissingFeatureData;
      });
    }

    // If all paths have been fetched before, then abort fetching
    if (!paths.length) {
      debug('Skipping previously-loaded routes: %o', paths);
      return;
    } else {
      debug('Loading URL rewrite data for routes: %o', paths);
    }

    return dispatch({
      bentoApi: {
        endpoint: 'rewrites/find',
        searchParams: { paths: paths.join(',') },
        schema: RewriteMapping,
        actions: [
          loadRewritesRequest,
          loadRewritesSuccess,
          loadRewritesFailure,
        ],
      },
    });
  };
}

const dynamicRoutesReducer = createReducer(initialState, {
  [loadRewritesRequest]: (state) => state,
  [loadRewritesFailure]: (state) => state,
  [loadRewritesSuccess]: (state, action) => {
    // `Rewrite` might not always exist in response, so it needs a default value
    const { Rewrite: rewrites = {} } = action.meta.entities;
    Object.keys(rewrites).forEach((pathname) => {
      state.routeInfoByPath[pathname] = {
        data: rewrites[pathname],
      };
    });
  },
  [loadRouteTypesSuccess]: (state, action) => {
    state.enabled = true;
    // This used to completely replace the contents of `routeTypeByPath` with
    // the payload instead of merging it, because (1) we only expected this
    // to happen once, during initialization, so it would never need to update
    // existing data, and (2) even if it did get called multiple times,
    // extending means an old rewrite that no longer exists can never be
    // removed - merging has the ability to add/update new data, but not delete.
    // Now that route types can be added by `assetRoutesExtension`, it needs to
    // merge like below. If rewrites are continuously updated as assets are
    // refreshed, we may need to address the deletion issue.
    state.routeTypeByPath = {
      ...state.routeTypeByPath,
      ...action.payload,
    };
  },
});

export const getRewrite = createSelector(
  [
    (state) => state.dynamicRoutes.routeInfoByPath,
    (state, pathname) => pathname,
  ],
  (routeInfoByPath, pathname) => {
    const canonicalPath = normalizePath(pathname);
    return routeInfoByPath[canonicalPath];
  }
);

export const getRouteType = createSelector(
  [
    (state) => state.dynamicRoutes.routeTypeByPath,
    (state, pathname) => pathname,
  ],
  (routeTypeByPath, pathname) => {
    const canonicalPath = normalizePath(pathname);
    return routeTypeByPath[canonicalPath];
  }
);

export const getRoutePathByType = createCachedSelector(
  [
    (state) => state.dynamicRoutes.routeTypeByPath,
    (state, routeType) => routeType,
  ],
  (routeTypeByPath, routeType) => {
    for (const canonicalPath in routeTypeByPath) {
      const routeTypeForPath = routeTypeByPath[canonicalPath];
      if (routeTypeForPath === routeType) {
        return canonicalPath;
      }
    }
  }
)((state, routeType) => routeType);

export const getRouteInfo = createSelector(
  [getRewrite],
  (rewrite) => (rewrite && rewrite.data) || null
);

export default function createDynamicRoutesModule(options) {
  return {
    id: 'dynamic-routes',
    reducerMap: {
      dynamicRoutes: dynamicRoutesReducer,
    },
    initialActions: [initDynamicRoutes(options)],
  };
}

import React from 'react';

import { FormattedMessage, useIntl } from '../../../react-intl';
import slugify from '../utils/slugify';

const DynamicFormattedMessage = FormattedMessage;

/**
 * Category Label Renderers
 */

function getCategoryMessage(category, labelOptions = {}) {
  return {
    id: `site_product_category.${slugify(category.label)}`,
    defaultMessage: category.label,
  };
}

export default function useCategoryLabels() {
  const intl = useIntl();

  return {
    // eslint-disable-next-line react/display-name
    renderLabel: (category, labelOptions = {}) => {
      return (
        <DynamicFormattedMessage
          {...getCategoryMessage(category, labelOptions)}
        />
      );
    },
    renderLabelString: (category, labelOptions = {}) => {
      return intl.formatMessage(getCategoryMessage(category, labelOptions));
    },
  };
}

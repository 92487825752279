import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useMembership } from '../../../../techstyle-shared/react-accounts';
import { Currency } from '../../../../techstyle-shared/react-intl';
import { FilterTag } from '../FilterTagsCustomFilters';
import { useOptionLabels } from '../LabelProvider';
import { LabelType } from '../LabelProvider/constants';
import { useProductFilters } from '../ProductFilterContextCustomFilters';
import ProductListingFilter from '../ProductListingFilter';
import RangeSlider from '../RangeSlider';
import { mobile } from '../styles';

function isValidNumber(number) {
  return !Number.isNaN(number) && Number.isFinite(number);
}

const ContentWrapper = styled.div`
  padding: 4px 8px 12px 8px;

  ${mobile`
    padding: 4px 16px 19px 16px;
  `};
`;

const CurrentValue = styled.p`
  margin-bottom: 12px;

  ${mobile`
    margin-bottom: 16px;
    font-size: 20px;
  `};

  ${({ currencyStyle }) => currencyStyle};
`;

const pointStyle = css`
  background: ${({ theme }) => theme.colors.coral};

  :focus {
    outline: none;
    box-shadow: 0 0 0 2px white,
      0 0 0 4px ${({ theme }) => theme.colors.focusColor};
    // If it's focused, render it above the other drag handle.
    z-index: 2;
  }
`;

const railStyle = css`
  background: ${({ theme }) => theme.colors.rules};
`;

const valueLineStyle = css`
  background: ${({ theme }) => theme.colors.coral};
`;

const PriceFilterContext = React.createContext();

export const usePriceFilter = () => {
  return useContext(PriceFilterContext);
};

const PriceCurrency = ({ currencyStyle }) => {
  const { activeValue, inputMax } = usePriceFilter();
  return (
    <CurrentValue currencyStyle={currencyStyle}>
      <Currency amount={activeValue.min} trimZeros />
      &ndash;
      <Currency amount={activeValue.max} trimZeros />
      {activeValue.max === inputMax ? '+' : null}
    </CurrentValue>
  );
};

PriceCurrency.propTypes = {
  currencyStyle: PropTypes.any,
};

const PriceRangeSlider = ({
  debounceTimeout = 100,
  step = 5,
  pointStyle: pointStyleFromProp,
  railStyle: railStyleFromProp,
  valueLineStyle: valueLineStyleFromProp,
  ...rest
}) => {
  const { activeValue, inputMax, inputMin, handleChange, setActiveValue } =
    usePriceFilter();
  return (
    <RangeSlider
      min={inputMin}
      max={inputMax}
      step={step}
      value={activeValue}
      onChange={handleChange}
      onInteraction={setActiveValue}
      debounceTimeout={debounceTimeout}
      pointStyle={pointStyleFromProp || pointStyle}
      railStyle={railStyleFromProp || railStyle}
      valueLineStyle={valueLineStyleFromProp || valueLineStyle}
      {...rest}
    />
  );
};

PriceRangeSlider.propTypes = {
  debounceTimeout: PropTypes.number,
  pointStyle: PropTypes.any,
  railStyle: PropTypes.any,
  step: PropTypes.number,
  valueLineStyle: PropTypes.any,
};

const getMin = ({ selectedFilters, isVip }) =>
  isVip
    ? selectedFilters.saleUnitPriceMinimum
    : selectedFilters.vipUnitPriceMinimum;

const getMax = ({ selectedFilters, isVip }) =>
  isVip
    ? selectedFilters.saleUnitPriceMaximum
    : selectedFilters.vipUnitPriceMaximum;

export const PriceFilterContent = ({
  children,
  filterField,
  selectedFilters,
  onChange,
  getActiveMin = getMin,
  getActiveMax = getMax,
  ...rest
}) => {
  const { isVip } = useMembership();
  const [minOption, maxOption] = filterField.items;

  const inputMin = minOption.value;
  const inputMax = maxOption.value;

  const priceFilterOptionMin = getActiveMin({ selectedFilters, isVip });
  const priceFilterOptionMax = getActiveMax({ selectedFilters, isVip });

  // The live value to show in the filter, even if the ProductBrowser state
  // hasn't been updated with it yet (or is null).
  const [currentMin, setMin] = useState(inputMin);
  const [currentMax, setMax] = useState(inputMax);
  const activeValue = useMemo(
    () => ({ min: currentMin, max: currentMax }),
    [currentMin, currentMax]
  );

  const handleChange = useCallback(
    ({ min, max }) => {
      onChange({
        filterField: filterField,
        newSelectedFilters: {
          // Unset the min or max if they come back as the input min or max,
          // respectively (use unbounded range instead).
          min: min == null || min <= inputMin ? null : min,
          max: max == null || max >= inputMax ? null : max,
        },
      });
    },
    [filterField, onChange, inputMax, inputMin]
  );

  const setActiveValue = useCallback(({ min, max }) => {
    setMin(min);
    setMax(max);
  }, []);
  // It's possible for the `selectedFilters` to change the filter value outside
  // of this specific component instance changing it (desktop vs. mobile filter,
  // for example) - which means we need to keep it in sync if it changes.
  useEffect(() => {
    setMin(priceFilterOptionMin || inputMin);
    setMax(priceFilterOptionMax || inputMax);
  }, [inputMax, inputMin, priceFilterOptionMax, priceFilterOptionMin]);

  const value = useMemo(
    () => ({
      min: currentMin,
      max: currentMax,
      activeValue,
      inputMax,
      inputMin,
      handleChange,
      setActiveValue,
    }),
    [
      currentMin,
      currentMax,
      activeValue,
      inputMax,
      inputMin,
      handleChange,
      setActiveValue,
    ]
  );

  return (
    <ContentWrapper {...rest}>
      <PriceFilterContext.Provider value={value}>
        {children}
      </PriceFilterContext.Provider>
    </ContentWrapper>
  );
};

PriceFilterContent.propTypes = {
  children: PropTypes.node,
  // The current filter field (ie filter setting)
  filterField: PropTypes.object,
  // Given the current set of selected filters, returns
  // the currently selected maximum value fo the price
  // range filter.
  getActiveMax: PropTypes.func,
  // Given the current set of selected filters, returns
  // the currently selected minimum value fo the price
  // range filter.
  getActiveMin: PropTypes.func,
  // Called when the value of the price range filter changes
  // with the currently selected filters and the filter setting.
  onChange: PropTypes.func,
  // The currently active filters, pulling from ProductFilterContext.
  selectedFilters: PropTypes.object,
};

PriceFilterContent.Currency = PriceCurrency;
PriceFilterContent.RangeSlider = PriceRangeSlider;

export const PriceRangeComponent = ({
  filterContentComponent: FilterContentComponent = PriceFilterContent,
  filterField,
  listingFilterComponent: ListingFilterComponent = ProductListingFilter,
  setSelectedFilters,
  ...rest
}) => {
  const { isVip } = useMembership();

  const handlePriceFilterChange = useCallback(
    ({ newSelectedFilters }) => {
      const priceFilterOptionMin = isVip
        ? 'saleUnitPriceMinimum'
        : 'vipUnitPriceMinimum';
      const priceFilterOptionMax = isVip
        ? 'saleUnitPriceMaximum'
        : 'vipUnitPriceMaximum';
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [priceFilterOptionMin]: newSelectedFilters.min,
        [priceFilterOptionMax]: newSelectedFilters.max,
      }));
    },
    [isVip, setSelectedFilters]
  );

  const renderFilter = useCallback((props) => {
    return (
      <FilterContentComponent {...props}>
        <FilterContentComponent.Currency />
        <FilterContentComponent.RangeSlider />
      </FilterContentComponent>
    );
  }, []);

  return (
    <ListingFilterComponent
      filterField={filterField}
      renderFilter={renderFilter}
      onChange={handlePriceFilterChange}
      {...rest}
    />
  );
};

PriceRangeComponent.propTypes = {
  filterContentComponent: PropTypes.node,
  filterField: PropTypes.arrayOf(PropTypes.object),
  listingFilterComponent: PropTypes.node,
  setSelectedFilters: PropTypes.func,
};

export const PriceRangeFilterTag = ({
  filterSetting,
  getActiveMin = getMin,
  getActiveMax = getMax,
  selectedFilters,
  ...rest
}) => {
  const optionLabelCtx = useOptionLabels();
  const { isVip } = useMembership();
  const { setSelectedFilters } = useProductFilters();

  const labelOptions = {
    labelType: LabelType.PRODUCT_FILTER_TAG,
  };

  const min = getActiveMin({ isVip, selectedFilters });

  const max = getActiveMax({ isVip, selectedFilters });

  const selectedOption = {
    value: {
      min,
      max,
    },
  };

  const shouldShowFilterTag = min != null || max != null;

  return shouldShowFilterTag ? (
    <FilterTag
      filterField={filterSetting}
      label={optionLabelCtx.renderLabel(
        filterSetting,
        selectedOption,
        labelOptions
      )}
      labelString={optionLabelCtx.renderLabelString(
        filterSetting,
        selectedOption,
        labelOptions
      )}
      filterValue="range"
      onDismiss={() => {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          vipUnitPriceMinimum: null,
          vipUnitPriceMaximum: null,
          saleUnitPriceMinimum: null,
          saleUnitPriceMaximum: null,
        }));
      }}
      {...rest}
    />
  ) : null;
};

PriceRangeFilterTag.propTypes = {
  filterSetting: PropTypes.object,
  // Given the current set of selected filters, returns
  // the currently selected maximum value fo the price
  // range filter.
  getActiveMax: PropTypes.func,
  // Given the current set of selected filters, returns
  // the currently selected minimum value fo the price
  // range filter.
  getActiveMin: PropTypes.func,
  selectedFilters: PropTypes.object,
};

export const getPriceRangeFilter = () => ({
  label: 'Price',
  field: 'price',
  items: [
    { value: 0, label: 'Minimum' },
    { value: 40, label: 'Maximum' },
  ],
  getDefaultValue: ({ filters }) => ({
    vipUnitPriceMinimum: null,
    vipUnitPriceMaximum: null,
    saleUnitPriceMinimum: null,
    saleUnitPriceMaximum: null,
    ...filters,
  }),
  fromUrlParams: ({ urlParams, filters }) => {
    let vipUnitPriceMinimum = filters.vipUnitPriceMinimum;
    const priceMinString = urlParams.get('price_min');
    if (priceMinString) {
      const parsedPriceMin = parseFloat(priceMinString);
      if (isValidNumber(parsedPriceMin) && parsedPriceMin >= 0) {
        vipUnitPriceMinimum = parsedPriceMin;
      }
    }
    let vipUnitPriceMaximum = filters.vipUnitPriceMaximum;
    const priceMaxString = urlParams.get('price_max');
    if (priceMaxString) {
      const parsedPriceMax = parseFloat(priceMaxString);
      if (isValidNumber(parsedPriceMax) && parsedPriceMax >= 0) {
        vipUnitPriceMaximum = parsedPriceMax;
      }
    }
    let saleUnitPriceMinimum = filters.saleUnitPriceMinimum;
    const salePriceMinString = urlParams.get('sale_price_min');
    if (salePriceMinString) {
      const parsedSalePriceMin = parseFloat(salePriceMinString);
      if (isValidNumber(parsedSalePriceMin) && parsedSalePriceMin >= 0) {
        saleUnitPriceMinimum = parsedSalePriceMin;
      }
    }
    let saleUnitPriceMaximum = filters.saleUnitPriceMaximum;
    const salePriceMaxString = urlParams.get('sale_price_max');
    if (salePriceMaxString) {
      const parsedSalePriceMax = parseFloat(salePriceMaxString);
      if (isValidNumber(parsedSalePriceMax) && parsedSalePriceMax >= 0) {
        saleUnitPriceMaximum = parsedSalePriceMax;
      }
    }
    return {
      ...filters,
      vipUnitPriceMinimum: vipUnitPriceMinimum,
      vipUnitPriceMaximum: vipUnitPriceMaximum,
      saleUnitPriceMinimum: saleUnitPriceMinimum,
      saleUnitPriceMaximum: saleUnitPriceMaximum,
    };
  },
  toUrlParams: ({ urlParams, filters, baseFilters }) => {
    const {
      vipUnitPriceMinimum,
      vipUnitPriceMaximum,
      saleUnitPriceMinimum,
      saleUnitPriceMaximum,
    } = filters;
    const params = { ...urlParams };
    if (
      vipUnitPriceMinimum != null &&
      vipUnitPriceMinimum !== baseFilters.vipUnitPriceMinimum
    ) {
      params.price_min = vipUnitPriceMinimum.toString();
    }
    if (
      vipUnitPriceMaximum != null &&
      vipUnitPriceMaximum !== baseFilters.vipUnitPriceMaximum
    ) {
      params.price_max = vipUnitPriceMaximum.toString();
    }
    if (
      saleUnitPriceMinimum != null &&
      saleUnitPriceMinimum !== baseFilters.saleUnitPriceMinimum
    ) {
      params.sale_price_min = saleUnitPriceMinimum.toString();
    }
    if (
      saleUnitPriceMaximum != null &&
      saleUnitPriceMaximum !== baseFilters.saleUnitPriceMaximum
    ) {
      params.sale_price_max = saleUnitPriceMaximum.toString();
    }
    return { ...params };
  },
  getCount: ({ currentCount, filters }) => {
    const {
      vipUnitPriceMinimum,
      vipUnitPriceMaximum,
      saleUnitPriceMinimum,
      saleUnitPriceMaximum,
    } = filters;
    if (
      vipUnitPriceMinimum != null ||
      vipUnitPriceMaximum != null ||
      saleUnitPriceMinimum != null ||
      saleUnitPriceMaximum != null
    ) {
      // Even though there are two fields, price range counts as one filter.
      return currentCount + 1;
    }
    return currentCount;
  },
  FilterTagComponent: PriceRangeFilterTag,
  FilterComponent: PriceRangeComponent,
});

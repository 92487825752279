import logger from './logger';

const debug = logger.extend('getDerivedMembershipValues');

export const MembershipStatusCode = {
  PENDING: 3925,
  PROSPECTIVE: 3926,
  ACTIVE: 3930,
  CANCELLED: 3940,
  CANCELLED_PASSIVE: 3941,
  CANCELLED_RECREATED: 3942,
};

export default function getDerivedMembershipValues({
  statusCode,
  membershipLevelGroupId,
}) {
  let isLead = false;
  let isPaygo = false; // Still expect `isLead` to be true for these.
  let isDowngraded = false; // Was formerly a VIP.

  let isVip = false; // Covers all VIP levels.
  let isClassicVip = false; // Legacy VIP status.
  let isActiveVip = false;
  let isGold = false;
  let isElite = false; // Alias for Gold.
  let isDiamond = false;
  let isDiamondElite = false; // Alias for Diamond.

  let isHardCancel = false;
  let isSoftCancel = false;
  let isPassiveCancel = false;
  let isCancelled = false; // Whether any of the above cancel states are true.

  let isBogus = false; // Whether anything seems amiss.

  if (statusCode != null) {
    switch (statusCode) {
      case MembershipStatusCode.PENDING:
        // This means Paygo users also count as Leads. If you want non-Paygo
        // Leads, do `isLead && !isPaygo`.
        isLead = true;
        switch (membershipLevelGroupId) {
          case 100:
            // Just a normal lead.
            break;
          case 200:
            isPaygo = true;
            break;
          case 300:
            isPaygo = true;
            isDowngraded = true;
            break;
          default:
            isBogus = true;
            debug(
              '%c!%c Encountered membership level inconsistent with status! statusCode: %o, membershipLevelGroupId: %o',
              'color: red',
              '',
              statusCode,
              membershipLevelGroupId
            );
        }
        break;
      case MembershipStatusCode.ACTIVE:
        isVip = true;
        switch (membershipLevelGroupId) {
          case 400:
            isClassicVip = true;
            break;
          case 500:
            isActiveVip = true;
            break;
          case 1000:
            isGold = isElite = true;
            break;
          case 2000:
            isDiamond = isDiamondElite = true;
            break;
          default:
            isBogus = true;
            debug(
              '%c!%c Encountered membership level inconsistent with status! statusCode: %o, membershipLevelGroupId: %o',
              'color: red',
              '',
              statusCode,
              membershipLevelGroupId
            );
        }
        break;
      case MembershipStatusCode.CANCELLED:
        isCancelled = true;
        isHardCancel = true;
        break;
      case MembershipStatusCode.CANCELLED_PASSIVE:
        isCancelled = true;
        isSoftCancel = isPassiveCancel = true;
        break;
      default:
        isBogus = true;
        debug(
          '%c!%c Encountered invalid membership status! statusCode: %o',
          'color: red',
          '',
          statusCode
        );
    }
  }

  let membershipLevelGroup = 'visitor';
  if (isElite || isDiamondElite) {
    membershipLevelGroup = 'elite';
  } else if (isVip) {
    membershipLevelGroup = 'vip';
  } else if (isLead) {
    membershipLevelGroup = 'lead';
  }

  return {
    isLead,
    isPaygo,
    isDowngraded,
    isVip,
    isClassicVip,
    isActiveVip,
    isGold,
    isElite,
    isDiamond,
    isDiamondElite,
    isHardCancel,
    isSoftCancel,
    isPassiveCancel,
    isCancelled,
    isBogus,
    membershipLevelGroup,
  };
}

import React, { useState, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import useBreakpoint from '../useBreakpoint';
import useId from '../useId';

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
  padding: 0;

  ${({ buttonStyle }) => buttonStyle};
`;

const Text = styled.span`
  padding-right: 5px;
`;

function ShowMoreText({
  maxLength,
  mobileMaxLength = Math.floor(maxLength / 2),
  buttonStyle,
  text,
  ...rest
}) {
  const { mobile } = useBreakpoint();
  const textId = useId();
  const [isOpen, setIsOpen] = useState(false);

  const exceededMaxLength = useMemo(() => {
    if (mobile) {
      return text.length > mobileMaxLength;
    }
    return text.length > maxLength;
  }, [maxLength, mobile, mobileMaxLength, text.length]);

  const toggleOpen = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const splitLongText = useCallback(() => {
    const ellipsis = String.fromCharCode(0x2026);
    if (mobile) {
      return text.slice(0, mobileMaxLength).concat(ellipsis);
    }

    return text.slice(0, maxLength).concat(ellipsis);
  }, [maxLength, mobile, mobileMaxLength, text]);

  const renderedText = useMemo(() => {
    if (isOpen || !exceededMaxLength) {
      return text;
    } else {
      return splitLongText();
    }
  }, [exceededMaxLength, isOpen, splitLongText, text]);

  return (
    <>
      <span
        // Describes complete text for accessibility and seo purposes
        hidden
        id={textId}
      >
        {text}
      </span>

      <Text data-testid="truncated-text" aria-describedby={textId} {...rest}>
        {renderedText}
      </Text>
      {exceededMaxLength && (
        <ToggleButton
          onClick={toggleOpen}
          buttonStyle={buttonStyle}
          data-testid="show-more-button"
          aria-hidden
        >
          {isOpen ? (
            <FormattedMessage
              id="site_pdp.show_less"
              defaultMessage="Show Less"
            />
          ) : (
            <FormattedMessage
              id="site_pdp.show_more"
              defaultMessage="Show More"
            />
          )}
        </ToggleButton>
      )}
    </>
  );
}

ShowMoreText.propTypes = {
  /* Styled components css */
  buttonStyle: PropTypes.any,

  /* Max length for the string before showing the see more button */
  maxLength: PropTypes.number.isRequired,

  /* Max length for the string on mobile.
   Defaults to 50% of the max length if not provided */
  mobileMaxLength: PropTypes.number,

  /* Text to be truncated */
  text: PropTypes.string.isRequired,

  /* Styled components css */
  textStyle: PropTypes.any,
};

export default ShowMoreText;

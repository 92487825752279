export const ProductType = {
  NORMAL: 1,
  OFFER_GIFT: 2,
  AUTOSHIP_GIFT: 3,
  FREE_SAMPLE: 4,
  GIFT_CERTIFICATE: 5,
  FLYER: 6,
  CANDIDATE_PRODUCT: 7,
  MEMBERSHIP_GIFT: 8,
  MEMBERSHIP_GWP: 9,
  UPSELL: 10,
  MEMBERSHIP_REWARD_POINTS_ITEM: 11,
  FREE_THIRD_PARTY_SUBSCRIPTION: 12,
  CROSS_SELL: 13,
  BUNDLE: 14,
  BUNDLE_COMPONENT: 15,
  DIRECTED_INSERT: 16,
  BOX_FEE: 17,
  MEMBERSHIP_FEE_PLACEHOLDER: 18,
  BAG_FEE: 19,
  GIFT_CARD: 20,
  BYO: 21,
};

// Matches GraphQL Product `Exclusive` enum.
export const ProductUserStatus = {
  LEAD_ONLY: 'lead',
  VIP_ONLY: 'vip',
  ELITE_ONLY: 'elite',
  POSTREG_ONLY: 'postReg',
  VIP_ELITE_ONLY: 'vipElite',
  VIP_EXCLUSIVE_ONLY: 'vipExclusive',
};

export const ProductStatusCode = {
  INITIATED: 1000,
  CREATING: 1002,
  NEW: 1009,
  ACTIVE: 1010,
  BACKORDERED: 1011,
  INACTIVE: 1020,
  UNAVAILABLE: 1021,
  DISCONTINUED: 1022,
  REMOVED: 1099,
};

export const PromoType = {
  NORMAL: 1, // not used?
  ITEM_DISCOUNT: 2,
  BOGO: 3,
  BOGO_WITH_DISCOUNT: 4,
  CART_WITH_MIN_SUBTOTAL: 5,
  ITEM_WITH_MIN_SUBTOTAL: 6,
  ACTIVE_MEMBER: 7, // not used?
  LOYALTY_MULTIPLIER: 8,
  BUY_X_GET_Y_OFF: 9,
  BUY_X_GET_EACH_Y: 10,
  EMBROIDERY_DISCOUNT: 11,
};

export const MembershipPromoType = {
  CONVERSION: 1,
  EARLY_CONVERSION: 2,
  RETENTION: 3,
  SALE_VIP: 4,
  PURCHASING: 5,
  SALE_ALL: 6,
  NO_SKIP_SUBSCRIBER: 7,
  SALE_AUTO_INSERT: 8,
  SALE_PAYG: 9,
  DEV_DEFINED: 10,
};

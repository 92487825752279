import React, { useCallback, useContext } from 'react';

import config from 'config';

import { useDomain } from '../../../techstyle-shared/redux-core';

import useIntl from './useIntl';

export const CurrencyFormatContext = React.createContext();

export default function useCurrency() {
  const { tld } = useDomain();
  const { locale: siteLocale } = useIntl();

  // NOTE: CurrencyFormatProvider itself uses `useCurrency`, so make sure to
  // support no context value.
  const currencyFormat = useContext(CurrencyFormatContext);
  const currencies = config.get('public.intl.currencies');
  const currency = currencies[tld];

  const formatCurrency = useCallback(
    (amount, options = {}) => {
      const {
        locale: currencyLocaleFromContext,
        prefix,
        prefixString: prefixStringFromContext,
        suffix,
        suffixString: suffixStringFromContext,
        trimZeros: trimZerosFromContext,
        ...contextFormatOptions
      } = currencyFormat;

      const {
        locale = currencyLocaleFromContext || siteLocale,
        prefixString = prefixStringFromContext,
        suffixString = suffixStringFromContext,
        trimZeros = trimZerosFromContext,
        ...formatOptions
      } = options;

      const finalOptions = {
        ...contextFormatOptions,
        ...formatOptions,
      };

      if (trimZeros && amount % 1 === 0) {
        finalOptions.minimumFractionDigits = 0;
      }

      // We can't use `narrowSymbol` for `currencyDisplay` because it's not
      // supported in Node and some browsers like Safari. So our next best
      // option is to change the `locale` used for currencies so that the
      // output when `symbol` is used is correct. But react-intl's
      // `formatNumber` doesn't allow overriding the locale, so we need to
      // use the `Intl.NumberFormat` API directly.
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        ...finalOptions,
      });
      const currencyString = formatter.format(amount);

      return `${prefixString}${currencyString}${suffixString}`;
    },
    [currency, currencyFormat, siteLocale]
  );

  return {
    currency,
    formatCurrency,
    ...currencyFormat,
  };
}

import config from 'config';

import { useIntl } from '../../../../techstyle-shared/react-intl';

export type useCdnImagePathOptions = {
  cdnBaseUrl?: string;
};

export default function useCdnImagePath(options: useCdnImagePathOptions = {}) {
  let { cdnBaseUrl } = options;
  if (!cdnBaseUrl) {
    if (!cdnBaseUrl && config.has('public.cdn.baseUrl')) {
      cdnBaseUrl = config.get('public.cdn.baseUrl');
    } else if (!cdnBaseUrl && config.has('public.cdn')) {
      cdnBaseUrl = config.get('public.cdn');
    } else {
      cdnBaseUrl = '';
    }
  }

  const intl = useIntl();
  const getCdnRootPath = (path: string) => {
    return `${cdnBaseUrl}/${path}`;
  };
  const getCdnImagePath = ({
    path,
    localize = 'global',
    folder,
    suffix = '',
  }: {
    path: string;
    localize?: string | boolean;
    folder?: string;
    suffix?: string;
  }) => {
    if (localize === true) {
      localize = intl.locale.replace('-', '_');
    }
    return getCdnRootPath(
      `media/images/${localize ? `${localize}/` : ''}${
        folder ? `${folder}/` : ''
      }${path}${suffix}.jpg`
    );
  };
  return {
    cdnBaseUrl,
    getCdnImagePath,
    getCdnRootPath,
  };
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useReviewSortLabels } from '../LabelProvider';
import { LabelType } from '../LabelProvider/constants';
import RadioButton from '../RadioButton';
import useId from '../useId';
import useTheme from '../useTheme';

const DefaultRadioIcon = styled.span`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black};
`;

const RadioItemWrapper = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
`;

const RadioItemLabel = styled.label`
  margin-left: 4px;
  ${({ radioItemLabelStyle }) => radioItemLabelStyle};

  ${({ isDisabled, theme }) => {
    if (isDisabled) {
      return { color: theme.colors.textDisabled };
    }
  }};

  ${({ isChecked, radioItemCheckedLabelStyle }) => {
    if (isChecked) {
      return radioItemCheckedLabelStyle;
    }
  }};
`;

const labelOptions = {
  whiteSpace: 'nowrap',
  labelType: LabelType.REVIEWS_SORT_DROPDOWN,
};

const SortRadioItem = ({
  option,
  isChecked,
  onChangeHandler,
  radioItemLabelStyle,
  radioItemCheckedLabelStyle,
  radioIcon: RadioIcon,
  radioButtonProps,
  isDisabled,
  ...rest
}) => {
  const theme = useTheme();
  const radioButtonId = useId();
  const sortLabelCtx = useReviewSortLabels();

  return (
    <RadioItemWrapper {...rest}>
      <RadioButton
        checkmarkIcon={RadioIcon || DefaultRadioIcon}
        id={radioButtonId}
        value={option.value}
        checked={isChecked}
        disabled={isDisabled}
        inputStyle={{
          border: `1px solid ${
            isDisabled ? theme.colors.textDisabled : theme.colors.black
          }`,
          padding: '5px',
        }}
        inputCheckedStyle={{
          background: isDisabled ? `${theme.colors.textDisabled}` : 'white',
          border: isDisabled ? 'none' : `1px solid ${theme.colors.black}`,
        }}
        inputFocusStyle={{
          boxShadow: 'none',
        }}
        onChange={onChangeHandler}
        {...radioButtonProps}
      />
      <RadioItemLabel
        isDisabled={isDisabled}
        isChecked={isChecked}
        radioItemLabelStyle={radioItemLabelStyle}
        radioItemCheckedLabelStyle={radioItemCheckedLabelStyle}
        htmlFor={radioButtonId}
      >
        {sortLabelCtx.renderLabel(option, labelOptions)}
      </RadioItemLabel>
    </RadioItemWrapper>
  );
};

SortRadioItem.propTypes = {
  /**
   * Boolean to mark the radio button as checked
   */
  isChecked: PropTypes.bool,

  /**
   * Boolean to mark the radio button as disabled
   */
  isDisabled: PropTypes.bool,

  /**
   * Action handler when the radio input has changed
   */
  onChangeHandler: PropTypes.func,

  /**
   * An object that contains label and value
   */
  option: PropTypes.object,

  /**
   * Props for the radio buttons to customize styles
   */
  radioButtonProps: PropTypes.object,

  /**
   * A custom component for css radio button
   */
  radioIcon: PropTypes.node,

  /**
   * Custom styles for the label of the checked radio item
   */
  radioItemCheckedLabelStyle: PropTypes.any,

  /**
   * Custom styles for the label of the radio item
   */
  radioItemLabelStyle: PropTypes.any,
};

export default SortRadioItem;

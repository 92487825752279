import { useEffect, useMemo } from 'react';

import config from 'config';
import { useSelector } from 'react-redux';

import { useSession } from '../../../techstyle-shared/redux-core';

import useCartActions from './useCartActions';

/**
 * A hook that returns the users's cart from global store.
 * It will fetch the cart if not already present or up-to-date in state.
 * @param {Object} options
 * @param {boolean} options.isLoginRequired
 * @param {Object} options.params - params object
 * @param {boolean} options.params.ignoreLinkedCart  Whether to include data about the linked cart
 * @param {boolean} options.params.forceCreate       Automatically create cart if no cart is found for the user
 * @param {boolean} options.params.noCache           When true, disables returning cart from the cache
 * @param {boolean} options.params.recalcCart        When true, triggers full recalculated cart via `getCheckoutCart`
 * @returns {Object} cart object
 */
export default function useCart({
  isLoginRequired = config.get('public.cart.loginRequired'),
  params: inputParams,
} = {}) {
  const { isLoggedIn } = useSession();
  const cart = useSelector((state) => state.cart);
  const { loadCart } = useCartActions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = useMemo(() => inputParams, [JSON.stringify(inputParams)]);

  useEffect(() => {
    isLoginRequired ? isLoggedIn && loadCart(params) : loadCart(params);
  }, [isLoggedIn, isLoginRequired, loadCart, params]);

  return cart;
}

import {
  gql,
  useQuery,
  useLazyQuery,
  QueryHookOptions,
  LazyQueryHookOptions,
} from '../../../techstyle-shared/react-graphql';

type ReviewsSortBy =
  | 'HighestRated'
  | 'MostRecent'
  | 'LowestRated'
  | 'WithPhotos';

interface ProductReviewCounts {
  count: number;
  reviewCount: number;
  recommendedPercentage: number;
  recommendedCount: number;
  averageReview: number;
  rating5Count: number;
  rating4Count: number;
  rating3Count: number;
  rating2Count: number;
  rating1Count: number;
}

interface ProductReviewSizeOption {
  label: string;
  attribute: string;
  size: string;
}

interface ProductReviewMetaData {
  reviewsMetaData: {
    counts: ProductReviewCounts;
    sizes: ProductReviewSizeOption[];
    overallFit: number;
  };
}

interface ProductReviewsMetaDataVars {
  groupCode: string;
  storeGroupId?: number;
  sizes?: string[];
  sortBy?: ReviewsSortBy;
  rating?: number;
  limit?: number;
  offset?: number;
}

export const GET_REVIEWS_METADATA_BY_GROUP_CODE = gql`
  query ReviewsMetaData(
    $groupCode: String!
    $storeGroupId: Int
    $sizes: [String!]
    $sortBy: ReviewsSortBy
    $rating: Int
    $limit: Int
    $offset: Int
  ) {
    reviewsMetaData: reviews(
      groupCode: $groupCode
      storeGroupId: $storeGroupId
      sizes: $sizes
      sortBy: $sortBy
      rating: $rating
      limit: $limit
      offset: $offset
    ) {
      counts {
        count
        reviewCount
        recommendedPercentage
        recommendedCount
        averageReview
        rating5Count
        rating4Count
        rating3Count
        rating2Count
        rating1Count
      }
      sizes {
        label
        attribute
        size
      }
      overallFit
    }
  }
`;

export const useReviewsMetaDataQuery = (
  {
    groupCode,
    storeGroupId,
    limit,
    offset,
    sortBy,
    sizes: sizesToQuery,
    rating: ratingToQuery,
  }: ProductReviewsMetaDataVars,
  options: QueryHookOptions<
    ProductReviewMetaData,
    ProductReviewsMetaDataVars
  > = {}
) => {
  return useQuery<ProductReviewMetaData, ProductReviewsMetaDataVars>(
    GET_REVIEWS_METADATA_BY_GROUP_CODE,
    {
      variables: {
        groupCode,
        limit,
        offset,
        sizes: sizesToQuery,
        rating: ratingToQuery,
        storeGroupId,
        sortBy,
      },
      ...options,
    }
  );
};

export const useLazyReviewsMetaDataQuery = (
  {
    groupCode,
    storeGroupId,
    limit,
    offset,
    sortBy,
    sizes: sizesToQuery,
    rating: ratingToQuery,
  }: ProductReviewsMetaDataVars,
  options: LazyQueryHookOptions<
    ProductReviewMetaData,
    ProductReviewsMetaDataVars
  > = {}
) => {
  return useLazyQuery<ProductReviewMetaData, ProductReviewsMetaDataVars>(
    GET_REVIEWS_METADATA_BY_GROUP_CODE,
    {
      variables: {
        groupCode,
        limit,
        offset,
        sizes: sizesToQuery,
        rating: ratingToQuery,
        storeGroupId,
        sortBy,
      },
      ...options,
    }
  );
};

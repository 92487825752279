import { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getNavContainer } from './navModule';
import useNavActions from './useNavActions';
import { filterNavItems } from './utils';

export default function useNav(handle, { filter } = {}) {
  const actions = useNavActions();

  // Allow passing a null `handle` to conditionally fetch navs. We can't bail
  // out early though due to the rules of hooks.
  const nav = useSelector((state) =>
    handle ? getNavContainer(state, handle) : null
  );
  const data = nav ? nav.data : null;

  const filteredData = useMemo(() => {
    if (!data || !data.navItems || !filter) {
      return data;
    }
    // TODO: Support nested nav filtering? Supply a no-op parent filter for now.
    const parentFilter = () => true;
    const navItemFilter = filter(parentFilter);
    return filterNavItems(data, navItemFilter);
  }, [data, filter]);

  useEffect(() => {
    if (handle) {
      actions.loadNav(handle);
    }
  });

  return useMemo(() => {
    if (nav) {
      return { ...nav, data: filteredData };
    }
    return null;
  }, [nav, filteredData]);
}

import { NextPageContext } from 'next';

/**
 * Given a NextJS context will determine whether a response has already been ended (e.g. with `ctx.res.end()`)
 * @param {*} ctx
 * @returns {boolean}
 */
const getIsResponseEnded = (ctx: NextPageContext) => {
  const { res } = ctx;

  if (res) {
    // `res.finished` is deprecated/obsolete in newer versions of Node.
    // `res.writableEnded` was added in v12.9.0
    // @ts-ignore
    return 'finished' in res ? res.finished : res.writableEnded;
  }

  return false;
};

export default getIsResponseEnded;

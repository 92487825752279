import React from 'react';

import config from 'config';
import Head from 'next/head';

const AppHead = () => {
  const fontId = config.get('public.brand.adobeFontId');
  const brand = config.get('public.brand.name');
  return (
    <Head>
      {fontId && (
        <link rel="stylesheet" href={`https://use.typekit.net/${fontId}.css`} />
      )}
      <link rel="stylesheet" href={`/static/fonts/${brand}-fonts.css`} />
    </Head>
  );
};

export default function fontStackExtension() {
  return {
    id: 'fontStackExtension',
    app: {
      render(props, children) {
        return (
          <>
            <AppHead />
            {children}
          </>
        );
      },
    },
  };
}

import React, {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
  useReducer,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export function useProductFilters() {
  return useContext(Context);
}

const expandedCollapsibleFiltersReducer = (prevState, action) => {
  const state = { ...prevState };
  if (action.type === 'reset') {
    return {};
  } else if (action.type === 'open_all' && action.filterFields) {
    action.filterFields.forEach((field) => (state[field] = true));
  } else {
    state[action.filterField] = !state[action.filterField];
  }
  return state;
};

export default function ProductFilterContext({
  appliedFilters,
  children,
  filterSettings: filterSettingsFromProps,
  onApply,
  onChange,
  onChangeFilterSettings,
}) {
  const appliedFiltersRef = useRef(appliedFilters);
  const filterSettingsRef = useRef(filterSettingsFromProps);
  // If we get a new `appliedFilters` prop, we should temporarily ignore
  // whatever value is in local state until we can sync it back up with
  // `appliedFilters`. Any changes will be lost (they may no longer be
  // relevant to the new set of filter fields anyway).
  const areFiltersChanging = appliedFilters !== appliedFiltersRef.current;
  const areFilterSettingsChanging =
    filterSettingsFromProps !== filterSettingsRef.current;

  const [selectedFiltersFromState, setSelectedFilters] =
    useState(appliedFilters);
  const selectedFilters = areFiltersChanging
    ? appliedFilters
    : selectedFiltersFromState;

  const [filterSettingsFromState, setFilterSettings] = useState(
    filterSettingsFromProps
  );
  const filterSettings = areFilterSettingsChanging
    ? filterSettingsFromProps
    : filterSettingsFromState;

  // TODO? Alternatively this could go into ProductBrowserSidebar state
  const [expandedCollapsibleFilters, toggleCollapsibleFilterIsOpen] =
    useReducer(expandedCollapsibleFiltersReducer, {});

  // Sync internal state when `appliedFilters` changes.
  useEffect(() => {
    appliedFiltersRef.current = appliedFilters;
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  // Sync internal state when `filterSettings` changes.
  useEffect(() => {
    filterSettingsRef.current = filterSettingsFromProps;
    setFilterSettings(filterSettingsFromProps);
  }, [filterSettingsFromProps]);

  const isDirty = selectedFilters !== appliedFilters;

  const updateSelectedFilters = useCallback(
    (newFilters) => {
      setSelectedFilters(newFilters);
      if (onChange) {
        onChange(newFilters);
      }
    },
    [onChange]
  );

  const value = useMemo(() => {
    const applySelectedFilters = () => {
      if (onApply) {
        onApply({
          newFilters: selectedFilters,
          newFilterSettings: filterSettings,
        });
      }
    };

    const applyFilters = ({ newFilters, newFilterSettings }) => {
      if (onApply) {
        onApply({ newFilters, newFilterSettings });
      }
    };

    const updateFilterSettings = (newFilterSettings) => {
      setFilterSettings(newFilterSettings);
      if (onChangeFilterSettings) {
        onChangeFilterSettings(newFilterSettings);
      }
    };

    return {
      appliedFilters,
      applyFilters,
      applySelectedFilters,
      filterSettings,
      initialFilterSettings: filterSettingsFromProps,
      selectedFilters,
      setSelectedFilters: updateSelectedFilters,
      setFilterSettings: updateFilterSettings,
      expandedCollapsibleFilters,
      toggleCollapsibleFilterIsOpen,
      isDirty,
    };
  }, [
    appliedFilters,
    isDirty,
    filterSettings,
    filterSettingsFromProps,
    updateSelectedFilters,
    selectedFilters,
    onApply,
    onChangeFilterSettings,
    expandedCollapsibleFilters,
    toggleCollapsibleFilterIsOpen,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ProductFilterContext.propTypes = {
  appliedFilters: PropTypes.object,
  children: PropTypes.node,
  filterSettings: PropTypes.arrayOf(PropTypes.object),
  onApply: PropTypes.func,
  onChange: PropTypes.func,
  onChangeFilterSettings: PropTypes.func,
};

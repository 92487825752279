import { useState, useEffect } from 'react';

import type { RmaResponseDto } from './types';
import useAccountActions from './useAccountActions';

export default function useRMADetails({
  rmaId,
  includeExchange,
}: {
  rmaId: string | number | null;
  includeExchange?: boolean;
}) {
  const actions = useAccountActions();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<RmaResponseDto | undefined>();
  const [error, setError] = useState<any>();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const response = await actions.loadRMADetails({
          rmaId,
          includeExchange,
        });
        setData(response.payload);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (rmaId) {
      loadData();
    }
  }, [actions, rmaId, includeExchange]);

  return { isLoading, data, error };
}

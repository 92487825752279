import React from 'react';

import { useRouter } from 'next/router';

import { EqualWebSnippet } from '../../../techstyle-shared/react-components';
import { useDomain, useSession } from '../../../techstyle-shared/redux-core';
import { getWidgetBtnStyle } from '../../constants/accessibilityWidget';

function AccessibilitySnippet() {
  const { tld } = useDomain();
  const isUSDomain = tld === '.com';
  const isProd = process.NODE_ENV === 'production';
  const { isAdmin } = useSession();
  const router = useRouter();
  const { query } = router;
  const enableSnippet = (isProd && !isAdmin) || (!isProd && !query?.admin);

  if (enableSnippet) {
    if (isUSDomain) {
      return <EqualWebSnippet btnStyle={getWidgetBtnStyle()} />;
    }
  }

  return null;
}

AccessibilitySnippet.propTypes = {};

export default AccessibilitySnippet;

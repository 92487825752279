import React from 'react';

import PropTypes from 'prop-types';

import { useId } from '../../../techstyle-shared/react-components';
import { defineMessages, useIntl } from '../../../techstyle-shared/react-intl';

const messages = defineMessages({
  title: {
    id: 'global_cta.close',
    defaultMessage: 'Close',
  },
});

export default function CloseIcon({
  color = 'currentColor',
  size = '1em',
  strokeWidth = '1',
  title: propsTitle,
  ...rest
}) {
  const intl = useIntl();
  const title =
    propsTitle === undefined ? intl.formatMessage(messages.title) : propsTitle;
  const titleId = useId();
  const hasAriaLabel = rest['aria-label'];

  return (
    <svg
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      strokeWidth={size}
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      {...rest}
    >
      {title && <title id={titleId}>{title}</title>}
      <line
        stroke={color}
        strokeWidth={strokeWidth}
        x1="0"
        x2="10"
        y1="10"
        y2="0"
      />
      <line
        stroke={color}
        strokeWidth={strokeWidth}
        x1="0"
        x2="10"
        y1="0"
        y2="10"
      />
    </svg>
  );
}

CloseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  strokeWidth: PropTypes.string,
  title: PropTypes.node,
};

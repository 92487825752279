import React, { useEffect } from 'react';

import config from 'config';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import featureNames from '../shared/constants/featureFlags';
import { SecondarySites } from '../shared/constants/secondarySites';
import { isYitty } from '../shared/utils/brandNameHelper';
import useHasFeature from '../shared/utils/useHasFeature';
import { getSecondarySite } from '../shared/utils/useSecondarySite';
import {
  updateMembershipDetail,
  useMembershipDetail,
} from '../techstyle-shared/react-accounts';
import { useSession } from '../techstyle-shared/redux-core';

const FL_OUTLET_HOME = 'https://www.fabletics.com/outlet';

const SecondarySiteValidator = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isOutletEnabled = useHasFeature(featureNames.WAREHOUSE_OUTLET_SITE);
  const secondarySiteConfig = config.get('public.secondarySite');
  const outletAccessCodeMembershipDetailName = secondarySiteConfig
    ? secondarySiteConfig.outlet.accessCode.membershipDetailName
    : null;
  const outletSiteAccessCode = useMembershipDetail(
    outletAccessCodeMembershipDetailName
  );
  const evergreenTestAccessCode = secondarySiteConfig
    ? secondarySiteConfig.outlet.accessCode.evergreenTestAccessCode
    : null;
  const { isAutoLoggedIn, isLoggedIn } = useSession();

  useEffect(() => {
    const secondarySite = getSecondarySite(router.asPath);
    if (secondarySite === SecondarySites.OUTLET) {
      if (isYitty()) {
        window.location = FL_OUTLET_HOME;
      } else if ((!isAutoLoggedIn && !isLoggedIn) || !isOutletEnabled) {
        router.replace('/outlet-splash');
      } else if (
        outletSiteAccessCode?.networkStatus?.isUpToDate &&
        outletSiteAccessCode?.value !== evergreenTestAccessCode
      ) {
        dispatch(
          updateMembershipDetail({
            name: outletAccessCodeMembershipDetailName,
            value: evergreenTestAccessCode,
          })
        );
      }
    }
  }, [
    isOutletEnabled,
    outletSiteAccessCode,
    router,
    isAutoLoggedIn,
    isLoggedIn,
    dispatch,
    evergreenTestAccessCode,
    outletAccessCodeMembershipDetailName,
  ]);

  return null;
};

export default function secondarySiteExtension() {
  return {
    id: 'secondarySite',
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <SecondarySiteValidator />
          </>
        );
      },
    },
  };
}

import { StatusCodes } from './src/techstyle-shared/next-server';
import { ProductStatusCode } from './src/techstyle-shared/react-products';

export const noResultsRedirect = {
  redirectPath: '/products/no-results',
  statusCode: StatusCodes.TEMPORARY_REDIRECT,
};

export const productStatusRedirects = {
  [ProductStatusCode.INITIATED]: noResultsRedirect,
  [ProductStatusCode.CREATING]: noResultsRedirect,
  [ProductStatusCode.INACTIVE]: noResultsRedirect,
  [ProductStatusCode.UNAVAILABLE]: noResultsRedirect,
  [ProductStatusCode.DISCONTINUED]: noResultsRedirect,
  [ProductStatusCode.REMOVED]: {
    redirectPath: '/404',
    statusCode: StatusCodes.TEMPORARY_REDIRECT,
  },
  INTERNAL_SERVER_ERROR: {
    ...noResultsRedirect,
    disabledClientSide: true,
  },
};

const errorCodePageMap = {
  '/products_bmig/[identifier]': productStatusRedirects,
  '/products/[identifier]': productStatusRedirects,
  '/reviews_bmig/[identifier]': productStatusRedirects,
  '/reviews/[identifier]': productStatusRedirects,
};

export default errorCodePageMap;

import React, { useContext } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export function useProductDetailContext() {
  return useContext(Context);
}

export default function ProductDetailContext({ children, value }) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ProductDetailContext.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};

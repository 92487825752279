import { schema } from '../../../techstyle-shared/redux-core';

export const NavErrorType = {
  NOT_FOUND: 'NOT_FOUND',
  OTHER: 'OTHER',
};

export const NavContainer = new schema.Entity(
  'NavContainer',
  {},
  {
    idAttribute: 'handle',
    processStrategy(input, parent, key) {
      if (!input.navSettings || !input.navSettings.id) {
        return null;
      }
      const { isSegmented, ...output } = input;
      return output;
    },
  }
);

export const NavSchema = new schema.Object({
  sections: [NavContainer],
});

import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import logger from '../shared/utils/logger';
import { Router } from '../techstyle-shared/next-routes';

const debug = logger.extend('urlRedactorExtension');

const redactedKeys = [
  'alkey',
  'altoken',
  'alemail',
  'admin_token',
  'admin_id',
  'refresh',
];

const sanitizeQuery = (query) => {
  const safeQuery = { ...query };
  const foundKeys = [];

  Object.keys(safeQuery).forEach((key) => {
    if (redactedKeys.includes(key)) {
      foundKeys.push(key);
      delete safeQuery[key];
    }
  });

  return {
    query: safeQuery,
    isRedacted: foundKeys.length > 0,
    foundKeys,
  };
};

const UrlRedactor = () => {
  const router = useRouter();

  useEffect(() => {
    const { isRedacted, query, foundKeys } = sanitizeQuery(router.query);

    if (isRedacted) {
      debug('Redacting auto-login parameters', { foundKeys, query });
      const paramIndex = router.asPath.indexOf('?');
      const pathname =
        paramIndex >= 0
          ? router.asPath.substring(0, paramIndex)
          : router.asPath;
      Router.replace({ pathname, query }, undefined, { shallow: true });
    }

    if (router.query.destination) {
      router.query.refresh
        ? (window.location = router.query.destination)
        : router.push(router.query.destination);
    }
  }, [router.query, router.asPath, router]);

  return null;
};

export default function urlRedactorExtension() {
  return {
    id: 'urlRedactor',
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <UrlRedactor />
          </>
        );
      },
    },
  };
}

/* eslint-disable react/prop-types */
import React from 'react';

import hoistNonReactStatics from 'hoist-non-react-statics';
// import PropTypes from 'prop-types';

import useTheme from './useTheme';

function defaultMergeProps(ownProps: any, variantProps: any) {
  return { ...variantProps, ...ownProps };
}

/**
 * A Higher Order Component (HOC) for retrieving theme values as props. Use this
 * when you create a component that has a dedicated section of the theme
 * supplying its default styling or the styling of multiple variants.
 *
 * Use like:
 *
 * ```js
 * export default withThemeProps(Modal, {
 *   section: 'modal',
 *   defaultVariant: 'default'
 * });
 * ```
 *
 * The resulting component will support an `variant` prop; if not passed
 * explicitly, it will default to the (optional) `defaultVariant`. The `section`
 * and `variant` are passed to the `useTheme` hook; see its documentation for
 * how it works.
 *
 * The resulting theme values are then merged with the component's own props and
 * passed to the component.
 */
export default function withThemeProps<Type>(
  Component: React.ComponentType,
  {
    section,
    defaultVariant,
    mergeProps = defaultMergeProps,
  }: {
    section?: string;
    defaultVariant?: string;
    mergeProps?: any;
  } = {}
) {
  const name = Component.displayName || Component.name || 'Component';

  type WithThemePropsType = Type & {
    variant?: string;
  };

  const WithThemeProps = React.forwardRef<unknown, WithThemePropsType>(
    ({ variant = defaultVariant, ...props }, ref) => {
      const variantProps = useTheme(section, variant);
      const mergedProps = mergeProps(props, variantProps);

      return <Component ref={ref} {...mergedProps} />;
    }
  );

  // WithThemeProps.propTypes = {
  //   ...Component.propTypes,
  //   variant: PropTypes.string,
  // };

  // WithThemeProps.defaultProps = {
  //   variant: 'defaultVariant',
  // };

  WithThemeProps.displayName = `withThemeProps(${name})`;

  hoistNonReactStatics(WithThemeProps, Component);
  return WithThemeProps;
}

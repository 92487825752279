import React, { useCallback, useContext, useMemo, useState } from 'react';

import styled from 'styled-components';

import useId from '../useId';

const Wrapper = styled.div`
  text-align: left;
`;

type RadioButtonGroupContext = {
  name?: string;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Context = React.createContext<RadioButtonGroupContext | undefined>(
  undefined
);

export function useRadioButtonGroupState() {
  return useContext(Context);
}

type RadioButtonGroupProps = React.ComponentPropsWithRef<typeof Wrapper> & {
  /**
   * The radio buttons and any other elements you wish to render. They may
   * appear anywhere as descendants and don’t necessarily have to be direct
   * children.
   */
  children: React.ReactNode;
  /**
   * The value of the `name` attribute to give each input element. If not
   * provided, one will be automatically generated.
   */
  name?: string;
  /**
   * Function to call when a new option is selected. Access `event.target.value`
   * to get the newly selected value.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * The currently selected radio value. Defining this will make the radio
   * group controlled.
   */
  value?: string;
};

export default function RadioButtonGroup({
  children,
  onChange,
  name,
  value: controlledValue,
  ...rest
}: RadioButtonGroupProps) {
  const autoName = useId('radio-auto-');
  name = name || autoName;
  const [uncontrolledValue, setUncontrolledValue] = useState<
    string | undefined
  >();
  const value =
    typeof controlledValue === 'undefined'
      ? uncontrolledValue
      : controlledValue;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, ...args: any[]) => {
      setUncontrolledValue(event.target.value);
      if (onChange) {
        onChange(event, ...(args as []));
      }
    },
    [onChange]
  );

  const context = useMemo(
    () => ({
      name,
      onChange: handleChange,
      value,
    }),
    [name, handleChange, value]
  );

  return (
    <Wrapper role="radiogroup" {...rest}>
      <Context.Provider value={context}>{children}</Context.Provider>
    </Wrapper>
  );
}

RadioButtonGroup.Context = Context;
RadioButtonGroup.useState = useRadioButtonGroupState;

import React, { useRef, useCallback, useContext, createContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useLayoutEffect from '../useLayoutEffect';
import useTabIndex from '../useTabIndex';

import { useDropdown } from './useDropdown';

const DropdownContext = createContext();

const useDropdownContext = () => useContext(DropdownContext);

const ButtonElement = styled.button`
  display: block;
  width: 100%;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
  background: transparent;
  color: inherit;
`;

const ListElement = styled.ul`
  display: ${(p) => (p.isOpened ? 'block' : 'none')};
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItemElement = styled.li`
  margin: 0;
`;

export const DropdownButton = ({
  children,
  tabIndex,
  onKeyDown,
  onClick,
  onMouseDown,
  ...rest
}) => {
  const buttonRef = useRef(null);
  const isFirstRender = useRef(true);
  const [state, actions] = useDropdownContext();

  tabIndex = useTabIndex(tabIndex);

  useLayoutEffect(() => {
    if (!state.isOpened && buttonRef.current && !isFirstRender.current) {
      buttonRef.current.focus();
    }
    isFirstRender.current = false;
  }, [state.isOpened]);

  const handleKeyDown = useCallback(
    (event) => {
      actions.onButtonKey(event);
      if (onKeyDown) {
        onKeyDown(event);
      }
    },
    [actions, onKeyDown]
  );

  const handleClick = useCallback(
    (event) => {
      actions.onButtonClick(event);
      if (onClick) {
        onClick(event);
      }
    },
    [actions, onClick]
  );

  const handleMouseDown = useCallback(
    (event) => {
      // * Prevent default onMouseDown if open to protect against race conditions
      if (state.isOpened) {
        event.preventDefault();
      }
      if (onMouseDown) {
        onMouseDown(event);
      }
    },
    [state.isOpened, onMouseDown]
  );

  const hasValue = state.highlightedValue !== null;

  return (
    <ButtonElement
      aria-expanded={state.isOpened ? 'true' : 'false'}
      aria-haspopup="listbox"
      type="button"
      {...rest}
      onKeyDown={hasValue ? handleKeyDown : undefined}
      onClick={hasValue ? handleClick : undefined}
      onMouseDown={handleMouseDown}
      tabIndex={tabIndex}
      ref={buttonRef}
    >
      {children}
    </ButtonElement>
  );
};

DropdownButton.displayName = 'Dropdown.Button';

DropdownButton.propTypes = {
  /**
   * Content to pass to the Dropdown.Button; should indicate the active option
   */
  children: PropTypes.node,
  /**
   * Optional callback to be invoked with the event on click.
   */
  onClick: PropTypes.func,
  /**
   * Optional callback to be invoked with the event on key down.
   */
  onKeyDown: PropTypes.func,
  /**
   * Optional callback to be invoked with the event on mouse down.
   */
  onMouseDown: PropTypes.func,
  /**
   * Override tabIndex
   */
  tabIndex: PropTypes.number,
};

export const DropdownList = ({ children, ...rest }) => {
  const listRef = useRef(null);
  const [state, actions] = useDropdownContext();

  useLayoutEffect(() => {
    if (state.isOpened && listRef.current) {
      listRef.current.focus();
    }
  }, [state.isOpened]);

  // * Bail out if no options have been passed
  if (!state.options || !state.options.length) {
    return null;
  }

  return (
    <ListElement
      aria-orientation="vertical"
      role="listbox"
      tabIndex={-1}
      {...rest}
      isOpened={state.isOpened}
      onKeyDown={actions.onListKey}
      onBlur={actions.onListBlur}
      aria-activedescendant={`${state.id}-${state.highlightedValue}`}
      ref={listRef}
    >
      {children}
    </ListElement>
  );
};

DropdownList.displayName = 'Dropdown.List';

DropdownList.propTypes = {
  /**
   * Content to pass to the Dropdown.List; should contain a list of Dropdown.ListItems
   */
  children: PropTypes.node,
};

export const DropdownListItem = ({ value, children, disabled, ...rest }) => {
  const itemRef = useRef(null);
  const [state, actions] = useDropdownContext();

  const isSelected = state.value === value;
  const isHighlighted = state.highlightedValue === value;

  const onClick = useCallback(
    (event) => {
      if (disabled) {
        return false;
      }
      actions.onClickItem(event, value);
      if (rest.onClick) {
        rest.onClick(event);
      }
    },
    [actions, rest, value, disabled]
  );

  useLayoutEffect(() => {
    if (!state.isOpened || !isHighlighted || !itemRef.current) {
      return;
    }

    const parent = itemRef.current.offsetParent;
    if (
      parent &&
      parent !== document.body &&
      parent.scrollHeight > parent.clientHeight
    ) {
      const scrollBottom = parent.clientHeight + parent.scrollTop;
      const itemOffsetTop = itemRef.current.offsetTop;
      const itemBottom = itemOffsetTop + itemRef.current.offsetHeight;
      if (itemOffsetTop < parent.scrollTop) {
        parent.scrollTop = itemOffsetTop;
      } else if (itemBottom > scrollBottom) {
        // The item might be taller than the offsetParent. If that happens,
        // prefer showing the top of the item rather than the bottom.
        parent.scrollTop = Math.min(
          itemOffsetTop,
          itemBottom - parent.clientHeight
        );
      }
    }
  }, [isHighlighted, state.isOpened]);

  return (
    <ListItemElement
      aria-selected={isSelected}
      aria-current={isHighlighted}
      role="option"
      {...rest}
      onClick={onClick}
      id={`${state.id}-${value}`}
      ref={itemRef}
      disabled={disabled}
    >
      {children}
    </ListItemElement>
  );
};

DropdownListItem.displayName = 'Dropdown.ListItem';

DropdownListItem.propTypes = {
  /**
   * Content to pass to the Dropdown.ListItem
   */
  children: PropTypes.node,
  /**
   * Allows for disabling an option and making it unusable
   */
  disabled: PropTypes.bool,
  /**
   * The list item's value corresponding to a Dropdown option's value.
   */
  value: PropTypes.string.isRequired,
};

/**
 * A low-level themeable Dropdown Select component.
 *
 * The `<Dropdown>` component itself renders nothing, but manages the state
 * of the Dropdown. It accepts a current value and a list of options, and is
 * a controlled component. To render a toggle button and content, use
 * `<Dropdown.Button>`; `<Dropdown.List>`; and `<Dropdown.ListItem>`, respectively.
 *
 * ```jsx
 * <Dropdown
 *   value="active"
 *   onChange={option => {}}
 *   options={[
 *     { value: 'active', name: 'Active' }
 *   ]}
 * >
 *   <Dropdown.Button>
 *     Active (Manage with state)
 *   </Dropdown.Button>
 *   <Dropdown.List>
 *     <Dropdown.ListItem value="active">
 *       Active
 *     </Dropdown.ListItem>
 *   </Dropdown.List>
 * </Dropdown>
 * ```
 *
 * All sub-components can be individually styled and customized. You can
 * also hook into Dropdown's state using `Dropdown.useDropdownContext` to
 * build your own components, or use the `useDropdown` hook without this
 * component.
 */
const Dropdown = ({ children, value, options, onChange, onOpen, onClose }) => {
  // Filter out our disabled options to prevent eny events getting attached to it.
  options = options.filter((option) => !option.disabled);

  const dropdown = useDropdown({ value, options, onChange, onOpen, onClose });

  return (
    <>
      <input type="hidden" value={value} />
      <DropdownContext.Provider value={dropdown}>
        {children}
      </DropdownContext.Provider>
    </>
  );
};

Dropdown.propTypes = {
  /**
   * Content to pass to Dropdown
   */
  children: PropTypes.node,
  /**
   * The (optional) `onChange` callback receives the newest selected option
   */
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  /**
   * Array of options [{ name: "Name", value: "value" }]
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  /**
   * Current value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Dropdown.useDropdownContext = useDropdownContext;
Dropdown.Button = DropdownButton;
Dropdown.List = DropdownList;
Dropdown.ListItem = DropdownListItem;

export default Dropdown;

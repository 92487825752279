import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { FaChevronUp } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import Dropdown from '../Dropdown';
import {
  LabelType,
  useFilterFieldLabels,
  useOptionLabels,
} from '../LabelProvider';
import { useProductBrowser } from '../ProductBrowserContext';
import { desktop, mobile } from '../styles';
import useBreakpoint from '../useBreakpoint';
import useId from '../useId';

const filterField = {
  field: 'sort',
  label: 'Sort',
};

const labelOptions = {
  whiteSpace: 'nowrap',
  labelType: LabelType.SORT_DROPDOWN,
};

const SortDropdownButton = styled(Dropdown.Button)`
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 0 16px;
  position: relative;
  width: auto;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${desktop`
    height: 32px;
    padding: 0 8px;
    font-size: 14px;
  `}

  &[data-expanded='true'] {
    background-color: #333333;
    color: #fff;
  }
  ${({ buttonStyle }) => buttonStyle};
`;

const ActiveSelectionText = styled.span`
  color: #676767;
  &[data-expanded='true'] {
    color: #fff;
  }
  ${({ activeSelectionStyle }) => activeSelectionStyle};
`;

const MobileLabel = styled.label`
  font-size: 16px;
  ${desktop`
    display: none;
  `}
  white-space: pre-wrap;
  ${({ mobileLabelStyle }) => mobileLabelStyle};
`;

const Chevron = styled(FaChevronUp)`
  font-size: 8px;
  margin-left: 6px;
  vertical-align: middle;
  ${(p) =>
    p.direction &&
    {
      up: css`
        transform: rotate(0deg);
      `,
      down: css`
        transform: rotate(180deg);
      `,
      left: css`
        transform: rotate(270deg) translateX(1px);
      `,
      right: css`
        transform: rotate(90deg) translateX(-1px);
      `,
    }[p.direction]}
`;

Chevron.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

/**
 * The implementation of Dropdown.Button for sorting.
 *
 * Doesn't accept props as it receives the data it needs from the nearest DropdownContext.
 * And as such should only be used within a DropdownContext.
 */
const DropdownButton = ({
  activeSelectionStyle,
  buttonStyle,
  chevronIcon: ChevronIcon = Chevron,
  id,
  mobileLabelStyle,
  labelSeparator,
  showDefaultOption,
}) => {
  const { isMobile } = useBreakpoint();
  const [dropdownState] = Dropdown.useDropdownContext();
  const filterFieldLabelCtx = useFilterFieldLabels();
  const optionLabelCtx = useOptionLabels();
  const isDefault = dropdownState.selectedOption.isDefault;
  const sortHasChanged = dropdownState.hasChanged;
  const showActiveOption =
    !isDefault || sortHasChanged || !isMobile || showDefaultOption;

  return (
    <SortDropdownButton
      id={id}
      data-autotag="sort-dropdown"
      data-expanded={dropdownState.isOpened}
      buttonStyle={buttonStyle}
    >
      <MobileLabel
        data-expanded={dropdownState.isOpened}
        mobileLabelStyle={mobileLabelStyle}
      >
        {filterFieldLabelCtx.renderLabel(filterField, labelOptions)}
        {(!isDefault || sortHasChanged || showDefaultOption) && labelSeparator}
      </MobileLabel>
      {showActiveOption && (
        <ActiveSelectionText
          data-expanded={dropdownState.isOpened}
          activeSelectionStyle={activeSelectionStyle}
        >
          {optionLabelCtx.renderLabel(
            filterField,
            dropdownState.selectedOption,
            labelOptions
          )}
        </ActiveSelectionText>
      )}
      <ChevronIcon direction={dropdownState.isOpened ? 'up' : 'down'} />
    </SortDropdownButton>
  );
};

DropdownButton.propTypes = {
  activeSelectionStyle: PropTypes.any,
  buttonStyle: PropTypes.any,
  chevronIcon: PropTypes.elementType,
  id: PropTypes.string,
  labelSeparator: PropTypes.string,
  mobileLabelStyle: PropTypes.any,
  showDefaultOption: PropTypes.bool,
};

const DropdownWrapper = styled.div`
  color: #333333;
  display: flex;
  text-align: left;
  position: relative;
`;

const ButtonListWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  ${desktop`
    flex-grow: 0;
    display: inline-block;
  `}

  ${({ buttonListWrapperStyle }) => buttonListWrapperStyle};
`;

const DropdownList = styled(Dropdown.List)`
  background-color: #fff;
  font-size: 14px;
  min-width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
  ${desktop`
    font-size: 12px;
    left: auto;
    right: 0;
  `}
  ${({ dropdownListStyle }) => dropdownListStyle};
`;

const DropdownListItem = styled(Dropdown.ListItem)`
  border-top: 1px solid #eeeeee;
  cursor: pointer;
  padding: 16px 8px;
  white-space: nowrap;
  &[aria-current='true'] {
    background-color: #ddedff;
  }
  ${desktop`
    padding: 8px;
    &[data-selected='true'] {
      color: #676767;
    }
  `}
  ${({ dropdownListItemStyle }) => dropdownListItemStyle};
`;

const DesktopLabel = styled.label`
  padding: 9px 8px 9px 0;
  font-size: 14px;
  line-height: 1;
  ${mobile`
    display: none;
  `}

  ${({ desktopLabelStyle }) => desktopLabelStyle};
`;

export const SortDropdown = ({
  id,
  value: valueFromProps,
  options: optionsFromProps,
  buttonStyle,
  chevronIcon,
  onChange,
  activeSelectionStyle,
  dropdownListStyle,
  buttonListWrapperStyle,
  dropdownListItemStyle,
  desktopLabelStyle,
  mobileLabelStyle,
  onOpen,
  onClose,
  labelSeparator = ': ',
  showDefaultOption = false,
  ...rest
}) => {
  const productBrowserCtx = useProductBrowser();
  const filterFieldLabelCtx = useFilterFieldLabels();
  const optionLabelCtx = useOptionLabels();
  const autoId = useId();
  id = id || autoId;

  const value = useMemo(() => {
    if (valueFromProps) {
      return valueFromProps;
    } else if (productBrowserCtx && productBrowserCtx.sort) {
      return productBrowserCtx.sort.value;
    }
  }, [productBrowserCtx, valueFromProps]);

  const options = useMemo(() => {
    if (optionsFromProps) {
      return optionsFromProps;
    } else if (productBrowserCtx && productBrowserCtx.sortOptions) {
      return productBrowserCtx.sortOptions;
    }
  }, [optionsFromProps, productBrowserCtx]);

  const handleSortChange = useCallback(
    (option) => {
      if (onChange) {
        onChange(option);
      } else if (productBrowserCtx && productBrowserCtx.setSort) {
        productBrowserCtx.setSort(option);
      }
    },
    [onChange, productBrowserCtx]
  );

  return (
    <DropdownWrapper {...rest}>
      <Dropdown
        value={value}
        options={options}
        onChange={handleSortChange}
        onOpen={onOpen}
        onClose={onClose}
      >
        <DesktopLabel desktopLabelStyle={desktopLabelStyle} htmlFor={id}>
          {filterFieldLabelCtx.renderLabel(filterField, labelOptions)}
        </DesktopLabel>
        <ButtonListWrapper buttonListWrapperStyle={buttonListWrapperStyle}>
          <DropdownButton
            id={id}
            buttonStyle={buttonStyle}
            chevronIcon={chevronIcon}
            activeSelectionStyle={activeSelectionStyle}
            mobileLabelStyle={mobileLabelStyle}
            labelSeparator={labelSeparator}
            showDefaultOption={showDefaultOption}
          />
          <DropdownList dropdownListStyle={dropdownListStyle}>
            {options.map((option) => {
              return (
                <DropdownListItem
                  value={option.value}
                  key={option.value}
                  data-autotag={`sort-by-${option.value}`}
                  data-selected={option.value === value}
                  dropdownListItemStyle={dropdownListItemStyle}
                >
                  {optionLabelCtx.renderLabel(
                    filterField,
                    option,
                    labelOptions
                  )}
                </DropdownListItem>
              );
            })}
          </DropdownList>
        </ButtonListWrapper>
      </Dropdown>
    </DropdownWrapper>
  );
};

SortDropdown.Chevron = Chevron;
SortDropdown.DropdownButton = DropdownButton;
SortDropdown.DesktopLabel = DesktopLabel;
SortDropdown.DropdownList = DropdownList;
SortDropdown.DropdownListItem = DropdownListItem;

export const individualSortOption = PropTypes.shape({
  value: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  sort: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        direction: PropTypes.oneOf(['asc', 'desc']),
      })
    ),
  ]),
});
export const sortOptionPropType = PropTypes.arrayOf(individualSortOption);

SortDropdown.propTypes = {
  activeSelectionStyle: PropTypes.any,
  buttonListWrapperStyle: PropTypes.any,
  buttonStyle: PropTypes.any,
  chevronIcon: PropTypes.elementType,
  desktopLabelStyle: PropTypes.any,
  dropdownListItemStyle: PropTypes.any,
  dropdownListStyle: PropTypes.any,
  id: PropTypes.string,
  /**
   * Flag to disable showing the coma after the mobile label on selection
   */
  labelSeparator: PropTypes.string,
  mobileLabelStyle: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  /**
   * Array of available sort options
   */
  options: sortOptionPropType,
  /**
   * Use showDefaultOption to display default Option in mobile mode.
   */
  showDefaultOption: PropTypes.bool,
  /**
   * Currently selected value. If none provided the first option's value
   * will be used.
   */
  value: PropTypes.string,
};

export default SortDropdown;

SortDropdown.ChevronIcon = Chevron;

import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadMembership,
  loadProfile,
} from '../../../../techstyle-shared/react-accounts';
import {
  useSession,
  useSessionDetail,
  updateSessionDetail,
} from '../../../../techstyle-shared/redux-core';
import logger from '../logger';
import { loggedInPageView } from '../marketingModule';

const debug = logger.extend('LoggedInPageView');

const getDefaultPrefetchedAttributes = (dispatch, getState) => {
  // ensure that membership and customer (profile) data is available to segment
  return Promise.all([dispatch(loadMembership()), dispatch(loadProfile())]);
};

function LoggedInPageView({
  getPrefetchedAttributes = getDefaultPrefetchedAttributes,
  sessionDetailName = 'first_logged_in_page_view',
}) {
  const dispatch = useDispatch();
  const pageWillUnmount = useSelector((state) => state.app.pageWillUnmount);
  const { isLoggedIn } = useSession();
  const firstLoggedInPageView = useSessionDetail(sessionDetailName);

  useEffect(() => {
    const checkLoggedInPageView = async () => {
      if (firstLoggedInPageView.networkStatus.isUpToDate) {
        // Check if it's the first logged in page view via sessionDetail
        if (
          !firstLoggedInPageView.value ||
          firstLoggedInPageView.value === '0'
        ) {
          try {
            await Promise.all([
              dispatch(
                updateSessionDetail({
                  name: 'first_logged_in_page_view',
                  value: '1',
                })
              ),
              dispatch(getDefaultPrefetchedAttributes),
            ]);
            // dispatch loggedInPageView action if it's the first loggedInPageView
            // It awaits the previous dispatches to ensure that we have those needed values prefetched
            dispatch(loggedInPageView());
          } catch (err) {
            debug('Error during dispatch: %s', err);
          }
        }
      }
    };

    if (isLoggedIn && !pageWillUnmount) {
      // Only run this check if the user is logged in
      checkLoggedInPageView();
    }
  }, [
    dispatch,
    firstLoggedInPageView,
    getPrefetchedAttributes,
    isLoggedIn,
    pageWillUnmount,
  ]);

  return null;
}

LoggedInPageView.propTypes = {
  getPrefetchedAttributes: PropTypes.func,
  sessionDetailName: PropTypes.string,
};

export default LoggedInPageView;

import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { createTest } from './abTestModule';
import useABTestActions from './useABTestActions';

/**
 * Fetch all enabled A/B tests from CMS. No user-specific data is included.
 * Only intended for use in the admin panel.
 */
export default function useAllABTests(enabled = true) {
  const actions = useABTestActions();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedDate, setFetchedDate] = useState(null);
  const [payload, setPayload] = useState([]);
  const forceAllControl = useSelector((state) => state.abTest.forceAllControl);

  useEffect(() => {
    const loadAllTests = async () => {
      setIsLoading(true);
      const result = await actions.loadAllTests();
      setIsLoading(false);
      setFetchedDate(result.meta.fetchedDate);
      setPayload(result.payload);
    };
    if (enabled) {
      loadAllTests();
    }
  }, [actions, enabled]);

  const allTests = useMemo(
    () =>
      payload.map((test) =>
        // NOTE! Even though it looks like we have test data, its meaning is
        // completely different from the data we get from other test endpoints:
        // instead of `activated`, `group`, `cohortNumber`, etc. reflecting the
        // user's status, this endpoint tells us their default values in CMS.
        // So, don't populate the test objects the same way we do for other
        // endpoints. That's why we're passing `null` for the test data here.
        createTest(test.campaignCode, null, forceAllControl)
      ),
    [forceAllControl, payload]
  );

  const testConfigByCampaignCode = useMemo(() => {
    const tests = {};
    payload.forEach((test) => {
      tests[test.campaignCode] = test;
    });
    return tests;
  }, [payload]);

  return useMemo(
    () => ({ allTests, testConfigByCampaignCode, fetchedDate, isLoading }),
    [allTests, testConfigByCampaignCode, fetchedDate, isLoading]
  );
}

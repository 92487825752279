import React from 'react';

import PropTypes from 'prop-types';

import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import GenericReviewsAttributeSelector from '../GenericReviewsAttributeSelector';
import {
  SelectorWrapper,
  SelectorCategoryLabelText,
  SelectedOptionLabelText,
  SelectorList,
} from '../GenericReviewsSelector';
import ProductAttributeSelector from '../ProductAttributeSelector';
import {
  AttributeFieldTypes,
  useProductAttributeFieldType,
} from '../ProductDetail';
import { useReviewsDrawer } from '../ReviewsDrawerProvider';

const ReviewsSizeSelector = ({
  selectedItems,
  addItem,
  removeItem,
  itemSizeLabelStyle,
  inputSizeStyle,
  itemSoldOutStyle,
}) => {
  const sizeField = useProductAttributeFieldType(AttributeFieldTypes.SIZE);
  const { sizes } = useReviewsDrawer();

  const sizeLabels = sizes.map((size) => size.label);

  if (!sizeField) {
    return null;
  }

  const selectedSizesText = selectedItems.join(', ');

  const renderSummary = () => {
    return (
      <SelectorWrapper>
        <SelectorCategoryLabelText>
          <FormattedMessage
            id="site_pdp.reviews_filter_by_size_header"
            defaultMessage="Filter By Size"
          />
          {': '}
          <SelectedOptionLabelText>{selectedSizesText}</SelectedOptionLabelText>
        </SelectorCategoryLabelText>
      </SelectorWrapper>
    );
  };

  const onOptionClick = (option) => {
    if (option && sizeLabels.includes(option.label)) {
      const alreadySelected = selectedItems.includes(option.value);
      if (alreadySelected) {
        removeItem(option.value);
      } else {
        addItem(option.value);
      }
    }
  };

  return (
    <ProductAttributeSelector field={sizeField}>
      {({ options }) => (
        <>
          {renderSummary()}
          <SelectorList data-autotag="review_filter_size">
            {options.map((option) => {
              const isSoldOut = !sizeLabels.includes(option.label);
              const isSelected = selectedItems.includes(option.label);

              return (
                <GenericReviewsAttributeSelector
                  key={option.value}
                  isChecked={isSelected}
                  name={option.value}
                  onChange={() => onOptionClick(option)}
                  selectorItemLabelComponent={option.label}
                  value={option.value}
                  isSoldOut={isSoldOut}
                  itemLabelStyle={itemSizeLabelStyle}
                  inputStyle={inputSizeStyle}
                  itemSoldOutStyle={itemSoldOutStyle}
                />
              );
            })}
          </SelectorList>
        </>
      )}
    </ProductAttributeSelector>
  );
};

ReviewsSizeSelector.propTypes = {
  addItem: PropTypes.func,
  inputSizeStyle: PropTypes.any,
  itemSizeLabelStyle: PropTypes.any,
  itemSoldOutStyle: PropTypes.any,
  removeItem: PropTypes.func,
  selectedItems: PropTypes.array,
};

export default ReviewsSizeSelector;

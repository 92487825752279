import { tabbable } from 'tabbable';

/**
 * Options for getRelativeTabbable
 * @typedef {Object} getRelativeTabbableOptions
 * @property {boolean} wrap - Set to true to make tabbable searches wrap around
 * to the beginning once they've reached the end, and vice-versa. When false,
 * going out of bounds will return a `null` result
 * @property {number} direction - The direction to proceed relative to the
 * tabbableReferenceElement to retrieve the tabbable element. `1` gives the
 * next tabbable, `-1` gives the previous tabbable.
 */

/**
 * Get a tabbable element relative to the given element in the tab order
 * @param {HTMLElement=} tabbableReferenceElement - element to move relative to
 * in the tab order.
 * @param {getRelativeTabbableOptions=} options
 */
export function getRelativeTabbable(
  tabbableReferenceElement = document.activeElement,
  { wrap = false, direction = 1 } = {}
) {
  const tabbables = tabbable(document.body, { displayCheck: 'non-zero-area' });
  const currentIndex = tabbables.findIndex(
    (el) => el === tabbableReferenceElement
  );

  if (
    currentIndex < 0 ||
    (!wrap &&
      (currentIndex + direction < 0 ||
        currentIndex + direction >= tabbables.length))
  ) {
    return null;
  }

  return tabbables[
    (currentIndex + direction + tabbables.length) % tabbables.length
  ];
}

/**
 * Get the next tabbable element in the tab order, relative to the given element
 * @param {HTMLElement=} tabbableReferenceElement - element to move relative to
 * in the tab order.
 * @param {getRelativeTabbableOptions=} options
 */
export const getNextTabbable = (tabbableReferenceElement, options) =>
  getRelativeTabbable(tabbableReferenceElement, { direction: 1, ...options });

/**
 * Get the previous tabbable element in the tab order, relative to the given
 * element
 * @param {HTMLElement=} tabbableReferenceElement - element to move relative to
 * in the tab order.
 * @param {getRelativeTabbableOptions=} options
 */
export const getPrevTabbable = (tabbableReferenceElement, options) =>
  getRelativeTabbable(tabbableReferenceElement, { direction: -1, ...options });

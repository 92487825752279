import React, { createContext, useContext, useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useCustomer } from '../../../../techstyle-shared/react-accounts';
import { desktop } from '../styles';

const InfoLabel = styled.div`
  font-size: 14px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  row-gap: 8px;

  ${desktop`
    grid-template-columns: 50% 50%;
  `}
`;

const SizePreferencesContext = createContext();

export function useSizePreferenceState() {
  return useContext(SizePreferencesContext);
}

export const SizePreference = ({ children, sizeField, ...rest }) => {
  const {
    profile: { [sizeField]: value },
  } = useSizePreferenceState();
  return (
    <InfoLabel {...rest}>
      {children}
      {value}
    </InfoLabel>
  );
};

const sizeFieldPropType = PropTypes.oneOf([
  'shoeSize',
  'braSize',
  'shoeWidth',
  'calfSize',
  'clothingSize',
  'denimSize',
  'braSupport',
  'dressSize',
  'lingerieSleepSize',
  'topSize',
  'braletteSize',
  'onePieceSize',
]);

SizePreference.propTypes = {
  children: PropTypes.node,
  sizeField: sizeFieldPropType,
};

// eslint-disable-next-line react/display-name
export const getSizePreferenceComponent = (sizeField) => (props) =>
  <SizePreference sizeField={sizeField} {...props} />;

export default function SizePreferences({ children }) {
  const { profile } = useCustomer();
  const value = useMemo(
    () => ({
      profile,
    }),
    [profile]
  );
  return (
    <SizePreferencesContext.Provider value={value}>
      {children}
    </SizePreferencesContext.Provider>
  );
}

SizePreferences.propTypes = {
  children: PropTypes.node,
};

SizePreferences.SizePreference = SizePreference;
SizePreferences.Wrapper = Wrapper;

SizePreferences.ShoeSize = getSizePreferenceComponent('shoeSize');
SizePreferences.ShoeWidth = getSizePreferenceComponent('shoeWidth');
SizePreferences.BraletteSize = getSizePreferenceComponent('braletteSize');
SizePreferences.DressSize = getSizePreferenceComponent('dressSize');
SizePreferences.OnePieceSize = getSizePreferenceComponent('onePieceSize');
SizePreferences.TopSize = getSizePreferenceComponent('topSize');
SizePreferences.LingerieSleepSize = getSizePreferenceComponent(
  'lingerieSleepSizeSize'
);
SizePreferences.BraSupport = getSizePreferenceComponent('braSupport');
SizePreferences.BraSize = getSizePreferenceComponent('braSize');
SizePreferences.DenimSize = getSizePreferenceComponent('denimSize');
SizePreferences.ClothingSize = getSizePreferenceComponent('clothingSize');
SizePreferences.CalfSize = getSizePreferenceComponent('calfSize');

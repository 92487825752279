import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';

import {
  FormattedMessage,
  FormattedNumber,
} from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import { useProductBrowser } from '../ProductBrowserContext';
import { desktop } from '../styles';

const ButtonElement = styled(Button)`
  height: 48px;
  border: 0;
  margin: 0;
  padding: 0 16px;
  font-family: inherit;
  font-weight: normal;
  font-size: 16px;
  background: transparent;
  color: #333;
  cursor: pointer;

  ${desktop`
    display: none;
  `};
`;

const Chevron = styled(FaChevronRight)`
  font-size: 8px;
  margin-bottom: 2px;
  vertical-align: middle;
`;

const FilterCount = styled.span`
  color: #676767;

  ${({ filterCountStyle }) => filterCountStyle};
`;

const FilterDrawerButton = React.forwardRef(
  (
    {
      filterCount: filterCountFromProps,
      filterCountStyle,
      afterLabelIcon = <Chevron />,
      beforeLabelIcon,
      labelStyle,
      label: labelFromProps,
      ...rest
    },
    ref
  ) => {
    const productBrowserCtx = useProductBrowser();

    // default to 0 if no filterCountFromProps and no filterCountFromCtx are available
    let filterCount = 0;
    if (filterCountFromProps != null) {
      filterCount = filterCountFromProps;
    } else if (
      productBrowserCtx &&
      productBrowserCtx.totalFilterCount != null
    ) {
      filterCount = productBrowserCtx.totalFilterCount;
    }

    const label = labelFromProps ?? (
      <FormattedMessage
        id="site_product_grid.filter_label"
        defaultMessage="Filter"
      />
    );

    const toggleFilterDrawer = useCallback(() => {
      if (productBrowserCtx) {
        productBrowserCtx.setFilterDrawerOpen((prevState) => !prevState);
      }
    }, [productBrowserCtx]);

    return (
      <ButtonElement
        data-autotag="filter-count"
        labelStyle={labelStyle}
        ref={ref}
        variant="filterDrawerButton"
        onClick={toggleFilterDrawer}
        {...rest}
      >
        {beforeLabelIcon}
        {label}{' '}
        {filterCount > 0 ? (
          <FilterCount filterCountStyle={filterCountStyle}>
            <FormattedMessage
              id="site_product_filter.filter_count"
              defaultMessage="({filterCount})"
              values={{
                filterCount: <FormattedNumber value={filterCount} />,
              }}
            />
          </FilterCount>
        ) : null}{' '}
        {afterLabelIcon}
      </ButtonElement>
    );
  }
);

FilterDrawerButton.displayName = 'FilterDrawerButton';

FilterDrawerButton.propTypes = {
  /**
   * Icon to display to the right of the label, defaults to chevron
   */
  afterLabelIcon: PropTypes.node,
  /**
   * Icon to display to the left of the label
   */
  beforeLabelIcon: PropTypes.node,
  filterCount: PropTypes.number,
  filterCountStyle: PropTypes.any,
  label: PropTypes.node,
  labelStyle: PropTypes.any,
  showChevronIcon: PropTypes.bool,
};

export default FilterDrawerButton;

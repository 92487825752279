import { lighten } from 'polished';
import { css } from 'styled-components';

export const createButtonAdditionalStyle = ({
  foreground,
  background,
  borderColor,
}) => css`
  background: ${background};
  border-color: ${borderColor || background};
  ${foreground &&
  css`
    & > span {
      color: ${foreground};
    }
  `}
`;

export const createButtonDisabledStyle = ({
  foreground,
  background,
  borderColor,
}) => css`
  &&& {
    ${createButtonAdditionalStyle({ foreground, background, borderColor })}
    &:hover, &:focus, &:active {
      ${createButtonAdditionalStyle({ foreground, background, borderColor })}
    }
  }
`;

export const createBorderStyle = ({
  width = 1,
  style = 'solid',
  color,
  background,
}) => {
  return css`
    border: ${width}px ${style} ${color || background};
  `;
};

export const createButtonStyle = ({
  baseButton,
  baseButtonStyle,
  baseButtonLabelStyle,
  foreground = 'inherit',
  background,
  border,
  labelStyles = '',
  additionalStyles = '',
}) => {
  return {
    ...baseButton({ color: foreground }),
    buttonStyle: css`
      ${baseButtonStyle}
      // background, not background-color, to support use of 'linear-gradient'
      background: ${background || lighten(0.3, foreground)};
      ${createBorderStyle({ ...border, background })}
      ${additionalStyles}
    `,
    labelStyle: css`
      ${baseButtonLabelStyle}
      color: ${foreground};
      ${labelStyles}
    `,
  };
};

import React, { useContext, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default function createPortalAttachment(
  displayName = 'PortalAttachment'
) {
  const Context = React.createContext();

  function Container({ as: ElementType = 'div', ...rest }) {
    const { setContainer, setContainerCount } = useContext(Context);

    useEffect(() => {
      setContainerCount((count) => count + 1);
      return () => setContainerCount((count) => count - 1);
    }, [setContainerCount]);

    return <ElementType ref={setContainer} {...rest} />;
  }

  Container.displayName = `${displayName}.Container`;
  Container.propTypes = { as: PropTypes.elementType };

  function Provider({ children }) {
    const [containerCount, setContainerCount] = useState(0);
    const [container, setContainer] = useState(null);

    if (containerCount > 1) {
      throw new Error(
        `Multiple <${displayName}.Container> elements detected; only one may be mounted at a time.`
      );
    }

    const value = useMemo(
      () => ({ container, setContainer, setContainerCount }),
      [container]
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  Provider.displayName = `${displayName}.Provider`;
  Provider.propTypes = { children: PropTypes.node };

  function PortalAttachment({ children }) {
    const context = useContext(Context);

    if (!context) {
      throw new Error(
        `${displayName} context not found, did you forget to add a <${displayName}.Provider>?`
      );
    }

    const { container } = context;

    if (!container) {
      return null;
    }

    return ReactDOM.createPortal(children, container);
  }

  PortalAttachment.displayName = displayName;
  PortalAttachment.propTypes = { children: PropTypes.node };

  PortalAttachment.Container = Container;
  PortalAttachment.Context = Context;
  PortalAttachment.Provider = Provider;

  return PortalAttachment;
}

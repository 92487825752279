import React, { useCallback } from 'react';

import styled from 'styled-components';

import { AdminPanel } from '../../../../techstyle-shared/react-admin';
import { useSession } from '../../../../techstyle-shared/redux-core';
import useAccountActions from '../useAccountActions';
import useCustomer from '../useCustomer';

const Columns = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MainColumn = styled.section`
  flex: 1 1 auto;
  overflow: auto;
`;

const CustomerDetails = styled.section`
  padding: 15px;
`;

const AddCustomerDetail = styled.section`
  padding: 15px;
  word-wrap: break-word;
`;

const HStack = styled.div`
  display: flex;
  align-items: center;
`;

const CustomerForm = styled.form`
  margin: 0 0 15px 0;
`;

const ActionInput = styled.input`
  flex: 1 1 auto;
  border: 1px solid #bbb;
  margin: 5px 10px 5px 0;
  padding: 3px 5px;
  font-size: 13px;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1);
`;

const ActionButton = styled(AdminPanel.Button)`
  flex: 0 0 auto;
`;

const CustomerDetailsHeader = styled.h2`
  font-size: inherit;
  font-weight: bold;
  margin: 0 0 10px 0;
`;

const CustomerName = styled.strong`
  color: ${({ theme }) => theme.colors.lightBlack};
`;

const Content = styled.section`
  padding: 20px;
`;

export default function CustomerDetailsAdminPanel() {
  const customer = useCustomer();
  const { isLoggedIn } = useSession();
  const accountActions = useAccountActions();

  const addCustomerDetailAction = useCallback(
    (event) => {
      event.preventDefault();
      const form = event.target;
      const { customerDetailName, customerDetailValue } = form.elements;
      const name = customerDetailName.value?.trim();
      const value = customerDetailValue.value?.trim();
      const date = new Date();
      const formattedDate = date.toISOString();

      if (!name || !value) {
        throw new Error('Please provide name and value');
      }
      accountActions.updateCustomerDetail({
        name,
        value,
        dateTimeAdded: formattedDate,
      });
    },
    [accountActions]
  );

  return (
    <AdminPanel id="customerDetails" label="Customer">
      <Columns>
        <AdminPanel.Sidebar title="Customer Details" width={350}>
          <CustomerDetails>
            <AddCustomerDetail>
              <AdminPanel.Field label="Add Customer Detail">
                <CustomerForm
                  name="addCustomerDetail"
                  onSubmit={addCustomerDetailAction}
                >
                  <HStack>
                    <ActionInput
                      name="customerDetailName"
                      size={6}
                      placeholder="name"
                    />
                  </HStack>
                  <HStack>
                    <ActionInput
                      name="customerDetailValue"
                      size={6}
                      placeholder="value"
                    />
                  </HStack>
                  <ActionButton type="submit">Add Detail</ActionButton>
                </CustomerForm>
              </AdminPanel.Field>
            </AddCustomerDetail>
          </CustomerDetails>
        </AdminPanel.Sidebar>
        <MainColumn>
          <Content>
            <CustomerDetailsHeader>
              Customer Data
              {isLoggedIn && (
                <>
                  :{' '}
                  <CustomerName>
                    {customer.firstName
                      ? `${customer.firstName} ${customer.lastName} (${customer.email})`
                      : customer.email}
                  </CustomerName>
                </>
              )}
            </CustomerDetailsHeader>
            {isLoggedIn ? (
              <AdminPanel.DataTable data={customer} />
            ) : (
              <p>Sign in to see customer data.</p>
            )}
          </Content>
        </MainColumn>
      </Columns>
    </AdminPanel>
  );
}

import { ProductType } from './constants';

// The gridIndex, productHref and size related fields should be added to product object
// when calling getTrackProductDetails.
export default function getTrackProductDetails(productInfo) {
  // Individual fields can be overwritten when tracking event.
  const {
    product,
    currentComponentIndex,
    isVip,
    href,
    gridIndex,
    size,
    sizedProductId,
    sizedItemNumber,
    imageUrl,
  } = productInfo;

  const isBundle = product.productTypeId === ProductType.BUNDLE;
  const currentComponent = isBundle
    ? product.componentProductIdObjectList[currentComponentIndex]
    : null;
  const priceContribution =
    currentComponent && currentComponent.priceContributionPercentage;
  const finalPrice = isVip ? product.defaultUnitPrice : product.retailUnitPrice;
  const salePrice =
    product.promos.promoPrice && product.saleUnitPrice
      ? Math.min(product.promos.promoPrice, product.saleUnitPrice)
      : product.promos.promoPrice || product.saleUnitPrice;
  const hasReviews = product.reviewCount > 0;

  return isBundle
    ? {
        bundle_alias: `${product.alias}`,
        bundle_name: `${product.label}`,
        bundle_product_id: `${product.masterProductId}` || null,
        bundle_quantity: product.componentCount || null,
        bundle_retail_price: product.retailUnitPrice || null,
        bundle_sale_price: salePrice,
        bundle_url: `${href}` || null,
        bundle_vip_price: product.defaultUnitPrice || null,
        category:
          `${currentComponent.defaultProductCategoryLabel}` ||
          `${product.defaultProductCategoryLabel}` ||
          null,
        is_bundle: isBundle,
        grid_label: `${product.promos.promoLabel}` || null,
        has_reviews: hasReviews,
        image_url: imageUrl,
        inventory_availability: product.availableQuantityMaster,
        name: `${currentComponent.label}` || null,
        position: gridIndex + 1,
        price: (salePrice || finalPrice) * priceContribution,
        product_id: currentComponent.componentProductId
          ? `${currentComponent.componentProductId}`
          : null,
        retail_price: product.retailUnitPrice * priceContribution,
        review_num: product.reviewCount,
        review_rating: product.averageReview || null,
        sale_price: salePrice ? salePrice * priceContribution : null,
        size: `${size}`,
        sku: `${product.itemNumber}` || null,
        url: href || null,
        variant: `${currentComponent.color}` || null,
        vip_price: product.defaultUnitPrice * priceContribution,
      }
    : {
        bundle_alias: null,
        bundle_name: null,
        bundle_product_id: null,
        bundle_quantity: null,
        bundle_retail_price: null,
        bundle_sale_price: null,
        bundle_url: null,
        bundle_vip_price: null,
        category: `${product.defaultProductCategoryLabel}` || null,
        is_bundle: isBundle,
        grid_label: `${product.promos.promoLabel}` || null,
        has_reviews: hasReviews,
        image_url: imageUrl,
        inventory_availability:
          product.availableQuantityMaster ||
          product.availableQuantityAnyProfile,
        name: `${product.label}`,
        position: gridIndex + 1,
        price: salePrice || finalPrice,
        product_id: product.masterProductId
          ? `${product.masterProductId}`
          : null,
        retail_price: product.retailUnitPrice,
        review_num: product.reviewCount,
        review_rating: product.averageReview || null,
        sale_price: salePrice || null,
        size: `${size}`,
        sized_product_id: `${sizedProductId}`,
        sized_sku: `${sizedItemNumber}`,
        sku: `${product.itemNumber}` || null,
        url: href || null,
        variant: `${product.color}` || null,
        vip_price: product.defaultUnitPrice || null,
      };
}

import React, { useEffect } from 'react';

import getCardType from 'credit-card-type';
import { Formik, FormikContextType, useFormikContext } from 'formik';
import styled, { StyledComponent } from 'styled-components';
import { number, object, SchemaOf, string } from 'yup';

import BaseFormField from '../FormField';
import PaymentTypeIcons from '../PaymentTypeIcons';

type GetDefaultFormSchemaProps = {
  errorMessages: {
    addressIsRequired: string;
    cardCodeIsRequired: string;
    cardNumIsRequired: string;
    expirationDateIsRequired: string;
    nameOnCardIsRequired: string;
  };
};
type FormikContext = FormikContextType<{
  addressId: number | null;
  cardCode: string;
  cardNum: string;
  cardType: string;
  expirationDate: string;
  nameOnCard: string;
}>;
type PaymentMethod = {
  addressId: number | null;
  cardCode: string;
  cardNum: string;
  cardType: string;
  expirationDate: string;
  nameOnCard: string;
};
const getDefaultFormSchema = ({
  errorMessages = {
    addressIsRequired: '',
    cardCodeIsRequired: '',
    cardNumIsRequired: '',
    expirationDateIsRequired: '',
    nameOnCardIsRequired: '',
  },
}: GetDefaultFormSchemaProps) => {
  return object({
    addressId: number().required(errorMessages.addressIsRequired),
    cardCode: string().required(errorMessages.cardCodeIsRequired),
    cardNum: string().required(errorMessages.cardNumIsRequired),
    cardType: string().required(),
    expirationDate: string().required(errorMessages.expirationDateIsRequired),
    nameOnCard: string().required(errorMessages.nameOnCardIsRequired),
  });
};
const FormField = styled(BaseFormField)`
  flex: 1;
`;
type CardNumberProps = {
  label: string;
};
const MAX_CARD_NUMBER_CHARS = 16;
const MAX_SPACES_BETWEEN_CHARS = 3;
const maxChars = MAX_CARD_NUMBER_CHARS + MAX_SPACES_BETWEEN_CHARS;
const CardNumber = ({ label, ...rest }: CardNumberProps) => {
  const { setFieldValue, values }: FormikContext = useFormikContext();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= maxChars) {
      const {
        currentTarget: { value: nextValue },
      } = e;
      const filteredString = nextValue.replace(/\s+/g, '');
      const group1 = filteredString.substring(0, 4);
      const group2 = filteredString.substring(4, 8);
      const group3 = filteredString.substring(8, 12);
      const group4 = filteredString.substring(12, 16);
      setFieldValue(
        'cardNum',
        `${group1}${group2 && ' '}${group2}${group3 && ' '}${group3}${
          group4 && ' '
        }${group4}`,
        false
      );
    }
  };
  return (
    <FormField
      name="cardNum"
      label={label}
      // @ts-ignore
      onChange={handleChange}
      value={values.cardNum}
      {...rest}
    />
  );
};
const PAYMENT_TYPES = PaymentTypeIcons.PAYMENT_TYPES;
const CardTypes: Record<string, string | undefined> = {
  'american-express': PAYMENT_TYPES.AMEX,
  mastercard: PAYMENT_TYPES.MASTERCARD,
};
const CardType = ({ children, ...rest }: React.PropsWithChildren<{}>) => {
  const { setFieldValue, values }: FormikContext = useFormikContext();
  const value = values.cardNum?.substring(0, 4);
  const [match = { type: null }] = getCardType(value);
  useEffect(() => {
    const cardType =
      value.length && match.type ? CardTypes[match.type] || match.type : null;
    setFieldValue('cardType', cardType, false);
  }, [match.type, setFieldValue, value]);
  return (
    <>
      {typeof children === 'function' ? (
        /* @ts-ignore */
        children({ cardType: values.cardType })
      ) : (
        <PaymentTypeIcons.PaymentTypeIcon
          paymentType={values.cardType}
          {...rest}
        />
      )}
    </>
  );
};
type ExpirationDateProps = {
  label: string;
};
const MAX_MONTH_DIGITS = 2;
const MAX_YEAR_DIGITS = 2;
const MAX_SEPARATOR_COUNT = 1;
const ExpirationDate = ({ label = 'MM/YY' }: ExpirationDateProps) => {
  const { setFieldValue, values }: FormikContext = useFormikContext();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value.length <=
      MAX_MONTH_DIGITS + MAX_YEAR_DIGITS + MAX_SEPARATOR_COUNT
    ) {
      const {
        currentTarget: { value: nextValue },
      } = e;
      const digits = nextValue.replace(/\D+/g, '');
      const month = digits.substring(0, 2);
      const year = digits.substring(2, 4);
      setFieldValue('expirationDate', `${month}${year && '/'}${year}`, false);
    }
  };
  return (
    <FormField
      label={label}
      name="expirationDate"
      // @ts-ignore
      onChange={handleChange}
      value={values.expirationDate}
    />
  );
};
interface RootProps
  extends Omit<
    React.ComponentPropsWithRef<typeof Formik>,
    'initialValues' | 'validationSchema'
  > {
  paymentMethod?: PaymentMethod;
  validationSchema: SchemaOf<PaymentMethod>;
}
const Form: StyledComponent<React.ElementType, {}> = styled.form`
  display: flex;
  flex-direction: column;
`;
const Root = ({
  children,
  paymentMethod = {
    addressId: null,
    cardCode: '',
    cardNum: '',
    cardType: '',
    expirationDate: '',
    nameOnCard: '',
  },
  onSubmit,
  validationSchema,
  ...rest
}: RootProps) => {
  const { addressId, cardCode, cardNum, cardType, expirationDate, nameOnCard } =
    paymentMethod;
  const initialValues: PaymentMethod = {
    addressId,
    cardCode,
    cardNum,
    cardType,
    expirationDate,
    nameOnCard,
  };
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      {...rest}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => {
        return <Form onSubmit={handleSubmit}>{children}</Form>;
      }}
    </Formik>
  );
};
export {
  CardNumber,
  CardType,
  ExpirationDate,
  FormField,
  getDefaultFormSchema,
  Root,
};

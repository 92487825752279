import React, { useState, useEffect, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import Button from '../Button';
import EqualWebSnippet from '../EqualWebSnippet';
import LoadingStatus from '../LoadingStatus';
import usePolling from '../usePolling';

const HideButtonStyle = createGlobalStyle`
  #INDmenu-btn {
    /* Don't use display: none here. We check for the button's dimensions as a
       more foolproof way to detect when it's ready to click. */
    visibility: hidden;
    opacity: 0;
    z-index: -1;
  }
`;

export default function EqualWebButton({
  as: ElementType = Button,
  children,
  initialLoadSnippet = false,
  loadSnippetOnTabPress = false,
  hideDefaultButton = true,
  pollingOptions,
  snippetOptions,
  ...rest
}) {
  const [loadSnippet, setLoadSnippet] = useState(initialLoadSnippet);
  const [hasPendingClick, setPendingClick] = useState(false);

  const checkForButton = useMemo(() => {
    if (loadSnippet) {
      return () => {
        const button = document.getElementById('INDmenu-btn');
        if (button) {
          // Wait for the button to actually have content/dimensions.
          const rect = button.getBoundingClientRect();
          if (rect.width > 0) {
            return button;
          }
        }
      };
    }
  }, [loadSnippet]);

  const button = usePolling({
    condition: checkForButton,
    interval: 500,
    maxPollCount: 120,
    ...pollingOptions,
  });

  const isLoading = loadSnippet && !button;

  const handleClick = useCallback(() => {
    window.requestAnimationFrame(() => button.click());
  }, [button]);

  const handlePendingClick = useCallback(() => {
    setLoadSnippet(true);
    setPendingClick(true);
  }, []);

  useEffect(() => {
    if (hasPendingClick && button) {
      window.requestAnimationFrame(() => button.click());
      setPendingClick(false);
    }
  }, [button, hasPendingClick]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const doLoadSnippet =
        typeof loadSnippetOnTabPress === 'function'
          ? loadSnippetOnTabPress(event)
          : loadSnippetOnTabPress;
      if (event.key === 'Tab' && doLoadSnippet && !loadSnippet) {
        setLoadSnippet(true);
        setPendingClick(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [loadSnippetOnTabPress, loadSnippet]);

  return (
    <>
      {hideDefaultButton ? <HideButtonStyle /> : null}
      {loadSnippet ? <EqualWebSnippet {...snippetOptions} /> : null}
      <LoadingStatus>
        <ElementType
          onClick={button ? handleClick : handlePendingClick}
          {...rest}
        >
          {children}
        </ElementType>
        {isLoading && hasPendingClick ? <LoadingStatus.Loading /> : null}
      </LoadingStatus>
    </>
  );
}

EqualWebButton.propTypes = {
  /**
   * Element type to render; defaults to `Button`.
   */
  as: PropTypes.elementType,
  /**
   * Content to render inside the `Button`.
   */
  children: PropTypes.node,
  /**
   * Injects the css to hide the widget's default button
   */
  hideDefaultButton: PropTypes.bool,
  /**
   * Renders the widget snippet when the page loads.
   */
  initialLoadSnippet: PropTypes.bool,
  /**
   * Renders the widget snippet on tab press. Can be a boolean or a function that returns a boolean.
   */
  loadSnippetOnTabPress: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  /**
   * Options to pass onto the usePolling hook.
   */
  pollingOptions: PropTypes.object,
  /**
   * Options to pass onto the EqualWebSnippet.
   */
  snippetOptions: PropTypes.object,
};

import React, { useCallback, useState, useContext, useMemo } from 'react';

import Observer from '@researchgate/react-intersection-observer';
import PropTypes from 'prop-types';
import { FaFilter } from 'react-icons/fa';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

import {
  FormattedMessage,
  FormattedNumber,
} from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import { useProductBrowser } from '../ProductBrowserContext';
import { forceReflow } from '../useSizeTransition';

// A custom styled version of our shared Button component to make use
// of all the functionality the Button component has already built-in

if (process.browser) {
  require('intersection-observer');
}

const FilterButton = styled(Button)`
  min-width: 105px;
  width: auto;
  height: 40px;
  border-radius: 7px;
  margin: 16px 0 0 0;
  padding: 0 8px;
  box-shadow: 0 4px 8px 0 rgba(162, 71, 107, 0.16);
  border: none;
  background-color: #a3476b;
  color: white;
  z-index: -1;
  transform: translate3d(0, 0, 0);
  transition: all ${(props) => props.transitionDuration}ms;

  &[data-transition-state='exiting'],
  &[data-transition-state='exited'] {
    /* There's no default margin, but if the consumer overrides margin, we
       should transition it to 0 on exit. */
    margin-top: 0;
    transform: translate3d(0, -100%, 0);
  }
`;

const ButtonLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const LabelText = styled.span`
  margin: 0 5px;

  ${({ labelTextStyle }) => labelTextStyle};
`;

const Context = React.createContext();

export function useFilterButtonContext() {
  return useContext(Context);
}

const InlineButtonObserver = ({ children }) => {
  const context = useFilterButtonContext();
  if (!context) {
    throw new Error(
      'FloatingFilterButton context not found, did you forget to add a <FloatingFilterButton.Provider>?'
    );
  }
  const { setFloatingButtonVisible } = context;
  const handleIntersection = useCallback(
    (entry) => {
      const { isIntersecting, boundingClientRect } = entry;
      // Check if the button is visible (not within the viewport, but rather
      // non-hidden via e.g. `display: none`. If it's not, then we shouldn't
      // render this button no matter what.
      if (boundingClientRect.width && boundingClientRect.height) {
        setFloatingButtonVisible(!isIntersecting);
      } else {
        setFloatingButtonVisible(false);
      }
    },
    [setFloatingButtonVisible]
  );

  const options = {
    root: process.browser && window.frameElement ? document : undefined,
    rootMargin: '0px 0px 8000px 0px',
  };

  return (
    <Observer onChange={handleIntersection} {...options}>
      {children}
    </Observer>
  );
};

InlineButtonObserver.displayName = 'FloatingFilterButton.InlineButtonObserver';
InlineButtonObserver.propTypes = { children: PropTypes.node };

function Provider({ children }) {
  const [isVisible, setFloatingButtonVisible] = useState(false);

  const value = useMemo(
    () => ({ isVisible, setFloatingButtonVisible }),
    [isVisible]
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

Provider.displayName = 'FloatingFilterButton.Provider';
Provider.propTypes = { children: PropTypes.node };

export const FloatingFilterButton = React.forwardRef(
  (
    {
      labelTextStyle,
      label = (
        <LabelText labelTextStyle={labelTextStyle}>
          <FormattedMessage
            id="site_product_grid.filters_title"
            defaultMessage="Filters"
          />
        </LabelText>
      ),
      icon = <FaFilter />,
      variant = 'floatingFilterButton',
      filterCount: filterCountFromProps,
      isVisible,
      transitionDuration = 300,
      ...rest
    },
    ref
  ) => {
    const context = useFilterButtonContext();
    const productBrowserCtx = useProductBrowser();

    let filterCount = filterCountFromProps;

    // props take priority if there are both context and props available
    if (productBrowserCtx && filterCountFromProps == null) {
      filterCount = productBrowserCtx.totalFilterCount;
    }

    const toggleFilterDrawer = useCallback(() => {
      if (productBrowserCtx) {
        productBrowserCtx.setFilterDrawerOpen((prevState) => !prevState);
      }
    }, [productBrowserCtx]);

    if (isVisible == null) {
      if (!context) {
        throw new Error(
          'FloatingFilterButton context not found, did you forget to add a <FloatingFilterButton.Provider>?'
        );
      }
      isVisible = context.isVisible;
    }

    return (
      <Transition
        in={isVisible}
        mountOnEnter
        unmountOnExit
        timeout={transitionDuration}
        onEnter={forceReflow}
      >
        {(state) => (
          <FilterButton
            ref={ref}
            data-autotag="filter-pill"
            data-transition-state={state}
            transitionDuration={transitionDuration}
            variant={variant}
            onClick={toggleFilterDrawer}
            {...rest}
          >
            <ButtonLabel>
              {icon}
              {label}
              {filterCount > 0 && (
                <FormattedMessage
                  id="site_product_filter.filter_count"
                  defaultMessage="({filterCount})"
                  values={{
                    filterCount: <FormattedNumber value={filterCount} />,
                  }}
                />
              )}
            </ButtonLabel>
          </FilterButton>
        )}
      </Transition>
    );
  }
);

FloatingFilterButton.InlineButtonObserver = InlineButtonObserver;
FloatingFilterButton.Context = Context;
FloatingFilterButton.Provider = Provider;
FloatingFilterButton.displayName = 'FloatingFilterButton';
FloatingFilterButton.propTypes = {
  /**
   * Number of filters currently applied
   */
  filterCount: PropTypes.number,
  /**
   * Icon to display on Filter Button
   */
  icon: PropTypes.node,
  /**
   * Override the context value to determine whether or not the button is visible
   */
  isVisible: PropTypes.bool,
  /**
   * FormattedMessage text for button
   */
  label: PropTypes.node,
  /**
   * Style to apply to the button label.
   */
  labelTextStyle: PropTypes.any,
  /**
   * How long the transition will last when the button appears and disappears.
   */
  transitionDuration: PropTypes.number,
  /**
   * The theme variant to pass to the Button component. Defaults to `primary`.
   */
  variant: PropTypes.string,
};

export default FloatingFilterButton;

import React, { ComponentProps, ReactNode } from 'react';

import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';

import Button from '../Button';
import { createContext } from '../utils/createContext';

export type Rating = 1 | 2 | 3 | 4 | 5;

export type ReviewRatingContextType = {
  isActive?: boolean;
  percentage: number;
  rating: Rating;
  ratingCount: number;
  reviewCount: number;
};

const [useReviewRatingContext, ReviewRatingContext] =
  createContext<ReviewRatingContextType>('ReviewRating');

const getState = (isActive?: boolean) => (isActive ? 'active' : 'inactive');

/* -------------------------------------------------------------------------------------------------
 * Root
 * ---------------------------------------------------------------------------------------------- */

type RootProps = {
  children: ReactNode;

  /**
   * The active state of the bar (i.e. when a rating is currently used as a filter it is active)
   */
  isActive?: boolean;

  /**
   * Rating in range 1 - 5
   */
  rating: Rating;

  /**
   * Total number of reviews for a particular rating
   */
  ratingCount: number;

  /**
   * Total number of reviews for this product
   */
  reviewCount: number;
};

const Root = ({
  children,
  isActive,
  rating,
  ratingCount,
  reviewCount,
}: RootProps) => {
  const percentage = reviewCount > 0 ? (ratingCount / reviewCount) * 100 : 0;

  return (
    <ReviewRatingContext.Provider
      value={{ isActive, percentage, rating, ratingCount, reviewCount }}
    >
      {children}
    </ReviewRatingContext.Provider>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Container
 * ---------------------------------------------------------------------------------------------- */

const ContainerInner = styled(Button)`
  background: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  min-height: unset;
  min-width: unset;
  font-size: inherit;
  cursor: pointer;

  &:disabled {
    background-color: transparent;
    color: unset;
    cursor: default;
    opacity: 1;
  }

  & > span {
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
  }

  ${({ barContainerStyle }) => barContainerStyle}
`;

type ContainerProps = ComponentProps<typeof ContainerInner> & {
  /**
   * Allows the value of `isActive` from the root context to be overriden
   */
  isActive?: boolean;

  /**
   * Click handler when a rating bar is clicked
   */
  onClick?: (rating: Rating) => void;
};

const Container = ({
  children,
  isActive: isActiveFromProps,
  onClick,
  barContainerStyle,
  ...rest
}: ContainerProps) => {
  const {
    isActive: isActiveFromContext,
    rating,
    ratingCount,
  } = useReviewRatingContext();

  const isActive =
    isActiveFromProps !== undefined ? isActiveFromProps : isActiveFromContext;

  const allowClick = !!(ratingCount > 0 && onClick);

  const handleClick = () => {
    onClick && onClick(rating);
  };

  return (
    <ContainerInner
      disabled={!allowClick}
      onClick={handleClick}
      data-state={getState(isActive)}
      barContainerStyle={barContainerStyle}
      {...rest}
    >
      {children}
    </ContainerInner>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Star Icon
 * ---------------------------------------------------------------------------------------------- */

const InnerStarIcon = styled(FaStar)`
  font-size: 12px;
`;

type StarIconProps = ComponentProps<typeof InnerStarIcon> & {
  /**
   * Allows the value of `isActive` from the root context to be overriden
   */
  isActive?: boolean;
};

const StarIcon = ({ isActive: isActiveFromProps, ...rest }: StarIconProps) => {
  const { isActive: isActiveFromContext } = useReviewRatingContext();

  const isActive =
    isActiveFromProps !== undefined ? isActiveFromProps : isActiveFromContext;

  return <InnerStarIcon data-state={getState(isActive)} {...rest} />;
};

/* -------------------------------------------------------------------------------------------------
 * Label
 * ---------------------------------------------------------------------------------------------- */

const LabelInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -${0.35 / 16}px;
  color: #222222;
`;

type LabelProps = ComponentProps<typeof LabelInner> & {
  /**
   * Allows the value of `isActive` from the root context to be overriden
   */
  isActive?: boolean;

  /**
   * Icon to override default star icon
   */
  starIcon?: ReactNode;
};

const Label = ({
  isActive: isActiveFromProps,
  starIcon = <StarIcon />,
  ...rest
}: LabelProps) => {
  const { isActive: isActiveFromContext, rating } = useReviewRatingContext();

  const isActive =
    isActiveFromProps !== undefined ? isActiveFromProps : isActiveFromContext;

  return (
    <LabelInner data-state={getState(isActive)} {...rest}>
      {rating} {starIcon}
    </LabelInner>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Bar
 * ---------------------------------------------------------------------------------------------- */

const BarInner = styled.div`
  background-color: #eeeeee;
  flex-grow: 1;
  height: 8px;
  margin: 10px 0;
  border-radius: 4px;
  overflow: hidden;
`;

type BarProps = ComponentProps<typeof BarInner> & {
  /**
   * Allows the value of `isActive` from the root context to be overriden
   */
  isActive?: boolean;
};

const Bar = ({ isActive: isActiveFromProps, children, ...rest }: BarProps) => {
  const { isActive: isActiveFromContext } = useReviewRatingContext();

  const isActive =
    isActiveFromProps !== undefined ? isActiveFromProps : isActiveFromContext;

  return (
    <BarInner data-state={getState(isActive)} {...rest}>
      {children}
    </BarInner>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Bar Indicator
 * ---------------------------------------------------------------------------------------------- */

const BarIndicatorInner = styled.div<{ $percentage: number }>`
  background-color: #000000;
  height: 100%;
  width: ${({ $percentage }) => $percentage}%;
  border-radius: 4px;
`;

type BarIndicatorProps = ComponentProps<typeof BarIndicatorInner> & {
  /**
   * Allows the value of `isActive` from the root context to be overriden
   */
  isActive?: boolean;
};

const BarIndicator = ({
  isActive: isActiveFromProps,
  ...rest
}: BarIndicatorProps) => {
  const { isActive: isActiveFromContext, percentage } =
    useReviewRatingContext();

  const isActive =
    isActiveFromProps !== undefined ? isActiveFromProps : isActiveFromContext;

  return (
    <BarIndicatorInner
      $percentage={percentage}
      data-state={getState(isActive)}
      {...rest}
    />
  );
};

/* -------------------------------------------------------------------------------------------------
 * Count
 * ---------------------------------------------------------------------------------------------- */

const CountInner = styled.span`
  font-size: 14px;
  letter-spacing: -${0.35 / 16}px;
  text-decoration: underline;
  color: #222222;
  min-width: 30px;
  text-align: right;
`;

type CountProps = ComponentProps<typeof CountInner> & {
  /**
   * Allows the value of `isActive` from the root context to be overriden
   */
  isActive?: boolean;
};

const Count = ({
  children,
  isActive: isActiveFromProps,
  ...rest
}: CountProps) => {
  const { isActive: isActiveFromContext, ratingCount } =
    useReviewRatingContext();

  const isActive =
    isActiveFromProps !== undefined ? isActiveFromProps : isActiveFromContext;

  return (
    <CountInner data-state={getState(isActive)} {...rest}>
      {children || ratingCount}
    </CountInner>
  );
};

export { Bar, BarIndicator, Container, Count, Label, Root, StarIcon };

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getMembership } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useMembership() {
  const actions = useAccountActions();
  const membership = useSelector(getMembership);

  useEffect(() => {
    if (!membership.networkStatus.isUpToDate) {
      actions.loadMembership();
    }
  }, [actions, membership.networkStatus.isUpToDate]);

  return membership;
}

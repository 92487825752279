import config from 'config';

import { createAction } from '../../../techstyle-shared/redux-core';

import { SearchPage } from './schema';

export const getSearchQuerySuggestionsRequest = createAction(
  'search/getSearchQuerySuggestionsRequest'
);
export const getSearchQuerySuggestionsSuccess = createAction(
  'search/getSearchQuerySuggestionsSuccess'
);
export const getSearchQuerySuggestionsFailure = createAction(
  'search/getSearchQuerySuggestionsFailure'
);

export const getSearchQueryResultsRequest = createAction(
  'search/getSearchQueryResultsRequest'
);
export const getSearchQueryResultsSuccess = createAction(
  'search/getSearchQueryResultsSuccess'
);
export const getSearchQueryResultsFailure = createAction(
  'search/getSearchQueryResultsFailure'
);

export function getSearchQuerySuggestions({ query }) {
  const compatibilityMode = config.get('public.search.compatibilityMode');
  const searchProvider = config.get('public.search.provider');
  //  Query suggestions will only be available for SS searchProvider
  if (searchProvider === 'ES') {
    throw new Error(
      'Getting search suggestions from the ElasticSearch search provider is not possible.'
    );
  }

  const params = {
    q: query,
  };

  let apiAction;
  let requests;
  switch (compatibilityMode) {
    case 'SX':
      apiAction = 'api';
      requests = {
        types: [
          getSearchQuerySuggestionsRequest.toString(),
          {
            type: getSearchQuerySuggestionsSuccess.toString(),
            payload(action, state, res) {
              return res.json().then((payload) => {
                // Rename field.
                const { 'corrected-query': correctedQuery, ...rest } = payload;
                return { ...rest, correctedQuery };
              });
            },
          },
          getSearchQuerySuggestionsFailure.toString(),
        ],
      };
      break;
    case 'BMIG':
      apiAction = 'bentoApi';
      requests = {
        actions: [
          getSearchQuerySuggestionsRequest,
          (payload, meta) => {
            // Rename field.
            const { 'corrected-query': correctedQuery, ...rest } = payload;
            payload = { ...rest, correctedQuery };
            return getSearchQuerySuggestionsSuccess(payload, meta);
          },
          getSearchQuerySuggestionsFailure,
        ],
      };
      break;
  }

  const action = {
    [apiAction]: {
      method: 'POST',
      endpoint: 'search/suggest',
      body: JSON.stringify(params),
      ...requests,
    },
  };

  return action;
}

export function getSearchQueryResults({
  aggregationFlags,
  aggregations,
  backgroundFilters,
  filters,
  page,
  query,
  searchType,
  pageSize,
  sort,
  merchantSegmentTags,
  ...rest
  // See API docs for all optional /search/products params:
  // https://qa1-tier1.techstyle.net/swagger-ui/index.html#/Search/searchProducts
}) {
  const compatibilityMode = config.get('public.search.compatibilityMode');
  const searchProvider = config.get('public.search.provider');

  if (Array.isArray(aggregationFlags)) {
    let flags = 0;
    aggregationFlags.forEach((flag) => {
      flags |= flag;
    });
    aggregationFlags = flags;
  }

  let endpoint;
  let method;
  let body;
  let searchParams;

  switch (searchProvider) {
    // What search provider to request - they accept different formats.
    case 'SS':
      endpoint = 'search/query';
      method = 'POST';
      body = {
        page,
        size: pageSize,
        sort,
        aggregations,
        backgroundFilters,
        filters,
        merchantSegmentTags,
        searchType,
        q: query,
      };
      break;
    case 'ES':
      endpoint = 'search/products';
      method = 'GET';
      searchParams = {
        page,
        size: pageSize,
        sort,
        aggs: aggregations,
        aggFlags: aggregationFlags,
        searchTerm: query,
        ...rest,
      };
      break;
  }

  switch (compatibilityMode) {
    // What action format `dispatch()` is expecting: legacy SX vs. BMIG.
    case 'SX':
      return {
        api: {
          endpoint,
          method,
          body: JSON.stringify(body),
          params: searchParams,
          types: [
            getSearchQueryResultsRequest.toString(),
            getSearchQueryResultsSuccess.toString(),
            getSearchQueryResultsFailure.toString(),
          ],
        },
      };
    case 'BMIG': // bentoApi action
      return {
        bentoApi: {
          endpoint,
          method,
          schema: SearchPage,
          json: body,
          searchParams,
          actions: [
            getSearchQueryResultsRequest,
            getSearchQueryResultsSuccess,
            getSearchQueryResultsFailure,
          ],
        },
      };
  }
}

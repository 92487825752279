import config from 'config';

/**
 * Responsible for parsing out the value(s) from the configured query parameter
 * into an array of overrides.
 *
 * Examples:
 * - 'feature_name:0' => [['feature_name', false]]
 * - 'feature_name,feature_name2:false' => [['feature_name', true], ['feature_name2', false]]
 */
export function parseOverridesFromQuery(queryValue) {
  return queryValue.split(',').map((featureValue) => {
    let [handle, value = true] = featureValue.split(':');
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
    value = Boolean(value);
    return [handle, value];
  });
}

/**
 * Handles transformation of handle => cookie name string
 *
 * Example:
 * - 'feature_name' => 'FMO-FEATURE_NAME'
 */
export function createFeatureOverrideCookieName({
  featureHandle,
  overridePrefix = config.get('public.features.overridePrefix'),
}) {
  return `${overridePrefix}-${featureHandle}`.toUpperCase();
}

/**
 * Handles transformation of cookie name => feature handle
 *
 * Example:
 * - 'FMO-FEATURE_NAME' => 'feature_name'
 */
export function getFeatureHandleFromCookieName({
  cookieName,
  overridePrefix = config.get('public.features.overridePrefix'),
}) {
  const re = new RegExp(`^${overridePrefix}-`);
  return cookieName.replace(re, '').toLowerCase();
}

/**
 * Tells us if a given cookie name is one of our overrides, based on our
 * configured `overridePrefix`
 *
 * Examples:
 * - 'FMO-FEATURE_NAME' => true
 * - 'CUSTOMER' => false
 */
export function isFeatureOverrideCookieName({
  cookieName,
  overridePrefix = config.get('public.features.overridePrefix'),
}) {
  const re = new RegExp(`^${overridePrefix}-`);
  return re.test(cookieName);
}

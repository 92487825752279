import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { FilterTag } from '../FilterTagsCustomFilters';
import { useOptionLabels } from '../LabelProvider';
import { LabelType } from '../LabelProvider/constants';
import { useProductFilters } from '../ProductFilterContextCustomFilters';
import ProductListingFilter from '../ProductListingFilter';

// eslint-disable-next-line react/prop-types
const AvgReviewComponent = ({
  filterField,
  listingFilterComponent: ListingFilterComponent = ProductListingFilter,
  setSelectedFilters,
}) => {
  const handleChange = ({ newSelectedFilters }) => {
    setSelectedFilters((oldSelectedFilters) => ({
      ...oldSelectedFilters,
      avgReviewMin: newSelectedFilters,
    }));
  };

  return (
    <ListingFilterComponent filterField={filterField} onChange={handleChange} />
  );
};

AvgReviewComponent.propTypes = {
  filterField: PropTypes.object,
  listingFilterComponent: PropTypes.elementType,
  setSelectedFilters: PropTypes.func,
};

const AvgReviewFilterTag = ({ filterSetting, selectedFilters, ...rest }) => {
  const optionLabelCtx = useOptionLabels();
  const { setSelectedFilters } = useProductFilters();

  const handleDismiss = useCallback(() => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      avgReviewMin: null,
    }));
  }, [setSelectedFilters]);

  const labelOptions = {
    labelType: LabelType.PRODUCT_FILTER_TAG,
  };
  const selectedOption = filterSetting.items.find(
    (item) => item.value === selectedFilters.avgReviewMin
  );
  return selectedOption ? (
    <FilterTag
      label={optionLabelCtx.renderLabel(
        filterSetting,
        selectedOption,
        labelOptions
      )}
      labelString={optionLabelCtx.renderLabelString(
        filterSetting,
        selectedOption,
        labelOptions
      )}
      onDismiss={handleDismiss}
      {...rest}
    />
  ) : null;
};

AvgReviewFilterTag.propTypes = {
  filterSetting: PropTypes.object,
  selectedFilters: PropTypes.object,
};

export const getAvgReviewFilter = () => ({
  // The label for the filter, shown when the filter itself is displayed.
  label: 'Avg Review Min',
  // The field the filter represents. This can be used in the functions below
  // to create more generic `fromUrlParams` and `toUrlParams` functions, but is
  // not obligatory for filters that don't rely on the LabelProvider or the
  field: 'avgReviewMin',
  // The different possible values for the filter. Filters that do not rely on
  // the ProductListingFilter or the LabelProvider do not have to use this field.
  items: [
    { value: 4, label: '4.0 & Up' },
    { value: 3, label: '3.0 & Up' },
  ],
  // This function gets the initial value for the filter if none is provided and
  // expects a full filter object as its return value.
  getDefaultValue: ({ filters }) => ({
    avgReviewMin: null,
    ...filters,
  }),
  // This function pulls the value of the filter from a given set of URL parameters.
  // The return value is the current set of filters. Aside from the two arguments
  // shown here, the function also receives the entire `filterSetting` object.
  fromUrlParams: ({ urlParams, filters }) => {
    const avgReviewMinString = urlParams.get('avg_review_min');
    const parsedAvgReviewMin = parseFloat(avgReviewMinString);
    // Make sure it's a number and within bounds.
    if (
      Number.isFinite(parsedAvgReviewMin) &&
      parsedAvgReviewMin > 0 &&
      parsedAvgReviewMin <= 5
    ) {
      return {
        ...filters,
        avgReviewMin: parsedAvgReviewMin,
      };
    }
    return filters;
  },
  // This function takes the current active filters and returns an object of the URL
  // parameters to be shown based on that filter. The object key matches the
  // key shown in the URL parameters, and the object is subsequently used to construct
  // a URLParams object.
  toUrlParams: ({ urlParams, filters, baseFilters }) => {
    if (
      filters.avgReviewMin != null &&
      filters.avgReviewMin !== baseFilters.avgReviewMin
    ) {
      return { ...urlParams, avg_review_min: filters.avgReviewMin };
    }

    return urlParams;
  },
  // This function gets the current active filter count; it expects a new total
  // count to be returned. Aside from the arguments shown here, this also receives the
  // `filterSetting` object for the filter.
  getCount: ({ currentCount, filters }) =>
    filters.avgReviewMin != null ? currentCount + 1 : currentCount,
  // The component used to render the filter tags, shown above the list of active filters.
  // If no filter tag should be shown (ie there is no value for this filter), the
  // component should render `null` or a similar value.
  FilterTagComponent: AvgReviewFilterTag,
  // A function that renders the filter within the list of active filters. This handles
  // both the collapsed and the expanded state of the filter.
  FilterComponent: AvgReviewComponent,
});

import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import { useProductFilters } from '../ProductFilterContext';
import { useProductFilters as useProductFiltersCustomFilters } from '../ProductFilterContextCustomFilters';
import ProductListingFilter, {
  ProductListingFilterStyle,
} from '../ProductListingFilter';
import { parseBraSize, parseBraSizes } from '../utils/sizes';

const BraSizeLabel = styled.span`
  display: flex;
  flex-direction: column;
`;

const BandSize = styled.span`
  font-size: 10px;
  opacity: 0.75;
  margin: -6px 0 2px 0;
`;

/**
 * A special version of `ProductListingFilter` with custom logic for separating
 * the `size_bra` aggregation filter into Band Size and Cup Size selections.
 */
const ProductSizeFilter = ({
  as: ElementType = ProductListingFilter,
  filterField: inputFilterField,
  selectedFilters: inputSelectedFilters,
  bandSizeCheckboxProps,
  cupSizeCheckboxProps,
  withNestedBraFilter = true,
  onChange,
  onFilterSizeClicked,
  ...rest
}) => {
  // These two contexts are identical for the purposes of what this
  // component does with them, but we need to check both to maintain
  // backwards compatibility.
  let filterContext = useProductFilters();
  const customFilterContext = useProductFiltersCustomFilters();

  filterContext = filterContext || customFilterContext;

  if (typeof inputSelectedFilters === 'function') {
    if (filterContext) {
      inputSelectedFilters = inputSelectedFilters(
        filterContext.selectedFilters
      );
    } else {
      inputSelectedFilters = inputSelectedFilters();
    }
  } else if (!inputSelectedFilters) {
    inputSelectedFilters = filterContext.selectedFilters;
  }

  const inputBraFilterField = useMemo(() => {
    if (inputFilterField.field === 'size_bra') {
      return inputFilterField;
    } else if (inputFilterField.nestedFilters) {
      return inputFilterField.nestedFilters.find(
        (subFilter) => subFilter.field === 'size_bra'
      );
    }
  }, [inputFilterField]);

  const selectedCupSizesByBand = useMemo(
    () =>
      inputBraFilterField
        ? parseBraSizes(inputSelectedFilters[inputBraFilterField.field])
        : {},
    [inputBraFilterField, inputSelectedFilters]
  );

  const cupSizesByBand = useMemo(
    () =>
      inputBraFilterField
        ? parseBraSizes(inputBraFilterField.items.map((item) => item.value))
        : {},
    [inputBraFilterField]
  );

  const selectableBandSizes = useMemo(
    () => Object.keys(cupSizesByBand).map((bandSize) => ({ value: bandSize })),
    [cupSizesByBand]
  );

  const [lastSelectedBand, setSelectedBand] = useState(null);
  const activeBand = useMemo(() => {
    if (
      // If no explicit selection, stay in sync with selected and selectable
      // sizes.
      lastSelectedBand == null ||
      // If the last selected option is no longer selectable, prevent from
      // using stale value.
      !selectableBandSizes.some(
        (bandSize) => bandSize.value === lastSelectedBand
      )
    ) {
      let firstBand = Object.keys(selectedCupSizesByBand)[0];
      if (!firstBand && selectableBandSizes.length) {
        firstBand = selectableBandSizes[0].value;
      }
      return firstBand || null;
    }
    return lastSelectedBand;
  }, [lastSelectedBand, selectableBandSizes, selectedCupSizesByBand]);

  const selectableCupSizes = useMemo(
    () =>
      activeBand && cupSizesByBand[activeBand]
        ? cupSizesByBand[activeBand].map((cupSize) => ({
            label: (
              <BraSizeLabel>
                <BandSize>{activeBand}</BandSize>
                <span>{cupSize}</span>
              </BraSizeLabel>
            ),
            value: cupSize,
          }))
        : [],
    [activeBand, cupSizesByBand]
  );

  const filterField = useMemo(() => {
    if (inputBraFilterField && withNestedBraFilter) {
      const isTopLevel = inputFilterField === inputBraFilterField;
      const emptyMessage =
        inputBraFilterField.totalAggregationCount !== 0 ? (
          <FormattedMessage
            id="site_product_filter.select_band_size"
            defaultMessage="Select a band size."
          />
        ) : null;
      const braFields = [
        {
          label: isTopLevel ? 'Band Size' : 'Bra Band Size',
          field: 'band_size',
          filterCount: 0,
          filterStyle: ProductListingFilterStyle.GRID,
          items: selectableBandSizes,
          totalAggregationCount: inputBraFilterField.totalAggregationCount,
          bandSizeCheckboxProps,
        },
        {
          label: isTopLevel ? 'Cup Size' : 'Bra Cup Size',
          field: 'cup_size',
          filterCount: inputSelectedFilters[inputBraFilterField.field].length,
          filterStyle: ProductListingFilterStyle.GRID,
          items: selectableCupSizes,
          totalAggregationCount: inputBraFilterField.totalAggregationCount,
          emptyMessage: emptyMessage,
          cupSizeCheckboxProps,
        },
      ];
      if (isTopLevel) {
        return {
          ...inputFilterField,
          nestedFilters: braFields,
          items: null,
        };
      } else {
        const nestedFilters = inputFilterField.nestedFilters.slice();
        // Replace `size_bra` with these filters.
        const braIndex = nestedFilters.indexOf(inputBraFilterField);
        nestedFilters.splice(braIndex, 1, ...braFields);
        return { ...inputFilterField, nestedFilters };
      }
    } else {
      return inputFilterField;
    }
  }, [
    bandSizeCheckboxProps,
    cupSizeCheckboxProps,
    inputBraFilterField,
    inputFilterField,
    inputSelectedFilters,
    selectableBandSizes,
    selectableCupSizes,
    withNestedBraFilter,
  ]);

  const selectedFilters = useMemo(
    () =>
      inputBraFilterField
        ? {
            ...inputSelectedFilters,
            band_size: activeBand,
            cup_size: activeBand
              ? selectedCupSizesByBand[activeBand] || []
              : [],
          }
        : inputSelectedFilters,
    [
      activeBand,
      inputBraFilterField,
      inputSelectedFilters,
      selectedCupSizesByBand,
    ]
  );

  const selectedFieldFilters = selectedFilters[filterField.field] ?? [];
  const [lastSelectedItem] = selectedFieldFilters.slice(-1);
  const prevLastSelectedItem = useRef(lastSelectedItem);
  const isLastSelectedItemChanging =
    lastSelectedItem !== prevLastSelectedItem.current;

  useEffect(() => {
    if (isLastSelectedItemChanging) {
      if (inputFilterField.field === 'size_bra') {
        if (lastSelectedItem) {
          const [bandSize] = parseBraSize(lastSelectedItem);

          if (bandSize !== lastSelectedBand) {
            setSelectedBand(bandSize);
          }
        } else {
          setSelectedBand(null);
        }
      }
      prevLastSelectedItem.current = lastSelectedItem;
    }
  }, [
    isLastSelectedItemChanging,
    inputFilterField.field,
    lastSelectedBand,
    lastSelectedItem,
  ]);

  const handleFilterChange = useCallback(
    ({
      filterField,
      newSelectedFilters,
      newSelectedFilterValue,
      newAllSelectedFilters,
      event,
      ...rest
    }) => {
      switch (filterField.field) {
        case 'band_size':
          setSelectedBand(newSelectedFilters);
          break;
        case 'cup_size': {
          const newBraSizes = {
            ...selectedCupSizesByBand,
            [activeBand]: newSelectedFilters,
          };
          // Convert back into a flat size list like the parent expects.
          const newSizeArray = [];
          for (const bandSize in newBraSizes) {
            for (const cupSize of newBraSizes[bandSize]) {
              newSizeArray.push(`${bandSize}${cupSize}`);
            }
          }
          const updatedNewSizesFilters = {
            ...newAllSelectedFilters,
            size_bra:
              event.target.type === 'radio'
                ? `${activeBand}${newSelectedFilterValue}`
                : newSizeArray,
          };
          onFilterSizeClicked &&
            onFilterSizeClicked({
              newSelectedFilterValue,
              newAllSelectedFilters: updatedNewSizesFilters,
            });
          onChange({
            filterField: inputBraFilterField,
            newSelectedFilters: newSizeArray,
            ...rest,
          });
          break;
        }
        default:
          onFilterSizeClicked &&
            onFilterSizeClicked({
              newSelectedFilterValue,
              newAllSelectedFilters,
            });

          onChange({
            filterField,
            newSelectedFilters:
              event.target.type === 'radio'
                ? [newSelectedFilterValue]
                : newSelectedFilters,
            newSelectedFilterValue,
            ...rest,
          });
      }
    },
    [
      activeBand,
      inputBraFilterField,
      onChange,
      onFilterSizeClicked,
      selectedCupSizesByBand,
    ]
  );

  return (
    <ElementType
      filterField={filterField}
      selectedFilters={selectedFilters}
      onChange={handleFilterChange}
      {...rest}
    />
  );
};

ProductSizeFilter.propTypes = {
  as: PropTypes.elementType,
  bandSizeCheckboxProps: PropTypes.object,
  cupSizeCheckboxProps: PropTypes.object,
  filterField: PropTypes.object,
  onChange: PropTypes.func,
  onFilterSizeClicked: PropTypes.func,
  selectedFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  withNestedBraFilter: PropTypes.bool,
};

ProductSizeFilter.BandSize = BandSize;

export default ProductSizeFilter;

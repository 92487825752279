import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { FaCompress } from 'react-icons/fa';
import {
  MOUSE_ACTIVATION as MouseActivation,
  TOUCH_ACTIVATION as TouchActivation,
} from 'react-input-position';
import { CSSTransition, Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import { useImageBrowser } from '../ImageBrowserContext';
import ReactInputPosition from '../ReactInputPosition';
import useHasTouch from '../useHasTouch';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(238, 238, 238, 0.32);
  z-index: 1;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.exit-active {
    opacity: 0;
    transition: opacity ${(props) => props.fadeDuration}ms;
  }

  &.exit-done {
    opacity: 0;
  }

  ${(props) => props.overlayStyle};
`;

const ZoomOutArea = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  z-index: 1;

  * {
    pointer-events: auto;
  }
`;

const OverlayMessage = styled.p.attrs((props) => ({
  children: props.children || (
    <FormattedMessage
      id="site_product_page.product_image_zoom_in"
      defaultMessage="Double-tap to Zoom"
    />
  ),
}))`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  opacity: 0.32;
`;

export const MoveMethods = {
  HOVER: 'hover',
  DRAG: 'drag',
};

const zoomOutButtonLabelStyle = css`
  display: flex;
  align-items: center;
`;

const ZoomOutIcon = styled(FaCompress)`
  margin-right: 6px;
`;

export { MouseActivation, TouchActivation };

function ZoomOutButton({
  children = (
    <>
      <ZoomOutIcon />{' '}
      <FormattedMessage
        id="site_product_page.product_image_zoom_out"
        defaultMessage="Zoom Out"
      />
    </>
  ),
  labelStyle = zoomOutButtonLabelStyle,
  ...rest
}) {
  return (
    <Button labelStyle={labelStyle} data-autotag="img_zoom_out" {...rest}>
      {children}
    </Button>
  );
}

function PictureRenderer({
  active,
  activePosition,
  elementDimensions,
  elementOffset,
  itemDimensions,
  itemPosition,
  passivePosition,
  prevActivePosition,
  itemRef,
  onLoadRefresh,
  hasAnimation,
  zoomDuration,
  zoomScale = 2,
  pictureElement,
}) {
  const zoomedImageWidth = elementDimensions.width * zoomScale;

  const transformOrigin = `${Math.floor(
    -itemPosition.x / (zoomScale - 1)
  )}px ${Math.floor(-itemPosition.y / (zoomScale - 1))}px`;

  const handleEnter = useCallback(() => {
    // `react-input-position` relies on having the image width known
    // beforehand. Because of this we need to set the size initially at the
    // scaled size. To do any animations the scale must be reset, transition
    // added, then scaled again.
    itemRef.current.style.transform = 'scale(1)';
    // eslint-disable-next-line no-unused-expressions
    itemRef.current.scrollTop; // Access scrollTop to trigger paint/layout.
    itemRef.current.style.transition = `transform ${zoomDuration}ms`;
    itemRef.current.style.visibility = 'visible';
    itemRef.current.style.transform = `scale(${zoomScale})`;
  }, [itemRef, zoomDuration, zoomScale]);

  const handleExit = useCallback(() => {
    itemRef.current.style.transform = 'scale(1)';
  }, [itemRef]);

  const handleExited = useCallback(() => {
    itemRef.current.style.visibility = 'hidden';
    itemRef.current.style.transition = null;
    itemRef.current.style.transform = `scale(${zoomScale})`;
  }, [itemRef, zoomScale]);

  const transitionProps = hasAnimation
    ? {
        timeout: zoomDuration,
        onEnter: handleEnter,
        onExit: handleExit,
        onExited: handleExited,
      }
    : {
        timeout: 0,
      };

  return (
    <>
      {pictureElement}
      <Transition in={active} {...transitionProps}>
        {React.cloneElement(pictureElement, {
          // On initialization this will load the same image as the above
          // <Picture />, `active` (zoomed) it can load another image if
          // necessary.
          breakpoints: active
            ? [
                {
                  minWidth: 0,
                  pictureWidth: zoomedImageWidth,
                },
              ]
            : pictureElement.props.breakpoints,
          ref: itemRef,
          style: {
            ...pictureElement.props.style,
            position: 'absolute',
            top: 0,
            left: 0,
          },
          imgStyle: {
            ...pictureElement.props.imgStyle,
            width: '100%',
            visibility: hasAnimation || !active ? 'hidden' : 'visible',
            transform: `scale(${zoomScale})`,
            transformOrigin,
          },
        })}
      </Transition>
    </>
  );
}

function PictureZoom({
  children,
  mouseActivation = MouseActivation.HOVER,
  mouseMoveMethod = MoveMethods.HOVER,
  touchActivation = TouchActivation.DOUBLE_TAP,
  zoomScale = 2,
  onZoomIn,
  onZoomOut,
  hasAnimation: hasAnimationFromProps,
  zoomDuration = 300,
  overlayStyle,
  overlayContent,
  overlayFadeDuration = 300,
  overlayTimeout = 3000,
  overlayEnabled,
  onOverlayHide,
  pictureKey,
  zoomOutTriggerEnabled,
  zoomOutTrigger,
  ...rest
}) {
  const imageBrowser = useImageBrowser();
  let imageBrowserSetIsZoomed;
  const overlayTimer = useRef();
  const inputPositionRef = useRef();
  const hasTouch = useHasTouch();
  const [isZoomed, setIsZoomed] = useState();
  const [isOverlayActive, setIsOverlayActive] = useState(overlayEnabled);
  const hasAnimation =
    hasAnimationFromProps ??
    (hasTouch || mouseActivation !== MouseActivation.HOVER);

  const isMouseMoveMethodDrag = mouseMoveMethod === MoveMethods.DRAG;

  if (imageBrowser) {
    imageBrowserSetIsZoomed = imageBrowser.setIsZoomed;
  }

  const handleActivate = useCallback(() => {
    setIsZoomed(true);
    if (imageBrowserSetIsZoomed) {
      imageBrowserSetIsZoomed(true);
    }
    if (isOverlayActive && overlayEnabled && overlayTimer.current) {
      setIsOverlayActive(false);
      clearTimeout(overlayTimer.current);
    }
    if (onZoomIn) {
      onZoomIn();
    }
  }, [imageBrowserSetIsZoomed, isOverlayActive, overlayEnabled, onZoomIn]);

  const handleDeactivate = useCallback(() => {
    setIsZoomed(false);
    if (imageBrowserSetIsZoomed) {
      imageBrowserSetIsZoomed(false);
    }
    if (onZoomOut) {
      onZoomOut();
    }
  }, [setIsZoomed, onZoomOut, imageBrowserSetIsZoomed]);

  useEffect(() => {
    setIsOverlayActive(overlayEnabled);
    if (overlayEnabled) {
      overlayTimer.current = setTimeout(() => {
        setIsOverlayActive(false);
      }, overlayTimeout);
    }

    return () => {
      if (overlayTimer.current) {
        clearTimeout(overlayTimer.current);
      }
    };
  }, [overlayEnabled, overlayTimeout]);

  useEffect(() => {
    inputPositionRef.current.deactivate();
  }, [pictureKey]);

  return (
    <Wrapper data-autotag="img_zoom" {...rest}>
      <ReactInputPosition
        ref={inputPositionRef}
        cursorStyle="zoom-in"
        cursorStyleActive="move"
        mouseActivationMethod={mouseActivation}
        touchActivationMethod={touchActivation}
        trackItemPosition
        itemPositionLimitBySize
        centerItemOnActivatePos={hasTouch ? true : isMouseMoveMethodDrag}
        alignItemOnActivePos={hasTouch ? false : !isMouseMoveMethodDrag}
        onActivate={handleActivate}
        onDeactivate={handleDeactivate}
      >
        <PictureRenderer
          hasAnimation={hasAnimation}
          zoomDuration={zoomDuration}
          zoomScale={zoomScale}
          pictureElement={children}
        />
      </ReactInputPosition>
      {overlayEnabled && overlayContent && (
        <CSSTransition
          in={isOverlayActive}
          timeout={{ exit: overlayFadeDuration }}
          onExited={onOverlayHide}
        >
          <Overlay
            overlayStyle={overlayStyle}
            fadeDuration={overlayFadeDuration}
          >
            {overlayContent}
          </Overlay>
        </CSSTransition>
      )}
      {zoomOutTrigger && isZoomed && (
        <ZoomOutArea>
          {React.cloneElement(zoomOutTrigger, {
            onClick: () => inputPositionRef.current.deactivate(),
          })}
        </ZoomOutArea>
      )}
    </Wrapper>
  );
}

const PositionProp = PropTypes.shape({
  x: PropTypes.number,
  y: PropTypes.number,
});

const DimensionProp = PropTypes.shape({
  width: PropTypes.number,
  height: PropTypes.number,
});

const OffsetProp = PropTypes.shape({
  left: PropTypes.number,
  top: PropTypes.number,
});

ZoomOutButton.propTypes = {
  children: PropTypes.node,
  labelStyle: PropTypes.any,
};

PictureRenderer.propTypes = {
  active: PropTypes.bool,
  activePosition: PositionProp,
  elementDimensions: DimensionProp,
  elementOffset: OffsetProp,
  hasAnimation: PropTypes.bool,
  itemDimensions: DimensionProp,
  itemPosition: PositionProp,
  itemRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onLoadRefresh: PropTypes.func,
  passivePosition: PositionProp,
  pictureElement: PropTypes.node,
  prevActivePosition: PositionProp,
  zoomDuration: PropTypes.number,
  zoomScale: PropTypes.number,
};

PictureZoom.propTypes = {
  children: PropTypes.node,
  hasAnimation: PropTypes.bool,
  mouseActivation: PropTypes.oneOf(Object.values(MouseActivation)),
  mouseMoveMethod: PropTypes.oneOf(Object.values(MoveMethods)),
  onOverlayHide: PropTypes.func,
  onZoomIn: PropTypes.func,
  onZoomOut: PropTypes.func,
  overlayContent: PropTypes.node,
  overlayEnabled: PropTypes.bool,
  overlayFadeDuration: PropTypes.number,
  overlayStyle: PropTypes.any,
  overlayTimeout: PropTypes.number,
  pictureKey: PropTypes.any,
  touchActivation: PropTypes.oneOf(Object.values(TouchActivation)),
  zoomDuration: PropTypes.number,
  zoomOutTrigger: PropTypes.node,
  zoomOutTriggerEnabled: PropTypes.bool,
  zoomScale: PropTypes.number,
};

PictureZoom.ZoomOutButton = ZoomOutButton;
PictureZoom.OverlayMessage = OverlayMessage;

export default PictureZoom;

export const cdnRegionOverrideCookie = 'tfg.next-server.cdnRegion';

export function markMalformedString(input, regex) {
  let markers = ' '.repeat(input.length);
  let isMalformed = false;
  input.replace(regex, (match, chars, pos) => {
    const count = chars.length;
    isMalformed = true;
    markers =
      markers.slice(0, pos) + '^'.repeat(count) + markers.slice(pos + count);
    return chars;
  });
  return [isMalformed, markers];
}

// Find tag-like and entity-like syntax.
const htmlRegex = /(<[a-z][^>]*>|&#?[a-z0-9]+;)/i;

/**
 * Return true if the given string looks like it contains HTML tags or entities,
 * false otherwise. The string does not have to be valid HTML, it simply must
 * resemble HTML content.
 */
export function detectHTML(string) {
  return htmlRegex.test(string);
}

export function buildMessageIndex(messages) {
  const keyIndex = {};
  const valueIndex = new Map();
  // We could generate this from `valueIndex.keys()`, but might as well just
  // build it as we go; probably cheaper.
  const values = [];

  for (const key in messages) {
    const value = messages[key];
    let index = valueIndex.get(value);
    if (index == null) {
      index = values.push(value) - 1;
      valueIndex.set(value, index);
    }
    keyIndex[key] = index;
  }

  return { keyIndex, values };
}

export function unindexMessages({ keyIndex, values }) {
  return Object.keys(keyIndex).reduce((messages, key) => {
    const index = keyIndex[key];
    messages[key] = values[index];
    return messages;
  }, {});
}

// If we end up needing this in multiple places, look into `lodash.defaults` as
// as substitute. For now, this is smaller.
export function omitUndefined(obj) {
  return Object.keys(obj).reduce((output, key) => {
    const value = obj[key];
    if (typeof value !== 'undefined') {
      output[key] = value;
    }
    return output;
  }, {});
}

/**
 * Extracts the geo country and region from cloudflare header
 * @param {*} req Request object
 * @returns {Array<string>}  An array containing the country and region extracted from the request
 */
export function extractGeoRegionFromHeaders(req) {
  const splitGeoRegion = req?.headers?.['x-tfg-geo-region']?.split('-');

  if (splitGeoRegion?.length !== 2) {
    return [];
  }

  return [splitGeoRegion[0], splitGeoRegion[1]];
}

/**
 * Extracts the geo country and region off query param
 * @param {*} allowOverride Allows override from the query param
 * @param {*} req Request object
 * @returns {Array<string>}  An array containing the country and region extracted from the request
 */
export function extractGeoRegionFromQuery(req) {
  const cdnQueryParam = req?.query?.geoOverride;

  const splitGeoRegion = cdnQueryParam?.split('-');

  if (splitGeoRegion?.length !== 2) {
    return [];
  }

  return [splitGeoRegion[0], splitGeoRegion[1]];
}

/**
 * Extracts the geo country and region off request cookie
 * The query param takes precedence over the header
 * @param {*} req Request object
 * @returns {Array<string>}  An array containing the country and region extracted from the request
 */
export function extractGeoRegionFromCookies(req) {
  const cdnCookie = req?.cookies?.[cdnRegionOverrideCookie];

  const splitGeoRegion = cdnCookie?.split('-');

  if (splitGeoRegion?.length !== 2) {
    return [];
  }

  return [splitGeoRegion[0], splitGeoRegion[1]];
}

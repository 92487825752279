import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import useLoadingStatus, { LoadingStatusContext } from '../useLoadingStatus';
import useMounted from '../useMounted';

export function Loading({ name }) {
  const { startLoading } = useLoadingStatus(name);
  useEffect(() => {
    const stopLoading = startLoading();
    return stopLoading;
  }, [startLoading]);
  return null;
}

Loading.displayName = 'LoadingStatus.Loading';

Loading.propTypes = {
  name: PropTypes.string,
};

export function LoadingIndicator({ children, name }) {
  const { isLoading } = useLoadingStatus(name);
  return isLoading ? children : null;
}

LoadingIndicator.displayName = 'LoadingStatus.LoadingIndicator';

LoadingIndicator.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  children: 'Loading…',
};

/**
 * A helper for creating scoped loading statuses, where multiple things can
 * contribute to the loading status.
 *
 * Wrap a section of the component tree in a `<LoadingStatus>` for each
 * significant section that needs to indicate its loading status. Components can
 * render `<LoadingStatus.Loading />` or call `startLoading` from the
 * `useLoadingStatus` hook to indicate that the ancestor `<LoadingStatus>` is
 * active. When active, any `<LoadingStatus.LoadingIndicator />` descendants of
 * the `<LoadingStatus>` will render their children (which should be some kind
 * of loading indicator, such as `<SpinnerLoadingIndicator>`,
 * `<ProgressLoadingIndicator>`, or a helpful message.
 */
export default function LoadingStatus({ children, initialStatus, name }) {
  const [MyContext] = useState(() => React.createContext());
  const parent = useContext(LoadingStatusContext);
  const self = useMemo(
    () => ({ name, Context: MyContext, parent }),
    [MyContext, name, parent]
  );

  const isMounted = useMounted();
  const [count, setCount] = useState(0);
  const isLoading = isMounted ? count > 0 : Boolean(initialStatus);

  const startLoading = useCallback(() => {
    setCount((prevCount) => prevCount + 1);
    let stopped = false;
    return () => {
      if (!stopped) {
        stopped = true;
        setCount((prevCount) => prevCount - 1);
      }
    };
  }, []);

  const loadingStatus = useMemo(
    () => ({
      isLoading,
      startLoading,
    }),
    [isLoading, startLoading]
  );

  return (
    <LoadingStatusContext.Provider value={self}>
      <MyContext.Provider value={loadingStatus}>{children}</MyContext.Provider>
    </LoadingStatusContext.Provider>
  );
}

LoadingStatus.propTypes = {
  children: PropTypes.node,
  initialStatus: PropTypes.bool,
  name: PropTypes.string,
};

LoadingStatus.defaultProps = {
  initialStatus: false,
};

LoadingStatus.Context = LoadingStatusContext;
LoadingStatus.Loading = Loading;
LoadingStatus.LoadingIndicator = LoadingIndicator;

import React from 'react';

import styled from 'styled-components';

import Button from '../Button';
import Carousel from '../Carousel';
import { SubcoSubscriptionDetail } from '../Checkout/checkoutTypes';
import Picture from '../Picture';
import { createContext } from '../utils/createContext';
import useCdnImagePath from '../utils/useCdnImagePath';

type SubcoContextValueType = {
  subcoDetail: SubcoSubscriptionDetail;
  imageSrc: {};
};

type RootProps = React.PropsWithChildren<{
  subcoDetail: SubcoSubscriptionDetail;
  subcoFolder?: string;
  localize?: string;
  suffix?: string;
}>;

export const [useSubcoContext, SubcoContext] =
  createContext<SubcoContextValueType>('SubcoContext');

const Subco = ({
  children,
  subcoDetail,
  subcoFolder,
  localize = '',
  suffix = '_lg',
}: RootProps) => {
  const { getCdnImagePath } = useCdnImagePath();
  const imageSource = subcoDetail?.imageName;
  const src = getCdnImagePath({
    path: imageSource,
    localize,
    folder: subcoFolder,
    suffix,
  });
  const imageList = [{ images: [{ url: src }] }];
  const context = {
    subcoDetail: subcoDetail,
    imageSrc: imageList,
  };

  return (
    <SubcoContext.Provider value={context}>{children}</SubcoContext.Provider>
  );
};

// Button
type SubcoButtonProps = React.ComponentPropsWithRef<typeof Button>;

const SubcoButton = (props: SubcoButtonProps) => {
  return <Button {...props} />;
};

// Image
// TODO update <any> generic when Picture.js is converted to TS
type SubcoMagazineImageProps = React.PropsWithRef<any>;

const SubcoMagazineImage = (props: SubcoMagazineImageProps) => {
  const { imageSrc } = useSubcoContext();
  return <Picture sources={imageSrc} {...props} />;
};

// Title
const SubcoTitle = styled.div``;
type SubcoMagazineTitleProps = React.ComponentPropsWithRef<typeof SubcoTitle>;

const SubcoMagazineTitle = (props: SubcoMagazineTitleProps) => {
  const { subcoDetail } = useSubcoContext();
  return <SubcoTitle {...props}>{subcoDetail?.label}</SubcoTitle>;
};

Subco.Context = Subco;
Subco.Title = SubcoMagazineTitle;
Subco.Image = SubcoMagazineImage;
Subco.Carousel = Carousel;
Subco.Button = SubcoButton;

export default Subco;

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  getDefaultAddress,
  useAddresses,
} from '../../../../techstyle-shared/react-accounts';
import type {
  AccountsModuleInitialState,
  AddressDto,
} from '../../../../techstyle-shared/react-accounts';
import { createContext } from '../utils/createContext';

export type ShippingAddressContextValue = {
  addresses: AddressDto[];
  defaultAddress: AddressDto;
};

const [useShippingAddresses, Context] =
  createContext<ShippingAddressContextValue>('ShippingAddresses');

const Address = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div``;

const Root = ({
  children,
  removeDefaultAddress,
}: React.PropsWithChildren<{ removeDefaultAddress: boolean }>) => {
  const { addresses } = useAddresses();
  const defaultAddress = useSelector<AccountsModuleInitialState, AddressDto>(
    getDefaultAddress
  );

  const value = useMemo(
    () => ({
      addresses: removeDefaultAddress
        ? addresses.filter(({ isDefault }) => !isDefault)
        : addresses,
      defaultAddress,
    }),
    [addresses, defaultAddress, removeDefaultAddress]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Root.propTypes = {
  removeDefaultAddress: PropTypes.bool,
};

Root.defaultProps = {
  removeDefaultAddress: false,
};

export { Address, Item, Root, useShippingAddresses as useContext };

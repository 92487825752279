import { useMemo } from 'react';

import config from 'config';

import { useDomain } from '../../../techstyle-shared/redux-core';

import useGeoCountry from './useGeoCountry';

export default function useSuggestedDomain() {
  const geoCountry = useGeoCountry();
  const { tld: currentTld } = useDomain();
  const supportedCountries = config.get('public.intl.supportedCountries');
  const suggestedTld = useMemo(
    () =>
      geoCountry
        ? Object.keys(supportedCountries).find((tld) =>
            supportedCountries[tld].includes(geoCountry)
          ) || null
        : null,
    [geoCountry, supportedCountries]
  );

  return useMemo(
    () => ({
      geoCountry,
      suggestedTld,
      shouldRedirect: suggestedTld != null && currentTld !== suggestedTld,
      supportedCountries: supportedCountries[currentTld] || [],
    }),
    [currentTld, geoCountry, suggestedTld, supportedCountries]
  );
}

import React, { useState, useRef, useEffect, useMemo } from 'react';

import defaults from 'lodash/defaults';
import PropTypes from 'prop-types';

import { useIntl, useCurrency } from '../../../../techstyle-shared/react-intl';
import { Script } from '../../../../techstyle-shared/react-marketing';
import useId from '../useId';

import { AfterpayConstants } from './constants';

export default function Afterpay({ attributes: attributesFromProps, ...rest }) {
  const { locale } = useIntl();
  const { currency } = useCurrency();
  const afterpayClassName = useId('afterpay-');
  const containerRef = useRef();
  // local state management of Afterpay to determine if script has been
  // successfully loaded
  const [isAfterpayReady, setIsAfterpayReady] = useState(false);

  const attributes = useMemo(() => {
    // Use `defaults` instead of `...spread` so that undefined values from
    // `attributesFromProps` are replaced by the defaults below. Using
    // `...spread` syntax instead would clobber earlier defined values with
    // undefined values.
    return defaults(attributesFromProps, {
      locale: locale.replace(/-/g, '_'),
      currency: currency,
      introText: 'Or',
      logoType: AfterpayConstants.logoType.LOCKUP,
      lockupTheme: AfterpayConstants.theme.lockup.BLACK,
      modalTheme: AfterpayConstants.theme.modal.WHITE,
    });
  }, [attributesFromProps, currency, locale]);

  useEffect(() => {
    if (window.Afterpay) {
      setIsAfterpayReady(true);
    } else {
      const handleReady = () => {
        setIsAfterpayReady(true);
      };
      window.addEventListener('Afterpay.ready', handleReady);
      return () => {
        window.removeEventListener('Afterpay.ready', handleReady);
      };
    }
  }, []);

  useEffect(() => {
    if (isAfterpayReady) {
      const Afterpay = window.Afterpay;
      // inserts <afterpay-placement> element provided by Afterpay beneath
      // element w/ the afterpayClassName
      Afterpay.createPlacements({
        targetSelector: `.${afterpayClassName}`,
        attributes,
      });

      const container = containerRef.current;

      // Remove sibling element created and appended by
      // Afterpay.createPlacements
      return function removePlacements() {
        const element = container.querySelector('afterpay-placement');

        if (element) {
          element.remove();
        }
      };
    }
  }, [afterpayClassName, attributes, isAfterpayReady]);

  return (
    <>
      <Script
        async
        globalScriptKey="afterpay-widget"
        skipServerRender
        shouldPersistInDOM
        src="https://js.afterpay.com/afterpay-1.x.js"
      />
      <div ref={containerRef} {...rest}>
        <div className={afterpayClassName} />
      </div>
    </>
  );
}

Afterpay.propTypes = {
  attributes: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    badgeTheme: PropTypes.oneOf(Object.values(AfterpayConstants.theme.badge)),
    cartIsEligible: PropTypes.bool,
    currency: PropTypes.oneOf(Object.values(AfterpayConstants.currency)),
    isEligible: PropTypes.bool,
    introText: PropTypes.string,
    locale: PropTypes.oneOf(Object.values(AfterpayConstants.locale)),
    lockupTheme: PropTypes.oneOf(Object.values(AfterpayConstants.theme.lockup)),
    logoType: PropTypes.oneOf(Object.values(AfterpayConstants.logoType)),
    max: PropTypes.number,
    min: PropTypes.number,
    modalLinkStyle: PropTypes.oneOf(
      Object.values(AfterpayConstants.modalLinkStyle)
    ),
    modalTheme: PropTypes.oneOf(Object.values(AfterpayConstants.theme.modal)),
    paymentAmountIsBold: PropTypes.bool,
    showWith: PropTypes.bool,
    showLowerLimit: PropTypes.bool,
    showUpperLimit: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(AfterpayConstants.size)),
  }),
};

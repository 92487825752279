import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useSession } from '../../redux-core';

import { getLoyaltyPointsForActionsProgress } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useLoyaltyPointsForActionsProgress() {
  const actions = useAccountActions();
  const { isLoggedIn } = useSession();

  const loyaltyPointsForActionsProgress = useSelector(
    getLoyaltyPointsForActionsProgress
  );

  useEffect(() => {
    if (
      !loyaltyPointsForActionsProgress.networkStatus.isUpToDate &&
      isLoggedIn
    ) {
      actions.loadLoyaltyPointsForActionsProgress();
    }
  }, [
    actions,
    loyaltyPointsForActionsProgress.networkStatus.isUpToDate,
    isLoggedIn,
  ]);

  return loyaltyPointsForActionsProgress;
}

import { useEffect, useMemo, useRef } from 'react';

import { PublicClientApplication } from '@azure/msal-browser';
import config from 'config';
import { useSelector } from 'react-redux';

import logger from './logger';
import useAdminActions from './useAdminActions';

const debug = logger.extend('useAzure');

export default function useAzure() {
  const msalInstance = useRef();
  const { azureValidate } = useAdminActions();
  const isAdmin = useSelector((state) => state.session.isAdmin);

  useEffect(() => {
    if (!msalInstance.current) {
      const authority = config.get('public.azure.authority');
      const clientId = config.get('public.azure.clientId');
      const redirectUri = config.get('public.azure.redirectUri');
      const tenantId = config.get('public.azure.tenantId');

      // Instantiate and save the Microsoft Client App
      msalInstance.current = new PublicClientApplication({
        auth: {
          authority: `${authority}/${tenantId}`,
          clientId,
          redirectUri,
        },
        system: {
          loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                debug('Logging disabled because error contains PII');
              } else {
                debug(message);
              }
            },
          },
        },
      });
    }
  }, []);

  // Login and validate
  useEffect(() => {
    if (isAdmin) {
      return;
    }

    const login = async () => {
      // Does token request if we're returning from login redirect:
      let tokenResponse = await msalInstance.current.handleRedirectPromise();

      try {
        if (!tokenResponse) {
          const account = msalInstance.current.getAllAccounts()[0];
          // Attempt to use existing SSO session
          if (account) {
            tokenResponse = await msalInstance.current.acquireTokenSilent({
              account,
            });
          } else {
            tokenResponse = await msalInstance.current.loginRedirect({});
          }
        }
      } catch (error) {
        debug('Azure authentication error. \n%s', error);
        throw error;
      }

      // Getting inconsistent name in response so check both
      const idToken = tokenResponse.idToken || tokenResponse.id_token;

      if (idToken) {
        await azureValidate({
          headers: {
            authorization: `Bearer ${idToken}`,
          },
        });
      }
    };

    login();
  }, [azureValidate, isAdmin]);

  return useMemo(
    () => ({
      isAdmin,
    }),
    [isAdmin]
  );
}

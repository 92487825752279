import { useCallback, useEffect, useMemo, useState } from 'react';

import chunk from 'lodash/chunk';

import useAccountActions from './useAccountActions';

const DEFAULT_ITEMS_PER_PAGE = 5;
const DEFAULT_INITIAL_FETCH_PAGE = 1;
const DEFAULT_ITEMS_PER_REQUEST = Number.MAX_SAFE_INTEGER;
// When the feature flag is no longer needed for this feature, use this hook
// Test and make sure it works with the new hook before deleting this comment
export default function useBouncebackEndowmentHistory(
  initialFetchPage = DEFAULT_INITIAL_FETCH_PAGE,
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
  itemsPerRequest = DEFAULT_ITEMS_PER_REQUEST
) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_FETCH_PAGE);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const { loadBouncebackEndowmentHistory } = useAccountActions();

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await loadBouncebackEndowmentHistory({
        page: initialFetchPage,
        size: itemsPerRequest,
      });

      if (response && response.payload?.historyData) {
        setData(response.payload.historyData || []);
        setTotalPageCount(Math.ceil(response.payload.pageCount || 0));
        setCurrentPage(response.payload.pageNumber || initialFetchPage);
      }
    } catch (error) {
      setData([]);
      setTotalPageCount(0);
    } finally {
      setLoading(false);
    }
  }, [itemsPerRequest, initialFetchPage, loadBouncebackEndowmentHistory]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const cachedData = useMemo(
    () => chunk(data, itemsPerPage),
    [data, itemsPerPage]
  );

  const currentData = useMemo(
    () => cachedData[currentPage - 1] || [],
    [cachedData, currentPage]
  );

  const fetchPage = (page) => {
    if (page <= totalPageCount && page > 0 && page !== currentPage) {
      setCurrentPage(page);
    }
  };

  return {
    data: currentData,
    loading,
    fetchPage,
    currentPage,
    totalPageCount,
  };
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

export class TextLink extends Component {
  static propTypes = {
    /**
     * Anything wrapped by TextLink
     */
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    /**
     * Render styles with a specific HTML element tag
     */
    tagName: PropTypes.oneOf(['a', 'button']).isRequired,
  };

  static defaultProps = {
    href: '#',
    tagName: 'a',
  };

  render() {
    const { children, href, tagName, ...props } = this.props;

    const Tag = tagName;
    const moreProps = tagName === 'a' ? { href } : {};

    return (
      <Tag {...props} {...moreProps}>
        {children}
      </Tag>
    );
  }
}

/** @component */
export default styled(TextLink)`
  ${(props) => (props.theme.link ? props.theme.link.defaultStyle : null)};
`;

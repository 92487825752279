import { createReducer, createSelector } from '@reduxjs/toolkit';

import {
  getSessionDetail,
  loadSession,
  loadSessionDetails,
  updateSessionDetail,
} from './bentoApiModule';
import createAction from './createAction';
import logger from './logger';
import { parseDate } from './utils';

// Whether server-side rendering is enabled. This does NOT mean we're currently
// on the server, it just indicates whether the app using this code uses SSR or
// not (for example, Next.js does, but Storybook does not). If not explicitly
// `false` or `0`, the assumption is that it's enabled.
export const ssr = !/^(0|false)$/.test(process.env.SSR);

const debug = logger.extend('timeModule');

export const TIME_TRAVEL_SESSION_DETAIL = 'admin_time_travel';

export const initialState = {
  pageRenderDate: null,
  serverRenderDate: null,
  timeTravelDate: null,
  timeTravelOffset: 0,
};

export function initTime(options = {}) {
  const { runUpdateSessionDetail = true } = options;
  return async (dispatch, getState, context) => {
    if (context.isServer && !context.hasPreloadedState) {
      await dispatch(loadSession());
      await dispatch(loadSessionDetails());

      const { renderTime, freezeTime = 'false' } = context.req.query;

      if (renderTime) {
        const date = parseDate(renderTime);
        // Ensure we got a valid date.
        if (date && date.getTime()) {
          const isFrozen = /^(true|1)$/.test(freezeTime);
          let value;
          if (isFrozen) {
            value = { timeTravelDate: date.toISOString() };
          } else {
            const offset = date.getTime() - Date.now();
            value = { timeTravelOffset: offset };
          }
          dispatch(configureTimeTravel(value));
          if (runUpdateSessionDetail) {
            dispatch(
              updateSessionDetail({
                name: TIME_TRAVEL_SESSION_DETAIL,
                value: JSON.stringify(value),
              })
            );
          }
        } else {
          debug(
            'Request contained a renderTime override, but it could not be parsed: “%s”',
            renderTime
          );
        }
      } else {
        // load time travel from session detail record
        const timeTravelSessionDetail = getSessionDetail(
          getState(),
          TIME_TRAVEL_SESSION_DETAIL
        );

        if (timeTravelSessionDetail?.value) {
          await dispatch(
            configureTimeTravel(JSON.parse(timeTravelSessionDetail.value))
          );
        }
      }
    }
  };
}

export const setPageRenderDate = createAction(
  'time/setPageRenderDate',
  (action) => {
    return (dispatch, getState, context) => {
      if (!action.payload) {
        const newDate = getNewDateFunction(getState());
        action.payload = newDate().toISOString();
      }
      action.meta = { isServer: context.isServer };
      return dispatch(action);
    };
  }
);

export const configureTimeTravel = createAction(
  'time/configureTimeTravel',
  (action) => {
    if (!action.payload) {
      // Ensure there's at least a payload object. This allows dispatching an
      // action with no payload to reset time travel.
      action.payload = {};
    }
  }
);

export const timeReducer = createReducer(initialState, {
  [setPageRenderDate]: (state, action) => {
    state.pageRenderDate = action.payload;
    if (action.meta.isServer) {
      state.serverRenderDate = action.payload;
    }
  },
  [configureTimeTravel]: (state, action) => {
    state.timeTravelDate = action.payload.timeTravelDate || null;
    state.timeTravelOffset = action.payload.timeTravelOffset || 0;
  },
});

export const getPageRenderDate = createSelector(
  [(state) => state.time.pageRenderDate],
  (pageRenderDate) => {
    if (!pageRenderDate) {
      throw new Error(
        'Redux store has no time.pageRenderDate. Did you dispatch the setPageRenderDate action?'
      );
    }
    return parseDate(pageRenderDate);
  }
);

export const getServerRenderDate = createSelector(
  // FIXME: It's ideal to lie here and say that `serverRenderDate` is the
  // `pageRenderDate` when SSR is disabled; it would be better to explicitly
  // return null so that consumers know there was no server render date.
  // However, this is a smaller change to get consumers of this selector
  // compatible with no-SSR support for now.
  [(state) => (ssr ? state.time.serverRenderDate : state.time.pageRenderDate)],
  (serverRenderDate) => {
    if (!serverRenderDate) {
      throw new Error(
        'Redux store has no time.serverRenderDate. Did you dispatch the setPageRenderDate action on the server?'
      );
    }
    return parseDate(serverRenderDate);
  }
);

/**
 * Return true if time travel is active (either via a frozen time or a time
 * offset), false otherwise.
 */
export const isTimeTravelActive = (state) =>
  state.time.timeTravelDate != null || state.time.timeTravelOffset !== 0;

/**
 * Return a function that will create a new Date object for the "current" time
 * when called, taking any configured time travel into account. Use this instead
 * of `new Date()`.
 */
export const getNewDateFunction = createSelector(
  [
    (state) => state.time.timeTravelDate,
    (state) => state.time.timeTravelOffset,
  ],
  (timeTravelDate, timeTravelOffset) => {
    return () => {
      if (timeTravelDate) {
        return parseDate(timeTravelDate);
      } else if (timeTravelOffset) {
        return new Date(Date.now() + timeTravelOffset);
      }
      return new Date();
    };
  }
);

/**
 * Return a function to get the "current" time in numeric form (the number of
 * milliseconds since Unix epoch), taking any configured time travel into
 * account. Use this instead of `Date.now()`.
 */
export const getDateNowFunction = createSelector(
  [getNewDateFunction],
  (newDate) => () => newDate().getTime()
);

export default {
  id: 'time',
  reducerMap: {
    time: timeReducer,
  },
  initialActions: [initTime()],
};

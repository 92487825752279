import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { BsArrowRight, BsArrowLeft } from 'react-icons/bs';
import styled, { css } from 'styled-components';

import { mobile } from '../styles';
import useMedia from '../useMedia';

const DOTS = '...';

const Pills = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  color: ${({ colors }) => colors.active};
  text-decoration: none;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    filter: brightness(70%);
  }

  // Sets active item styles
  ${({ active }) =>
    !active &&
    css`
      color: ${({ colors }) => colors.inactive};
      text-decoration: underline;
      font-weight: normal;
    `}

  // Overrides color when it's an arrow
  ${({ isArrow, colors }) =>
    isArrow &&
    css`
      color: ${colors.arrow};
    `}

  // Overrides styles when item is disabled or is dots
  ${({ isDots, disabled, colors }) =>
    (isDots || disabled) &&
    css`
      ${disabled && colors.disabled && `color: ${colors.disabled};`}
      cursor: default;
      text-decoration: none;
      &:hover {
        filter: none;
      }
    `}

  ${({ pillStyle }) => pillStyle}
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 420px;

  ${mobile`
    background-color: white;
  `}
`;

const range = (start, end) => {
  const length = end - start + 1;
  // Create an array of certain length and set the elements within it from
  // start value to end value.
  return Array.from({ length }, (_, idx) => idx + start);
};

const Paginator = ({
  onPageChange,
  totalCount,
  siblingCount = 2,
  currentPage,
  pageSize,
  arrowLeft,
  arrowRight,
  colors = {},
  pillStyle,
  size,
  ...rest
}) => {
  const siblingCountForWidth = useMedia({ minWidth: '480px' })
    ? siblingCount
    : 1;

  const totalPageCount = useMemo(() => {
    return size || Math.ceil(totalCount / pageSize);
  }, [size, totalCount, pageSize]);

  const paginationRange = useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const visiblePageNumbers = siblingCountForWidth + 5;

    // Case 1:
    // If the number of pages is less than the page numbers we want to show in our
    // paginationComponent, we return the range [1..totalPageCount]
    if (visiblePageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    const leftSiblingIndex = Math.max(currentPage - siblingCountForWidth, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCountForWidth,
      totalPageCount
    );

    // We do not show dots just when there is just one page number to be inserted between the extremes
    //  of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2
    // and rightSiblingIndex < totalPageCount - 2
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // Case 2: No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCountForWidth;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    // Case 3: No right dots to show, but left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCountForWidth;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    // Case 4: Both left and right dots to be shown
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalPageCount, siblingCountForWidth, currentPage]);

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    if (currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <Wrapper {...rest}>
      <Pills
        onClick={onPrevious}
        pillStyle={pillStyle}
        disabled={currentPage === 1}
        colors={colors}
        isArrow
      >
        {arrowLeft || <BsArrowLeft strokeWidth={0.8} />}
      </Pills>

      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <Pills
              key={index}
              className="pagination-item dots"
              colors={colors}
              isDots
              pillStyle={pillStyle}
            >
              &#8230;
            </Pills>
          );
        }

        // Render our Page Pills
        return (
          <Pills
            key={index}
            onClick={() => onPageChange(pageNumber)}
            active={currentPage === pageNumber}
            colors={colors}
            pillStyle={pillStyle}
          >
            {pageNumber}
          </Pills>
        );
      })}

      <Pills
        onClick={onNext}
        disabled={currentPage === lastPage}
        colors={colors}
        isArrow
        pillStyle={pillStyle}
      >
        {arrowRight || <BsArrowRight strokeWidth={0.8} />}
      </Pills>
    </Wrapper>
  );
};

Paginator.propTypes = {
  /**
   *  Custom element to be rendered as left arrow
   */
  arrowLeft: PropTypes.node,
  /**
   * Custom element to be rendered as right arrow
   */
  arrowRight: PropTypes.node,
  /**
   *  Object with colors to render the text.
   * May contain properties for active, inactive and disabled
   */
  colors: PropTypes.shape({
    activeColor: PropTypes.string,
    inactiveColor: PropTypes.string,
    disabledColor: PropTypes.string,
  }),
  /**
   * Number of the page that is currently selected
   */
  currentPage: PropTypes.number,
  /**
   * Callback that will be called on page change
   */
  onPageChange: PropTypes.func,
  /**
   * Amount of items included in a "page". Will be used to calculate
   * the total amount of pages if `size` is not provided
   */
  pageSize: PropTypes.number,
  /**
   *  Override styles for the button "Pills". Can be anything Styled Components accepts
   */
  pillStyle: PropTypes.any,
  /**
   *  The number of Pills to be rendered to each side of the active page
   */
  siblingCount: PropTypes.number,
  /**
   *  The total page count.
   */
  size: PropTypes.number,
  /**
   * The total amount of data. Will be used to calculate the total pages if
   * `size` is not provided
   */
  totalCount: PropTypes.number,
};

export default Paginator;

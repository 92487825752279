import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getWishlistIds } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useWishlistIds() {
  const actions = useAccountActions();
  const wishlistIds = useSelector(getWishlistIds);

  useEffect(() => {
    if (!wishlistIds.networkStatus.isUpToDate) {
      actions.loadWishlistIds();
    }
  }, [actions, wishlistIds.networkStatus.isUpToDate]);

  return wishlistIds;
}

import React from 'react';

import { Field, FieldProps } from 'formik';
import styled, { css } from 'styled-components';

import ErrorIcon from '../ErrorIcon';
import TextField from '../TextField';

const StyledTextField: any = styled(TextField)`
  [data-field-input] {
    transform: translate3d(0, -8px, 0);
  }

  [data-field-input-box] {
    ${(props: any) =>
      props.error &&
      css`
        border: 1px solid ${(props) => props.theme.colors.formError};
      `};
  }

  [data-after-input] {
    margin-top: -5px;
  }

  [data-field-label] {
    &[data-label-position='inside'] {
      display: inline-block;
      background-color: ${(props) => props.theme.colors.white};
      padding: 0 5px;
      transform: translate3d(0, -13px, 0) scale(0.75);
      pointer-events: auto;
    }
  }
`;

const errorStyle = css`
  color: ${(props) => props.theme.colors.formError};
  margin-top: 3px;
`;

interface FormFieldProps {
  name: string;
  label: string;
}

export default function FormField({ name, label, ...rest }: FormFieldProps) {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <StyledTextField
          variant="collapse"
          name={field.name}
          label={label}
          labelPosition="floating"
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          error={form.errors[field.name]}
          errorStyle={errorStyle}
          afterInput={form.errors[field.name] ? <ErrorIcon /> : null}
          {...rest}
        />
      )}
    </Field>
  );
}

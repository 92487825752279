import React from 'react';

import PropTypes from 'prop-types';

import useCurrency from '../useCurrency';
import { omitUndefined } from '../utils';

export default function Currency({
  amount,
  currencyDisplay,
  maximumFractionDigits,
  minimumFractionDigits,
  trimZeros,
  useGrouping,
  ...rest
}) {
  const { formatCurrency, prefix, suffix } = useCurrency();

  // This is tedious, but we don't want `undefined` prop values below to override
  // values defined in the context. But we also need to merge the options with
  // props taking precedence; this means we need to remove `undefined` from the
  // options passed in so that the context values will be seen instead.
  const formatOptions = omitUndefined({
    currencyDisplay,
    maximumFractionDigits,
    minimumFractionDigits,
    trimZeros,
    useGrouping,
    // We're going to add the potentially-React-element versions of these below,
    // so make sure the string doesn't already include them.
    prefixString: '',
    suffixString: '',
  });

  const displayAmount = formatCurrency(amount, formatOptions);

  return (
    <span {...rest}>
      {prefix}
      {displayAmount}
      {suffix}
    </span>
  );
}

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currencyDisplay: PropTypes.string,
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
  trimZeros: PropTypes.bool,
  useGrouping: PropTypes.bool,
};

import normalizeUrl from './normalizeUrl';

export const pathHierarchy = {
  EXACT: 'exact',
  OUTSIDE: 'outside',
  WITHIN: 'within',
} as const;

type Hierarchies = keyof typeof pathHierarchy;
export type PathHierarchy = (typeof pathHierarchy)[Hierarchies];

export default function comparePathToUrl(
  path: string,
  url: string
): PathHierarchy {
  const normalizedUrl = normalizeUrl(url);

  if (path === normalizedUrl) {
    return pathHierarchy.EXACT;
  }
  if (path.startsWith(normalizedUrl)) {
    return pathHierarchy.WITHIN;
  }
  return pathHierarchy.OUTSIDE;
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import FocusTrap from 'focus-trap-react';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { Transition, animated } from 'react-spring';
import styled from 'styled-components';

import AppHidden from '../AppHidden';
import useMounted from '../useMounted';
import withThemeProps from '../withThemeProps';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${(props) => props.wrapperStyle};
`;

const Overlay = animated(styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${(props) => props.overlayStyle};
`);

const Dialog = animated(styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;

  > * {
    flex-shrink: 0;
    flex-grow: 1;
  }

  ${(props) => props.dialogStyle};
`);

/**
 * @component
 *
 * An accessible modal component for making content appear above the main page.
 *
 * The modal will be open by default if the component is mounted, or you can
 * keep it mounted and use the `isOpen` prop. The advantage of using the
 * `isOpen` prop is that any exit animation will be allowed to complete before
 * the modal’s elements are unmounted; if the entire `<Modal>` is unmounted
 * then there is no way to delay their disapperance.
 *
 * You can use the `[data-modal-overlay]` and `[data-modal-dialog]` selectors
 * to target the overlay and dialog, respectively. However, this should rarely
 * be necessary. Most of the time, you’ll want to style your dialog content
 * directly instead, and potentially pass `overlayColor`. The dialog has no
 * default width or color; it adapts to the content you pass as a child. Do not
 * style `[data-modal-dialog]` just to change the appearance of your content.
 */
export function Modal__() {
  return null;
}
export function Modal({
  alert,
  animationDisabled,
  beforeDialog,
  children,
  className,
  transitionDelay,
  dialogProps,
  dialogStyle,
  escapeExits,
  focusTrapConfig,
  initialFocus,
  isOpen,
  onEnter,
  onExit,
  overlayClickExits,
  overlayColor,
  overlayStyle,
  springConfig,
  springEvents,
  title,
  titleId,
  transitionEnter,
  transitionFrom,
  transitionLeave,
  wrapperStyle,
}) {
  const mounted = useMounted();
  // Track this node with both state (to respond to updates in certain effects)
  // and a ref (for synchronous updates).
  const dialogRef = useRef();
  const [dialogNode, setDialogNode] = useState();

  const updateDialogRef = useCallback((node) => {
    dialogRef.current = node;
    setDialogNode(node);
  }, []);

  useEffect(() => {
    if (isOpen && onEnter) {
      onEnter();
    }
  }, [isOpen, onEnter]);

  const focusTrapOptions = useMemo(() => {
    return {
      escapeDeactivates: false,
      initialFocus:
        initialFocus || (beforeDialog ? () => dialogRef.current : undefined),
      fallbackFocus: () => dialogRef.current,
    };
  }, [beforeDialog, initialFocus]);

  const handleKeyDown = useCallback(
    (event) => {
      if (onExit && event.keyCode === 27) {
        onExit(event);
      }
    },
    [onExit]
  );

  const hiddenColor = useMemo(
    () => transparentize(1, overlayColor),
    [overlayColor]
  );

  if (!mounted) {
    return null;
  }

  if (!title && !titleId) {
    throw new Error(
      `You must supply either the title or titleId prop for accessibility.`
    );
  }

  return ReactDOM.createPortal(
    <Transition
      items={isOpen}
      from={{
        overlayColor: hiddenColor,
        ...transitionFrom,
      }}
      enter={{
        overlayColor,
        ...transitionEnter,
      }}
      leave={{
        overlayColor: hiddenColor,
        ...transitionLeave,
      }}
      immediate={animationDisabled}
      config={springConfig}
      delay={transitionDelay}
      native
      {...springEvents}
    >
      {(styles, isVisible, ...props) => {
        const { overlayColor, ...dialogTransition } = styles;
        return isVisible ? (
          <FocusTrap
            // Don't waste the user's time with animation: disable the focus
            // trap as soon as the modal starts closing, instead of when it's
            // done animating.
            focusTrapOptions={focusTrapOptions}
            active={
              focusTrapConfig.active !== undefined
                ? focusTrapConfig.active
                : isOpen && dialogNode != null
            }
          >
            <RemoveScroll forwardProps allowPinchZoom enabled={isOpen}>
              <Wrapper
                className={className}
                onKeyDown={escapeExits ? handleKeyDown : undefined}
                tabIndex={-1}
                wrapperStyle={wrapperStyle}
              >
                {isOpen ? <AppHidden /> : null}
                <Overlay
                  data-modal-overlay=""
                  onClick={overlayClickExits ? onExit : undefined}
                  overlayStyle={overlayStyle}
                  style={{
                    // Don't waste the user's time with animation: allow
                    // clicking through the overlay as soon as the modal
                    // starts closing, instead of when it's done animating.
                    pointerEvents: isOpen ? undefined : 'none',
                    backgroundColor: overlayColor,
                  }}
                />
                {beforeDialog}
                {isOpen || transitionLeave ? (
                  <Dialog
                    aria-label={title}
                    aria-labelledby={titleId}
                    data-modal-dialog=""
                    dialogStyle={dialogStyle}
                    ref={updateDialogRef}
                    role={alert ? 'alertdialog' : 'dialog'}
                    style={dialogTransition}
                    tabIndex={-1}
                    {...dialogProps}
                  >
                    {children}
                  </Dialog>
                ) : null}
              </Wrapper>
            </RemoveScroll>
          </FocusTrap>
        ) : null;
      }}
    </Transition>,
    document.body
  );
}

Modal.propTypes = {
  /**
   * Whether the dialog is showing an alert. This affects whether the ARIA role
   * is `dialog` or `alertdialog`.
   */
  alert: PropTypes.bool,
  /**
   * Whether to disable animation and hide/show the modal immediately.
   */
  animationDisabled: PropTypes.bool,
  /**
   * Content to render before the dialog element inside the same parent wrapper.
   * This allows you to offset the drawer or render additional content within
   * the same focus trap (like a header).
   */
  beforeDialog: PropTypes.node,
  /**
   * Your dialog content. Style this when you want to change the modal’s width.
   */
  children: PropTypes.node,
  /**
   * The class applied to the element wrapping the dialog and overlay.
   */
  className: PropTypes.string,
  /**
   * Addtional props passed to dialog element.
   */
  dialogProps: PropTypes.any,
  /**
   * Styles to pass to the dialog element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  dialogStyle: PropTypes.any,
  /**
   * Whether the <kbd>Escape</kbd> key closes the modal.
   */
  escapeExits: PropTypes.bool,
  /**
   * Pass additional parameters to `focus-trap`
   */
  focusTrapConfig: PropTypes.object,
  /**
   * An element to focus when the modal opens, or a function that returns an
   * element. Passed to `focus-trap`, see:
   * https://github.com/davidtheclark/focus-trap#usage
   */
  initialFocus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  /**
   * Whether the modal is open or closed. You can also choose to mount or
   * unmount the entire `<Modal>` element instead, but it’s better to use the
   * `isOpen` prop to allow exit animations to run before the modal’s elements
   * are unmounted.
   */
  isOpen: PropTypes.bool,
  /**
   * A function called when the modal is opened.
   */
  onEnter: PropTypes.func,
  /**
   * A function called when the modal should close.
   */
  onExit: PropTypes.func,
  /**
   * Whether clicking the overlay should close the modal.
   */
  overlayClickExits: PropTypes.bool,
  /**
   * The background color of the overlay.
   */
  overlayColor: PropTypes.string,
  /**
   * Styles to pass to the overlay element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  overlayStyle: PropTypes.any,
  /**
   * Control the transition speed by configuring the spring used to animate the
   * modal. See the [react-spring docs](https://react-spring.io/common/configs).
   */
  springConfig: PropTypes.object,
  /**
   * Event handlers to pass to react-spring's Transition component. Allows us to react to things like
   * the spring animation finishing. See the [react-spring Transition event docs](https://react-spring.io/common/props#events).
   *
   */
  springEvents: PropTypes.object,
  /**
   * The dialog title. You must supply either this or `titleId`. The title
   * must be a string since it is placed in the `aria-label` attribute; it
   * cannot be a React element.
   */
  title: PropTypes.string,
  /**
   * The ID of an element that contains the title of the dialog. This will be
   * used to populate the `aria-labelledby` attribute. You must supply either
   * this or `title`.
   */
  titleId: PropTypes.string,
  /**
   * Delay in ms before the animation starts. Also valid as a function for individual keys: key => delay,
   * see: https://react-spring.io/common/props
   */
  transitionDelay: PropTypes.number,
  /**
   * An object to pass to [react-spring’s Transition component](https://react-spring.io/components/transition).
   */
  transitionEnter: PropTypes.object,
  /**
   * An object to pass to [react-spring’s Transition component](https://react-spring.io/components/transition).
   */
  transitionFrom: PropTypes.object,
  /**
   * An object to pass to [react-spring’s Transition component](https://react-spring.io/components/transition).
   * If no object is passed as the leave transition, the dialog element will
   * disappear immediately (while the overlay fades away). Otherwise, it will
   * remain mounted while the animation runs.
   */
  transitionLeave: PropTypes.object,
  /**
   * The theme variant to render. Defaults to `default`.
   */
  variant: PropTypes.string,
  /**
   * Styles to pass to the Wrapper element.
   */
  wrapperStyle: PropTypes.any,
};
Modal.defaultProps = {
  alert: false,
  animationDisabled: false,
  escapeExits: true,
  focusTrapConfig: {},
  isOpen: true,
  overlayClickExits: true,
  overlayColor: 'rgba(0, 0, 0, 0.4)',
  springConfig: {
    tension: 500,
    friction: 40,
  },
  transitionDelay: 0,
};

export default withThemeProps(Modal, {
  section: 'modal',
  defaultVariant: 'default',
});

import React, { useState, useContext, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

import CollapsibleGroup from '../CollapsibleGroup';
import Untabbable from '../Untabbable';
import useId from '../useId';
import useSizeTransition from '../useSizeTransition';
import useTabIndex from '../useTabIndex';

const ContentContainer = styled.div`
  box-sizing: border-box;
  height: auto;
  will-change: height;
  overflow: hidden;
  transition: height ${(props) => props.transitionDuration}ms;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);

  &[data-transition-state='exiting'],
  &[data-transition-state='exited'] {
    height: 0;
  }

  &[data-transition-state='exited'] {
    visibility: hidden;
  }
`;

const ButtonElement = styled.button`
  display: block;
  width: 100%;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
  background: transparent;
  color: inherit;
`;

const CollapsibleContext = React.createContext();

export function useCollapsibleState() {
  return useContext(CollapsibleContext);
}

function preventDefault(event) {
  event.preventDefault();
}

export function CollapsibleButton({ children, onClick, tabIndex, ...rest }) {
  const context = useCollapsibleState();
  tabIndex = useTabIndex(tabIndex);

  const handleClick = useCallback(
    (event) => {
      // Strangely, Safari does not focus buttons when they're clicked. Without
      // this, unexpected behavior emerges when Collapsible is combined with a
      // FocusRegion.
      event.currentTarget.focus();
      if (onClick) {
        onClick(event, context);
      } else {
        context.toggleCollapsible();
      }
    },
    [context, onClick]
  );

  return (
    <ButtonElement
      aria-controls={context.contentId}
      aria-expanded={context.isOpen}
      id={context.buttonId}
      // Strangely, Safari blurs the button on mousedown instead of focusing it
      // like other browsers. Trying to call `event.target.focus()` on
      // mousedown does nothing. However, calling `preventDefault` seems to at
      // least prevent it from blurring on mousedown. Without this, when
      // combined with a FocusRegion, clicking the button when open would
      // close (due to blurring), then reopen it.
      onMouseDown={preventDefault}
      {...rest}
      onClick={handleClick}
      tabIndex={tabIndex}
    >
      {children}
    </ButtonElement>
  );
}

CollapsibleButton.displayName = 'Collapsible.Button';

CollapsibleButton.propTypes = {
  /**
   * Content to pass to the CollapsibleButton. Will be displayed within the button.
   */
  children: PropTypes.node,
  /**
   * The function to call when the collapsible button is clicked. Passes the CollapsibleContext with:
   * isOpen,
   * contentId,
   * buttonId,
   * openCollapsible,
   * closeCollapsible,
   * toggleCollapsible
   */
  onClick: PropTypes.func,
  /**
   * The value of the tabIndex attribute.
   */
  tabIndex: PropTypes.number,
};

export function CollapsibleContent({
  children,
  mountOnEnter = false,
  transitionDuration = 400,
  unmountOnExit = false,
  ...rest
}) {
  const context = useCollapsibleState();
  const { prepare, run, cleanup } = useSizeTransition(null, { height: true });

  return (
    <Untabbable active={!context.isOpen}>
      <Transition
        in={context.isOpen}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        timeout={transitionDuration}
        onEnter={prepare}
        onEntering={run}
        onEntered={cleanup}
        onExit={prepare}
        onExiting={run}
        onExited={cleanup}
      >
        {(state) => (
          <ContentContainer
            id={context.contentId}
            aria-labelledby={context.buttonId}
            aria-hidden={!context.isOpen}
            data-transition-state={state}
            transitionDuration={transitionDuration}
            {...rest}
          >
            {children}
          </ContentContainer>
        )}
      </Transition>
    </Untabbable>
  );
}

CollapsibleContent.displayName = 'Collapsible.Content';

CollapsibleContent.propTypes = {
  /**
   * The content to show when expanded and hide when collapsed.
   */
  children: PropTypes.node,
  /**
   * Whether to delay mounting the content container until expanded.
   */
  mountOnEnter: PropTypes.bool,
  /**
   * Duration of the transition animation in milliseconds.
   */
  transitionDuration: PropTypes.number,
  /**
   * Whether to unmount the content container when collapsed.
   */
  unmountOnExit: PropTypes.bool,
};

/**
 * A low-level collapsible component that can be used to build accordions or
 * one-off elements that expand and collapse.
 *
 * The `<Collapsible>` component itself renders nothing, but manages the state
 * of each collapsible item. To render a toggle button and content, use
 * `<Collapsible.Button>` and `<Collapsible.Content>`, respectively.
 *
 * ```jsx
 * <Collapsible defaultOpen={false}>
 *   <Collapsible.Button>Click to toggle</Collapsible.Button>
 *   <Collapsible.Content>Surprise!</Collapsible.Content>
 * </Collapsible>
 *   <Collapsible defaultOpen={false}>
 *   <Collapsible.Button>Click to toggle</Collapsible.Button>
 *   <Collapsible.Content>Surprise!</Collapsible.Content>
 * </Collapsible>
 *   <Collapsible defaultOpen={false}>
 *   <Collapsible.Button>Click to toggle</Collapsible.Button>
 *   <Collapsible.Content>Surprise!</Collapsible.Content>
 * </Collapsible>
 * ```
 *
 * `Collapsible.Button` and `Collapsible.Content` can be individually styled
 * with the usual props like `className` and `style`. You can also build your
 * own completely custom elements with the `useCollapsibleState` hook.
 *
 * See `<CollapsibleGroup>` for managing multiple collapsibles in a single
 * group.
 */
export default function Collapsible({
  children,
  isOpen: inputIsOpen,
  defaultOpen = true,
  onOpen,
  onClose,
}) {
  const groupState = CollapsibleGroup.useState();
  const [internalIsOpen, setOpen] = useState(defaultOpen);
  const contentId = useId();
  const buttonId = useId();
  let isOpen;
  if (inputIsOpen != null) {
    isOpen = inputIsOpen;
  } else if (groupState && !groupState.allowMultipleOpen) {
    isOpen =
      typeof groupState.openId === 'undefined'
        ? defaultOpen
        : groupState.openId === contentId;
  } else {
    isOpen = internalIsOpen;
  }

  const openCollapsible = useCallback(
    (event) => {
      if (inputIsOpen != null) {
        if (onOpen) {
          onOpen(event);
        }
      } else if (groupState && !groupState.allowMultipleOpen) {
        groupState.setOpenId(contentId);
      } else {
        setOpen(true);
      }
    },
    [contentId, onOpen, inputIsOpen, groupState]
  );

  const closeCollapsible = useCallback(
    (event) => {
      if (inputIsOpen != null) {
        if (onClose) {
          onClose(event);
        }
      } else if (groupState && !groupState.allowMultipleOpen) {
        groupState.setOpenId(null);
      } else {
        setOpen(false);
      }
    },
    [onClose, inputIsOpen, groupState]
  );

  const toggleCollapsible = useCallback(() => {
    if (isOpen) {
      closeCollapsible();
    } else {
      openCollapsible();
    }
  }, [closeCollapsible, isOpen, openCollapsible]);

  const context = useMemo(
    () => ({
      isOpen,
      contentId,
      buttonId,
      openCollapsible,
      closeCollapsible,
      toggleCollapsible,
    }),
    [
      closeCollapsible,
      contentId,
      isOpen,
      buttonId,
      openCollapsible,
      toggleCollapsible,
    ]
  );

  return (
    <CollapsibleGroup.UnsetGroup>
      <CollapsibleContext.Provider value={context}>
        {children}
      </CollapsibleContext.Provider>
    </CollapsibleGroup.UnsetGroup>
  );
}

Collapsible.propTypes = {
  /**
   * Content to render, usually a `Collapsible.Button` and `Collapsible.Content`.
   * Use the `useCollapsibleState` hook to access the state of the Collapsible
   * directly for more manual control.
   */
  children: PropTypes.node,
  /**
   * Whether or not the collapsible component will render as open initially.
   * Defaults to true.
   */
  defaultOpen: PropTypes.bool,
  /**
   * Allows you to control if the collapsible is open or not. You must supply
   * `onClose` and `onOpen` handlers in this case.
   */
  isOpen: PropTypes.bool,
  /**
   * Callback function to handle the closing of the collapsible component.
   */
  onClose: PropTypes.func,
  /**
   * Callback function to handle the opening of the collapsible component.
   */
  onOpen: PropTypes.func,
};

Collapsible.Button = CollapsibleButton;
Collapsible.Content = CollapsibleContent;
Collapsible.Context = CollapsibleContext;
Collapsible.useState = useCollapsibleState;

import React, { useMemo } from 'react';

import {
  useCategoryLabels,
  useOptionLabels,
  useFilterFieldLabels,
} from './LabelProvider';
import { LabelType } from './LabelProvider/constants';

export default function useFilterTagValues({
  aggregations,
  categoryFilter,
  filters,
  avgReviewFilter,
  priceFilter,
  setFilters,
}) {
  const categoryLabelCtx = useCategoryLabels();
  const optionLabelCtx = useOptionLabels();
  const filterFieldCtx = useFilterFieldLabels();

  return useMemo(() => {
    const filterTagValues = [];
    if (categoryFilter) {
      const labelOptions = {
        labelType: LabelType.CATEGORY_FILTER_TAG,
      };
      filterTagValues.push(
        ...categoryFilter.items
          .filter((item) => filters.categoryIds.includes(item.value))
          .map((item) => {
            return {
              filterField: categoryFilter,
              label: categoryLabelCtx.renderLabel(item, labelOptions),
              labelString: categoryLabelCtx.renderLabelString(
                item,
                labelOptions
              ),
              filterValue: item.value,
              onDismiss: () => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  categoryIds: prevFilters.categoryIds.filter(
                    (value) => value !== item.value
                  ),
                }));
              },
            };
          })
      );
    }
    aggregations.forEach((aggFilter) => {
      const labelOptions = {
        labelType: LabelType.PRODUCT_FILTER_TAG,
      };
      const values = filters.aggregationFilter[aggFilter.field];
      if (values.length) {
        filterTagValues.push(
          ...values.map((filterValue) => {
            const isSizeFilter = /^size_/.test(aggFilter.field);
            const option = { label: filterValue, value: filterValue };
            return {
              filterField: aggFilter,
              label: (
                <>
                  {optionLabelCtx.renderLabel(aggFilter, option, labelOptions)}
                  {isSizeFilter ? (
                    <>
                      {' ('}
                      {filterFieldCtx.renderLabel(aggFilter, labelOptions)})
                    </>
                  ) : null}
                </>
              ),
              labelString: `${optionLabelCtx.renderLabelString(
                aggFilter,
                option,
                labelOptions
              )}${
                isSizeFilter
                  ? ` (${filterFieldCtx.renderLabelString(
                      aggFilter,
                      labelOptions
                    )})`
                  : ''
              }`,
              filterValue,
              onDismiss: () => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  aggregationFilter: {
                    ...prevFilters.aggregationFilter,
                    [aggFilter.field]: prevFilters.aggregationFilter[
                      aggFilter.field
                    ].filter((value) => value !== filterValue),
                  },
                }));
              },
            };
          })
        );
      }
    });

    // FIXME: This always adds `avgReviewMin` to the end, but the order should
    // be controllable by the consumer (just like the filters themselves).
    if (avgReviewFilter && filters.avgReviewMin != null) {
      const labelOptions = {
        labelType: LabelType.PRODUCT_FILTER_TAG,
      };
      const selectedOption = avgReviewFilter.items.find(
        (item) => item.value === filters.avgReviewMin
      );
      filterTagValues.push({
        filterField: avgReviewFilter,
        label: optionLabelCtx.renderLabel(
          avgReviewFilter,
          selectedOption,
          labelOptions
        ),
        labelString: optionLabelCtx.renderLabelString(
          avgReviewFilter,
          selectedOption,
          labelOptions
        ),
        filterValue: selectedOption.value,
        onDismiss: () => {
          setFilters((prevFilters) => ({ ...prevFilters, avgReviewMin: null }));
        },
      });
    }

    // FIXME: This always adds `price` to the end, but the order should
    // be controllable by the consumer (just like the filters themselves).
    if (
      priceFilter &&
      (filters.vipUnitPriceMinimum != null ||
        filters.vipUnitPriceMaximum != null ||
        filters.saleUnitPriceMinimum != null ||
        filters.saleUnitPriceMaximum != null)
    ) {
      const labelOptions = {
        labelType: LabelType.PRODUCT_FILTER_TAG,
      };
      const selectedOption = {
        value: {
          min: filters.vipUnitPriceMinimum || filters.saleUnitPriceMinimum,
          max: filters.vipUnitPriceMaximum || filters.saleUnitPriceMaximum,
        },
      };
      filterTagValues.push({
        filterField: priceFilter,
        label: optionLabelCtx.renderLabel(
          priceFilter,
          selectedOption,
          labelOptions
        ),
        labelString: optionLabelCtx.renderLabelString(
          priceFilter,
          selectedOption,
          labelOptions
        ),
        // This is only used by FilterTags to generate a key! In this case,
        // doesn't need to be specific to the actual value; it's arbitrary.
        filterValue: 'range',
        onDismiss: () => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            vipUnitPriceMinimum: null,
            vipUnitPriceMaximum: null,
            saleUnitPriceMinimum: null,
            saleUnitPriceMaximum: null,
          }));
        },
      });
    }

    return filterTagValues;
  }, [
    aggregations,
    avgReviewFilter,
    categoryFilter,
    categoryLabelCtx,
    filterFieldCtx,
    filters.aggregationFilter,
    filters.avgReviewMin,
    filters.categoryIds,
    filters.vipUnitPriceMaximum,
    filters.vipUnitPriceMinimum,
    filters.saleUnitPriceMaximum,
    filters.saleUnitPriceMinimum,
    optionLabelCtx,
    priceFilter,
    setFilters,
  ]);
}

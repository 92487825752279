import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';
import useTheme from '../useTheme';
import withThemeProps from '../withThemeProps';

export function getCircleRadius({ size, strokeWidth }) {
  return size / 2 - strokeWidth / 2;
}

export function getCircleCircumference({ radius }) {
  return 2 * Math.PI * radius;
}

export function getCircleOffset({ circumference, maxValue, minValue, value }) {
  const rangedValue = Math.min(Math.max(value, minValue), maxValue);
  const percentage = (rangedValue - minValue) / (maxValue - minValue);
  const offset = (1 - percentage) * circumference;
  return offset;
}

// Aligns the passed children to the center of the svg
const DefaultChildrenWrapper = styled.div`
  font-size: ${({ size }) => size / 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function RadialProgressBar({
  children,
  isResponsive = false,
  maxValue = 100,
  minValue = 0,
  progressBarColor = 'currentColor',
  size: sizeFromProps = 100,
  strokeWidth = 10,
  title,
  trackBarColor = 'currentColor',
  trackBarFillColor = 'transparent',
  value = 0,
  ...rest
}) {
  const theme = useTheme();
  const titleId = useId();

  const size = isResponsive ? 100 : sizeFromProps;
  const center = size / 2;
  const radius = getCircleRadius({ size, strokeWidth });
  const circumference = getCircleCircumference({ radius });
  const offset = getCircleOffset({ circumference, maxValue, minValue, value });

  return (
    <svg
      width={isResponsive ? '100%' : size}
      height={isResponsive ? '100%' : size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {title && <title id={titleId}>{title}</title>}

      <circle
        cx={center}
        cy={center}
        fill={theme.colors[trackBarFillColor] || trackBarFillColor}
        r={radius}
        stroke={theme.colors[trackBarColor] || trackBarColor}
        strokeWidth={strokeWidth}
      />

      <circle
        cx={center}
        cy={center}
        fill="none"
        r={radius}
        stroke={theme.colors[progressBarColor] || progressBarColor}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeWidth={strokeWidth}
        transform={`rotate(-90 ${center} ${center})`}
      />

      <foreignObject width={size} height={size}>
        <DefaultChildrenWrapper size={size}>{children}</DefaultChildrenWrapper>
      </foreignObject>
    </svg>
  );
}

RadialProgressBar.propTypes = {
  /**
   * Note that children will be rendered inside of a <foreignObject> element.
   */
  children: PropTypes.any,
  /**
   * Boolean to determine if the size of the svg element should be responsive to its parent container.
   * When its set to true, the width and height of the svg element is set to `100%`, and the `size`
   * constant that is used to calculate the `center`, `radius`, `circumference` and `offset` is set to
   * `100`, that way the values of `stroke-dasharray` and `stroke-dashoffset` stays consistent, while
   * the svg is responsive. Also note that while `isResponsive` is set to true, any value passed to the
   * `size` prop will be voided and not affect the size of the svg element.
   */
  isResponsive: PropTypes.bool,
  /**
   * Maximum value of the progress bar. Defaults to 100.
   */
  maxValue: PropTypes.number,
  /**
   * Minimum value of the progress bar. Defaults to 0.
   */
  minValue: PropTypes.number,
  /**
   * Stroke color of the progress bar.
   * The color is first looked up in `theme.colors`, and otherwise may be any valid CSS color value.
   */
  progressBarColor: PropTypes.string,
  /**
   * Width and height of the svg element. Defaults to 100.
   * The value of `size` must always be greater than `strokeWidth`.
   * If the prop `isResponsive` is set to true, any value passed to `size` will not affect the svg element.
   */
  size: PropTypes.number,
  /**
   * Stroke width of both the progress bar and tracking bar. Defaults to 10.
   * Note that strokeWidth must always be less than size.
   */
  strokeWidth: PropTypes.number,
  /**
   * Accessible, short-text title description that will not be rendered, the browser will
   * display this description as a tooltip on hover.
   */
  title: PropTypes.string,
  /**
   * Stroke color of the tracking bar.
   * The color is first looked up in `theme.colors`, and otherwise may be any valid CSS color value.
   */
  trackBarColor: PropTypes.string,
  /**
   * Fill color of the tracking bar.
   * The color is first looked up in `theme.colors`, and otherwise may be any valid CSS color value.
   */
  trackBarFillColor: PropTypes.string,
  /**
   * Completion value of the progress bar from `minValue` to `maxValue`. Required.
   */
  value: PropTypes.number.isRequired,
};

export default withThemeProps(RadialProgressBar, {
  section: 'radialProgressBar',
  defaultVariant: 'default',
});

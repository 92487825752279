import React from 'react';

// eslint-disable-next-line
import extends2 from '@babel/runtime/helpers/extends';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import useSiteSection from '../../../../layout/utils/useSiteSection';
import {
  desktop,
  mobile,
  FableticsLogo,
  FableticsMenLogo,
  YittyLogo,
} from '../../../../techstyle-shared/react-components';

const useBrandName = () => {
  return useSelector((state) => state.storeGroup.storeLabel);
};

const LogoWrapper = styled.a.attrs((props) => ({
  href: props.href || '/',
  'data-autotag': 'site_header_logo',
}))`
  display: inline-block;
  flex: 0 0 auto;

  /* Fabletics Men's logo is 18px taller than the default, so we don't need this extra
  padding for vertically centering the logo visually. */

  padding-bottom: ${({ isMale, brandName }) =>
    isMale || brandName !== 'Fabletics' ? '0px' : '8px'};

  ${desktop`
    flex: 0 1 auto;

    /* Pull the men's logo down on desktop so that it doesn't affect alignment
        of the other items it's vertically centered with. */

    margin-bottom: ${({ isMale }) => (isMale ? '-10px' : '0px')};
  `}
`;

const StyledFableticsLogo = styled(FableticsLogo)`
  color: ${({ color, theme }) => color || theme.colors.palette.lightBlack};
  width: ${({ width }) => width || '245px'};

  ${mobile`
    max-width: 145px;
  `}
`;

const StyledFableticsMenLogo = styled(FableticsMenLogo)`
  color: ${({ color, theme }) => color || theme.colors.palette.lightBlack};
  width: 100%;
  max-width: ${({ width }) => width || '245px'};

  ${mobile`
    max-width: 145px;
  `}
`;

const StyledYittyLogo = styled(YittyLogo)`
  width: 100%;
  max-width: ${({ width }) => width || '52px'};

  ${mobile`
    max-width: 65px;
  `}
`;
/**
 * Returns appropriate logo based on brand name and current site section
 * Wraps logo in <a> tag
 * Optionally supply props for adjusting logo 'width', 'color', and 'aria-label'
 * @param currentSiteSection
 * @param rest
 * @returns {JSX.Element}
 */

const SiteLogo = (_ref) => {
  const rest = extends2({}, _ref);
  const ariaLabel = rest['aria-label'];
  const color = rest.color;
  const width = rest.width;
  const brandName = (0, useBrandName)();

  const { currentPath: currentSiteSection } = useSiteSection();

  return (
    <LogoWrapper
      {...rest}
      isMale={currentSiteSection === 'MEN'}
      brandName={brandName}
    >
      {brandName === 'Fabletics' &&
        (currentSiteSection === 'MEN' ? (
          <StyledFableticsMenLogo
            aria-label={ariaLabel || undefined}
            color={color || undefined}
            width={width || undefined}
          />
        ) : (
          <StyledFableticsLogo
            aria-label={ariaLabel || undefined}
            color={color || undefined}
            width={width || undefined}
          />
        ))}
      {brandName === 'Yitty' && (
        <StyledYittyLogo
          aria-label={ariaLabel || undefined}
          color={color || undefined}
          width={width || undefined}
        />
      )}
    </LogoWrapper>
  );
};

SiteLogo.propTypes = {
  /** Constant returned from session, usu. one of ['WOMEN', 'MEN', 'STORES', 'FIT'] */
  // eslint-disable-next-line
  currentSiteSection: propTypes.oneOf(['WOMEN', 'MEN', 'STORES', 'FIT'])
};
/**
 * Allow recomposition of styled components
 */

SiteLogo.Wrapper = LogoWrapper;
SiteLogo.FableticsMenLogo = StyledFableticsMenLogo;
SiteLogo.FableticsLogo = StyledFableticsLogo;
SiteLogo.YittyLogo = StyledYittyLogo;

export default SiteLogo;

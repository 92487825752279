import { Gender } from '../../techstyle-shared/react-accounts';

const featureNames = {
  AFTERPAY: 'afterpay',
  ALLOW_FRONTEND_LOGGING: 'allow_frontend_logging',
  ALLOW_USER_UPLOADED_REVIEW_PHOTOS: 'allow_user_uploaded_review_photos',
  BOUNCEBACK_ENDOWMENT: 'bounceback_endowment',
  BOT_TEST: 'bot_test',
  BUILD_YOUR_OWN_BUNDLE: 'build_your_own_bundle',
  CANCEL_OFFER_HIGH: 'cancel_offer_high_50',
  CANCEL_OFFER_LOW: 'cancel_offer_low_20',
  CANCEL_OFFER_MID: 'cancel_offer_mid_30',
  CANCEL_OFFER_VERY_LOW: 'cancel_offer_very_low_10',
  CANCEL_ORDER_POPUP: 'cancel_order_popup',
  CONSTRUCTOR_SEARCH: 'use_constructorio_search',
  DENIM_INTEREST: 'denim_interest',
  GENDER_AUTO_FILTER: 'gender_auto_filter',
  GENESYS_WEB_CHAT: 'genesys_web_chat',
  COGNIGY_WEB_CHAT: 'cognigy_web_chat',
  KHLOE_SKIP_THE_MONTH: 'khloe_skip_the_month',
  KLARNA: 'klarna',
  LOYALTY_PROGRAM_FL: 'loyalty_program_fl',
  LOYALTY_PROGRAM_YITTY: 'loyalty_program_yitty',
  MEMBERSHIP_5995_PRICE: 'membership_5995_price',
  ONLINE_CANCEL_POPUP: 'online_cancel_chat_popup',
  POSTREG_TIMER: 'postreg_timer',
  PRIORITIZED_PROMOS: 'prioritized_promos',
  QUIZ_BIRTHDAY_AB_TEST_FEATURE: 'fabletics_quiz_birthday_ab_test',
  RETAILPRICETEST: 'RETAILPRICETEST',
  RETURN_FEATURE_BY_90_DAYS: 'return_feature_by_90_days',
  RETURN_IN_STORE: 'return_in_store',
  SCRUBS: 'scrubs',
  SHOW_OOS: 'show_oos',
  SINGLE_CTA_VIP_OPT_IN: 'single_cta_vip_opt_in',
  SKIP_TEST: 'skip_test',
  TEXT_EMBROIDERY: 'text_embroidery',
  USPS_RMA: 'usps_rma',
  ENABLE_PDP_VIDEO_DESCRIPTIONS: 'web_enable_pdp_video_descriptions',
  PASSWORDLESS_LOGIN: 'passwordless_login',
  PASSWORDLESS_SIGNUP: 'passwordless_signup',
  PROMO_PICKER_DEBUG: 'promo_picker_debug',
  PROMO_PICKER_MODAL_SIZES: 'ppp_sizes',
  PROMO_PICKER_SMS: 'ppp_sms',
  WAREHOUSE_OUTLET_SITE: 'warehouse_outlet_site',
  SAVE_OFFER_SEGMENTATION: 'save_offer_segmentation',
  USE_PRICING_SERVICE: 'use_pricing_service',
  SNOOZE_AB_TEST: 'snooze_ab_test',
  HDYH_TIERED_LIST: 'hdyh_tiered_list',
  SHOPPABLE_VIDEO: 'shoppable_video',
  SHOP_BY_COLOR: 'shop_by_color',
  SHOULD_USE_IMAGE_SERVICE_URL: 'use_image_service_url',
  WEB_ENABLE_POINTS_FOR_ACTIONS: 'web_enable_points_for_actions',
  INCENTIVIZED_REVIEWS_UI: 'incentivized_reviews_ui',
};

/*
  adding a validator to this object will circumvent feature creature
  and will not make an API call to retrieve these values when using
  local utilities getHasFeature and useHasFeature.

  READ BEFORE ADDING TO THIS LIST:
  When moving features into this list: please validate with a staff
  engineer that this is the appropriate place to put your feature
  and that the feature is now considered a statically defined feature.

  If adding a feature to this list that is permanently enabled or
  disabled: please create a tech debt or squad ticket to clean up
  the path that is no longer used. Please note the deprecation ticket
  in a comment above your feature.
*/
export const staticFeatureValidators = {
  [featureNames.GENDER_AUTO_FILTER]: ({ session }) => {
    return session.isLoggedIn;
  },
  [featureNames.KHLOE_SKIP_THE_MONTH]: ({ gender }) => {
    return gender === Gender.FEMALE;
  },
  [featureNames.LOYALTY_PROGRAM_FL]: ({ membership, tld }) => {
    return membership.isVip;
  },
  [featureNames.LOYALTY_PROGRAM_YITTY]: ({ membership, tld }) => {
    return tld === '.com' && membership.isVip;
  },
  [featureNames.MEMBERSHIP_5995_PRICE]: ({ tld }) => {
    return ['.com', '.ca', '.co.uk'].includes(tld);
  },
  [featureNames.QUIZ_BIRTHDAY_AB_TEST_FEATURE]: ({ tld }) => {
    return ['.com'].includes(tld);
  },
  [featureNames.RETAILPRICETEST]: ({ membership }) => {
    return !membership.isVip;
  },
  [featureNames.RETURN_FEATURE_BY_90_DAYS]: ({ tld }) => {
    return ['.com', '.ca'].includes(tld);
  },
  [featureNames.RETURN_IN_STORE]: ({ tld }) => {
    return ['.com'].includes(tld);
  },
  [featureNames.TEXT_EMBROIDERY]: ({ tld }) => {
    return ['.com'].includes(tld);
  },
  [featureNames.PROMO_PICKER_DEBUG]: () =>
    window?.location.hostname === 'localhost',
};

export default featureNames;

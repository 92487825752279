import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useSession } from '../../../techstyle-shared/redux-core';

import { getMembershipDetail } from './accountsModule';
import useAccountActions from './useAccountActions';

/**
 * Get full membership detail object from state. Will fetch from
 * API if needed.
 * @param {String} key The name of the membership detail to fetch.
 * @returns {Object} An object of full membership detail state.
 */
export default function useMembershipDetail(key) {
  const actions = useAccountActions();
  const { isLoggedIn } = useSession();

  // Since hooks can't be called conditionally, allow passing
  // a null `key` to skip loading a membership detail record.
  const membershipDetail = useSelector((state) =>
    key ? getMembershipDetail(state, key) : null
  );

  const isUpToDate = membershipDetail
    ? membershipDetail.networkStatus.isUpToDate
    : true;

  useEffect(() => {
    if (key && isLoggedIn && !isUpToDate) {
      actions.loadMembershipDetails([key]);
    }
  }, [actions, isLoggedIn, isUpToDate, key]);

  return membershipDetail;
}

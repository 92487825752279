import React from 'react';

import { FaRedoAlt } from 'react-icons/fa';
import styled from 'styled-components';

const ArrowLoadingIndicatorIcon = styled(FaRedoAlt)`
  ${({ theme }) => theme.animations.spin};
  display: block;
`;

export default function ArrowLoadingIndicator(props) {
  return <ArrowLoadingIndicatorIcon {...props} />;
}

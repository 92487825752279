import config from 'config';

import logger from './logger';

const debug = logger.extend('initializeSessionToken');

export default function initializeSessionToken({
  req,
  readSessionToken,
  updateSessionToken,
}) {
  // Checks if the auth token exists in cookies if anonymousServerSession
  // is false.
  const { anonymousServerSession } = req.context;

  const sessionToken = readSessionToken();
  // Only initializes the token if it doesn't currently exist
  if (sessionToken == null) {
    const sessionCookie = config.get('public.bentoApi.sessionCookie');
    const sessionCookieValue = req.cookies[sessionCookie];

    if (sessionCookieValue) {
      if (anonymousServerSession) {
        debug(
          "Ignoring incoming %s cookie, 'anonymousServerSession' is enabled.",
          sessionCookie
        );
      } else {
        debug(
          'Initializing authorization from incoming %s cookie.',
          sessionCookie
        );
        updateSessionToken(sessionCookieValue);
      }
    }
  }
}

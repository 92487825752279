import React, { useCallback, useEffect, useMemo } from 'react';

import { createBatches } from './batch';
import { mergeSlotsWithItems } from './slots';

export const Context = React.createContext();

export default function useItems(
  itemCount,
  slots,
  stepSize,
  targetBatchSize,
  maxBatchCount,
  getItems,
  loadItems
) {
  const itemCountUnknown = itemCount == null;
  const batches = useMemo(() => {
    if (itemCount == null) {
      return [];
    }

    const batches = createBatches({
      itemCount,
      slots,
      stepSize,
      targetBatchSize,
      maxBatchCount,
    });

    return batches;
  }, [itemCount, slots, stepSize, targetBatchSize, maxBatchCount]);

  const getItemsWithSlots = useCallback(
    ({ startIndex, stopIndex, slots, ...rest }) => {
      const items = getItems({ startIndex, stopIndex, slots, ...rest });
      return mergeSlotsWithItems({
        slots,
        items,
        startIndex,
        stopIndex,
      });
    },
    [getItems]
  );

  useEffect(() => {
    if (itemCountUnknown) {
      loadItems({
        startIndex: 0,
        stopIndex: targetBatchSize,
      });
    }
  }, [itemCountUnknown, loadItems, targetBatchSize]);

  return { batches, getItemsWithSlots };
}

import React from 'react';

import MiniCartContextProvider from '../cart/components/MiniCartContext';

export default function miniCartContextExtension() {
  return {
    id: 'miniCartContext',
    app: {
      render(props, children) {
        return <MiniCartContextProvider>{children}</MiniCartContextProvider>;
      },
    },
  };
}

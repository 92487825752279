import React, { useContext } from 'react';

export const Context = React.createContext(false);

type UntabbableProps = {
  /**
   * Whether to actually mark the tree as untabbable or not. If explicitly set
   * to false, the Untabbable will have no effect.
   */
  active?: boolean;
  /**
   * A single React element. The child or any of its descendants will get a
   * `tabindex` of `-1` if they use `useTabIndex`.
   */
  children: React.ReactElement;
};

/**
 * A wrapper that marks a section of the component tree as unreachable via the
 * <kbd>Tab</kbd> key. It renders no element on its own and must contain a
 * single element child. By itself it does nothing, but descendants using the
 * `useTabIndex` hook will receive `-1` if there is an active Untabbable
 * ancestor.
 *
 * If you need to toggle tabbability back on, set the `active` prop to false.
 * This is preferable to conditionally inserting the Untabbable parent since it
 * keeps the component tree structure the same, avoiding remounts.
 */
const Untabbable = React.forwardRef(function Untabbable(
  { active = true, children }: UntabbableProps,
  ref
) {
  const parentActive = useContext(Context);
  const child = React.Children.only(children);
  return (
    <Context.Provider value={Boolean(parentActive || active)}>
      {React.cloneElement(child, { ref })}
    </Context.Provider>
  );
});

Untabbable.displayName = 'Untabbable';

Untabbable.defaultProps = {
  active: true,
};

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-894053907
// Fix TypeScript error `Context` does not exist on...
const UntabbableNamespace = Object.assign(Untabbable, { Context });

export default UntabbableNamespace;

import { differenceInHours } from 'date-fns';

import { parseDate } from '../../../techstyle-shared/redux-core';

export default function getPostRegHoursFunction({
  dateTimeAdded,
  getCurrentDate,
}) {
  const getHoursSinceJoining = () => {
    const memberJoinDateTime = parseDate(dateTimeAdded).getTime();
    return differenceInHours(getCurrentDate(), memberJoinDateTime);
  };
  return getHoursSinceJoining;
}

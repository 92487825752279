import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { GoogleTagManagerSnippet } from '../../../techstyle-shared/react-marketing';

export default function FableticsTrackingVariables({ dataLayerName }) {
  const {
    isScrubsCustomer,
    isScrubsSession,
    isYittyCustomer,
    isYittySession,
    networkStatus,
  } = useSelector((state) => ({
    isScrubsCustomer: state.customerDetails?.isScrubs?.value,
    isScrubsSession: state.sessionDetails?.keys?.isScrubs?.value,
    isYittyCustomer: state.customerDetails?.is_yitty?.value,
    isYittySession: state.sessionDetails?.keys?.is_yitty?.value,
    networkStatus: state.sessionDetails?.networkStatus,
  }));

  const data = networkStatus.isUpToDate
    ? {
        is_scrubs: Boolean(isScrubsSession || isScrubsCustomer),
        is_yitty: Boolean(isYittySession || isYittyCustomer),
      }
    : null;
  return (
    <GoogleTagManagerSnippet.Push dataLayerName={dataLayerName} data={data} />
  );
}

FableticsTrackingVariables.propTypes = {
  dataLayerName: PropTypes.string,
};

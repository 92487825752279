import React from 'react';

import dynamic from 'next/dynamic';

import { AdminGuard } from '../../../techstyle-shared/react-admin';

import accountsModule, {
  loadLoyaltyDetails,
  loadCustomerDetails,
  loadMembership,
  loadMembershipPeriod,
  loadProfile,
  loadPayments,
} from './accountsModule';
import logger from './logger';

const debug = logger.extend('accountsExtension');

const CustomerDetailsAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './CustomerDetailsAdminPanel')
);
const CustomerEmailAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './CustomerEmailAdminPanel')
);
const MembershipDetailsAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './MembershipDetailsAdminPanel')
);

export default function accountsExtension({
  defaultProfileRequired = true,
  defaultMembershipRequired = true,
  defaultMembershipPeriodRequired = false,
  defaultLoyaltyDetailsRequired = false,
  defaultPaymentsRequired = false,
  globalPrefetchCustomerDetails,
} = {}) {
  return (registry) => ({
    id: 'accounts',
    redux: {
      modules: [accountsModule],
    },
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <AdminGuard>
              <CustomerDetailsAdminPanel />
              <CustomerEmailAdminPanel />
              <MembershipDetailsAdminPanel />
            </AdminGuard>
          </>
        );
      },
    },
    page(options) {
      const {
        membershipRequired = defaultMembershipRequired,
        membershipPeriodRequired = defaultMembershipPeriodRequired,
        profileRequired = defaultProfileRequired,
        loyaltyDetailsRequired = defaultLoyaltyDetailsRequired,
        paymentsRequired = defaultPaymentsRequired,
        prefetchCustomerDetails,
      } = options;

      const getPrefetchCustomerDetails = registry.utils.getMergedArrayOption({
        globalPrefetchCustomerDetails,
        prefetchCustomerDetails,
      });

      return {
        async getInitialProps(ctx) {
          const [prefetchCustomerDetails, meta] =
            await getPrefetchCustomerDetails(ctx);

          if (prefetchCustomerDetails.length) {
            debug(
              'Prefetching %s %s added by %s.',
              prefetchCustomerDetails.length,
              prefetchCustomerDetails.length === 1
                ? 'customer detail'
                : 'customer details',
              meta.sources.join(', ')
            );
          }

          await Promise.all([
            membershipRequired ? ctx.store.dispatch(loadMembership()) : null,
            membershipPeriodRequired
              ? ctx.store.dispatch(loadMembershipPeriod())
              : null,
            profileRequired ? ctx.store.dispatch(loadProfile()) : null,
            paymentsRequired ? ctx.store.dispatch(loadPayments()) : null,
            loyaltyDetailsRequired
              ? ctx.store.dispatch(loadLoyaltyDetails())
              : null,
            prefetchCustomerDetails.length
              ? ctx.store.dispatch(loadCustomerDetails(prefetchCustomerDetails))
              : null,
          ]);
        },
      };
    },
  });
}

import React from 'react';

import { useIntl } from '../../../../techstyle-shared/react-intl';
import type { PathHierarchy } from '../../../../techstyle-shared/react-navigation';

interface DisplayLabelProps {
  label: string | { id: string; defaultMessage: string };
  isResourceBundle: boolean;
  groupCode?: string;
}

const DisplayLabel = ({
  isResourceBundle,
  label,
  groupCode,
}: DisplayLabelProps) => {
  const { formatMessage } = useIntl();
  if (isResourceBundle) {
    return formatMessage({
      id: `${groupCode}.${label}`,
      defaultMessage: label,
    });
  }
  if (typeof label === 'object' && label.id) {
    return formatMessage(label);
  }
  return label;
};

interface NavLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement | HTMLSpanElement> {
  label: string | { id: string; defaultMessage: string };
  currentPathHierarchy: PathHierarchy;
  isResourceBundle: boolean;
  href?: string;
  groupCode?: string;
  tagName?: React.ElementType;
}

export default function NavLink({
  currentPathHierarchy,
  groupCode,
  href,
  isResourceBundle,
  label,
  tagName,
  ...rest
}: NavLinkProps) {
  const Tag = tagName || (href ? 'a' : 'span');

  return (
    <Tag
      {...(href && {
        href,
      })}
      data-path-hierarchy={currentPathHierarchy}
      {...rest}
    >
      <DisplayLabel
        isResourceBundle={isResourceBundle}
        label={label}
        groupCode={groupCode}
      />
    </Tag>
  );
}

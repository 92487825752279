import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';

import Observer from '@researchgate/react-intersection-observer';
import detectPassiveEvents from 'detect-passive-events';
import PropTypes from 'prop-types';
import StickyNode from 'react-stickynode';
import styled, { css } from 'styled-components';

import logger from '../logger';
import StickyProvider from '../StickyProvider';
import useLayoutEffect from '../useLayoutEffect';

if (process.browser) {
  require('intersection-observer');
}

const debug = logger.extend('Sticky');

const defaultAutoHideStyle = css`
  transform: translate3d(0, -100%, 0) !important;
  transition-delay: 750ms;
`;

function getStickyStyles({
  autoHide,
  activeClass,
  autoHideStyle,
  isAutoHiding,
  releasedClass,
  top,
}) {
  if (!autoHide) {
    return null;
  }

  const activeStyle = isAutoHiding ? autoHideStyle : null;
  const topOffset = typeof top === 'number' ? top : 0;

  return css`
    & > .sticky-inner-wrapper {
      transition-property: transform;
      transition-duration: 500ms;
    }

    &.${activeClass} > .sticky-inner-wrapper {
      ${activeStyle};
    }

    &.${releasedClass} > .sticky-inner-wrapper {
      /* When a bottomBoundary is passed, we can potentially be in the RELEASED
         state. The container is no longer fixed position, instead it is pushed
         down from its original position via transform. This does not play well
         with autoHide, which conflicts by pulling the container back up via
         transform. So when using autoHide with bottomBoundary, we force the
         RELEASED state to match the auto-hiding FIXED state. A side effect of
         this is that it will hide a little earlier than it has to (when there
         is not enough room for it to fully show at the bottom of its boundary,
         where RELEASED kicks in). */
      position: fixed !important;
      top: ${topOffset}px !important;
      ${autoHideStyle};
    }
  `;
}

const StyledSticky = styled(StickyNode)`
  ${getStickyStyles};
`;

/**
 * Sticky component with support for autohiding.
 */
const Sticky = React.forwardRef(
  ({ autoHide, autoHideStyle, children, onStateChange, ...rest }, ref) => {
    const stickyContext = useContext(StickyProvider.StickyContext);
    const [isScrollingUp, setScrollingUp] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [onScreen, setOnScreen] = useState(true);
    const [isSticky, setIsSticky] = useState(false);
    const internalRef = useRef();
    const prevOffsetRef = useRef();
    const prevDocHeightRef = useRef();

    const handleFocus = useCallback(() => {
      setIsFocused(true);
    }, []);

    const handleBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleMouseEnter = useCallback(() => {
      setIsHovering(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
      setIsHovering(false);
    }, []);

    if (!ref) {
      ref = internalRef;
    }

    useLayoutEffect(() => {
      if (stickyContext) {
        const getOffsetHeight = () => {
          // FIXME: This is not a public API! Undocumented, `react-stickynode`
          // could change at any time.
          return ref.current.state.top + ref.current.state.height;
        };
        stickyContext.registerSticky(ref, { getOffsetHeight });
        return () => {
          stickyContext.unregisterSticky(ref);
        };
      }
    }, [ref, stickyContext]);

    const handleIntersectionChange = useCallback(
      ({ isIntersecting }) => setOnScreen(isIntersecting),
      []
    );

    const handleStateChange = useCallback(
      (state, ...args) => {
        setIsSticky(state.status === StickyNode.STATUS_FIXED);
        if (stickyContext) {
          stickyContext.updateHeight();
        }
        if (onStateChange) {
          onStateChange(state, ...args);
        }
      },
      [onStateChange, stickyContext]
    );

    useEffect(() => {
      // Only track scrolling when `autoHide` is enabled and the content is sticky.
      if (autoHide && isSticky) {
        const handleScroll = () => {
          const newDocHeight = document.body.clientHeight;
          const newOffset = window.pageYOffset;

          if (
            prevDocHeightRef.current != null &&
            prevOffsetRef.current != null
          ) {
            const deltaDocHeight = prevDocHeightRef.current - newDocHeight;
            const deltaOffset = prevOffsetRef.current - newOffset;

            // Check if this event results from a page height change instead of
            // a user initiated scroll. If the user didn't initiate the scroll,
            // we won't execute what's below.
            if (deltaDocHeight !== deltaOffset) {
              const scrollingUp = newOffset < prevOffsetRef.current;
              setScrollingUp(scrollingUp);
            } else {
              debug(
                'Detected a scroll event that was likely triggered by a page height change; ignoring.'
              );
            }
          }
          prevDocHeightRef.current = newDocHeight;
          prevOffsetRef.current = newOffset;
        };

        const options = detectPassiveEvents.hasSupport
          ? { captive: false, passive: true }
          : false;

        window.addEventListener('scroll', handleScroll, options);

        return () => {
          window.removeEventListener('scroll', handleScroll, options);
        };
      }
    }, [autoHide, isSticky]);

    const isAutoHiding =
      !isScrollingUp && !onScreen && autoHide && !isHovering && !isFocused;

    const autoHideProps = {
      onBlur: handleBlur,
      onFocus: handleFocus,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    };

    return (
      <Observer onChange={handleIntersectionChange} disabled={!autoHide}>
        <StyledSticky
          autoHide={autoHide}
          autoHideStyle={autoHideStyle}
          isAutoHiding={isAutoHiding}
          onStateChange={handleStateChange}
          ref={ref}
          {...rest}
        >
          {typeof children === 'function' ? (
            children(autoHideProps)
          ) : (
            <div {...autoHideProps}>{children}</div>
          )}
        </StyledSticky>
      </Observer>
    );
  }
);

Sticky.displayName = 'Sticky';

Sticky.propTypes = {
  /**
   * Class to apply when sticky state is activated.
   */
  activeClass: PropTypes.string,
  /**
   * If true, the sticky content will hide when scrolling down and re-appear
   * when scroll back up. Otherwise, it will be sticky 100% of the time.
   */
  autoHide: PropTypes.bool,
  /**
   * Styles to apply when auto hiding. It can be anything styled-components
   * supports in its tagged template literals, including strings, objects, and
   * functions.
   */
  autoHideStyle: PropTypes.any,
  /**
   * Content to make sticky.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * Function to call when sticky state changes.
   * See [react-stickynode docs](https://github.com/yahoo/react-stickynode).
   */
  onStateChange: PropTypes.func,
  /**
   * Class to apply when sticky state is released.
   */
  releasedClass: PropTypes.string,
};

Sticky.defaultProps = {
  activeClass: 'active',
  autoHide: false,
  autoHideStyle: defaultAutoHideStyle,
  releasedClass: 'released',
};

Sticky.STATUS_ORIGINAL = StickyNode.STATUS_ORIGINAL; // The default status, locating at the original position.
Sticky.STATUS_RELEASED = StickyNode.STATUS_RELEASED; // The released status, locating at somewhere on document but not default one.
Sticky.STATUS_FIXED = StickyNode.STATUS_FIXED; // The sticky status, locating fixed to the top or the bottom of screen.

export default Sticky;

import useBreakpointSelector from './useBreakpointSelector';

export const InputMethod = {
  TOUCH: 'TOUCH',
  MOUSE: 'MOUSE',
  STYLUS: 'STYLUS',
  CONTROLLER: 'CONTROLLER',
  UNKNOWN: 'UNKNOWN',
};

const breakpoints = [
  {
    type: InputMethod.TOUCH,
    mediaQuery: '(hover: none) and (pointer: coarse)',
  },
  { type: InputMethod.STYLUS, mediaQuery: '(hover: none) and (pointer: fine)' },
  {
    type: InputMethod.CONTROLLER,
    mediaQuery: '(hover: hover) and (pointer: coarse)',
  },
  { type: InputMethod.MOUSE, mediaQuery: '(hover: hover) and (pointer: fine)' },
];

const unknownBreakpoint = { type: InputMethod.UNKNOWN };

export default function usePrimaryInputMethod() {
  const { type } = useBreakpointSelector(breakpoints, unknownBreakpoint);

  // Return an object so we could add more metadata about this in the future,
  // e.g. number of touch points.
  return { type };
}

import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const collapsedWidth = '30px';

function getSidebarSize({ isOpen, width }) {
  if (isOpen) {
    if (typeof width === 'number') {
      width = `${width}px`;
    }
    return width;
  }
  return collapsedWidth;
}

const SidebarColumn = styled.section`
  flex: 0 0 ${getSidebarSize};
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 1px 0 1px rgba(255, 255, 255, 0.8);
  overflow: hidden;
  transition: flex-basis 0.4s cubic-bezier(0.23, 1, 0.32, 1);
`;

const SidebarCollapsible = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  ${({ width }) => ({ width })};
  overflow: hidden;
`;

const SidebarHeader = styled.header`
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 3px 0;
`;

const SidebarTitle = styled.h1`
  margin: 5px 12px 0 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: ${18 / 14};
  text-transform: uppercase;
  opacity: 0.9;
`;

const SidebarToggle = styled.button`
  width: collapsedWidth;
  border: 0;
  border-radius: 3px;
  margin-left: auto;
  padding: 0.5em;
  font-size: 14px;
  line-height: 0.75;
  background: transparent;
  color: ${({ theme }) => theme.colors.mediumGray};
  cursor: pointer;
`;

const ToggleLabel = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${collapsedWidth};
  font-size: 15px;
  font-weight: bold;
  line-height: ${collapsedWidth};
  text-transform: uppercase;
  letter-spacing: ${1 / 16}em;
  white-space: nowrap;
  overflow: visible;
`;

const slideUp = keyframes`
  0% {
    transform: rotate(90deg) translate3d(12px, 8px, 0);
    opacity: 0;
  }
`;

const VerticalText = styled.span`
  display: block;
  position: relative;
  transform: rotate(90deg) translate3d(12px, 0, 0);
  transform-origin: 1px ${collapsedWidth};
  opacity: 0.9;
  animation: 0.5s ${slideUp} ease-out;
`;

const SidebarBody = styled.div`
  flex: 1 1 auto;
  transition: transform 0.2s;
  overflow: auto;

  [aria-hidden='true'] > & {
    transform: translate3d(-${collapsedWidth}, 0, 0);
  }
`;

export default function AdminPanelSidebar({
  title,
  children,
  collapsible = true,
  defaultOpen = true,
  width = 240,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <SidebarColumn
      isOpen={isOpen}
      width={width}
      collapsible={collapsible}
      {...rest}
    >
      <SidebarCollapsible isOpen={isOpen} width={width} aria-hidden={!isOpen}>
        <SidebarHeader>
          <SidebarTitle>{title}</SidebarTitle>
          {collapsible ? (
            <SidebarToggle
              aria-expanded={isOpen}
              isOpen={isOpen}
              onClick={handleToggle}
            >
              {isOpen ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
              {isOpen ? null : (
                <ToggleLabel>
                  <VerticalText>{title}</VerticalText>
                </ToggleLabel>
              )}
            </SidebarToggle>
          ) : null}
        </SidebarHeader>
        <SidebarBody>{children}</SidebarBody>
      </SidebarCollapsible>
    </SidebarColumn>
  );
}

AdminPanelSidebar.propTypes = {
  children: PropTypes.node,
  collapsible: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.number,
};

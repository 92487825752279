import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  addBundleToCart,
  addItemToCart,
  addSubcoMagazineToCart,
  addLoyaltyItemToCart,
  invalidateCart,
  loadCart,
  removeBundleFromCart,
  removeCartLineItem,
  removeProductFromCart,
  updateShippingOption,
  updateVIPCartStatus,
  submitOrder,
  completeOrder,
} from './cartModule';

export default function useCartActions() {
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return bindActionCreators(
      {
        addBundleToCart,
        addItemToCart,
        addSubcoMagazineToCart,
        addLoyaltyItemToCart,
        invalidateCart,
        loadCart,
        removeBundleFromCart,
        removeCartLineItem,
        removeProductFromCart,
        updateShippingOption,
        updateVIPCartStatus,
        submitOrder,
        completeOrder,
      },
      dispatch
    );
  }, [dispatch]);

  return actions;
}

import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { FaPortrait, FaAngleUp, FaAngleDown } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import {
  FormattedMessage,
  useIntl,
} from '../../../../techstyle-shared/react-intl';
import Collapsible from '../Collapsible';
import { useModelImageContext } from '../ModelImageProvider/ModelImageProvider';
import OverflowCarousel from '../OverflowCarousel';
import {
  IndicatorControlButton,
  IndicatorWrapper,
  CellsWrapper,
} from '../OverflowCarousel/OverflowCarousel';
import BasePicture from '../Picture';
import {
  AttributeFieldTypes,
  useProductAttributeFieldType,
  useProductDetailContext,
} from '../ProductDetail';
import BaseRadioButton from '../RadioButton';
import BaseRadioButtonGroup from '../RadioButtonGroup';
import useHasTouch from '../useHasTouch';
import useId from '../useId';
import { getProductImageSources } from '../useProductImages';
import { convertInchesToMeters } from '../utils/conversion';

const BOTTOM_SIZE = 'bottomSize';
const TOP_SIZE = 'topSize';
const BRA_SIZE = 'braSize';

const messages = {
  [BOTTOM_SIZE]: (
    <FormattedMessage id="site_pdp.bottom" defaultMessage="Bottom" />
  ),
  [BRA_SIZE]: <FormattedMessage id="site_pdp.bra" defaultMessage="Bra" />,
  [TOP_SIZE]: <FormattedMessage id="site_pdp.top" defaultMessage="Top" />,
};

const defaultSizesToCheck = [BOTTOM_SIZE, TOP_SIZE, BRA_SIZE];

const modelSizeMap = {
  size_bottom: BOTTOM_SIZE,
  size_bra: BRA_SIZE,
  size_top: TOP_SIZE,
};

const aspectRatio = 1;

const breakpoints = [{ minWidth: 0, pictureWidth: 200 }];

const RadioButtonWrapper = styled.div``;

const RadioButtonGroup = styled(BaseRadioButtonGroup)``;

const CollapsibleCarouselWrapper = styled(Collapsible.Content)`
  ${({ $carouselWrapperStyle }) => $carouselWrapperStyle};
`;

const SmallPicture = styled(BasePicture)`
  width: 100%;
  height: 100%;

  ${({ $pictureStyle }) => $pictureStyle};
`;

const PictureWrapper = styled.span`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
  overflow: hidden;
`;

const checkmarkStyle = css`
  visibility: visible;
`;

const inputStyle = css`
  padding: 0;
`;

const RadioButton = styled(BaseRadioButton).attrs({
  checkmarkStyle,
  inputStyle,
})`
  height: 64px;
  width: 64px;
  overflow: hidden;
  border: 3px solid transparent;
  padding: 0;
  margin: 0;

  input:checked + && {
    border: 3px solid #333;
    ${PictureWrapper} {
      border: 3px solid #fff;
    }
  }

  input:checked:focus + && {
    box-shadow: inset 0 0 0 3px #fff,
      0 0 0 3px ${({ theme }) => theme.colors.focusColor};
  }
`;

const ModelInfoWrapper = styled.div`
  padding: 16px 16px 8px;
  ${({ $modelInfoWrapperStyle }) => $modelInfoWrapperStyle};
`;

const ModelInfo = styled.div`
  font-size: 14px;
  line-height: ${16 / 14};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SeeOtherText = styled.span`
  font-size: 12px;
  line-height: ${16 / 12};
`;

const CarouselWrapper = styled.div`
  ${({ $carouselWrapperStyle }) => $carouselWrapperStyle};
`;

const Wrapper = styled.div`
  ${CellsWrapper} {
    padding-top: 8px;
    padding-bottom: 14px;
  }

  ${IndicatorControlButton} {
    width: 32px;
    height: 32px;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    ${({ indicatorControlStyle }) => indicatorControlStyle};
  }

  ${IndicatorWrapper} {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ indicatorWrapperStyle }) => indicatorWrapperStyle};
  }

  ${ModelInfo} {
    ${({ modelInfoStyle }) => modelInfoStyle};
  }

  ${SeeOtherText} {
    ${({ seeOtherModelsStyle }) => seeOtherModelsStyle};
  }

  ${RadioButton} {
    ${({ radioButtonStyle }) => radioButtonStyle};
  }

  ${RadioButtonGroup} {
    display: flex;
    text-align: center;
  }

  ${RadioButtonWrapper} {
    display: flex;
    flex-direction: column;
    width: 64px;
    margin: 0 6px;
    ${({ radioButtonWrapperStyle }) => radioButtonWrapperStyle};
  }
`;

const SizeLabel = styled.label`
  font-size: 14px;
  line-height: ${16 / 14};

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      font-weight: 700;
    `}
`;

const CollapseIcon = styled(FaAngleDown)`
  font-size: 12px;
`;

const ExpandIcon = styled(FaAngleUp)`
  font-size: 12px;
`;

const PortraitIcon = styled(FaPortrait)`
  margin-right: 8px;
`;

const ProductModelBrowserOpenIndicatorWrapper = styled.span`
  margin-left: 8px;
`;

const getModelSizes = (model = {}, sizesToCheck = defaultSizesToCheck) => {
  const sizes = [];

  if (!model) {
    return sizes;
  }

  sizesToCheck.forEach((singleSize) => {
    if (model[singleSize]) {
      sizes.push([singleSize, model[singleSize]]);
    }
  });

  return sizes;
};

const getModelSizeList = (model = {}, sizesToCheck = defaultSizesToCheck) => {
  const modelSizes = getModelSizes(model, sizesToCheck);

  return modelSizes.map((singleSize) => singleSize[1]).join(', ');
};

function ModelRadioButton({
  images,
  model,
  index,
  isChecked,
  pictureStyle,
  sizesToCheck,
  ...rest
}) {
  const id = useId();
  const modelSizeList = useMemo(() => {
    return getModelSizeList(model, sizesToCheck);
  }, [model, sizesToCheck]);

  return (
    <RadioButtonWrapper {...rest}>
      <RadioButton
        id={id}
        value={String(index)}
        data-autotag="pdp-modal-styledSelect-option"
        checkmarkIcon={
          <PictureWrapper>
            <SmallPicture
              aspectRatio={aspectRatio}
              breakpoints={breakpoints}
              sources={getProductImageSources(images[0])}
              $pictureStyle={pictureStyle}
            />
          </PictureWrapper>
        }
      />
      <SizeLabel htmlFor={id} $isChecked={isChecked}>
        {modelSizeList || (
          <FormattedMessage
            id="site_pdp.alt_model_default_images_label"
            defaultMessage="Default Photos"
          />
        )}
      </SizeLabel>
    </RadioButtonWrapper>
  );
}

ModelRadioButton.propTypes = {
  images: PropTypes.array,
  index: PropTypes.number,
  isChecked: PropTypes.bool,
  model: PropTypes.object,
  pictureStyle: PropTypes.any,
  sizesToCheck: PropTypes.array,
};

function ProductModelBrowserOpenIndicator({
  collapseIcon = <CollapseIcon />,
  expandIcon = <ExpandIcon />,
}) {
  const { isOpen } = Collapsible.useState();

  return (
    <ProductModelBrowserOpenIndicatorWrapper>
      {isOpen ? collapseIcon : expandIcon}
    </ProductModelBrowserOpenIndicatorWrapper>
  );
}

ProductModelBrowserOpenIndicator.propTypes = {
  collapseIcon: PropTypes.node,
  expandIcon: PropTypes.node,
};

const ProductModelBrowser = React.forwardRef(
  (
    {
      carouselWrapperStyle,
      cellsWrapperStyle,
      collapseIcon,
      defaultOpen = true,
      disableWhenNoModelsAvailable = true,
      expandIcon,
      hideIndicatorsWhenUnscrollable = true,
      isCollapsible = true,
      measurementSystem = 'imperial',
      modelInfoWrapperStyle,
      pictureStyle,
      portraitIcon = <PortraitIcon />,
      ...rest
    },
    ref
  ) => {
    const sizeType = useProductAttributeFieldType(AttributeFieldTypes.SIZE);
    const { isBundle, selectionStateByProductIndex } =
      useProductDetailContext();
    const { activeModelIndex, activeModel, modelSets, setActiveModelIndex } =
      useModelImageContext();
    const intl = useIntl();
    const hasMultipleModels = modelSets.length > 1;
    const hasTouch = useHasTouch();
    const showCollapsibleModule = hasMultipleModels && isCollapsible;
    let DynamicCollapsible = 'div';
    let DynamicCollapsibleButton = 'div';
    let DynamicCollapsibleCarousel = CarouselWrapper;
    let collapsibleProps = {};

    if (showCollapsibleModule) {
      DynamicCollapsible = Collapsible;
      DynamicCollapsibleButton = Collapsible.Button;
      DynamicCollapsibleCarousel = CollapsibleCarouselWrapper;
      collapsibleProps = { defaultOpen };
    }

    const handleChange = useCallback(
      (event) => {
        setActiveModelIndex(Number(event.currentTarget.value));
      },
      [setActiveModelIndex]
    );

    const sizesToCheck = useMemo(() => {
      // When an individual item attempt to only display the relevant size.
      if (!isBundle) {
        return modelSizeMap[sizeType]
          ? [modelSizeMap[sizeType]]
          : defaultSizesToCheck;
      }

      // When an outfit attempt to only show the sizes that are in the outfit, if one of the sizes is
      // not one of the default sizes, use all sizes.

      // Use set to prevent duplicates (e.g. when an outfit has multiple `size_bottom`)
      let sizeFields = new Set();

      for (const singleSelectionState of selectionStateByProductIndex) {
        const { attributeFieldTypes } = singleSelectionState;
        const sizeType =
          modelSizeMap[attributeFieldTypes[AttributeFieldTypes.SIZE]];

        if (sizeType) {
          sizeFields.add(sizeType);
        } else {
          sizeFields = new Set(defaultSizesToCheck);
          break;
        }
      }

      return Array.from(sizeFields);
    }, [isBundle, selectionStateByProductIndex, sizeType]);

    const activeModelSizes = useMemo(() => {
      return getModelSizes(activeModel, sizesToCheck);
    }, [activeModel, sizesToCheck]);

    const activeModelHeight = useMemo(() => {
      if (!activeModel?.heightInInches) {
        return null;
      }

      const height = Number(activeModel.heightInInches);
      if (measurementSystem === 'metric') {
        const heightInMeters = convertInchesToMeters(height);

        return `${intl.formatNumber(heightInMeters, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })} m`;
      }
      const feet = Math.floor(height / 12);
      const inches = height % 12;
      return `${feet}'${inches}"`;
    }, [activeModel, intl, measurementSystem]);

    const hasActiveModelInfo = activeModel && activeModelSizes.length;

    if (disableWhenNoModelsAvailable && !hasMultipleModels && !activeModel) {
      return null;
    }

    return (
      <Wrapper {...rest} ref={ref}>
        <DynamicCollapsible {...collapsibleProps}>
          <ModelInfoWrapper $modelInfoWrapperStyle={modelInfoWrapperStyle}>
            <DynamicCollapsibleButton data-autotag="pdp-modal-styledSelect-list">
              <ModelInfo>
                {portraitIcon}

                {hasActiveModelInfo ? (
                  <span>
                    <span>{activeModel.firstName}</span>
                    {(activeModelHeight || activeModelSizes.length) && ' - '}
                    {activeModelHeight && `${activeModelHeight} `}
                    {activeModelSizes.map(
                      ([sizeType, size], index, { length }) => (
                        <span key={sizeType}>
                          {messages[sizeType]}: {size}
                          {length - 1 > index && ', '}
                        </span>
                      )
                    )}
                  </span>
                ) : (
                  <FormattedMessage
                    id="site_pdp.alt_model_default_images_label"
                    defaultMessage="Default Photos"
                  />
                )}
                {showCollapsibleModule && (
                  <ProductModelBrowserOpenIndicator
                    expandIcon={expandIcon}
                    collapseIcon={collapseIcon}
                  />
                )}
              </ModelInfo>
            </DynamicCollapsibleButton>
            {hasMultipleModels && (
              <SeeOtherText>
                <FormattedMessage
                  id="site_pdp.alt_model_default_message"
                  defaultMessage="See how it fits on other sizes"
                />
              </SeeOtherText>
            )}
          </ModelInfoWrapper>
          {hasMultipleModels && (
            <DynamicCollapsibleCarousel
              mountOnEnter
              unmountOnExit
              $carouselWrapperStyle={carouselWrapperStyle}
            >
              <OverflowCarousel
                cellsWrapperStyle={cellsWrapperStyle}
                hideIndicators={hasTouch}
                hideIndicatorsWhenUnscrollable={hideIndicatorsWhenUnscrollable}
              >
                <RadioButtonGroup
                  onChange={handleChange}
                  value={String(activeModelIndex)}
                >
                  {modelSets.map(({ images, model }, index) => (
                    <ModelRadioButton
                      key={index}
                      images={images}
                      model={model}
                      index={index}
                      pictureStyle={pictureStyle}
                      isChecked={index === activeModelIndex}
                      sizesToCheck={sizesToCheck}
                    />
                  ))}
                </RadioButtonGroup>
              </OverflowCarousel>
            </DynamicCollapsibleCarousel>
          )}
        </DynamicCollapsible>
      </Wrapper>
    );
  }
);

ProductModelBrowser.displayName = 'ProductModelBrowser';

ProductModelBrowser.propTypes = {
  carouselWrapperStyle: PropTypes.any,
  cellsWrapperStyle: PropTypes.any,
  collapseIcon: PropTypes.node,
  defaultOpen: PropTypes.bool,
  disableWhenNoModelsAvailable: PropTypes.bool,
  expandIcon: PropTypes.node,
  hideIndicatorsWhenUnscrollable: PropTypes.bool,
  indicatorControlStyle: PropTypes.any,
  indicatorWrapperStyle: PropTypes.any,
  isCollapsible: PropTypes.bool,
  measurementSystem: PropTypes.oneOf(['imperial', 'metric']),
  modelInfoStyle: PropTypes.any,
  modelInfoWrapperStyle: PropTypes.any,
  pictureStyle: PropTypes.any,
  portraitIcon: PropTypes.node,
  radioButtonStyle: PropTypes.any,
  radioButtonWrapperStyle: PropTypes.any,
  seeOtherModelsStyle: PropTypes.any,
};

export default ProductModelBrowser;

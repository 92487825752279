import Routes from 'next-routes';

import routeMappings from './src/shared/utils/routeMappings';

const routes = Routes();

routeMappings.forEach(({ aliases, intl, page, pattern, isExternal }) => {
  // this allows us to use the `routeMapping` for localized URL lookups, but prevent
  // nextjs from attempting to handle the route
  if (isExternal) {
    return;
  }
  const paths = [...(intl ? Object.values(intl) : []), ...(aliases || [])];
  const rewrites = pattern ? paths.map((path) => `${path}/${pattern}`) : paths;
  rewrites.forEach((rewrite) => routes.add(rewrite, page));
});

const { Link, Router } = routes;

export default routes;
export { Link, Router };

import React, { useCallback, useState } from 'react';

import {
  FaChevronDown,
  FaChevronUp,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import BaseOverflow, {
  OverflowCanScrollType,
  OverflowIndicatorRefsType,
} from 'react-overflow-indicator';
import styled, { css, CSSProp } from 'styled-components';

type Directions = 'up' | 'down' | 'left' | 'right';
type FlexDirections = 'row' | 'column';
type FlexDirectionProps = {
  $direction: FlexDirections;
};

if (process.browser) {
  // `react-overflow-indicator` depends on IntersectionObserver.
  require('intersection-observer');
  // The buttons below use `scrollBy`, which isn't supported on some browsers.
  require('smoothscroll-polyfill').polyfill();
}

export const CellsWrapper = styled.div<
  FlexDirectionProps & { $cellsWrapperStyle?: CSSProp }
>`
  display: flex;
  flex-direction: ${({ $direction }) => $direction};

  ${({ $cellsWrapperStyle }) => $cellsWrapperStyle};
`;

// `!important` required because the library puts `flex-direction` styles on the element
const Overflow = styled(BaseOverflow)<
  FlexDirectionProps & { $isScrollbarVisible: boolean }
>`
  flex: 1 1 0;
  overflow: auto;
  flex-direction: ${({ $direction }) => $direction} !important;

  [data-overflow-viewport] {
    ${({ $isScrollbarVisible }) =>
      !$isScrollbarVisible &&
      css`
        overflow: hidden !important;
      `}
  }
`;

type IndicatorControlButtonProps = {
  $indicatorControlStyle?: CSSProp;
};

export const IndicatorControlButton = styled.button<IndicatorControlButtonProps>`
  flex: 1 0 auto;
  ${({ $indicatorControlStyle }) => $indicatorControlStyle};
`;

type IndicatorWrapperProps = {
  $canScroll: boolean;
  $indicatorWrapperStyle?: CSSProp;
};

export const IndicatorWrapper = styled.div<IndicatorWrapperProps>`
  ${({ $indicatorWrapperStyle }) => $indicatorWrapperStyle};
`;

type IndicatorControlProps = {
  alwaysShowIndicators?: boolean;
  direction: Directions;
  indicatorArrow?: JSX.Element;
  indicatorControlStyle?: CSSProp;
  indicatorWrapperStyle?: CSSProp;
  scrollByAmount?: number;
};

const overflowIndicatorArrows = {
  up: <FaChevronUp />,
  down: <FaChevronDown />,
  left: <FaChevronLeft />,
  right: <FaChevronRight />,
};

function IndicatorControl({
  alwaysShowIndicators = true,
  direction,
  indicatorArrow = overflowIndicatorArrows[direction],
  indicatorControlStyle,
  indicatorWrapperStyle,
  scrollByAmount,
}: IndicatorControlProps) {
  const handleIndicatorClick = useCallback(
    (refs: OverflowIndicatorRefsType) => {
      if (refs.viewport.current) {
        const { clientHeight, clientWidth } = refs.viewport.current;
        const scrollByWidth = scrollByAmount ?? clientWidth;
        const scrollByHeight = scrollByAmount ?? clientHeight;
        const scrollDirection = {
          up: {
            top: -scrollByHeight,
          },
          down: {
            top: scrollByHeight,
          },
          left: {
            left: -scrollByWidth,
          },
          right: {
            left: scrollByWidth,
          },
        };

        refs.viewport.current.scrollBy({
          ...scrollDirection[direction],
          behavior: 'smooth',
        });
      }
    },
    [direction, scrollByAmount]
  );

  return (
    <Overflow.Indicator direction={direction}>
      {(canScroll, refs) => (
        <IndicatorWrapper
          $indicatorWrapperStyle={indicatorWrapperStyle}
          $canScroll={canScroll}
        >
          {alwaysShowIndicators || canScroll ? (
            <IndicatorControlButton
              disabled={!canScroll}
              onClick={() => handleIndicatorClick(refs)}
              $indicatorControlStyle={indicatorControlStyle}
            >
              {indicatorArrow}
            </IndicatorControlButton>
          ) : null}
        </IndicatorWrapper>
      )}
    </Overflow.Indicator>
  );
}

type OverflowIndicatorArrows = {
  [direction in Directions]: JSX.Element;
};

type OverflowCarouselProps = {
  cellsWrapperStyle?: CSSProp;
  direction?: 'row' | 'column';
  endIndicator?: JSX.Element;
  hideIndicators?: boolean;
  hideIndicatorsWhenUnscrollable?: boolean;
  indicatorArrows?: OverflowIndicatorArrows;
  indicatorControlStyle?: CSSProp;
  isScrollbarVisible?: boolean;
  indicatorWrapperStyle?: CSSProp;
  scrollByAmount?: number;
  startIndicator?: JSX.Element;
};

const directionsMap = {
  row: ['left', 'right'],
  column: ['up', 'down'],
} as const;

function OverflowCarousel({
  children,
  cellsWrapperStyle,
  direction = 'row',
  endIndicator = <IndicatorControl direction={directionsMap[direction][1]} />,
  hideIndicators: hideIndicatorsFromProps = false,
  hideIndicatorsWhenUnscrollable,
  indicatorControlStyle,
  indicatorWrapperStyle,
  isScrollbarVisible = hideIndicatorsFromProps,
  scrollByAmount,
  startIndicator = <IndicatorControl direction={directionsMap[direction][0]} />,
  ...rest
}: React.PropsWithChildren<OverflowCarouselProps>) {
  const [startDirection, endDirection] = directionsMap[direction];
  const [canScroll, setCanScroll] = useState<OverflowCanScrollType>();

  const hideIndicators =
    hideIndicatorsFromProps ||
    (hideIndicatorsWhenUnscrollable &&
      canScroll &&
      !canScroll[startDirection] &&
      !canScroll[endDirection]);

  return (
    <Overflow
      tolerance="1px"
      $isScrollbarVisible={isScrollbarVisible}
      $direction={direction}
      onStateChange={(state) => setCanScroll(state.canScroll)}
      {...rest}
    >
      {!hideIndicators &&
        startIndicator &&
        React.cloneElement(startIndicator, {
          direction: startDirection,
          indicatorControlStyle,
          indicatorWrapperStyle,
          scrollByAmount,
          ...startIndicator.props,
        })}
      <Overflow.Content>
        <CellsWrapper
          $cellsWrapperStyle={cellsWrapperStyle}
          $direction={direction}
        >
          {children}
        </CellsWrapper>
      </Overflow.Content>
      {!hideIndicators &&
        endIndicator &&
        React.cloneElement(endIndicator, {
          direction: endDirection,
          indicatorControlStyle,
          indicatorWrapperStyle,
          scrollByAmount,
          ...endIndicator.props,
        })}
    </Overflow>
  );
}

OverflowCarousel.IndicatorControl = IndicatorControl;

export default OverflowCarousel;

import React, { useContext } from 'react';

export const RoutesContext = React.createContext({
  // If the app isn't using `RoutesProvider`, we'll just spit the input URL
  // back out.
  resolve(href, asPath) {
    return { href, asPath };
  },
});

export default function useRoutes() {
  return useContext(RoutesContext);
}

import { placeSlots } from './slots';

export const ChildType = {
  ITEM: 'ITEM',
  BATCH: 'BATCH',
};

/**
 * Create batches of items based on the given `itemCount` and `targetBatchSize`.
 * Batches will contain at most `targetBatchSize` items (not including slots),
 * unless we have no choice but to place more.
 *
 * Slots are for inserting non-item content into batches at specific row/column
 * positions.
 */
export function createBatches({
  childType = ChildType.ITEM,
  /**
   * If you have an array of items to batch, you can pass that, and `itemCount`
   * and `children` will be populated automatically. If on the other hand you
   * only know the number of items, that's fine too, but `children` will be
   * empty. You'll still have the `itemStartIndex` and `itemStopIndex` of each
   * batch to know which items the batch represents.
   */
  items,
  itemCount = items.length,
  /**
   * Input slot definitions. They will be normalized and laid out (see
   * `createSlots` and `createLayout`), then included in the appropriate
   * batches. It's possible for some slots not to be placed (for example, if
   * there aren't enough columns in the grid to fit them, not enough items to
   * place them without holes, another earlier-placed slot would overlap,
   * etc.).
   */
  slots: inputSlots = [],
  /**
   * Specifies the minimum size a batch can increase by each time more items
   * are added to it; the batch's size will thus always be a multiple of this
   * number (unless there aren't enough items to fill it). For grids, this will
   * be the number of columns in the grid.
   */
  stepSize: columnCount = 1,
  /**
   * The maximum number of items (excluding slots) to fit into a batch under
   * ideal circumstances. It's possible to go over this amount if there are
   * multi-row slots arranged in a particular way such that they can't be broken
   * up.
   */
  targetBatchSize = 60,
  /**
   * If there end up being more than this number of batches, we'll start
   * batching batches into batches! The `targetBatchSize` for those parent
   * batches will be the same as `maxBatchCount`.
   */
  maxBatchCount = 20,
}) {
  // If for some strange reason the consumer requests a `maxBatchCount` of 1,
  // then basically no batching happens; everything should get placed in that
  // one batch.
  if (maxBatchCount === 1) {
    targetBatchSize = itemCount;
  }

  const { slots } = placeSlots({
    itemCount,
    columnCount,
    slots: inputSlots,
  });

  const totalSlotCellCount = slots.reduce(
    (count, slot) => count + slot.cellCount,
    0
  );
  const totalCellCount = itemCount + totalSlotCellCount;

  /**
   * Find slots that start within the given range from `startIndex` to
   * `stopIndex` (they don't necessarily have to also end within this range).
   */
  const findSlotsInRange = ({ startIndex, stopIndex }) => {
    return slots.filter(
      (slot) => slot.cellIndex >= startIndex && slot.cellIndex < stopIndex
    );
  };

  /**
   * Round up the given `stopIndex` to align with the `columnCount`, and make
   * sure it doesn't exceed `totalCellCount`.
   */
  const clampToStepSize = (stopIndex) => {
    const partialRowCellCount = stopIndex % columnCount;
    if (partialRowCellCount > 0) {
      const remainingCellCount = columnCount - partialRowCellCount;
      stopIndex += remainingCellCount;
    }
    stopIndex = Math.min(totalCellCount, stopIndex);
    return stopIndex;
  };

  /**
   * Get the minimum possible batch increase starting from the given
   * `startIndex`, accounting for the `columnCount` and any slots that are
   * placed in the resulting range. (Multi-row slots will require adding more
   * rows, for example, increasing the minimum `stopIndex` further.)
   */
  const getMinBatchIncrease = (startIndex) => {
    const slots = [];
    let stopIndex = clampToStepSize(startIndex + columnCount);
    let prevStopIndex = startIndex;
    while (true) {
      const newSlots = findSlotsInRange({
        startIndex: prevStopIndex,
        stopIndex,
      });
      if (newSlots.length) {
        slots.push(...newSlots);
        const minStopIndex = Math.max(
          ...newSlots.map((slot) => slot.lastCellIndex + 1)
        );
        const nextStopIndex = clampToStepSize(minStopIndex);
        if (nextStopIndex > stopIndex) {
          prevStopIndex = stopIndex;
          stopIndex = nextStopIndex;
        } else {
          break;
        }
      } else {
        break;
      }
    }
    const slotCellCount = slots.reduce(
      (count, slot) => count + slot.cellCount,
      0
    );
    const itemCount = stopIndex - startIndex - slotCellCount;
    return { stopIndex, slots, slotCellCount, itemCount };
  };

  // There are different indexes to keep track of, and it's important that
  // we not mix them together, otherwise stuff could appear in the wrong spot,
  // be skipped, or be duplicated. Here is some terminology used in this code:
  //
  // - "Item index" refers to the index of an item in the original `items` array
  //   not including slots. Items only ever occupy one cell space. In a product
  //   grid, the items are products.
  // - "Cell index" refers to the index of a cell in the grid. Some elements
  //   (like slots) can consume more than one cell (2x1, 2x2, etc.) so even
  //   though that element only occupies one spot in the overall set of
  //   elements, it occupies multiple cell spaces. It is generally the most
  //   useful to build the batches based on cell index, because the grid has a
  //   certain number of columns (cells across) that we must align to, so
  //   tracking the number of cells is required. So, `startIndex` and
  //   `stopIndex` below are cell indexes.
  // - "Element index" refers to the index of either an item or a slot in the
  //   final merged array. Slots will be interleaved with items, so an item's
  //   original item index can be offset (whereupon it becomes an element
  //   index).
  //
  // If there were no slots, then cell index, item index, and element index
  // would all be the same. But with slots present, they push items around, and
  // each one can potentially occupy more than one cell. For example, if there
  // are 3 items and a 2x1 slot after the first item, the final set of elements
  // would look like:
  //   - first item (cell index: 0, item index: 0, element index: 0)
  //   - 2x1 slot (cell index: 1, item index: N/A, element index: 1)
  //   - second item (cell index: 3, item index: 1, element index: 2)
  //   - third item (cell index: 4, item index: 2, element index: 3)
  // Note how the 2x1 slot has no item index, because it's not an item, and the
  // second item's cell index has increased by 2 instead of 1, because the 2x1
  // slot occupies 2 cells.

  let batches = [];
  let startIndex = 0; // It's a cell index (see above).
  // The resulting batch will have both a `startIndex/stopIndex` (cell indexes)
  // and an `itemStartIndex/itemStopIndex` (so we can grab the necessary items
  // from the items array). The difference between these indexes is how many
  // slot cells have been placed so far.
  let placedSlotCellCount = 0;
  while (startIndex < totalCellCount) {
    // It's possible that even creating the smallest possible batch would still
    // exceed the `targetBatchSize` (if multi-row slots are arranged in a
    // particular way so they can't be broken up). But we have to keep creating
    // batches until there are no more items left. Start with creating the
    // smallest possible batch...
    let {
      stopIndex,
      itemCount: batchItemCount,
      slotCellCount,
      slots: slotsInBatch,
    } = getMinBatchIncrease(startIndex);

    // ...then keep extending the batch, ensuring that the `batchItemCount`
    // doesn't exceed the `targetBatchSize`.
    while (batchItemCount < targetBatchSize && stopIndex < totalCellCount) {
      const nextIncrease = getMinBatchIncrease(stopIndex);
      if (batchItemCount + nextIncrease.itemCount <= targetBatchSize) {
        stopIndex = nextIncrease.stopIndex;
        batchItemCount += nextIncrease.itemCount;
        slotCellCount += nextIncrease.slotCellCount;
        slotsInBatch.push(...nextIncrease.slots);
      } else {
        // The attempted increase would exceed the `targetBatchSize`.
        break;
      }
    }

    // The batch's start index in the item array will be the same as the cell
    // index but offset by the number of cells occupied by slots so far.
    let itemStartIndex =
      childType === ChildType.BATCH
        ? // When batching batches however, reducing itemStartIndex
          // implies a total of placedSlotCellCount items would be skipped.
          startIndex
        : startIndex - placedSlotCellCount;
    // The item stop index will be the same as above except must also exclude
    // any slot cells placed in this batch.
    let itemStopIndex =
      childType === ChildType.BATCH
        ? // Same as itemStartIndex, no need to consider placed slots
          // when batching batches.
          stopIndex
        : stopIndex - placedSlotCellCount - slotCellCount;

    const children = items ? items.slice(itemStartIndex, itemStopIndex) : null;

    let cellCount = stopIndex - startIndex;
    // When batching batches, the parent batch's `itemStartIndex`,
    // `itemStopIndex`, `cellCount`, and `slotCellCount` will reflect the
    // contents of the child batches' items/cells, not the list of batches.
    if (childType === ChildType.BATCH) {
      itemStartIndex = children[0].itemStartIndex;
      itemStopIndex = children[children.length - 1].itemStopIndex;
      cellCount = children.reduce((count, child) => count + child.cellCount, 0);
      slotCellCount = children.reduce(
        (count, child) => count + child.slotCellCount,
        0
      );
    }

    const batch = {
      childType,
      children,
      startIndex,
      stopIndex,
      itemStartIndex,
      itemStopIndex,
      cellCount,
      itemCount: itemStopIndex - itemStartIndex,
      slotCellCount,
      slots: slotsInBatch,
    };
    batches.push(batch);
    startIndex = stopIndex;
    placedSlotCellCount += slotCellCount;
  }

  if (maxBatchCount != null && batches.length > maxBatchCount) {
    // There are too many batches. Batch the batches!
    batches = createBatches({
      childType: ChildType.BATCH,
      items: batches,
      stepSize: 1,
      targetBatchSize: maxBatchCount,
      maxBatchCount,
    });
  }

  return batches;
}

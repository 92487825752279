export type Action<Payload = any, Meta = any> = {
  type: string;
  payload: Payload;
  error?: boolean;
  meta?: Meta;
};

export type ActionCreator<Payload = any, Meta = any> = {
  (payload: Payload, meta: Meta): Action<Payload, Meta>;
  toString: () => string;
  type: string;
};

/**
 * Return a function that can be used to create action objects with the given
 * `type`. The second argument `enhanceAction` can be used for further
 * customization of the created action object; it should either modify the
 * action it receives or return a new one.

 * The first argument of the action creator is the `payload`. If the
 * payload is an `Error` instance, the action will also have an `error` flag
 * set to true. The second argument is the `meta` property, which can be used
 * to store additional information about the action.
 *
 * The action creator will have a custom `toString` method that returns the
 * given `type` as a string. This allows it to be used directly in places that
 * expect an action type like `createReducer`.
 */
export default function createAction<Payload = any, Meta = any>(
  type: string,
  enhanceAction?: (baseAction: Action<Payload, Meta>) => Action<Payload, Meta>
): ActionCreator<Payload, Meta> {
  function actionCreator(payload: Payload, meta: Meta) {
    const baseAction: Action<Payload, Meta> = { type, payload };
    if (payload instanceof Error) {
      baseAction.error = true;
    }
    if (meta) {
      baseAction.meta = meta;
    }
    const action = enhanceAction ? enhanceAction(baseAction) : baseAction;
    return action || baseAction;
  }

  actionCreator.toString = () => `${type}`;
  actionCreator.type = type;
  return actionCreator;
}

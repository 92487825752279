import { differenceInCalendarDays } from 'date-fns';

import { parseDate } from '../../../techstyle-shared/redux-core';

import { SkipTheMonthStatus } from './constants';
import logger from './logger';

const debug = logger.extend('getDerivedSkipTheMonthStatus');

export default function getDerivedSkipTheMonthStatus({
  forceDateDue,
  dateDue,
  forceSkipAllowed,
  skipAllowed,
  statusCode,
  getCurrentDate,
}) {
  let isBogus = false; // Whether anything seems amiss.
  let hasSkipped = false;

  if (statusCode != null) {
    switch (statusCode) {
      case SkipTheMonthStatus.PENDING:
      case SkipTheMonthStatus.CANCELLED:
      case SkipTheMonthStatus.ERROR_DURING_PROCESSING:
      case SkipTheMonthStatus.MARKED_FOR_CREDIT:
      case SkipTheMonthStatus.CREDIT_FAILED:
      case SkipTheMonthStatus.CREDIT_PENDING:
      case SkipTheMonthStatus.CREDITED:
      case SkipTheMonthStatus.PURCHASED:
      case SkipTheMonthStatus.EMP_BILLED:
      case SkipTheMonthStatus.EMP_BILLED_DOWNGRADED_IN_CYCLE:
      case SkipTheMonthStatus.EMP_BILLING_FAILED_HARD:
      case SkipTheMonthStatus.EMP_BILLING_FAILED_PERMANENT:
      case SkipTheMonthStatus.EMP_BILLING_FAILED_SOFT:
      case SkipTheMonthStatus.EMP_CANCELLED:
      case SkipTheMonthStatus.EMP_ERROR_DURING_PROCESSING:
      case SkipTheMonthStatus.EMP_MARKED_FOR_PROCESSING:
      case SkipTheMonthStatus.EMP_PENDING:
      case SkipTheMonthStatus.EMP_PROCESSING:
        break;
      case SkipTheMonthStatus.SKIPPED:
      case SkipTheMonthStatus.EMP_SKIPPED:
        hasSkipped = true;
        break;
      default:
        isBogus = true;
        debug(
          '%c!%c Encountered invalid skip the month status! statusCode: %o',
          'color: red',
          '',
          statusCode
        );
    }
  }

  const getDaysLeftToSkip = () => {
    // Check if we have forceDateDue, otherwise use state's dateDue
    const date = forceDateDue || dateDue;
    if (date == null) {
      return null;
    }

    return differenceInCalendarDays(
      parseDate(date),
      parseDate(getCurrentDate())
    );
  };

  if (!forceSkipAllowed && hasSkipped && skipAllowed) {
    debug(
      '%c!%c Inconsistent skip the month status! statusCode: %o skipAllowed: %o',
      'color: red',
      '',
      statusCode,
      skipAllowed
    );
  }

  return {
    isBogus,
    hasSkipped: forceSkipAllowed ? false : hasSkipped,
    skipAllowed: forceSkipAllowed != null ? forceSkipAllowed : skipAllowed,
    dateDue: forceDateDue || dateDue,
    getDaysLeftToSkip,
  };
}

import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';

import Script from '../Script';

export default function SentrySnippet({ isSentryInitRequired = true }) {
  const isSentrySharedSdk =
    config.has('server.sentry.useSharedSdk') &&
    config.get('server.sentry.useSharedSdk');

  const ignoreErrors = config.has('server.sentry.ignoreErrors')
    ? config.get('server.sentry.ignoreErrors')
    : [];

  if (!isSentrySharedSdk) {
    isSentryInitRequired = isSentrySharedSdk;
  }

  const sentryTracesSampleRate = parseFloat(
    process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE
  );
  const sentrySampleRate = parseFloat(
    process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE
  );
  return (
    <>
      <Script
        globalScriptKey={['sentry', 'sdk']}
        src="https://browser.sentry-cdn.com/7.91.0/bundle.min.js"
        integrity="sha384-EWe7pAKivyOSR2S5qZMe7OTV+EXB30wM9PkDUwFBotJpHNnqWjtYlQZiyUrzXtkY"
        crossOrigin="anonymous"
      />
      <Script
        globalScriptKey={['sentry', 'performance-bundle']}
        src="https://browser.sentry-cdn.com/7.91.0/bundle.tracing.min.js"
        integrity="sha384-mLp8eX2NZEYz/yYlB3hz0DAxA9qX7yneHI9PPB9DfnLCo4Z+yC1XQzP9D7b9oqS2"
        crossOrigin="anonymous"
      />
      <Script
        globalScriptKey={['sentry', 'rewrite-frames']}
        src="https://browser.sentry-cdn.com/7.91.0/rewriteframes.min.js"
        integrity="sha384-YVCofJrSkJ+4szVOiYhaoa7FDzQ6JfxuQhlH4IRkaKFh/hAxOiV7OnNxdcwhV4r7"
        crossOrigin="anonymous"
      />
      {isSentryInitRequired && (
        <Script
          globalScriptKey={['sentry', 'init']}
          dangerouslySetInnerHTML={{
            __html: `
            Sentry.init({
              dsn: ${serialize(config.get('public.sentry.dsn'))},
              ignoreErrors: ${JSON.stringify(ignoreErrors)},
              integrations: [new Sentry.Integrations.BrowserTracing(), new Sentry.Integrations.RewriteFrames({ prefix: '~/' })],
              tracesSampleRate: ${
                !isNaN(sentryTracesSampleRate)
                  ? sentryTracesSampleRate
                  : serialize(config.get('public.sentry.tracesSampleRate'))
              },
              sampleRate: ${
                !isNaN(sentrySampleRate)
                  ? sentrySampleRate
                  : serialize(config.get('public.sentry.sampleRate'))
              },
              environment: ${serialize(
                config.get('public.sentry.environment')
              )},
              allowUrls: ${serialize(config.get('public.sentry.allowUrls'))},
              ignoreErrors: ${serialize(
                config.get('public.sentry.ignoreErrors')
              )},
              release: ${serialize(process.env.NEXT_PUBLIC_SENTRY_RELEASE)},
            });`,
          }}
        />
      )}
    </>
  );
}

SentrySnippet.propTypes = {
  /**
   * Whether to initialize the Sentry SDK or not, defaults to true.
   */
  isSentryInitRequired: PropTypes.bool,
};

import { useEffect, useState } from 'react';

import {
  upToDateStatus,
  outOfDateStatus,
} from '../../../techstyle-shared/redux-core';

import useAccountActions from './useAccountActions';

const initialState = {
  reviewableProducts: null,
  networkStatus: outOfDateStatus(),
};

export default function useReviewableProducts() {
  const actions = useAccountActions();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const load = async () => {
      const result = await actions.loadReviewableProducts();
      setState({
        reviewableProducts: result.payload,
        networkStatus: upToDateStatus(),
      });
    };
    load();
  }, [actions, setState]);

  return state;
}

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { HTMLAsset } from '../../../../techstyle-shared/react-assets';
import { useCarouselContext } from '../CarouselContext';
import { useProductContext } from '../ProductContext';
import { useProductPromoAsset } from '../useProductPromoAsset';

// TODO: fix errors here
const ProductPromoMessage = React.forwardRef(
  (
    {
      as: ElementType = 'div',
      assetContainerName,
      product: productFromProps,
      filter,
      ...rest
    },
    ref
  ) => {
    const carousel = useCarouselContext();
    const productFromContext = useProductContext();
    const product = productFromProps || productFromContext;

    const assetContainer = useProductPromoAsset(assetContainerName, {
      product,
      filter,
    });

    useEffect(() => {
      if (carousel && assetContainer.data) {
        carousel.notifyImageLoaded();
      }
    }, [assetContainer.data, carousel]);

    if (!assetContainer.data || !assetContainer.data.assets[0]) {
      return null;
    }

    return (
      <ElementType data-autotag="grid-product-promo-msg" ref={ref} {...rest}>
        <HTMLAsset asset={assetContainer.data.assets[0]} />
      </ElementType>
    );
  }
);

ProductPromoMessage.displayName = 'ProductPromoMessage';
ProductPromoMessage.propTypes = {
  /**
   * Element type to render. Defaults to a div.
   */
  as: PropTypes.elementType,
  /**
   * The asset container name. If no name is passed, the component
   * will not render.
   */
  assetContainerName: PropTypes.string,
  /**
   * An asset [array] filter function for custom filtering
   */
  filter: PropTypes.func,
  /**
   * Product. If none is passed, it will attempt to retrieve from
   * ProductContext.
   */
  product: PropTypes.object,
};

export default ProductPromoMessage;

import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setForceAllTestsControl,
  forceTestCohort,
  forceAllTestsToControl,
  splitTest,
  loadAllTests,
  loadActivatedTests,
} from './abTestModule';

export default function useABTestActions() {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          setForceAllTestsControl,
          forceTestCohort,
          forceAllTestsToControl,
          splitTest,
          loadAllTests,
          loadActivatedTests,
        },
        dispatch
      ),
    [dispatch]
  );

  return actions;
}

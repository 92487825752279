import React from 'react';

import { defineMessages, FormattedMessage, useIntl } from '../../../react-intl';
import slugify from '../utils/slugify';

import { LabelType } from './constants';
import useCategoryLabels from './useCategoryLabels';

const DynamicFormattedMessage = FormattedMessage;

const filterFieldLabels = defineMessages({
  // FIXME: The default `slugify` function handles this poorly because it
  // doesn't account for camel casing, so it outputs `avgreviewmin`. We should
  // update `slugify` in the next MAJOR release since it would be a breaking
  // change.
  avgReviewMin: {
    id: 'site_product_filter.avg_review_min',
    defaultMessage: 'Avg. Rating',
  },
  sort: {
    id: 'site_product_grid.sort_label',
    defaultMessage: 'Sort',
  },
});

/**
 * Filter Field Label Renderers
 */

function getFilterFieldMessage(filterField, labelOptions = {}) {
  const { label, field } = filterField;

  return (
    filterFieldLabels[field] || {
      id: `site_product_filter.${slugify(field)}`,
      defaultMessage: label,
    }
  );
}

export default function useFilterFieldLabels() {
  const intl = useIntl();
  const categoryLabels = useCategoryLabels();

  return {
    // eslint-disable-next-line react/display-name
    renderLabel: (filterField, labelOptions = {}) => {
      const { labelType } = labelOptions;

      if (labelType === LabelType.CATEGORY_FILTER) {
        const { category } = filterField;
        return (
          <FormattedMessage
            id="site_product_filter.category_style"
            defaultMessage="{category} Style"
            values={{
              category: categoryLabels.renderLabel(category, labelOptions),
            }}
          />
        );
      }

      return (
        <DynamicFormattedMessage
          {...getFilterFieldMessage(filterField, labelOptions)}
        />
      );
    },
    renderLabelString: (filterField, labelOptions = {}) => {
      const { labelType } = labelOptions;

      if (labelType === LabelType.CATEGORY_FILTER) {
        const { category } = filterField;
        return intl.formatMessage(
          {
            id: 'site_product_filter.category_style',
            defaultMessage: '{category} Style',
          },
          {
            category: categoryLabels.renderLabelString(category, labelOptions),
          }
        );
      }

      return intl.formatMessage(
        getFilterFieldMessage(filterField, labelOptions)
      );
    },
  };
}

export const isBuilderPreview = (req) => {
  const allowSameSiteSessionCookieParams = ['builder.space', 'builder.preview'];
  if (req?.query) {
    const paramNotFound = allowSameSiteSessionCookieParams.find(
      (value) => !req.query[value]
    );
    if (!paramNotFound) {
      return true;
    }
  }
  return false;
};

import { useCallback, useEffect, useReducer, useRef } from 'react';

import config from 'config';

import {
  mergeNetworkStatus,
  outOfDateStatus,
  upToDateStatus,
  NetworkStatus,
} from '../../../techstyle-shared/redux-core';

import { ShippingStatusFilter } from './accountsModuleTyped';
import { OrdersReducerAction } from './constants';
import { OrderHistoryDto } from './types';
import useAccountActions from './useAccountActions';

const initialState = {
  pages: {},
  pageCount: null,
  totalRecords: null,
  networkStatus: outOfDateStatus(),
};

const initialPageState = {
  orders: [],
  networkStatus: outOfDateStatus(),
  error: null,
};

type OrdersPage = {
  error?: null;
  networkStatus: NetworkStatus;
  orders?: OrderHistoryDto[];
  page?: number;
};

type OrdersState = {
  pageCount: null | number;
  totalRecords: null | number;
  pages: Record<string | number, OrdersPage>;
  networkStatus: NetworkStatus;
};

const createInitialPageState = (state: Partial<OrdersPage>) => {
  return {
    ...initialPageState,
    ...state,
  };
};

type ActionType =
  | {
      type: typeof OrdersReducerAction.REQUEST;
      payload: {
        page: number;
        pageSize: number;
      };
    }
  | {
      type: typeof OrdersReducerAction.SUCCESS;
      payload: {
        page: number;
        pageSize: number;
        totalRecords: number;
        orders: [OrderHistoryDto[]] | [];
      };
    }
  | {
      type: typeof OrdersReducerAction.RESET;
      payload: {
        page: number;
        pageSize: number;
      };
    }
  | {
      type: typeof OrdersReducerAction.ERROR;
      payload: {
        page: number;
        pageSize: number;
      };
    }
  | {
      type: typeof OrdersReducerAction.CANCEL;
      payload: {
        page: number;
        pageSize: number;
      };
    };

const ordersReducer = (state: OrdersState, action: ActionType): OrdersState => {
  const { page } = action.payload || {};
  switch (action.type) {
    case OrdersReducerAction.REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.page]: createInitialPageState({
            networkStatus: {
              ...state.networkStatus,
              isLoading: true,
            },
          }),
        },
      };
    case OrdersReducerAction.SUCCESS:
      return {
        ...state,
        pageCount: Math.ceil(
          action.payload.totalRecords / action.payload.pageSize
        ),
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            page,
            orders: action.payload.orders[0] ?? [],
            error: null,
            networkStatus: upToDateStatus(),
          },
        },
        totalRecords: action.payload.totalRecords,
      };
    case OrdersReducerAction.RESET:
      return initialState;
    case OrdersReducerAction.ERROR:
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            networkStatus: outOfDateStatus(),
          },
        },
      };
    case OrdersReducerAction.CANCEL:
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            error: null,
            networkStatus: outOfDateStatus(),
          },
        },
      };
    default:
      return state;
  }
};

type OrdersOptions = {
  orderStatusFilter?: ShippingStatusFilter;
  excludeOrderTypeIds?: Array<number>;
  dateExpectedMin?: Date;
  dateExpectedMax?: Date;
  page: number;
};

export default function useOrders({
  page = 1,
  orderStatusFilter,
  excludeOrderTypeIds,
  dateExpectedMin,
  dateExpectedMax,
}: OrdersOptions) {
  const accountActions = useAccountActions();
  const [state, dispatch] = useReducer(ordersReducer, initialState);
  const pageSize: number = config.get('public.orders.pageSize');
  const firstRun = useRef(true);

  const currentPage = state.pages[page];
  const needCurrentPage =
    !currentPage ||
    (!currentPage.networkStatus.isUpToDate &&
      !currentPage.networkStatus.isLoading);

  const load = useCallback(async () => {
    try {
      dispatch({
        type: OrdersReducerAction.REQUEST,
        payload: { page, pageSize },
      });
      const result = await accountActions.loadOrders({
        page,
        pageSize,
        orderStatusFilter,
        excludeOrderTypeIds,
        dateExpectedMin,
        dateExpectedMax,
      });

      if (result.payload) {
        dispatch({
          type: OrdersReducerAction.SUCCESS,
          payload: { ...result.payload, page, pageSize },
        });
      }
    } catch (err) {
      dispatch({
        type: OrdersReducerAction.ERROR,
        payload: { page, pageSize },
      });
    }
  }, [
    page,
    pageSize,
    accountActions,
    orderStatusFilter,
    excludeOrderTypeIds,
    dateExpectedMax,
    dateExpectedMin,
  ]);

  useEffect(() => {
    if (needCurrentPage) {
      load();
    }
  }, [load, needCurrentPage]);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    dispatch({
      type: OrdersReducerAction.RESET,
      payload: { page, pageSize },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderStatusFilter,
    excludeOrderTypeIds,
    dateExpectedMax,
    dateExpectedMin,
  ]);

  const loadedOrders = Object.values(state.pages).reduce(
    (previous: OrderHistoryDto[], current) => {
      if (current.orders) {
        return previous.concat(current.orders);
      }
      return previous;
    },
    []
  );

  return {
    loadedOrders,
    networkStatus: mergeNetworkStatus(
      Object.values(state.pages).map((page) => page.networkStatus)
    ),
    orders: state,
    page:
      currentPage ||
      createInitialPageState({
        page,
      }),
    retry: load,
  };
}

import { useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import { SecondarySites } from '../constants/secondarySites';

export function getSecondarySiteBuilderModelQuery(secondarySite) {
  return secondarySite
    ? { $eq: secondarySite }
    : { $ne: Object.values(SecondarySites) };
}

export function getSecondarySite(path) {
  const siteValues = Object.values(SecondarySites);
  for (const siteValue of siteValues) {
    const regex = new RegExp(`^/${siteValue}(?:/|\\?|$)`);
    if (regex.test(path)) {
      return siteValue;
    }
  }
  return null;
}

export default function useSecondarySite() {
  const [secondarySite, setSecondarySite] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const secondarySite = getSecondarySite(router.asPath);
    if (secondarySite) {
      setSecondarySite(secondarySite);
    }
  }, [router.asPath]);
  return secondarySite;
}

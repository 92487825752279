import config from 'config';
import { addDays, differenceInCalendarDays } from 'date-fns';
import loadScript from 'little-loader';
import Router from 'next/router';

import { MasterProduct } from '../../../techstyle-shared/react-products';
import {
  createAction,
  createReducer,
  createCachedSelector,
  createSelector,
  loadSession,
  loadSessionSuccess,
  invalidate,
  invalidateOnCustomerChange,
  startLoading,
  stopLoading,
  upToDateStatus,
  outOfDateStatus,
  parseDate,
  getNewDateFunction,
} from '../../../techstyle-shared/redux-core';

import {
  orderDetailsReducer,
  initialOrderDetailsState,
  loadOrderDetailsRequest,
  loadOrderDetailsSuccess,
  loadOrderDetailsFailure,
  invalidateOrderDetailsAction,
  invalidateOrderDetails,
  getOrderById,
  loadOrderDetails,
  loadOrdersRequest,
  loadOrdersSuccess,
  loadOrdersFailure,
  loadOrders,
  initialReturnSettingsState,
  loadReturnSettingsRequest,
  loadReturnSettingsSuccess,
  loadReturnSettingsFailure,
  loadReturnSettings,
  initiateReturn,
  initiateReturnRequest,
  initiateReturnSuccess,
  initiateReturnFailure,
  returnSettingsReducer,
  loadRMADetailsRequest,
  loadRMADetailsSuccess,
  loadRMADetailsFailure,
  loadRMADetails,
  ShippingStatusFilter,
  loadLoyaltyHistoryRequest,
  loadLoyaltyHistorySuccess,
  loadLoyaltyHistoryFailure,
  loadLoyaltyHistory,
} from './accountsModuleTyped';
import {
  SkipReason,
  MembershipUpdateStatus,
  CancelReason,
  CcpaRequestSource,
  WaitlistType,
} from './constants';
import getDerivedMembershipValues, {
  MembershipStatusCode,
} from './getDerivedMembershipValues';
import getDerivedSkipTheMonthStatus from './getDerivedSkipTheMonthStatus';
import getPostRegDaysFunction from './getPostRegDaysFunction';
import getPostRegHoursFunction from './getPostRegHoursFunction';
import logger from './logger';
import {
  PaymentMethod,
  CustomerDetail,
  CustomerDetailError,
  MembershipDetailError,
  RetailStoresError,
  ForeignMembershipError,
} from './schema';
import { getISOBirthDateString } from './utils';

export {
  orderDetailsReducer,
  initialOrderDetailsState,
  loadOrderDetails,
  loadOrderDetailsRequest,
  loadOrderDetailsSuccess,
  loadOrderDetailsFailure,
  invalidateOrderDetailsAction,
  invalidateOrderDetails,
  loadOrdersRequest,
  loadOrdersSuccess,
  loadOrdersFailure,
  loadOrders,
  getOrderById,
  initialReturnSettingsState,
  loadReturnSettingsRequest,
  loadReturnSettingsSuccess,
  loadReturnSettingsFailure,
  loadReturnSettings,
  initiateReturn,
  initiateReturnRequest,
  initiateReturnSuccess,
  initiateReturnFailure,
  loadRMADetailsRequest,
  loadRMADetailsSuccess,
  loadRMADetailsFailure,
  loadRMADetails,
  ShippingStatusFilter,
  loadLoyaltyHistoryRequest,
  loadLoyaltyHistorySuccess,
  loadLoyaltyHistoryFailure,
  loadLoyaltyHistory,
  MembershipStatusCode,
};

const debug = logger.extend('accountsModule');

const initialState = {
  addresses: {
    addresses: [],
    networkStatus: upToDateStatus(),
  },
  copsSegmentTraits: {
    networkStatus: upToDateStatus(),
  },
  customer: {
    networkStatus: upToDateStatus(),
  },
  customerDetails: {},
  emailPreferences: {
    vars: {},
    networkStatus: upToDateStatus(),
  },
  foreignMembership: {},
  loyalty: {
    redemption: null,
    tier: null,
    networkStatus: outOfDateStatus(),
  },
  loyaltyPointsForActionsProgress: {
    actions: [],
    networkStatus: outOfDateStatus(),
  },
  loyaltyRewardPromos: {
    promos: [],
    networkStatus: outOfDateStatus(),
  },
  loyaltyPlans: {
    tiers: [],
    networkStatus: outOfDateStatus(),
  },
  membership: {
    networkStatus: upToDateStatus(),
  },
  membershipDetails: {},
  membershipPeriod: {
    forceDateDue: null,
    forceSkipAllowed: null,
    networkStatus: upToDateStatus(),
  },
  membershipCancellationReasons: {
    reasons: [],
    networkStatus: outOfDateStatus(),
  },
  orderDetails: initialOrderDetailsState,
  payments: {
    paymentMethods: [],
    networkStatus: upToDateStatus(),
  },
  phoneNumber: {
    phoneNumber: null,
    networkStatus: upToDateStatus(),
  },
  retailStores: {},
  returnSettings: initialReturnSettingsState,
  wishlist: {
    items: [],
    total: 0,
    networkStatus: upToDateStatus(),
  },
  wishlistIds: {
    total: 0,
    masterProductIds: [],
    networkStatus: upToDateStatus(),
  },
  waitlist: {
    items: [],
    total: 0,
    networkStatus: upToDateStatus(),
  },
  waitlistIds: {
    items: [],
    total: 0,
    networkStatus: outOfDateStatus(),
  },
};

export const initMembershipPeriod = () => {
  return (dispatch, getState, context) => {
    if (context.isServer && !context.hasPreloadedState) {
      const skipOverride =
        context.req.query.stmOverride || context.req.query.stm_override || '';

      if (/^(true|1)$/.test(skipOverride)) {
        const todaysDate = getNewDateFunction(getState())();
        const forceDate = addDays(todaysDate, 5);
        dispatch(forceSkipAllowed(true));
        dispatch(forceDateDue(forceDate.toISOString()));
      } else if (skipOverride) {
        const date = parseDate(skipOverride);
        if (!Number.isNaN(date.getTime())) {
          // checking if the date is valid
          const daysLeftToSkip = differenceInCalendarDays(
            date,
            getNewDateFunction(getState())()
          );
          dispatch(forceSkipAllowed(daysLeftToSkip >= 0));
          dispatch(forceDateDue(date.toISOString()));
        }
      }
    }
  };
};

// initForceRegistrationDate is meant to override the registration date associated with a user
// this initialization function is how we account for url params that force reg date
// fired in initialActions
export const initForceRegistrationDate = () => {
  return async (dispatch, getState, context) => {
    // pull server & request off of context
    const { isServer, req: request } = context;
    // confirm we're on the server and there is a request available
    if (isServer && request) {
      // destructure regDate from query params
      const { regDate } = request.query;

      // account for if there is no regDate present
      if (!regDate) {
        return;
      }

      // if there is a regDate, enact override
      await dispatch(forceRegistrationDate(regDate));
    }
  };
};

export const forceDateDue = createAction('accounts/forceDateDue');
export const forceSkipAllowed = createAction('accounts/forceSkipAllowed');

export const signUpRequest = createAction('accounts/signUpRequest');
export const signUpSuccess = createAction('accounts/signUpSuccess');
export const signUpFailure = createAction('accounts/signUpFailure');

export const activateTrialToMembershipRequest = createAction(
  'accounts/activateTrialToMembershipRequest'
);
export const activateTrialToMembershipSuccess = createAction(
  'accounts/activateTrialToMembershipSuccess'
);
export const activateTrialToMembershipFailure = createAction(
  'accounts/activateTrialToMembershipFailure'
);

export const logInRequest = createAction('accounts/logInRequest');
export const logInSuccess = createAction('accounts/logInSuccess');
export const logInFailure = createAction('accounts/logInFailure');

export const logOutRequest = createAction('accounts/logOutRequest');
export const logOutSuccess = createAction('accounts/logOutSuccess');
export const logOutFailure = createAction('accounts/logOutFailure');

export const forgotPasswordRequest = createAction(
  'accounts/forgotPasswordRequest'
);
export const forgotPasswordSuccess = createAction(
  'accounts/forgotPasswordSuccess'
);
export const forgotPasswordFailure = createAction(
  'accounts/forgotPasswordFailure'
);

export const loginMethodsRequest = createAction('accounts/loginMethodsRequest');
export const loginMethodsSuccess = createAction('accounts/loginMethodsSuccess');
export const loginMethodsFailure = createAction('accounts/loginMethodsFailure');

export const loginMethodsFromEmailRequest = createAction(
  'accounts/loginMethodsFromEmailRequest'
);
export const loginMethodsFromEmailSuccess = createAction(
  'accounts/loginMethodsFromEmailSuccess'
);
export const loginMethodsFromEmailFailure = createAction(
  'accounts/loginMethodsFromEmailFailure'
);

export const requestMultifactorPinRequest = createAction(
  'accounts/requestMultifactorPinRequest'
);
export const requestMultifactorPinSuccess = createAction(
  'accounts/requestMultifactorPinSuccess'
);
export const requestMultifactorPinFailure = createAction(
  'accounts/requestMultifactorPinFailure'
);

export const submitMultifactorPinRequest = createAction(
  'accounts/submitMultifactorPinRequest'
);
export const submitMultifactorPinSuccess = createAction(
  'accounts/submitMultifactorPinSuccess'
);
export const submitMultifactorPinFailure = createAction(
  'accounts/submitMultifactorPinFailure'
);

export const resetPasswordRequest = createAction(
  'accounts/resetPasswordRequest'
);
export const resetPasswordSuccess = createAction(
  'accounts/resetPasswordSuccess'
);
export const resetPasswordFailure = createAction(
  'accounts/resetPasswordFailure'
);

export const updatePasswordRequest = createAction(
  'accounts/updatePasswordRequest'
);
export const updatePasswordSuccess = createAction(
  'accounts/updatePasswordSuccess'
);
export const updatePasswordFailure = createAction(
  'accounts/updatePasswordFailure'
);

export const loadProfileRequest = createAction('accounts/loadProfileRequest');
export const loadProfileSuccess = createAction('accounts/loadProfileSuccess');
export const loadProfileFailure = createAction('accounts/loadProfileFailure');

export const invalidateProfile = createAction('accounts/invalidateProfile');

export const loadPhoneNumberRequest = createAction(
  'accounts/loadPhoneNumberRequest'
);
export const loadPhoneNumberSuccess = createAction(
  'accounts/loadPhoneNumberSuccess'
);
export const loadPhoneNumberFailure = createAction(
  'accounts/loadPhoneNumberFailure'
);

export const invalidatePhoneNumber = createAction(
  'accounts/invalidatePhoneNumber'
);

export const loadWishlistRequest = createAction('accounts/loadWishlistRequest');
export const loadWishlistSuccess = createAction('accounts/loadWishlistSuccess');
export const loadWishlistFailure = createAction('accounts/loadWishlistFailure');

export const loadMembershipCreditsRequest = createAction(
  'accounts/loadMembershipCreditsRequest'
);
export const loadMembershipCreditsSuccess = createAction(
  'accounts/loadMembershipCreditsSuccess'
);
export const loadMembershipCreditsFailure = createAction(
  'accounts/loadMembershipCreditsFailure'
);

export const invalidateWishlist = createAction('accounts/invalidateWishlist');

export const loadWishlistIdsRequest = createAction(
  'accounts/loadWishlistIdsRequest'
);
export const loadWishlistIdsSuccess = createAction(
  'accounts/loadWishlistIdsSuccess'
);
export const loadWishlistIdsFailure = createAction(
  'accounts/loadWishlistIdsFailure'
);

export const loadBouncebackEndowmentHistoryRequest = createAction(
  'accounts/loadBouncebackEndowmentHistoryRequest'
);
export const loadBouncebackEndowmentHistorySuccess = createAction(
  'accounts/loadBouncebackEndowmentHistorySuccess'
);
export const loadBouncebackEndowmentHistoryFailure = createAction(
  'accounts/loadBouncebackEndowmentHistoryFailure'
);

export const invalidateWishlistIds = createAction(
  'accounts/invalidateWishlistIds'
);

export const addWishlistItemRequest = createAction(
  'accounts/addWishlistItemRequest'
);
export const addWishlistItemSuccess = createAction(
  'accounts/addWishlistItemSuccess'
);
export const addWishlistItemFailure = createAction(
  'accounts/addWishlistItemFailure'
);

export const deleteWishlistItemRequest = createAction(
  'accounts/deleteWishlistItemRequest'
);
export const deleteWishlistItemSuccess = createAction(
  'accounts/deleteWishlistItemSuccess'
);
export const deleteWishlistItemFailure = createAction(
  'accounts/deleteWishlistItemFailure'
);

export const loadCustomerDetailsRequest = createAction(
  'accounts/loadCustomerDetailsRequest'
);
export const loadCustomerDetailsSuccess = createAction(
  'accounts/loadCustomerDetailsSuccess'
);
export const loadCustomerDetailsFailure = createAction(
  'accounts/loadCustomerDetailsFailure'
);
export const updateCustomerDetailRequest = createAction(
  'accounts/updateCustomerDetailRequest'
);
export const updateCustomerDetailSuccess = createAction(
  'accounts/updateCustomerDetailSuccess'
);
export const updateCustomerDetailFailure = createAction(
  'accounts/updateCustomerDetailFailure'
);

export const invalidateCustomerDetails = createAction(
  'accounts/invalidateCustomerDetails'
);

export const loadMembershipRequest = createAction(
  'accounts/loadMembershipRequest'
);
export const loadMembershipSuccess = createAction(
  'accounts/loadMembershipSuccess'
);
export const loadMembershipFailure = createAction(
  'accounts/loadMembershipFailure'
);

export const invalidateMembership = createAction(
  'accounts/invalidateMembership'
);

export const loadMembershipDetailsRequest = createAction(
  'accounts/loadMembershipDetailsRequest'
);
export const loadMembershipDetailsSuccess = createAction(
  'accounts/loadMembershipDetailsSuccess'
);
export const loadMembershipDetailsFailure = createAction(
  'accounts/loadMembershipDetailsFailure'
);

export const updateMembershipDetailRequest = createAction(
  'accounts/updateMembershipDetailRequest'
);
export const updateMembershipDetailSuccess = createAction(
  'accounts/updateMembershipDetailSuccess'
);
export const updateMembershipDetailFailure = createAction(
  'accounts/updateMembershipDetailFailure'
);

export const invalidateMembershipDetails = createAction(
  'accounts/invalidateMembershipDetails'
);

export const loadMembershipPeriodRequest = createAction(
  'accounts/loadMembershipPeriodRequest'
);
export const loadMembershipPeriodSuccess = createAction(
  'accounts/loadMembershipPeriodSuccess'
);
export const loadMembershipPeriodFailure = createAction(
  'accounts/loadMembershipPeriodFailure'
);

export const loadHappyReturnBarsRequest = createAction(
  'accounts/loadHappyReturnBarsRequest'
);
export const loadHappyReturnBarsSuccess = createAction(
  'accounts/loadHappyReturnBarsSuccess'
);
export const loadHappyReturnBarsFailure = createAction(
  'accounts/loadHappyReturnBarsFailure'
);

export const invalidateMembershipPeriod = createAction(
  'accounts/invalidateMembershipPeriod'
);

export const skipMembershipPeriodRequest = createAction(
  'accounts/skipMembershipPeriodRequest'
);

export const skipMembershipPeriodSuccess = createAction(
  'accounts/skipMembershipPeriodSuccess'
);

export const skipMembershipPeriodFailure = createAction(
  'accounts/skipMembershipPeriodFailure'
);

export const snoozeMembershipRequest = createAction(
  'accounts/snoozeMembershipRequest'
);

export const snoozeMembershipSuccess = createAction(
  'accounts/snoozeMembershipSuccess'
);

export const snoozeMembershipFailure = createAction(
  'accounts/snoozeMembershipFailure'
);

export const loadMembershipCancellationReasonsRequest = createAction(
  'accounts/loadMembershipCancellationReasonsRequest'
);
export const loadMembershipCancellationReasonsSuccess = createAction(
  'accounts/loadMembershipCancellationReasonsSuccess'
);
export const loadMembershipCancellationReasonsFailure = createAction(
  'accounts/loadMembershipCancellationReasonsFailure'
);

export const purchaseMembershipPeriodRequest = createAction(
  'accounts/purchaseMembershipPeriodRequest'
);
export const purchaseMembershipPeriodSuccess = createAction(
  'accounts/purchaseMembershipPeriodSuccess'
);
export const purchaseMembershipPeriodFailure = createAction(
  'accounts/purchaseMembershipPeriodFailure'
);

export const updateMembershipStatusRequest = createAction(
  'accounts/updateMembershipStatusRequest'
);
export const updateMembershipStatusSuccess = createAction(
  'accounts/updateMembershipStatusSuccess'
);
export const updateMembershipStatusFailure = createAction(
  'accounts/updateMembershipStatusFailure'
);

export const updateProfileRequest = createAction(
  'accounts/updateProfileRequest'
);
export const updateProfileSuccess = createAction(
  'accounts/updateProfileSuccess'
);
export const updateProfileFailure = createAction(
  'accounts/updateProfileFailure'
);

export const verifyPasswordRequest = createAction(
  'accounts/verifyPasswordRequest'
);
export const verifyPasswordSuccess = createAction(
  'accounts/verifyPasswordSuccess'
);
export const verifyPasswordFailure = createAction(
  'accounts/verifyPasswordFailure'
);

export const loadLoyaltyDetailsRequest = createAction(
  'accounts/loadLoyaltyDetailsRequest'
);
export const loadLoyaltyDetailsSuccess = createAction(
  'accounts/loadLoyaltyDetailsSuccess'
);
export const loadLoyaltyDetailsFailure = createAction(
  'accounts/loadLoyaltyDetailsFailure'
);
export const invalidateLoyaltyDetails = createAction(
  'accounts/invalidateLoyaltyDetails'
);

export const loadLoyaltyPlansRequest = createAction(
  'accounts/loadLoyaltyPlansRequest'
);
export const loadLoyaltyPlansSuccess = createAction(
  'accounts/loadLoyaltyPlansSuccess'
);
export const loadLoyaltyPlansFailure = createAction(
  'accounts/loadLoyaltyPlansFailure'
);

export const loadLoyaltyPointsForActionsProgressRequest = createAction(
  'accounts/loadLoyaltyPointsForActionsProgressRequest'
);
export const loadLoyaltyPointsForActionsProgressSuccess = createAction(
  'accounts/loadLoyaltyPointsForActionsProgressSuccess'
);
export const loadLoyaltyPointsForActionsProgressFailure = createAction(
  'accounts/loadLoyaltyPointsForActionsProgressFailure'
);
export const forceLoyaltyPointsForActionsProgress = createAction(
  'accounts/forceLoyaltyPointsForActionsProgress'
);

export const sendPointsForActionsEventRequest = createAction(
  'accounts/sendPointsForActionsEventRequest'
);
export const sendPointsForActionsEventSuccess = createAction(
  'accounts/sendPointsForActionsEventSuccess'
);
export const sendPointsForActionsEventFailure = createAction(
  'accounts/sendPointsForActionsEventFailure'
);

export const loadLoyaltyRewardPromosRequest = createAction(
  'accounts/loadLoyaltyRewardPromosRequest'
);
export const loadLoyaltyRewardPromosSuccess = createAction(
  'accounts/loadLoyaltyRewardPromosSuccess'
);
export const loadLoyaltyRewardPromosFailure = createAction(
  'accounts/loadLoyaltyRewardPromosFailure'
);

export const loadEmailPreferencesRequest = createAction(
  'accounts/loadEmailPreferencesRequest'
);
export const loadEmailPreferencesSuccess = createAction(
  'accounts/loadEmailPreferencesSuccess'
);
export const loadEmailPreferencesFailure = createAction(
  'accounts/loadEmailPreferencesFailure'
);

export const updateEmailPreferencesRequest = createAction(
  'accounts/updateEmailPreferencesRequest'
);
export const updateEmailPreferencesSuccess = createAction(
  'accounts/updateEmailPreferencesSuccess'
);
export const updateEmailPreferencesFailure = createAction(
  'accounts/updateEmailPreferencesFailure'
);

export const invalidateEmailPreferences = createAction(
  'accounts/invalidateEmailPreferences'
);

export const saveProductReviewRequest = createAction(
  'accounts/saveProductReviewRequest'
);

export const saveProductReviewSuccess = createAction(
  'accounts/saveProductReviewSuccess'
);

export const saveProductReviewFailure = createAction(
  'accounts/saveProductReviewFailure'
);

export const saveProductReviewImageRequest = createAction(
  'accounts/saveProductReviewImageRequest'
);

export const saveProductReviewImageSuccess = createAction(
  'accounts/saveProductReviewImageSuccess'
);

export const saveProductReviewImageFailure = createAction(
  'accounts/saveProductReviewImageFailure'
);

export const loadReviewableProductsRequest = createAction(
  'accounts/loadReviewableProductsRequest'
);

export const loadReviewableProductsSuccess = createAction(
  'accounts/loadReviewableProductsSuccess'
);

export const loadReviewableProductsFailure = createAction(
  'accounts/loadReviewableProductsFailure'
);

export const loadShippingLocationsRequest = createAction(
  'accounts/loadShippingLocationsRequest'
);

export const loadShippingLocationsSuccess = createAction(
  'accounts/loadShippingLocationsSuccess'
);

export const loadShippingLocationsFailure = createAction(
  'accounts/loadShippingLocationsFailure'
);

export const loadReviewableProductDetailsRequest = createAction(
  'accounts/loadReviewableProductDetailsRequest'
);

export const loadReviewableProductDetailsSuccess = createAction(
  'accounts/loadReviewableProductDetailsSuccess'
);

export const loadReviewableProductDetailsFailure = createAction(
  'accounts/loadReviewableProductDetailsFailure'
);

export const addMembershipSignupRecordRequest = createAction(
  'accounts/addMembershipSignupRecordRequest'
);

export const addMembershipSignupRecordSuccess = createAction(
  'accounts/addMembershipSignupRecordSuccess'
);

export const addMembershipSignupRecordFailure = createAction(
  'accounts/addMembershipSignupRecordFailure'
);

export const loadPaymentsRequest = createAction('accounts/loadPaymentsRequest');
export const loadPaymentsSuccess = createAction('accounts/loadPaymentsSuccess');
export const loadPaymentsFailure = createAction('accounts/loadPaymentsFailure');
export const updatePaymentRequest = createAction(
  'accounts/updatePaymentRequest'
);
export const updatePaymentSuccess = createAction(
  'accounts/updatePaymentSuccess'
);
export const updatePaymentFailure = createAction(
  'accounts/updatePaymentFailure'
);
export const invalidatePayments = createAction('accounts/invalidatePayments');
export const addPaymentRequest = createAction('accounts/addPaymentRequest');
export const addPaymentSuccess = createAction('accounts/addPaymentSuccess');
export const addPaymentFailure = createAction('accounts/addPaymentFailure');

export const tokenizeCreditCardRequest = createAction(
  'tokenizeCreditCardRequest'
);
export const tokenizeCreditCardSuccess = createAction(
  'tokenizeCreditCardSuccess'
);
export const tokenizeCreditCardFailure = createAction(
  'tokenizeCreditCardFailure'
);

export const submitCcpaRequest = createAction('accounts/submitCcpaRequest');
export const submitCcpaSuccess = createAction('accounts/submitCcpaSuccess');
export const submitCcpaFailure = createAction('accounts/submitCcpaFailure');

export const getIsMemberFromStateRequest = createAction(
  'accounts/getIsMemberFromStateRequest'
);
export const getIsMemberFromStateSuccess = createAction(
  'accounts/getIsMemberFromStateSuccess'
);
export const getIsMemberFromStateFailure = createAction(
  'accounts/getIsMemberFromStateFailure'
);

export const loadAddressesRequest = createAction(
  'accounts/loadAddressesRequest'
);
export const loadAddressesSuccess = createAction(
  'accounts/loadAddressesSuccess'
);
export const loadAddressesFailure = createAction(
  'accounts/loadAddressesFailure'
);

export const validateAddressRequest = createAction(
  'accounts/validateAddressRequest'
);
export const validateAddressSuccess = createAction(
  'accounts/validateAddressSuccess'
);
export const validateAddressFailure = createAction(
  'accounts/validateAddressFailure'
);

export const createAddressRequest = createAction(
  'accounts/createAddressRequest'
);
export const createAddressSuccess = createAction(
  'accounts/createAddressSuccess'
);
export const createAddressFailure = createAction(
  'accounts/createAddressFailure'
);

export const updateAddressRequest = createAction(
  'accounts/updateAddressRequest'
);
export const updateAddressSuccess = createAction(
  'accounts/updateAddressSuccess'
);
export const updateAddressFailure = createAction(
  'accounts/updateAddressFailure'
);

export const setDefaultAddressRequest = createAction(
  'accounts/setDefaultAddressRequest'
);
export const setDefaultAddressSuccess = createAction(
  'accounts/setDefaultAddressSuccess'
);
export const setDefaultAddressFailure = createAction(
  'accounts/setDefaultAddressFailure'
);

export const deleteAddressRequest = createAction(
  'accounts/deleteAddressRequest'
);
export const deleteAddressSuccess = createAction(
  'accounts/deleteAddressSuccess'
);
export const deleteAddressFailure = createAction(
  'accounts/deleteAddressFailure'
);

export const loadRetailStoresRequest = createAction(
  'accounts/loadRetailStoresRequest'
);
export const loadRetailStoresSuccess = createAction(
  'accounts/loadRetailStoresSuccess'
);
export const loadRetailStoresFailure = createAction(
  'accounts/loadRetailStoresFailure'
);
export const loadWaitlistRequest = createAction('accounts/loadWaitlistRequest');
export const loadWaitlistSuccess = createAction('accounts/loadWaitlistSuccess');
export const loadWaitlistFailure = createAction('accounts/loadWaitlistFailure');

// actions associated w/ waitlist ids
export const loadWaitlistIdsRequest = createAction(
  'accounts/loadWaitlistIdsRequest'
);
export const loadWaitlistIdsSuccess = createAction(
  'accounts/loadWaitlistIdsSuccess'
);
export const loadWaitlistIdsFailure = createAction(
  'accounts/loadWaitlistIdsFailure'
);
export const invalidateWaitlistIds = createAction(
  'accounts/invalidateWaitlistIds'
);
export const deleteWaitlistItemSuccess = createAction(
  'accounts/deleteWaitlistItemSuccess'
);

export const addWaitlistItemRequest = createAction(
  'accounts/addWaitlistItemRequest'
);
export const addWaitlistItemSuccess = createAction(
  'accounts/addWaitlistItemSuccess'
);
export const addWaitlistItemFailure = createAction(
  'accounts/addWaitlistItemFailure'
);

export const addWaitlistBundleRequest = createAction(
  'accounts/addWaitlistBundleRequest'
);
export const addWaitlistBundleSuccess = createAction(
  'accounts/addWaitlistBundleSuccess'
);
export const addWaitlistBundleFailure = createAction(
  'accounts/addWaitlistBundleFailure'
);

export const createIdmeVerifyRequest = createAction(
  'accounts/createIdmeVerifyRequest'
);
export const createIdmeVerifySuccess = createAction(
  'accounts/createIdmeVerifySuccess'
);
export const createIdmeVerifyFailure = createAction(
  'accounts/createIdmeVerifyFailure'
);

export const loadForeignMembershipRequest = createAction(
  'accounts/loadForeignMembershipRequest'
);
export const loadForeignMembershipSuccess = createAction(
  'accounts/loadForeignMembershipSuccess'
);
export const loadForeignMembershipFailure = createAction(
  'accounts/loadForeignMembershipFailure'
);
export const invalidateForeignMembership = createAction(
  'accounts/invalidateForeignMembership'
);

export const loadMembershipSuggestedProductsRequest = createAction(
  'accounts/loadMembershipSuggestedProductsRequest'
);

export const loadMembershipSuggestedProductsSuccess = createAction(
  'accounts/loadMembershipSuggestedProductsSuccess'
);

export const loadMembershipSuggestedProductsFailure = createAction(
  'accounts/loadMembershipSuggestedProductsFailure'
);
export const forceRegDateRequest = createAction('accounts/forceRegDateRequest');
export const forceRegDateSuccess = createAction('accounts/forceRegDateSuccess');
export const forceRegDateFailure = createAction('accounts/forceRegDateFailure');

export const loadCopsSegmentTraitsRequest = createAction(
  'accounts/loadCopsSegmentTraitsRequest'
);
export const loadCopsSegmentTraitsSuccess = createAction(
  'accounts/loadCopsSegmentTraitsSuccess'
);
export const loadCopsSegmentTraitsFailure = createAction(
  'accounts/loadCopsSegmentTraitsFailure'
);

export const redeemLoyaltyPromoSuccess = createAction(
  'promos/redeemMemberLoyaltyPromoSuccess'
);

export function loadHappyReturnBars({ orderId, retailer, distance = 50 }) {
  return {
    bentoApi: {
      endpoint: `accounts/me/orders/${orderId}/closestBars?retailer=${retailer}&distance=${distance}`,
      requestKey: JSON.stringify([
        'getClosestReturnBars',
        orderId,
        retailer,
        distance,
      ]),
      actions: [
        loadHappyReturnBarsRequest,
        loadHappyReturnBarsSuccess,
        loadHappyReturnBarsFailure,
      ],
    },
  };
}

export function loadCopsSegmentTraits() {
  return async (dispatch, getState) => {
    // Force session to resolve before we check `networkStatus`, otherwise
    // `loadSessionSuccess` may not have fired yet and thus triggered the
    // reducer below updating `networkStatus`.
    await dispatch(loadSession());

    const { networkStatus } = getState().copsSegmentTraits;

    const headers = {};
    const searchParams = {};
    if (config.get('public.copsTraits.cacheStrategy') === 'noCache') {
      searchParams.noCache = true;
      headers['cache-control'] = 'no-cache';
    }

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/segment/traits',
          headers,
          searchParams,
          actions: [
            loadCopsSegmentTraitsRequest,
            loadCopsSegmentTraitsSuccess,
            loadCopsSegmentTraitsFailure,
          ],
        },
      });
    }
  };
}

export function validateAddress(address) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/address/verify',
      json: address,
      actions: [
        validateAddressRequest,
        validateAddressSuccess,
        validateAddressFailure,
      ],
    },
  };
}

export function loadShippingLocations(postalCode, providerId, countryCode) {
  return {
    bentoApi: {
      endpoint: `shipping/providers/${providerId}/locations?countryCode=${countryCode}&postalCode=${postalCode}`,
      requestKey: JSON.stringify([
        'getNearbyShippingProviderLocations',
        postalCode,
        providerId,
        countryCode,
      ]),
      actions: [
        loadShippingLocationsRequest,
        loadShippingLocationsSuccess,
        loadShippingLocationsFailure,
      ],
    },
  };
}

export function loadAddresses() {
  return async (dispatch, getState) => {
    // Force session to resolve before we check `networkStatus`, otherwise
    // `loadSessionSuccess` may not have fired yet and thus triggered the
    // reducer below updating `networkStatus`.
    await dispatch(loadSession());

    const { networkStatus } = getState().addresses;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/addresses',
          requestKey: 'loadAddresses',
          // It is required, but we already took care of it above.
          sessionRequired: false,
          actions: [
            loadAddressesRequest,
            loadAddressesSuccess,
            loadAddressesFailure,
          ],
        },
      });
    }
  };
}

export function createAddress(address) {
  return async (dispatch, getState) => {
    const checkCustomerContact = !address.firstName || !address.lastName;
    const checkCustomerPhone = !address.phone;

    await Promise.all([
      checkCustomerContact ? dispatch(loadProfile()) : null,
      checkCustomerPhone ? dispatch(loadPhoneNumber()) : null,
    ]);

    const { customer, phoneNumber } = getState();
    return dispatch({
      bentoApi: {
        method: 'POST',
        endpoint: 'accounts/me/addresses',
        json: {
          ...address,
          isDefault:
            typeof address.isDefault !== 'undefined' ? address.isDefault : true,
          firstName: address.firstName || customer.firstName,
          lastName: address.lastName || customer.lastName,
          phone: address.phone || phoneNumber.phoneNumber || '',
        },
        actions: [
          createAddressRequest,
          createAddressSuccess,
          createAddressFailure,
        ],
      },
    });
  };
}

export function updateAddress(address) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: `accounts/me/addresses/${address.id}`,
      json: address,
      actions: [
        updateAddressRequest,
        updateAddressSuccess,
        updateAddressFailure,
      ],
    },
  };
}

export function setDefaultAddress(address) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: `accounts/me/addresses/${address.id}`,
      json: {
        type: 'shipping',
        ...address,
        isDefault: true,
        applyCartShipAddress: true,
      },
      actions: [
        setDefaultAddressRequest,
        setDefaultAddressSuccess,
        setDefaultAddressFailure,
      ],
    },
  };
}

export function deleteAddress(address) {
  return {
    bentoApi: {
      method: 'DELETE',
      endpoint: `accounts/me/addresses/${address.id}`,
      actions: [
        deleteAddressRequest,
        deleteAddressSuccess,
        deleteAddressFailure,
      ],
    },
  };
}

export function submitCcpa({
  email,
  firstName,
  lastName,
  requestTypeId,
  requestSourceId = CcpaRequestSource.WEB,
} = {}) {
  return async (dispatch, getState, context) => {
    if (!email || !firstName || !lastName) {
      await dispatch(loadProfile());
      const { customer } = getState();
      email = email || customer.email;
      firstName = firstName || customer.firstName;
      lastName = lastName || customer.lastName;
    }
    const payload = {
      email,
      firstname: firstName,
      lastname: lastName,
      requestTypeId,
      requestSourceId,
    };

    return dispatch({
      bentoApi: {
        method: 'POST',
        endpoint: 'members/ccpa/request',
        json: payload,
        actions: [submitCcpaRequest, submitCcpaSuccess, submitCcpaFailure],
      },
    });
  };
}

// Legal-approved methods to determine if logged-in user is from a specific state
export function getIsMemberFromState(state) {
  return {
    bentoApi: {
      endpoint: 'members/fromstate',
      searchParams: { state },
      actions: [
        getIsMemberFromStateRequest,
        getIsMemberFromStateSuccess,
        getIsMemberFromStateFailure,
      ],
    },
  };
}

export function signUp(payload, extraMeta) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'members/signup',
      json: payload,
      actions: [
        (payload, meta) =>
          signUpRequest(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          signUpSuccess(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          signUpFailure(payload, {
            ...meta,
            ...extraMeta,
          }),
      ],
    },
  };
}

export function activateTrialToMembership() {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/trial/convert',
      actions: [
        activateTrialToMembershipRequest,
        activateTrialToMembershipSuccess,
        activateTrialToMembershipFailure,
      ],
    },
  };
}

export function logIn(credentials, extraMeta) {
  return (dispatch, getState, context) => {
    if (getState().bentoApi.overrideReCaptchaEnv) {
      credentials = { overrideEnv: true, ...credentials };
    }
    return dispatch({
      bentoApi: {
        method: 'POST',
        endpoint: 'auth/login',
        json: credentials,
        actions: [
          (payload, meta) =>
            logInRequest(payload, {
              ...meta,
              ...extraMeta,
            }),
          (payload, meta) =>
            logInSuccess(payload, {
              ...meta,
              ...extraMeta,
            }),
          (payload, meta) =>
            logInFailure(payload, {
              ...meta,
              ...extraMeta,
            }),
        ],
      },
    });
  };
}

export function facebookLogIn(payload, extraMeta) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'auth/facebook/login',
      json: payload,
      actions: [
        (payload, meta) =>
          logInRequest(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          logInSuccess(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          logInFailure(payload, {
            ...meta,
            ...extraMeta,
          }),
      ],
    },
  };
}

export function autoLogin(credentials, extraMeta) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'auth/autologin',
      json: credentials,
      actions: [
        (payload, meta) =>
          logInRequest(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          logInSuccess(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          logInFailure(payload, {
            ...meta,
            ...extraMeta,
          }),
      ],
    },
  };
}

export function autoLoginPasswordless(credentials, extraMeta) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'auth/autologin/passwordless',
      json: credentials,
      actions: [
        (payload, meta) =>
          logInRequest(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          logInSuccess(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          logInFailure(payload, {
            ...meta,
            ...extraMeta,
          }),
      ],
    },
  };
}

export function gmsLogin(credentials, extraMeta) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'auth/gmsLogin',
      json: credentials,
      actions: [
        (payload, meta) =>
          logInRequest(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          logInSuccess(payload, {
            ...meta,
            ...extraMeta,
          }),
        (payload, meta) =>
          logInFailure(payload, {
            ...meta,
            ...extraMeta,
          }),
      ],
    },
  };
}

export function logOut(redirectUrl = '/') {
  return async (dispatch) => {
    const result = await dispatch({
      bentoApi: {
        method: 'POST',
        endpoint: 'auth/logout',
        actions: [logOutRequest, logOutSuccess, logOutFailure],
      },
    });

    // TODO: Failure actions should now throw/reject, so this `error` check
    // shouldn't be necessary. But it's also backwards compatible, so keeping it
    // for now means we don't need to force a peer dependency on
    // `@techstyle/redux-core` >=0.2.0. We can remove this in the future when we
    // upgrade the minimum version for other reasons.
    if (!result.error && process.browser) {
      window.location.href = redirectUrl;
    }

    return result;
  };
}

export function loginMethods() {
  return {
    bentoApi: {
      method: 'GET',
      endpoint: 'members/loginMethods',
      loginRequired: true,
      actions: [loginMethodsRequest, loginMethodsSuccess, loginMethodsFailure],
    },
  };
}

export function loginMethodsFromEmail(email) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'members/loginMethods', // This is the post endpoint for login methods
      json: { email },
      actions: [
        loginMethodsFromEmailRequest,
        loginMethodsFromEmailSuccess,
        loginMethodsFromEmailFailure,
      ],
    },
  };
}

export function requestMultifactorPin({ type, destination, email }) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'members/multiFactor',
      json: { type, destination, email },
      actions: [
        requestMultifactorPinRequest,
        requestMultifactorPinSuccess,
        requestMultifactorPinFailure,
      ],
    },
  };
}

export function submitMultifactorPin({ pin, email }) {
  return (dispatch) => {
    return dispatch({
      bentoApi: {
        method: 'POST',
        endpoint: 'auth/autologin/pin',
        json: { pin, email },
        actions: [
          (payload, meta) => {
            dispatch(submitMultifactorPinRequest);
            return logInRequest(payload, meta);
          },
          (payload, meta) => {
            dispatch(submitMultifactorPinSuccess);
            return logInSuccess(payload, meta);
          },
          (payload, meta) => {
            dispatch(submitMultifactorPinFailure);
            return logInFailure(payload, meta);
          },
        ],
      },
    });
  };
}

export function forgotPassword(payload) {
  return (dispatch, getState, context) => {
    if (getState().bentoApi.overrideReCaptchaEnv) {
      payload = { overrideEnv: true, ...payload };
    }
    return dispatch({
      bentoApi: {
        method: 'POST',
        endpoint: 'members/forgot',
        json: payload,
        actions: [
          forgotPasswordRequest,
          forgotPasswordSuccess,
          forgotPasswordFailure,
        ],
      },
    });
  };
}

// For resetting password via "Forgot Password" flow.
// Requires `prkey` generated in "Forgot Password" e-mail.
export function resetPassword(payload = {}) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'members/reset',
      json: {
        ...payload,
        // Automatically get `prkey` from the current URL if not supplied.
        prkey: payload.prkey || Router.query.prkey,
      },
      actions: [
        resetPasswordRequest,
        resetPasswordSuccess,
        resetPasswordFailure,
      ],
    },
  };
}

export function updatePassword(payload = {}) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/reset',
      json: payload,
      actions: [
        updatePasswordRequest,
        updatePasswordSuccess,
        updatePasswordFailure,
      ],
    },
  };
}

export function loadProfile() {
  return async (dispatch, getState) => {
    // Force session to resolve before we check `networkStatus`, otherwise
    // `loadSessionSuccess` may not have fired yet and thus triggered the
    // reducer below updating `networkStatus`.
    await dispatch(loadSession());

    const { networkStatus } = getState().customer;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/profile',
          requestKey: 'loadProfile',
          sessionRequired: false,
          actions: [loadProfileRequest, loadProfileSuccess, loadProfileFailure],
        },
      });
    }
  };
}

export function loadPhoneNumber() {
  return async (dispatch, getState) => {
    // Force session to resolve before we check `networkStatus`, otherwise
    // `loadSessionSuccess` may not have fired yet and thus triggered the
    // reducer below updating `networkStatus`.
    await dispatch(loadSession());

    const { networkStatus } = getState().phoneNumber;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/profile',
          requestKey: 'loadPhoneNumber',
          searchParams: { includePhoneNumber: true },
          // It is required, but we already took care of it above.
          sessionRequired: false,
          actions: [
            loadPhoneNumberRequest,
            loadPhoneNumberSuccess,
            loadPhoneNumberFailure,
          ],
        },
      });
    }
  };
}

export function updateProfile(payload) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/profile',
      json: payload,
      actions: [
        updateProfileRequest,
        updateProfileSuccess,
        updateProfileFailure,
      ],
    },
  };
}

export function verifyPassword(payload) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/profile/verify',
      method: 'POST',
      json: payload,
      actions: [
        verifyPasswordRequest,
        verifyPasswordSuccess,
        verifyPasswordFailure,
      ],
    },
  };
}

/**
 * @function loadWishlist Retrieves favorite/wishlist items
 * @param {number} page The page number you are looking for in paginated results
 * @param {number} pageSize The number of items to return
 * @param {'ASC'|'DESC'} sortDirection The direction to sort wishlist items
 * @returns {Object} An object full of wishlist state
 */
export function loadWishlist({
  page = 1,
  pageSize = 100,
  sortDirection = '',
  retailDiscounting = false,
} = {}) {
  const searchParams = { page, pageSize, retailDiscounting };
  if (sortDirection !== '') {
    searchParams.sortDirection = sortDirection;
  }

  return {
    bentoApi: {
      endpoint: 'accounts/me/wishlist',
      requestKey: JSON.stringify([
        'wishlist',
        page,
        pageSize,
        retailDiscounting,
      ]),
      searchParams,
      actions: [loadWishlistRequest, loadWishlistSuccess, loadWishlistFailure],
    },
  };
}

export function loadMembershipCredits({
  page = 1,
  pageSize = 15,
  sortDirection,
  date,
} = {}) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/tokens',
      requestKey: JSON.stringify([
        'membershipCredits',
        page,
        pageSize,
        sortDirection,
        date,
      ]),
      searchParams: { page, pageSize, sortDirection, date },
      actions: [
        loadMembershipCreditsRequest,
        loadMembershipCreditsSuccess,
        loadMembershipCreditsFailure,
      ],
    },
  };
}

export function loadWishlistIds() {
  return async (dispatch, getState) => {
    // Force session to resolve before we check `networkStatus`, otherwise
    // `loadSessionSuccess` may not have fired yet and thus triggered the
    // reducer below updating `networkStatus`.
    await dispatch(loadSession());

    const { networkStatus } = getState().wishlistIds;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/wishlist/ids',
          requestKey: 'loadWishlistIds',
          // It is required, but we already took care of it above.
          sessionRequired: false,
          actions: [
            loadWishlistIdsRequest,
            loadWishlistIdsSuccess,
            loadWishlistIdsFailure,
          ],
        },
      });
    }
  };
}

/**
 * @function loadBouncebackEndowmentHistory Retrieves the history of bounceback endowments awarded to the user
 * @param {number} page The current page of the paginated results to fetch. Default value is 1.
 * @param {number} count The number of results to return for the current page being fetched. Default value is 5.
 * @returns {Object} An object containing the endowment history state
 */
export function loadBouncebackEndowmentHistory({ page = 1, count = 5 } = {}) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/endowment/history',
      searchParams: { page, count },
      requestKey: JSON.stringify([
        'loadBouncebackEndowmentHistory',
        page,
        count,
      ]),
      actions: [
        loadBouncebackEndowmentHistoryRequest,
        loadBouncebackEndowmentHistorySuccess,
        loadBouncebackEndowmentHistoryFailure,
      ],
    },
  };
}

export function addWishlistItem(productId, options = {}) {
  const { optimistic = false } = options;

  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/wishlist',
      requestKey: JSON.stringify(['addWishlistItem', options]),
      searchParams: { optimistic },
      json: { productId: productId },
      actions: [
        addWishlistItemRequest,
        addWishlistItemSuccess,
        addWishlistItemFailure,
      ],
    },
  };
}

export function deleteWishlistItem(productId) {
  return {
    bentoApi: {
      method: 'DELETE',
      endpoint: `accounts/me/wishlist/${productId}`,
      actions: [
        deleteWishlistItemRequest,
        deleteWishlistItemSuccess,
        deleteWishlistItemFailure,
      ],
    },
  };
}

export function loadCustomerDetails(keys, options = {}) {
  const { batch = true } = options;

  if (batch) {
    return (dispatch, getState, context) => {
      let currentBatch = context.loadCustomerDetailsBatch;

      if (currentBatch) {
        // Add to the existing scheduled batch.
        currentBatch.keys.push(...keys);
      } else {
        // Schedule a new batch.
        currentBatch = {
          keys: keys.slice(),
          promise: new Promise((resolve, reject) => {
            const loadCustomerDetailsAfterTick = async () => {
              // Let a tick of the event loop pass.
              await Promise.resolve();
              // After waiting, get a snapshot of the current queue and clear it
              // out.
              const currentBatch = context.loadCustomerDetailsBatch;
              context.loadCustomerDetailsBatch = undefined;
              // Get unique keys.
              const batchKeys = Array.from(new Set(currentBatch.keys));
              try {
                resolve(
                  dispatch(loadCustomerDetails(batchKeys, { batch: false }))
                );
              } catch (err) {
                reject(err);
              }
            };

            loadCustomerDetailsAfterTick();
          }),
        };
        context.loadCustomerDetailsBatch = currentBatch;
      }
      return currentBatch.promise;
    };
  }

  return (dispatch, getState, context) => {
    const keysToFetch = [];
    const existingRecords = getState().customerDetails;

    keys.forEach((key) => {
      const record = existingRecords[key];
      if (!record) {
        debug(
          'Enqueuing fetch of customer detail key “%s” because it has no existing record.',
          key
        );
        keysToFetch.push(key);
      } else if (record.error) {
        debug(
          'Skipping customer detail key “%s” because it has an error.',
          key
        );
      } else if (record.networkStatus.isUpToDate) {
        debug(
          'Skipping customer detail key “%s” because it is already up-to-date.',
          key
        );
      } else if (record.networkStatus.isLoading) {
        debug(
          'Skipping customer detail key “%s” because it is already loading.',
          key
        );
      } else {
        // FIXME: Since the resulting `loadCustomerDetailsRequest` action below
        // is not necessarily fired synchronously, we haven't necessarily
        // updated the store with `isLoading` yet, so the above check will fail
        // and we'll end up here instead. Switching to track inflight requests
        // on `context` or via sagas may fix this.
        debug(
          'Enqueuing fetch of customer detail key “%s” because it out of date.',
          key
        );
        keysToFetch.push(key);
      }
    });

    if (!keysToFetch.length) {
      return;
    }

    return dispatch({
      bentoApi: {
        endpoint: 'accounts/me/detail',
        requestKey: JSON.stringify(['loadCustomerDetails', keysToFetch]),
        searchParams: {
          names: keysToFetch.join(','),
        },
        schema: [CustomerDetail],
        actions: [
          (payload, meta) =>
            loadCustomerDetailsRequest(payload, {
              ...meta,
              keysToFetch,
            }),
          (payload, meta) =>
            loadCustomerDetailsSuccess(payload, {
              ...meta,
              keysToFetch,
            }),
          (payload, meta) =>
            loadCustomerDetailsFailure(payload, {
              ...meta,
              keysToFetch,
            }),
        ],
      },
    });
  };
}

export function updateCustomerDetail({
  name,
  value,
  dateTimeAdded,
  options = {
    shouldInvalidateFeatures: false,
  },
}) {
  if (!name) {
    throw new Error("updateCustomerDetail must be given a 'name' string.");
  }
  const payload = { name, value, dateTimeAdded };
  const json = { name, value, datetimeAdded: dateTimeAdded };

  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/detail',
      json,
      requestKey: JSON.stringify(['updateCustomerDetail', payload]),
      actions: [
        (noContent, meta) =>
          updateCustomerDetailRequest(payload, { ...meta, ...options }),
        (noContent, meta) =>
          updateCustomerDetailSuccess(payload, { ...meta, ...options }),
        (noContent, meta) =>
          updateCustomerDetailFailure(payload, { ...meta, ...options }),
      ],
    },
  };
}

export function forceRegistrationDate(regDate) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/membership/signup',
      json: { regDate },
      actions: [forceRegDateRequest, forceRegDateSuccess, forceRegDateFailure],
    },
  };
}

export function loadMembership() {
  return async (dispatch, getState, context) => {
    // Force session to resolve before we check `networkStatus`, otherwise
    // `loadSessionSuccess` may not have fired yet and thus triggered the
    // reducer below updating `networkStatus`.
    await dispatch(loadSession());

    const { networkStatus } = getState().membership;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/membership',
          requestKey: 'loadMembership',
          // It is required, but we already took care of it above.
          sessionRequired: false,
          actions: [
            loadMembershipRequest,
            loadMembershipSuccess,
            loadMembershipFailure,
          ],
        },
      });
    }
  };
}

export function loadMembershipDetails(keys, options = {}) {
  if (typeof keys === 'string') {
    keys = [keys];
  }
  const { batch = true } = options;

  if (batch) {
    return (dispatch, getState, context) => {
      let currentBatch = context.loadMembershipDetailsBatch;

      if (currentBatch) {
        // Add to the existing scheduled batch.
        currentBatch.keys.push(...keys);
      } else {
        // Schedule a new batch.
        currentBatch = {
          keys: keys.slice(),
          promise: new Promise((resolve, reject) => {
            const loadMembershipDetailsAfterTick = async () => {
              await Promise.resolve();
              const currentBatch = context.loadMembershipDetailsBatch;
              context.loadMembershipDetailsBatch = undefined;
              const batchKeys = Array.from(new Set(currentBatch.keys));
              try {
                resolve(
                  dispatch(loadMembershipDetails(batchKeys, { batch: false }))
                );
              } catch (err) {
                reject(err);
              }
            };

            loadMembershipDetailsAfterTick();
          }),
        };
        context.loadMembershipDetailsBatch = currentBatch;
      }
      return currentBatch.promise;
    };
  }

  return (dispatch, getState, context) => {
    const keysToFetch = [];
    const existingRecords = getState().membershipDetails;

    keys.forEach((key) => {
      const record = existingRecords[key];
      if (!record) {
        debug(
          'Enqueuing fetch of membership detail key “%s” because it has no existing record.',
          key
        );
        keysToFetch.push(key);
      } else if (record.error) {
        debug(
          'Skipping membership detail key “%s” because it has an error.',
          key
        );
      } else if (record.networkStatus.isUpToDate) {
        debug(
          'Skipping membership detail key “%s” because it is already up-to-date.',
          key
        );
      } else if (record.networkStatus.isLoading) {
        debug(
          'Skipping membership detail key “%s” because it is already loading.',
          key
        );
      } else {
        debug(
          'Enqueuing fetch of membership detail key “%s” because it out of date.',
          key
        );
        keysToFetch.push(key);
      }
    });

    if (!keysToFetch.length) {
      return;
    }

    return dispatch({
      bentoApi: {
        endpoint: `accounts/me/membership/detail?names=${keys}`,
        requestKey: JSON.stringify(['loadMembershipDetails', keysToFetch]),
        searchParams: {
          names: keysToFetch.join(','),
        },
        actions: [
          (payload, meta) =>
            loadMembershipDetailsRequest(payload, {
              ...meta,
              keysToFetch,
            }),
          (payload, meta) =>
            loadMembershipDetailsSuccess(payload, {
              ...meta,
              keysToFetch,
            }),
          (payload, meta) =>
            loadMembershipDetailsFailure(payload, {
              ...meta,
              keysToFetch,
            }),
        ],
      },
    });
  };
}

export function updateMembershipDetail({ name, value } = {}) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/membership/detail',
      json: { name, value },
      requestKey: JSON.stringify(['updateMembershipDetail', name, value]),
      actions: [
        (payload, meta) =>
          updateMembershipDetailRequest(payload, {
            ...meta,
            name,
            value,
          }),
        (payload, meta) =>
          updateMembershipDetailSuccess(payload, {
            ...meta,
            name,
            value,
          }),
        (payload, meta) =>
          updateMembershipDetailFailure(payload, {
            ...meta,
            name,
            value,
          }),
      ],
    },
  };
}

export function loadMembershipPeriod() {
  return {
    bentoApi: {
      endpoint: 'accounts/me/membership/period',
      requestKey: 'loadMembershipPeriod',
      actions: [
        loadMembershipPeriodRequest,
        loadMembershipPeriodSuccess,
        loadMembershipPeriodFailure,
      ],
    },
  };
}

export function skipMembershipPeriod({
  reason = SkipReason.OTHER,
  reasonComment = '',
} = {}) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/membership/period/skip',
      json: { membershipSkipReasonId: reason, reasonComment },
      requestKey: JSON.stringify([
        'skipMembershipPeriod',
        reason,
        reasonComment,
      ]),
      actions: [
        skipMembershipPeriodRequest,
        skipMembershipPeriodSuccess,
        skipMembershipPeriodFailure,
      ],
    },
  };
}

export function snoozeMembership({ skipCurrentMonth = false }) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/membership/snooze',
      json: { skipCurrentMonth },
      requestKey: 'snoozeMembership',
      actions: [
        snoozeMembershipRequest,
        snoozeMembershipSuccess,
        snoozeMembershipFailure,
      ],
    },
  };
}

export function loadMembershipCancellationReasons() {
  return async (dispatch, getState) => {
    const { networkStatus } = getState().membershipCancellationReasons;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          method: 'GET',
          endpoint: 'settings/membership/cancellationreasons',
          requestKey: 'loadMembershipCancellationReasons',
          actions: [
            loadMembershipCancellationReasonsRequest,
            loadMembershipCancellationReasonsSuccess,
            loadMembershipCancellationReasonsFailure,
          ],
        },
      });
    }
  };
}

export function updateMembershipStatus({
  cancelActivatingOrder,
  reasonComment,
  reasonId,
  status,
} = {}) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/membership/status',
      json: { cancelActivatingOrder, reasonComment, reasonId, status },
      requestKey: JSON.stringify(['updateMembershipStatus', reasonId, status]),
      actions: [
        updateMembershipStatusRequest,
        updateMembershipStatusSuccess,
        updateMembershipStatusFailure,
      ],
    },
  };
}

export function addMembershipSignupRecord(body) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/membership/membershipBrandSignup',
      json: body,
      requestKey: JSON.stringify(['addMembershipSignupRecord', body]),
      actions: [
        addMembershipSignupRecordRequest,
        addMembershipSignupRecordSuccess,
        addMembershipSignupRecordFailure,
      ],
    },
  };
}

export function cancelMembership({
  reasonId = CancelReason.OTHER,
  cancelActivatingOrder = false,
}) {
  return updateMembershipStatus({
    status: MembershipUpdateStatus.CANCEL,
    reasonId,
    cancelActivatingOrder,
  });
}

export function loadLoyaltyDetails() {
  return async (dispatch, getState, context) => {
    const { networkStatus } = getState().loyalty;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/loyalty/details',
          requestKey: 'loadLoyaltyDetails',
          actions: [
            loadLoyaltyDetailsRequest,
            loadLoyaltyDetailsSuccess,
            loadLoyaltyDetailsFailure,
          ],
        },
      });
    }
  };
}

export function loadLoyaltyPlans() {
  return async (dispatch, getState) => {
    await dispatch(loadSession());

    const { networkStatus } = getState().loyaltyPlans;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'members/loyalty/plans',
          actions: [
            loadLoyaltyPlansRequest,
            loadLoyaltyPlansSuccess,
            loadLoyaltyPlansFailure,
          ],
        },
      });
    }
  };
}

export function loadLoyaltyPointsForActionsProgress() {
  return async (dispatch, getState) => {
    const { networkStatus } = getState().loyaltyPointsForActionsProgress;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/loyalty/points-for-actions-progress',
          requestKey: 'loadLoyaltyPointsForActionsProgress',
          actions: [
            loadLoyaltyPointsForActionsProgressRequest,
            loadLoyaltyPointsForActionsProgressSuccess,
            loadLoyaltyPointsForActionsProgressFailure,
          ],
        },
      });
    }
  };
}

export function sendPointsForActionsEvent(data) {
  const actionId = data.value?.data?.data?.actionId;
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'kafka/topics/AWS_FBL_FE_Events/records',
      json: data,
      requestKey: `sendPointsForActionsEvent/${actionId}`,
      actions: [
        sendPointsForActionsEventRequest,
        (payload, meta) =>
          sendPointsForActionsEventSuccess(
            {
              ...payload,
              actionId,
            },
            meta
          ),
        sendPointsForActionsEventFailure,
      ],
    },
  };
}

export function loadLoyaltyRewardPromos({ clearCache = true } = {}) {
  return async (dispatch, getState) => {
    await dispatch(loadSession());

    const { networkStatus } = getState().loyaltyRewardPromos;

    if (!networkStatus.isUpToDate) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/loyalty/rewards',
          searchParams: { clearCache },
          actions: [
            loadLoyaltyRewardPromosRequest,
            loadLoyaltyRewardPromosSuccess,
            loadLoyaltyRewardPromosFailure,
          ],
        },
      });
    }
  };
}

export function loadEmailPreferences(varsArr = []) {
  const masterList = varsArr.length
    ? varsArr
    : config.get('public.emailPreferences.masterList');
  const vars = masterList.join(',');
  const searchParams = {};

  if (masterList.length) {
    // only add query params for vars if they are present
    searchParams.vars = vars;
  }

  return {
    bentoApi: {
      endpoint: 'accounts/me/preferences/email',
      searchParams,
      requestKey: JSON.stringify(['loadEmailPreferences', vars]),
      actions: [
        loadEmailPreferencesRequest,
        loadEmailPreferencesSuccess,
        loadEmailPreferencesFailure,
      ],
    },
  };
}

export function loadReviewableProducts() {
  return {
    bentoApi: {
      endpoint: `accounts/me/reviews/products`,
      requestKey: 'loadReviewProducts',
      actions: [
        loadReviewableProductsRequest,
        loadReviewableProductsSuccess,
        loadReviewableProductsFailure,
      ],
    },
  };
}

export function loadReviewableProductDetails(masterProductId) {
  return {
    bentoApi: {
      endpoint: `products/${masterProductId}/review/pages`,
      requestKey: JSON.stringify(['loadReviewProductDetails', masterProductId]),
      actions: [
        loadReviewableProductDetailsRequest,
        loadReviewableProductDetailsSuccess,
        loadReviewableProductDetailsFailure,
      ],
    },
  };
}

export function saveProductReview(params) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/reviews',
      json: params,
      actions: [
        saveProductReviewRequest,
        saveProductReviewSuccess,
        saveProductReviewFailure,
      ],
    },
  };
}

export function saveProductReviewImage(productId, imageData) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: `accounts/me/products/${productId}/images`,
      json: {
        base64DataUrl: imageData,
      },
      actions: [
        saveProductReviewImageRequest,
        saveProductReviewImageSuccess,
        saveProductReviewImageFailure,
      ],
    },
  };
}

export function updateEmailPreferences(payload) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/preferences/email',
      json: payload,
      actions: [
        updateEmailPreferencesRequest,
        updateEmailPreferencesSuccess,
        updateEmailPreferencesFailure,
      ],
    },
  };
}

export function loadPayments() {
  const searchParams = { includeKlarna: true };
  return {
    bentoApi: {
      endpoint: 'accounts/me/payments',
      requestKey: 'loadPayments',
      searchParams,
      schema: [PaymentMethod],
      actions: [loadPaymentsRequest, loadPaymentsSuccess, loadPaymentsFailure],
    },
  };
}

export function addPayment({ paymentProviderType = 'vantiv', payload }) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint:
        paymentProviderType !== 'vantiv'
          ? `accounts/me/payments/${paymentProviderType}`
          : 'accounts/me/payments',
      json: payload,
      actions: [addPaymentRequest, addPaymentSuccess, addPaymentFailure],
    },
  };
}

export function updatePayment({ paymentMethod, paymentId, payload }) {
  return {
    bentoApi: {
      method: 'PATCH',
      endpoint: `accounts/me/payments/${paymentMethod}/${paymentId}`,
      json: payload,
      actions: [
        updatePaymentRequest,
        updatePaymentSuccess,
        updatePaymentFailure,
      ],
    },
  };
}

export function purchaseMembershipPeriod() {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/membership/period/purchase',
      actions: [
        purchaseMembershipPeriodRequest,
        purchaseMembershipPeriodSuccess,
        purchaseMembershipPeriodFailure,
      ],
    },
  };
}

export function verifyIdmeCustomer(payload) {
  if (!payload) {
    throw new Error(
      'verifyIdmeCustomer must be given parameter "payload" {code: codeval}'
    );
  }

  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/partner/idme',
      json: payload,
      requestKey: 'verifyIdmeCustomer',
      actions: [
        createIdmeVerifyRequest,
        createIdmeVerifySuccess,
        createIdmeVerifyFailure,
      ],
    },
  };
}

let tokenizerPromise;

function loadTokenizer({
  script = config.get('public.tfgTokenizer.script'),
} = {}) {
  if (!tokenizerPromise) {
    tokenizerPromise = new Promise((resolve, reject) => {
      loadScript(script, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(window.tfgtoken);
        }
      });
    });
  }
  return tokenizerPromise;
}

class TokenizeError extends Error {
  constructor(action) {
    super(action);
    const { payload } = action;
    this.name = 'TokenizeError';
    this.message = payload.message;
    this.statusCode = payload && payload.code ? payload.code : undefined;
  }
}

export function tokenizeCreditCard({ cardInfo }) {
  return async (dispatch, getState) => {
    const { customerId } = getState().membership;
    const { storeGroupId } = getState().storeGroup;
    const { customerAuth } = getState().session;

    dispatch(tokenizeCreditCardRequest());

    const tokenizer = await loadTokenizer();

    const formData = {
      card_num: cardInfo.cardNum,
      card_code: cardInfo.cardCode,
      store_group_id: storeGroupId,
      customer_id: customerId,
      jwt_authorization: customerAuth,
    };

    return new Promise((resolve, reject) => {
      const onSuccess = (outputForm) => {
        const outputData = tokenizer.serializeObject(outputForm);
        resolve(
          dispatch(
            tokenizeCreditCardSuccess({
              cardBin: outputData.card_bin,
              cardGateway: outputData.card_gateway,
              cardNum: outputData.card_token,
              cardCode: outputData.card_code,
              cardType: outputData.card_type,
              customerId: parseInt(outputData.customer_id, 10),
              storeGroupId: parseInt(outputData.store_group_id, 10),
            })
          )
        );
      };
      const onError = async (error) => {
        const action = await dispatch(tokenizeCreditCardFailure(error));
        reject(new TokenizeError(action));
      };

      tokenizer.postToTokenServer(formData, onSuccess, onError);
    });
  };
}

// end point to retrieve retail stores by
// 1. by point(point=<latitude>,<longitude>)
// 2. by zip/country (If doing a zip code search this must be provided also)
// 3. by city (City name, radius not utilized, if state is also supplied)
// 4. by state (State abbreviation or fully spelled out, radius not utilized)
// 5. by storeIds (Comma separated list of storeIds to search for.)
// 6. by member profile address (if no query params are passed).
// 7. by radius in miles
export function loadRetailStores(params = {}) {
  return (dispatch, getState) => {
    const existingRecords = getState().retailStores;
    const key = JSON.stringify(['retailStores', params]);
    let shouldFetch = false;
    if (existingRecords[key]) {
      if (existingRecords[key].networkStatus.isLoading) {
        // Already loading, do nothing.
        debug(
          'Skipping Retail Stores fetch because it is already loading.',
          key
        );
      } else if (!existingRecords[key].networkStatus.isUpToDate) {
        shouldFetch = true;
      }
    } else {
      shouldFetch = true;
    }

    if (shouldFetch) {
      return dispatch({
        bentoApi: {
          endpoint: 'accounts/me/retailstores',
          method: 'GET',
          searchParams: params,
          requestKey: key,
          actions: [
            (payload, meta) =>
              loadRetailStoresRequest(payload, { ...meta, key }),
            (payload, meta) =>
              loadRetailStoresSuccess(payload, { ...meta, key }),
            (payload, meta) =>
              loadRetailStoresFailure(payload, { ...meta, key }),
          ],
        },
      });
    } else {
      debug(
        'Skipping retail store fetch because it is already up-to-date.',
        key
      );
    }
  };
}

export function loadWaitlist({ page = 1, pageSize = 24 } = {}) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/waitlist',
      requestKey: JSON.stringify(['waitlist', page, pageSize]),
      searchParams: { page, pageSize },
      actions: [loadWaitlistRequest, loadWaitlistSuccess, loadWaitlistFailure],
    },
  };
}

export function loadWaitlistIds() {
  return {
    bentoApi: {
      endpoint: 'accounts/me/waitlist/ids',
      actions: [
        loadWaitlistIdsRequest,
        loadWaitlistIdsSuccess,
        loadWaitlistIdsFailure,
      ],
    },
  };
}

export function addWaitlistItem(productId, options = {}) {
  const payload = {
    productId,
    waitlistTypeId: WaitlistType.PREORDER,
    ...options,
  };

  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/waitlist',
      json: payload,
      actions: [
        addWaitlistItemRequest,
        addWaitlistItemSuccess,
        addWaitlistItemFailure,
      ],
    },
  };
}

export function addWaitlistBundle(setId, componentProductIds, options = {}) {
  const payload = {
    setId,
    componentProductIds,
    waitlistTypeId: WaitlistType.PREORDER,
    ...options,
  };

  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'accounts/me/waitlist/sets',
      json: payload,
      actions: [
        addWaitlistBundleRequest,
        addWaitlistBundleSuccess,
        addWaitlistBundleFailure,
      ],
    },
  };
}

export function loadForeignMembership(storeGroupLabel) {
  return (dispatch, getState, context) => {
    const state = getState().foreignMembership;
    const group = state[storeGroupLabel];
    let shouldFetch = false;

    if (group) {
      if (group.networkStatus.isLoading) {
        // Already loading, do nothing.
      } else if (!group.networkStatus.isUpToDate) {
        shouldFetch = true;
      }
    } else {
      shouldFetch = true;
    }

    if (!shouldFetch) {
      return;
    }

    return dispatch({
      bentoApi: {
        endpoint: `accounts/me/memberships/${storeGroupLabel}`,
        requestKey: `loadForeignMembership:${storeGroupLabel}`,
        actions: [
          (payload, meta) =>
            loadForeignMembershipRequest(payload, {
              ...meta,
              storeGroupLabel,
            }),
          (payload, meta) =>
            loadForeignMembershipSuccess(payload, {
              ...meta,
              storeGroupLabel,
            }),
          (payload, meta) =>
            loadForeignMembershipFailure(payload, {
              ...meta,
              storeGroupLabel,
            }),
        ],
      },
    });
  };
}

export function loadMembershipSuggestedProducts({
  page = 1,
  pageSize = 24,
} = {}) {
  return async (dispatch, getState, context) => {
    return dispatch({
      bentoApi: {
        endpoint: 'accounts/me/products/suggested',
        requestKey: JSON.stringify([
          'membershipSuggestedProducts',
          page,
          pageSize,
        ]),
        // It is required, but we already took care of it above.
        sessionRequired: false,
        searchParams: { page, pageSize },
        schema: [MasterProduct],
        actions: [
          loadMembershipSuggestedProductsRequest,
          loadMembershipSuggestedProductsSuccess,
          loadMembershipSuggestedProductsFailure,
        ],
      },
    });
  };
}

export const addressesReducer = createReducer(initialState.addresses, {
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [loadAddressesRequest]: startLoading,
  [loadAddressesFailure]: stopLoading,
  [loadAddressesSuccess]: (state, action) => {
    state.addresses = action.payload;
    state.networkStatus = upToDateStatus();
  },
  [createAddressSuccess]: invalidate,
  [updateAddressSuccess]: invalidate,
  [setDefaultAddressSuccess]: invalidate,
  [deleteAddressSuccess]: invalidate,
});

export const customerReducer = createReducer(initialState.customer, {
  [invalidateProfile]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [logInRequest]: startLoading,
  [logInFailure]: stopLoading,
  [logInSuccess]: (state, action) => {
    return {
      ...action.payload.customer,
      networkStatus: upToDateStatus(),
    };
  },
  [signUpRequest]: startLoading,
  [signUpFailure]: stopLoading,
  [signUpSuccess]: (state, action) => {
    return {
      ...action.payload.customer,
      networkStatus: upToDateStatus(),
    };
  },
  [activateTrialToMembershipRequest]: startLoading,
  [activateTrialToMembershipFailure]: stopLoading,
  [activateTrialToMembershipSuccess]: invalidate,
  [loadProfileRequest]: startLoading,
  [loadProfileFailure]: stopLoading,
  [loadProfileSuccess]: (state, action) => {
    return {
      ...action.payload,
      networkStatus: upToDateStatus(),
    };
  },
  [updateProfileRequest]: startLoading,
  [updateProfileFailure]: stopLoading,
  [updateProfileSuccess]: (state, action) => {
    return {
      ...action.payload,
      networkStatus: upToDateStatus(),
    };
  },
  [loadPhoneNumberRequest]: startLoading,
  [loadPhoneNumberFailure]: stopLoading,
  [loadPhoneNumberSuccess]: (state, action) => {
    // `phoneNumber` is kept separately, in the `phoneNumber` state/reducer.
    const { phoneNumber, ...profile } = action.payload;
    return {
      ...profile,
      networkStatus: upToDateStatus(),
    };
  },
});

export const phoneNumberReducer = createReducer(initialState.phoneNumber, {
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [invalidatePhoneNumber]: invalidate,
  [loadPhoneNumberRequest]: startLoading,
  [loadPhoneNumberFailure]: stopLoading,
  [loadPhoneNumberSuccess]: (state, action) => {
    const { phoneNumber } = action.payload;
    state.phoneNumber = phoneNumber;
    state.networkStatus = upToDateStatus();
  },
});

export const copsSegmentTraitsReducer = createReducer(
  initialState.copsSegmentTraits,
  {
    [logInSuccess]: invalidate,
    [signUpSuccess]: invalidate,
    [loadSessionSuccess]: invalidateOnCustomerChange,
    [loadCopsSegmentTraitsRequest]: startLoading,
    [loadCopsSegmentTraitsFailure]: stopLoading,
    [loadCopsSegmentTraitsSuccess]: (state, action) => {
      state.traits = action.payload;
      state.networkStatus = upToDateStatus();
    },
  }
);

export const loyaltyReducer = createReducer(initialState.loyalty, {
  [invalidateLoyaltyDetails]: invalidate,
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [logOutSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [updateMembershipStatusSuccess]: invalidate,
  [loadLoyaltyDetailsRequest]: startLoading,
  [loadLoyaltyDetailsFailure]: stopLoading,
  [loadLoyaltyDetailsSuccess]: (state, action) => {
    const redemption = action.payload.redemption[0] || null;
    const tier = action.payload.tier[0] || null;
    state.redemption = redemption;
    state.tier = tier;
    state.networkStatus = upToDateStatus();
  },
  [redeemLoyaltyPromoSuccess]: (state) => {
    state.networkStatus = outOfDateStatus();
  },
});

export const loyaltyPlansReducer = createReducer(initialState.loyaltyPlans, {
  [updateMembershipStatusSuccess]: invalidate,
  [loadLoyaltyPlansRequest]: startLoading,
  [loadLoyaltyPlansFailure]: stopLoading,
  [loadLoyaltyPlansSuccess]: (state, action) => {
    return {
      ...action.payload,
      networkStatus: upToDateStatus(),
    };
  },
});

const upsertAction = (state, { actionId, quantity }) => {
  const existingAction = state.actions.find(
    (action) => action.actionId === actionId
  );
  const isQtyUndefined = typeof quantity === 'undefined';
  if (existingAction) {
    existingAction.quantity = isQtyUndefined
      ? existingAction.quantity + 1
      : quantity;
  } else {
    state.actions.push({
      actionId,
      quantity: isQtyUndefined ? 1 : quantity,
    });
  }
};

export const loyaltyPointsForActionsProgressReducer = createReducer(
  initialState.loyaltyPointsForActionsProgress,
  {
    [logInSuccess]: invalidate,
    [signUpSuccess]: invalidate,
    [logOutSuccess]: invalidate,
    [loadSessionSuccess]: invalidateOnCustomerChange,
    [loadLoyaltyPointsForActionsProgressRequest]: startLoading,
    [loadLoyaltyPointsForActionsProgressFailure]: stopLoading,
    [loadLoyaltyPointsForActionsProgressSuccess]: (state, action) => {
      state.actions = action.payload;
      state.networkStatus = upToDateStatus();
    },
    [forceLoyaltyPointsForActionsProgress]: (state, action) => {
      upsertAction(state, {
        actionId: action.payload.actionId,
        quantity: action.payload.quantity,
      });
    },
    [sendPointsForActionsEventSuccess]: (state, action) => {
      // Perform an optimistic update to the state (since this action is async)
      upsertAction(state, {
        actionId: action.payload.actionId,
      });
    },
  }
);

export function forceLoyaltyPointsForAction(action) {
  return (dispatch) => dispatch(forceLoyaltyPointsForActionsProgress(action));
}

export const loyaltyRewardPromosReducer = createReducer(
  initialState.loyaltyRewardPromos,
  {
    [invalidateLoyaltyDetails]: invalidate,
    [logInSuccess]: invalidate,
    [signUpSuccess]: invalidate,
    [loadSessionSuccess]: invalidateOnCustomerChange,
    [updateMembershipStatusSuccess]: invalidate,
    [loadLoyaltyRewardPromosRequest]: startLoading,
    [loadLoyaltyRewardPromosFailure]: stopLoading,
    [loadLoyaltyRewardPromosSuccess]: (state, action) => {
      state.promos = action.payload;
      state.networkStatus = upToDateStatus();
    },
    [redeemLoyaltyPromoSuccess]: (state) => {
      state.networkStatus = outOfDateStatus();
    },
  }
);

export const membershipCancellationReasonsReducer = createReducer(
  initialState.membershipCancellationReasons,
  {
    [loadMembershipCancellationReasonsRequest]: startLoading,
    [loadMembershipCancellationReasonsFailure]: stopLoading,
    [loadMembershipCancellationReasonsSuccess]: (state, action) => {
      state.reasons = action.payload;
      state.networkStatus = upToDateStatus();
    },
  }
);

export const wishlistReducer = createReducer(initialState.wishlist, {
  [invalidateWishlist]: invalidate,
  [addWishlistItemSuccess]: invalidate,
  [deleteWishlistItemSuccess]: invalidate,
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  // Updating membership status invalidates the wishlist because it's possible
  // that the returned products include personalized prices.
  [updateMembershipStatusSuccess]: (state, action) => {
    // Only invalidate when there are items in the wishlist. We don't expect
    // *different* items when membership status is updated, just potentially
    // different prices. So if the wishlist is empty, there's no need to refetch
    // it!
    if (state.items.length) {
      invalidate(state);
    }
  },
  [loadWishlistRequest]: startLoading,
  [loadWishlistFailure]: stopLoading,
  [loadWishlistSuccess]: (state, action) => {
    let { page, pageSize, items: pageItems, total } = action.payload;
    const startIndex = (page - 1) * pageSize;
    const stopIndex = startIndex + pageItems.length;
    // Sometimes the total is wrong: when you remove the last item, the total
    // seems to stay at 1 even though no items are returned.
    if (pageItems.length < pageSize) {
      if (stopIndex < total) {
        const wrongTotal = total;
        total = stopIndex;
        debug(
          '%c!%c Wishlist API returned inconsistent total (%s); overriding to %s. See: TRORR-219',
          'color: red',
          '',
          wrongTotal,
          total
        );
      } else if (startIndex > 0 && total === 1) {
        debug(
          '%c!%c Wishlist API total (%s) may be incorrect, but using it anyway. See: TRORR-219',
          'color: red',
          '',
          total
        );
      }
    }
    const nextItems = state.items.slice(0);
    if (nextItems.length > total) {
      nextItems.length = total;
    } else if (nextItems.length < stopIndex && stopIndex <= total) {
      nextItems.length = stopIndex;
    }
    nextItems.splice(startIndex, pageItems.length, ...pageItems);

    state.items = nextItems;
    state.total = total;
    // FIXME: This actually isn't necessarily true, since the wishlist is
    // paginated. More accurately, this particular page of results is up to
    // date. Multiple requests for different wishlist pages could also be
    // happening in parallel, which is why a single `isLoading` status is
    // difficult to manage. We'll have to use a more complicated API for
    // paginated result sets if we want to rely on this.
    state.networkStatus = upToDateStatus();
  },
});

export const wishlistIdsReducer = createReducer(initialState.wishlistIds, {
  [invalidateWishlistIds]: invalidate,
  [addWishlistItemSuccess]: invalidate,
  [deleteWishlistItemSuccess]: invalidate,
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [loadWishlistIdsRequest]: startLoading,
  [loadWishlistIdsFailure]: stopLoading,
  [loadWishlistIdsSuccess]: (state, action) => {
    const { total, masterProductIds } = action.payload;
    state.total = total;
    state.masterProductIds = masterProductIds;
    state.networkStatus = upToDateStatus();
  },
});

export const waitlistReducer = createReducer(initialState.waitlist, {
  [addWaitlistItemSuccess]: invalidate,
  [addWaitlistBundleSuccess]: invalidate,
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [loadWaitlistRequest]: startLoading,
  [loadWaitlistFailure]: stopLoading,
  [loadWaitlistSuccess]: (state, action) => {
    const { page, pageSize, items: pageItems, total } = action.payload;
    const startIndex = (page - 1) * pageSize;
    const stopIndex = startIndex + pageItems.length;

    const nextItems = state.items.slice(0);
    if (nextItems.length > total) {
      nextItems.length = total;
    } else if (nextItems.length < stopIndex && stopIndex <= total) {
      nextItems.length = stopIndex;
    }

    nextItems.splice(startIndex, pageItems.length, ...pageItems);

    state.items = nextItems;
    state.total = total;
    state.networkStatus = upToDateStatus();
  },
});

export const waitlistIdsReducer = createReducer(initialState.waitlistIds, {
  [addWaitlistItemSuccess]: invalidate,
  [addWaitlistBundleSuccess]: invalidate,
  [invalidateWaitlistIds]: invalidate,
  [deleteWaitlistItemSuccess]: invalidate,
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [loadWaitlistIdsRequest]: startLoading,
  [loadWaitlistIdsFailure]: stopLoading,
  [loadWaitlistIdsSuccess]: (state, action) => {
    const { items: waitlistItemIds } = action.payload;

    state.items = waitlistItemIds;
    state.total = waitlistItemIds.length;
    state.networkStatus = upToDateStatus();
  },
});

export const customerDetailsReducer = createReducer(
  initialState.customerDetails,
  {
    [invalidateCustomerDetails]: (state, action) => {
      const keys = Object.keys(state);
      keys.forEach((key) => {
        // Reset error, since this can also prevent it from being refetched.
        state[key].error = null;
        invalidate(state[key]);
      });
    },
    [loadCustomerDetailsRequest]: (state, action) => {
      action.meta.keysToFetch.forEach((name) => {
        if (!state[name]) {
          state[name] = {
            fetchedDate: action.meta.fetchedDate,
            networkStatus: outOfDateStatus(),
          };
        }
        startLoading(state[name]);
      });
    },
    [loadCustomerDetailsFailure]: (state, action) => {
      action.meta.keysToFetch.forEach((name) => {
        state[name].error = CustomerDetailError.OTHER;
        stopLoading(state[name]);
      });
    },
    [loadCustomerDetailsSuccess]: (state, action) => {
      const { fetchedDate } = action.meta;
      const keyFetchStatus = new Map(
        action.meta.keysToFetch.map((name) => [name, false])
      );
      action.payload.forEach((detail) => {
        const { name, value, dateTimeAdded } = detail;
        keyFetchStatus.set(name, true);
        state[name] = {
          value,
          dateTimeAdded,
          error: null,
          fetchedDate,
          networkStatus: upToDateStatus(),
        };
      });
      keyFetchStatus.forEach((fetched, name) => {
        if (!fetched) {
          // We requested this key, but did not receive it. It must not be
          // populated for this user?
          state[name] = {
            value: null,
            dateTimeAdded: null,
            error: null,
            fetchedDate,
            networkStatus: upToDateStatus(),
          };
        }
      });
    },
    [updateCustomerDetailSuccess]: (state, action) => {
      const { name, value } = action.payload;
      const existingRecord = state[name];
      state[name] = {
        ...existingRecord,
        // Optimistically update the value, although it may be overwritten
        // when we actually refetch.
        value,
        // Reset error so that it will refetch even if it errored last time.
        error: null,
        // We don't necessarily know the new value of `dateTimeAdded`, since
        // the API could assign it or ignore the value we send if it's already
        // set. So invalidate it and don't set `dateTimeAdded` here; it will be
        // populated when refetched.
        networkStatus: outOfDateStatus(),
      };
    },
  }
);

export const emailPreferencesReducer = createReducer(
  initialState.emailPreferences,
  {
    [invalidateEmailPreferences]: invalidate,
    [logInSuccess]: invalidate,
    [signUpSuccess]: invalidate,
    [loadSessionSuccess]: invalidateOnCustomerChange,
    [loadEmailPreferencesRequest]: startLoading,
    [loadEmailPreferencesFailure]: stopLoading,
    [loadEmailPreferencesSuccess]: (state, action) => {
      return {
        ...action.payload,
        networkStatus: upToDateStatus(),
      };
    },
    [updateEmailPreferencesRequest]: startLoading,
    [updateEmailPreferencesFailure]: stopLoading,
    // The response is a 204 No Content. We could use the sent `payload` to
    // populate some fields here. But there are more fields in the actual
    // response than are likely to be sent in an update, so even if we did that,
    // marking the state as `isUpToDate` would not really be accurate.
    // Invalidate so that it will be correctly refetched if requested.
    [updateEmailPreferencesSuccess]: invalidate,
  }
);

export const membershipReducer = createReducer(initialState.membership, {
  [invalidateMembership]: invalidate,
  [activateTrialToMembershipSuccess]: invalidate,
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [updateMembershipStatusSuccess]: invalidate,
  [snoozeMembershipSuccess]: invalidate,
  [addMembershipSignupRecordSuccess]: invalidate,
  [forceRegDateSuccess]: invalidate,
  [loadMembershipRequest]: startLoading,
  [loadMembershipFailure]: stopLoading,
  [loadMembershipSuccess]: (state, action) => {
    return {
      ...action.payload,
      networkStatus: upToDateStatus(),
    };
  },
  [purchaseMembershipPeriodSuccess]: invalidate,
});

const invalidateMembershipDetailsState = (state, action) => {
  const keys = Object.keys(state);
  keys.forEach((key) => {
    // Reset error, since this can also prevent it from being refetched.
    state[key].error = null;
    invalidate(state[key]);
  });
};

export const membershipDetailsReducer = createReducer(
  initialState.membershipDetails,
  {
    [invalidateMembershipDetails]: invalidateMembershipDetailsState,
    [loadSessionSuccess]: (state, action) => {
      if (action.meta.customerDidChange) {
        invalidateMembershipDetailsState(state, action);
      }
    },
    [loadMembershipDetailsRequest]: (state, action) => {
      action.meta.keysToFetch.forEach((name) => {
        if (!state[name]) {
          state[name] = {
            fetchedDate: action.meta.fetchedDate,
            networkStatus: outOfDateStatus(),
          };
        }
        startLoading(state[name]);
      });
    },
    [loadMembershipDetailsFailure]: (state, action) => {
      action.meta.keysToFetch.forEach((name) => {
        state[name].error = MembershipDetailError.OTHER;
        stopLoading(state[name]);
      });
    },
    [loadMembershipDetailsSuccess]: (state, action) => {
      const { fetchedDate } = action.meta;
      const keyFetchStatus = new Map(
        action.meta.keysToFetch.map((name) => [name, false])
      );
      action.payload.forEach((detail) => {
        const { name, value } = detail;
        keyFetchStatus.set(name, true);
        state[name] = {
          value,
          error: null,
          fetchedDate,
          networkStatus: upToDateStatus(),
        };
      });
      keyFetchStatus.forEach((fetched, name) => {
        if (!fetched) {
          // We requested this key, but did not receive it. It must not be
          // populated for this user?
          state[name] = {
            value: null,
            error: null,
            fetchedDate,
            networkStatus: upToDateStatus(),
          };
        }
      });
    },
    [updateMembershipDetailRequest]: (state, action) => {
      if (!state[action.meta.name]) {
        state[action.meta.name] = {
          networkStatus: outOfDateStatus(),
        };
      }
      startLoading(state[action.meta.name]);
    },
    [updateMembershipDetailFailure]: (state, action) => {
      const { name } = action.meta;
      state[name].networkStatus = upToDateStatus();
    },
    [updateMembershipDetailSuccess]: (state, action) => {
      const { name, value } = action.meta;
      state[name].value = value;
      state[name].networkStatus = upToDateStatus();
    },
  }
);

export const membershipPeriodReducer = createReducer(
  initialState.membershipPeriod,
  {
    [forceDateDue]: (state, action) => {
      state.forceDateDue = action.payload;
    },
    [forceSkipAllowed]: (state, action) => {
      state.forceSkipAllowed = action.payload;
    },
    [invalidateMembershipPeriod]: invalidate,
    [snoozeMembershipSuccess]: invalidate,
    [logInSuccess]: invalidate,
    [signUpSuccess]: invalidate,
    [updateMembershipStatusSuccess]: invalidate,
    [loadSessionSuccess]: invalidateOnCustomerChange,
    [skipMembershipPeriodSuccess]: invalidate,
    [loadMembershipPeriodRequest]: startLoading,
    [loadMembershipPeriodFailure]: stopLoading,
    [loadMembershipPeriodSuccess]: (state, action) => {
      return {
        forceDateDue: state.forceDateDue,
        forceSkipAllowed: state.forceSkipAllowed,
        ...action.payload,
        networkStatus: upToDateStatus(),
      };
    },
    [purchaseMembershipPeriodSuccess]: invalidate,
  }
);

export const paymentsReducer = createReducer(initialState.payments, {
  [invalidatePayments]: invalidate,
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [loadPaymentsRequest]: startLoading,
  [loadPaymentsFailure]: stopLoading,
  [loadPaymentsSuccess]: (state, action) => {
    state.paymentMethods = action.payload;
    state.networkStatus = upToDateStatus();
  },
  [addPaymentSuccess]: invalidate,
  [updatePaymentSuccess]: invalidate,
});

export const retailStoresReducer = createReducer(initialState.retailStores, {
  [loadRetailStoresRequest]: (state, action) => {
    if (!state[action.meta.key]) {
      state[action.meta.key] = {
        networkStatus: outOfDateStatus(),
      };
    }
    startLoading(state[action.meta.key]);
  },
  [loadRetailStoresFailure]: (state, action) => {
    if (state[action.meta.key]) {
      state[action.meta.key].error = RetailStoresError.OTHER;
      state[action.meta.key].fetchedDate = action.meta.fetchedDate;
      stopLoading(state[action.meta.key]);
    }
  },
  [loadRetailStoresSuccess]: (state, action) => {
    const result = action.payload;
    state[action.meta.key] = {
      results: result,
      fetchedDate: action.meta.fetchedDate,
      error: null,
      networkStatus: upToDateStatus(),
    };
  },
});

function invalidateForeignMemberships(state) {
  Object.keys(state).forEach((storeGroupLabel) => {
    const feature = state[storeGroupLabel];
    feature.networkStatus = outOfDateStatus();
  });
}

export const foreignMembershipReducer = createReducer(
  initialState.foreignMembership,
  {
    [invalidateForeignMembership]: invalidateForeignMemberships,
    [invalidateMembership]: invalidateForeignMemberships,
    [logInSuccess]: invalidateForeignMemberships,
    [signUpSuccess]: invalidateForeignMemberships,
    [loadSessionSuccess]: (state, action) => {
      if (action.meta.customerDidChange) {
        invalidateForeignMemberships(state);
      }
    },
    [updateMembershipStatusSuccess]: invalidateForeignMemberships,
    [addMembershipSignupRecordSuccess]: invalidateForeignMemberships,
    [loadForeignMembershipRequest]: (state, action) => {
      if (!state[action.meta.storeGroupLabel]) {
        state[action.meta.storeGroupLabel] = {
          networkStatus: outOfDateStatus(),
        };
      }
      startLoading(state[action.meta.storeGroupLabel]);
    },
    [loadForeignMembershipFailure]: (state, action) => {
      if (state[action.meta.storeGroupLabel]) {
        state[action.meta.storeGroupLabel].error = ForeignMembershipError.OTHER;
        state[action.meta.storeGroupLabel].fetchedDate =
          action.meta.fetchedDate;
        stopLoading(state[action.meta.storeGroupLabel]);
      }
    },
    [loadForeignMembershipSuccess]: (state, action) => {
      state[action.meta.storeGroupLabel] = {
        ...action.payload,
        networkStatus: upToDateStatus(),
      };
    },
  }
);

export const getAddresses = createSelector(
  [(state) => state.addresses],
  (addresses) => addresses
);

export const getDefaultAddress = createSelector(
  [(state) => state.addresses.addresses],
  (addresses) => {
    return addresses.find((address) => address.isDefault);
  }
);

export const getCustomerDetail = createCachedSelector(
  [(state, key) => state.customerDetails[key]],
  (customerDetail) =>
    customerDetail || {
      fetchedDate: null,
      networkStatus: outOfDateStatus(),
    }
)((state, key) => key);

export const getFullBirthday = createSelector(
  [(state) => state.customer.profile],
  (profile) => {
    return getISOBirthDateString(profile);
  }
);

export const getMembershipCancellationReasons = createSelector(
  [(state) => state.membershipCancellationReasons],
  (membershipCancellationReasons) => {
    return membershipCancellationReasons;
  }
);

export const getCustomer = createSelector(
  [(state) => state.customer, getFullBirthday],
  (customer, fullBirthday) => {
    return { ...customer, fullBirthday };
  }
);

export const getCopsSegmentTraits = createSelector(
  [(state) => state.copsSegmentTraits],
  (traits) => {
    return traits;
  }
);

export const getCurrentPostRegHoursFunction = createSelector(
  [
    (state) => state.membership.dateTimeAdded,
    (state) => state.membership.onSiteMembershipExperience,
    getNewDateFunction,
  ],
  (dateTimeAdded, onSiteMembershipExperience, getCurrentDate) => {
    const startDateTimeString = onSiteMembershipExperience || dateTimeAdded;
    return getPostRegHoursFunction({
      dateTimeAdded: startDateTimeString,
      getCurrentDate,
    });
  }
);

export const getCurrentPostRegDaysFunction = createSelector(
  [
    (state) => state.membership.dateTimeAdded,
    (state) => state.membership.onSiteMembershipExperience,
    getNewDateFunction,
  ],
  (dateTimeAdded, onSiteMembershipExperience, getCurrentDate) => {
    const startDateTimeString = onSiteMembershipExperience || dateTimeAdded;
    return getPostRegDaysFunction({
      dateTimeAdded: startDateTimeString,
      getCurrentDate,
    });
  }
);

export const getLoyaltyDetails = createSelector(
  [(state) => state.loyalty],
  (loyalty) => {
    return loyalty;
  }
);

export const getLoyaltyPlans = createSelector(
  [(state) => state.loyaltyPlans],
  (loyaltyPlans) => {
    return loyaltyPlans;
  }
);

export const getLoyaltyPointsForActionsProgress = createSelector(
  [(state) => state.loyaltyPointsForActionsProgress],
  (loyaltyPointsForActionsProgress) => {
    return loyaltyPointsForActionsProgress;
  }
);

export const getLoyaltyRewardPromos = createSelector(
  [(state) => state.loyaltyRewardPromos],
  (loyaltyRewardPromos) => {
    return loyaltyRewardPromos;
  }
);

export const getMembershipHelpers = createSelector(
  [
    (state) => state.membership.statusCode,
    (state) => state.membership.membershipLevelGroupId,
  ],
  (statusCode, membershipLevelGroupId) => {
    return getDerivedMembershipValues({ statusCode, membershipLevelGroupId });
  }
);

export const getMembership = createSelector(
  [
    (state) => state.membership,
    getMembershipHelpers,
    getCurrentPostRegHoursFunction,
    getCurrentPostRegDaysFunction,
  ],
  (
    membership,
    membershipHelpers,
    getCurrentPostRegHours,
    getCurrentPostRegDays
  ) => {
    return {
      ...membership,
      ...membershipHelpers,
      getCurrentPostRegHours,
      getCurrentPostRegDays,
    };
  }
);

/**
 * @param {Object} state Redux state
 * @param {String[]} names Array of of Membership Detail keys/names
 * @returns {String[]} Membership detail values
 */
export const getMembershipDetails = createCachedSelector(
  [(state, names) => names, (state) => state.membershipDetails],
  (names, membershipDetails) => {
    if (!names) {
      return membershipDetails;
    }

    if (typeof names === 'string') {
      names = [names];
    }

    const details = {};

    names.forEach((name) => {
      details[name] = membershipDetails[name] || {
        value: null,
        error: null,
        fetchedDate: null,
        networkStatus: outOfDateStatus(),
      };
    });

    return details;
  }
)((state, names) => JSON.stringify(['membershipDetails', names]));

export const getMembershipDetail = createCachedSelector(
  [(state, name) => name, (state) => state.membershipDetails],
  (name, membershipDetails) => {
    if (membershipDetails[name]) {
      return membershipDetails[name];
    }
    return {
      value: null,
      error: null,
      fetchedDate: null,
      networkStatus: outOfDateStatus(),
    };
  }
)((state, name) => JSON.stringify(['membershipDetail', name]));

// TODO: Ensure this works with API response during STM period.
export const getMembershipPeriodHelpers = createSelector(
  [
    (state) => state.membershipPeriod.forceDateDue,
    (state) => state.membershipPeriod.dateDue,
    (state) => state.membershipPeriod.forceSkipAllowed,
    (state) => state.membershipPeriod.skipAllowed,
    (state) => state.membershipPeriod.statusCode,
    getNewDateFunction,
  ],
  (
    forceDateDue,
    dateDue,
    forceSkipAllowed,
    skipAllowed,
    statusCode,
    getCurrentDate
  ) => {
    return getDerivedSkipTheMonthStatus({
      forceDateDue,
      dateDue,
      forceSkipAllowed,
      skipAllowed,
      statusCode,
      getCurrentDate,
    });
  }
);

export const getMembershipPeriod = createSelector(
  [(state) => state.membershipPeriod, getMembershipPeriodHelpers],
  (membershipPeriod, membershipPeriodHelpers) => {
    return {
      ...membershipPeriod,
      ...membershipPeriodHelpers,
    };
  }
);

export const getDefaultPaymentMethod = createSelector(
  [(state) => state.payments.paymentMethods],
  (paymentMethods) => {
    return (
      paymentMethods.find((paymentMethod) => paymentMethod.isDefault) ||
      paymentMethods[0]
    );
  }
);

export const getWishlistIds = createSelector(
  [(state) => state.wishlistIds],
  (wishlistIds) => {
    let idSet;
    return {
      ...wishlistIds,
      // Instead of using `wishlistIds.masterProductIds.includes(masterProductId)`,
      // consumers should use this `hasProductId` helper instead. It will build
      // a Set once and use `has()`, which will perform a more efficient O(1)
      // check each time instead of O(n).
      hasProductId(masterProductId) {
        if (!idSet) {
          idSet = new Set(wishlistIds.masterProductIds);
        }
        return idSet.has(masterProductId);
      },
    };
  }
);

export const getRetailStores = createCachedSelector(
  [(state, key) => state.retailStores[JSON.stringify(['retailStores', key])]],
  (retailStores) =>
    retailStores || {
      fetchedDate: null,
      networkStatus: outOfDateStatus(),
    }
)((state, key) => JSON.stringify(['retailStores', key]));

export const getPreorderList = createSelector(
  [(state) => state.waitlist],
  (waitlist) => {
    let preorderListIds;
    const preorderListItems = waitlist.items.filter(
      ({ waitlistTypeId }) => waitlistTypeId === WaitlistType.PREORDER
    );
    return {
      networkStatus: waitlist.networkStatus,
      total: waitlist.total,
      items: preorderListItems,
      // The API returns the total amount of items in the waitlist regardless of waitlistTypeId.
      // `totalFetched` is the amount of "preorder" items within the pages fetched so far.
      totalFetched: preorderListItems.length,
      hasProductId(productId) {
        if (!preorderListIds) {
          const ids = [];
          preorderListItems.forEach(({ productId, bundleItems }) => {
            if (bundleItems) {
              bundleItems.forEach(({ productId }) => {
                ids.push(productId);
              });
            } else {
              ids.push(productId);
            }
          });
          preorderListIds = new Set(ids);
        }
        return preorderListIds.has(productId);
      },
    };
  }
);

export const getWaitlist = createSelector(
  [(state) => state.waitlist],
  (waitlist) => {
    let waitlistIds;
    const waitlistItems = waitlist.items.filter(
      ({ waitlistTypeId }) =>
        waitlistTypeId === WaitlistType.EMAIL_NOTIFICATION ||
        waitlistTypeId === WaitlistType.AUTO_PURCHASE
    );
    return {
      networkStatus: waitlist.networkStatus,
      total: waitlist.total,
      items: waitlistItems,
      // The API returns the total amount of items in the waitlist regardless of waitlistTypeId.
      // `totalFetched` is the amount of "waitlist" items within the pages fetched so far.
      totalFetched: waitlistItems.length,
      hasProductId(productId) {
        if (!waitlistIds) {
          const ids = [];
          waitlistItems.forEach(({ productId, bundleItems }) => {
            if (bundleItems) {
              bundleItems.forEach(({ productId }) => {
                ids.push(productId);
              });
            } else {
              ids.push(productId);
            }
          });
          waitlistIds = new Set(ids);
        }
        return waitlistIds.has(productId);
      },
    };
  }
);

export const getWaitlistIds = createSelector(
  [(state) => state.waitlistIds],
  (waitlistIds) => {
    const productIds = waitlistIds.items.filter(
      ({ waitlistTypeId }) =>
        waitlistTypeId === WaitlistType.EMAIL_NOTIFICATION ||
        waitlistTypeId === WaitlistType.AUTO_PURCHASE
    );
    const ids = productIds.map(({ productId }) => productId);
    const idSet = new Set(ids);

    return {
      items: productIds,
      total: waitlistIds.total,
      networkStatus: waitlistIds.networkStatus,
      hasProductId(productId) {
        return idSet.has(productId);
      },
    };
  }
);

export const getForeignMembershipHelpers = createSelector(
  [
    (foreignMembership) => foreignMembership?.statusCode || null,
    (foreignMembership) => foreignMembership?.membershipLevelGroupId || null,
    (foreignMembership) => foreignMembership?.isForeignVip,
  ],
  (statusCode, membershipLevelGroupId, isForeignVip) => {
    if (!statusCode && isForeignVip) {
      statusCode = MembershipStatusCode.ACTIVE;
    }
    if (statusCode) {
      return getDerivedMembershipValues({ statusCode, membershipLevelGroupId });
    }
  }
);

export const getForeignMembership = createCachedSelector(
  [
    (state, storeGroupLabel) => storeGroupLabel,
    (state, storeGroupLabel) => state.foreignMembership[storeGroupLabel],
    (state, storeGroupLabel) =>
      getForeignMembershipHelpers(state.foreignMembership[storeGroupLabel]),
  ],
  (storeGroupLabel, foreignMembership = {}, membershipHelpers) => {
    return {
      // Default networkStatus prevents the hook from re-fetching on failures.
      networkStatus: upToDateStatus(),
      storeGroupLabel,
      ...foreignMembership,
      ...membershipHelpers,
    };
  }
)((state, storeGroupLabel) => storeGroupLabel);

export default {
  id: 'accounts',
  reducerMap: {
    addresses: addressesReducer,
    copsSegmentTraits: copsSegmentTraitsReducer,
    customer: customerReducer,
    customerDetails: customerDetailsReducer,
    emailPreferences: emailPreferencesReducer,
    foreignMembership: foreignMembershipReducer,
    loyalty: loyaltyReducer,
    loyaltyPlans: loyaltyPlansReducer,
    loyaltyPointsForActionsProgress: loyaltyPointsForActionsProgressReducer,
    loyaltyRewardPromos: loyaltyRewardPromosReducer,
    membership: membershipReducer,
    membershipCancellationReasons: membershipCancellationReasonsReducer,
    membershipDetails: membershipDetailsReducer,
    membershipPeriod: membershipPeriodReducer,
    orderDetails: orderDetailsReducer,
    payments: paymentsReducer,
    phoneNumber: phoneNumberReducer,
    retailStores: retailStoresReducer,
    returnSettings: returnSettingsReducer,
    wishlist: wishlistReducer,
    wishlistIds: wishlistIdsReducer,
    waitlist: waitlistReducer,
    waitlistIds: waitlistIdsReducer,
  },
  sagas: [],
  initialActions: [initMembershipPeriod(), initForceRegistrationDate()],
};

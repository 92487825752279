import React, { useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

import { Component as AssetProvider } from '../AssetProvider';

export default function AssetFilter({ children, filter }) {
  const { filter: filterFromContext } = useContext(AssetProvider.Context);
  const newFilter = useMemo(
    () => (filter ? filter(filterFromContext) : filterFromContext),
    [filter, filterFromContext]
  );
  return <AssetProvider filter={newFilter}>{children}</AssetProvider>;
}

AssetFilter.propTypes = {
  children: PropTypes.node,
  /**
   * A filter creation function that will return a filter suitable for passing
   * to Array's `find` or `filter` methods. The function will be called with
   * the `parentFilter` (from any ancestor providers, or the default). Filters
   * may choose to include or ignore the existing parent filter in their
   * criteria.
   */
  filter: PropTypes.func,
};

import React, { useCallback } from 'react';

import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useCustomer } from '../../../../techstyle-shared/react-accounts';
import { useRegion } from '../../../../techstyle-shared/react-intl';
import BirthdayPicker from '../BirthdayPicker';
import Button from '../Button';
import FormField from '../FormField';
import { desktop } from '../styles';
import { passwordLengthCheck } from '../utils/formValidation';

export const FormGrid = styled.div`
  display: grid;

  ${desktop`
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  `}
`;

export const FormActions = styled.div`
  margin: 24px 0;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;

  ${desktop`
    padding: 0 145px;
    margin-top: 24px;
  `}
`;

const BirthdayField = ({ dayMessage, monthMessage, yearMessage, ...rest }) => {
  const region = useRegion();
  return (
    <Field>
      {({ field }) => (
        <BirthdayPicker {...rest}>
          {region === 'EU' ? (
            <>
              <BirthdayPicker.Day
                dayMessage={dayMessage}
                birthDay={field.value.birthDay}
                birthMonth={field.value.birthMonth}
                birthYear={field.value.birthYear}
                onChange={field.onChange}
              />
              <BirthdayPicker.Month
                birthMonth={field.value.birthMonth}
                monthMessage={monthMessage}
                onChange={field.onChange}
              />
            </>
          ) : (
            <>
              <BirthdayPicker.Month
                birthMonth={field.value.birthMonth}
                monthMessage={monthMessage}
                onChange={field.onChange}
              />
              <BirthdayPicker.Day
                dayMessage={dayMessage}
                birthDay={field.value.birthDay}
                birthMonth={field.value.birthMonth}
                birthYear={field.value.birthYear}
                onChange={field.onChange}
              />
            </>
          )}
          <BirthdayPicker.Year
            birthYear={field.value.birthYear}
            yearMessage={yearMessage}
            onChange={field.onChange}
          />
        </BirthdayPicker>
      )}
    </Field>
  );
};

BirthdayField.propTypes = {
  dayMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  monthMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  yearMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export const NameBirthdayForm = ({
  errorMessages,
  children,
  onSubmit,
  ...rest
}) => {
  const { firstName, lastName, profile } = useCustomer();

  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    birthDay: profile.birthDay,
    birthMonth: profile.birthMonth,
    birthYear: profile.birthYear,
  };

  const validate = useCallback(
    (values) => {
      const errors = {};

      const firstName = values.firstName.trim();
      const lastName = values.lastName.trim();

      if (firstName.length === 0) {
        errors.firstName = errorMessages.firstName;
      }

      if (lastName.length === 0) {
        errors.lastName = errorMessages.lastName;
      }

      return errors;
    },
    [errorMessages]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      {...rest}
    >
      {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
    </Formik>
  );
};

NameBirthdayForm.TextField = FormField;
NameBirthdayForm.FormAction = FormActions;
NameBirthdayForm.FormGrid = FormGrid;
NameBirthdayForm.BirthdayField = BirthdayField;

NameBirthdayForm.propTypes = {
  children: PropTypes.any,
  errorMessages: PropTypes.object,
  onSubmit: PropTypes.func,
};

const emailFormat = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);
// regex tester link: https://regex101.com/r/A8g1f3/2

export const EmailForm = ({ errorMessages, children, onSubmit, ...rest }) => {
  const { email } = useCustomer();

  const initialValues = {
    email: email,
    password: '',
  };

  const validate = useCallback(
    (values) => {
      const errors = {};

      const email = values.email.trim();
      const password = values.password.trim();
      const isSupportedEmail = emailFormat.test(email);

      if (email.length === 0) {
        errors.email = errorMessages.invalidEmail;
      } else if (!isSupportedEmail) {
        errors.email = errorMessages.unsupportedEmail;
      }

      if (password && password.length < 6) {
        errors.password = errorMessages.invalidPassword;
      }

      return errors;
    },
    [errorMessages]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      {...rest}
    >
      {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
    </Formik>
  );
};

EmailForm.TextField = FormField;
EmailForm.FormGrid = FormGrid;
EmailForm.FormAction = FormActions;

EmailForm.propTypes = {
  children: PropTypes.any,
  errorMessages: PropTypes.object,
  onSubmit: PropTypes.func,
};

const ForgotPasswordButton = styled(Button)`
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  text-decoration: underline;
  font-size: 14px;
  line-height: 1.14;
  text-align: right;
`;

export const PasswordForm = ({
  errorMessages,
  children,
  onSubmit,
  ...rest
}) => {
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validate = useCallback(
    (values) => {
      const errors = {};

      if (!passwordLengthCheck(values.currentPassword)) {
        errors.currentPassword = errorMessages.lengthTooShort;
      }

      if (!passwordLengthCheck(values.newPassword)) {
        errors.newPassword = errorMessages.lengthTooShort;
      }

      if (!passwordLengthCheck(values.confirmPassword)) {
        errors.confirmPassword = errorMessages.lengthTooShort;
      }

      if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = errorMessages.noMatch;
      }

      return errors;
    },
    [errorMessages]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      {...rest}
    >
      {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
    </Formik>
  );
};

PasswordForm.TextField = FormField;
PasswordForm.FormAction = FormActions;
PasswordForm.FormGrid = FormGrid;
PasswordForm.ForgotPasswordButton = ForgotPasswordButton;

PasswordForm.propTypes = {
  children: PropTypes.any,
  errorMessages: PropTypes.object,
  onSubmit: PropTypes.func,
};

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Picture from '../Picture';

const ReviewPicture = styled.div`
  width: 116px;
  cursor: pointer;
  margin-top: 16px;
`;

const ReviewsPhoto = ({ reviewerPhoto, onPictureClick, ...rest }) => {
  const photo = [
    {
      images: [
        {
          url: reviewerPhoto,
          width: 200,
        },
      ],
    },
  ];

  if (!reviewerPhoto) {
    return null;
  }

  return (
    <ReviewPicture onClick={() => onPictureClick(photo)}>
      <Picture
        sources={photo}
        breakpoints={[{ minWidth: 0, pictureWidth: 103 }]}
        {...rest}
      />
    </ReviewPicture>
  );
};

ReviewsPhoto.propTypes = {
  onPictureClick: PropTypes.any,
  reviewerPhoto: PropTypes.any,
};

export default ReviewsPhoto;

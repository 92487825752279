import React from 'react';

import PropTypes from 'prop-types';

import useTheme from '../useTheme';

export default function ErrorIcon({
  size = '1em',
  color = '#A14569',
  ...rest
}) {
  const theme = useTheme();
  const fill = theme.colors[color] || color;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.14191 12.5624C9.14191 12.7231 9.01691 12.8571 8.85619 12.8571H7.14191C6.98119 12.8571 6.85619 12.7231 6.85619 12.5624V10.866C6.85619 10.7053 6.98119 10.5714 7.14191 10.5714H8.85619C9.01691 10.5714 9.14191 10.7053 9.14191 10.866V12.5624ZM9.12405 9.22315C9.11512 9.33922 8.98119 9.4285 8.82048 9.4285H7.16869C6.99905 9.4285 6.86512 9.33922 6.86512 9.22315L6.71334 5.14279C6.71334 5.08922 6.74012 4.99993 6.80262 4.95529C6.85619 4.91065 6.93655 4.85707 7.01691 4.85707H8.98119C9.06155 4.85707 9.14191 4.91065 9.19548 4.95529C9.25798 4.99993 9.28477 5.07136 9.28477 5.12493L9.12405 9.22315ZM8.99905 0.88386C8.80262 0.517788 8.41869 0.285645 7.99905 0.285645C7.57941 0.285645 7.19548 0.517788 6.99905 0.88386L0.141908 13.4553C-0.0545201 13.8035 -0.0455916 14.2321 0.159766 14.5803C0.365123 14.9285 0.740123 15.1428 1.14191 15.1428H14.8562C15.258 15.1428 15.633 14.9285 15.8383 14.5803C16.0437 14.2321 16.0526 13.8035 15.8562 13.4553L8.99905 0.88386Z"
        fill={fill}
      />
    </svg>
  );
}

ErrorIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

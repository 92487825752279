import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import styled, { css, keyframes } from 'styled-components';

import VisuallyHidden from '../../../shared/components/VisuallyHidden';
import { Drawer } from '../../../techstyle-shared/react-components';
import { useIntl } from '../../../techstyle-shared/react-intl';

export const durations = {
  FADE: 240,
  INIT: 360,
  POLL: 100,
};

const gradientTranslate = keyframes`
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
`;

const dialogStyle = css`
  min-height: 0;
  height: auto;
  width: 100%;
  align-items: stretch;
`;

const Wrapper = styled.div`
  opacity: 0;
  transition: opacity ${durations.FADE}ms ease-out;
  ${({ $isLoading }) =>
    $isLoading &&
    `
    opacity: 1;
  `}
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.sizes.xxxs}px;
  background: ${({ theme }) => theme.colors.backgroundCallout};
  background-size: 200vw 100%;
  background-position: right center;
  animation: ${gradientTranslate} 1s ease-in-out infinite alternate;
  transition: transform ${durations.POLL}ms ease-in-out;
  transform-origin: left center;
  transform: rotateY(90deg);
  ${({ $progress }) => `
    // Value is used to simulate an advancing percentage by rotating the progress bar.
    // Converts percentage range [0, 100] to degree rotation range [90, 0]
    transform: rotateY(${90 - $progress * 0.9}deg);
  `}
`;

const PageTransitionProgressBar = () => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const intervalId = useRef(null);
  const [progress, setProgress] = useState(0);

  const loadingMessage = formatMessage({
    id: 'router.content_loading',
    defaultMessage: 'Content loading...',
  });

  useEffect(() => {
    let setIsLoadingTimeout;
    let setIsMountedTimeout;

    const handleRouteChangeStart = () => {
      setIsMounted(true);
      setIsMountedTimeout = setTimeout(() => {
        setIsLoading(true);
      }, durations.POLL);
    };

    const handleRouteChangeComplete = () => {
      setIsLoading(false);
      setIsLoadingTimeout = setTimeout(() => {
        setIsMounted(false);
      }, durations.FADE);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);
    return () => {
      clearTimeout(setIsMountedTimeout);
      clearTimeout(setIsLoadingTimeout);
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [router]);

  const increment = useCallback(() => {
    setProgress((progress) => {
      // Inspired by NProgress
      // https://github.com/rstacruz/nprogress/blob/e1a8b7fb6e059085df5f83c45d3c2308a147ca18/nprogress.js#L167
      if (progress < 100) {
        if (progress >= 0 && progress < 20) {
          progress += 10;
        } else if (progress >= 20 && progress < 50) {
          progress += 4;
        } else if (progress >= 50 && progress < 80) {
          progress += 2;
        } else if (progress >= 80 && progress < 99) {
          progress += 0.5;
        }
      }
      return progress;
    });
  }, []);

  useEffect(() => {
    if (isMounted && isLoading && !intervalId.current) {
      increment();
      intervalId.current = setInterval(increment, durations.POLL);
    }
  }, [increment, intervalId, isLoading, isMounted]);

  useEffect(() => {
    if (isMounted && !isLoading && progress >= 10 && progress < 100) {
      setProgress(100);
    }
  }, [isLoading, isMounted, progress]);

  useEffect(() => {
    if (!isMounted) {
      setProgress(0);
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, [intervalId, isMounted]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return (
    <Drawer
      animationDisabled
      dialogStyle={dialogStyle}
      escapeExits={false}
      isModal={false}
      isOpen={isMounted}
      position="top"
      title={loadingMessage}
      scrollDisabled={false}
      unmountOnExit
    >
      <Wrapper $isLoading={isLoading}>
        <span id="page-transition-progress-bar">
          <VisuallyHidden>{loadingMessage}</VisuallyHidden>
        </span>
        <ProgressBar
          aria-describedby="page-transition-progress-bar"
          $progress={progress}
        />
      </Wrapper>
    </Drawer>
  );
};

export default PageTransitionProgressBar;

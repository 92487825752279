import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  loadAllRetailStores,
  loadRetailStoresWithFilter,
} from './retailStoresModule';

export default function useRetailStoresActions() {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          loadAllRetailStores,
          loadRetailStoresWithFilter,
        },
        dispatch
      ),
    [dispatch]
  );

  return actions;
}

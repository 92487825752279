import React from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  useMembership,
  useLoyalty,
} from '../../../../techstyle-shared/react-accounts';
import { isTruthyQueryParameter } from '../../../../techstyle-shared/redux-core';
import { desktop, mobile } from '../styles';
import useBreakpoint from '../useBreakpoint';

const InfoBox = styled.div`
  padding: 0 21px;
  border-left: 1px solid ${({ theme }) => theme.colors.lightMineral};
  font-weight: 600;
  font-size: 14px;

  ${mobile`
    border: none;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-weight: 400;
  `}
`;

const PointCreditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${InfoBox}:first-child {
    border: none;
  }

  ${mobile`
    width: auto;
    flex-direction: column;
    gap: 8px;
  `}
`;

const Amount = styled.p``;

const Label = styled.p``;

const CaretRight = styled.span`
  margin-left: 3px;
  ${desktop`
    display: none;
  `}
`;

const MemberCredit = ({ memberCreditLabel, ...rest }) => {
  const { membershipCredits } = useMembership();

  return (
    <InfoBox {...rest}>
      <Amount>{membershipCredits}</Amount>
      <Label>{memberCreditLabel}</Label>
    </InfoBox>
  );
};

MemberCredit.propTypes = {
  memberCreditLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const StoreCredit = ({ storeCreditLabel, ...rest }) => {
  const { storeCreditBalance } = useMembership();
  return (
    <InfoBox {...rest}>
      <Amount>{storeCreditBalance}</Amount>
      <Label>{storeCreditLabel}</Label>
    </InfoBox>
  );
};

StoreCredit.propTypes = {
  storeCreditLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const RewardPoint = ({ rewardPointLabel, ...rest }) => {
  const { redemption } = useLoyalty();
  const redemptionBalance =
    redemption && redemption.balance >= 0 ? redemption.balance : 0;

  const { isMobile } = useBreakpoint();
  const { query } = useRouter();
  const isBMIGAccountEnabled = isTruthyQueryParameter(query.force_bmig_routes);
  const hrefToReward = isBMIGAccountEnabled
    ? `/account_bmig/rewards`
    : `/rewards`;
  return (
    <InfoBox {...rest}>
      <Amount>{redemptionBalance}</Amount>
      {isMobile ? (
        <Label as="a" href={hrefToReward}>
          {rewardPointLabel}
          <CaretRight>{'>'}</CaretRight>
        </Label>
      ) : (
        <Label>{rewardPointLabel}</Label>
      )}
    </InfoBox>
  );
};

RewardPoint.propTypes = {
  rewardPointLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default function PointsAndCredits({ children, ...rest }) {
  return <PointCreditContainer {...rest}>{children}</PointCreditContainer>;
}

PointsAndCredits.Amount = Amount;
PointsAndCredits.Label = Label;
PointsAndCredits.InfoBox = InfoBox;
PointsAndCredits.MemberCredit = MemberCredit;
PointsAndCredits.StoreCredit = StoreCredit;
PointsAndCredits.RewardPoint = RewardPoint;

PointsAndCredits.propTypes = {
  children: PropTypes.node,
};

import React, { useCallback, useMemo, useState } from 'react';

import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';

export const MiniCartContext = React.createContext({
  canScroll: false,
  setCanScroll: () => {},
  handleOverflowChange: () => {},
  itemCountPrevious: 0,
  setItemCountPrevious: () => {},
});

export const useMiniCartContext = () => {
  const context = React.useContext(MiniCartContext);

  if (isUndefined(context)) {
    throw new Error(
      'MiniCart Context must be used inside an MiniCart Provider'
    );
  }

  return context;
};

const MiniCartContextProvider = ({ children }) => {
  const [canScroll, setCanScroll] = useState({ down: false, up: false });
  const [itemCountPrevious, setItemCountPrevious] = useState(0);

  const handleOverflowChange = useCallback((state) => {
    setCanScroll(state.canScroll);
  }, []);

  const value = useMemo(
    () => ({
      canScroll,
      setCanScroll,
      handleOverflowChange,
      itemCountPrevious,
      setItemCountPrevious,
    }),
    [canScroll, handleOverflowChange, itemCountPrevious]
  );

  return (
    <MiniCartContext.Provider value={value}>
      {children}
    </MiniCartContext.Provider>
  );
};

MiniCartContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MiniCartContextProvider;

import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadAssets, invalidateAssets } from './assetsModule';

export default function useAssetActions() {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          loadAssets,
          invalidateAssets,
        },
        dispatch
      ),
    [dispatch]
  );

  return actions;
}

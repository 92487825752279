import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { FaAngleDoubleUp, FaAngleDoubleDown } from 'react-icons/fa';
import styled from 'styled-components';

import { VirtualScroll } from '../../../../techstyle-shared/react-virtual-scroll';
import useAdminTools from '../useAdminTools';

function getType(obj) {
  let type = Object.prototype.toString.call(obj).slice(8, -1);

  if (type === 'String') {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    if (urlRegex.test(obj)) {
      type = 'URL';
    }
  }
  return type;
}

function isTabularData(value) {
  const type = getType(value);
  return type === 'Object' || type === 'Array';
}

const Table = styled.table`
  ${({ tableLayout }) => ({ tableLayout })};
  min-width: 100%;
  margin: 0;
  border: 2px solid ${({ theme }) => theme.colors.tableBorder};
  border-collapse: collapse;
  color: ${({ theme }) => theme.colors.darkGray};

  & & {
    border: 0;
  }

  & + & {
    margin-top: 20px;
  }
`;

const DataLink = styled.a`
  text-decoration: underline;
`;

const cellPadding = '4px 8px';

const TitleCell = styled.th`
  padding: ${cellPadding};
  text-align: left;
  background: white;
`;

const LabelCell = styled.td`
  vertical-align: top;
  border-right: 1px solid ${({ theme }) => theme.colors.tableBorder};
  padding: ${({ isTabular }) => (isTabular ? 0 : cellPadding)};
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  background-color: ${({ isEven, isOpen, theme }) => {
    if (!isOpen) {
      return theme.colors.cellBgDisabled;
    } else {
      return isEven ? theme.colors.cellHeadBgAlt : theme.colors.cellHeadBg;
    }
  }};
  color: ${({ isOpen, theme }) =>
    isOpen ? 'inherit' : theme.colors.disabledText};
`;

const Cell = styled.td`
  border: 0;
  padding: ${({ isOpen, isTabular }) =>
    isOpen && isTabular ? 0 : cellPadding};
  background-color: ${({ isEven, isOpen, theme }) => {
    if (!isOpen) {
      return theme.colors.cellBgDisabled;
    } else {
      return isEven ? theme.colors.cellBgAlt : theme.colors.cellBg;
    }
  }};
  color: ${({ isOpen, theme }) =>
    isOpen ? 'inherit' : theme.colors.disabledText};
  white-space: pre-wrap;
`;

const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableBorder};

  tbody:last-child > &:last-child {
    border-bottom: 0;
  }
`;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: ${cellPadding};
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: left;
  background: transparent;
  color: inherit;
`;

const ToggleIcon = styled.span.attrs((props) => ({
  children: props.isOpen ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />,
}))`
  margin-left: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.mediumGray};

  > * {
    vertical-align: middle;
  }
`;

export function DataRow({ data, label, index, depth = 0, sortKeys, children }) {
  const isTabular = isTabularData(data);
  const [isOpen, setIsOpen] = useState(true);
  const isEven = index % 2 === 0;
  const valueType = getType(data);

  let tableSize;
  switch (valueType) {
    case 'Array':
      tableSize = data.length;
      break;
    case 'Object':
      tableSize = Object.keys(data).length;
      break;
    default:
      tableSize = 0;
  }

  const toggleChildren = useCallback(
    () => {
      setIsOpen(!isOpen);
    },
    [isOpen],
    setIsOpen
  );

  const closedValue = isTabular
    ? `(${valueType} with ${tableSize.toLocaleString()} ${
        tableSize === 1 ? 'item' : 'items'
      })`
    : null;

  return (
    <Row>
      <LabelCell
        isEven={isEven}
        isOpen={isOpen}
        isTabular={isTabular}
        title={valueType}
      >
        {isTabular ? (
          <ToggleButton
            onClick={toggleChildren}
            aria-label={isOpen ? 'Collapse' : 'Expand'}
            title={`${valueType} (click to ${isOpen ? 'collapse' : 'expand'})`}
          >
            {label}
            <ToggleIcon isOpen={isOpen} />
          </ToggleButton>
        ) : (
          label
        )}
      </LabelCell>
      <Cell isEven={isEven} isTabular={isTabular} isOpen={isOpen}>
        {isOpen ? (
          <DataTable
            data={data}
            isOpen={isOpen}
            depth={depth}
            sortKeys={sortKeys}
          />
        ) : (
          closedValue
        )}
      </Cell>
    </Row>
  );
}

DataRow.propTypes = {
  children: PropTypes.node,
  data: PropTypes.any,
  depth: PropTypes.number,
  index: PropTypes.number,
  label: PropTypes.string,
  sortKeys: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool),
  ]),
};

export default function DataTable({
  batchSize = 24,
  children,
  data,
  depth = 0,
  sortKeys = false,
  title,
  ...rest
}) {
  const dataType = getType(data);
  const isTabular = isTabularData(data);

  const { activePanelNode } = useAdminTools();

  const sortTheseKeys = Array.isArray(sortKeys)
    ? sortKeys[depth] || false
    : sortKeys;

  const orderedKeys = useMemo(() => {
    if (!isTabular) {
      return [];
    }
    const keys = Object.keys(data);
    if (sortTheseKeys && !Array.isArray(data)) {
      keys.sort();
    }
    return keys;
  }, [data, isTabular, sortTheseKeys]);

  const maxKeyLength = useMemo(
    () =>
      Math.max((title || '').length, ...orderedKeys.map((key) => key.length)),
    [orderedKeys, title]
  );

  const rows = useMemo(
    () => orderedKeys.map((key) => [key, data[key]]),
    [data, orderedKeys]
  );

  // Only activate fancy-pants viewport culling optimization when there are a
  // lot of rows.
  const willUseCulling = rows.length > batchSize;
  const labelWidth = `calc(${maxKeyLength}ch + 32px)`;

  const getItems = useCallback(
    ({ startIndex, stopIndex }) => {
      return rows.slice(startIndex, stopIndex);
    },
    [rows]
  );

  const renderItems = useCallback(
    ({ isVisible, items, lastKnownHeight, startIndex, stopIndex }) => {
      if (isVisible && items) {
        return items.map(([key, value], index) => (
          <DataRow
            key={key}
            index={index}
            data={value}
            depth={depth + 1}
            label={key}
            sortKeys={sortKeys}
          />
        ));
      }
      const height =
        lastKnownHeight != null
          ? lastKnownHeight
          : 27 * (stopIndex - startIndex);
      return (
        <Row style={{ height }}>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </Row>
      );
    },
    [depth, sortKeys]
  );

  if (!isTabular) {
    if (dataType === 'URL') {
      return (
        <DataLink rel="noopener noreferrer" target="_blank" href={data}>
          {data}
        </DataLink>
      );
    }
    if (typeof data === 'function') {
      return '[Function]';
    }
    return `${data}`;
  }

  return (
    <Table {...rest} tableLayout="fixed">
      <colgroup>
        <col style={{ width: labelWidth }} />
        <col />
      </colgroup>
      {title ? (
        <thead>
          <Row>
            <TitleCell colSpan={2}>{title}</TitleCell>
          </Row>
        </thead>
      ) : null}
      {willUseCulling ? (
        <VirtualScroll
          as="tbody"
          root={activePanelNode}
          itemCount={rows.length}
          renderItems={renderItems}
          getItems={getItems}
          targetBatchSize={batchSize}
          maxBatchCount={null}
        />
      ) : (
        <tbody>{renderItems({ isVisible: true, items: rows })}</tbody>
      )}
    </Table>
  );
}

DataTable.propTypes = {
  batchSize: PropTypes.number,
  children: PropTypes.node,
  data: PropTypes.any,
  depth: PropTypes.number,
  open: PropTypes.bool,
  /**
   * Whether to sort the keys in the rendered table when `data` is an object.
   * If supplied as an array, it specifies what `sortKeys` value to use at
   * different depths.
   */
  sortKeys: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.bool),
  ]),
  title: PropTypes.node,
};

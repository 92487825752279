import { createAction } from '../../../techstyle-shared/redux-core';

export const loadAllRetailStoresRequest = createAction(
  'retail-stores/loadRetailStoresRequest'
);
export const loadAllRetailStoresSuccess = createAction(
  'retail-stores/loadRetailStoresSuccess'
);
export const loadAllRetailStoresFailure = createAction(
  'retail-stores/loadRetailStoresFailure'
);

export function loadAllRetailStores() {
  const key = JSON.stringify(['allRetailStores']);

  return {
    bentoApi: {
      endpoint: 'retailstores',
      requestKey: key,
      actions: [
        (payload, meta) => {
          return loadAllRetailStoresRequest(payload, { ...meta, key });
        },
        (payload, meta) =>
          loadAllRetailStoresSuccess(payload, { ...meta, key }),
        (payload, meta) =>
          loadAllRetailStoresFailure(payload, { ...meta, key }),
      ],
    },
  };
}

/* 
options should contain a searchParams object, which should contain a
filterString formatted like key=value, joined by "&"
for example:
region=NA
region=NA&yitty_products=Y

See swagger docs:
https://retailstore-api-retail.techstyle.tech/_swagger/#/RetailStore/RetailstoreController_getRetailStoreList
*/
export function loadRetailStoresWithFilter(options = {}) {
  const { searchParams } = options;
  const key = 'retailstores' + JSON.stringify(searchParams);

  return {
    bentoApi: {
      endpoint: 'retailstores',
      requestKey: key,
      searchParams: searchParams,
      actions: [
        (payload, meta) => {
          return loadAllRetailStoresRequest(payload, { ...meta, key });
        },
        (payload, meta) =>
          loadAllRetailStoresSuccess(payload, { ...meta, key }),
        (payload, meta) =>
          loadAllRetailStoresFailure(payload, { ...meta, key }),
      ],
    },
  };
}

export default {
  id: 'retailstores',
};

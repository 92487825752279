import React from 'react';

import config from 'config';
import dynamic from 'next/dynamic';

import { AdminGuard } from '../../../techstyle-shared/react-admin';

import DefaultCurrencyFormatProvider from './CurrencyFormatProvider';
import initLocaleData from './initLocaleData';
import intlModule, { loadResourceBundles } from './intlModule';
import IntlProvider from './IntlProvider';
import logger from './logger';

const debug = logger.extend('intlExtension');

const IntlAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './IntlAdminPanel')
);

/**
 * The default function used for detecting locale. It uses the `tld` that was
 * detected from the request along with a TLD -> locale mapping configured in
 * `public.intl.locales` to select a locale, or `public.intl.defaultLocale` if
 * there is no match.
 */
export function defaultDetectLocale(req) {
  const locales = config.get('public.intl.locales');
  const defaultLocale = config.get('public.intl.defaultLocale');
  return locales[req.context.domain.tld] || defaultLocale;
}

export default function intlExtension(options = {}) {
  if (typeof options === 'function') {
    options = { detectLocale: options };
  }
  const {
    detectLocale = defaultDetectLocale,
    globalResourceBundles,
    CurrencyFormatProvider = DefaultCurrencyFormatProvider,
  } = options;

  initLocaleData();

  return (registry) => ({
    id: 'intl',
    server: process.browser
      ? undefined
      : {
          // FIXME: `node-config` requires that all config is loaded before
          // being accessed, after which the config is frozen. It's possible to
          // disable this, but I'd rather use a better config library instead.
          init() {
            require('./config');
          },
          configure(server) {
            const { default: localeMiddleware } = require('./localeMiddleware');
            const { default: geoMiddleware } = require('./geoMiddleware');

            // Abundance of caution: ensure backwards compatibility if someone
            // upgrades `@techstyle/react-intl` without upgrading
            // `@techstyle/next-server`. TODO: This can be removed when everyone
            // is up to date.
            const addMiddleware = (name, middleware) => {
              if (server.useTracked) {
                server.useTracked(name, middleware);
              } else {
                server.use(middleware);
              }
            };

            addMiddleware('geoMiddleware', geoMiddleware());
            addMiddleware('localeMiddleware', localeMiddleware(detectLocale));
          },
        },
    document: process.browser
      ? undefined
      : {
          getInitialProps: {
            enhance(getInitialProps) {
              return async (ctx) => {
                const initialProps = await getInitialProps(ctx);
                // Add `lang` attribute to root `<html>` element.
                initialProps.htmlProps.lang = ctx.req.context.locale;
                // Add locale data to a `<script>` tag that will be read by
                // `initLocaleData` (see above) in the browser.
                initialProps.beforeNextScript.push(
                  <script
                    dangerouslySetInnerHTML={{
                      __html: ctx.req.context.localeData,
                    }}
                  />
                );
                return initialProps;
              };
            },
          },
        },
    app: {
      render(props, children) {
        return (
          <IntlProvider>
            <>
              <CurrencyFormatProvider>{children}</CurrencyFormatProvider>
              <AdminGuard>
                <IntlAdminPanel />
              </AdminGuard>
            </>
          </IntlProvider>
        );
      },
    },
    page({ resourceBundles = [] }) {
      if (config.has('public.intl.globalResourceBundles')) {
        // eslint-disable-next-line no-console
        console.warn(
          'Config option public.intl.globalResourceBundles has been deprecated, please migrate to the globalResourceBundles option on intlExtension.'
        );
      }

      const getResourceBundles = registry.utils.getMergedArrayOption({
        globalResourceBundles,
        resourceBundles,
      });

      return {
        async getInitialProps(ctx) {
          const [resourceBundles, meta] = await getResourceBundles(ctx);
          if (resourceBundles.length) {
            debug(
              'Loading %s %s added by %s.',
              resourceBundles.length,
              resourceBundles.length === 1 ? 'bundle' : 'bundles',
              meta.sources.join(', ')
            );
            await ctx.store.dispatch(loadResourceBundles(resourceBundles));
          }
        },
      };
    },
    redux: {
      modules: [intlModule],
    },
  });
}

import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

export default function StyleContext({ children, context }) {
  const createTheme = useCallback(
    (parentTheme) => {
      if (!context) {
        return parentTheme;
      }

      const newContext = { ...parentTheme.context };
      let hasChanged = false;

      if (Array.isArray(context)) {
        context.forEach((name) => {
          if (!newContext[name]) {
            newContext[name] = true;
            hasChanged = true;
          }
        });
      } else if (typeof context === 'object') {
        Object.keys(context).forEach((name) => {
          const value = context[name];
          if (newContext[name] !== value) {
            newContext[name] = value;
            hasChanged = true;
          }
        });
      } else if (!newContext[context]) {
        newContext[context] = true;
        hasChanged = true;
      }

      if (!hasChanged) {
        return parentTheme;
      }

      return { ...parentTheme, context: newContext };
    },
    [context]
  );

  return <ThemeProvider theme={createTheme}>{children}</ThemeProvider>;
}

StyleContext.propTypes = {
  children: PropTypes.node,
  context: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]).isRequired,
};

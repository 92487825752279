import { useEffect, useCallback, useState } from 'react';

import { useRenderTime, parseDate } from '../../../techstyle-shared/redux-core';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

function useForceUpdate() {
  const [, setUpdateCount] = useState(0);
  return useCallback(
    () => setUpdateCount((updateCount) => updateCount + 1),
    []
  );
}

function padNumber(n) {
  return n < 10 ? `0${n}` : n.toString();
}

export default function useCountdown(endDate) {
  let countdownDate;
  if (endDate != null) {
    countdownDate = parseDate(endDate);
    if (Number.isNaN(countdownDate.getTime())) {
      throw new Error('endDate is not a valid date.');
    }
  }
  const getTime = useRenderTime();
  const forceUpdate = useForceUpdate();

  const hasDate = countdownDate != null;
  let timeRemainingMs = 0;
  let isTimerActive = false;
  let seconds = 0;
  let minutes = 0;
  let hours = 0;
  let days = 0;

  if (hasDate) {
    timeRemainingMs = countdownDate.getTime() - getTime();
    isTimerActive = timeRemainingMs > 0;
    if (isTimerActive) {
      seconds = Math.floor(timeRemainingMs / ONE_SECOND) % 60;
      minutes = Math.floor(timeRemainingMs / ONE_MINUTE) % 60;
      hours = Math.floor(timeRemainingMs / ONE_HOUR) % 24;
      days = Math.floor(timeRemainingMs / ONE_DAY);
    }
  }

  const countdownDateUnix = countdownDate?.getTime();

  useEffect(() => {
    if (isTimerActive) {
      let timeout;
      const tick = () => {
        forceUpdate();
        // Account for potential lags in setTimeout
        const msUntilNextSecondChange = Math.floor(
          ((countdownDateUnix - Date.now()) % 1000) + 1
        );
        timeout = setTimeout(tick, msUntilNextSecondChange);
      };
      tick();
      return () => clearTimeout(timeout);
    }
  }, [countdownDateUnix, forceUpdate, isTimerActive]);

  return {
    hasDate,
    isTimerActive,
    timeRemainingMs,
    seconds,
    minutes,
    hours,
    days,
    padNumber,
  };
}

import { useEffect, useRef } from 'react';

import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { urlNoQueryString } from '../../../../techstyle-shared/redux-core';
import {
  routeChangeStart,
  routeChangeComplete,
  routeChangeError,
  newRouteLoaded,
} from '../routesModule';

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default function RouterEvents({ getInitialPropsCount }) {
  const dispatch = useDispatch();
  const nextRouter = useRouter();
  const asPath = nextRouter.asPath;
  const previousAsPath = usePrevious(asPath);

  useEffect(() => {
    // Don't fire on the initial page load; only on changes.
    if (
      getInitialPropsCount > 1 ||
      (previousAsPath &&
        urlNoQueryString(previousAsPath) !== urlNoQueryString(asPath))
    ) {
      dispatch(
        newRouteLoaded(null, {
          url: asPath,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getInitialPropsCount, urlNoQueryString(asPath)]);

  useEffect(() => {
    const handleStart = (url) => {
      dispatch(routeChangeStart(null, { url }));
    };

    const handleComplete = (url) => {
      dispatch(routeChangeComplete(null, { url }));
    };

    const handleError = (err, url) => {
      dispatch(routeChangeError(err, { url }));
    };

    Router.events.on('routeChangeStart', handleStart);
    Router.events.on('routeChangeComplete', handleComplete);
    Router.events.on('routeChangeError', handleError);

    return () => {
      Router.events.off('routeChangeStart', handleStart);
      Router.events.off('routeChangeComplete', handleComplete);
      Router.events.off('routeChangeError', handleError);
    };
  }, [dispatch]);

  return null;
}

RouterEvents.propTypes = {
  getInitialPropsCount: PropTypes.number,
};

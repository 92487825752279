import React, { useMemo } from 'react';

import {
  FormattedMessage as ReactIntlFormattedMessage,
  FormattedHTMLMessage as ReactIntlHTMLFormattedMessage,
} from 'react-intl';
import { useSelector } from 'react-redux';

import useIntl from '../useIntl';
import { detectHTML } from '../utils';

const nbsp = '\u00a0';

type FormattedMessageProps = React.ComponentProps<
  typeof ReactIntlFormattedMessage
> & {
  /**
   * Whether or not to render the message as raw HTML. By default, this will be
   * detected automatically by inspecting the message.
   */
  isHTML?: boolean;
  /**
   * HTML element to render instead of a `<span>`.
   */
  tagName?: string;
  /**
   * CSS `white-space` override. The default `TextComponent` applies `pre-wrap`
   * by default.
   */
  whiteSpace?: string;
  id?: string;
  defaultMessage?: string;
};

type BaseProps = {
  as?: string;
  highlightBundles: boolean;
  whiteSpace?: string;
};

type HighlightBundleProps = BaseProps & {
  highlightBundles: true;
  isGroupLoaded?: boolean;
  isKeyLoaded?: boolean;
  title?: string;
};

type MessageProps = BaseProps | HighlightBundleProps;

/**
 * A wrapped version of `FormattedMessage` from `react-intl` that supports the
 * `highlightBundles` option. If `highlightBundles` is disabled, then there is
 * no difference.
 */
export default function FormattedMessage({
  children,
  isHTML,
  whiteSpace,
  ...props
}: FormattedMessageProps) {
  const intl = useIntl();

  if (!intl) {
    throw new Error(
      'FormattedMessage must be rendered inside an IntlProvider supplied by @techstyle/react-intl.'
    );
  }

  // TO-DO: Remove these "any" when the intl module gets typed
  const highlightBundles = useSelector(
    (state: any) => state.intl.highlightBundles
  ) as boolean;
  const resourceBundles = useSelector((state: any) =>
    highlightBundles ? state.intl.resourceBundles : null
  );

  const { [props.id]: message = props.defaultMessage } = intl.messages;

  isHTML = useMemo(() => {
    if (isHTML != null) {
      return isHTML;
    }
    if (message == null) {
      return false;
    }
    return detectHTML(message);
  }, [isHTML, message]);

  const MessageComponent = isHTML
    ? ReactIntlHTMLFormattedMessage
    : ReactIntlFormattedMessage;

  const renderMessage = useMemo(() => {
    // If a custom `children` prop was already supplied, we can't safely
    // highlight it without potentially affecting layout, so bail out. Consumers
    // should use custom `children` as a last resort; try `tagName` first.
    if (children) {
      return children;
    }

    const isHighlight = (v: any): v is HighlightBundleProps =>
      v.highlightBundles === true;

    const TextComponent = intl.textComponent;

    const messageProps: MessageProps = {
      as: props.tagName,
      highlightBundles,
      whiteSpace,
    };

    if (isHighlight(messageProps)) {
      // Determine whether both the group and key are populated.
      const localeBundles = resourceBundles[intl.locale];
      const groupCode = props.id.split('.', 1)[0];
      const isGroupLoaded =
        localeBundles != null && localeBundles[groupCode] != null;
      const isKeyLoaded = intl.messages[props.id] != null;

      // Generate a status message to show in the `title` attribute.
      messageProps.title = `${
        isGroupLoaded && isKeyLoaded ? '✔︎' : '✘'
      }${nbsp}${props.id}`;
      messageProps.isGroupLoaded = isGroupLoaded;
      messageProps.isKeyLoaded = isKeyLoaded;
    }

    if (isHTML) {
      // eslint-disable-next-line react/display-name
      return (html: any) => (
        <TextComponent
          {...messageProps}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    }
    // eslint-disable-next-line react/display-name
    return (...nodes: React.ReactNode[]) =>
      React.createElement(TextComponent, messageProps, ...nodes);
  }, [
    children,
    highlightBundles,
    intl.locale,
    intl.messages,
    intl.textComponent,
    isHTML,
    props.id,
    props.tagName,
    resourceBundles,
    whiteSpace,
  ]);

  return <MessageComponent {...props}>{renderMessage}</MessageComponent>;
}

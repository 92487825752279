import config from 'config';
import { useSelector } from 'react-redux';

import {
  Gender,
  useCustomerDetails,
  getCustomerDetail,
} from '../../techstyle-shared/react-accounts';
import {
  createSelector,
  getSessionDetail,
  useSessionDetail,
} from '../../techstyle-shared/redux-core';

const GENDER_FILTER_FEMALE = 'female';
const GENDER_FILTER_MALE = 'male';

export function getGridGender(categoryPath) {
  if (config.get('public.brand.onlyWomens')) {
    return Gender.FEMALE;
  }

  if (typeof categoryPath !== 'string') {
    return null;
  }

  const gridGender = categoryPath?.toLowerCase()?.split('/');
  if (gridGender.indexOf('womens') >= 0) {
    return Gender.FEMALE;
  } else if (gridGender.indexOf('mens') >= 0) {
    return Gender.MALE;
  } else {
    return null;
  }
}

export const getGender = createSelector(
  [
    (state) => getCustomerDetail(state, 'gender').value,
    (state) => getSessionDetail(state, 'gender_select_clicked_mens').value,
    (state) => getSessionDetail(state, 'gender_select_clicked_womens').value,
  ],
  (gender, maleSessionFlag, femaleSessionFlag) => {
    let genderFilterString = GENDER_FILTER_FEMALE;
    if (!gender) {
      if (maleSessionFlag === '1') {
        gender = Gender.MALE;
        genderFilterString = GENDER_FILTER_MALE;
      } else if (femaleSessionFlag === '1') {
        gender = Gender.FEMALE;
        genderFilterString = GENDER_FILTER_FEMALE;
      } else {
        gender = Gender.FEMALE;
        genderFilterString = GENDER_FILTER_FEMALE;
      }
    } else if (gender === Gender.MALE) {
      genderFilterString = GENDER_FILTER_MALE;
    }

    return {
      gender,
      genderFilterString,
      isMale: gender === Gender.MALE,
      isFemale: gender === Gender.FEMALE,
    };
  }
);

export default function useGender() {
  // We'll use the selector above to actually get the gender info object, but
  // we still call these hooks in order to trigger loading the details if
  // necessary.
  useCustomerDetails(['gender']);
  useSessionDetail('gender_select_clicked_mens');
  useSessionDetail('gender_select_clicked_womens');
  return useSelector(getGender);
}

import { css } from 'styled-components';

import { mobile } from '../../../techstyle-shared/react-components';

import { fontStacks } from './fonts';

const values = {
  Header100: css`
    font-weight: 900;
    font-size: 100px;
    line-height: ${80 / 100};
    font-family: ${fontStacks.baseFamily};
  `,
  Header60: css`
    font-weight: 900;
    font-size: 60px;
    line-height: ${50 / 60};
    font-family: ${fontStacks.baseFamily};
  `,
  Header48: css`
    font-weight: 700;
    font-size: 48px;
    line-height: ${36 / 48};
    font-family: ${fontStacks.baseFamily};
  `,
  Header40: css`
    font-weight: 900;
    font-size: 40px;
    line-height: ${48 / 40};
    font-family: ${fontStacks.baseFamily};
  `,
  Header32: css`
    font-weight: bold;
    font-size: 32px;
    line-height: ${40 / 32};
    font-family: ${fontStacks.baseFamily};
  `,
  Header22: css`
    font-weight: 700;
    font-size: 22px;
    line-height: ${26 / 22};
    font-family: ${fontStacks.baseFamily};
  `,
  Header20: css`
    font-weight: 700;
    font-size: 20px;
    line-height: ${24 / 20};
    font-family: ${fontStacks.baseFamily};
  `,
  Header18: css`
    font-weight: 700;
    font-size: 18px;
    line-height: ${24 / 18};
    font-family: ${fontStacks.baseFamily};
  `,
  Header16med: css`
    font-weight: 600;
    font-size: 16px;
    line-height: ${20 / 16};
    font-family: ${fontStacks.baseFamily};
  `,
  Header16: css`
    font-weight: 500;
    font-size: 16px;
    line-height: ${20 / 16};
    font-family: ${fontStacks.baseFamily};
  `,
  Header14med: css`
    font-weight: 600;
    font-size: 14px;
    line-height: ${16 / 14};
    font-family: ${fontStacks.baseFamily};
  `,
  Header14: css`
    font-weight: 400;
    font-size: 14px;
    line-height: ${16 / 14};
    font-family: ${fontStacks.baseFamily};
  `,
  Header12med: css`
    font-weight: 600;
    font-size: 12px;
    line-height: ${16 / 12};
    font-family: ${fontStacks.baseFamily};
  `,
  Header12: css`
    font-weight: 450;
    font-size: 12px;
    line-height: ${16 / 12};
    font-family: ${fontStacks.baseFamily};
  `,
  Body24: css`
    font-weight: 400;
    font-size: 24px;
    line-height: ${32 / 24};
    font-family: ${fontStacks.textFamily};
  `,
  Body20: css`
    font-weight: 400;
    font-size: 20px;
    line-height: ${32 / 20};
    font-family: ${fontStacks.textFamily};
  `,
  Body18: css`
    font-weight: 400;
    font-size: 18px;
    line-height: ${24 / 18};
    font-family: ${fontStacks.textFamily};
  `,
  Body16: css`
    font-weight: 400;
    font-size: 16px;
    line-height: ${20 / 16};
    font-family: ${fontStacks.textFamily};
  `,
  Body14: css`
    font-weight: 400;
    font-size: 14px;
    line-height: ${16 / 14};
    font-family: ${fontStacks.textFamily};
  `,
  Body12: css`
    font-weight: 400;
    font-size: 12px;
    line-height: ${16 / 12};
    font-family: ${fontStacks.textFamily};
  `,
  Body12med: css`
    font-weight: 500;
    font-size: 12px;
    line-height: ${16 / 12};
    font-family: ${fontStacks.textFamily};
  `,
  LabelSize24: css`
    font-weight: 500;
    font-size: 24px;
    line-height: ${32 / 24};
    letter-spacing: ${0.2 / 24}em;
    font-family: ${fontStacks.textFamily};
  `,
  LabelSize18: css`
    font-weight: 500;
    font-size: 18px;
    line-height: ${24 / 18};
    letter-spacing: ${0.2 / 18}em;
    font-family: ${fontStacks.textFamily};
  `,
  LabelSize16: css`
    font-weight: 500;
    font-size: 16px;
    line-height: ${24 / 16};
    letter-spacing: ${0.2 / 16}em;
    font-family: ${fontStacks.textFamily};
  `,
  LabelSize14: css`
    font-weight: 500;
    font-size: 14px;
    line-height: ${16 / 14};
    letter-spacing: ${0.2 / 14}em;
    font-family: ${fontStacks.textFamily};
  `,
  LabelSize12: css`
    font-weight: 500;
    font-size: 12px;
    line-height: ${16 / 12};
    letter-spacing: ${0.2 / 12}em;
    font-family: ${fontStacks.textFamily};
  `,
};

const variants = {
  accountConfirmationHeader: {
    textStyle: values.Header32,
  },
  accountConfirmationSubheader: {
    textStyle: values.Body24,
  },
  accountGreetingRewardsLabel: {
    textStyle: values.Header12,
  },
  accountGreetingRewardsValue: {
    textStyle: css`
      color: ${({ theme }) => theme.colors.textEmphasized};
      font-weight: 600;
    `,
  },
  accountSectionHeader: {
    textStyle: css`
      ${values.Header16};
      font-weight: 600;
    `,
  },
  bodyMed: {
    textStyle: values.Body14,
  },
  bodyMedEmphasized: {
    textStyle: values.Header14med,
  },
  formLabelEmphasized: {
    textStyle: css`
      ${values.LabelSize16};
      font-weight: 600;
      letter-spacing: normal;
      line-height: ${20 / 16};
    `,
  },
  cardHeader: {
    textStyle: values.Header18,
  },
  cardEmphasized: {
    textStyle: values.Header12med,
  },
  cardBody: {
    textStyle: css`
      ${values.Body14}
      font-weight: 400;
    `,
  },
  cardBodyMed: {
    textStyle: css`
      ${values.Body16};

      ${mobile`
        font-weight: 500;
      `}
    `,
  },
  cardBodyHeavy: {
    textStyle: css`
      ${values.Body14}
      font-weight: 400;
    `,
  },
  cardBodyLg: {
    textStyle: css`
      ${values.Body16}
      font-weight: 450;
      line-height: 1.5;
    `,
  },
  characterCountLabel: {
    textStyle: css`
      ${values.Header12med};
    `,
  },
  contactFormHeader: {},
  contactFormOptIn: {},
  embroideryNotification: {
    textStyle: css`
      ${values.LabelSize12}
    `,
  },
  embroideryModalCounter: {},
  gridTabOffer: {
    textStyle: css`
      ${values.Header32}
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;

      display: block;
      margin: 32px 0;
    `,
  },
  headlineLarge: {
    textStyle: css`
      ${values.Header48}
      font-weight: 900;
    `,
  },
  headlineMedium: {
    textStyle: values.Header32,
  },
  inlineFigureTitle: {
    textStyle: css`
      ${values.Header20}
      color: ${({ theme }) => theme.colors.textDefault};
    `,
  },
  inlineFigureBody: {
    textStyle: css`
      ${values.Body16}
      color: ${({ theme }) => theme.colors.textDefault};
    `,
  },
  inlineFigureSubtitle: {
    textStyle: css`
      ${values.header16};
      font-weight: 500;
    `,
  },
  displayFieldLabel: {
    textStyle: css`
      ${values.Body16}
      color: ${({ theme }) => theme.colors.textDefault};
    `,
  },
  displayFieldValue: {
    textStyle: css`
      ${values.Header14med}
      color: ${({ theme }) => theme.colors.textDefault};
    `,
  },
  iconLabel: {
    textStyle: values.header12,
  },
  labelUnstyled: {
    textStyle: values.Header14,
  },
  linkDeemphasized: {
    textStyle: css`
      ${values.Header12}
      color: ${({ theme }) => theme.colors.linkTextDeemphasized};
    `,
  },
  linkEmphasized: {
    textStyle: css`
      ${values.Header14med}
      color: ${({ theme }) => theme.colors.linkText};
    `,
  },
  linkUnstyled: {
    textStyle: values.Header14med,
  },
  listItemHeading: {
    textStyle: css`
      ${values.Header16}
      margin-bottom: 8px;
    `,
  },
  linkSpeedyTermsAndPrivacy: {
    textStyle: css`
      ${values.Header12}
      color: ${({ theme }) => theme.colors.linkTextDeemphasized};
    `,
  },
  miniCartTitle: {
    textStyle: css`
      ${values.Header14med}
      color: ${({ theme: { colors } }) => colors.textDefault};
    `,
  },
  miniCartEmptyMessage: {
    textStyle: css`
      ${values.Header22}
      color: ${({ theme }) => theme.colors.textDefault};
    `,
  },
  miniCartBundleToggle: {
    textStyle: css`
      ${values.Header12med}
    `,
  },
  miniCartScarcityNotice: {
    textStyle: css`
      ${values.LabelSize12}
    `,
  },
  miniCartScarcityCallout: {
    textStyle: css`
      ${values.Header14}
      font-weight: 500;
    `,
  },
  miniCartError: {
    textStyle: values.Header14med,
  },
  miniCartRemoveItemError: {
    textStyle: css`
      ${values.Header14}
      font-weight: 500;
    `,
  },
  miniCartFreeShippingCallout: {
    textStyle: css`
      ${values.Header12med}
      color: ${({ theme }) => theme.colors.textFadedAlternate};
    `,
  },
  miniCartTimerPromoTitle: {
    textStyle: css`
      ${values.Header16}
      color: ${({ theme }) => theme.colors.textLight};
    `,
  },
  miniCartTimerPromoOffer: {
    textStyle: css`
      ${values.Header14}
      color: ${({ theme }) => theme.colors.textLight};
    `,
  },
  miniCartTimerHeader: {
    textStyle: css`
      ${values.Header12}
      color: ${({ theme }) => theme.colors.textLight};
    `,
  },
  miniCartTimerWrapper: {
    textStyle: css`
      ${values.Header14med}
      color: ${({ theme }) => theme.colors.textLight};
    `,
  },
  miniCartQuality: {
    textStyle: css`
      ${values.Header12}
    `,
  },
  miniCartPreOrderLabel: {
    textStyle: css`
      ${values.LabelSize12};
      font-size: 11px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.textEmphasized};
    `,
  },
  modalCallout: {
    textStyle: css`
      ${values.Header14}
      text-align: center;
      color: ${({ theme }) => theme.colors.textEmphasized};
    `,
  },
  modalHeader: {
    textStyle: css`
      ${values.Header22}
      text-align: center;
    `,
  },
  modalSubheader: {
    textStyle: css`
      ${values.Body14}
      text-align: center;
    `,
  },
  orderDetailsHeading: {
    textStyle: css`
      ${values.Header20}
      line-height: ${25 / 20};
    `,
  },
  orderVipSavings: {
    textStyle: css`
      ${values.Header14med}
      font-weight: 500;
    `,
  },
  orderedListSectionSubtitle: {
    textStyle: css``,
  },
  orderedListSectionTitle: {
    textStyle: css``,
  },
  productRatingCount: {
    textStyle: css`
      ${values.Header12}
      color: ${({ theme }) => theme.colors.linkTextDeemphasized};
    `,
  },
  postRegGridTab: {
    textStyle: css`
      color: ${({ theme }) => theme.colors.palette.black};
      ${values.LabelSize16}

      letter-spacing: inherit;
    `,
  },
  postRegTabCountDownTimer: {
    textStyle: css`
      color: ${({ theme }) => theme.colors.palette.deepBrown};
      ${values.LabelSize16}

      font-weight: 700;
      letter-spacing: inherit;
    `,
  },
  legacyPostregCountdownTimer: {
    textStyle: css`
      color: ${({ theme }) => theme.colors.palette.deepBrown};
      ${values.Header14med}
    `,
  },
  productCellTitle: {
    textStyle: css`
      ${values.Header16}
      line-height: ${24 / 16};
      font-weight: 600;
    `,
  },
  productCellBody: {
    textStyle: css`
      ${values.Body14}
      line-height: ${18 / 14};
    `,
  },
  productCellBodyEmphasized: {
    textStyle: css`
      ${values.Body14}
      font-weight: 500;
      line-height: ${18 / 14};
    `,
  },
  quizAnswer: {
    textStyle: css`
      ${values.Body14}
      line-height: 1.1;
      font-weight: 700;
      text-align: center;
    `,
  },
  quizPageHeader: {
    textStyle: css`
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 1px;
      font-weight: 700;

      ${mobile`
        font-size: 18px;
        line-height: ${24 / 18};
      `}
    `,
  },
  quizPageSubHeader: {
    textStyle: css`
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
    `,
  },
  quizSkippedText: {
    textStyle: values.Body12,
  },
  quizStepCounter: {
    textStyle: css`
      font-size: 14px;
      ${mobile`
        font-size: 12px;
      `}
    `,
  },
  quizSizeBlock: {
    textStyle: css`
      ${values.Body16};
      font-weight: 600;
    `,
  },
  sectionTitle: {
    textStyle: css`
      ${values.heading1small};
      font-weight: 800;
      text-align: center;
      color: ${({ theme }) => theme.colors.textDefault};
      text-transform: uppercase;
    `,
  },
  sectionHeader: {
    textStyle: css`
      ${values.heading3small};
      font-weight: 600;
      text-align: center;
    `,
  },
  sectionBody: {
    textStyle: css`
      ${values.paragraph3};
      text-align: center;
    `,
  },
  sectionText: {
    textStyle: css`
      ${values.Body16}
      color: ${({ theme }) => theme.colors.textDefault};
      text-align: center;
    `,
  },
  splashPageDate: {},
  splashPageHeader: {},
  splashPageSubheader: {},
  splashPageOptIn: {},
  splashPageEarlyAccess: {},
  splashPageThanks: {},
  splashPageEarlyAccessModalHeader: {},
  welcomePopupLegalLanguage: {
    textStyle: values.Header12,
  },
  sustainabilityFeature: {
    textStyle: css`
      ${values.Body18}
      line-height: 28px;
    `,
  },
  sizeGuideHeader: {
    textStyle: values.Header22,
  },
  sizeGuideSubheader: {
    textStyle: values.Header16,
  },
  sizeGuideText: {
    textStyle: values.Body14,
  },
  sizeGuideCaption: {
    textStyle: values.Body12,
  },
  termsText: {
    textStyle: css`
      ${values.LabelSize12}
    `,
  },
  default: {
    // WARNING: for deprecated `fontVariant` use only. Please do not use for other use cases.
    textStyle: css`
      font-family: ${fontStacks.baseFamily};
      text-transform: uppercase;
    `,
  },
};

export const typography = {
  values,
  variants,
};

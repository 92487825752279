import { useMemo } from 'react';

import { useCustomerDetail } from '../../techstyle-shared/react-accounts';
import { useSessionDetail } from '../../techstyle-shared/redux-core';

export const useOneTrustConsent = () => {
  const oneTrustSessionDetail = useSessionDetail('onetrust_consent');
  const oneTrustCustomerDetail = useCustomerDetail('onetrust_consent');

  return useMemo(() => {
    const oneTrustSessionDetails =
      typeof oneTrustSessionDetail?.value === typeof 'string'
        ? oneTrustSessionDetail.value.split(',')
        : [];
    const oneTrustCustomerDetails =
      typeof oneTrustCustomerDetail?.value === typeof 'string'
        ? oneTrustCustomerDetail.value.split(',')
        : [];
    return [...oneTrustSessionDetails, ...oneTrustCustomerDetails];
  }, [oneTrustCustomerDetail.value, oneTrustSessionDetail.value]);
};

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useStickyHeight from '../useStickyHeight';

function getStickyOffsetStyle({ stickyHeight }) {
  return {
    height: stickyHeight,
    marginTop: -stickyHeight,
  };
}

const Anchor = styled.a`
  position: absolute;
  visibility: hidden;
  z-index: -1;
  ${getStickyOffsetStyle};
`;

/**
 * Render a hidden anchor element with the given `id`. When linked to via a
 * fragment (like `#id`), it will account for the height of any `<Sticky>`
 * elements so that the page is scrolled to the right position without obscuring
 * the content that follows. Should be placed before or within the desired
 * target element.
 */
export default function LinkTarget({ id, ...rest }) {
  const stickyHeight = useStickyHeight();
  return <Anchor id={id} stickyHeight={stickyHeight} {...rest} />;
}

LinkTarget.propTypes = {
  id: PropTypes.string,
};

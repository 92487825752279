import PropTypes from 'prop-types';
// Temporary util until aggregations can do categoryIds.

/**
 * Translates the breadcrumb data from breadcrumb API endpoint into a structure
 * similar to aggregations from products/v2. The field it uses is `categoryIds`.
 * @param {Object} activeCategory
 * @returns {Object} breadcrumb data as aggregration data
 */
export function categoryBreadcrumbToFilterCategory(activeCategory) {
  const { label, subcategories } = activeCategory;

  const items = subcategories
    .filter((category) => category.filterable)
    .map((category) => ({
      value: category.productCategoryId,
      label: category.label,
      filterCount: null,
    }));

  if (!items.length) {
    // There should be no `categoryIds` filter in this case.
    return null;
  }

  return {
    field: 'categoryIds',
    category: activeCategory,
    label: `${label} Style`, // Just a default, can be customized.
    totalAggregationCount: null,
    items,
  };
}

export const getTotalFilterCountCustomFilters = (filterSettings, filters) => {
  const { categoryIds = [], aggregationFilter = {} } = filters;

  return filterSettings.reduce((runningCount, filterSetting) => {
    const { getCount } = filterSetting;
    if (getCount) {
      return filterSetting.getCount({
        currentCount: runningCount,
        filters,
        filterSetting,
      });
    } else {
      return runningCount + aggregationFilter[filterSetting.field].length;
    }
  }, categoryIds.length);
};

export const getTotalFilterCount = (filters) => {
  const {
    categoryIds = [],
    aggregationFilter = {},
    avgReviewMin,
    vipUnitPriceMinimum,
    vipUnitPriceMaximum,
    saleUnitPriceMinimum,
    saleUnitPriceMaximum,
  } = filters;

  let totalCount = categoryIds.length;
  for (const field in aggregationFilter) {
    totalCount += aggregationFilter[field].length;
  }
  if (avgReviewMin != null) {
    totalCount += 1;
  }
  // Even though there are two fields, price range counts as one filter.
  if (
    vipUnitPriceMinimum != null ||
    vipUnitPriceMaximum != null ||
    saleUnitPriceMinimum != null ||
    saleUnitPriceMaximum != null
  ) {
    totalCount += 1;
  }
  return totalCount;
};

export const getCategoryTree = (
  activeCategory,
  showActiveSubcategories = true,
  showSiblingCategories = true,
  activeCategoryChecker = (category) => category.productCategoryId
) => {
  const isTopLevel =
    activeCategory.isTopLevel ||
    activeCategory.siblings.some((category) => category.isTopLevel);

  if (!showActiveSubcategories) {
    // removing activeCategory's subcategories if showActiveSubcategories is false
    const { subcategories, ...modifiedActiveCategory } = activeCategory;
    activeCategory = modifiedActiveCategory;
  }

  const siblings = activeCategory.siblings.map((sibling) =>
    activeCategoryChecker(sibling) === activeCategoryChecker(activeCategory)
      ? activeCategory
      : sibling
  );

  if (!showSiblingCategories) {
    // removing sibling categories if showSiblingCategories is false
    const { siblings, ...modifiedActiveCategory } = activeCategory;
    activeCategory = modifiedActiveCategory;
  }

  const activeSiblings = showSiblingCategories ? siblings : [activeCategory];

  const hasSubcategories = Boolean(
    activeCategory.subcategories && activeCategory.subcategories.length
  );

  // If the active category is top-level, only show its siblings if there are
  // no subcategories to list. Otherwise, the only item visible would be the
  // single active category.
  let tree = isTopLevel && hasSubcategories ? [activeCategory] : activeSiblings;

  if (!isTopLevel) {
    const parent = activeCategory.ancestors[0];

    if (parent) {
      tree = [
        {
          ...parent,
          isAncestor: true,
          subcategories: tree,
        },
      ];

      if (!parent.isTopLevel) {
        const grandParent = activeCategory.ancestors[1];
        if (grandParent) {
          const parentSiblings = parent.siblings
            ? parent.siblings.map((sibling) =>
                activeCategoryChecker(parent) === activeCategoryChecker(sibling)
                  ? tree[0]
                  : sibling
              )
            : [];

          tree = [
            {
              ...grandParent,
              isAncestor: true,
              subcategories: parentSiblings,
            },
          ];
        }
      }
    }
  }

  return tree;
};

export const getTopLevelCategory = (activeCategory) => {
  const isTopLevel =
    activeCategory.isTopLevel ||
    activeCategory.siblings.some((category) => category.isTopLevel);

  let topLevelCategory = activeCategory;

  if (!isTopLevel) {
    const parent = activeCategory.ancestors[0];

    if (parent) {
      topLevelCategory = {
        ...parent,
        isAncestor: true,
      };

      if (!parent.isTopLevel) {
        const grandParent = activeCategory.ancestors[1];
        if (grandParent) {
          topLevelCategory = {
            ...grandParent,
            isAncestor: true,
          };
        }
      }
    }
  }

  return topLevelCategory;
};

export const categoryPropType = PropTypes.shape({
  isAncestor: PropTypes.bool,
  label: PropTypes.node,
  productCategoryId: PropTypes.number,
  // Circular, don't think PropTypes can do that.
  subcategories: PropTypes.array,
  urlPath: PropTypes.string,
});

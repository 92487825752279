import React from 'react';

import PropTypes from 'prop-types';

import { useProductContext } from '../ProductContext';

const ProductName = React.forwardRef(
  (
    {
      as: ElementType = 'span',
      product: productFromProps,
      prefix,
      suffix,
      ...rest
    },
    ref
  ) => {
    const productFromContext = useProductContext();
    const product = productFromProps || productFromContext;

    return (
      <ElementType data-autotag="grid-product-name" ref={ref} {...rest}>
        {prefix && prefix}
        {product.label}
        {suffix && suffix}
      </ElementType>
    );
  }
);

ProductName.displayName = 'ProductName';
ProductName.propTypes = {
  /**
   * Element type to render. Defaults to a span.
   */
  as: PropTypes.elementType,
  /**
   * Adds extra output before the product label.
   */
  prefix: PropTypes.node,
  /**
   * Product. If none is passed, it will attempt to retrieve one from
   * ProductContext.
   */
  product: PropTypes.object,
  /**
   * Adds extra output after the product label.
   */
  suffix: PropTypes.node,
};

export default ProductName;

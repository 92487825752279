import { eachYearOfInterval } from 'date-fns/eachYearOfInterval';

const EARLIEST_VALID_YEAR = '2014';

const yearsFromStartToNow = (startYear: string) =>
  eachYearOfInterval({
    start: new Date(`${startYear}/1/1`),
    end: new Date(),
  }).map((date) => `${date.getFullYear()}`);

const validStartYears = yearsFromStartToNow(EARLIEST_VALID_YEAR);

export const yearsFromNowToStart = (startYear: string) => {
  if (!validStartYears.includes(startYear)) {
    throw new Error(
      `You must pass a year as a string and it must be between ${EARLIEST_VALID_YEAR}-present year`
    );
  }

  return yearsFromStartToNow(startYear).reverse();
};

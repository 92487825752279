/**
 * Forked from: https://github.com/streamich/use-media
 * Original license included below.
 */
// This is free and unencumbered software released into the public domain.
//
// Anyone is free to copy, modify, publish, use, compile, sell, or
// distribute this software, either in source code form or as a compiled
// binary, for any purpose, commercial or non-commercial, and by any
// means.
//
// In jurisdictions that recognize copyright laws, the author or authors
// of this software dedicate any and all copyright interest in the
// software to the public domain. We make this dedication for the benefit
// of the public at large and to the detriment of our heirs and
// successors. We intend this dedication to be an overt act of
// relinquishment in perpetuity of all present and future rights to this
// software under copyright law.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
// EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
// IN NO EVENT SHALL THE AUTHORS BE LIABLE FOR ANY CLAIM, DAMAGES OR
// OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE,
// ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR
// OTHER DEALINGS IN THE SOFTWARE.
//
// For more information, please refer to <http://unlicense.org>
import { useEffect, useState } from 'react';

import { objectToMediaQuery } from './styles';

/**
 * A fork of use-media: https://github.com/streamich/use-media
 * ...with additional features:
 *
 * - If no media query is passed, do not invoke `matchMedia` and return the
 *  `defaultState` instead.
 * - The `defaultState` is `null` by default. This lets you distinguish between
 *   the value being unknown (before the effect with `matchMedia` runs) and the
 *   true value, and makes default `matches` values an explicit choice.
 */
export default function useMedia(rawQuery, defaultState = null) {
  const [state, setState] = useState(defaultState);
  const query = objectToMediaQuery(rawQuery);

  useEffect(() => {
    if (query) {
      let mounted = true;
      const mql = window.matchMedia(query);
      const onChange = () => {
        if (!mounted) {
          return;
        }
        setState(mql.matches);
      };
      mql.addListener(onChange);
      setState(mql.matches);

      return () => {
        mounted = false;
        mql.removeListener(onChange);
      };
    }
  }, [defaultState, query]);

  return query ? state : defaultState;
}

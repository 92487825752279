import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FaChevronUp } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import styled, { ThemeProvider, css } from 'styled-components';

import logger from '../logger';
import adminTheme from '../styles/theme';
import useAdminActions from '../useAdminActions';
import useAdminTools from '../useAdminTools';
import useResizable from '../useResizable';
import { styledRefProp } from '../utils';

const debug = logger.extend('AdminTools');

const headerHeight = 40;
const initialHeight = 440;
const storageKey = 'tfg.react-admin.state';

const Spacer = styled.div`
  visibility: hidden;
`;

const exitStyle = css`
  transform: translate3d(0, 100%, 0) translate3d(0, 20px, 0);
`;

const enterStyle = css`
  transform: translate3d(0, 0, 0);
`;

const transitionDuration = 200;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: ${headerHeight}px;
  max-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  background: rgb(232, 231, 226);
  color: rgb(91, 95, 105);
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.2), 0 -1px 0 rgba(0, 0, 0, 0.2);
  ${enterStyle};
  transition-property: height, transform;
  transition-duration: ${transitionDuration}ms;
  transition-timing-function: ease-out;

  &[data-is-resizing='true'] {
    transition: none;
  }

  &.transition-appear:not(.transition-appear-active),
  &.transition-exit-active,
  &.transition-exit-done {
    ${exitStyle};
  }
`;

const Header = styled.div`
  flex: 0 0 auto;
  position: relative;
  display: flex;
  height: ${headerHeight}px;
  align-items: stretch;
  justify-content: flex-start;
  font-size: 14px;
  line-height: ${18 / 14};
  background: rgba(200, 200, 200, 0.3);
  color: rgb(72, 75, 84);
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.8),
    inset 0 -1px 1px rgba(0, 0, 0, 0.15), inset 0 1px 0 rgba(255, 255, 255, 0.8);
  user-select: none;
  z-index: 1;
`;

const ResizeHandle = styled.div`
  display: none;
  top: -4px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  cursor: ns-resize;

  [data-is-open='true'] & {
    display: block;
  }
`;

const Title = styled.h1`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 12px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 1px 0 0 0 rgba(255, 255, 255, 0.7);
  opacity: 0.7;
  z-index: -1;
`;

const Button = styled.button`
  position: relative;
  border: 0;
  margin: 0;
  padding: 1px 12px 2px 12px;
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  background: transparent;
  color: inherit;
  cursor: pointer;
`;

const TabButton = styled(Button)`
  transition-property: all;
  transition-duration: 0.2s;

  &[data-active] {
    background: rgba(3, 172, 255, 0.9);
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 0 40px 2px rgba(0, 49, 143, 0.8);
  }

  &:not([data-active]):hover {
    background: rgba(3, 172, 255, 0.15);
    color: black;
  }
`;

const TabIcon = styled.svg`
  font-size: 20px;
  vertical-align: top;
`;

function Tab({ compact, panelId, label, icon }) {
  const { activePanel, dispatch } = useAdminTools();

  const isActive = activePanel === panelId;

  const activate = useCallback(() => {
    dispatch({ type: 'activate', panel: panelId });
  }, [dispatch, panelId]);

  let children = label;
  if (icon) {
    const iconElement = <TabIcon $isActive={isActive} as={icon} />;
    if (compact) {
      children = iconElement;
    } else {
      children = (
        <>
          {iconElement} {label}
        </>
      );
    }
  }

  return (
    <TabButton
      data-active={isActive ? '' : undefined}
      onClick={activate}
      aria-label={label}
      title={label}
    >
      {children}
    </TabButton>
  );
}

Tab.propTypes = {
  compact: PropTypes.bool,
  icon: PropTypes.any,
  label: PropTypes.string,
  panelId: PropTypes.string,
};

function Tabs() {
  const { panels } = useAdminTools();

  const registeredPanelList = useMemo(() => {
    return orderBy(
      Object.keys(panels)
        .map((id) => panels[id])
        .filter(Boolean),
      [(panel) => panel.icon == null, (panel) => !panel.compact, 'label']
    );
  }, [panels]);

  return registeredPanelList.map((panel) => (
    <Tab
      key={panel.id}
      panelId={panel.id}
      label={panel.label}
      icon={panel.icon}
      compact={panel.compact}
    />
  ));
}

const ToggleButton = styled(Button)`
  flex: 1 0 auto;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
  line-height: 0.5;
  text-align: right;

  [data-is-open='true'] & {
    flex-grow: 0;
    margin-left: auto;
  }
`;

const ToggleIcon = styled(FaChevronUp)`
  transition: transform 0.3s ease-out;

  [data-is-open='true'] & {
    transform: rotate(-180deg);
  }
`;

const LogOutButton = styled(Button)`
  font-size: 12px;
`;

const Body = styled.div`
  position: relative;
  display: block;
  flex: 1 1 auto;
  font-size: 13px;
  overflow: auto;
  z-index: 0;
`;

export default function AdminTools() {
  const wrapperRef = useRef();
  const [mounted, setMounted] = useState(false);
  const {
    enabled,
    isOpen,
    activePanel,
    dispatch,
    setForceAdmin,
    setActivePanelNode,
  } = useAdminTools();
  const hasBeenOpened = useRef(isOpen);
  const { azureInvalidate } = useAdminActions();
  const { height, startHeight, setHeight, renderResizeHandle, isResizing } =
    useResizable(wrapperRef, initialHeight);

  const handleToggle = useCallback(() => {
    dispatch({ type: isOpen ? 'close' : 'open' });
  }, [dispatch, isOpen]);

  const logOut = useCallback(() => {
    setForceAdmin(false);
    azureInvalidate();
  }, [azureInvalidate, setForceAdmin]);

  useEffect(() => {
    if (isOpen) {
      hasBeenOpened.current = true;
    }
  }, [isOpen]);

  // Restore from sessionStorage on mount if it was open last.
  useEffect(() => {
    if (window.sessionStorage) {
      try {
        const stateString = window.sessionStorage.getItem(storageKey);
        if (stateString) {
          const persistedState = JSON.parse(stateString);
          if (persistedState.height != null) {
            setHeight(persistedState.height);
          }
          if (persistedState.isOpen) {
            if (persistedState.activePanel) {
              dispatch({ type: 'activate', panel: persistedState.activePanel });
            } else {
              dispatch({ type: 'open' });
            }
          }
        }
      } catch (err) {
        debug(`Failed to restore “${storageKey}” from sessionStorage.`);
      }
    }
  }, [dispatch, setHeight]);

  // Begin persisting Admin Tools state in sessionStorage once it gets opened
  // for the first time.
  useEffect(() => {
    if (hasBeenOpened.current && window.sessionStorage) {
      try {
        window.sessionStorage.setItem(
          storageKey,
          JSON.stringify({
            activePanel,
            height: startHeight,
            isOpen,
          })
        );
      } catch (err) {
        debug(`Failed to persist “${storageKey}” in sessionStorage.`);
      }
    }
  }, [activePanel, isOpen, startHeight]);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return ReactDOM.createPortal(
    <ThemeProvider theme={adminTheme}>
      <>
        {enabled ? (
          <Spacer
            style={{
              height: isOpen
                ? // To prevent stuttering while dragging, keep the spacer at the
                  // previous height until complete.
                  startHeight
                : headerHeight,
            }}
          />
        ) : null}
        <CSSTransition
          classNames="transition"
          in={enabled}
          appear
          mountOnEnter
          unmountOnExit
          timeout={transitionDuration}
        >
          <Wrapper
            // styled-components v3 compatibility for SX.
            {...{ [styledRefProp]: wrapperRef }}
            style={{ height: isOpen ? height : headerHeight }}
            data-autotag="debug_panel"
            data-is-open={isOpen}
            data-is-resizing={isResizing}
          >
            <Header>
              {renderResizeHandle(<ResizeHandle />)}
              <Title>Admin</Title>
              <Tabs />
              <ToggleButton
                onClick={handleToggle}
                aria-label={isOpen ? 'Close' : 'Open'}
              >
                <ToggleIcon
                  data-autotag={
                    isOpen ? 'debug_panel_slide_down' : 'debug_panel_slide_up'
                  }
                />
              </ToggleButton>
              <LogOutButton onClick={logOut}>Log Out</LogOutButton>
            </Header>
            <Body
              // styled-components v3 compatibility for SX.
              {...{ [styledRefProp]: setActivePanelNode }}
            />
          </Wrapper>
        </CSSTransition>
      </>
    </ThemeProvider>,
    document.body
  );
}

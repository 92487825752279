import { useProductDetailSelectionState } from './useProductDetailSelectionState';

export const AttributeFieldTypes = {
  COLOR: 'COLOR',
  SIZE: 'SIZE',
  OTHER: 'OTHER',
};

// Returns the field for a given attribute type
// `type` must be one of: AttributeFieldTypes
export const useProductAttributeFieldType = (type) => {
  const { attributeFieldTypes } = useProductDetailSelectionState();

  if (attributeFieldTypes === undefined) {
    throw new Error(
      'useProductAttributeType must be used inside of ProductDetail'
    );
  }

  const field = attributeFieldTypes[type];

  return field;
};

import React from 'react';

import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import {
  defineMessages,
  useIntl,
} from '../../../../techstyle-shared/react-intl';
import Modal from '../Modal';
import { useProductDetailContext } from '../ProductDetail';
import { mobile } from '../styles';
import Video from '../Video';

const messages = defineMessages({
  close: {
    id: 'global_cta.close',
    defaultMessage: 'Close',
  },
});

const CloseIcon = styled(FaTimes)`
  font-size: 24px;
  fill: #eee;
  stroke: rgba(0, 0, 0, 0.3);
  stroke-width: 8%;
  vertical-align: top;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 12px;
  background: transparent;
  z-index: 2;

  ${mobile`
    padding: 20px;
  `};

  ${(props) => props.closeButtonStyle};
`;

const defaultDialogStyle = css`
  position: relative;
  width: 720px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);

  ${mobile`
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.5);
  `};
`;

// We need this element to ensure that the video element itself isn't stretched
// to the height of the dialog, it needs to be vertically centered on mobile.
const VideoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const videoWrapperStyle = css`
  width: 100%;
  flex: 0 0 auto;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
`;

const ProductVideoModal = ({
  aspectRatio,
  closeButtonStyle,
  closeIcon,
  closeLabel = messages.close,
  dialogStyle = defaultDialogStyle,
  onExit,
  overlayColor = 'rgba(0, 0, 0, 0.5)',
  product: productFromProps,
  videoProps,
  ...rest
}) => {
  const intl = useIntl();
  const productDetailFromCtx = useProductDetailContext();
  const { video } = productFromProps || productDetailFromCtx.product;
  const url = video.url;

  if (typeof closeLabel !== 'string') {
    closeLabel = intl.formatMessage(closeLabel);
  }

  return (
    <Modal
      data-autotag="video_modal"
      dialogStyle={dialogStyle}
      onExit={onExit}
      overlayColor={overlayColor}
      {...rest}
    >
      <CloseButton
        aria-label={closeLabel}
        onClick={onExit}
        data-autotag="video_modal_close_icon"
        closeButtonStyle={closeButtonStyle}
      >
        {closeIcon || <CloseIcon />}
      </CloseButton>
      <VideoContainer>
        <Video
          url={url}
          aspectRatio={aspectRatio}
          wrapperStyle={videoWrapperStyle}
          {...videoProps}
        />
      </VideoContainer>
    </Modal>
  );
};
ProductVideoModal.displayName = 'ProductVideoModal';
ProductVideoModal.CloseIcon = CloseIcon;

ProductVideoModal.propTypes = {
  /**
   * Video aspect ratio
   */
  aspectRatio: PropTypes.number,
  /**
   * close Button styles
   */
  closeButtonStyle: PropTypes.any,
  /**
   * Pass custom or any other close Icon as react component
   */
  closeIcon: PropTypes.node,
  /**
   * A string or message descriptor for the close button label.
   */
  closeLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dialogStyle: PropTypes.any,
  onExit: PropTypes.func,
  overlayColor: PropTypes.string,
  /**
   * product having video
   */
  product: PropTypes.object,
  /**
   * other Video props.
   */
  videoProps: PropTypes.object,
};

export default ProductVideoModal;

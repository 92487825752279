import React, { useState, useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';

import {
  useCustomerDetail,
  useAccountActions,
} from '../../../../techstyle-shared/react-accounts';
import {
  useSession,
  useSessionActions,
  useSessionDetail,
} from '../../../../techstyle-shared/redux-core';
import logger from '../logger';
import Script from '../Script';

const debug = logger.extend('OneTrustSnippet');

function Setup({ children }) {
  const [oneTrustReady, setOneTrustReady] = useState(false);
  const [activeGroups, setActiveGroups] = useState();

  const sessionActions = useSessionActions();
  const accountActions = useAccountActions();
  const { isLoggedIn, sessionId } = useSession();
  const oneTrustConsentSession = useSessionDetail('onetrust_consent');
  const oneTrustConsentCustomer = useCustomerDetail('onetrust_consent');

  const handleConsentChanged = useCallback((event) => {
    // event.detail.join() is the same as window.OnetrustActiveGroups
    debug('New consent groups value: %o', event.detail);
    setActiveGroups(event.detail.slice());
  }, []);

  useEffect(() => {
    if (window.OneTrust && window.OneTrust.OnConsentChanged) {
      setOneTrustReady(true);
    } else {
      window.OptanonWrapper = () => {
        setOneTrustReady(true);
      };
    }
  }, []);

  useEffect(() => {
    if (oneTrustReady) {
      if (window.OnetrustActiveGroups != null) {
        // onMount activeGroups parsed from global var string
        const parsedActiveGroups =
          window.OnetrustActiveGroups.split(',').filter(Boolean);
        setActiveGroups(parsedActiveGroups);
      }
      window.OneTrust.OnConsentChanged(handleConsentChanged);
    }
  }, [handleConsentChanged, oneTrustReady]);

  useEffect(() => {
    if (activeGroups) {
      const detailValue = activeGroups.join();

      // Update the session when the session detail has been loaded
      // or current session id does not match session detail's session id
      if (
        oneTrustConsentSession.networkStatus.isUpToDate ||
        oneTrustConsentSession.error ||
        (oneTrustConsentSession.data &&
          oneTrustConsentSession.data.sessionId !== sessionId)
      ) {
        if (oneTrustConsentSession.value !== detailValue) {
          sessionActions.updateSessionDetail({
            name: 'onetrust_consent',
            value: detailValue,
          });
        }
      }
    }
  }, [activeGroups, oneTrustConsentSession, sessionActions, sessionId]);

  useEffect(() => {
    if (activeGroups) {
      const detailValue = activeGroups.join();
      // Update customerDetail if logged in
      if (isLoggedIn) {
        if (
          oneTrustConsentCustomer.networkStatus.isUpToDate ||
          oneTrustConsentCustomer.error
        ) {
          if (oneTrustConsentCustomer.value !== detailValue) {
            accountActions.updateCustomerDetail({
              name: 'onetrust_consent',
              value: detailValue,
            });
          }
        }
      }
    }
  }, [accountActions, activeGroups, isLoggedIn, oneTrustConsentCustomer]);

  if (typeof children === 'function') {
    return (
      <>
        {children({
          activeGroups: activeGroups,
        })}
      </>
    );
  } else {
    return <>{children}</>;
  }
}

Setup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

/**
 * Render a JavaScript snippet that loads OneTrust.
 */
export default function OneTrustSnippet({ apiKey, geoResponse }) {
  // If there isn't a valid stateCode and/or countryCode, OneTrust will
  // fallback to the geolocation detection settings in the console.
  const initialOneTrust = {};

  if (geoResponse) {
    initialOneTrust.geolocationResponse = geoResponse;
  }

  return (
    <>
      <Script
        globalScriptKey={['onetrust', 'init']}
        dangerouslySetInnerHTML={{
          __html: `
var OneTrust = ${serialize(initialOneTrust)};
function OptanonWrapper() {};`,
        }}
      />
      <Script
        globalScriptKey={['onetrust']}
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        charSet="UTF-8"
        data-domain-script={apiKey}
      />
    </>
  );
}

OneTrustSnippet.propTypes = {
  /**
   * The OneTrust API key (referred to as domain-script by OneTrust).
   */
  apiKey: PropTypes.string.isRequired,
  geoResponse: PropTypes.shape({
    stateCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    countryCode: PropTypes.string,
  }),
};

OneTrustSnippet.Setup = Setup;

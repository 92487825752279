import { withApollo } from './apolloClient';
import cacheOptions from './cacheOptions';
import withRemoveInitialReduxState from './withRemoveInitialReduxState';

export default function apolloExtension(options = {}) {
  const {
    clearCacheOnLogin = false,
    globalEnableGetDataFromTree = true,
    ...restOfOptions
  } = options;

  // Keep reference to current apollo client for middleware
  let apolloClient;
  const onApolloClientCreated = (currentApolloClient) => {
    apolloClient = currentApolloClient;
  };

  const apolloOptions = {
    cacheOptions,
    errorCodeRules: [],
    onApolloClientCreated,
    ...restOfOptions,
  };

  return {
    id: 'apollo',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
          configure(server) {
            const {
              default: supergraphProxyMiddleware,
            } = require('./supergraphProxyMiddleware');
            server.useTracked(
              'supergraphProxyMiddleware',
              supergraphProxyMiddleware()
            );

            const {
              default: graphqlProxyMiddleware,
            } = require('./graphqlProxyMiddleware');
            server.useTracked(
              'graphqlProxyMiddleware',
              graphqlProxyMiddleware()
            );
          },
        },
    redux() {
      const reduxConfig = {};

      if (process.browser && clearCacheOnLogin) {
        /**
         * Setup middleware to watch for successful login / sign up
         * and clear the apollo cache when this happens so queries
         * get recalled since data could be different for user
         */
        const apolloClearStoreOnLoginAndSignupMiddleware =
          (store) => (next) => (action) => {
            const type = action?.type;
            if (
              // FIXME: Import these from `@techstyle/react-accounts` but
              // avoid circular references
              type === 'accounts/signUpSuccess' ||
              type === 'accounts/logInSuccess'
            ) {
              if (apolloClient) {
                apolloClient.clearStore();
              }
            }
            return next(action);
          };

        const extension = {
          middleware: [apolloClearStoreOnLoginAndSignupMiddleware],
        };
        reduxConfig.extensions = [extension];
      }

      return reduxConfig;
    },
    page({ enableGetDataFromTree }) {
      return {
        async getInitialProps(ctx) {
          ctx.enableGetDataFromTree =
            enableGetDataFromTree ?? globalEnableGetDataFromTree;
        },
      };
    },
    app: {
      enhance(App) {
        if (process.browser) {
          return withApollo(apolloOptions)(App);
        }

        // When `withApollo` runs `getInitialProps` it should have access to the redux initial state
        // this is so the redux store is populated when running `getDataFromTree`
        const { getInitialProps } = App;
        let currentCtx;
        App.getInitialProps = async ({ Component, ctx }) => {
          const initialProps = await getInitialProps({ Component, ctx });
          currentCtx = ctx;
          return { ...initialProps, initialState: ctx.store.getState() };
        };

        const { getDataFromTree } = require('@apollo/client/react/ssr');

        const getDataFromTreeWithPerformance = async (app) => {
          const { performance } = currentCtx.req;
          const { enableGetDataFromTree } = currentCtx;
          const startTime = process.hrtime();
          const entry = {
            type: 'next',
            name: 'getDataFromTree',
            requestStart: performance.getOffset(startTime),
          };
          performance.entries.push(entry);
          try {
            if (enableGetDataFromTree) {
              await getDataFromTree(app);
            }
          } finally {
            const endTime = process.hrtime();
            entry.duration = performance.getDuration(startTime, endTime);
          }
        };

        return withRemoveInitialReduxState(
          withApollo(apolloOptions)(App, {
            getDataFromTree: getDataFromTreeWithPerformance,
          })
        );
      },
    },
  };
}

import React from 'react';

import NextApp from 'next/app';
import PropTypes from 'prop-types';

export default (registry) =>
  function createApp() {
    function App(props) {
      const { Component, pageProps } = props;
      const getLayout = Component.getLayout || ((page) => page);
      const page = getLayout(<Component {...pageProps} />);
      const children = registry.app.render(props, page);
      return children;
    }

    App.propTypes = {
      Component: PropTypes.elementType,
      pageProps: PropTypes.object,
    };

    App.getInitialProps = (appContext) => {
      const { ctx } = appContext;
      ctx.registry = registry;

      if (!process.browser && ctx.req) {
        // Intercept internal routes (like `/_next/static/...`) that would
        // render a high-fidelity error page (causing RBs, navs, etc. to be
        // fetched) and return a bare error page instead. We never expect
        // real users to navigate to such internal routes, so a fancy error
        // page isn't necessary, it just makes the server do more work.
        if (ctx.req.context.isInternalRoute && ctx.pathname === '/_error') {
          ctx.res.setHeader('Content-Type', 'text/plain');
          ctx.res.end(ctx.res.statusCode ? ctx.res.statusCode.toString() : '');
          return {};
        }
      }

      const getInitialProps = registry.app.enhanceInitialProps(
        async (appContext) => {
          const getExtensionProps = registry.app.getInitialProps(appContext);
          const getAppProps = NextApp.getInitialProps(appContext);
          const [extensionProps, appProps] = await Promise.all([
            getExtensionProps,
            getAppProps,
          ]);
          return { ...extensionProps, ...appProps };
        }
      );

      return getInitialProps(appContext);
    };

    return registry.app.enhance(App);
  };

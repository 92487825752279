import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import BasicDropdown from '../../../shared/components/BasicDropdown';
import Text from '../../../shared/components/Text';
import { DenimInterestPages } from '../../../shared/constants/denimInterestPages';
import useGender from '../../../shared/utils/useGender';
import { useAccountActions } from '../../../techstyle-shared/react-accounts';
import { AssetContainer } from '../../../techstyle-shared/react-assets';
import {
  mobile,
  Button,
  Modal as BaseModal,
  desktop,
} from '../../../techstyle-shared/react-components';
import {
  FormattedMessage,
  useIntl,
} from '../../../techstyle-shared/react-intl';
import { useSessionActions } from '../../../techstyle-shared/redux-core';

const FEMALE_SIZE_OPTIONS = [
  { name: '23', value: '23' },
  { name: '24', value: '24' },
  { name: '25', value: '25' },
  { name: '26', value: '26' },
  { name: '27', value: '27' },
  { name: '28', value: '28' },
  { name: '29', value: '29' },
  { name: '30', value: '30' },
  { name: '31', value: '31' },
  { name: '32', value: '32' },
  { name: '33', value: '33' },
  { name: '34', value: '34' },
  { name: '35', value: '35' },
  { name: '36', value: '36' },
  { name: '37', value: '37' },
  { name: '38', value: '38' },
  { name: '39', value: '39' },
  { name: '40', value: '40' },
  { name: 'Size not listed', value: 'Size-not-listed' },
];

const MALE_SIZE_OPTIONS = [
  { name: '29', value: '29' },
  { name: '30', value: '30' },
  { name: '31', value: '31' },
  { name: '32', value: '32' },
  { name: '33', value: '33' },
  { name: '34', value: '34' },
  { name: '35', value: '35' },
  { name: '36', value: '36' },
  { name: '37', value: '37' },
  { name: '38', value: '38' },
  { name: '39', value: '39' },
  { name: '40', value: '40' },
  { name: '41', value: '41' },
  { name: '42', value: '42' },
  { name: '43', value: '43' },
  { name: '44', value: '44' },
  { name: '46', value: '46' },
  { name: '48', value: '48' },
  { name: '50', value: '50' },
  { name: '52', value: '52' },
  { name: 'Size not listed', value: 'Size-not-listed' },
];

const FEMALE_INSEAM_OPTIONS = [
  { name: 'Extra Short', value: 'Extra-Short' },
  { name: 'Short', value: 'Short' },
  { name: 'Regular', value: 'Regular' },
  { name: 'Tall', value: 'Tall' },
  { name: 'Extra Tall', value: 'Extra-Tall' },
];

const MALE_INSEAM_OPTIONS = [
  { name: 'Under 30 inches', value: 'Under-30-inches' },
  { name: '30 in', value: '30 in' },
  { name: '32 in', value: '32 in' },
  { name: '34 in', value: '34 in' },
  { name: 'Over 34 inches', value: 'Over-34-inches' },
];

const dialogStyle = css`
  ${({ theme: { sizes } }) => `
    border-radius: ${sizes.sm}px;
    margin: ${sizes.lg}px;
    padding: ${sizes.md}px;
  `}
  width: 100%;
  max-width: 450px;
  height: fit-content;
  position: relative;
  overflow: visible;
  background: ${({ theme }) => theme.colors.palette.black};
  ${desktop`
    ${({ theme: { sizes } }) => `
      padding: ${sizes.xxxl}px ${sizes.lg}px;
    `}
  `}
`;

const Modal = styled(BaseModal).attrs({
  dialogStyle,
})``;

const BackgroundAsset = styled(AssetContainer)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

const Heading = styled(Text).attrs({
  forwardedAs: 'h2',
})`
  ${({ theme }) => theme.typography.values.heading3};
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.4;
`;

const SizeHeading = styled(Text).attrs({
  forwardedAs: 'h2',
})`
  font-size: 27px;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 6px;
  margin-top: 14px;
  ${mobile`
    font-size: 15px;
  `}
`;

const SizeSubheading = styled(Text).attrs({
  forwardedAs: 'p',
})`
  font-size: 16px;
  text-transform none;
  text-align: center;
  color: ${({ theme }) => theme.colors.palette.black};
  margin-bottom: 10px;
`;

const SuccessSubheadingWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 35px;
`;

const CloseButton = styled(Button).attrs({ variant: 'unstyled' })`
  position: absolute;
  ${({ theme: { colors, sizes } }) => `
    color: ${colors.palette.white};
    height: ${sizes.xxl}px;
    width: ${sizes.xxl}px;
    top: ${sizes.sm}px;;
    right: ${sizes.sm}px;
  `}
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${mobile`
    color: ${({ theme }) => theme.colors.palette.black};
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.palette.white};
  box-shadow: 0px 8px 10px -5px ${({ theme }) => theme.colors.modalOverlay};
`;

const DropdownWrapper = styled.div`
  margin-bottom: 25px;
`;

const InseamHeadingWrapper = styled.div`
  margin: 40px auto 14px;
  ${mobile`
    margin: 10px auto 10px;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 4px auto 60px;
  gap: 24px;
`;

const StyledButton = styled(Button).attrs({
  variant: 'primary',
})`
  border-radius: 0;
  width: 100%;
  border-color: ${({ theme }) => theme.colors.palette.white};
`;

const dropdownListStyle = css`
  z-index: 3; /* Adjusting z-index to ensure the bottom dropdown stays behind the options list. */
`;

const InterestPageWrapper = styled.div`
  margin-top: 40px;
`;

const SizePageWrapper = styled.div``;

const SuccessPageWrapper = styled.div`
  margin: 75px auto 75px;
`;

const DenimInterestModal = ({ popupShownCount, activePage, setActivePage }) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [sizeValue, setSizeValue] = useState('');
  const [inseamValue, setInseamValue] = useState('');
  const { isFemale } = useGender();
  const { formatMessage } = useIntl();
  const actions = useAccountActions();
  const sessionActions = useSessionActions();
  const isSubmitDisabled = !sizeValue || !inseamValue;

  useEffect(() => {
    const handleDenimPopupDisplay = () => {
      if (popupShownCount) {
        if (Number(popupShownCount) <= 3) {
          const newCount = Number(popupShownCount) + 1;
          // increase times the popup has been shown
          actions.updateCustomerDetail({
            name: 'denim_popup_shown_count',
            value: newCount,
          });
        } else {
          // after 3x ignoring the popup, add 'denim_interest_no' to customer record
          actions.updateCustomerDetail({
            name: 'denim_interest',
            value: false,
          });
        }
      } else {
        // add a counter to the customer to keep track of times they've seen the popup
        actions.updateCustomerDetail({
          name: 'denim_popup_shown_count',
          value: 1,
        });
      }
    };
    handleDenimPopupDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    // add to current session so the popup doesn't come up again until next time
    sessionActions.updateSessionDetail({
      name: 'denim_popup_shown',
      value: true,
    });
    setModalIsOpen(false);
  };

  const handleYes = () => {
    actions.updateCustomerDetail({
      name: 'denim_interest',
      value: true,
    });
    setActivePage(DenimInterestPages.SIZE);
  };

  const handleNo = () => {
    actions.updateCustomerDetail({
      name: 'denim_interest',
      value: false,
    });
    setModalIsOpen(false);
  };

  const onSubmit = () => {
    if (isFemale) {
      actions.updateCustomerDetail({
        name: 'denim_womens_waist',
        value: sizeValue,
      });
      actions.updateCustomerDetail({
        name: 'denim_womens_inseam',
        value: inseamValue,
      });
    } else {
      actions.updateCustomerDetail({
        name: 'denim_mens_waist',
        value: sizeValue,
      });
      actions.updateCustomerDetail({
        name: 'denim_mens_inseam',
        value: inseamValue,
      });
    }
    setActivePage(DenimInterestPages.SUCCESS);
  };

  return (
    <Modal
      title="denim-interest-modal"
      isOpen={modalIsOpen}
      onExit={closeModal}
    >
      <BackgroundAsset
        name="denim_interest_bg_desktop"
        aspectRatio="auto"
        background
      />
      <Wrapper>
        <CloseButton
          aria-label={formatMessage({
            id: 'denim_popup.close',
            defaultMessage: 'Close',
          })}
          onClick={closeModal}
        >
          <FaTimes aria-hidden size={24} />
        </CloseButton>
        {activePage === DenimInterestPages.INTEREST && (
          <InterestPageWrapper>
            <Heading>
              <FormattedMessage
                id="denim_popup.are_you_interested"
                defaultMessage="Hey there,<br/>are you interested in<br/>denim from Fabletics?"
              />
            </Heading>
            <ButtonsWrapper>
              <StyledButton onClick={handleYes}>
                <FormattedMessage id="site_account.yes" defaultMessage="Yes" />
              </StyledButton>
              <StyledButton onClick={handleNo}>
                <FormattedMessage id="site_account.no" defaultMessage="No" />
              </StyledButton>
            </ButtonsWrapper>
          </InterestPageWrapper>
        )}
        {activePage === DenimInterestPages.SIZE && (
          <SizePageWrapper>
            <SizeHeading>
              <FormattedMessage
                id="denim_popup.size"
                defaultMessage="What size do you typically buy for jeans?"
              />
            </SizeHeading>
            <SizeSubheading>
              <FormattedMessage
                id="denim_popup.size_subhead"
                defaultMessage="(Keep in mind that sizes may vary slightly by brand)"
              />
            </SizeSubheading>
            <DropdownWrapper>
              <BasicDropdown
                dropdownListStyle={dropdownListStyle}
                name="size"
                options={isFemale ? FEMALE_SIZE_OPTIONS : MALE_SIZE_OPTIONS}
                value={sizeValue}
                onChange={(option) => {
                  setSizeValue(option.value);
                }}
                label={
                  <FormattedMessage
                    id="denim_popup.select_an_option"
                    defaultMessage="Select an option"
                  />
                }
              />
              <InseamHeadingWrapper>
                <SizeHeading>
                  <FormattedMessage
                    id="denim_popup.length"
                    defaultMessage="What length of denim do you normally purchase?"
                  />
                </SizeHeading>
              </InseamHeadingWrapper>
              <BasicDropdown
                name="inseam"
                options={isFemale ? FEMALE_INSEAM_OPTIONS : MALE_INSEAM_OPTIONS}
                value={inseamValue}
                onChange={(options) => {
                  setInseamValue(options.value);
                }}
                label={
                  <FormattedMessage
                    id="denim_popup.select_an_option"
                    defaultMessage="Select an option"
                  />
                }
              />
            </DropdownWrapper>
            <StyledButton onClick={onSubmit} disabled={isSubmitDisabled}>
              <FormattedMessage
                id="denim_popup.submit"
                defaultMessage="Submit"
              />
            </StyledButton>
          </SizePageWrapper>
        )}
        {activePage === DenimInterestPages.SUCCESS && (
          <SuccessPageWrapper>
            <Heading>
              <FormattedMessage
                id="denim_popup.thanks"
                defaultMessage="Thanks for your input!"
              />
            </Heading>
            <SuccessSubheadingWrapper>
              <SizeSubheading>
                <FormattedMessage
                  id="denim_popup.thanks_subhead"
                  defaultMessage="Stay tuned - something new is coming!"
                />
              </SizeSubheading>
            </SuccessSubheadingWrapper>
            <StyledButton onClick={closeModal}>
              <FormattedMessage id="denim_popup.close" defaultMessage="Close" />
            </StyledButton>
          </SuccessPageWrapper>
        )}
      </Wrapper>
    </Modal>
  );
};

DenimInterestModal.propTypes = {
  activePage: PropTypes.string,
  popupShownCount: PropTypes.any,
  setActivePage: PropTypes.func,
};

export default DenimInterestModal;

import config from 'config';

import {
  createAction,
  createReducer,
  createSelector,
  getDateNowFunction,
  parseDate,
} from '../../../techstyle-shared/redux-core';

import logger from './logger';
import { ResourceBundle } from './schema';
import { buildMessageIndex } from './utils';

const debug = logger.extend('intlModule');

export const initialState = {
  locale: 'en-US',
  // This indicates the country detected for the incoming request, and is not
  // necessarily the user's actual country. If they're logged in, there will be
  // another place from which to read the correct country from their account.
  // Use this if you are interested in the geographic origin of the request
  // specifically.
  geoCountry: null,
  geoState: null,
  geoLocation: null,
  forceGeoCountry: null,
  forceGeoState: null,
  region: 'NA',
  resourceBundles: {},
  highlightBundles: false,
  ipAddress: '',
};

export function initIntl() {
  return (dispatch, getState, context) => {
    if (context.isServer && !context.hasPreloadedState) {
      const { locale, geoCountry, geoState, geoLocation, ipAddress } =
        context.req.context;

      if (!locale) {
        throw new Error(
          `The request does not have a locale. Have you installed the locale middleware? URL: ${context.req.path}`
        );
      }
      dispatch(setLocale(locale));

      if (geoCountry) {
        dispatch(setGeoCountry({ geoCountry }));
      }

      if (geoState) {
        dispatch(setGeoState({ geoState }));
      }

      if (geoLocation) {
        dispatch(setGeoLocation(geoLocation));
      }

      if (ipAddress) {
        dispatch(setIpAddress(ipAddress));
      }

      const regions = config.get('public.intl.regions');
      const { tld } = getState().domain;
      const region = regions[tld];

      if (region) {
        debug(`Selected region: ${tld} -> ${region}`);
        dispatch(setRegion(region));
      }

      /**
       * Highlight bundles cookie and query param logic:
       *
       * - If the query param is true, set the cookie (if it doesn't exist) and enable highlighted bundles
       * - If the query param is false, clear the cookie (if it exists) and don't enable highlight bundles
       * - If the cookie exists and query param is not provided, enable highlighted bundles
       */

      // Support both camelcase and underscore param name.
      const highlightBundlesQuery =
        context.req.query.highlightBundles ||
        context.req.query.highlight_bundles ||
        '';
      const highlightBundlesQueryEnabled = /^(true|1)$/.test(
        highlightBundlesQuery
      );
      const highlightBundlesQueryDisabled = /^(false|0)$/.test(
        highlightBundlesQuery
      );
      const highlightBundlesCookieEnabled =
        context.req.cookies.highlightBundles;

      const highlightBundlesEnabled =
        highlightBundlesQueryEnabled ||
        (highlightBundlesCookieEnabled && !highlightBundlesQueryDisabled);

      if (highlightBundlesQueryEnabled) {
        if (!highlightBundlesCookieEnabled) {
          context.res.cookie('highlightBundles', 'true', {
            path: '/',
            domain: context.req.context.domain.parentCookieDomain,
            secure: true,
          });
        }
      } else if (
        highlightBundlesCookieEnabled &&
        highlightBundlesQueryDisabled
      ) {
        context.res.clearCookie('highlightBundles', {
          path: '/',
          domain: context.req.context.domain.parentCookieDomain,
          secure: true,
        });
      }

      dispatch(setHighlightBundles(highlightBundlesEnabled));
    }
  };
}

export const setLocale = createAction('intl/setLocale');
export const setGeoCountry = createAction('intl/setGeoCountry');
export const setGeoState = createAction('intl/setGeoState');
export const setRegion = createAction('intl/setRegion');
export const setGeoLocation = createAction('intl/setGeoLocation');
export const setHighlightBundles = createAction('intl/setHighlightBundles');
export const setIpAddress = createAction('intl/setIpAddress');
export const loadResourceBundlesRequest = createAction(
  'intl/loadResourceBundlesRequest'
);
export const loadResourceBundlesSuccess = createAction(
  'intl/loadResourceBundlesSuccess'
);
export const loadResourceBundlesFailure = createAction(
  'intl/loadResourceBundlesFailure'
);

export function loadResourceBundles(groupCodes, meta = {}) {
  if (groupCodes && typeof groupCodes === 'string') {
    groupCodes = [groupCodes];
  } else if (!groupCodes || !groupCodes.length) {
    throw new Error(
      `loadResourceBundles must be called with at least one group code`
    );
  }

  const maxAge = config.get('public.intl.maxAge');

  return (dispatch, getState) => {
    const state = getState();
    const { intl } = state;
    const locale = meta.locale || intl.locale;
    const localeBundles = intl.resourceBundles[locale];
    const bundlesToFetch = localeBundles ? [] : groupCodes;
    const bundlesFromCache = [];
    const now = getDateNowFunction(state)();
    if (localeBundles) {
      groupCodes.forEach((groupCode) => {
        const bundle = localeBundles[groupCode];
        let shouldFetch = true;
        if (bundle) {
          const fetchedDate = parseDate(bundle.fetchedDate);
          const age = now - fetchedDate.getTime();
          if (age >= maxAge) {
            debug('Resource bundle “%s” is expired; refetching.', groupCode);
          } else {
            shouldFetch = false;
          }
        }
        if (shouldFetch) {
          bundlesToFetch.push(groupCode);
        } else {
          bundlesFromCache.push(groupCode);
        }
      });
    }

    if (bundlesToFetch.length) {
      return dispatch({
        bentoApi: {
          endpoint: 'resources',
          searchParams: { bundles: bundlesToFetch.join(' ') },
          headers: { 'Accept-Language': locale },
          sessionRequired: false,
          schema: [ResourceBundle],
          actions: [
            loadResourceBundlesRequest,
            (payload, meta) =>
              loadResourceBundlesSuccess(payload, {
                ...meta,
                groupCodes: bundlesToFetch,
                locale,
              }),
            loadResourceBundlesFailure,
          ],
        },
      });
    }
  };
}

export const intlReducer = createReducer(initialState, {
  [setLocale]: (state, action) => {
    state.locale = action.payload;
  },
  [setGeoCountry]: (state, action) => {
    const { geoCountry, forceGeoCountry } = action.payload;
    if (typeof geoCountry !== 'undefined') {
      state.geoCountry = geoCountry;
    }
    if (typeof forceGeoCountry !== 'undefined') {
      state.forceGeoCountry = forceGeoCountry;
    }
  },
  [setGeoState]: (state, action) => {
    const { geoState, forceGeoState } = action.payload;
    if (typeof geoState !== 'undefined') {
      state.geoState = geoState;
    }
    if (typeof forceGeoState !== 'undefined') {
      state.forceGeoState = forceGeoState;
    }
  },
  [setRegion]: (state, action) => {
    state.region = action.payload;
  },
  [setGeoLocation]: (state, action) => {
    state.geoLocation = action.payload;
  },
  [setIpAddress]: (state, action) => {
    state.ipAddress = action.payload;
  },
  [setHighlightBundles]: (state, action) => {
    state.highlightBundles = action.payload;
  },
  [loadResourceBundlesSuccess]: (state, action) => {
    const { fetchedDate, groupCodes, locale } = action.meta;

    // default all requested groupCodes to empty
    if (groupCodes && locale) {
      if (!state.resourceBundles[locale]) {
        state.resourceBundles[locale] = {};
      }

      groupCodes.forEach((groupCode) => {
        const { keyIndex, values } = buildMessageIndex({});
        state.resourceBundles[locale][groupCode] = {
          fetchedDate,
          keyIndex,
          values,
        };
      });
    }

    action.payload.forEach((bundle) => {
      const { locale, groupCode, resources } = bundle;
      if (!state.resourceBundles[locale]) {
        state.resourceBundles[locale] = {};
      }
      const { keyIndex, values } = buildMessageIndex(resources);
      state.resourceBundles[locale][groupCode] = {
        fetchedDate,
        keyIndex,
        values,
      };
    });
  },
});

/**
 * Get the `locale` prop or `intl.locale` from the store.
 */
export const getLocale = (state, props) =>
  (props && props.locale) || state.intl.locale;

/**
 * Get the `region` prop or `intl.region` from the store.
 */
export const getRegion = (state, props) =>
  (props && props.region) || state.intl.region;

export const getGeoCountry = (state) =>
  state.intl.forceGeoCountry || state.intl.geoCountry;

export const getGeoState = (state) =>
  state.intl.forceGeoState || state.intl.geoState;

export const getGeoLocation = (state) => state.intl.geoLocation;

export const getIpAddress = (state) => state.intl.ipAddress;
/**
 * Get all loaded resource bundles from the store (including for other locales).
 */
export const getResourceBundles = (state) => state.intl.resourceBundles;

/**
 * Get messages for the specified locale (from either prop or store) suitable
 * for being passed to `IntlProvider`.
 */
export const getMessages = createSelector(
  [getLocale, getResourceBundles],
  (locale, resourceBundles) => {
    const messages = {};
    const localeBundles = resourceBundles[locale] || {};
    for (const groupCode in localeBundles) {
      const { keyIndex, values } = localeBundles[groupCode];
      for (const key in keyIndex) {
        const index = keyIndex[key];
        const id = `${groupCode}.${key}`;
        messages[id] = values[index];
      }
    }
    return messages;
  }
);

export default {
  id: 'intl',
  reducerMap: {
    intl: intlReducer,
  },
  initialActions: [initIntl()],
};

import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import useAnimationTimeout from '../useAnimationTimeout';

/**
 * A component that delays the MouseEnter event for its child, so that it must
 * remain hovered for a certain amount of time before being activated.
 *
 * There is no wrapper element, the single child will be cloned with an
 * `onMouseEnter` and `onMouseLeave` props.
 */
export default function HoverDelay({
  children,
  onEnter,
  onLeave,
  timeout,
  value,
}) {
  const [startTimeout, cancelTimeout] = useAnimationTimeout();

  const onMouseEnter = useCallback(() => {
    startTimeout(onEnter, timeout, value);
  }, [onEnter, startTimeout, timeout, value]);

  const onMouseLeave = useCallback(
    (event) => {
      cancelTimeout();
      onLeave(value);
    },
    [cancelTimeout, onLeave, value]
  );

  return React.cloneElement(children, { onMouseEnter, onMouseLeave });
}

HoverDelay.propTypes = {
  children: PropTypes.element,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func,
  timeout: PropTypes.number,
  value: PropTypes.any,
};

HoverDelay.defaultProps = {
  timeout: 150,
};

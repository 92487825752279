import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useSession } from '../../../techstyle-shared/redux-core';

import useAccountActions from './useAccountActions';

export default function useLoyalty() {
  const actions = useAccountActions();
  const { isLoggedIn } = useSession();

  const loyalty = useSelector((state) => state.loyalty);

  useEffect(() => {
    if (!loyalty.networkStatus.isUpToDate && isLoggedIn) {
      actions.loadLoyaltyDetails();
    }
  }, [actions, loyalty.networkStatus.isUpToDate, isLoggedIn]);

  return loyalty;
}

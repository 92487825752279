import { useEffect, useMemo } from 'react';

import { useSelector, shallowEqual } from 'react-redux';

import { useSession } from '../../../techstyle-shared/redux-core';

import { getCustomerDetail } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useCustomerDetails(inputKeys) {
  const actions = useAccountActions();
  const { isLoggedIn } = useSession();

  // `inputKeys` will likely be passed as an inline array, so memoize it in
  // order to get a stable value and do less work.
  const keyString = JSON.stringify(inputKeys);
  const keys = useMemo(() => JSON.parse(keyString), [keyString]);

  const values = useSelector(
    // This only provides access to the value. For full details including
    // `dateTimeAdded`, `fetchedDate`, `error`, and `networkStatus`, use the
    // `useCustomerDetail` hook to get individual records.
    (state) => keys.map((key) => getCustomerDetail(state, key).value),
    shallowEqual
  );

  const isUpToDate = useSelector((state) =>
    keys.every((key) => getCustomerDetail(state, key).isUpToDate)
  );

  useEffect(() => {
    if (isLoggedIn && !isUpToDate) {
      actions.loadCustomerDetails(keys);
    }
  }, [actions, isLoggedIn, isUpToDate, keys]);

  return values;
}

import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { withThemeProps } from '../../../techstyle-shared/react-components';

const StyledText = styled.p`
  ${({ textStyle }) => textStyle};
  ${({ $center }) =>
    $center &&
    css`
      text-align: center;
    `}
`;

const Text = React.forwardRef(function Text(
  { as = 'p', children, className, textStyle, center = false, ...rest },
  ref
) {
  return (
    <StyledText
      as={as}
      className={className}
      textStyle={textStyle}
      $center={center}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledText>
  );
});

Text.propTypes = {
  as: PropTypes.elementType,
  // Helper for centering text, used to avoid creating a styled component just to center
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  textStyle: PropTypes.any,
};

export default withThemeProps(Text, {
  section: 'typography',
  defaultVariant: 'default',
});

import React, { useContext, useMemo, useEffect } from 'react';

import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import styled, { css, ThemeConsumer } from 'styled-components';

import { Component as AssetProvider } from '../AssetProvider';
import logger from '../logger';
import { colorStyles, highlightStyles, mobileBreakpoint } from '../styles';
import { assetPropType, parseAspectRatio } from '../utils';

const debug = logger.extend('ImageAsset');

const AssetInspector = dynamic(
  () => import(/* webpackChunkName: "AssetInspector" */ '../AssetInspector'),
  { loading: () => null }
);

function backgroundStyles({ imageUrl }) {
  if (imageUrl) {
    return css`
      background-image: url('${imageUrl}');
      ${focalPointStyles};
    `;
  }
}

function focalPointStyles({ focalPoint }) {
  return css`
    background-position: ${focalPoint || 'center'};
  `;
}

function hoverImageStyles({ hoverImageUrl }) {
  if (hoverImageUrl) {
    return css`
      background-image: url('${hoverImageUrl}');
      ${hoverFocalPointStyles};
    `;
  }
}

function hoverFocalPointStyles({ hoverFocalPoint }) {
  if (hoverFocalPoint) {
    return css`
      background-position: ${hoverFocalPoint};
    `;
  }
}

function mobileBackgroundStyles({ mobileImageUrl }) {
  if (mobileImageUrl) {
    return css`
      @media ${mobileBreakpoint} {
        background-image: url('${mobileImageUrl}');
        ${mobileFocalPointStyles};
      }
    `;
  }
}

function mobileFocalPointStyles({ mobileFocalPoint }) {
  if (mobileFocalPoint) {
    return css`
      background-position: ${mobileFocalPoint};
    `;
  }
}

function aspectRatioStyles({ numericAspectRatio }) {
  if (numericAspectRatio) {
    const proportionalHeight = `${100 / numericAspectRatio}%`;
    return css`
      height: 0;
      padding-bottom: ${proportionalHeight};
    `;
  }
  return css`
    height: auto;
  `;
}

// Styling gets a bit murky when both `<picture>` and `<img>` are involved.
// We know we want these styles, so just apply the same to both...
const PictureElement = styled.picture`
  display: inline-block;
  width: 100%;
  height: auto;
  vertical-align: middle;
`;

const ImageElement = styled.img`
  display: inline-block;
  width: 100%;
  height: auto;
  vertical-align: middle;
`;

// Component to use for the hover image when present. It will be rendered on
// top of the normal image instead of completely replacing it, so that the
// original's dimensions are preserved.
const HoverImage = styled(ImageElement)`
  position: absolute;
  left: 0;
  top: 0;
`;

// The outer wrapper when `background` is false.
const ForegroundImage = styled.div`
  display: inline-block;
  position: relative;
  ${highlightStyles};

  > [data-hover-image] {
    display: none;
  }

  :hover > [data-hover-image] {
    display: block;
  }
`;

// The outer wrapper when `background` is true.
const BackgroundImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  position: relative;
  background-size: cover;
  text-align: center;
  overflow: hidden;
  ${backgroundStyles};
  ${colorStyles};
  ${highlightStyles};

  ${mobileBackgroundStyles};

  :link {
    color: inherit;
    text-decoration: inherit;
  }

  :hover {
    ${hoverImageStyles};
  }
`;

// An element that forces its container to be *at least* as tall as necessary
// to preserve a certain aspect ratio. It's not forced to be *only* this tall
// however - if the text/HTML foreground content in the container needs to be
// taller, then it will be.
const AspectRatioShim = styled.div.attrs({
  'aria-hidden': true,
})`
  position: relative;
  width: 100%;
  margin-left: -100%;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
  ${aspectRatioStyles}
`;

// Foreground text/HTML content.
const Content = styled.div`
  width: 100%;
`;

export default function ImageAsset({
  asset,
  background,
  children,
  className,
  aspectRatio,
  href = asset.options.targetUrl || undefined,
  lazyLoad,
  handleOnLoad,
  rootMargin = '400px',
  alt,
  ...rest
}) {
  const { highlightAssets } = useContext(AssetProvider.Context);
  const numericAspectRatio = useMemo(() => {
    if (aspectRatio && aspectRatio !== 'auto') {
      return parseAspectRatio(aspectRatio);
    }
  }, [aspectRatio]);

  const imageUrl = asset.imageFilename;
  const mobileImageUrl = asset.mobileImageFilename;
  const hoverImageUrl = asset.options.hoverImage;
  const { focalPoint, hoverFocalPoint, mobileFocalPoint } =
    asset.options.customVars;
  const backgroundColor = asset.options.bgColor || undefined;
  const textColor =
    // Encourage migration to `textColor` spelling instead of `textcolor`, but
    // support both.
    asset.options.customVars.textColor ||
    asset.options.customVars.textcolor ||
    undefined;
  let title = rest.title || asset.options.hoverText || undefined;
  let inspector = null;

  const supportsLazyLoading = useNativeLazyLoading();
  const [imageRef, imageIsInView] = useInView({
    triggerOnce: true,
    rootMargin,
    skip: !lazyLoad || supportsLazyLoading,
  });
  const shouldLoadImage = !lazyLoad || imageIsInView || supportsLazyLoading;

  // If there's a hover image, preload it so there's no delay.
  useEffect(() => {
    if (hoverImageUrl) {
      const image = new Image();
      image.src = hoverImageUrl;
    }
  }, [hoverImageUrl]);

  if (highlightAssets) {
    // Unset `href` when debugging assets, because the asset inspector controls
    // render interactive elements like buttons and links, which are invalid
    // when inside another interactive element like `<a href>`.
    href = undefined;
    title = `${asset.container.name}: ${asset.label || asset.id}`;
    inspector = <AssetInspector asset={asset} />;
  }

  if (background) {
    let aspectRatioShim = null;

    if (aspectRatio === 'auto' && imageUrl) {
      aspectRatioShim = (
        <AspectRatioShim>
          <PictureElement data-image="">
            {mobileImageUrl ? (
              <ThemeConsumer>
                {(theme) => {
                  const mobileQuery = mobileBreakpoint({ theme });
                  return shouldLoadImage ? (
                    <source media={mobileQuery} srcSet={mobileImageUrl} />
                  ) : null;
                }}
              </ThemeConsumer>
            ) : null}
            {shouldLoadImage ? (
              <ImageElement
                src={imageUrl}
                alt={alt}
                loading={lazyLoad ? 'lazy' : 'eager'}
                onLoad={handleOnLoad}
              />
            ) : null}
          </PictureElement>
        </AspectRatioShim>
      );
    } else if (numericAspectRatio) {
      aspectRatioShim = (
        <AspectRatioShim numericAspectRatio={numericAspectRatio} />
      );
    }

    const foreground = children ? (
      <Content>{children}</Content>
    ) : (
      <Content
        // Use an attribute selector with this to target the HTML with CSS.
        data-asset-html=""
        dangerouslySetInnerHTML={{ __html: asset.options.htmlText }}
      />
    );

    return (
      <BackgroundImage
        as={href == null ? undefined : 'a'}
        backgroundColor={backgroundColor}
        className={className}
        focalPoint={focalPoint}
        highlightAssets={highlightAssets}
        hoverFocalPoint={hoverFocalPoint}
        hoverImageUrl={hoverImageUrl}
        href={href}
        imageUrl={imageUrl}
        mobileFocalPoint={mobileFocalPoint}
        mobileImageUrl={mobileImageUrl}
        textColor={textColor}
        title={title}
        ref={imageRef}
        {...rest}
      >
        {aspectRatioShim}
        {foreground}
        {inspector}
      </BackgroundImage>
    );
  } else if (debug.enabled && aspectRatio) {
    debug(
      'Asset “%s” used %caspectRatio%c without %cbackground%c,%c this will have no effect.',
      asset.container.name,
      'font-weight: bold',
      '',
      'font-weight: bold; white-space: nowrap',
      'font-weight: normal',
      ''
    );
  }

  return (
    <ForegroundImage
      as={href == null ? undefined : 'a'}
      className={className}
      highlightAssets={highlightAssets}
      href={href}
      title={title}
      ref={imageRef}
      {...rest}
    >
      <PictureElement data-image="">
        {mobileImageUrl ? (
          <ThemeConsumer>
            {(theme) => {
              const mobileQuery = mobileBreakpoint({ theme });
              return shouldLoadImage ? (
                <source media={mobileQuery} srcSet={mobileImageUrl} />
              ) : null;
            }}
          </ThemeConsumer>
        ) : null}
        {shouldLoadImage ? (
          <ImageElement
            src={imageUrl}
            alt={alt}
            loading={lazyLoad ? 'lazy' : 'eager'}
            onLoad={handleOnLoad}
          />
        ) : null}
      </PictureElement>
      {hoverImageUrl ? (
        <HoverImage data-hover-image="" src={hoverImageUrl} />
      ) : null}
      {inspector}
    </ForegroundImage>
  );
}

ImageAsset.propTypes = {
  alt: PropTypes.string,
  /**
   * The aspect ratio (formatted like `16:9`, `4:3`, `1:1`, etc.) at which to
   * render the component, if it’s a background image. Use `auto` to
   * automatically match the aspect ratio of the actual image (the same as if
   * it were a foreground image).
   */
  aspectRatio: PropTypes.string,
  asset: assetPropType,
  background: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  handleOnLoad: PropTypes.func,
  /**
   * Set or unset where the image should link; if unspecified, it will be taken
   * from the asset's `targetUrl` option. You can use this to force the image to
   * link to a certain URL, or set to `null` to undo the URL set by the asset.
   */
  href: PropTypes.string,
  /**
   * Lazy loads the image
   */
  lazyLoad: PropTypes.bool,
  /**
   * Margin around the root. Can have values similar to the CSS margin property,
   * e.g. "10px 20px 30px 40px" or "400px" (top, right, bottom, left). The values can be percentages.
   * default value is set to "400px"
   */
  rootMargin: PropTypes.string,
};

ImageAsset.defaultProps = {
  alt: '',
  background: false,
  lazyLoad: false,
};

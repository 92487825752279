import { gql, useQuery } from '../../../techstyle-shared/react-graphql';

import {
  INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT,
  INDIVIDUAL_PRODUCT_PRICE_FRAGMENT,
  INDIVIDUAL_PRODUCT_PROMOS_FRAGMENT,
  INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT,
  INDIVIDUAL_PRODUCT_SELECTORS_FRAGMENT,
} from './useProductQuery';

export const GET_PRODUCTS_BY_MASTER_PRODUCT_IDS = gql`
  ${INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRICE_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PROMOS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_SELECTORS_FRAGMENT}
  query AllMasterProductsByProductIds($productIds: [Int!]!) {
    masterProducts(productIds: $productIds) {
      ... on IndividualProduct {
        ...IndividualProductMainParts
        ...IndividualProductPriceParts
        ...IndividualProductPromosParts
        ...IndividualProductImagesParts
        ...IndividualProductSelectorsParts
      }
    }
  }
`;

export const useProductsByMasterProductIdsQuery = (
  { productIds },
  options = {},
  query
) => {
  return useQuery(query ?? GET_PRODUCTS_BY_MASTER_PRODUCT_IDS, {
    ...options,
    variables: {
      productIds,
      ...options.variables,
    },
  });
};

import React, { useMemo } from 'react';

import NextLink from 'next/link';
import PropTypes from 'prop-types';

import useRoutes from '../useRoutes';

export default function Link({
  as,
  children,
  href,
  // Unlike next/link, passHref defaults to true.
  passHref = true,
  // Unlike next/link, prefetch defaults to false.
  prefetch = false,
  route: inputRoute,
  to,
  ...rest
}) {
  const routesContext = useRoutes();
  const route = useMemo(() => {
    // next-routes uses `route` and `to`, we're going to start treating those
    // and `href` all the same and send them through `resolve`.
    return routesContext.resolve(inputRoute || to || href, as);
  }, [as, href, inputRoute, routesContext, to]);

  return (
    <NextLink
      href={route.href}
      as={route.asPath}
      passHref={passHref}
      prefetch={prefetch}
      {...rest}
    >
      {children}
    </NextLink>
  );
}

Link.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  passHref: PropTypes.bool,
  prefetch: PropTypes.bool,
  route: PropTypes.string,
  to: PropTypes.string,
};

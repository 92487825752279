import React, { useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export function useProductContext() {
  return useContext(Context);
}

export default function ProductContext({
  children,
  product,
  productIndex = 0,
}) {
  const context = useMemo(() => {
    // TODO: Watch out of other times we need to do backwards compatibility stuff like this in context,
    // it might be better to do this a level higher (e.g. Apollo Cache?)
    const productImages = product.imageSets
      ? {
          default: product.imageSets.default?.images ?? null,
          plus: product.imageSets.plus?.images ?? null,
        }
      : product.productImages;

    return {
      ...product,
      productIndex: String(productIndex),
      productImages,
    };
  }, [product, productIndex]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

ProductContext.propTypes = {
  children: PropTypes.node,
  product: PropTypes.object,
  productIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

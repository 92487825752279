import { StatusCodes } from 'http-status-codes';
import { NextPageContext } from 'next';
import Router from 'next/router';

import getIsResponseEnded from './getIsResponseEnded';

type IsomorphicRedirectOptions = {
  ctx?: NextPageContext;
  disabledClientSide?: boolean;
  disabledServerSide?: boolean;
  redirectPath: string;
  statusCode: StatusCodes;
};

function isTruthyQueryParameter(parameter: string) {
  return /^(true|1)$/.test(parameter);
}

const isomorphicRedirect = ({
  redirectPath,
  disabledClientSide = false,
  disabledServerSide = false,
  statusCode = 307,
  ctx,
}: IsomorphicRedirectOptions) => {
  if (ctx) {
    const {
      res,
      query: { errorCodeRedirectDisabled },
    } = ctx;
    let disabled = disabledServerSide;

    if (typeof errorCodeRedirectDisabled === 'string') {
      disabled = isTruthyQueryParameter(errorCodeRedirectDisabled);
    }
    if (res && !disabled) {
      const responseEnded = getIsResponseEnded(ctx);

      if (!responseEnded) {
        res.writeHead(statusCode, { Location: redirectPath });
        res.end();
      }
    }
  } else {
    if (process.browser) {
      const errorCodeRedirectDisabled =
        Router?.router?.query.disableErrorCodeRedirect;
      let disabled = disabledClientSide;

      if (typeof errorCodeRedirectDisabled === 'string') {
        disabled = isTruthyQueryParameter(errorCodeRedirectDisabled);
      }

      if (!disabled) {
        Router.replace(redirectPath);
      }
    }
  }
};

export default isomorphicRedirect;

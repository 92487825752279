import { useEffect } from 'react';

import { useModuleSelector } from './accountsModuleTyped';
import useAccountActions from './useAccountActions';

export default function useReturnSettings() {
  const actions = useAccountActions();
  const returnSettings = useModuleSelector((state) => state.returnSettings);

  useEffect(() => {
    if (!returnSettings.networkStatus.isUpToDate) {
      actions.loadReturnSettings();
    }
  }, [actions, returnSettings.networkStatus.isUpToDate]);

  return returnSettings;
}

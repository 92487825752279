import { CookieNames } from '../constants/cookies';
import { SecondarySites } from '../constants/secondarySites';

const siteValues = Object.values(SecondarySites);

export const getSecondarySite = (context) => {
  // We need to ignore the proxydirectory to get this to work in lower environments
  const proxyRegex = new RegExp(`^/proxydirectory(?:/|$)`);
  const path = context.asPath;

  if (!proxyRegex.test(path)) {
    const cookies = context.req?.universalCookies || context.cookies;
    cookies.remove(CookieNames.SECONDARY_SITE);

    for (const siteValue of siteValues) {
      const secondarySiteRegex = new RegExp(`^/${siteValue}(?:/|$)`);

      if (secondarySiteRegex.test(path)) {
        cookies.set(CookieNames.SECONDARY_SITE, siteValue);
        return siteValue;
      }
    }
  }
  return null;
};

import {
  logInSuccess,
  signUpSuccess,
  updateMembershipStatusSuccess,
} from '../../../techstyle-shared/react-accounts';
import {
  createAction,
  createReducer,
  createSelector,
  outOfDateStatus,
  upToDateStatus,
  startLoading,
  stopLoading,
  createCachedSelector,
  loadSessionSuccess,
} from '../../../techstyle-shared/redux-core';

import logger from './logger';

const debug = logger.extend('abTestModule');

export const initialState = {
  forceAllControl: false,
  shouldUseActivatedTests: false,
  requestedTests: {},
  activatedTests: {
    data: [],
    fetchedDate: null,
    networkStatus: outOfDateStatus(),
  },
  testsUsedOnPage: [],
};

export const defaultTestData = {
  campaignCode: '',
  data: {
    group: 0,
    cohortNumber: 1,
    enabled: false,
    activated: false,
  },
  fetchedDate: null,
  networkStatus: outOfDateStatus(),
};

export const TestErrorType = {
  OTHER: 'OTHER',
};

export const setShouldUseActivatedTests = createAction(
  'abTest/setShouldUseActivatedTests'
);
export const invalidateTests = createAction('abTest/invalidateTests');
export const markTestUsedOnPage = createAction('abTest/markTestUsedOnPage');
export const clearTestsUsedOnPage = createAction('abTest/clearTestsUsedOnPage');

export const loadAllTestsRequest = createAction('abTest/loadAllTestsRequest');
export const loadAllTestsSuccess = createAction('abTest/loadAllTestsSuccess');
export const loadAllTestsFailure = createAction('abTest/loadAllTestsFailure');

export const loadActivatedTestsRequest = createAction(
  'abTest/loadActivatedTestsRequest'
);
export const loadActivatedTestsSuccess = createAction(
  'abTest/loadActivatedTestsSuccess'
);
export const loadActivatedTestsFailure = createAction(
  'abTest/loadActivatedTestsFailure'
);

export const forceAllTestsToControlRequest = createAction(
  'abTest/forceAllTestsToControlRequest'
);
export const forceAllTestsToControlSuccess = createAction(
  'abTest/forceAllTestsToControlSuccess'
);
export const forceAllTestsToControlFailure = createAction(
  'abTest/forceAllTestsToControlFailure'
);
export const invalidateActivatedTests = createAction(
  'abTest/invalidateActivatedTests'
);

export const setForceAllTestsControl = createAction(
  'abTest/setForceAllTestsControl'
);

export const forceTestCohortRequest = createAction(
  'abTest/forceTestCohortRequest'
);
export const forceTestCohortSuccess = createAction(
  'abTest/forceTestCohortSuccess'
);
export const forceTestCohortFailure = createAction(
  'abTest/forceTestCohortFailure'
);

export const splitTestRequest = createAction('abTest/splitTestRequest');
export const splitTestSuccess = createAction('abTest/splitTestSuccess');
export const splitTestFailure = createAction('abTest/splitTestFailure');

// We don't want to import the features here, so we just create a copy of the
const loadFeaturesSuccess = createAction('features/loadFeaturesSuccess');

/**
 * Loads tests that the current user has been assigned a group for.
 * The endpoint implies "activated" tests, but really returns all tests
 * that are enabled for the current user (control or variant)
 */
export function loadActivatedTests(forceRequest = false) {
  const request = {
    bentoApi: {
      endpoint: 'tests/activated',
      requestKey: 'loadActivatedTests',
      actions: [
        loadActivatedTestsRequest,
        loadActivatedTestsSuccess,
        loadActivatedTestsFailure,
      ],
    },
  };

  if (forceRequest) {
    return request;
  }

  return (dispatch, getState) => {
    const state = getState();
    const { activatedTests } = state.abTest;
    const { networkStatus } = activatedTests;

    if (!networkStatus.isUpToDate) {
      return dispatch(request);
    }
  };
}

// Forces all tests to control
export function forceAllTestsToControl() {
  return {
    bentoApi: {
      endpoint: 'tests/forceControl',
      method: 'GET',
      requestKey: 'forceAllTestsToControl',
      actions: [
        forceAllTestsToControlRequest,
        forceAllTestsToControlSuccess,
        forceAllTestsToControlFailure,
      ],
    },
  };
}

// Enable test for user and assign random value if none exists
export function splitTest(
  campaignCode,
  {
    activate = true,
    explain = false,
    expireCache = true,
    forceRequest = false,
    usedOnPage = true,
    ...options
  } = {}
) {
  return (dispatch, getState) => {
    const state = getState();
    const { error, networkStatus } = getTest(state, campaignCode);

    if (usedOnPage) {
      dispatch(markTestUsedOnPage(campaignCode));
    }

    let shouldFetch = false;

    if (forceRequest) {
      shouldFetch = true;
    } else if (error) {
      debug('A/B test “%s” has error; skipping refetch.', campaignCode);
    } else if (!networkStatus.isUpToDate && !networkStatus.isLoading) {
      debug('A/B test “%s” is invalidated; refetching.', campaignCode);
      shouldFetch = true;
    }

    if (shouldFetch) {
      return dispatch({
        bentoApi: {
          endpoint: `tests/${campaignCode}/split`,
          method: 'POST',
          json: {
            ...options,
            activate,
            explain,
            expireCache,
            campaignCode,
          },
          requestKey: `splitTest:${campaignCode}`,
          actions: [
            (payload, meta) =>
              splitTestRequest(payload, { ...meta, campaignCode }),
            (payload, meta) =>
              splitTestSuccess(payload, { ...meta, campaignCode }),
            (payload, meta) =>
              splitTestFailure(payload, { ...meta, campaignCode }),
          ],
        },
      });
    }
  };
}

// Forces user test participation to a specific cohort
export function forceTestCohort(
  campaignCode,
  { cohortNumber = 2, explain = false, expireCache = true, ...options } = {}
) {
  return {
    bentoApi: {
      endpoint: `tests/${campaignCode}/force`,
      method: 'POST',
      json: { ...options, cohortNumber },
      actions: [
        (payload, meta) =>
          forceTestCohortRequest(payload, { ...meta, campaignCode }),
        (payload, meta) =>
          forceTestCohortSuccess(payload, { ...meta, campaignCode }),
        (payload, meta) =>
          forceTestCohortFailure(payload, { ...meta, campaignCode }),
      ],
    },
  };
}

// Loads all system A/B test data
export function loadAllTests() {
  return {
    bentoApi: {
      endpoint: 'tests',
      requestKey: 'loadAllTests',
      actions: [loadAllTestsRequest, loadAllTestsSuccess, loadAllTestsFailure],
    },
  };
}

function handleTestRequest(state, action) {
  const { campaignCode } = action.meta;
  if (!state.requestedTests[campaignCode]) {
    state.requestedTests[campaignCode] = {
      fetchedDate: action.meta.fetchedDate,
      networkStatus: outOfDateStatus(),
    };
  }
  startLoading(state.requestedTests[campaignCode]);
}

function handleTestSuccess(state, action) {
  const { campaignCode, fetchedDate } = action.meta;
  const { payload: test } = action;
  state.requestedTests[campaignCode] = {
    data: test,
    error: null,
    fetchedDate,
    networkStatus: upToDateStatus(),
  };
}

function handleTestFailure(state, action) {
  const { campaignCode } = action.meta;
  const test = state.requestedTests[campaignCode];
  if (test) {
    // TODO: Can we determine more specific error types?
    test.error = TestErrorType.OTHER;
    stopLoading(test);
  }
}

function handleActivatedTestsRequest(state) {
  startLoading(state.activatedTests);
}

function handleActivatedTestsSuccess(state, action) {
  const {
    payload: tests,
    meta: { fetchedDate },
  } = action;

  state.activatedTests = {
    data: tests,
    fetchedDate,
    networkStatus: upToDateStatus(),
  };

  if (state.shouldUseActivatedTests) {
    tests.forEach((test) => {
      const { campaignCode } = test;
      const existingTest = state.requestedTests[campaignCode];
      const isActivatedTestNewer = !existingTest
        ? true
        : Date.parse(fetchedDate) > Date.parse(existingTest.fetchedDate);

      if (isActivatedTestNewer) {
        state.requestedTests[campaignCode] = {
          data: {
            ...test,
            enabled: true,
            activated: test.group > 1,
          },
          fetchedDate,
          networkStatus: upToDateStatus(),
        };
      }
    });
  }
}

function handleActivatedTestsFailure(state) {
  stopLoading(state.activatedTests);
}

function invalidateRequestedTests(state) {
  Object.keys(state.requestedTests).forEach((campaignCode) => {
    state.requestedTests[campaignCode].networkStatus = outOfDateStatus();
  });
  state.activatedTests.networkStatus = outOfDateStatus();
}

export const abTestReducer = createReducer(initialState, {
  [setForceAllTestsControl]: (state, action) => {
    state.forceAllControl = action.payload;
  },
  [setShouldUseActivatedTests]: (state, action) => {
    state.shouldUseActivatedTests = action.payload;
  },
  [splitTestRequest]: handleTestRequest,
  [splitTestFailure]: handleTestFailure,
  [splitTestSuccess]: handleTestSuccess,
  [forceTestCohortRequest]: handleTestRequest,
  [forceTestCohortFailure]: handleTestFailure,
  [forceTestCohortSuccess]: handleTestSuccess,
  [loadActivatedTestsRequest]: handleActivatedTestsRequest,
  [loadActivatedTestsFailure]: handleActivatedTestsFailure,
  [loadActivatedTestsSuccess]: handleActivatedTestsSuccess,
  [forceAllTestsToControlRequest]: (state) => {
    startLoading(state.activatedTests);
  },
  [forceAllTestsToControlSuccess]: handleActivatedTestsSuccess,
  [forceAllTestsToControlFailure]: (state) => {
    stopLoading(state.activatedTests);
  },
  [invalidateActivatedTests]: (state) => {
    state.activatedTests.networkStatus = outOfDateStatus();
  },
  [markTestUsedOnPage]: (state, action) => {
    const campaignCode = action.payload;
    if (!state.testsUsedOnPage.includes(campaignCode)) {
      state.testsUsedOnPage.push(campaignCode);
    }
  },
  [clearTestsUsedOnPage]: (state, action) => {
    state.testsUsedOnPage = [];
  },
  [invalidateTests]: invalidateRequestedTests,
  [loadSessionSuccess]: (state, action) => {
    if (action.meta.customerDidChange) {
      invalidateRequestedTests(state);
    }
  },
  [logInSuccess]: invalidateRequestedTests,
  [signUpSuccess]: invalidateRequestedTests,
  [updateMembershipStatusSuccess]: invalidateRequestedTests,
  [loadFeaturesSuccess]: (state, action) => {
    const { meta } = action;
    if (meta?.shouldInvalidateABTests) {
      state.activatedTests.networkStatus = outOfDateStatus();
    }
  },
});

export function createTest(campaignCode, test, forceAllControl) {
  if (test) {
    if (test.data) {
      return {
        campaignCode,
        ...test,
        data: {
          ...test.data,
          cohortNumber: forceAllControl ? 1 : test.data.cohortNumber,
          group: forceAllControl ? 1 : test.data.group,
          isControl: forceAllControl ? true : test.data.cohortNumber <= 1,
          isVariant: forceAllControl ? false : test.data.cohortNumber > 1,
        },
      };
    }
    return { campaignCode, ...test };
  }
  return {
    campaignCode,
    fetchedDate: null,
    networkStatus: outOfDateStatus(),
  };
}

const getCachedTest = createCachedSelector(
  [
    (abTest, campaignCode) => campaignCode,
    (abTest, campaignCode) => abTest.requestedTests[campaignCode],
    (abTest) => abTest.forceAllControl,
  ],
  createTest
)((abTest, campaignCode) => campaignCode);

export function getTest(state, campaignCode) {
  return getCachedTest(state.abTest, campaignCode);
}

export const getTestsUsedOnPage = createSelector(
  [(state) => state.abTest],
  (abTest) => {
    return abTest.testsUsedOnPage.map((campaignCode) =>
      getCachedTest(abTest, campaignCode)
    );
  }
);

export const getRequestedTests = createSelector(
  [(state) => state.abTest],
  (abTest) => {
    return Object.keys(abTest.requestedTests).map((campaignCode) =>
      getCachedTest(abTest, campaignCode)
    );
  }
);

function initABTestModule(
  { shouldUseActivatedTests } = { shouldUseActivatedTests: false }
) {
  return async (dispatch, getState, context) => {
    if (shouldUseActivatedTests) {
      dispatch(setShouldUseActivatedTests(true));
      if (context.isServer && !context.hasPreloadedState) {
        const isAnonymous = context.req.context.anonymousServerSession;
        if (!isAnonymous) {
          dispatch(loadActivatedTests());
        }
      }

      if (!context.isServer) {
        dispatch(loadActivatedTests());
      }
    }
  };
}

export default function abTestModule(
  options = { shouldUseActivatedTests: false }
) {
  return {
    id: 'abTest',
    reducerMap: {
      abTest: abTestReducer,
    },
    initialActions: [initABTestModule(options)],
  };
}

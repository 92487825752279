import React from 'react';

import PageCountProvider from './PageCountProvider';

// The easiest way to detect whether a non-shallow page render has occured is
// by counting the number of times `getInitialProps` has run. Since it must
// run on the server once to serve an initial page, it starts at 1. We then
// increment it on every `getInitialProps` call in the browser.
let getInitialPropsCount = 1;

export default function pageTrackingExtension({
  cookieName,
  cookieOptions,
  usePageCountReducer,
}) {
  return {
    id: 'pageTracking',
    app: {
      getInitialProps: ({ Component, ctx }) => {
        if (process.browser) {
          getInitialPropsCount += 1;
        }
        return {};
      },
      render(props, children) {
        return (
          <PageCountProvider
            cookieName={cookieName}
            cookieOptions={cookieOptions}
            getInitialPropsCount={getInitialPropsCount}
            usePageCountReducer={usePageCountReducer}
          >
            {children}
          </PageCountProvider>
        );
      },
    },
  };
}

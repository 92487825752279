import React from 'react';

import {
  loadCustomerDetails,
  loadMembership,
} from '../../../techstyle-shared/react-accounts';

import marketingModule, {
  setPageInfo,
  setRequestInfo,
} from './marketingModule';
import MarketingSnippets from './MarketingSnippets';
import sendToRaygun from './sendToRaygun';

export default function marketingExtension({
  getRequestInfo,
  googleTagManagerEvents,
  segmentEvents,
  referringPageCookieName,
  reduxMiddlewares,
  ...snippets
} = {}) {
  return {
    id: 'marketing',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
    redux(context) {
      const reduxConfig = {
        modules: [marketingModule],
      };
      const extension = { middleware: [] };

      if (process.browser) {
        if (googleTagManagerEvents) {
          extension.middleware.push(googleTagManagerEvents);
        }
        if (segmentEvents) {
          extension.middleware.push(segmentEvents);
        }
      }

      const extraMiddleware =
        typeof reduxMiddlewares === 'function'
          ? reduxMiddlewares(context)
          : reduxMiddlewares;

      if (extraMiddleware && Array.isArray(extraMiddleware)) {
        extraMiddleware.forEach((middleware) => {
          extension.middleware.push(middleware);
        });
      }

      if (extension.middleware.length) {
        reduxConfig.extensions = [extension];
      }

      return reduxConfig;
    },
    document: process.browser
      ? undefined
      : {
          getInitialProps: {
            enhance(getInitialProps) {
              return async (ctx) => {
                const initialProps = await getInitialProps(ctx);
                let { beforeMain, beforeNextScript, afterNextScript } =
                  snippets;

                const { requestInfo } = ctx.store.getState().marketing;

                if (typeof beforeMain === 'function') {
                  beforeMain = beforeMain(requestInfo);
                }
                if (typeof beforeNextScript === 'function') {
                  beforeNextScript = beforeNextScript(requestInfo);
                }
                if (typeof afterNextScript === 'function') {
                  afterNextScript = afterNextScript(requestInfo);
                }

                if (beforeMain) {
                  initialProps.beforeMain.push(beforeMain);
                }
                if (beforeNextScript) {
                  initialProps.beforeNextScript.push(beforeNextScript);
                }
                if (afterNextScript) {
                  initialProps.afterNextScript.push(afterNextScript);
                }
                return initialProps;
              };
            },
          },
        },
    app: {
      getInitialProps: {
        enhance(getInitialProps) {
          return async ({ Component, ctx }) => {
            ctx.sendToRaygun = sendToRaygun;
            if (ctx.err) {
              sendToRaygun(ctx.err);
            }

            let initialProps;
            try {
              initialProps = await getInitialProps({ Component, ctx });
            } finally {
              // Populate `marketing.requestInfo` in a `finally` block so that
              // it's available even if `getInitialProps` threw an error.
              if (getRequestInfo && ctx.req) {
                const requestInfo = await getRequestInfo(ctx);
                ctx.store.dispatch(setRequestInfo(requestInfo));
              }
            }

            const { requestInfo } = ctx.store.getState().marketing;

            return { ...initialProps, marketingRequestInfo: requestInfo };
          };
        },
      },
      render(props, children) {
        // eslint-disable-next-line react/prop-types
        const { marketingRequestInfo: requestInfo } = props;
        return (
          <MarketingSnippets
            referringPageCookieName={referringPageCookieName}
            requestInfo={requestInfo}
            snippets={snippets}
          >
            {children}
          </MarketingSnippets>
        );
      },
    },
    page({ marketingInfo: inputMarketingInfo }) {
      return {
        async getInitialProps(ctx) {
          const marketingInfo =
            typeof inputMarketingInfo === 'function'
              ? await inputMarketingInfo(ctx)
              : inputMarketingInfo;

          ctx.store.dispatch(setPageInfo(marketingInfo));

          // prefetching membership and customerDetail for segment's GTM dataLayer if server-side
          if (segmentEvents && !process.browser) {
            const prefetchCustomerDetails = ['gender'];
            await Promise.all([
              ctx.store.dispatch(loadMembership()),
              ctx.store.dispatch(loadCustomerDetails(prefetchCustomerDetails)),
            ]);
          }

          return {};
        },
      };
    },
  };
}

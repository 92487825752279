import BaseReactInputPosition from 'react-input-position';

/**
 * A modified version of `react-input-position` that plays nicer with iOS 15 Safari.
 *
 * The base version of `react-input-position` sets the `itemPosition` back to `0, 0`
 * whenever there is a window resize. On newer versions of iOS a resize event is called
 * any time the address bar changes in size, what this means for us is that whenever
 * PictureZoom is being interacted with the `itemPosition` will constantly get reset
 * whenever the user is dragging in the y-axis.
 *
 * This modification to `handleResize` will ensure that the `itemPosition` will never get
 * reset when the element is being interacted with using touch and will only get reset if
 * the item itself changes in size.
 */
export default class ReactInputPosition extends BaseReactInputPosition {
  handleResize = () => {
    if (!this.touched) {
      if (this.itemRef.current) {
        const state = this.getState();
        const { width: prevWidth, height: prevHeight } = state.itemDimensions;
        const { width, height } = this.itemRef.current.getBoundingClientRect();

        if (prevWidth !== width || prevHeight !== height) {
          this.refreshPosition();
        }
      }
    }
  };
}

import { fontFace, hideVisually } from 'polished';
import { css } from 'styled-components';

/**
 * DEPRECATED
 * Use polished instead: https://polished.js.org/docs/#hidevisually
 * For backwards compatibility, we still offer our own export.
 */
export const visuallyHidden = hideVisually();

const defaultFontFormats = ['woff2', 'woff'];

export function globalFontFaces({ theme }) {
  if (theme && theme.fonts) {
    return theme.fonts.map((font) =>
      fontFace({
        fontDisplay: 'fallback',
        fileFormats: defaultFontFormats,
        ...font,
      })
    );
  }
}

// Use a more modern inheritance-based box-sizing setting:
// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
export const globalBorderBox = css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

export const globalReset = css`
  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  dl,
  dd,
  form,
  fieldset,
  label {
    margin: 0;
    padding: 0;
  }
`;

function camelToHyphen(str) {
  return str
    .replace(/[A-Z]/g, function (m) {
      return '-' + m.toLowerCase();
    })
    .toLowerCase();
}

// Simple cache for the expensive part of `objectToMediaQuery` below.
const mediaQueryCache = new Map();

export function objectToMediaQuery(query) {
  if (typeof query === 'string' || !query) {
    return query;
  }
  const cacheKey = JSON.stringify(query);
  let string = mediaQueryCache.get(cacheKey);
  if (string == null) {
    string = Object.keys(query)
      .map((name) => {
        const feature = camelToHyphen(name);
        let value = query[name];
        if (typeof value === 'boolean') {
          return value ? feature : `not ${feature}`;
        }
        if (typeof value === 'number' && /[height|width]$/.test(feature)) {
          value = `${value}px`;
        }
        return `(${feature}: ${value})`;
      })
      .join(' and ');

    mediaQueryCache.set(cacheKey, string);
  }
  return string;
}

export function getDesktopMediaQuery(theme) {
  const { mobile, desktop } = theme.breakpoints;
  if (desktop) {
    return objectToMediaQuery(desktop);
  }
  // Invert the mobile media query with `not`.
  return `not all and ${objectToMediaQuery(mobile)}`;
}

/**
 * Media query template that renders only on mobile sizes.
 * Use like:
 *   ${mobile`color: black;`}
 */
export function mobile(...args) {
  return css`
    @media ${(props) => objectToMediaQuery(props.theme.breakpoints.mobile)} {
      ${css(...args)};
    }
  `;
}

/**
 * Media query template that renders only on desktop sizes.
 * Use like:
 *   ${desktop`color: black;`}
 */
export function desktop(...args) {
  return css`
    @media ${(props) => getDesktopMediaQuery(props.theme)} {
      ${css(...args)};
    }
  `;
}

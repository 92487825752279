import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getWaitlistIds } from './accountsModule';
import useAccountActions from './useAccountActions';

// useWaitlistIds custom hook to retrieve all waitlist item Ids associated w/ current user
export default function useWaitlistIds() {
  const actions = useAccountActions();
  const waitlistIds = useSelector(getWaitlistIds);
  const isUpToDate = waitlistIds.networkStatus.isUpToDate;

  useEffect(() => {
    const load = async () => {
      await actions.loadWaitlistIds();
    };

    if (!isUpToDate) {
      load();
    }
  }, [actions, isUpToDate]);

  return waitlistIds;
}

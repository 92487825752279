import { useEffect } from 'react';

import { getOrderById } from './accountsModule';
import { useModuleSelector } from './accountsModuleTyped';
import type { OrderId } from './accountsModuleTyped';
import useAccountActions from './useAccountActions';

export default function useOrderDetail({ orderId }: { orderId: OrderId }) {
  const actions = useAccountActions();
  const orderDetails = useModuleSelector((state) =>
    orderId ? getOrderById(state, orderId) : null
  );

  const isUpToDate = orderDetails
    ? orderDetails.networkStatus.isUpToDate
    : true;

  useEffect(() => {
    if (!isUpToDate) {
      actions.loadOrderDetails(orderId);
    }
  }, [actions, isUpToDate, orderId]);

  return orderDetails;
}

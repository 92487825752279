import { useEffect, useState } from 'react';

/**
 * Hook for working with timeouts. See `docs/Hooks.md` for usage!
 */
export default function useTimeout(delay, callback) {
  const [done, setDone] = useState(delay === 0);

  useEffect(() => {
    if (!done && delay != null && delay !== 0) {
      const timer = setTimeout(() => {
        setDone(true);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [delay, done]);

  useEffect(() => {
    if (done && callback) {
      callback();
    }
  }, [callback, done]);

  return done;
}

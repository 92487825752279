import React, { useEffect, useRef, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useFocusRegion from '../useFocusRegion';
import useTabIndex from '../useTabIndex';

function getVisibilityStyles({ isVisible }) {
  if (!isVisible) {
    return {
      opacity: 0,
      pointerEvents: 'none',
      zIndex: -1,
    };
  }
}

const RevealOnFocusButton = styled.button`
  position: relative;
  ${getVisibilityStyles};
`;

/**
 * A focusable element (by default, a `<button>`) that will be invisible unless
 * focused (typically by navigating with the keyboard) or rendered within a
 * `<FocusRegion>` that has focus. The element will still be visible to screen
 * readers and other assistive technology.
 *
 * The element can be revealed in the following ways:
 *
 * - It gains focus (`.focus()` called on ref, navigated to via Tab, etc.)
 * - Its `forceReveal` prop is set to true.
 * - There is an ancestor `<FocusRegion>` and something is focused within it.
 * - The nearest ancestor `<FocusRegion>` has its `forceFocus` prop set to true.
 * - The nearest ancestor `<FocusRegion>` had its `initialFocus` prop set to
 *   true and nothing has yet been unfocused within it.
 */
export default function RevealOnFocus({
  forceReveal = false,
  onBlur,
  onFocus,
  tabIndex,
  ...rest
}) {
  tabIndex = useTabIndex(tabIndex);
  const ref = useRef();
  const [hasFocus, setFocus] = useState(false);

  useEffect(() => {
    if (document.hasFocus() && ref.current.contains(document.activeElement)) {
      setFocus(true);
    }
  }, []);

  const handleBlur = useCallback(
    (event) => {
      setFocus(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const handleFocus = useCallback(
    (event) => {
      setFocus(true);
      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const regionHasFocus = useFocusRegion();
  const isVisible = Boolean(forceReveal || hasFocus || regionHasFocus);

  return (
    <RevealOnFocusButton
      isVisible={isVisible}
      onBlur={handleBlur}
      onFocus={handleFocus}
      ref={ref}
      tabIndex={tabIndex}
      {...rest}
    />
  );
}

RevealOnFocus.propTypes = {
  /**
   * Element type to render; defaults to `button`.
   */
  as: PropTypes.elementType,
  /**
   * Whether to reveal the element regardless of whether it (or its
   * `<FocusRegion>`) is actually focused. This can be useful to show the
   * element when something else is visible but not necessarily focused, like a
   * menu.
   */
  forceReveal: PropTypes.bool,
  /**
   * Blur handler; it will be called after updating the internal focused state.
   */
  onBlur: PropTypes.func,
  /**
   * Focus handler; it will be called after updating the internal focused state.
   */
  onFocus: PropTypes.func,
  /**
   * The desired `tabIndex`; it will be used as input to `useTabIndex` to
   * determine the final value.
   */
  tabIndex: PropTypes.number,
};

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { getResolveRoute } from '../dynamicRoutesModule';
import { RoutesContext } from '../useRoutes';

export default function RoutesProvider({ children }) {
  const dispatch = useDispatch();
  const value = useMemo(() => {
    // In general it's not OK to dispatch during render. But we're not updating
    // the store, just dispatching a synchronous thunk to get access to
    // `context`. Maybe there's an even better way?
    const resolveRoute = dispatch(getResolveRoute());
    return { resolve: resolveRoute };
  }, [dispatch]);

  return (
    <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>
  );
}

RoutesProvider.propTypes = {
  children: PropTypes.node,
};

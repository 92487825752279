/* eslint-disable react/no-unused-prop-types */
import React, { useMemo, useRef } from 'react';

import config from 'config';

import OneTrustSnippet from '../OneTrustSnippet';
import SegmentSnippet from '../SegmentSnippet';

type OneTrustConsentLevels = 'C0001' | 'C0002' | 'C0003' | 'C0004';

type SetupSegmentWithActiveGroupsProps = {
  activeGroups?: OneTrustConsentLevels[];
};

function SetupSegmentWithActiveGroups({
  activeGroups,
}: SetupSegmentWithActiveGroupsProps) {
  const hasEverConsented = useRef(false);
  const segmentMapping = config.get('public.oneTrust.segmentMapping') as Record<
    OneTrustConsentLevels,
    string[]
  >;

  const segmentCategories = useMemo(() => {
    let activeCategories: string[] = [];
    if (!activeGroups || !segmentMapping) {
      return activeCategories;
    }

    activeCategories = activeCategories
      .concat(...activeGroups.map((activeGroup) => segmentMapping[activeGroup]))
      .filter(Boolean);

    if (activeCategories.length) {
      hasEverConsented.current = true;
    }

    return activeCategories;
  }, [activeGroups, segmentMapping]);

  // If the user has ever consented to any OneTrust consent level, we'll render
  // the Segment snippet.
  // In the scenario where consent for Segment was given and is taken away,
  // `SegmentSnippet.Setup` needs to continue to be rendered because it
  // will reload the page when the active integrations change.
  if (hasEverConsented.current) {
    return <SegmentSnippet.Setup activeCategories={segmentCategories} />;
  }

  return null;
}

/**
 * This component will handle setting up Segment based on OneTrust consent levels,
 * and will only render the Segment snippet if the user has ever consented to any
 * OneTrust consent level.
 * Usage of this assumes that `<OneTrustSnippet />` is rendered _before_ this component.
 */
function OneTrustSetupWithSegment() {
  return (
    <>
      <SegmentSnippet autoLoad={false} />
      <OneTrustSnippet.Setup>
        {({ activeGroups }: { activeGroups: OneTrustConsentLevels[] }) => (
          <SetupSegmentWithActiveGroups activeGroups={activeGroups} />
        )}
      </OneTrustSnippet.Setup>
    </>
  );
}

export default OneTrustSetupWithSegment;

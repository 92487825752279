import getCreateApp from './createApp';
import getCreatePage from './createPage';
import { getMergedArrayOption } from './utils';

export default function createRegistry() {
  const registered = [];

  const instance = function createPage(Component, options) {
    return instance.createPage(Component, options);
  };

  if (!process.browser) {
    const getCreateDocument = require('./createDocument').default;
    instance.createDocument = getCreateDocument(instance);
  }

  // Give access to the function that created this registry. Then if you get
  // access to this registry you can easily create a fresh one without importing
  // and depending on `@techstyle/next-server` directly.
  instance.createRegistry = createRegistry;

  instance.createApp = getCreateApp(instance);
  instance.createPage = getCreatePage(instance);
  instance.utils = { getMergedArrayOption };

  instance.register = (extension) => {
    // Allow extension to be a function that accepts the registry as the first
    // argument. This allows the extension to see or add others.
    if (typeof extension === 'function') {
      // Lazily initialize when extensions start being accessed.
    } else if (!extension) {
      throw new Error(
        `Did not receive an extension to register; received ${extension} instead.`
      );
    } else if (typeof extension.id !== 'string') {
      throw new Error(
        `Extensions must have a ID string, but the 'id' property was ${extension.id}`
      );
    }
    registered.push(extension);
  };

  instance.getRegistered = () => registered;

  instance.forEach = (callback, thisArg) => {
    for (let i = 0; i < registered.length; i++) {
      let extension = registered[i];
      if (typeof extension === 'function') {
        extension = extension(instance);
        registered[i] = extension;
      }
      callback.call(thisArg, extension, i, registered);
    }
  };

  instance.forEachAsync = async (callback, thisArg) => {
    for (let i = 0; i < registered.length; i++) {
      let extension = registered[i];
      if (typeof extension === 'function') {
        extension = extension(instance);
        registered[i] = extension;
      }
      await callback.call(thisArg, extension, i, registered);
    }
  };

  instance.forEachReverse = (callback, thisArg) => {
    for (let i = registered.length - 1; i >= 0; i--) {
      let extension = registered[i];
      if (typeof extension === 'function') {
        extension = extension(instance);
        registered[i] = extension;
      }
      callback.call(thisArg, extension, i, registered);
    }
  };

  if (!process.browser) {
    instance.server = {
      async init(server) {
        await instance.forEachAsync(async (extension) => {
          if (extension.server && extension.server.init) {
            await extension.server.init(server);
          }
        });
      },
      async handler({ handler, app, server }) {
        instance.forEachReverse((extension) => {
          if (extension.server && extension.server.handler) {
            const newHandler = extension.server.handler({
              handler,
              app,
              server,
            });
            if (newHandler != null) {
              handler = newHandler;
            }
          }
        });
        return handler;
      },
      async configure(server) {
        await instance.forEachAsync(async (extension) => {
          if (extension.server && extension.server.configure) {
            await extension.server.configure(server);
          }
        });
      },
      async configureInitialMiddleware(server) {
        await instance.forEachAsync(async (extension) => {
          if (extension.server && extension.server.configureInitialMiddleware) {
            await extension.server.configureInitialMiddleware(server);
          }
        });
      },
      async configureErrorMiddleware(server) {
        await instance.forEachAsync(async (extension) => {
          if (extension.server && extension.server.configureErrorMiddleware) {
            await extension.server.configureErrorMiddleware(server);
          }
        });
      },
    };

    instance.document = {
      enhance(Document) {
        instance.forEachReverse((extension) => {
          if (extension.document && extension.document.enhance) {
            Document = extension.document.enhance(Document);
          }
        });
        return Document;
      },
      enhanceInitialProps(getInitialProps) {
        instance.forEachReverse((extension) => {
          if (
            extension.document &&
            extension.document.getInitialProps &&
            typeof extension.document.getInitialProps.enhance === 'function'
          ) {
            getInitialProps =
              extension.document.getInitialProps.enhance(getInitialProps);
          }
        });
        return getInitialProps;
      },
      async getInitialProps(ctx) {
        const promises = [];
        instance.forEach((extension) => {
          if (
            extension.document &&
            typeof extension.document.getInitialProps === 'function'
          ) {
            const promise = extension.document.getInitialProps(ctx);
            promises.push(promise);
          }
        });
        const initialProps = await Promise.all(promises);
        return Object.assign({}, ...initialProps);
      },
    };
  }

  instance.app = {
    enhance(App) {
      instance.forEachReverse((extension) => {
        if (extension.app && extension.app.enhance) {
          App = extension.app.enhance(App);
        }
      });
      return App;
    },
    enhanceInitialProps(getInitialProps) {
      instance.forEachReverse((extension) => {
        if (
          extension.app &&
          extension.app.getInitialProps &&
          typeof extension.app.getInitialProps.enhance === 'function'
        ) {
          getInitialProps =
            extension.app.getInitialProps.enhance(getInitialProps);
        }
      });
      return getInitialProps;
    },
    async getInitialProps({ Component, ctx }) {
      const promises = [];
      instance.forEach((extension) => {
        if (
          extension.app &&
          typeof extension.app.getInitialProps === 'function'
        ) {
          const promise = extension.app.getInitialProps({
            Component,
            ctx,
          });
          promises.push(promise);
        }
      });
      const initialProps = await Promise.all(promises);
      return Object.assign({}, ...initialProps);
    },
    render(props, children) {
      instance.forEachReverse((extension) => {
        if (extension.app && extension.app.render) {
          children = extension.app.render(props, children);
        }
      });
      return children;
    },
  };

  instance.page = (options) => ({
    enhance(Page) {
      instance.forEachReverse((extension) => {
        const page =
          typeof extension.page === 'function'
            ? extension.page(options)
            : extension.page;
        if (page && page.enhance) {
          Page = page.enhance(Page);
        }
      });
      return Page;
    },
    enhanceInitialProps(getInitialProps) {
      instance.forEachReverse((extension) => {
        const page =
          typeof extension.page === 'function'
            ? extension.page(options)
            : extension.page;
        if (
          page &&
          page.getInitialProps &&
          typeof page.getInitialProps.enhance === 'function'
        ) {
          getInitialProps = page.getInitialProps.enhance(getInitialProps);
        }
      });
      return getInitialProps;
    },
    async getInitialProps(ctx) {
      const promises = [];
      instance.forEach((extension) => {
        const page =
          typeof extension.page === 'function'
            ? extension.page(options)
            : extension.page;
        if (page && typeof page.getInitialProps === 'function') {
          const promise = page.getInitialProps(ctx);
          promises.push(promise);
        }
      });
      const initialProps = await Promise.all(promises);
      return Object.assign({}, ...initialProps);
    },
    render(props, children) {
      instance.forEachReverse((extension) => {
        const page =
          typeof extension.page === 'function'
            ? extension.page(options)
            : extension.page;
        if (page && page.render) {
          children = page.render(props, children);
        }
      });
      return children;
    },
  });

  return instance;
}

/**
 * A custom `fetch` implementation that uses `node-fetch` as a base.
 * It adopts several features and conventions from
 * [ky](https://github.com/sindresorhus/ky) and the custom Savage X client, with
 * additional enhancements.
 *
 * This module is written in a higher-order function style, similar to Higher
 * Order Components (HOCs). Individual features are added with separate
 * functions, and the final `fetch` function is the result of composing them.
 * This is merely to make the code more testable and break it into bite-size
 * pieces: instead of one giant function, the additional features can be
 * debugged and modified individually.
 *
 * Features include:
 *
 * - Timeouts.
 * - Debug logging.
 * - Retries with configurable, exponential backoff using
 *   [node-retry](https://github.com/tim-kos/node-retry).
 * - Promise rejections on HTTP errors.
 * - Convenience options like `prefixUrl`, `searchParams`, and `json`.
 * - Hooks for performing additional logic or modification before the request is
 *   sent or the response is returned.
 */
import config from 'config';
import './nodeFetchPolyfill';
import { nanoid } from 'nanoid/non-secure';
import retry from 'retry';

import logger from './logger';
import { toPlainObject, getRetryConfig, getBaseURI } from './utils';

const debug = logger.extend('fetch');
const endsWithSlash = /\/$/;
const jsonContentType = /^application\/json($|;|\+)/;

export class HTTPError extends Error {
  constructor(response) {
    super(`${response.status} ${response.statusText}`.trim());
    this.name = 'HTTPError';
    this.response = response;
  }
}

export class TimeoutError extends Error {
  constructor(message = 'Request timed out') {
    super(message);
    this.name = 'TimeoutError';
  }
}

function rejectWithTimeout(timeout, { log = debug } = {}) {
  let timeoutId;
  const promise = new Promise((resolve, reject) => {
    timeoutId = setTimeout(() => {
      log('Fetch timeout after %sms.', timeout);
      reject(new TimeoutError());
    }, timeout);
  });
  // Give access to the `timeoutId` so it can be cleared if necessary.
  promise.timeoutId = timeoutId;
  return promise;
}

function resolveAfterTimeout(timeout) {
  let timeoutId;
  const promise = new Promise((resolve, reject) => {
    timeoutId = setTimeout(resolve, timeout);
  });
  // Give access to the `timeoutId` so it can be cleared if necessary.
  promise.timeoutId = timeoutId;
  return promise;
}

/**
 * Create a version of `fetch` that rejects with a `TimeoutError` after the
 * number of milliseconds given in `timeout` has elapsed. Pass `false` to
 * disable.
 */
export function withTimeout(baseFetch, defaults = {}) {
  const { timeout: defaultTimeout = 30000 } = defaults;

  return function fetch(input, options = {}) {
    const { timeout = defaultTimeout } = options;
    const promise = baseFetch(input, options);
    if (timeout === false) {
      return promise;
    }
    const timeoutError = rejectWithTimeout(timeout, options);
    const { timeoutId } = timeoutError;
    const onResolve = (response) => {
      clearTimeout(timeoutId);
      return response;
    };
    const onReject = (err) => {
      clearTimeout(timeoutId);
      throw err;
    };
    const requestComplete = promise.then(onResolve, onReject);
    return Promise.race([timeoutError, requestComplete]);
  };
}

/**
 * Create a version of `fetch` that rejects with an `HTTPError` if `response.ok`
 * is false. The error object will have a `response` property. Pass the
 * `throwHttpErrors` option with a value of `false` to disable.
 */
export function withHttpErrors(baseFetch) {
  return function fetch(input, options = {}) {
    const { throwHttpErrors = true } = options;
    const promise = baseFetch(input, options);
    if (!throwHttpErrors) {
      return promise;
    }
    return promise.then((response) => {
      if (!response.ok) {
        throw new HTTPError(response);
      }
      return response;
    });
  };
}

export const defaultRetryMethods = new Set([
  'GET',
  'PUT',
  'HEAD',
  'DELETE',
  'OPTIONS',
  'TRACE',
]);

export const defaultRetryStatusCodes = new Set([
  408, 413, 429, 500, 502, 503, 504,
]);

/**
 * Return true if the given `error` could reasonably be the result of a bad
 * `fetch` (including via our custom `fetch`), or if it looks more like a
 * programmer error (via one of the standard JavaScript error types, like
 * `TypeError`). It's easier to do it this way rather than include a whitelist
 * of errors because some parts of `node-fetch` throw plain `Error` instances
 * instead of a custom type.
 */
export function isFetchError(error) {
  return !/^(Range|Reference|Syntax|Type|URI)Error$/.test(error.name);
}

export function defaultRetryPolicy(err, response, input, options) {
  if (!defaultRetryMethods.has(options.method)) {
    return false;
  }
  if (err) {
    if (isFetchError(err)) {
      return 'default';
    }
  } else if (defaultRetryStatusCodes.has(response.status)) {
    return 'default';
  }
  return false;
}

/**
 * Simulate request failures.
 */
export function withFailureSimulation(baseFetch, defaults = {}) {
  return function fetch(input, options = {}) {
    const {
      failureRate = defaults.failureRate,
      failureType = defaults.failureType,
      log = debug,
    } = options;

    if (failureRate) {
      if (Math.random() < failureRate) {
        log(
          `Simulating failure of type “${failureType}”, check Admin Tools to change this.`
        );
        switch (failureType) {
          case 'fetch':
            throw new TypeError('Failed to fetch');
          case 'timeout':
            return new Promise(() => {
              // Never resolve!
            });
          default: {
            const Response = global.Response;
            if (Response) {
              return Promise.resolve(
                new Response('{}', { status: failureType })
              );
            } else {
              log(
                'Response type not found; failure simulation will be skipped.'
              );
            }
          }
        }
      }
    }
    return baseFetch(input, options);
  };
}

/**
 * Create a version of `fetch` with retry support. The `retry` option will be
 * passed to [getRetryConfig](./utils.js) along with the rejection error (if
 * any), response (if any), and fetch arguments.
 */
export function withRetry(baseFetch, defaults = {}) {
  const { retry: defaultRetry = defaultRetryPolicy } = defaults;

  return function fetch(input, options = {}) {
    const {
      retry: retryOption = defaultRetry,
      onRetry = defaults.onRetry,
      registerTimeout = defaults.registerTimeout,
      unregisterTimeout = defaults.unregisterTimeout,
      log = debug,
    } = options;

    const attemptFetch = (attemptNumber) => {
      return baseFetch(input, options).then(
        (response) => considerRetry(attemptNumber, null, response),
        (err) => considerRetry(attemptNumber, err, null)
      );
    };

    const considerRetry = async (attemptNumber, err, response) => {
      const retryConfig = await getRetryConfig(
        retryOption,
        err,
        response,
        input,
        options
      );

      // check if this is an excluded route for retries
      let isExcludedRoute = false;
      try {
        const url = new URL(response?.url);
        isExcludedRoute = retryConfig?.excludedRoutes?.includes(url?.pathname);
      } catch (err) {
        // ignore if this errors and allow normal logic to proceed
        log('Error checking excluded routes: %s', err);
      }

      if (retryConfig && !isExcludedRoute) {
        const timeouts = retry.timeouts(retryConfig);
        if (timeouts.length > attemptNumber) {
          const timeout = timeouts[attemptNumber];
          return scheduleRetry(attemptNumber + 1, timeout);
        } else {
          log(
            'Reached fetch retry limit after %s.',
            attemptNumber
              ? `${attemptNumber} ${attemptNumber === 1 ? 'retry' : 'retries'}`
              : 'first attempt'
          );
        }
      }
      if (err) {
        throw err;
      }
      return response;
    };

    const scheduleRetry = (attemptNumber, timeout) => {
      log('Scheduling fetch retry #%s in %sms.', attemptNumber, timeout);
      const retryDelay = resolveAfterTimeout(timeout);
      const { timeoutId } = retryDelay;
      if (registerTimeout) {
        registerTimeout(timeoutId);
      }
      if (onRetry) {
        onRetry(attemptNumber, timeout);
      }
      return retryDelay.then(() => {
        if (unregisterTimeout) {
          unregisterTimeout(timeoutId);
        }
        return attemptFetch(attemptNumber);
      });
    };

    return attemptFetch(0);
  };
}

/**
 * Create a version of fetch with support for a `prefixUrl` option, so that
 * callers only have to supply a path relative to that prefix. The prefix will
 * automatically have a `/` appended if there is not one already.
 */
export function withPrefixUrl(baseFetch, defaults = {}) {
  return function fetch(input, options = {}) {
    let { prefixUrl = defaults.prefixUrl } = options;
    if (typeof prefixUrl === 'string') {
      if (!endsWithSlash.test(prefixUrl)) {
        prefixUrl += '/';
      }
      input = prefixUrl + input;
      options = {
        ...options,
        prefixUrl,
      };
    }
    return baseFetch(input, options);
  };
}

/**
 * Create a version of `fetch` with a `hooks` API similar to
 * [ky](https://www.npmjs.com/package/ky). Some differences:
 *
 * - `beforeRequest` receives both the `input` and `options` as arguments, and
 *   can optionally return entirely new `fetch` arguments to use instead.
 * - `afterReponse` also receives the `input` and `options` after the
 *   `response` argument, so it can make decisions based on the request that
 *   was sent.
 */
export function withHooks(baseFetch, defaults = {}) {
  const { hooks: defaultHooks = {} } = defaults;

  return function fetch(input, { hooks = {}, ...options } = {}) {
    // Merge and clean up.
    hooks = {
      beforeRequest: []
        .concat(defaultHooks.beforeRequest, hooks.beforeRequest)
        .filter(Boolean),
      afterResponse: []
        .concat(defaultHooks.afterResponse, hooks.afterResponse)
        .filter(Boolean),
    };

    let fetchArgs = [input, options];
    const { log = debug } = options;

    hooks.beforeRequest.forEach((beforeRequest) => {
      const newFetchArgs = beforeRequest(...fetchArgs);
      if (newFetchArgs != null) {
        log('beforeRequest hook returned new fetch params.');
        fetchArgs = newFetchArgs;
      }
    });

    let promise = baseFetch(...fetchArgs);

    hooks.afterResponse.forEach((afterResponse) => {
      promise = promise.then(async (response) => {
        const newResponse = await afterResponse(response, ...fetchArgs);
        if (newResponse != null) {
          log('afterResponse hook returned a new response.');
          return newResponse;
        }
        return response;
      });
    });

    return promise;
  };
}

export function isJsonContentType(response) {
  const contentType = response.headers.get('Content-Type');
  return jsonContentType.test(contentType);
}

/**
 * Create a version of `fetch` with automatic debug logging. By default, a
 * summary line of outgoing requests and incoming responses is sent to this
 * module's `debug` logger. If the `public.debug.verbose` config option is
 * enabled, then additional information like headers and bodies are logged on
 * the server as well.
 */
export function withLogging(baseFetch) {
  // In the browser we have the handy Network tab. On the server we need a way
  // to see more details about the requests going through.
  const verbose = config.has('public.debug.verbose')
    ? config.get('public.debug.verbose')
    : false;

  return function fetch(input, options) {
    const { log = debug } = options;
    log(`> %c%s%c %s`, 'font-weight: bold', options.method, '', input);
    if (!process.browser && verbose) {
      const headers = options.headers ? toPlainObject(options.headers) : {};
      if (Object.keys(headers).length) {
        log('> Headers:');
        log('%O', headers);
      }
      if (typeof options.body !== 'undefined') {
        log('> Body:');
        log('%O', options.body);
      }
    }

    return baseFetch(input, options).then(
      async (response) => {
        log(
          `< %c%s%c %s`,
          `font-weight: bold; color: ${response.ok ? 'limegreen' : 'red'}`,
          response.status,
          '',
          input
        );
        if (!process.browser && verbose) {
          const headers = toPlainObject(response.headers);
          if (Object.keys(headers).length) {
            log('< Headers:');
            log('%O', headers);
          }
          const responseText = await response.text();
          const clonedResponse = new Response(responseText, {
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
          });
          if (isJsonContentType(response)) {
            try {
              const body = JSON.parse(responseText);
              log('< Body:');
              log('%O', body);
            } catch (decodeError) {
              // That's OK, it was just for logging purposes.
              log('%c!%c %s', 'color: yellow', '', decodeError);
            }
          } else {
            log('< Body:');
            log('%s', responseText);
          }
          return clonedResponse;
        }
        return response;
      },
      (err) => {
        log('%c!%c %s', 'color: red', '', err);
        throw err;
      }
    );
  };
}

/**
 * Create a version of `fetch` with automatic JSON body serialization and
 * headers.
 */
export function withJson(baseFetch) {
  return function fetch(input, options = {}) {
    const { json } = options;
    if (typeof json !== 'undefined') {
      const body = JSON.stringify(json);
      const headers = new Headers(options.headers);
      headers.set('Content-Type', 'application/json');
      options = {
        ...options,
        body,
        headers,
      };
    }
    return baseFetch(input, options);
  };
}

function objectToSearchParams(obj) {
  const searchParams = new URLSearchParams();
  const add = (name, value) => {
    if (typeof value === 'undefined') {
      return;
    }
    if (value == null) {
      value = '';
    }
    searchParams.append(name, value.toString());
  };
  Object.keys(obj).forEach((name) => {
    const value = obj[name];
    if (Array.isArray(value)) {
      value.forEach((item) => add(name, item));
    } else {
      add(name, value);
    }
  });
  return searchParams;
}

/**
 * Create a version of `fetch` with support for a `searchParams` option that
 * updates the `input` URL. It can be a string, a plain object, or a
 * `URLSearchParams` instance.
 */
export function withSearchParams(baseFetch) {
  return function fetch(input, options = {}) {
    let { searchParams } = options;

    if (typeof searchParams === 'string') {
      searchParams = new URLSearchParams(searchParams);
    } else if (
      Object.prototype.toString.call(searchParams) === '[object Object]'
    ) {
      searchParams = objectToSearchParams(searchParams);
    } else if (searchParams == null) {
      searchParams = new URLSearchParams();
    } else {
      searchParams = new URLSearchParams(searchParams.toString());
    }

    const url = new URL(input, getBaseURI());

    const queryString = searchParams.toString();
    if (queryString) {
      url.search = queryString;
    }
    input = url.toString();
    options = { ...options, searchParams };
    return baseFetch(input, options);
  };
}

let defaultAgent;

/**
 * Create a shared `https.Agent` instance
 */
function getDefaultAgent() {
  if (
    // Technically, this `process.browser` check shouldn't be necessary. We
    // already check it below when setting the `agent` option, and webpack's
    // Terser plugin correctly recognizes that this function is unreachable and
    // entirely removes it. However, webpack has already determined the
    // dependency graph at this point and fails to prune the `https` module and
    // its dependencies. See: https://github.com/webpack/webpack/issues/10281
    !process.browser &&
    !defaultAgent
  ) {
    let customAgentOptions = {};

    // If `server.bentoApi.rejectUnauthorized` is false, add
    // `rejectUnauthorized: false` to shared `https.Agent` instance.
    // Useful for API hosts (in QA, for example) that have invalid or self-signed
    // SSL certificates.
    if (config.get('server.bentoApi.rejectUnauthorized') === false) {
      // Require strict `false` value to disable certificate validation.
      customAgentOptions = { rejectUnauthorized: false };
    }

    if (config.has('server.fetchSettings.agent')) {
      const fetchAgentConfig = config.get('server.fetchSettings.agent');

      if (fetchAgentConfig.keepAlive) {
        customAgentOptions = { ...customAgentOptions, ...fetchAgentConfig };
      }
    }

    if (Object.keys(customAgentOptions).length) {
      const https = require('https');
      defaultAgent = new https.Agent(customAgentOptions);
    }
  }
  return defaultAgent;
}

/**
 * Create a version of fetch that has some default options from `defaults`.
 */
export function withDefaults(baseFetch, defaults = {}) {
  return function fetch(input, options = {}) {
    const correlationId =
      options.correlationId || defaults.correlationId || nanoid(16);

    let log = options.log || defaults.log;
    if (!log) {
      log = debug.extend(correlationId);
    }
    const method = (options.method || defaults.method || 'GET').toUpperCase();
    const headers = new Headers();

    if (defaults.headers) {
      const defaultHeaders = new Headers(defaults.headers);
      defaultHeaders.forEach((value, name) => headers.set(name, value));
    }
    if (options.headers) {
      const moreHeaders = new Headers(options.headers);
      moreHeaders.forEach((value, name) => headers.set(name, value));
    }

    const agent = process.browser
      ? undefined
      : options.agent || defaults.agent || getDefaultAgent();

    options = {
      credentials: 'same-origin',
      ...defaults,
      ...options,
      correlationId,
      log,
      method,
      headers,
      agent,
    };

    const promise = baseFetch(input, options);

    return promise;
  };
}

/**
 * Create a custom `fetch` with default options and enhanced features.
 */
export function createFetch(defaults = {}) {
  let fetch = global.fetch;
  fetch = withFailureSimulation(fetch);
  fetch = withLogging(fetch);
  if (!process.browser) {
    const { withHar } = require('node-fetch-har');
    fetch = withHar(fetch, { har: defaults.har });
  }
  fetch = withHooks(fetch);
  fetch = withJson(fetch);
  fetch = withTimeout(fetch);
  fetch = withRetry(fetch);
  fetch = withHttpErrors(fetch);
  fetch = withSearchParams(fetch);
  fetch = withPrefixUrl(fetch);
  fetch = withDefaults(fetch, defaults);
  return fetch;
}

import React from 'react';

import config from 'config';

import BrowserSupportProvider from './BrowserSupportProvider';
import logger from './logger';

const debug = logger.extend('browserSupportExtension');

export default function browserSupportExtension() {
  return {
    id: 'browserSupport',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
          configure(server) {
            const dismissAlertUrl = config.get(
              'public.browserSupport.dismissAlertUrl'
            );

            // Register the POST route that will dismiss the browser support
            // alert by setting a cookie.
            server.post(dismissAlertUrl, (req, res) => {
              req.universalCookies.set('showBrowserAlert', '0', {
                secure: true,
              });
              res.redirect('back');
            });
          },
        },
    app: {
      getInitialProps: process.browser
        ? () => {
            // When rendering new pages in the browser, use the initial detected
            // values that were determined by the server (below).
            const { browserName, isBrowserSupported, showBrowserAlert } =
              window.__NEXT_DATA__.props.initialProps;
            return { browserName, isBrowserSupported, showBrowserAlert };
          }
        : async ({ Component, ctx }) => {
            const userAgent = ctx.req.headers['user-agent'];
            let isBrowserSupported;
            let browserName;

            // Only run if `userAgent` is actually populated.
            if (userAgent) {
              const { matchesUA } = require('browserslist-useragent');
              const parser = require('ua-parser-js');

              isBrowserSupported = matchesUA(userAgent, {
                browsers: config.get('server.browserSupport.browserslistQuery'),
                allowHigherVersions: true,
              });
              if (isBrowserSupported) {
                debug('Browser is supported: %s', userAgent);
              } else {
                debug('Browser is NOT supported: %s', userAgent);
              }

              browserName = parser(userAgent).browser.name || '';
              switch (browserName) {
                case 'IE':
                case 'IEMobile':
                  // Use a value more suitable for showing to the user.
                  browserName = 'Internet Explorer';
                  break;
              }
            } else {
              // If no `User-Agent` header was supplied at all, just assume the
              // browser is supported. It would be annoying to show this alert
              // to people who deliberately disable this header (for privacy
              // purposes, for example). We could support a third value here
              // like null or `UNKNOWN`, but this seems fine.
              debug('User-Agent unknown, assuming support.');
              isBrowserSupported = true;
              browserName = '';
            }

            const { universalCookies } = ctx.req;

            // Show the browser alert if we have a cookie that already
            // says to show the alert, otherwise, show the alert if
            // the browser is not supported.
            const showBrowserAlert =
              universalCookies.get('showBrowserAlert') === '0'
                ? false
                : !isBrowserSupported;

            if (showBrowserAlert) {
              ctx.req.universalCookies.set('showBrowserAlert', '1', {
                secure: true,
              });
            }

            return {
              browserName,
              isBrowserSupported,
            };
          },
      render(props, children) {
        const {
          // eslint-disable-next-line react/prop-types
          browserName,
          // eslint-disable-next-line react/prop-types
          isBrowserSupported,
        } = props;

        return (
          <BrowserSupportProvider
            browserName={browserName}
            isSupported={isBrowserSupported}
          >
            {children}
          </BrowserSupportProvider>
        );
      },
    },
  };
}

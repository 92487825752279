import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import withThemeProps from '../withThemeProps';

function getColor({ theme, ruleColor }) {
  if (theme && theme.colors) {
    ruleColor = theme.colors[ruleColor] || ruleColor;
  }
  return ruleColor || 'currentColor';
}

function getWidth({ ruleThickness }) {
  if (typeof ruleThickness === 'number') {
    return `${ruleThickness}px`;
  }
  return ruleThickness;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2em 0;
  text-align: center;
  ${(props) => props.separatorStyle};
`;

const Rule = styled.hr`
  margin: 0 1em;
  flex-basis: 2em;
  flex-grow: 1;
  flex-shrink: 0;
  border: 0;
  border-top: ${getWidth} solid ${getColor};

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  ${(props) => props.ruleStyle};
`;

/**
 * A horizontal separator with optional text.
 *
 * - Theme section: `separator`
 * - Default variant: `default`
 */
export function Separator({
  children,
  color,
  ruleStyle,
  separatorStyle,
  thickness,
  ...rest
}) {
  const childCount = React.Children.count(children);
  const hasText = childCount > 0;
  const rule = (
    <Rule
      data-rule=""
      ruleColor={color}
      ruleStyle={ruleStyle}
      ruleThickness={thickness}
    />
  );
  return (
    <Wrapper separatorStyle={separatorStyle} {...rest}>
      {rule}
      {hasText ? children : null}
      {hasText ? rule : null}
    </Wrapper>
  );
}

Separator.propTypes = {
  /**
   * Content to render between the two horizontal rules, with one on either
   * side. If not present, there will be just one horizontal rule spanning the
   * full width.
   */
  children: PropTypes.node,
  /**
   * The color of the horizontal rule elements. If not provided, it defaults to
   * the current foreground (text) color. The color is first looked up in
   * `theme.colors`, and otherwise may be any valid CSS color value.
   */
  color: PropTypes.string,
  /**
   * Styles to pass to the horizontal rule element(s). It will be included along
   * with the base styles and can be anything styled-components supports in its
   * tagged template literals, including strings, objects, and functions.
   */
  ruleStyle: PropTypes.any,
  /**
   * Styles to pass to the wrapper element. Use this to adjust the vertical
   * margin added by the separator. This will be included along with the base
   * styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  separatorStyle: PropTypes.any,
  /**
   * The thickness of the horizontal rule lines. It can be a number (in pixels)
   * or any valid CSS `border-width` value.
   */
  thickness: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Separator.defaultProps = {
  color: 'currentColor',
  thickness: 1,
};

export default withThemeProps(Separator, {
  section: 'separator',
  defaultVariant: 'default',
});

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.fill || props.color || 'black'};
  vertical-align: top;
`;

const JFBrandsLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      // Let `aria-label` take control if present. Consumers are also free to
      // override with their own `aria-labelledby`.
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 175 46"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* NOTE: this is read by screen readers so it needs to be in a human-readable format: */}
      <title id={titleId}>JF Brands</title>
      <path d="M1.6696 45.0844V20.9676C1.6696 18.647 2.00352 16.3818 2.67135 14.1718C3.33919 11.9617 4.36878 10.028 5.76011 8.37045C7.15144 6.65769 8.90451 5.33167 11.0193 4.39241C13.1898 3.39791 15.7499 2.98353 18.6995 3.14928V1.49176C16.1394 1.43651 13.8855 1.71277 11.9376 2.32052C10.0454 2.92828 8.40363 3.75704 7.0123 4.8068C5.62097 5.85656 4.50791 7.09969 3.67311 8.5362C2.83831 9.91747 2.22613 11.4092 1.83656 13.0115H1.6696V2.48628H0V45.0844H1.6696Z" />
      <path d="M24.1081 24.1168H60.0044C60.1157 21.1886 59.8096 18.3708 59.0861 15.6635C58.4182 12.9562 57.3052 10.5528 55.7469 8.45333C54.2443 6.35381 52.2686 4.66867 49.8198 3.39791C47.4267 2.12714 44.5606 1.49176 41.2214 1.49176C38.8283 1.49176 36.4909 1.98902 34.2091 2.98353C31.9273 3.97804 29.9238 5.44218 28.1986 7.37594C26.4733 9.25446 25.082 11.575 24.0246 14.3375C22.9672 17.1 22.4385 20.2493 22.4385 23.7853C22.4385 26.9346 22.8002 29.8905 23.5237 32.6531C24.2472 35.3603 25.3602 37.7361 26.8629 39.7804C28.3655 41.7694 30.2856 43.3164 32.623 44.4214C35.0161 45.5264 37.8822 46.0513 41.2214 45.9961C46.1189 45.9961 50.2372 44.6424 53.5764 41.9351C56.9156 39.1726 58.8913 35.3051 59.5035 30.3325H57.8339C57.1104 35.0288 55.246 38.5649 52.2407 40.9406C49.2911 43.2612 45.5624 44.4214 41.0545 44.4214C37.9935 44.4214 35.4057 43.8965 33.2908 42.8468C31.176 41.797 29.4508 40.3605 28.1151 38.5372C26.7794 36.714 25.7776 34.5868 25.1098 32.1558C24.4976 29.6695 24.1637 26.9899 24.1081 24.1168ZM58.3348 22.4593H24.1081C24.275 19.4758 24.8315 16.7962 25.7776 14.4204C26.7238 12.0446 27.9481 10.028 29.4508 8.37045C31.009 6.65769 32.79 5.3593 34.7935 4.47529C36.8526 3.59128 39.0509 3.14928 41.3884 3.14928C44.171 3.14928 46.6198 3.67416 48.7346 4.72392C50.8494 5.71843 52.6025 7.09969 53.9938 8.86771C55.4408 10.6357 56.526 12.7076 57.2495 15.0834C57.973 17.4039 58.3348 19.8626 58.3348 22.4593Z" />
      <path d="M76.2432 0L91.3172 39.5278L106.964 0H113.324V45.0844H108.999V6.12491H108.872L93.3525 45.0844H89.2819L74.3351 6.12491H74.2078V45.0844H69.8828V0H76.2432Z" />
      <path d="M125.109 0V45.0844H129.434V0H125.109Z" />
      <path d="M137.046 0L152.438 21.8476L136.156 45.0844H140.99L154.982 25.1942L168.785 45.0844H174L157.59 21.9739L173.11 0H168.276L154.982 18.8799L142.135 0H137.046Z" />
    </Svg>
  );
});

JFBrandsLogo.displayName = 'JFBrandsLogo';

JFBrandsLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default JFBrandsLogo;

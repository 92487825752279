import React from 'react';

import PropTypes from 'prop-types';

import { AssetContainerType } from '../constants';
import { Component as HTMLAsset } from '../HTMLAsset';
import { Component as ImageAsset } from '../ImageAsset';
import useAsset from '../useAsset';

const assetComponents = {
  [AssetContainerType.HTML]: HTMLAsset,
  [AssetContainerType.IMAGE]: ImageAsset,
  [AssetContainerType.SLIDER]: ImageAsset,
};

function createEmptyImageAssetContainer(name) {
  const container = {
    name,
    type: AssetContainerType.IMAGE,
    assets: [],
  };
  const asset = {
    container,
    id: null,
    label: '(missing)',
    options: {
      customVars: {},
    },
  };
  container.assets.push(asset);
  return container;
}

/**
 * A component that renders a single asset container. You must supply the
 * asset's key `name`. The `useAsset` hook will be called to load the data,
 * assets, and error values.
 */
export default function AssetContainer({
  as: AssetComponent,
  children,
  className,
  filter,
  forceStoreGroup,
  multi = false,
  name,
  ...rest
}) {
  if (!name) {
    throw new Error(
      `AssetContainer requires an asset name but was given ${JSON.stringify(
        name
      )}.`
    );
  }

  const assetContainer = useAsset(name, {
    multi,
    filter,
    forceStoreGroup,
  });

  let { data, error, networkStatus } = assetContainer;
  const hasAsset = data != null && data.assets.length > 0;
  // If this is a background image asset and there's foreground content to
  // render, make sure we always render it even if there is no asset.
  if (
    !hasAsset &&
    rest.background &&
    children != null &&
    typeof children !== 'function'
  ) {
    data = createEmptyImageAssetContainer(name);
  }
  const assets = data ? data.assets : [];
  const type = data && data.type;

  if (typeof children === 'function') {
    const meta = { className, name, multi };

    const status = {
      data: multi ? assets : assets[0],
      type,
      error,
      loading: networkStatus.isLoading,
      networkStatus,
      meta,
    };

    return children(status);
  }

  if (!data) {
    return null;
  }

  if (!AssetComponent) {
    AssetComponent = assetComponents[data.type];
    if (!AssetComponent) {
      throw new Error(`Unknown container type: '${data.type}'`);
    }
  }

  if (multi) {
    return assets.map((asset) => (
      <AssetComponent
        asset={asset}
        className={className}
        key={`${asset.id}-${asset.imageId}`}
        {...rest}
      >
        {children}
      </AssetComponent>
    ));
  }

  const asset = assets[0];
  if (!asset) {
    return null;
  }

  return (
    <AssetComponent asset={asset} className={className} {...rest}>
      {children}
    </AssetComponent>
  );
}

AssetContainer.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  filter: PropTypes.func,
  forceStoreGroup: PropTypes.number,
  multi: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

AssetContainer.HTMLAsset = HTMLAsset;
AssetContainer.ImageAsset = ImageAsset;

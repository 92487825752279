import round from 'lodash/round';
import { hideVisually } from 'polished';
import { css, keyframes } from 'styled-components';

import CloseButton from '../../../shared/components/CloseButton';
import { mobile } from '../../../techstyle-shared/react-components';
import { checkbox, radioButton, textField } from '../inputs';

import button from './buttons';
import { colorTokens, palette } from './colors';
import { fontStacks, fonts } from './fonts';
import { defaultVideoRatio, fontSizes, sizes } from './sizes';
import { themeTokens } from './themeTokens';
import { typography } from './typography';
import { zIndexes } from './zIndexes';

const globalStyle = css`
  html {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fontStacks.baseFamily};
    line-height: 1.2;
    color: ${({ theme: { colors } }) => colors.textDefault};
  }

  h1 {
    font-family: ${({ theme }) => theme.fontStacks.baseFamily};
    color: ${({ theme: { colors } }) => colors.palette.black};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  /* there is a pixel tracking img tag inserted near the closing body tag that only appears on prod. This code should hide it. */
  body > img {
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0px;
    bottom: 0px;
    margin: -1px;
    z-index: -999;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const colorValues = {
  // TODO: this color is used in `flyt-components`. Future work to remove this dependency required
  deprecated: {
    lightGray2: '#eaeaea',
    lightGray: '#BFBFBF',
  },

  ...colorTokens,
  palette,

  // TODO: this color is used in `react-components`. Future work to remove this dependency required
  darkenGray: '#6C7164',
};

const alerts = {
  variants: {},
};

const animations = {
  keyframes: {
    spin: keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }`,
  },
};
animations.spin = css`
  animation: ${animations.keyframes.spin} 1s infinite
    cubic-bezier(0.785, 0.135, 0.15, 0.86);
`;

const spacing = {
  base: 0.75,
  defaultSize: 'm',
  sizes: {
    xs: 0.5,
    s: 1,
    m: 1.5,
    l: 2,
    xl: 3,
    pixels: {
      xxxs: 4,
      xxs: 8,
      xs: 12,
      s: 16,
      m: 24,
      l: 32,
      xl: 36,
      xxl: 40,
      xxxl: 48,
    },
  },
};

const space = (size = null, relative = false) => {
  if (size === null) {
    size = spacing.defaultSize;
  }

  if (size in spacing.sizes) {
    size = spacing.sizes[size];
  }

  const units = relative ? 'em' : 'rem';

  return `${size * spacing.base}${units}`;
};

export default {
  alerts,
  colors: colorValues,
  ...themeTokens,
  fonts,
  // NOTE: This is optional, it gets rid of the browser warning about preloading a font without using it
  preloadFonts: [],
  typography,
  fontStacks,
  globalStyle,
  lists: {
    bare: css`
      padding: 0;
      margin: 0;
      list-style: none;
    `,
    heart: css`
      padding: 0;
      list-style: url("data:image/svg+xml,%3Csvg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.52892e-08 2.40029C-5.46559e-05 2.08136 0.0634614 1.76561 0.186836 1.47151C0.310211 1.17741 0.490969 0.910851 0.718547 0.687411C0.946124 0.463971 1.21596 0.288134 1.51227 0.170176C1.80859 0.0522179 2.12544 -0.00549457 2.44432 0.000411567C2.82162 -0.00159211 3.19503 0.0766163 3.53981 0.229853C3.8846 0.38309 4.19288 0.607856 4.44422 0.889255C4.69556 0.607856 5.00383 0.38309 5.34862 0.229853C5.6934 0.0766163 6.06682 -0.00159211 6.44411 0.000411567C6.76299 -0.00549457 7.07984 0.0522179 7.37616 0.170176C7.67248 0.288134 7.94231 0.463971 8.16988 0.687411C8.39746 0.910851 8.57822 1.17741 8.7016 1.47151C8.82497 1.76561 8.88849 2.08136 8.88843 2.40029C8.88843 4.78061 6.05347 6.57785 4.44422 8C2.83852 6.56585 3.52892e-08 4.78239 3.52892e-08 2.40029Z' fill='%238031A7'/%3E%3C/svg%3E%0A");
    `,
  },
  spacing,
  sizes,
  fontSizes,
  space,
  animations,
  zIndexes,
  site: {
    maxWidth: 1330,
  },
  styles: {
    knockoutText: css`
      background: ${colorValues.horizontalGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `,
    visuallyHidden: css`
      ${hideVisually()};
      /*
       * Prevent visual styles defined above the visually hidden text from changing
       * how the text is read, e.g., making "Fit" be pronounced as "F-I-T"
       */
      text-transform: none;
    `,
  },
  drawer: {
    variants: {
      default: {
        dialogStyle: css`
          padding: 40px 20px;
          width: 87.5vw;
          height: auto;
          max-height: calc(100vh - 88px);
          margin: 44px 0 44px auto;
          background-color: ${({ theme }) => theme.colors.palette.white};
          align-self: flex-start;

          ${CloseButton} {
            font-size: 13px;
            position: absolute;
            top: 5px;
            right: 5px;
            width: 44px;
            height: 44px;
            padding: 0;
          }
        `,
        position: 'left',
      },
      navigation: {
        dialogStyle: css`
          flex: 1 1 auto;
          max-width: 87.5%;
          background-color: ${({ theme }) => theme.colors.palette.white};
        `,
      },
      fullScreen: {
        dialogStyle: css`
          left: 0;
          right: 0;
          width: 100%;
          background-color: ${({ theme }) => theme.colors.palette.white};
        `,
      },
    },
  },
  dropdown: css`
    font-weight: 500;
  `,
  modal: {
    variants: {
      default: {
        dialogStyle: css`
          padding: 40px;
          background-color: white;
          ${CloseButton} {
            position: absolute;
            top: 0;
            right: 0;
            padding: 12px;
          }
        `,
      },
      unpadded: {
        dialogStyle: css`
          padding: 0;
          background-color: white;
        `,
      },
    },
  },
  button,
  link: {
    variants: {
      emphasized: css`
        color: ${({ theme }) => theme.colors.linkText};
        font-weight: 600;
        cursor: pointer;
      `,
    },
  },
  actionResultLabel: css`
    color: ${({ theme }) => theme.colors.palette.lighterBlack};
    font-weight: 500;
  `,
  formActionsLayout: {
    default: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
    `,
    mobile: '',
  },
  carousel: {
    variants: {
      mobileNav: {
        adaptiveHeight: false,
        cellAlign: 'left',
        pageDots: false,
        prevNextButtons: false,
        resize: true,
        wrapAround: true,
        cellStyle: css`
          max-width: 90%;
          width: 160px;
          flex-basis: 160px;
          flex-shrink: 0;
        `,
      },
      grid: {
        adaptiveHeight: true,
        autoPlay: false,
        cellAlign: 'left',
        contain: true,
        groupCells: 4,
        initialIndex: 0,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: false,
      },
      marketingCards: {
        adaptiveHeight: true,
        autoPlay: false,
        cellAlign: 'center',
        contain: true,
        groupCells: 1,
        initialIndex: 0,
        pageDots: false,
        prevNextButtons: false,
        wrapAround: false,
      },
      default: {
        autoPlay: 5000,
        pageDots: true,
        prevNextButtons: true,
        imagesLoaded: true,
        wrapAround: true,
        adaptiveHeight: true,
      },
    },
  },
  videoCarousel: {
    padding: sizes.lg,
    video: {
      ratio: defaultVideoRatio,
      desktop: {
        width: 281,
        height: round(281 / defaultVideoRatio),
      },
      mobile: {
        width: 216,
        height: round(216 / defaultVideoRatio),
      },
    },
    thumbnailsCarousel: {
      gap: {
        desktop: sizes.sm,
        mobile: sizes.xxs,
      },
      video: {
        ratio: defaultVideoRatio,
        desktop: {
          width: 120,
          height: round(120 / defaultVideoRatio),
        },
        mobile: {
          width: 100,
          height: round(100 / defaultVideoRatio),
        },
        radius: sizes.xxxs,
      },
      description: {
        lines: {
          desktop: 3,
          mobile: 3,
        },
        font: {
          size: {
            desktop: '16px',
            mobile: '14px',
          },
          lineHeight: {
            desktop: '21px',
            mobile: '18px',
          },
        },
        height: {
          desktop: 76,
          mobile: 68,
        },
        margin: {
          desktop: 8,
          mobile: 8,
        },
      },
    },
    productThumbnail: {
      border: 1,
      desktop: {
        width: 70,
        height: 106,
      },
      mobile: {
        width: 55,
        height: 83,
      },
    },
    avatar: {
      desktop: 38,
      mobile: 28,
    },
    description: {
      lines: {
        desktop: 2,
        mobile: 2,
      },
      font: {
        size: {
          desktop: '16px',
          mobile: '14px',
        },
        lineHeight: {
          desktop: '22px',
          mobile: '20px',
        },
      },
      height: {
        desktop: 45,
        mobile: 40,
      },
      margin: {
        desktop: 8,
        mobile: 8,
      },
    },
    modalDescription: {
      lines: 2,
      font: {
        size: '16px',
        lineHeight: '20px',
      },
    },
  },
  textField,
  checkbox,
  radio: {
    defaultStyle: css`
      opacity: ${(props) => (props.disabled ? 0.2 : 1)};
      &:checked {
        background-color: ${({ theme }) =>
          theme.colors.textEmphasizedAlternate};
      }
    `,
  },
  toastMessage: {
    variants: {
      default: {
        $toastMessageStyle: css`
          ${mobile`
            position: relative;
            top: 50px;
          `}
        `,
      },
      success: {
        $toastMessageStyle: css`
          display: flex;
          justify-content: space-between;
          background-color: ${({ theme }) => theme.colors.backgroundLight};
          border: 1px solid ${({ theme }) => theme.colors.success};
          ${mobile`
            position: relative;
            top: 70px;
          `}
        `,
      },
    },
  },
  radioButton,
  breakpoints: {
    mobile: {
      maxWidth: 767,
    },
    xs: {
      maxWidth: 576,
    },
    tablet: {
      minWidth: 768,
      maxWidth: 1023,
    },
    laptop: {
      maxWidth: 1280,
    },
  },
  assets: {
    // htmlStyles: css`
    //   font-size: 12px;
    //   ${desktop`
    //     font-size: 14px;
    //   `};
    //   @media screen and (min-width: ${(props) =>
    //       props.theme.breakpoints.xlarge}px) {
    //     font-size: 16px;
    //   }
    // `,
    // imageBreakpoint: 768
  },
  // FIXME: SXF assess if we need these
  collapsibleTrigger: {
    defaultStyle: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: bold;
      line-height: ${32 / 12};
      padding: 8px 0;
      cursor: pointer;
      user-select: none;
    `,
  },
  hintInput: {
    defaultStyle: css`
      min-height: 15px;
      font-size: 10px;
      line-height: 1.5;
      color: ${colorValues.palette.black};
    `,
  },

  input: {
    defaultStyle: css`
      border-color: ${colorValues.palette.gray};
      background: transparent none repeat scroll 0% 0%;
      padding: 23px 16px 7px;
      line-height: 21px;
      font-weight: 500;
      font-size: 14px;
      transition: border-color 0.2s ease-in-out;
    `,
  },
  inputBox: {
    defaultStyle: css`
      position: relative;
      border-color: ${colorValues.borderLight};
      border-style: solid;
      border-width: 1px;
      border-image: none 100% / 1 / 0 stretch;
      border-radius: ${({ theme }) => theme.borderRadiusInput};
      background: transparent none repeat scroll 0% 0%;
      padding: 0;
      &[data-has-focus] {
        padding: 0;
        border-width: 1px;
        border-style: solid;
        box-shadow: none;
        border-color: ${colorValues.focusColor};
        label[data-label-position='inside'],
        div[data-label-position='inside'] {
          color: ${colorValues.focusColor};
        }
      }
      label[data-label-position='inside'] {
        font-size: 12px;
      }
    `,
  },
  modalDialog: {
    defaultStyle: css`
      background-color: ${colorValues.white};
    `,
  },
  labelInput: {
    defaultStyle: css`
      margin: 0;
      text-transform: uppercase;
      &[data-label-position='floating'],
      &[data-label-position='inside'] {
        position: absolute;
        padding: 7px 9px;
        margin-left: 0;
        line-height: 1.2;
        z-index: 2;
      }
      &[data-label-position='inside'] {
        font-size: 10px;
        color: rgb(125, 125, 125);
        transform: translate3d(0, 0, 0) scale(1);
        margin: 0;
        top: 0;
      }
      &[data-label-position='floating'] {
        font-size: 16px;
        color: ${colorValues.darkenGray};
        transform: translate3d(0, calc(50% - 7px), 0);
        margin: 0;
      }
    `,
  },
  errorInput: {
    defaultStyle: css`
      min-height: 15px;
      font-size: 10px;
      line-height: 1.5;
      border-color: ${colorValues.error};
      color: ${colorValues.error};
    `,
  },
  header: {
    height: {
      desktop: 65,
      mobile: 72,
    },
  },
  loginForm: {
    forgotPassword: css`
      color: ${({ theme }) => theme.colors.textActionDeemphasized};
      width: auto;
      align-self: flex-end;
      margin-top: ${({ theme }) => theme.sizes.sm}px;
      margin-bottom: ${({ theme }) => theme.sizes.md}px;
    `,
    errorMessage: css`
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.errorMessage};
    `,
    slidingPanelHeader: css`
      margin-bottom: ${({ theme }) => theme.sizes.sm}px;
      text-align: center;
    `,
  },
};

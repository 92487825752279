import styled from 'styled-components';

import { desktop } from '../styles';

const Grid = styled.dl`
  display: grid;
  text-align: left;
  grid-gap: 24px;

  ${desktop`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 80px;
  `}
`;

const Column = styled.div``;

const Label = styled.dt`
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 6px;

  ${desktop`
    font-size: 16px;
    padding-bottom: 8px;
  `}
`;

const Value = styled.dd`
  font-size: 14px;

  ${desktop`
    font-size: 16px;
  `}
`;

export { Grid, Label, Value, Column };

import { useCallback } from 'react';

import { useMembership } from '../../../techstyle-shared/react-accounts';
import { useAsset } from '../../../techstyle-shared/react-assets';

function toStringSet(string, normalizeCase = false) {
  return new Set(
    (string || '')
      .toString()
      .replace(/(, | )/g, ',')
      .split(',')
      .map((item) => item.trim())
      .map((item) => (normalizeCase ? item.toLowerCase() : item))
      .filter((item) => item)
  );
}

const emptySet = new Set();

export function getPromoAssetFlags(asset) {
  const { flags } = asset.options.customVars;
  return flags ? toStringSet(flags) : emptySet;
}

export function createStringSetFilter(ids) {
  const idListSet = toStringSet(ids);

  return (item, index, array) => {
    return idListSet.has(item.toString());
  };
}

// split out because SavageX isn't on react-assets yet.
export function usePromoAssetFilter({ product, filter: filterFromProps }) {
  const { isVip } = useMembership();
  const {
    productCategoryIdList,
    featuredProductLocationIdList,
    masterProductId,
  } = product;

  const filter = useCallback(
    (parentFilter) => (asset, index, assets) => {
      const {
        categoryId,
        excludeFplIds,
        fplid: fplId,
      } = asset.options.customVars;

      // Excludes: Stop checks and return immediately

      if (!masterProductId) {
        return false;
      }
      if (!parentFilter(asset, index, assets)) {
        return false;
      }
      if (
        typeof filterFromProps === 'function' &&
        !filterFromProps(asset, index, assets)
      ) {
        return false;
      }

      const flags = getPromoAssetFlags(asset);

      if (flags.has('nonVip') && isVip) {
        return false;
      }
      if (flags.has('Vip') && !isVip) {
        return false;
      }

      if (excludeFplIds && featuredProductLocationIdList) {
        const hasExcludedFpl = featuredProductLocationIdList.some(
          createStringSetFilter(excludeFplIds)
        );
        if (hasExcludedFpl) {
          return false;
        }
      }

      // Includes

      if (
        fplId &&
        featuredProductLocationIdList &&
        !featuredProductLocationIdList.some(createStringSetFilter(fplId))
      ) {
        return false;
      }

      if (
        categoryId &&
        productCategoryIdList &&
        !productCategoryIdList.some(createStringSetFilter(categoryId))
      ) {
        return false;
      }

      return true;
    },
    [
      filterFromProps,
      isVip,
      featuredProductLocationIdList,
      productCategoryIdList,
      masterProductId,
    ]
  );

  return filter;
}

// maybe not necessary since filter was split out above?
export function useProductPromoAsset(
  assetContainerName,
  { product, filter: filterFromProps, multi }
) {
  const filter = usePromoAssetFilter({
    product,
    filter: filterFromProps,
  });

  const assetContainer = useAsset(assetContainerName, { filter, multi });
  const { data } = assetContainer;
  const asset = (data && data.assets[0]) || undefined;
  const flags = asset ? getPromoAssetFlags(asset) : emptySet;
  const bundleMessage = asset
    ? asset.options.customVars.bundleMessage
    : undefined;

  return {
    ...assetContainer,
    flags,
    bundleMessage,
  };
}

import {
  logInSuccess,
  signUpSuccess,
  updateMembershipStatusSuccess,
} from '../../../techstyle-shared/react-accounts';
import {
  createAction,
  createReducer,
  getNewDateFunction,
  invalidate,
  invalidateOnCustomerChange,
  isTimeTravelActive,
  loadSessionSuccess,
  startLoading,
  stopLoading,
  upToDateStatus,
  createSelector,
  outOfDateStatus,
} from '../../../techstyle-shared/redux-core';

import { Promos } from './schema';

export const initialState = {
  promos: [],
  networkStatus: upToDateStatus(),
};

export const loadPromosRequest = createAction('promos/loadPromosRequest');
export const loadPromosSuccess = createAction('promos/loadPromosSuccess');
export const loadPromosFailure = createAction('promos/loadPromosFailure');
export const invalidatePromos = createAction('promos/invalidatePromos');

export const addMemberPromoType = 'promos/addMemberPromo';
export const addMemberPromoRequest = createAction(
  'promos/addMemberPromoRequest'
);
export const addMemberPromoSuccess = createAction(
  'promos/addMemberPromoSuccess'
);
export const addMemberPromoFailure = createAction(
  'promos/addMemberPromoFailure'
);

export const cancelMemberPromoType = 'promos/cancelMemberPromo';
export const cancelMemberPromoRequest = createAction(
  'promos/cancelMemberPromoRequest'
);
export const cancelMemberPromoSuccess = createAction(
  'promos/cancelMemberPromoSuccess'
);
export const cancelMemberPromoFailure = createAction(
  'promos/cancelMemberPromoFailure'
);

export const checkPromosRequest = createAction(
  'promos/checkMembershipPromosRequest'
);
export const checkPromosSuccess = createAction(
  'promos/checkMembershipPromosSuccess'
);
export const checkPromosFailure = createAction(
  'promos/checkMembershipPromosFailure'
);

export const redeemLoyaltyPromoRequest = createAction(
  'promos/redeemMemberLoyaltyPromoRequest'
);
export const redeemLoyaltyPromoSuccess = createAction(
  'promos/redeemMemberLoyaltyPromoSuccess'
);
export const redeemLoyaltyPromoFailure = createAction(
  'promos/redeemMemberLoyaltyPromoFailure'
);

export function loadPromos() {
  return (dispatch, getState) => {
    const state = getState();

    let activeDate;
    if (isTimeTravelActive(state)) {
      const date = getNewDateFunction(state)();
      activeDate = date.toISOString();
    }

    return dispatch({
      bentoApi: {
        endpoint: 'accounts/me/promos',
        requestKey: 'loadMemberPromos',
        searchParams: { activeDate },
        schema: [Promos],
        actions: [loadPromosRequest, loadPromosSuccess, loadPromosFailure],
      },
    });
  };
}

export function addMemberPromo(payload) {
  return {
    type: addMemberPromoType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'POST',
        endpoint: 'accounts/me/membership/promo',
        json: payload,
        actions: [
          addMemberPromoRequest,
          addMemberPromoSuccess,
          addMemberPromoFailure,
        ],
      },
    },
  };
}

export function cancelMemberPromo(promoId) {
  return {
    type: cancelMemberPromoType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'DELETE',
        endpoint: `accounts/me/membership/promo/${promoId}`,
        actions: [
          cancelMemberPromoRequest,
          cancelMemberPromoSuccess,
          cancelMemberPromoFailure,
        ],
      },
    },
  };
}

export function checkMembershipPromos({ forceCheck = true }) {
  return {
    bentoApi: {
      endpoint: 'accounts/me/membership/promo/check',
      method: 'POST',
      actions: [checkPromosRequest, checkPromosSuccess, checkPromosFailure],
      body: JSON.stringify({
        forceCheck,
      }),
    },
  };
}

export function redeemLoyaltyPromo({ pointsLevel }) {
  const payload = {
    pointsLevel,
  };

  return {
    bentoApi: {
      endpoint: 'accounts/me/loyalty/rewards/redeem',
      method: 'POST',
      requestKey: `redeemPointsLevel:${pointsLevel}`,
      actions: [
        redeemLoyaltyPromoRequest,
        redeemLoyaltyPromoSuccess,
        redeemLoyaltyPromoFailure,
      ],
      json: payload,
    },
  };
}

export const promosReducer = createReducer(initialState, {
  [invalidatePromos]: invalidate,
  [logInSuccess]: invalidate,
  [signUpSuccess]: invalidate,
  [loadSessionSuccess]: invalidateOnCustomerChange,
  [updateMembershipStatusSuccess]: invalidate,
  [loadPromosRequest]: startLoading,
  [loadPromosFailure]: stopLoading,
  [loadPromosSuccess]: (state, action) => {
    state.promos = action.payload;
    state.networkStatus = upToDateStatus();
  },
  // TODO: serialize these calls safely so as not to result in inconsistent
  // state (addMemberPromoRequest could complete before loadPromosRequest)
  [addMemberPromoRequest]: startLoading,
  [addMemberPromoFailure]: stopLoading,
  [addMemberPromoSuccess]: invalidate,
  [cancelMemberPromoRequest]: startLoading,
  [cancelMemberPromoFailure]: stopLoading,
  [cancelMemberPromoSuccess]: invalidate,
  [redeemLoyaltyPromoRequest]: startLoading,
  [redeemLoyaltyPromoFailure]: stopLoading,
  [redeemLoyaltyPromoSuccess]: (state) => {
    state.networkStatus = outOfDateStatus();
  },
});

export const getPromos = createSelector([(state) => state.promos], (promos) => {
  return promos;
});

export default {
  id: 'promos',
  reducerMap: {
    promos: promosReducer,
  },
  initialActions: [],
  sagas: [],
};

import config from 'config';

import {
  createFetch,
  defaultRetryMethods,
  isJsonContentType,
  isFetchError,
} from './fetch';
import initializeSessionToken from './initializeSessionToken';
import logger from './logger';
import { matchCookieDomain } from './utils';

const debug = logger.extend('bentoApiClient');

/**
 * Return a function that will retrieve either a `public.bentoApi` or
 * `server.bentoApi` config key depending on whether `isServer` is true. If
 * there is no server-specific value, the public value will be used.
 */
function getEnvConfig(isServer) {
  return (key, isRequired = true) => {
    const publicKey = `public.bentoApi.${key}`;
    if (isServer) {
      const serverKey = `server.bentoApi.${key}`;
      if (config.has(serverKey)) {
        return config.get(serverKey);
      }
    }
    if (!isRequired && !config.has(publicKey)) {
      return undefined;
    }
    return config.get(publicKey);
  };
}

const bentoRetryPresets = new Map([
  [502, 'default'],
  [503, 'default'],
  [504, 'default'],
  [421, 'throttled'],
  [429, 'throttled'],
]);

const ErrorCodes = {
  STORE_NOT_FOUND: -32001,
  INVALID_PARAMS: -32022,
  ORDER_NOT_FOUND: -32080,
};

const EchoCartErrorCodes = {
  NO_INVENTORY: 120,
};

async function getErrorCodes(response) {
  try {
    const clonedResponse = response.clone();
    const payload = await clonedResponse.json();
    const errorCodes = [];
    const addError = (error, payload) => {
      const { message } = payload;
      if (
        error &&
        typeof error === 'object' &&
        typeof error.code === 'number'
      ) {
        const isEchoCartError = message?.includes('echoServiceCart') ?? false;
        errorCodes.push({
          isEchoCartError,
          code: error.code,
        });
      }
    };

    if (payload.error) {
      addError(payload.error, payload);
    }

    if (payload.errors) {
      payload.errors.forEach((error) => addError(error, payload));
    }

    return errorCodes;
  } catch (err) {
    return [];
  }
}

export async function bentoRetryPolicy(err, response, input, options) {
  if (err) {
    // If we don't have a response, we may not know whether the request actually
    // made it through to the server. Only retry safe methods.
    return isFetchError(err) && defaultRetryMethods.has(options.method)
      ? 'default'
      : false;
  }
  if (response.status === 502) {
    const { log = debug } = options;
    // Some 502 errors should actually be 400-level errors because they indicate
    // a bad request. Try to detect those here.
    // TODO: Make sure we have a TRO ticket request for making these the correct
    // status code.
    const errors = await getErrorCodes(response);
    for (const error of errors) {
      const { isEchoCartError, code } = error;
      if (isEchoCartError) {
        switch (code) {
          case EchoCartErrorCodes.NO_INVENTORY:
            log('Detected error code %s in response; disabling retry.', code);
            return false;
        }
      } else {
        switch (code) {
          case ErrorCodes.INVALID_PARAMS:
          case ErrorCodes.STORE_NOT_FOUND:
          case ErrorCodes.ORDER_NOT_FOUND:
            log('Detected error code %s in response; disabling retry.', code);
            return false;
        }
      }
    }
  }
  // If we do have a response, we can usually tell with more certainty whether
  // the request actually took effect, even if it was a `POST` or `PATCH`. So
  // for these status codes at least, we can retry those methods too.
  return bentoRetryPresets.get(response.status) || false;
}

// FIXME: Bento REST API sometimes returns a 200 OK response, but then includes
// additional information in the body like `status: 502` or an `errors` array.
// This makes sure responses with a `status` >=400 throw errors.
async function fixErrorResponse(response, input, options) {
  if (response.status === 200 && isJsonContentType(response)) {
    const body = await response.text();
    const payload = JSON.parse(body);
    if (
      payload &&
      typeof payload === 'object' &&
      typeof payload.status === 'number' &&
      payload.status >= 400
    ) {
      const { log = debug } = options;
      log(
        '%c!%c Received HTTP 200 response with an error payload (status: %s); a fixed Response instance will be created. Notify the Bento API team about this discrepancy!',
        'color: yellow',
        '',
        payload.status
      );
      return new Response(body, {
        status: payload.status,
        // Don't include `statusText` because remember, the `response.status`
        // wasn't accurate!
        headers: response.headers,
      });
    } else {
      // Response looks fine, but we already consumed its body so must return a
      // clone.
      return new Response(body, {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
      });
    }
  }
}

export default function createBentoApiClient(options) {
  const {
    storeDomain,
    req,
    isServer,
    trueClientIp,
    readSessionToken = req?.context.readSessionToken,
    updateSessionToken = req?.context.updateSessionToken,
    isUnsafeAuth,
    isContentPreview,
    secondarySite,
  } = options;
  // Helper for retrieving public or server specific config.
  const configGet = getEnvConfig(isServer);

  const prefixUrl = configGet('gateway');
  const apiKey = configGet(
    secondarySite ? `secondarySiteKeys.${secondarySite}` : 'key'
  );
  const apiKeyHeader = configGet('keyHeader');
  const gatewayToken = configGet('gatewayToken', false);
  const sessionCookie = configGet('sessionCookie');
  const timeout = configGet('timeout', false);
  const color = req?.universalCookies?.get('color');

  const headers = {
    'Content-Type': 'application/json',
    'x-tfg-storeDomain': storeDomain,
    [apiKeyHeader]: apiKey,
  };

  if (color) {
    headers.color = color;
  }

  if (gatewayToken) {
    headers['x-gateway-token'] = gatewayToken;
  }

  if (trueClientIp) {
    headers['True-Client-IP'] = trueClientIp;
  }

  if (
    (req?.context?.bentoApi?.enableContentPreview &&
      req?.context?.bentoApi?.enableContentPreview(req)) ||
    isContentPreview
  ) {
    headers['x-tfg-content-preview'] = 'true';
  }

  const hooks = {
    beforeRequest: [],
    afterResponse: [],
  };

  hooks.afterResponse.push(fixErrorResponse);

  // The browser will automatically manage cookies, including attaching them to
  // requests, whereas the server needs to do this manually. In order to avoid
  // complicating cookie parsing and management libraries, the server uses the
  // `Authorization` header instead, which is much simpler.

  // NOTE! A client can be created without necessarily having a `req` due to
  // features like rewrites, which load the endpoint before a request comes
  // in.
  if (!process.browser || isUnsafeAuth) {
    if (req) {
      // If the token was already initialized elsewhere, this will do nothing.
      initializeSessionToken({ req, readSessionToken, updateSessionToken });
    }

    hooks.beforeRequest.push((input, options) => {
      if (readSessionToken) {
        const sessionToken = readSessionToken();
        if (sessionToken) {
          options.headers.set('Authorization', `Bearer ${sessionToken}`);
        }
      }
      if (req) {
        const origin = req.get('Origin');
        if (origin) {
          options.headers.set('Origin', origin);
        }
      }
    });

    hooks.afterResponse.push((response) => {
      // Remember what we said above about using the Authorization header to
      // avoid cookie complications? Well, the API doesn't always send a new
      // `Authorization` header when the token is updated, but it does send
      // `Set-Cookie`. So we need to deal with cookies anyway.
      const setCookies = response?.headers?.get('Set-Cookie');
      if (!setCookies) {
        return;
      }

      const setCookieParser = require('set-cookie-parser');
      const cookies = setCookieParser.parse(setCookies);
      cookies.forEach(({ ...cookie }) => {
        if (
          cookie.name === sessionCookie &&
          readSessionToken &&
          updateSessionToken
        ) {
          const newToken = cookie.value;
          const currentToken = readSessionToken();
          if (newToken !== currentToken) {
            debug('Received new session token via Set-Cookie header.');
            updateSessionToken(newToken);
          }
        }
        if (req && !req.context.anonymousServerSession) {
          const requestDomain = req.context.url.hostname;
          if (
            cookie.domain &&
            !matchCookieDomain(cookie.domain, requestDomain)
          ) {
            debug(
              'Set-Cookie domain does not match request domain: %s vs. %s. Rewriting cookie without domain.',
              cookie.domain,
              requestDomain
            );
            cookie.domain = undefined;
          }
          // `reduxExtension` will take care of adding this to the response.
          // We don't want to call `res.cookie` every time we receive one in
          // an API response because there could be multiple, resulting in
          // multiple `Set-Cookie` headers for the same cookie.
          cookie.secure = true;
          req.context.setCookies.set(cookie.name, cookie);
        }
      });
    });
  }

  if (typeof req?.context?.bentoApi?.beforeRequestHooks === 'function') {
    hooks.beforeRequest.push(...req.context.bentoApi.beforeRequestHooks);
  }

  if (typeof req?.context?.bentoApi?.afterResponseHooks === 'function') {
    hooks.afterResponse.push(...req.context.bentoApi.afterResponseHooks);
  }

  const client = createFetch({
    headers,
    har: req && req.context.har ? req.context.har : false,
    hooks,
    prefixUrl,
    retry: bentoRetryPolicy,
    timeout,
  });

  return client;
}

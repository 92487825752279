import React from 'react';

import {
  FormattedMessage,
  useIntl,
} from '../../../../techstyle-shared/react-intl';
import slugify from '../utils/slugify';

const DynamicFormattedMessage = FormattedMessage;

/**
 * Review Sort Option Label Renderers
 */

function getReviewSortMessage(option, labelOptions = {}) {
  return {
    id: `site_pdp.reviews_sort_by_${slugify(option.label)}`,
    defaultMessage: option.label,
  };
}

export default function useReviewSortLabels() {
  const intl = useIntl();

  return {
    // eslint-disable-next-line react/display-name
    renderLabel: (option, labelOptions = {}) => {
      return (
        <DynamicFormattedMessage
          {...getReviewSortMessage(option, labelOptions)}
        />
      );
    },
    renderLabelString: (option, labelOptions = {}) => {
      return intl.formatMessage(getReviewSortMessage(option, labelOptions));
    },
  };
}

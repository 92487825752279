import { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import { useLocalStorage } from '../../../techstyle-shared/redux-core';

const unique = (input) => Array.from(new Set(input));
const emptyArray = [];

export default function useSearchHistory({
  maxLength = 4,
  storageKey = 'SearchSuggestHistory',
} = {}) {
  const [history, setHistory] = useLocalStorage(storageKey, {
    defaultValue: emptyArray,
  });

  const add = useCallback(
    (searchText) => {
      if (searchText) {
        setHistory((prevHistory) => {
          // Only return new array if it actually makes a difference, otherwise
          // keep existing value. This will cut down on unnecessary value
          // changes and thus re-renders.
          if (prevHistory[0] === searchText) {
            return prevHistory;
          }
          const newHistory = [searchText, ...prevHistory];
          const uniqueItems = unique(newHistory);
          if (uniqueItems.length > maxLength) {
            return uniqueItems.slice(0, maxLength);
          } else {
            return uniqueItems;
          }
        });
      }
    },
    [maxLength, setHistory]
  );

  const clear = useCallback(() => {
    setHistory(emptyArray);
  }, [setHistory]);

  const deleteItemByValue = useCallback(
    (itemToRemove) => {
      setHistory((prevHistory) => {
        const filteredHistory = prevHistory.filter(
          (item) => item !== itemToRemove
        );
        // Only return new array if it actually made a difference, otherwise
        // keep existing value. This will cut down on unnecessary value changes
        // and thus re-renders.
        return filteredHistory === prevHistory.length
          ? prevHistory
          : filteredHistory;
      });
    },
    [setHistory]
  );

  return useMemo(() => {
    return {
      add,
      clear,
      delete: deleteItemByValue,
      history,
    };
  }, [add, clear, deleteItemByValue, history]);
}

useSearchHistory.propTypes = {
  maxLength: PropTypes.number,
  storageKey: PropTypes.string,
};

import camelcaseKeys from 'camelcase-keys';

import { schema } from '../../../techstyle-shared/redux-core';

const httpRegex = /^http:/i;

// Sometimes URL fields start with `http:`, which we can't display on HTTPS
// pages, so fix them up as early as possible.
const fixHttpFields = [
  'image_front',
  'image_back',
  // Just in case.
  'master_product_image_full_url',
  'master_product_image_grid_url',
];

// From some endpoints (like ES search) these are number-strings like '1' or '0'
// instead of `true` or `false`.
const booleanFields = [
  'daily_fix_active',
  'lead_only_flag',
  'vip_elite_only_flag',
  'elite_only_flag',
];

export const MasterProduct = new schema.Entity(
  'MasterProduct',
  {},
  {
    idAttribute: 'master_product_id',
    // Summary of preprocessing:
    // - Convert everything from underscores to camelcase, except for
    //   `esImages.images` key names which are IDs, and
    //   `esImages.images.metadata.sizes` which are sizes like `300x300`.
    // - Manually correct `altmodel` and `altmodels` to `altModel` and
    //   `altModels`, respectively.
    processStrategy(input) {
      const { esImages = {}, ...rest } = input;
      const {
        altmodels: altModels = [],
        images = {},
        ...esImagesRest
      } = esImages;

      // If we ever need to do this for more fields, make it an array of fields
      // later.
      if (rest.brand_id === '') {
        rest.brand_id = null;
      }

      fixHttpFields.forEach((key) => {
        if (rest[key] != null) {
          rest[key] = rest[key].replace(httpRegex, 'https:');
        }
      });

      booleanFields.forEach((key) => {
        if (typeof rest[key] === 'string') {
          rest[key] = rest[key] === '1';
        }
      });

      const output = camelcaseKeys(rest, { deep: true });

      output.esImages = {
        ...camelcaseKeys({ altModels, ...esImagesRest }),
        // These keys are IDs like `Chanel-H-01` so we can't touch them.
        images: Object.keys(images).reduce((newImages, key) => {
          const imagesForKey = images[key] || [];
          newImages[key] = imagesForKey.map((image) => {
            const newImage = camelcaseKeys(image, {
              deep: true,
              // These keys are image sizes like `300x300` and we don't want
              // to uppercase the `x` by accident.
              stopPaths: ['sizes'],
            });
            if (newImage.metadata) {
              const { altmodel: altModel, ...rest } = newImage.metadata;
              newImage.metadata = { altModel, ...rest };
            }
            return newImage;
          });
          return newImages;
        }, {}),
      };

      return output;
    },
  }
);

export const ProductsPage = new schema.Object({
  products: new schema.Array(MasterProduct),
});

import React from 'react';

import AccessibilitySnippet from '../shared/components/AccessibilitySnippet';
import { loadCustomerDetails } from '../techstyle-shared/react-accounts';
import {
  loadSession,
  loadSessionDetails,
} from '../techstyle-shared/redux-core';

export default function FableticsPageExtension() {
  return {
    id: 'fableticsPage',
    page() {
      return {
        getInitialProps: {
          enhance(getInitialProps) {
            return async (ctx) => {
              await ctx.store.dispatch(loadSession());
              const sessionDetailsRequest = ctx.store.dispatch(
                loadSessionDetails()
              );
              const customerDetailsRequest = ctx.store.dispatch(
                loadCustomerDetails(['isScrubs', 'is_yitty'])
              );
              await Promise.all([
                sessionDetailsRequest,
                customerDetailsRequest,
              ]);
              return getInitialProps(ctx);
            };
          },
        },
      };
    },
    app: {
      render(props, children) {
        return (
          <>
            <AccessibilitySnippet />
            {children}
          </>
        );
      },
    },
  };
}

module.exports = {
  public: {
    vizury: {
      key: '',
      wpnUrl: '',
      analyzeUrl: '',
      campaign: {},
    },
    webchatScriptUrl:
      'https://github.com/Cognigy/Webchat/releases/latest/download/webchat.js',
    oneTrust: {
      segmentMapping: {
        // OneTrust Performance Cookies
        C0002: ['A/B Testing', 'Feature Flagging', 'Live Chat', 'Tag Managers'],
        // OneTrust Functional Cookies
        C0003: ['Analytics', 'Raw Data', 'Segment.io', 'Other'],
        // OneTrust Targeting Cookies
        C0004: ['Email', 'Email Marketing', 'CRM', 'Advertising'],
      },
    },
    sentry: {
      ignoreErrors: [],
      useSharedSdk: true,
    },
  },
  server: {},
};

import { useRef, useCallback, useEffect } from 'react';

import setAnimationTimeout from './setAnimationTimeout';

/**
 * Get a cancellable `requestAnimationFrame` based timeout to use in a
 * component.
 *
 * ```js
 * const [start, cancel] = useAnimationTimeout();
 * ```
 *
 * Each usage represents a single "instance" of a timeout: calling the `start`
 * function always starts this particular timeout, and calling the `cancel`
 * function always cancels it. If a timeout is started when it is already
 * active, it will first be cancelled, thus starting the delay over from zero
 * elapsed time. If the component unmounts, the timeout is cancelled.
 */
export default function useAnimationTimeout() {
  const cancelRef = useRef();

  const start = useCallback((callback, delay, ...args) => {
    if (cancelRef.current) {
      cancelRef.current();
    }
    cancelRef.current = setAnimationTimeout(callback, delay, ...args);
  }, []);

  const cancel = useCallback(() => {
    if (cancelRef.current) {
      cancelRef.current();
      cancelRef.current = undefined;
    }
  }, []);

  useEffect(() => {
    return () => {
      if (cancelRef.current) {
        cancelRef.current();
      }
    };
  }, []);

  return [start, cancel];
}

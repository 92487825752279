import React, { useEffect, useState } from 'react';

import Observer from '@researchgate/react-intersection-observer';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import styled from 'styled-components';

if (process.browser) {
  require('intersection-observer');
}

const VideoWrapper = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: calc(100% / ${({ aspectRatio }) => aspectRatio});
  }

  ${(props) => props.wrapperStyle};
`;

const StyledPlayer = styled(ReactPlayer)`
  position: absolute;
  bottom: 0;
  top: 0;
`;

const getUrlFromVideoId = (videoId, defaultService = 'vimeo') => {
  const regexp = new RegExp('https?://');

  if (regexp.test(videoId)) {
    return videoId;
  }

  const [serviceName, id] = `${videoId}`.includes(':')
    ? videoId.split(':')
    : [defaultService, videoId];

  if (serviceName === 'vimeo') {
    return `https://vimeo.com/${id}`;
  } else if (serviceName === 'youtube') {
    return `https://www.youtube.com/watch?v=${id}`;
  }
};

const noop = () => {};

const Video = React.forwardRef(
  (
    {
      aspectRatio = 16 / 9,
      autoPlay = true,
      hasControls = true,
      height = '100%',
      isLooping = true,
      isMuted = true,
      isPlaying: isPlayingFromProps,
      onEnded,
      onPause,
      onPlay,
      pauseOnViewportLeave = true,
      style,
      threshold = 0,
      url,
      width = '100%',
      wrapper,
      wrapperStyle,
      volume = 1,
      onIntersectionChange = noop,
      ...rest
    },
    ref
  ) => {
    const [isPlaying, setIsPlaying] = useState(autoPlay);
    const [isInViewport, setIsInViewport] = useState(false);
    const [shouldResume, setShouldResume] = useState(true);

    const isControlled = isPlayingFromProps != null;

    if (
      process.env.NODE_ENV !== 'production' &&
      isControlled &&
      (!onPlay || !onPause || !onEnded)
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        `You are using the controlled version of the Video component.
        Because the player keeps track of its playing state internally,
        you must update the isPlaying prop on each pause, play and end
        event to avoid mismatches.`
      );
    }

    const handlePlay = () => {
      if (onPlay) {
        onPlay();
      }

      if (!isControlled) {
        setIsPlaying(true);
      }
    };

    const handleEnded = () => {
      if (onEnded) {
        onEnded();
      }
      setShouldResume(false);
      if (!isControlled) {
        setIsPlaying(false);
      }
    };

    const handlePause = () => {
      if (onPause) {
        onPause();
      }

      if (pauseOnViewportLeave && isInViewport) {
        // It wasn't auto-paused... probably.
        setShouldResume(false);
      }

      if (!isControlled) {
        // We want to pause the video whenever it's off-screen, but keep track of whether it was
        // paused before it went off-screen. React-player sees a pause caused by changing its playing
        // prop as a pause event.
        setIsPlaying(false);
      }
    };

    const handleIntersectionChange = (event) => {
      setIsInViewport(event.isIntersecting);
      if (typeof onIntersectionChange === 'function') {
        onIntersectionChange(event);
      }
    };

    let shouldPlay;

    if (isControlled) {
      shouldPlay = isPlayingFromProps;
    } else {
      shouldPlay = isPlaying;
    }

    useEffect(() => {
      if (pauseOnViewportLeave) {
        if (isInViewport) {
          if (shouldResume) {
            setIsPlaying(true);
          }
        } else {
          setIsPlaying(false);
        }
      }
    }, [isInViewport, pauseOnViewportLeave, shouldResume]);

    return (
      <Observer
        disabled={!pauseOnViewportLeave}
        onChange={handleIntersectionChange}
        threshold={threshold}
      >
        <VideoWrapper aspectRatio={aspectRatio} wrapperStyle={wrapperStyle}>
          <StyledPlayer
            height={height}
            width={width}
            controls={hasControls}
            loop={isLooping}
            muted={isMuted}
            onEnded={handleEnded}
            onPause={handlePause}
            onPlay={handlePlay}
            playing={shouldPlay}
            ref={ref}
            style={style}
            url={url}
            wrapper={wrapper}
            {...rest}
          />
        </VideoWrapper>
      </Observer>
    );
  }
);

Video.displayName = 'Video';

Video.propTypes = {
  aspectRatio: PropTypes.number,
  autoPlay: PropTypes.bool,
  hasControls: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLooping: PropTypes.bool,
  isMuted: PropTypes.bool,
  isPlaying: PropTypes.bool,
  onEnded: PropTypes.func,
  onIntersectionChange: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  pauseOnViewportLeave: PropTypes.bool,
  style: PropTypes.object,
  threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  url: PropTypes.string,
  volume: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapper: PropTypes.element,
  wrapperStyle: PropTypes.any,
};

Video.getUrlFromVideoId = getUrlFromVideoId;

export default Video;

import React from 'react';

import styled, { css } from 'styled-components';

import ArrowLoadingIndicator from '../../../icons/components/ArrowLoadingIndicator';
import {
  createButtonAdditionalStyle,
  createButtonDisabledStyle,
  createButtonStyle,
} from '../utils/button';

const LoadingIndicatorWrapper = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const baseButtonStyle = css`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: auto;
  min-width: fit-content;
  opacity: 1;
  font-size: ${({ theme: { spacing } }) => spacing.sizes.pixels.s}px;
  ${({ theme: { spacing, button } }) => css`
    padding: ${spacing.sizes.pixels.s}px ${spacing.sizes.pixels.xxl}px;
    border-radius: ${button.borderRadius};
  `}
  background: ${({ theme }) => theme.colors.backgroundDark};
  transition: background-color 0.16s ease-in-out, border-color 0.16s ease-in-out;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const baseButtonLabelStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  // Gap to give appropriate space among button children
  gap: ${({ theme: { spacing } }) => spacing.sizes.pixels.xxs}px;
  font-family: ${({ theme }) => {
    return theme.fontStacks.baseFamily;
  }};
  font-size: inherit;
  font-weight: 700;
  line-height: 1.25em;
  letter-spacing: 0.02em;
  transition: color 0.16s ease-in-out;
`;

const StyledArrowLoadingIndicator = styled(ArrowLoadingIndicator)`
  ${({ $fill }) =>
    css`
      fill: ${$fill};
    `}
`;

const baseButton = (fillColor = { color: 'inherit' }) => ({
  loadingIndicator: (
    <LoadingIndicatorWrapper>
      <StyledArrowLoadingIndicator
        $fill={css`
          ${fillColor}
        `}
      />
    </LoadingIndicatorWrapper>
  ),
});

const baseButtonConfig = {
  baseButton,
  baseButtonStyle,
  baseButtonLabelStyle,
};

const tertiaryButton = {
  labelStyles: css`
    font-size: ${({ theme: { spacing } }) => spacing.sizes.pixels.xs}px;
    font-weight: 500;
    gap: ${({ theme: { spacing } }) => spacing.sizes.pixels.xxxs}px;
  `,
  disabledStyles: css`
    ${({ disabled, theme }) =>
      disabled &&
      createButtonDisabledStyle({
        foreground: theme.colors.palette.lightestBlack,
        background: theme.colors.buttonTertiaryDisabledBg,
      })}
  `,
};

const secondaryButtonStyles = (injectedStyles = '') =>
  createButtonStyle({
    ...baseButtonConfig,
    foreground: css`
      ${({ theme }) => theme.colors.palette.black}
    `,
    background: 'transparent',
    border: {
      color: css`
        ${({ theme }) => theme.colors.palette.black}
      `,
      width: 3,
    },
    additionalStyles: css`
      &:hover,
      &:focus,
      &:active {
        ${createButtonAdditionalStyle({
          foreground: css`
            ${({ theme }) => theme.colors.palette.white}
          `,
          background: css`
            ${({ theme }) => theme.colors.palette.trueBlack}
          `,
        })}
      }

      ${injectedStyles}

      ${({ disabled }) =>
        disabled &&
        createButtonDisabledStyle({
          foreground: css`
            ${({ theme }) => theme.colors.palette.lightMineral}
          `,
          background: 'transparent',
          borderColor: css`
            ${({ theme }) => theme.colors.palette.lightMineral}
          `,
        })}
    `,
  });

const primaryButtonStyles = (injectedStyles = '') =>
  createButtonStyle({
    ...baseButtonConfig,
    foreground: css`
      ${({ theme }) => theme.colors.palette.white}
    `,
    background: css`
      ${({ theme }) => theme.colors.palette.black}
    `,
    additionalStyles: css`
      border: 3px solid ${({ theme }) => theme.colors.palette.black};

      &:hover,
      &:focus,
      &:active {
        color: ${({ theme }) => theme.colors.palette.white};
        background: ${({ theme }) => theme.colors.palette.black};
      }

      ${({ disabled }) =>
        disabled &&
        createButtonDisabledStyle({
          foreground: css`
            ${({ theme }) => theme.colors.sublabelBlack}
          `,
          background: css`
            ${({ theme }) => theme.colors.buttonPrimaryDisabledBg}
          `,
        })}

      ${injectedStyles}
    `,
  });

const button = {
  borderRadius: css`
    ${({ theme }) => theme.sizes.sm}px
  `,
  variants: {
    primary: primaryButtonStyles(),
    primaryAlt: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.white}
      `,
      background: css`
        ${({ theme }) => theme.colors.backgroundDark}
      `,
      additionalStyles: css`
        ${({ disabled }) =>
          disabled &&
          createButtonDisabledStyle({
            foreground: css`
              ${({ theme }) => theme.colors.palette.lightPurple}
            `,
            background: css`
              ${({ theme }) => theme.colors.palette.featherLightPurple}
            `,
          })}
      `,
    }),
    primaryAddToBag: primaryButtonStyles(),
    secondary: secondaryButtonStyles(),
    alternate: secondaryButtonStyles(css`
      padding: ${({ theme }) => `${theme.sizes.xs}px ${theme.sizes.md}px`};
    `),
    secondaryOrange: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.textEmphasizedAlternate}
      `,
      background: 'transparent',
      border: {
        color: css`
          ${({ theme }) => theme.colors.textEmphasizedAlternate}
        `,
        width: 3,
      },
      additionalStyles: css`
        &:hover,
        &:focus,
        &:active {
          ${createButtonAdditionalStyle({
            foreground: css`
              ${({ theme }) => theme.colors.textLight}
            `,
            background: css`
              ${({ theme }) => theme.colors.textEmphasizedAlternate}
            `,
          })}
        }

        ${({ disabled }) =>
          disabled &&
          createButtonDisabledStyle({
            foreground: css`
              ${({ theme }) => theme.colors.actionDisabled}
            `,
            background: 'transparent',
            borderColor: css`
              ${({ theme }) => theme.colors.actionDisabled}
            `,
          })}
      `,
    }),
    tertiary: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.black}
      `,
      background: css`
        ${({ theme }) => theme.colors.buttonTertiaryBg}
      `,
      labelStyles: tertiaryButton.labelStyles,
      additionalStyles: css`
        ${tertiaryButton.additionalStyles}
        &:hover, &:focus, &:active {
          ${createButtonAdditionalStyle({
            foreground: css`
              ${({ theme }) => theme.colors.palette.white}
            `,
            background: css`
              ${({ theme }) => theme.colors.palette.trueBlack}
            `,
          })}
        }

        ${tertiaryButton.disabledStyles}
      `,
    }),
    link: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.black}
      `,
      background: 'transparent',
      additionalStyles: css`
        padding: 0;

        &:hover,
        &:focus,
        &:active {
          ${createButtonAdditionalStyle({
            foreground: css`
              ${({ theme }) => theme.colors.palette.trueBlack}
            `,
            background: 'transparent',
          })}
        }

        ${({ disabled }) =>
          disabled &&
          createButtonDisabledStyle({
            foreground: css`
              ${({ theme }) => theme.colors.sublabelBlack}
            `,
            background: 'transparent',
          })}
      `,
    }),
    linkSecondary: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.linkText}
      `,
      background: 'transparent',
      additionalStyles: css`
        padding: 0;

        &:hover,
        &:focus,
        &:active {
          ${createButtonAdditionalStyle({
            foreground: css`
              ${({ theme }) => theme.colors.textEmphasized}
            `,
            background: 'transparent',
          })}
        }

        ${({ disabled }) =>
          disabled &&
          createButtonDisabledStyle({
            foreground: css`
              ${({ theme }) => theme.colors.palette.purple}
            `,
            background: 'transparent',
          })}
      `,
    }),
    gradient: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.white}
      `,
      background: css`
        ${({ theme }) => theme.colors.horizontalGradient}
      `,
      additionalStyles: css`
        height: 44px;
        border-radius: 12px;
        border: none;

        ${({ disabled }) =>
          disabled &&
          createButtonDisabledStyle({
            foreground: css`
              ${({ theme }) => theme.colors.palette.white}
            `,
            background: css`
              ${({ theme }) => theme.colors.horizontalGradient}
            `,
          })}
      `,
    }),
    white: createButtonStyle({
      ...baseButtonConfig,
      background: css`
        ${({ theme }) => theme.colors.palette.white}
      `,
    }),
    facebook: createButtonStyle({
      ...baseButtonConfig,
      foreground: '#4268B2',
      background: css`
        ${({ theme }) => theme.colors.palette.white}
      `,
      border: {
        color: '#4268B2',
        width: 3,
      },
      additionalStyles: css`
        font-weight: 600;
        border-width: 2px;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
      `,
    }),
    unstyled: {
      buttonStyle: css`
        cursor: pointer;
        width: auto;
        height: auto;
        border: none;
        margin: 0;
        padding: 0;
        font-size: inherit;
        font-weight: normal;
        background: transparent;
        color: inherit;

        ${(props) =>
          props.disabled &&
          css`
            cursor: not-allowed;
            opacity: 0.5;
          `}
      `,
      labelStyle: baseButtonLabelStyle,
    },
  },
};

export default button;

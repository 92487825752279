import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { trackTaggstarTestEntered } from '../shared/actions';
import { useLocalStorage } from '../techstyle-shared/redux-core';

const TaggstarTracking = () => {
  const dispatch = useDispatch();
  const [taggstarExperimentId, setTaggstarExperimentId] = useLocalStorage(
    'taggstarTest',
    {
      defaultValue: '',
    }
  );

  useEffect(() => {
    const handleResponse = (event) => {
      const experimentId = event?.detail?.experiment?.id;
      if (experimentId && experimentId !== taggstarExperimentId) {
        setTaggstarExperimentId(experimentId);
        dispatch(trackTaggstarTestEntered(event));
      }
    };

    window.addEventListener('taggstar:response', handleResponse);

    return () => {
      window.removeEventListener('taggstar:response', handleResponse);
    };
  }, [dispatch, setTaggstarExperimentId, taggstarExperimentId]);

  return <></>;
};

export default function TaggstarExtension() {
  return {
    id: 'taggstar',
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <TaggstarTracking {...props} />
          </>
        );
      },
    },
  };
}

import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  addContact,
  verifyContact,
  getDirectMarketingGatewayByCode,
  getDirectMarketingSiteById,
  loadDirectMarketingDisposition,
  loadReferrerOptions,
  loadNestedReferrerOptions,
  loadFunnel,
} from './marketingModule';

export default function useMarketingActions() {
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return bindActionCreators(
      {
        addContact,
        verifyContact,
        getDirectMarketingGatewayByCode,
        getDirectMarketingSiteById,
        loadDirectMarketingDisposition,
        loadFunnel,
        loadReferrerOptions,
        loadNestedReferrerOptions,
      },
      dispatch
    );
  }, [dispatch]);
  return actions;
}

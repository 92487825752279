import theme from '../../styles/theme';

export const getWidgetBtnStyle = () => {
  return {
    scale: [0.5, 0.5],
    vPosition: ['90%', '20%'],
    color: {
      main: theme.colors.palette.adaButtonMain,
      second: theme.colors.palette.white,
    },
    icon: { type: 11, shape: 'circle' },
  };
};

import { useState } from 'react';

import { usePopper } from 'react-popper';

export const PopoutPlacements = {
  AUTO: 'auto',
  AUTO_START: 'auto-start',
  AUTO_END: 'auto-end',
  TOP: 'top',
  TOP_START: 'top-start',
  TOP_END: 'top-end',
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',
  RIGHT: 'right',
  RIGHT_START: 'right-start',
  RIGHT_END: 'right-end',
  LEFT: 'left',
  LEFT_START: 'left-start',
  LEFT_END: 'left-end',
};

/**
 * Create the attributes necessary to render a tooltip/popper
 *
 * The first argument is an object of generic properties that are relevant to all popouts.
 *
 * The second argument is an object passed directly to the library responsible for handling
 * the tooltip/popout behavior, in this case Popper.js.
 */
export default function usePopout(
  { placement = PopoutPlacements.BOTTOM, xOffset = 0, yOffset = 0 } = {},
  popperOptions = {}
) {
  const { modifiers = [], popperOptionsWithoutModifiers } = popperOptions;

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [xOffset, yOffset],
        },
      },
      ...modifiers,
    ],
    placement,
    ...popperOptionsWithoutModifiers,
  });

  return {
    arrowRef: setArrowElement,
    anchorRef: setReferenceElement,
    popoutRef: setPopperElement,
    attributes,
    styles,
  };
}

import React from 'react';

import { DeviceDetectionProvider } from '../context/deviceDetection';

export default function deviceDetectionExtension() {
  return {
    id: 'deviceDetection',
    app: {
      getInitialProps: process.browser
        ? () => {
            // When rendering new pages in the browser, use the initial detected
            // values that were determined by the server (below).
            const { isMobileDevice, userAgent } =
              window.__NEXT_DATA__.props.initialProps;
            return { isMobileDevice, userAgent };
          }
        : async ({ ctx }: { ctx: any }) => {
            const userAgent = ctx.req.headers['user-agent'];
            return {
              userAgent,
            };
          },
      render(props: { userAgent: any; isMobileDevice: any }, children: any) {
        const { userAgent } = props;

        return (
          <DeviceDetectionProvider userAgent={userAgent}>
            {children}
          </DeviceDetectionProvider>
        );
      },
    },
  };
}

import React from 'react';

import config from 'config';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import WelcomeModal from '../layout/components/WelcomeModal';
import { isYitty } from '../shared/utils/brandNameHelper';
import { useMembership } from '../techstyle-shared/react-accounts';
import { useDomain } from '../techstyle-shared/redux-core';

const WelcomeModalWrapper = () => {
  const router = useRouter();
  const { isDowngraded, isLead, isVip } = useMembership();
  const { tld } = useDomain();

  // there is a loading page after user login, if a FL first time user logs into yitty,
  // we should disable the popup since they won't have time to close it before they
  // are redirected to home page
  const popupDisabledRoutes = config.has(
    'public.brand.attentive.popupDisabledRoutes'
  )
    ? config.get('public.brand.attentive.popupDisabledRoutes')
    : [];
  // After login, a 'redirecting' component is rendered while the app remounts.
  const pageWillUnmount = useSelector((state) => state.app.pageWillUnmount);
  if (popupDisabledRoutes.includes(router.route) || pageWillUnmount) {
    return null;
  }

  // Show Welcome Modal only on .com domain when VIP user's account originated from another
  // storefront/sub-brand, or when user is a Lead
  const isUsDomain = tld === '.com';

  const shouldShow =
    isYitty() && isUsDomain && ((isLead && !isDowngraded) || isVip);

  if (!shouldShow) {
    return null;
  }

  return <WelcomeModal isLead={isLead} isVip={isVip} />;
};

export default function fabWelcomeModalExtension() {
  return {
    id: 'fabWelcome',
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <WelcomeModalWrapper {...props} />
          </>
        );
      },
    },
  };
}

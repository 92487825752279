import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProductContext from '../ProductContext';

const Wrapper = styled.div``;

const ProductCell = React.forwardRef(({ children, product, ...rest }, ref) => {
  return (
    <ProductContext product={product}>
      <Wrapper {...rest} ref={ref}>
        {children}
      </Wrapper>
    </ProductContext>
  );
});

ProductCell.displayName = 'ProductCell';
ProductCell.propTypes = {
  children: PropTypes.node,
  product: PropTypes.object,
};

export default ProductCell;

import ensure from '@redux-beacon/ensure';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import config from 'config';
import { createMiddleware } from 'redux-beacon';

export default function createGoogleTagManagerEvents(defineEvents) {
  // Return a Redux middleware function. It won't be called until the store is
  // being created, after all extensions have been initialized, so it's safe to
  // call `config.get` in `defineEvents`.
  return (store) => {
    const { dispatch, getState } = store;
    const getContext = () => dispatch((dispatch, getState, context) => context);
    const helpers = { dispatch, getState, getContext, ensure };
    const eventDefs =
      typeof defineEvents === 'function' ? defineEvents(helpers) : defineEvents;
    const dataLayerName = config.has('public.googleTagManager.dataLayerName')
      ? config.get('public.googleTagManager.dataLayerName')
      : 'dataLayer';
    const tracker = GoogleTagManager({ dataLayerName });
    const middleware = createMiddleware(eventDefs, tracker);
    return middleware(store);
  };
}

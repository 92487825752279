import React from 'react';

import PropTypes from 'prop-types';
import {
  FaCommentDots,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaTiktok,
  FaTumblr,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import styled from 'styled-components';

const SocialButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  background: transparent;
  color: inherit;
`;

const SocialLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

/**
 * A social media link with an icon representing the given platform. Default
 * icons and labels are included for several social networks.
 *
 * The built-in icons come from the [react-icons](https://github.com/react-icons/react-icons)
 * package and render with their default settings. This means they inherit the
 * current foreground color and font size. You can use this to easily change the
 * color and size dynamically (via CSS) or en masse (if you are rendering many
 * social icons in one place).
 *
 * Other ways to style the icon include passing the `color` and `size` props
 * individually, or using the [IconContext provider from react-icons](https://github.com/react-icons/react-icons#configuration),
 * which is re-exported from this package.
 *
 * All remaining props not documented here will be passed along to the
 * underlying link or button element.
 */
export default function SocialIcon({
  color,
  href,
  platform,
  icon = SocialIcon.defaultIcons[platform],
  label = SocialIcon.defaultLabels[platform],
  rel,
  size,
  target,
  title,
  ...rest
}) {
  // The custom `icon` can be either an element or a function. If it's a
  // function, it will be used to create a React element and passed several
  // props.
  if (typeof icon === 'function') {
    const IconComponent = icon;
    icon = (
      <IconComponent
        color={color}
        data-social-icon={platform}
        role="presentation"
        size={size}
      />
    );
  }

  // Throw an error if the label is missing, except in production.
  if (process.env.NODE_ENV !== 'production') {
    if (!label && !rest['aria-label'] && !rest['aria-labelledby']) {
      throw new Error(
        `The SocialIcon link for “${platform}” must have a label for accessibility purposes. Use the label, aria-label, or aria-labelledby prop.`
      );
    }
  }

  // If no `href` is supplied, render a button instead (and assume there will be
  // an `onClick` prop to control it).
  if (typeof href === 'undefined') {
    return (
      <SocialButton
        aria-label={label}
        type="button"
        title={title || label}
        {...rest}
      >
        {icon}
      </SocialButton>
    );
  } else {
    return (
      <SocialLink
        aria-label={label}
        href={href}
        rel={rel}
        target={target}
        title={title || label}
        {...rest}
      >
        {icon}
      </SocialLink>
    );
  }
}

SocialIcon.propTypes = {
  /**
   * The color to apply to the icon. You might prefer defining this in another
   * way: by changing the current CSS foreground color. You can also supply a
   * default via the `IconContext` provider supplied by `react-icons` (you
   * should use the version exported from this package).
   */
  color: PropTypes.string,
  /**
   * The link destination. If not provided, a button will be rendered instead.
   */
  href: PropTypes.string,
  /**
   * A React element or component type to render as the icon. If given a
   * function, it will be treated as a component type and used to create an
   * element, which will receive several props for the component to optionally
   * use.
   */
  icon: PropTypes.any,
  /**
   * The text to use for the element’s ARIA label. Labels for several social
   * platforms are included by default, otherwise you must supply one.
   */
  label: PropTypes.string,
  /**
   * The handle for a social platform, e.g. `instagram`. This is used to look up
   * a default icon and label.
   */
  platform: PropTypes.string.isRequired,
  /**
   * The value of the `rel` attribute when rendering a link element. It is
   * recommended that you use either `noreferrer` or `noopener` for security and
   * performance reasons. Defaults to `noreferrer`.
   */
  rel: PropTypes.string,
  /**
   * The size of the icon. You might prefer defining this in another way: by
   * changing the current CSS font size. By default it will be rendered at
   * `1em`. You can also supply a default via the `IconContext` provider
   * supplied by `react-icons` (you should use the version exported from this
   * package).
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The link target to use if `href` is provided. By default, social links will
   * open in a new window.
   */
  target: PropTypes.string,
  title: PropTypes.string,
};

SocialIcon.defaultProps = {
  rel: 'noreferrer',
  target: '_blank',
};

SocialIcon.defaultIcons = {
  blog: FaCommentDots,
  facebook: FaFacebookF,
  instagram: FaInstagram,
  pinterest: FaPinterestP,
  tiktok: FaTiktok,
  tumblr: FaTumblr,
  twitter: FaTwitter,
  youtube: FaYoutube,
};

SocialIcon.defaultLabels = {
  blog: 'Blog',
  facebook: 'Facebook',
  instagram: 'Instagram',
  pinterest: 'Pinterest',
  tiktok: 'TikTok',
  tumblr: 'Tumblr',
  twitter: 'Twitter',
  youtube: 'YouTube',
};

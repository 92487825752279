import config from 'config';

import { schema } from '../../../techstyle-shared/redux-core';

import logger from './logger';
import { markMalformedString } from './utils';

const debug = logger.extend('schema');
const malformedGroupRegex = /((?:^[^\w])|(?:[^\w&+-]))/g;
const malformedKeyRegex = /((?:^[^\w])|(?:[^\w.&+-])|(?:[^\w&+-]$))/g;

export function printResourceBundleWarnings(value, log = debug) {
  const { groupCode } = value;
  const malformed = [];
  const [isMalformed, markers] = markMalformedString(
    groupCode,
    malformedGroupRegex
  );
  if (isMalformed) {
    malformed.push([groupCode, null, markers]);
  }
  Object.keys(value.resources).forEach((key) => {
    const [isMalformed, markers] = markMalformedString(key, malformedKeyRegex);
    if (isMalformed) {
      malformed.push([groupCode, key, markers]);
    }
  });
  if (malformed.length) {
    log(
      `Resource bundle “${groupCode}” had the following issues. Correct them to fix this warning.`
    );
    malformed.forEach(([groupCode, key, markers]) => {
      if (key == null) {
        log(`• Malformed group code “${groupCode}”`);
        log(`                        %c%s%c `, 'color: red', markers, '');
      } else {
        log(`• Malformed key “${key}”`);
        log(`                 %c%s%c `, 'color: red', markers, '');
      }
    });
  }
}

export function generateFilterOptions() {
  const configMessages = config.get('server.intl.messages');
  const namespaces = Object.keys(configMessages);
  const messageIds = Object.values(configMessages).reduce(
    (combined, messages) => {
      if (messages) {
        const ids = messages.map((message) => message.id);
        if (combined) {
          return [...combined, ...ids];
        }
        return ids;
      }
      return combined;
    },
    null
  );

  const configDynamicMessageRbs = config.get('server.intl.dynamicMessages');
  const dynamicMessageRbs = Object.values(configDynamicMessageRbs).reduce(
    (combined, groupCodes) => {
      if (groupCodes) {
        if (combined) {
          return [...combined, ...groupCodes];
        }
        return groupCodes;
      }
      return combined;
    },
    null
  );

  if (messageIds) {
    debug(
      'Message filtering enabled: found %d message(s) from %d package(s) in %cserver.intl.messages%c.',
      messageIds.length,
      namespaces.length,
      'font-weight: bold',
      ''
    );
  } else {
    debug(
      'Message filtering disabled: %cserver.intl.messages%c did not define any messages. This is expected in development mode; in production, it indicates that the %cintl:extract-messages%c script was not run.',
      'font-weight: bold',
      '',
      'font-weight: bold',
      ''
    );
  }
  return {
    messageIds: messageIds && new Set(messageIds),
    dynamicMessageRbs: dynamicMessageRbs && new Set(dynamicMessageRbs),
  };
}

let filterOptions;

export function filterResourceBundle(resourceBundle) {
  if (process.browser || !config.get('server.intl.enableMessageFiltering')) {
    return resourceBundle;
  }
  if (!filterOptions) {
    filterOptions = generateFilterOptions();
  }
  const { messageIds, dynamicMessageRbs } = filterOptions;

  if (dynamicMessageRbs && dynamicMessageRbs.has(resourceBundle.groupCode)) {
    debug(
      'Skipped filtering for resource bundle “%s” since it appears in %cserver.intl.dynamicMessages%c.',
      resourceBundle.groupCode,
      'font-weight: bold',
      ''
    );
    return resourceBundle;
  }
  if (messageIds) {
    let filteredCount = 0;
    resourceBundle = {
      ...resourceBundle,
      resources: Object.entries(resourceBundle.resources).reduce(
        (filteredResources, [messageKey, messageValue]) => {
          const id = `${resourceBundle.groupCode}.${messageKey}`;
          if (messageIds.has(id)) {
            filteredResources[messageKey] = messageValue;
          } else {
            filteredCount++;
          }
          return filteredResources;
        },
        {}
      ),
    };
    if (filteredCount) {
      debug(
        'Filtered %s message(s) from resource bundle “%s” that did not appear in %cserver.intl.messages%c.',
        filteredCount,
        resourceBundle.groupCode,
        'font-weight: bold',
        ''
      );
    }
  }
  return resourceBundle;
}

export const ResourceBundle = new schema.Entity(
  'ResourceBundle',
  {},
  {
    idAttribute: 'groupCode',
    processStrategy(input) {
      if (debug.enabled) {
        printResourceBundleWarnings(input);
      }
      return filterResourceBundle({
        ...input,
        locale: input.locale ? input.locale.replace('_', '-') : null,
      });
    },
  }
);

import React from 'react';

import styled, { css } from 'styled-components';

import CloseIcon from '../../../icons/components/CloseIcon';
import { Button, desktop } from '../../../techstyle-shared/react-components';

const CloseButton = styled(Button).attrs({
  variant: 'unstyled',
  labelStyle: css`
    display: flex;
  `,
  children: <CloseIcon strokeWidth="2" />,
})`
  flex: 0 0 auto;
  color: ${({ theme }) => theme.colors.sublabelBlack};
  cursor: pointer;

  ${desktop`
    color: ${({ theme }) => theme.colors.closeButton};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.sublabelBlack};
    }
  `};
`;

export default CloseButton;

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import { styledRefProp } from './utils';

// Helper for calling `useLayoutEffect` that shuts up the annoying server
// warning.
const useServerSafeLayoutEffect = process.browser ? useLayoutEffect : () => {};

function reducer(state, action) {
  switch (action.type) {
    case 'start':
      return {
        ...state,
        dragging: true,
        start: action.position,
        stop: null,
        delta: { x: 0, y: 0 },
      };
    case 'stop':
      return {
        ...state,
        dragging: false,
        stop: action.position,
        delta: {
          x: action.position.x - state.start.x,
          y: action.position.y - state.start.y,
        },
      };
    case 'drag':
      return {
        ...state,
        dragging: true,
        stop: null,
        delta: {
          x: action.position.x - state.start.x,
          y: action.position.y - state.start.y,
        },
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

function Draggable({ children, onStart, onStop, onDrag }) {
  const ref = useRef();
  const [state, dispatch] = useReducer(reducer, {
    ref,
    dragging: false,
    start: null,
    stop: null,
    delta: { x: 0, y: 0 },
  });

  const { dragging } = state;

  useServerSafeLayoutEffect(() => {
    if (state.dragging) {
      if (onDrag) {
        onDrag(state);
      }
    } else if (state.stop) {
      if (onStop) {
        onStop(state);
      }
    }
  }, [onDrag, onStop, state]);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      const onMouseDown = (event) => {
        const { pageX: x, pageY: y } = event;
        dispatch({ type: 'start', position: { x, y } });
      };

      node.addEventListener('mousedown', onMouseDown, true);

      return () => {
        node.removeEventListener('mousedown', onMouseDown, true);
      };
    }
  }, []);

  useEffect(() => {
    if (dragging) {
      const onMouseUp = (event) => {
        const { pageX: x, pageY: y } = event;
        dispatch({ type: 'stop', position: { x, y } });
      };

      const onMouseMove = (event) => {
        const { pageX: x, pageY: y } = event;
        dispatch({ type: 'drag', position: { x, y } });
      };

      document.addEventListener('mouseup', onMouseUp, true);
      document.addEventListener('mousemove', onMouseMove, true);

      return () => {
        document.removeEventListener('mouseup', onMouseUp, true);
        document.removeEventListener('mousemove', onMouseMove, true);
      };
    }
  }, [dragging]);

  return React.cloneElement(children, {
    // styled-components v3 compatibility for SX.
    [styledRefProp]: ref,
  });
}

export default function useResizable(ref, initialHeight, overshoot = 0) {
  const [isResizing, setResizing] = useState(false);
  const [startHeight, setHeight] = useState(initialHeight);
  const [dragOffset, setDragOffset] = useState(0);
  const height = startHeight + dragOffset;

  const onDrag = useCallback((state) => {
    setResizing(true);
    setDragOffset(-state.delta.y);
  }, []);

  const onStop = useCallback((state) => {
    setResizing(false);
    setHeight((prevHeight) => prevHeight - state.delta.y);
    setDragOffset(0);
  }, []);

  useServerSafeLayoutEffect(() => {
    const node = ref.current;
    if (node) {
      const actualHeight = parseFloat(window.getComputedStyle(node).height);
      const targetHeight = actualHeight - overshoot;
      setHeight(targetHeight);
    }
  }, [overshoot, ref, isResizing]);

  const renderResizeHandle = useCallback(
    (element) => {
      return (
        <Draggable onDrag={onDrag} onStop={onStop}>
          {element}
        </Draggable>
      );
    },
    [onDrag, onStop]
  );

  return { height, startHeight, setHeight, renderResizeHandle, isResizing };
}

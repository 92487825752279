import React from 'react';

// The `initialState` from Redux added in the `apolloExtension` is not needed
// after the initialization happens. This HOC removes it from the `initialProps`
// so it is not passed to the client.
const withRemoveInitialReduxState = (App) => {
  const getInitialProps = App.getInitialProps;

  function WithRemoveInitialReduxState(props) {
    return <App {...props} />;
  }

  if (getInitialProps) {
    WithRemoveInitialReduxState.getInitialProps = async (pageCtx) => {
      const { initialState, ...initialProps } = await getInitialProps(pageCtx);

      return initialProps;
    };
  }

  return WithRemoveInitialReduxState;
};

export default withRemoveInitialReduxState;

import {
  gql,
  useQuery,
  useLazyQuery,
  QueryHookOptions,
  LazyQueryHookOptions,
} from '../../../techstyle-shared/react-graphql';

type ReviewsSortBy =
  | 'HighestRated'
  | 'MostRecent'
  | 'LowestRated'
  | 'WithPhotos';

interface ReviewData {
  reviewerName: string;
  reviewerCity: string;
  reviewerState: string;
  reviewerMembershipLevel: string;
  productId: number;
  productSize: string;
  productColor: string;
  reviewRating: string;
  reviewTitle: string;
  reviewContent: string;
  reviewerPhoto: string;
  reviewTimestamp: string;
  reviewSourceId: number;
  reviewSourceLabel: string;
}

interface ProductReviewData {
  reviewsData: {
    reviews: ReviewData[];
  };
}

interface ProductReviewsVars {
  groupCode: string;
  storeGroupId?: number;
  sizes?: string[];
  sortBy?: ReviewsSortBy;
  rating?: number;
  limit?: number;
  offset?: number;
}

export const GET_REVIEWS_BY_GROUP_CODE = gql`
  query Reviews(
    $groupCode: String!
    $offset: Int
    $limit: Int
    $rating: Int
    $sortBy: ReviewsSortBy
    $sizes: [String!]
    $storeGroupId: Int
  ) {
    reviewsData: reviews(
      groupCode: $groupCode
      offset: $offset
      limit: $limit
      rating: $rating
      sortBy: $sortBy
      sizes: $sizes
      storeGroupId: $storeGroupId
    ) {
      reviews {
        reviewerName
        reviewerCity
        reviewerState
        reviewerMembershipLevel
        productId
        productSize
        productColor
        reviewRating
        reviewTitle
        reviewContent
        reviewerPhoto
        reviewTimestamp
        reviewSourceId
        reviewSourceLabel
      }
    }
  }
`;

export const useReviewsQuery = (
  {
    groupCode,
    storeGroupId,
    limit,
    offset,
    sortBy,
    sizes: sizesToQuery,
    rating: ratingToQuery,
  }: ProductReviewsVars,
  options: QueryHookOptions<ProductReviewData, ProductReviewsVars> = {}
) => {
  return useQuery<ProductReviewData, ProductReviewsVars>(
    GET_REVIEWS_BY_GROUP_CODE,
    {
      variables: {
        groupCode,
        limit,
        offset,
        sizes: sizesToQuery,
        rating: ratingToQuery,
        storeGroupId,
        sortBy,
      },
      ...options,
    }
  );
};

export const useLazyReviewsQuery = (
  {
    groupCode,
    storeGroupId,
    limit,
    offset,
    sortBy,
    sizes: sizesToQuery,
    rating: ratingToQuery,
  }: ProductReviewsVars,
  options: LazyQueryHookOptions<ProductReviewData, ProductReviewsVars> = {}
) => {
  return useLazyQuery<ProductReviewData, ProductReviewsVars>(
    GET_REVIEWS_BY_GROUP_CODE,
    {
      variables: {
        groupCode,
        limit,
        offset,
        sizes: sizesToQuery,
        rating: ratingToQuery,
        storeGroupId,
        sortBy,
      },
      ...options,
    }
  );
};

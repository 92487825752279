import React from 'react';

import globalConfig from 'config';
import dynamic from 'next/dynamic';
import withRedux from 'next-redux-wrapper';
import { CookiesProvider } from 'react-cookie';
import { Provider, connect } from 'react-redux';

import appModule, {
  getCookies,
  getRandomNumberGenerator,
  setPageWillUnmount,
} from './appModule';
import AppProvider from './AppProvider';
import bentoApiModule, {
  getSession,
  loadSession,
  setSessionToken,
  VisitorStatus,
} from './bentoApiModule';
import configureStore, { getDefaultExtensions } from './configureStore';
import domainModule from './domainModule';
import logger from './logger';
import RenderTime from './RenderTime';
import timeModule, {
  isTimeTravelActive,
  setPageRenderDate,
} from './timeModule';
import useDomain from './useDomain';

const debug = logger.extend('reduxExtension');

const BentoApiAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './BentoApiAdminPanel')
);
const TimeTravelAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './TimeTravelAdminPanel')
);

// It will be very rare to show this alert, and overrides are disabled in
// production anyway. So lazy load it, and render only when the `domain.source`
// is `cookie`.
const DomainOverrideAlert = dynamic(
  () =>
    import(
      /* webpackChunkName: "DomainOverrideAlert" */ './DomainOverrideAlert'
    ),
  {
    ssr: false,
  }
);

function ConditionalDomainAlert() {
  const domain = useDomain();
  return domain.source === 'cookie' ? <DomainOverrideAlert /> : null;
}

export default function reduxExtension(
  makeStore = configureStore,
  config,
  options = {}
) {
  return (registry) => ({
    id: 'redux',
    redux: (context) => ({
      modules: [appModule, domainModule, bentoApiModule, timeModule],
      extensions: getDefaultExtensions(context),
    }),
    admin: {
      panels(initialProps) {
        const { har, harFile } = initialProps;
        return [
          <BentoApiAdminPanel key="bentoApi" har={har} harFile={harFile} />,
          <TimeTravelAdminPanel key="timeTravel" />,
        ];
      },
    },
    server: process.browser
      ? {}
      : {
          init() {
            require('./config');
          },
          configure(server) {
            // Import these here so they don't impact the browser bundle size.
            const {
              default: anonymousServerSessionMiddleware,
            } = require('./anonymousServerSessionMiddleware');
            const {
              default: universalCookieMiddleware,
            } = require('./universalCookieExpress');
            const {
              default: bentoApiExpressMiddleware,
            } = require('./bentoApiExpressMiddleware');
            const {
              default: automatedTestCookieMiddleware,
            } = require('./automatedTestCookieMiddleware');

            // Abundance of caution: ensure backwards compatibility if someone
            // upgrades `~/techstyle-shared/redux-core` without upgrading
            // `@techstyle/next-server`. TODO: This can be removed when everyone
            // is up to date.
            const addMiddleware = (name, middleware) => {
              if (server.useTracked) {
                server.useTracked(name, middleware);
              } else {
                server.use(middleware);
              }
            };

            const { getIsAnonymousServerSessionEnabled } = options;
            addMiddleware(
              'anonymousServerSessionMiddleware',
              anonymousServerSessionMiddleware(
                getIsAnonymousServerSessionEnabled
              )
            );
            addMiddleware(
              'universalCookieMiddleware',
              universalCookieMiddleware()
            );
            addMiddleware(
              'bentoApiExpressMiddleware',
              bentoApiExpressMiddleware(options)
            );
            addMiddleware(
              'automatedTestCookieMiddleware',
              automatedTestCookieMiddleware()
            );
          },
        },
    app: {
      enhance(App) {
        return withRedux(makeStore, { registry, ...config })(App);
      },
      getInitialProps: {
        enhance(getInitialProps) {
          return async ({ Component, ctx }) => {
            // Do this first in case anything goes wrong or returns early.
            if (!process.browser) {
              const useUnsafeAuth = globalConfig.get(
                'server.bentoApi.unsafeAuth'
              );
              if (useUnsafeAuth) {
                await ctx.store.dispatch(loadSession());
                const { isAdmin } = getSession(ctx.store.getState());
                const sessionToken = ctx?.req?.context?.readSessionToken();
                if (
                  sessionToken &&
                  (process?.env?.NODE_CONFIG_ENV !== 'production' || isAdmin)
                ) {
                  await ctx.store.dispatch(setSessionToken(sessionToken));
                }
              }
              if (!ctx.req.context.anonymousServerSession) {
                debug(
                  'anonymousServerSession is false; caching will be disabled.'
                );
                // TODO: As long as we set `private`, maybe we can increase the
                // `max-age` to that this user's browser caches the response
                // for them only?
                ctx.res.set('Cache-Control', 'private, max-age=0');
              }
            }

            ctx.store.dispatch(setPageWillUnmount(false));
            ctx.random = ctx.store.dispatch(getRandomNumberGenerator());
            ctx.store.dispatch(setPageRenderDate());
            let initialProps;
            try {
              initialProps = await getInitialProps({ Component, ctx });
            } finally {
              if (!process.browser) {
                const { setCookies } = ctx.req.context;
                // If have cookies and have not sent response headers
                // then append additional cookies
                // ie: redirecting(302) an already logged in user
                if (setCookies.size && !ctx.res.headersSent) {
                  debug(
                    'Appending %s received Set-Cookie %s to response.',
                    setCookies.size,
                    setCookies.size === 1 ? 'header' : 'headers'
                  );
                  setCookies.forEach((cookie) => {
                    const { name, value, ...options } = cookie;
                    ctx.req.universalCookies.set(name, value, options);
                  });
                }

                // If we're in a time travel session, or the user is an admin,
                // then we want to disable caching.
                if (ctx.req.context.anonymousServerSession) {
                  const timeTravelActive = isTimeTravelActive(
                    ctx.store.getState()
                  );
                  const { isAdmin } = getSession(ctx.store.getState());

                  if (timeTravelActive || isAdmin) {
                    ctx.res.set('Cache-Control', 'private, max-age=0');
                  }
                }
              }
            }

            if (!process.browser) {
              await ctx.store.endRootSaga();
            }

            if (!process.browser) {
              const { har, shouldSendHarLogs, correlationId } = ctx.req.context;
              if (har && shouldSendHarLogs(ctx)) {
                // Generate a reasonable filename for the HAR file.
                const harFile = `ssr_${ctx.pathname
                  // Add `index` onto paths that end with `/`.
                  .replace(/\/+$/, '/index')
                  // Remove leading slash.
                  .replace(/^\/+/, '')
                  // Replace all remaining slashes with `_`.
                  .replace(/\/+/g, '_')}_${correlationId}.har`;
                initialProps = { ...initialProps, har, harFile };
              }
            }

            if (!process.browser) {
              const cookies = ctx.store.dispatch(getCookies());
              // We don't want to transfer cookies in the HTML payload from the
              // server because (1) the browser already has them so it's a waste
              // of bytes, and (2) some of these cookies might be marked
              // HttpOnly, so we'd be violating their policy by transmitting
              // them in a method readable by JavaScript on the page. So, we
              // wrap the cookies in an object with `toJSON()` defined to return
              // nothing.
              const serverOnlyCookies = {
                cookies,
                toJSON() {
                  return undefined;
                },
              };
              initialProps = { ...initialProps, serverOnlyCookies };
            }

            return initialProps;
          };
        },
      },
      render(initialProps, children) {
        const { store, serverOnlyCookies } = initialProps;
        const cookies = serverOnlyCookies
          ? serverOnlyCookies.cookies
          : // Retrieve post configure store creation Cookies instance from context
            store.dispatch(getCookies());

        return (
          <CookiesProvider cookies={cookies}>
            <Provider store={store}>
              <AppProvider>
                <>
                  <ConditionalDomainAlert />
                  <RenderTime>{children}</RenderTime>
                </>
              </AppProvider>
            </Provider>
          </CookiesProvider>
        );
      },
    },
    page(options) {
      const {
        mapStateToProps,
        mapDispatchToProps,
        sessionRequired = true,
        loggedInRedirectUrl,
      } = options;

      return {
        async getInitialProps(ctx) {
          if (sessionRequired || loggedInRedirectUrl) {
            await ctx.store.dispatch(loadSession());
          }
          if (loggedInRedirectUrl) {
            const { visitorStatus } = ctx.store.getState().session;
            if (visitorStatus === VisitorStatus.LOGGED_IN) {
              if (ctx.res) {
                // redirect already logged in user on the server
                ctx.res.redirect(302, loggedInRedirectUrl);
                return;
              }
              window.location.href = loggedInRedirectUrl;
            }
          }
        },
        // Instead of needing to use both `connect()` and `createPage()`, wrap
        // pages in `connect()` automatically if the options contain
        // `mapStateToProps` or `mapDispatchToProps`.
        enhance:
          mapStateToProps || mapDispatchToProps
            ? connect(mapStateToProps, mapDispatchToProps)
            : undefined,
      };
    },
  });
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.fill || props.color || '#eb4498'};
  vertical-align: top;
`;

const SavageLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      // Let `aria-label` take control if present. Consumers are also free to
      // override with their own `aria-labelledby`.
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      {...props}
      viewBox="0 0 383 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      ref={ref}
    >
      <title>SavageX</title>
      <path
        d="M4.27643 31.2151C2.67921 30.6962 1.25373 29.9759 0 29.0577V23.7187H0.223268C1.41517 25.4084 2.76852 26.6852 4.27643 27.5454C5.78435 28.4056 7.40218 28.8358 9.13336 28.8358C10.2978 28.8358 11.3557 28.6344 12.3106 28.235C13.2655 27.8356 14.0281 27.2484 14.5948 26.4769C15.1616 25.7054 15.4467 24.8008 15.4467 23.763C15.4467 22.7253 15.1169 21.8002 14.4609 21.0697C13.8048 20.3425 12.7434 19.653 11.2836 19.001L6.9419 17.0893C4.85349 16.1403 3.30092 14.9694 2.28763 13.5732C1.27091 12.1804 0.765979 10.6375 0.765979 8.94768C0.765979 7.25791 1.21251 5.77296 2.10902 4.4109C3.00552 3.04543 4.25582 1.97012 5.87022 1.18497C7.48118 0.399829 9.27419 0.00725555 11.2424 0.00725555C12.764 0.00725555 14.1964 0.201835 15.5394 0.584167C16.8825 0.969913 18.016 1.47514 18.9434 2.09643V7.16915H18.7201C17.8854 5.8344 16.8172 4.82737 15.5188 4.14463C14.2204 3.4619 12.8087 3.12053 11.287 3.12053C10.0917 3.12053 9.02688 3.31511 8.08572 3.70086C7.14456 4.0866 6.40606 4.64986 5.87022 5.39063C5.33438 6.13139 5.06302 7.03602 5.06302 8.1045C5.06302 9.11154 5.36873 10.0025 5.98014 10.774C6.59155 11.5455 7.61514 12.2589 9.04749 12.911L13.4338 14.9592C15.5841 15.9389 17.1882 17.0518 18.2461 18.2943C19.3041 19.5403 19.8365 21.097 19.8365 22.9677C19.8365 24.7189 19.3659 26.2755 18.4247 27.641C17.4836 29.0065 16.2024 30.0749 14.5742 30.843C12.9461 31.6145 11.1496 32.0003 9.18145 32.0003C7.50866 32.0003 5.87709 31.7408 4.27987 31.2219L4.27643 31.2151Z"
        fill="black"
      />
      <path
        d="M64.9813 31.4571L56.1605 7.476C55.8308 6.55772 55.4804 5.65993 55.1094 4.78261C54.735 3.90871 54.3641 3.14405 53.9896 2.49203C53.6152 1.84002 53.2202 1.24604 52.8046 0.713507V0.535995H60.1896V0.713507C59.8599 1.24604 59.6881 1.85709 59.6744 2.53641C59.6606 3.21915 59.7568 3.96674 59.9664 4.78261C60.1759 5.59848 60.4747 6.54066 60.8629 7.60914L67.4888 25.7187H67.712L74.7844 7.476C75.2035 6.40752 75.5161 5.47559 75.7256 4.67337C75.9351 3.87116 76.0382 3.16111 76.0382 2.53641C76.0382 1.91171 75.9489 1.30749 75.7703 0.713507V0.535995H81.9496V0.713507C81.4138 1.24604 80.9501 1.84685 80.5619 2.51593C80.1738 3.1816 79.8166 3.88823 79.4868 4.629C79.1571 5.36976 78.7552 6.31877 78.2777 7.476L68.8764 31.4571H64.9813Z"
        fill="black"
      />
      <path
        d="M121.056 29.9892C118.579 28.6544 116.659 26.794 115.302 24.4044C113.946 22.0182 113.265 19.2941 113.265 16.2389C113.265 13.1836 113.997 10.3434 115.46 7.89584C116.924 5.44823 118.981 3.52292 121.64 2.11307C124.295 0.703218 127.28 0 130.594 0C132.594 0 134.414 0.19458 136.056 0.576912C137.698 0.962657 139.219 1.52591 140.621 2.26668V7.51692H140.353C139.367 6.12414 138.055 5.04883 136.413 4.29099C134.771 3.53316 132.965 3.15765 130.996 3.15765C128.341 3.15765 126.05 3.72091 124.123 4.84742C122.2 5.97394 120.719 7.52375 119.692 9.49685C118.662 11.47 118.146 13.7025 118.146 16.1911C118.146 18.6796 118.631 20.8849 119.603 22.7965C120.571 24.7082 121.966 26.2136 123.79 27.3128C125.61 28.4086 127.757 28.9582 130.237 28.9582C131.402 28.9582 132.535 28.8251 133.641 28.5588C134.744 28.2926 135.908 27.8932 137.134 27.3572L135.434 29.8936V21.9739C135.434 20.8781 135.376 19.9495 135.256 19.1917C135.135 18.4339 134.936 17.7614 134.651 17.1674C134.366 16.5734 134.002 15.9965 133.555 15.4332V15.2557H141.167V15.4332C140.779 15.9965 140.487 16.5768 140.295 17.1674C140.099 17.7614 139.972 18.4202 139.913 19.1473C139.851 19.8744 139.824 20.8166 139.824 21.9739V29.047C138.33 29.9653 136.726 30.6856 135.012 31.2044C133.294 31.7233 131.481 31.9828 129.571 31.9828C126.376 31.9828 123.543 31.3171 121.066 29.9789L121.056 29.9892Z"
        fill="black"
      />
      <path
        d="M169.268 31.457H148.782V31.2795C149.139 30.7743 149.431 30.211 149.655 29.5898C149.878 28.965 150.029 28.2618 150.101 27.4767C150.177 26.6915 150.214 25.7186 150.214 24.5614V7.3906C150.214 6.26408 150.177 5.29801 150.101 4.49921C150.025 3.697 149.885 2.99378 149.675 2.38614C149.466 1.77851 149.167 1.22208 148.779 0.716856V0.539345H168.68V5.7452H168.454C168.066 5.18195 167.588 4.75182 167.021 4.45483C166.455 4.15784 165.737 3.94278 164.871 3.80965C164.005 3.67652 162.903 3.60824 161.56 3.60824H154.639V14.2862H159.544C160.708 14.2862 161.683 14.2282 162.477 14.1087C163.267 13.9892 163.923 13.8117 164.445 13.5762C164.967 13.3406 165.407 13.0266 165.764 12.6408H165.987V18.9596H165.764C165.407 18.5431 164.964 18.2188 164.445 17.9798C163.923 17.7443 163.274 17.5736 162.498 17.4678C161.721 17.3654 160.753 17.3107 159.588 17.3107H154.639V28.3881H162.23C163.452 28.3881 164.49 28.313 165.342 28.1662C166.194 28.0195 166.925 27.7429 167.533 27.3435C168.141 26.9441 168.646 26.3877 169.034 25.6743H169.261V31.457H169.268Z"
        fill="black"
      />
      <path
        d="M237.866 29.6581C238.075 29.0812 238.216 28.4121 238.292 27.6543C238.364 26.8964 238.405 25.9577 238.405 24.8277V7.16536C238.405 6.00812 238.367 5.05912 238.292 4.31835C238.216 3.57758 238.075 2.91533 237.866 2.33842C237.656 1.76151 237.358 1.21873 236.969 0.713507V0.535995H256.081V5.74186H255.858C255.559 5.23663 255.119 4.8304 254.535 4.51976C253.955 4.20911 253.206 3.9804 252.296 3.8302C251.386 3.68341 250.29 3.60831 249.005 3.60831H242.833V14.7301H247.751C249.51 14.7301 250.87 14.5969 251.825 14.3307C252.78 14.0644 253.481 13.6479 253.931 13.0847H254.199V19.359H253.931C253.484 18.7958 252.78 18.3861 251.825 18.1369C250.87 17.8843 249.514 17.758 247.751 17.758H242.833V24.8311C242.833 25.9884 242.871 26.9374 242.946 27.6782C243.022 28.4189 243.169 29.0812 243.393 29.6581C243.616 30.235 243.953 30.7778 244.399 31.283V31.4605H236.966V31.283C237.354 30.7778 237.653 30.2384 237.862 29.6581H237.866Z"
        fill="black"
      />
      <path
        d="M293.793 29.5693C293.985 28.931 294.129 28.2209 294.219 27.4324C294.308 26.6472 294.353 25.6914 294.353 24.5615V2.27356L293.277 0.628165V0.539409H299.769L315.439 25.2305H315.663V7.39066C315.663 6.23343 315.632 5.26394 315.573 4.47538C315.515 3.69023 315.377 2.99384 315.171 2.3828C314.962 1.77516 314.663 1.21873 314.275 0.713507V0.535995H320.544V0.713507C320.186 1.21873 319.901 1.77516 319.692 2.3828C319.482 2.99043 319.334 3.69706 319.245 4.49586C319.156 5.29808 319.111 6.26073 319.111 7.38725V31.4571H314.453L298.024 5.69748H297.757V24.5615C297.757 25.688 297.801 26.6472 297.89 27.4324C297.98 28.2175 298.121 28.931 298.316 29.5693C298.509 30.2077 298.787 30.7778 299.144 31.283V31.4605H292.92V31.283C293.308 30.7778 293.597 30.2077 293.793 29.5693Z"
        fill="black"
      />
      <path
        d="M335.664 29.5898C335.918 28.9651 336.083 28.2619 336.155 27.4767C336.227 26.6916 336.268 25.7187 336.268 24.5615V3.56393H333.819C332.507 3.56393 331.394 3.65268 330.484 3.8302C329.574 4.00771 328.818 4.26715 328.224 4.60851C327.626 4.94988 327.149 5.37318 326.792 5.8784H326.568V0.535995H350.362V5.87499H350.138C349.781 5.36976 349.304 4.94988 348.706 4.6051C348.108 4.26373 347.363 4.00429 346.467 3.82678C345.573 3.64927 344.467 3.56051 343.155 3.56051H340.706V24.5615C340.706 25.688 340.751 26.6541 340.84 27.4529C340.93 28.2551 341.088 28.9651 341.311 29.5898C341.534 30.2145 341.857 30.7778 342.273 31.2796V31.4571H334.661V31.2796C335.08 30.7744 335.413 30.2111 335.667 29.5898H335.664Z"
        fill="black"
      />
      <path
        d="M366.255 16.4198L367.419 20.8234L359.182 7.25405C358.647 6.36308 358.114 5.51307 357.592 4.69721C357.07 3.88134 356.555 3.1474 356.046 2.49538C355.538 1.84337 355.002 1.24939 354.435 0.716856V0.539345H362.136V0.672478C362.016 1.32449 362.023 1.99357 362.161 2.67631C362.294 3.35904 362.525 4.06226 362.854 4.78937C363.184 5.51649 363.644 6.36991 364.242 7.34622L368.986 15.1772H369.164L374.045 7.3906C374.67 6.35284 375.165 5.46187 375.522 4.7211C375.879 3.98033 376.134 3.28394 376.285 2.62852C376.432 1.9765 376.481 1.33815 376.419 0.716856V0.539345H383V0.716856C382.344 1.28011 381.732 1.90482 381.166 2.58414C380.599 3.26687 380.032 4.01447 379.465 4.83034C378.899 5.64621 378.346 6.51328 377.81 7.43156L369.216 21.0009L370.603 16.2423V24.5614C370.603 25.6879 370.648 26.654 370.737 27.4528C370.827 28.255 370.985 28.9582 371.208 29.5659C371.431 30.1735 371.706 30.7436 372.036 31.2795V31.457H364.829V31.2795C365.187 30.747 365.472 30.1735 365.681 29.5659C365.891 28.9582 366.039 28.255 366.128 27.4528C366.217 26.6506 366.262 25.6879 366.262 24.5614V16.4198H366.255Z"
        fill="black"
      />
      <path
        d="M284.913 31.457H264.428V31.2795C264.785 30.7743 265.077 30.211 265.3 29.5898C265.524 28.965 265.675 28.2618 265.747 27.4767C265.822 26.6915 265.86 25.7186 265.86 24.5614V7.3906C265.86 6.26408 265.822 5.29801 265.747 4.49921C265.671 3.697 265.53 2.99378 265.321 2.38614C265.111 1.77851 264.813 1.22208 264.424 0.716856V0.539345H284.326V5.7452H284.099C283.711 5.18195 283.234 4.75182 282.667 4.45483C282.1 4.15784 281.382 3.94278 280.517 3.80965C279.651 3.67652 278.549 3.60824 277.206 3.60824H270.284V14.2862H275.189C276.354 14.2862 277.329 14.2282 278.123 14.1087C278.913 13.9892 279.569 13.8117 280.091 13.5762C280.613 13.3406 281.053 13.0266 281.41 12.6408H281.633V18.9596H281.41C281.053 18.5431 280.61 18.2188 280.091 17.9798C279.569 17.7443 278.92 17.5736 278.143 17.4678C277.367 17.3654 276.398 17.3107 275.234 17.3107H270.284V28.3881H277.875C279.098 28.3881 280.136 28.313 280.987 28.1662C281.839 28.0195 282.571 27.7429 283.179 27.3435C283.79 26.9441 284.292 26.3877 284.68 25.6743H284.907V31.457H284.913Z"
        fill="black"
      />
      <path
        d="M27.3004 24.6093L36.791 0.535995H40.6415L50.3107 24.384C51.6091 27.5416 52.3716 29.7229 53.804 31.2796V31.4571H46.3709V31.2796C47.2674 29.7673 46.7727 27.3641 45.653 24.5615L45.3404 23.8036H31.1028L30.7902 24.6502C29.6704 27.5416 29.2686 29.7673 30.2063 31.2796V31.4571H23.8037V31.2796C25.4146 29.7673 26.1772 27.5416 27.2969 24.6058L27.3004 24.6093ZM44.1348 20.7381L38.2267 5.96716H38.0481L32.3187 20.7381H44.1382H44.1348Z"
        fill="black"
      />
      <path
        d="M83.8078 24.6093L93.2984 0.535995H97.1489L106.818 24.384C108.116 27.5416 108.879 29.7229 110.311 31.2796V31.4571H102.878V31.2796C103.775 29.7673 103.28 27.3641 102.16 24.5615L101.848 23.8036H87.6102L87.2977 24.6502C86.1779 27.5416 85.776 29.7673 86.7137 31.2796V31.4571H80.3111V31.2796C81.9221 29.7673 82.6846 27.5416 83.8044 24.6058L83.8078 24.6093ZM100.639 20.7381L94.7307 5.96716H94.5521L88.8227 20.7381H100.642H100.639Z"
        fill="black"
      />
      <path
        d="M207.804 24.7423L203.057 18.2461L198.31 24.7423C196.431 27.3231 195.624 29.1904 195.624 31.3273V31.4604H188.101V31.2829C190.025 29.6375 191.594 27.5006 193.652 24.6979L200.68 14.9997L195.084 7.30184C193.023 4.49921 191.457 2.36225 189.534 0.716856V0.539345H197.101V0.672478C197.101 2.80944 197.908 4.67672 199.787 7.25746L203.053 11.7499L206.32 7.25746C208.199 4.67672 209.006 2.80944 209.006 0.672478V0.539345H216.573V0.716856C214.649 2.36225 213.08 4.49921 211.022 7.30184L205.427 14.9997L212.455 24.6979C214.515 27.5006 216.082 29.6375 218.005 31.2829V31.4604H210.483V31.3273C210.483 29.1904 209.676 27.3231 207.797 24.7423H207.804Z"
        fill="black"
      />
    </Svg>
  );
});

SavageLogo.displayName = 'SavageLogo';

SavageLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default SavageLogo;

import { useProductContext } from '../ProductContext';

import { useProductDetailContext } from './ProductDetailContext';

export function useProductDetailSelectionState(indexFromArgs) {
  const productDetailContext = useProductDetailContext();
  const productContext = useProductContext();

  if (typeof productDetailContext === 'undefined') {
    throw new Error(
      'useProductDetailSelectionState must be used inside of ProductDetail'
    );
  }

  if (typeof productContext === 'undefined') {
    throw new Error(
      'useProductDetailSelectionState must be used inside of ProductContext'
    );
  }

  const index =
    typeof indexFromArgs !== 'undefined'
      ? indexFromArgs
      : productContext.productIndex;

  if (typeof index === 'undefined') {
    throw new Error(
      'useProductDetailSelectionState must be have an index available from either props or parent context.'
    );
  }

  return productDetailContext.selectionStateByProductIndex[index];
}

import { useState, useEffect, useContext } from 'react';

import RenderTime from './RenderTime';

export default function useRenderTime(updateOnMount = false) {
  const getTimeState = useContext(RenderTime.Context);
  const [getTime, isInitial] = getTimeState();
  const [upToDate, setUpToDate] = useState(!isInitial);

  useEffect(() => {
    if (!upToDate && updateOnMount) {
      setUpToDate(true);
    }
  }, [upToDate, updateOnMount]);

  return getTime;
}

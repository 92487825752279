import { updateCustomerDetail } from '../../techstyle-shared/react-accounts';
import { updateSessionDetail } from '../../techstyle-shared/redux-core';

export const saveSessionOrCustomerDetail = async ({
  detailKey,
  detailValue,
  isLoggedIn,
  dispatch,
}) => {
  await dispatch(
    updateSessionDetail({
      name: detailKey,
      value: detailValue,
    })
  );

  if (isLoggedIn) {
    await dispatch(
      updateCustomerDetail({
        name: detailKey,
        value: detailValue,
      })
    );
  }
};

import { useEffect, useRef } from 'react';

/**
 * A hook that will filter out all the properties of the given object that have
 * not changed since the previous render.
 */
export default function useChangedValues(values) {
  const previousValues = useRef({});

  const changedValues = {};

  if (values) {
    for (const key in values) {
      const value = values[key];
      const previousValue = previousValues.current[key];
      if (value !== previousValue) {
        changedValues[key] = value;
      }
    }
  }

  useEffect(() => {
    if (values) {
      previousValues.current = values;
    }
  }, [values]);

  return changedValues;
}

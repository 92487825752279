import React, { useEffect, useMemo, useRef, useState } from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import { useDomain } from '../../../../techstyle-shared/redux-core';
import logger from '../logger';
import Script from '../Script';

const debug = logger.extend('SegmentSnippet');

function useSegmentIntegrations(writeKey) {
  const [integrations, setIntegrations] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!writeKey) {
      return;
    }

    let ignoreUpdates = false;

    const fetchIntegrations = async () => {
      try {
        const res = await window.fetch(
          `https://cdn.segment.com/v1/projects/${writeKey}/integrations`
        );
        if (!res.ok) {
          throw new Error(
            `Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`
          );
        }
        const integrations = await res.json();
        if (!ignoreUpdates) {
          setIntegrations(integrations);
        }
      } catch (err) {
        if (!ignoreUpdates) {
          setError(err);
        }
      }
    };

    fetchIntegrations();

    return () => {
      ignoreUpdates = true;
    };
  }, [writeKey]);

  return { integrations, error };
}

const SEGMENT_CATEGORY = 'Segment.io';

function SegmentSetup({ activeCategories, writeKey }) {
  const [initialized, setInitialized] = useState(false);
  const { tld } = useDomain();
  const shouldSegmentBeEnabled = activeCategories
    ? activeCategories.includes(SEGMENT_CATEGORY)
    : true;
  writeKey = writeKey || config.get('public.segment.writeKey')[tld];

  const { integrations, error } = useSegmentIntegrations(
    // Don't activate this hook if we aren't going to filter the destinations by
    // category anyway - no reason to fetch in that case.
    activeCategories ? writeKey : null
  );

  // Filtered integrations by category.
  const consentedIntegrations = useMemo(() => {
    if (integrations && activeCategories) {
      const consentedIntegrations = {
        // Disable all integrations by default, then enable the ones that are active/consented to.
        All: false,
        [SEGMENT_CATEGORY]: shouldSegmentBeEnabled,
      };

      // Now add any integrations that match `activeCategories`.
      integrations.forEach((integration) => {
        if (activeCategories.includes(integration.category)) {
          consentedIntegrations[integration.name] = true;
        }
      });

      return consentedIntegrations;
    }

    return null;
  }, [activeCategories, integrations, shouldSegmentBeEnabled]);

  const previousConsentedIntegrations = useRef(consentedIntegrations);

  useEffect(() => {
    // When Segment loads, it replaces the `window.analytics` object (to one
    // without the `load` method).So, it's possible for `window.analytics` to
    // exist but not`window.analytics.load`. This could happen here (and throw
    // an error without this check) if this component remounts (most likely
    // during  development - time hot module reloading).
    if (initialized || !writeKey || !window.analytics.load) {
      return;
    }
    if (!activeCategories) {
      // No category filtering; everything is enabled.
      window.analytics.load(writeKey);
      setInitialized(true);
    } else if (
      integrations &&
      shouldSegmentBeEnabled &&
      consentedIntegrations
    ) {
      debug(
        'Found %d integrations, calling analytics.load with: %o',
        integrations.length,
        consentedIntegrations
      );

      window.analytics.load(writeKey, {
        integrations: consentedIntegrations,
      });
      setInitialized(true);
    } else if (error) {
      // If we're here, we know we need to filter the integrations, but there
      // was an error fetching them. So we can't do anything; Segment will not
      // be loaded. Don't set `initialized` either, since we haven't actually
      // loaded Segment.
    }
  }, [
    activeCategories,
    consentedIntegrations,
    error,
    initialized,
    integrations,
    shouldSegmentBeEnabled,
    writeKey,
  ]);

  // When the consented integrations change, we need to reload the page to make sure
  // the new preferences can take effect.
  useEffect(() => {
    if (
      initialized &&
      activeCategories &&
      consentedIntegrations &&
      window.analytics &&
      window.analytics.initialized &&
      previousConsentedIntegrations.current !== consentedIntegrations
    ) {
      debug(
        'Consented integrations have changed to: %o. Reloading page.',
        consentedIntegrations
      );
      window.location.reload();
    }
    previousConsentedIntegrations.current = consentedIntegrations;
  }, [activeCategories, consentedIntegrations, initialized]);

  return null;
}

SegmentSetup.propTypes = {
  // An array of Segment integration categories to enable (for example,
  // "Analytics"). If no value is provided, integrations are not fetched nor
  // filtered; the default behavior is to enable them all.
  activeCategories: PropTypes.arrayOf(PropTypes.string),
  writeKey: PropTypes.string,
};

/**
 * Render a JavaScript snippet that loads Segment.
 */
export default function SegmentSnippet({ autoLoad = true, writeKey }) {
  const { tld } = useDomain();
  writeKey = writeKey || config.get('public.segment.writeKey')[tld];

  useEffect(() => {
    // When Segment loads, it replaces the `window.analytics` object. So, it's
    // possible for `window.analytics` to exist but not `window.analytics.load`.
    // This could happen here (and throw an error without this check) if this
    // component remounts (most likely during  development-time hot module
    // reloading).
    if (autoLoad && writeKey && window.analytics.load) {
      window.analytics.load(writeKey);
    }
  }, [autoLoad, writeKey]);

  if (!writeKey) {
    return null;
  }

  return (
    /**
     * This minified script was taken from {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/ Segment's Quickstart Guide}
     * and modified to use the `writeKey` prop and remove the initial `analytics.load` and `analytics.page` calls.
     */
    <Script
      globalScriptKey="segment-script"
      dangerouslySetInnerHTML={{
        __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${writeKey}";analytics.SNIPPET_VERSION="4.15.2";}}();`,
      }}
    />
  );
}

SegmentSnippet.propTypes = {
  autoLoad: PropTypes.bool,
  writeKey: PropTypes.string,
};

SegmentSnippet.Setup = SegmentSetup;

import productsModule from './productsModule';

// TODO: optional default product response filters...
export default function productsExtension(options = {}) {
  return {
    id: 'products',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
    redux: {
      modules: [productsModule],
    },
  };
}

import React, { ReactNode } from 'react';

import styled, { FlattenInterpolation, ThemeProps } from 'styled-components';

import ReviewRating from '../ReviewRating';

/* -------------------------------------------------------------------------------------------------
 * Default Review Rating Bar
 * ----------------------------------------------------------------------------------------------- */

// TODO: Remove in favor of creating a composed component per brand

const RatingBarLabel = styled(ReviewRating.Label)<{ $labelStyle?: StyleProp }>`
  ${({ $labelStyle }) => $labelStyle}
`;

const RatingBarStarIcon = styled(ReviewRating.StarIcon)<{
  $starIconStyles?: StyleProp;
  $isActive?: boolean;
}>`
  ${({ $starIconStyles }) => $starIconStyles}
`;

const RatingBar = styled(ReviewRating.Bar)<{ $barStyle?: StyleProp }>`
  margin: 10px 0;
  ${({ $barStyle }) => $barStyle}
`;

const RatingBarIndicator = styled(ReviewRating.BarIndicator)<{
  $barFillStyle?: StyleProp;
  $isActive?: boolean;
}>`
  ${({ $barFillStyle }) => $barFillStyle}
`;

const RatingCount = styled(ReviewRating.Count)<{ $countStyle?: StyleProp }>`
  ${({ $countStyle }) => $countStyle}
`;

type StyleProp = FlattenInterpolation<ThemeProps<any>>;

type ReviewRatingBarProps = {
  /**
   * Custom styles for the the bar container
   */
  barContainerStyle?: StyleProp;
  /**
   * Custom styles for the filled part of the rating bar
   */
  barFillStyle?: StyleProp;
  /**
   * Custom styles for the base of the rating bar
   */
  barStyle?: StyleProp;
  /**
   * The active state of the bar (i.e. when a rating is currently used as a filter it is active)
   */
  isActive?: boolean;
  /**
   * Click handler when a rating bar is clicked
   */
  onRatingBarClickHandler?: (rating: ReviewRating.Rating) => void;

  /**
   * Custom styles for the rating count at the end of each rating bar
   */
  ratingCountStyle?: StyleProp;

  /**
   * Total number of reviews for a particular rating
   */
  ratingItemCount: number;

  /**
   * Custom styles for the rating number at the start of each rating bar
   */
  ratingNumberStyle?: StyleProp;

  /**
   * Total number of reviews for this product
   */
  reviewCount: number;

  /**
   * Icon to override default star icon
   */
  starIcon?: ReactNode;

  /**
   * Custom styles for the default star icon in each rating bar
   */
  starIconStyle?: StyleProp;

  /**
   *  Star Rating in range 1..5
   */
  starRating: ReviewRating.Rating;
};

const ReviewRatingBar = ({
  barFillStyle,
  barStyle,
  isActive,
  onRatingBarClickHandler,
  reviewCount,
  ratingCountStyle,
  ratingItemCount,
  ratingNumberStyle,
  starIcon,
  starIconStyle,
  starRating,
  barContainerStyle,
}: ReviewRatingBarProps) => {
  return (
    <ReviewRating.Root
      isActive={isActive}
      rating={starRating}
      ratingCount={ratingItemCount}
      reviewCount={reviewCount}
    >
      <ReviewRating.Container
        onClick={onRatingBarClickHandler}
        barContainerStyle={barContainerStyle}
      >
        <RatingBarLabel
          $labelStyle={ratingNumberStyle}
          starIcon={
            starIcon || (
              <RatingBarStarIcon
                $isActive={isActive}
                $starIconStyles={starIconStyle}
              />
            )
          }
        />
        <RatingBar $barStyle={barStyle}>
          <RatingBarIndicator
            $barFillStyle={barFillStyle}
            $isActive={isActive}
          />
        </RatingBar>
        <RatingCount $countStyle={ratingCountStyle} />
      </ReviewRating.Container>
    </ReviewRating.Root>
  );
};

export default ReviewRatingBar;

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getCopsSegmentTraits } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useCopsSegmentTraits() {
  const actions = useAccountActions();
  const copsSegmentTrait = useSelector(getCopsSegmentTraits);

  useEffect(() => {
    if (!copsSegmentTrait.networkStatus.isUpToDate) {
      actions.loadCopsSegmentTraits();
    }
  }, [actions, copsSegmentTrait.networkStatus.isUpToDate]);

  return copsSegmentTrait;
}

import React from 'react';

import FlagAT from 'flagkit-web/svgs/AT.svg';
import FlagAU from 'flagkit-web/svgs/AU.svg';
import FlagBE from 'flagkit-web/svgs/BE.svg';
import FlagCA from 'flagkit-web/svgs/CA.svg';
import FlagDE from 'flagkit-web/svgs/DE.svg';
import FlagDK from 'flagkit-web/svgs/DK.svg';
import FlagES from 'flagkit-web/svgs/ES.svg';
import FlagFR from 'flagkit-web/svgs/FR.svg';
import FlagGB from 'flagkit-web/svgs/GB.svg';
import FlagNL from 'flagkit-web/svgs/NL.svg';
import FlagSE from 'flagkit-web/svgs/SE.svg';
import FlagUS from 'flagkit-web/svgs/US.svg';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const flags = {
  AT: FlagAT,
  AU: FlagAU,
  BE: FlagBE,
  CA: FlagCA,
  DE: FlagDE,
  DK: FlagDK,
  ES: FlagES,
  FR: FlagFR,
  GB: FlagGB,
  NL: FlagNL,
  SE: FlagSE,
  US: FlagUS,
};

const Svg = styled.svg`
  max-width: 100%;
  vertical-align: top;
`;

export default function FlagIcon({ country, width, height, ...props }) {
  const FlagComponent = flags[country];
  return FlagComponent ? (
    <Svg
      as={FlagComponent}
      width={width}
      height={height}
      role="img"
      {...props}
    />
  ) : null;
}

FlagIcon.propTypes = {
  /**
   * Two-letter country code of the flag to render.
   */
  country: PropTypes.oneOf([
    'AT',
    'AU',
    'BE',
    'CA',
    'DE',
    'DK',
    'ES',
    'FR',
    'GB',
    'NL',
    'SE',
    'US',
  ]).isRequired,
  /**
   * Icon height; defaults to scaling with the width.
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Icon width; defaults to the natural size of the SVG.
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FlagIcon.defaultProps = {
  height: null,
};

export function toDigitString(value, width, emptyValue = null) {
  value = parseInt(value, 10);
  if (value || value === 0) {
    let digits = value.toString();
    if (digits.length > width) {
      return digits.slice(-width);
    }
    while (digits.length < width) {
      digits = `0${digits}`;
    }
    return digits;
  }
  return emptyValue;
}

/**
 * What if it's 1999, and their card expires in "01"? That doesn't mean it
 * expired in 1901, it means it expires in 2001. What if it's 2001, and their
 * card expired in "99"? That doesn't mean it expires in 2099, it means it
 * expired in 1999. So it's not as simple as chopping off and adding digits
 * based on the current year. This function properly converts two-digit years to
 * the most reasonable four-digit interpretation.
 */
export function toFullYear(
  expYear,
  { currentYear = new Date().getFullYear() } = {}
) {
  expYear = parseInt(expYear, 10);
  if (expYear >= 100) {
    return expYear;
  }
  const maxYear = currentYear + 20;
  const thisCentury = parseInt(currentYear.toString().slice(0, -2), 10);
  const prevCentury = thisCentury - 1;
  const nextCentury = thisCentury + 1;
  // Get the highest year from this list that's less than or equal to `maxYear`.
  // In other words, credit cards are allowed to expire 20 years from now, but
  // 2-digit years after that will be considered the past.
  const candidates = [
    nextCentury * 100 + expYear,
    thisCentury * 100 + expYear,
    prevCentury * 100 + expYear,
  ];
  return candidates.find((year) => year <= maxYear);
}

export function convertEmptyStringsToNull(obj) {
  const newObj = { ...obj };
  for (const key in newObj) {
    if (newObj[key] === '') {
      newObj[key] = null;
    }
  }
  return newObj;
}
// Grabs birthday values from customer.profile and parses them to return a date object or null if there’s an invalid date
export function getISOBirthDateString(customerProfile) {
  if (!customerProfile) {
    return null;
  }
  const [day, month, year] = [
    customerProfile['birth-day'],
    customerProfile['birth-month'],
    customerProfile['birth-year'],
  ];
  if (day && month && year) {
    // we want the month index so we subtract 1
    const birthDate = Date.UTC(year, month - 1, day);
    if (Number.isNaN(birthDate)) {
      return null;
    } else {
      return new Date(birthDate).toISOString().split('T')[0];
    }
  }
  return null;
}

export function getParentRoute(asPath) {
  // Get the path's parent route, return the base route, if not nested
  //  (e.g. if url is '/account/favorites', return '/account')
  //        ==> return string up to and including second '/'
  const nestedIndex = asPath.slice(1).indexOf('/');
  let basePath = asPath;

  if (nestedIndex > 0) {
    basePath = asPath.slice(0, nestedIndex + 1);
  }

  return basePath;
}

import config from 'config';

import {
  forceTestCohortSuccess,
  forceAllTestsToControlSuccess,
} from '../../../techstyle-shared/react-abtest';
import {
  logInSuccess,
  signUpSuccess,
  updateCustomerDetailSuccess,
  updateMembershipStatusSuccess,
} from '../../../techstyle-shared/react-accounts';
import {
  createAction,
  createReducer,
  createCachedSelector,
  getDateNowFunction,
  loadSessionSuccess,
  updateSessionDetailSuccess,
  outOfDateStatus,
  startLoading,
  stopLoading,
  upToDateStatus,
  parseDate,
} from '../../../techstyle-shared/redux-core';

import {
  createFeatureOverrideCookieName,
  getFeatureHandleFromCookieName,
  isFeatureOverrideCookieName,
  parseOverridesFromQuery,
} from './featuresUtils';
import getDerivedFeatureState, { featureIdMap } from './getDerivedFeatureState';
import logger from './logger';
import { Feature, FeatureErrorType } from './schema';

const debug = logger.extend('featuresModule');

const baseOverridenFeatureData = {
  featureId: featureIdMap.OVERRIDDEN,
  enabled: true,
  valueType: 'boolean',
  value: true,
};

export const initialState = {
  containers: {},
  overrides: {},
};

export const addFeatureOverrideSuccess = createAction(
  'features/addFeatureOverrideSuccess'
);
export const removeFeatureOverrideSuccess = createAction(
  'features/removeFeatureOverrideSuccess'
);

export const loadFeaturesRequest = createAction('features/loadFeaturesRequest');
export const loadFeaturesSuccess = createAction('features/loadFeaturesSuccess');
export const loadFeaturesFailure = createAction('features/loadFeaturesFailure');
export const invalidateFeatures = createAction('features/invalidateFeatures');

export function initFeatures() {
  return (dispatch, getState, context) => {
    const { isServer, hasPreloadedState, req } = context;
    if (isServer && !hasPreloadedState) {
      const overrideParameter = config.get('public.features.overrideParameter');

      let queryValue;
      if (overrideParameter && req.query[overrideParameter]) {
        queryValue = req.query[overrideParameter];
        debug(
          'Found feature override parameter: %s => %s',
          overrideParameter,
          queryValue
        );
      }

      if (queryValue) {
        const queryOverrides = parseOverridesFromQuery(queryValue);
        if (queryOverrides.length) {
          queryOverrides.forEach((override) => {
            const [handle, value] = override;
            debug(
              'Adding feature override from query: %s => %s',
              handle,
              value
            );
            dispatch(addFeatureOverride({ handle, value }));
          });
        }
      }

      const allowCookieOverrides = config.get(
        'public.features.allowCookieOverrides'
      );
      if (!allowCookieOverrides) {
        debug(
          `Feature override cookies disabled via 'public.features.allowCookieOverrides'.`
        );
        return;
      }

      const allCookies = req.universalCookies.getAll();
      for (const [cookieName, stringValue] of Object.entries(allCookies)) {
        if (isFeatureOverrideCookieName({ cookieName })) {
          const handle = getFeatureHandleFromCookieName({ cookieName });
          const value = Boolean(JSON.parse(stringValue));
          debug('Found feature override: %s => %s', handle, value);
          dispatch(addFeatureOverride({ handle, value, skipCookie: true }));
        }
      }
    }
  };
}

export function addFeatureOverride({ handle, value, skipCookie }) {
  return (dispatch, getState, context) => {
    if (!skipCookie) {
      const allowCookieOverrides = config.get(
        'public.features.allowCookieOverrides'
      );
      if (allowCookieOverrides) {
        const cookieName = createFeatureOverrideCookieName({
          featureHandle: handle,
        });
        if (cookieName) {
          context.cookies.set(cookieName, value, { secure: true });
        }

        // we have got to normalize this handle to lowercase in this scenario
        // otherwise we will have conflicting cookies/state
        handle = handle.toLowerCase();
      } else {
        debug(
          `'public.features.allowCookieOverrides' is disabled; skip adding override cookie.`
        );
      }
    }

    return dispatch(addFeatureOverrideSuccess({ handle, value }));
  };
}

export function removeFeatureOverride({ handle, skipCookie }) {
  return (dispatch, getState, context) => {
    if (!skipCookie) {
      const allowCookieOverrides = config.get(
        'public.features.allowCookieOverrides'
      );
      if (allowCookieOverrides) {
        const cookieName = createFeatureOverrideCookieName({
          featureHandle: handle,
        });
        if (cookieName) {
          context.cookies.remove(cookieName);
        }
      } else {
        debug(
          `'public.features.allowCookieOverrides' is disabled; skip removing override cookie.`
        );
      }
    }

    return dispatch(removeFeatureOverrideSuccess({ handle }));
  };
}

export function loadFeatures(
  handles,
  {
    batch = config.get('public.features.batch'),
    batchKey = 'default',
    shouldInvalidateABTests = false,
    additionalQueryParams = {},
  } = {}
) {
  if (handles && typeof handles === 'string') {
    handles = [handles];
  } else if (!handles || !handles.length) {
    // we don't allow fetching all the features, it's not necessary and expensive
    throw new Error(`loadFeatures must be called with at least one handle`);
  }

  if (batch) {
    return (dispatch, getState, context) => {
      context.loadFeaturesBatches = context.loadFeaturesBatches || {};
      context.invalidateABTestsByBatch = context.invalidateABTestsByBatch || {};
      const { loadFeaturesBatches: batches, invalidateABTestsByBatch } =
        context;
      let currentBatch = batches[batchKey];
      const currentInvalidateABTests = invalidateABTestsByBatch[batchKey];

      if (shouldInvalidateABTests && currentInvalidateABTests !== true) {
        invalidateABTestsByBatch[batchKey] = shouldInvalidateABTests;
      }

      if (currentBatch) {
        // Add to the batch.
        currentBatch.handles.push(...handles);
      } else {
        // Start a new batch.
        currentBatch = {
          handles: handles.slice(),
          promise: new Promise((resolve, reject) => {
            const loadFeaturesAfterTick = async () => {
              // Let a tick of the event loop pass.
              await Promise.resolve();
              // After waiting, get a snapshot of the current queue and clear it
              // out.
              const { loadFeaturesBatches: batches, invalidateABTestsByBatch } =
                context;
              const currentBatch = batches[batchKey];
              const currentInvalidateABTests =
                invalidateABTestsByBatch[batchKey];
              batches[batchKey] = undefined;
              invalidateABTestsByBatch[batchKey] = undefined;
              // Get unique handles.
              const batchHandles = Array.from(new Set(currentBatch.handles));
              try {
                resolve(
                  dispatch(
                    loadFeatures(batchHandles, {
                      batch: false,
                      batchKey,
                      shouldInvalidateABTests: currentInvalidateABTests,
                      additionalQueryParams: additionalQueryParams,
                    })
                  )
                );
              } catch (err) {
                reject(err);
              }
            };

            loadFeaturesAfterTick();
          }),
        };
        batches[batchKey] = currentBatch;
      }
      return currentBatch.promise;
    };
  }

  return (dispatch, getState) => {
    const state = getState();
    const { features } = state;
    const now = getDateNowFunction(state)();
    const handlesToFetch = [];

    handles.forEach((handle) => {
      const container = features.containers[handle];
      const override = features.overrides[handle];
      let shouldFetch = false;
      if (!handle) {
        // `null` handle input
      } else if (override) {
        // We have an override, do not bother fetching
      } else if (container) {
        const maxAge = config.get('public.features.maxAge');
        let isExpired = false;
        if (container.fetchedDate) {
          const fetchedDate = parseDate(container.fetchedDate);
          const age = now - fetchedDate.getTime();
          isExpired = age >= maxAge;
        }
        if (container.networkStatus.isLoading) {
          // Already loading, do nothing.
        } else if (isExpired) {
          debug('Feature “%s” is expired; refetching.', handle);
          shouldFetch = true;
        } else if (container.error) {
          // Don't attempt to fetch if there was an error last time!
          debug('Feature “%s” has error; skipping refetch.', handle);
        } else if (!container.networkStatus.isUpToDate) {
          debug('Feature “%s” is invalidated; refetching.', handle);
          shouldFetch = true;
        }
      } else {
        shouldFetch = true;
      }

      if (shouldFetch) {
        handlesToFetch.push(handle);
      }
    });

    if (handlesToFetch.length) {
      return dispatch({
        bentoApi: {
          endpoint: 'features',
          searchParams: {
            handles: handlesToFetch.join(' '),
            ...additionalQueryParams,
          },
          requestKey: `loadFeatures-${JSON.stringify(handlesToFetch)}`,
          schema: [Feature],
          actions: [
            (payload, meta) =>
              loadFeaturesRequest(payload, {
                ...meta,
                handlesToFetch,
              }),
            (payload, meta) =>
              loadFeaturesSuccess(payload, {
                ...meta,
                handlesToFetch,
                shouldInvalidateABTests,
              }),
            (payload, meta) =>
              loadFeaturesFailure(payload, {
                ...meta,
                handlesToFetch,
              }),
          ],
        },
      });
    }
  };
}

export function invalidateLoadedFeatures(state) {
  Object.keys(state.containers).forEach((handle) => {
    const feature = state.containers[handle];
    feature.networkStatus = outOfDateStatus();
  });
}

export const featuresReducer = createReducer(initialState, {
  [invalidateFeatures]: invalidateLoadedFeatures,
  [logInSuccess]: invalidateLoadedFeatures,
  [signUpSuccess]: invalidateLoadedFeatures,
  [loadSessionSuccess]: (state, action) => {
    if (action.meta.customerDidChange) {
      invalidateLoadedFeatures(state);
    }
  },
  [updateMembershipStatusSuccess]: invalidateLoadedFeatures,
  [updateSessionDetailSuccess]: (state, action) => {
    const { shouldInvalidateFeatures = false } = action?.meta || {};
    if (shouldInvalidateFeatures) {
      invalidateLoadedFeatures(state);
    }
  },
  [updateCustomerDetailSuccess]: (state, action) => {
    const { shouldInvalidateFeatures = false } = action?.meta || {};
    if (shouldInvalidateFeatures) {
      invalidateLoadedFeatures(state);
    }
  },
  [forceTestCohortSuccess]: invalidateLoadedFeatures,
  [forceAllTestsToControlSuccess]: invalidateLoadedFeatures,
  [addFeatureOverrideSuccess]: (state, action) => {
    const { handle, value } = action.payload;
    state.overrides[handle] = {
      value,
    };
  },
  [removeFeatureOverrideSuccess]: (state, action) => {
    const { handle } = action.payload;
    const { [handle]: removed, ...overrides } = state.overrides;
    state.overrides = overrides;
  },
  [loadFeaturesRequest]: (state, action) => {
    action.meta.handlesToFetch.forEach((containerName) => {
      if (!state.containers[containerName]) {
        state.containers[containerName] = {
          networkStatus: outOfDateStatus(),
        };
      }
      startLoading(state.containers[containerName]);
    });
  },
  [loadFeaturesFailure]: (state, action) => {
    action.meta.handlesToFetch.forEach((containerName) => {
      const feature = state.containers[containerName];
      if (feature) {
        // TODO: Detect different error types here?
        feature.error = FeatureErrorType.OTHER;
        stopLoading(feature);
      }
    });
  },
  [loadFeaturesSuccess]: (state, action) => {
    const { payload, meta } = action;
    const { fetchedDate, handlesToFetch } = meta;

    if (handlesToFetch) {
      handlesToFetch.forEach((handle) => {
        const container = payload.find((feature) => feature.handle === handle);
        let error = null;

        if (!container) {
          error = FeatureErrorType.NOT_FOUND;
        }

        state.containers[handle] = {
          data: container || state.containers[handle] || null,
          error,
          fetchedDate,
          networkStatus: upToDateStatus(),
        };
      });
    }
  },
});

const getBaseFeatureState = (handle, feature, override) => {
  // if we have found an override, provide some
  // skeletal state for the derived values to work from
  if (override) {
    const { value } = override;
    return {
      data: {
        ...baseOverridenFeatureData,
        handle,
        value,
      },
      fetchedDate: null,
      networkStatus: upToDateStatus(),
    };
  }

  // otherwise, if we have an actual feature in state, return that badboy
  if (feature) {
    return { handle, ...feature };
  }

  // Since hooks can't be called conditionally, allow passing a null `handle` to
  // skip loading any feature record. we will still provide a barebones state
  // here so that the API consumers can rely on their derived state responses.
  // in order to check for a "null" feature, they could check for either the
  // handle or data props being `null`
  if (!handle) {
    return {
      handle,
      data: null,
      fetchedDate: null,
      networkStatus: upToDateStatus(),
    };
  }

  // this state is the stub for an in-flight feature response
  return {
    handle,
    fetchedDate: null,
    networkStatus: outOfDateStatus(),
  };
};

export const getFeature = createCachedSelector(
  [
    (_state, handle) => handle,
    (state, handle) => state.features.containers[handle],
    (state, handle) => state.features.overrides[handle],
  ],
  (handle, feature, override) => {
    const baseFeatureState = getBaseFeatureState(handle, feature, override);
    const derivedFeatureState = getDerivedFeatureState(baseFeatureState);
    return {
      ...baseFeatureState,
      ...derivedFeatureState,
    };
  }
)((_state, handle) => handle || 'nullFeature');

export const getFeatures = createCachedSelector(
  [(state) => state, (_state, handles) => handles],
  (state, handles) => {
    return handles.map((handle) => getFeature(state, handle));
  }
)((_state, handles) => JSON.stringify(['getFeatures', handles]));

export default {
  id: 'features',
  initialActions: [initFeatures()],
  reducerMap: {
    features: featuresReducer,
  },
};

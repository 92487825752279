import config from 'config';
import { formatDate } from 'date-fns/format';
import { parse as parseDateString } from 'date-fns/parse';
import { parseISO as parseISODate } from 'date-fns/parseISO';
import { toDate } from 'date-fns/toDate';
import pacificTimeOffset from 'pacific-time-offset';

const durationRegex = /^(\d+)(d|h|m|s|ms)$/;
const amounts = {
  ms: 1,
  s: 1000,
  m: 1000 * 60,
  h: 1000 * 60 * 60,
  d: 1000 * 60 * 60 * 24,
};

export function isValidDate(date) {
  return !Number.isNaN(date.getTime());
}

// Emulate the old `parse` from date-fns v1, which was less strict in what is
// accepted. If `formatString` is provided, use the new `parse` from v2.
/**
 * @param {string} inputDate
 * @param {string} [formatString]
 * @param {Date} [referenceDate]
 * @param {import('date-fns').ParseOptions} [options]
 * @returns {Date}
 */
export function parseDate(
  inputDate,
  formatString,
  referenceDate = new Date(),
  options
) {
  if (typeof inputDate === 'string') {
    if (formatString) {
      return parseDateString(inputDate, formatString, referenceDate, options);
    } else {
      const date = parseISODate(inputDate);
      return isValidDate(date) ? date : new Date(inputDate);
    }
  }
  return toDate(inputDate);
}

export function parseDuration(duration) {
  if (typeof duration === 'number') {
    return duration;
  }
  if (typeof duration !== 'string') {
    throw new Error(`Invalid duration value: ${duration}`);
  }
  let lastUnit;
  let lastUnitAmount;
  return duration.split(' ').reduce((total, part) => {
    const match = part.match(durationRegex);
    if (!match) {
      throw new Error(`Invalid duration string: “${duration}”`);
    }
    const number = parseInt(match[1], 10);
    const unit = match[2];
    const unitAmount = amounts[unit];
    if (lastUnit) {
      if (lastUnitAmount <= unitAmount) {
        throw new Error(
          `Duration units must be in descending order, but ‘${unit}’ was found after ‘${lastUnit}’ in “${duration}”`
        );
      }
    }
    lastUnit = unit;
    lastUnitAmount = unitAmount;
    const amount = number * unitAmount;
    return total + amount;
  }, 0);
}

/**
 * Sometimes we need date values in Pacific Time (specifically,
 * `America/Los_Angeles`). This function determines what the given `date` would
 * be in Pacific Time by accounting for the difference between the local time
 * zone and the Pacific Time offset (whether PST or PDT) for the given date.
 * Since JavaScript times don't have time zones attached (they are ALWAYS in
 * local time), returning the resulting Date object itself would be dangerous,
 * as there is no indication that it has been tampered with and no longer
 * represents a local time. So, you must supply a format and this function will
 * return a string instead. This is to prevent any bugs and confusion that would
 * result from using the returned Date object!
 */
export function toPacificDateString(date, format) {
  // Backwards compatibility: convert date-fns v1 format tokens to date-fns v2
  // format tokens. It's incredibly unlikely that the tokens we're excluding by
  // doing this (Y and D) are actually wanted, ever.
  format = format.replace(/Y/g, 'y').replace(/D/g, 'd');
  // `pacificOffset` will be the offset from UTC -> Pacific (a negative number),
  // while `getTimezoneOffset()` returns the offset from local time -> UTC.
  // Thus we add them together instead of subtracting. Both values are minutes.
  const pacificOffset = pacificTimeOffset(date);
  const localOffset = date.getTimezoneOffset();
  const applyOffset = localOffset + pacificOffset;
  const offsetMilliseconds = applyOffset * 1000 * 60;
  const offsetDate = new Date(date.getTime() + offsetMilliseconds);
  return formatDate(offsetDate, format);
}

/**
 * Create a store that will ignore all dispatched actions. Used to avoid
 * unnecessary initialization if we get a request we know doesn't need Redux
 * to be initialized.
 */
export function nullStoreEnhancer(createStore) {
  return (...args) => {
    const dispatch = (action) => action;
    const store = createStore(...args);
    return { ...store, dispatch };
  };
}

/**
 * Convert a Map-like value that has a `forEach` method into a plain object with
 * the same keys and values.
 */
export function toPlainObject(mapLike) {
  const obj = {};
  mapLike.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
}

let retryPresets;

/**
 * Get a Promise that resolves to a config object for use with
 * [node-retry](https://github.com/tim-kos/node-retry) based on the given value,
 * which can be:
 *
 * - An object that already conforms to the config node-retry expects for its
 *   `timeouts` function.
 * - `undefined`, `null`, or `false` to disable retry.
 * - A number, which translates to `{ retries: N }`. The remaining options will
 *   be the defaults from node-retry.
 * - A string, which should be the name of a config preset defined in the
 *   `public.retry` config option. You can use this to define different retry
 *   configs for different situations and refer to them by name.
 * - A function, which will be passed any remaining arguments to this function.
 *   The function can return any of the above formats, including a Promise.
 *
 */
export async function getRetryConfig(value, ...args) {
  if (!retryPresets) {
    retryPresets = config.has('public.retry') ? config.get('public.retry') : {};
  }
  if (typeof value === 'function') {
    value = await value(...args);
  }
  if (typeof value === 'number') {
    return { retries: value };
  }
  if (typeof value === 'string') {
    value = retryPresets[value];
  }
  return value || null;
}

let _baseURI;

/**
 * IE11 does not support `document.baseURI`. We need to search for a `<base>`
 * tag and use it if present. As an optimization, we do this only once and
 * assume it will never change. This function is only intended to work in the
 * browser!
 */
export function getBaseURI() {
  if (process.browser) {
    if (document.baseURI) {
      return document.baseURI;
    }
    if (typeof _baseURI === 'undefined') {
      // Change from `undefined` to `null` to signify that we've checked for a
      // `<base>` tag already.
      _baseURI = null;
      const base = document.querySelector('base');
      if (base) {
        const baseHref = base.getAttribute('href');
        if (baseHref) {
          _baseURI = baseHref;
        }
      }
    }
    return _baseURI || document.URL;
  }
}

const ORIGIN_PLACEHOLDER = 'https://example.tld';

function getURLObject(href) {
  return new URL(href, ORIGIN_PLACEHOLDER);
}

// Get the name of the type/class for the given input, for example: "Object",
// "String", "URLSearchParams", "Array", etc. This is a common way to build a
// more specific version of `typeof` without relying on `instanceof` or strict
// equality (which don't work across frames).
function getTypeName(obj) {
  return Object.prototype.toString.call(obj).slice(8, -1);
}

// Filter out null/undefined values from a plain object.
function filterObjectValues(obj) {
  return Object.keys(obj).reduce((output, key) => {
    const value = obj[key];
    if (value != null) {
      output[key] = value;
    }
    return output;
  }, {});
}

function forEachQueryParam(source, callback) {
  const params = new URLSearchParams(source);
  params.forEach(callback);
}

export function getQueryParametersFromURL(href) {
  const query = {};
  const url = getURLObject(href);
  forEachQueryParam(url.search, (value, key) => {
    query[key] = value;
  });
  return query;
}

/**
 * Quick URL builder that is simpler to use than `URL` and `url.format`.
 * The input `href` may include all parts of a URL including origin, pathname,
 * query params and fragment. Parameters in `query` will be added onto that
 * unless they are null/undefined. If the included `href` has no origin, a
 * relative URL is returned.
 */
export function buildURL(href, query) {
  const url = getURLObject(href);
  if (getTypeName(query) === 'Object') {
    query = filterObjectValues(query);
  }
  forEachQueryParam(query, (value, key) => {
    url.searchParams.set(key, value);
  });

  // If `href` had no origin, return a relative URL.
  if (url.origin === ORIGIN_PLACEHOLDER) {
    return `${url.pathname}${url.search}${url.hash}`;
  }
  // If `href` included an origin, return the fully qualified URL.
  return url.toString();
}

/**
 * Check whether an actual domain like `fabletics.com` or `www.fabletics.com`
 * matches a cookie domain specifier like `.fabletics.com`.
 */
export function matchCookieDomain(cookieDomain, actualDomain) {
  if (actualDomain === cookieDomain) {
    return true;
  }
  if (cookieDomain.startsWith('.')) {
    return (
      actualDomain === cookieDomain.slice(1) ||
      actualDomain.endsWith(cookieDomain)
    );
  }
  return false;
}

/** These are the headers we need to pass with errors to Raygun. */
export const errorResponseHeadersToTrack = [
  'x-tfg-tier1-request-id',
  'x-tfg-tier1-server',
  'x-amzn-requestid',
];

/**
 * Get values from supplied HTTP headers for given array of header keys
 * @return {object} object of keyed values
 */
export function getHeaderValuesByKeys(headers, headerKeys) {
  return headerKeys.reduce((keyValues, key) => {
    const value = headers.get(key);
    if (value) {
      keyValues[key] = value;
    }
    return keyValues;
  }, {});
}

/**
 * Checks if the url query parameter passed is a truthy value
 */
export function isTruthyQueryParameter(parameter) {
  return /^(true|1)$/.test(parameter);
}

/**
 * Returns the URL with no queries or fragments
 * @param {string} url
 */
export function urlNoQueryString(url) {
  const regex = /([?#])/g;
  return url.split(regex)[0];
}

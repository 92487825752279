import React from 'react';

import PropTypes from 'prop-types';
import { FaShoppingCart } from 'react-icons/fa';
import styled from 'styled-components';

import { AdminPanel } from '../../../../techstyle-shared/react-admin';
import useCart from '../useCart';
import useCartActions from '../useCartActions';

const Columns = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MainColumn = styled.div`
  flex: 1 1 auto;
  padding: 20px;
  overflow: auto;
`;

const Negative = styled.span`
  color: rgb(200, 0, 0);
`;

const Faded = styled.span`
  opacity: 0.3;
`;

export function CartDetail({ label, value, ...rest }) {
  let formattedValue = value;
  if (!value) {
    formattedValue = <Faded>&mdash;</Faded>;
  } else if (value < 0) {
    formattedValue = <Negative>{value}</Negative>;
  }
  return (
    <AdminPanel.Field label={label}>
      <span {...rest}>{formattedValue}</span>
    </AdminPanel.Field>
  );
}

CartDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
};

const CartActions = styled.div`
  padding: 12px;

  form {
    display: flex;
    align-items: center;
    margin: 10px 0;

    > :not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const ActionButton = styled(AdminPanel.Button)`
  flex: 0 0 auto;
`;

const ActionInput = styled.input`
  width: 100%;
  border: 1px solid #bbb;
  padding: 3px 5px;
  font-size: 13px;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1);
`;

export default function CartAdminPanel() {
  const cart = useCart();
  const actions = useCartActions();

  const refreshCart = () => {
    actions.invalidateCart();
  };

  const addItem = (event) => {
    event.preventDefault();
    const form = event.target;
    const { productId } = form.elements;
    actions.addItemToCart(parseInt(productId.value, 10));
  };

  const removeItem = (event) => {
    event.preventDefault();
    const form = event.target;
    const { lineId } = form.elements;
    actions.removeCartLineItem(parseInt(lineId.value, 10));
  };

  const removeProduct = (event) => {
    event.preventDefault();
    const form = event.target;
    const { productId } = form.elements;
    actions.removeProductFromCart(parseInt(productId.value, 10));
  };

  return (
    <AdminPanel id="cart" label="Cart" icon={FaShoppingCart}>
      <Columns>
        <AdminPanel.Sidebar title="Cart Actions" width={250}>
          <CartActions>
            <ActionButton type="button" onClick={refreshCart}>
              Load Cart
            </ActionButton>
            <form name="addItem" onSubmit={addItem}>
              <ActionInput name="productId" size={6} placeholder="Product ID" />
              <ActionButton type="submit">Add Product</ActionButton>
            </form>
            <form name="removeProduct" onSubmit={removeProduct}>
              <ActionInput name="productId" size={6} placeholder="Product ID" />
              <ActionButton type="submit">Remove Product</ActionButton>
            </form>
            <form name="removeItem" onSubmit={removeItem}>
              <ActionInput name="lineId" size={6} placeholder="Line Item ID" />
              <ActionButton type="submit">Remove Line Item</ActionButton>
            </form>
          </CartActions>
        </AdminPanel.Sidebar>
        <AdminPanel.Sidebar title="Cart Summary" width={230}>
          <AdminPanel.Fields rowGap={0} valueWidth="auto">
            <CartDetail value={cart.subtotal} label="Subtotal:" />
            <CartDetail value={cart.tax} label="Tax:" />
            <CartDetail value={cart.shipping} label="Shipping:" />
            <CartDetail value={-cart.credit} label="Credit:" />
            <CartDetail
              value={-cart.subtotalDiscount}
              label="Discount Subtotal:"
            />
            <CartDetail
              value={-cart.shippingDiscount}
              label="Shipping Discount:"
            />
            <CartDetail value={-cart.discount} label="Discount Total:" />
            <CartDetail
              value={cart.total}
              label="TOTAL:"
              style={{ fontWeight: 'bold' }}
            />
            <AdminPanel.Separator />
            <CartDetail value={cart.itemCount} label="Item Count:" />
            <CartDetail value={cart.quantity} label="Quantity:" />
          </AdminPanel.Fields>
        </AdminPanel.Sidebar>
        <MainColumn>
          <AdminPanel.DataTable data={cart} title="Cart" />
        </MainColumn>
      </Columns>
    </AdminPanel>
  );
}

import React from 'react';

import { builder } from '@builder.io/react';
import * as Sentry from '@sentry/browser';
import config from 'config';

import errorCodePageMap from './errorCodePageMap';
import googleTagManagerEvents from './googleTagManagerEvents';
import routes from './routes';
import segmentEvents from './segmentEvents';
import { isBuilderPreview } from './src/builder/utils/isBuilderPreview';
import {
  adminExtension,
  deviceDetectionExtension,
  fabWelcomeModalExtension,
  fontStackExtension,
  liveChatExtension,
  miniCartContextExtension,
  pageReloadExtension,
  pageTransitionExtension,
  referringPageExtension,
  pageScrollRestorationExtension,
  fableticsPageExtension,
  taggstarExtension,
  urlRedactorExtension,
  secondarySiteExtension,
  denimInterestModalExtension,
} from './src/extensions';
import usePageCountReducer from './src/grid/utils/usePageCountReducer';
import FableticsTrackingVariables from './src/layout/components/FableticsTrackingVariables';
import { getBranchUrlParams } from './src/layout/utils/getBranchUrlParams';
import { getFooterNavHandle } from './src/layout/utils/navHandles';
import { saveSessionOrCustomerDetail } from './src/layout/utils/saveSessionOrCustomerDetail';
import ConstructorIoBeaconSnippet from './src/shared/components/ConstructorIoBeaconSnippet';
import featureNames from './src/shared/constants/featureFlags';
import { getSecondarySite } from './src/shared/utils/getSecondarySite';
import { redirect } from './src/shared/utils/routing';
import truthyQueryParameter from './src/shared/utils/truthyQueryParameter';
import theme from './src/styles/theme';
import {
  dynamicRoutesExtension,
  routesExtension,
  Router,
} from './src/techstyle-shared/next-routes';
import {
  errorLoggingExtension,
  errorRedirectExtension,
  createRegistry,
} from './src/techstyle-shared/next-server';
import { abTestExtension } from './src/techstyle-shared/react-abtest';
import {
  accountsExtension,
  autoLoginExtension,
  gmsLoginExtension,
  loadCopsSegmentTraits,
} from './src/techstyle-shared/react-accounts';
import { adminExtension as reactAdminExtension } from './src/techstyle-shared/react-admin';
import { assetsExtension } from './src/techstyle-shared/react-assets';
import { browserSupportExtension } from './src/techstyle-shared/react-browser-support';
import { cartExtension } from './src/techstyle-shared/react-cart';
import { styledComponentsExtension } from './src/techstyle-shared/react-components';
import { featuresExtension } from './src/techstyle-shared/react-features';
import { apolloExtension } from './src/techstyle-shared/react-graphql';
import { intlExtension } from './src/techstyle-shared/react-intl';
import {
  marketingExtension,
  GoogleTagManagerSnippet,
  GoogleTagManagerVariables,
  OneTrustSnippet,
  pageTrackingExtension,
  SegmentSnippet,
  SegmentGTMVariables,
  SentrySnippet,
  LoggedInPageView,
  OneTrustSetupWithSegment,
} from './src/techstyle-shared/react-marketing';
import { navExtension } from './src/techstyle-shared/react-navigation';
import { performanceExtension } from './src/techstyle-shared/react-performance';
import { productsExtension } from './src/techstyle-shared/react-products';
import { promosExtension } from './src/techstyle-shared/react-promos';
import { retailStoresExtension } from './src/techstyle-shared/react-retail-stores';
import { searchExtension } from './src/techstyle-shared/react-search';
import {
  reduxExtension,
  loadSession,
  VisitorStatus,
  configureStore,
} from './src/techstyle-shared/redux-core';

builder.init(config.public.builderApi.key);
builder.apiEndpoint = 'content';
const registry = createRegistry();

registry.register(performanceExtension());
registry.register(errorLoggingExtension());
registry.register(
  errorRedirectExtension({
    errorCodeRules: errorCodePageMap,
  })
);
registry.register(
  reduxExtension(
    configureStore,
    {
      getSecondarySite: getSecondarySite,
    },
    {
      enableContentPreview: isBuilderPreview,
    }
  )
);
registry.register(deviceDetectionExtension());
registry.register(
  apolloExtension({
    errorCodeRules: errorCodePageMap,
    clearCacheOnLogin: true,
  })
);
registry.register(pageReloadExtension());
registry.register(
  dynamicRoutesExtension({
    routeTypePageMap: {
      SALE: '/sale',
    },
    builderRoutePageMap: {
      account: '/builder/account-page',
      page: '/builder/page',
      'landing-page': '/builder/landing-page',
      'category-page': '/builder/category-page',
      'collection-page': '/builder/collection-page',
      'sale-page': '/builder/sale-page',
      'edit-symbol': '/builder/edit-symbol',
      outlet: '/builder/outlet',
      'mobile-app/edit-header': '/builder/mobile-app/edit-header',
      'mobile-app/edit-screen': '/builder/mobile-app/edit-screen',
    },
    handleRouteInfo({ ctx, routeInfo }) {
      // if there is EXACTLY 1 alias for this route, replace the current route with it
      // this maps EU countries to their translated urls
      // i.e. replaces /womens/bottoms with /damen/hosen for example
      if (
        routeInfo?.aliasList?.length === 1 &&
        routeInfo.aliasList[0] !== routeInfo.canonicalPath
      ) {
        if (ctx.res) {
          const { altoken, alkey, alemail } = ctx.query;
          let routeUrl = routeInfo.aliasList[0];
          if (ctx.query.alemail !== undefined) {
            routeUrl += `?alemail=${alemail}`;
          } else if (ctx.query.alkey !== undefined) {
            routeUrl += `?alkey=${alkey}&altoken=${altoken}`;
          }
          ctx.res.writeHead(302, { Location: routeUrl });
          ctx.res.end();
        } else {
          Router.push(routeInfo.aliasList[0]);
        }
      }
    },
  })
);

registry.register(routesExtension({ routes }));
registry.register(
  autoLoginExtension({
    onAutoLoginError({ ctx, type }) {
      const secondarySite = getSecondarySite(ctx);
      const prefix = secondarySite ? `/${secondarySite}` : '';
      const excludedPaths =
        config.get('public.autoLoginExcludedRedirectPaths') || [];
      if (!excludedPaths.includes(ctx.pathname)) {
        redirect({
          res: ctx.res,
          url: `${prefix}/login?alError=${type}`,
        });
      }
    },
  })
);
registry.register(urlRedactorExtension());
registry.register(gmsLoginExtension());
registry.register(reactAdminExtension());
registry.register(
  intlExtension({
    globalResourceBundles: [
      'app_global',
      'byob',
      'cart_summary',
      'fl_sustainability_banner',
      'fl_vip_popup',
      'global_checkout',
      'global_cta',
      'global_login',
      'member_box',
      'minicart',
      'nav_banners',
      'privacy',
      'quiz',
      'search_autosuggest',
      'search_results',
      'site_login',
      'site_navigation',
      'site_seo_description',
      'site_seo_title',
      'site_skinny_banner',
      'site_skinny_banners',
      'terms',
      'vip_treatment',
    ],
  })
);
registry.register(fableticsPageExtension());
registry.register(abTestExtension());
registry.register(productsExtension());
registry.register(retailStoresExtension());
registry.register(searchExtension());
registry.register(liveChatExtension());
registry.register(promosExtension({ firstHourRefresh: true }));
registry.register(
  accountsExtension({
    globalPrefetchCustomerDetails: [
      'gender',
      'isScrubs',
      'is_yitty',
      'quiz_offer',
      'shipping_zip',
      'utm_term',
      'top-size',
      'bottom-size',
      'bra-size',
      'onetrust_consent',
      'outlet_site_access_code',
    ],
  })
);
registry.register(browserSupportExtension());
registry.register(cartExtension());
registry.register(miniCartContextExtension());
registry.register(
  styledComponentsExtension(theme, { enableGlobalFontFaces: false })
);
registry.register(pageTransitionExtension());
registry.register(denimInterestModalExtension());
registry.register(
  assetsExtension({
    async globalPrefetchAssets(ctx) {
      await ctx.store.dispatch(loadSession());
      const { session } = ctx.store.getState();
      const isLoggedIn = session.visitorStatus === VisitorStatus.LOGGED_IN;
      return [
        'meta_nav_image_offer',
        'skinny_banner',
        'skinny_banner_mobile',
        !isLoggedIn && 'MensLogin_Landing_Desktop_Modal_Banner',
        // TODO: after https://jira.techstyle.net/browse/YIT-1473 is merged, remove _Yitty
        !isLoggedIn && config.get('public.brand.loginModalBanner'),
        'mini_cart_top_cta_button',
        'mini_cart_bottom_cta_button',
        'reward_cashback_minicart_bg',
        'VIP_Treatment_Icon_Skip',
        'VIP_Treatment_Icon_Sweat',
        'VIP_Treatment_Icon_Save',
        'PFA_Video_Action_Hero',
        'PFA_Review_Action_Hero',
        'PFA_Spend50_Action_Hero',
        'PFA_App_Action_Hero',
        'PFA_All_Actions_Action_Hero',
      ];
    },
  })
);
registry.register(
  navExtension({
    globalPrefetchNavs(ctx) {
      const {
        intl: { region },
        session,
      } = ctx.store.getState();
      const isLoggedIn = session.visitorStatus === VisitorStatus.LOGGED_IN;
      return [getFooterNavHandle({ region }), isLoggedIn && 'my_account'];
    },
  })
);
registry.register(
  featuresExtension({
    globalPrefetchFeatures: [
      featureNames.SCRUBS,
      'yitty_checkout_interstitial',
      'fl_removed_checkout_interstitial',
      featureNames.POSTREG_TIMER,
      featureNames.ALLOW_FRONTEND_LOGGING,
      featureNames.BUILD_YOUR_OWN_BUNDLE,
      featureNames.BOUNCEBACK_ENDOWMENT,
      featureNames.USE_PRICING_SERVICE,
      featureNames.PASSWORDLESS_LOGIN,
      featureNames.PASSWORDLESS_SIGNUP,
      featureNames.COGNIGY_WEB_CHAT,
      featureNames.WAREHOUSE_OUTLET_SITE,
      featureNames.HDYH_TIERED_LIST,
      featureNames.SHOP_BY_COLOR,
      featureNames.SHOULD_USE_IMAGE_SERVICE_URL,
      featureNames.INCENTIVIZED_REVIEWS_UI,
    ],
  })
);
registry.register(secondarySiteExtension());
registry.register(fabWelcomeModalExtension());
registry.register(pageScrollRestorationExtension());
registry.register(taggstarExtension());
registry.register(
  marketingExtension({
    async getRequestInfo(ctx) {
      const { tld } = ctx.req.context.domain;
      const oneTrustConfig = config.get('public.oneTrust');
      const overrideGeolocationOneTrust = oneTrustConfig.overrideGeolocation;

      const {
        query: { fromMobileApp, branchLink },
      } = ctx.req;

      await ctx.store.dispatch(loadSession());
      const { session } = ctx.store.getState();

      await ctx.store.dispatch(loadCopsSegmentTraits()).catch((error) => {
        // if the call returns with an error, we don't want to break the page
        Sentry.captureException(error);
      });

      let trackingParams = {
        ...(truthyQueryParameter(fromMobileApp)
          ? { fromMobileApp: true }
          : null),
      };
      if (branchLink) {
        const branchData = await getBranchUrlParams({ tld, value: branchLink });
        trackingParams = {
          ...trackingParams,
          ...branchData,
        };
      }

      const utmTerm = ctx.req.query?.utm_term;
      if (utmTerm) {
        await saveSessionOrCustomerDetail({
          detailKey: 'utm_term',
          detailValue: utmTerm,
          isLoggedIn: session.visitorStatus === VisitorStatus.LOGGED_IN,
          dispatch: ctx.store.dispatch,
        });
      }

      return {
        trackingParams,
        oneTrustApiKey:
          config.get('public.oneTrust.apiKeys')[tld] === undefined &&
          oneTrustConfig.fallbackTld
            ? config.get('public.oneTrust.apiKeys')[oneTrustConfig.fallbackTld]
            : config.get('public.oneTrust.apiKeys')[tld],
        isOneTrustEnabled:
          oneTrustConfig.forceEnableOneTrust ||
          config
            .get('public.oneTrust.enabledCountries')
            .includes(ctx.req.context.geoCountry),
        oneTrustGeoResponse: overrideGeolocationOneTrust
          ? {
              countryCode: ctx.req.context.geoCountry,
              stateCode: ctx.req.context.geoState,
            }
          : undefined,
        sentryEnabled: config.get('public.sentry.enabled'),
      };
    },
    head(requestInfo) {
      return (
        <>
          {requestInfo.oneTrustApiKey && requestInfo.isOneTrustEnabled && (
            <OneTrustSnippet
              apiKey={requestInfo.oneTrustApiKey}
              geoResponse={requestInfo.oneTrustGeoResponse}
            />
          )}
          {requestInfo.sentryEnabled && <SentrySnippet />}
        </>
      );
    },
    beforeContent(requestInfo) {
      const gtmContainerId = config.get('public.googleTagManager.containerId');
      return (
        <>
          {requestInfo.oneTrustApiKey && requestInfo.isOneTrustEnabled && (
            <OneTrustSetupWithSegment />
          )}
          {gtmContainerId && (
            <>
              <GoogleTagManagerVariables />

              <SegmentGTMVariables />
            </>
          )}
          <GoogleTagManagerSnippet.Setup />
          <FableticsTrackingVariables />
          {(!requestInfo.oneTrustApiKey || !requestInfo.isOneTrustEnabled) && (
            <>
              <SegmentSnippet />
            </>
          )}
          <LoggedInPageView />
        </>
      );
    },
    afterContent(requestInfo) {
      return (
        <>
          <ConstructorIoBeaconSnippet />
        </>
      );
    },
    afterNextScript(requestInfo) {
      const gtmContainerId = config.get('public.googleTagManager.containerId');
      return (
        <>
          {gtmContainerId && <GoogleTagManagerSnippet />}
          {gtmContainerId && <GoogleTagManagerSnippet.NoScript />}
        </>
      );
    },
    googleTagManagerEvents,
    segmentEvents,
    referringPageCookieName: 'referring_page_selection',
  })
);

registry.register(
  pageTrackingExtension({
    cookieName: 'FL-GENERAL-PAGE_VIEW_COUNT',
    usePageCountReducer,
  })
);

registry.register(adminExtension());

registry.register(referringPageExtension());

registry.register(fontStackExtension());

export default registry;

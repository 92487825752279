import retailStoresModules from './retailStoresModule';

export default function retailStoresExtension(options = {}) {
  return {
    id: 'retailstores',
    redux: {
      modules: [retailStoresModules],
    },
  };
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useSession } from '../../../../techstyle-shared/redux-core';
import Button from '../Button';
import MoreColorsLabel from '../MoreColorsLabel';
import ProductCell from '../ProductCell';
import ProductImage from '../ProductImage';
import ProductName from '../ProductName';
import ProductPrice from '../ProductPrice';
import ProductReview from '../ProductReview';
import WishlistToggleButton from '../WishlistToggleButton';

const ProductLink = styled(Button).attrs(() => ({
  variant: 'other',
}))`
  display: block;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

const Wrapper = styled(ProductCell)`
  position: relative;
  font-size: 14px;
  text-align: left;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const ProductSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0;

  & > *:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Price = styled(ProductPrice)`
  display: block;
  margin-bottom: 4px;
`;

const Name = styled(ProductName)`
  display: block;
`;

const AdditionalContent = styled.div`
  margin-top: 16px;
`;

const DefaultProductCell = React.forwardRef(
  (
    {
      children,
      product,
      imageAspectRatio,
      imageType,
      href,
      reviewsHref,
      additionalContentHref,
      ...rest
    },
    ref
  ) => {
    const { isLoggedIn } = useSession();
    return (
      <Wrapper {...rest} product={product} ref={ref}>
        <ProductLink href={href} aria-label={product.label}>
          <ProductImage
            aspectRatio={imageAspectRatio}
            type={imageType}
            aria-label={product.label}
            role="img"
          />
        </ProductLink>

        {isLoggedIn ? (
          <ImageOverlay>
            <WishlistToggleButton />
          </ImageOverlay>
        ) : null}

        <ProductSection>
          <ProductLink href={href} tabIndex={-1}>
            <Price />
            <Name />
          </ProductLink>
          <ProductLink
            href={reviewsHref}
            tabIndex={reviewsHref === href ? -1 : undefined}
          >
            <ProductReview />
          </ProductLink>
          <MoreColorsLabel href={href} tabIndex={-1} />
        </ProductSection>
        {children ? (
          <AdditionalContent>
            <ProductLink
              href={additionalContentHref || href}
              tabIndex={additionalContentHref ? undefined : -1}
            >
              {children}
            </ProductLink>
          </AdditionalContent>
        ) : null}
      </Wrapper>
    );
  }
);

DefaultProductCell.displayName = 'DefaultProductCell';

DefaultProductCell.propTypes = {
  additionalContentHref: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
  imageAspectRatio: PropTypes.number,
  imageType: PropTypes.oneOf(['default', 'plus']),
  product: PropTypes.object.isRequired,
  reviewsHref: PropTypes.string.isRequired,
  showWishlistOverlay: PropTypes.bool,
};

export default DefaultProductCell;

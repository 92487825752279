import React from 'react';

import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

import { useSession } from '../../../techstyle-shared/redux-core';

const withHooksHOC = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const { isAdmin } = useSession();
    return <Component isAdmin={isAdmin} {...props} />;
  };
};

/* as of the current react version (18.2.0), error boundaries are only supported 
by class based components and not hooks. If hook support is added in the future, 
we can refactor this into a functional hook component */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
    fallbackComponent: PropTypes.elementType,
    isAdmin: PropTypes.bool,
  };

  static defaultProps = {
    fallbackComponent: null,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
    if (this.props.isAdmin) {
      // eslint-disable-next-line no-console
      console.error(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent;
    }

    return this.props.children;
  }
}

export default withHooksHOC(ErrorBoundary);

function isValidNumber(number) {
  return !Number.isNaN(number) && Number.isFinite(number);
}

/**
 * Retrieve the initial filter, sort, and mySize values
 */
// * baseFilters is the same data structure as productJson
export function parseProductListingQueryParams(
  query,
  { baseFilters, filterSettings, sortOptions }
) {
  // * Accept query string object as well as an instance of URLSearchParams
  const urlParams = new URLSearchParams(query);
  const baseAggregationFilter = { ...baseFilters.aggregationFilter } || {};

  const filtersFromURLParams = filterSettings.reduce(
    (filtersAcc, filterSetting) => {
      if (filterSetting.fromUrlParams) {
        return filterSetting.fromUrlParams({
          baseFilters,
          filters: filtersAcc,
          filterSetting,
          urlParams,
        });
      }

      const key = filterSetting.field;
      const baseValues = baseAggregationFilter[key] || [];
      const valueString = urlParams.get(key);
      if (valueString) {
        const mergedValues = baseValues.concat(
          valueString.split(',').filter(Boolean)
        );
        const uniqueValues = new Set(mergedValues);
        filtersAcc.aggregationFilter[key] = Array.from(uniqueValues);
      } else {
        filtersAcc.aggregationFilter[key] = baseValues;
      }
      return filtersAcc;
    },
    { aggregationFilter: {} }
  );

  const baseCategoryIds = baseFilters.categoryIds || [];
  const mergedCategoryIds = baseCategoryIds.concat(
    (urlParams.get('category_ids') || '')
      .split(',')
      .map((id) => parseInt(id, 10))
      .filter(Number.isFinite)
  );
  const uniqueCategoryIds = new Set(mergedCategoryIds);
  const categoryIds = Array.from(uniqueCategoryIds);

  let defaultTagId = baseFilters.defaultTagId;
  const defaultTagIdString = urlParams.get('defaultTagId');
  if (defaultTagIdString) {
    const parsedDefaultTagId = parseInt(defaultTagIdString, 10);
    if (isValidNumber(parsedDefaultTagId)) {
      defaultTagId = parsedDefaultTagId;
    }
  }

  const sortOption = sortOptions.find(
    (option) => option.value === urlParams.get('sort')
  );

  const initialFilters = {
    ...baseFilters,
    ...filtersFromURLParams,
    categoryIds,
    defaultTagId,
  };

  // * my_size determines whether products are auto filtered by the user's size
  const mySize = urlParams.get('my_size') !== 'false';

  return {
    filters: initialFilters,
    sortOption,
    mySize,
  };
}

/**
 * Creates a URLSearchParams object instance based on the filters and sort
 */
export function createProductListingQueryParams({
  autoAppliedFilters,
  baseFilters,
  filters,
  filterSettings,
  sortOption,
  mySize = true,
}) {
  const baseAggregationFilter = baseFilters.aggregationFilter || {};

  const { aggregationFilter = {}, categoryIds = [], defaultTagId } = filters;

  let params = {};

  // URLSearchParams instances are ordered, so add the params to this object in
  // the order we wish them to appear in the URL. Currently this is:
  // 1. defaultTagId (added for Curvy page on SXF)
  // 2. category_ids
  // 3. aggregation fields
  // 4. avg_review_min
  // 5. my_size
  // 6. sort
  if (defaultTagId != null) {
    if (defaultTagId !== baseFilters.defaultTagId) {
      params.defaultTagId = defaultTagId.toString();
    }
  }

  if (categoryIds.length) {
    const baseCategoryIds = new Set(baseFilters.categoryIds);
    const paramCategoryIds = categoryIds.filter(
      (value) => !baseCategoryIds.has(value)
    );
    if (paramCategoryIds.length) {
      params.category_ids = paramCategoryIds.sort().join(',');
    }
  }

  //  TODO: There's some unnecessary iteration here to make sure that aggregations
  // appear before avgReviewMin and price. However, if the order of the URL params
  // is supposed to otherwise match the order that filters appear, we could just include
  // these based on the order of 'filterSettings'.
  baseFilters.aggregations.forEach((aggregation) => {
    const key = aggregation.field;
    const values = aggregationFilter[key];
    // If a filter has been automatically applied (like a saved size), don't
    // include it in the URL params.
    if (values && values.length && !autoAppliedFilters?.[key]) {
      const baseValues = new Set(baseAggregationFilter[key]);
      const paramValues = values.filter((value) => !baseValues.has(value));
      if (paramValues.length) {
        params[key] = paramValues.sort().join(',');
      }
    }
  });

  filterSettings.forEach((filterSetting) => {
    if (filterSetting.toUrlParams) {
      params = filterSetting.toUrlParams({
        autoAppliedFilters,
        baseFilters,
        filters,
        filterSetting,
        urlParams: params,
      });
    }
  });

  // * only include my_size param if falsy (profile size filtering should be disabled)
  if (!mySize) {
    params.my_size = 'false';
  }

  if (sortOption && !sortOption.isDefault) {
    params.sort = sortOption.value;
  }

  return new URLSearchParams(params);
}

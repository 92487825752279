import React from 'react';

// Why does the section level default to `null` instead of a value like 1?
// If there is no `<Section>` ancestor, we want the default assumption for
// elements like `<Heading>` to be that they're level 2. This matches ARIA
// heading role behavior. But when you do add a `<Section>`, the first output
// level should also be 2, not incremented yet. This is to discourage people
// from rendering level 1 headings, which should be rare (i.e. reserved for the
// title of the page).
//
// This behavior means we need to distinguish between having a `<Section>`
// ancestor and not having one.
export default React.createContext(null);

import React, { useCallback, useMemo } from 'react';

import { useRouter } from 'next/router';
import { FaChevronDown } from 'react-icons/fa';
import styled from 'styled-components';

import { comparePathToUrl } from '../../../../techstyle-shared/react-navigation';
import type {
  PathHierarchy,
  RouteMap,
} from '../../../../techstyle-shared/react-navigation';
import { useDomain } from '../../../../techstyle-shared/redux-core';
import { NavItem } from '../AccountLinks/AccountLinks';
import Dropdown from '../Dropdown';
import NavLink from '../NavLink';
import getNavItemHref from '../utils/getNavItemHref';

const Content = styled.div``;
const DropdownButton = styled(Dropdown.Button)``;
const DropdownList = styled(Dropdown.List)``;
const DropdownListItem = styled(Dropdown.ListItem)``;

const DropdownButtonContent = styled.span`
  flex: 1 0 auto;
`;

const Chevron = styled(FaChevronDown)<{ $direction: 'up' | 'down' }>`
  flex: 0 0 10px;
  transform: rotate(
    ${({ $direction }: any) => ($direction === 'up' ? 180 : 0)}deg
  );
  transform-origin: 50% 50%;
`;

interface ButtonProps {
  hideCaret?: boolean;
}

function Button({
  children,
  hideCaret = false,
  ...rest
}: React.PropsWithChildren<ButtonProps>) {
  const [dropdownState] = Dropdown.useDropdownContext();
  return (
    <DropdownButton {...rest}>
      <DropdownButtonContent>{children}</DropdownButtonContent>
      {!hideCaret && (
        <Chevron $direction={dropdownState.isOpened ? 'up' : 'down'} />
      )}
    </DropdownButton>
  );
}

type DropdownNavNavItem = NavItem & {
  value: string;
  currentPathHierarchy: PathHierarchy;
};
interface DropdownNavProps {
  navItems: NavItem[];
  groupCode?: string;
  onClickNavLink?: (navItem?: DropdownNavNavItem) => void;
  routeMappings?: RouteMap;
  title: string;
  value: string;
}

function DropdownNav({
  groupCode,
  navItems,
  routeMappings,
  title,
  onClickNavLink,
  ...rest
}: DropdownNavProps) {
  const router = useRouter();
  const { tld } = useDomain();
  const dropdownNavItems = useMemo(
    () =>
      navItems.map((navItem: NavItem) => {
        return {
          ...navItem,
          value: getNavItemHref({ url: navItem.url, tld, routeMappings }),
          currentPathHierarchy: comparePathToUrl(router.pathname, navItem.url),
        };
      }),
    [navItems, routeMappings, router.pathname, tld]
  ) as DropdownNavNavItem[];

  const handleNavItemClick = useCallback(
    (nextSelectedItem: DropdownNavNavItem) => {
      if (onClickNavLink) {
        onClickNavLink(nextSelectedItem);
      }
      router.push(nextSelectedItem.value);
    },
    [onClickNavLink, router]
  );

  return (
    <Dropdown
      options={dropdownNavItems}
      onChange={handleNavItemClick}
      {...rest}
    >
      <Content>
        <Button>{title}</Button>
        <DropdownList>
          {dropdownNavItems.map((navItem: DropdownNavNavItem) => (
            <DropdownListItem value={navItem.value} key={navItem.id}>
              <NavLink
                label={navItem.label}
                tagName="span"
                groupCode={groupCode}
                isResourceBundle={navItem.isResourceBundle}
                currentPathHierarchy={navItem.currentPathHierarchy}
              />
            </DropdownListItem>
          ))}
        </DropdownList>
      </Content>
    </Dropdown>
  );
}

DropdownNav.Content = Content;
DropdownNav.List = DropdownList;
DropdownNav.ListItem = DropdownListItem;
DropdownNav.Link = NavLink;
DropdownNav.Button = DropdownButton;
DropdownNav.Chevron = Chevron;

export default DropdownNav;

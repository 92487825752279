import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { mobile } from '../styles';

const Wrapper = styled.div`
  ${({ offset }) => css`
    padding-top: ${offset}px;
    margin-top: -${offset}px;
  `};

  ${mobile`
    ${({ mobileOffset }) => css`
      padding-top: ${mobileOffset}px;
      margin-top: -${mobileOffset}px;
    `};
  `};
`;

/**
 * This component is used to wrap scrollable targets. It will account for the
 * fixed header of the page, so the header won't cover the element after scrolling.
 */
const ScrollOffset = forwardRef(
  ({ children, offset, mobileOffset, ...rest }, ref) => {
    return (
      <Wrapper ref={ref} offset={offset} mobileOffset={mobileOffset} {...rest}>
        {children}
      </Wrapper>
    );
  }
);

ScrollOffset.displayName = 'ScrollOffset';

ScrollOffset.propTypes = {
  children: PropTypes.node,
  mobileOffset: PropTypes.number,
  offset: PropTypes.number,
};

export default ScrollOffset;

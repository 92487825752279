import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  azureValidate,
  azureInvalidate,
  oktaVerify,
  oktaLogout,
} from './adminModule';

export default function useAdminActions() {
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return bindActionCreators(
      { azureValidate, azureInvalidate, oktaVerify, oktaLogout },
      dispatch
    );
  }, [dispatch]);
  return actions;
}

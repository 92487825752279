import chalk from 'chalk';
import config from 'config';
import jwtDecode from 'jwt-decode';
import { createHarLog } from 'node-fetch-har';
import urlTemplate from 'url-template';

import logger from './logger';

const debug = logger.extend('bentoApiExpressMiddleware');

export function getStoreDomain(url, domain, template) {
  if (typeof template === 'string') {
    template = urlTemplate.parse(template);
  }
  const values = {
    href: url.href,
    origin: url.origin,
    protocol: url.protocol,
    username: url.username,
    password: url.password,
    host: url.host,
    hostname: url.hostname,
    port: url.port,
    pathname: url.pathname,
    search: url.search,
    hash: url.hash,
    subdomain: domain.subdomain,
    name: domain.name,
    tld: domain.tld,
  };
  return template.expand(values);
}

/**
 * We can't verify the JWT without a secret key, but we can at least check what
 * claims are there. This will return true if there is an `adminAuth` claim.
 * The final check for whether to send any resulting HAR logs happens after all
 * calls have been made, by checking the session `isAdmin` flag.
 */
export function detectMaybeAdmin(req) {
  const sessionCookie = config.get('public.bentoApi.sessionCookie');
  const tokenValue = req.cookies[sessionCookie];
  if (tokenValue) {
    try {
      const claims = jwtDecode(tokenValue);
      return Boolean(claims.adminAuth);
    } catch (err) {
      return false;
    }
  }
  return false;
}

function getConfigStoreDomain() {
  if (config.has('server.domain.storeDomain')) {
    // eslint-disable-next-line no-console
    console.warn(`${chalk.yellow(
      '!'
    )} Config key server.domain.storeDomain is deprecated in favor of server.bentoApi.storeDomain.
  Please migrate; the old config key will no longer be read in the future.`);
  }
  if (config.has('server.bentoApi.storeDomain')) {
    return config.get('server.bentoApi.storeDomain');
  }
  return config.get('server.domain.storeDomain');
}

export default function bentoApiExpressMiddleware({
  recordHarLogs = config.has('server.bentoApi.recordHarLogs')
    ? config.get('server.bentoApi.recordHarLogs')
    : false,
  storeDomain = getConfigStoreDomain(),
  beforeRequestHooks = [],
  afterResponseHooks = [],
  enableContentPreview,
} = {}) {
  const storeDomainTemplate = urlTemplate.parse(storeDomain); // Parse once.
  return (req, res, next) => {
    if (!req.context.isInternalRoute) {
      const { url, domain } = req.context;
      let shouldRecordHarLogs = false;
      let shouldSendHarLogs = (ctx) => false;
      if (recordHarLogs) {
        if (recordHarLogs === true) {
          debug('HAR logging is enabled for all requests.');
          shouldRecordHarLogs = true;
          shouldSendHarLogs = () => true;
        } else if (
          recordHarLogs.secretKey &&
          req.get('x-har-secret-key') === recordHarLogs.secretKey
        ) {
          debug('HAR logging activated by incoming request headers.');
          shouldRecordHarLogs = true;
          shouldSendHarLogs = (ctx) => true;
        } else if (recordHarLogs.enableForAdmins) {
          // We can't verify the JWT claims without knowing the secret key,
          // which we'd rather not worry about in the React app. So let's
          // *record* HAR logs if there's an `adminAuth` claim in the
          // `tos_token` JWT (which might have been tampered with), and only
          // *send* them if we get the `isAdmin` flag on the session. We could
          // just record HAR logs for every request and still only send them for
          // admins, but this is an optimization to cut down on work for known
          // non-admins.
          const isMaybeAdmin = detectMaybeAdmin(req);
          if (isMaybeAdmin) {
            debug('HAR logging activated by adminAuth claim in tos_token.');
            shouldRecordHarLogs = true;
            shouldSendHarLogs = (ctx) => {
              const { session } = ctx.store.getState();
              const isAdmin = Boolean(session.isAdmin);
              if (isAdmin) {
                debug(
                  'HAR logs will be sent: session set the isAdmin flag, confirming adminAuth claim.'
                );
              } else {
                debug(
                  'HAR logs will not be sent: session did not set the isAdmin flag despite adminAuth claim.'
                );
              }
              return isAdmin;
            };
          }
        }
      }
      if (shouldRecordHarLogs) {
        req.context.har = createHarLog([], { title: url.href });
        req.context.shouldSendHarLogs = shouldSendHarLogs;
      } else {
        debug('HAR logging is disabled.');
      }
      req.context.storeDomain = getStoreDomain(
        url,
        domain,
        storeDomainTemplate
      );
      // Keep track of cookies received from the API by name.
      req.context.setCookies = new Map();

      req.context.bentoApi = {
        beforeRequestHooks,
        afterResponseHooks,
        enableContentPreview,
      };

      // Store the session JWT here. It can potentially update across API calls,
      // and if there are multiple API clients (like Tier1 and GraphQL), they
      // will all need to stay in sync. We don't initialize `sessionToken` here
      // even though we could read the incoming cokoies, because we don't know
      // the final value of `anonymousServerSession` yet.
      let sessionToken;
      req.context.readSessionToken = () => sessionToken;
      req.context.updateSessionToken = (newToken) => {
        sessionToken = newToken;
      };
    }
    next();
  };
}

import React from 'react';

import PropTypes from 'prop-types';

import Collapsible from '../Collapsible';
import CollapsibleGroup from '../CollapsibleGroup';

const InfoContent = ({ children, ...rest }) => {
  const { isOpen } = Collapsible.useState();
  if (isOpen) {
    return null;
  }
  return <div {...rest}>{children}</div>;
};

InfoContent.propTypes = {
  children: PropTypes.node,
};

const AccountInfoPanel = (props) => (
  <Collapsible defaultOpen={false} {...props} />
);

const EditContent = (props) => (
  <Collapsible.Content unmountOnExit mountOnEnter {...props} />
);

export default function AccountInfoPanels(props) {
  return <CollapsibleGroup allowMultipleOpen={false} {...props} />;
}

AccountInfoPanels.InfoPanel = AccountInfoPanel;
AccountInfoPanels.EditContent = EditContent;
AccountInfoPanels.Button = Collapsible.Button;
AccountInfoPanels.InfoContent = InfoContent;

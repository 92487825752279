import React, { useContext } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export const useProductBrowser = () => {
  return useContext(Context);
};

export default function ProductBrowserContext({ children, value }) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ProductBrowserContext.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};

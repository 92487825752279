import React, { useState, useMemo, useContext } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div``;

export const CollapsibleGroupContext = React.createContext();

export function useCollapsibleGroupState() {
  return useContext(CollapsibleGroupContext);
}

/**
 * Use this to prevent any nested Collapsibles from thinking they're part of any
 * parent CollapsibleGroup when nested.
 */
export function UnsetGroup({ children }) {
  return (
    <CollapsibleGroupContext.Provider value={null}>
      {children}
    </CollapsibleGroupContext.Provider>
  );
}

UnsetGroup.propTypes = {
  children: PropTypes.node,
};

export default function CollapsibleGroup({
  allowMultipleOpen = false,
  children,
  ...rest
}) {
  const [openId, setOpenId] = useState();

  const context = useMemo(
    () => ({
      allowMultipleOpen,
      openId,
      setOpenId,
    }),
    [allowMultipleOpen, openId]
  );

  return (
    <Wrapper {...rest}>
      <CollapsibleGroupContext.Provider value={context}>
        {children}
      </CollapsibleGroupContext.Provider>
    </Wrapper>
  );
}

CollapsibleGroup.propTypes = {
  /**
   * Allows multiple `Collapsible` components to be open. If false, then only one can be open at a time.
   */
  allowMultipleOpen: PropTypes.bool,
  /**
   * Content of the CollapsibleGroup. Usually `Collapsible` components.
   */
  children: PropTypes.node,
};

CollapsibleGroup.UnsetGroup = UnsetGroup;
CollapsibleGroup.useState = useCollapsibleGroupState;

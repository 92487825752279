// import type { NextPageContext } from 'next';
import Router from 'next/router';

import type { PageContextWithRedux } from '../../../techstyle-shared/redux-core';
import {
  loadSession,
  getSession,
  ThunkDispatch,
  AnyAction,
} from '../../../techstyle-shared/redux-core';

export const authenticationGuard = async (
  { res, store }: PageContextWithRedux,
  redirectUrl: string = '/login'
) => {
  await (store.dispatch as ThunkDispatch<{}, {}, AnyAction>)(loadSession());
  const { isLoggedIn } = getSession(store.getState());

  if (!isLoggedIn && redirectUrl) {
    if (res) {
      // if `res` is present, do server side redirect
      res.writeHead(307, { Location: redirectUrl });
      res.end();
    } else {
      // client redirect with next/router
      Router.replace(redirectUrl);
    }

    // Return an empty object,
    // otherwise Next.js will throw an error
    return {};
  }
};

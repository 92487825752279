export const sizes = {
  xxxs: 4,
  xxs: 8,
  xs: 12,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 36,
  xxl: 40,
  xxxl: 48,
};

export const fontSizes = {
  xs: 12,
  sm: 14,
  md: 16,
};

export const defaultVideoRatio = 9 / 16;

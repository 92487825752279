import { useMemo, useEffect } from 'react';

import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import {
  useSessionActions,
  getSessionDetail,
  useSessionDetail,
} from '../../techstyle-shared/redux-core';

export const SiteSection = {
  YIT: 'YIT',
  STORES: 'STORES',
  FIT: 'FIT',
  MEN: 'MEN',
  WOMEN: 'WOMEN',
};

export const NavSection = {
  WOMEN: 'WOMEN',
  MEN: 'MEN',
  SCRUBS: 'SCRUBS',
};

function useSiteSection() {
  const { asPath } = useRouter();
  const startPath = asPath.split('/')[1];
  const sessionActions = useSessionActions();

  const sessionDetailKey = 'siteSection';

  const stateData = useSelector(
    (state) => getSessionDetail(state, sessionDetailKey).value
  );

  const sessionDetailValue = useSessionDetail(sessionDetailKey);

  const currentPath = useMemo(() => {
    let section = SiteSection.YIT;

    switch (startPath) {
      case 'store-locations':
        section = SiteSection.STORES;
        break;
      case 'fitness-app':
        section = SiteSection.FIT;
        break;
      case 'shop':
        section = SiteSection.YIT;
        break;
      case 'mens':
        section = SiteSection.MEN;
        break;
      case 'womens':
        section = SiteSection.WOMEN;
        break;
      default:
        if (stateData === undefined || stateData === '') {
          section = SiteSection.YIT;
        }
        section = stateData;
    }
    return section;
  }, [startPath, stateData]);

  useEffect(() => {
    if (sessionDetailValue.value !== currentPath) {
      sessionActions.updateSessionDetail({
        name: sessionDetailKey,
        value: currentPath,
      });
    }
  }, [currentPath, sessionDetailValue, sessionActions]);

  return { currentPath, sessionDetailValue: sessionDetailValue?.value };
}

export default useSiteSection;

import React, { useMemo } from 'react';

import styled, { CSSProp } from 'styled-components';

import GridProvider, { useGridState, GridContextType } from '../GridProvider';
import { BreakPoint } from '../types';
import { toMediaQuery } from '../useBreakpointSelector';
import useLayoutEffect from '../useLayoutEffect';

function getBreakpointStyles(breakpoints: BreakPoint[]) {
  return breakpoints.map((breakpoint, i, array) => {
    const nextBreakpoint = array[i + 1];
    const mediaQuery = toMediaQuery(breakpoint, nextBreakpoint);
    const key = `@media ${mediaQuery}`;
    return {
      [key]: {
        gridRowGap: breakpoint.rowGap || breakpoint.gap,
        gridColumnGap: breakpoint.columnGap || breakpoint.gap,
        gridTemplateColumns: `repeat(${breakpoint.columns}, 1fr)`,
      },
    };
  });
}

type WrapperProps = {
  $breakpointStyles: CSSProp;
};

const Wrapper = styled.div<WrapperProps>`
  display: grid;

  ${({ $breakpointStyles }) => $breakpointStyles};
`;

type GridElementProps = {
  onStateChange?: (state: GridContextType) => void;
};

const GridElement = React.forwardRef(
  (
    {
      // eslint-disable-next-line react/prop-types
      onStateChange,
      ...rest
    }: React.ComponentPropsWithRef<typeof Wrapper & GridElementProps>,
    ref
  ) => {
    const state = useGridState();

    const breakpointStyles = useMemo(() => {
      if (!state) {
        return null;
      }

      return getBreakpointStyles(state.breakpoints);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.breakpoints]);

    useLayoutEffect(() => {
      if (onStateChange) {
        onStateChange(state);
      }
    }, [state, onStateChange]);

    return <Wrapper $breakpointStyles={breakpointStyles} ref={ref} {...rest} />;
  }
);

GridElement.displayName = 'Grid.GridElement';

type GridProps = {
  /**
   * An array of breakpoints, which should contain both the viewport properties
   * (e.g. `minWidth`) and the grid properties (e.g. `columns`, `gap`) for each
   * breakpoint.
   *
   * The array should be a constant or memoized, otherwise a render loop is
   * likely (because it must select a new breakpoint from the new array every
   * time).
   *
   * If no value is passed for this prop, they will be inherited from the
   * nearest `<GridProvider>` ancestor. If there is no such ancestor, an error
   * will be thrown.
   */
  breakpoints?: BreakPoint[];
  /**
   * The breakpoint to choose during server-side rendering and when no others
   * match. You can pass an item from `breakpoints` here, or leave it empty to
   * easily indicate that the current breakpoint is unknown.
   */
  defaultBreakpoint?: BreakPoint;
  /**
   * A function to call whenever the grid state (like the current breakpoint)
   * changes. This supplies the same context value as the `useGridState` hook.
   */
  onStateChange?: () => void;
};

const Grid = React.forwardRef<unknown, React.PropsWithChildren<GridProps>>(
  (
    {
      breakpoints,
      defaultBreakpoint,
      onStateChange,
      children,
      ...rest
    }: React.PropsWithChildren<GridProps>,
    ref
  ) => {
    const isSubgrid = !breakpoints;
    const parentGridState = useGridState();

    if (isSubgrid && !parentGridState) {
      throw new Error(
        '<Grid> was created without breakpoints, but there is no <GridProvider> ancestor. ' +
          'Define breakpoints to create a grid, or add <GridProvider> to create a subgrid.'
      );
    }

    const grid = (
      <GridElement onStateChange={onStateChange} ref={ref} {...rest}>
        {children}
      </GridElement>
    );

    if (isSubgrid) {
      // No need for a provider, there already is a provider ancestor.
      return grid;
    }

    // Otherwise, create our own provider.
    return (
      <GridProvider
        breakpoints={breakpoints}
        defaultBreakpoint={defaultBreakpoint}
      >
        {grid}
      </GridProvider>
    );
  }
);

Grid.displayName = 'Grid';

export default Grid;

import styled from 'styled-components';

import BaseSeperator from '../Separator';
import { desktop } from '../styles';

const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${desktop`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`;

const Separator = styled(BaseSeperator)``;

export { Header, Separator };

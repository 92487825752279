import React, { useCallback } from 'react';

import logger from '@techstyle/debug';
import styled from 'styled-components';

import { useLocalStorage } from '../../../../techstyle-shared/redux-core';
import AdminPanel from '../AdminPanel';

const identity = (string) => string;

const NamespacesInput = styled.input.attrs({
  type: 'text',
})`
  padding: 3px 5px;
  font-family: Menlo, Monaco, Consolas, 'Source Code Pro', monospace;
  font-size: 13px;
  line-height: 18px;
`;

const PresetList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const PresetButton = styled.button`
  margin: 0 10px 0 0;
  border: 0;
  border-radius: 2px;
  padding: 1px 5px;
  font-family: Menlo, Monaco, Consolas, 'Source Code Pro', monospace;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  background: rgb(69, 133, 200, 0.7);
  color: white;
  text-shadow: 0 1px 0 rgba(0, 49, 111, 0.5);

  :hover,
  &[data-active] {
    background: rgb(69, 133, 200, 1);
  }
`;

export default function LoggingAdminPanel() {
  const [namespaces, setStorageNamespaces] = useLocalStorage('debug', {
    encode: identity,
    decode: identity,
  });

  const setNamespaces = useCallback(
    (namespaces) => {
      if (namespaces) {
        logger.enable(namespaces);
      } else {
        logger.disable();
      }
      setStorageNamespaces(namespaces);
    },
    [setStorageNamespaces]
  );

  const changeDebug = useCallback(
    (event) => {
      setNamespaces(event.target.value);
    },
    [setNamespaces]
  );

  const debugLink = (
    <a
      href="https://github.com/visionmedia/debug"
      target="_blank"
      rel="noopener noreferrer"
    >
      debug
    </a>
  );

  return (
    <AdminPanel id="logging" label="Logging">
      <AdminPanel.Fields>
        <AdminPanel.Field
          label={
            <label htmlFor="debugLoggingNamespaces">Enable namespaces:</label>
          }
        >
          <NamespacesInput
            id="debugLoggingNamespaces"
            size={32}
            value={namespaces || ''}
            onChange={changeDebug}
          />
        </AdminPanel.Field>
        <AdminPanel.Field label="Presets:">
          <PresetList>
            <li>
              <PresetButton
                title="Turn off all debug logging"
                data-active={namespaces ? undefined : ''}
                onClick={() => {
                  setNamespaces('');
                }}
              >
                off
              </PresetButton>
            </li>
            <li>
              <PresetButton
                title="Turn on debug logging for TechStyle modules"
                data-active={namespaces === '@techstyle/*' ? '' : undefined}
                onClick={() => {
                  setNamespaces('@techstyle/*');
                }}
              >
                TechStyle modules
              </PresetButton>
            </li>
            <li>
              <PresetButton
                title="Turn on debug logging for all modules"
                data-active={namespaces === '*' ? '' : undefined}
                onClick={() => {
                  setNamespaces('*');
                }}
              >
                everything
              </PresetButton>
            </li>
          </PresetList>
        </AdminPanel.Field>
        <AdminPanel.Field label={<AdminPanel.HelpIcon />}>
          <AdminPanel.HelpText>
            Modules using the {debugLink} package for debug message logging can
            specify namespaces in which to write their messages. When messages
            are enabled, the namespace is printed alongside the output, so you
            can tell where it came from and which namespace you may want to
            focus on.
          </AdminPanel.HelpText>
          <AdminPanel.HelpText>
            Namespaces be toggled with a syntax that supports lists (using{' '}
            <AdminPanel.InlineCode>,</AdminPanel.InlineCode>), wildcards (using{' '}
            <AdminPanel.InlineCode>*</AdminPanel.InlineCode>), and exclusions
            (using <AdminPanel.InlineCode>-</AdminPanel.InlineCode>). See the
            documentation for the{' '}
            <a
              href="https://github.com/visionmedia/debug"
              target="_blank"
              rel="noopener noreferrer"
            >
              debug
            </a>{' '}
            npm package for more information.
          </AdminPanel.HelpText>
        </AdminPanel.Field>
      </AdminPanel.Fields>
    </AdminPanel>
  );
}

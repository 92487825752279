export const LabelType = {
  CATEGORY_BREADCRUMBS: 'CATEGORY_BREADCRUMBS',
  CATEGORY_DROPDOWN: 'CATEGORY_DROPDOWN',
  CATEGORY_FILTER: 'CATEGORY_FILTER',
  CATEGORY_FILTER_TAG: 'CATEGORY_FILTER_TAG',
  CATEGORY_TREE: 'CATEGORY_TREE',
  PRODUCT_FILTER: 'PRODUCT_FILTER',
  PRODUCT_FILTER_SUMMARY: 'PRODUCT_FILTER_SUMMARY',
  PRODUCT_FILTER_TAG: 'PRODUCT_FILTER_TAG',
  SORT_DROPDOWN: 'SORT_DROPDOWN',
  TOP_LEVEL_CATEGORY_HEADING: 'TOP_LEVEL_CATEGORY_HEADING',
  REVIEW_SORT_DROPDOWN: 'REVIEW_SORT_DROPDOWN',
};

import { loadFeaturesSuccess } from '../../../techstyle-shared/react-features';

import logger from './logger';

const debug = logger.extend('sentryFeatureTagMiddleware');

export default function sentryFeatureTagMiddleware() {
  return (store) => (next) => (action) => {
    if (action.type === loadFeaturesSuccess.type) {
      debug(
        'A Feature was loaded. Checking if Sentry is enabled in the browser...'
      );
      if (process.browser && typeof window.Sentry !== 'undefined') {
        action.payload.forEach(({ enabled, handle, value }) => {
          let featureTagValue = enabled && value;
          if (typeof value !== 'boolean') {
            // 200 characters max which is why we use substring
            featureTagValue = JSON.stringify(value).substring(0, 199);
          }
          const handleName = handle.substring(0, 24);
          // 32 characters max which is why we use substring
          window.Sentry.setTag(`feature_${handleName}`, featureTagValue);
          window.Sentry.addBreadcrumb({
            category: 'feature flag',
            message: `Setting feature '${handleName}' to Sentry state with value: ${featureTagValue}`,
            level: 'info',
          });
          debug(
            'Setting feature `%s` to Sentry state with value: `%s`',
            handleName,
            value
          );
        });
      }
    }
    return next(action);
  };
}

import config from 'config';

import logger from '../logger';

const debug = logger.extend('errorLoggingExtension');

const reqPropertiesToInclude = ['headers', 'query', 'url', 'originalUrl'];

function reduceRequestProperties(itemsToKeep, item) {
  const [key, value] = item;
  if (reqPropertiesToInclude.includes(key)) {
    if (key === 'headers' && typeof value === 'object') {
      Object.entries(value).forEach((headerItem) => {
        const [key, value] = headerItem;
        itemsToKeep[`header_${key}`] = value;
      });
    } else {
      itemsToKeep[key] = JSON.stringify(value);
    }
  }
  return itemsToKeep;
}

export function createDefaultErrorHandler() {
  if (!process.browser) {
    // Can't create logger right away as it uses `config.get`, lazily create the
    // first time there's an error to report.
    let logger;
    return (err, additionalInfo = {}) => {
      if (config.get('server.graylog.enabled')) {
        if (!logger) {
          const { makeGraylogLogger } = require('./graylog');
          logger = makeGraylogLogger();
        }
        const errPropertiesToRemove = ['stack'];
        const allowPropertyList = Object.getOwnPropertyNames(err).filter(
          (property) => !errPropertiesToRemove.includes(property)
        );
        const { req, ...rest } = additionalInfo;
        const requestProperties = req
          ? Object.entries(req).reduce(reduceRequestProperties, {})
          : {};
        const errorToSend = {
          error_object: JSON.stringify(err, allowPropertyList),
          error_stack: err.stack,
          ...err.customData,
          ...requestProperties,
          ...rest,
        };
        logger.error(`${err.name} - ${err.message}`, errorToSend);
      }
    };
  }
}

export default function errorLoggingExtension({
  onError = createDefaultErrorHandler(),
} = {}) {
  return {
    id: 'errorLogging',
    server: process.browser
      ? {}
      : {
          configure(server) {
            // For error debugging purposes, add a middleware that will throw an
            // error if a `debugErrorType=middleware` query param is in the URL.
            function debugErrorMiddleware() {
              return (req, res, next) => {
                if (req.query.debugErrorType === 'middleware') {
                  throw new Error(
                    'Debug error thrown via debugErrorType: middleware'
                  );
                }
                next();
              };
            }

            server.useTracked('debugErrorMiddleware', debugErrorMiddleware());
          },
          configureInitialMiddleware(server) {
            const isSentryEnabled =
              config.has('server.sentry.enabled') &&
              config.get('server.sentry.enabled');

            const isSentrySharedSdk =
              config.has('server.sentry.useSharedSdk') &&
              config.get('server.sentry.useSharedSdk');

            const ignoreErrors = config.has('server.sentry.ignoreErrors')
              ? config.get('server.sentry.ignoreErrors')
              : [];

            if (isSentryEnabled && isSentrySharedSdk) {
              const Sentry = require('@sentry/node');
              const sentrySampleRate = parseFloat(
                process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE
              );

              const sentryTracesSampleRate = parseFloat(
                process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE
              );

              Sentry.init({
                dsn: config.get('server.sentry.dsn'),
                ignoreErrors,
                integrations: [
                  // enable HTTP calls tracing
                  new Sentry.Integrations.Http({ tracing: true }),
                  // enable Express.js middleware tracing
                  new Sentry.Integrations.Express({ server }),
                ],
                sampleRate: !isNaN(sentrySampleRate)
                  ? sentrySampleRate
                  : config.has('server.sentry.sampleRate') &&
                    config.get('server.sentry.sampleRate'),
                tracesSampleRate: !isNaN(sentryTracesSampleRate)
                  ? sentryTracesSampleRate
                  : config.get('server.sentry.tracesSampleRate'),
                environment:
                  (config.has('server.sentry.environment') &&
                    config.get('server.sentry.environment')) ||
                  '',
                beforeSend(event) {
                  event.tags.storeId =
                    config.has('public.brand.id') &&
                    config.get('public.brand.id');
                  event.tags.storeLabel =
                    config.has('public.brand.label') &&
                    config.get('public.brand.label');
                  return event;
                },
              });

              server.useTracked(
                'Sentry.Handlers.requestHandler',
                Sentry.Handlers.requestHandler()
              );
              server.useTracked(
                'Sentry.Handlers.tracingHandler',
                Sentry.Handlers.tracingHandler()
              );
            }
          },
          configureErrorMiddleware(server) {
            const isSentryEnabled =
              config.has('server.sentry.enabled') &&
              config.get('server.sentry.enabled');

            if (isSentryEnabled) {
              const Sentry = require('@sentry/node');

              server.useTracked(
                'Sentry.Handlers.errorHandler',
                Sentry.Handlers.errorHandler()
              );
            }

            function errorLoggingMiddleware() {
              return (err, req, res, next) => {
                debug('Calling onError for: %s', err);
                onError(err, { req });
                next(err);
              };
            }

            server.useTracked(
              'errorLoggingMiddleware',
              errorLoggingMiddleware()
            );
          },
        },
    app: process.browser
      ? undefined
      : {
          getInitialProps({ ctx, Component }) {
            let debugErrorType;
            const isErrorPage = ctx.pathname === '/_error';
            if (isErrorPage) {
              if (ctx.err) {
                const { pathname, query, req } = ctx;
                debug('Calling onError for: %s', ctx.err);
                onError(ctx.err, { pathname, query, req });
              }
            } else {
              // This param is ignored if the error page is already being
              // rendered as we don't want to throw *another* error while
              // trying to render the error page.
              debugErrorType = ctx.query.debugErrorType || undefined;
            }
            // For error debugging purposes, throw an error if a
            // `debugErrorType=getInitialProps` query param is in the URL.
            if (debugErrorType === 'getInitialProps') {
              throw new Error(
                'Debug error thrown via debugErrorType: getInitialProps'
              );
            }
            return { debugErrorType };
          },
          render({ debugErrorType }, children) {
            // For error debugging purposes, throw an error during render if a
            // `debugErrorType=render` query param is in the URL.
            if (debugErrorType === 'render') {
              throw new Error('Debug error thrown via debugErrorType: render');
            }
            return children;
          },
        },
    page: process.browser
      ? undefined
      : {
          getInitialProps: {
            enhance(getInitialProps) {
              return async (ctx) => {
                let initialProps;
                try {
                  // For error debugging purposes, throw an error if a
                  // `debugErrorType=pageGetInitialProps` query param is in the URL of a page.
                  const debugErrorType = ctx.query?.debugErrorType || undefined;
                  if (debugErrorType === 'pageGetInitialProps') {
                    throw new Error(
                      'Debug error thrown via debugErrorType: pageGetInitialProps'
                    );
                  }
                  initialProps = await getInitialProps(ctx);
                } catch (err) {
                  // Set a default object when an error is thrown so the
                  // page's getInitialProps will resolve to a defined object.
                  initialProps = {
                    error: {
                      name: err?.name,
                      message: err?.message,
                      stack: err?.stack,
                    },
                  };
                  const { pathname, query } = ctx;
                  debug('Calling onError for: %s', err);
                  onError(err, { pathname, query });
                }
                return initialProps;
              };
            },
          },
        },
  };
}

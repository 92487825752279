import { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getDefaultPaymentMethod } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function usePayments() {
  const actions = useAccountActions();
  const payments = useSelector((state) => state.payments);
  const defaultPaymentMethod = useSelector(getDefaultPaymentMethod);

  useEffect(() => {
    if (!payments.networkStatus.isUpToDate) {
      actions.loadPayments();
    }
  }, [actions, payments.networkStatus.isUpToDate]);

  return useMemo(
    () => ({
      ...payments,
      defaultPaymentMethod,
    }),
    [payments, defaultPaymentMethod]
  );
}

import {
  AuthType,
  loadSession,
  VisitorStatus,
} from '../../../techstyle-shared/redux-core';

import { autoLogin, autoLoginPasswordless } from './accountsModule';
import logger from './logger';

const debug = logger.extend('autoLoginExtension');

export const AutoLoginType = {
  CREDENTIALS: 1,
  PASSWORDLESS: 2,
};

/**
 * AutoLogin extension that adds support for the app to detect the
 * standard altoken/alkey/alemail query params.
 *
 * @param {Object} options - The options for the auto login extension.
 * @param {Function} options.onAutoLoginError - The callback function to handle auto login errors.
 * @returns {Object} - The auto login extension object.
 */
export default function autoLoginExtension({ onAutoLoginError } = {}) {
  return {
    id: 'autoLogin',
    redux: {
      modules: [],
    },
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
          configure(server) {
            const {
              default: autoLoginMiddleware,
            } = require('./autoLoginMiddleware');

            // Abundance of caution: ensure backwards compatibility if someone
            // upgrades `~/techstyle-shared/redux-core` without upgrading
            // `@techstyle/next-server`. TODO: This can be removed when everyone
            // is up to date.
            const addMiddleware = (name, middleware) => {
              if (server.useTracked) {
                server.useTracked(name, middleware);
              } else {
                server.use(middleware);
              }
            };

            addMiddleware('autoLoginMiddleware', autoLoginMiddleware());
          },
        },
    app: {
      getInitialProps: {
        enhance(getInitialProps) {
          return async ({ Component, ctx }) => {
            const {
              altoken: alToken,
              alkey: alKey,
              alemail: alEmail,
            } = ctx.query;
            if ((alToken && alKey) || alEmail) {
              await ctx.store.dispatch(loadSession());
              const { visitorStatus, authType } =
                ctx.store?.getState()?.session;
              const isPasswordlessLogin = Boolean(alEmail);
              if (
                visitorStatus === VisitorStatus?.LOGGED_IN &&
                (authType === AuthType?.CREDENTIALS ||
                  authType === AuthType?.GMS_LOGIN)
              ) {
                debug(
                  'Session is already logged in, autologin params will be ignored.'
                );
              } else {
                try {
                  if (isPasswordlessLogin) {
                    await ctx.store.dispatch(
                      autoLoginPasswordless({
                        email: alEmail,
                      })
                    );
                  } else {
                    await ctx.store.dispatch(autoLogin({ alToken, alKey }));
                  }
                } catch (error) {
                  onAutoLoginError &&
                    onAutoLoginError({
                      error,
                      ctx,
                      type: isPasswordlessLogin
                        ? AutoLoginType.PASSWORDLESS
                        : AutoLoginType.CREDENTIALS,
                      payload: alEmail
                        ? { alEmail }
                        : {
                            alToken,
                            alKey,
                          },
                    });
                  debug(
                    '%c!%c Error using autologin params: %s',
                    'color: red',
                    '',
                    error
                  );
                  debug(
                    'Render will continue as if the params were not supplied.'
                  );
                }
              }
            }
            return getInitialProps({ Component, ctx });
          };
        },
      },
    },
  };
}

import React from 'react';

import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

import {
  defineMessages,
  useIntl,
  FormattedMessage,
} from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import { mobile } from '../styles';

const messages = defineMessages({
  deselectLabel: {
    id: 'site_product_filter.deselect_button',
    defaultMessage: 'Dismiss {filterValue} filter',
  },
});

const TagHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  line-height: 32px;
  color: #333;

  ${mobile`
    font-size: 16px;
    line-height: 48px;
  `}
`;

const HeaderLabelWrapper = styled.span`
  ${({ headerStyle }) => headerStyle};
`;

const ClearAllButton = styled(Button).attrs(() => ({
  variant: 'clearAllFilters',
}))`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #333;

  &,
  &:focus,
  &:hover {
    text-decoration: underline;
  }

  ${({ buttonStyle }) => buttonStyle};
`;

const TagList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  margin: -4px 0 0 0;

  ${({ tagListStyle }) => tagListStyle};
`;

const Tag = styled.li`
  padding: 0 0 0 8px;
  margin: 4px 4px 0 0;
  background: #eeeeee;
  border-radius: 4px;
  text-align: left;
  font-size: 12px;
  line-height: 24px;
  color: #333;

  ${mobile`
    font-size: 14px;
    line-height: 32px;
  `}

  ${({ tagStyle }) => tagStyle};
`;

const DeselectIcon = styled(MdClose)`
  display: inline-block;
  vertical-align: -2px;
  color: #999;
`;

const DeselectButton = styled(Button).attrs(() => ({
  variant: 'deselectFilter',
  children: <DeselectIcon />,
}))`
  width: auto;
  height: 24px;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 6px 0 3px;
  margin-left: 2px;
  font-size: 12px;

  ${mobile`
    height: 32px;
    font-size: 16px;
  `};

  ${({ buttonStyle }) => buttonStyle};
`;

const FilterTags = ({
  clearAllButtonComponent,
  clearAllButtonStyle,
  deselectButtonStyle,
  headerStyle,
  onClearAll,
  selectedFilters,
  tagListStyle,
  tagStyle,
  ...rest
}) => {
  const intl = useIntl();
  const ClearAllBtn = clearAllButtonComponent || ClearAllButton;

  return (
    <div {...rest}>
      <TagHeader>
        <HeaderLabelWrapper headerStyle={headerStyle}>
          <FormattedMessage
            id="site_product_filter.filtered_by"
            defaultMessage="Filtered by:"
          />
        </HeaderLabelWrapper>
        <ClearAllBtn
          onClick={onClearAll}
          data-autotag="filter-clear-all"
          buttonStyle={clearAllButtonStyle}
        >
          <FormattedMessage
            id="site_product_filter.clear_all"
            defaultMessage="Clear all"
          />
        </ClearAllBtn>
      </TagHeader>
      <TagList data-autotag="filtered-by-textbox" tagListStyle={tagListStyle}>
        {selectedFilters.map((value) => {
          const deselectLabel = intl.formatMessage(messages.deselectLabel, {
            filterValue: value.labelString,
          });
          return (
            <Tag
              key={`${value.filterField.field}-${value.filterValue}`}
              data-autotag="filtered-by-option"
              tagStyle={tagStyle}
            >
              {value.label}
              <DeselectButton
                aria-label={deselectLabel}
                data-autotag="filtered-by-option-close-icon"
                buttonStyle={deselectButtonStyle}
                onClick={(event) => value.onDismiss(event, value)}
              />
            </Tag>
          );
        })}
      </TagList>
    </div>
  );
};

/**
 * See: ../ProductListingFilter/__fixtures__/filterData.json
 * This consists of information for both the category's label
 * and key, and the filter's value and label. The labels are
 * necessary to display the filter and to generate slugified
 * internationalization keys.
 */
const filterValue = PropTypes.shape({
  filterField: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
  }),
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  labelString: PropTypes.string.isRequired,
  label: PropTypes.node,
  onDismiss: PropTypes.func,
});

FilterTags.propTypes = {
  clearAllButtonComponent: PropTypes.elementType,
  clearAllButtonStyle: PropTypes.any,
  deselectButtonStyle: PropTypes.any,
  headerStyle: PropTypes.any,
  onClearAll: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(filterValue).isRequired,
  tagListStyle: PropTypes.any,
  tagStyle: PropTypes.any,
};

export default FilterTags;

import React from 'react';

import dynamic from 'next/dynamic';

import AdminGuard from './AdminGuard';
import adminModule from './adminModule';
import AdminProvider from './AdminProvider';

const AdminTools = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './AdminTools')
);

const LoggingAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './LoggingAdminPanel')
);

const defaultOptions = {
  enableCFOverride: false,
};

export default function adminExtension({ enableCFOverride } = defaultOptions) {
  return (registry) => ({
    id: 'admin',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
    app: {
      render(props, children) {
        const panelsFromExtensions = [];
        registry.forEach((extension) => {
          if (extension.admin && extension.admin.panels) {
            let panels = extension.admin.panels;
            if (typeof panels === 'function') {
              panels = panels(props);
            }
            panelsFromExtensions.push(panels);
          }
        });
        return (
          <AdminProvider enableCFOverride={enableCFOverride}>
            {children}
            <AdminGuard>
              {panelsFromExtensions}
              <LoggingAdminPanel />
            </AdminGuard>
            <AdminGuard mode="once">
              <AdminTools />
            </AdminGuard>
          </AdminProvider>
        );
      },
    },
    redux: {
      modules: [adminModule],
    },
  });
}

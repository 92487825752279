import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useBreakpoint from '../useBreakpoint';

const CarouselSkeletonLoadingWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px; /* Adjust the gap between cards as needed */
  justify-content: space-around;
  margin-top: 16px;
  ${(props) => props.$loadingSkeletonWrapperStyle};
`;

const CarouselSkeletonLoadingCard = styled.div`
  flex: 1;
  min-width: 150px;
  height: 250px;
  background: #fbfbfb;
  overflow: hidden;
  position: relative;
  border: 2px solid #eeeeee;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to right,
      #fbfbfb 0%,
      #f5f5f5 50%,
      #fbfbfb 100%
    );
    animation: load 1.5s infinite;
  }

  @keyframes load {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  ${(props) => props.$loadingSkeletonCardStyle};
`;

function CarouselSkeletonLoader({
  loadingSkeletonDesktopCardCount,
  loadingSkeletonMobileCardCount,
  loadingSkeletonCardStyle,
  loadingSkeletonWrapperStyle,
}) {
  const { isMobile } = useBreakpoint();
  const numCardsShown = isMobile
    ? loadingSkeletonMobileCardCount
    : loadingSkeletonDesktopCardCount;

  return (
    <CarouselSkeletonLoadingWrapper
      $loadingSkeletonWrapperStyle={loadingSkeletonWrapperStyle}
    >
      {[...Array(numCardsShown)].map((_, index) => (
        <CarouselSkeletonLoadingCard
          key={index}
          $loadingSkeletonCardStyle={loadingSkeletonCardStyle}
        />
      ))}
    </CarouselSkeletonLoadingWrapper>
  );
}

export default CarouselSkeletonLoader;

CarouselSkeletonLoader.propTypes = {
  loadingSkeletonCardStyle: PropTypes.any,
  loadingSkeletonDesktopCardCount: PropTypes.number,
  loadingSkeletonMobileCardCount: PropTypes.number,
  loadingSkeletonWrapperStyle: PropTypes.any,
};

import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  useCategoryBreadcrumbs,
  useCategoryBreadcrumbHierarchy,
  useProductListingQueryParamsCustomFilters,
} from '../../../../techstyle-shared/react-products';
import BaseBreadcrumbs from '../Breadcrumbs';
import BaseFilterTags, {
  AggregationFilterTags,
  CategoryFilterTags,
} from '../FilterTagsCustomFilters';
import { LabelType, useCategoryLabels } from '../LabelProvider';
import ProductBrowserContext, {
  useProductBrowser,
} from '../ProductBrowserContext';
import ProductCategoryFilter from '../ProductCategoryFilter';
import ProductFilterContext, {
  useProductFilters,
} from '../ProductFilterContextCustomFilters';
import ProductListingFilter from '../ProductListingFilter';
import ProductSizeFilter from '../ProductSizeFilter';
import { sortOptionPropType, individualSortOption } from '../SortDropdown';
import { mobile, desktop } from '../styles';
import {
  categoryBreadcrumbToFilterCategory,
  getTotalFilterCountCustomFilters,
} from '../utils/category';
import {
  getNormalizedFiltersCustomFilters,
  openCustomFilterDrawers,
} from '../utils/filters';
import { getCombinedFilterSizeData } from '../utils/sizes';

export function getDefaultSortOption(sortOptions) {
  return (
    sortOptions.find((option) => option.isDefault) || sortOptions[0] || null
  );
}

const MobileNavigation = styled.div`
  position: relative;
  z-index: 2;

  ${desktop`display: none;`};
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  ${desktop`
    padding: 16px 24px 16px 32px;
  `};
`;

const Body = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 16px 32px 16px;
  z-index: 0;

  ${desktop`
    padding: 0 32px 32px 32px;
  `};
`;

const Breadcrumbs = styled(BaseBreadcrumbs)`
  font-size: 14px;
  line-height: ${24 / 14};

  ${mobile`
    display: none;
  `}
`;

const FilterTags = styled(BaseFilterTags)`
  margin-bottom: 8px;
`;

function selectAggregationFilter(selectedFilters) {
  return selectedFilters.aggregationFilter;
}

const categoryBreadcrumbLabelOptions = {
  labelType: LabelType.CATEGORY_BREADCRUMBS,
};

const CategoryBreadcrumbs = (props) => {
  const { breadcrumbs } = useProductBrowser();
  const categoryLabelCtx = useCategoryLabels();

  const breadcrumbLabels = useMemo(
    () =>
      breadcrumbs
        ? breadcrumbs.map((breadcrumb) =>
            categoryLabelCtx.renderLabel(
              breadcrumb,
              categoryBreadcrumbLabelOptions
            )
          )
        : [],
    [breadcrumbs, categoryLabelCtx]
  );
  return (
    <Breadcrumbs activeElementType="h1" labels={breadcrumbLabels} {...props} />
  );
};

export const ProductFilters = ({
  categoryFilter: categoryFilterFromProps,
  colorSwatches: colorSwatchesFromProps,
  combineFilterSizeData = true,
  hideCategoryFilter,
  listingFilterComponent: ListingFilter = ProductListingFilter,
  sizeFilterComponent: SizeFilter = ProductSizeFilter,
  ...rest
}) => {
  const productBrowserCtx = useProductBrowser();
  const { filterSettings, setSelectedFilters } = useProductFilters();

  let colorSwatches = colorSwatchesFromProps;
  let categoryFilter = categoryFilterFromProps;

  if (productBrowserCtx) {
    const {
      categoryFilter: categoryFilterFromCtx,
      colorSwatches: colorSwatchesFromCtx,
    } = productBrowserCtx;

    categoryFilter = categoryFilter || categoryFilterFromCtx;
    colorSwatches = colorSwatches || colorSwatchesFromCtx;
  }

  const filterSettingsSizeData = combineFilterSizeData
    ? getCombinedFilterSizeData(filterSettings)
    : filterSettings;

  const handleCategoryFilterChange = ({ newSelectedFilters }) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      categoryIds: newSelectedFilters,
    }));
  };

  const {
    setAutoAppliedFilters,
    autoAppliedFilters: autoAppliedFiltersFromCtx,
  } = productBrowserCtx;
  const handleAggregationFilterChange = useCallback(
    ({ filterField, newSelectedFilters }) => {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        aggregationFilter: {
          ...prevFilters.aggregationFilter,
          [filterField.field]: newSelectedFilters,
        },
      }));

      const { [filterField.field]: autoApplied, ...autoAppliedFilters } =
        autoAppliedFiltersFromCtx || {};

      // If we don't see the auto-applied value in our new list of filters, it has been
      // removed and is no longer considered to be auto-applied.
      if (
        autoApplied &&
        !autoApplied.includes((auto) => newSelectedFilters.includes(auto))
      ) {
        setAutoAppliedFilters(autoAppliedFilters);
      }
    },
    [autoAppliedFiltersFromCtx, setAutoAppliedFilters, setSelectedFilters]
  );

  return (
    <>
      {categoryFilter && !hideCategoryFilter ? (
        <ProductCategoryFilter
          as={ListingFilter}
          filterField={categoryFilter}
          onChange={handleCategoryFilterChange}
        />
      ) : null}

      {filterSettingsSizeData
        .filter(
          (filterField) =>
            filterField.totalAggregationCount !== 0 ||
            filterField.FilterComponent
        )
        .map((filterField) => {
          if (filterField.FilterComponent) {
            return (
              <React.Fragment key={filterField.field}>
                <filterField.FilterComponent
                  filterField={filterField}
                  listingFilterComponent={ListingFilter}
                  setSelectedFilters={setSelectedFilters}
                />
              </React.Fragment>
            );
          }

          const FilterComponent = filterField.field.startsWith('size')
            ? SizeFilter
            : ListingFilter;

          return (
            <FilterComponent
              key={filterField.field}
              filterField={filterField}
              colorSwatches={colorSwatches}
              selectedFilters={selectAggregationFilter}
              onChange={handleAggregationFilterChange}
              {...rest}
            />
          );
        })}
    </>
  );
};

ProductFilters.propTypes = {
  /**
   * Category object to pass to ProductCategoryFilter
   */
  categoryFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  colorSwatches: PropTypes.object,
  /**
   * Boolean to show combined filter size data, or to show them as separate filters
   */
  combineFilterSizeData: PropTypes.bool,
  filterSettings: PropTypes.array,
  hideCategoryFilter: PropTypes.bool,
  /**
   * Component to render in place for ProductListingFilter.
   */
  listingFilterComponent: PropTypes.elementType,
  /**
   * Component to render in place for ProductSizeFilter.
   */
  sizeFilterComponent: PropTypes.elementType,
};

const SideBarFilterTags = (props) => {
  const {
    baseFilters,
    categoryFilter,
    openFilterDrawersOnClearAll,
    filters,
    filterSettings,
    totalFilterCount,
  } = useProductBrowser();
  const {
    toggleCollapsibleFilterIsOpen,
    setFilterSettings,
    setSelectedFilters,
  } = useProductFilters();

  const shouldClearFilterSettings = filterSettings.some(
    ({ getBaseValue }) => typeof getBaseValue === 'function'
  );

  const clearAllFilters = useCallback(() => {
    setSelectedFilters(baseFilters);

    if (shouldClearFilterSettings) {
      const baseFilterSettings = filterSettings.map((filterSetting) =>
        typeof filterSetting.getBaseValue === 'function'
          ? {
              ...filterSetting,
              ...filterSetting.getBaseValue({
                filterSetting,
                selectedFilters: baseFilters,
              }),
            }
          : filterSetting
      );

      setFilterSettings(baseFilterSettings);
    }
    if (openFilterDrawersOnClearAll) {
      // Open all filter drawers;
      openCustomFilterDrawers(toggleCollapsibleFilterIsOpen, filterSettings);
    } else {
      // Close all filter drawers;
      toggleCollapsibleFilterIsOpen({ type: 'reset' });
    }
  }, [
    baseFilters,
    filterSettings,
    setFilterSettings,
    setSelectedFilters,
    shouldClearFilterSettings,
    toggleCollapsibleFilterIsOpen,
    openFilterDrawersOnClearAll,
  ]);

  if (totalFilterCount > 0) {
    return (
      <FilterTags onClearAll={clearAllFilters} {...props}>
        {categoryFilter && (
          <CategoryFilterTags
            categoryFilter={categoryFilter}
            selectedFilters={filters}
            {...props}
          />
        )}
        {filterSettings.map((filterSetting) => {
          const { FilterTagComponent = AggregationFilterTags, field } =
            filterSetting;

          return (
            <React.Fragment key={field}>
              <FilterTagComponent
                filterSetting={filterSetting}
                selectedFilters={filters}
                {...props}
              />
            </React.Fragment>
          );
        })}
      </FilterTags>
    );
  }

  if (props.alwaysShowLabel) {
    return (
      <FilterTags onClearAll={clearAllFilters} hideClearAllButton {...props} />
    );
  }

  return null;
};

SideBarFilterTags.propTypes = {
  alwaysShowLabel: PropTypes.bool,
};

export default function ProductBrowser({
  activeCategory: inputActiveCategory,
  autoAppliedFilters: initialAutoAppliedFilters,
  breadcrumbs: inputBreadcrumbs,
  baseFilters: inputBaseFilters,
  children,
  colorSwatches,
  filterSettings: initialFilterSettings = [],
  initialCount = 0,
  initialFilters = inputBaseFilters,
  sortOptions,
  initialSortOption,
  mySize = true,
  enableQueryParams = true,
  openFilterDrawersOnClearAll = false,
}) {
  // This will be used to determine whether the grid parameters are changing
  // (most likely from `getInitialProps` routing to a new page).
  const baseFiltersRef = useRef(inputBaseFilters);
  const isGridChanging = inputBaseFilters !== baseFiltersRef.current;

  const [isLoading, setIsLoading] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [totalCount, setTotalCount] = useState(initialCount);

  // Aggregation data comes from the products API response.
  const [filterSettingsFromState, setFilterSettings] = useState(
    initialFilterSettings
  );
  const filterSettings = isGridChanging
    ? initialFilterSettings
    : filterSettingsFromState;

  initialFilters = { aggregations: [], ...initialFilters };

  const [autoAppliedFiltersFromState, setAutoAppliedFilters] = useState(
    initialAutoAppliedFilters
  );

  const autoAppliedFilters = isGridChanging
    ? initialAutoAppliedFilters
    : autoAppliedFiltersFromState;

  // Filters are initialized based on the rewrite `productJson` combined with
  // URL parameters, and they are updated by adjusting the filters in various
  // components.
  const [filtersFromState, setFilters] = useState(initialFilters);
  const filters = isGridChanging ? initialFilters : filtersFromState;

  const normalizedFilters = useMemo(
    () => getNormalizedFiltersCustomFilters(filterSettings, filters),
    [filterSettings, filters]
  );
  const baseFilters = useMemo(
    () => getNormalizedFiltersCustomFilters(filterSettings, inputBaseFilters),
    [filterSettings, inputBaseFilters]
  );

  initialSortOption = initialSortOption || getDefaultSortOption(sortOptions);

  // * If the sort is to be displayed, initialSortOption should always be provided
  const [sortOptionFromState, setSortOption] = useState(initialSortOption);
  const sortOption = isGridChanging ? initialSortOption : sortOptionFromState;

  // The grid may be changing, in which case we should sync the internal state
  // back up with the incoming props.
  useEffect(() => {
    baseFiltersRef.current = inputBaseFilters;
    setFilterSettings(filterSettings);
    setFilters(filters);
    setSortOption(sortOption);
  }, [filterSettings, inputBaseFilters, filters, sortOption]);

  useProductListingQueryParamsCustomFilters({
    autoAppliedFilters,
    baseFilters,
    filters: normalizedFilters,
    filterSettings,
    sortOptions,
    sortOption,
    mySize,
    enabled: enableQueryParams,
  });

  const primaryCategoryId = (baseFilters.backgroundCategoryIds ||
    baseFilters.categoryIds ||
    [])[0];

  const activeCategoryFromState = useCategoryBreadcrumbs(primaryCategoryId);
  const breadcrumbsFromState =
    useCategoryBreadcrumbHierarchy(primaryCategoryId);

  const activeCategory = inputActiveCategory || activeCategoryFromState;
  const breadcrumbs = inputBreadcrumbs || breadcrumbsFromState;

  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const totalFilterCount = useMemo(
    () => getTotalFilterCountCustomFilters(filterSettings, normalizedFilters),
    [filterSettings, normalizedFilters]
  );

  const categoryFilter = useMemo(
    () =>
      activeCategory
        ? categoryBreadcrumbToFilterCategory(activeCategory)
        : null,
    [activeCategory]
  );

  const toggleOverlay = useCallback(() => {
    setOverlayVisible((prevState) => !prevState);
  }, []);

  const openOverlay = useCallback(() => {
    setOverlayVisible(true);
  }, []);

  const closeOverlay = useCallback(() => {
    setOverlayVisible(false);
  }, []);

  const value = useMemo(
    () => ({
      activeCategory,
      autoAppliedFilters,
      setAutoAppliedFilters,
      breadcrumbs,
      setFilterSettings,
      baseFilters,
      categoryFilter,
      colorSwatches,
      filters: normalizedFilters,
      filterSettings,
      openFilterDrawersOnClearAll,
      mySize,
      setFilters,
      isFilterDrawerOpen,
      setFilterDrawerOpen,
      sort: sortOption,
      setSort: setSortOption,
      sortOptions,
      primaryCategoryId,
      totalFilterCount,
      isLoading,
      setIsLoading,
      overlayVisible,
      toggleOverlay,
      openOverlay,
      closeOverlay,
      totalCount,
      setTotalCount,
    }),
    [
      activeCategory,
      autoAppliedFilters,
      breadcrumbs,
      baseFilters,
      categoryFilter,
      colorSwatches,
      filterSettings,
      normalizedFilters,
      openFilterDrawersOnClearAll,
      mySize,
      isFilterDrawerOpen,
      sortOption,
      sortOptions,
      primaryCategoryId,
      totalFilterCount,
      isLoading,
      overlayVisible,
      toggleOverlay,
      openOverlay,
      closeOverlay,
      totalCount,
    ]
  );

  return (
    <ProductBrowserContext value={value}>
      <ProductFilterContext
        appliedFilters={normalizedFilters}
        filterSettings={filterSettings}
        onChange={setFilters}
        onChangeFilterSettings={setFilterSettings}
      >
        {typeof children === 'function' ? children(value) : children}
      </ProductFilterContext>
    </ProductBrowserContext>
  );
}

ProductBrowser.propTypes = {
  /**
   * Custom category hierarchy to use instead of category-based breadcrumbs
   * from Redux store
   */
  activeCategory: PropTypes.object,
  /**
   * Aggregation filters that have been automatically applied (ie profile sizes).
   * These are not added to the URL params until a new filter is added to that field
   * or the auto-applied value is removed.
   */
  autoAppliedFilters: PropTypes.object,
  /**
   * The filter settings that cannot be unapplied; if the user resets/clears all
   * filters, this is what they will reset to.
   */
  baseFilters: PropTypes.object,
  /**
   * Custom breadcrumbs to use instead of category-based breadcrumbs from
   * Redux store
   */
  breadcrumbs: PropTypes.array,
  /**
   * Grid area content.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * A color swatch mapping to supply via context to filters that render colors.
   */
  colorSwatches: PropTypes.object,
  /**
   * Determines whether url params should be added when filtering.
   */
  enableQueryParams: PropTypes.bool,
  /**
   * The filters to be handled by the ProductBrowser. This partly matches the
   * `aggregations` data structure returned from the `products/v2` endpoint,
   * extended to allow different transformations.
   *
   * For examples of how these props are used, please see the existing filterSettings
   * in `/ProductBrowserCustomFilters/filterSettings`.
   */
  filterSettings: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The filter field that corresponds to the filter.
       */
      field: PropTypes.string,
      /**
       * The label for the filter.
       */
      label: PropTypes.string,
      /**
       * The total number of possible aggregation values for the filter. Only used
       * for aggregation filters currently.
       */
      totalAggregationCount: PropTypes.number,
      /**
       * The different options for the value of the filter.
       */
      items: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.any,
          label: PropTypes.string,
        })
      ),
      /**
       * Renders the filtering component in the sidebar's list of filters. If not
       * provided, this will render using the `ProductListingFilter` or the
       * `listingFilterComponent` if one is set on the `ProductFilter`.
       */
      renderFilterComponent: PropTypes.func,
      /**
       * Translates from the given URL parameters into the correct filter value
       * for this filter. If not specified, the filter will attempt to pull the
       * value from the URL parameters based on its `field` and add it to the
       * `aggregationFilter` object.
       */
      fromUrlParams: PropTypes.func,
      /**
       * Translates the current filter value into corresponding URL parameters.
       * If not specified, this will pull the value of the filter from the
       * `aggregationFilter` object based on its `field`.
       */
      toUrlParams: PropTypes.func,
      /**
       * Gets the current active filter count based on the value of the current filter,
       * displayed above the list of filters. If not specified, this will check
       * the count of values in `aggregationFilter[field]`.
       */
      getCount: PropTypes.func,
      /**
       * The React component used to render the tag values that appear above the list of filters.
       * If not specified, this will render using the `AggregationFilterTag` component.
       */
      FilterTagComponent: PropTypes.elementType,
      /**
       * Gets the default value for this filter when the ProductBrowser is initialized.
       * If not specified, this will add a default value of `[]` in `aggregationFilter[field]`.
       */
      getDefaultValue: PropTypes.func,
    })
  ),
  /**
   * Initial "total products" count for a paged product result.
   */
  initialCount: PropTypes.number,
  /**
   * The initial filter settings. It's possible for this to be different from
   * `baseFilters`, because other filter settings can be initialized via URL
   * parameters.
   */
  initialFilters: PropTypes.object,
  /**
   * Initial sort to be used.
   */
  initialSortOption: individualSortOption,
  /**
   * Determines whether the grid should be filtered by the user's profile
   * sizes (true = filtered).
   */
  mySize: PropTypes.bool,
  /**
   * Determines whether the filters should be opened on the sidebar when
   * clicking "Clear All".
   */
  openFilterDrawersOnClearAll: PropTypes.bool,
  /**
   * Sort options to display in the `SortDropdown`.
   */
  sortOptions: sortOptionPropType,
};

ProductBrowser.Body = Body;
ProductBrowser.CategoryBreadcrumbs = CategoryBreadcrumbs;
ProductBrowser.FilterTags = SideBarFilterTags;
ProductBrowser.Header = Header;
ProductBrowser.MobileNavigation = MobileNavigation;
ProductBrowser.ProductFilters = ProductFilters;

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getSessionDetail } from './bentoApiModule';
import useSessionActions from './useSessionActions';

export default function useSessionDetail(key) {
  const actions = useSessionActions();

  const sessionDetail = useSelector((state) =>
    key ? getSessionDetail(state, key) : null
  );

  // Intentionally fire this effect always (no dependenceis). The
  // `loadSessionDetails` action already checks whether it should run or not.
  useEffect(() => {
    if (key) {
      actions.loadSessionDetails();
    }
  });

  return sessionDetail;
}

import React from 'react';

import useId from '../useId';
import useTheme from '../useTheme';

interface UpChevronProps extends React.SVGAttributes<SVGElement> {
  color: string;
  size: string | number;
  title?: string;
}

export default function UpChevron({
  color = 'currentColor',
  size = '1em',
  title,
  ...rest
}: UpChevronProps) {
  const theme = useTheme();
  const titleId = useId();
  const fill = theme.colors[color] || color;
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {title && <title id={titleId}> {title} </title>}
      <path
        d="M0.6875 7.25L1.28125 7.875C1.4375 8 1.6875 8 1.8125 7.875L7.5 2.21875L13.1562 7.875C13.2812 8 13.5312 8 13.6875 7.875L14.2812 7.25C14.4375 7.09375 14.4375 6.875 14.2812 6.71875L7.75 0.15625C7.59375 0.03125 7.375 0.03125 7.21875 0.15625L0.6875 6.71875C0.53125 6.875 0.53125 7.09375 0.6875 7.25Z"
        fill={fill}
      />
    </svg>
  );
}

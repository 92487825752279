import { MdLocalShipping } from 'react-icons/md';
import styled from 'styled-components';

import ProgressLoadingIndicator from '../ProgressLoadingIndicator/ProgressLoadingIndicator';

const Wrapper = styled.div`
  background-color: #d3d3d3;
  padding: 2px;
`;

const LabelWrapper = styled.div`
  padding: 3px;
  margin: 8px;
`;

const MessageLabel = styled.label``;

const ShippingIcon = styled(MdLocalShipping)`
  font-size: 24px;
  transform: scaleX(-1);
  position: relative;
  top: 7px;
  margin-right: 5px;
`;

const StyledLoadingIndicator = styled(ProgressLoadingIndicator).attrs(
  (props) => ({
    fillStyle: {
      animationIterationCount: 1,
      maxWidth: `${props.fillPercentage}%`,
      borderRadius: 8,
    },
    thickness: 8,
  })
)`
  border: 3px solid ${({ theme }) => theme.colors.black};
  border-radius: 99px;
  margin: 8px;
  padding: 3px;
`;

const ShippingThreshold = {
  Wrapper: Wrapper,
  LabelWrapper: LabelWrapper,
  ShippingIcon: ShippingIcon,
  MessageLabel: MessageLabel,
  LoadingIndicator: StyledLoadingIndicator,
};

export default ShippingThreshold;

import React from 'react';

import Head from 'next/head';

/**
 * Render a `<meta name="viewport">` tag with optimal default settings. The tag
 * will automatically be rendered into the document head via the Next.js
 * `<Head>` component; you don’t need to wrap it in `<Head>` yourself.
 */
export default function ResponsiveViewport() {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
  );
}

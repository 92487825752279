import logger from './logger';

const debug = logger.extend('loggerMiddleware');

export default function createLoggerMiddleware() {
  return (store) => (next) => (action) => {
    switch (action.type) {
      case '@@Internal/ModuleManager/ModuleAdded':
        debug('Module added: %c%s%c', 'font-weight: bold', action.payload, '');
        break;
      case '@@Internal/ModuleManager/ModuleRemoved':
        debug(
          'Module removed: %c%s%c',
          'font-weight: bold',
          action.payload,
          ''
        );
        break;
      default:
        debug(
          'Action dispatched: %c%s%c',
          'font-weight: bold',
          action.type,
          ''
        );
    }
    if (action.error) {
      debug('%c!%c %s', 'color: red', '', action.payload);
    }
    const preState = store.getState();
    const result = next(action);
    const postState = store.getState();
    const changedState = [];
    Object.keys(postState).forEach((key) => {
      if (postState[key] !== preState[key]) {
        changedState.push(key);
      }
    });
    if (changedState.length) {
      debug(
        `State changed: ${changedState.map((key) => `%c${key}%c`).join(', ')}`,
        ...changedState.reduce((args) => {
          args.push('font-weight: bold', '');
          return args;
        }, [])
      );
    }
    return result;
  };
}

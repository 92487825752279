import { useState } from 'react';

import { useAutoIdContext } from './AutoIdProvider';

export default function useId(prefix = 'id-') {
  const getId = useAutoIdContext();

  const [id] = useState(() => `${prefix}${getId()}`);
  return id;
}

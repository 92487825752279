import { createAction } from '../../techstyle-shared/redux-core';

export const trackStartChatClick = createAction('TRACK_START_CHAT_CLICK');
export const trackReturnCancelClick = createAction('TRACK_RETURN_CANCEL_CLICK');
export const trackReturnSubmitClick = createAction('TRACK_RETURN_SUBMIT_CLICK');
export const trackCommunicationPreferenceClick = createAction(
  'TRACK_COMMUNICATION_PREFERENCE_CLICK'
);
export const trackSizePreferenceClick = createAction(
  'TRACK_SIZE_PREFERENCE_CLICK'
);
export const trackSubmitReviewClick = createAction('TRACK_SUBMIT_REVIEW_CLICK');
export const trackVipChatCancelClick = createAction(
  'TRACK_VIP_CHAT_CANCEL_CLICK'
);
export const trackVipPhoneCancelClick = createAction(
  'TRACK_VIP_PHONE_CANCEL_CLICK'
);
export const trackVipOnlineCancelClick = createAction(
  'TRACK_VIP_ONLINE_CANCEL_CLICK'
);
export const trackProfileSuggestedAddressClick = createAction(
  'TRACK_PROFILE_SUGGESTED_ADDRESS_CLICK'
);
export const trackProfileNavLinkClick = createAction(
  'TRACK_PROFILE_NAV_LINK_CLICK'
);

export const trackOnlineCancelPageView = createAction(
  'TRACK_ONLINE_CANCEL_PAGE_VIEW'
);

export const trackShoppableVideoFeedPageView = createAction(
  'TRACK_SHOPPABLE_VIDEO_FEED_PAGE_VIEW'
);

export const trackManageMembershipView = createAction(
  'TRACK_MANAGE_MEMBERSHIP_VIEW'
);

export const trackPauseMembershipClick = createAction(
  'TRACK_PAUSE_MEMBERSHIP_CLICK'
);

export const trackPauseBillingSubmit = createAction(
  'TRACK_PAUSE_BILLING_SUBMIT'
);

export const trackRedeemOfferClick = createAction('TRACK_REDEEM_OFFER_CLICK');

export const trackCancelMemberShipClick = createAction(
  'TRACK_CANCEL_MEMBERSHIP_CLICK'
);

export const trackConfirmPauseMembershipClick = createAction(
  'TRACK_CONFIRM_PAUSE_MEMBERSHIP_CLICK'
);

export const trackConfirmRedeemOfferClick = createAction(
  'TRACK_CONFIRM_REDEEM_OFFER_CLICK'
);

export const trackConfirmCancelMembershipClick = createAction(
  'TRACK_CONFIRM_CANCEL_MEMBERSHIP_CLICK'
);

export const trackCancelOrderPopupYesClick = createAction(
  'TRACK_CANCEL_ORDER_POPUP_YES_CLICK'
);

export const trackCancelOrderPopupNoClick = createAction(
  'TRACK_CANCEL_ORDER_POPUP_NO_CLICK'
);

export const trackCommunicationRegistrationSubmit = createAction(
  'TRACK_COMMUNICATION_REGISTRATION_SUBMIT'
);

export const trackRegistrationFailure = createAction(
  'TRACK_REGISTRATION_FAILURE'
);

export type NetworkStatus = {
  isUpToDate: boolean;
  isLoading: boolean;
};

type StateWithNetworkStatus = {
  networkStatus: NetworkStatus;
};

type ActionWithMeta = {
  meta: {
    customerDidChange?: boolean;
  };
};

export function startLoading(state: StateWithNetworkStatus) {
  state.networkStatus.isLoading = true;
}

export function stopLoading(state: StateWithNetworkStatus) {
  state.networkStatus.isLoading = false;
}

export function invalidate(state: StateWithNetworkStatus) {
  state.networkStatus.isUpToDate = false;
}

/**
 * Invalidation helper intended for use with the `loadSessionSuccess` action,
 * which will populate the `customerDidChange` flag on its `meta` field.
 */
export function invalidateOnCustomerChange(
  state: StateWithNetworkStatus,
  action: ActionWithMeta
) {
  if (action.meta.customerDidChange) {
    invalidate(state);
  }
}

export function upToDateStatus() {
  return {
    isUpToDate: true,
    isLoading: false,
  };
}

export function outOfDateStatus() {
  return {
    isUpToDate: false,
    isLoading: false,
  };
}

export function mergeNetworkStatus(statuses: NetworkStatus[]) {
  return {
    isUpToDate: statuses.every((status) => status.isUpToDate),
    isLoading: statuses.some((status) => status.isLoading),
  };
}

import { useEffect } from 'react';

import useAppElement from '../useAppElement';

/**
 * @component
 *
 * A utility component that allows for multiple components (for example,
 * stacked modals) to control the `aria-hidden` status of the root app element
 * without interfering with one another.
 *
 * The situation this prevents is a naïve component thinking that because it’s
 * hiding or unmounting itself, it can unset `aria-hidden`. This overlooks the
 * fact that another component could also be hiding the app.
 *
 * Render this component whenever the app should be hidden. If at least one is
 * mounted anywhere, then `aria-hidden` will be true. It will be unset when none
 * remain.
 */
export default function AppHidden() {
  const getAppElement = useAppElement();
  useEffect(() => {
    const node = getAppElement();
    if (node) {
      const hiddenCount = parseInt(node.dataset.hiddenCount || 0, 10);
      node.dataset.hiddenCount = hiddenCount + 1;
      node.setAttribute('aria-hidden', 'true');

      return () => {
        const hiddenCount = parseInt(node.dataset.hiddenCount || 0, 10);
        const nextHiddenCount = Math.max(0, hiddenCount - 1);
        node.dataset.hiddenCount = nextHiddenCount;
        if (!nextHiddenCount) {
          node.removeAttribute('aria-hidden');
        }
      };
    }
  }, [getAppElement]);
  return null;
}

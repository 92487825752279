import React from 'react';

import AppFromNext, { AppContext, AppProps } from 'next/app';

import getIsResponseEnded from './getIsResponseEnded';

function getDisplayName(Component: React.ComponentType & typeof AppFromNext) {
  return Component.displayName || Component.name || 'Unknown';
}

const withErrorRedirect = (App: typeof AppFromNext) => {
  const getInitialProps = App.getInitialProps;

  function WithErrorRedirect(props: AppProps) {
    return <App {...props} />;
  }

  WithErrorRedirect.displayName = `WithErrorRedirect(${getDisplayName(App)})`;

  if (getInitialProps) {
    WithErrorRedirect.getInitialProps = async (pageCtx: AppContext) => {
      const ctx = pageCtx.ctx;
      const {
        query: { simulateErrorCode },
      } = ctx;

      if (simulateErrorCode) {
        ctx.errorCodes = new Set(
          typeof simulateErrorCode === 'string'
            ? [simulateErrorCode]
            : simulateErrorCode
        );
      }

      const initialProps = await getInitialProps(pageCtx);

      if (ctx?.errorCodes) {
        const errorCodeArray = Array.from(ctx.errorCodes);

        if (errorCodeArray.length) {
          const firstErrorCode = errorCodeArray[0];

          if (ctx.handleErrorCode) {
            ctx.handleErrorCode(firstErrorCode);
          }

          if (getIsResponseEnded(ctx)) {
            return {};
          }
        }
      }

      return initialProps;
    };
  }

  return WithErrorRedirect;
};

export default withErrorRedirect;

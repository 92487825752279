import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.color || props.fill || 'black'};
  vertical-align: top;
`;

const SavageMarkLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];

  return (
    <Svg
      // Let `aria-label` take control if present. Consumers are also free to
      // override with their own `aria-labelledby`.
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 54 54"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={titleId}>Savage</title>
      <path
        d="M27.001 24.207c5.429-7.51 10.856-15.02 16.284-22.531C43.795.972 43.28 0 42.4 0h-4.89v.389c.157 1.398-.106 2.978-.794 4.74-.69 1.762-1.8 3.704-3.332 5.827L27 19.751a52447.68 52447.68 0 00-6.383-8.795c-1.533-2.123-2.644-4.065-3.332-5.827-.687-1.76-.951-3.341-.794-4.74V0H11.6c-.88 0-1.395.972-.885 1.676L27 24.207h.001zM9.437 1.496A3.644 3.644 0 006.484 0H3.32v.389a34.875 34.875 0 014.363 4.428c1.324 1.606 3.043 3.834 5.159 6.682l9.983 13.53-12.111 16.306C8.332 44.598 6.4 47.098 4.92 48.833A36.938 36.938 0 010 53.61V54h3.595a3.643 3.643 0 002.953-1.496l19.878-27.503L9.437 1.496zM49.08 48.833c-1.479-1.735-3.412-4.235-5.792-7.498L31.175 25.029l9.983-13.53c2.116-2.848 3.835-5.076 5.16-6.682A34.879 34.879 0 0150.68.39V0h-3.164a3.643 3.643 0 00-2.953 1.496L27.574 25l19.878 27.503A3.644 3.644 0 0050.405 54H54v-.39a36.937 36.937 0 01-4.92-4.777zM27 25.796L7.827 52.323c-.509.704.005 1.676.885 1.676h4.778v-.31c-.106-1.45.239-3.16 1.032-5.128 1.513-3.754 3.808-6.527 3.808-6.527A430.01 430.01 0 0027 30.221a428.02 428.02 0 008.669 11.813s2.295 2.773 3.808 6.527c.794 1.968 1.138 3.678 1.031 5.127V54h4.778c.88 0 1.394-.972.886-1.676L26.998 25.796H27z"
        fill="#000"
      />
    </Svg>
  );
});

SavageMarkLogo.displayName = 'SavageLogo';

SavageMarkLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   * default multi-tone treatment.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default SavageMarkLogo;

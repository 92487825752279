// Updating this file? Try to keep them alphabetized so they're easy to find.

export const CancelReason = {
  TOO_EXPENSIVE: 31,
  DONT_UNDERSTAND_PROGRAM: 40,
  DISLIKE_SELECTIONS: 41,
  DISLIKE_QUALITY_OF_PRODUCT: 43,
  DISLIKE_AUTO_BILLING: 44,
  TERMS_MEMBERSHIP_FEE: 51,
  PACKAGE_NEVER_RECEIVED: 54,
  FRAUDULENT: 56,
  CANT_REMEMBER_TO_SKIP: 58,
  ORDER_NEVER_SHIPPED: 60,
  OTHER: 99,
  POOR_MEMBER_EXPERIENCE: 216,
  MOVING_OUT_OF_COUNTRY: 217,
  DUPLICATE_ACCOUNT: 218,
  NOT_ENOUGH_INVENTORY: 219,
  TEST_ACCOUNT: 220,
  COVID_19: 221,
} as const;

export const CcpaRequestSource = {
  AGENT: 1,
  CHAT: 2,
  WEB: 3,
  MOBILEAPP: 4,
} as const;

export const CcpaRequestType = {
  ERASE: 1,
  ACCESS: 2,
  OPT_OUT: 4,
} as const;

export const Gender = {
  FEMALE: 'F',
  MALE: 'M',
} as const;

export const apiGenderMap = {
  [Gender.FEMALE]: 'female',
  [Gender.MALE]: 'male',
} as const;

export const MembershipCreditReducerAction = {
  REQUEST: 'request',
  SUCCESS: 'success',
  ERROR: 'error',
  CANCEL: 'cancel',
} as const;

export const MembershipCreditReducerStatus = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
  IDLE: 'idle',
} as const;

export const MembershipType = {
  CREDIT: 1,
  FEE: 2,
  TOKEN: 3,
  TOKEN_ANNIVERSARY: 4,
} as const;

export const MembershipUpdateStatus = {
  CANCEL: 'cancel',
} as const;

export const PaymentMethodStatus = {
  NONE: 'NONE',
  OK: 'OK',
  EXPIRING: 'EXPIRING',
  EXPIRED: 'EXPIRED',
} as const;

export const ReturnAction = {
  RETURN: 10,
  RETURN_REFUND_CREDIT_CARD: 11,
  RETURN_REFUND_CREDIT_CARD_AND_STORE_CREDIT: 12,
  RETURN_STORE_CREDIT: 15,
  RETURN_MEMBERSHIP_CREDIT: 16,
  NO_REFUND: 19,
  EXCHANGE: 20,
  EXCHANGE_DIFFERENT_PRODUCT: 21,
  EXCHANGE_CHANGE_COLOR_SIZE: 22,
  EXCHANGE_RESHIP_SAME: 25,
} as const;

export const ReturnCondition = {
  NEW: 1,
  USED: 2,
  DAMAGED: 3,
} as const;
export const ReturnReason = {
  FIT_TOO_LONG: 140,
  FIT_TOO_LARGE: 148,
  FIT_TOO_SMALL: 149,
  DID_NOT_LIKE_FABRIC: 150,
  DID_NOT_LIKE_COLOR: 151,
  DID_NOT_LIKE_STYLE: 152,
  PURCHASED_WRONG_SIZE: 153,
  CHANGED_MY_MIND: 137,
  ORDERED_MULTIPLE_SIZE_COLORS_FOR_BEST_FIT: 154,
  GIFT: 155,
  NOT_AS_PICTURED: 138,
  ARRIVED_LATE: 157,
  DAMAGED_OR_DEFECTIVE: 156,
  RECEIVED_WRONG_ITEM: 158,
  OTHER: 99,
} as const;

export const SkipReason = {
  BUDGET: 21,
  SELECTIONS: 22,
  TIME: 23,
  NOTHING_WANTED: 24,
  NOTHING_LIKED: 25,
  SOLDOUT: 26,
  ADMIN: 32,
  CONSOLE: 31,
  IVR: 33,
  OTHER: 99,
} as const;

export const SkipTheMonthStatus = {
  CANCELLED: 3951,
  CREDIT_FAILED: 3954,
  CREDIT_PENDING: 3956,
  CREDITED: 3957,
  ERROR_DURING_PROCESSING: 3952,
  EMP_BILLED: 5170,
  EMP_BILLED_DOWNGRADED_IN_CYCLE: 5171,
  EMP_BILLING_FAILED_HARD: 5161,
  EMP_BILLING_FAILED_PERMANENT: 5162,
  EMP_BILLING_FAILED_SOFT: 5160,
  EMP_CANCELLED: 5151,
  EMP_ERROR_DURING_PROCESSING: 5158,
  EMP_MARKED_FOR_PROCESSING: 5155,
  EMP_PENDING: 5150,
  EMP_PROCESSING: 5157,
  EMP_SKIPPED: 5168,
  MARKED_FOR_CREDIT: 3953,
  PENDING: 3950,
  PURCHASED: 3959,
  SKIPPED: 3955,
} as const;

export const OrdersReducerAction = {
  REQUEST: 'request',
  SUCCESS: 'success',
  ERROR: 'error',
  CANCEL: 'cancel',
  RESET: 'reset',
} as const;

export const WaitlistType = {
  EMAIL_NOTIFICATION: 1,
  AUTO_PURCHASE: 2,
  PREORDER: 3,
} as const;

export const OrderTypeId = {
  CONTINUITY_AUTOSHIP: 1,
  RISK_FREE_TRIAL_COMPLETION: 2,
  TEST_ORDER: 3,
  HISTORICAL: 4,
  NON_SHIPPABLE_ORDER: 5,
  RESHIP: 6,
  MANUAL_REVIEW: 7,
  DEFERRED: 8,
  MEMBERSHIP_FEE: 9,
  MEMBERSHIP_CREDIT: 10,
  EXCHANGE: 11,
  MEMBERSHIP_PRODUCT_WAIT_LIST: 12,
  NON_SUBSCRIPTION_ORDER: 13,
  WHOLESALE: 14,
  SAMPLE_REQUEST_MARKETING: 15,
  SAMPLE_REQUEST_MERCHANDISING: 16,
  SAMPLE_REQUEST_PR: 17,
  MEMBERSHIP_AUTOSHIP: 18,
  RETAIL: 19,
  RETAIL_REPLENISH_RUSH: 20,
  RETAIL_REPLENISH_OVERNIGHT: 21,
  RETAIL_REPLENISH_STANDARD: 22,
  MEMBERSHIP_CONVERSION_ORDER: 23,
  SAMPLE_REQUEST_SOCIAL: 24,
  BOX_SUBSCRIPTION: 25,
  BOX_EXCHANGE: 26,
  RETAIL_UNIFORM: 27,
  RETAIL_MASTER: 28,
  RETAIL_MARKETING: 29,
  SPLIT_ORDER: 30,
  UPSELL: 31,
  BORDER_FREE: 32,
  FIRST_ONLINE_RETAIL_ORDER: 33,
  SUBSCRIPTION_ORDER: 34,
  PRODUCT_SEEDING_ORDER: 35,
  PREORDER: 36,
  SPLIT_ORDER_MASTER: 37,
  SPLIT_ORDER_CHILD: 38,
  MEMBERSHIP_TOKEN: 39,
  RETAIL_BOPS: 40,
  RETAIL_BOSS: 41,
  THRED_UP_BAG: 42,
  CUSTOMIZED_PREPAY_REQUIRED: 43,
  FIRST_GUEST: 44,
  REPEAT_GUEST: 45,
  DROPSHIP: 46,
  CUSTOMIZATION: 47,
} as const;

export const LoyaltyHistoryReducerAction = {
  REQUEST: 'request',
  SUCCESS: 'success',
  ERROR: 'error',
  CANCEL: 'cancel',
  RESET: 'reset',
} as const;

import React from 'react';

import PropTypes from 'prop-types';

import { Script } from '../../../../techstyle-shared/react-marketing';
import { useProductContext } from '../ProductContext';
import useProductDetailSchema from '../useProductDetailSchema';

function ProductDetailSchema({
  additionalFields,
  brand,
  canonicalUrl,
  currency,
  product: productFromProps,
}) {
  const productFromContext = useProductContext();
  const product = productFromProps || productFromContext;

  const { schemaContent } = useProductDetailSchema({
    additionalFields,
    brand,
    canonicalUrl,
    currency,
    product,
  });

  return (
    <Script
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaContent) }}
      globalScriptKey={`productJSON-${product.masterProductId}`}
      type="application/ld+json"
    />
  );
}

ProductDetailSchema.propTypes = {
  additionalFields: PropTypes.object,
  brand: PropTypes.string,
  canonicalUrl: PropTypes.string,
  currency: PropTypes.string,
  product: PropTypes.object,
};

export default ProductDetailSchema;

import React, { useCallback, useContext, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Transition, animated } from 'react-spring';
import styled from 'styled-components';

import usePopout, { PopoutPlacements } from '../usePopout';

const TooltipContext = React.createContext();

export function useTooltipState() {
  return useContext(TooltipContext);
}

const AnchorContainer = styled.div``;

const Arrow = styled.div``;

// Do not style this BodyContent. Since it is animated, style here will always be loaded at last.
const BodyContent = animated(styled.div``);

export function TooltipAnchor({ children, ...rest }) {
  const context = useTooltipState();

  return (
    <AnchorContainer {...context.anchorProps} {...rest}>
      {children}
    </AnchorContainer>
  );
}

TooltipAnchor.propTypes = {
  children: PropTypes.node,
};

export function TooltipArrow(props) {
  const context = useTooltipState();

  return <Arrow {...context.arrowProps} {...props} />;
}
export function TooltipContent__() {
  return null;
}
export function TooltipContent({
  animationDisabled = false,
  children,
  springConfig = {
    tension: 500,
    friction: 40,
  },
  transitionEnter = { opacity: 1 },
  transitionFrom = { opacity: 0 },
  transitionLeave = { opacity: 0 },
  ...rest
}) {
  const context = useTooltipState();

  return (
    <Transition
      items={context.isShowing}
      from={{
        ...transitionFrom,
      }}
      enter={{
        ...transitionEnter,
      }}
      leave={{
        ...transitionLeave,
      }}
      immediate={animationDisabled}
      config={springConfig}
      unique
    >
      {(styles, isVisible) =>
        isVisible && (
          <BodyContent
            aria-hidden={!context.isShowing}
            {...context.contentProps}
            style={{
              ...context.contentProps.style,
              ...styles,
            }}
            {...rest}
          >
            {children}
          </BodyContent>
        )
      }
    </Transition>
  );
}

TooltipContent.propTypes = {
  animationDisabled: PropTypes.bool,
  children: PropTypes.node,
  springConfig: PropTypes.object,
  transitionEnter: PropTypes.object,
  transitionFrom: PropTypes.object,
  transitionLeave: PropTypes.object,
};

export default function Tooltip({
  children,
  isShowing: isShowingFromProps,
  onHide,
  onShow,
  placement,
  xOffset,
  yOffset,
}) {
  const [isShowing, setIsShowing] = useState(false);

  const { arrowRef, anchorRef, popoutRef, attributes, styles } = usePopout({
    placement,
    xOffset,
    yOffset,
  });

  const showTooltip = useCallback(
    (event) => {
      if (isShowingFromProps != null) {
        if (onShow) {
          onShow(event);
        }
      } else {
        setIsShowing(true);
      }
    },
    [onShow, isShowingFromProps]
  );

  const hideTooltip = useCallback(
    (event) => {
      if (isShowingFromProps != null) {
        if (onHide) {
          onHide(event);
        }
      } else {
        setIsShowing(false);
      }
    },
    [onHide, isShowingFromProps]
  );

  const toggleTooltip = useCallback(() => {
    if (isShowing) {
      hideTooltip();
    } else {
      showTooltip();
    }
  }, [hideTooltip, isShowing, showTooltip]);

  const anchorProps = {
    onBlur: hideTooltip,
    onFocus: showTooltip,
    onMouseEnter: showTooltip,
    onMouseLeave: hideTooltip,
    onTouchStart: toggleTooltip,
    ref: anchorRef,
  };

  const arrowProps = useMemo(
    () => ({
      ref: arrowRef,
      style: styles.arrow,
    }),
    [arrowRef, styles.arrow]
  );

  const contentProps = useMemo(
    () => ({
      ...attributes.popper,
      style: styles.popper,
      ref: popoutRef,
    }),
    [attributes.popper, styles.popper, popoutRef]
  );

  const context = useMemo(
    () => ({
      anchorProps,
      arrowProps,
      contentProps,
      isShowing,
      showTooltip,
      hideTooltip,
      toggleTooltip,
    }),
    [
      anchorProps,
      arrowProps,
      contentProps,
      hideTooltip,
      isShowing,
      showTooltip,
      toggleTooltip,
    ]
  );

  return (
    <TooltipContext.Provider value={context}>
      {children}
    </TooltipContext.Provider>
  );
}

Tooltip.propTypes = {
  children: PropTypes.node,
  isShowing: PropTypes.bool,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
  placement: PropTypes.oneOf(Object.values(PopoutPlacements)),
  xOffset: PropTypes.number,
  yOffset: PropTypes.number,
};

Tooltip.Anchor = TooltipAnchor;
Tooltip.Arrow = TooltipArrow;
Tooltip.Content = TooltipContent;
Tooltip.useState = useTooltipState;

import React, { useContext } from 'react';

export const LoadingStatusContext = React.createContext(null);

export default function useLoadingStatus(name) {
  let parent = useContext(LoadingStatusContext);
  if (name) {
    while (parent && parent.name !== name) {
      parent = parent.parent;
    }
  }
  if (!parent) {
    throw new Error(
      name
        ? `No LoadingStatus named “${name}” was found here. Did you render a <LoadingStatus name="${name}"> ancestor?`
        : 'No LoadingStatus was found here. Did you render a <LoadingStatus> ancestor?'
    );
  }
  return useContext(parent.Context);
}

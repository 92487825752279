export const initialState = {
  activePanel: null,
  lastActivePanel: null,
  isOpen: false,
  panels: {},
  // Whenever these change, they will be run with `useLayoutEffect`. This allows
  // us to call arbitrary functions after state changes, since the reducer
  // itself must be pure.
  effects: [],
};

export default function reducer(state, action) {
  switch (action.type) {
    case 'open': {
      if (!state.isOpen) {
        // If no panel is selected, select the first one.
        const activePanel =
          state.activePanel ||
          state.lastActivePanel ||
          Object.keys(state.panels).find((id) => state.panels[id] != null) ||
          null;
        const effects = [];
        if (activePanel) {
          const panel = state.panels[activePanel];
          if (panel && panel.onActivate) {
            effects.push(() => panel.onActivate());
          }
        }
        return {
          ...state,
          isOpen: true,
          activePanel,
          lastActivePanel: activePanel,
          effects,
        };
      }
      return state;
    }
    case 'close':
      if (state.isOpen) {
        return { ...state, isOpen: false, activePanel: null, effects: [] };
      }
      return state;
    case 'activate': {
      const panel = state.panels[action.panel];
      const effects = [];
      if (panel && panel.onActivate) {
        effects.push(() => panel.onActivate(action.state));
      }
      return {
        ...state,
        isOpen: true,
        activePanel: action.panel,
        lastActivePanel: action.panel,
        effects,
      };
    }
    case 'register': {
      const effects = [];
      // It's possible that a panel is re-registering itself after changing some
      // of its properties (like the icon, `onActivate`, etc.). So it could
      // already be the active panel.
      if (state.activePanel === action.panel.id && action.panel.onActivate) {
        effects.push(() => action.panel.onActivate());
      }
      return {
        ...state,
        panels: {
          ...state.panels,
          [action.panel.id]: action.panel,
        },
        effects,
      };
    }
    case 'unregister': {
      let activePanel = state.activePanel;
      if (activePanel === action.id) {
        activePanel =
          Object.keys(state.panels).find(
            (id) => state.panels[id] != null && id !== action.id
          ) || null;
      }
      return {
        ...state,
        activePanel,
        lastActivePanel: activePanel,
        panels: {
          ...state.panels,
          [action.id]: null,
        },
        effects: [],
      };
    }
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}

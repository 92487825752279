import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { createProductListingQueryParams } from '../../../../techstyle-shared/react-products';
import { buildURL } from '../../../../techstyle-shared/redux-core';
import { LabelType, useCategoryLabels } from '../LabelProvider';
import { useProductBrowser } from '../ProductBrowserContext';
import { getCategoryTree, categoryPropType } from '../utils/category';

const CategoryItems = styled.ul`
  list-style: none;
  text-align: left;
  font-size: 14px;
  line-height: ${18 / 14};

  & & {
    padding-top: 7px;
    padding-left: 8px;
  }

  & & & {
    padding-left: 24px;
  }

  ${({ categoryListStyle }) => categoryListStyle};
`;

const CategoryItem = styled.li`
  padding: 7px 0 ${(props) => (props.hasChildren ? 0 : 7)}px 0;

  ${({ categoryItemStyle }) => categoryItemStyle};
`;

const CategoryLink = styled.a`
  text-decoration: none;
  color: ${({ isActive, isAncestor }) =>
    isActive || isAncestor ? '#333' : '#999'};

  ${({ categoryLinkStyle }) => categoryLinkStyle};
`;

const NON_LETTER_CHAR = /[^a-z]/g;
const formatAutoTag = (category) => {
  return category.label && typeof category.label === 'string'
    ? `prodgrid-filter-${
        category.isTopLevel
          ? 'all'
          : category.label.toLowerCase().replace(NON_LETTER_CHAR, '')
      }`
    : undefined;
};

const Category = ({
  activeCategory,
  activeCategoryChecker,
  category,
  categoryItemStyle,
  categoryLinkStyle,
  categoryListStyle,
  depth,
  preserveFilters,
  hideInactiveSubcategories,
}) => {
  const isActive =
    activeCategoryChecker(category) === activeCategoryChecker(activeCategory);

  const subcategories = category.subcategories || [];

  const categoryLabelCtx = useCategoryLabels();
  const isTopLevel = depth === 0 && (isActive || category.isAncestor);
  const labelOptions = {
    isActive,
    depth,
    isTopLevel,
    labelType: LabelType.CATEGORY_TREE,
  };

  const showSubcategories = hideInactiveSubcategories
    ? subcategories.length && activeCategory.urlPath.includes(category.urlPath)
    : !!subcategories.length;

  const productBrowserCtx = useProductBrowser();

  const href = useMemo(() => {
    if (preserveFilters && productBrowserCtx) {
      const {
        filters,
        baseFilters,
        sort: sortOption,
        mySize,
      } = productBrowserCtx;

      const params = createProductListingQueryParams({
        filters:
          typeof preserveFilters === 'function'
            ? preserveFilters(filters, category)
            : filters,
        baseFilters,
        sortOption,
        mySize,
      });

      return buildURL(category.urlPath, params);
    }
    return category.urlPath;
  }, [category, preserveFilters, productBrowserCtx]);

  return (
    <CategoryItem
      hasChildren={subcategories.length > 0}
      categoryItemStyle={categoryItemStyle}
    >
      <CategoryLink
        isActive={isActive}
        isAncestor={category.isAncestor}
        href={href}
        data-autotag={formatAutoTag(category)}
        categoryLinkStyle={categoryLinkStyle}
      >
        {categoryLabelCtx.renderLabel(category, labelOptions)}
      </CategoryLink>
      {showSubcategories ? (
        <CategoryItems categoryListStyle={categoryListStyle}>
          {subcategories.map((subcategory) => (
            <Category
              key={activeCategoryChecker(subcategory)}
              category={subcategory}
              activeCategory={activeCategory}
              activeCategoryChecker={activeCategoryChecker}
              categoryItemStyle={categoryItemStyle}
              categoryLinkStyle={categoryLinkStyle}
              categoryListStyle={categoryListStyle}
              depth={depth + 1}
              preserveFilters={preserveFilters}
              hideInactiveSubcategories={hideInactiveSubcategories}
            />
          ))}
        </CategoryItems>
      ) : null}
    </CategoryItem>
  );
};

Category.propTypes = {
  activeCategory: categoryPropType,
  activeCategoryChecker: PropTypes.func,
  category: categoryPropType,
  categoryItemStyle: PropTypes.any,
  categoryLinkStyle: PropTypes.any,
  categoryListStyle: PropTypes.any,
  depth: PropTypes.number,
  hideInactiveSubcategories: PropTypes.bool,
  preserveFilters: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOfType]),
};

export default function ProductCategoryTree({
  activeCategory: activeCategoryFromProps,
  activeCategoryChecker = (category) => category.productCategoryId,
  categoryItemStyle,
  categoryLinkStyle,
  categoryListStyle,
  preserveFilters = false,
  hideInactiveSubcategories = false,
  showActiveSubcategories = true,
  showSiblingCategories = true,
  ...rest
}) {
  const productBrowserCtx = useProductBrowser();

  let activeCategory = activeCategoryFromProps;

  if (productBrowserCtx) {
    const { activeCategory: activeCategoryFromCtx } = productBrowserCtx;
    if (!activeCategoryFromProps) {
      activeCategory = activeCategoryFromCtx;
    }
  }

  const tree = useMemo(
    () =>
      getCategoryTree(
        activeCategory,
        showActiveSubcategories,
        showSiblingCategories,
        activeCategoryChecker
      ),
    [
      activeCategory,
      showActiveSubcategories,
      showSiblingCategories,
      activeCategoryChecker,
    ]
  );

  return (
    <CategoryItems categoryListStyle={categoryListStyle} {...rest}>
      {tree.map((category, index) => (
        <Category
          key={activeCategoryChecker(category)}
          category={category}
          activeCategory={activeCategory}
          activeCategoryChecker={activeCategoryChecker}
          categoryItemStyle={categoryItemStyle}
          categoryLinkStyle={categoryLinkStyle}
          categoryListStyle={categoryListStyle}
          depth={0}
          preserveFilters={preserveFilters}
          hideInactiveSubcategories={hideInactiveSubcategories}
        />
      ))}
    </CategoryItems>
  );
}

ProductCategoryTree.propTypes = {
  activeCategory: categoryPropType,
  activeCategoryChecker: PropTypes.func,
  categoryItemStyle: PropTypes.any,
  categoryLinkStyle: PropTypes.any,
  categoryListStyle: PropTypes.any,
  hideInactiveSubcategories: PropTypes.bool,
  preserveFilters: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  showActiveSubcategories: PropTypes.bool,
  showSiblingCategories: PropTypes.bool,
};

import { createReducer, createSelector } from '@reduxjs/toolkit';
import urlTemplate from 'url-template';

import createAction from './createAction';

export const initialState = {};

export const setDomain = createAction('domain/setDomain');

export function initDomain() {
  return (dispatch, getState, context) => {
    if (context.isServer && !context.hasPreloadedState) {
      const { domain } = context.req.context;
      dispatch(setDomain(domain));
    }
  };
}

export const getDomainRedirectFunction = createSelector(
  [(state) => state.domain],
  (domain) => {
    const template = urlTemplate.parse(domain.template);
    return (inputValues) => {
      if (typeof inputValues === 'string') {
        inputValues = { domain: inputValues };
      }
      const values = {
        subdomain: domain.subdomain,
        name: domain.name,
        tld: domain.tld,
        ...inputValues,
      };
      if (!values.domain) {
        values.domain = [
          values.subdomain || '',
          values.name || '',
          values.tld || '',
        ].join('');
      }
      if (!values.pathname) {
        values.pathname = '/';
      }
      if (typeof values.hash === 'string') {
        if (values.hash === '') {
          values.hash = null;
        } else if (values.hash.startsWith('#')) {
          values.hash = values.hash.slice(1);
        }
      }
      return template.expand(values);
    };
  }
);

export const domainReducer = createReducer(initialState, {
  [setDomain]: (state, action) => action.payload,
});

export default {
  id: 'domain',
  reducerMap: {
    domain: domainReducer,
  },
  initialActions: [initDomain()],
};

import React, { useEffect, useMemo } from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import { useIntl } from '../../../../techstyle-shared/react-intl';
import { Script } from '../../../../techstyle-shared/react-marketing';

export function EqualWebSnippet({
  siteKey = config.get('public.equalWeb.siteKey'),
  position = 'left',
  btnStyle = {},
  ...rest
}) {
  const { locale } = useIntl();
  const menuLang = useMemo(() => locale.split('-')[0].toUpperCase(), [locale]);

  useEffect(() => {
    if (!window.interdeal) {
      // sets interdeal if not already set
      window.interdeal = {
        sitekey: siteKey,
        Position: position,
        Menulang: menuLang,
        btnStyle,
        domains: {
          js: 'https://aacdn.nagich.com/',
          acc: 'https://access.nagich.com/',
        },
      };
    }
  }, [menuLang, position, siteKey, btnStyle]);

  return (
    <Script
      async
      crossOrigin="anonymous"
      data-cfasync="true"
      globalScriptKey="ada-widget"
      integrity="sha512-SVffVpbO/SKg8zoE3NWu0Ct32mTRDO5b27N4qA5C+e6ctRHVwAk158RdBWMlaD2UuAtQUxeRwY71joiCkwsFVA=="
      skipServerRender
      src="https://aacdn.nagich.com/core/4.6.11/accessibility.js"
      {...rest}
    />
  );
}

EqualWebSnippet.propTypes = {
  /**
   * Button style for the widget menu.
   */
  btnStyle: PropTypes.object,
  /**
   * Position for widget menu.
   */
  position: PropTypes.oneOf(['left', 'right']),
  /**
   * sitekey for the window.interdeal options; if not set it comes from the
   * config value `public.equalWebWidget.siteKey`.
   */
  siteKey: PropTypes.string,
};

export default React.memo(EqualWebSnippet);

import PropTypes from 'prop-types';

import useABTest from '../useABTest';

function ABTest({ campaignCode, children }) {
  const testData = useABTest(campaignCode);

  return children(testData);
}

ABTest.propTypes = {
  campaignCode: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default ABTest;

import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadNav, invalidateNavs } from './navModule';

export default function useAccountActions() {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          loadNav,
          invalidateNavs,
        },
        dispatch
      ),
    [dispatch]
  );

  return actions;
}

import { useEffect, useMemo, useState } from 'react';

import useProductActions from './useProductActions';

export default function useRecommendedProducts(
  masterProductId,
  productOptions
) {
  const actions = useProductActions();
  const [products, setProducts] = useState({});
  const options = useMemo(
    () => productOptions,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(productOptions)]
  );

  useEffect(() => {
    const load = async () => {
      const result = await actions.loadRecommendedProducts(
        masterProductId,
        options
      );
      setProducts(result.payload);
    };

    if (masterProductId) {
      load();
    }
  }, [actions, options, masterProductId]);

  return products;
}

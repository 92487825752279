import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';

import { useIntl } from '../../../../techstyle-shared/react-intl';
import { Script } from '../../../../techstyle-shared/react-marketing';
import logger from '../logger';
import useDelay from '../useDelay';
import usePolling from '../usePolling';

const debug = logger.extend('ReCAPTCHA');

// Promises that will track whether the JavaScript API for a particular locale
// has been loaded.
const apiPromises = {};

// The ReCAPTCHA API supports a few extended locales (with region) such as
// `en-GB`, but for most locales you must pass only the 2-letter language tag.
// See: https://developers.google.com/recaptcha/docs/language
const supportedExtendedLocales = new Set(['en-GB', 'fr-CA', 'de-AT', 'de-CH']);

function getWidgetLocale(locale) {
  if (!locale) {
    return undefined;
  }
  if (supportedExtendedLocales.has(locale)) {
    return locale;
  }
  const [primaryLanguage] = locale.split('-', 1);
  return primaryLanguage;
}

const Wrapper = styled.div`
  text-align: ${(props) => props.alignBox || 'inherit'};
`;

const BoundingBox = styled.div`
  display: inline-block;
  position: relative;
  /* Since the ReCAPTCHA widget loads dynamically, it starts out with no
     height. Add a minimum here to prevent it from pushing stuff around too
     much. */
  min-width: 304px;
  min-height: 78px;
  vertical-align: top;

  &[data-recaptcha-size='compact'] {
    min-width: 164px;
    min-height: 144px;
  }
`;

const progress = keyframes`
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  margin: auto 30px;
  border-radius: 2px;
  background: rgb(106, 160, 218);
  transform: scaleX(0);
  transform-origin: 0% 50%;
  animation: ${progress} 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  animation-delay: 200ms;
`;

export const Placeholder = styled.div.attrs({
  children: <ProgressBar />,
})`
  /* These styles are designed to look exactly like the real ReCAPTCHA
     container. */
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 74px;
  max-width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  background: #f9f9f9;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.08);
  opacity: 0;
  transition-property: opacity;
  transition-duration: ${(props) => props.transitionDuration || 0}ms;
  transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  pointer-events: none;

  &[data-recaptcha-theme='dark'] {
    border-color: #525252;
    background: #222;
  }

  &[data-recaptcha-size='compact'] {
    width: 156px;
    height: 136px;
    border: 0;
  }

  &[data-transition-state='entered'] {
    opacity: 1;
  }
`;

const Positioned = styled.div`
  position: relative;
`;

function checkForSelector(selector) {
  if (selector) {
    const result = document.querySelector(selector) != null;
    if (result) {
      debug('Found selector: %s', selector);
    }
    return result;
  }
  return false;
}

/**
 * ReCAPTCHA v2 as a React component. It behaves in much the same way as
 * [https://github.com/dozoisch/react-google-recaptcha](react-google-recaptcha)
 * but with some improvements.
 *
 * - The `siteKey` is automatically read from the `public.recaptcha.siteKey`
 *   config property if set.
 * - The `hl` parameter for loading a particular language is automatically set
 *   to the site's current locale via the `useIntl()` hook supplied by
 *   `@techstyle/react-intl`.
 * - The JavaScript API for ReCAPTCHA will only be loaded once (per language)
 *   instead of every time the component mounts.
 * - The ReCAPTCHA API does not offer an official way to destroy the widget if
 *   you decide to stop rendering it (like will happen on SPA navigation). This
 *   component takes care of the cleanup.
 */
const ReCAPTCHA = React.memo(function ReCAPTCHA({
  align,
  className,
  locale: localeFromProps,
  onChange,
  onError,
  onExpired,
  placeholder,
  renderDelay,
  siteKey = config.get('public.recaptcha.siteKey'),
  size,
  style,
  tabIndex,
  theme,
  transitionDuration,
}) {
  const parentRef = useRef();
  const onChangeRef = useRef(onChange);
  const onErrorRef = useRef(onError);
  const onExpiredRef = useRef(onExpired);
  const doneDelaying = useDelay(renderDelay);
  const [api, setApi] = useState();

  // After calling the ReCAPTCHA API's `render` method, the widget is usually
  // still not visible, because the actual content is contained in an iframe
  // that also must load. This can take a while, especially on slow connections.
  // In order to know when the widget is actually displayed, we poll for the
  // existence of an element that gets added when rendering is complete. This
  // depends on an implementation detail of the ReCAPTCHA widget, so if that
  // takes too long (via reaching `maxPollCount`) we'll bail out and stop
  // showing the placeholder regardless of whether we know the render status
  // or not.
  const [pollSelector, setPollSelector] = useState();
  const checkWidgetRendered = useMemo(() => {
    if (pollSelector) {
      return () => checkForSelector(pollSelector);
    }
  }, [pollSelector]);
  const widgetRendered = usePolling({
    condition: checkWidgetRendered,
    interval: 500,
    maxPollCount: 60,
  });

  const intl = useIntl();
  const localeFromContext = intl ? intl.locale : undefined;
  const locale = localeFromProps || localeFromContext;
  const widgetLocale = getWidgetLocale(locale);
  const onloadCallback = `onReCAPTCHALoaded_${
    widgetLocale ? widgetLocale.replace(/-/g, '_') : 'auto'
  }`;

  const handleResponse = useCallback((token) => {
    if (onChangeRef.current) {
      onChangeRef.current(token);
    }
  }, []);

  const handleExpired = useCallback((...args) => {
    if (onExpiredRef.current) {
      onExpiredRef.current(...args);
    } else if (onChangeRef.current) {
      onChangeRef.current(null);
    }
  }, []);

  const handleError = useCallback((...args) => {
    if (onErrorRef.current) {
      onErrorRef.current(...args);
    }
  }, []);

  useEffect(() => {
    onChangeRef.current = onChange;
    onErrorRef.current = onError;
    onExpiredRef.current = onExpired;
  }, [onChange, onError, onExpired]);

  useEffect(() => {
    if (!apiPromises[onloadCallback]) {
      debug('Creating new grecaptcha Promise for callback: %s', onloadCallback);
      apiPromises[onloadCallback] = new Promise((resolve, reject) => {
        window[onloadCallback] = function onReCAPTCHALoaded() {
          resolve(window.grecaptcha);
        };
      });
    }

    let ignoreResult = false;

    const updateWhenReady = async () => {
      const api = await apiPromises[onloadCallback];
      debug('Received grecaptcha API for callback: %s', onloadCallback);
      if (!ignoreResult) {
        setApi(api);
      }
    };

    updateWhenReady();

    return () => {
      ignoreResult = true;
    };
  }, [onloadCallback]);

  useEffect(() => {
    if (doneDelaying && api) {
      // Don't render into `parentRef` directly, instead create a node
      // specifically for each `render` call.
      let containerNode = document.createElement('div');
      const parentNode = parentRef.current;
      parentNode.appendChild(containerNode);

      const widgetId = api.render(containerNode, {
        sitekey: siteKey,
        size,
        tabindex: tabIndex,
        theme,
        callback: handleResponse,
        'error-callback': handleError,
        'expired-callback': handleExpired,
      });
      debug('Rendered widget %s.', widgetId);

      // This is a fallback selector if we don't find an iframe matching the
      // expected pattern. It has the downside of not being specific to this
      // widget instance, but will exist if any other ReCAPTCHA widget exists
      // on the page.
      const fallbackSelector = '.g-recaptcha-bubble-arrow';
      let instanceSelector;
      let iframe = containerNode.querySelector('iframe[name^="a-"]');
      if (iframe) {
        const name = iframe.getAttribute('name');
        if (name) {
          // Selector specific to this widget instance.
          instanceSelector = `iframe[name="c-${name.slice(2)}"]`;
        }
        // Abundance of caution: don't retain a reference to this node for
        // longer than we need to.
        iframe = undefined;
      }
      const pollSelector = instanceSelector || fallbackSelector;
      debug('Polling for selector: %s', pollSelector);
      setPollSelector(pollSelector);

      const textarea = document.getElementById('g-recaptcha-response');
      if (textarea) {
        textarea.setAttribute('aria-hidden', 'true');
        textarea.setAttribute('aria-label', 'do not use');
        textarea.setAttribute('aria-readonly', 'true');
      }

      return () => {
        // Amazingly, there is no valid way to destroy the widget. Calling
        // `reset` replaces the widget's DOM nodes. If the container it rendered
        // into no longer exists, eventually it will throw an unhandled Promise
        // rejection. We have two options: let the error happen (probably fine,
        // but noisy), or use a workaround where we clean up the widget's DOM
        // nodes ourselves. We do the latter below, inspired by
        // `react-google-recaptcha` but improved.

        let zombieParent = document.createElement('div');
        zombieParent.style.display = 'none';
        document.body.appendChild(zombieParent);
        zombieParent.appendChild(containerNode);
        // Abundance of caution: don't retain a reference to this node for
        // longer than we need to.
        containerNode = undefined;

        // Clean up all the widget's DOM nodes a minute from now.
        setTimeout(() => {
          if (zombieParent.parentNode) {
            zombieParent.parentNode.removeChild(zombieParent);
          }
          // Abundance of caution: don't retain a reference to this node for
          // longer than we need to.
          zombieParent = undefined;

          // In addition to the element that gets injected into the `container`
          // we created, ReCAPTCHA also adds stuff to the document body. Those
          // need to be cleaned up too.
          if (instanceSelector) {
            let element = document.querySelector(instanceSelector);
            while (element) {
              // Find the topmost ReCAPTCHA node and remove it.
              if (element.parentNode === document.body) {
                // Found it, remove.
                element.parentNode.removeChild(element);
                break;
              } else if (element.parentNode === parentNode) {
                // It could be possible for ReCAPTCHA to change their
                // implementation to stick the extra nodes in the container we
                // gave it. If that happens, bail out of cleanup; we should be
                // all good.
                break;
              } else {
                element = element.parentNode;
              }
            }
            // Abundance of caution: don't retain a reference to this node for
            // longer than we need to.
            element = undefined;
          }
        }, 60000);

        api.reset(widgetId);
      };
    }
  }, [
    api,
    doneDelaying,
    handleError,
    handleExpired,
    handleResponse,
    siteKey,
    size,
    tabIndex,
    theme,
  ]);

  const scriptElement = useMemo(() => {
    let src = `https://www.google.com/recaptcha/api.js?onload=${onloadCallback}&render=explicit`;
    if (widgetLocale) {
      src += `&hl=${widgetLocale}`;
    }
    return (
      <Script
        src={src}
        async
        globalScriptKey={['recaptcha', onloadCallback]}
        skipServerRender
      />
    );
  }, [onloadCallback, widgetLocale]);

  return (
    // Add an additional div wrapper, otherwise content after the ReCAPTCHA will
    // be placed alongside content before it, since it initially renders as an
    // inline element.
    <Wrapper className={className} style={style} alignBox={align}>
      <BoundingBox data-recaptcha-size={size} data-recaptcha-theme={theme}>
        <Transition in={!widgetRendered} timeout={transitionDuration}>
          {(state) =>
            React.cloneElement(placeholder, {
              'data-recaptcha-placeholder': '',
              'data-transition-state': state,
              'data-recaptcha-size': size,
              'data-recaptcha-theme': theme,
              transitionDuration,
            })
          }
        </Transition>
        <Positioned ref={parentRef} />
      </BoundingBox>
      {doneDelaying ? scriptElement : null}
    </Wrapper>
  );
});

ReCAPTCHA.propTypes = {
  /**
   * How to align the ReCAPTCHA widget within its container element.
   */
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
  /**
   * The class to apply to the wrapper element.
   */
  className: PropTypes.string,
  /**
   * The locale to use. It must be one of the locales supported by ReCAPTCHA
   * according to their documentation. If not provided, the locale is determined
   * automatically from the `intl` context.
   */
  locale: PropTypes.string,
  /**
   * A function to call with the ReCAPTCHA token when the user submits their
   * verification. If the token expires and no `onExpired` handler is passed,
   * this will be called with `null`.
   */
  onChange: PropTypes.func,
  /**
   * A function to call when ReCAPTCHA encounters an error.
   */
  onError: PropTypes.func,
  /**
   * A function to call when the token expires.
   */
  onExpired: PropTypes.func,
  /**
   * Content to render while the ReCAPTCHA widget is loading. By default it’s an
   * empty box that looks very much like the ReCAPTCHA container.
   */
  placeholder: PropTypes.element,
  /**
   * Number of milliseconds to wait until actually loading the ReCAPTCHA widget.
   * Since it is often not critical to render right away, this can cut down on
   * stuttering caused by all the JavaScript and DOM elements that the widget
   * adds.
   */
  renderDelay: PropTypes.number,
  /**
   * The public site key value obtained from registering the site with the
   * reCAPTCHA service. By default this will be read from the
   * `public.recaptcha.siteKey` config property if set.
   */
  siteKey: PropTypes.string,
  /**
   * The size to render. There are two named sizes, normal or compact.
   */
  size: PropTypes.oneOf(['normal', 'compact']),
  /**
   * Inline styles to apply to the wrapper element.
   */
  style: PropTypes.object,
  /**
   * See: [react-google-recaptcha](https://www.npmjs.com/package/react-google-recaptcha)
   */
  tabIndex: PropTypes.number,
  /**
   * The theme to render, light or dark.
   */
  theme: PropTypes.oneOf(['light', 'dark']),
  /**
   * The transition duration of the `placeholder` element once the ReCAPTCHA
   * widget loads.
   */
  transitionDuration: PropTypes.number,
};

ReCAPTCHA.defaultProps = {
  align: 'inherit',
  placeholder: <Placeholder />,
  renderDelay: 500,
  transitionDuration: 1000,
};

ReCAPTCHA.Placeholder = Placeholder;

export default ReCAPTCHA;

import { useContext, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { AdminContext } from '../AdminProvider';

export default function AdminGuard({ children, mode }) {
  const { enabled, activePanelNode } = useContext(AdminContext);
  const hasBeenEnabled = useRef(enabled);

  let status;
  switch (mode) {
    case 'immediate':
      status = enabled;
      break;
    case 'once':
      status = enabled || hasBeenEnabled.current;
      break;
    case 'visible':
      status = activePanelNode != null;
      break;
  }

  useEffect(() => {
    if (enabled) {
      hasBeenEnabled.current = enabled;
    }
  }, [enabled]);

  return status ? children : null;
}

AdminGuard.propTypes = {
  /**
   * Children to render when Admin Tools are enabled.
   */
  children: PropTypes.node,
  /**
   * The method for determining the current Admin Tools status.
   * - If `immediate`, the status will change as soon as the Admin Tools become
   *   enabled or disabled.
   * - If `once`, the status will remain `true` once Admin Tools become enabled
   *   for the first time.
   * - If `visible` (the default), the status will change when the Admin Tools
   *   element is mounted or unmounted. This allows panels to remain mounted as
   *   the Admin Tools are transitioning out of view.
   */
  mode: PropTypes.oneOf(['immediate', 'once', 'visible']),
};

AdminGuard.defaultProps = {
  mode: 'visible',
};

/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';

import { FaCheck } from 'react-icons/fa';

import { SerializedOrderLineItem } from '../AccountOrderDetails';
import CheckboxRadioBase from '../CheckboxRadioBase';
import { CheckboxRadioBaseProps } from '../CheckboxRadioBase/CheckboxRadioBase';
import { createContext } from '../utils/createContext';

import { useContext as useRMAContext } from './RMAFlow';

type RMASelectionItemContext = {
  selected: boolean;
  lineItem: SerializedOrderLineItem;
  select: () => void;
  unselect: () => void;
};

const [useContext, Context] =
  createContext<RMASelectionItemContext>('RMASelectionItem');

type RMASelectionItemProps = {
  lineItem: SerializedOrderLineItem;
};

/**
 * A checkbox that extends CheckboxRadioBase that uses the RMASelectionItemContext to manage it's state.
 */
const Checkbox = React.forwardRef<HTMLInputElement, CheckboxRadioBaseProps>(
  ({ checkmarkIcon = FaCheck, type = 'checkbox', onChange, ...rest }, ref) => {
    const { selected, lineItem, select, unselect } = useContext();

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.currentTarget;
        if (checked) {
          select();
        } else {
          unselect();
        }

        if (onChange) {
          onChange(event);
        }
      },
      [onChange, select, unselect]
    );

    return (
      <CheckboxRadioBase
        type={type}
        checkmarkIcon={checkmarkIcon}
        ref={ref}
        {...rest}
        disabled={!lineItem.isReturnable}
        onChange={handleChange}
        checked={selected}
      />
    );
  }
);

Checkbox.displayName = 'RMACheckbox';

function Root({
  lineItem,
  children,
}: React.PropsWithChildren<RMASelectionItemProps>) {
  const { rmaItems, selectRMAItem, unselectRMAItem } = useRMAContext();
  const { orderLineId } = lineItem;
  const selected = !!rmaItems[orderLineId];

  const select = useCallback(() => {
    selectRMAItem(lineItem);
  }, [selectRMAItem, lineItem]);

  const unselect = useCallback(() => {
    unselectRMAItem(orderLineId);
  }, [unselectRMAItem, orderLineId]);

  const contextValue = useMemo(
    () => ({
      selected,
      lineItem,
      select,
      unselect,
    }),
    [selected, lineItem, select, unselect]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export { Root, Checkbox, useContext };

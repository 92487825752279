import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { MdAdd, MdRemove } from 'react-icons/md';
import styled, { css } from 'styled-components';

import {
  FormattedNumber,
  FormattedMessage,
} from '../../../../techstyle-shared/react-intl';
import Checkbox from '../Checkbox';
import Collapsible from '../Collapsible';
import Heading from '../Heading';
import {
  LabelType,
  useFilterFieldLabels,
  useOptionLabels,
} from '../LabelProvider';
import { useProductFilters } from '../ProductFilterContext';
import { useProductFilters as useProductFiltersCustomFilters } from '../ProductFilterContextCustomFilters';
import ProductSizeFilter from '../ProductSizeFilter';
import Section from '../Section';
import { desktop, mobile } from '../styles';
import useBreakpoint from '../useBreakpoint';
import useId from '../useId';
import slugify from '../utils/slugify';

import { ProductListingFilterStyle } from './constants';

const Wrapper = styled.div`
  box-shadow: inset 0 1px 0 0 #eee;

  :last-child {
    box-shadow: 0 1px 0 0 #eee, 0 -1px 0 0 #eee;
  }
`;

const FilterFieldButton = styled(Collapsible.Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 16px;
  text-align: left;

  ${desktop`
    padding: 12px 8px;
  `}

  ${({ $filterFieldButtonStyle }) => $filterFieldButtonStyle};
`;

const FilterFieldLabel = styled.span`
  font-size: 16px;
  color: #333;

  ${desktop`
    font-size: 14px;
    `}
  ${({ labelContainerStyle }) => labelContainerStyle};
`;

const FilterFieldLabelText = styled.span`
  ${({ labelStyle }) => labelStyle};
`;

const FilterValueSummary = styled.span`
  color: #999;
  ${({ filterCountStyle }) => filterCountStyle};
`;

const FilterList = styled.ul`
  padding-left: ${({ depth }) => (depth > 0 ? 8 : 24)}px;
  padding-bottom: ${({ depth }) => (depth > 0 ? 4 : 12)}px;
  padding-top: 6px;
  list-style: none;

  ${desktop`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 16px;
    padding-left: ${({ depth }) => (depth > 0 ? 8 : 16)}px;
    padding-bottom: 16px;
  `}

  ${({ listStyle }) => listStyle};
`;

const FilterWrappedList = styled.ul`
  padding-left: ${({ depth }) => (depth > 0 ? 8 : 24)}px;
  padding-bottom: ${({ depth }) => (depth > 0 ? 4 : 12)}px;
  padding-top: 6px;
  list-style: none;

  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;

  ${desktop`
    padding-left: 16px;
    padding-left: ${({ depth }) => (depth > 0 ? 8 : 16)}px;
    padding-bottom: 16px;
  `}

  ${({ listStyle }) => listStyle};
`;

const checkmarkStyle = css`
  font-size: 12px;
  color: #ddd;
  visibility: visible;

  ${mobile`font-size: 14px;`}
`;

const checkmarkCheckedStyle = css`
  color: #333;
`;

const checkmarkCheckingStyle = css`
  opacity: 1;
  color: #aaa;
`;

const checkmarkDisabledStyle = css`
  color: #333;
`;

const gridCheckmarkStyle = css`
  display: none;
`;

const inputCheckedStyle = css`
  background: none;
`;

const gridInputCheckedStyle = css`
  background: #eee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #333;
`;

const FilterCheckbox = styled(Checkbox).attrs((props) => {
  return {
    checkmarkStyle: props.checkmarkStyle || checkmarkStyle,
    checkmarkCheckedStyle: props.checkmarkCheckedStyle || checkmarkCheckedStyle,
    checkmarkCheckingStyle:
      props.checkmarkCheckingStyle || checkmarkCheckingStyle,
    checkmarkDisabledStyle:
      props.checkmarkDisabledStyle || checkmarkDisabledStyle,
    inputCheckedStyle: props.inputCheckedStyle || inputCheckedStyle,
  };
})`
  padding: 2px;
  margin: 0 6px 0 2px;
  border: none;
`;

const FilterItem = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 8px 0;
  ${({ disabled, disabledItemStyle }) =>
    disabled &&
    `
    opacity: 0.32;
    ${disabledItemStyle};
  `};

  ${desktop`
    padding: 4px 0;
  `}

  ${({ itemStyle }) => itemStyle};
`;

const FilterItemLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #333;
  padding: 4px 0;

  ${mobile`
    padding-left: 4px;
  `}

  ${({ itemLabelStyle }) => itemLabelStyle};
`;

const CircleIcon = styled.span`
  display: ${({ colorSwatches, value }) =>
    colorSwatches[value] ? 'block' : 'none'};
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  border-radius: 28px;
  ${({ backgroundImage }) =>
    backgroundImage && `background-image: url(${backgroundImage})`};
  background: ${({ value, colorSwatches }) => `${colorSwatches[value]}`};
  box-shadow: ${({ showBorder }) => showBorder && 'inset 0 0 0 1px #333'};

  ${desktop`
    height: 16px;
    width: 16px;
  `};

  ${({ circleIconStyle }) => circleIconStyle};
`;

const SwatchGridFilterItem = styled.li`
  justify-content: center;

  &:hover ${CircleIcon} {
    ${({ isChecked, disabled }) =>
      !isChecked &&
      !disabled &&
      `
        border: 2px solid #fff;
        box-shadow: 0 0 0 1px #18181E;
    `};
  }

  ${CircleIcon} {
    width: 32px;
    height: 32px;
    ${({ isChecked }) =>
      isChecked &&
      `
      border: 2px solid #fff;
      box-shadow: 0 0 0 2px #18181E;
    `};

    ${({ disabled, colorValue, colorSwatches, disabledItemStyle }) =>
      disabled &&
      `
      opacity: 0.3;
      background: linear-gradient(135deg, ${colorSwatches[colorValue]} calc(50% - 1px), #E5E4F4 50%, ${colorSwatches[colorValue]} calc(50% + 1px));
      color: #70758C;
      cursor: not-allowed;

      ${disabledItemStyle};
    `};
  }

  ${FilterItemLabel} {
    flex-direction: column;
    cursor: pointer;
    gap: 6px;

    ${({ disabled }) =>
      disabled &&
      `
      color: #70758C;
      cursor: not-allowed;
    `}

    ${CircleIcon} {
      margin: 0;
    }
  }

  ${FilterCheckbox} {
    display: none;
  }

  margin-right: 8px;

  ${({ itemStyle }) => itemStyle};
`;

const TagsGridFilterItem = styled.li`
  display: flex;
  margin: 0 8px 8px 0;

  ${FilterCheckbox} {
    display: none;
  }

  ${FilterItemLabel} {
    height: 40px;
    padding: 8px 16px 8px 16px;
    border-radius: 48px;
    border: 1px solid #ededf0;
    cursor: pointer;
    font-weight: 400;

    &:hover {
      ${({ isChecked, disabled }) =>
        !isChecked &&
        !disabled &&
        `
          border: 1px solid #18181E;
      `};
    }

    ${({ isChecked, disabled }) =>
      isChecked &&
      !disabled &&
      `
      border: 1px solid #18181E;
      color: #fff;
      background-color: #18181E;
    `};

    ${({ disabled, disabledItemStyle }) =>
      disabled &&
      `
      background: linear-gradient(135deg, transparent calc(50% - 1px), #E5E4F4 50%, transparent calc(50% + 1px));
      color: #70758C;
      cursor: not-allowed;

      ${disabledItemStyle};
    `};
  }

  ${({ itemStyle }) => itemStyle};
`;

const NestedItemsSectionLabel = styled(Heading)`
  display: flex;
  font-size: 12px;
  line-height: 24px;
  color: #333;
  font-weight: bold;

  ${mobile`
    font-size: 14px;
    padding-top: 8px;
  `}

  ${({ $nestedStyle }) => $nestedStyle};
`;

const FilterGrid = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding: 8px 0 16px 16px;

  ${FilterList} & {
    padding: 0 0 16px 0;
  }

  ${({ listStyle }) => listStyle};
`;

const GridItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 3px 3px 0;
  color: ${({ disabled }) => (disabled ? '#ccc' : '#333')};

  ${({ itemStyle }) => itemStyle};
`;

const GridItemLabel = styled.label`
  font-size: 16px;
  letter-spacing: ${-0.4 / 16}em;
  text-align: center;
  right: 0;
  left: 0;
  position: absolute;

  ${({ gridLabelStyle }) => gridLabelStyle};
`;

const FilterCountLabel = styled.span`
  ${({ filterItemCountStyle }) => filterItemCountStyle};
`;

const GridItemCheckbox = styled(Checkbox).attrs((props) => {
  return {
    checkmarkStyle: props.checkmarkStyle || gridCheckmarkStyle,
    inputCheckedStyle: props.inputCheckedStyle || gridInputCheckedStyle,
  };
})`
  width: 56px;
  height: 56px;
  margin: 0;
  border-radius: 0;
  border: none;
  line-height: 56px;
  background-color: #eee;
  box-sizing: border-box;

  ${({ disabled }) =>
    disabled &&
    css`
      border: solid 1px #eee;
      background-color: #fff;
    `};
`;

const EmptyMessage = styled.p`
  padding: 0 0 16px 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
  color: #333;

  ${mobile`
    font-size: 14px;
  `}
`;

const ToggleIcon = styled.svg`
  flex: 0 0 auto;
`;

const DynamicFormattedMessage = FormattedMessage;

const FilterListItem = React.memo(
  ({
    filterField,
    checkboxProps,
    circleIconStyle,
    swatchCheckboxProps,
    colorSwatches,
    filter,
    filterItemCountStyle,
    filterLabelOptions,
    filterStyle,
    gridCheckboxProps,
    gridLabelStyle,
    itemLabelStyle,
    itemStyle,
    disabledItemStyle,
    onChange,
    selectedFilters,
    getSwatchIcon,
    label: filterLabel,
    renderLabel,
    showFilterItemCount = false,
  }) => {
    const checkboxId = useId();
    const label = filter.label || filter.value.toString();
    // optional value to be appended to the filter's label, between parenthesis. e.g. Blue (12)
    const filterItemCount = filter.filterCount;
    const filterFieldKey = `filter-${slugify(filterField.field, '-')}-option`;

    if (
      swatchCheckboxProps &&
      (filterStyle === ProductListingFilterStyle.SWATCH ||
        filterStyle === ProductListingFilterStyle.SWATCH_GRID)
    ) {
      checkboxProps = { ...checkboxProps, ...swatchCheckboxProps };
    }

    const hasFilterItems =
      filter.backgroundCount == null || filter.backgroundCount > 0;
    const isChecked = Array.isArray(selectedFilters)
      ? selectedFilters.includes(filter.value)
      : selectedFilters === filter.value;

    const handleChange = useCallback(
      (event) => {
        onChange({ event, filterField, filter });
      },
      [filterField, filter, onChange]
    );

    const renderSwatchIcon = () => {
      return getSwatchIcon ? (
        getSwatchIcon({ value: filter.value, isChecked })
      ) : (
        <CircleIcon
          value={filter.value}
          colorSwatches={colorSwatches}
          showBorder={label === 'White'}
          circleIconStyle={circleIconStyle}
        />
      );
    };

    if (filterStyle === ProductListingFilterStyle.GRID) {
      if (filterField.bandSizeCheckboxProps) {
        gridCheckboxProps = {
          ...gridCheckboxProps,
          ...filterField.bandSizeCheckboxProps,
        };
      } else if (filterField.cupSizeCheckboxProps) {
        gridCheckboxProps = {
          ...gridCheckboxProps,
          ...filterField.cupSizeCheckboxProps,
        };
      }
      return (
        <GridItem
          key={filter.value}
          disabled={!hasFilterItems}
          data-autotag={filterFieldKey}
          itemStyle={itemStyle}
          disabledItemStyle={disabledItemStyle}
        >
          <GridItemCheckbox
            checked={isChecked}
            disabled={!hasFilterItems}
            id={checkboxId}
            name={filterFieldKey}
            value={filter.value}
            onChange={handleChange}
            variant="categoryFilterGridCheck"
            {...gridCheckboxProps}
          />
          <GridItemLabel
            htmlFor={checkboxId}
            isChecked={isChecked}
            gridLabelStyle={gridLabelStyle}
          >
            {renderLabel(filterField, filter, filterLabelOptions)}
            {showFilterItemCount && filterItemCount && (
              <FilterCountLabel filterItemCountStyle={filterItemCountStyle}>
                &nbsp;({filterItemCount})
              </FilterCountLabel>
            )}
          </GridItemLabel>
        </GridItem>
      );
    }

    if (filterStyle === ProductListingFilterStyle.SWATCH_GRID) {
      return (
        <SwatchGridFilterItem
          key={filter.value}
          disabled={!hasFilterItems}
          data-autotag={filterFieldKey}
          itemStyle={itemStyle}
          isChecked={isChecked}
          colorSwatches={colorSwatches}
          colorValue={filter.value}
          disabledItemStyle={disabledItemStyle}
        >
          <FilterItemLabel
            htmlFor={checkboxId}
            isChecked={isChecked}
            itemLabelStyle={itemLabelStyle}
          >
            {renderSwatchIcon()}
            <div>
              {renderLabel(filterField, filter, filterLabelOptions)}
              {showFilterItemCount && filterItemCount && (
                <FilterCountLabel filterItemCountStyle={filterItemCountStyle}>
                  &nbsp;({filterItemCount})
                </FilterCountLabel>
              )}
            </div>
          </FilterItemLabel>
          <FilterCheckbox
            checked={isChecked}
            disabled={!hasFilterItems}
            id={checkboxId}
            name={filterFieldKey}
            value={filter.value}
            onChange={handleChange}
            variant="categoryFilterCheck"
            {...checkboxProps}
          />
        </SwatchGridFilterItem>
      );
    }

    if (filterStyle === ProductListingFilterStyle.TAGS_GRID) {
      return (
        <TagsGridFilterItem
          key={filter.value}
          disabled={!hasFilterItems}
          data-autotag={filterFieldKey}
          itemStyle={itemStyle}
          isChecked={isChecked}
          disabledItemStyle={disabledItemStyle}
        >
          <FilterItemLabel
            htmlFor={checkboxId}
            isChecked={isChecked}
            itemLabelStyle={itemLabelStyle}
          >
            {renderLabel(filterField, filter, filterLabelOptions)}
            {showFilterItemCount && filterItemCount && (
              <FilterCountLabel filterItemCountStyle={filterItemCountStyle}>
                &nbsp;({filterItemCount})
              </FilterCountLabel>
            )}
          </FilterItemLabel>
          <FilterCheckbox
            checked={isChecked}
            disabled={!hasFilterItems}
            id={checkboxId}
            name={filterFieldKey}
            value={filter.value}
            onChange={handleChange}
            variant="categoryFilterCheck"
            {...checkboxProps}
          />
        </TagsGridFilterItem>
      );
    }

    return (
      <FilterItem
        key={filter.value}
        disabled={!hasFilterItems}
        data-autotag={filterFieldKey}
        itemStyle={itemStyle}
      >
        <FilterCheckbox
          checked={isChecked}
          disabled={!hasFilterItems}
          id={checkboxId}
          name={filterFieldKey}
          value={filter.value}
          onChange={handleChange}
          variant="categoryFilterCheck"
          {...checkboxProps}
        />
        <FilterItemLabel
          htmlFor={checkboxId}
          isChecked={isChecked}
          itemLabelStyle={itemLabelStyle}
        >
          {filterStyle === ProductListingFilterStyle.SWATCH &&
            renderSwatchIcon()}
          {renderLabel(filterField, filter, filterLabelOptions)}
          {showFilterItemCount && filterItemCount && (
            <FilterCountLabel filterItemCountStyle={filterItemCountStyle}>
              &nbsp;({filterItemCount})
            </FilterCountLabel>
          )}
        </FilterItemLabel>
      </FilterItem>
    );
  }
);

FilterListItem.displayName = 'FilterListItem';

FilterListItem.propTypes = {
  checkboxProps: PropTypes.object,
  circleIconStyle: PropTypes.any,
  colorSwatches: PropTypes.object,
  disabledItemStyle: PropTypes.any,
  filter: PropTypes.object,
  filterField: PropTypes.shape({
    bandSizeCheckboxProps: PropTypes.object,
    cupSizeCheckboxProps: PropTypes.object,
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  filterItemCountStyle: PropTypes.any,
  filterLabelOptions: PropTypes.shape({
    labelType: PropTypes.string,
    filterStyle: PropTypes.any,
  }),
  filterStyle: PropTypes.oneOf([
    ProductListingFilterStyle.DEFAULT,
    ProductListingFilterStyle.GRID,
    ProductListingFilterStyle.SWATCH,
    ProductListingFilterStyle.SWATCH_GRID,
    ProductListingFilterStyle.TAGS_GRID,
  ]),
  getSwatchIcon: PropTypes.func,
  gridCheckboxProps: PropTypes.object,
  gridLabelStyle: PropTypes.any,
  itemLabelStyle: PropTypes.any,
  itemStyle: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  renderLabel: PropTypes.func,
  selectedFilters: PropTypes.any,
  showFilterItemCount: PropTypes.bool,
  swatchCheckboxProps: PropTypes.object,
};

export default function ProductListingFilter({
  filterField,
  filterFieldLabelStyle,
  checkboxProps,
  circleIconStyle,
  collapseIconComponent = MdRemove,
  colorSwatches,
  disabledItemStyle,
  disabledGridItemStyle,
  disabledSwatchItemStyle,
  disabledSwatchGridItemStyle,
  disabledTagsGridItemStyle,
  expandIconComponent = MdAdd,
  // TODO: Rename this to `filterSummaryStyle` in a future MAJOR release.
  filterCountStyle,
  filterStyle: filterStyleFromProps,
  gridCheckboxProps,
  gridItemStyle,
  gridLabelStyle,
  gridListStyle,
  filterItemCountStyle,
  filterFieldButtonStyle,
  filterFieldLabelContainerStyle,
  itemLabelStyle,
  itemStyle,
  label,
  labelType,
  listStyle,
  nestedListStyle,
  nestedHeadingStyle,
  onChange,
  renderCollapsibleContent,
  renderFilter,
  renderSummary,
  selectedFilters,
  showFilterItemCount = false,
  swatchCheckboxProps,
  swatchItemStyle,
  swatchLabelStyle,
  swatchListStyle,
  swatchGridItemStyle,
  swatchGridLabelStyle,
  swatchGridListStyle,
  tagsGridItemStyle,
  tagsGridLabelStyle,
  tagsGridListStyle,
  getSwatchIcon,
  ...rest
}) {
  const { isMobile } = useBreakpoint();
  // These two contexts are identical for the purposes of what this
  // component does with them, but we need to check both to maintain
  // backwards compatibility.
  let filterContext = useProductFilters();
  const customFilterContext = useProductFiltersCustomFilters();
  filterContext = filterContext || customFilterContext;

  const filterFieldLabelCtx = useFilterFieldLabels();
  const optionLabelCtx = useOptionLabels();
  const [isOpenFromState, setIsOpen] = useState(false);
  const isOpenFromContext =
    filterContext?.expandedCollapsibleFilters[filterField.field];
  const isOpen = filterContext ? isOpenFromContext : isOpenFromState;

  if (typeof selectedFilters === 'function') {
    if (filterContext) {
      selectedFilters = selectedFilters(filterContext.selectedFilters);
    } else {
      selectedFilters = selectedFilters();
    }
  } else if (!selectedFilters) {
    selectedFilters = filterContext.selectedFilters;
  }

  const countFilters = useCallback(
    (filterField) => {
      // Allow filter fields to override the # of filters they have applied.
      if (filterField.filterCount != null) {
        return filterField.filterCount;
      }
      const values = selectedFilters[filterField.field];
      if (Array.isArray(values)) {
        return values.length;
      }
      return values == null ? 0 : 1;
    },
    [selectedFilters]
  );

  const filterCount = useMemo(() => {
    if (filterField.nestedFilters) {
      return filterField.nestedFilters.reduce(
        (total, subFilter) => total + countFilters(subFilter),
        0
      );
    }
    return countFilters(filterField);
  }, [filterField, countFilters]);

  const singleFilterItem = useMemo(() => {
    if (filterField.nestedFilters) {
      return null;
    }
    const value = selectedFilters[filterField.field];
    if (Array.isArray(value)) {
      return null;
    }
    if (value != null) {
      return filterField.items.find((item) => item.value === value);
    }
  }, [
    filterField.field,
    filterField.items,
    filterField.nestedFilters,
    selectedFilters,
  ]);

  const getFilterStyle = useCallback(
    (filterField) => {
      if (filterStyleFromProps) {
        return filterStyleFromProps;
      }

      if (filterField.filterStyle) {
        return filterField.filterStyle;
      }

      const isLongFilterLabel = (item) => {
        const label = item.label || item.value.toString();
        if (typeof label === 'string') {
          return label.length > 4;
        }
        return false;
      };

      const hasLongFilterLabel = filterField.items.some(isLongFilterLabel);

      switch (filterField.field) {
        case 'color':
          return ProductListingFilterStyle.SWATCH;
        case 'size_bottom':
        case 'size_bra':
        case 'size_top':
        case 'size_denim':
        case 'size':
          return isMobile && !hasLongFilterLabel
            ? ProductListingFilterStyle.GRID
            : ProductListingFilterStyle.DEFAULT;
        default:
          return ProductListingFilterStyle.DEFAULT;
      }
    },
    [filterStyleFromProps, isMobile]
  );

  const getItemStyle = (filterStyle) => {
    switch (filterStyle) {
      case ProductListingFilterStyle.SWATCH:
        return swatchItemStyle;
      case ProductListingFilterStyle.SWATCH_GRID:
        return swatchGridItemStyle;
      case ProductListingFilterStyle.TAGS_GRID:
        return tagsGridItemStyle;
      case ProductListingFilterStyle.GRID:
        return gridItemStyle;
      case ProductListingFilterStyle.DEFAULT:
        return itemStyle;
      default:
        return itemStyle;
    }
  };

  const getDisabledItemStyle = (filterStyle) => {
    switch (filterStyle) {
      case ProductListingFilterStyle.SWATCH:
        return disabledSwatchItemStyle;
      case ProductListingFilterStyle.SWATCH_GRID:
        return disabledSwatchGridItemStyle;
      case ProductListingFilterStyle.TAGS_GRID:
        return disabledTagsGridItemStyle;
      case ProductListingFilterStyle.GRID:
        return disabledGridItemStyle;
      case ProductListingFilterStyle.DEFAULT:
        return disabledItemStyle;
      default:
        return disabledItemStyle;
    }
  };

  const getItemLabelStyle = (filterStyle) => {
    switch (filterStyle) {
      case ProductListingFilterStyle.SWATCH:
        return swatchLabelStyle;
      case ProductListingFilterStyle.SWATCH_GRID:
        return swatchGridLabelStyle;
      case ProductListingFilterStyle.TAGS_GRID:
        return tagsGridLabelStyle;
      case ProductListingFilterStyle.GRID:
        return gridLabelStyle;
      case ProductListingFilterStyle.DEFAULT:
        return itemLabelStyle;
      default:
        return itemLabelStyle;
    }
  };

  const getListStyle = (filterStyle) => {
    switch (filterStyle) {
      case ProductListingFilterStyle.SWATCH:
        return swatchListStyle;
      case ProductListingFilterStyle.SWATCH_GRID:
        return swatchGridListStyle;
      case ProductListingFilterStyle.TAGS_GRID:
        return tagsGridListStyle;
      case ProductListingFilterStyle.GRID:
        return gridListStyle;
      case ProductListingFilterStyle.DEFAULT:
        return listStyle;
      default:
        return listStyle;
    }
  };

  const getListComponent = (filterStyle) => {
    switch (filterStyle) {
      case ProductListingFilterStyle.GRID:
        return FilterGrid;
      case ProductListingFilterStyle.TAGS_GRID:
      case ProductListingFilterStyle.SWATCH_GRID:
        return FilterWrappedList;
      default:
        return FilterList;
    }
  };

  const labelOptions = {
    labelType: labelType || LabelType.PRODUCT_FILTER,
  };

  const handleFilterFieldClick = useCallback(
    () =>
      filterContext
        ? filterContext.toggleCollapsibleFilterIsOpen({
            type: 'toggle',
            filterField: filterField.field,
          })
        : setIsOpen((prevOpen) => !prevOpen),
    [filterContext, filterField]
  );

  const handleChange = useCallback(
    ({ event, filterField, filter }) => {
      // only gives us the current filter item
      let newSelectedFilters;
      // gives us all new filter values by filter field
      const newAllSelectedFilters = { ...selectedFilters };
      const oldSelectedFilters = selectedFilters[filterField.field];
      if (Array.isArray(oldSelectedFilters)) {
        if (event.target.checked) {
          newSelectedFilters = [
            ...selectedFilters[filterField.field],
            filter.value,
          ];
          if (event.target.type === 'radio') {
            newSelectedFilters = [filter.value];
          }
        } else {
          newSelectedFilters = selectedFilters[filterField.field].filter(
            (filterItem) => filterItem !== filter.value
          );
        }
      } else {
        newSelectedFilters = event.target.checked ? filter.value : null;
      }
      newAllSelectedFilters[filterField.field] = newSelectedFilters;

      onChange({
        event,
        filterField,
        newSelectedFilters,
        newSelectedFilterValue: filter.value,
        newAllSelectedFilters,
        // pass the entire filter object, so we can define our own callbacks outside the lib
        filterObject: filter,
      });
    },
    [onChange, selectedFilters]
  );

  const renderFilterList = (filterField, depth = 0) => {
    if (filterField.nestedFilters) {
      return (
        <FilterList depth={depth} listStyle={nestedListStyle}>
          {filterField.nestedFilters
            .filter(
              (subFilter) =>
                subFilter.totalAggregationCount !== 0 || subFilter.emptyMessage
            )
            .map((subFilter) => (
              <li key={subFilter.field}>
                <Section>
                  <NestedItemsSectionLabel $nestedStyle={nestedHeadingStyle}>
                    {filterFieldLabelCtx.renderLabel(subFilter, labelOptions)}
                  </NestedItemsSectionLabel>
                  {renderFilterList(subFilter, depth + 1)}
                </Section>
              </li>
            ))}
        </FilterList>
      );
    }

    if (!filterField.items.length && filterField.emptyMessage) {
      return <EmptyMessage>{filterField.emptyMessage}</EmptyMessage>;
    }

    const filterStyle = getFilterStyle(filterField);
    const listStyle = getListStyle(filterStyle);
    const itemStyle = getItemStyle(filterStyle);
    const disabledItemStyle = getDisabledItemStyle(filterStyle);
    const itemLabelStyle = getItemLabelStyle(filterStyle);
    const ListComponent = getListComponent(filterStyle);

    return (
      <ListComponent depth={depth} listStyle={listStyle}>
        {filterField.items.map((filter) => {
          const filterLabelOptions = {
            labelType: labelType || LabelType.PRODUCT_FILTER,
            filterStyle,
          };
          const filterLabel = optionLabelCtx.renderLabelString(
            filterField,
            filter,
            filterLabelOptions
          );
          const renderLabel = (filterField, filter, labelOptions) =>
            optionLabelCtx.renderLabel(filterField, filter, labelOptions);

          return (
            <FilterListItem
              filterField={filterField}
              checkboxProps={checkboxProps}
              swatchCheckboxProps={swatchCheckboxProps}
              colorSwatches={colorSwatches}
              filter={filter}
              filterStyle={filterStyle}
              key={filter.value}
              gridCheckboxProps={gridCheckboxProps}
              gridLabelStyle={gridLabelStyle}
              itemLabelStyle={itemLabelStyle}
              itemStyle={itemStyle}
              disabledItemStyle={disabledItemStyle}
              onChange={handleChange}
              selectedFilters={selectedFilters[filterField.field]}
              circleIconStyle={circleIconStyle}
              filterLabelOptions={filterLabelOptions}
              getSwatchIcon={getSwatchIcon}
              label={filterLabel}
              renderLabel={renderLabel}
              showFilterItemCount={showFilterItemCount}
              filterItemCountStyle={filterItemCountStyle}
            />
          );
        })}
      </ListComponent>
    );
  };

  const autotagKey = slugify(filterField.label, '-');

  if (!renderSummary) {
    renderSummary = () => {
      if (singleFilterItem) {
        return (
          <>
            {' ('}
            {optionLabelCtx.renderLabel(filterField, singleFilterItem, {
              ...labelOptions,
              labelType: LabelType.PRODUCT_FILTER_SUMMARY,
            })}
            {')'}
          </>
        );
      } else if (filterCount > 0) {
        return (
          <>
            {' '}
            <DynamicFormattedMessage
              id="site_product_filter.filter_count"
              defaultMessage="({filterCount})"
              filterFieldButtonStyle={filterFieldButtonStyle}
              values={{
                filterCount: <FormattedNumber value={filterCount} />,
              }}
            />
          </>
        );
      } else {
        return null;
      }
    };
  }

  const summaryContent = renderSummary({
    filterField,
    selectedFilters,
  });

  return (
    <Wrapper {...rest}>
      <Collapsible isOpen={isOpen} defaultOpen={false}>
        <FilterFieldButton
          $filterFieldButtonStyle={filterFieldButtonStyle}
          onClick={handleFilterFieldClick}
          data-autotag={`filter-${autotagKey}`}
        >
          <FilterFieldLabel
            labelContainerStyle={filterFieldLabelContainerStyle}
          >
            <FilterFieldLabelText labelStyle={filterFieldLabelStyle}>
              {label ||
                filterFieldLabelCtx.renderLabel(filterField, labelOptions)}
            </FilterFieldLabelText>
            {summaryContent != null ? (
              <FilterValueSummary filterCountStyle={filterCountStyle}>
                {summaryContent}
              </FilterValueSummary>
            ) : null}
          </FilterFieldLabel>
          {isOpen ? (
            <ToggleIcon
              as={collapseIconComponent}
              data-autotag={`filter-${autotagKey}-collapse`}
            />
          ) : (
            <ToggleIcon
              as={expandIconComponent}
              data-autotag={`filter-${autotagKey}-expand`}
            />
          )}
        </FilterFieldButton>
        <Collapsible.Content mountOnEnter unmountOnExit>
          {renderCollapsibleContent
            ? renderCollapsibleContent({
                filterField,
                labelType,
                onChange,
                selectedFilters,
              })
            : null}
          {renderFilter
            ? renderFilter({
                filterField,
                labelType,
                onChange,
                selectedFilters,
              })
            : renderFilterList(filterField)}
        </Collapsible.Content>
      </Collapsible>
    </Wrapper>
  );
}

const filterStylePropType = PropTypes.oneOf([
  ProductListingFilterStyle.DEFAULT,
  ProductListingFilterStyle.GRID,
  ProductListingFilterStyle.SWATCH,
  ProductListingFilterStyle.SWATCH_GRID,
  ProductListingFilterStyle.TAGS_GRID,
]);

ProductListingFilter.propTypes = {
  checkboxProps: PropTypes.object,
  circleIconStyle: PropTypes.any,
  collapseIconComponent: PropTypes.elementType,
  colorSwatches: PropTypes.object,
  disabledGridItemStyle: PropTypes.any,
  disabledItemStyle: PropTypes.any,
  disabledSwatchGridItemStyle: PropTypes.any,
  disabledSwatchItemStyle: PropTypes.any,
  disabledTagsGridItemStyle: PropTypes.any,
  expandIconComponent: PropTypes.elementType,
  filterCount: PropTypes.number,
  filterCountStyle: PropTypes.any,
  filterField: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    nestedFilters: PropTypes.array,
    items: PropTypes.array,
    emptyMessage: PropTypes.node,
    filterCount: PropTypes.number,
    filterStyle: filterStylePropType,
  }),
  filterFieldButtonStyle: PropTypes.any,
  filterFieldLabelContainerStyle: PropTypes.any,
  filterFieldLabelStyle: PropTypes.any,
  filterItemCountStyle: PropTypes.any,
  filterStyle: filterStylePropType,
  getSwatchIcon: PropTypes.func,
  gridCheckboxProps: PropTypes.object,
  gridItemStyle: PropTypes.any,
  gridLabelStyle: PropTypes.any,
  gridListStyle: PropTypes.any,
  itemLabelStyle: PropTypes.any,
  itemStyle: PropTypes.any,
  /**
   * Custom label to render for the collapsible button. Defaults to category label.
   */
  label: PropTypes.node,
  labelType: PropTypes.string,
  listStyle: PropTypes.any,
  nestedHeadingStyle: PropTypes.any,
  nestedListStyle: PropTypes.any,
  onChange: PropTypes.func,
  renderCollapsibleContent: PropTypes.func,
  renderFilter: PropTypes.func,
  renderSummary: PropTypes.func,
  selectedFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  showFilterItemCount: PropTypes.bool,
  swatchCheckboxProps: PropTypes.object,
  swatchGridItemStyle: PropTypes.any,
  swatchGridLabelStyle: PropTypes.any,
  swatchGridListStyle: PropTypes.any,
  swatchItemStyle: PropTypes.any,
  swatchLabelStyle: PropTypes.any,
  swatchListStyle: PropTypes.any,
  tagsGridItemStyle: PropTypes.any,
  tagsGridLabelStyle: PropTypes.any,
  tagsGridListStyle: PropTypes.any,
};

/**
 * Exporting `BandSize` here to allow brands to custom style the component.
 * Needed to be exported via the `ProductListingFilter` container component rather than directly from `ProductSizeFilter` for it to work.
 */
ProductListingFilter.BandSize = ProductSizeFilter.BandSize;
ProductListingFilter.SwatchGridFilterItem = SwatchGridFilterItem;
ProductListingFilter.CircleIcon = CircleIcon;
ProductListingFilter.TagsGridFilterItem = TagsGridFilterItem;
ProductListingFilter.FilterItemLabel = FilterItemLabel;
ProductListingFilter.FilterCheckbox = FilterCheckbox;

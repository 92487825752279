import config from 'config';

import { useRegion } from '../../techstyle-shared/react-intl';

export function getFooterNavHandle({ region }) {
  let nav = config.get('public.brand.navigation.footerNav');
  if (region === 'EU') {
    nav = config.get('public.brand.navigation.footerNavEU');
  }
  return nav;
}

export function useFooterNavHandle() {
  const region = useRegion();
  return getFooterNavHandle({ region });
}

import React, { useContext, useMemo, useState, useCallback } from 'react';

import PropTypes from 'prop-types';

const ReviewsPhotoDrawerContext = React.createContext();

export const useReviewsPhotoDrawer = () =>
  useContext(ReviewsPhotoDrawerContext);

function ReviewsPhotoDrawerProvider({ children }) {
  const [isPhotoDrawerOpen, setPhotoDrawerOpen] = useState(false);
  const [reviewPhoto, setReviewPhoto] = useState({});

  const openPhotoDrawer = useCallback((photoProps) => {
    setPhotoDrawerOpen(true);
    setReviewPhoto(photoProps);
  }, []);

  const closePhotoDrawer = useCallback(() => {
    setPhotoDrawerOpen(false);
    setReviewPhoto({});
  }, []);

  const value = useMemo(
    () => ({
      isPhotoDrawerOpen,
      openPhotoDrawer,
      closePhotoDrawer,
      reviewPhoto,
      setReviewPhoto,
    }),
    [closePhotoDrawer, isPhotoDrawerOpen, openPhotoDrawer, reviewPhoto]
  );

  return (
    <ReviewsPhotoDrawerContext.Provider value={value}>
      {children}
    </ReviewsPhotoDrawerContext.Provider>
  );
}

ReviewsPhotoDrawerProvider.propTypes = {
  /* Wrapped components */
  children: PropTypes.node,
};

export default ReviewsPhotoDrawerProvider;

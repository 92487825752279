import styled from 'styled-components';

function getHighlightStyle({ highlightBundles, isGroupLoaded, isKeyLoaded }) {
  if (!highlightBundles) {
    return null;
  }
  if (isGroupLoaded) {
    if (isKeyLoaded) {
      return { outline: '2px solid red' };
    }
    return { outline: '2px dashed red' };
  }
  return { outline: '2px dotted red' };
}

export default styled.span`
  white-space: ${(props) => props.whiteSpace || 'pre-wrap'};
  ${getHighlightStyle};
`;

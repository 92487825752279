import ensure from '@redux-beacon/ensure';
import Segment, {
  trackPageView,
  trackEvent,
  setAlias,
  setGroup,
  identifyUser,
  reset,
} from '@redux-beacon/segment';
import { createMiddleware } from 'redux-beacon';

const getUserAgentIsBot = (overrideRegex) => {
  try {
    const botRegex =
      process.browser && window.navigator.userAgent
        ? new RegExp(
            overrideRegex ||
              /bot|crawl|spider|curl|loadtest|loadimpact|loader\.io/
          ).test(window.navigator.userAgent)
        : false;
    return botRegex;
  } catch (error) {
    return false;
  }
};

export default function createSegmentEvents(defineEvents) {
  // Return a Redux middleware function. It won't be called until the store is
  // being created, after all extensions have been initialized, so it's safe to
  // call `config.get` in `defineEvents`.
  return (store) => {
    const { intl } = store.getState();

    let isUserAgentBot = false;
    let isABotRegex;
    const intlSiteTracking =
      // eslint-disable-next-line camelcase
      intl.resourceBundles[intl.locale || 'en-US']?.site_tracking;

    if (
      intlSiteTracking &&
      typeof intlSiteTracking.keyIndex.bot_filter_bmig !== 'undefined'
    ) {
      const botFilterIndex = intlSiteTracking.keyIndex.bot_filter_bmig;
      isABotRegex = intlSiteTracking.values[botFilterIndex];
    }

    isUserAgentBot = getUserAgentIsBot(isABotRegex);

    if (
      (process.browser && !window.analytics) ||
      (process.browser && isUserAgentBot)
    ) {
      // No-op middleware.
      return (next) => (action) => next(action);
    }

    const createEvent = (event) => trackEvent(() => event)();
    const createPageView = (event) => trackPageView(() => event)();
    const { dispatch, getState } = store;
    const getContext = () => dispatch((dispatch, getState, context) => context);

    const helpers = {
      dispatch,
      getState,
      getContext,
      ensure,
      trackEvent,
      trackPageView,
      setAlias,
      setGroup,
      identifyUser,
      reset,
      // Use these if you have a custom event definition but still want to use
      // the helpers to format the events correctly.
      createEvent,
      createPageView,
    };
    const eventDefs =
      typeof defineEvents === 'function' ? defineEvents(helpers) : defineEvents;
    const tracker = Segment();
    const middleware = createMiddleware(eventDefs, tracker);
    return middleware(store);
  };
}

import React, { useCallback, useMemo, useReducer } from 'react';

import { endOfYear } from 'date-fns/endOfYear';
import { startOfYear } from 'date-fns/startOfYear';
import { subDays } from 'date-fns/subDays';
import { subMonths } from 'date-fns/subMonths';
import styled from 'styled-components';

import {
  ShippingStatusFilter,
  useOrders,
} from '../../../../techstyle-shared/react-accounts';
import type { OrderHistoryDto } from '../../../../techstyle-shared/react-accounts';
import StyledSelect from '../StyledSelect';
import { mobile } from '../styles';
import { createContext } from '../utils/createContext';
import {
  getOrderStatusFromCode,
  OrderStatus,
} from '../utils/getOrderStatusFromCode';

import LoadMoreButton from './LoadMoreButton';
import { yearsFromNowToStart } from './yearsFromNowToStart';

type AccountOrdersContextType = {
  canLoadMore: boolean;
  daysToReturn: number;
  getReturnByDate: (date: Date) => Date;
  loadMore: () => void;
  isLoading: boolean;
  orders: OrderHistoryDto[];
  page: number;
  dateRangeFilter: string;
  orderStatusFilter: OrderStatusFilter | ShippingStatusFilter;
  setDateRangeFilter: (filter: string) => void;
  setOrderStatusFilter: (
    filter: OrderStatusFilter | ShippingStatusFilter
  ) => void;
  totalRecords: number | null;
};

const [useAccountOrders, AccountOrdersContext] =
  createContext<AccountOrdersContextType>('AccountOrders');

export enum OrderStatusFilter {
  All = 'All',
  Shipped = 'Shipped',
  Pending = 'Pending',
  Cancelled = 'Cancelled',
}

enum OrderFilterStateActions {
  SET_ORDER_STATUS = 'setOrderStatusFilter',
  SET_DATE_RANGE = 'setDateRangeFilter',
  LOAD_MORE = 'loadMore',
}

const DEFAULT_DATE_RANGE_OPTIONS = {
  LAST_30_DAYS: 'Last 30 Days',
  PAST_6_MONTHS: 'Past 6 Months',
};

export const DEFAULT_DATE_RANGES = {
  [DEFAULT_DATE_RANGE_OPTIONS.LAST_30_DAYS]: {
    start: subDays(new Date(), 30),
    end: new Date(),
  },
  [DEFAULT_DATE_RANGE_OPTIONS.PAST_6_MONTHS]: {
    start: subMonths(new Date(), 6),
    end: new Date(),
  },
};

const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  width: 100%;

  ${mobile`
    flex-direction: column;
    align-items: revert;
  `}
`;

const FilterWrapper = styled.div`
  display: flex;

  ${mobile`
    gap: 16px;
    padding-top: 8px;
  `}
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;

  ${mobile`
    flex-direction: column;
    align-items: revert;
    width: 100%;
    margin-left: 0px;
  `}
`;

const SelectLabel = styled.span`
  padding-right: 8px;
  color: ${({ theme }: any) => theme.colors.brownGray};

  ${mobile`
    padding-bottom: 4px;
  `}
`;

const FilterSelect = styled(StyledSelect)`
  width: 150px;
  border: 1px solid ${({ theme }: any) => theme.colors.brownGreyTwo};

  ${mobile`
     width: 100%;
  `}
`;

const Select = styled(StyledSelect.Select)`
  font-size: 14px;
`;

const initialOrderFilterState = {
  orderStatusFilter: OrderStatusFilter.All,
  shippingStatusFilter: ShippingStatusFilter.All,
  dateRangeFilter: DEFAULT_DATE_RANGE_OPTIONS.PAST_6_MONTHS,
  page: 1,
};

type OrderFilterState = {
  orderStatusFilter: OrderStatusFilter | ShippingStatusFilter;
  shippingStatusFilter: ShippingStatusFilter;
  excludeOrderTypeIds?: Array<number>;
  dateRangeFilter: string;
  page: number;
};

type OrderFilterActions =
  | {
      type: typeof OrderFilterStateActions.SET_ORDER_STATUS;
      payload: OrderStatusFilter | ShippingStatusFilter;
    }
  | {
      type: typeof OrderFilterStateActions.SET_DATE_RANGE;
      payload: string;
    }
  | {
      type: typeof OrderFilterStateActions.LOAD_MORE;
    };

const getShippingStatusFilter = (
  payload: OrderStatusFilter | ShippingStatusFilter
) => {
  switch (payload) {
    case OrderStatusFilter.All:
      return ShippingStatusFilter.All;
    case OrderStatusFilter.Pending:
      return ShippingStatusFilter.Pending;
    case OrderStatusFilter.Shipped:
      return ShippingStatusFilter.Shipped;
    case OrderStatusFilter.Cancelled:
      return ShippingStatusFilter.All;
    default:
      return payload;
  }
};

const orderFilterReducer = (
  state: OrderFilterState,
  action: OrderFilterActions
) => {
  switch (action.type) {
    case OrderFilterStateActions.LOAD_MORE:
      return {
        ...state,
        page: state.page + 1,
      };
    case OrderFilterStateActions.SET_ORDER_STATUS:
      return {
        ...state,
        page: 1,
        orderStatusFilter: action.payload,
        shippingStatusFilter: getShippingStatusFilter(action.payload),
      };
    case OrderFilterStateActions.SET_DATE_RANGE:
      return {
        ...state,
        page: 1,
        dateRangeFilter: action.payload,
      };
    default:
      return state;
  }
};

const dateRangeOptionsFromStartYear = (startYear: string) => [
  ...Object.values(DEFAULT_DATE_RANGE_OPTIONS),
  ...yearsFromNowToStart(startYear),
];

export const getStartAndEndOfYear = (year: string) => {
  return {
    start: startOfYear(new Date(`${year}/1/1`)),
    end: endOfYear(new Date(`${year}/1/1`)),
  };
};

export const getDateRangeFilterStartAndEnd = ({
  dateRangeFilter,
}: {
  dateRangeFilter: string;
}) => {
  const isDefaultDateRange = Object.values(DEFAULT_DATE_RANGE_OPTIONS).includes(
    dateRangeFilter
  );

  if (isDefaultDateRange) {
    return DEFAULT_DATE_RANGES[dateRangeFilter];
  }

  return getStartAndEndOfYear(dateRangeFilter);
};

type AccountOrdersProps = {
  daysToReturn?: number;
  productTypeIdsToFilter?: number[];
  productCategoryIdsToFilter?: number[];
  excludeOrderTypeIds?: Array<number>;
};

function AccountOrders({
  children,
  daysToReturn = 60,
  productTypeIdsToFilter = [],
  productCategoryIdsToFilter = [],
  excludeOrderTypeIds,
}: React.PropsWithChildren<AccountOrdersProps>) {
  // Using a reducer here so `page` is set back to 1 at the same time
  // as the filters being updated. This is so the last used page isn't fetched
  // when a new filter is set.
  const [
    { page, orderStatusFilter, shippingStatusFilter, dateRangeFilter },
    dispatch,
  ] = useReducer(orderFilterReducer, initialOrderFilterState);

  const dateRange = useMemo(
    () => getDateRangeFilterStartAndEnd({ dateRangeFilter }),
    [dateRangeFilter]
  );

  const {
    loadedOrders,
    orders: ordersState,
    networkStatus,
  } = useOrders({
    page,
    orderStatusFilter: shippingStatusFilter,
    excludeOrderTypeIds,
    dateExpectedMin: dateRange.start,
    dateExpectedMax: dateRange.end,
  });

  const filteredOrders = useMemo(() => {
    let orders = loadedOrders;
    if (orderStatusFilter === OrderStatusFilter.Cancelled) {
      orders = orders.filter((order) => {
        return (
          getOrderStatusFromCode({
            processingStatusCode: order.processingStatuscode,
          }) === OrderStatus.Cancelled
        );
      });
    }

    if (orderStatusFilter === OrderStatusFilter.Pending) {
      orders = orders.filter((order) => {
        const orderStatusFromCode = getOrderStatusFromCode({
          processingStatusCode: order.processingStatuscode,
        });

        if (!orderStatusFromCode) {
          return;
        }

        return [
          OrderStatus.Pending,
          OrderStatus.Test,
          OrderStatus.Hold,
        ].includes(orderStatusFromCode);
      });
    }

    return orders.map((singleOrder) => {
      return {
        ...singleOrder,
        orderLines: singleOrder.orderLines.filter(
          // FIXME: OrderHistoryLineItemDto is an incomplete type
          // @ts-ignore
          (singleOrderLine: {
            productTypeId: number;
            defaultProductCategoryId: number;
          }) => {
            if (
              productTypeIdsToFilter.includes(singleOrderLine.productTypeId) ||
              productCategoryIdsToFilter.includes(
                singleOrderLine.defaultProductCategoryId
              )
            ) {
              return false;
            }
            return true;
          }
        ),
      };
    });
  }, [
    loadedOrders,
    orderStatusFilter,
    productCategoryIdsToFilter,
    productTypeIdsToFilter,
  ]);

  const canLoadMore = ordersState.pageCount
    ? ordersState.pageCount > page
    : false;

  const loadMore = useCallback(() => {
    if (canLoadMore) {
      dispatch({ type: OrderFilterStateActions.LOAD_MORE });
    }
  }, [canLoadMore]);

  const setOrderStatusFilter = useCallback(
    (filter: OrderStatusFilter | ShippingStatusFilter) => {
      dispatch({
        type: OrderFilterStateActions.SET_ORDER_STATUS,
        payload: filter,
      });
    },
    []
  );

  const setDateRangeFilter = useCallback((filter: string) => {
    dispatch({
      type: OrderFilterStateActions.SET_DATE_RANGE,
      payload: filter,
    });
  }, []);

  const getReturnByDate = useCallback(
    (date: Date) => {
      const returnBy = date;
      returnBy.setDate(returnBy.getDate() + daysToReturn);
      return returnBy;
    },
    [daysToReturn]
  );

  const contextValue = useMemo(
    () => ({
      page,
      totalRecords: ordersState.totalRecords,
      canLoadMore,
      loadMore,
      orders: filteredOrders,
      setOrderStatusFilter,
      setDateRangeFilter,
      isLoading: networkStatus.isLoading,
      orderStatusFilter,
      excludeOrderTypeIds,
      dateRangeFilter,
      daysToReturn,
      getReturnByDate,
    }),
    [
      excludeOrderTypeIds,
      canLoadMore,
      loadMore,
      filteredOrders,
      networkStatus.isLoading,
      ordersState.totalRecords,
      page,
      dateRangeFilter,
      orderStatusFilter,
      setDateRangeFilter,
      setOrderStatusFilter,
      daysToReturn,
      getReturnByDate,
    ]
  );

  return (
    <AccountOrdersContext.Provider value={contextValue}>
      {children}
    </AccountOrdersContext.Provider>
  );
}

const FilterSelectNamespace = Object.assign(FilterSelect, {
  ...StyledSelect,
  Select,
  Label: SelectLabel,
  Wrapper: SelectWrapper,
});

export {
  AccountOrders as Root,
  useAccountOrders as useContext,
  dateRangeOptionsFromStartYear,
  LoadMoreButton,
  PageHeader,
  FilterWrapper,
  DEFAULT_DATE_RANGE_OPTIONS,
  FilterSelectNamespace as FilterSelect,
};

import isFeatureEnabled from './isFeatureEnabled';

export const featureIdMap = {
  OVERRIDDEN: -1,
  NOT_FOUND: 0,
};

export default function getDerivedFeatureState(featureState) {
  const { data: feature = {} } = featureState;
  const isOverride = feature && feature.featureId === featureIdMap.OVERRIDDEN;
  const isFound = feature && feature.featureId > featureIdMap.NOT_FOUND;
  const isEnabled = isFeatureEnabled(featureState);

  return {
    isEnabled,
    isFound,
    isOverride,
  };
}

import React from 'react';

import { CartOrderSubmitRequestDto } from '../../../../techstyle-shared/react-types';
import Button from '../Button';
import { usePaymentContext } from '../Payment/PaymentContext';

type PlaceOrderButtonProps = React.ComponentPropsWithRef<typeof Button> & {
  payload?: CartOrderSubmitRequestDto;
  onSuccess?: () => void;
  onError?: (e: any) => void;
  onClick?: () => void;
};

const PlaceOrderButton = ({
  onSuccess,
  onError,
  onClick,
  payload,
  ...rest
}: PlaceOrderButtonProps) => {
  const { cartPaymentMethod, placeOrderAndSubmit } = usePaymentContext();
  if (!cartPaymentMethod) {
    return null;
  }
  const handleClick = async () => {
    if (onClick) {
      onClick();
    }
    try {
      await placeOrderAndSubmit(cartPaymentMethod, payload);
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (onError) {
        onError(e);
      }
    }
  };

  return <Button onClick={handleClick} {...rest} />;
};

export default PlaceOrderButton;

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const UnstyledButton = styled.button`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background: none;
  background-color: transparent;
  text-align: left;
`;

const ScrollButton = styled(UnstyledButton)``;
/**
 * Handles the smooth scrolling by selecting the body and scrolling it into view.
 * Also blurs the activeElement to ensure that the accessibility follows the scroll to top.
 * @param {event} e
 */
const handleScrollToTop = (e) => {
  if (document.activeElement) {
    document.activeElement.blur();
  }
  document.body.scrollIntoView({
    behavior: 'smooth',
  });
};

/**
 * Scrolls the window to the top with the smooth scroll behavior
 * @param {*} props
 */
function ScrollToTop({ children, ...rest }) {
  return (
    <ScrollButton onClick={handleScrollToTop} {...rest}>
      {children}
    </ScrollButton>
  );
}

ScrollToTop.propTypes = {
  /**
   * Will render the children inside the button
   */
  children: PropTypes.node,
};

export default ScrollToTop;

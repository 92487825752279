import config from 'config';
import decodeHtml from 'decode-html';
import PropTypes from 'prop-types';
import urlTemplate from 'url-template';

const escapeRegex = /(\\r\\n|\\r|\\n)|(\\t)/g;
let assetUrlTemplate;

/**
 * Decode the escaping performed by the asset API on HTML and custom var fields.
 * Let's just say that it has very special ideas about escaping.
 */
export function decodeAssetHtml(htmlText) {
  if (typeof htmlText !== 'string') {
    return htmlText;
  }
  return decodeHtml(
    htmlText.replace(escapeRegex, (match, newline, tab) => {
      if (newline) {
        return '\n';
      }
      if (tab) {
        return '\t';
      }
      return match;
    })
  );
}

export function resolveAssetUrl(filename, asset) {
  if (!filename) {
    return filename;
  }
  if (!assetUrlTemplate) {
    assetUrlTemplate = urlTemplate.parse(
      config.get('public.assets.urlTemplate')
    );
  }
  const values = { id: asset.id, filename };
  return assetUrlTemplate.expand(values);
}

export function parseAspectRatio(string) {
  const [width, height] = string.split(':');
  const numericAspectRatio = parseInt(width, 10) / parseInt(height, 10);
  if (!numericAspectRatio || numericAspectRatio < 0) {
    throw new Error(`Invalid aspect ratio: '${string}'`);
  }
  return numericAspectRatio;
}

export function filterAssets(assets, filter, multi) {
  if (multi) {
    return filter ? assets.filter(filter) : assets;
  }
  const asset = filter ? assets.find(filter) : assets[0];
  return asset == null ? [] : [asset];
}

export const assetPropType = PropTypes.shape({
  id: PropTypes.number,
  options: PropTypes.object,
});

import { createReducer, createAction } from '../techstyle-shared/redux-core';

export const updateChatInfo = createAction('liveChat/updateChatInfo');

const liveChatReducer = createReducer(
  {
    firstName: '',
    lastName: '',
    email: '',
    reasoning: '',
  },
  {
    [updateChatInfo]: (state, action) => {
      return { ...state, ...action.payload };
    },
  }
);

const liveChatModule = {
  id: 'liveChat',
  reducerMap: {
    liveChat: liveChatReducer,
  },
};

export default function liveChatExtension() {
  return {
    id: 'liveChat',
    redux: { modules: [liveChatModule] },
  };
}

import { css } from 'styled-components';

export default {
  breakpoints: {
    mobile: { maxWidth: 767 },
  },
  colors: {
    black: '#000000',
    focusColor: 'rgba(50, 120, 230, 0.8)',
    promoPrice: '#a14569',
    textDisabled: '#888',
    white: '#ffffff',
    formError: '#a14569',
  },
  context: {},
  heading: {
    variants: {
      primary: {},
    },
  },
  toastMessage: {
    variants: {
      warning: {
        $toastMessageStyle: css`
          border: 1px solid #ffc107;
          display: flex;
          justify-content: space-between;
        `,
      },
      error: {
        $toastMessageStyle: css`
          border: 1px solid #a14569;
        `,
      },
      success: {
        $toastMessageStyle: css`
          border: 1px solid #05944f;
        `,
      },
    },
  },
};

import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Currency } from '../../../../techstyle-shared/react-intl';
import { createContext } from '../utils/createContext';

import { Cart, CartItemDiscount } from './checkoutTypes';

/* Discount Fields to be shown as -ve when displayed */
const discountFields = [
  'vipDiscount',
  'storeCreditAmount',
  'tokenQuantity',
  'markdownSavingsAmount',
  'discounts',
  'subtotalDiscount',
  'memberCreditAmount',
] as const;

/* context value of Order summary being returned */
enum OrderSummaryValue {
  Subtotal = 'subtotal',
  Total = 'total',
  Tariff = 'tariffSurchargeAmount',
  Shipping = 'shipping',
  VipDiscount = 'vipDiscount',
  TokenQuantity = 'tokenQuantity',
  Tax = 'tax',
  StoreCredit = 'storeCreditAmount',
  Discounts = 'discounts',
  VatAmount = 'vatAmount',
  MarkdownSavingsAmount = 'markdownSavingsAmount',
  SubTotalDiscount = 'subtotalDiscount',
  MemberCreditAmount = 'memberCreditAmount',
}

export type OrderSummaryContextValue = Pick<Cart, `${OrderSummaryValue}`>;

type RootProps = React.PropsWithChildren<{
  order: OrderSummaryContextValue;
}>;

export const [useOrderSummaryContext, OrderSummaryContext] =
  createContext<OrderSummaryContextValue>('OrderSummaryContext');

const Price = styled(Currency)``;

const OrderSummary = ({ children, order }: RootProps) => {
  const context = useMemo(() => {
    const returnValue = { ...order };

    // we are going through the `discountFields` array and returning the -ve amount
    // for these fields as they will be displayed as -ve amount on the order summary in checkout page.
    discountFields.forEach((field) => {
      if (field === 'discounts') {
        const discountedReturnValues: CartItemDiscount[] = [];
        returnValue[field].forEach((item) => {
          const newDiscount = { ...item };
          if (newDiscount.amount > 0) {
            newDiscount.amount *= -1;
          }
          discountedReturnValues.push(newDiscount);
        });
        returnValue[field] = discountedReturnValues;
      } else {
        returnValue[field] *= -1;
      }
    });
    return returnValue;
  }, [order]);

  return (
    <OrderSummaryContext.Provider value={context}>
      {children}
    </OrderSummaryContext.Provider>
  );
};

const LineItemWrapper = styled.div`
  margin-bottom: 16px;
`;

const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LineItemLabel = styled.span``;

const LineItemValue = styled.span``;

OrderSummary.Context = OrderSummary;
OrderSummary.LineItemWrapper = LineItemWrapper;
OrderSummary.LineItem = LineItem;
OrderSummary.LineItemLabel = LineItemLabel;
OrderSummary.LineItemValue = LineItemValue;
OrderSummary.Price = Price;

export default OrderSummary;

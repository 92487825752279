import { OrderLineItem } from '../Checkout/checkoutTypes';
import { OrderLineItemType } from '../OrderLineItem';

const getCartItemSoldOutStatus = (
  cartItem: OrderLineItem | OrderLineItemType
) => {
  let soldOutStatus = false;
  if (!cartItem) {
    return soldOutStatus;
  }

  if (cartItem.bundleItems && cartItem.bundleItems.length) {
    soldOutStatus = cartItem.bundleItems.some(
      (item) => item.availableQuantity === 0
    );
  } else {
    soldOutStatus = cartItem.availableQuantity === 0;
  }

  return soldOutStatus;
};

export default getCartItemSoldOutStatus;

import { useMemo } from 'react';

// This function returns input images along with webP images
// It duplicates the images and create webp image format and add it to the srcList.
// webp images are added first to the list so that if any browser doesn't support it, it can fallback
export const getWebPImageSource = (images) => {
  return {
    images: images.map((image) => {
      return {
        ...image,
        url: image.url.replace(/\.[^.]+$/, '.webp'),
      };
    }),
    type: 'image/webp',
  };
};

export const getProductImageSources = ({
  sizes: images,
  isVideo,
  videoPath,
}) => [{ images, isVideo, videoPath }];

// hook to return list of images
export default function useProductImages({
  product = {},
  images: imagesFromArgs,
  type = 'default',
  renderWebP = false,
}) {
  const images =
    imagesFromArgs ||
    product.images ||
    product.productImages ||
    product.imageSets ||
    {};

  // Retrieve the correct type of images (default or plus),
  // falling back to default if no plus images exist
  return useMemo(() => {
    let imageSet;

    if (images.length) {
      imageSet = images;
    } else {
      if (Array.isArray(images[type])) {
        if (type === 'default') {
          imageSet = images.default;
        } else {
          imageSet = images[type]?.length ? images[type] : images.default || [];
        }
      } else {
        // `product.imageSets` has `images` nested inside of imageSet[type] object.
        // This catches data structure with `imageSet[type].images` array
        if (type === 'default' && images.default) {
          imageSet = images.default?.images || [];
        } else {
          imageSet = images[type]?.images.length
            ? images[type].images
            : images.default?.images || [];
        }
      }
    }

    if (!imageSet || !imageSet.length) {
      return [];
    }

    const imageList = imageSet.map(getProductImageSources);

    if (renderWebP) {
      return imageList.map((sources) => [
        getWebPImageSource(sources[0].images),
        ...sources,
      ]);
    } else {
      return imageList;
    }
  }, [renderWebP, images, type]);
}

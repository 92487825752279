import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
  useReducer,
} from 'react';

import {
  gql,
  useApolloClient,
  useQuery,
} from '../../../techstyle-shared/react-graphql';

import { ProductType } from './constants';
import logger from './logger';
import {
  GET_PRODUCT_BY_PERMALINK_QUERY,
  GET_PRODUCT_BY_PRODUCT_ID_QUERY,
  INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT,
  INDIVIDUAL_PRODUCT_PRICE_FRAGMENT,
  INDIVIDUAL_PRODUCT_PROMOS_FRAGMENT,
  INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT,
  INDIVIDUAL_PRODUCT_VIDEO_FRAGMENT,
} from './useProductQuery';

const debug = logger.extend('useProductQueryAndPreload');

// A "skinnier" request for related products
// - skus, attributes, swatches, and reviews removed from this query
//   - they are shared from the initial product query
export const GET_RELATED_PRODUCTS_BY_PRODUCT_IDS_QUERY = gql`
  ${INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRICE_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PROMOS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_VIDEO_FRAGMENT}
  query PreloadProductsByMasterProductIds($productIds: [Int!]!) {
    products: masterProducts(productIds: $productIds) {
      ... on IndividualProduct {
        ...IndividualProductMainParts
        ...IndividualProductPriceParts
        ...IndividualProductPromosParts
        ...IndividualProductImagesParts
        ...IndividualProductVideoParts
      }
    }
  }
`;

// Hoisted to keep same reference
const EMPTY_OBJECT = {};

const CACHE_HEADER = 'x-tfg-cacheby';
const CACHE_VALUE = 'default:compute';
const CACHE_HEADERS = { [CACHE_HEADER]: CACHE_VALUE };

// default check if we should retrieve related products
const retrieveRelatedProducts = (product) => {
  const colorAttribute = product?.attributes?.find?.(
    (a) => a.field === 'Color'
  );
  return colorAttribute ? colorAttribute.options.length > 1 : false;
};

const getPermalinkOrProductId = (permalinkOrProductId) => {
  return typeof permalinkOrProductId === 'string'
    ? { permalink: permalinkOrProductId }
    : { productId: permalinkOrProductId };
};

const baseProductDataWithRelatedInitialState = {
  product: undefined,
  loading: false,
  error: false,
};

// NOTE: baseProductDataWithRelated === BPDWR
function baseProductDataWithRelatedReducer(state, action) {
  switch (action.type) {
    case 'BPDWR/Loaded':
      return { ...state, loading: false, product: action.payload };
    case 'BPDWR/Loading':
      return { ...state, loading: true };
    case 'BPDWR/Error':
      return { ...state, loading: false, error: action.payload };
    case 'BPDWR/reset':
      return { ...baseProductDataWithRelatedInitialState };
    default:
      throw new Error('BPDWR invalid action');
  }
}

/**
 * At a high level this hook retrieves a product
 * and preloads/caches related products if there are any.
 * - The first permalink/productId it receives it will load & preload related products
 * - The next permalink it receives it will check if it was a related product to the
 *   first product loaded
 *   - related: load the preload product from cache and return its product attributes
 *     combined with the original/first product retrieved
 *   - unrelated: "reset the hook" - clear cache and load then preload related products
 *
 * Hook lifecycle for initial permalink/productId ( initial PDP load client/server ):
 * - Step 1: SSR and client - retrieve the base product with no related products
 * - Step 2: client only - retrieve base product with all related products
 * - Step 3: client only - preload/cache related products retrieved from step 2
 *
 * Hook lifecycle for new permalink ( user changing colors ):
 * - check if permalink was related to initial product
 *   - if related: use product from cache combined with initial product data
 *     - if not in cache: re-request product from graphQL
 *   - if not related: "reset the hook" - clear cache and start as though this is an
 *     initial product
 */
export function setupUseProductQueryAndPreload({
  productByPermalinkQuery = GET_PRODUCT_BY_PERMALINK_QUERY,
  productByProductIdQuery = GET_PRODUCT_BY_PRODUCT_ID_QUERY,
  preloadRelatedProductsByProductIdsQuery = GET_RELATED_PRODUCTS_BY_PRODUCT_IDS_QUERY,
  retrieveRelatedProductsCheck = retrieveRelatedProducts,
  preloadProductSkuCheck = (s) => s.availableQuantity > 0 || s.isPreorder,
} = {}) {
  function useProductQueryAndPreload({
    permalinkOrProductId,
    initialProduct: initialProductFromProps,
    additionalQueryVariables = {},
    queryOptions = {},
    enableCDNCache = true,
  }) {
    // "Save" the initial product if supplied.
    // Mainly used to handle bundle product preloading
    const [initialProduct] = useState(initialProductFromProps);

    // We will call useQuery's refetch if permalink or productId changes
    // and they are not related with the preloaded products
    const [baseProductVariables, setBaseProductVariables] = useState(
      getPermalinkOrProductId(permalinkOrProductId)
    );
    const updateBaseProductVariables = useCallback(
      (updatedPermalinkOrProductId) => {
        setBaseProductVariables(
          getPermalinkOrProductId(updatedPermalinkOrProductId)
        );
      },
      []
    );

    const sharedApolloContext = useRef();
    sharedApolloContext.current = {
      skipBatch: true,
      ...(enableCDNCache ? { headers: CACHE_HEADERS } : {}),
    };

    // STEP 1: Get base product with no related products
    // - fetchRelatedProducts defaults to `false`
    // - skip step1 if we have an initial product
    //  - utilized for bundles
    // - This `useQuery` is fetched on SSR via `getDataFromTree`
    const {
      data: baseProductData,
      error: baseProductError,
      loading: baseProductLoading,
    } = useQuery(
      typeof permalinkOrProductId === 'string'
        ? productByPermalinkQuery
        : productByProductIdQuery,
      {
        context: sharedApolloContext.current,
        variables: { ...baseProductVariables, ...additionalQueryVariables },
        // We only skip this query if we already have an initial product to use (bundles)
        skip: !!initialProduct,
        ...queryOptions,
      }
    );

    // NOTE: we also default to the initialProduct if one was supplied
    const baseProduct = baseProductData?.product ?? initialProduct;

    const apolloClient = useApolloClient();

    // NOTE: save queryOptions and additionalQueryVariables
    // to refs so they dont trigger re-renders/useEffects
    // for preloading code
    const preloadQueryOptions = useRef(queryOptions);
    const preloadAdditionalQueryVariables = useRef(additionalQueryVariables);
    preloadQueryOptions.current = queryOptions;
    preloadAdditionalQueryVariables.current = additionalQueryVariables;

    // Store retrieved base product that has ALL related products
    const [baseProductDataWithRelated, dispatchBPDWR] = useReducer(
      baseProductDataWithRelatedReducer,
      baseProductDataWithRelatedInitialState
    );

    // STEP 2: Get base product with ALL related products
    // - ~ query with fetchRelatedProducts set to `true`
    // - Only fetch related products if we need to:
    //   - we have a base Product & !loading & baseProduct !== baseProductWithRelated
    //   - is not a bundle, bundles don't have related products
    // This will cascade to Step 3 and preload related products
    useEffect(() => {
      // failing this criteria will send this useeffect into an infinite loop
      if (
        baseProduct &&
        !baseProductLoading &&
        baseProduct.permalink !==
          baseProductDataWithRelated.product?.permalink &&
        !baseProductDataWithRelated.loading &&
        !baseProductDataWithRelated.error &&
        baseProduct?.productTypeId !== ProductType.BUNDLE
      ) {
        const retrieveRelatedProducts = !baseProductDataWithRelated?.product
          ? retrieveRelatedProductsCheck(baseProduct)
          : true;
        if (retrieveRelatedProducts) {
          debug(
            'fetching base product with related products',
            baseProduct.permalink
          );
          dispatchBPDWR({ type: 'BPDWR/Loading' });
          // NOTE: can not utilize `useLazyQuery` since it behaves
          // like `useQuery` after the first call. We need direct
          // control over exactly when to retrieve products with related.
          apolloClient
            .query({
              query: productByPermalinkQuery,
              variables: {
                permalink: baseProduct.permalink,
                ...preloadAdditionalQueryVariables.current,
                fetchRelatedProducts: true,
              },
              context: sharedApolloContext.current,
              ...preloadQueryOptions.current,
            })
            .then((productDataWithRelated) => {
              // it is possible we get a 200 response with errors in the response
              // we should not trust that a 200 is automatically successful
              // the else if and else cases here are in place to treat 200 responses that have errors
              if (productDataWithRelated?.data?.product) {
                debug(
                  'Retrieved base product with related products',
                  baseProduct.permalink
                );
                dispatchBPDWR({
                  type: 'BPDWR/Loaded',
                  payload: productDataWithRelated.data.product,
                });
              } else if (productDataWithRelated?.errors?.length) {
                debug(
                  'Failed to load base product with related products',
                  productDataWithRelated.errors
                );
                dispatchBPDWR({
                  type: 'BPDWR/Error',
                  payload: productDataWithRelated.errors,
                });
              } else {
                const errors = [
                  {
                    message:
                      'Response to retrieve related products was not parseable',
                    path: null,
                    extensions: {},
                    locations: [],
                  },
                ];
                debug('Failed to parse response for related products');
                dispatchBPDWR({
                  type: 'BPDWR/Error',
                  payload: errors,
                });
              }
            })
            .catch((err) => {
              debug('Failed to load base product with related products', err);
              dispatchBPDWR({ type: 'BPDWR/Error', payload: err });
            });
        } else {
          debug('No related products to load', baseProduct.permalink);
        }
      }
    }, [
      apolloClient,
      baseProduct,
      baseProductLoading,
      baseProductDataWithRelated,
    ]);

    const loadRelatedProductByProductIds = useCallback(
      (productIds) => {
        return apolloClient.query({
          query: preloadRelatedProductsByProductIdsQuery,
          variables: {
            productIds,
            ...preloadAdditionalQueryVariables.current,
          },
          context: sharedApolloContext.current,
          ...preloadQueryOptions.current,
        });
      },
      [apolloClient]
    );

    // Cache of related products in hook
    // TODO: figure out if we can utilize apollo cache
    // Map<permalink, product>
    const relatedProductsCache = useRef(new Map());

    // track currently selected related product and spread it's data
    const [currentRelatedProduct, setCurrentRelatedProduct] = useState();
    const [currentRelatedProductError, setCurrentRelatedProductError] =
      useState(false);

    const clearRelatedProduct = useCallback(() => {
      setCurrentRelatedProduct(undefined);
      setCurrentRelatedProductError(false);
    }, []);
    const resetBaseProduct = useCallback(
      (updatedPermalinkOrProductId) => {
        clearRelatedProduct();
        // also remove all related products from hook cache
        relatedProductsCache.current.clear();
        dispatchBPDWR({ type: 'BPDWR/reset' });
        updateBaseProductVariables(updatedPermalinkOrProductId);
      },
      [updateBaseProductVariables, clearRelatedProduct]
    );

    // Identify related products that we should preload
    const isBundle = baseProduct?.productTypeId === ProductType.BUNDLE;
    const relatedProductsSkus = isBundle
      ? undefined
      : baseProductDataWithRelated?.product?.skus;
    const relatedProductsPermalink =
      baseProductDataWithRelated?.product?.permalink;

    // Map<permalink, masterProductId>
    const relatedProductsToPreload = useMemo(() => {
      const relatedProducts = new Map();
      if (relatedProductsSkus) {
        relatedProductsSkus.forEach((s) => {
          // NOTE: allow brands to overwrite the default sku check for what to preload
          const preloadRelatedProduct = preloadProductSkuCheck(s);
          if (
            preloadRelatedProduct &&
            // Skip product if same permalink as base product
            relatedProductsPermalink !== s.permalink
          ) {
            relatedProducts.set(s.permalink, s.masterProductId);
          }
        });
      }
      return relatedProducts;
    }, [relatedProductsSkus, relatedProductsPermalink]);

    // STEP 3: load related products - START
    // Pre-load/Cache all available related products
    // when skus are available ( not bundles )
    // ie: provide a smoother user experience when browsing colors
    useEffect(() => {
      if (relatedProductsToPreload.size > 0) {
        const productIds = Array.from(relatedProductsToPreload.values()).sort();
        // Request base data for related products
        // it doesn't matter if this fails since we will re-request
        // when/if the user selects it
        debug('attempting preloading for productIds', `${productIds}`);

        loadRelatedProductByProductIds(productIds)
          .then((relatedProductsData) => {
            debug(
              'preloaded related products, adding to cache',
              `${productIds}`
            );
            // Store related products in local Map cache
            relatedProductsData.data.products.forEach((relatedProduct) => {
              relatedProductsCache.current.set(
                relatedProduct.permalink,
                relatedProduct
              );
            });
          })
          // NOTE: without this catch Apollo with throw the error
          // and crash the app
          .catch((err) => {
            debug('Failed to preload related products', err);
          });
      }
    }, [relatedProductsToPreload, loadRelatedProductByProductIds]);
    // ^ STEP 3 - END

    // NOTE: Construct actual product data here.
    // Combine "initial" and preloaded/lazy product(s)
    // - we want to share aspects of the original product data request
    //   - specifically share skus and attributes
    const currentRelatedProductData = currentRelatedProduct || EMPTY_OBJECT;
    const currentProductData = isBundle
      ? baseProduct
      : baseProductDataWithRelated?.product ?? baseProduct;
    const product = useMemo(() => {
      return currentProductData
        ? {
            ...currentProductData,
            ...currentRelatedProductData,
          }
        : undefined;
    }, [currentProductData, currentRelatedProductData]);

    // When permalink changes we "fetch" the updated product data
    // but this data should already have been preloaded/cached
    // ** UNLESS ** it is new product not related to the current product
    useEffect(() => {
      if (
        !baseProductLoading &&
        !baseProductDataWithRelated.loading &&
        product &&
        permalinkOrProductId !== product.permalink &&
        permalinkOrProductId !== product.masterProductId
      ) {
        const isRelatedProductPermalink =
          relatedProductsToPreload.has(permalinkOrProductId) ||
          [...relatedProductsToPreload.values()].includes(permalinkOrProductId);

        // new permalink is a related product
        if (isRelatedProductPermalink) {
          // check if we successfully preloaded and cached this product
          const relatedProductFromCache =
            relatedProductsCache.current.get(permalinkOrProductId);
          // if the new permalink is related with the initial product loaded
          // then use that products data from cache
          if (relatedProductFromCache) {
            debug('Found related product in cache', permalinkOrProductId);
            setCurrentRelatedProduct(relatedProductFromCache);
          } else {
            debug(
              'No related product in cache, requesting from graphQL',
              permalinkOrProductId
            );
            loadRelatedProductByProductIds([
              relatedProductsToPreload.get(permalinkOrProductId),
            ])
              .then((relatedProductData) => {
                const relatedProduct = relatedProductData.data.products[0];
                debug(
                  'Retrieved related product, adding to cache',
                  permalinkOrProductId
                );
                relatedProductsCache.current.set(
                  relatedProduct.permalink,
                  relatedProduct
                );
                if (!currentRelatedProductData) {
                  setCurrentRelatedProduct(relatedProduct);
                }
              })
              .catch((err) => {
                debug('Failed to load related product', err);
                setCurrentRelatedProductError(true);
              });
          }
        } else if (
          currentProductData &&
          permalinkOrProductId === currentProductData.permalink
        ) {
          debug('setting product back to base product', permalinkOrProductId);
          clearRelatedProduct();
        } else {
          debug(
            'related product not found for permalink, resetting',
            permalinkOrProductId
          );
          // If we do not have a preloaded ID related with the new permalink
          // then this is most likely a completely new product, so we will
          // load the new product & reset preloaded product data
          // NOTE: essentially new groupCode of products
          resetBaseProduct(permalinkOrProductId);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permalinkOrProductId, baseProductLoading]);

    return {
      product,
      baseProductLoading,
      relatedProductsLoading: baseProductDataWithRelated.loading,
      productError:
        baseProductError ||
        baseProductDataWithRelated.error ||
        currentRelatedProductError,
    };
  }

  return useProductQueryAndPreload;
}

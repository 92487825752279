import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Picture from '../Picture';
import { useProductContext } from '../ProductContext';
import { desktop } from '../styles';
import { getWebPImageSource } from '../useProductImages';

const Wrapper = styled.div`
  position: relative;
`;

const HoverPicture = styled(Picture)`
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  ${desktop`
    ${Wrapper}:hover & {
      display: block;
    }
  `}
`;

const Placeholder = styled.div`
  background: #eee;
  padding-top: ${({ aspectRatio }) => 100 / aspectRatio}%;
`;

// If renderWebP flag is set, duplicate the images and create webp images and add it to the srcList.
// webp images are added first to the list so that if any browser doesn't support it, it can fallback
// to the 2nd set of images(original) one.
const getImageSrcList = (images, renderWebP) => {
  const srcList = [];
  if (renderWebP) {
    const webPImages = getWebPImageSource(images);
    srcList.push(webPImages);
  }
  srcList.push({ images });
  return srcList;
};

const ProductImage = ({
  product: productFromProps,
  aspectRatio,
  containerMaxWidth,
  type = 'default',
  primaryAngle,
  secondaryAngle,
  alt,
  renderWebP = false,
  breakpoints,
  disableHover = false,
  loading = 'eager',
  ...rest
}) => {
  const productFromContext = useProductContext();

  const { productImages } = productFromProps || productFromContext;

  const images = useMemo(() => {
    if (!productImages) {
      return null;
    }
    // Retrieve the correct type of images (default or plus),
    // falling back to default if no plus images exist
    const imageSet =
      productImages[type] && productImages[type].length
        ? productImages[type]
        : productImages.default;

    if (!imageSet || !imageSet.length) {
      return null;
    }
    // Get primary and secondary image sets with given angles, use the first two sets of images by default
    const getImagesByAngle = (angle) =>
      imageSet.find((img) => img.angle === angle);

    const primaryAngleImages =
      (primaryAngle != null && getImagesByAngle(primaryAngle)) || imageSet[0];
    const secondaryAngleImages =
      (secondaryAngle != null && getImagesByAngle(secondaryAngle)) ||
      imageSet[1];

    const primaryImageSources = primaryAngleImages
      ? primaryAngleImages.sizes
      : undefined;
    const secondaryImageSources = secondaryAngleImages
      ? secondaryAngleImages.sizes
      : undefined;

    return {
      primaryImageSources: primaryImageSources
        ? getImageSrcList(primaryImageSources, renderWebP)
        : undefined,
      secondaryImageSources: secondaryImageSources
        ? getImageSrcList(secondaryImageSources, renderWebP)
        : undefined,
    };
  }, [type, productImages, primaryAngle, secondaryAngle, renderWebP]);

  // If there are no images, and we also don't know the aspect ratio, then
  // render nothing.
  if (!images && !aspectRatio) {
    return null;
  }

  return (
    <Wrapper
      {...rest}
      data-autotag={`product-image${type === 'plus' ? '-plussize' : ''}`}
    >
      {images ? (
        <>
          <Picture
            sources={images.primaryImageSources}
            aspectRatio={aspectRatio}
            containerMaxWidth={containerMaxWidth}
            loading={loading}
            alt={alt}
            breakpoints={breakpoints}
          />
          {!disableHover && images.secondaryImageSources ? (
            <HoverPicture
              breakpoints={breakpoints}
              sources={images.secondaryImageSources}
              aspectRatio={aspectRatio}
              containerMaxWidth={containerMaxWidth}
              data-autotag="product-image-on-hover"
              loading="lazy"
              alt={alt}
            />
          ) : null}
        </>
      ) : (
        <Placeholder aspectRatio={aspectRatio} role="img" />
      )}
    </Wrapper>
  );
};

ProductImage.propTypes = {
  /**
   * Image alt property to pass to Picture component
   */
  alt: PropTypes.string,
  /**
   * Image aspect ratio in decimal format. Overrides ratio derived from
   * product image data and enables rendering placeholder when product
   * image data is empty.
   */
  aspectRatio: PropTypes.number,
  /**
   * Custom breakpoints which will be passed on to the Picture
   * in case Picture is not wrapped in Grid context.
   */
  breakpoints: PropTypes.array,
  /**
   * The maximum width of the ProductGrid, which will be passed on to
   * the Picture to calculate its maximum size past the highest breakpoint.
   */
  containerMaxWidth: PropTypes.number,
  /**
   * `disableHover` flag to disable hover image
   */
  disableHover: PropTypes.bool,
  /**
   * Flag to lazy or eager load the main Picture
   */
  loading: PropTypes.oneOf(['eager', 'lazy']),
  /**
   * Angle for primary image set.
   */
  primaryAngle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Product. If none is passed, it will attempt to retrieve one from
   * ProductContext.
   */
  product: PropTypes.object,
  /**
   * `renderWebP` flag renders webp images when set true along with source images.
   */
  renderWebP: PropTypes.bool,
  /**
   * Angle for secondary image set.
   */
  secondaryAngle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The type of the image to render; either 'default' or 'plus'.
   * TODO: This may be retrieved from a Grid context in the future.
   */
  type: PropTypes.oneOf(['default', 'plus']),
};

export default ProductImage;

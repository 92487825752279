import React from 'react';

import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import withThemeProps from '../withThemeProps';

const progressAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const Wrapper = styled.span`
  display: block;
  ${(props) => props.containerStyle};
`;

const ProgressBar = styled.span`
  display: block;
  ${(props) => ({ height: props.thickness })};
  background: ${(props) => props.color || 'currentColor'};
  animation-name: ${(props) => props.animation};
  animation-duration: ${(props) => props.animationDuration}ms;
  animation-timing-function: ${(props) => props.animationTimingFunction};
  animation-iteration-count: infinite;
  ${(props) => props.fillStyle};
`;

/**
 * A loading indicator with a progress bar animation. The progress bar animation
 * will repeat as long as necessary, since it is typically used for showing
 * something with indeterminate progress.
 */
export function ProgressLoadingIndicator({
  animation,
  animationDuration,
  animationTimingFunction,
  children,
  color,
  fillStyle,
  thickness,
  ...rest
}) {
  return (
    <Wrapper {...rest}>
      <ProgressBar
        animation={animation}
        animationDuration={animationDuration}
        animationTimingFunction={animationTimingFunction}
        color={color}
        data-progress-bar=""
        fillStyle={fillStyle}
        thickness={thickness}
      />
      {children}
    </Wrapper>
  );
}

ProgressLoadingIndicator.propTypes = {
  /**
   * A CSS animation name or `keyframes` object created with
   * `styled-components`.
   */
  animation: PropTypes.any,
  /**
   * The duration of the progress animation in milliseconds.
   */
  animationDuration: PropTypes.number,
  /**
   * The easing function.
   */
  animationTimingFunction: PropTypes.string,
  /**
   * Content to render inside the container and after the progress bar element.
   * You can use this to include a message.
   */
  children: PropTypes.node,
  /**
   * The fill color of the progress bar. By default it will inherit the current
   * foreground color.
   */
  color: PropTypes.string,
  /**
   * Styles to apply to the outer container element that the progress bar will
   * grow to fill. Can be anything `styled-components` supports in `styled`
   * interpolations.
   */
  containerStyle: PropTypes.any,
  /**
   * Styles to apply to the inner progress bar element that will grow to fill
   * its container. Can be anything `styled-components` supports in `styled`
   * interpolations.
   */
  fillStyle: PropTypes.any,
  /**
   * The height of the inner progress bar element.
   */
  thickness: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ProgressLoadingIndicator.defaultProps = {
  'aria-label': 'Loading…',
  animation: progressAnimation,
  animationDuration: 3000,
  animationTimingFunction: 'ease-out',
  color: 'currentColor',
  thickness: 4,
};

export default withThemeProps(ProgressLoadingIndicator, {
  section: 'progressLoadingIndicator',
  defaultVariant: 'default',
});

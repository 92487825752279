import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';
import withThemeProps from '../withThemeProps';

const Wrapper = styled.div`
  text-align: left;

  ${(props) => props.wrapperStyle};
`;

const InputBox = styled.fieldset`
  border: 1px solid #888;
  padding: 1px;
  background: white;
  color: black;
  cursor: text;

  &[data-has-focus] {
    border-color: ${(props) => props.theme.colors.focusColor};
    /* widen border without shifting/jumping */
    border-width: 2px;
    padding: 0;

    &[data-label-position='legend'] {
      /* fieldset/legend have weird layout behavior, need this offset on one of
         the vertical dimensions. */
      padding-top: 1px;
    }
  }

  ${(props) => props.inputBoxStyle};
`;

const HStack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BeforeInput = styled.div`
  margin: 3px 0 5px 8px;
  font-size: 16px;
  line-height: 1.25;
`;

const AfterInput = styled.div`
  margin: 3px 8px 5px 0;
  font-size: 16px;
  line-height: 1.25;
`;

const Label = styled.label`
  display: block;

  &[data-label-position='before'] {
    font-size: 14px;
    margin-bottom: 4px;
  }

  &[data-label-position='floating'],
  &[data-label-position='inside'] {
    margin: 2px 8px 0 8px;
    padding: 0;
    font-size: 16px;
    line-height: 1;
    transform: translate3d(0, 13px, 0) scale(1);
    transform-origin: left bottom;
    transition-property: transform;
    transition-duration: 200ms;
    pointer-events: none;
  }

  &[data-label-position='inside'] {
    transform: translate3d(0, 0, 0) scale(0.75);
    pointer-events: auto;
  }

  ${(props) => props.labelStyle};
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0;
  padding: 3px 8px 5px 8px;
  font-family: inherit;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.25;
  background: transparent;
  color: inherit;
  transform: translate3d(0, 0, 0);
  transform-origin: left top;
  transition-property: transform;
  transition-duration: 200ms;

  :focus {
    outline: none;
  }

  [data-label-position='floating'] & {
    transform: translate3d(0, -8px, 0);
  }

  ${(props) => props.inputStyle};
`;

const Hint = styled.p`
  margin: 8px 0 0 0;
  font-size: 13px;

  ${(props) => props.hintStyle};
`;

const ErrorMessage = styled.p`
  margin: 8px 0 0 0;
  font-size: 13px;
  color: #f44;

  ${(props) => props.errorStyle};
`;

const Legend = styled.legend`
  font-size: 10px;
  line-height: 1;
  margin-left: 8px;

  ${(props) => props.legendStyle};
`;

/**
 * Text input field withs upport for various label positions and adornments.
 */
// eslint-disable-next-line react/display-name
export const TextField = React.forwardRef(
  (
    {
      afterInput,
      beforeInput,
      className,
      error,
      errorStyle,
      hint,
      hintStyle,
      id,
      inputBoxStyle,
      inputStyle,
      label,
      labelPosition,
      labelStyle,
      legendStyle,
      multiline,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      style,
      touched,
      type,
      value,
      wrapperStyle,
      ...rest
    },
    ref
  ) => {
    const autoId = useId();
    const hintId = useId();
    const internalRef = useRef();
    const inputRef = ref || internalRef;
    const [internalValue, setInternalValue] = useState('');
    const [hasFocus, setFocus] = useState(false);
    const hasError = error != null && error !== false;
    const hasErrorMessage = hasError && error !== true;
    const hasValue =
      value == null ? internalValue !== '' : value.toString() !== '';
    const hasLabel = Boolean(label);

    if (labelPosition === 'floating') {
      if (hasFocus || hasValue || beforeInput) {
        labelPosition = 'inside';
      }
    }
    if (!hasLabel) {
      labelPosition = 'none';
    }

    useEffect(() => {
      if (
        document.hasFocus() &&
        inputRef.current.contains(document.activeElement)
      ) {
        setFocus(true);
      }
    }, [inputRef]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (inputRef.current.value !== internalValue) {
        setInternalValue(inputRef.current.value);
      }
    });

    const focus = useCallback(() => {
      inputRef.current.focus();
    }, [inputRef]);

    const handleChange = useCallback(
      (event) => {
        setInternalValue(event.target.value);
        if (onChange) {
          onChange(event);
        }
      },
      [onChange]
    );

    const handleFocus = useCallback(
      (event) => {
        setFocus(true);
        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus]
    );

    const handleBlur = useCallback(
      (event) => {
        setFocus(false);
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur]
    );

    if (!id) {
      id = autoId;
    }

    if (multiline && type !== 'text') {
      multiline = false;
      // eslint-disable-next-line no-console
      console.warn(
        "The 'multiline' prop may only be used with the 'text' input type and will be ignored."
      );
    }

    if (!hasLabel && !rest['aria-label']) {
      // eslint-disable-next-line no-console
      console.warn(
        "The TextField has no 'label' or 'aria-label'! It must have a label for accessibility purposes; 'placeholder' is not an acceptable substitute."
      );
    }

    const commonProps = {
      hasError,
      hasFocus,
      hasLabel,
      hasValue,
    };

    let input = (
      <Input
        aria-describedby={hint ? hintId : undefined}
        data-field-input=""
        id={id}
        inputStyle={inputStyle}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={labelPosition === 'floating' ? '' : placeholder}
        ref={inputRef}
        type={type}
        value={value}
        {...commonProps}
        {...rest}
      />
    );

    if (multiline) {
      input = React.cloneElement(input, { as: 'textarea' });
    }

    const labelElement = hasLabel ? (
      <Label
        data-field-label=""
        data-label-position={labelPosition}
        hasError={hasError}
        htmlFor={id}
        labelStyle={labelStyle}
        {...commonProps}
      >
        {label}
      </Label>
    ) : null;
    return (
      <Wrapper
        className={className}
        style={style}
        wrapperStyle={wrapperStyle}
        {...commonProps}
      >
        {labelPosition === 'before' ? labelElement : null}
        <InputBox
          data-field-input-box=""
          data-has-focus={hasFocus ? '' : undefined}
          data-has-label={hasLabel ? '' : undefined}
          data-has-value={hasValue ? '' : undefined}
          data-label-position={labelPosition}
          inputBoxStyle={inputBoxStyle}
          onClick={focus}
          {...commonProps}
        >
          {labelPosition === 'floating' || labelPosition === 'inside'
            ? labelElement
            : null}
          {labelPosition === 'legend' ? (
            <Legend legendStyle={legendStyle} {...commonProps}>
              {labelElement}
            </Legend>
          ) : null}
          <HStack>
            {beforeInput ? (
              <BeforeInput data-before-input="">{beforeInput}</BeforeInput>
            ) : null}
            {input}
            {afterInput ? (
              <AfterInput data-after-input="">{afterInput}</AfterInput>
            ) : null}
          </HStack>
        </InputBox>
        {hint ? (
          <Hint
            data-field-hint=""
            hintStyle={hintStyle}
            id={hintId}
            {...commonProps}
          >
            {hint}
          </Hint>
        ) : null}
        {hasErrorMessage ? (
          <ErrorMessage
            data-field-error=""
            data-autotag={
              rest['data-autotag'] ? `${rest['data-autotag']}-err` : undefined
            }
            errorStyle={errorStyle}
            {...commonProps}
          >
            {error}
          </ErrorMessage>
        ) : null}
      </Wrapper>
    );
  }
);

TextField.propTypes = {
  /**
   * Element(s) to render within the visual input area after the `<input>` or
   * `<textarea>` elements.
   */
  afterInput: PropTypes.node,
  /**
   * Element(s) to render within the visual input area before the `input` or
   * `textarea` elements.
   */
  beforeInput: PropTypes.node,
  /**
   * Class name(s) to apply to the container element.
   */
  className: PropTypes.string,
  /**
   * Error message or flag. If you'd like to indicate a field error without
   * supplying a message, pass `true`.
   */
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  /**
   * Styles to pass to the error message. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  errorStyle: PropTypes.any,
  /**
   * Hint message.
   */
  hint: PropTypes.string,
  /**
   * Styles to pass to the hint message. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  hintStyle: PropTypes.any,
  /**
   * The `id` to add to the input element, also used to populate the label's
   * `for` attribute.
   */
  id: PropTypes.string,
  /**
   * Styles to apply to the visual input area that encapsulates the actual
   * `input` or `textarea` element. It will be included along with the base
   * styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  inputBoxStyle: PropTypes.any,
  /**
   * Styles to pass to the `input` or `textarea` element. It will be included
   * along with the base styles and can be anything styled-components supports
   * in its tagged template literals, including strings, objects, and functions.
   */
  inputStyle: PropTypes.any,
  /**
   * Field label. It should not include a `<label>` element; this component does
   * that for you.
   */
  label: PropTypes.node,
  /**
   * The placement of the field label.
   */
  labelPosition: PropTypes.oneOf(['before', 'floating', 'legend', 'inside']),
  /**
   * Styles to pass to the `label` element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  labelStyle: PropTypes.any,
  /**
   *  Style to pass to `legend` element. It will be included along with the base
   * styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  legendStyle: PropTypes.any,
  /**
   * Whether the text field will be multiline (via a `textarea` element) or not.
   */
  multiline: PropTypes.bool,
  /**
   * Blur event handler.
   */
  onBlur: PropTypes.func,
  /**
   * Change event handler.
   */
  onChange: PropTypes.func,
  /**
   * Focus event handler.
   */
  onFocus: PropTypes.func,
  /**
   * Placeholder label or example of what should be entered in the field. For
   * floating labels, it only becomes visible after the input is focused.
   */
  placeholder: PropTypes.string,
  /**
   * Inline styles to apply to the root element.
   */
  style: PropTypes.object,
  /**
   * Whether the input has been touched or not.
   */
  touched: PropTypes.bool,
  /**
   * Type attribute to pass to the `input` element. If `multiline` is true, this
   * must be `text`, or else `multiline` will be automatically turned off.
   */
  type: PropTypes.oneOf([
    'email',
    'number',
    'password',
    'search',
    'tel',
    'text',
  ]),
  /**
   * Current field value.
   */
  value: PropTypes.string,
  /**
   * Styles to pass to the wrapper element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  wrapperStyle: PropTypes.any,
};

TextField.defaultProps = {
  labelPosition: 'floating',
  multiline: false,
  type: 'text',
};

export default withThemeProps(TextField, {
  section: 'textField',
  defaultVariant: 'default',
});

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Heading from '../Heading';
import { LabelType, useCategoryLabels } from '../LabelProvider';
import { useProductBrowser } from '../ProductBrowserContext';
import { getTopLevelCategory } from '../utils/category';

const CategoryHeading = styled(Heading)`
  text-align: left;
  padding-bottom: 7px;
`;

const labelOptions = { labelType: LabelType.TOP_LEVEL_CATEGORY_HEADING };

function TopLevelCategoryHeading({
  activeCategory: activeCategoryFromProps,
  ...rest
}) {
  const productBrowserCtx = useProductBrowser();
  const categoryLabelCtx = useCategoryLabels();
  let activeCategory = activeCategoryFromProps;
  if (productBrowserCtx && productBrowserCtx.activeCategory) {
    if (!activeCategoryFromProps) {
      activeCategory = productBrowserCtx.activeCategory;
    }
  }

  const category = useMemo(
    () => getTopLevelCategory(activeCategory),
    [activeCategory]
  );

  return (
    <CategoryHeading level={2} {...rest}>
      {categoryLabelCtx.renderLabel(category, labelOptions)}
    </CategoryHeading>
  );
}

TopLevelCategoryHeading.propTypes = { activeCategory: PropTypes.object };

export default TopLevelCategoryHeading;

import camelcaseKeys from 'camelcase-keys';

import { MasterProduct } from '../../../techstyle-shared/react-products';
import { schema } from '../../../techstyle-shared/redux-core';

export const SearchPage = {
  products: [MasterProduct],
  aggregations: new schema.Object(
    {},
    {
      processStrategy(input, parent, key) {
        return camelcaseKeys(input, { deep: true });
      },
    }
  ),
};

import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view-if-needed';

export function SmoothScrollLink({ children, href, onClick, ...rest }) {
  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
      if (!event.defaultPrevented && href.startsWith('#')) {
        const targetId = href.slice(1);
        let target = null;
        if (targetId) {
          target =
            document.getElementById(targetId) ||
            document.querySelector(`a[name="${targetId}"]`);
        } else {
          // Official spec behavior: empty fragment scrolls to the top.
          target = document.body;
        }
        if (target) {
          event.preventDefault();
          const options = {
            // Why not use the `align.topOffset` option from `scroll-into-view`
            // with the current Sticky height here? That would work, but you
            // also need to consider direct visits to `#fragment` URLs. In
            // those cases, there is no control over where the browser scrolls
            // to other than the actual position of the element with the given
            // `id` (or `<a name>`). So to align the scroll position on both
            // types of visits, it's better to fix the offset on the target's
            // end instead of here.
            align: { top: 0, left: 0 },
          };
          scrollIntoView(target, options, (eventType) => {
            if (eventType === 'complete') {
              // Since we called `preventDefault`, the URL hash hasn't actually
              // been updated yet. Do that now that we're done scrolling. Since
              // we already scrolled to the new location ourselves, this should
              // not cause a jump.
              window.location.href = href;
            }
          });
        }
      }
    },
    [href, onClick]
  );

  return (
    <a href={href} onClick={handleClick} {...rest}>
      {children}
    </a>
  );
}

SmoothScrollLink.propTypes = {
  children: PropTypes.node,
  /**
   * The link target. In order to successfully smooth scroll, it must be a
   * URL fragment only (begin with a `#` that matches an `id` or `<a name>` on
   * the page). To automatically account for any fixed position `<Sticky>`
   * elements, you should render the target with `<LinkTarget>`.
   */
  href: PropTypes.string,
  /**
   * Any additional behavior to perform on click besides the smooth scroll.
   * Smooth scroll will be cancelled if you call `preventDefault()` on the
   * event.
   */
  onClick: PropTypes.func,
};

export default SmoothScrollLink;

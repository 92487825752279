import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getMembershipPeriod } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useMembershipPeriod() {
  const actions = useAccountActions();
  const membershipPeriod = useSelector(getMembershipPeriod);

  useEffect(() => {
    if (!membershipPeriod.networkStatus.isUpToDate) {
      actions.loadMembershipPeriod();
    }
  }, [actions, membershipPeriod.networkStatus.isUpToDate]);

  return membershipPeriod;
}

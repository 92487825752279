import React, { useCallback } from 'react';

import styled from 'styled-components';

import BaseLoadMoreButton from '../LoadMoreButton';

import { useContext } from './AccountOrders';

const Button = styled(BaseLoadMoreButton)`
  border: none;
  padding: 14px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default function LoadMoreButton({
  children,
  // eslint-disable-next-line react/prop-types
  onClick,
  hideWhenNoMoreToLoad = true,
  ...rest
}: React.ComponentPropsWithRef<typeof BaseLoadMoreButton> & {
  hideWhenNoMoreToLoad?: boolean;
}) {
  const { canLoadMore, isLoading, loadMore } = useContext();

  const handleLoadMore = useCallback(
    (event: any) => {
      loadMore();
      if (onClick) {
        onClick(event);
      }
    },
    [onClick, loadMore]
  );

  if (hideWhenNoMoreToLoad && !canLoadMore) {
    return null;
  }

  return (
    <Button
      disabled={isLoading || !canLoadMore}
      {...rest}
      isLoading={isLoading}
      onClick={handleLoadMore}
    >
      {children}
    </Button>
  );
}

/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */

export const AdIdTypes = {
  AD_ID: 'adId',
  GEO: 'geo',
  NONE: 'none',
};

export const AlgoIndexTypes = {
  BOUTIQUE: 'boutique',
  BOUTIQUE_MENS: 'boutique_flm',
  BOUTIQUE_YITTY: 'boutique_ytw',
  BOUTIQUE_SCWA: 'boutique_scwa',
  BOUTIQUE_SCWB: 'boutique_scwb',
  BOUTIQUE_SCMA: 'boutique_scma',
  BOUTIQUE_SCMB: 'boutique_scmb',
  AD_ID: 'ad_id',
};

const generateAdId = ({ customerDetails, isScrubs, isFabletics }) => {
  const { utmTerm, gender: genderDetail, shippingZip } = customerDetails;

  const utmId = utmTerm?.split('fb_ad_id_');
  const adId = utmId?.length > 1 ? utmId[1] : null;

  const brand = isScrubs ? 'SC' : isFabletics ? 'FL' : 'YT';
  const gender = genderDetail === 'M' ? 'M' : 'W';

  const brandId = `${brand}${gender}`;
  const contextId = adId || shippingZip;

  // if we have none of these, we can't generate an id
  if (!contextId) {
    return {
      id: null,
      type: AdIdTypes.NONE,
    };
  }

  const id = adId ? `${contextId}_${brandId}` : `${brandId}_${contextId}`;

  if (adId) {
    return {
      id,
      type: AdIdTypes.AD_ID,
    };
  }

  return {
    id,
    type: AdIdTypes.GEO,
  };
};

export const getAlgorithmConfigId = ({ esIndex, algoConfigContext }) => {
  const { customerDetails, membership, isScrubs, isFabletics } =
    algoConfigContext;
  switch (esIndex) {
    case AlgoIndexTypes.BOUTIQUE:
    case AlgoIndexTypes.BOUTIQUE_MENS:
    case AlgoIndexTypes.BOUTIQUE_YITTY:
    case AlgoIndexTypes.BOUTIQUE_SCWA:
    case AlgoIndexTypes.BOUTIQUE_SCWB:
    case AlgoIndexTypes.BOUTIQUE_SCMA:
    case AlgoIndexTypes.BOUTIQUE_SCMB:
      return {
        id: membership?.customerId,
        type: 'customerId',
      };
    case AlgoIndexTypes.AD_ID:
      return generateAdId({ customerDetails, isScrubs, isFabletics });
    default:
      return null;
  }
};

const disabledAdIdTypes = [AdIdTypes.GEO, AdIdTypes.NONE];

export const getAlgoConfig = ({ algoOptions, algoConfigContext }) => {
  const {
    esIndex,
    debug,
    fallbackFpl,
    fallbackFplThreshold,
    disableCollectionOnThresholdUnmet,
    algorithmPageSize,
  } = algoOptions;
  const algorithm = esIndex;
  const algorithmConfigId = getAlgorithmConfigId({
    esIndex,
    algoConfigContext,
  });

  // we disable ad_id:geo lookups for now
  let algoDisabledReason = null;

  if (
    esIndex === AlgoIndexTypes.AD_ID &&
    disabledAdIdTypes.includes(algorithmConfigId?.type)
  ) {
    algoDisabledReason =
      'ad_id algo type disabled due to unsupported type or no ad_id present.';
  }

  return {
    isAlgoDisabled: Boolean(algoDisabledReason),
    algoDisabledReason,
    algorithm,
    fallbackFpl,
    fallbackFplThreshold,
    disableCollectionOnThresholdUnmet,
    algorithmQueryOptions: {
      _id: algorithmConfigId?.id,
      type: algorithmConfigId?.type,
    },
    algorithmPageSize,
    debug,
  };
};

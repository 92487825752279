import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  useReducer,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export function useProductFilters() {
  return useContext(Context);
}

const expandedCollapsibleFiltersReducer = (prevState, action) => {
  const state = { ...prevState };
  if (action.type === 'reset') {
    return {};
  } else {
    state[action.filterField] = !state[action.filterField];
  }
  return state;
};

export default function ProductFilterContext({
  appliedFilters,
  children,
  onApply,
  onChange,
  ...rest
}) {
  const appliedFiltersRef = useRef(appliedFilters);
  // If we get a new `appliedFilters` prop, we should temporarily ignore
  // whatever value is in local state until we can sync it back up with
  // `appliedFilters`. Any changes will be lost (they may no longer be
  // relevant to the new set of filter fields anyway).
  const areFiltersChanging = appliedFilters !== appliedFiltersRef.current;

  const [selectedFiltersFromState, setSelectedFilters] =
    useState(appliedFilters);
  const selectedFilters = areFiltersChanging
    ? appliedFilters
    : selectedFiltersFromState;

  // TODO? Alternatively this could go into ProductBrowserSidebar state
  const [expandedCollapsibleFilters, toggleCollapsibleFilterIsOpen] =
    useReducer(expandedCollapsibleFiltersReducer, {});

  // Sync internal state when `appliedFilters` changes.
  useEffect(() => {
    appliedFiltersRef.current = appliedFilters;
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  const isDirty = selectedFilters !== appliedFilters;

  const value = useMemo(() => {
    const applySelectedFilters = () => {
      if (onApply) {
        onApply(selectedFilters);
      }
    };

    const applyFilters = (newFilters) => {
      if (onApply) {
        onApply(newFilters);
      }
    };

    const updateSelectedFilters = (newFilters) => {
      setSelectedFilters(newFilters);
      if (onChange) {
        onChange(newFilters);
      }
    };

    return {
      appliedFilters,
      applyFilters,
      applySelectedFilters,
      selectedFilters,
      setSelectedFilters: updateSelectedFilters,
      expandedCollapsibleFilters,
      toggleCollapsibleFilterIsOpen,
      isDirty,
    };
  }, [
    appliedFilters,
    isDirty,
    onChange,
    selectedFilters,
    onApply,
    expandedCollapsibleFilters,
    toggleCollapsibleFilterIsOpen,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ProductFilterContext.propTypes = {
  appliedFilters: PropTypes.object,
  children: PropTypes.node,
  onApply: PropTypes.func,
  onChange: PropTypes.func,
};

/**
 * Functions for placing slots (which usually contain assets) into a grid
 * layout. The comments here may contain ASCII art examples; the convention used
 * is to give items letters (a, b, c) and slots numbers (1, 2, 3). When a slot
 * number appears more than once, that means it's a multi-cell slot
 * (2x1, 2x2, etc.).
 *
 * For example, the following grid has 5 items (a-e) and 2 slots (a 2x1 and
 * a 1x1):
 *
 * a 1 1
 * b c d
 * 2 e
 *
 */

/**
 * Normalize and filter the given `inputSlots`. A new set of slot objects based
 * on the input slots will be returned. The output slots will have all fields
 * like `row`, `column`, `cellsWide`, `cellsTall`, etc. resolved to their
 * numeric values. The output array will exclude any slots that don't fit in
 * the given `columnCount` (based on `cellsWide`, `minColumns`, and
 * `maxColumns`). The output slots will maintain the same order as the input.
 * The returned slots will have a null `elementIndex`, which will be determined
 * later when the slots are actually placed (in `placeSlots`).
 */
export function createSlots(inputSlots, { columnCount }) {
  return inputSlots
    .map((inputSlot, originalSlotIndex) => {
      // If `position` is given and `row` is not, translate the given position
      // into its equivalent `row` and `column`. The `position` is interpreted
      // as a 1-based index. This is mostly intended for use with legacy grid
      // assets that predate `row` and `column` support. This will get
      // translated back into an `index` later, but needs to be potentially
      // filtered out first if it ends up being ineligible to be placed.
      if (typeof inputSlot.position === 'number' && !inputSlot.row) {
        const row = Math.ceil(inputSlot.position / columnCount);
        const column = ((inputSlot.position - 1) % columnCount) + 1;
        inputSlot = {
          ...inputSlot,
          row,
          column,
        };
      }

      const cellsWide =
        inputSlot.cellsWide === 'full' ? columnCount : inputSlot.cellsWide || 1;

      let column = inputSlot.column || 1;
      if (column === 'left') {
        column = 1;
      } else if (column === 'right') {
        column = columnCount - cellsWide + 1;
      } else if (column < 0) {
        // left     right
        // -5 -4 -3 -2 -1
        column = columnCount - cellsWide + column + 2;
      }
      const columnIndex = column - 1;
      const row = inputSlot.row || 1;
      const rowIndex = row - 1;
      const cellsTall = inputSlot.cellsTall || 1;
      const cellIndex = rowIndex * columnCount + columnIndex;
      const lastCellIndex =
        (rowIndex + cellsTall - 1) * columnCount +
        (columnIndex + cellsWide - 1);
      const cellCount = cellsWide * cellsTall;
      const minColumns = inputSlot.minColumns || null;
      const maxColumns = inputSlot.maxColumns || null;

      return {
        ...inputSlot,
        originalSlotIndex,
        row,
        rowIndex,
        column,
        columnIndex,
        cellsWide,
        cellsTall,
        cellCount,
        cellIndex,
        lastCellIndex,
        minColumns,
        maxColumns,
        // To be determined during `createLayout`.
        elementIndex: null,
      };
    })
    .filter((slot) => {
      return (
        slot.rowIndex >= 0 &&
        slot.columnIndex >= 0 &&
        slot.columnIndex + slot.cellsWide <= columnCount &&
        (slot.minColumns == null || columnCount >= slot.minColumns) &&
        (slot.maxColumns == null || columnCount <= slot.maxColumns)
      );
    });
}

/**
 * Create a sparse 2D array representing where slots are placed. Any "empty"
 * row or empty cell in a column will be `undefined`. Occupied cells will
 * contain the slot object that occupies them. The input `slots` must be already
 * normalized via `createSlots` above. Slot priority is based on their order
 * in `slots`; earlier slots are placed first (even if they are lower in the
 * grid).
 *
 * An example output array might look like:
 *
 * ```
 * [
 *   // row 1 (no slots)
 *   undefined,
 *   // row 2 (a 2x1 slot occupying columns 2 and 3)
 *   [undefined, firstSlotObject, firstSlotObject, undefined]
 * ]
 * ```
 *
 * ...which means there is a 2x1 slot on row 2 in a 4-column grid.
 */
export function createLayout({ slots, columnCount }) {
  const layout = [];

  const isAvailable = ({
    rowIndex: rowIndexStart,
    columnIndex: columnIndexStart,
    cellsWide,
    cellsTall,
  }) => {
    for (
      let rowIndex = rowIndexStart;
      rowIndex < rowIndexStart + cellsTall;
      rowIndex++
    ) {
      if (layout[rowIndex]) {
        for (
          let columnIndex = columnIndexStart;
          columnIndex < columnIndexStart + cellsWide;
          columnIndex++
        ) {
          if (layout[rowIndex][columnIndex] != null) {
            return false;
          }
        }
      }
    }

    return true;
  };

  slots.forEach((slot) => {
    if (!isAvailable(slot)) {
      return;
    }

    for (
      let rowIndex = slot.rowIndex;
      rowIndex < slot.rowIndex + slot.cellsTall;
      rowIndex++
    ) {
      if (!layout[rowIndex]) {
        layout[rowIndex] = new Array(columnCount);
      }
      for (
        let columnIndex = slot.columnIndex;
        columnIndex < slot.columnIndex + slot.cellsWide;
        columnIndex++
      ) {
        layout[rowIndex][columnIndex] = slot;
      }
    }
  });

  return layout;
}

/**
 * Count the minimum number of items required to successfully place all of the
 * slots found in the given `layout`. If `itemCount` is given, this function
 * will return early as soon as the count exceeds the given `itemCount`, without
 * continuing to count (as we already know the layout is invalid).
 *
 * The number of items required is determined by the number of "holes" in the
 * layout, i.e. the empty spaces between slot cells. If there are holes, the
 * layout is invalid. Slots are only allowed to be the final element in the grid
 * if an item is also in the same row as the last slot cell.
 *
 * Examples:
 *
 * The layout below requires at least 6 items. Slot 2 will be removed, since
 * there are not enough items to fill the hole at row 2, column 3. Slot 1 will
 * remain.
 *
 * a b 1 c
 * d e   2
 *
 * The layout below requires at least 4 items. Both slot 1 and slot 2 will be
 * removed, since no items appear on the final row.
 *
 * 1 a b c
 * 1 2
 *
 * We expect that if the layout is invalid (`itemsRequired > itemCount`), a new
 * layout will be attempted with the last slot removed, and so on, until the
 * layout is valid (which could mean no slots are placed).
 */
function countItemsRequired({ layout, itemCount, columnCount }) {
  let itemsRequired = 0;
  for (let rowIndex = 0; rowIndex < layout.length; rowIndex++) {
    const row = layout[rowIndex];
    if (row) {
      const isLastRow = rowIndex === layout.length - 1;
      if (isLastRow) {
        // Count only unfilled spaces before the last slot.
        // If we don't find an unfilled space, require at least one filled
        // space following the last slot.
        let foundHole = false;
        let beforeLastSlot = false;
        for (
          let columnIndex = row.length - 1;
          columnIndex >= 0;
          columnIndex--
        ) {
          const element = row[columnIndex];
          if (element == null) {
            if (beforeLastSlot) {
              itemsRequired += 1;
              foundHole = true;
            }
          } else if (!beforeLastSlot) {
            beforeLastSlot = true;
          }
        }
        if (!foundHole) {
          itemsRequired += 1;
        }
      } else {
        for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
          const element = row[columnIndex];
          if (element == null) {
            itemsRequired += 1;
          }
        }
      }
    } else {
      itemsRequired += columnCount;
    }
    if (itemCount != null && itemsRequired > itemCount) {
      break;
    }
  }
  return itemsRequired;
}

/**
 * Normalize the given input `slots` and create a `layout` that places as many
 * of them as possible without holes in the grid. The output will include the
 * placed `slots`, each assigned an `elementIndex`, and the resulting `layout`.
 */
export function placeSlots({ slots: inputSlots, itemCount, columnCount }) {
  let slots = createSlots(inputSlots, { columnCount });
  let layout = createLayout({ slots, columnCount });
  let minItemCount = countItemsRequired({ layout, itemCount, columnCount });
  // It's pretty hard to actually determine which slots can be placed in one
  // pass, because slots can be dependent on each other for placement. (Maybe
  // it's a case of the bin-packing problem?) So the simplest algorithm is to
  // try a layout, remove the "highest" slot that doesn't fit, then create a new
  // layout without it. We do this until all the remaining slots fit.
  while (minItemCount > itemCount) {
    // Find the last slot not necessarily in placement order, but by which has
    // the highest `lastCellIndex`.
    const lastSlotIndex = slots.reduce(
      (lastSlotIndex, slot, thisSlotIndex, slots) => {
        if (lastSlotIndex === -1) {
          return thisSlotIndex;
        } else if (slot.lastCellIndex > slots[lastSlotIndex].lastCellIndex) {
          return thisSlotIndex;
        } else {
          return lastSlotIndex;
        }
      },
      -1
    );
    slots.splice(lastSlotIndex, 1);
    layout = createLayout({ slots, itemCount, columnCount });
    minItemCount = countItemsRequired({ layout, itemCount, columnCount });
  }
  // Assign an `elementIndex` to each slot. When a slot is merged into the
  // array of items, this will be the index it gets inserted at. It's
  // potentially different from the slot's `cellIndex` due to multi-cell slots
  // (2x1, 2x2, etc.). It's fine to mutate slots here (setting `elementIndex`)
  // because we created these slots above.
  let elementIndex = 0;
  for (let rowIndex = 0; rowIndex < layout.length; rowIndex++) {
    const row = layout[rowIndex];
    if (row) {
      for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
        const element = row[columnIndex];
        if (element == null) {
          elementIndex += 1;
          // We're going to see some slots multiple times (if they occupy
          // multiple cells), so use this check for whether it's been assigned
          // an `elementIndex` to track whether we've encountered it already.
        } else if (element.elementIndex == null) {
          element.elementIndex = elementIndex;
          elementIndex += 1;
        }
      }
    } else {
      elementIndex += columnCount;
    }
  }
  // Return only placed slots, sorted by their `elementIndex`.
  slots = slots.filter((slot) => slot.elementIndex != null);
  slots.sort((slot, otherSlot) => slot.elementIndex - otherSlot.elementIndex);
  return { slots, layout };
}

/**
 * Given a slice of the full set of items from `startIndex` to `stopIndex`, and
 * the slots that fall within this range, return a new array containing both the
 * items and the slots at their expected positions.
 */
export function mergeSlotsWithItems({
  slots,
  items,
  startIndex, // Refers to item index, not cell index!
  stopIndex, // Refers to item index, not cell index!
}) {
  if (items && slots.length) {
    const mergedElements = items.slice();
    slots.forEach((slot) => {
      mergedElements.splice(slot.elementIndex - startIndex, 0, slot);
    });
    return mergedElements;
  }
  return items;
}

import React from 'react';

import AppFromNext, { AppContext } from 'next/app';
import dynamic from 'next/dynamic';

import { errorCodeRedirect } from './errorCodeRedirect';
import type { ErrorCodeRulesByPage } from './errorCodeRedirect';
import withErrorRedirect from './withErrorRedirect';

const ErrorRedirectAdminPanel = dynamic(
  () => import(/* webpackChunkName: "AdminTools" */ './ErrorRedirectAdminPanel')
);

type ErrorCodeExtensionOptions = {
  errorCodeRules?: ErrorCodeRulesByPage;
};

export default function errorRedirectExtension({
  errorCodeRules,
}: ErrorCodeExtensionOptions = {}) {
  return {
    id: 'errorRedirect',
    admin: {
      panels: [
        <ErrorRedirectAdminPanel
          key="errorRedirect"
          errorCodeRules={errorCodeRules}
        />,
      ],
    },
    app: {
      enhance(App: typeof AppFromNext) {
        return withErrorRedirect(App);
      },
      getInitialProps: {
        enhance(getInitialProps: typeof AppFromNext.getInitialProps) {
          if (getInitialProps) {
            return async (appCtx: AppContext) => {
              const { ctx } = appCtx;
              ctx.errorCodes = ctx.errorCodes ?? new Set();
              ctx.handleErrorCode = (errorCode: string) =>
                errorCodeRedirect({
                  code: errorCode,
                  errorCodeRules,
                  ctx,
                });

              return getInitialProps(appCtx);
            };
          }
        },
      },
    },
  };
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.color || props.fill || 'black'};
  vertical-align: top;
`;

const FableticsMarkLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];

  return (
    <Svg
      // Let `aria-label` take control if present. Consumers are also free to
      // override with their own `aria-labelledby`.
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={titleId}>Fabletics</title>
      <path d="M16.1,13.7c-2.8,1.2-7.9,1-8.9,1.2c-1,0.2-3.3-0.1-5,3.4c0.4-0.4,0.9-0.7,1.5-0.7c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2c-0.8,0-1.5-0.5-1.8-1.2c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3v0v0c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0.1-0.7v0c0,0,0,0,0-0.1c0.2-1.5,0.7-3.1,0.7-3.1l0,0c0.3-0.9,0.4-1.3,0.4-1.3c0,0,0,0,0,0c0.6-1.3,1.5-2.8,2.9-3.3c0,0,1.2-0.5,4.1-0.5c0,0,0,0,0,0h0c0.2,0,0.4,0,0.6,0c3,0,4.9-0.2,4.9-0.2c2.3-0.2,3.7-0.6,4.9-1.4 C20.4,9.9,19.4,12.4,16.1,13.7" />
      <path d="M23.3,3.6C23.1,4.2,22.7,5,22,5.7c0,0-0.9,1.2-2.9,2c0,0-1.3,0.6-3.6,0.8c0,0-2,0.2-4.9,0.2c-0.2,0-0.6,0-0.7,0h0c-2.9,0-4.1,0.5-4.1,0.5c-1.4,0.5-2.4,2-2.9,3.3c0,0,0,0,0,0c0,0.1-0.7,1.7-1.3,4.6c0,0,0,0,0,0v-6.7c0-0.3,0-0.7,0.1-1c0.3-2.1,1.5-3.9,3.3-4.9c0.4-0.2,2.1-1,4.9-1c0.8,0,5.5-0.1,7.6-0.1c4.8-0.2,6.2-0.8,6.2-0.8C23.4,3.2,23.3,3.6,23.3,3.6" />
    </Svg>
  );
});

FableticsMarkLogo.displayName = 'FableticsLogo';

FableticsMarkLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   * default multi-tone treatment.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default FableticsMarkLogo;

import React, { useEffect, useRef, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getServerRenderDate, getDateNowFunction } from '../timeModule';

const Context = React.createContext(() => [Date.now, false]);

/**
 * A context provider for accessing the current time while ensuring that the
 * initial client-side render uses the same time as the server. For this to
 * work, you must pass the `initialNow` prop.
 *
 * The context value is a function that can be called to get the current time.
 *
 * Note that components consuming this context will not automatically re-render
 * after mounting, so will not automatically use the latest time; they must
 * trigger their own update. If using the `useRenderTime` hook, this can be
 * done automatically with the `updateOnMount` option.
 */
export default function RenderTime({ children }) {
  const serverRenderDate = useSelector(getServerRenderDate);
  const initialNow = serverRenderDate.getTime();
  const now = useSelector(getDateNowFunction);

  const isInitial = useRef(initialNow != null);
  const value = useCallback(() => {
    const getTime = isInitial.current ? () => initialNow : now;
    return [getTime, isInitial.current];
  }, [initialNow, now]);

  useEffect(() => {
    isInitial.current = false;
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

RenderTime.propTypes = {
  children: PropTypes.node,
};

RenderTime.Context = Context;

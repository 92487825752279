import { useEffect, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Component as AssetProvider } from './AssetProvider';
import { getAssetContainer } from './assetsModule';
import useAssetActions from './useAssetActions';
import { filterAssets } from './utils';

export default function useAsset(
  name,
  {
    multi = false,
    filter: filterFromComponent,
    forceStoreGroup,
    batchKey = forceStoreGroup,
  } = {}
) {
  const actions = useAssetActions();

  // Allow passing a null `name` to conditionally fetch assets. We can't bail
  // out early though due to the rules of hooks.
  const assetContainer = useSelector((state) =>
    name ? getAssetContainer(state, name) : null
  );
  const data = assetContainer ? assetContainer.data : null;

  const { filter: filterFromContext } = useContext(AssetProvider.Context);

  const filter = useMemo(() => {
    return filterFromComponent
      ? filterFromComponent(filterFromContext)
      : filterFromContext;
  }, [filterFromComponent, filterFromContext]);

  const filteredData = useMemo(() => {
    if (!data || !filter) {
      return data;
    }
    return {
      ...data,
      assets: filterAssets(data.assets, filter, multi),
    };
  }, [data, filter, multi]);

  useEffect(() => {
    if (name) {
      actions.loadAssets(name, { forceStoreGroup, batchKey });
    }
  });

  return useMemo(() => {
    if (assetContainer) {
      return { ...assetContainer, data: filteredData };
    }
    return null;
  }, [assetContainer, filteredData]);
}

import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

// * Provide `as` support
const BreadcrumbWrapper = styled.div``;

// Zeplin mocks may still show #999999 for inactive, #676767 is AA compliant
const BreadcrumbItem = styled.span`
  margin: 0;
  color: ${(p) => (p.isActive ? `#222222` : `#676767`)};
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;

  ${({ itemStyle }) => itemStyle};
`;

const Breadcrumbs = React.memo(
  React.forwardRef(
    (
      {
        activeElementType,
        enableTrailingDelimiter = false,
        itemStyle,
        labels,
        delimiter = ` / `,
        ...rest
      },
      ref
    ) => {
      return (
        <BreadcrumbWrapper ref={ref} {...rest}>
          {labels.map((label, index) => {
            const layerNum = index + 1;
            const isActive = layerNum === labels.length;
            return (
              <Fragment key={index}>
                <BreadcrumbItem
                  data-autotag={`grid-breadcrumb-layer-${layerNum}`}
                  isActive={isActive}
                  as={isActive ? activeElementType : undefined}
                  itemStyle={itemStyle}
                >
                  {label}
                </BreadcrumbItem>
                {(!isActive || enableTrailingDelimiter) && delimiter}
              </Fragment>
            );
          })}
        </BreadcrumbWrapper>
      );
    }
  )
);

Breadcrumbs.BreadcrumbItem = BreadcrumbItem;

Breadcrumbs.propTypes = {
  /**
   * Pass this to override the element type (for example, `h1`) that the active
   * breadcrumb item renders as.
   */
  activeElementType: PropTypes.elementType,
  /**
   * The delimiter to use between items.
   */
  delimiter: PropTypes.node,
  /**
   * Enabling this boolean forces the delimiter to render after the final breadcrumb
   * item.
   */
  enableTrailingDelimiter: PropTypes.bool,
  itemStyle: PropTypes.any,
  /**
   * The breadcrumb items. The item at the top of the stack is
   * treated as the most nested (and current) breadcrumb in the chain.
   */
  labels: PropTypes.arrayOf(PropTypes.node).isRequired,
};

Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;

import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  FormattedDate,
  useIntl,
  useRegion,
  FormattedMessage,
} from '../../../../techstyle-shared/react-intl';
import { useProductReviews } from '../ProductReviewsContext';
import ReviewsPhoto from '../ReviewsPhoto/ReviewsPhoto';
import ReviewStars from '../ReviewStars';
import ShowMoreText from '../ShowMoreText';
import SkeletonLine from '../SkeletonLine';
import { mobile, desktop } from '../styles';
import useBreakpoint from '../useBreakpoint';
import { emailAddressIsValid } from '../utils/formValidation';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ReviewWrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: #fff;
  padding: 32px;
  box-shadow: inset 0 1px 0 0 #eeeeee;

  flex-direction: row;

  ${mobile`
    flex-direction: column;
    padding: 16px 0;
  `}

  ${(props) =>
    props.vertical &&
    css`
      flex-direction: column;
      padding: 16px 0;
    `}

  ${({ blockStyle }) => blockStyle}
`;

const ReviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 5px;

  ${desktop`
    width: 192px;
  `}
`;

const ReviewBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ReviewerName = styled.p`
  font-size: 14px;

  font-weight: bold;
  ${({ reviewerNameStyle }) => reviewerNameStyle}
`;

const ProductInfoLabel = styled.div`
  display: flex;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkenGray};

  span {
    &:last-child {
      margin-left: 4px;
    }
  }

  ${({ vertical }) =>
    vertical &&
    css`
      justify-content: space-between;
    `};

  & + & {
    padding-top: 8px;
  }

  ${({ productInfoStyle }) => productInfoStyle};
`;

const ProductInfoValue = styled.span`
  margin-left: 4px;
`;

const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledStars = styled(ReviewStars)`
  margin-right: 4px;
  font-size: 12px;
  ${({ reviewStarsStyle }) => reviewStarsStyle}
`;

const Date = styled.div`
  display: flex;

  span {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.darkenGray};
  }

  ${({ dateStyle }) => dateStyle}
`;

const ReviewContent = styled.span`
  font-size: 14px;

  ${({ contentStyle }) => contentStyle}
`;

const ReviewTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;

  ${mobile`
    font-weight: regular;
  `}
`;

const ReviewerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const ProductInfoWrapper = styled.div`
  margin-top: 32px;

  ${mobile`
    display: none;
  `}

  ${({ productInfoWrapperStyle }) => productInfoWrapperStyle}
`;

const StyledReviewsPhoto = styled(ReviewsPhoto)`
  ${({ reviewerPhotoStyle }) => reviewerPhotoStyle}
`;

export function RecentReviewsLoading({
  blockStyle,
  contentStyle,
  reviewerNameStyle,
  productInfoStyle,
  pageSize = 3,
  vertical,
  ...rest
}) {
  const loadingArray = new Array(pageSize).fill(0);
  const { desktop } = useBreakpoint();
  const shouldRenderProductInfo = desktop && !vertical;

  return (
    <Wrapper {...rest}>
      {loadingArray.map((_, index) => (
        <ReviewWrapper blockStyle={blockStyle} vertical={vertical} key={index}>
          <ReviewHeader>
            <ReviewerWrapper>
              <ReviewerName reviewerNameStyle={reviewerNameStyle}>
                <SkeletonLine width={120} />
              </ReviewerName>
            </ReviewerWrapper>
            {shouldRenderProductInfo && (
              <>
                <ProductInfoLabel productInfoStyle={productInfoStyle}>
                  <SkeletonLine />
                </ProductInfoLabel>

                <ProductInfoLabel productInfoStyle={productInfoStyle}>
                  <SkeletonLine />
                </ProductInfoLabel>
              </>
            )}
          </ReviewHeader>
          <ReviewBody>
            <StarsContainer>
              <SkeletonLine width={180} />
            </StarsContainer>
            <ReviewTitle>
              <SkeletonLine width={100} />
            </ReviewTitle>
            <ReviewContent contentStyle={contentStyle}>
              <SkeletonLine />
            </ReviewContent>
          </ReviewBody>
        </ReviewWrapper>
      ))}
    </Wrapper>
  );
}

RecentReviewsLoading.propTypes = {
  blockStyle: PropTypes.any,
  contentStyle: PropTypes.any,
  pageSize: PropTypes.number,
  productInfoStyle: PropTypes.any,
  reviewerNameStyle: PropTypes.any,
  vertical: PropTypes.bool,
};

export default function RecentReviews({
  blockStyle,
  reviewStarsStyle,
  productInfoStyle,
  productInfoWrapperStyle,
  dateStyle,
  reviewerNameStyle,
  reviewerPhotoStyle,
  membershipBadges,
  vertical,
  contentStyle,
  showMoreTextProps,
  reviewStarProps,
  loading,
  pageSize = 10,
  onPictureClick = () => {},
  ...rest
}) {
  const { reviews } = useProductReviews();
  const { desktop } = useBreakpoint();
  const intl = useIntl();
  const region = useRegion();
  const locale = intl.locale;

  const shouldRenderProductInfo = desktop && !vertical;

  if (loading) {
    const loadingArray = reviews || new Array(pageSize).fill(0);
    return (
      <RecentReviewsLoading
        blockStyle={blockStyle}
        contentStyle={contentStyle}
        pageSize={loadingArray.length}
        productInfoStyle={productInfoStyle}
        reviewerNameStyle={reviewerNameStyle}
        vertical={vertical}
        {...rest}
      />
    );
  }

  return (
    <Wrapper {...rest}>
      {reviews.map(
        (
          {
            reviewerName,
            productSize,
            productColor,
            reviewRating,
            reviewerPhoto,
            reviewTimestamp,
            reviewContent,
            reviewTitle,
            reviewId,
            reviewerMembershipLevel,
          },
          index
        ) => (
          <ReviewWrapper
            blockStyle={blockStyle}
            vertical={vertical}
            key={reviewId || index}
          >
            <ReviewHeader>
              <ReviewerWrapper>
                <ReviewerName reviewerNameStyle={reviewerNameStyle}>
                  {emailAddressIsValid(reviewerName) ? (
                    <FormattedMessage
                      id="site_pdp.reviewer_name_anonymous"
                      defaultMessage="Anonymous"
                    />
                  ) : (
                    reviewerName
                  )}
                </ReviewerName>
                {reviewerMembershipLevel &&
                  membershipBadges &&
                  membershipBadges[reviewerMembershipLevel]}
              </ReviewerWrapper>
              {shouldRenderProductInfo && (
                <>
                  {productSize && (
                    <ProductInfoLabel productInfoStyle={productInfoStyle}>
                      <FormattedMessage
                        id="site_pdp.size_purchased"
                        defaultMessage="Size Purchased:"
                      />
                      <ProductInfoValue>{productSize}</ProductInfoValue>
                    </ProductInfoLabel>
                  )}
                  {productColor && (
                    <ProductInfoLabel productInfoStyle={productInfoStyle}>
                      <FormattedMessage
                        id="site_pdp.color_purchased"
                        defaultMessage="Color:"
                      />
                      <ProductInfoValue>{productColor}</ProductInfoValue>
                    </ProductInfoLabel>
                  )}
                </>
              )}
            </ReviewHeader>
            <ReviewBody>
              <StarsContainer>
                {reviewRating ? (
                  <StyledStars
                    rating={reviewRating}
                    reviewStarsStyle={reviewStarsStyle}
                    {...reviewStarProps}
                  />
                ) : null}
                <Date dateStyle={dateStyle}>
                  <FormattedDate
                    value={reviewTimestamp}
                    day="numeric"
                    month={
                      locale === 'en-GB' || region === 'EU' ? 'numeric' : 'long'
                    }
                    year="numeric"
                  />
                </Date>
              </StarsContainer>
              <ReviewTitle>{reviewTitle}</ReviewTitle>
              <ReviewContent contentStyle={contentStyle}>
                <ShowMoreText
                  text={reviewContent}
                  maxLength={160}
                  {...showMoreTextProps}
                />
              </ReviewContent>

              {vertical && (
                <ProductInfoWrapper
                  productInfoWrapperStyle={productInfoWrapperStyle}
                >
                  {productSize && (
                    <ProductInfoLabel
                      productInfoStyle={productInfoStyle}
                      vertical
                    >
                      <FormattedMessage
                        id="site_pdp.size_purchased"
                        defaultMessage="Size Purchased:"
                      />
                      <ProductInfoValue>{productSize}</ProductInfoValue>
                    </ProductInfoLabel>
                  )}
                  {productColor && (
                    <ProductInfoLabel
                      productInfoStyle={productInfoStyle}
                      vertical
                    >
                      <FormattedMessage
                        id="site_pdp.color_purchased"
                        defaultMessage="Color:"
                      />
                      <ProductInfoValue>{productColor}</ProductInfoValue>
                    </ProductInfoLabel>
                  )}
                </ProductInfoWrapper>
              )}
              <StyledReviewsPhoto
                reviewerPhoto={reviewerPhoto}
                reviewerPhotoStyle={reviewerPhotoStyle}
                onPictureClick={(photo) =>
                  onPictureClick({
                    reviewerName,
                    photo,
                  })
                }
              />
            </ReviewBody>
          </ReviewWrapper>
        )
      )}
    </Wrapper>
  );
}

RecentReviews.propTypes = {
  /**
   * Styles to pass to the block container element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  blockStyle: PropTypes.any,

  /**
   * Styles to pass to the review content element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  contentStyle: PropTypes.any,

  /**
   * Styles to pass to the date element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  dateStyle: PropTypes.any,

  /* Prop to control if the data is still loading. Will render a skeleton of the data */
  loading: PropTypes.bool,

  /**
   *  Object containing components to be selected by the membershipLevel key
   */
  membershipBadges: PropTypes.object,

  /**
   * A callback that's triggered once review picture is clicked.
   * Passes revivers name and image sources as an argument
   */
  onPictureClick: PropTypes.func,

  /**
   *  Size of the page of reviews that is being loaded. Will be used the correct
   * expected amount of skeletons
   */
  pageSize: PropTypes.number,

  /**
   * Styles to pass to the review stars element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  productInfoStyle: PropTypes.any,

  /**
   * Styles to pass to ProductInfoWrapper component, this appears when `vertical` is enabled.
   * By default this section is hidden for mobile.
   *  */
  productInfoWrapperStyle: PropTypes.any,

  // Review content to render in the block
  review: PropTypes.object,

  /**
   * Prop that will be spread on the review stars.
   * Accepts any of the props that can be passed to ReviewStars component
   */
  reviewStarProps: PropTypes.object,

  /**
   * Styles to pass to the review stars element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  reviewStarsStyle: PropTypes.any,
  /**
   * Styles to pass to the review stars element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  reviewerNameStyle: PropTypes.any,

  /**
   * Styles to pass to the review photo element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  reviewerPhotoStyle: PropTypes.any,

  /**
   * Any props that the component ShowMoreText accepts
   */
  showMoreTextProps: PropTypes.any,

  /**
   * Boolean that controls if flex-direction is column and renders or not product info.
   * Supposed to be used when using the recent reviews block inside the side drawer, for example.
   */
  vertical: PropTypes.bool,
};

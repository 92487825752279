import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import PropTypes from 'prop-types';

import {
  useCookies,
  useHydrationStatus,
} from '../../../../techstyle-shared/redux-core';
import useDefaultPageCountReducer from '../useDefaultPageCountReducer';

const Context = React.createContext();

const PageCountProvider = React.memo(function PageCountProvider({
  children,
  cookieName,
  cookieOptions,
  getInitialPropsCount,
  usePageCountReducer = useDefaultPageCountReducer,
}) {
  // Since the count is cookie based, and the app may potentially be using
  // `anonymousServerSession` mode, we want to prevent accidentally reading
  // the cookie too early, like during SSR (where we are not allowed to read
  // user-specific cookies due to `anonymousServerSession`) or before
  // hydration (where there might be a hydration mismatch if the server didn't
  // read the cookie but it's now available in the browser during hydration).
  // So, we add an `isReady` flag that will prevent reading and writing the
  // cookie until after hydration.
  const isReady = useHydrationStatus();
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const pageCountCookie = cookies[cookieName];

  let pageCount = null;
  if (isReady && pageCountCookie != null) {
    const parsedValue = parseInt(pageCountCookie, 10);
    // only set if number
    if (!Number.isNaN(parsedValue)) {
      pageCount = parsedValue;
    }
  }

  const updateRef = useRef();
  const pageCountReducer = usePageCountReducer();

  const setPageCount = useCallback(
    (pageCount) => {
      if (pageCount == null) {
        // remove the cookie
        removeCookie(cookieName);
      } else {
        setCookie(cookieName, pageCount.toString(), cookieOptions);
      }
    },
    [cookieName, cookieOptions, removeCookie, setCookie]
  );

  const value = useMemo(
    () => ({ isReady, pageCount, setPageCount }),
    [isReady, pageCount, setPageCount]
  );

  useEffect(() => {
    updateRef.current = () => {
      const newPageCount = pageCountReducer(pageCount);
      setPageCount(newPageCount);
    };
  }, [pageCount, pageCountReducer, setPageCount]);

  useEffect(() => {
    if (isReady) {
      const updatePageCount = updateRef.current;
      updatePageCount();
    }
    // We want this effect to run whenever `getInitialPropsCount` changes.
  }, [isReady, getInitialPropsCount]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
});

PageCountProvider.propTypes = {
  children: PropTypes.node,
  cookieName: PropTypes.string,
  /**
   * Cookie options like `path`, `expires`, `maxAge`, etc. If none are passed,
   * the react-cookie defaults are used, which results in a session cookie.
   * See the react-cookie docs:
   * https://github.com/reactivestack/cookies/tree/master/packages/react-cookie
   */
  cookieOptions: PropTypes.object,
  /**
   * The raw count of how many times `getInitialProps` has been run.
   */
  getInitialPropsCount: PropTypes.number,
  /**
   * A hook that will return a function to update the page count given the
   * current page count (which may be null). Consumers can override this to
   * return a custom counting function, and since it's a hook, they can call
   * any other hooks that they need for decision making.
   *
   * The hook's return value should be a function of the form:
   * `pageCount => newPageCount`.
   */
  usePageCountReducer: PropTypes.func,
};

PageCountProvider.Context = Context;

export default PageCountProvider;

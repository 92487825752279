import PropTypes from 'prop-types';

import isFeatureEnabled from '../isFeatureEnabled';
import useFeature from '../useFeature';

/**
 * A component that renders a single feature. You must supply the
 * feature's key `handle`. The `useFeatures` hook will be called to load the data,
 * and features.
 */
export default function Feature({
  children,
  handle,
  validateIsEnabled = isFeatureEnabled,
}) {
  const featureState = useFeature(handle);
  if (!handle) {
    throw new Error(
      `Feature requires an handle name but was given ${JSON.stringify(handle)}.`
    );
  }

  if (!children) {
    return null;
  }

  if (typeof children === 'function') {
    return children(featureState);
  }

  return validateIsEnabled(featureState) && children;
}

Feature.propTypes = {
  /**
   * A function to call with the results feature data or a child
   * node to render, if the `validateIsEnabled` function is `true`.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * The handle of the feature.
   */
  handle: PropTypes.string.isRequired,
  /**
   * A validation function that can be passed to determine if the returned
   * feature is meant to be enabled to render the node children.
   */
  validateIsEnabled: PropTypes.func,
};

import { schema } from '../../../techstyle-shared/redux-core';

import { AssetContainerType } from './constants';
import { decodeAssetHtml, resolveAssetUrl } from './utils';

export const AssetErrorType = {
  NOT_FOUND: 'NOT_FOUND',
  OTHER: 'OTHER',
};

/**
 * Groups linked slider images into a new child array and removes the
 * siblings from the main assets array.
 * This is because grouped slider asset images from CMS are returned
 * at the top level with all assets in the container.
 *
 * Example:
 * If we have 8 images as A, B, C, D, E, F, G, H but B&C and E&F are
 * actually linked slider images, we need to remove C and F from the
 * original assets array and group them together in a new `sliderAssets`
 * array inside the first "slider" asset.
 *
 * In a grid, this might look like:
 *   A  BC D
 *   EF G  H
 */
export function sliderAssetsReducer(nestedAssets, asset, index, assets) {
  const isFirstOfMultiple = asset.id === assets[index + 1]?.id;
  const isSubsequentSliderAsset = asset.id === assets[index - 1]?.id;

  // Add subsequent grouped slider items
  if (
    isSubsequentSliderAsset &&
    nestedAssets[nestedAssets.length - 1]?.sliderAssets
  ) {
    nestedAssets[nestedAssets.length - 1].sliderAssets.push(asset);
    return nestedAssets;
  }

  nestedAssets.push({
    ...asset,
    isSlider: isFirstOfMultiple,
    sliderAssets: [asset],
  });
  return nestedAssets;
}

function processAsset(input) {
  // Fix casing of some fields.
  const { endDatetime: endDateTime, ...rest } = input;
  const {
    bgcolor: bgColor,
    hoverimage: hoverImage,
    hovertext: hoverText,
    htmltext: htmlText,
    imagemap: imageMap,
    imagemapname: imageMapName,
    ...options
  } = input.options;

  // Decode custom var values; they will be escaped in the input.
  const customVars = {};
  for (const key in options.customVars) {
    const value = options.customVars[key];
    customVars[key] = decodeAssetHtml(value);
  }

  return {
    ...rest,
    cdnImageFilename: resolveAssetUrl(input.cdnImageFilename, input),
    endDateTime,
    imageFilename: resolveAssetUrl(input.imageFilename, input),
    mobileCdnImageFilename: resolveAssetUrl(
      input.mobileCdnImageFilename,
      input
    ),
    mobileImageFilename: resolveAssetUrl(input.mobileImageFilename, input),
    options: {
      ...options,
      bgColor,
      customVars,
      hoverImage: resolveAssetUrl(hoverImage, input),
      hoverText: decodeAssetHtml(hoverText),
      htmlText: decodeAssetHtml(htmlText),
      imageMap,
      imageMapName,
    },
  };
}

export const AssetContainer = new schema.Entity(
  'AssetContainer',
  {},
  {
    idAttribute(input, parent, key) {
      return key;
    },
    processStrategy(input, parent, key) {
      const { container } = input;
      if (!container.id) {
        return null;
      }
      // Fix casing of some fields.
      const { startendTime: startEndTime, ...options } = container.options;

      let lastId;
      let isFiltered = false;
      // Remove filtered assets, as they won't have IDs or segmentation objects.
      // See: https://jira.justfab.net/browse/FNDRR-1351
      let assets = input.assets
        .filter((asset) => {
          if (asset.filtered) {
            // Asset will be segmented if they're filtered
            isFiltered = true;
          }
          return !asset.filtered;
        })
        .map(processAsset);

      if (container.type === AssetContainerType.SLIDER) {
        assets = assets.reduce(sliderAssetsReducer, []);
      } else {
        // Remove duplicate assets from the API response. They'll always be
        // adjacent, so we only need to remember the previous ID.
        assets = assets.filter((asset) => {
          if (asset.id === lastId) {
            return false;
          }
          lastId = asset.id;
          return true;
        });
      }

      return {
        ...container,
        assets,
        isFiltered,
        name: key,
        options: { ...options, startEndTime },
      };
    },
  }
);

export const AssetContainerMap = new schema.Values(AssetContainer);

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mobile } from '../styles';

const Wrapper = styled.div`
  flex: 0 0 268px;
  margin-right: 32px;
  color: #333333;

  ${mobile`display: none;`};
`;

export default function ProductBrowserSideBar({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
}

ProductBrowserSideBar.propTypes = {
  children: PropTypes.node,
};

import React, { useMemo } from 'react';

import styled from 'styled-components';

import type { OrderHistoryDto } from '../../../../techstyle-shared/react-accounts';
import AccountOrders from '../AccountOrders';
import Heading from '../Heading';
import Picture from '../Picture';
import { desktop } from '../styles';
import BaseTrackPackage from '../TrackPackage';
import { createContext } from '../utils/createContext';
import {
  getOrderStatusFromCode,
  OrderStatus,
} from '../utils/getOrderStatusFromCode';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 18px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  gap: 24px;

  ${desktop`
    padding: 20px 24px;
  `}
`;

const DataColumn = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  text-align: left;
  font-size: 14px;

  ${desktop`
    flex-direction: column;
  `}
`;

const DetailsLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  gap: 4px;
`;

const StatusHeading = styled(Heading)`
  font-size: 20px;
  color: #333;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${desktop`
    flex-direction: row;
    align-items: center;
  `}
`;

const ImagesWrapper = styled.div`
  display: flex;
  gap: 14px;
  flex: 1;
  overflow: hidden;

  ${desktop`
    gap: 8px;
    margin-left: 24px;
  `}
`;

const ImageOverlayText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 700;
  color: #000;
`;

const ImageWrapper = styled.a`
  position: relative;
  cursor: pointer;
`;

type OrderHistoryItemContextValueType = {
  orderId: OrderHistoryDto['orderId'];
  total: OrderHistoryDto['total'];
  datePlaced: OrderHistoryDto['datePlaced'];
  status?: OrderStatus;
  tracking: OrderHistoryDto['tracking'];
  // TODO: Change to OrderHistoryDto['orderLines']
  lineItems: any;
  isReturnable: OrderHistoryDto['isReturnable'];
  rmaIds: OrderHistoryDto['rmaIds'];
  returnByDate: Date;
};

const [useOrderHistoryItemContext, OrderHistoryItemContext] =
  createContext<OrderHistoryItemContextValueType>('OrderHistoryItemContext');

type RootProps = React.PropsWithChildren<{
  // TODO: Update OrderHistoryDto type in react-accounts
  // The orderLines field was added to the API so the type
  // needs to be updated to reflect that change
  order: OrderHistoryDto & { orderLines: any };
}>;

const Root = ({ children, order }: RootProps) => {
  const { getReturnByDate } = AccountOrders.useContext();
  const {
    orderId,
    orderSourceId,
    total,
    datePlaced,
    processingStatuscode,
    tracking,
    orderLines,
    isReturnable,
    rmaIds,
    storeId,
  } = order;

  const status = getOrderStatusFromCode({
    processingStatusCode: processingStatuscode,
  });

  const returnByDate = useMemo(() => {
    const date = new Date(datePlaced);
    return getReturnByDate(date);
  }, [datePlaced, getReturnByDate]);

  const contextValue = useMemo(() => {
    return {
      orderId,
      orderSourceId,
      total,
      datePlaced,
      status,
      tracking,
      lineItems: orderLines,
      isReturnable,
      rmaIds,
      returnByDate,
      storeId,
    };
  }, [
    orderId,
    orderSourceId,
    total,
    datePlaced,
    status,
    tracking,
    orderLines,
    isReturnable,
    rmaIds,
    returnByDate,
    storeId,
  ]);

  return (
    <OrderHistoryItemContext.Provider value={contextValue}>
      {children}
    </OrderHistoryItemContext.Provider>
  );
};

type ImageProps = Omit<
  React.ComponentPropsWithRef<typeof Picture>,
  'sources'
> & { url: string };

const StyledPicture = styled(Picture)`
  width: 70px;

  ${desktop`
    width: 100px;
  `}
`;

const breakpoints = [{ minWidth: 0, pictureWidth: 78 }];

const Image = ({ url, ...rest }: ImageProps) => {
  const sources = useMemo(
    () => [
      {
        images: [
          {
            width: 78,
            height: 110,
            url,
          },
        ],
      },
    ],
    [url]
  );

  return (
    <StyledPicture breakpoints={breakpoints} sources={sources} {...rest} />
  );
};

const OverlayImage = styled(Image)`
  opacity: 0.5;
`;

const TrackPackageLink = ({
  children,
  ...rest
}: React.ComponentPropsWithRef<typeof BaseTrackPackage.Link>) => {
  const { tracking } = useOrderHistoryItemContext();

  return (
    <BaseTrackPackage.Link tracking={tracking} {...rest}>
      {children}
    </BaseTrackPackage.Link>
  );
};

const TrackPackageDisclaimer = ({
  children,
  ...rest
}: React.ComponentPropsWithRef<typeof BaseTrackPackage.Disclaimer>) => {
  const { status } = useOrderHistoryItemContext();

  return status !== OrderStatus.Shipped ? (
    <BaseTrackPackage.Disclaimer {...rest}>
      {children}
    </BaseTrackPackage.Disclaimer>
  ) : null;
};

const TrackPackageNamespace = {
  Link: TrackPackageLink,
  Disclaimer: TrackPackageDisclaimer,
  Wrapper: BaseTrackPackage.Wrapper,
};

export {
  Root,
  Wrapper,
  Header,
  MainContent,
  DetailsLink,
  StatusHeading,
  DataColumn,
  Image,
  OverlayImage,
  ImageWrapper,
  ImagesWrapper,
  ImageOverlayText,
  TrackPackageNamespace as TrackPackage,
  useOrderHistoryItemContext as useContext,
};

import { useCallback, useMemo, useRef, useState } from 'react';

import {
  useHydrationStatus,
  HydrationStatus,
} from '../../../techstyle-shared/redux-core';

import { BreakPoint } from './types';
import useLayoutEffect from './useLayoutEffect';

export function toMediaQuery(
  breakpoint: BreakPoint,
  nextBreakpoint: BreakPoint
) {
  if (typeof breakpoint === 'string') {
    return breakpoint;
  }
  if (typeof breakpoint.mediaQuery === 'string') {
    return breakpoint.mediaQuery;
  }
  const parts = [];
  if (typeof breakpoint.minWidth === 'number') {
    parts.push(`(min-width: ${breakpoint.minWidth}px)`);
  }
  if (typeof breakpoint.maxWidth === 'number') {
    parts.push(`(max-width: ${breakpoint.maxWidth}px)`);
  } else if (nextBreakpoint && typeof nextBreakpoint.minWidth === 'number') {
    parts.push(`(max-width: ${nextBreakpoint.minWidth - 1}px)`);
  }
  if (!parts.length) {
    throw new Error(
      'Could not determine media query for breakpoint: you must specify mediaQuery, minWidth, or maxWidth'
    );
  }
  return parts.join(' and ');
}

function useRenderCount(): [number, () => void] {
  const [value, setValue] = useState(0);
  const forceUpdate = useCallback(() => setValue((value) => value + 1), []);
  return [value, forceUpdate];
}

/**
 * A hook that will choose from a list of given breakpoints and return the
 * last matching one, or the given `defaultBreakpoint` (which may be null) if
 * none match.
 *
 * Again, the *last* matching breakpoint will be selected. This is to reflect
 * what would happen if the same breakpoints were specified in the same order in
 * CSS: if multiple match, the last one's rules win.
 *
 * When used during server-side rendering, and while hydrating in the browser,
 * it will always choose the `initialBreakpoint`, which defaults to the same
 * value as the `defaultBreakpoint`. After that, including when first mounted
 * on renders *after* hydration, it will always use `window.matchMedia` to get
 * the correct current breakpoint.
 *
 * Breakpoints may be specified as media query strings, or otherwise must
 * contain one of `mediaQuery`, `minWidth`, or `maxWidth`. They may also contain
 * other custom properties that are ignored by this hook. This means you can
 * include other details about each breakpoint for your own usage, like its
 * name or number of grid columns.
 *
 * The `breakpoints` array must be a constant or memoized, otherwise a render
 * loop is likely.
 */
export default function useBreakpointSelector(
  breakpoints: BreakPoint[],
  defaultBreakpoint: BreakPoint | null = null,
  initialBreakpoint = defaultBreakpoint
) {
  const latestBreakpoint = useRef<BreakPoint | null>();
  const [renderCount, forceUpdate] = useRenderCount();
  // If the app has already hydrated, then it's safe to use browser features
  // like `window.matchMedia` without waiting for an initial render pass. When
  // hydrating, we'll need to return the `initialBreakpoint` initially (to match
  // what the server returned), but any components mounting after that will be
  // able to return the correct breakpoint on the first pass.
  const isReady = useHydrationStatus(
    (status) => status === HydrationStatus.COMPLETE || renderCount > 0
  );

  const { getLastMatch, startListening } = useMemo(() => {
    if (!isReady) {
      return {};
    }

    const matchMedias = breakpoints.map((breakpoint, i, array) => {
      const nextBreakpoint = array[i + 1];
      const mediaQuery = toMediaQuery(breakpoint, nextBreakpoint);
      const handleChange = () => {
        // `getLastMatch` will be called here and then again when the component
        // actually renders, but that's OK because we're using it here to bail
        // out of an additional render. Cheaper to call this function twice than
        // render twice (where it would be called again anyway).
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const newBreakpoint = getLastMatch();
        if (newBreakpoint !== latestBreakpoint.current) {
          forceUpdate();
        }
      };
      const mediaQueryList = window.matchMedia(mediaQuery);
      const startListening = () => {
        mediaQueryList.addListener(handleChange);
        return () => mediaQueryList.removeListener(handleChange);
      };
      return { breakpoint, mediaQueryList, startListening };
    });

    const getLastMatch = () => {
      const lastIndex = matchMedias.length - 1;
      for (let i = lastIndex; i >= 0; i--) {
        const { breakpoint, mediaQueryList } = matchMedias[i];
        if (mediaQueryList.matches) {
          return breakpoint;
        }
      }
    };

    const startListening = () => {
      const cleanupFns = matchMedias.map(({ startListening }) =>
        startListening()
      );
      return () => cleanupFns.forEach((stopListening) => stopListening());
    };

    return { getLastMatch, startListening };
  }, [breakpoints, forceUpdate, isReady]);

  let breakpoint = initialBreakpoint;

  if (isReady) {
    breakpoint = getLastMatch?.() || defaultBreakpoint;
  }

  useLayoutEffect(() => {
    latestBreakpoint.current = breakpoint;
  }, [breakpoint]);

  useLayoutEffect(() => {
    if (isReady) {
      const stopListening = startListening?.();
      return stopListening;
    } else {
      forceUpdate();
    }
  }, [forceUpdate, isReady, startListening]);

  return breakpoint;
}

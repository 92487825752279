import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import DownChevron from '../DownChevron';

const Fieldset = styled.fieldset`
  position: relative; /* for dropdown icon positioning */
  border: 1px solid #888;
  text-align: left;

  :focus-within {
    border: 2px solid ${(props) => props.theme.colors.focusColor};
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  display: none;
  ${({ $hasError }) =>
    $hasError &&
    css`
      display: block;
    `};
`;

const Select = styled.select`
  width: 100%;
  border: none;
  font-size: 16px;
  padding: 10px 9px 7px;
  appearance: none;
  color: ${({ theme }) => theme.colors.textDisabled};

  &:focus-visible {
    outline: none;
  }
`;

const ChevronDown = styled.div`
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 12px;
`;

const Legend = styled.legend`
  font-size: 10px;
  line-height: 1;
  margin-left: 8px;
`;

const Option = styled.option``;

const PlaceHolder = ({ children, ...rest }) => {
  return (
    <Option value="" disabled selected hidden {...rest}>
      {children}
    </Option>
  );
};

PlaceHolder.propTypes = {
  children: PropTypes.any,
};

const Chevron = ({ ...rest }) => (
  <ChevronDown aria-hidden {...rest}>
    <DownChevron />
  </ChevronDown>
);

const StyledSelect = ({ children, ...rest }) => {
  return <Fieldset {...rest}>{children}</Fieldset>;
};

StyledSelect.ErrorMessage = ErrorMessage;
StyledSelect.Select = Select;
StyledSelect.Option = Option;
StyledSelect.PlaceHolder = PlaceHolder;
StyledSelect.Legend = Legend;
StyledSelect.Chevron = Chevron;

StyledSelect.propTypes = {
  children: PropTypes.any,
};

export default StyledSelect;

import React from 'react';

import styled from 'styled-components';

import { OrderHistoryDto } from '../../../../techstyle-shared/react-accounts';
import { desktop } from '../styles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const StyledLink = styled.a`
  width: 100%;
  color: #fff;
  background-color: #000;
  cursor: pointer;
  text-decoration: none;
  padding: 14px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 8px;

  &[data-disabled='true'] {
    pointer-events: none;
    background-color: #ccc;
  }

  ${desktop`
    width: 215px;
  `}
`;

type LinkProps = React.ComponentPropsWithRef<typeof StyledLink> & {
  tracking?: OrderHistoryDto['tracking'];
  pluralMessage?: (packageNumber: number) => React.ReactNode;
};

const Link = ({
  children,
  tracking,
  pluralMessage,
  ...rest
}: React.PropsWithChildren<LinkProps>) => {
  // Display a disabled link if there are no packages to track
  if (!tracking?.length) {
    return (
      <StyledLink data-disabled {...rest}>
        {children}
      </StyledLink>
    );
  }

  return (
    <>
      {tracking.map(({ trackingUrl }: any, index: number) => (
        <StyledLink key={trackingUrl} href={trackingUrl} {...rest}>
          {tracking.length > 1 && pluralMessage
            ? pluralMessage(index + 1)
            : children}
        </StyledLink>
      ))}
    </>
  );
};

const Disclaimer = styled.p`
  color: #767676;
  font-size: 10px;
`;

export { Link, Disclaimer, Wrapper };

/* eslint-disable react/prop-types */
import React from 'react';

import { FaCheck } from 'react-icons/fa';

import CheckboxRadioBase from '../CheckboxRadioBase';
import { CheckboxRadioBaseProps } from '../CheckboxRadioBase/CheckboxRadioBase';
import withThemeProps from '../withThemeProps';

/**
 * Checkbox input with custom rendering.
 */
export const Checkbox = React.forwardRef<
  HTMLInputElement,
  CheckboxRadioBaseProps
>(({ checkmarkIcon = FaCheck, type = 'checkbox', ...rest }, ref) => {
  return (
    <CheckboxRadioBase
      checkmarkIcon={checkmarkIcon}
      ref={ref}
      type={type}
      {...rest}
    />
  );
});

Checkbox.displayName = 'Checkbox';

export default withThemeProps(Checkbox, {
  section: 'checkbox',
  defaultVariant: 'default',
});

import camelcaseKeys from 'camelcase-keys';
import { schema as normalizrSchema } from 'normalizr';
import omit from 'object.omit';
import pick from 'object.pick';

import ObjectSchema from './ObjectSchema';

export const SessionDetailError = {
  OTHER: 'OTHER',
};

const schema = {
  ...normalizrSchema,
  Object: ObjectSchema,
};

export default schema;

/**
 * Just the store group related data from the `sessions` endpoint.
 */
export const StoreGroup = new schema.Entity(
  'StoreGroup',
  {},
  {
    idAttribute: 'storeGroupId',
  }
);

/**
 * Just the session related data from the `sessions` endpoint.
 */
export const Session = new schema.Entity(
  'Session',
  { storeGroup: StoreGroup },
  {
    // Frustratingly, this must be the key *before* preprocessing with
    // `processStrategy`. Otherwise this would be `sessionKey`.
    idAttribute: 'session',
    processStrategy(input) {
      const storeGroupProps = [
        'autoshipIncentiveProductGroupId',
        'autoshipIncentiveQuantity',
        'boxProgramId',
        'defaultAutoshipDiscountMatrixId',
        'defaultAutoshipShippingOptionId',
        'defaultLanguageId',
        'defaultWarehouseId',
        'freeItemOrderShippingOptionId',
        'masterStoreGroupId',
        'resourceBundleId',
        'sampleProductGroupId',
        'sampleQuantity',
        'shippingOptions',
        'storeCode',
        'storeDomainId',
        'storeDomainTypeId',
        'storeGroupId',
        'storeGroupLabel',
        'storeLabel',
        'storeId',
        'supportEmail',
        'supportHours',
        'supportPhone',
        'supportUrl',
      ];
      const output = omit(input, [
        'baseDomain',
        'creditcardMethod',
        'customer',
        'domain',
        'environment',
        'httpMediaUrl',
        'httpBaseUrl',
        'httpsMediaUrl',
        'httpsBaseUrl',
        'ip',
        'paymentOptions',
        'persistSessionLogging',
        'receiptBodyHtml',
        'receiptBodyText',
        'receiptEmail',
        'receiptSubject',
        'session',
        ...storeGroupProps,
      ]);
      // Move non session related values to a `storeGroup` field.
      output.storeGroup = pick(input, storeGroupProps);
      // Rename these fields.
      output.customerKey = input.customer;
      output.sessionKey = input.session;
      // Fix casing of some keys.
      output.storeGroup.creditCardMethod = input.creditcardMethod;
      if (input.paymentOptions) {
        // `camelcaseKeys` throws an error when given null/undefined, which
        // could happen if we try to normalize an API response that didn't
        // contain `paymentOptions`.
        const paymentOptions = camelcaseKeys(input.paymentOptions);
        // As far as we can tell `paymentSettings` shouldn't really be an
        // array, most likely this is an API mistake. It's a single object with
        // `paymentTypes` as keys.
        if (Array.isArray(paymentOptions.paymentSettings)) {
          paymentOptions.paymentSettings = paymentOptions.paymentSettings[0];
        }
        if (paymentOptions.paymentSettings) {
          // FIXME: Why is it `psp_settings` instead of just `psp`? Ask API team.
          for (const key in paymentOptions.paymentSettings) {
            const value = paymentOptions.paymentSettings[key];
            paymentOptions.paymentSettings[key] = camelcaseKeys(value, {
              deep: true,
            });
          }
        }
        output.storeGroup.paymentOptions = paymentOptions;
      }
      return output;
    },
  }
);

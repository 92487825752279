import React, { useContext, useMemo, useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import getSelectedReviewsSizes from '../getSelectedReviewsSizes';

const ReviewsDrawerContext = React.createContext();

export const useReviewsDrawer = () => useContext(ReviewsDrawerContext);

const DEFAULT_SORT_OPTIONS = [
  {
    label: 'Most Recent',
    value: 'MostRecent',
  },
  {
    label: 'Highest Rated',
    value: 'HighestRated',
  },
  {
    label: 'Lowest Rated',
    value: 'LowestRated',
  },
  {
    label: 'Reviews with Photos',
    value: 'WithPhotos',
  },
];

function ReviewsDrawerProvider({
  children,
  sortOptions = DEFAULT_SORT_OPTIONS,
}) {
  const [product, setProduct] = useState({});
  const { permalink, masterProductId, groupCode } = product;
  const [isReviewsDrawerOpen, setIsReviewsDrawerOpen] = useState(false);
  const [isPhotoDrawerOpen, setPhotoDrawerOpen] = useState(false);
  const [reviewPhoto, setReviewPhoto] = useState({});
  const [rating, setRating] = useState();
  const [sizes, setSizes] = useState([]);
  const [counts, setCounts] = useState();

  let defaultSortBy = sortOptions ? sortOptions[1].value : '';
  if (rating) {
    defaultSortBy = sortOptions[0].value;
  }
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [filteredSizes, setFilteredSizes] = useState([]);
  const [otherSelectors, setOtherSelectors] = useState([]);

  const clearAllFilters = useCallback(() => {
    setRating(undefined);
    setSortBy(defaultSortBy);
    setFilteredSizes([]);
    setOtherSelectors([]);
  }, [defaultSortBy]);

  const openReviewsDrawer = useCallback((newProduct) => {
    if (newProduct) {
      setProduct(newProduct);
    }
    setIsReviewsDrawerOpen(true);
  }, []);

  const closeReviewsDrawer = useCallback(() => {
    setIsReviewsDrawerOpen(false);
    setProduct({});
    clearAllFilters();
  }, [clearAllFilters]);

  const openPhotoDrawer = useCallback((photoProps) => {
    setPhotoDrawerOpen(true);
    setReviewPhoto(photoProps);
  }, []);

  const closePhotoDrawer = useCallback(() => {
    setPhotoDrawerOpen(false);
    setReviewPhoto({});
  }, []);

  const { sizesToQuery } = useMemo(() => {
    return getSelectedReviewsSizes({ sizes, filteredSizes, otherSelectors });
  }, [sizes, filteredSizes, otherSelectors]);

  // When there is an active rating there is an additional selected filter.
  const numSelectedFilters = rating
    ? filteredSizes.length + otherSelectors.length + 1
    : filteredSizes.length + otherSelectors.length;

  const value = useMemo(
    () => ({
      product,
      isReviewsDrawerOpen,
      isPhotoDrawerOpen,
      openPhotoDrawer,
      closePhotoDrawer,
      reviewPhoto,
      setReviewPhoto,
      openReviewsDrawer,
      closeReviewsDrawer,
      clearAllFilters,
      sortBy,
      setSortBy,
      filteredSizes,
      setFilteredSizes,
      otherSelectors,
      setOtherSelectors,
      rating,
      setRating,
      permalink,
      groupCode,
      masterProductId,
      defaultSortBy,
      sortOptions,
      sizes,
      setSizes,
      counts,
      setCounts,
      sizesToQuery,
      numSelectedFilters,
    }),
    [
      product,
      isReviewsDrawerOpen,
      isPhotoDrawerOpen,
      openPhotoDrawer,
      closePhotoDrawer,
      reviewPhoto,
      openReviewsDrawer,
      closeReviewsDrawer,
      clearAllFilters,
      sortBy,
      filteredSizes,
      otherSelectors,
      rating,
      permalink,
      groupCode,
      masterProductId,
      defaultSortBy,
      sortOptions,
      sizes,
      counts,
      sizesToQuery,
      numSelectedFilters,
    ]
  );

  return (
    <ReviewsDrawerContext.Provider value={value}>
      {children}
    </ReviewsDrawerContext.Provider>
  );
}

ReviewsDrawerProvider.propTypes = {
  /* Wrapped components */
  children: PropTypes.node,
  sortOptions: PropTypes.array,
};

export default ReviewsDrawerProvider;

import { createAction } from '../../techstyle-shared/redux-core';

export const trackProductListFilter = createAction('TRACK_PRODUCT_LIST_FILTER');
export const trackWishlist = createAction('TRACK_ADDED_TO_WISHLIST');
export const trackPromoClicked = createAction('TRACK_PROMO_CLICKED');
export const trackPromoViewed = createAction('TRACK_PROMO_VIEWED');
export const identifyUserOnAction = createAction('IDENTIFY_USER_ON_ACTION');

export const trackCompleteRegistration = createAction(
  'TRACK_COMPLETE_REGISTRATION'
);

export const trackProductListSwatchClicked = createAction(
  'TRACK_PRODUCT_LIST_SWATCH_CLICKED'
);

export const trackTaggstarTestEntered = createAction(
  'TRACK_TAGGSTAR_TEST_ENTERED'
);

export const trackCognigyEvent = createAction('TRACK_COGNIGY_EVENT');

export const generatePromoEvent = (promoInfo, options) => {
  const {
    label: creativeLabel,
    imageFilename: imageId,
    isVideoAsset,
  } = promoInfo;

  const name = promoInfo?.container?.name;
  const targetUrl = promoInfo?.options?.targetUrl;
  const customVars = promoInfo?.options?.customVars;

  const isClickable = Boolean(targetUrl || customVars?.link);
  let properties = {};
  let promoEvent = {
    name: options?.eventName,
    properties: {
      feature: 'react',
      loggedin_status: options?.isLoggedIn,
      session_id: options?.sessionId.toString(),
      store_group_id: options?.storeGroupId,
      automated_test: Boolean(options?.cookies.get('automated_test')),
      name: name,
      creative: creativeLabel,
      image_id: imageId,
      clickable: isClickable,
      membership_brand_id: options?.membershipBrandId,
      membership_status: options?.membershipLevelGroup,
      user_status_initial: options?.membershipLevelGroup,
      country: options?.country,
      gender: options?.gender,
      is_scrubs: !!options?.isScrubs?.value,
      dmg_entered_on: options?.dmGatewayCode,
      utm_source: options?.utmSource,
      utm_medium: options?.utmMedium,
      utm_campaign: options?.utmCampaign,
    },
  };

  if (isClickable) {
    properties = {
      ...properties,
      clickable_url: targetUrl || customVars?.link,
    };
  }

  const processedCustomVars =
    customVars &&
    Object.keys(customVars).reduce((acc, currentKey) => {
      const customVar = customVars[currentKey];
      if (customVar) {
        acc[currentKey] = customVar;
      }
      return acc;
    }, {});

  if (isVideoAsset) {
    properties = {
      ...properties,
      video_id: customVars?.VideoId,
    };
  }

  if (processedCustomVars && Object.keys(processedCustomVars).length > 0) {
    properties = {
      ...properties,
      custom_vars: processedCustomVars,
    };
  }

  promoEvent = {
    ...promoEvent,
    properties: {
      ...promoEvent.properties,
      ...properties,
    },
  };

  return promoEvent;
};

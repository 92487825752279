import { FaExclamationCircle } from 'react-icons/fa';
import styled from 'styled-components';

export const SelectorWrapper = styled.div`
  font-size: 16px;
  margin-top: 16px;
`;

export const SelectorCategoryLabelText = styled.div`
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.darkenGray};
  line-height: 1.5;
  font-size: 16px;
  letter-spacing: ${-0.4 / 16}em;
`;

export const SelectedOptionLabelText = styled.span`
  color: ${({ theme }) => theme.colors.lightBlack};
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: ${-0.4 / 16}em;
  text-transform: none;
`;

export const SelectOptionLabelText = styled.span`
  color: ${({ theme }) => theme.colors.errorRed};
  font-size: 16px;
  line-height: 1.14;
  letter-spacing: ${-0.25 / 14}em;
  display: flex;
  text-transform: none;
`;

export const SelectorList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

export const ErrorIcon = styled(FaExclamationCircle)`
  color: ${({ theme }) => theme.colors.errorRed};
  font-size: 16px;
  font-weight: 500;
  vertical-align: text-bottom;
  margin-right: 4px;
`;

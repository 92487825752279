import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

const ReferringPageWrapper = ({ isServer }) => {
  const router = useRouter();

  function storePathValues() {
    const storage = isServer ? global.sessionStorage : window.sessionStorage;
    if (!storage) {
      return;
    }
    const prevPath = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath);
    storage.setItem('currentPath', window.location.pathname);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => storePathValues, [router.asPath]);

  if (isServer) {
    storePathValues();
  }

  return <></>;
};

ReferringPageWrapper.propTypes = {
  isServer: PropTypes.bool,
};

export default function referringPageExtension() {
  return {
    id: 'referringPage',
    app: {
      getInitialProps({ ctx: { isServer } }) {
        return { isServer };
      },
      render({ isServer }, children) {
        return (
          <>
            {children}
            <ReferringPageWrapper isServer={isServer} />
          </>
        );
      },
    },
  };
}

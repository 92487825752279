/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from 'react';

import Dropdown from '../Dropdown';
import RadioButtonGroup from '../RadioButtonGroup';
import { createContext } from '../utils/createContext';

import {
  useContext as useRMAContext,
  RMA_PROCESSES,
  RMAOptions,
} from './RMAFlow';
import type { RMAItem, RMAAction, RMAReason, PartialSKU } from './RMAFlow';

type RMAOptionsItemContext = {
  exchangeUnavailable: boolean;
  rmaItem: RMAItem;
  orderLineId: number;
  rmaProcess?: RMA_PROCESSES;
  selectProcess: (process: RMA_PROCESSES) => void;
  selectAction: (action: RMAAction) => void;
  selectReason: (reason: RMAReason) => void;
  selectSKU: (sku: PartialSKU) => void;
  setExchangeUnavailable: (unavailable: boolean) => void;
};

const [useContext, Context] =
  createContext<RMAOptionsItemContext>('RMASelectionItem');

type RMAOptionsItemProps = {
  orderLineId: number;
};

function Root({
  orderLineId,
  children,
}: React.PropsWithChildren<RMAOptionsItemProps>) {
  const {
    rmaItems,
    selectRMAProcess,
    selectRMAAction,
    selectRMAReason,
    selectRMASKU,
  } = useRMAContext();
  const rmaItem = rmaItems[orderLineId];
  const [exchangeUnavailable, setExchangeUnavailable] = useState(false);

  const selectProcess = useCallback(
    (process: RMA_PROCESSES) => {
      selectRMAProcess(orderLineId, process);
    },
    [orderLineId, selectRMAProcess]
  );

  const selectAction = useCallback(
    (action: RMAAction) => {
      selectRMAAction(orderLineId, action);
    },
    [orderLineId, selectRMAAction]
  );

  const selectReason = useCallback(
    (reason: RMAReason) => {
      selectRMAReason(orderLineId, reason);
    },
    [orderLineId, selectRMAReason]
  );

  const selectSKU = useCallback(
    (sku: PartialSKU) => {
      selectRMASKU(orderLineId, sku);
    },
    [orderLineId, selectRMASKU]
  );
  const contextValue = useMemo(
    () => ({
      exchangeUnavailable,
      orderLineId,
      rmaItem,
      selectProcess,
      selectAction,
      selectReason,
      selectSKU,
      setExchangeUnavailable,
      rmaProcess: rmaItem?.rmaProcess,
    }),
    [
      exchangeUnavailable,
      orderLineId,
      rmaItem,
      selectProcess,
      selectAction,
      selectReason,
      selectSKU,
    ]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

function SelectProcessRadioButtonGroup({
  children,
  onChange,
  ...rest
}: Omit<React.ComponentProps<typeof RadioButtonGroup>, 'name'>) {
  const { rmaItem, selectProcess } = useContext();
  const { rmaProcess } = rmaItem;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (value in RMA_PROCESSES) {
        selectProcess(value as RMA_PROCESSES);
      }

      if (onChange) {
        onChange(event);
      }
    },
    [onChange, selectProcess]
  );

  return (
    <RadioButtonGroup {...rest} onChange={handleChange} value={rmaProcess}>
      {children}
    </RadioButtonGroup>
  );
}

function SelectActionRadioButtonGroup({
  children,
  onChange,
  ...rest
}: Omit<React.ComponentProps<typeof RadioButtonGroup>, 'name'>) {
  const { rmaItem, selectAction } = useContext();
  const { actionId } = rmaItem;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      selectAction(Number(value) as RMAAction);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange, selectAction]
  );

  return (
    <RadioButtonGroup
      {...rest}
      onChange={handleChange}
      value={actionId ? String(actionId) : undefined}
    >
      {children}
    </RadioButtonGroup>
  );
}

function SelectReasonDropdown({ children }: React.PropsWithChildren<{}>) {
  const { rmaItem, selectReason } = useContext();
  const { reasonId } = rmaItem;
  const { returnReasons } = RMAOptions.useContext();

  const options = useMemo(() => {
    if (!returnReasons) {
      return [];
    }

    return returnReasons.map((reason) => ({
      value: String(reason.reasonId),
      name: reason.label,
    }));
  }, [returnReasons]);

  const handleChange = useCallback(
    (option: { value: string; name: string }) => {
      selectReason(Number(option.value) as RMAReason);
    },
    [selectReason]
  );

  return (
    <Dropdown
      value={reasonId ? String(reasonId) : ''}
      options={options}
      onChange={handleChange}
    >
      {children}
    </Dropdown>
  );
}

const SelectReasonDropdownNamespace = Object.assign(SelectReasonDropdown, {
  ...Dropdown,
});

export {
  Root,
  useContext,
  SelectProcessRadioButtonGroup,
  SelectActionRadioButtonGroup,
  SelectReasonDropdownNamespace as SelectReasonDropdown,
};

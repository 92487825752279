import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMembership } from '../../../../techstyle-shared/react-accounts';
import { FormattedDate } from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import { mobile } from '../styles';
import Tooltip from '../Tooltip';

const GreetingContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;

  ${mobile`
    justify-content: flex-start;
    margin-top: 20px;
  `}
`;

const GreetingLabel = styled.p`
  font-weight: 700;
  font-size: 20px;
`;

export const MemberInfoGreeting = ({ status, greeting, ...rest }) => {
  return (
    <GreetingContainer {...rest}>
      <GreetingLabel>{greeting}</GreetingLabel>
      {status}
    </GreetingContainer>
  );
};

MemberInfoGreeting.Label = GreetingLabel;

MemberInfoGreeting.propTypes = {
  greeting: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  status: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const MemberSinceMessage = styled.p`
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;

  ${mobile`
    text-align: left;
  `}
`;

export const MemberInfoMemberSince = ({ memberSince, ...rest }) => {
  const { dateActivated } = useMembership();
  return (
    <MemberSinceMessage {...rest}>
      {memberSince}{' '}
      <FormattedDate
        value={dateActivated}
        month="long"
        day="numeric"
        year="numeric"
      />
    </MemberSinceMessage>
  );
};

MemberInfoMemberSince.propTypes = {
  memberSince: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export const SkipTheMonthButton = styled(Button)`
  width: auto;
  font-weight: 700;
  font-size: 14px;
  padding: 14px;

  ${mobile`
    width: auto;
    flex: 1;
  `}
`;

const StyledTooltipAnchor = styled(Tooltip.Anchor)`
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
`;

const StyledTooltipContent = styled(Tooltip.Content)`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  max-width: 350px;
  padding: 10px;
  position: relative;
  z-index: 1;
`;

const StyledArrow = styled(Tooltip.Arrow)`
  border-style: solid;
  border-width: 0 10px 9px 10px;
  border-color: transparent transparent ${({ theme }) => theme.colors.black};
  position: absolute;
  top: -9px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 9px 10px;
    border-color: transparent transparent ${({ theme }) => theme.colors.black};
    position: absolute;
    top: 2px;
    left: -10px;
  }
`;

export const MemberInfoMoreInfo = ({ children, ...rest }) => {
  return <Tooltip {...rest}>{children}</Tooltip>;
};

MemberInfoMoreInfo.Anchor = StyledTooltipAnchor;
MemberInfoMoreInfo.Content = StyledTooltipContent;
MemberInfoMoreInfo.Arrow = StyledArrow;

MemberInfoMoreInfo.propTypes = {
  children: PropTypes.node,
};

const StyledMessage = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
`;

export const MemberInfoSkipTheMonthMessage = ({ children, ...rest }) => {
  return <StyledMessage {...rest}>{children}</StyledMessage>;
};

MemberInfoSkipTheMonthMessage.propTypes = {
  children: PropTypes.node,
};

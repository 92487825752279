import type { Product } from './dto/Product';

export function transformDimensions(dimensions: Record<any, any>) {
  return dimensions
    ? Object.entries(dimensions)
        .map(([key, dimension]: [string, Record<any, any>]) => {
          const { label, options } = dimension;
          if (key !== '__typename') {
            return {
              field: label?.replace(' ', '_').toLowerCase() ?? null,
              label: label ?? null,
              items:
                options?.map((item: Record<any, any>) => ({
                  backgroundCount: item.count,
                  // TODO: update filterCount to reflect the filtered count and not the initial count
                  filterCount: item.count,
                  value: item.label,
                })) ?? [],
            };
          }
        })
        .filter((item: any) => item !== undefined)
    : dimensions;
}
/**
 *
 * @param products gqlProducts
 * @returns a parsed gqlProducts to V2 product so the format is the same with V2 products
 * to be consumed in the front end.
 */
export function parseGqlProductsToV2(products: Product[]) {
  return products
    ? products.map((product: Product) => {
        const {
          availableQuantity,
          pricing,
          images,
          fpl,
          categories,
          defaultProductCategory,
          relatedProducts,
          tags,
          reviewsSummary,
          tokenSummary,
          ...rest
        } = product;
        return {
          ...rest,
          availableQuantityMaster: availableQuantity?.master,
          availableQuantityAnyProfile: availableQuantity?.anyProfile,
          availableQuantityPreorderMaster: availableQuantity?.preorderMaster,
          promos: pricing?.finalDetails,
          defaultUnitPrice: pricing?.final,
          retailUnitPrice: pricing?.retail,
          saleUnitPrice: pricing?.sales,
          defaultProductCategoryLabel: defaultProductCategory?.label,
          productImages: images,
          featuredProductLocationIdList: fpl?.map((item: any) => item.id) || [],
          productCategoryIdList:
            Array.from(new Set(categories?.map((item: any) => item.id))) || [],
          relatedProductIdObjectList: relatedProducts || [],
          tagIdList: tags?.map((item: any) => item.id) || [],
          reviewCount: reviewsSummary?.count,
          averageReview: reviewsSummary?.averageRating,
          tokenRedemptionQuantity: tokenSummary?.redemptionQty,
        };
      })
    : products;
}

import { useEffect } from 'react';

import { useProductDetailContext } from '../ProductDetail';

import { useErrorContext } from './ErrorContext';

export const useErrorRegistrationContext = (ref, hasError, identifier) => {
  const { addError, removeError, initRegisteredErrors } = useErrorContext();
  const { product } = useProductDetailContext();

  useEffect(() => {
    initRegisteredErrors();
  }, [initRegisteredErrors, product.permalink]);

  useEffect(() => {
    if (hasError) {
      addError(ref, identifier);
    } else if (!hasError) {
      removeError(identifier);
    }
  }, [hasError, addError, ref, removeError, identifier]);
};

import React, { useEffect } from 'react';

import { adjustHue, saturate, darken } from 'polished';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FaQuestionCircle } from 'react-icons/fa';
import { FormattedDate, FormattedRelative } from 'react-intl';
import styled, { ThemeProvider } from 'styled-components';

import AdminPanelSidebar from '../AdminPanelSidebar';
import DataTable from '../DataTable';
import SpinnerLoadingIndicator from '../SpinnerLoadingIndicator';
import adminTheme from '../styles/theme';
import useAdminTools from '../useAdminTools';

const Fields = styled.div`
  display: grid;
  grid-template-columns: auto ${({ valueWidth = '1fr' }) => valueWidth};
  ${({ columnGap = 10, rowGap = 8 }) => ({ columnGap, rowGap })};
  align-items: baseline;
  margin: 0;
  padding: 20px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.8);

  & + & {
    padding-top: 0;
  }
`;

const FieldLabel = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: right;
`;

const TableLabel = styled(FieldLabel)`
  align-self: start;
  margin-top: 15px;
`;

const CheckboxLabel = styled(FieldLabel)`
  align-self: start;

  > * {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Separator = styled.hr`
  grid-column: 1 / -1; /* In a CSS grid, span all columns. */
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableBorder};
  margin: 0.75em 0;
  width: 100%;
`;

const Button = styled.button`
  display: inline-block;
  margin: 0;
  padding: 5px 10px 6px 10px;
  border: 0;
  border-radius: 3px;
  font-family: inherit;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  text-decoration: none;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.7), 0 1px 5px rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    to bottom,
    ${({ color = '#2588c1' }) => color},
    ${({ color = '#2588c1' }) =>
      darken(0.08, saturate(0.1, adjustHue(7, color)))}
  );
  color: #fff;

  &:active {
    transform: translate3d(0, 1px, 0);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.7), 0 0 5px rgba(0, 0, 0, 0.4);
  }
`;

const TableValue = styled.div`
  margin: 8px 0;
`;

function DateTime({ value, showRelative = true }) {
  return (
    <>
      <FormattedDate
        value={value}
        year="numeric"
        month="long"
        day="numeric"
        hour="2-digit"
        minute="2-digit"
        second="2-digit"
        hour12
        timeZoneName="short"
      />{' '}
      {showRelative ? (
        <span
          css={`
            font-size: 11px;
            opacity: 0.7;
          `}
        >
          (<FormattedRelative value={value} />)
        </span>
      ) : null}
    </>
  );
}

DateTime.propTypes = {
  showRelative: PropTypes.bool,
  value: PropTypes.string,
};

const HelpIcon = styled(FaQuestionCircle).attrs({
  'aria-label': 'Help',
  size: 16,
  color: 'rgba(20, 82, 203, 0.8)',
})`
  vertical-align: -4px;
`;

const HelpText = styled.p`
  max-width: 600px;
  font-size: 12px;

  & + & {
    margin-top: 1em;
  }
`;

function Field({ children, label, type }) {
  switch (type) {
    case 'checkbox':
      return (
        <>
          <CheckboxLabel>{children}</CheckboxLabel>
          <div>{label}</div>
        </>
      );
    case 'table':
      return (
        <>
          <TableLabel>{label}</TableLabel>
          <TableValue>{children}</TableValue>
        </>
      );
    default:
      return (
        <>
          <FieldLabel>{label}</FieldLabel>
          <div>{children}</div>
        </>
      );
  }
}

Field.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  type: PropTypes.string,
};

const Code = styled.code`
  font-family: Menlo, Monaco, Consolas, 'Source Code Pro', monospace;
  font-weight: normal;
`;

const InlineCode = styled(Code)`
  margin: 0 1px;
  border-radius: 2px;
  padding: 2px 3px;
  background: rgba(255, 255, 255, 0.9);
  color: rgb(59, 60, 73);
`;

const PreformattedText = styled.pre`
  margin: 0;
  padding: 4px 5px;
  border-radius: 3px;
  font-size: 11px;
  line-height: ${16 / 11};
  background: rgba(255, 255, 255, 0.7);
  color: rgb(59, 60, 73, 0.9);
  white-space: pre-wrap;
`;

function CodeBlock({ children }) {
  return (
    <PreformattedText>
      <Code>{children}</Code>
    </PreformattedText>
  );
}

CodeBlock.propTypes = {
  children: PropTypes.node,
};

export default function AdminPanel({
  children,
  compact = true,
  id,
  label,
  icon,
  onActivate,
}) {
  const { activePanel, dispatch, activePanelNode } = useAdminTools();

  useEffect(() => {
    dispatch({
      type: 'register',
      panel: { id, label, icon, compact, onActivate },
    });
    return () => {
      dispatch({ type: 'unregister', id });
    };
  }, [compact, dispatch, icon, id, label, onActivate]);

  return activePanel === id && activePanelNode
    ? ReactDOM.createPortal(
        <ThemeProvider theme={adminTheme}>
          <>{children}</>
        </ThemeProvider>,
        activePanelNode
      )
    : null;
}

AdminPanel.propTypes = {
  children: PropTypes.node,
  compact: PropTypes.bool,
  icon: PropTypes.elementType,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  /**
   * A function to call when the panel is activated. It can be activated in the
   * following ways:
   *
   * - When the Admin Tools are opened, if it was already the active panel or if
   *   no other panel is active and it is the first one (activated by default).
   * - When the Admin Tools `activate` action is dispatched. The action can
   *   optionally contain a `state` property, which the `onActivate` function
   *   will be called with. Panels can use this to allow their state to be
   *   changed from other components. (For example, the Assets panel adds an
   *   Inspect Asset button to each asset that opens the panel with that asset
   *   selected.) When this action is dispatched, the panels may be re-activated
   *   even if it was already the active panel.
   * - If the panel re-registers itself (which may happen if it remounts or
   *   certain `AdminPanel` props change) and it was already the active panel
   *   when it was unregistered.
   */
  onActivate: PropTypes.func,
};

// Library of helpers for rendering panel content.
AdminPanel.Button = Button;
AdminPanel.DataTable = DataTable;
AdminPanel.Separator = Separator;
AdminPanel.Sidebar = AdminPanelSidebar;
AdminPanel.DateTime = DateTime;
AdminPanel.Field = Field;
AdminPanel.Fields = Fields;
AdminPanel.InlineCode = InlineCode;
AdminPanel.CodeBlock = CodeBlock;
AdminPanel.HelpIcon = HelpIcon;
AdminPanel.HelpText = HelpText;
AdminPanel.SpinnerLoadingIndicator = SpinnerLoadingIndicator;

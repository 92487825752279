import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownMenu from '../DropdownMenu';
import VisuallyHidden from '../VisuallyHidden';

const DropdownList = styled(DropdownMenu.List)`
  margin-top: 58px;
  border-radius: 0 0 12px 12px;
  max-height: 360px;
  overflow-y: auto;

  && {
    padding-top: 0;
  }
  ${({ dropdownListStyle }) => dropdownListStyle};
`;

const DropdownButton = styled(DropdownMenu.Button)`
  border-radius: 13px;
  padding: 16px 20px;
  text-align: left;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;

  & > span {
    margin: 0 4px 0 0;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.disabledDropdownButtonBackground};

    color: ${({ theme }) => theme.colors.dropdownButton};
    cursor: not-allowed;
    svg {
      color: ${({ theme }) => theme.colors.dropdownButton};
    }
  }
`;

const DropdownListItem = styled(DropdownMenu.ListItem)`
  &[aria-current='true'] {
    /* Reset the default style */
    color: ${({ theme }) => theme.colors.textDefault};
  }

  /*
   * Show option highlighted by the keyboard, but give priority to mouse
   * hovering when a mouse is used
   */
  *:not(:hover) > &[aria-current='true'],
  &:hover {
    background: ${({ theme }) => theme.colors.dropdownListItemBackground};
  }
`;

export default function BasicDropdown({
  buttonAutotag,
  className,
  disabled,
  dropdownListStyle,
  label,
  listAutotag,
  name,
  onChange,
  options,
  placeholder,
  value,
}) {
  const selectedOption = options.find((opt) => opt.value === value);

  // We convert any number values to strings for internal use in the dropdown
  // component, but convert them back in the onChange handler
  const stringConvertedOptions = options.map((o) => ({
    ...o,
    value: String(o.value),
  }));
  const stringConvertedValue = String(value ?? '');

  return (
    <DropdownMenu
      name={name}
      className={className}
      options={stringConvertedOptions}
      value={stringConvertedValue}
      onChange={(nextSelectedOption) => {
        onChange(
          options[
            stringConvertedOptions.findIndex(
              (opt) => opt.value === nextSelectedOption.value
            )
          ]
        );
      }}
    >
      <DropdownButton disabled={disabled} data-autotag={buttonAutotag}>
        <VisuallyHidden>
          {label}: {selectedOption ? selectedOption.name : placeholder}
        </VisuallyHidden>

        <span aria-hidden>
          {selectedOption ? selectedOption.name : placeholder || label}
        </span>
      </DropdownButton>

      <DropdownList
        data-autotag={listAutotag}
        data-scroll-lock-scrollable=""
        dropdownListStyle={dropdownListStyle}
      >
        {stringConvertedOptions.map((option) => (
          <DropdownListItem
            value={option.value}
            key={option.value}
            data-autotag={option.dataAutotag}
          >
            {option.name}
          </DropdownListItem>
        ))}
      </DropdownList>
    </DropdownMenu>
  );
}

const optionValueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

BasicDropdown.propTypes = {
  buttonAutotag: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownListStyle: PropTypes.any,
  label: PropTypes.node.isRequired,
  listAutotag: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: optionValueType.isRequired,
      dataAutotag: PropTypes.string,
    })
  ).isRequired,
  placeholder: PropTypes.node,
  value: optionValueType,
};

import config from 'config';

import fableticsTheme from './themes/fabletics/theme';
import savageTheme from './themes/savage/theme';
import yittyTheme from './themes/yitty/theme';

/**
 * Typically, brand related configurations are loaded with config.get()
 * These theme files, however, are used in techstyle registry during bootstrap,
 * and config.get() breaks trying to combine a few config files. Elsewhere it works
 * just fine.
 *
 * In order to set a value on config.get('public'), see the whitelabel folder
 */
const brand = config?.public?.brand?.name;
export const themes = {
  fabletics: fableticsTheme,
  yitty: yittyTheme,
  savage: savageTheme,
};

const theme = themes[brand] ?? themes.yitty;

export default theme;

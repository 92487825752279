import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext(false);

const FocusRegion = React.forwardRef(
  (
    {
      as: ElementType = 'div',
      children,
      initialFocus = false,
      forceFocus = false,
      onBlur,
      onFocus,
      onFocusChange,
      ...rest
    },
    ref
  ) => {
    const internalRef = useRef();
    const [hasFocus, setFocus] = useState(Boolean(initialFocus));
    ref = ref || internalRef;

    const handleBlur = useCallback(
      (event) => {
        const newFocusElement = event.relatedTarget;
        if (!newFocusElement || !ref.current.contains(newFocusElement)) {
          setFocus(false);
        }
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur, ref]
    );

    const handleFocus = useCallback(
      (event) => {
        setFocus(true);
        if (onFocus) {
          onFocus(event);
        }
      },
      [onFocus]
    );

    const focusValue = Boolean(forceFocus || hasFocus);

    useEffect(() => {
      if (document.hasFocus() && ref.current.contains(document.activeElement)) {
        setFocus(true);
      }
    }, [ref]);

    useEffect(() => {
      if (onFocusChange) {
        onFocusChange(focusValue);
      }
    }, [focusValue, onFocusChange]);

    return (
      <ElementType
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      >
        <Context.Provider value={focusValue}>{children}</Context.Provider>
      </ElementType>
    );
  }
);

FocusRegion.displayName = 'FocusRegion';

FocusRegion.propTypes = {
  /**
   * Element type to render; defaults to `div`.
   */
  as: PropTypes.elementType,
  /**
   * Content to render; focusing any descendants will cause the `FocusRegion`
   * to enter its focused state.
   */
  children: PropTypes.node,
  /**
   * Whether to consider the region focused regardless of focus events.
   */
  forceFocus: PropTypes.bool,
  /**
   * Whether the region should be considered focused initially, before any
   * focus events are received.
   */
  initialFocus: PropTypes.bool,
  /**
   * Blur handler; it will be called after updating the internal focused state.
   */
  onBlur: PropTypes.func,
  /**
   * Focus handler; it will be called after updating the internal focused state.
   */
  onFocus: PropTypes.func,
  /**
   * Function to call when the focus state updates; it will receive true if
   * focused, false otherwise. Unlike `onBlur` and `onFocus` which will be
   * called when any descendant receives or loses focus, this will only be
   * called when focus moves in or out of the entire region. The function will
   * also be called with the initial focus value on mount, and when the
   * `onFocusChange` prop itself changes.
   */
  onFocusChange: PropTypes.func,
};

FocusRegion.Context = Context;

export default FocusRegion;

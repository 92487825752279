import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useSession } from '../../../techstyle-shared/redux-core';

import { getCustomerDetail } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useCustomerDetail(key) {
  const actions = useAccountActions();
  const { isLoggedIn } = useSession();

  // Since hooks can't be called conditionally, allow passing a null `key` to
  // skip loading any customer detail record.
  const customerDetail = useSelector((state) =>
    key ? getCustomerDetail(state, key) : null
  );

  const isUpToDate = customerDetail
    ? customerDetail.networkStatus.isUpToDate
    : true;

  useEffect(() => {
    if (key && isLoggedIn && !isUpToDate) {
      actions.loadCustomerDetails([key]);
    }
  }, [actions, isLoggedIn, isUpToDate, key]);

  return customerDetail;
}

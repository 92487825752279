/* eslint-disable camelcase */
import config from 'config';

import logger from '../../shared/utils/logger';
import { createFetch } from '../../techstyle-shared/redux-core';

const debug = logger.extend('Branch API');

export function createBranchClient({ apiUrl }) {
  return createFetch({
    prefixUrl: apiUrl,
  });
}

export function translateBranchDataForSegment(payload) {
  const { data } = payload;
  const {
    '~feature': utm_medium,
    '~channel': utm_source,
    '~campaign': utm_campaign,
    '~content': utm_content,
    '~term': utm_term,
    '~vendor': seo_vendor,
  } = data;
  return {
    utm_medium,
    utm_source,
    utm_campaign,
    utm_content,
    utm_term,
    seo_vendor,
  };
}

export async function getBranchUrlParams({ tld = '.com', value } = {}) {
  const { apiKeys, apiUrl, baseUrl } = config.has('public.branch')
    ? config.get('public.branch')
    : {};
  const apiKey = apiKeys[tld];
  const branchClient = createBranchClient({ apiUrl });

  if (value && apiKey) {
    const dataUrl = baseUrl + value;
    const apiUrl = `url?branch_key=${apiKey}&url=${dataUrl}`;

    debug(`Found URL parameter: '${value}'`);
    try {
      const response = await branchClient(apiUrl);
      if (response && response.status === 200) {
        const data = await response.json();
        const branchData = translateBranchDataForSegment(data);
        debug(`Passing Branch parameters: ${JSON.stringify(branchData)}`);
        return branchData;
      }
    } catch (error) {
      debug(`Error response`, error);
    }
  }
}

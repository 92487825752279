import React from 'react';

export function createContext<A extends {} | null>(
  providerName: string,
  hookNameOverride: string = `use${providerName}Context`,
  providerNameOverride: string = `${providerName}Provider`
) {
  const Context = React.createContext<A | undefined>(undefined);

  const useContext = () => {
    const contextValue = React.useContext(Context);

    if (contextValue === undefined) {
      throw new Error(
        `${hookNameOverride} must be used inside of a ${providerNameOverride}.`
      );
    }

    return contextValue;
  };

  return [useContext, Context] as const;
}

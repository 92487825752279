import React from 'react';

import styled, { css } from 'styled-components';

import ArrowLoadingIndicator from '../../../icons/components/ArrowLoadingIndicator';
import {
  createButtonAdditionalStyle,
  createButtonDisabledStyle,
  createButtonStyle,
} from '../utils/button';

const LoadingIndicatorWrapper = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const sizeVariants = {
  sm: {
    padding: css`
      padding: ${({ theme }) =>
        css`
          ${theme.sizes.xxs}px ${theme.sizes.sm}px
        `};
    `,
    typography: css`
      font-weight: 600;
      ${({ theme: { typography } }) => typography.values.paragraph1};
    `,
  },
  md: {
    padding: css`
      padding: ${({ theme }) =>
        css`
          ${theme.sizes.xs}px 18px
        `};
    `,
    typography: css`
      font-weight: 600;
      ${({ theme: { typography } }) => typography.values.paragraph2};
    `,
  },
  lg: {
    padding: 'padding: 14px 20px',
    typography: css`
      font-weight: 600;
      ${({ theme: { typography } }) => typography.values.paragraph3};
    `,
  },
};

const baseButtonStyle = css`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: auto;
  min-width: fit-content;
  opacity: 1;

  ${({ theme: { button }, size = 'md' }) => css`
    border-radius: ${button.borderRadius};
    ${sizeVariants[size].padding};

    & > span {
      ${sizeVariants[size].typography};
      font-family: inherit;
    }
  `}

  transition: background-color 0.16s ease-in-out, border-color 0.16s ease-in-out;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const baseButtonLabelStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  // Gap to give appropriate space among button children
  ${({ theme: { spacing } }) => css`
    gap: ${spacing.sizes.pixels.xxs}px;
  `}
  transition: color 0.16s ease-in-out;
`;

const StyledArrowLoadingIndicator = styled(ArrowLoadingIndicator)`
  ${({ $fill }) =>
    css`
      fill: ${$fill};
    `}
`;

const baseButton = (fillColor = { color: 'inherit' }) => ({
  loadingIndicator: (
    <LoadingIndicatorWrapper>
      <StyledArrowLoadingIndicator
        $fill={css`
          ${fillColor}
        `}
      />
    </LoadingIndicatorWrapper>
  ),
});

const baseButtonConfig = {
  baseButton,
  baseButtonStyle,
  baseButtonLabelStyle,
};

const secondaryButtonStyles = createButtonStyle({
  ...baseButtonConfig,
  background: css`
    ${({ theme }) => theme.colors.palette.stone}
  `,
  additionalStyles: css`
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      background: ${({ theme }) => theme.colors.palette.stone110};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.colors.palette.gunmetal40};
        background-color: ${({ theme }) => theme.colors.palette.gunmetal20};
      `}
  `,
});

const primaryAddToBagStyles = createButtonStyle({
  ...baseButtonConfig,
  foreground: css`
    ${({ theme }) => theme.colors.palette.white}
  `,
  background: css`
    ${({ disabled, theme }) =>
      disabled
        ? theme.colors.palette.lavender100
        : theme.colors.palette.lavender}
  `,
  additionalStyles: css`
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      background: ${({ theme }) => theme.colors.palette.lavender900};
    }

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
          background: ${({ theme }) => theme.colors.palette.lavender100};
        }
      `}
  `,
});

const tertiaryButtonStyles = createButtonStyle({
  ...baseButtonConfig,
  foreground: css`
    ${({ theme }) => theme.colors.palette.white}
  `,
  background: css`
    ${({ theme }) => theme.colors.alertRed}
  `,
  additionalStyles: css`
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      ${createButtonAdditionalStyle({
        background: css`
          ${({ theme }) => theme.colors.palette.red500};
        `,
      })}
    }

    ${({ disabled }) => disabled && css``}
  `,
});

const primaryButtonStyles = (injectedStyles = '') =>
  createButtonStyle({
    ...baseButtonConfig,
    foreground: css`
      ${({ disabled, theme }) =>
        disabled ? theme.colors.palette.black : theme.colors.palette.white}
    `,
    background: css`
      ${({ disabled, theme }) =>
        disabled
          ? theme.colors.palette.gunmetal20
          : theme.colors.palette.gunmetal}
    `,
    additionalStyles: css`
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.palette.white};

      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: 1px;

      padding: 16px 24px;

      border-radius: 8px;

      &:hover,
      &:focus,
      &:active {
        color: ${({ theme }) => theme.colors.palette.white};
        background: ${({ theme }) => theme.colors.palette.gunmetal300};
      }

      ${injectedStyles}

      ${({ disabled }) =>
        disabled &&
        css`
          span {
            color: ${({ theme }) => theme.colors.palette.gunmetal40};
          }

          &:hover,
          &:focus,
          &:active {
            color: ${({ theme }) => theme.colors.palette.black};
            background-color: ${({ theme }) => theme.colors.palette.gunmetal30};
          }
        `}
    `,
  });

const button = {
  borderRadius: css`
    ${({ theme }) => theme.sizes.xxxs}px
  `,
  variants: {
    // Fabletics-specific button styles below
    primary: primaryButtonStyles(),
    primaryAlt: primaryButtonStyles(),
    secondary: secondaryButtonStyles,
    tertiary: tertiaryButtonStyles,
    alternate: tertiaryButtonStyles,
    primaryAddToBag: primaryAddToBagStyles,
    gradient: tertiaryButtonStyles,
    flBorder: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.primary || '#FF906D'}
      `,
      background: 'transparent',
      border: {
        color: css`
          ${({ theme }) => theme.colors.primary || '#FF906D'}
        `,
        width: 1,
      },
      additionalStyles: css`
        border-radius: 4px;
        border-width: 2px;
      `,
    }),
    flDarkBlue: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.white}
      `,
      background: css`
        ${({ theme }) => theme.colors.darkBlue || '#122C4B'}
      `,
    }),
    flBlack: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.white}
      `,
      background: css`
        ${({ theme }) => theme.colors.palette.black}
      `,
      border: {
        color: css`
          ${({ theme }) => theme.colors.palette.black}
        `,
        width: 1,
      },
    }),
    flStrongRed: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.white}
      `,
      background: css`
        ${({ theme }) => theme.colors.alertRed}
      `,
    }),
    flClear: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.black}
      `,
      background: css`
        ${({ theme }) => theme.colors.clear || '#E8E9E9'}
      `,
      border: {
        color: css`
          ${({ theme }) => theme.colors.palette.white}
        `,
        width: 1,
      },
    }),
    flDeselectFilter: {
      ...baseButton(),
      buttonStyle: css`
        &&& {
          font-size: 14px;
          font-weight: 300;
          text-align: right;
          color: ${({ theme }) => theme.colors.deselectFilterButton};
        }
      `,
    },
    flTiny: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.white}
      `,
      background: css`
        ${({ theme }) => theme.colors.primary || '#FF906D'}
      `,
      border: {
        color: css`
          ${({ theme }) => theme.colors.primary || '#FF906D'}
        `,
        width: 1,
      },
      additionalStyles: css`
        padding: 2px 15px;
        font-weight: normal;
        font-size: 1em;
      `,
    }),
    white: secondaryButtonStyles,
    unstyled: {
      buttonStyle: css`
        cursor: pointer;
        width: auto;
        height: auto;
        border: none;
        margin: 0;
        padding: 0;
        font-size: inherit;
        font-weight: normal;
        background: transparent;
        color: inherit;

        ${(props) =>
          props.disabled &&
          css`
            cursor: not-allowed;
            opacity: 0.5;
          `}
      `,
      labelStyle: baseButtonLabelStyle,
    },
    link: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.palette.black}
      `,
      background: 'transparent',
      additionalStyles: css`
        padding: 0;

        ${({ disabled }) =>
          disabled &&
          createButtonDisabledStyle({
            foreground: css`
              ${({ theme }) => theme.colors.palette.gunmetal30}
            `,
            background: 'transparent',
          })}
      `,
    }),
    linkSecondary: createButtonStyle({
      ...baseButtonConfig,
      foreground: css`
        ${({ theme }) => theme.colors.linkText}
      `,
      background: 'transparent',
      additionalStyles: css`
        padding: 0;

        &:hover,
        &:focus,
        &:active {
          ${createButtonAdditionalStyle({
            foreground: css`
              ${({ theme }) => theme.colors.textEmphasized}
            `,
            background: 'transparent',
          })}
        }

        ${({ disabled }) =>
          disabled &&
          createButtonDisabledStyle({
            foreground: css`
              ${({ theme }) => theme.colors.palette.gunmetal30}
            `,
            background: 'transparent',
          })}
      `,
    }),
  },
  sizeVariants,
};

export default button;

import React, { useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';
import {
  FaRegHeart as EmptyHeartIcon,
  FaHeart as HeartIcon,
} from 'react-icons/fa';
import styled from 'styled-components';

import {
  useAccountActions,
  useWishlistIds,
} from '../../../../techstyle-shared/react-accounts';
import {
  useIntl,
  defineMessages,
} from '../../../../techstyle-shared/react-intl';
import { useProductContext } from '../ProductContext';
import useTabIndex from '../useTabIndex';

const intlAriaLabel = defineMessages({
  removeFromWishlist: {
    id: 'site_product_page.added_to_wishlist',
    defaultMessage: 'added to wishlist',
  },
  addToWishlist: {
    id: 'site_product_page.add_to_wishlist',
    defaultMessage: 'add to wishlist',
  },
});

const Button = styled.button`
  background-color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  padding: 0.5em;
  position: relative;
  ${(p) => ({ fontSize: p.fontSize })};
`;

export const WishlistToggleButton = React.forwardRef(
  (
    {
      addLabel = <EmptyHeartIcon color="#888" />,
      buttonAriaLabel,
      onAdd,
      onRemove,
      product: productFromProps,
      removeLabel = <HeartIcon color="#c5789e" />,
      size = '1em',
      tabIndex,
      ...rest
    },
    ref
  ) => {
    const intl = useIntl();
    const actions = useAccountActions();
    const { hasProductId } = useWishlistIds();
    const productFromContext = useProductContext();

    const product = productFromProps || productFromContext;
    const { masterProductId } = product;

    tabIndex = useTabIndex(tabIndex);

    const isInWishlist = hasProductId(masterProductId);

    const ariaLabel = useMemo(() => {
      if (buttonAriaLabel) {
        return buttonAriaLabel({ product, isInWishlist });
      }
      return intl.formatMessage(
        isInWishlist
          ? intlAriaLabel.removeFromWishlist
          : intlAriaLabel.addToWishlist
      );
    }, [intl, product, isInWishlist, buttonAriaLabel]);

    const addToWishlist = useCallback(() => {
      if (onAdd) {
        onAdd({
          product,
          addToWishlist: () => actions.addWishlistItem(masterProductId),
        });
      } else {
        actions.addWishlistItem(masterProductId);
      }
    }, [product, actions, onAdd, masterProductId]);

    const removeFromWishlist = useCallback(() => {
      if (onRemove) {
        onRemove({
          product,
          deleteFromWishlist: () => actions.deleteWishlistItem(masterProductId),
        });
      } else {
        actions.deleteWishlistItem(masterProductId);
      }
    }, [product, actions, onRemove, masterProductId]);

    const handleClick = useCallback(() => {
      if (isInWishlist) {
        removeFromWishlist();
      } else {
        addToWishlist();
      }
    }, [isInWishlist, addToWishlist, removeFromWishlist]);

    if (!masterProductId) {
      throw new Error(
        `WishlistToggleButton must be used with a valid Product (specified via the 'product' prop or via ProductContext)`
      );
    }

    return (
      <Button
        ref={ref}
        data-autotag={
          isInWishlist
            ? 'grid-favorite-icon-filled'
            : 'grid-favorite-icon-empty'
        }
        aria-label={ariaLabel}
        aria-pressed={isInWishlist}
        onClick={handleClick}
        fontSize={size}
        tabIndex={tabIndex}
        {...rest}
      >
        {isInWishlist ? removeLabel : addLabel}
      </Button>
    );
  }
);

WishlistToggleButton.displayName = 'WishlistToggleButton';

WishlistToggleButton.propTypes = {
  /**
   * Empty icon to indicate when product isn't in wishlist
   */
  addLabel: PropTypes.node,
  /**
   * Allows for a custom aria-label based on whether the product is in wishlist
   */
  buttonAriaLabel: PropTypes.func,
  /**
   * Allows for a custom aria-label based on whether the icon is in wishlist
   */
  onAdd: PropTypes.func,
  /**
   * Allows for a custom aria-label based on whether the icon is in wishlist
   */
  onRemove: PropTypes.func,
  /**
   * Optionally can use the product specified via props instead of context.
   * Product via prop will take precedence over the one (if any) available via context.
   */
  product: PropTypes.object,
  /**
   * Full icon to indicate when product is in wishlist
   */
  removeLabel: PropTypes.node,
  /**
   * Size of the SVG (heart icon within the button)
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The value of the `tabindex` attribute. If the button has an active
   * `<Untabbable>` ancestor, it will automatically be set to -1.
   */
  tabIndex: PropTypes.number,
};

WishlistToggleButton.EmptyHeartIcon = EmptyHeartIcon;
WishlistToggleButton.HeartIcon = HeartIcon;

export default WishlistToggleButton;

import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Checkbox from '../Checkbox';
import { ProductSelectorType } from '../GenericProductAttributeSelector/constants';
import { visuallyHidden } from '../styles';

function getItemStyle({ selectorType, ...rest }) {
  switch (selectorType) {
    case ProductSelectorType.GRID:
      return gridItemStyle;
    case ProductSelectorType.SWATCH:
      return swatchItemStyle;
  }
}

function getInputStyle({ selectorType }) {
  switch (selectorType) {
    case ProductSelectorType.GRID:
      return gridInputStyle;
    case ProductSelectorType.SWATCH:
      return swatchInputStyle;
  }
}

function getBackgroundUrl({ backgroundUrl }) {
  if (backgroundUrl) {
    return css`
      background-image: url('${backgroundUrl}');
    `;
  }
}

const SelectorItemLabel = styled.label`
  font-size: 16px;
  text-align: center;
  position: absolute;
  ${({ hideLabel }) =>
    hideLabel &&
    css`
      ${visuallyHidden}
    `}
  ${({ itemLabelStyle }) => itemLabelStyle};
`;

// The current width of diagonal is 3px.
// To update the diagonal width to 1px, change all 1px to 0.5px as override inside itemSoldOutStyle
const selectorGreyedItemStyle = css`
  ${({ isSoldOut }) =>
    isSoldOut &&
    css`
      overflow: hidden;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to top left,
          transparent calc(50% - 1.5px),
          #eee calc(50% - 1.5px),
          #eee calc(50% + 1.5px),
          transparent calc(50% + 1.5px),
          transparent
        );
      }
      ${({ itemSoldOutStyle }) => itemSoldOutStyle};
    `}
`;

const SelectorButton = styled(Checkbox).attrs((props) => ({
  checkmarkCheckedStyle: props.checkmarkCheckedStyle || checkmarkCheckedStyle,
  checkmarkCheckingStyle:
    props.checkmarkCheckingStyle || checkmarkCheckingStyle,
}))`
  ${getInputStyle};
  ${selectorGreyedItemStyle};

  && {
    ${(props) => props.customInputStyle};
  }
`;

const SelectorItem = styled.li`
  display: block;
  ${getItemStyle}

  ${({ itemStyle }) => itemStyle};
`;

const checkmarkCheckedStyle = css`
  display: none;
`;

const checkmarkCheckingStyle = css`
  display: none;
`;

const gridItemStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4px;
`;

const swatchItemStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4px;
`;

const gridInputStyle = css`
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  border: none;
  background-color: #eeeeee;
  line-height: 1;
  box-sizing: border-box;

  ${({ isSoldOut }) =>
    isSoldOut &&
    css`
      && {
        border: 3px solid #eeeeee;
        background-color: white;
      }
    `};

  input:checked + && {
    border: solid 3px #333333;
    background-color: #eee;
  }

  ${({ isSoldOut }) =>
    isSoldOut &&
    css`
      input:checked + && {
        background-color: white;
      }
    `};
`;

const swatchInputStyle = css`
  height: 48px;
  width: 48px;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 50%;

  ${getBackgroundUrl};

  input:checked + && {
    ${getBackgroundUrl};
    border: 3px solid #333333;
    box-shadow: inset 0 0 0 3px #ffffff;
  }

  input:checked:focus + && {
    box-shadow: inset 0 0 0 3px #ffffff,
      0 0 0 3px ${({ theme }) => theme.colors.focusColor};
  }
`;

const GenericReviewsAttributeSelector = ({
  autoTag,
  hideLabel,
  inputStyle,
  isSoldOut,
  isChecked,
  itemLabelStyle,
  itemStyle,
  itemSoldOutStyle,
  name,
  onChange,
  selectorItemLabelComponent,
  selectorType = ProductSelectorType.GRID,
  swatchImageUrl,
  radioButtonId,
  radioButtonProps,
  value,
  ...rest
}) => {
  return (
    <SelectorItem
      itemStyle={itemStyle}
      data-autotag={autoTag}
      selectorType={selectorType}
      {...rest}
    >
      <SelectorButton
        id={radioButtonId}
        name={name}
        value={value}
        selectorType={selectorType}
        checked={isChecked}
        onChange={onChange}
        isSoldOut={isSoldOut}
        customInputStyle={inputStyle}
        backgroundUrl={swatchImageUrl}
        itemSoldOutStyle={itemSoldOutStyle}
        {...radioButtonProps}
      />
      <SelectorItemLabel
        htmlFor={radioButtonId}
        hideLabel={hideLabel}
        itemLabelStyle={itemLabelStyle}
        isChecked={isChecked}
        isSoldOut={isSoldOut}
      >
        {selectorItemLabelComponent}
      </SelectorItemLabel>
    </SelectorItem>
  );
};

GenericReviewsAttributeSelector.propTypes = {
  autoTag: PropTypes.string,
  /**
   * Styles to apply to the Category label.
   */
  categoryStyle: PropTypes.any,
  /**
   * Styles to apply to the checkmark element when the input is checked.
   */
  checkmarkCheckedStyle: PropTypes.any,
  /**
   * Styles to apply to the checkmark element when the input is disabled.
   */
  checkmarkDisabledStyle: PropTypes.any,
  /**
   * flag to display/hide sold out and currently selected items.
   */
  displayCheckedAndSoldOut: PropTypes.bool,
  /**
   * flag to display/hide sold out items.
   */
  displaySoldOutItem: PropTypes.bool,
  /**
   * flag to display/hide input label on screen.
   */
  hideLabel: PropTypes.bool,
  /**
   * Styles to always apply to the custom input element.
   * These are applied in CheckBoxRadioBase.
   */
  inputStyle: PropTypes.any,
  isChecked: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  /**
   * Styles to apply to the Input Label.
   */
  itemLabelStyle: PropTypes.any,
  /**
   * Styles to apply to sold out item.
   */
  itemSoldOutStyle: PropTypes.any,
  /**
   * Styles to apply to list item.
   */
  itemStyle: PropTypes.any,

  /**
   * Header label style.
   */
  labelStyle: PropTypes.any,
  /**
   * Options list style.
   */
  listStyle: PropTypes.any,
  /**
   * Input Onchange handler function.
   */
  name: PropTypes.string,
  onChange: PropTypes.func,
  radioButtonId: PropTypes.string,
  /**
   * Object to pass rest of the props to CheckboxRadioBase component.
   */
  radioButtonProps: PropTypes.object,
  /**
   * function to display any custom header/label .
   */
  renderSummary: PropTypes.func,
  /**
   * styles to display currently selected option in header/summary.
   */
  selectedItemLabelStyle: PropTypes.any,
  selectorItemLabelComponent: PropTypes.any,
  /**
   * Selector style indicating GRID/SWATCH.
   */
  selectorType: PropTypes.string,
  swatchImageUrl: PropTypes.string,
  value: PropTypes.string,
};

export default GenericReviewsAttributeSelector;

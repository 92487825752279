import React, { ReactElement, ReactNode } from 'react';

import styled, { CSSProp, css } from 'styled-components';

import ProgressLoadingIndicator from '../ProgressLoadingIndicator';
import useTabIndex from '../useTabIndex';
import withThemeProps from '../withThemeProps';

const ButtonElement = styled.button<{ $buttonStyle?: CSSProp }>`
  position: relative;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  ${({ $buttonStyle }) => $buttonStyle};
`;

const Label = styled.span<{
  $labelStyle?: CSSProp;
  $isLoadingLabelVisible?: boolean;
}>`
  [data-loading] > & {
    /* Use visibility instead of unmounting the label or using display: none so
     * that the button's width as determined by the label doesn't change. */
    ${({ $isLoadingLabelVisible }) =>
      $isLoadingLabelVisible
        ? css`
            visibility: visible;
            margin-left: 8px;
          `
        : css`
            visibility: hidden;
          `}
  }
  ${({ $labelStyle }) => $labelStyle}
`;

export const DefaultLoadingIndicator = styled(ProgressLoadingIndicator).attrs({
  'data-button-loading': '',
  fillStyle: { minWidth: 4, borderRadius: 2 },
  variant: 'button',
})`
  ${({ $isLoadingLabelVisible }) =>
    !$isLoadingLabelVisible &&
    css`
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      padding: 0 12px;
      transform: translate3d(0, -50%, 0);
    `}
`;

DefaultLoadingIndicator.displayName = 'Button.DefaultLoadingIndicator';

export type ButtonProps = React.ComponentPropsWithRef<typeof ButtonElement> & {
  /**
   * Styles to pass to the button element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  buttonStyle?: CSSProp;
  /**
   * The content of the button (usually a label and/or icon).
   */
  children?: ReactNode;
  /**
   * The link target, if the button should behave as a link. This will cause the
   * supplied `tagName` to be ignored in favor of rendeirng an `<a>` element,
   * and prevent the default application of the `button` role (unless explicitly
   * provided).
   */
  href?: string;
  /**
   * Whether the activity that the button controls is busy loading.
   */
  isLoading?: boolean;
  /**
   * Show Button label while loading/submitting.
   */
  isLoadingLabelVisible?: boolean;
  /**
   * Styles to pass to the button's inner `span` element that wraps the label.
   */
  labelStyle?: CSSProp;
  /**
   * A loading element to render when `isLoading` is true. For best results,
   * it should be absolutely positioned inside the button so that the button
   * size doesn't change when it switches to the loading state.
   */
  loadingIndicator?: ReactElement;
  /**
   * The [ARIA role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques)
   * to use. It defaults to `button` unless `href` is supplied. You can force it
   * to be `button` anyway (or anything else) by explicitly supplying it.
   */
  role?: string;
  /**
   * The value of the `tabindex` attribute. If the button has an active
   * `<Untabbable>` ancestor, it will automatically be set to -1.
   */
  tabIndex?: number;
  /**
   * The underlying element to render. Defaults to rendering a `button` element.
   * If `href` is supplied, this is ignored and an `<a>` element will be
   * rendered.
   */
  tagName?: any;
  /**
   * The HTML button type. The default is `button`, which has no default
   * behavior. You’ll need to specify `type="submit"` explicitly if you need a
   * form submission button.
   */
  type?: string;
  /**
   * The theme variant to render. Defaults to `primary`.
   */
  variant?: string;
};

/**
 * A basic button component with theme support. All additional props besides
 * those documented are passed along to the underlying DOM element.
 *
 * The component can also render links that will use the same styling as your
 * theme’s buttons if you pass the `href` prop.
 */
export const Button = React.forwardRef<unknown, ButtonProps>(
  (
    {
      buttonStyle,
      children,
      href,
      isLoading = false,
      isLoadingLabelVisible = false,
      labelStyle,
      loadingIndicator = <DefaultLoadingIndicator />,
      role,
      tabIndex,
      tagName,
      type,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    let buttonType: string | undefined = type || 'button';

    if (href) {
      tagName = 'a';
      buttonType = undefined;
    } else if (!role) {
      role = 'button';
    }

    tabIndex = useTabIndex(tabIndex);

    return (
      <ButtonElement
        as={tagName}
        $buttonStyle={buttonStyle}
        data-loading={isLoading ? '' : undefined}
        href={href}
        ref={ref}
        role={role}
        tabIndex={tabIndex}
        type={buttonType}
        $isLoadingLabelVisible={isLoadingLabelVisible}
        {...rest}
      >
        {isLoading ? loadingIndicator : null}
        <Label
          $labelStyle={labelStyle}
          $isLoadingLabelVisible={isLoadingLabelVisible}
        >
          {children}
        </Label>
      </ButtonElement>
    );
  }
);

Button.displayName = 'Button';

export default withThemeProps<ButtonProps>(Button, {
  section: 'button',
  defaultVariant: 'primary',
});

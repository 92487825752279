export const palette = {
  white: '#FFFFFF',
  black: '#000000',

  lavender: '#C5B4E3',
  lavender100: '#F5F2F9',
  lavender200: '#E1DAEC',
  lavender300: '#B8ABCE',
  lavender400: '#A394BD',
  lavender500: '#8E7FAB',
  lavender600: '#7A6B96',
  lavender700: '#65587F',
  lavender800: '#514668',
  lavender900: '#3E354F',

  stone: '#C8C9C7',
  stone70: '#F4F4F4',
  stone80: '#E5E5E5',
  stone90: '#C7C8C6',
  stone110: '#AEAEAE',
  stone115: '#8F8F8F',
  stone120: '#6E6E6E',

  gunmetal: '#333536',
  gunmetal5: '#EDEFF0',
  gunmetal10: '#E4E6E7',
  gunmetal15: '#D8DADB',
  gunmetal20: '#D0D2D3',
  gunmetal25: '#C4C6C7',
  gunmetal30: '#B7B9BA',
  gunmetal40: '#A9ABAC',
  gunmetal45: '#969899',
  gunmetal50: '#808080',
  gunmetal65: '#747677',
  gunmetal70: '#6B6D6E',
  gunmetal80: '#5B5D5E',
  gunmetal90: '#4E5051',
  gunmetal200: '#272E35',
  gunmetal300: '#1B1F24',

  marble: '#F5F5F5',

  red200: '#FFABAB',
  red500: '#C92C49',

  teal: '#5BE9DC',
  teal120: '#0B6060',

  green: '#0F8B8D',

  yellow: '#FFC043',

  blue: '#5897FB',

  purple: '#7F63B5',

  backgroundOverlay: 'rgba(250, 250, 250, 0.75)',

  // EqualWeb colors
  adaButtonMain: '#1C4BB6',

  // WARNING: colors below are not intended for SXF features but to not break components referencing them throughout the app. These should be deprecated in the future
  hydrowGreen: '#D1EF3D',
  hydrowGreen90: '#E4F495',

  outletRed: '#FE001A',

  deepBrown: '#332213',
  lightDeepBrown: '#5C4E42',
  yittyPurple: '#8031A7',

  modalOverlay: 'rgba(250, 250, 250, 0.75)',
};

export const colorTokens = {
  accountBoxContentBorder: palette.gunmetal15,
  accountBoxDivider: palette.gunmetal15,
  accountBoxWrapper: palette.gunmetal15,
  accountOrderDetailsBorder: palette.gunmetal15,
  accountOrderDetailsLineItem: palette.gunmetal90,
  mobileAccountPhoneNumberDisclaimerBorder: palette.gunmetal25,
  mobileAccountPhoneNumberDisclaimer: palette.gunmetal45,
  accountPhoneNumberDisclaimer: palette.gunmetal70,
  activateTrialSelectWrapper: palette.gunmetal25,
  arrowIcon: palette.black,
  backgroundTableRow: palette.gunmetal25,
  backgroundTableRowLight: palette.gunmetal10,
  backgroundCallout: palette.gunmetal65,
  backgroundCalloutAlternate: palette.gunmetal65,
  backgroundConfirmation: palette.white,
  backgroundDark: palette.gunmetal,
  backgroundAccounts: palette.gunmetal5,
  backgroundAccountsLight: palette.gunmetal5,
  backgroundVision: palette.gunmetal5,
  backgroundCheckout: palette.gunmetal5,
  backgroundDarkAlternate: palette.black,
  backgroundDefault: palette.black,
  backgroundDrawer: palette.gunmetal5,
  backgroundLight: palette.white,
  backgroundLightAlternate: palette.gunmetal25,
  backgroundDisabledLight: palette.gunmetal15,
  backgroundEmphasized: palette.black,
  backgroundEmphasizedLight: palette.gunmetal10,
  backgroundDeemphasizedLight: palette.gunmetal15,
  backgroundEmphasizedLightAlternate: palette.gunmetal15,
  backgroundOverlay: 'rgba(250, 250, 250, 0.75)',
  basketPricePromoClearance: palette.lavender700,
  billMeNowBorder: palette.gunmetal25,
  billMeNowConfAmount: palette.gunmetal25,
  bopsCancelBtn: palette.gunmetal,
  bopsChangeStoreBtn: palette.gunmetal,
  bopsHeaderBg: palette.gunmetal25,
  bopsListItemCounter: palette.gunmetal,
  borderDark: palette.black,
  border: palette.gunmetal25,
  borderLine: palette.gunmetal15,
  backgroundModalOverlay: 'rgba(0, 0, 0, 0.4)',
  borderLight: palette.gunmetal25,
  borderLightAlternate: palette.gunmetal45,
  borderShadow: palette.gunmetal20,
  builderAvailablePointsBorderInactive: palette.gunmetal25,
  builderAvailablePointsProgressCircle: palette.gunmetal25,
  builderBubbleImageWithLabelBorder: palette.gunmetal25,
  buttonPrimaryDisabledBg: palette.gunmetal20,
  buttonSecondaryDisabledBorder: palette.gunmetal65,
  buttonTertiaryDisabledFg: palette.gunmetal45,
  buttonTertiaryDisabledBg: palette.gunmetal20,
  buttonTertiaryBg: palette.gunmetal20,
  careGuideSectionBg: palette.gunmetal10,
  careGuideTitle: palette.black,
  ccPaymentFormWrapper: palette.gunmetal25,
  checkboxDisabled: palette.gunmetal10,
  compressionGuideSectionBg: palette.gunmetal10,
  compressionGuideOverlayButtonBg: palette.gunmetal10,
  compressionGuideOverlaySectionBg: palette.gunmetal10,
  compressionGuideOverlayCarouselBg: palette.gunmetal10,
  defaultRegFieldBorder: palette.gunmetal25,
  dropdownButtonBg: palette.gunmetal5,
  emphasizedNavItemLabel: palette.red500,
  empTooltipBg: palette.gunmetal25,
  enhancedNavTabBorder: palette.gunmetal,
  error: palette.red500,
  fabricCardBg: palette.gunmetal10,
  faqAccordionBg: palette.gunmetal25,
  findStoreBtn: palette.gunmetal,
  fitGuideDefault: palette.gunmetal80,
  focusColor: palette.gunmetal65,
  ftvModalBackground: palette.white,
  genderTabBorder: palette.gunmetal,
  genericSelectorInputSize: palette.gunmetal25,
  genericSelectorInputSizeSoldOut: palette.gunmetal25,
  genericSelectorItemSoldOutBg: palette.gunmetal25,
  gridSwatchInputCheckedFocus: palette.blue,
  gridSwatchesLoadingSkeletonBorder: palette.gunmetal15,
  gridSwatchesLoadingSkeletonBg15: palette.gunmetal15,
  gridSwatchesLoadingSkeletonBg25: palette.gunmetal25,
  gridPrimarySwatchRadioButtonBorder: palette.gunmetal90,
  headNavFlyoutBackground: palette.white,
  headNavFlyoutBorder: palette.gunmetal15,
  headNavTabActive: palette.black,
  headNavTabInactive: palette.gunmetal,
  headNavTextDefault: palette.black,
  headNavTabActiveBackgroundMobile: palette.gunmetal,
  headNavTabActiveForegroundMobile: palette.white,
  headNavTabActiveBorderMobile: palette.gunmetal,
  headNavTabInactiveBackgroundMobile: palette.white,
  headNavTabInactiveForegroundMobile: palette.gunmetal,
  headNavTabInactiveBorderMobile: palette.gunmetal15,
  headNavTabContentBackgroundMobile: palette.white,
  headNavBorder: palette.gunmetal25,
  iconColor: palette.gunmetal,
  iconColorAlternate: palette.gunmetal65,
  iconColorCartCount: palette.lavender,
  iconColorDeemphasized: palette.gunmetal15,
  iconColorRemoveFromCart: palette.gunmetal45,
  iconColorWishlist: palette.lavender,
  idMeVerifiedIcon: palette.gunmetal5,
  initiateReturnContainerBorder: palette.gunmetal25,
  learnMoreLink: palette.gunmetal,
  linkText: palette.gunmetal65,
  linkTextAlternate: palette.gunmetal65,
  linkTextDeemphasized: palette.gunmetal45,
  linkTextDeemphasizedAlternate: palette.gunmetal5,
  linkTextSpeedyTermsAndPrivacy: palette.lavender500,
  liveChatSurveyBorder: palette.gunmetal25,
  loyaltyActionCta: palette.lavender300,
  loyaltyActionCardBadge: palette.lavender300,
  loyaltyActionCardPoints: palette.lavender300,
  loyaltyActionCardBorder: palette.stone120,
  loyaltyHomepageActionCardBorder: palette.stone120,
  loyaltyLegacyBonusCardBorder: palette.stone120,
  loyaltyActivePrimaryButton: palette.lavender300,
  loyaltyLegacyActionCardBadge: palette.lavender300,
  loyaltyLegacyActionCardPoints: palette.lavender300,
  loyaltyRewardProgressPillBackground: palette.lavender300,
  loyaltyProgressTrackerPillBackground: palette.lavender300,
  loyaltyDisablePrimaryButton: palette.lavender100,
  loyaltyTierBadgeBg: '#1B1C19',
  loyaltyTierBadgeBorder: '#918E8C',
  loyaltyTierBadgeCompletedBorder: palette.gunmetal80,
  loyaltyVideoBannerSubtitle: palette.gunmetal80,
  loyaltyTooltipBg: palette.gunmetal25,
  loyaltyHomepageActionActionCardBorder: palette.stone,
  loyaltyHomepageActionActionCardCompletedBorder: palette.gunmetal80,
  loyaltyLegacyActionCardBg: palette.stone,
  loyaltyLegacyActionCardCompletedBg: palette.gunmetal80,
  loyaltyLegacyActionCardTitle: palette.gunmetal80,
  loyaltyRewardProgressTrackerBarBg: palette.stone,
  loyaltyRewardProgressTrackerBarCompletedBg: palette.gunmetal80,
  loyaltyActionVideoWrapperBackground: palette.gunmetal25,
  loyaltyProgressTrackerBarBg: palette.gunmetal25,
  loyaltyProgressTrackerBarCompletedBg: palette.gunmetal80,
  loyaltyProgressTrackerNotation: palette.gunmetal80,
  loyaltyActionCardBadgeBorder: palette.gunmetal80,
  loyaltyActionCardTitle: palette.gunmetal80,
  loyaltyActionCardSubtitle: palette.gunmetal80,
  loyaltyActionCardSelectedBackground: palette.gunmetal90,
  memberBoxBorder: palette.gunmetal25,
  memberCreditsM1Bg: palette.gunmetal10,
  memberInfoBackground: palette.stone70,
  memberInfoBorder: palette.stone90,
  memberInfoGreetingBorder: palette.gunmetal65,
  memberInfoLeadBackground: palette.stone70,
  memberInfoTrialDays: palette.gunmetal65,
  membershipBalanceWrapper: palette.gunmetal15,
  membershipBalanceAmountDivider: palette.gunmetal15,
  membershipBalanceTooltipBorder: palette.gunmetal15,
  membershipBalanceTooltipShadow: palette.gunmetal25,
  membershipContactChat: 'transparent',
  membershipCommunicationPreference: palette.gunmetal65,
  membershipContactChatText: palette.white,
  membershipContactPhone: palette.white,
  membershipContactPhoneText: palette.black,
  membershipContactTextActive: palette.black,
  membershipContactBoxBg: palette.gunmetal10,
  membershipOptInBorder: palette.gunmetal25,
  membershipOptInRadioButtonBlockSelectedBg: palette.stone,
  membershipTierElite: palette.lavender,
  membershipTierVIP: palette.gunmetal65,
  membershipFreeTrial: palette.lavender,
  metaNavBorder: '#EAEAEA',
  metaNavText: palette.gunmetal,
  metaNavListItemBg: palette.gunmetal,
  metaNavTextDeemphasized: palette.gunmetal45,
  metaNavResources: '#858583',
  mobileMetaNavListItemBorder: palette.stone90,
  metaNavListItemBorder: palette.gunmetal15,
  mobileMetaNavListItemBg: palette.gunmetal90,
  miniCartBorder: palette.gunmetal15,
  miniCartBundle: palette.gunmetal25,
  miniCartFocus: palette.gunmetal5,
  modialRelaySelectorClosestPointBorder: palette.gunmetal25,
  modialRelaySelectorClosestPointBg: palette.gunmetal25,
  modialRelayClosestPointBg: palette.stone,
  navMemberPanelStatusIsVip: palette.lavender300,
  navMemberPanelStatusNotVip: palette.gunmetal,
  offerButtonBg: palette.gunmetal,
  outfitDetailsLabel: palette.black,
  paymentOptionBorder: palette.gunmetal25,
  pdpBopsStoreFinderBg: palette.gunmetal25,
  pdpCalloutBgBrand: palette.purple,
  pdpCalloutYittyCollection: palette.yittyPurple,
  pdpCalloutBg: palette.black,
  pdpCalloutText: palette.white,
  pdpColorInputCheckedFocus: palette.blue,
  pdpColorSelectorInputBorder: palette.gunmetal25,
  pdpExtendedSizeCalloutBg: palette.gunmetal5,
  pdpOutfitSectionBg: palette.gunmetal5,
  pdpQuantitySelectorBorder: palette.gunmetal25,
  pdpShippingInfo: palette.gunmetal,
  philanthropyBorder: palette.gunmetal25,
  platinumExclusiveBackground: palette.gunmetal,
  postRegTime: palette.gunmetal25,
  postRegTimeWrapperBorder: palette.gunmetal25,
  preOrderCalloutBackground: palette.gunmetal,
  preorderRadioBorder: palette.gunmetal25,
  preorderRadioButtonBlockSelectedBg: palette.stone,
  promoText: palette.lavender,
  questionMarkToolTipShadow: palette.gunmetal25,
  radioButtonDisabled: palette.gunmetal10,
  returnInStoreBox: palette.gunmetal5,
  reviewBrowserSubmit: palette.gunmetal25,
  reviewsColorWrapper: palette.gunmetal5,
  reviewsDrawerFilterCount: palette.gunmetal25,
  saleText: palette.red500,
  memberOfferText: palette.purple,
  scrubsGroupOrdersLabel: palette.gunmetal25,
  scrubsGroupOrdersRadio: palette.gunmetal25,
  searchFieldWrapperBorder: palette.gunmetal15,
  searchPaneFieldContentColor: palette.gunmetal90,
  selectedFilterColor: palette.red500,
  selectBorder: palette.gunmetal25,
  quizAnswerBackgroundLight: palette.gunmetal15,
  quizAnswerBackgroundDark: palette.gunmetal15,
  quizAnswerBackgroundHover: palette.gunmetal15,
  quizAnswerColorHover: palette.black,
  quizButtonBackground: palette.black,
  quizDropdownBorder: palette.gunmetal20,
  quizDropdownOptionBackground: palette.gunmetal10,
  quizEdit: palette.black,
  quizModalBackground: palette.white,
  quizSizingBorder: palette.gunmetal20,
  sizeSelectionSizingBorder: palette.gunmetal20,
  quizTextDark: palette.black,
  sizeSelectionDark: palette.black,
  quizTextLight: palette.gunmetal65,
  scarcityCallout: palette.lavender,
  separator: palette.gunmetal15,
  success: palette.green,
  searchInputClearBtn: palette.gunmetal10,
  searchInputField: palette.gunmetal10,
  sectionNodeFontColor: palette.gunmetal,
  shopNavPanelBorder: palette.gunmetal15,
  shopNavLinkBorder: palette.gunmetal15,
  skipToolTipBorder: palette.gunmetal15,
  skipToolTipShadow: palette.gunmetal25,
  mobileSkinnyBannerTermsButtonText: palette.white,
  sortDropdownBtnListBorder: palette.gunmetal15,
  sortDropdownListBorder: palette.gunmetal15,
  sortDropdownListItemBgHover: palette.stone80,
  speedySignupFormDropdownBg: palette.gunmetal10,
  stickyBannerBackground: palette.white,
  stickyCountdownTrackBar: palette.teal,
  stillHaveQuestionsFormWrapperBg: palette.gunmetal5,
  stillHaveQuestionsWrapperDisabledBg: palette.stone,
  storeLocationButtonWrapper: palette.gunmetal5,
  storeLocationDetailsWrapper: palette.gunmetal5,
  storeLocationRetailStoreHours: palette.gunmetal5,
  tabBorder: palette.black,
  tabBackground: palette.gunmetal25,
  tabSelected: palette.black,
  tabSelectedText: palette.white,
  tabDeselected: palette.white,
  tabDeselectedText: palette.black,
  taxShippingMessage: palette.gunmetal,
  textActionDeemphasized: palette.gunmetal,
  textConfirmation: palette.gunmetal65,
  textDeemphasized: palette.gunmetal45,
  textDeemphasizedAlternate: palette.gunmetal65,
  textDefault: palette.black,
  textFaded: palette.black,
  textEmphasized: palette.gunmetal,
  textEmphasizedAlternate: palette.gunmetal,
  textFadedAlternate: palette.gunmetal65,
  textHighlighted: palette.black,
  textHighlightedAlternate: palette.gunmetal,
  textLight: palette.white,
  textLightAlternate: palette.gunmetal65,
  textValue: palette.gunmetal65,
  thredUpBackground: '#eff0f2',
  toggleContainerBg: palette.gunmetal25,
  toggleEnabledBackground: palette.gunmetal15,
  toggleEnabledForeground: palette.gunmetal65,
  tooltipBorder: palette.gunmetal,
  topBarWrapperBorder: palette.gunmetal15,
  trialMembershipBoxBorder: palette.gunmetal25,
  referrerOptionDropdownFocus: palette.gunmetal20,
  ugcNodeWrapperBg: palette.gunmetal5,
  unsupportedMessageBg: palette.gunmetal,
  verifiedIconBg: palette.gunmetal,
  vipBadge: palette.gunmetal65,
  vipMembershipProgramWrapper: palette.teal120,
  warning: palette.yellow,
  styledHtmlAssetAnchor: palette.gunmetal50,
  inactivePaginator: palette.gunmetal50,
  accountPageMenuMobile: palette.gunmetal50,
  selectorText: palette.gunmetal50,
  returnConfirmationShipmentNote: palette.gunmetal50,
  initiateReturnOutfitLabel: palette.gunmetal50,
  leadMembershipAmount: palette.gunmetal50,
  idMeWhatIsAnchor: palette.gunmetal50,
  serviceDiscountTermsButton: palette.gunmetal50,
  navigationMoveLeftIcon: palette.gunmetal50,
  reviewsCount: palette.gunmetal50,
  productDetailCtaModalInfo: palette.gunmetal50,
  empAddToBagText: palette.gunmetal50,
  preorderCtaShippingInfo: palette.gunmetal50,
  productDetailProgressBar: palette.gunmetal50,
  progressBar: palette.gunmetal50,
  drawerReviewsFilters: palette.gunmetal50,
  drawerStyleResultCount: palette.gunmetal50,
  speedySignupReferrerDropdownLabel: palette.gunmetal50,
  empUpsellPrice: palette.gunmetal50,
  waitlistModalMessage: palette.gunmetal50,
  passwordFieldButton: palette.gunmetal50,
  upsellCarouselMessage: palette.gunmetal50,
  dropdownButton: palette.gunmetal50,
  dropdownListItem: palette.gunmetal50,
  compressionOverlayStickyHeader: palette.gunmetal50,
  compressionOverlayStickySubheader: palette.gunmetal50,
  fabricGuideHeader: palette.gunmetal50,
  womenFitGuideDisclosureText: palette.gunmetal50,
  checkboxInputBorder: palette.gunmetal50,
  radioButtonInputBorder: palette.gunmetal50,
  reviewsContentWrapper: palette.gunmetal15,
  offerSnoozeAcceptance: palette.gunmetal15,
  orderConfirmationCarousel: palette.gunmetal15,
  productListingFilter: palette.gunmetal15,
  outfitItemWrapper: palette.gunmetal15,
  outfitItemActions: palette.gunmetal15,
  outfitItemImage: palette.gunmetal15,
  productDetailSeparator: palette.gunmetal15,
  productDetailsOutfitMessage: palette.gunmetal15,
  productDetailsDrawerButtonBorder: palette.gunmetal15,
  mobileQuickViewHeader: palette.gunmetal15,
  mobileTopShadow: palette.gunmetal15,
  quickViewOutfitBorder: palette.gunmetal15,
  upsellCarouselBorder: palette.gunmetal15,
  wearItWithItemBorder: palette.gunmetal15,
  storeLocationDetailsSeparator: palette.gunmetal15,
  sizeGridTabBorder: palette.gunmetal15,
  womenFabricChartSeparator: palette.gunmetal15,
  careGuideSectionBorder: palette.gunmetal15,
  mobileHowItWorksMemberCredits: palette.gunmetal15,
  recommendedReviewBorder: palette.gunmetal15,
  recentReviewsBorder: palette.gunmetal15,
  recentReviewsWrapperBoxShadow: palette.gunmetal5,
  accountOrderDetails: palette.gunmetal15,
  accountOrderDetailsContentBorder: palette.gunmetal15,
  accountOrderDetailsShippingInfoBorder: palette.gunmetal15,
  addressConfirmationRadioButtons: palette.gunmetal15,
  addressSubFormLabelBorder: palette.gunmetal15,
  addressListBorder: palette.gunmetal15,
  basketItemBorder: palette.gunmetal15,
  cardSubformLabel: palette.gunmetal15,
  cardFormGridFieldsetBorder: palette.gunmetal15,
  ccpaConfirmBackground: palette.gunmetal15,
  ccpaConfirmationNumberBorder: palette.gunmetal15,
  initiateReturnConfirmationCardBorder: palette.gunmetal15,
  productSizeSelectorInputBorder: palette.gunmetal15,
  productSizeSelectorSoldOutBackground: palette.gunmetal15,
  initiateReturnRmaCardBorder: palette.gunmetal15,
  initiateReturnRmaCardSpacer: palette.gunmetal15,
  initiateReturnInstructionsPanelBorder: palette.gunmetal15,
  initiateReturnConfirmationBorder: palette.gunmetal15,
  initiateReturnConfirmationItemCardTopBorder: palette.gunmetal15,
  initiateReturnHappyReturnCardBorder: palette.gunmetal15,
  initiateReturnHappyReturnCardBorderSelected: palette.black,
  initiateReturnPrintLabelCardBorder: palette.gunmetal15,
  initiateReturnSelectBorder: palette.gunmetal15,
  initiateReturnCancelButton: palette.gunmetal15,
  initiateReturnBackground: palette.gunmetal15,
  leadMembershipInfoBorder: palette.gunmetal15,
  membershipCreditsTableBorder: palette.gunmetal15,
  membershipCreditsRowBorder: palette.gunmetal15,
  vipPerksCirle: palette.lavender300,
  myVipPerksBorder: palette.gunmetal15,
  myVipListBackground: palette.gunmetal15,
  myVipContactBorder: palette.gunmetal15,
  myVipContactQuestionsBorder: palette.gunmetal15,
  myVipContactVariantBorder: palette.gunmetal15,
  myVipContactVariantQuestionsBorder: palette.gunmetal15,
  carouselProgressIndicatorBackground: palette.gunmetal15,
  dropdownListBorder: palette.gunmetal15,
  dropdownButtonBorder: palette.gunmetal15,
  dropdownListItemBorder: palette.gunmetal15,
  compressionGuideBreakBorder: palette.gunmetal15,
  referrerOptionDropdownButtonBackground: palette.gunmetal15,
  speedySignupDropdownListBorder: palette.gunmetal15,
  closeButtonBorder: palette.gunmetal15,
  bottomNavPanelBorder: palette.gunmetal15,
  searchFieldBorder: palette.gunmetal15,
  flytHeadStickyBorder: palette.gunmetal15,
  headNavMiniCartArrowBorder: palette.gunmetal15,
  headNavAccountMenuBorder: palette.gunmetal15,
  orderSummaryTotalsContent: palette.gunmetal15,
  orderSummaryTotalsBorder: palette.gunmetal15,
  orderSummaryBorder: palette.gunmetal15,
  orderSummaryInfoLabelBorder: palette.gunmetal90,
  orderSummaryDetailItemLabel: palette.gunmetal90,
  paymentMethodListItem: palette.gunmetal15,
  pointsHistoryTableBorder: palette.gunmetal15,
  pointsHistoryTableRowBorder: palette.gunmetal15,
  profileFormBorder: palette.gunmetal15,
  perksListBorder: palette.gunmetal15,
  sizePreferencesListItemBorder: palette.gunmetal15,
  swatchInputBorder: palette.gunmetal15,
  enhancedNavButtonGroupBorder: palette.gunmetal15,
  enhancedNavListBorder: palette.gunmetal15,

  howItWorksBackground: palette.gunmetal30,
  howItWorksMemberCredits: palette.gunmetal30,
  howItWorksBenefitsBackground: palette.gunmetal30,
  howItWorksFeaturedGridBackground: palette.gunmetal30,
  howItWorksFaqBackground: palette.gunmetal30,
  loginAssetBorder: palette.gunmetal30,
  storeLocationsLocatorBackground: palette.gunmetal30,
  noResultsHorizontalRuleBorder: palette.gunmetal30,
  basketItemImageBackground: palette.gunmetal30,
  basketItemProductImageBackground: palette.gunmetal30,
  billMeNowPaymentFormBackground: palette.gunmetal30,
  billMeNowChangePaymentBackground: palette.gunmetal30,
  billMeNowDialogBackground: palette.gunmetal30,
  returnConfirmationBackground: palette.gunmetal30,
  returnConfirmationBtnBackground: palette.gunmetal65,
  initiateReturnInStoreBorder: palette.gunmetal30,
  fitAppMindfulnessBackground: palette.gunmetal30,
  faqAccordionButton: palette.gunmetal30,
  marketingCarouselBackground: palette.gunmetal30,
  searchFieldBackground: palette.stone70,
  searchSuggestIsHighlightedBackground: palette.stone80,
  scrubsSizeMessageBorder: palette.gunmetal30,
  quizDropdownOptionBackgroundMobile: palette.gunmetal30,
  quizDropdownParentHoverColor: palette.black,
  embroideryProgressBarBackground: palette.gunmetal30,
  embroideryTextSelectionPreviewContainerBg: palette.gunmetal25,
  memberBoxBottomInfoBackground: palette.gunmetal30,
  haveQuestionsCancelBorder: palette.gunmetal30,
  reviewBrowserSubmitDisabled: palette.gunmetal30,
  skipTheMonthBorder: palette.gunmetal30,
  skipTheMonthProfileHeaderBg: palette.stone,
  skipTheMonthHeadingWrapperBg: palette.stone70,
  skipTheMonthKhloeProfileHeaderBg: palette.stone70,
  skipTheMonthKhloeCtaBg: palette.stone70,
  postregSelectorDivider: palette.gunmetal30,
  postregOfferBorder: palette.gunmetal30,
  checkoutHeaderBorder: palette.gunmetal30,
  favoritesPlaceholderImageBackground: palette.gunmetal30,
  styleGuideAssetWrapper: palette.gunmetal30,
  enhancedNavMenuScrollbarBackground: palette.gunmetal30,
  aboutLayoutCollectionsBackground: palette.gunmetal30,
  disabledDropdownButtonBackground: palette.gunmetal30,
  dropdownListItemBackground: palette.gunmetal30,
  inputCheckedColor: palette.gunmetal30,
  sortActiveSelection: palette.gunmetal90,
  searchSubmitButtonText: palette.gunmetal,
  returnInStoreViewMore: palette.gunmetal65,
  skipTheMonthConfSubHeader: palette.gunmetal65,
  activateTrialSelectBorder: palette.gunmetal65,
  footerSkinnyBannerWrapper: palette.gunmetal65,
  mobileSearchPanePlaceholder: palette.gunmetal65,
  pdpTooltipBorder: palette.gunmetal65,
  pdpTokenOnlyCallout: palette.gunmetal65,
  memberCreditCalloutToolipBorder: palette.gunmetal65,
  storeHoursInactive: palette.gunmetal65,
  fieldsetSelectBorder: palette.gunmetal65,
  fitAppPageBannerBg: palette.gunmetal65,
  storeLocationsDropdownArrow: palette.gunmetal65,
  storeLocationsDropdownLabel: palette.gunmetal65,

  orderConfirmationCarouselPromoPrice: palette.red500,
  loyaltyPrice: palette.red500,
  unavailableStockStatus: palette.red500,

  searchDivider: palette.gunmetal15,
  unfilledCompressionScaleBar: palette.gunmetal15,

  billMeNowSuccessOne: palette.gunmetal,
  loadingScreenBackground: palette.stone,
  errorPageBodyColor: palette.gunmetal,

  addPaymentIcon: palette.gunmetal45,
  placeholderImageBackground: palette.gunmetal45,
  soldOutSelectorItemSizeInput: palette.gunmetal45,
  soldOutSelectorItemSizeLabel: palette.gunmetal45,

  builderComparisonTableRowBackground: palette.gunmetal10,
  builderComparisonStyledDropdownBackground: palette.gunmetal10,
  storeLocationsMobileRegionLabel: palette.gunmetal10,

  headNavIcon: palette.gunmetal80,
  forgotPasswordControl: palette.gunmetal80,
  compressionGuideInfo: palette.gunmetal80,

  orderHistoryDetailsLink: palette.black,
  returnMessage: palette.black,
  ordersDataValue: palette.black,
  orderHistoryItemDetails: palette.black,
  onlineCancelFinalHeader: palette.black,
  onlineCancelFinalButton: palette.black,
  loyaltyLockIcon: palette.black,
  loyaltyActionCardIconColor: palette.gunmetal,
  loyaltyActionIconColor: palette.gunmetal,
  loyaltyActionProgressHeading: palette.gunmetal,
  loyaltyDeprecatedHomepageRewardsProgressHeading: palette.gunmetal,
  loyaltyDeprecatedRewardsProgressHeading: palette.gunmetal,
  loyaltyVideoBannerTitle: palette.gunmetal,
  loyaltyActionProgressCollapsibleIcon: palette.gunmetal50,
  rewardPromoCardTitle: palette.black,
  afterpayPlacement: palette.black,
  sizeChartBackgroundImage: palette.black,
  fitAppTextCopy: palette.black,
  sizeGridSizeTab: palette.black,
  defaultButtonOnHover: palette.black,

  backButton: palette.gunmetal50,
  liveChatCharactersRemaining: palette.gunmetal50,
  memberBoxBottomInfoClose: palette.gunmetal50,
  myVipContactCancelContactMethod: palette.gunmetal50,
  myVipContactAnytime: palette.gunmetal50,
  inputClearButton: palette.gunmetal50,
  inputShadow: palette.gunmetal50,
  speedySignupTermsWrapper: palette.gunmetal50,
  fitGuideLink: palette.gunmetal50,
  fitGuideExclamationIcon: palette.gunmetal50,
  fabricCardAssetPlaceholder: palette.gunmetal50,
  hydrowTestimonialHeading: palette.gunmetal50,
  hydrowTestimonialText: palette.gunmetal50,
  inputSizeSelectorSoldOut: palette.gunmetal50,
  swatchTagLabel: palette.gunmetal70,

  desktopProductListingFilter: palette.gunmetal15,
  swatchesMoreColorsLink: palette.gunmetal25,
  inactiveTextInput: palette.gunmetal45,
  flickityPageDots: palette.gunmetal45,
  quizDropdownLabel: palette.gunmetal45,
  quizDropdownOptionBg: palette.stone70,
  hdyhQuizDropdownNativeSelectBorder: palette.gunmetal25,
  birthdaySpan: palette.gunmetal45,
  zipCodeInputColor: palette.gunmetal45,
  registrationFieldsLabel: palette.gunmetal45,
  disabledSecondaryButton: palette.gunmetal45,
  textDisabled: palette.gunmetal50,
  priceOfferOr: palette.gunmetal70,

  alertRed: palette.lavender,
  unfocusedBackground: palette.gunmetal30,
  sublabelBlack: palette.gunmetal80,
  focusText: palette.gunmetal90,
  focusBackground: palette.gunmetal,
  focusBorder: palette.gunmetal20,
  unfocusedLabel: palette.gunmetal45,
  unfocusedSublabel: palette.gunmetal45,
  unfocusedAction: palette.gunmetal50,
  unfocusedBorder: palette.gunmetal25,
  demureBorder: palette.gunmetal15,
  mobileSkinnyBannerTermsButton: palette.gunmetal,
  membershipStatusBadge: palette.gunmetal,
  wearItWithTermsButton: palette.gunmetal,
  membershipBalancesTagValue: palette.gunmetal,
  productReviewCount: palette.gunmetal50,
  addressListButtonDisabled: palette.gunmetal50,
  promoPriceStrikethrough: palette.gunmetal50,
  activeBreadcrumb: palette.gunmetal,
  accountOrdersDataLabel: palette.gunmetal90,
  accountOrdersDataValue: palette.gunmetal90,
  doNotCancelVipLink: palette.black,
  dropdownMenuButton: palette.stone,
  dropdownMenuButtonBorder: palette.gunmetal25,
  canScrollGradient: palette.black,

  actionDisabled: palette.gunmetal65,
  actionEnabled: palette.black,
  fadedBackground: palette.stone,
  registrationPasswordField: palette.gunmetal80,
  passwordFieldFieldsetLabel: palette.gunmetal80,
  textFieldColor: palette.gunmetal80,
  textFieldFieldsetLabel: palette.gunmetal80,
  tableData: palette.gunmetal80,
  closeIcon: palette.gunmetal80,
  inactiveItem: palette.gunmetal45,
  navMemberSkip: palette.gunmetal45,
  closeButton: palette.gunmetal,
  loadingLineMessage: palette.gunmetal45,
  questionMarkTooltipAnchor: palette.gunmetal45,
  deselectFilterButton: palette.gunmetal45,
  builderSubtitle: palette.gunmetal45,
  shopNavBottomPanel: palette.gunmetal45,
  errorMessage: palette.red500,
  alertWarning: palette.yellow,
  returnRmaCartTitle: palette.lavender700,
  myVipContactQuestions: palette.stone,
  additionalText: palette.gunmetal70,
  faintBorder: palette.gunmetal15,
  faintBackground: palette.gunmetal15,

  filterPillUnselected: palette.stone,
  shoppableVideoProgressBackground: palette.gunmetal80,
  shoppableVideoDarkGray: palette.gunmetal65,
  shoppableVideoLightGray: palette.gunmetal45,
  shoppableVideoSilver: palette.gunmetal25,
  shoppableVideoFeaturedText: palette.gunmetal90,
  shoppableVideoFeaturedButton: palette.gunmetal,
  shoppableVideoLoaderBg: palette.gunmetal15,
  shoppableVideoCarouselTitle: palette.gunmetal,
  shoppableVideoTooltipBg: palette.lavender300,
  shoppableVideoSkeletonGradient20: '#9C9C9C33',
  shoppableVideoSkeletonGradient30: '#9C9C9C4D',
  shoppableVideoProductInfoOverlay: '#FFFFFFE6',
  shoppableVideoShadowShim0: '#00000000',
  shoppableVideoShadowShim16: '#0000002A',
  shoppableVideoShadowShim70: '#000000B3',
  shoppableVideoShadowShim90: '#000000E5',
  shoppableVideoProductCardOverlay: '#FFFFFF80',
  shoppableVideoCarouselDescription: palette.gunmetal,
  shoppableVideoCarouselFullWidthWrapperBg: palette.gunmetal10,
  shoppableVideoAuthorProfileImageBg: palette.gunmetal25,
  shoppableVideoIntroVideoTitle: palette.gunmetal90,
  shoppableVideoIntroVideoDescription: palette.gunmetal90,

  bouncebackEndowmentMiniCartPrompt: palette.lavender,
  bouncebackEndowmentHighlight: palette.lavender,

  promoPickerModalBackgroundTop: palette.stone115,
  promoPickerModalBackgroundBottom: palette.white,
  promoPickerGiftBoxColor: palette.lavender300,
  signupFormSmsDisclosure: palette.gunmetal65,
  promoPickerFormPanelsLink: palette.gunmetal70,

  outletSplashSubmitButtonFocus: palette.lavender700,
  outletSplashPrimaryColor: palette.lavender,
  outletDisabledSplashSubmitButtonBg: palette.stone120,

  errorPageBackgroundColor: palette.stone,

  tieredListDropdownParentOptionBg: palette.stone70,

  searchCioSkeletonGradient15: palette.gunmetal15,
  searchCioSkeletonGradient25: palette.gunmetal25,
  searchCioBarLayoutBorder: palette.gunmetal20,
  searchCioOverlayNoResultsFoundTitle: palette.gunmetal70,
  searchCioDesktopInputPlaceholder: palette.gunmetal90,

  hamburgerMenuHeaderBannerBorder: palette.gunmetal15,

  pinVerificationLoginSectionHoverBg: palette.gunmetal20,
  pinVerificationFormDigitInputBorder: palette.gunmetal25,
  pinVerificationFormResendCodeText: palette.gunmetal45,
  pinVerificationLoginSectionBorder: palette.gunmetal25,
  pinVerificationMethodIcon: palette.gunmetal45,

  countryDropdownMenuBorder: palette.stone115,

  sendLinkToPhoneNumberInputBorder: palette.gunmetal25,
  sendLinkToPhoneNumberInputPlaceholder: palette.gunmetal90,

  sustainabilityCardWrapperBorder: palette.gunmetal25,

  loginAuthForgotPasswordControlButton: palette.gunmetal40,

  klarnaCreditPromotion: palette.gunmetal50,
};

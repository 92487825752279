import React from 'react';

import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import CloseButton from '../CloseButton';
import RadioButtonGroup from '../RadioButtonGroup';
import { useReviewsDrawer } from '../ReviewsDrawerProvider';
import SortRadioItem from '../SortRadioItem';

const DefaultCloseIcon = styled(MdClose)`
  font-size: 16px;
  color: inherit;
  vertical-align: middle;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkenGray};
`;

const SectionTitleLabel = styled.div`
  flex: 1;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.4px;
  ${({ sectionTitleStyle }) => sectionTitleStyle};
`;

const ClearAllButton = styled(Button)`
  font-size: 16px;
  background: none;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  text-transform: none;
  font-weight: normal;
  text-decoration: underline;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  &:hover,
  &:focus {
    text-decoration: underline;
    background: none;
  }

  ${({ clearAllButtonStyle }) => clearAllButtonStyle};
`;

const SelectedItems = styled.span`
  ${({ sectionTitleSelectedItemsStyle }) => sectionTitleSelectedItemsStyle};
`;

const ApplyButton = styled(Button)`
  margin-top: 16px;
  ${({ applyButtonStyle }) => applyButtonStyle};
`;

const ReviewsFilters = ({
  sectionTitleStyle,
  applyButtonStyle,
  clearAllButtonStyle,
  closeIcon: CloseIcon,
  onApplyHandler,
  onCloseDrawerHandler,
  radioButtonProps,
  radioIcon,
  radioItemCheckedLabelStyle,
  radioItemLabelStyle,
  sectionTitleSelectedItemsStyle,
  shouldShowCloseButton,
  onClearAllHandler,
  onRadioItemChange,
  selectedSortValue,
  sizeFilterComponent,
  shouldDisableApplyButton,
}) => {
  const { sortOptions, rating } = useReviewsDrawer();

  return (
    <>
      <SectionTitleWrapper>
        <SectionTitleLabel sectionTitleStyle={sectionTitleStyle}>
          <FormattedMessage
            id="site_pdp.reviews_sort_by_header"
            defaultMessage="Sort by:"
          />{' '}
          <SelectedItems
            sectionTitleSelectedItemsStyle={sectionTitleSelectedItemsStyle}
          />
        </SectionTitleLabel>
        {shouldShowCloseButton ? (
          <CloseButton onClick={onCloseDrawerHandler}>
            {CloseIcon || <DefaultCloseIcon />}
          </CloseButton>
        ) : (
          <ClearAllButton
            clearAllButtonStyle={clearAllButtonStyle}
            onClick={onClearAllHandler}
            data-autotag="drawer_clear_all"
          >
            <FormattedMessage
              id="site_pdp.reviews_filter_clear_all"
              defaultMessage="Clear All"
            />
          </ClearAllButton>
        )}
      </SectionTitleWrapper>
      <RadioButtonGroup name="review_filter_sort" data-autotag="review_filter">
        {sortOptions.map((option, index) => (
          <SortRadioItem
            radioButtonProps={radioButtonProps}
            radioIcon={radioIcon}
            radioItemLabelStyle={radioItemLabelStyle}
            radioItemCheckedLabelStyle={radioItemCheckedLabelStyle}
            key={index}
            isDisabled={
              rating && ['HighestRated', 'LowestRated'].includes(option.value)
            }
            option={option}
            onChangeHandler={onRadioItemChange}
            isChecked={selectedSortValue === option.value}
          />
        ))}
      </RadioButtonGroup>

      {sizeFilterComponent}

      <ApplyButton
        disabled={shouldDisableApplyButton}
        applyButtonStyle={applyButtonStyle}
        onClick={onApplyHandler}
        data-autotag="review_filter_apply_btn"
      >
        <FormattedMessage
          id="site_pdp.reviews_filter_apply_cta"
          defaultMessage="Apply"
        />
      </ApplyButton>
    </>
  );
};

ReviewsFilters.propTypes = {
  /**
   * Styles to apply to the apply button in the side drawer
   */
  applyButtonStyle: PropTypes.any,

  /**
   * Action handler for the clear All in Sort and Filter drawer
   */
  clearAllButtonStyle: PropTypes.any,

  /**
   * Styles to apply to the apply button in the side drawer
   */
  closeIcon: PropTypes.node,

  /**
   * Action handler for the apply button in Sort and Filter drawer
   */
  onApplyHandler: PropTypes.func,

  /**
   * * Action handler for the Clear All button
   */
  onClearAllHandler: PropTypes.func,

  /**
   * * Action handler for the close button in Sort and Filter drawer
   */
  onCloseDrawerHandler: PropTypes.func,

  /**
   * Action handler when a sort Radio item is changed
   */
  onRadioItemChange: PropTypes.func,

  /**
   * * Props for the radio buttons to customize styles
   */
  radioButtonProps: PropTypes.object,

  /**
   * A custom component for css radio button
   */
  radioIcon: PropTypes.node,

  /**
   * Styles to be applied on radio item label of sorting options
   */
  radioItemCheckedLabelStyle: PropTypes.any,

  /**
   * Styles to be applied on radio item label of sorting options
   */
  radioItemLabelStyle: PropTypes.any,

  /**
   * Styles to be applied on selected options shown in the section header
   */
  sectionTitleSelectedItemsStyle: PropTypes.any,

  /**
   * Styles to be applied on the title of the Sort/Filter section
   */
  sectionTitleStyle: PropTypes.any,

  /**
   * The value of the selected sort radio button
   */
  selectedSortValue: PropTypes.string,
  /**
   * A boolean to disable the apply button conditionally
   */
  shouldDisableApplyButton: PropTypes.bool,

  /**
   * A boolean to show the close icon in the stacked Filters drawer
   */
  shouldShowCloseButton: PropTypes.bool,

  /**
   * A component to show the size filters
   */
  sizeFilterComponent: PropTypes.node,
};

export default ReviewsFilters;

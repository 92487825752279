import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { FaCaretDown } from 'react-icons/fa';
import {
  useMenuState,
  Menu,
  MenuGroup,
  MenuItem,
  MenuButton,
  MenuSeparator,
} from 'reakit/Menu';
import styled from 'styled-components';

import { useHydrationStatus } from '../../../../techstyle-shared/redux-core';
import FlagIcon from '../FlagIcon';
import HoverDelay from '../HoverDelay';

export const Wrapper = styled.div`
  display: inline-block;

  &[data-align-icon='left'] {
    text-align: left;
  }
  &[data-align-icon='right'] {
    text-align: right;
  }
`;

const MenuContent = styled.div`
  background: white;
`;

export const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const MenuListItem = styled.li`
  margin: 0;

  > [data-country-link] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25em 0.5em;
  }

  &:not(:first-child) > [data-country-link] {
    border-top: 0;
  }
`;

export const MenuItemIcon = styled(FlagIcon)`
  vertical-align: top;

  [data-align-icon='left'] & {
    order: -1;
  }
`;

export const CountryName = styled.span`
  flex-grow: 1;
  [data-align-icon='right'] & {
    margin-right: 0.5em;
  }
  [data-align-icon='left'] & {
    margin-left: 0.5em;
  }
`;

export const FlagIconDisclosure = styled.button`
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: inherit;

  :disabled {
    color: inherit;
  }
`;

const CaretWrapper = styled.span`
  display: inline-block;
  [data-align-icon='left'] & {
    margin-left: 0.25em;
  }
  [data-align-icon='right'] & {
    margin-right: 0.25em;
  }
`;

/**
 * A hook for debouncing menu activation. When a menu is activated via hover, a
 * common flaw is for the user to click before realizing the menu is already
 * being activated automatically from their cursor position. Thus the click
 * often comes in right after the menu automatically opens, toggling it back to
 * closed. The disables the disclosure button for the given `duration` after the
 * menu becomes visible.
 */
function useDebounce(isActive, duration = 400) {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isActive) {
      setDisabled(true);
      const timeout = setTimeout(() => {
        setDisabled(false);
      }, duration);
      return () => clearTimeout(timeout);
    } else {
      setDisabled(false);
    }
  }, [isActive, duration]);

  return disabled;
}

export default function CountryMenu({
  activeFirst,
  buttonAutoTag,
  alignIcon,
  className,
  countries,
  disclosureLabel,
  groupInactive,
  groupSeparator,
  hasCaret,
  iconSize,
  menuAutoTag,
  menuLabel,
  onChange,
  onToggle,
  openEvent,
  placement,
  value,
}) {
  const hydrationStatus = useHydrationStatus((status) => status);
  const previousPlacement = useRef(placement);
  const menuState = useMenuState({
    placement,
    unstable_fixed: true,
  });
  const disableDisclosure = useDebounce(menuState.visible);

  useEffect(() => {
    if (previousPlacement.current !== placement) {
      menuState.place(placement);
      previousPlacement.current = placement;
    }
  }, [placement, menuState]);

  const getItemGroups = () => {
    if (!activeFirst || !value) {
      return [countries];
    }

    const activeItem = countries.find(({ code }) => code === value);

    if (!activeItem) {
      return [countries];
    }

    const inactiveItems = countries.filter((country) => country !== activeItem);

    if (groupInactive) {
      return [[activeItem], inactiveItems];
    } else {
      return [[activeItem, ...inactiveItems]];
    }
  };

  const items = getItemGroups().map((group, i) => (
    <React.Fragment key={i}>
      {i > 0 ? groupSeparator : null}
      <MenuGroup as={MenuList} data-country-menu-list="">
        {group.map((country) => (
          <MenuListItem
            key={country.code}
            data-country-menu-list-item=""
            data-country-menu-active={country.code === value}
          >
            <MenuItem
              {...menuState}
              as="a"
              data-country-link={country.code}
              href={country.href}
              data-menu-item=""
              data-autotag={country.autoTag}
              disabled={country.disabled}
              focusable={country.focusable}
            >
              <CountryName>{country.label}</CountryName>
              <MenuItemIcon
                width={iconSize}
                country={country.code}
                data-country-menu-icon=""
              />
            </MenuItem>
          </MenuListItem>
        ))}
      </MenuGroup>
    </React.Fragment>
  ));

  const menu = (
    <Wrapper data-align-icon={alignIcon} className={className}>
      <MenuButton
        {...menuState}
        data-autotag={buttonAutoTag || null}
        data-country-menu-disclosure=""
        as={FlagIconDisclosure}
        aria-label={disclosureLabel}
        disabled={disableDisclosure}
        // This is necessary because Reakit's disclosure activation behaves
        // incorrectly when SSR'd. So remount it with a different key upon
        // hydration.
        key={`disclosure-${hydrationStatus}`}
      >
        {hasCaret ? (
          <CaretWrapper>
            <FaCaretDown />
          </CaretWrapper>
        ) : null}
        <MenuItemIcon
          width={iconSize}
          country={value}
          data-country-menu-icon=""
        />
      </MenuButton>
      <Menu
        {...menuState}
        aria-label={menuLabel}
        as={MenuContent}
        data-autotag={menuAutoTag || null}
        data-country-menu=""
      >
        {items}
      </Menu>
    </Wrapper>
  );

  return openEvent === 'hover' ? (
    <HoverDelay
      onEnter={menuState.visible ? undefined : menuState.show}
      onLeave={menuState.hide}
    >
      {menu}
    </HoverDelay>
  ) : (
    menu
  );
}
CountryMenu.propTypes = {
  /**
   * Enable to render the active country at the top of the list.
   */
  activeFirst: PropTypes.bool,
  /**
   * Sets the position of the flag relative to the label.
   */
  alignIcon: PropTypes.oneOf(['left', 'right']).isRequired,
  /**
   * Autotag value to add to the active country flag icon.
   */
  buttonAutoTag: PropTypes.string,
  /**
   * Class name(s) to apply to the container element.
   */
  className: PropTypes.string,
  /**
   * Array of country objects
   */
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      autoTag: PropTypes.string,
      code: PropTypes.string,
      disabled: PropTypes.bool,
      focusable: PropTypes.bool,
      href: PropTypes.string,
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.func,
      ]),
    })
  ),
  /**
   * The label to apply to the MenuDisclosure element.
   */
  disclosureLabel: PropTypes.string,
  /**
   * Whether to split the active and inactive items into two separate MenuGroup
   * elements. Only meaningful when `activeFirst` is true.
   */
  groupInactive: PropTypes.bool,
  /**
   * The content to render in between the active item and the inactive items.
   * Only meaningful when both `activeFirst` and `groupInactive` are true.
   */
  groupSeparator: PropTypes.node,
  /**
   * Enable to display a caret next to the flag icon (see JustFab)
   */
  hasCaret: PropTypes.bool,
  /**
   * Sets the width of the icon.
   */
  iconSize: PropTypes.string,
  /**
   * The autotag value to add to the flags menu.
   */
  menuAutoTag: PropTypes.string,
  /**
   * The `aria-label` to apply to the Menu element.
   */
  menuLabel: PropTypes.string,
  /**
   * (optional) onChange callback
   */
  onChange: PropTypes.func,
  /**
   * (optional) onToggle callback
   */
  onToggle: PropTypes.func,
  /**
   * Determines how the menu "open" state is triggered.
   */
  openEvent: PropTypes.oneOf(['hover', 'click']).isRequired,
  /**
   * The menu placement relative to the disclosure element.
   * See: https://reakit.io/docs/menu/
   */
  placement: PropTypes.string,
  /**
   * Sets the currently active country.
   */
  value: PropTypes.string,
};
CountryMenu.defaultProps = {
  activeFirst: true,
  alignIcon: 'left',
  countries: [],
  disclosureLabel: 'Select country',
  groupInactive: false,
  hasCaret: false,
  iconSize: '28px',
  menuLabel: 'Countries',
  openEvent: 'click',
  placement: 'auto-end',
};

CountryMenu.Wrapper = Wrapper;
CountryMenu.MenuList = MenuList;
CountryMenu.MenuListItem = MenuListItem;
CountryMenu.MenuItemIcon = MenuItemIcon;
CountryMenu.MenuSeparator = MenuSeparator;
CountryMenu.CountryName = CountryName;
CountryMenu.FlagIconDisclosure = FlagIconDisclosure;

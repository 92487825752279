// NOTE: see `react-packages/packages/react-assets/src/utils.js
// TODO: Move to export, however sharing this utility from react-assets may not make sense,
// since it violates hierarchy, because a generic Picture doesn't have anything to do with assets
export function parseAspectRatio(string) {
  const [width, height] = string.split(':');
  const numericAspectRatio = parseInt(width, 10) / parseInt(height, 10);
  if (!numericAspectRatio || numericAspectRatio < 0) {
    throw new Error(`Invalid aspect ratio: '${string}'`);
  }
  return numericAspectRatio;
}

// helper function for findImageInImageSets
// Requires at least a min/max value for height or width (both can be provided as well)
export function findImageByRange({
  sizedImages,
  heightMinRange,
  heightMaxRange,
  widthMinRange,
  widthMaxRange,
} = {}) {
  let imageInRange;
  // flag for whether height & width are provided
  const heightProvided =
    heightMinRange !== undefined && heightMaxRange !== undefined;
  const widthProvided =
    widthMinRange !== undefined && widthMaxRange !== undefined;

  // if imagesSet isn't provided or hasn't loaded yet, return null
  if (!sizedImages) {
    return null;
  }

  // if both height values are provided and not both width values are
  if (heightProvided && !widthProvided) {
    imageInRange = sizedImages.find(
      ({ height }) => heightMinRange < height && heightMaxRange > height
    );
  }
  // if both width values are provided and not both height values are
  else if (!heightProvided && widthProvided) {
    imageInRange = sizedImages.find(
      ({ width }) => widthMinRange < width && widthMaxRange > width
    );
  }
  // if both height values & width values are provided
  else {
    imageInRange = sizedImages.find(
      ({ height, width }) =>
        heightMinRange < height &&
        heightMaxRange > height &&
        widthMinRange < width &&
        widthMaxRange > width
    );
  }

  return imageInRange;
}

// Retrieve an image within a given size range from a product imageSet.
// If no image is found in the given range, returns the second-largest.
export function findImageInImageSets({
  imageSets,
  imageType = 'default',
  imageAngle,
  heightMinRange,
  heightMaxRange,
  widthMinRange,
  widthMaxRange,
} = {}) {
  // target imageSet by type, i.e., default, memberModels, plus
  let imageSetImages;

  // if imagesSets aren't provided or haven't loaded yet, return null
  if (!imageSets) {
    return null;
  }

  // determine what imageSet to target in imageSets by type, i.e., memberModels, plus, or default
  if (imageType === 'memberModels') {
    imageSetImages = imageSets[imageType]?.[0]?.images;
  } else {
    imageSetImages = imageSets[imageType]?.images;
  }

  if (imageSetImages) {
    let sizedImages;

    if (imageAngle) {
      // target imageSet by angle, i.e., front-1, back-1, etc.
      const imageSetByAngle = imageSetImages.find(
        (image) => image.angle === imageAngle
      );
      sizedImages = imageSetByAngle?.sizes;
    } else {
      sizedImages = imageSetImages[0]?.sizes;
    }

    if (sizedImages) {
      // image found that matches inputted height/width range
      const imageInSizeRange = findImageByRange({
        sizedImages,
        heightMinRange,
        heightMaxRange,
        widthMinRange,
        widthMaxRange,
      });

      // if imageInSizeRange is instantiated, matching image has been found
      if (imageInSizeRange) {
        return imageInSizeRange;
      }
      // otherwise, sort relevant imageSet and select 2nd to largest image
      else {
        const sortedImages = sizedImages
          .slice()
          .sort((a, b) => a.height - b.height);
        // before returning 2nd largest image, ensure there are at least two images in sorted images => if not, just return single image
        return sortedImages.length >= 2
          ? sortedImages[sortedImages.length - 2]
          : sortedImages[sortedImages.length - 1];
      }
    }
  }
}

import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  loadPromos,
  addMemberPromo,
  cancelMemberPromo,
  checkMembershipPromos,
  redeemLoyaltyPromo,
} from './promosModule';

export default function usePromoActions() {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          loadPromos,
          addMemberPromo,
          cancelMemberPromo,
          checkMembershipPromos,
          redeemLoyaltyPromo,
        },
        dispatch
      ),
    [dispatch]
  );

  return actions;
}

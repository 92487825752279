import React, { useState, useRef, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';

import { ChildType } from '../batch';
import useVirtualScroll from '../useVirtualScroll';

const VirtualScrollBatch = React.memo(function VirtualScrollBatch({
  as: ElementType = 'div',
  childrenArray,
  childType,
  getItems,
  hideDelay = 750,
  initialVisible = false,
  loadItems,
  renderItems,
  startIndex,
  stopIndex,
  slots,
  visibleDelay = 150,
}) {
  const { observe, unobserve } = useVirtualScroll();
  const lastKnownHeight = useRef(null);
  const [isVisible, setVisible] = useState(initialVisible);
  const ref = useRef();
  useEffect(() => {
    let disconnecting = false;
    let timeout;

    const handleChange = (isIntersecting, entry) => {
      if (disconnecting) {
        return;
      }
      if (timeout != null) {
        clearTimeout(timeout);
      }
      if (isIntersecting) {
        timeout = setTimeout(() => {
          setVisible(true);
        }, visibleDelay);
      } else {
        lastKnownHeight.current = entry.boundingClientRect.height;
        // Hide batches on a delay, in case they come back into view right away.
        timeout = setTimeout(() => {
          setVisible(false);
        }, hideDelay);
      }
    };

    const observed = ref.current;
    observe(observed, handleChange);

    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
      disconnecting = true;
      unobserve(observed);
    };
  }, [visibleDelay, hideDelay, observe, unobserve]);

  const getItemsCached = useMemo(() => {
    let wasCalled = false;
    let items;
    return () => {
      if (wasCalled) {
        return items;
      }
      wasCalled = true;
      items = getItems({ startIndex, stopIndex, slots });
      return items;
    };
  }, [getItems, startIndex, stopIndex, slots]);

  useEffect(() => {
    if (isVisible) {
      if (childType === ChildType.ITEM) {
        if (!getItemsCached()) {
          loadItems({ startIndex, stopIndex });
        }
      }
    }
  }, [
    childType,
    getItemsCached,
    isVisible,
    loadItems,
    renderItems,
    startIndex,
    stopIndex,
  ]);

  return (
    <ElementType ref={ref}>
      {childType === ChildType.BATCH
        ? childrenArray.map((batch, i) => {
            const key = `batch-${batch.itemStartIndex}-${batch.itemStopIndex}`;
            return (
              <VirtualScrollBatch
                as={ElementType}
                childType={batch.childType}
                childrenArray={batch.children}
                getItems={getItems}
                initialVisible={initialVisible && i === 0}
                key={key}
                loadItems={loadItems}
                renderItems={renderItems}
                slots={batch.slots}
                startIndex={batch.itemStartIndex}
                stopIndex={batch.itemStopIndex}
              />
            );
          })
        : renderItems({
            items: getItemsCached(),
            startIndex,
            stopIndex,
            isVisible,
            lastKnownHeight: lastKnownHeight.current,
          })}
    </ElementType>
  );
});

VirtualScrollBatch.propTypes = {
  as: PropTypes.elementType,
  childType: PropTypes.string,
  childrenArray: PropTypes.array,
  getItems: PropTypes.func,
  hideDelay: PropTypes.number,
  initialVisible: PropTypes.bool,
  loadItems: PropTypes.func,
  renderItems: PropTypes.func,
  slots: PropTypes.arrayOf(PropTypes.object),
  startIndex: PropTypes.number,
  stopIndex: PropTypes.number,
  visibleDelay: PropTypes.number,
};

export default VirtualScrollBatch;

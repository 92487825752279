import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormattedMessage } from '../../../../techstyle-shared/react-intl';
import { mobile } from '../styles';

const RUNS_SMALL_MAX = 0.25;
const RUNS_LARGE_MIN = 0.75;

const OverallFitTitle = styled.div`
  margin: 32px 0px 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.lightBlack};

  ${mobile`
    text-align: left;
  `};

  ${({ overallFitStyle }) => overallFitStyle};
`;

const Base = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 0.5em;
  border-radius: 0.25em;
  position: relative;
  margin: 0.625em 0;
  ${({ sliderRailStyle }) => sliderRailStyle};
`;

const Separator = styled.span`
  width: 1px;
  height: 0.5em;
  left: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
`;

const Pivot = styled.span`
  width: 0.25em;
  height: 1em;
  position: absolute;
  left: calc(100% * ${({ $pivotPosition }) => $pivotPosition});
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 0.125em;
  top: -50%;
  ${({ sliderPivotStyle }) => sliderPivotStyle};
`;

const FitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FitType = styled.span`
  font-size: 0.875em;
  position: relative;
  color: ${({ theme, shouldHighlight }) =>
    shouldHighlight ? theme.colors.lightBlack : theme.colors.textDisabled};
  font-weight: ${({ shouldHighlight }) => (shouldHighlight ? '500' : 'normal')};
  ${({ fitTypeStyle }) => fitTypeStyle};
`;

const OverallFitSlider = ({
  sliderPosition = 3,
  minPosition = 1,
  maxPosition = 5,
  overallFitStyle,
  sliderPivotStyle,
  sliderRailStyle,
  fitTypeStyle,
  ...rest
}) => {
  // Value from 0 to 1 -- `0` being the `sliderPosition` is at the `minPosition`.
  const pivotPosition =
    (minPosition - sliderPosition) / (minPosition - maxPosition);

  return (
    <div {...rest}>
      <OverallFitTitle overallFitStyle={overallFitStyle}>
        <FormattedMessage
          id="site_product_page.overall_fit"
          defaultMessage="Overall Fit"
        />
      </OverallFitTitle>
      <FitWrapper>
        <FitType
          shouldHighlight={pivotPosition < RUNS_SMALL_MAX}
          fitTypeStyle={fitTypeStyle}
        >
          <FormattedMessage
            id="site_product_page.fit_runs_small"
            defaultMessage="Runs Small"
          />
        </FitType>
        <FitType
          shouldHighlight={
            pivotPosition >= RUNS_SMALL_MAX && pivotPosition <= RUNS_LARGE_MIN
          }
          fitTypeStyle={fitTypeStyle}
        >
          <FormattedMessage
            id="site_product_page.fit_true_to_size"
            defaultMessage="True to Size"
          />
        </FitType>
        <FitType
          shouldHighlight={pivotPosition > RUNS_LARGE_MIN}
          fitTypeStyle={fitTypeStyle}
        >
          <FormattedMessage
            id="site_product_page.fit_runs_large"
            defaultMessage="Runs Large"
          />
        </FitType>
      </FitWrapper>

      <Base sliderRailStyle={sliderRailStyle}>
        <Pivot
          sliderPivotStyle={sliderPivotStyle}
          $pivotPosition={pivotPosition}
        />
        <Separator />
      </Base>
    </div>
  );
};

OverallFitSlider.propTypes = {
  /**
   * Styles for Fit Type wrapper
   */
  fitTypeStyle: PropTypes.any,
  /**
   * The max value of the slider (defaults to 1)
   */
  maxPosition: PropTypes.number,
  /**
   * The min value of the slider (defaults to 5)
   */
  minPosition: PropTypes.number,
  /**
   * Styles for Overall Fit heading text
   */
  overallFitStyle: PropTypes.any,
  /**
   * Styles fit scale indicator
   */
  sliderPivotStyle: PropTypes.any,
  /**
   * The position of the slider from `minPosition` to `maxPosition`. `minPosition` being 'runs small' and `maxPosition` being 'runs large'.
   */
  sliderPosition: PropTypes.number,
  /**
   * Styles fit scale bar
   */
  sliderRailStyle: PropTypes.any,
};

export default OverallFitSlider;

import React, { useCallback, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AdminPanel } from '../../../../techstyle-shared/react-admin';
import { setGeoCountry, setGeoState } from '../intlModule';

export default function AdminGeoDetection() {
  const geoCountryRef = useRef();
  const geoStateRef = useRef();
  const dispatch = useDispatch();
  const geoCountry = useSelector((state) => state.intl.geoCountry);
  const geoState = useSelector((state) => state.intl.geoState);
  const forceGeoCountry = useSelector((state) => state.intl.forceGeoCountry);
  const forceGeoState = useSelector((state) => state.intl.forceGeoState);

  const handleRestoreCountry = useCallback(() => {
    dispatch(setGeoCountry({ forceGeoCountry: null }));
  }, [dispatch]);

  const handleRestoreState = useCallback(() => {
    dispatch(setGeoState({ forceGeoState: null }));
  }, [dispatch]);

  const handleForceCountry = useCallback(
    (event) => {
      dispatch(setGeoCountry({ forceGeoCountry: geoCountryRef.current.value }));
    },
    [dispatch]
  );

  const handleForceState = useCallback(
    (event) => {
      dispatch(setGeoState({ forceGeoState: geoStateRef.current.value }));
    },
    [dispatch]
  );

  const forceOptions = useMemo(() => {
    const options = ['CA', 'US'];
    // If the current `forceGeoCountry` is neither of the above, add it.
    if (forceGeoCountry && !options.includes(forceGeoCountry)) {
      options.push(forceGeoCountry);
    }
    return options;
  }, [forceGeoCountry]);

  return (
    <>
      <AdminPanel.Field label="Visitor country:">
        <AdminPanel.Fields columnGap={6} rowGap={4} style={{ padding: 0 }}>
          <AdminPanel.Field
            type="checkbox"
            label={
              <label htmlFor="adminGeoCountryAuto">
                {geoCountry || 'Unknown'} (auto-detected based on IP)
              </label>
            }
          >
            <input
              type="radio"
              name="adminGeoCountry"
              value="autoGeoCountry"
              id="adminGeoCountryAuto"
              checked={forceGeoCountry == null}
              onChange={handleRestoreCountry}
            />
          </AdminPanel.Field>
          <AdminPanel.Field
            type="checkbox"
            label={
              <div>
                <label htmlFor="adminGeoCountryForce">Force country to</label>{' '}
                <select
                  ref={geoCountryRef}
                  value={forceGeoCountry || forceOptions[0]}
                  onChange={handleForceCountry}
                  disabled={forceGeoCountry == null}
                >
                  {forceOptions.map((countryCode) => (
                    <option key={countryCode} value={countryCode}>
                      {countryCode}
                    </option>
                  ))}
                </select>
              </div>
            }
          >
            <input
              type="radio"
              name="adminGeoCountry"
              id="adminGeoCountryForce"
              value="forceGeoCountry"
              checked={forceGeoCountry != null}
              onChange={handleForceCountry}
            />
          </AdminPanel.Field>
        </AdminPanel.Fields>
      </AdminPanel.Field>
      <AdminPanel.Field label="Visitor state/province:">
        <AdminPanel.Fields columnGap={6} rowGap={4} style={{ padding: 0 }}>
          <AdminPanel.Field
            type="checkbox"
            label={
              <label htmlFor="adminGeoStateAuto">
                {geoState || 'Unknown'} (auto-detected based on IP)
              </label>
            }
          >
            <input
              type="radio"
              name="adminGeoState"
              value="autoGeoState"
              id="adminGeoStateAuto"
              checked={forceGeoState == null}
              onChange={handleRestoreState}
            />
          </AdminPanel.Field>
          <AdminPanel.Field
            type="checkbox"
            label={
              <div>
                <label htmlFor="adminGeoStateForce">Force state/province</label>{' '}
                <label>
                  to{' '}
                  <input
                    ref={geoStateRef}
                    name="forceGeoState"
                    type="text"
                    size={5}
                    defaultValue={forceGeoState || ''}
                    onChange={handleForceState}
                  />
                </label>
              </div>
            }
          >
            <input
              type="radio"
              name="adminGeoState"
              value="forceGeoState"
              id="adminGeoStateForce"
              checked={forceGeoState != null}
              onChange={handleForceState}
            />
          </AdminPanel.Field>
        </AdminPanel.Fields>
      </AdminPanel.Field>
    </>
  );
}

import { addLocaleData } from 'react-intl';

export default function initLocaleData() {
  // Register React Intl's locale data for the user's locale in the browser. This
  // locale data was added to the page by `pages/_document.js`. This only happens
  // once, on initial page load in the browser.
  if (typeof window !== 'undefined' && window.ReactIntlLocaleData) {
    Object.keys(window.ReactIntlLocaleData).forEach((lang) => {
      addLocaleData(window.ReactIntlLocaleData[lang]);
    });
  }
}

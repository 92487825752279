import React from 'react';

import config from 'config';
import dynamic from 'next/dynamic';

import { AdminGuard } from '../../../techstyle-shared/react-admin';

import { Container as AssetProvider } from './AssetProvider';
import assetsModule, { loadAssets } from './assetsModule';
import { Component as DefaultAssetFilter } from './DefaultAssetFilter';
import logger from './logger';

const debug = logger.extend('assetsExtension');

const AssetAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './AssetAdminPanel')
);

export default function assetsExtension({
  defaultFilterComponent = DefaultAssetFilter,
  globalPrefetchAssets,
} = {}) {
  return (registry) => ({
    id: 'assets',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
    app: {
      render(props, children) {
        if (defaultFilterComponent) {
          children = React.createElement(defaultFilterComponent, {}, children);
        }
        return (
          <AssetProvider>
            {children}
            <AdminGuard>
              <AssetAdminPanel />
            </AdminGuard>
          </AssetProvider>
        );
      },
    },
    page({ prefetchAssets = [] }) {
      if (config.has('public.assets.globalPrefetchAssets')) {
        // eslint-disable-next-line no-console
        console.warn(
          'Config option public.assets.globalPrefetchAssets has been deprecated, please migrate to the globalPrefetchAssets option on assetsExtension.'
        );
      }
      const getPrefetchAssets = registry.utils.getMergedArrayOption({
        globalPrefetchAssets,
        prefetchAssets,
      });

      return {
        async getInitialProps(ctx) {
          const [prefetchAssets, meta] = await getPrefetchAssets(ctx);
          if (prefetchAssets.length) {
            debug(
              'Prefetching %s %s added by %s.',
              prefetchAssets.length,
              prefetchAssets.length === 1 ? 'asset' : 'assets',
              meta.sources.join(', ')
            );
            // FIXME: Prefetching does not support specifying options like
            // `batchKey` or `forceStoreGroup`. If you need these parameters, do
            // not prefetch the asset.
            await ctx.store.dispatch(loadAssets(prefetchAssets));
          }
        },
      };
    },
    redux: {
      modules: [assetsModule],
    },
  });
}

import React from 'react';

import PropTypes from 'prop-types';

// Super simple output of nav items
//  TODO:
//  - propTypes
//  - build out components for ListItem, Link, Submenu, etc
//  - default styling
export default function NavItem({ className, data, props = {}, ...rest }) {
  const gaLabel = data.gaLabel || data.label;
  const gaCategory = props.gaCategory || '';
  const gaAction = props.gaAction || '';

  const renderSubMenu = (data) => {
    if (data.navItems) {
      return (
        <ul data-nav-handle={data.handle}>
          {data.navItems.map((item, index) => {
            return <NavItem data={item} key={index} props={props} />;
          })}
        </ul>
      );
    }
  };

  return (
    <li
      className={data.label}
      data-ga-category={gaCategory}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
    >
      <a href={data.url}>{data.label}</a>
      {renderSubMenu(data)}
    </li>
  );
}

NavItem.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object, // TODO: Make more strict.
  gaAction: PropTypes.string,
  gaCategory: PropTypes.string,
  props: PropTypes.object,
};

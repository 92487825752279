import { useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  addFeatureOverride,
  removeFeatureOverride,
  loadFeatures,
  invalidateFeatures,
} from './featuresModule';

export default function useFeatureActions() {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          addFeatureOverride,
          removeFeatureOverride,
          loadFeatures,
          invalidateFeatures,
        },
        dispatch
      ),
    [dispatch]
  );

  return actions;
}

import React, { useMemo } from 'react';

import {
  useOrderDetails,
  OrderDetail,
} from '../../../../techstyle-shared/react-accounts';
import type { OrderLineItemDto } from '../../../../techstyle-shared/react-types';
import { createContext } from '../utils/createContext';
import {
  getOrderStatusFromCode,
  OrderStatus,
} from '../utils/getOrderStatusFromCode';
import getProductColorFromAlias from '../utils/getProductColorFromAlias';

import { SerializedOrderLineItem } from './types';

type OrderId = string | number;

type AccountOrdersRootProps = {
  orderId: OrderId;
  productTypeIdsToFilter?: number[];
};

type AccountOrderDetailsContext = {
  hasError: boolean;
  isLoading: boolean;
  orderData?: Omit<OrderDetail, 'orderLines'> & {
    orderLines: SerializedOrderLineItem[];
    splitOrders?: (Omit<OrderDetail, 'orderLines'> & {
      orderLines: SerializedOrderLineItem[];
    })[];
    orderStatus: OrderStatus | undefined;
    tokenQuantity: number;
  };
  orderId: OrderId;
};

const [useContext, Context] = createContext<AccountOrderDetailsContext>(
  'AccountOrderDetails'
);

/**
 * Serializes orderData.orderLines so that it can be safely
 * passed to OrderLineItem, which is also used in Checkout.
 */
const serializeOrderLineItem = (
  item: OrderLineItemDto,
  parentItem?: OrderLineItemDto
): SerializedOrderLineItem => {
  // Bundle items don't have a discount field, so we should use the parent's field instead.
  // We need fields like `finalSale` for RMA items.
  const discountItem = parentItem ?? item;
  const isBundleItem = parentItem !== undefined;
  const discount = discountItem.discount && {
    ...discountItem.discount,
    amount: discountItem.discount.amount,
    promoLabel: discountItem.discount.label,
  };

  return {
    ...item,
    discount,
    thumbnailImageWidth: 195,
    thumbnailImageHeight: 130,
    thumbnailImageSrc: item.imageURL,
    color: getProductColorFromAlias(item.alias),
    isBundleItem,
  };
};

const Root = ({
  children,
  orderId,
  productTypeIdsToFilter = [],
}: React.PropsWithChildren<AccountOrdersRootProps>) => {
  const orderDetails = useOrderDetails({ orderId });

  const contextValue = useMemo(() => {
    if (orderDetails?.error) {
      return {
        hasError: true,
        isLoading: false,
        orderData: undefined,
        orderId,
      };
    }

    if (!orderDetails?.data) {
      return {
        hasError: false,
        isLoading: orderDetails?.networkStatus.isLoading ?? true,
        orderData: undefined,
        orderId,
      };
    }

    const { data } = orderDetails;

    const filteredOrderLines = data.orderLines
      .filter((singleOrderLine: OrderLineItemDto) => {
        return !productTypeIdsToFilter.includes(singleOrderLine.productTypeId);
      })
      .map((item: OrderLineItemDto) => ({
        ...serializeOrderLineItem(item),
        bundleItems: item.bundleItems?.map((bundleItem: OrderLineItemDto) =>
          serializeOrderLineItem(bundleItem, item)
        ),
      }));

    const isMasterOrder = data.isMasterOrder;
    const splitOrders =
      isMasterOrder && data.splitOrders
        ? data.splitOrders.map((splitOrder: OrderDetail) => ({
            ...splitOrder,
            orderLines: splitOrder.orderLines
              .filter((singleOrderLine: OrderLineItemDto) => {
                return !productTypeIdsToFilter.includes(
                  singleOrderLine.productTypeId
                );
              })
              .map((item: OrderLineItemDto) => ({
                ...serializeOrderLineItem(item),
                bundleItems: item.bundleItems?.map(
                  (bundleItem: OrderLineItemDto) =>
                    serializeOrderLineItem(bundleItem, item)
                ),
              })),
          }))
        : undefined;

    return {
      hasError: false,
      isLoading: orderDetails.networkStatus.isLoading ?? true,
      orderData: {
        ...data,
        orderLines: filteredOrderLines,
        splitOrders: splitOrders as
          | (Omit<OrderDetail, 'orderLines'> & {
              orderLines: SerializedOrderLineItem[];
            })[]
          | undefined,
        tokenQuantity: data.memberTokenQuantity,
        orderStatus: getOrderStatusFromCode({
          processingStatusCode: data.processingStatusCode,
        }),
      },
      orderId,
    } as AccountOrderDetailsContext;
  }, [orderDetails, orderId, productTypeIdsToFilter]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export { Root, useContext };

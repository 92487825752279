import React, {
  useCallback,
  useEffect,
  useLayoutEffect as useRealLayoutEffect,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import {
  useHydrationStatus,
  HydrationStatus,
} from '../../../../techstyle-shared/redux-core';
import logger from '../logger';

const evaluatedScripts = new Map();

const debug = logger.extend('Script');

function createScript(src, scriptText, props = {}) {
  const script = document.createElement('script');
  for (const key in props) {
    const value = props[key];
    if (typeof value === 'string') {
      script.setAttribute(key, value);
    } else {
      script[key] = props[key];
    }
  }
  // Force non-async behavior by default, just like non-dynamic `<script>` tags.
  script.async = Boolean(props.async);
  if (src) {
    script.src = src;
  }
  if (scriptText) {
    script.appendChild(document.createTextNode(scriptText));
  }
  return script;
}

// Helper for calling `useLayoutEffect` that shuts up the annoying server
// warning.
const useLayoutEffect = process.browser ? useRealLayoutEffect : useEffect;

/**
 * Return a function that will always call the latest given `callback`, even if
 * `callback` was something else when this function was originally added to a
 * callback queue (like a Promise's `then()` queue for example). If the returned
 * function is called multiple times, it will only call `callback` again when
 * it changes (that is, the same `callback` won't be called again even if the
 * returned function is).
 */
function useLatestCallback(callback) {
  const ref = useRef();
  const executed = useRef(false);

  useLayoutEffect(() => {
    ref.current = callback;
    executed.current = false;
  }, [callback]);

  return useCallback((...args) => {
    if (ref.current && !executed.current) {
      executed.current = true;
      ref.current(...args);
    }
  }, []);
}

/**
 * A `<script>` element that will actually evaluate its contents when rendered
 * on the client after hydration has already taken place, unlike vanilla
 * `<script>` behavior in React. Usually React will not load or evaluate scripts
 * inserted in this way, because the official DOM behavior does not evaluate
 * them in certain cases either, and several situations would have ambiguous
 * behavior.
 *
 * This component resolves that ambiguous behavior and forces the script to
 * evaluate:
 *
 * - If the script was rendered on the server, then it does not need to be
 *   evaluated on its first render, because the browser would have already run
 *   it. Otherwise, we force the script to run after mounting by appending an
 *   additional `<script>` element ourselves in a manner that does trigger
 *   evaluation.
 * - The script will be re-run (whether it was originally in the server output
 *   or not) when either `src` or the inline script content (in
 *   `dangerouslySetInnerHTML`) changes.
 * - If the `globalScriptKey` prop is supplied, it will be serialized and used
 *   to track whether the script was already evaluated. If so, evaluation will
 *   be skipped.
 */
function Script({
  dangerouslySetInnerHTML,
  globalScriptKey,
  onError,
  onLoad,
  onReady,
  skipServerRender = false,
  src,
  type,
  isUnmountChild = true,
  shouldPersistInDOM = !isUnmountChild,
  ...rest
}) {
  const hydrationStatus = useHydrationStatus((status) => status);
  const [fromServer] = useState(
    hydrationStatus !== HydrationStatus.COMPLETE && !skipServerRender
  );
  // If this script will have its evaluation globally recorded, this is the
  // string that will be used to identify it.
  const globalId = globalScriptKey ? JSON.stringify(globalScriptKey) : null;

  const isScript = !type || type === 'text/javascript';
  const scriptText = dangerouslySetInnerHTML
    ? dangerouslySetInnerHTML.__html.trim()
    : '';
  const hasContent = Boolean(src || scriptText);

  const handleError = useLatestCallback(onError);
  const handleLoad = useLatestCallback(onLoad);
  const handleReady = useLatestCallback(onReady);

  const detectUnmount = useRef(false);

  // A key for determining when the script has changed.
  const scriptKey = JSON.stringify(
    src ? { src, isScript } : { scriptText, isScript }
  );

  // A ref for tracking which version of the script was already executed. If
  // the previous `scriptKey` matches, we don't need to re-evaluate it. Remember
  // that if the script came from the server, it was evaluated!
  const evaluatedKey = useRef(fromServer ? scriptKey : null);

  useEffect(() => {
    return () => {
      detectUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    // A shortened version of the script for logging purposes.
    let logScript = globalId;
    if (!globalId) {
      logScript = src || scriptText;
      if (debug.enabled && logScript.length > 60) {
        logScript = `${logScript.slice(0, 60)}…`;
      }
    }

    let promise = globalId ? evaluatedScripts.get(globalId) : undefined;

    const wasEvaluated = globalId
      ? fromServer || promise != null
      : evaluatedKey.current === scriptKey;

    const shouldEvaluate = hasContent && isScript && !wasEvaluated;
    evaluatedKey.current = scriptKey;

    if (shouldEvaluate) {
      debug('Appending script to trigger evaluation: %o', logScript);

      let onerror;
      let onload;

      promise = new Promise((resolve, reject) => {
        onerror = (err) => {
          reject(err);
          handleError();
        };
        onload = () => {
          resolve();
          handleLoad();
          handleReady();
        };
      });

      if (globalId) {
        evaluatedScripts.set(globalId, promise);
      }

      const script = createScript(src, scriptText, {
        ...rest,
        onerror,
        onload,
      });

      document.body.appendChild(script);

      return () => {
        const isUnmounting = detectUnmount.current;
        if (isUnmounting && !shouldPersistInDOM) {
          document.body.removeChild(script);
        }
      };
    } else {
      debug('Skipping evaluation of script: %o', logScript);
      // In this case the script is already loaded or loading. Ignore this
      // render's `onLoad`, only enqueue `onReady`.
      if (!promise && globalId) {
        // This script came from the server. We have no idea way to know when
        // its `onLoad` was/will be fired; assume it was a synchronous script
        // executed before the application.
        promise = Promise.resolve();
        evaluatedScripts.set(globalId, promise);
      }
      if (promise) {
        promise.then(handleReady, handleError);
      }
    }
  });

  // If we're rendering in the browser, remember that React won't actually
  // evaluate scripts injected in this way. They will only have been evalutated
  // if they were in the original HTML payload. So, we might be about to inject
  // a different `<script>` tag in `useEffect` above in order to trigger
  // evaluation. If we're going to do that, don't bother returning a useless
  // `<script>` element here that will ultimately be inert (and confusing).
  // Render null instead.
  if (fromServer || !hasContent || !isScript) {
    return (
      <script
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        src={src}
        type={type}
        {...rest}
      />
    );
  }
  return null;
}

Script.propTypes = {
  /**
   * The inline script content, just like a normal `<script>` tag.
   */
  dangerouslySetInnerHTML: PropTypes.shape({
    __html: PropTypes.string,
  }),
  /**
   * A unique identifier for this script that will be used to determine whether
   * it has already been executed. (It does not have to be a string or numeric
   * ID, as it will be serialized for you.) This is useful if the script is
   * rendered in a place where it can potentially be unmounted, then mounted
   * again later. Most often this is the case with pages, but it can happen to
   * the app component too, if an error is thrown. See:
   * https://github.com/zeit/next.js/issues/7578
   */
  globalScriptKey: PropTypes.any,
  /**
   * @deprecated since version 5.9.0. Use `shouldPersistInDOM` instead.
   */
  isUnmountChild: PropTypes.bool,
  /**
   * Error event callback to add to the script.
   */
  onError: PropTypes.func,
  /**
   * Load event callback to add to the script. Will only be called once for any
   * given `globalScriptKey`.
   */
  onLoad: PropTypes.func,
  /**
   * Callback fired after the script is loaded, including if the script was
   * already loaded previously. Unlike `onLoad`, this can be called multiple
   * times for the same `globalScriptKey`.
   */
  onReady: PropTypes.func,
  /**
   * Prevent unmounting the `<script>` from the DOM even when the parent
   * component is unmounted. Must be used in conjunction with `globalScriptKey`.
   */
  shouldPersistInDOM: PropTypes.bool,
  /**
   * Whether to skip server side rendering the script.
   */
  skipServerRender: PropTypes.bool,
  /**
   * The script URL to load.
   */
  src: PropTypes.string,
  /**
   * The script type. This is usually not required; please don't pass
   * `text/javascript` unnecessarily. Only useful when you're using alternate
   * script types to store data (often used for templating).
   */
  type: PropTypes.string,
};

// By default, React.memo checks reference equality for each prop. But Script
// elements have two common props for which this would fail: `dangerouslySetInnerHTML`
// and `globalScriptKey`. So let's use a custom comparison function that
// stringifies those.
function arePropsEqual(prevProps, nextProps) {
  for (const key in prevProps) {
    switch (key) {
      case 'dangerouslySetInnerHTML':
      case 'globalScriptKey':
        if (JSON.stringify(prevProps[key]) !== JSON.stringify(nextProps[key])) {
          return false;
        }
        break;
      default:
        if (prevProps[key] !== nextProps[key]) {
          return false;
        }
    }
  }
  for (const key in nextProps) {
    if (!(key in prevProps)) {
      return false;
    }
  }
  return true;
}

export default React.memo(Script, arePropsEqual);

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import useMarketingActions from './useMarketingActions';

export default function useReferrerOptions(params) {
  const data = useSelector((state) => state.marketing.referrerOptions);
  const actions = useMarketingActions();

  useEffect(() => {
    if (!data.networkStatus.isUpToDate) {
      actions.loadReferrerOptions(params);
    }
  }, [actions, data.networkStatus.isUpToDate, params]);

  return data.options;
}

import { differenceInDays } from 'date-fns';

import { parseDate } from '../../../techstyle-shared/redux-core';

export default function getPostRegDaysFunction({
  dateTimeAdded,
  getCurrentDate,
}) {
  const getDaysSinceJoining = () => {
    const memberJoinDateTime = parseDate(dateTimeAdded);
    return differenceInDays(getCurrentDate(), memberJoinDateTime);
  };
  return getDaysSinceJoining;
}

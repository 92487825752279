import React, { useMemo, useCallback } from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import { useCookies } from '../../../../techstyle-shared/redux-core';

const BrowserSupportContext = React.createContext();

export default function BrowserSupportProvider({
  browserName,
  children,
  isSupported,
}) {
  const [cookies, setCookie] = useCookies(['showBrowserAlert']);
  const showBrowserAlert = cookies.showBrowserAlert === '1';

  const dismissBrowserAlert = useCallback(() => {
    setCookie('showBrowserAlert', '0', { path: '/', secure: true });
  }, [setCookie]);

  const value = useMemo(
    () => ({
      browserName,
      dismissAlertUrl: config.get('public.browserSupport.dismissAlertUrl'),
      dismissBrowserAlert,
      isSupported,
      showBrowserAlert,
    }),
    [browserName, dismissBrowserAlert, isSupported, showBrowserAlert]
  );

  return (
    <BrowserSupportContext.Provider value={value}>
      {children}
    </BrowserSupportContext.Provider>
  );
}

BrowserSupportProvider.Context = BrowserSupportContext;

BrowserSupportProvider.propTypes = {
  browserName: PropTypes.string,
  children: PropTypes.node,
  isSupported: PropTypes.bool,
};

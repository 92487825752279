import React from 'react';

import { createContext } from '../utils/createContext';
import getCartItemSoldOutStatus from '../utils/getCartItemSoldOutStatus';

import { Cart, OrderLineItem } from './checkoutTypes';

type CheckoutContextValueType = {
  cart?: Cart;
  cartItems?: OrderLineItem[];
};

type RootProps = React.PropsWithChildren<{ cart?: Cart }>;

export const [useCheckoutContext, Context] =
  createContext<CheckoutContextValueType>('CheckoutContext');

export const getNumberOfSoldOutItems = (cartItems: OrderLineItem[]) => {
  let numberOfSoldOutItems = 0;

  cartItems.forEach((item) => {
    if (getCartItemSoldOutStatus(item)) {
      numberOfSoldOutItems = numberOfSoldOutItems + 1;
    }
  });
  return numberOfSoldOutItems;
};

export default function CheckoutContext({ children, cart }: RootProps) {
  const context = {
    cart: cart,
    cartItems: cart?.items,
  };
  return <Context.Provider value={context}>{children}</Context.Provider>;
}

import React from 'react';

import { getSelectorsByUserAgent } from 'react-device-detect';

export type DeviceDetectionContextType = {
  selectors: any;
  userAgent: string;
};

export const DeviceDetectionContext =
  React.createContext<DeviceDetectionContextType>({
    selectors: {},
    userAgent: '',
  });

export function useDeviceDetection() {
  const { selectors } = React.useContext(DeviceDetectionContext);
  return selectors;
}

export function DeviceDetectionProvider({
  userAgent,
  children,
}: {
  userAgent: string;
  children: React.ReactNode;
}) {
  const selectors = getSelectorsByUserAgent(userAgent);
  return (
    <DeviceDetectionContext.Provider
      value={{
        userAgent,
        selectors,
      }}
    >
      {children}
    </DeviceDetectionContext.Provider>
  );
}

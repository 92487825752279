import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.fill || props.color || 'black'};
  vertical-align: top;
`;

const DreamPairsKidsLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 309 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={titleId}>Dream Pairs Kids</title>
      <path
        data-letter="D"
        d="M0 0.211914H11.628C14.8795 0.211914 16.9302 0.847946 18.2736 2.22601C19.9698 3.9588 20.5365 5.9729 20.5365 10.0724C20.5365 16.4348 20.3245 18.3796 19.5478 20.0389C18.239 22.6197 15.8723 23.7858 11.7727 23.7858H0V0.211914ZM10.8859 19.9004C13.0427 19.9004 14.2434 19.4764 14.9508 18.4857C15.7275 17.3889 15.9395 15.9761 15.9395 11.9459C15.9395 7.91565 15.7622 6.50293 15.0201 5.47752C14.3494 4.52347 13.2527 4.13411 11.1672 4.13411H4.52357V19.8983H10.8859V19.9004Z"
      />
      <path
        data-letter="R"
        d="M23.8938 0.211914H35.4872C41.1789 0.211914 43.1216 1.97934 43.1216 7.24496C43.1216 11.9459 42.3082 13.218 38.8794 13.8907V14.068C41.5661 14.2087 42.9096 15.8008 42.8728 18.6629V23.7879H38.3126V19.5457C38.384 16.9649 37.2872 15.8701 34.7778 15.9394H28.4154V23.7858H23.8918V0.211914H23.8938ZM34.7451 12.0886C36.1598 12.0886 37.0773 11.8766 37.6072 11.3812C38.3493 10.7798 38.5267 10.0724 38.5267 8.05834C38.5267 4.73549 37.9619 4.09945 35.0632 4.09945H28.4174V12.0886H34.7451Z"
      />
      <path
        data-letter="E"
        d="M50.9314 10.0745H61.9234V13.3973H50.9314V20.0431H62.6307V23.79H46.4078V0.211914H62.5246V3.9588H50.9314V10.0745Z"
      />
      <path
        data-letter="A"
        d="M70.6546 19.2644L69.2051 23.7879H64.4696L72.3139 0.211914H78.9942L86.9834 23.7879H82.3539L80.8331 19.2644H70.6525H70.6546ZM75.7448 3.67747L71.7146 15.9068H79.8098L75.7448 3.67747Z"
      />
      <path
        data-letter="M"
        d="M116.567 23.7879H112.113V10.9225C112.113 9.22645 112.148 8.76573 112.29 5.69157L112.362 3.9588H112.221L111.691 5.58557C110.702 8.65971 110.454 9.4731 110.064 10.4985L104.868 23.7879H100.803L95.572 10.6045C95.1135 9.4731 94.8647 8.73107 93.876 5.69157L93.3459 4.0648H93.2053L93.3114 5.76088C93.452 8.72902 93.4887 9.4731 93.4887 10.9205V23.7858H89.0344V0.211914H96.3162L100.522 11.1345C100.946 12.2313 101.192 13.008 102.183 16.0129L102.713 17.6743H102.854L103.384 16.0474C104.303 13.0793 104.587 12.2659 104.974 11.1692L109.145 0.211914H116.567V23.7879Z"
      />
      <path
        data-letter="P"
        d="M129.538 0.211914H139.081C141.59 0.211914 141.837 0.211914 142.722 0.283264C144.807 0.423925 146.434 1.30866 147.317 2.75808C148.093 4.06683 148.377 5.58557 148.377 8.44974C148.377 12.8327 147.28 14.9895 144.558 15.8008C143.922 16.0129 142.179 16.1426 139.327 16.1901H134.062V23.79H129.538V0.211914ZM139.152 12.3006C143.146 12.3006 143.782 11.7706 143.782 8.37635C143.782 5.76088 143.498 4.80683 142.579 4.31147C141.943 4.02811 141.519 3.9588 139.611 3.9588H134.062V12.3006H139.152Z"
      />
      <path
        data-letter="A"
        d="M154.103 19.2644L152.654 23.7879H147.918L155.765 0.211914H162.445L170.434 23.7879H165.805L164.283 19.2644H154.103ZM159.193 3.67747L155.163 15.9068H163.258L159.193 3.67747Z"
      />
      <path
        data-letter="I"
        d="M177.006 23.7879H172.483V0.211914H177.006V23.7879Z"
      />
      <path
        data-letter="R"
        d="M181.141 0.211914H192.734C198.426 0.211914 200.368 1.97934 200.368 7.24496C200.368 11.9459 199.555 13.218 196.126 13.8907V14.068C198.813 14.2087 200.157 15.8008 200.12 18.6629V23.7879H195.56V19.5457C195.63 16.9649 194.534 15.8701 192.024 15.9394H185.662V23.7858H181.139L181.141 0.211914ZM191.992 12.0886C193.406 12.0886 194.325 11.8766 194.854 11.3812C195.597 10.7798 195.773 10.0724 195.773 8.05834C195.773 4.73549 195.21 4.09945 192.31 4.09945H185.664V12.0886H191.992Z"
      />
      <path
        data-letter="S"
        d="M217.018 7.10438V6.68037C217.018 4.09955 216.275 3.56952 212.634 3.56952C208.357 3.56952 207.509 4.09955 207.509 6.78637C207.509 8.48246 207.933 9.26118 209.066 9.61386C209.666 9.79122 209.878 9.79122 213.554 10.0032C217.512 10.2499 218.856 10.4986 219.987 11.312C221.437 12.3007 221.933 13.6808 221.933 16.6835C221.933 19.6864 221.509 21.2072 220.483 22.2326C219.069 23.6106 217.266 24 212.354 24C208.077 24 206.627 23.7533 205.072 22.8339C204.189 22.3038 203.445 21.1725 203.198 19.935C202.986 18.9098 202.95 18.2024 202.915 16.329H207.262C207.262 18.0963 207.368 18.6978 207.758 19.297C208.323 20.251 208.89 20.3571 212.565 20.3571C215.214 20.3571 215.96 20.251 216.63 19.7211C217.232 19.297 217.478 18.3776 217.478 16.753C217.478 15.1282 217.125 14.4555 216.17 14.1374C215.792 13.9961 214.296 13.8432 211.68 13.6788C207.65 13.4321 206.802 13.2894 205.602 12.7247C203.728 11.8053 202.986 10.1092 202.986 6.85773C202.986 4.0649 203.587 2.33416 204.859 1.41476C206.309 0.318016 208.146 0 212.528 0C216.912 0 218.856 0.458677 220.022 1.76744C220.941 2.79283 221.365 4.20555 221.365 6.25635V7.10438H217.018Z"
      />
      <path
        data-letter="K"
        d="M238.437 9.82578H240.557L248.368 0.211914H253.952L244.41 11.7339L254.942 23.7858H249.216L240.557 13.7113H238.437V23.7858H233.914V0.211914H238.437V9.82578Z"
      />
      <path
        data-letter="I"
        d="M261.552 23.7879H257.029V0.211914H261.552V23.7879Z"
      />
      <path
        data-letter="D"
        d="M265.686 0.211914H277.314C280.565 0.211914 282.616 0.847946 283.96 2.22601C285.656 3.9588 286.222 5.9729 286.222 10.0724C286.222 16.4348 286.01 18.3796 285.234 20.0389C283.925 22.6197 281.558 23.7858 277.456 23.7858H265.686V0.211914ZM276.571 19.9004C278.728 19.9004 279.93 19.4764 280.637 18.4857C281.413 17.3889 281.626 15.9761 281.626 11.9459C281.626 7.91565 281.448 6.50293 280.706 5.47752C280.035 4.52347 278.939 4.13411 276.853 4.13411H270.208V19.8983H276.57L276.571 19.9004Z"
      />
      <path
        data-letter="S"
        d="M302.691 7.10438V6.68037C302.691 4.09955 301.95 3.56952 298.309 3.56952C294.032 3.56952 293.184 4.09955 293.184 6.78637C293.184 8.48246 293.608 9.26118 294.739 9.61386C295.341 9.79122 295.552 9.79122 299.229 10.0032C303.187 10.2499 304.531 10.4986 305.662 11.312C307.112 12.3007 307.606 13.6808 307.606 16.6835C307.606 19.6864 307.182 21.2072 306.157 22.2326C304.742 23.6106 302.941 24 298.027 24C293.75 24 292.301 23.7533 290.746 22.8339C289.862 22.3038 289.118 21.1725 288.872 19.935C288.661 18.9098 288.626 18.2024 288.589 16.329H292.938C292.938 18.0963 293.043 18.6978 293.432 19.297C293.997 20.251 294.565 20.3571 298.24 20.3571C300.89 20.3571 301.634 20.251 302.304 19.7211C302.906 19.297 303.152 18.3776 303.152 16.753C303.152 15.1282 302.8 14.4555 301.843 14.1374C301.467 13.9961 299.971 13.8432 297.355 13.6788C293.325 13.4321 292.477 13.2894 291.275 12.7247C289.403 11.8053 288.661 10.1092 288.661 6.85773C288.661 4.0649 289.262 2.33416 290.534 1.41476C291.984 0.318016 293.821 0 298.203 0C302.586 0 304.531 0.458677 305.696 1.76744C306.616 2.79283 307.04 4.20555 307.04 6.25635V7.10438H302.691Z"
      />
    </Svg>
  );
});

DreamPairsKidsLogo.displayName = 'DorinaLogo';

DreamPairsKidsLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default DreamPairsKidsLogo;

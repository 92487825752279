import React from 'react';

import config from 'config';

import { Script } from '../../techstyle-shared/react-marketing';
import { useDomain } from '../../techstyle-shared/redux-core';

export function triggerRender(creativeGroup) {
  if (window?.__attentive?.trigger) {
    return window.__attentive.trigger(creativeGroup);
  } else {
    throw new Error(`Attentive is not defined on window.`);
  }
}

export default function AttentiveTag() {
  const { tld } = useDomain();
  const attentiveDomain = config.get('public.brand.attentive.domain')[tld];
  const src = `//cdn.attn.tv/${attentiveDomain}/dtag.js`;

  return (
    <>
      <Script globalScriptKey="attentive-script" src={src} />
    </>
  );
}

import { MutableRefObject, useCallback, useMemo, useRef } from 'react';

/**
 * When modifying CSS properties within a single tick, measurements won't
 * necessarily see the effects of those changes unless we force a reflow.
 * Accessing `scrollTop` is one way to achieve this.
 */
export function forceReflow(node: HTMLElement) {
  node.scrollTop; // eslint-disable-line no-unused-expressions
}

function setSizeProperty(
  node: HTMLElement,
  property: 'width' | 'height',
  value?: string | number | null
) {
  switch (typeof value) {
    case 'undefined':
      return;
    case 'number':
      value = `${value}px`;
  }
  node.style[property] = value || '';
}

function setSize(
  node: HTMLElement,
  width?: number | string | null,
  height?: number | string | null
) {
  setSizeProperty(node, 'width', width);
  setSizeProperty(node, 'height', height);
}

/**
 * When a new transition action like `prepare` happens before a previous action
 * completes, the style property is modified during a CSS transition. Chrome
 * handles this smoothly, but Safari effectively kills the transition, making it
 * frozen until it is later reset. Setting `transition-property` to `none` until
 * we're actually expecting to animate the property seems to fix this.
 */
function disableTransition(node: HTMLElement) {
  node.style.transitionProperty = 'none';
}

function restoreTransition(node: HTMLElement) {
  node.style.transitionProperty = '';
}

function measureSize(node: HTMLElement) {
  const { width, height } = node.getBoundingClientRect();
  return [width, height];
}

type SizeOptions = {
  width?: number | string | boolean;
  height?: number | string | boolean;
};

function unsetSize(node: HTMLElement, options: SizeOptions) {
  restoreTransition(node);
  setSize(
    node,
    options.width ? null : undefined,
    options.height ? null : undefined
  );
}

function freezeCurrentSize(node: HTMLElement, options: SizeOptions) {
  const [width, height] = measureSize(node);
  disableTransition(node);
  setSize(
    node,
    options.width ? width : undefined,
    options.height ? height : undefined
  );
}

function transitionToAutoSize(node: HTMLElement, options: SizeOptions) {
  const { width: prevWidth, height: prevHeight } = node.style;
  disableTransition(node);
  setSize(
    node,
    options.width ? null : undefined,
    options.height ? null : undefined
  );
  forceReflow(node);
  const [nextWidth, nextHeight] = measureSize(node);
  setSize(
    node,
    options.width ? prevWidth : undefined,
    options.height ? prevHeight : undefined
  );
  forceReflow(node);
  restoreTransition(node);
  setSize(
    node,
    options.width ? nextWidth : undefined,
    options.height ? nextHeight : undefined
  );
}

export default function useSizeTransition(
  ref?: MutableRefObject<HTMLElement | null>,
  initialOptions: SizeOptions = { width: true, height: true }
) {
  const options = useRef<SizeOptions>(initialOptions);

  const prepare = useCallback(
    (node: any) => {
      if (ref) {
        node = ref.current;
      }
      if (node) {
        freezeCurrentSize(node, options.current);
      }
    },
    [options, ref]
  );

  const run = useCallback(
    (node: any) => {
      if (ref) {
        node = ref.current;
      }
      if (node) {
        transitionToAutoSize(node, options.current);
      }
    },
    [options, ref]
  );

  const cleanup = useCallback(
    (node?: HTMLElement | null) => {
      if (ref) {
        node = ref.current;
      }
      if (node) {
        unsetSize(node, options.current);
      }
    },
    [options, ref]
  );

  return useMemo(() => ({ prepare, run, cleanup }), [cleanup, prepare, run]);
}

import React from 'react';

import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { createGlobalStyle, css } from 'styled-components';

const DebugTools = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ '../shared/components/DebugTools')
);

const BuilderAdminTools = dynamic(() =>
  import(
    /* webpackChunkName: "AdminTools" */ '../shared/components/BuilderAdminTools'
  )
);

const HighlightGlobalStyles = createGlobalStyle`
 
  ${({ $highlightBundlesEnabled }) =>
    $highlightBundlesEnabled &&
    css`
      body {
        svg.whitelabel-icon {
          outline: 2px solid purple;
        }
      }
    `}
`;

const EnableHighlightStyles = (props) => {
  const highlightBundlesEnabled = useSelector(
    (state) => state.intl.highlightBundles
  );

  if (highlightBundlesEnabled) {
    return (
      <HighlightGlobalStyles
        $highlightBundlesEnabled={highlightBundlesEnabled}
      />
    );
  }

  return null;
};

export default function adminExtension() {
  return {
    id: 'adminExtension',
    admin: {
      panels: [<DebugTools key="yitty" />, <BuilderAdminTools key="builder" />],
    },
    app: {
      render(props, children) {
        return (
          <>
            <EnableHighlightStyles />
            {children}
          </>
        );
      },
    },
  };
}

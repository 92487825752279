import React from 'react';

import PropTypes from 'prop-types';
import Overflow from 'react-overflow-indicator';
import styled from 'styled-components';

import DownChevron from '../DownChevron';
import { useImageBrowser } from '../ImageBrowserContext';
import Picture from '../Picture';
import UpChevron from '../UpChevron';

if (process.browser) {
  // `react-overflow-indicator` depends on IntersectionObserver.
  require('intersection-observer');
  // The buttons below use `scrollBy`, which isn't supported on some browsers.
  require('smoothscroll-polyfill').polyfill();
}

const defaultBreakpoints = [{ minWidth: 0, pictureWidth: 88 }];

const CellsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.button`
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;

  &::after {
    display: ${(props) => (props.isSelected ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid black;
  }

  & + & {
    margin-top: 8px;
  }

  ${(props) => props.buttonStyle};
`;

const Wrapper = styled.div`
  display: flex;
  width: 88px;
  flex-direction: column;
`;

const OverflowStyled = styled(Overflow)`
  flex: 1 1 0;
  overflow: auto;

  [data-overflow-viewport] {
    overflow: hidden !important;
  }
`;

const DownIndicator = styled.button`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: transparent;
  border: none;
  padding: 4px;
  z-index: 2;
  text-align: center;

  > * {
    vertical-align: top;
  }
`;

const UpIndicator = styled.button`
  position: absolute;
  top: 0;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  padding: 4px;
  z-index: 2;
  text-align: center;

  > * {
    vertical-align: top;
  }
`;

const TopFadeOut = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: linear-gradient(to top, transparent, white);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.5s;
`;

const BottomFadeOut = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30px;
  background: linear-gradient(to bottom, transparent, white);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  z-index: 1;
  pointer-events: none;
  transition: opacity 0.5s;
`;

export default function SecondaryImages({
  autoTagPrefix = 'pdp',
  breakpoints = defaultBreakpoints,
  buttonStyle,
  images,
  onClick,
  activeImageIndex,
  renderPicture = (props) => <Picture {...props} />,
  ...rest
}) {
  const imageBrowserContext = useImageBrowser();
  if (images == null) {
    images = imageBrowserContext.images;
  }
  if (activeImageIndex == null) {
    activeImageIndex = imageBrowserContext.activeImageIndex;
  }
  if (onClick == null) {
    onClick = imageBrowserContext.setActiveImageIndex;
  }

  return (
    <Wrapper
      data-autotag={`${autoTagPrefix}_secondary_media_content`}
      {...rest}
    >
      <OverflowStyled tolerance="1px">
        <Overflow.Indicator direction="up">
          {(canScroll) => <TopFadeOut isVisible={canScroll} />}
        </Overflow.Indicator>
        <Overflow.Indicator direction="up">
          {(canScroll, refs) =>
            canScroll ? (
              <UpIndicator
                type="button"
                onClick={() => {
                  refs.viewport.current.scrollBy({
                    top: -refs.viewport.current.clientHeight,
                    behavior: 'smooth',
                  });
                }}
                data-autotag={`${autoTagPrefix}_previous_img_btn`}
              >
                <UpChevron color="#333333" />
              </UpIndicator>
            ) : null
          }
        </Overflow.Indicator>
        <OverflowStyled.Content>
          <CellsWrapper>
            {images.map((image, index) => {
              return (
                <ButtonWrapper
                  type="button"
                  key={index}
                  isSelected={index === activeImageIndex}
                  buttonStyle={buttonStyle}
                  onClick={() => {
                    onClick(index);
                  }}
                >
                  {renderPicture({
                    sources: image,
                    breakpoints: breakpoints,
                  })}
                </ButtonWrapper>
              );
            })}
          </CellsWrapper>
        </OverflowStyled.Content>
        <Overflow.Indicator direction="down">
          {(canScroll) => <BottomFadeOut isVisible={canScroll} />}
        </Overflow.Indicator>
        <Overflow.Indicator direction="down">
          {(canScroll, refs) =>
            canScroll ? (
              <DownIndicator
                type="button"
                onClick={() => {
                  refs.viewport.current.scrollBy({
                    top: refs.viewport.current.clientHeight,
                    behavior: 'smooth',
                  });
                }}
                data-autotag={`${autoTagPrefix}_next_img_btn`}
              >
                <DownChevron color="#333333" />
              </DownIndicator>
            ) : null
          }
        </Overflow.Indicator>
      </OverflowStyled>
    </Wrapper>
  );
}

SecondaryImages.propTypes = {
  activeImageIndex: PropTypes.number,
  autoTagPrefix: PropTypes.string,
  breakpoints: PropTypes.array,
  buttonStyle: PropTypes.any,
  images: PropTypes.array,
  onClick: PropTypes.func,
  renderPicture: PropTypes.func,
};

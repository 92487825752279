import config from 'config';

import {
  logInSuccess,
  signUpSuccess,
  updateMembershipStatusSuccess,
} from '../../../techstyle-shared/react-accounts';
import {
  createAction,
  createCachedSelector,
  createReducer,
  createSelector,
  getDateNowFunction,
  loadSessionSuccess,
  outOfDateStatus,
  parseDate,
  startLoading,
  stopLoading,
  upToDateStatus,
} from '../../../techstyle-shared/redux-core';

import logger from './logger';
import { AssetErrorType, AssetContainerMap } from './schema';

const debug = logger.extend('assetsModule');

export const initialState = {
  containers: {},
  highlightAssets: false,
};

export function initAssets() {
  return (dispatch, getState, context) => {
    if (context.isServer && !context.hasPreloadedState) {
      // Support both camelcase and underscore param name.
      const highlightAssets =
        context.req.query.highlightAssets ||
        context.req.query.highlight_assets ||
        '';

      if (/^(true|1)$/.test(highlightAssets)) {
        dispatch(setHighlightAssets(true));
      }
    }
  };
}

export const setHighlightAssets = createAction('assets/setHighlightAssets');
export const loadAssetsRequest = createAction('assets/loadAssetsRequest');
export const loadAssetsSuccess = createAction('assets/loadAssetsSuccess');
export const loadAssetsFailure = createAction('assets/loadAssetsFailure');
export const invalidateAssets = createAction('assets/invalidateAssets');

const getAllSegmentIds = createSelector(
  [(state) => state.domain.tld],
  (tld) => {
    const allSegmentIds = config.get('public.segmentation.allSegmentIds');
    if (allSegmentIds && allSegmentIds[tld]) {
      return new Set(allSegmentIds[tld]);
    }
  }
);

export function loadAssets(names, options = {}) {
  if (names && typeof names === 'string') {
    names = [names];
  } else if (!names || !names.length) {
    throw new Error(`loadAssets must be called with at least one name`);
  }

  const { batch = config.get('public.assets.batch') } = options;
  const maxAge = config.get('public.assets.maxAge');
  const { batchKey = 'default' } = options;
  const { forceStoreGroup } = options;

  if (batch) {
    return (dispatch, getState, context) => {
      context.loadAssetsBatches = context.loadAssetsBatches || {};
      const { loadAssetsBatches: batches } = context;
      let currentBatch = batches[batchKey];

      if (currentBatch) {
        // Add to the batch.
        currentBatch.names.push(...names);
      } else {
        // Start a new batch.
        currentBatch = {
          names: names.slice(),
          promise: new Promise((resolve, reject) => {
            const loadAssetsAfterTick = async () => {
              // Let a tick of the event loop pass.
              await Promise.resolve();
              // After waiting, get a snapshot of the current queue and clear it
              // out.
              const { loadAssetsBatches: batches } = context;
              const currentBatch = batches[batchKey];
              batches[batchKey] = undefined;
              // Get unique names.
              const batchNames = Array.from(new Set(currentBatch.names));
              try {
                resolve(
                  dispatch(
                    loadAssets(batchNames, {
                      batch: false,
                      batchKey,
                      forceStoreGroup,
                    })
                  )
                );
              } catch (err) {
                reject(err);
              }
            };

            loadAssetsAfterTick();
          }),
        };
        batches[batchKey] = currentBatch;
      }
      return currentBatch.promise;
    };
  }

  return async (dispatch, getState, context) => {
    const requestKey = batchKey ? `assets.${batchKey}` : null;

    const prevBatch = requestKey ? context.inflight.get(requestKey) : null;
    if (prevBatch) {
      // If there's a previous batch, we need to wait for it to finish before
      // we should fetch more.
      await prevBatch;
    }

    const state = getState();
    const { assets } = state;
    const allSegmentIds = getAllSegmentIds(state);
    // FIXME: If trying to fetch the same asset from multiple store groups,
    // you'll get one or the other; the behavior is undefined.
    // See: https://jira.justfab.net/browse/BMIG-731
    const containersToFetch = [];
    const containersFromCache = [];

    const now = getDateNowFunction(state)();

    names.forEach((name) => {
      const container = assets.containers[name];
      let shouldFetch = false;
      if (container) {
        let isExpired = false;
        if (container.fetchedDate) {
          const fetchedDate = parseDate(container.fetchedDate);
          const age = now - fetchedDate.getTime();
          isExpired = age >= maxAge;
        }
        if (container.networkStatus.isLoading) {
          // Already loading, do nothing.
        } else if (isExpired) {
          debug('Asset container “%s” is expired; refetching.', name);
          shouldFetch = true;
        } else if (container.error) {
          // Don't attempt to fetch if there was an error last time! Only when
          // the container expires (above) will it be retried.
          debug('Asset container “%s” has error; skipping refetch.', name);
        } else if (!container.networkStatus.isUpToDate) {
          debug('Asset container “%s” is invalidated; refetching.', name);
          shouldFetch = true;
        }
      } else {
        shouldFetch = true;
      }
      if (shouldFetch) {
        containersToFetch.push(name);
      } else {
        containersFromCache.push(name);
      }
    });

    if (containersToFetch.length) {
      const headers = {};

      if (forceStoreGroup) {
        headers['x-tfg-storeGroupId'] = forceStoreGroup;
      }

      return dispatch({
        bentoApi: {
          endpoint: 'assets',
          searchParams: { keys: containersToFetch.join(' ') },
          requestKey,
          headers,
          schema: AssetContainerMap,
          actions: [
            (payload, meta) =>
              loadAssetsRequest(payload, {
                ...meta,
                containersToFetch,
              }),
            (payload, meta) =>
              loadAssetsSuccess(payload, {
                ...meta,
                allSegmentIds,
              }),
            (payload, meta) =>
              loadAssetsFailure(payload, {
                ...meta,
                containersToFetch,
              }),
          ],
        },
      });
    }
  };
}

export const assetsReducer = createReducer(initialState, {
  [invalidateAssets]: invalidateSegmentedAssetContainers,
  [logInSuccess]: invalidateSegmentedAssetContainers,
  [signUpSuccess]: invalidateSegmentedAssetContainers,
  [loadSessionSuccess]: (state, action) => {
    if (action.meta.customerDidChange) {
      invalidateSegmentedAssetContainers(state);
    }
  },
  [updateMembershipStatusSuccess]: invalidateSegmentedAssetContainers,
  [setHighlightAssets]: (state, action) => {
    state.highlightAssets = action.payload;
  },
  [loadAssetsRequest]: (state, action) => {
    action.meta.containersToFetch.forEach((containerName) => {
      if (!state.containers[containerName]) {
        state.containers[containerName] = {
          fetchedDate: action.meta.fetchedDate,
          networkStatus: outOfDateStatus(),
        };
      }
      startLoading(state.containers[containerName]);
    });
  },
  [loadAssetsFailure]: (state, action) => {
    action.meta.containersToFetch.forEach((containerName) => {
      const asset = state.containers[containerName];
      if (asset) {
        // TODO: Detect different error types here?
        asset.error = AssetErrorType.OTHER;
        stopLoading(asset);
      }
    });
  },
  [loadAssetsSuccess]: (state, action) => {
    const { entities, fetchedDate, allSegmentIds } = action.meta;
    const { AssetContainer: assetContainers } = entities;

    if (assetContainers) {
      Object.keys(assetContainers).forEach((name) => {
        const container = assetContainers[name];
        let error = null;
        let isSegmented = true;

        if (container) {
          // If `isFiltered` is true, there's no reason to check
          // `segmentationIds` because we already know it's segmented, and the
          // `isSegmented` default above is already accurate.
          if (
            !container.isFiltered &&
            allSegmentIds &&
            container.segmentationIds
          ) {
            isSegmented = container.segmentationIds.some(
              (id) => !allSegmentIds.has(id)
            );
          }
        } else {
          error = AssetErrorType.NOT_FOUND;
          isSegmented = false;
        }

        state.containers[name] = {
          data: container,
          error,
          fetchedDate,
          isSegmented,
          networkStatus: upToDateStatus(),
        };
      });
    }
  },
});

export function invalidateSegmentedAssetContainers(state) {
  const detectSegmentation = config.get('public.assets.detectSegmentation');
  Object.keys(state.containers).forEach((name) => {
    const container = state.containers[name];
    if (!detectSegmentation || container.isSegmented) {
      container.networkStatus = outOfDateStatus();
    }
  });
}
/**
 * Used internally by `getAssetContainer` and `getAllAssetContainers` for more
 * efficient caching. Expects to be called just with the `assets` state.
 */
export const getCachedAssetContainer = createCachedSelector(
  [(assets, name) => assets.containers[name], (assets, name) => name],
  (container, name) => {
    if (container) {
      if (container.data) {
        const assetContainer = { name, ...container };
        // Give each asset a reference back to the container
        const assets = assetContainer.data.assets.map((asset) => ({
          ...asset,
          container: assetContainer,
        }));

        assetContainer.data = { ...assetContainer.data, assets };

        return assetContainer;
      }
      return { name, ...container };
    }
    return { name, fetchedDate: null, networkStatus: outOfDateStatus() };
  }
)((assets, name) => name);

export function getAssetContainer(state, name) {
  return getCachedAssetContainer(state.assets, name);
}

export const getAllAssetContainers = createSelector(
  [(state) => state.assets],
  (assets) => {
    const names = Object.keys(assets.containers);
    return names.map((name) => getCachedAssetContainer(assets, name));
  }
);

export default {
  id: 'assets',
  reducerMap: {
    assets: assetsReducer,
  },
  initialActions: [initAssets()],
  sagas: [],
};

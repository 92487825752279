import React from 'react';

import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { AdminGuard } from '../../../techstyle-shared/react-admin';
import {
  isTruthyQueryParameter,
  VisitorStatus,
} from '../../../techstyle-shared/redux-core';

import abTestModule, {
  setForceAllTestsControl,
  splitTest,
  clearTestsUsedOnPage,
  forceAllTestsToControl,
} from './abTestModule';
import logger from './logger';
import useActivatedABTests from './useActivatedABTests';

const debug = logger.extend('abTestExtension');

const ABTestAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './ABTestAdminPanel')
);

const ABTestExtensionWrapper = ({
  children,
  shouldUseActivatedTests = false,
}) => {
  // When `shouldUseActivatedTests` is true, this will make sure that the activated tests stays up to date
  // whenever the `activatedTests` network state is invalidated.
  useActivatedABTests(shouldUseActivatedTests);

  return (
    <>
      {children}
      <AdminGuard>
        <ABTestAdminPanel />
      </AdminGuard>
    </>
  );
};

ABTestExtensionWrapper.propTypes = {
  children: PropTypes.node,
  shouldUseActivatedTests: PropTypes.bool,
};

export default function abTestExtension({
  globalABTests,
  shouldUseActivatedTests,
} = {}) {
  return (registry) => ({
    id: 'abTest',
    redux: {
      modules: [abTestModule({ shouldUseActivatedTests })],
    },
    server: process.browser
      ? {}
      : {
          init() {
            require('./config');
          },
        },
    app: {
      getInitialProps: {
        enhance(getInitialProps) {
          return ({ Component, ctx }) => {
            ctx.store.dispatch(clearTestsUsedOnPage());
            return getInitialProps({ Component, ctx });
          };
        },
      },
      render(props, children) {
        return (
          <ABTestExtensionWrapper
            shouldUseActivatedTests={shouldUseActivatedTests}
          >
            {children}
          </ABTestExtensionWrapper>
        );
      },
    },
    page(options) {
      const { abTests } = options;

      const getABTests = registry.utils.getMergedArrayOption({
        globalABTests,
        abTests,
      });

      return {
        async getInitialProps(ctx) {
          const { pathname } = await ctx;

          if (ctx.isServer && !ctx.hasPreloadedState) {
            // Helper function to handle query parameters and cookies
            const handleQueryParamAndCookie = (paramName, cookieName) => {
              const paramValue = ctx.req.query[paramName];
              let isEnabled = isTruthyQueryParameter(paramValue);

              if (isEnabled) {
                ctx.req.universalCookies.set(cookieName, paramValue, {
                  secure: true,
                });
                debug(`Cookie ${cookieName} set to: %s`, paramValue);
              } else if (!ctx.req.context.anonymousServerSession) {
                // If using an anonymous server session, rely only on the query param
                // to enable the forced control setting; otherwise, read the cookie.
                isEnabled = isTruthyQueryParameter(
                  ctx.req.universalCookies.get(cookieName)
                );
              }

              return isEnabled;
            };

            // Check if the `test_framework_force_control` query parameter is enabled
            // This writes up in the Redux state only
            const forceAllControl = handleQueryParamAndCookie(
              'test_framework_force_control',
              'test_framework_force_control'
            );
            if (forceAllControl) {
              await ctx.store.dispatch(setForceAllTestsControl(true));
            }

            // Check if the `force_tests_control` query parameter is enabled
            // This writes up in the database that all tests should be forced to control
            const isForceAllTestToControlEnabled = handleQueryParamAndCookie(
              'force_tests_control',
              'force_tests_control'
            );
            if (isForceAllTestToControlEnabled) {
              await ctx.store.dispatch(forceAllTestsToControl());
              const { session } = ctx.store.getState();
              if (session?.visitorStatus === VisitorStatus.LOGGED_IN) {
                // If the user is logged in, remove the cookie to avoid overfiring the action on subsequent requests
                ctx.req.universalCookies.remove('force_tests_control');
              }
            }
          }

          const [abTests, meta] = await getABTests(ctx);

          if (abTests.length) {
            debug(
              'Prefetching %s %s added by %s.',
              abTests.length,
              abTests.length === 1 ? 'A/B test' : 'A/B tests',
              meta.sources.join(', ')
            );
            await Promise.all(
              abTests.map((campaignCode) => {
                return ctx.store.dispatch(
                  splitTest(campaignCode, { pathname })
                );
              })
            );
          }
        },
      };
    },
  });
}

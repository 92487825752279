import { createReducer } from '@reduxjs/toolkit';

import createAction from './createAction';

export const setLogging = createAction('logging/setLogging');

export const initialState = {
  errors: {},
};

export function initLogging() {
  return (dispatch, getState, context) => {
    if (context.isServer && !context.hasPreloadedState) {
      const { logging } = context.req.context;
      dispatch(setLogging(logging));
    }
  };
}

export const logRequest = createAction('logging/logRequest');
export const logSuccess = createAction('logging/logSuccess');
export const logFailure = createAction('logging/logFailure');

export function log(payload) {
  return async (dispatch) => {
    return dispatch({
      bentoApi: {
        method: 'POST',
        endpoint: `logging`,
        json: payload,
        actions: [logRequest, logSuccess, logFailure],
      },
    });
  };
}

export const logErrorReducer = createReducer(initialState.errors, {
  [logSuccess]: (state, action) => {},
  [logRequest]: (state, action) => {},
  [logFailure]: (state, action) => {},
});

export default {
  id: 'logging',
  reducerMap: {
    errors: logErrorReducer,
  },
  initialActions: [initLogging()],
};

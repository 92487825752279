// Temporary helper to get normalized filters
export const getNormalizedFilters = (filters) => {
  return {
    categoryIds: [],
    ...filters,
    aggregationFilter: {
      // Ensure every aggregation has an entry in `aggregationFilter`.
      ...filters.aggregations.reduce((filters, filterField) => {
        filters[filterField.field] = [];
        return filters;
      }, {}),
      ...filters.aggregationFilter,
    },
  };
};

export const getNormalizedFiltersCustomFilters = (
  filterSettings,
  initialFilters
) => {
  return {
    categoryIds: [],
    ...filterSettings.reduce(
      (filters, { getDefaultValue, field }) => {
        if (getDefaultValue) {
          filters = getDefaultValue({ filters });
        } else {
          filters.aggregationFilter[field] =
            filters.aggregationFilter[field] || [];
        }
        return filters;
      },
      // Make sure that we can still look for our fields if `initialFilters`
      // doesn't have an `aggregationFilter`.
      {
        aggregationFilter: {},
        ...initialFilters,
      }
    ),
  };
};

export const openCustomFilterDrawers = (
  toggleCollapsibleFilterIsOpen,
  filterSettings
) => {
  const toggleFilters = [...filterSettings.map((filter) => filter.field)];

  // size filter is not always present as 'size', but we need to open it as well
  if (!toggleFilters.includes('size')) {
    toggleFilters.push('size');
  }
  toggleCollapsibleFilterIsOpen({
    type: 'open_all',
    filterFields: toggleFilters,
  });
};

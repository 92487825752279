import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getCustomer } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useCustomer() {
  const actions = useAccountActions();
  const customer = useSelector(getCustomer);

  useEffect(() => {
    if (!customer.networkStatus.isUpToDate) {
      actions.loadProfile();
    }
  }, [actions, customer.networkStatus.isUpToDate]);

  return customer;
}

import React, { useMemo } from 'react';

import { BreakPoint } from '../types';
import useBreakpointSelector from '../useBreakpointSelector';

export type GridContextType = {
  breakpoint: BreakPoint | null;
  breakpoints: BreakPoint[];
};

const GridContext = React.createContext<GridContextType | undefined>(undefined);

export function useGridState() {
  return React.useContext(GridContext);
}

type GridProviderProps = {
  /**
   * An array of breakpoints, which should contain both the viewport properties
   * (e.g. `minWidth`) and the grid properties (e.g. `columns`, `gap`) for each
   * breakpoint.
   *
   * The array should be a constant or memoized, otherwise a render loop is
   * likely (because it must select a new breakpoint from the new array every
   * time).
   */
  breakpoints: BreakPoint[];
  /**
   * The breakpoint to choose during server-side rendering and when no others
   * match. You can pass an item from `breakpoints` here, or leave it empty to
   * easily indicate that the current breakpoint is unknown.
   */
  defaultBreakpoint?: BreakPoint;
};

/**
 * A component for managing and providing grid state (via context) without
 * actually rendering a grid. You can use this to render composite grids that
 * consist of multiple subgrids which will all get their state from this
 * provider.
 */
export default function GridProvider({
  breakpoints,
  children,
  defaultBreakpoint,
}: React.PropsWithChildren<GridProviderProps>) {
  const breakpoint = useBreakpointSelector(breakpoints, defaultBreakpoint);
  const state = useMemo(
    () => ({ breakpoint, breakpoints }),
    [breakpoint, breakpoints]
  );
  return <GridContext.Provider value={state}>{children}</GridContext.Provider>;
}

GridProvider.Context = GridContext;

import React from 'react';

import CatchLinks from './CatchLinks';
import createDynamicRoutesModule, {
  loadRewrites,
  getRouteInfo,
} from './dynamicRoutesModule';
import RoutesProvider from './RoutesProvider';

export default function dynamicRoutesExtension({
  getIsEnabled,
  refreshInterval,
  rewritesFilterList,
  routeTypePageMap = {},
  builderRoutePageMap = {},
  getIgnoreBuilderProductListingRoutes,
  getShouldUseRoutingByTld,
  handleRouteInfo,
} = {}) {
  return {
    id: 'dynamic-route',
    redux: {
      modules: [
        createDynamicRoutesModule({ routeTypePageMap, builderRoutePageMap }),
      ],
    },
    server: process.browser
      ? {}
      : {
          init() {
            require('./config');
          },
          configure(server) {
            const {
              default: dynamicRoutesMiddleware,
            } = require('./dynamicRoutesMiddleware');

            server.useTracked(
              'dynamicRoutesMiddleware',
              dynamicRoutesMiddleware({
                getIsEnabled,
                refreshInterval,
                rewritesFilterList,
                routeTypePageMap,
                builderRoutePageMap,
                getIgnoreBuilderProductListingRoutes,
                getShouldUseRoutingByTld,
              })
            );
          },
          handler({ handler, app, server }) {
            const {
              default: dynamicRouteHandler,
            } = require('./dynamicRouteHandler');
            return dynamicRouteHandler({ handler, app, server });
          },
        },
    app: {
      render(props, children) {
        return (
          <RoutesProvider>
            <CatchLinks>{children}</CatchLinks>
          </RoutesProvider>
        );
      },
    },
    page(options) {
      return {
        getInitialProps: {
          enhance(getInitialProps) {
            return async (ctx) => {
              if (process.browser) {
                if (options.routeInfoRequired) {
                  await ctx.store.dispatch(loadRewrites([ctx.asPath]));
                  const state = ctx.store.getState();
                  // TODO: 404 if it doesn't exist?
                  ctx.routeInfo = getRouteInfo(state, ctx.asPath);
                }
              } else if (ctx.req) {
                ctx.routeInfo = ctx.req.context.routeInfo;
              }

              if (handleRouteInfo && ctx.routeInfo) {
                const { routeInfo } = ctx;
                const handlerResponse = await handleRouteInfo({
                  ctx,
                  routeInfo,
                });

                ctx.routeInfo = {
                  ...ctx.routeInfo,
                  handlerResponse,
                };
              }

              return getInitialProps(ctx);
            };
          },
        },
      };
    },
  };
}

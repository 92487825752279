export default function slugify(str, separator = '_') {
  if (!str) {
    return str;
  }

  return str
    .toString()
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]+/gi, separator)
    .replace(/\//g, separator)
    .replace(/^_+|_+$/gi, '');
}

const gtPressuraStandard = 'GTPressura';
const gtPressuraExtended = 'GTPressura-Extended';
const playfairDisplay = 'Playfair-Display';

export const fonts = [
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 300,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Text',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 300,
    fontStyle: 'Italic',
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Text-Italic',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 400,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Regular',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 400,
    fontStyle: 'Italic',
    fontFilePath:
      '/static/fonts/gt-pressura/GT-Pressura-Standard-Regular-Italic',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 600,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Medium',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 600,
    fontStyle: 'Italic',
    fontFilePath:
      '/static/fonts/gt-pressura/GT-Pressura-Standard-Medium-Italic',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 700,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Bold',
  },
  {
    fontFamily: gtPressuraStandard,
    fontWeight: 700,
    fontStyle: 'Italic',
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Standard-Bold-Italic',
  },
  {
    fontFamily: gtPressuraExtended,
    fontWeight: 400,
    fontFilePath: '/static/fonts/gt-pressura/GT-Pressura-Extended-Regular',
  },
  {
    fontFamily: gtPressuraExtended,
    fontWeight: 400,
    fontStyle: 'Italic',
    fontFilePath:
      '/static/fonts/gt-pressura/GT-Pressura-Extended-Regular-Italic',
  },
];

const stacks = {
  baseFamily: `${gtPressuraStandard}, sans-serif`,
  textFamily: `${gtPressuraStandard}, sans-serif`,
  tempFamily: `sans-serif`,
};

const gtPressuraFamily = {
  standard: `${stacks.baseFamily}`,
  extended: `${gtPressuraExtended}, ${stacks.baseFamily}`,
};

export const fontStacks = {
  ...stacks,
  alternateFamily: `${stacks.baseFamily}`,

  standardGtPressuraFamily: `${gtPressuraFamily.standard}`,
  extendedGtPressuraFamily: `${gtPressuraFamily.extended}`,
  tempFamily: `${playfairDisplay}, ${stacks.tempFamily}`,

  navBarFamily: stacks.textFamily,
  vipHomepageFamily: `${gtPressuraFamily.standard}`,
  condensedVipHomepageFamily: `${gtPressuraFamily.standard}`,
};

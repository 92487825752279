import React from 'react';

import PropTypes from 'prop-types';

import { Component as NavItem } from '../NavItem';
import useNav from '../useNav';

export default function NavContainer({
  children,
  className,
  filter,
  handle,
  gaCategory,
  gaAction,
  ...rest
}) {
  if (!handle) {
    throw new Error(
      `NavContainer requires a nav handle but was given ${JSON.stringify(
        handle
      )}.`
    );
  }

  const nav = useNav(handle, { filter });

  gaCategory = gaCategory || 'navigation';
  if (!gaAction) {
    if (nav.data && nav.data.navSettings.gaAction) {
      gaAction = nav.data.navSettings.gaAction;
    } else {
      gaAction = handle;
    }
  }

  const props = { gaCategory, gaAction };

  if (typeof children === 'function') {
    const meta = { className, handle };
    const status = {
      data: nav.data,
      error: nav.error,
      networkStatus: nav.networkStatus,
      meta,
      props,
    };
    return children(status);
  }

  if (!nav.data) {
    return null;
  }

  return (
    <nav data-nav-handle={handle} className={className}>
      <ul>
        {nav.data.navItems
          ? nav.data.navItems.map((item) => {
              return <NavItem data={item} key={item.id} props={props} />;
            })
          : null}
      </ul>
    </nav>
  );
}

NavContainer.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  /**
   * A function for recursively filtering nav items. The function will be passed
   * the parent filter function in order to support nested filtering, and should
   * return a function to pass to a `navItems` array's `filter` method.
   */
  filter: PropTypes.func,
  gaAction: PropTypes.string,
  gaCategory: PropTypes.string,
  handle: PropTypes.string.isRequired,
};

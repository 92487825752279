import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getMembership } from '../../../techstyle-shared/react-accounts';
import {
  getDateNowFunction,
  useDomain,
  useSession,
  createSelector,
  parseDate,
} from '../../../techstyle-shared/redux-core';

import usePromoActions from './usePromoActions';

export const getMemberJoinDate = createSelector(
  (state) => getMembership(state).dateTimeAdded,
  (dateTimeAdded) => (dateTimeAdded ? parseDate(dateTimeAdded) : null)
);

const oneHour = 60 * 60 * 1000;

function OneHourPromosExpire() {
  const { isLoggedIn } = useSession();
  const { tld } = useDomain();
  const memberJoinDate = useSelector(getMemberJoinDate);
  const getDateNow = useSelector(getDateNowFunction);
  const actions = usePromoActions();

  // Refresh everything promo-related after user is registered for 1 hour
  useEffect(() => {
    // This feature is for UK logged in users only
    if (tld !== '.co.uk' || !isLoggedIn || !memberJoinDate) {
      return;
    }

    // Ideally we'd just get `products` fresh from the API to update promos, but if the user
    // has been browsing for 1 hour there is a good chance they have 100s of products
    // in redux, as well as many products that came from the `recommended` endpoint,
    // and we have no way of knowing which products they're currently viewing.
    // Requesting ALL of these products from the API requires a huge payload so
    // ultimately it is more efficient to just reload the current page.
    const now = getDateNow();
    const endDate = memberJoinDate.getTime() + oneHour;
    const isExpired = now > endDate;

    if (!isExpired) {
      const oneHourAfterRegistrationTimer = setTimeout(async () => {
        await actions.checkMembershipPromos({ forceCheck: true });
        document.location.reload();
      }, endDate - now);

      return () => clearTimeout(oneHourAfterRegistrationTimer);
    }
  }, [isLoggedIn, tld, memberJoinDate, getDateNow, actions]);

  return null;
}

export default OneHourPromosExpire;

import React from 'react';

// eslint-disable-next-line node/no-unpublished-import
import { AutoIdProvider as SvgIdProvider } from '@techstyle/babel-plugin-inline-react-svg/auto-id';

import {
  GlobalStyle,
  ResponsiveViewport,
  AppElement,
  AutoIdProvider,
  StickyProvider,
  styledComponentsExtension as baseStyledComponentsExtension,
} from '../techstyle-shared/react-components';

// ThemeProvider is passed through storybook addon/contexts - see context.storybook.js
// eslint-disable-next-line react/prop-types
const OmitThemeProvider = (props, children) => {
  return (
    <>
      <AutoIdProvider>
        <SvgIdProvider>
          <StickyProvider>
            <AppElement>
              {
                // We could make this an option (or get viewport settings from
                // the theme) later, but for now everyone gets the same
                // reasonable default viewport behavior.
              }
              <ResponsiveViewport />
              <GlobalStyle />
              {children}
            </AppElement>
          </StickyProvider>
        </SvgIdProvider>
      </AutoIdProvider>
    </>
  );
};

const baseExtension = baseStyledComponentsExtension();
baseExtension.app.render = OmitThemeProvider;
export default baseExtension;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import {
  ProductType,
  getProductAvailabilityForUser,
} from '../../../../techstyle-shared/react-products';
import {
  createReducer,
  createAction,
} from '../../../../techstyle-shared/redux-core';
import logger from '../logger';
import ProductContext from '../ProductContext';
import { isStandardSelectorVariation, getActiveSizes } from '../utils/sizes';

import ProductDetailContext from './ProductDetailContext';
import { AttributeFieldTypes } from './useProductAttributeFieldType';

const debug = logger.extend('ProductDetail');

const initialProductSelectorState = {
  activeSizes: null,
  attributeFieldTypesByProductIndex: {},
  product: null,
  bundleProducts: null,
  selectedAttributesByProductIndex: {},
  allowInvalidOptionSelection: false,
};

const initializeProduct = createAction('initializeProduct');
const updateBundleProduct = createAction('updateBundleProduct');
const selectAttribute = createAction('selectAttribute');

export const ProductAttributeField = {
  COLOR: 'Color',
  SIZE: 'Size',
  INSEAM: 'Inseam',
  LINING: 'Lining',
};

// return null to NOT pre-select
const defaultGetPreselectAttributeIndex = () => null;

/**
 * Loops through available attribute options and attempts to preselect
 * value based on the product information, default size information, and
 * getPreselectAttributeIndex override function
 */
const getPreselectedAttributes = (
  { masterProductId, skus = [], attributes },
  selectedOptions,
  activeSizes,
  attributeFieldTypes,
  getPreselectAttributeIndex = defaultGetPreselectAttributeIndex
) => {
  const otherFields = attributeFieldTypes[AttributeFieldTypes.OTHER] ?? [];
  let selectedAttributes;
  const attributeList = [];
  const selectedOptionsByField = {};

  skus.forEach((singleSKU) => {
    if (singleSKU.masterProductId === masterProductId) {
      const { options } = singleSKU;
      // If shared attributes are initialized then check for common attributes
      // An attribute should only be preselected if all SKUs of a `masterProductId` are common.
      if (selectedAttributes) {
        options.forEach((singleOption) => {
          const { attribute, value } = singleOption;
          const currentValue = selectedAttributes[attribute];
          if (currentValue !== null && currentValue !== value) {
            delete selectedAttributes[attribute];
          }
        });
      } else {
        // Initialize shared attributes
        selectedAttributes = {};
        options.forEach((singleOption) => {
          const { attribute, value } = singleOption;
          selectedAttributes[attribute] = value;
          attributeList.push(attribute);
        });
      }
    }
  });

  // Loop over the attribute list for the current product
  // If an attribute is present in `selectedOptions`, and is not currently in `selectedAttributes` it can be added as a `selectedAttribute`.
  if (selectedOptions || activeSizes) {
    attributeList.forEach((singleAttribute) => {
      if (!selectedAttributes[singleAttribute]) {
        const selectedOption = selectedOptions?.[singleAttribute];
        const selectedSize = activeSizes?.[singleAttribute];
        // Already selected options are preferred, on initialization selectedSize will likely be used
        const selectedValue = selectedOption?.value ?? selectedSize;

        if (selectedValue) {
          const attribute = attributes.find(
            ({ field }) => field === singleAttribute
          );
          if (attribute) {
            const option = attribute.options.find(
              ({ value }) => value === selectedValue
            );

            // If the exact `value is found` use that, else check if the value exists in aliases
            if (option) {
              selectedAttributes[singleAttribute] = option.value;
            } else {
              const optionFromAlias = attribute.options.find(({ alias }) =>
                alias?.some((value) => value === selectedValue)
              );

              if (optionFromAlias) {
                selectedAttributes[singleAttribute] = optionFromAlias.value;
              }
            }
          }
        } else if (otherFields.includes(singleAttribute)) {
          // If an "other" attribute, select "Regular" by default, if "Regular" does not exist, select the first one in option list
          const attribute = attributes.find(
            ({ field }) => field === singleAttribute
          );

          if (attribute) {
            // Different countries use differnt variations of the 'Regular' option, i.e., men's pants inseam sizes
            // These options come back from the API translated according to the country/domain
            // Those options are encapsulated in the following conditional passed to the .find() invocation where we search for options that include any of those 'Regular' variantions
            const option =
              attribute.options.find(({ label, value }) => {
                if (isStandardSelectorVariation(value)) {
                  return true;
                }
                return isStandardSelectorVariation(label);
              }) ?? attribute.options[0];

            selectedAttributes[singleAttribute] = option?.value;

            // Force selecting a specific option if consumer provides a `getPreselectAttributeIndex`
            // function that returns a value.
            const overrideIndex = getPreselectAttributeIndex(attribute);
            if (overrideIndex !== null && attribute.options?.[overrideIndex]) {
              selectedAttributes[singleAttribute] =
                attribute.options[overrideIndex].value;
            }
          }
        }
      }
    });
  }

  // Retrieve the option for each attribute, this includes additional data. e.g. `label`
  if (selectedAttributes) {
    attributes.forEach(({ field, options }) => {
      const attributeValue = selectedAttributes[field];
      if (attributeValue) {
        const selectedOption = options.find(
          ({ value }) => attributeValue === value
        );
        if (selectedOption) {
          selectedOptionsByField[field] = selectedOption;
        }
      }
    });
  }

  return selectedOptionsByField;
};

/**
 * Handles reading the attributes associated to a product categorizing
 * them into predetermined fields.
 */
const getAttributeFieldTypes = ({ attributes = [] }) => {
  const attributeFieldTypes = {};

  attributes.forEach(({ field }) => {
    if (field === 'Color') {
      attributeFieldTypes[AttributeFieldTypes.COLOR] = field;
    } else if (field.toLowerCase().startsWith('size')) {
      attributeFieldTypes[AttributeFieldTypes.SIZE] = field;
    } else {
      if (attributeFieldTypes[AttributeFieldTypes.OTHER]) {
        attributeFieldTypes[AttributeFieldTypes.OTHER].push(field);
      } else {
        attributeFieldTypes[AttributeFieldTypes.OTHER] = [field];
      }
    }
  });

  return attributeFieldTypes;
};

/**
 * Handles generating the initial state and marking the preselecdted
 * attributes for products
 */
const getInitialProductSelectorState =
  (state) =>
  ({
    product,
    activeSizes,
    preSelectedBundleItemSizes,
    getPreselectAttributeIndex,
  }) => {
    const {
      bundleProducts: bundleProductsFromState,
      product: productFromState,
    } = state;
    const { bundleComponentProducts } = product;
    const selectedAttributesByProductIndex = {};
    const attributeFieldTypesByProductIndex = {};
    const preSelectedBundleItemSizesWithMatchFound = {
      ...preSelectedBundleItemSizes,
    };

    let bundleProducts = null;

    if (bundleComponentProducts) {
      if (
        product.permalink === productFromState?.permalink &&
        bundleProductsFromState?.length > 0
      ) {
        bundleProducts = [...bundleProductsFromState];
      } else {
        bundleProducts = [...bundleComponentProducts];
      }

      bundleProducts.forEach((singleProduct, index) => {
        const attributeFieldTypes = getAttributeFieldTypes(singleProduct);
        const productId = singleProduct.masterProductId;
        selectedAttributesByProductIndex[index] = getPreselectedAttributes(
          singleProduct,
          state.selectedAttributesByProductIndex?.[index],
          preSelectedBundleItemSizes[productId]
            ? preSelectedBundleItemSizes[productId]
            : activeSizes,
          attributeFieldTypes,
          getPreselectAttributeIndex
        );

        // Mark the size item as matchFound so that we don't re-iterate over the skus of the same item
        // while finding the correct product with different permalink which was selected and added to cart in checkout.
        if (
          preSelectedBundleItemSizes[productId] &&
          !preSelectedBundleItemSizesWithMatchFound[productId].matchFound
        ) {
          preSelectedBundleItemSizesWithMatchFound[productId].matchFound = true;
        }
        attributeFieldTypesByProductIndex[index] = attributeFieldTypes;
      });
      return {
        ...state,
        activeSizes,
        product,
        bundleProducts,
        selectedAttributesByProductIndex,
        attributeFieldTypesByProductIndex,
        preSelectedBundleItemSizesWithMatchFound,
      };
    }

    const attributeFieldTypes = getAttributeFieldTypes(product);

    return {
      ...state,
      activeSizes,
      product,
      bundleProducts,
      selectedAttributesByProductIndex: {
        0: getPreselectedAttributes(
          product,
          state.selectedAttributesByProductIndex?.['0'],
          activeSizes,
          attributeFieldTypes,
          getPreselectAttributeIndex
        ),
      },
      attributeFieldTypesByProductIndex: {
        0: attributeFieldTypes,
      },
    };
  };

const productSelectorStateReducer = createReducer(initialProductSelectorState, {
  [initializeProduct]: (state, action) => {
    const {
      product,
      activeSizes,
      preSelectedBundleItemSizes,
      getPreselectAttributeIndex,
    } = action;

    if (state.product === product && state.activeSizes === activeSizes) {
      debug('Skipping getInitialProductSelectorState');
      return state;
    }
    return getInitialProductSelectorState(state)({
      product,
      activeSizes,
      preSelectedBundleItemSizes,
      getPreselectAttributeIndex,
    });
  },
  [updateBundleProduct]: (state, action) => {
    const {
      product,
      productIndex,
      preSelectedBundleItemSize,
      getPreselectAttributeIndex,
    } = action;
    const attributeFieldTypes = getAttributeFieldTypes(product);
    state.bundleProducts[productIndex] = {
      ...state.bundleProducts[productIndex],
      ...product,
    };
    state.selectedAttributesByProductIndex[productIndex] =
      getPreselectedAttributes(
        product,
        state.selectedAttributesByProductIndex?.[productIndex],
        preSelectedBundleItemSize || state.activeSizes,
        attributeFieldTypes,
        getPreselectAttributeIndex
      );
    state.attributeFieldTypesByProductIndex[productIndex] = attributeFieldTypes;
  },
  [selectAttribute]: (state, action) => {
    const { field, option, productIndex } = action;

    if (option == null) {
      // It's being deselected completely.
      delete state.selectedAttributesByProductIndex[productIndex][field];
    } else {
      state.selectedAttributesByProductIndex[productIndex][field] = option;
    }

    // If there is a selected attribute that causes there to be no available SKUs, remove it.
    // This can happen on products where only _some_ SKUs have an "Other" attribute (e.g. "Inseam")
    let potentialSKUs = state.bundleProducts
      ? state.bundleProducts[productIndex].skus
      : state.product.skus;

    for (const singleAttribute in state.selectedAttributesByProductIndex[
      productIndex
    ]) {
      const filteredSKUs = potentialSKUs.filter((sku) => {
        const { options } = sku;
        const selectedOption = options.find(
          ({ attribute }) => attribute === singleAttribute
        );
        if (
          selectedOption?.value ===
          state.selectedAttributesByProductIndex[productIndex][singleAttribute]
            .value
        ) {
          return true;
        }
        return false;
      });

      if (filteredSKUs.length === 0 && !state.allowInvalidOptionSelection) {
        delete state.selectedAttributesByProductIndex[productIndex][
          singleAttribute
        ];
      } else {
        potentialSKUs = filteredSKUs;
      }
    }
  },
});

// Returns the current SKUs with an additional field `optionValuesByAttribute`
// Formatted options is an object that represents the array of options
const getSKUsWithOptions = ({ skus = [], currentUserStatus }) => {
  return skus.map((singleSKU) => {
    const optionValuesByAttribute = {};
    singleSKU.options.forEach((singleOption) => {
      const { attribute, value } = singleOption;
      optionValuesByAttribute[attribute] = value;
    });

    let isProductAvailableForUser = true;
    if (singleSKU.requiredUserStatus && currentUserStatus) {
      isProductAvailableForUser = getProductAvailabilityForUser({
        currentUserStatus,
        requiredUserStatus: singleSKU.requiredUserStatus,
      });
    }

    return {
      ...singleSKU,
      optionValuesByAttribute,
      isProductAvailableForUser,
    };
  });
};

// When there are duplicate colors for a product we need a way to determine
// which SKU to prefer.
const getPreferredSKU = ({ skus, isProductAvailableForUser, permalink }) => {
  const potentialSKUs = [...skus];

  // Sort by `masterProductId` so that we prefer older products first
  potentialSKUs.sort((a, b) => a.masterProductId - b.masterProductId);

  // Filter out out of stock SKUs
  let inStockSKUs = potentialSKUs.filter(
    ({ availableQuantity, isPreorder, availableQuantityPreorder }) =>
      availableQuantity > 0 || (isPreorder && availableQuantityPreorder > 0)
  );

  // If there are multiple in stock SKUs we will filter out the current
  // permalinks SKUs when it is not available to the user.
  // Note: It's still possible that this "preferred" SKU won't be available to the user
  // It's not known until the new product data is used.
  if (inStockSKUs.length > 1 && !isProductAvailableForUser) {
    inStockSKUs = inStockSKUs.filter(
      ({ permalink: skuPermalink }) => permalink !== skuPermalink
    );
  }

  return inStockSKUs.length > 1 ? potentialSKUs[0] : inStockSKUs[0];
};

export const getMatchingSKUsForSelectedAttributes = (
  skus,
  selectedAttributes
) => {
  const skusWithOptions = getSKUsWithOptions({ skus });
  return skusWithOptions.filter((sku) => {
    return Object.entries(sku.optionValuesByAttribute).every(
      ([field, value]) => {
        return (
          !selectedAttributes[field] ||
          selectedAttributes[field].value === value
        );
      }
    );
  });
};

const getSelectedPermalink = ({
  selectedAttributes,
  skus,
  permalink,
  isProductAvailableForUser,
}) => {
  const matchingSKUs = getMatchingSKUsForSelectedAttributes(
    skus,
    selectedAttributes
  );

  const matchingSKU = getPreferredSKU({
    skus: matchingSKUs,
    permalink,
    isProductAvailableForUser,
  });

  return matchingSKU?.permalink ?? permalink;
};

const getHiddenAttributes = ({ attributes, selectedAttributes, skus = [] }) => {
  const [potentialSKU] = getMatchingSKUsForSelectedAttributes(
    skus,
    selectedAttributes
  );

  if (potentialSKU) {
    if (attributes.length === potentialSKU.options.length) {
      return [];
    } else {
      const hiddenAttributes = new Set();

      attributes.forEach(({ field }) => {
        hiddenAttributes.add(field);
      });

      potentialSKU.options.forEach(({ attribute }) => {
        hiddenAttributes.delete(attribute);
      });

      return [...hiddenAttributes];
    }
  }

  return [];
};

const getAvailableSwatchTypes = (product) => {
  const swatchTypes = [];
  product.skus.forEach((sku) => {
    if (sku.swatchType !== undefined && !swatchTypes.includes(sku.swatchType)) {
      swatchTypes.push(sku.swatchType);
    }
  });
  return swatchTypes;
};

const getSelectedSKU = ({
  selectedAttributes,
  attributes,
  skus,
  permalink,
  isProductAvailableForUser,
}) => {
  // A matching SKU is determined if _all_ selected attributes match the options in
  // a SKU
  const potentialSKUs = skus.filter((singleSKU) => {
    return Object.entries(singleSKU.optionValuesByAttribute).every(
      ([field, value]) => {
        return selectedAttributes[field]?.value === value;
      }
    );
  });

  if (potentialSKUs.length > 0) {
    if (potentialSKUs.length === 1) {
      return potentialSKUs[0];
    } else {
      return getPreferredSKU({
        skus: potentialSKUs,
        permalink,
        isProductAvailableForUser,
      });
    }
  }
  return null;
};

const defaultGetIsPlusSize = () => false;

const defaultGetIsForcedSoldOut = () => false;

const defaultUserStatus = {
  membershipLevelGroup: 'visitor',
};
const defaultPreSelectedBundleItemSizes = {};

/**
 * Product Detail Component
 *
 * Takes in combination of information about a particular product and
 * current user and assembles a Context with this information useful for
 * other components to render product information.
 */
export default function ProductDetail({
  children,
  currentUserStatus = defaultUserStatus,
  product: productFromProps,
  onPermalinkChange,
  onColorChange,
  profileSizes,
  sessionSizes,
  preSelectedBundleItemSizes = defaultPreSelectedBundleItemSizes,
  getIsPlusSize = defaultGetIsPlusSize,
  getIsForcedSoldOut = defaultGetIsForcedSoldOut,
  isQuickView = false,
  isQuickViewPage = false,
  getPreselectAttributeIndex = defaultGetPreselectAttributeIndex,
  enableBundleItemMembershipFilter = false,
  allowInvalidOptionSelection,
}) {
  const activeSizes = useMemo(() => {
    // No activeSizes should be returned in sessionSizes/profileSizes is not fully available
    if (sessionSizes === false || profileSizes === false) {
      return null;
    }
    return getActiveSizes(profileSizes, sessionSizes);
  }, [profileSizes, sessionSizes]);

  const [
    {
      product,
      bundleProducts,
      selectedAttributesByProductIndex,
      attributeFieldTypesByProductIndex,
      preSelectedBundleItemSizesWithMatchFound,
    },
    dispatch,
  ] = useReducer(
    productSelectorStateReducer,
    initialProductSelectorState,
    (initialState) => ({
      ...getInitialProductSelectorState(initialState)({
        product: productFromProps,
        activeSizes,
        preSelectedBundleItemSizes,
        getPreselectAttributeIndex,
      }),
      allowInvalidOptionSelection,
    })
  );

  useEffect(() => {
    if (currentUserStatus === defaultUserStatus) {
      debug(
        'Using default visitor status; pass currentUserStatus if you want accurate product availability.'
      );
    }
  }, [currentUserStatus]);

  const isProductAvailableForUser = useMemo(() => {
    return getProductAvailabilityForUser({
      currentUserStatus,
      requiredUserStatus: product.requiredUserStatus,
    });
  }, [currentUserStatus, product]);

  const skusByProductIndex = useMemo(() => {
    if (bundleProducts) {
      const skus = bundleProducts.map((singleProduct, index) => {
        return getSKUsWithOptions({
          skus: singleProduct.skus,
          currentUserStatus,
        });
      });
      return skus;
    }

    return [
      getSKUsWithOptions({
        skus: product.skus,
        currentUserStatus,
      }),
    ];
  }, [bundleProducts, product, currentUserStatus]);

  const selectedSKUByProductIndex = useMemo(() => {
    if (bundleProducts) {
      const skus = bundleProducts.map((singleProduct, index) =>
        getSelectedSKU({
          selectedAttributes: selectedAttributesByProductIndex[index] ?? {},
          skus: skusByProductIndex[index],
          attributes: singleProduct.attributes,
          isProductAvailableForUser,
        })
      );
      return skus;
    }

    return [
      getSelectedSKU({
        selectedAttributes: selectedAttributesByProductIndex[0],
        skus: skusByProductIndex[0],
        attributes: product.attributes,
        permalink: product.permalink,
        isProductAvailableForUser,
      }),
    ];
  }, [
    bundleProducts,
    isProductAvailableForUser,
    product.attributes,
    product.permalink,
    selectedAttributesByProductIndex,
    skusByProductIndex,
  ]);

  const isBundle = useMemo(
    () =>
      product.typeId === ProductType.BUNDLE ||
      product.productTypeId === ProductType.BUNDLE,
    [product]
  );

  const { isPlusSizeProduct, isPlusSizeProductByProductIndex } = useMemo(() => {
    // Used to determine in a bundle is plus size
    let plusSizeProduct = false;
    const plusSizeProductByProductIndex = {};
    const products = isBundle ? bundleProducts : [product];

    // Find the size attribute of a product, if it exists determine if all sizes
    // are plus sizes
    products.forEach((singleProduct, index) => {
      const sizeField =
        attributeFieldTypesByProductIndex[index][AttributeFieldTypes.SIZE];
      const selectedAttribute = singleProduct.attributes.find(
        (singleAttribute) => singleAttribute.field === sizeField
      );

      if (selectedAttribute) {
        plusSizeProductByProductIndex[index] = selectedAttribute.options.every(
          (singleAttribute) => getIsPlusSize(singleAttribute)
        );

        // If any product is a plus size product the outfit is plus size
        // This matches the image behavior in outfits, where if a single
        // plus size is selected the plus size images are used.
        if (!plusSizeProduct && plusSizeProductByProductIndex[index]) {
          plusSizeProduct = true;
        }
      }
    });

    return {
      isPlusSizeProduct: plusSizeProduct,
      isPlusSizeProductByProductIndex: plusSizeProductByProductIndex,
    };
  }, [
    attributeFieldTypesByProductIndex,
    bundleProducts,
    getIsPlusSize,
    isBundle,
    product,
  ]);

  const { isPlusSizeSelected, isPlusSizeSelectedByProductIndex } =
    useMemo(() => {
      // If any items in a bundle have a plus size selected, `isPlusSize` is true
      let plusSizeSelected = isPlusSizeProduct;
      // Used to track which items have plus size selected
      const plusSizeSelectedByProductIndex = {};

      Object.entries(selectedAttributesByProductIndex).forEach(
        ([productIndex, selectedAttributes]) => {
          if (isPlusSizeProductByProductIndex[productIndex]) {
            plusSizeSelectedByProductIndex[productIndex] = true;
          } else {
            const sizeField =
              attributeFieldTypesByProductIndex[productIndex][
                AttributeFieldTypes.SIZE
              ];
            const selectedOption = selectedAttributes[sizeField];

            if (selectedOption) {
              const isPlusSizeValue = selectedOption.value
                ? getIsPlusSize(selectedOption, sizeField)
                : false;
              plusSizeSelectedByProductIndex[productIndex] = isPlusSizeValue;

              if (!plusSizeSelected) {
                plusSizeSelected = isPlusSizeValue;
              }
            } else {
              plusSizeSelectedByProductIndex[productIndex] = false;
            }
          }
        }
      );

      return {
        isPlusSizeSelected: plusSizeSelected,
        isPlusSizeSelectedByProductIndex: plusSizeSelectedByProductIndex,
      };
    }, [
      attributeFieldTypesByProductIndex,
      getIsPlusSize,
      isPlusSizeProduct,
      isPlusSizeProductByProductIndex,
      selectedAttributesByProductIndex,
    ]);

  const isForcedSoldOut = useMemo(() => {
    return getIsForcedSoldOut(product);
  }, [getIsForcedSoldOut, product]);

  const permalink = useMemo(() => {
    // Permalinks for bundles should not change.
    if (isBundle) {
      return product.permalink;
    }

    // The current permalink for individual products is dependent on selected attributes
    return getSelectedPermalink({
      selectedAttributes: selectedAttributesByProductIndex[0],
      skus: skusByProductIndex[0],
      permalink: product.permalink,
      isProductAvailableForUser,
    });
  }, [
    selectedAttributesByProductIndex,
    skusByProductIndex,
    isBundle,
    product,
    isProductAvailableForUser,
  ]);

  const selectedPermalinkByProductIndex = useMemo(() => {
    if (bundleProducts) {
      const permalinks = bundleProducts.map((singleProduct, index) => {
        return getSelectedPermalink({
          selectedAttributes: selectedAttributesByProductIndex[index],
          skus: skusByProductIndex[index],
          permalink: singleProduct.permalink,
          isProductAvailableForUser,
        });
      });
      return permalinks;
    }

    return [
      getSelectedPermalink({
        selectedAttributes: selectedAttributesByProductIndex[0],
        skus: skusByProductIndex[0],
        permalink: product.permalink,
        isProductAvailableForUser,
      }),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bundleProducts,
    product,
    selectedAttributesByProductIndex,
    skusByProductIndex,
  ]);

  const isOutOfStockByProductIndex = useMemo(() => {
    return skusByProductIndex.map((skus) =>
      skus.every((sku) => sku.availableQuantity <= 0)
    );
  }, [skusByProductIndex]);

  const isOutOfStock = useMemo(() => {
    return isOutOfStockByProductIndex.every(
      (outOfStockStatus) => outOfStockStatus
    );
  }, [isOutOfStockByProductIndex]);

  const isPreOrderOutOfStockByProductIndex = useMemo(() => {
    return skusByProductIndex.map((skus) =>
      skus.every((sku) => sku.availableQuantityPreorder <= 0)
    );
  }, [skusByProductIndex]);

  const isPreOrderOutOfStock = useMemo(() => {
    return isPreOrderOutOfStockByProductIndex.every(
      (outOfStockStatus) => outOfStockStatus
    );
  }, [isPreOrderOutOfStockByProductIndex]);

  const hiddenAttributesByProductIndex = useMemo(() => {
    if (bundleProducts) {
      const hiddenAttributes = bundleProducts.map((singleProduct, index) => {
        return getHiddenAttributes({
          skus: singleProduct.skus,
          selectedAttributes: selectedAttributesByProductIndex[index],
          attributes: singleProduct.attributes,
        });
      });
      return hiddenAttributes;
    }
    return [
      getHiddenAttributes({
        skus: product.skus,
        selectedAttributes: selectedAttributesByProductIndex[0],
        attributes: product.attributes,
      }),
    ];
  }, [bundleProducts, product, selectedAttributesByProductIndex]);

  const swatchTypesAvailableByProductIndex = useMemo(() => {
    if (bundleProducts) {
      const swatchTypes = bundleProducts.map((singleProduct, index) => {
        return getAvailableSwatchTypes(singleProduct);
      });
      return swatchTypes;
    }
    return [getAvailableSwatchTypes(product)];
  }, [bundleProducts, product]);

  const selectionStateByProductIndex = useMemo(() => {
    const length = isBundle ? bundleProducts?.length : 1;
    const selectionState = [...Array(length)].map((value, index) => ({
      skus: skusByProductIndex[index],
      selectedSKU: selectedSKUByProductIndex[index],
      selectedAttributes: selectedAttributesByProductIndex[index],
      attributeFieldTypes: attributeFieldTypesByProductIndex[index],
      selectedPermalink: selectedPermalinkByProductIndex[index],
      isPlusSizeSelected: isPlusSizeSelectedByProductIndex[index],
      isPlusSizeProduct: isPlusSizeProductByProductIndex[index],
      hasPermalinkMismatch: isBundle
        ? bundleProducts[index].permalink !==
          selectedPermalinkByProductIndex[index]
        : product.permalink !== selectedPermalinkByProductIndex[index],
      productIndex: index,
      isOutOfStock: isOutOfStockByProductIndex[index],
      isPreOrderOutOfStock: isPreOrderOutOfStockByProductIndex[index],
      hiddenAttributes: hiddenAttributesByProductIndex[index],
      swatchTypesAvailable: swatchTypesAvailableByProductIndex[index],
    }));

    return selectionState;
  }, [
    isBundle,
    bundleProducts,
    skusByProductIndex,
    selectedSKUByProductIndex,
    selectedAttributesByProductIndex,
    attributeFieldTypesByProductIndex,
    selectedPermalinkByProductIndex,
    isPlusSizeSelectedByProductIndex,
    isPlusSizeProductByProductIndex,
    product.permalink,
    isOutOfStockByProductIndex,
    isPreOrderOutOfStockByProductIndex,
    hiddenAttributesByProductIndex,
    swatchTypesAvailableByProductIndex,
  ]);

  const handleUpdateBundleProduct = useCallback(
    ({ product, productIndex, preSelectedBundleItemSize }) => {
      dispatch({
        type: updateBundleProduct,
        product,
        productIndex,
        preSelectedBundleItemSize,
      });
    },
    []
  );

  // state management for quantity selection => provides quantity for upcoming ATB functionality
  const [itemQuantity, setItemQuantity] = useState(1);

  const onQuantityChange = useCallback(
    (newQuantity) => setItemQuantity(newQuantity),
    [setItemQuantity]
  );

  const resetQuantity = useCallback(
    () => setItemQuantity(1),
    [setItemQuantity]
  );

  const getRelatedSKUs = ({ field, option, product }) => {
    const selectedAttributes = {
      [field]: option,
    };
    return getMatchingSKUsForSelectedAttributes(
      product.skus,
      selectedAttributes
    );
  };

  const handleOnSelectorChange = useCallback(
    ({ attribute: { field }, option, product }) => {
      const { productIndex } = product;

      dispatch({
        type: selectAttribute,
        field,
        option,
        productIndex,
      });

      if (onColorChange && field === ProductAttributeField.COLOR) {
        onColorChange({
          option,
          product,
          selectionStateByProductIndex,
          relatedSKUs: getRelatedSKUs({
            field,
            option,
            product,
          }),
        });
      }
    },
    [onColorChange, selectionStateByProductIndex]
  );

  /**
   * Context value for ProductDetailContext
   *
   * @returns {
   *  product - Product for which details generated for
   *  bundleProducts - if product is a bundle, this will be array of bundle components, null otherwise
   *  permalink - permalink for the product
   *  isBundle - boolean if product is a bundle
   *  onSelectorChange - callback for when new product options are selected
   *  updateBundleProduct - function for updating a bundle component product
   *  isPlusSizeSelected - boolean for if any product items are plus sized
   *  selectionStateByProductIndex - holds product selections
   *  itemQuantity - number, quantity of product
   *  onQuantityChange - callback to handle adjusting the quantity
   *  isProductAvailableForUser - boolean, if product is available
   *  isForcedSoldOut - boolean, if product is being forced Sold Out
   *  isQuickView - boolean, is the detail context being used in a Quick View instance
   *  isQuickViewPage - boolean, is the detail context being used in a Quick View page
   *  preSelectedBundleItemSizesWithMatchFound - items in bundle that have pre-supplied selections
   *  isOutOfStock - boolean, if item out of stock.
   * }
   */
  const productDetailValue = useMemo(
    () => ({
      product,
      bundleProducts,
      enableBundleItemMembershipFilter,
      permalink,
      isBundle,
      onSelectorChange: handleOnSelectorChange,
      updateBundleProduct: handleUpdateBundleProduct,
      isPlusSizeSelected,
      selectionStateByProductIndex,
      itemQuantity,
      onQuantityChange,
      resetQuantity,
      isProductAvailableForUser,
      isForcedSoldOut,
      isQuickView,
      isQuickViewPage,
      preSelectedBundleItemSizesWithMatchFound,
      isOutOfStock,
      isPreOrderOutOfStock,
      allowInvalidOptionSelection,
    }),
    [
      product,
      enableBundleItemMembershipFilter,
      bundleProducts,
      permalink,
      isBundle,
      handleOnSelectorChange,
      handleUpdateBundleProduct,
      isPlusSizeSelected,
      selectionStateByProductIndex,
      itemQuantity,
      onQuantityChange,
      resetQuantity,
      isProductAvailableForUser,
      isForcedSoldOut,
      isQuickView,
      isQuickViewPage,
      preSelectedBundleItemSizesWithMatchFound,
      isOutOfStock,
      isPreOrderOutOfStock,
      allowInvalidOptionSelection,
    ]
  );

  useEffect(() => {
    dispatch({
      type: initializeProduct,
      product: productFromProps,
      activeSizes,
      preSelectedBundleItemSizes,
      getPreselectAttributeIndex,
    });
  }, [
    productFromProps,
    activeSizes,
    preSelectedBundleItemSizes,
    getPreselectAttributeIndex,
  ]);

  useEffect(() => {
    if (onPermalinkChange && product.permalink !== permalink) {
      onPermalinkChange(permalink);
    }
  }, [onPermalinkChange, permalink, product.permalink]);

  return (
    <ProductDetailContext value={productDetailValue}>
      <ProductContext product={product}>
        {typeof children === 'function'
          ? children(productDetailValue)
          : children}
      </ProductContext>
    </ProductDetailContext>
  );
}

ProductDetail.displayName = 'ProductDetail';

ProductDetail.propTypes = {
  /**
   * Determines if the selector will allow an invalid selection
   * combination, e.g. no matching sku. Default behavior will
   * prevent any invalid selections.
   *
   */
  allowInvalidOptionSelection: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  currentUserStatus: PropTypes.object.isRequired,
  /**
   * Enables Filtering of Bundle Items availability based on membership
   *
   */
  enableBundleItemMembershipFilter: PropTypes.bool,
  getIsForcedSoldOut: PropTypes.func,
  getIsPlusSize: PropTypes.func,
  /**
   * function to allow overriding default size selections initially determined
   * by profile sizes and/or session sizes
   */
  getPreselectAttributeIndex: PropTypes.func,
  /**
   * Designates Product Detail in a QuickView modal instance or not.
   */
  isQuickView: PropTypes.bool,
  /**
   * Designates Product Detail in a QuickView page instance or not.
   */
  isQuickViewPage: PropTypes.bool,
  /**
   * Callback function to run when a color selector change has fired
   */
  onColorChange: PropTypes.func,
  /**
   * Callback function to run if the product's permalink value is updated
   */
  onPermalinkChange: PropTypes.func,
  /**
   * Object having input sizes from cart for bundle items so that we can preselect sizes in quick view checkout
   */
  preSelectedBundleItemSizes: PropTypes.object,
  /**
   * Object containing information about the product being displayed. This product object is usually obtained via API
   * calls to either 'useProductQueryAndPreload' or 'useProductQuery' and the object type should follow the schema provided
   * by the GraphQL product service.
   */
  product: PropTypes.object.isRequired,
  /**
   * Size preferences derived from user profile. Used to facilitate default product
   * size selections
   */
  profileSizes: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /**
   * Size preferences derived from current session activity. Used to facilitate default
   * product size selections
   */
  sessionSizes: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import withThemeProps from '../withThemeProps';

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const cycleAnimation = keyframes`
  0% {
    opacity: 1;
  }
  94% {
    opacity: 0.1;
  }
  /* Fade back in quickly, not instantly. */
  100% {
    opacity: 1;
  }
`;

/**
 * In addition to the spokes cycling, the whole container can rotate. If a
 * zero/null `rotationDuration` is given, the animation will not be added.
 */
function getRotationAnimation({ rotationDuration }) {
  if (rotationDuration) {
    return css`
      animation-name: ${rotateAnimation};
      animation-duration: ${rotationDuration}ms;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    `;
  }
}

const Wrapper = styled.span`
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: ${(props) => 2 * (props.spokeLength + props.innerRadius)}px;
  height: ${(props) => 2 * (props.spokeLength + props.innerRadius)}px;
  ${getRotationAnimation};
  ${(props) => props.spinnerStyle};
`;

/**
 * Each spoke will fade out with a delay that makes it look like they cycle in
 * a circular motion. If a zero/null `cycleDuration` is given, the animation
 * will not be added.
 */
function getSpokeAnimation({ cycleDuration, spokeCount, spokeNumber }) {
  // Only animate of the duration is not null and nonzero.
  if (cycleDuration) {
    // Negative animation delays can be used to start an animation immediately
    // but with a time offset. We use this to start each spoke at a different
    // fade point, creating the cycle effect.
    const negativeOffset = spokeNumber - spokeCount;
    const timePerSpoke = cycleDuration / spokeCount;
    return css`
      animation-name: ${cycleAnimation};
      animation-duration: ${cycleDuration}ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-delay: ${negativeOffset * timePerSpoke}ms;
    `;
  }
}

/**
 * Determine how much to rotate each spoke by, based on how many there are.
 */
function getSpokeRotation({ spokeNumber, spokeCount }) {
  return `${((spokeNumber - 1) * (360 / spokeCount)).toFixed(2)}deg`;
}

const Spoke = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: ${(props) => props.spokeWidth}px;
  height: ${(props) => props.spokeLength}px;
  background: ${(props) => props.spokeColor || 'currentColor'};
  border-radius: ${(props) => props.spokeWidth / 2}px;
  transform: translateX(-50%) rotate(${getSpokeRotation});
  transform-origin: 50% ${(props) => props.spokeLength + props.innerRadius}px;
  ${getSpokeAnimation};
  ${(props) => props.spokeStyle};
`;

/**
 * A loading indicator with a segmented spinning effect. The animation can
 * rotate the entire element, cycle through fading the segments of the spinner,
 * or both.
 */
export function SpinnerLoadingIndicator({
  color,
  cycleDuration,
  innerRadius,
  rotationDuration,
  spinnerStyle,
  spokeCount,
  spokeLength,
  spokeStyle,
  spokeWidth,
  ...rest
}) {
  const spokes = useMemo(() => {
    const spokes = [];
    for (let i = 0; i < spokeCount; i++) {
      spokes.push(
        <Spoke
          data-spoke={i + 1}
          cycleDuration={cycleDuration}
          innerRadius={innerRadius}
          key={i}
          spokeColor={color}
          spokeCount={spokeCount}
          spokeLength={spokeLength}
          spokeNumber={i + 1}
          spokeStyle={spokeStyle}
          spokeWidth={spokeWidth}
        />
      );
    }
    return spokes;
  }, [
    color,
    cycleDuration,
    innerRadius,
    spokeCount,
    spokeLength,
    spokeStyle,
    spokeWidth,
  ]);

  return (
    <Wrapper
      {...rest}
      data-spinner=""
      innerRadius={innerRadius}
      // If these change, it could screw up the animation timing, so remount
      // to start from scratch.
      key={`${spokeCount}:${cycleDuration}`}
      rotationDuration={rotationDuration}
      spinnerStyle={spinnerStyle}
      spokeLength={spokeLength}
      spokeWidth={spokeWidth}
    >
      {spokes}
    </Wrapper>
  );
}

SpinnerLoadingIndicator.propTypes = {
  /**
   * An accessible label for the loading indicator.
   */
  'aria-label': PropTypes.string,
  /**
   * The color of the spokes. By default it will inherit the current foreground
   * color.
   */
  color: PropTypes.string,
  /**
   * How long it takes the spoke fading animation to make one rotation around
   * the spinner. Note that for this animation, the spoke elements do not
   * actually move, they just fade in and out in an offset cycle. Set to 0 or
   * null to disable the cycle animation.
   */
  cycleDuration: PropTypes.number,
  /**
   * How far the spokes are from the center of the spinner, in pixels.
   */
  innerRadius: PropTypes.number,
  /**
   * How long it takes the rotation animation to perform one full rotation.
   * This will cause the spokes to move instead of just fade in a cycle. Set to
   * 0 or null to disable the rotation animation (the default).
   */
  rotationDuration: PropTypes.number,
  /**
   * Styles to apply to the outer container element. Can be anything
   * `styled-components` supports in `styled` interpolations.
   */
  spinnerStyle: PropTypes.any,
  /**
   * The number of spokes.
   */
  spokeCount: PropTypes.number,
  /**
   * The length of each spoke, in pixels.
   */
  spokeLength: PropTypes.number,
  /**
   * Styles to apply to each spoke element. Can be anything `styled-components`
   * supports in `styled` interpolations.
   */
  spokeStyle: PropTypes.any,
  /**
   * The thickness of each spoke, in pixels.
   */
  spokeWidth: PropTypes.number,
};

SpinnerLoadingIndicator.defaultProps = {
  'aria-label': 'Loading…',
  color: 'currentColor',
  cycleDuration: 1000,
  innerRadius: 7,
  rotationDuration: 0,
  spokeCount: 12,
  spokeLength: 6,
  spokeWidth: 2,
};

export default withThemeProps(SpinnerLoadingIndicator, {
  section: 'spinnerLoadingIndicator',
  defaultVariant: 'default',
});

import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';

import logger from '../logger';
import Script from '../Script';

const debug = logger.extend('RaygunSnippet');

const defaultOptions = {
  debugMode: debug.enabled,
  ignore3rdPartyErrors: true,
  excludedHostnames: ['localhost'],
};

/**
 * Render a JavaScript snippet that loads Raygun.
 */
export default function RaygunSnippet({
  apiKey = config.get('public.raygun.apiKey'),
  customDataVariables = config.has('public.raygun.customDataVariables')
    ? config.get('public.raygun.customDataVariables')
    : ['dataLayer', 'jsDebuggingData'],
  enableCrashReporting = config.has('public.raygun.enableCrashReporting')
    ? config.get('public.raygun.enableCrashReporting')
    : true,
  globalScriptKey,
  options: inputOptions = config.has('public.raygun.options')
    ? config.get('public.raygun.options')
    : undefined,
  type,
}) {
  switch (type) {
    case 'script':
      return (
        <Script
          globalScriptKey={globalScriptKey || ['raygun', type]}
          dangerouslySetInnerHTML={{
            __html: `
!function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
(a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
f.async=1,f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");
`,
          }}
        />
      );
    case 'setup': {
      const customDataFunction =
        customDataVariables && customDataVariables.length
          ? `function() {
  return ${serialize(customDataVariables)}.reduce(function(data, name) {
    data[name] = window[name];
    return data;
  }, {});
}`
          : null;
      const options = { ...defaultOptions, ...inputOptions };
      return (
        <Script
          globalScriptKey={globalScriptKey || ['raygun', type, apiKey]}
          dangerouslySetInnerHTML={{
            __html: `
rg4js("apiKey", ${serialize(apiKey)});
rg4js("enableCrashReporting", ${enableCrashReporting ? 'true' : 'false'});
rg4js("options", ${serialize(options)});
${customDataFunction ? `rg4js("withCustomData", ${customDataFunction});` : ''}
`,
          }}
        />
      );
    }
  }
  debug(`Unknown type passed to RaygunSnippet: ${type}`);
  return null;
}

RaygunSnippet.propTypes = {
  /**
   * Your Raygun API key. Automatically read from `public.raygun.apiKey` if not
   * explicitly passed.
   */
  apiKey: PropTypes.string,
  /**
   * Global variable names to read and send to Raygun using the `withCustomData`
   * option. Automatically read from `public.raygun.customDataVariables` if
   * defined, otherwise defaults to sending `dataLayer` and `jsDebuggingData`.
   */
  customDataVariables: PropTypes.arrayOf(PropTypes.string),
  /**
   * Whether to set the `enableCrashReporting` option. Automatically read from
   * `public.raygun.enableCrashReporting` if defined, otherwise defaults to
   * true.
   */
  enableCrashReporting: PropTypes.bool,
  /**
   * Custom `globalScriptKey` to pass to the `Script` component. If not
   * provided, one will be generated.
   */
  globalScriptKey: PropTypes.any,
  /**
   * Advanced options to set using Raygun's `options` method. These will be
   * merged with the following default options, so make sure to unset these if
   * they aren't what you want:
   * ```
   * {
   *   debugMode: debug.enabled,
   *   ignore3rdPartyErrors: true,
   *   excludedHostnames: ['localhost'],
   * }
   * ```
   * See available options here: https://raygun.com/documentation/language-guides/javascript/crash-reporting/advanced-setup/
   */
  options: PropTypes.object,
  /**
   * The type of snippet to render.
   * - The `script` snippet will render the Raygun loader, which downloads their
   *   script and makes globals like `rg4js` available.
   * - The `setup` snippet will call `rg4js` with the options necessary to
   *   configure Raygun, like `apiKey` and `enableCrashReporting`. This should
   *   be placed somewhere after the `script` snippet.
   */
  type: PropTypes.oneOf(['script', 'setup']),
};

RaygunSnippet.defaultProps = {
  type: 'script',
};

/**
 * A helper component for rendering the `setup` snippet type.
 */
RaygunSnippet.Setup = (props) => <RaygunSnippet {...props} type="setup" />;
RaygunSnippet.Setup.displayName = 'RaygunSnippet.Setup';

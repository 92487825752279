import config from 'config';

import { useMembership } from '../../techstyle-shared/react-accounts';
import { useFeature } from '../../techstyle-shared/react-features';
import { useMarketingDisposition } from '../../techstyle-shared/react-marketing';
import { useDomain, useSession } from '../../techstyle-shared/redux-core';
import { staticFeatureValidators } from '../constants/featureFlags';

import useGender from './useGender';

export { default as featureNames } from '../constants/featureFlags';

/**
 * Hook that returns current state of a feature. If it is not yet loaded, it
 * will dispatch `loadFeatures` and update its value after the feature has loaded.
 *
 * NOTE: If the feature was not pre-fetched, this update will trigger a re-render
 *  of the component(s) using this hook.
 */
export default function useHasFeature(handle) {
  const session = useSession();
  const { tld } = useDomain();
  const { offer, experience } = useMarketingDisposition();
  const membership = useMembership();
  const { gender } = useGender();
  const additionalQueryParams = {
    brand: config.get('public.brand.name') + tld.substring(1),
    membership: membership.membershipLevelGroup.toLowerCase(),
    gender: gender,
  };
  if (
    additionalQueryParams.membership !== 'vip' &&
    additionalQueryParams.membership !== 'elite'
  ) {
    additionalQueryParams.funnel = offer || 'default';
    additionalQueryParams.experience = experience || 'default';
  }
  let isStaticEnabled, featureCreatureHandle;
  const validator = staticFeatureValidators[handle];
  const hasStaticFeature = Boolean(validator);

  if (hasStaticFeature) {
    const validator = staticFeatureValidators[handle];
    isStaticEnabled = validator({
      handle,
      session,
      membership,
      gender,
      tld,
    });
  } else {
    featureCreatureHandle = handle;
  }

  const { isEnabled: isEnabledFeatureCreature } = useFeature(
    featureCreatureHandle,
    {
      additionalQueryParams,
    }
  );

  return hasStaticFeature ? isStaticEnabled : isEnabledFeatureCreature;
}

import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

import {
  defineMessages,
  useIntl,
  FormattedMessage,
} from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import {
  useCategoryLabels,
  useFilterFieldLabels,
  useOptionLabels,
} from '../LabelProvider';
import { LabelType } from '../LabelProvider/constants';
import { useProductFilters } from '../ProductFilterContextCustomFilters';
import { mobile } from '../styles';

const messages = defineMessages({
  deselectLabel: {
    id: 'site_product_filter.deselect_button',
    defaultMessage: 'Dismiss {filterValue} filter',
  },
});

const TagHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  line-height: 32px;
  color: #333;

  ${mobile`
    font-size: 16px;
    line-height: 48px;
  `}
`;

const HeaderLabelWrapper = styled.span`
  ${({ headerStyle }) => headerStyle};
`;

const ClearAllButton = styled(Button).attrs(() => ({
  variant: 'clearAllFilters',
}))`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #333;

  &,
  &:focus,
  &:hover {
    text-decoration: underline;
  }

  ${({ buttonStyle }) => buttonStyle};
`;

const TagList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  margin: -4px 0 0 0;

  ${({ tagListStyle }) => tagListStyle};
`;

const Tag = styled.li`
  padding: 0 0 0 8px;
  margin: 4px 4px 0 0;
  background: #eeeeee;
  border-radius: 4px;
  text-align: left;
  font-size: 12px;
  line-height: 24px;
  color: #333;

  ${mobile`
    font-size: 14px;
    line-height: 32px;
  `}

  ${({ tagStyle }) => tagStyle};
`;

const DeselectIcon = styled(MdClose)`
  display: inline-block;
  vertical-align: -2px;
  color: #999;
`;

const DeselectButton = styled(Button).attrs(() => ({
  variant: 'deselectFilter',
}))`
  width: auto;
  height: 24px;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 6px 0 3px;
  margin-left: 2px;
  font-size: 12px;

  ${mobile`
    height: 32px;
    font-size: 16px;
  `};

  ${({ buttonStyle }) => buttonStyle};
`;

const FilterTags = ({
  clearAllButtonComponent,
  clearAllButtonStyle,
  deselectButtonStyle,
  children,
  headerStyle,
  onClearAll,
  tagListStyle,
  tagStyle,
  headerLabelComponent,
  hideClearAllButton,
  ...rest
}) => {
  const ClearAllBtn = clearAllButtonComponent || ClearAllButton;

  return (
    <div {...rest}>
      <TagHeader>
        <HeaderLabelWrapper headerStyle={headerStyle}>
          {headerLabelComponent || (
            <FormattedMessage
              id="site_product_filter.filtered_by"
              defaultMessage="Filtered by:"
            />
          )}
        </HeaderLabelWrapper>
        {!hideClearAllButton && (
          <ClearAllBtn
            onClick={onClearAll}
            data-autotag="filter-clear-all"
            buttonStyle={clearAllButtonStyle}
          >
            <FormattedMessage
              id="site_product_filter.clear_all"
              defaultMessage="Clear all"
            />
          </ClearAllBtn>
        )}
      </TagHeader>
      <TagList data-autotag="filtered-by-textbox" tagListStyle={tagListStyle}>
        {children}
      </TagList>
    </div>
  );
};

export const CategoryFilterTags = ({
  categoryFilter,
  selectedFilters,
  ...rest
}) => {
  const categoryLabelCtx = useCategoryLabels();
  const { setSelectedFilters } = useProductFilters();

  const categoriesToRender = categoryFilter.items.filter((item) =>
    selectedFilters.categoryIds.includes(item.value)
  );

  const labelOptions = {
    labelType: LabelType.CATEGORY_FILTER_TAG,
  };

  return categoriesToRender.map((category) => {
    return (
      <FilterTag
        key={category.value}
        label={categoryLabelCtx.renderLabel(category, labelOptions)}
        labelString={categoryLabelCtx.renderLabelString(category, labelOptions)}
        onDismiss={() => {
          setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            categoryIds: prevFilters.categoryIds.filter(
              (value) => value !== category.value
            ),
          }));
        }}
        value={category}
        {...rest}
      />
    );
  });
};

CategoryFilterTags.propTypes = {
  categoryFilter: PropTypes.object,
  selectedFilters: PropTypes.object,
};

export const AggregationFilterTags = ({
  filterSetting,
  selectedFilters,
  ...props
}) => {
  const values = selectedFilters.aggregationFilter[filterSetting.field];
  const labelOptions = {
    labelType: LabelType.PRODUCT_FILTER_TAG,
  };
  const optionLabelCtx = useOptionLabels();
  const filterFieldCtx = useFilterFieldLabels();

  const isSizeFilter = /^size_/.test(filterSetting.field);

  const { setSelectedFilters } = useProductFilters();

  const getCurrentItem = (value) => {
    return filterSetting.items?.find((item) => item.value === value);
  };

  return values.map((value) => {
    const currentItem = getCurrentItem(value);
    const option = {
      label: currentItem?.showLabelAsTag ? currentItem.label : value,
      value: value,
    };
    return (
      <FilterTag
        key={value}
        label={
          <>
            {optionLabelCtx.renderLabel(filterSetting, option, labelOptions)}
            {isSizeFilter ? (
              <>
                {' ('}
                {filterFieldCtx.renderLabel(filterSetting, labelOptions)})
              </>
            ) : null}
          </>
        }
        labelString={`${optionLabelCtx.renderLabelString(
          filterSetting,
          option,
          labelOptions
        )}${
          isSizeFilter
            ? ` (${filterFieldCtx.renderLabelString(
                filterSetting,
                labelOptions
              )})`
            : ''
        }`}
        onDismiss={(value) => {
          if (currentItem?.removeFilterCallback) {
            currentItem.removeFilterCallback();
          }
          setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            aggregationFilter: {
              ...prevFilters.aggregationFilter,
              [filterSetting.field]: prevFilters.aggregationFilter[
                filterSetting.field
              ].filter((prevValue) => prevValue !== value),
            },
          }));
        }}
        value={value}
        {...props}
      />
    );
  });
};

AggregationFilterTags.propTypes = {
  filterSetting: PropTypes.object,
  value: PropTypes.string,
};

export const FilterTag = ({
  children = <DeselectIcon />,
  deselectButtonStyle,
  label,
  labelString,
  onDismiss,
  tagStyle,
  value,
}) => {
  const intl = useIntl();
  const deselectLabel = intl.formatMessage(messages.deselectLabel, {
    filterValue: labelString,
  });

  const handleDismiss = useCallback(() => {
    onDismiss(value);
  }, [onDismiss, value]);

  return (
    <Tag data-autotag="filtered-by-option" tagStyle={tagStyle}>
      {label}
      <DeselectButton
        aria-label={deselectLabel}
        data-autotag="filtered-by-option-close-icon"
        buttonStyle={deselectButtonStyle}
        onClick={handleDismiss}
      >
        {children}
      </DeselectButton>
    </Tag>
  );
};

FilterTag.propTypes = {
  children: PropTypes.any,
  /**
   * See: ../ProductListingFilter/__fixtures__/filterData.json
   * This consists of information for both the category's label
   * and key, and the filter's value and label. The labels are
   * necessary to display the filter and to generate slugified
   * internationalization keys.
   */
  deselectButtonStyle: PropTypes.any,
  label: PropTypes.node,
  labelString: PropTypes.string,
  onDismiss: PropTypes.func,
  tagStyle: PropTypes.any,
  value: PropTypes.any,
};

FilterTags.propTypes = {
  children: PropTypes.node,
  clearAllButtonComponent: PropTypes.elementType,
  clearAllButtonStyle: PropTypes.any,
  deselectButtonStyle: PropTypes.any,
  headerLabelComponent: PropTypes.any,
  headerStyle: PropTypes.any,
  hideClearAllButton: PropTypes.bool,
  onClearAll: PropTypes.func,
  tagListStyle: PropTypes.any,
  tagStyle: PropTypes.any,
};

FilterTags.AggregationFilterTags = AggregationFilterTags;
FilterTags.CategoryFilterTags = CategoryFilterTags;
FilterTags.FilterTag = FilterTag;

export default FilterTags;

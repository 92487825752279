import { logInSuccess } from '../../../techstyle-shared/react-accounts';
import {
  createAction,
  createCachedSelector,
  createReducer,
  createSelector,
  startLoading,
  stopLoading,
  upToDateStatus,
  outOfDateStatus,
  updateSessionDetail,
  invalidate,
} from '../../../techstyle-shared/redux-core';

import logger from './logger';

const debug = logger.extend('marketingModule');

const initialState = {
  funnels: {},
  marketing: {
    requestInfo: {},
    pageInfo: {},
    referrerOptions: {
      options: [],
      networkStatus: outOfDateStatus(),
    },
    disposition: {
      networkStatus: outOfDateStatus(),
    },
  },
};

export const setRequestInfo = createAction('marketing/setRequestInfo');
export const setPageInfo = createAction('marketing/setPageInfo');

export const loadFunnelRequest = createAction('marketing/loadFunnelRequest');
export const loadFunnelSuccess = createAction('marketing/loadFunnelSuccess');
export const loadFunnelFailure = createAction('marketing/loadFunnelFailure');

export const addContactRequest = createAction('marketing/addContactRequest');
export const addContactSuccess = createAction('marketing/addContactSuccess');
export const addContactFailure = createAction('marketing/addContactFailure');

export const verifyContactRequest = createAction(
  'marketing/verifyContactRequest'
);
export const verifyContactSuccess = createAction(
  'marketing/verifyContactSuccess'
);
export const verifyContactFailure = createAction(
  'marketing/averifyContactFailure'
);

export const loggedInPageView = createAction('marketing/loggedInPageView');

export const loadDirectMarketingDispositionRequest = createAction(
  'marketing/loadDirectMarketingDispositionRequest'
);
export const loadDirectMarketingDispositionSuccess = createAction(
  'marketing/loadDirectMarketingDispositionSuccess'
);
export const loadDirectMarketingDispositionFailure = createAction(
  'marketing/loadDirectMarketingDispositionFailure'
);
export const getDirectMarketingGatewayByCodeRequest = createAction(
  'marketing/getDirectMarketingGatewayByCodeRequest'
);
export const getDirectMarketingGatewayByCodeSuccess = createAction(
  'marketing/getDirectMarketingGatewayByCodeSuccess'
);
export const getDirectMarketingGatewayByCodeFailure = createAction(
  'marketing/getDirectMarketingGatewayByCodeFailure'
);
export const getDirectMarketingSiteByIdRequest = createAction(
  'marketing/getDirectMarketingSiteByIdRequest'
);
export const getDirectMarketingSiteByIdSuccess = createAction(
  'marketing/getDirectMarketingSiteByIdSuccess'
);
export const getDirectMarketingSiteByIdFailure = createAction(
  'marketing/getDirectMarketingSiteByIdFailure'
);
export const loadReferrerOptionsRequest = createAction(
  'marketing/loadReferrerOptionsRequest'
);
export const loadReferrerOptionsSuccess = createAction(
  'marketing/loadReferrerOptionsSuccess'
);
export const loadReferrerOptionsFailure = createAction(
  'marketing/loadReferrerOptionsFailure'
);
export const loadNestedReferrerOptionsRequest = createAction(
  'marketing/loadNestedReferrerOptionsRequest'
);
export const loadNestedReferrerOptionsSuccess = createAction(
  'marketing/loadNestedReferrerOptionsSuccess'
);
export const loadNestedReferrerOptionsFailure = createAction(
  'marketing/loadNestedReferrerOptionsFailure'
);

export const FunnelErrorType = {
  OTHER: 'OTHER',
};

export function initFunnel() {
  return async (dispatch, getState, context) => {
    // access context to check two things:
    // we are on the server
    // there is request data on context
    const { isServer, req: request } = context;
    // actually ensure we're on the server and there are request params
    if (isServer && request) {
      // if there, destructure params off request
      const { promo, theme } = request.query;

      // if either promo or theme are missing in url, do nothing
      if (!promo || !theme) {
        return;
      }

      // set up funnel override object
      const funnelOverride = {
        name: 'origin',
        value: JSON.stringify({
          promo,
          theme,
        }),
      };
      // dispatch events to update session detail state & funnel state
      // set session detail state
      await dispatch(updateSessionDetail(funnelOverride));
      // load/set funnel state
      await dispatch(loadFunnel(promo));
    }
  };
}

export function loadFunnel(funnelKey) {
  if (!funnelKey) {
    throw new Error(`loadFunnel must be called with a funnel key`);
  }

  return (dispatch, getState) => {
    const state = getState();
    const funnels = state.funnels;
    const funnel = funnels[funnelKey];
    let shouldFetch = false;

    if (funnel) {
      if (funnel.networkStatus.isLoading) {
        // Already loading, do nothing.
      } else if (funnel.error) {
        // Don't attempt to fetch if there was an error last time!
        debug('funnelKey has error; skipping refetch.', funnelKey);
      } else if (!funnel.networkStatus.isUpToDate) {
        debug('funnelKey has been invalidated; refetching.', funnelKey);
        shouldFetch = true;
      }
    } else {
      shouldFetch = true;
    }

    if (shouldFetch) {
      return dispatch({
        bentoApi: {
          endpoint: `funnel/${funnelKey}`,
          requestKey: `loadFunnel-${funnelKey}`,
          actions: [
            (payload, meta) =>
              loadFunnelRequest(payload, {
                ...meta,
                funnelKey,
              }),
            (payload, meta) =>
              loadFunnelSuccess(payload, {
                ...meta,
                funnelKey,
              }),
            (payload, meta) =>
              loadFunnelFailure(payload, { ...meta, funnelKey }),
          ],
        },
      });
    }
  };
}

export function addContact(payload) {
  if (!payload) {
    throw new Error('addContact must be given a payload object.');
  }

  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'contacts',
      requestKey: 'addContact',
      json: payload,
      actions: [addContactRequest, addContactSuccess, addContactFailure],
    },
  };
}

export function verifyContact(email) {
  if (!email) {
    throw new Error('verifyContact must be given an email.');
  }

  return {
    bentoApi: {
      endpoint: `contacts/verify?email=${email}`,
      method: 'GET',
      actions: [
        verifyContactRequest,
        verifyContactSuccess,
        verifyContactFailure,
      ],
    },
  };
}

export const funnelReducer = createReducer(initialState.funnels, {
  [loadFunnelRequest]: (state, action) => {
    const funnelKey = action.meta.funnelKey;
    let funnel = state[funnelKey];

    if (!funnel) {
      funnel = state[funnelKey] = {
        networkStatus: outOfDateStatus(),
      };
    }
    startLoading(funnel);
  },
  [loadFunnelFailure]: (state, action) => {
    const funnelKey = action.meta.funnelKey;
    const funnel = state[funnelKey];

    funnel.error = FunnelErrorType.OTHER;
    stopLoading(funnel);
  },
  [loadFunnelSuccess]: (state, action) => {
    const funnelKey = action.meta.funnelKey;

    state[funnelKey] = {
      data: action.payload,
      networkStatus: upToDateStatus(),
    };
  },
});

export const marketingReducer = createReducer(initialState.marketing, {
  [logInSuccess]: (state) => {
    invalidate(state.disposition);
  },
  [setPageInfo]: (state, action) => {
    state.pageInfo = { ...action.payload };
  },
  [setRequestInfo]: (state, action) => {
    state.requestInfo = { ...action.payload };
  },
  [loadReferrerOptionsRequest]: (state) => {
    startLoading(state.referrerOptions);
  },
  [loadReferrerOptionsFailure]: (state) => {
    stopLoading(state.referrerOptions);
  },
  [loadReferrerOptionsSuccess]: (state, action) => {
    state.referrerOptions.options = action.payload;
    state.referrerOptions.networkStatus = upToDateStatus();
  },
  [loadDirectMarketingDispositionRequest]: (state) => {
    startLoading(state.disposition);
  },
  [loadDirectMarketingDispositionFailure]: (state) => {
    stopLoading(state.disposition);
  },
  [loadDirectMarketingDispositionSuccess]: (state, action) => {
    state.disposition = { ...action.payload, networkStatus: upToDateStatus() };
  },
});

export const getRaygunUser = createSelector(
  [(state) => state.session, (state) => state.customer],
  (session, customer) => {
    if (customer.customerKey) {
      const { firstName, lastName } = customer;
      const fullName =
        firstName && lastName ? `${firstName} ${lastName}` : firstName;

      return {
        identifier: customer.customerKey,
        isAnonymous: false,
        email: customer.email || undefined,
        firstName: firstName || undefined,
        fullName: fullName || undefined,
      };
    } else if (session.sessionVisitor) {
      return {
        identifier: session.sessionVisitor,
        isAnonymous: true,
      };
    } else {
      // It may be possible for there to be no session, if we land on a page
      // that doesn't require any API calls and has set `sessionRequired` to
      // false. The docs say to "pass in empty strings" to reset the current
      // user, although it's not clear if this can also apply to the
      // `identifier` field. But we don't have an identifier, so this seems apt.
      // See: https://github.com/MindscapeHQ/raygun4js#resetting-the-user
      return {
        identifier: '',
        isAnonymous: true,
      };
    }
  }
);

export const getMarketingInfo = createSelector(
  [(state) => state.marketing],
  (marketingInfo) => marketingInfo
);

export function loadDirectMarketingDisposition(code, options = {}) {
  return async (dispatch, getState, context) => {
    const { gender = '' } = options;
    const { networkStatus } = getState().marketing.disposition;

    // hasChanges is intended to check that this call is not
    // intended to update the disposition because marketing/disposition
    // acts as both a GET and a POST endpoint
    const hasChanges = code || gender?.length;

    if (!networkStatus.isUpToDate || hasChanges) {
      return dispatch({
        bentoApi: {
          endpoint: `marketing/disposition`,
          method: 'GET',
          requestKey: JSON.stringify([
            'directMarketingDisposition',
            code,
            gender,
          ]),
          searchParams: { code, gender },
          actions: [
            loadDirectMarketingDispositionRequest,
            loadDirectMarketingDispositionSuccess,
            loadDirectMarketingDispositionFailure,
          ],
        },
      });
    }
  };
}

export const getDirectMarketingDisposition = createSelector(
  [(state) => state.marketing.disposition],
  (disposition) => disposition
);

export function getDirectMarketingGatewayByCode(code) {
  return {
    bentoApi: {
      endpoint: `marketing/dmg/${code}`,
      method: 'GET',
      requestKey: JSON.stringify(['directMarketingGatewayByCode', code]),
      actions: [
        getDirectMarketingGatewayByCodeRequest,
        getDirectMarketingGatewayByCodeSuccess,
        getDirectMarketingGatewayByCodeFailure,
      ],
    },
  };
}

export function getDirectMarketingSiteById(dmSiteId) {
  return {
    bentoApi: {
      endpoint: `marketing/dms/${dmSiteId}`,
      method: 'GET',
      requestKey: JSON.stringify(['directMarketingSiteById', dmSiteId]),
      actions: [
        getDirectMarketingSiteByIdRequest,
        getDirectMarketingSiteByIdSuccess,
        getDirectMarketingSiteByIdFailure,
      ],
    },
  };
}

export function loadReferrerOptions(params = {}) {
  return {
    bentoApi: {
      endpoint: 'marketing/referrers',
      method: 'GET',
      requestKey: 'referrerOption',
      searchParams: params,
      actions: [
        loadReferrerOptionsRequest,
        loadReferrerOptionsSuccess,
        loadReferrerOptionsFailure,
      ],
    },
  };
}

export function loadNestedReferrerOptions(params = {}) {
  return {
    bentoApi: {
      endpoint: 'marketing/referrers',
      method: 'POST',
      json: params,
      actions: [
        loadNestedReferrerOptionsRequest,
        loadNestedReferrerOptionsSuccess,
        loadNestedReferrerOptionsFailure,
      ],
    },
  };
}

export const getFunnel = createCachedSelector(
  [
    (state, funnelKey) => state.funnels[funnelKey],
    (state, funnelKey) => funnelKey,
  ],
  (funnel, funnelKey) => {
    if (funnel) {
      return { funnelKey, ...funnel };
    }

    return {
      funnelKey,
      fetchedDate: null,
      networkStatus: outOfDateStatus(),
    };
  }
)((state, funnelKey) => funnelKey);

export default {
  id: 'marketing',
  reducerMap: {
    funnels: funnelReducer,
    marketing: marketingReducer,
  },
  initialActions: [initFunnel()],
};

import React, { useState, useMemo, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import ImageBrowserContext from '../ImageBrowserContext';

export default function ImageBrowser({ images, children }) {
  // use the state to store the  index of currently selected image.
  const [activeImageIndexFromState, setActiveImageIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const imagesRef = useRef(images);
  const imagesChanged = images !== imagesRef.current;
  const activeImageIndex = imagesChanged ? 0 : activeImageIndexFromState;

  const value = useMemo(
    () => ({
      images,
      activeImageIndex,
      activeImage: images[activeImageIndex],
      isZoomed,
      setActiveImageIndex,
      setIsZoomed,
    }),
    [activeImageIndex, images, isZoomed]
  );

  // Whenever the image set is changed, set the active image index back to 0
  useEffect(() => {
    imagesRef.current = images;
    setActiveImageIndex(0);
  }, [images]);

  return (
    <ImageBrowserContext value={value}>
      {typeof children === 'function' ? children(value) : children}
    </ImageBrowserContext>
  );
}

ImageBrowser.propTypes = {
  /**
   * Image area content.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /**
   * array of images
   */
  images: PropTypes.array,
};

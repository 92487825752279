import React, {
  useEffect,
  useLayoutEffect as useRealLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import PropTypes from 'prop-types';

import useItems from '../useItems';
import { Context } from '../useVirtualScroll';
import VirtualScrollBatch from '../VirtualScrollBatch';

if (process.browser) {
  require('intersection-observer');
}

const useLayoutEffect = process.browser ? useRealLayoutEffect : useEffect;

const VirtualScroll = React.memo(function VirtualScroll({
  as,
  slots,
  getItems,
  itemCount,
  loadItems,
  stepSize = 1,
  maxBatchCount,
  renderItems,
  root,
  rootMargin = '800px 0px 1600px 0px',
  targetBatchSize,
}) {
  const observer = useRef();
  const handlerMap = useRef();
  if (handlerMap.current == null) {
    handlerMap.current = new Map();
  }
  const { batches, getItemsWithSlots } = useItems(
    itemCount,
    slots,
    stepSize,
    targetBatchSize,
    maxBatchCount,
    getItems,
    loadItems
  );

  useLayoutEffect(() => {
    let disconnecting = false;

    const handleChange = (entries) => {
      if (disconnecting) {
        return;
      }
      entries.forEach((entry) => {
        const isIntersecting =
          entry.isIntersecting || entry.intersectionRatio > 0;
        const callback = handlerMap.current.get(entry.target);
        if (callback) {
          callback(isIntersecting, entry);
        }
      });
    };

    observer.current = new IntersectionObserver(handleChange, {
      root,
      rootMargin,
    });

    return () => {
      disconnecting = true;
      observer.current.disconnect();
    };
  }, [root, rootMargin]);

  const context = useMemo(() => {
    return {
      observe(node, handler) {
        handlerMap.current.set(node, handler);
        return observer.current.observe(node);
      },
      unobserve(node) {
        handlerMap.current.delete(node);
        return observer.current.unobserve(node);
      },
    };
  }, []);

  const children = useMemo(() => {
    return batches.map((batch, i) => {
      const key = `batch-${batch.itemStartIndex}-${batch.itemStopIndex}`;
      return (
        <VirtualScrollBatch
          as={as}
          childrenArray={batch.children}
          childType={batch.childType}
          initialVisible={i === 0}
          getItems={getItemsWithSlots}
          key={key}
          loadItems={loadItems}
          renderItems={renderItems}
          slots={batch.slots}
          startIndex={batch.itemStartIndex}
          stopIndex={batch.itemStopIndex}
        />
      );
    });
  }, [batches, as, getItemsWithSlots, loadItems, renderItems]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
});

VirtualScroll.propTypes = {
  /**
   * The element type that individual batches will render, defaults to `div`.
   * When rendering a `<table>`, you'll want this to be `tbody`.
   */
  as: PropTypes.elementType,
  /**
   * Function to synchronously return the range of items from `startIndex` to
   * `stopIndex`, or nothing if they're not all available. If not available then
   * `loadItems` will be called with the same range.
   */
  getItems: PropTypes.func,
  /**
   * The total number of items in the full list (even if they're not all loaded
   * yet). If not passed, then an initial `loadItems` call will be made, which
   * should result in the correct value being passed.
   */
  itemCount: PropTypes.number,
  /**
   * Function to trigger loading of the range of items from `startIndex` to
   * `stopIndex`. When the items load it should ultimately cause `getItems` to
   * update.
   */
  loadItems: PropTypes.func,
  /**
   * The maximum number of batches to render before recursively batching the
   * batches. This may be necessary if there are a lot of items, but is not
   * always possible (for example in a `<table>`, where `<tbody>` cannot be
   * nested). Set to `null` to disable recursive batching.
   */
  maxBatchCount: PropTypes.number,
  /**
   * Function to render the given `items` or a placeholder for them if not
   * available. It is called with `items`, `isVisible`, `startIndex`,
   * `stopIndex`, and `lastKnownHeight`.
   */
  renderItems: PropTypes.func.isRequired,
  /**
   * The `root` option to pass to the IntersectionObserver. It must be a DOM
   * node, not a ref (so that updating it triggers a re-render). If not passed,
   * the browser viewport is used.
   */
  root: PropTypes.object,
  /**
   * The `rootMargin` option to pass to the IntersectionObserver. It can be
   * used to extend or contract the area that triggers batches becoming visible.
   */
  rootMargin: PropTypes.string,

  /** Adds inserts into the given items inside the VirtualScroll list. This is
   * subject to grouping and layouting so that the number of `stepSize` is
   * never broken up for a group, depending on the insert's size and position.
   */
  slots: PropTypes.arrayOf(
    PropTypes.shape({
      cellsTall: PropTypes.number,
      cellsWide: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['full']),
      ]),
      column: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['right', 'left']),
      ]),
      maxColumns: PropTypes.number,
      minColumns: PropTypes.number,
      row: PropTypes.number,
    })
  ),
  /**
   * Specifies the minimum size of a group of `slots`, e.g. number of columns
   * in a grid. This gives us an indication of how far along we should
   * move when changing the size of a batch.
   */
  stepSize: PropTypes.number,
  /**
   * The target number of items to render in each batch. It's possible that
   * during batching the size will be smaller (if there aren't enough items to
   * fill the batch) or larger (if a batch must be extended to contain a
   * multiple of some number of items, for grids).
   */
  targetBatchSize: PropTypes.number,
};

VirtualScroll.Context = Context;

export default VirtualScroll;

import React from 'react';

import { useRouter } from 'next/router';
import styled from 'styled-components';

import { comparePathToUrl } from '../../../../techstyle-shared/react-navigation';
import type { RouteMap } from '../../../../techstyle-shared/react-navigation';
import { useDomain } from '../../../../techstyle-shared/redux-core';
import BaseNavLink from '../NavLink';
import getNavItemHref from '../utils/getNavItemHref';

const List = styled.ul`
  list-style: none;
`;
const ListItem = styled.li``;
const NavLink = styled(BaseNavLink)`
  text-decoration: none;
`;

export type NavItem = {
  id: number;
  label: string;
  url: string;
  isResourceBundle: boolean;
};

interface AccountLinksProps {
  navItems: NavItem[];
  groupCode?: string;
  routeMappings?: RouteMap;
  onClickNavLink?: (navItem?: NavItem) => void;
}

export default function AccountLinks({
  groupCode,
  navItems,
  routeMappings,
  onClickNavLink,
  ...rest
}: AccountLinksProps) {
  const router = useRouter();
  const { tld } = useDomain();

  return (
    <List {...rest}>
      {navItems.map((navItem: NavItem) => (
        <ListItem key={navItem.id}>
          <NavLink
            label={navItem.label}
            href={getNavItemHref({ url: navItem.url, tld, routeMappings })}
            groupCode={groupCode}
            isResourceBundle={navItem.isResourceBundle}
            currentPathHierarchy={comparePathToUrl(
              router.pathname,
              navItem.url
            )}
            {...(onClickNavLink
              ? {
                  onClick: () => onClickNavLink(navItem),
                }
              : {})}
          />
        </ListItem>
      ))}
    </List>
  );
}

AccountLinks.List = List;
AccountLinks.ListItem = ListItem;
AccountLinks.Link = NavLink;

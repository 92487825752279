import { useCallback, useMemo, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import useAccountActions from './useAccountActions';

/**
 * @param {number} pageSize The number of items to return
 * @param {'ASC'|'DESC'} sortDirection The direction to sort wishlist items
 * @returns {Object} Wishlist object
 */
export default function useWishlist({
  pageSize = 24,
  sortDirection = '',
  retailDiscounting = false,
} = {}) {
  const [page, setPage] = useState(1);
  const actions = useAccountActions();
  const wishlist = useSelector((state) => state.wishlist);
  // This is not an ideal way to track this; it should be done in the reducer.
  // But better than we had before, for now.
  const [fetchedPage, setFetchedPage] = useState(null);
  const isUpToDate = wishlist.networkStatus.isUpToDate && page === fetchedPage;

  const buildWishlistIds = useCallback(() => {
    let hasEmptySlot = false;
    const ids = new Set();
    wishlist.items.forEach((item) => {
      if (item) {
        ids.add(item.masterProductId);
      } else {
        hasEmptySlot = true;
      }
    });
    return { ids, hasEmptySlot };
  }, [wishlist.items]);

  const loadNextPage = useCallback(() => {
    // FIXME: If this fails, the page will still have been incremented but never
    // loaded, leaving a gap in `items`. Detect this scenario at a later time.
    setPage((page) => page + 1);
  }, []);

  const loadPreviousPage = useCallback(() => {
    setPage((page) => page - 1);
  }, []);

  const hasProductId = useMemo(() => {
    let result;
    return (masterProductId) => {
      // Lazily build a Set of product IDs only when necessary.
      if (!result) {
        result = buildWishlistIds();
      }
      const { ids, hasEmptySlot } = result;
      if (ids.has(masterProductId)) {
        return true;
      } else if (wishlist.items.length < wishlist.total || hasEmptySlot) {
        return null;
      }
      return false;
    };
  }, [buildWishlistIds, wishlist.items.length, wishlist.total]);

  useEffect(() => {
    const load = async () => {
      await actions.loadWishlist({
        page,
        pageSize,
        sortDirection,
        retailDiscounting,
      });
      setFetchedPage(page);
    };

    if (!isUpToDate) {
      load();
    }
  }, [
    actions,
    fetchedPage,
    page,
    pageSize,
    isUpToDate,
    sortDirection,
    retailDiscounting,
  ]);

  return useMemo(
    () => ({
      ...wishlist,
      page,
      pageSize,
      retailDiscounting,
      hasProductId,
      loadNextPage,
      loadPreviousPage,
    }),
    [
      hasProductId,
      loadNextPage,
      loadPreviousPage,
      page,
      pageSize,
      retailDiscounting,
      wishlist,
    ]
  );
}

const colorValues = {
  white: '#fff',
  sunset: '#ff906d',
  black: '#000000',
  offWhite: 'rgb(242, 247, 248)',
  lightBlack: 'rgb(40, 45, 49)',
  lightGray: 'rgb(221, 237, 239)',
  darkGray: 'rgb(60, 65, 69)',
  mediumGray: 'rgb(130, 133, 138)',
  yellow: '#8a6d3b',
  red: '#f64441',
  darkRed: '#cd0600',
  green: '#3c763d',
};

const colorAssignments = {
  primary: colorValues.darkGray,
  error: colorValues.red,
  errorText: colorValues.darkRed,
  disabledText: 'rgba(60, 65, 69, 0.7)',
  mediumGray: 'rgb(130, 133, 138)',
  tableBorder: 'rgb(187, 200, 192)',
  cellBg: 'rgb(242, 247, 248)',
  cellBgAlt: 'rgb(221, 237, 239)',
  cellHeadBg: 'rgb(228, 240, 241)',
  cellHeadBgAlt: 'rgb(208, 231, 233)',
  cellBgDisabled: 'rgb(204, 218, 221)',
};

const colors = {
  ...colorValues,
  ...colorAssignments,
};

export default {
  colors,
};

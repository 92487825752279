import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import styled, { css } from 'styled-components';

import CloseButton from '../CloseButton';
import Drawer from '../Drawer';
import { mobile } from '../styles';

const StyledDrawer = styled(Drawer)`
  flex-direction: column;
  width: 375px;

  ${mobile`
    width: 100%;
  `}
`;

const DrawerContent = styled.div`
  flex: 1;
  overflow: auto;

  ${(props) => props.drawerContentStyle};
`;

const getDialogStyle = (additionalStyles) => css`
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  flex: 1;
  overflow: hidden;

  ${additionalStyles}
`;

const HeaderContainer = styled.div`
  flex-basis: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;

  box-shadow: inset 0 -1px 0 0 #eeeeee, inset 0 1px 0 0 #eeeeee,
    0 4px 8px 0 rgba(0, 0, 0, 0.04);

  ${(props) => props.headerStyle};
`;

const DefaultCloseIcon = styled(MdClose)`
  font-size: 16px;
  color: inherit;
  vertical-align: middle;
`;

export default function ReviewsDrawer({
  children,
  closeButtonProps,
  closeIcon,
  drawerContentStyle,
  drawerContentProps = {},
  dialogStyle: dialogStyleFromProps,
  header,
  headerStyle,
  isOpen,
  onExit,
  ...rest
}) {
  const dialogStyle = useMemo(
    () => getDialogStyle(dialogStyleFromProps),
    [dialogStyleFromProps]
  );

  return (
    <StyledDrawer
      isOpen={isOpen}
      onExit={onExit}
      position="right"
      dialogStyle={dialogStyle}
      isModal
      {...rest}
    >
      {header && (
        <HeaderContainer headerStyle={headerStyle}>
          <CloseButton onClick={onExit} left {...closeButtonProps}>
            {closeIcon || <DefaultCloseIcon />}
          </CloseButton>
          {header}
        </HeaderContainer>
      )}

      <DrawerContent
        drawerContentStyle={drawerContentStyle}
        {...drawerContentProps}
      >
        {children}
      </DrawerContent>
    </StyledDrawer>
  );
}

ReviewsDrawer.propTypes = {
  /**
   * Content to render in the body of your drawer
   */
  children: PropTypes.node,
  /**
   * Any prop that can be passed down to the CloseButton component
   */
  closeButtonProps: PropTypes.object,
  /**
   * Element to render a close button in the header of your drawer.
   */
  closeIcon: PropTypes.node,
  /**
   * Styles to pass to the dialog element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  dialogStyle: PropTypes.any,
  /**
   * Props to pass to DrawerContent wrapper
   * */
  drawerContentProps: PropTypes.object,
  /**
   * Styles to pass to the content of your drawer. It will be merged
   * with the default styles and override any matching props.
   * */
  drawerContentStyle: PropTypes.any,
  /**
   * Element to render the content of a header.
   */
  header: PropTypes.node,
  /**
   * Styles that will be passed to the header wrapper and override defaults
   */
  headerStyle: PropTypes.any,
  /**
   * Controls whether the dialog is open
   */
  isOpen: PropTypes.bool,

  /**
   * Function to close the dialog
   */
  onExit: PropTypes.func,
  /**
   * Any other prop available to the drawer component
   */
  ...Drawer.propTypes,
};

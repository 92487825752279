import { useEffect, useMemo, useState } from 'react';

import useProductActions from './useProductActions';

/**
 * @param {import('../../react-types/react-types').CatalogProductFilterDto} inputFilters
 * @param inputOptions
 * @returns {Partial<import('../../react-types/react-types').CatalogProductFilterResponseDto>}
 */
export default function useProductListing(inputFilters, inputOptions) {
  const actions = useProductActions();
  const [products, setProducts] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filters = useMemo(() => inputFilters, [JSON.stringify(inputFilters)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options = useMemo(() => inputOptions, [JSON.stringify(inputOptions)]);

  useEffect(() => {
    const load = async () => {
      const result = await actions.loadProducts(filters, options);
      setProducts(result.payload);
    };

    if (filters) {
      load();
    }
  }, [actions, filters, options]);

  return products;
}

import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';

import Script from '../Script';

/**
 * Render a JavaScript snippet that loads Curalate.
 */
export default function CuralateSnippet({
  siteName = config.get('public.curalate.siteName'),
  skipServerRender = true,
}) {
  const siteNameString = encodeURIComponent(siteName);
  return (
    <Script
      skipServerRender={skipServerRender}
      globalScriptKey={['curalate', siteName]}
      dangerouslySetInnerHTML={{
        __html: `
!function(){var e=window.crl8=window.crl8||{},n=!1,i=[];e.ready=function(e){
n?e():i.push(e)},e.pixel=e.pixel||function(){e.pixel.q.push(arguments)},
e.pixel.q=e.pixel.q||[];var t=window.document,o=t.createElement("script"),
c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";
o.src=t.location.protocol+"//cdn.curalate.com/sites/${siteNameString}/site/latest/site."+c,
o.onload=function(){n=!0,i.forEach(function(e){e()})};var r=t.getElementsByTagName("script")[0];
r.parentNode.insertBefore(o,r.nextSibling)}();
`,
      }}
    />
  );
}

CuralateSnippet.propTypes = {
  /**
   * The site name to put in the Curalate script URL. If not provided, it will
   * attempt to use the `public.curalate.siteName` config option.
   */
  siteName: PropTypes.string,
  /**
   * Whether to skip server side rendering the script.
   */
  skipServerRender: PropTypes.bool,
};

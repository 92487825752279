import React from 'react';

import PropTypes from 'prop-types';

import Script from '../Script';

/**
 * Render a JavaScript snippet that loads Genesys Web Messenger.
 */
export default function GenesysWebChatSnippet({
  deploymentId = '',
  environment = 'use1',
  ...rest
}) {
  return (
    <Script
      async
      globalScriptKey="genesys"
      skipServerRender
      charset="utf-8"
      dangerouslySetInnerHTML={{
        __html: `
          (function (g, e, n, es, ys) {
            g['_genesysJs'] = e;
            g[e] = g[e] || function () {
              (g[e].q = g[e].q || []).push(arguments)
            };
            g[e].t = 1 * new Date();
            g[e].c = es;
            ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
          })(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
            environment: '${environment}',
            deploymentId: '${deploymentId}'
          });
        `,
      }}
      {...rest}
    />
  );
}

GenesysWebChatSnippet.propTypes = {
  deploymentId: PropTypes.string,
  environment: PropTypes.string,
};

import React, {
  useEffect,
  useLayoutEffect as useRealLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { trackProductViewed, trackProductPriceViewed } from '../productsModule';
import { Context } from '../useProductListViewed';

if (process.browser) {
  require('intersection-observer');
}

const useLayoutEffect = process.browser ? useRealLayoutEffect : useEffect;

const ProductListViewedProvider = React.memo(
  function ProductListViewedProvider({
    children,
    root,
    rootMargin = '0px 0px 0px 0px',
    id,
    threshold,
    once = false,
    shouldTrackProductPriceViewed = false,
  }) {
    const dispatch = useDispatch();
    const observer = useRef();
    const productMap = useRef();
    const dispatchedProducts = useRef();
    const dispatchedProductPrices = useRef(new Set());

    if (productMap.current == null) {
      productMap.current = new Map();
    }

    if (dispatchedProducts.current == null) {
      dispatchedProducts.current = new Set();
    }

    useLayoutEffect(() => {
      let disconnecting = false;

      const handleChange = (entries) => {
        if (disconnecting) {
          return;
        }
        entries.forEach((entry) => {
          const isIntersecting =
            entry.isIntersecting && entry.intersectionRatio > 0;
          if (isIntersecting) {
            const productInfo = productMap.current.get(entry.target);
            const masterProductId = productInfo?.product?.masterProductId;

            if (
              shouldTrackProductPriceViewed &&
              !productInfo?.isPriceHidden &&
              entry.intersectionRatio > threshold[1] &&
              (!once || !dispatchedProductPrices.current?.has(masterProductId))
            ) {
              dispatch(trackProductPriceViewed(productInfo, { id }));
              if (masterProductId) {
                dispatchedProductPrices.current.add(masterProductId);
              }
            }

            if (
              once &&
              masterProductId &&
              dispatchedProducts.current?.has(masterProductId)
            ) {
              return;
            }

            if (productInfo) {
              dispatch(trackProductViewed(productInfo, { id }));

              if (masterProductId && dispatchedProducts.current) {
                dispatchedProducts.current.add(masterProductId);
              }
            }
          }
        });
      };

      observer.current = new IntersectionObserver(handleChange, {
        root,
        rootMargin,
        threshold,
      });

      return () => {
        disconnecting = true;
        observer.current.disconnect();
      };
    }, [
      dispatch,
      id,
      root,
      rootMargin,
      shouldTrackProductPriceViewed,
      threshold,
      once,
    ]);

    const context = useMemo(() => {
      return {
        observe(node, productInfo) {
          productMap.current.set(node, productInfo);
          return observer.current.observe(node);
        },
        unobserve(node) {
          productMap.current.delete(node);
          return observer.current.unobserve(node);
        },
      };
    }, []);

    return <Context.Provider value={context}>{children}</Context.Provider>;
  }
);

ProductListViewedProvider.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  /**
   * The `once` option to pass to the IntersectionObserver. A boolean
   * which indicates if the trackProductViewed should be dispatched only once per Grid.
   */
  once: PropTypes.bool,
  /**
   * The `root` option to pass to the IntersectionObserver. It must be a DOM
   * node, not a ref (so that updating it triggers a re-render). If not passed,
   * the browser viewport is used.
   */
  root: PropTypes.object,
  /**
   * The `rootMargin` option to pass to the IntersectionObserver. It can be
   * used to extend or contract the area that triggers what gets set as seen.
   */
  rootMargin: PropTypes.string,
  shouldTrackProductPriceViewed: PropTypes.bool,
  /**
   * The `threshold` option to pass to the IntersectionObserver. An array of
   * values (between 0 and 1.0). Represents distance needed to cover to trigger callback.
   */
  threshold: PropTypes.array,
};

ProductListViewedProvider.Context = Context;

export default ProductListViewedProvider;

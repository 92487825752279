export default function isFeatureEnabled(featureState) {
  if (!featureState) {
    return false;
  }
  const { data: feature } = featureState;
  if (!feature) {
    return null;
  }
  if (!feature.enabled) {
    return false;
  }
  // TODO: handle non-boolean features?
  // (feature.valueType === 'boolean')
  return Boolean(feature.value);
}

import React from 'react';

import { AutoIdProvider as SvgIdProvider } from '@techstyle/babel-plugin-inline-react-svg/auto-id';
import { ThemeProvider } from 'styled-components';

import AppElement from './AppElement';
import AutoIdProvider from './AutoIdProvider';
import GlobalStyle from './GlobalStyle';
import ResponsiveViewport from './ResponsiveViewport';
import StickyProvider from './StickyProvider';
import defaultTheme from './styles/defaultTheme';

function createFontPreloadProps(font) {
  const preferredFormat = font.fileFormats ? font.fileFormats[0] : 'woff2';
  return {
    href: `${font.fontFilePath}.${preferredFormat}`,
    type: `font/${preferredFormat}`,
  };
}

function createFontPreloadLink(props) {
  return (
    // Not a component, just returns an element; pacify ESLint.
    // eslint-disable-next-line react/prop-types
    <link rel="preload" as="font" crossOrigin="" key={props.href} {...props} />
  );
}

export default function styledComponentsExtension(theme, options = {}) {
  const { enableGlobalFontFaces = true } = options;
  theme = {
    ...defaultTheme,
    ...theme,
    breakpoints: {
      ...defaultTheme.breakpoints,
      ...(theme ? theme.breakpoints : null),
    },
    colors: {
      ...defaultTheme.colors,
      ...(theme ? theme.colors : null),
    },
  };
  return {
    id: 'styledComponents',
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
        },
    document: process.browser
      ? undefined
      : {
          getInitialProps: {
            enhance(getInitialProps) {
              const { ServerStyleSheet } = require('styled-components');
              return async (ctx) => {
                const sheet = new ServerStyleSheet();
                const { renderPage } = ctx;
                try {
                  ctx.renderPage = () =>
                    renderPage({
                      enhanceApp: (App) => (props) =>
                        sheet.collectStyles(React.createElement(App, props)),
                    });

                  const initialProps = await getInitialProps(ctx);
                  initialProps.styles = (
                    <>
                      {initialProps.styles}
                      {sheet.getStyleElement()}
                    </>
                  );

                  const fonts = theme.fonts || [];
                  const preloadFonts =
                    theme.preloadFonts || fonts.map(createFontPreloadProps);

                  if (preloadFonts.length) {
                    initialProps.headTop = (
                      <>
                        {preloadFonts.map(createFontPreloadLink)}
                        {initialProps.headTop}
                      </>
                    );
                  }

                  return initialProps;
                } finally {
                  sheet.seal();
                }
              };
            },
          },
        },
    app: {
      render(props, children) {
        return (
          <ThemeProvider theme={theme}>
            <AutoIdProvider>
              <SvgIdProvider>
                <StickyProvider>
                  <AppElement>
                    {
                      // We could make this an option (or get viewport settings from
                      // the theme) later, but for now everyone gets the same
                      // reasonable default viewport behavior.
                    }
                    <ResponsiveViewport />
                    <GlobalStyle
                      enableGlobalFontFaces={enableGlobalFontFaces}
                    />
                    {children}
                  </AppElement>
                </StickyProvider>
              </SvgIdProvider>
            </AutoIdProvider>
          </ThemeProvider>
        );
      },
    },
  };
}

import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { createStore } from 'redux-dynamic-modules';
import createSagaMiddleware, { END } from 'redux-saga';
import { all } from 'redux-saga/effects';

import bentoApiReduxMiddleware from './bentoApiReduxMiddleware';
import createChannelMiddleware from './channelMiddleware';
import createCookieInstanceWithDefaultOptions from './cookieInstance';
import logger from './logger';

const debug = logger.extend('configureStore');

export function createRootSaga(sagas) {
  return function* rootSaga() {
    debug('Running %d saga(s).', sagas.length);
    yield all(sagas.map((saga) => saga()));
    debug('Ending root saga.');
  };
}

export function getDefaultExtensions(context = {}) {
  const sagaMiddleware = createSagaMiddleware();
  context.sagaMiddleware = sagaMiddleware;

  const middleware = [
    createChannelMiddleware(),
    ...getDefaultMiddleware({
      thunk: {
        extraArgument: context,
      },
      serializableCheck: false,
      immutableCheck: process.env.NODE_ENV === 'test',
    }),
    sagaMiddleware,
    bentoApiReduxMiddleware(context),
  ];

  if (process.env.NODE_ENV !== 'production') {
    // Logger middleware: print actions dispatched through the store for
    // easier debugging.
    const { default: createLoggerMiddleware } = require('./loggerMiddleware');

    middleware.push(createLoggerMiddleware());
  }
  return [{ middleware }];
}

export function getInitialMiddleware() {
  return getDefaultExtensions()[0].middleware;
}

/**
 * Create and return a Redux store instance. This function's signature is
 * designed to work with `next-redux-wrapper`.
 */
export default function configureStore(preloadedState, options) {
  // There will either be `options.req` (during SSR getInitialProps) or
  // `preloadedState` (SSR render phase, and client-side hydration), but never
  // both.
  const parentCookieDomain = options.req
    ? options.req.context.domain.parentCookieDomain
    : preloadedState?.domain.parentCookieDomain;
  const context = {
    ...options,
    hasPreloadedState: preloadedState != null,
    // If we have `options.req` then use that existing Cookies instance.
    // However, if `anonymousServerSession` is enabled, we want the React
    // components to ignore the user's real cookies, since the output would be
    // specific to that user, so create an empty Cookies instance instead.
    // In the browser, the new Cookies instance will automatically read the
    // cookies that the browser has access to.
    cookies:
      options.req && !options.req.context.anonymousServerSession
        ? options.req.universalCookies
        : createCookieInstanceWithDefaultOptions({
            domain: parentCookieDomain,
            path: '/',
            secure: true,
          }),
  };

  const { registry, enhancers = [], initialModules = [] } = options;

  // `@techstyle/next-server` extensions can add "modules" and "extensions"
  // (see: `redux-dynamic-modules`) and "sagas" (see: `redux-saga`).
  const reduxModules = [...initialModules];
  const reduxExtensions = [];
  const reduxSagas = [];

  if (registry) {
    registry.forEach((middleware) => {
      let { redux } = middleware;
      if (typeof redux === 'function') {
        redux = redux(context);
      }
      if (redux) {
        if (redux.modules) {
          reduxModules.push(...redux.modules);
          redux.modules.forEach((module) => {
            if (module.sagas) {
              reduxSagas.push(...module.sagas);
            }
          });
        }
        if (redux.extensions) {
          reduxExtensions.push(...redux.extensions);
        }
        if (redux.sagas) {
          reduxSagas.push(...redux.sagas);
        }
      }
    });
  }

  const store = createStore(
    {
      initialState: preloadedState,
      extensions: reduxExtensions,
      enhancers,
    },
    ...reduxModules
  );

  if (context.sagaMiddleware) {
    if (!context.isServer || !context.hasPreloadedState) {
      // Run the root saga only if we're running in the browser or in the
      // `getInitialProps` phase on the server. (When the store gets recreated
      // during the server's render phase, there should be no more actions being
      // dispatched and thus no need for sagas.)
      const rootSaga = createRootSaga(reduxSagas);
      store.rootSagaTask = context.sagaMiddleware.run(rootSaga);
      store.endRootSaga = () => {
        store.dispatch(END);
        return store.rootSagaTask.toPromise();
      };
    }
  }

  return store;
}

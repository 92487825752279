import { schema } from 'normalizr';

// Custom object schema.
// Includes `processStrategy` for pre-processing of input
export default class ObjectSchema extends schema.Object {
  constructor(definition, options = {}) {
    super(definition);
    const { processStrategy = (input) => ({ ...input }) } = options;
    this._processStrategy = processStrategy;
  }

  normalize(input, parent, key, ...rest) {
    const object = this._processStrategy(input, parent, key);
    return super.normalize(object, parent, key, ...rest);
  }
}

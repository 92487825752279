import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getLoyaltyRewardPromos } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useLoyaltyRewardPromos() {
  const actions = useAccountActions();
  const loyaltyRewardPromos = useSelector(getLoyaltyRewardPromos);

  useEffect(() => {
    if (!loyaltyRewardPromos.networkStatus.isUpToDate) {
      actions.loadLoyaltyRewardPromos();
    }
  }, [actions, loyaltyRewardPromos.networkStatus.isUpToDate]);

  return loyaltyRewardPromos;
}

import React from 'react';

import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import { Dropdown } from '../../../techstyle-shared/react-components';

const commonTextStyles = css`
  font-size: 16px;
  line-height: 1;
  padding: 14px 24px;
  color: ${({ theme }) => theme.colors.textDefault};
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: 24px;
`;

const DropdownButton = styled(Dropdown.Button)`
  ${commonTextStyles}
  ${({ theme }) => theme.dropdown};
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.dropdownMenuButton};
  text-align: center;
  display: flex;
  border-radius: 31px;
  border: 1px solid ${({ theme }) => theme.colors.dropdownButtonBorder};
  &[aria-expanded='true'] {
    background-color: ${({ theme }) => theme.colors.dropdownMenuButton};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const DropdownButtonContent = styled.span`
  flex: 1 0 auto;
  ${({ $hasCaret }) =>
    $hasCaret &&
    css`
      margin-left: 10px;
    `};
`;

const Chevron = styled(FaChevronDown)`
  width: 10px;
  flex: 0 0 10px;
  color: ${({ theme }) => theme.colors.sublabelBlack};
  transform: rotate(${({ $direction }) => ($direction === 'up' ? 180 : 0)}deg);
  transform-origin: 50% 50%;
`;

const List = styled(Dropdown.List)`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.palette.white};
  border: 1px solid ${({ theme }) => theme.colors.dropdownListBorder};
  border-top-width: 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  // this is the only way i could get the shadow correct...
  // line height + padding + border
  && {
    padding-top: ${16 + 28 + 2}px;
  }
`;

const ListItem = styled(Dropdown.ListItem)`
  ${commonTextStyles}
  text-align: left;
  border-top: 1px solid ${({ theme }) => theme.colors.dropdownListItemBorder};
  &[aria-current='true'] {
    color: ${({ theme }) => theme.colors.dropdownListItem};
  }
  &:first-child {
    border-top-width: 0;
  }
`;

function Button({ children, 'data-autotag': dataAutotag, showCaret = true }) {
  const [dropdownState] = Dropdown.useDropdownContext();
  return (
    <DropdownButton>
      <DropdownButtonContent $hasCaret={showCaret} data-autotag={dataAutotag}>
        {children}
      </DropdownButtonContent>
      {showCaret && (
        <Chevron $direction={dropdownState.isOpened ? 'up' : 'down'} />
      )}
    </DropdownButton>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  'data-autotag': PropTypes.string,
  showCaret: PropTypes.bool,
};

function Content({ children }) {
  const [dropdownState] = Dropdown.useDropdownContext();
  return <Wrapper $isOpen={dropdownState.isOpened}>{children}</Wrapper>;
}

Content.propTypes = {
  children: PropTypes.node,
};

export default function DropdownMenu({ children, ...rest }) {
  return (
    <Dropdown {...rest}>
      <Content>{children}</Content>
    </Dropdown>
  );
}
DropdownMenu.propTypes = {
  children: PropTypes.node,
};
DropdownMenu.Button = Button;
DropdownMenu.List = List;
DropdownMenu.ListItem = ListItem;

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getAddresses, getDefaultAddress } from './accountsModule';
import useAccountActions from './useAccountActions';

export default function useDefaultAddress() {
  const actions = useAccountActions();
  const addresses = useSelector(getAddresses);
  const defaultAddress = useSelector(getDefaultAddress);

  useEffect(() => {
    if (!addresses.networkStatus.isUpToDate) {
      actions.loadAddresses();
    }
  }, [actions, addresses.networkStatus.isUpToDate]);

  return defaultAddress;
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.fill || props.color || '#303031'};
  vertical-align: top;
`;

const DreamPairsLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 221 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={titleId}>Dream Pairs</title>
      <path
        data-letter="D"
        d="M0.408386 0.287598H11.9437C15.1578 0.287598 17.2007 0.927687 18.5353 2.28959C20.2105 4.00559 20.7689 6.0076 20.7689 10.0661C20.7689 16.3853 20.5646 18.3056 19.7883 19.9535C18.4945 22.5139 16.152 23.6715 12.0799 23.6715H0.408386V0.287598ZM11.2083 19.8037C13.3465 19.8037 14.5313 19.3951 15.2395 18.4145C16.0022 17.325 16.2201 15.9223 16.2201 11.9183C16.2201 7.85979 16.0431 6.52513 15.3076 5.5037C14.6403 4.56399 13.5508 4.18264 11.4807 4.18264H4.88905V19.8037H11.2083Z"
      />
      <path
        data-letter="R"
        d="M24.0101 0.287506H35.5046C41.1565 0.287506 43.0767 2.04437 43.0767 7.27408C43.0767 11.9318 42.2732 13.1984 38.8685 13.8521V14.0291C41.5378 14.1789 42.8725 15.7451 42.8316 18.5915V23.6714H38.3101V19.4631C38.3782 16.9028 37.2886 15.8268 34.81 15.8949H28.4907V23.6714H24.0101V0.287506ZM34.7691 12.068C36.1719 12.068 37.0844 11.8501 37.6155 11.3598C38.3509 10.7742 38.528 10.066 38.528 8.06398C38.528 4.76817 37.956 4.1417 35.0824 4.1417H28.4907V12.068H34.7691Z"
      />
      <path
        data-letter="E"
        d="M50.7994 10.066H61.6947V13.3618H50.7994V19.9534H62.4029V23.6714H46.3051V0.287506H62.2939V4.00551H50.7994V10.066Z"
      />
      <path
        data-letter="A"
        d="M71.2683 19.1908L69.8383 23.6714H65.1397L72.9162 0.287506H79.535L87.4613 23.6714H82.8717L81.36 19.1908H71.2683ZM76.321 3.73313L72.3169 15.8541H80.3522L76.321 3.73313Z"
      />
      <path
        data-letter="M"
        d="M117.125 23.6714H112.712V10.9104C112.712 9.2352 112.739 8.77218 112.889 5.72151L112.957 4.00551H112.821L112.29 5.62617C111.309 8.67683 111.064 9.4804 110.683 10.4882L105.521 23.6714H101.49L96.301 10.5971C95.8516 9.4804 95.6064 8.74494 94.6259 5.72151L94.0947 4.11445H93.9585L94.0675 5.7896C94.2037 8.74494 94.2445 9.4804 94.2445 10.9104V23.6714H89.8183V0.287506H97.0364L101.217 11.1283C101.64 12.2042 101.885 12.9805 102.865 15.963L103.383 17.6109H103.533L104.05 15.9903C104.963 13.0486 105.249 12.245 105.63 11.1555L109.77 0.287506H117.125V23.6714Z"
      />
      <path
        data-letter="P"
        d="M128.74 0.287689H138.205C140.698 0.287689 140.943 0.287689 141.814 0.369399C143.884 0.505589 145.491 1.37721 146.377 2.82083C147.139 4.11464 147.425 5.62636 147.425 8.45912C147.425 12.8036 146.336 14.9418 143.639 15.7453C142.686 16.0722 142.686 16.0722 138.45 16.1403H133.221V23.6716H128.74V0.287689ZM138.273 12.2725C142.236 12.2725 142.863 11.7549 142.863 8.39102C142.863 5.78978 142.591 4.85007 141.678 4.35979C141.038 4.07379 140.616 4.0057 138.723 4.0057H133.221V12.2725H138.273Z"
      />
      <path
        data-letter="A"
        d="M152.37 19.1909L150.926 23.6716H146.228L154.018 0.287689H160.637L168.563 23.6716H163.96L162.462 19.1909H152.37ZM157.409 3.73331L153.419 15.8543H161.44L157.409 3.73331Z"
      />
      <path
        data-letter="I"
        d="M175.891 23.6716H171.396V0.287689H175.891V23.6716Z"
      />
      <path
        data-letter="R"
        d="M179.091 0.287689H190.585C196.237 0.287689 198.157 2.04455 198.157 7.27427C198.157 11.932 197.354 13.1985 193.949 13.8523V14.0293C196.618 14.1791 197.953 15.7453 197.912 18.5917V23.6716H193.391V19.4633C193.459 16.9029 192.383 15.827 189.891 15.8951H183.585V23.6716H179.091V0.287689ZM189.85 12.0682C191.252 12.0682 192.165 11.8503 192.696 11.36C193.432 10.7744 193.609 10.0662 193.609 8.06416C193.609 4.76835 193.037 4.14189 190.163 4.14189H183.585V12.0682H189.85Z"
      />
      <path
        data-letter="S"
        d="M215.33 7.12457V6.70238C215.33 4.15562 214.595 3.62448 210.986 3.62448C206.737 3.62448 205.892 4.15563 205.892 6.81134C205.892 8.5001 206.314 9.2628 207.445 9.6169C208.03 9.7939 208.248 9.7939 211.885 9.9982C215.82 10.2433 217.155 10.4885 218.272 11.3056C219.702 12.2862 220.192 13.6481 220.192 16.6307C220.192 19.5315 219.784 21.1113 218.762 22.1328C217.359 23.4947 215.575 23.876 210.7 23.876C206.464 23.876 205.021 23.6309 203.482 22.732C202.61 22.2009 201.861 21.0841 201.616 19.8448C201.412 18.837 201.371 18.1288 201.343 16.2766H205.647C205.647 18.0334 205.756 18.6191 206.137 19.2183C206.709 20.1716 207.268 20.267 210.904 20.267C213.532 20.267 214.268 20.1716 214.935 19.6405C215.534 19.2183 215.78 18.3058 215.78 16.6988C215.78 15.1598 215.426 14.4244 214.486 14.0975C213.927 13.8932 213.927 13.8932 210.032 13.6481C206.042 13.403 205.198 13.2668 203.999 12.6948C202.147 11.7959 201.412 10.1072 201.412 6.87944C201.412 4.11477 202.011 2.39877 203.264 1.4863C204.707 0.396767 206.532 0.0835266 210.877 0.0835266C215.262 0.0835266 217.155 0.532957 218.299 1.84039C219.212 2.8482 219.634 4.25095 219.634 6.29381V7.12457H215.33Z"
      />
    </Svg>
  );
});

DreamPairsLogo.displayName = 'DreamPairsLogo';

DreamPairsLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default DreamPairsLogo;

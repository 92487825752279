// for now, we dont have a way to PREVENT translations from
// working in all store groups; though, likely not really a problem.
// we maintain the key:value structure here in case we need to do
// more advanced manipulation in the future, e.g. build time etc.
export default [
  {
    page: 'help/faq',
    aliases: ['/help'],
    intl: {
      '.ca': '/faq',
      '.de': '/hilfe/fragen-faq',
      '.dk': '/kundeservice/faq',
      '.es': '/ayuda/faq',
      '.fr': '/aide/faq',
      '.se': '/hjalp/faq',
    },
  },
  {
    page: 'account/profile',
    intl: {
      '.de': '/konto/profil',
      '.es': '/cuenta/perfil',
      '.fr': '/mon-compte/profil',
    },
  },
  {
    page: 'account/orders',
    intl: {
      '.de': '/konto/bestellungen',
      '.es': '/cuenta/pedidos',
      '.fr': '/mon-compte/commandes',
    },
  },
  {
    page: 'account/orders',
    pattern: ':orderId(\\d+)',
    intl: {
      '.de': '/konto/bestellungen',
      '.es': '/cuenta/pedidos',
      '.fr': '/mon-compte/commandes',
    },
  },
  {
    page: 'account/payments',
    intl: {
      '.de': '/konto/zahlungen',
      '.es': '/cuenta/pagos',
      '.fr': '/mon-compte/paiements',
    },
  },
  {
    page: 'account/shipping',
    intl: {
      '.de': '/konto/versand',
      '.es': '/cuenta/envio',
      '.fr': '/mon-compte/livraison',
    },
  },
  {
    page: 'account/my-vip',
    intl: {
      '.de': '/konto/meine-mitgliedschaft',
      '.es': '/cuenta/mi-suscripcion',
      '.fr': '/mon-compte/my-vip',
    },
  },
  {
    page: 'account/initiate-return',
    intl: {
      '.de': '/konto/retoure-einleiten',
      '.es': '/cuenta/iniciar-devolucion',
      '.fr': '/mon-compte/demande-retour',
    },
  },
  {
    page: 'account/complete-return',
    pattern: ':orderId(\\d+)',
    intl: {
      '.com': '/account/complete-return',
      '.de': '/konto/retoure-abschliesen',
      '.es': '/cuenta/completar-devolucion',
      '.fr': '/mon-compte/validation-retour',
    },
  },
  {
    page: 'account/return-confirmation',
    pattern: ':rmaId(\\d+)',
    intl: {
      '.com': '/account/return-confirmation',
      '.de': '/konto/retoure-bestatigen',
      '.es': '/cuenta/confirmacion-devolucion',
      '.fr': '/mon-compte/confirmation-retour',
    },
  },
  {
    page: 'account/write-review',
    pattern: ':reviewId(\\d+)',
    intl: {
      '.de': '/konto/bewertung-hinterlassen',
      '.es': '/cuenta/escribir-opinion',
      '.fr': '/mon-compte/rediger-avis',
    },
  },
  {
    page: 'account/reviews',
    intl: {
      '.de': '/konto/bewertungen',
      '.es': '/cuenta/opiniones',
      '.fr': '/mon-compte/avis',
    },
  },
  {
    page: 'account/pre-orders',
    isExternal: true,
    intl: {
      '.de': '/konto/vorbestellungen',
      '.es': '/cuenta/reservas',
      '.fr': '/mon-compte/pre-commandes',
    },
  },
  {
    page: 'account/cancel',
    intl: {
      '.de': '/konto/abbrechen',
      '.es': '/cuenta/cancelar',
      '.fr': '/mon-compte/annuler',
    },
  },
  {
    page: 'account/waitlist',
    isExternal: true,
    intl: {
      '.co.uk': '/account/waitinglist',
      '.de': '/konto/warteliste',
      '.dk': '/account/waitinglist',
      '.es': '/cuenta/lista-espera',
      '.fr': '/mon-compte/liste-d-attente',
      '.nl': '/account/waitinglist',
      '.se': '/account/waitinglist',
    },
  },
  {
    page: 'benefits/rewardsshop',
    isExternal: true,
    intl: {
      '.de': '/benefits/belohnungspunkte',
      '.es': '/benefits/recompensas',
      '.fr': '/benefits/mes-recompenses',
    },
  },
  {
    page: 'account/favorites',
    intl: {
      '.co.uk': '/account/favourites',
      '.de': '/konto/favoriten',
      '.dk': '/account/favourites',
      '.es': '/cuenta/favoritos',
      '.fr': '/mon-compte/favoris',
      '.nl': '/account/favourites',
      '.se': '/account/favourites',
    },
  },
  {
    page: 'communication-preference-center',
    intl: {
      '.de': '/newsletter-einstellungen',
    },
  },
  {
    page: 'guides/fabric',
    intl: {
      '.de': '/guide/material',
      '.es': '/guia/tejidos',
      '.fr': '/guide/matieres',
    },
  },
  {
    page: 'mens/size-guide',
    intl: {
      '.de': '/herren/groessentabelle',
      '.fr': '/homme/guide-des-tailles',
      '.es': '/hombre/guia-tallas',
    },
  },
  {
    page: 'womens/size-guide',
    intl: {
      '.de': '/damen/groessentabelle',
      '.fr': '/femme/guide-des-tailles',
      '.es': '/mujer/guia-tallas',
    },
  },
  {
    page: 'store-locations',
    intl: {
      '.de': '/filialen',
    },
  },
];

import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getDomainRedirectFunction } from './domainModule';

export default function useDomain() {
  const domain = useSelector((state) => state.domain);
  const getDomainRedirect = useSelector(getDomainRedirectFunction);
  return useMemo(
    () => ({
      ...domain,
      getDomainRedirect,
    }),
    [domain, getDomainRedirect]
  );
}

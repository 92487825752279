import { createAction } from '../../techstyle-shared/redux-core';

export const trackVideoPlaybackStarted = createAction(
  'TRACK_VIDEO_PLAYBACK_STARTED'
);

export const trackVideoPlaybackCompleted = createAction(
  'TRACK_VIDEO_PLAYBACK_COMPLETED'
);

export const trackShoppableVideoIntroModalPageView = createAction(
  'TRACK_SHOPPABLE_VIDEO_INTRO_MODAL_PAGE_VIEW'
);

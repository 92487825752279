import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import SectionContext from '../SectionContext';

/**
 * A component for rendering sections of the page while automatically
 * incrementing the current heading level, for use with the `<Heading>`
 * component. Use this to automatically provide a sensible document outline
 * structure.
 *
 * To discourage rendering level 1 headings, the default heading level is 2, and
 * the first `<Section>` to render does not increment that. This may seem
 * surprising in isolation, but is designed with the most likely usage patterns
 * in mind.
 *
 * ```jsx
 * <Heading>No section ancestor, this will be level 2.</Heading>
 * <Section>
 *   <Heading>First section, this will also be level 2!</Heading>
 *   <Section>
 *     <Heading>Now it's level 3.</Heading>
 *   </Section>
 * </Section>
 * ```
 */
export default function Section({
  as: ElementType = 'section',
  level,
  ...rest
}) {
  // Default to `section`, but allow `as={null}` to mean `as={React.Fragment}`.
  if (ElementType == null) {
    ElementType = React.Fragment;
  }
  const currentLevel = useContext(SectionContext) || 1;
  if (typeof level === 'function') {
    level = level(currentLevel);
  }
  const newLevel = level == null ? currentLevel + 1 : level;
  return (
    <SectionContext.Provider value={newLevel}>
      <ElementType {...rest} />
    </SectionContext.Provider>
  );
}

Section.propTypes = {
  /**
   * The element type to render, defaults to `section`. To render no wrapper
   * element (but still increment the heading level), use `as={null}` or
   * (equivalent) `as={React.Fragment}`.
   */
  as: PropTypes.elementType,
  /**
   * Override the new section depth with a custom level value. If given as a
   * function, it will be called with the current level so you can make a
   * decision based on it.
   */
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
};

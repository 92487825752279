import { createAction } from '../../../techstyle-shared/redux-core';

export const oktaVerifyRequest = createAction('admin/oktaVerifyRequest');
export const oktaVerifySuccess = createAction('admin/oktaVerifySuccess');
export const oktaVerifyFailure = createAction('admin/oktaVerifyFailure');

export const azureValidateRequest = createAction('admin/azureValidateRequest');
export const azureValidateSuccess = createAction('admin/azureValidateSuccess');
export const azureValidateFailure = createAction('admin/azureValidateFailure');

export function oktaVerify(payload) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'auth/okta/verify',
      json: payload,
      actions: [oktaVerifyRequest, oktaVerifySuccess, oktaVerifyFailure],
    },
  };
}

export const oktaLogoutRequest = createAction('admin/oktaLogoutRequest');
export const oktaLogoutSuccess = createAction('admin/oktaLogoutSuccess');
export const oktaLogoutFailure = createAction('admin/oktaLogoutFailure');

export function oktaLogout() {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'auth/okta/logout',
      actions: [oktaLogoutRequest, oktaLogoutSuccess, oktaLogoutFailure],
    },
  };
}

export function azureValidate(options) {
  const { headers = {} } = options;
  headers['x-tfg-content-preview'] = 'true';

  return {
    bentoApi: {
      endpoint: 'auth/azure/validate',
      headers,
      actions: [
        azureValidateRequest,
        azureValidateSuccess,
        azureValidateFailure,
      ],
    },
  };
}

export const azureInvalidateRequest = createAction(
  'admin/azureInvalidateRequest'
);
export const azureInvalidateSuccess = createAction(
  'admin/azureInvalidateSuccess'
);
export const azureInvalidateFailure = createAction(
  'admin/azureInvalidateFailure'
);

export function azureInvalidate() {
  return {
    bentoApi: {
      endpoint: 'auth/azure/invalidate',
      actions: [
        azureInvalidateRequest,
        azureInvalidateSuccess,
        azureInvalidateFailure,
      ],
    },
  };
}

export default {
  id: 'admin',
  reducerMap: {},
};

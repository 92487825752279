import { css } from 'styled-components';

export function colorStyles({ backgroundColor, textColor }) {
  return {
    backgroundColor,
    color: textColor,
  };
}

export function highlightStyles({ highlightAssets, theme }) {
  if (highlightAssets) {
    if (theme && theme.assets && theme.assets.highlightStyles) {
      return theme.assets.highlightStyles;
    }
    return css`
      outline: 2px solid rgba(41, 236, 255, 0.8);
      outline-offset: -2px;
      box-shadow: 0 0 15px rgba(38, 255, 235, 0.7);
    `;
  }
}

/**
 * Return a media query that should come after `@media` for targeting "mobile"
 * screen sizes.
 *
 * - If `assets.mobileBreakpoint` is defined on the theme, we prefer that.
 * - Second choice is the theme's generic `mobileBreakpoint`.
 * - Lastly, fall back to a hardcoded breakpoint of <= 767px.
 */
export function mobileBreakpoint({ theme }) {
  if (theme) {
    // TODO: Use the new `breakpoints.mobile` standard.
    if (theme.assets && theme.assets.mobileBreakpoint) {
      return theme.assets.mobileBreakpoint;
    }
    if (theme.mobileBreakpoint) {
      return theme.mobileBreakpoint;
    }
  }
  return 'screen and (max-width: 767px)';
}

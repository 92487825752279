import React from 'react';

import { Transition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { useToastContext } from '../ToastContext';
import withThemeProps from '../withThemeProps';

const transitionIn = css`
  transform: translate3d(0, 0, 0);
`;

const transitionOut = css`
  transform: translate3d(0, -200%, 0);
`;

type WrapperProps = {
  $transitionState: string;
};

const Wrapper = styled.div<WrapperProps>`
  left: 0;
  right: 0;
  height: ${(props: { $transitionState: string }) =>
    props.$transitionState === 'exited' ? 0 : 'auto'};
  ${(props: { $transitionState: string }) =>
    props.$transitionState === 'exited' || props.$transitionState === 'exiting'
      ? transitionOut
      : transitionIn};
  transition-property: transform;
  transition-duration: 300ms;
  z-index: 3;
  overflow: hidden;
`;

type ToastMessageProps = React.ComponentPropsWithRef<typeof Wrapper> & {
  children: React.ReactNode;
};

// This isn't currently ideal because there is a bug in react-transition-group:
// https://github.com/reactjs/react-transition-group/issues/338
const ToastMessage = React.forwardRef(({ children, ...rest }: any, ref) => {
  const { hideMessage, isOpen } = useToastContext();

  return (
    <Transition in={!!isOpen} timeout={300}>
      {(state) => {
        return (
          <Wrapper
            $transitionState={state}
            aria-hidden={!isOpen}
            onClick={hideMessage}
            ref={ref}
            {...rest}
          >
            {children}
          </Wrapper>
        );
      }}
    </Transition>
  );
});

ToastMessage.displayName = 'ToastMessage';

export default withThemeProps<ToastMessageProps>(ToastMessage, {
  section: 'toastMessage',
  defaultVariant: 'default',
});

export type { ToastMessageProps };

import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import Overflow from 'react-overflow-indicator';
import styled, { css } from 'styled-components';

import {
  FormattedMessage,
  useIntl,
  defineMessages,
} from '../../../../techstyle-shared/react-intl';
import Button from '../Button';
import Drawer from '../Drawer';
import FilterTags, {
  AggregationFilterTags,
  CategoryFilterTags,
} from '../FilterTagsCustomFilters';
import { useProductBrowser } from '../ProductBrowserContext';
import { ProductFilters } from '../ProductBrowserCustomFilters';
import ProductFilterContext, {
  useProductFilters,
} from '../ProductFilterContextCustomFilters';
import { getTotalFilterCountCustomFilters } from '../utils/category';
import { openCustomFilterDrawers } from '../utils/filters';

const messages = defineMessages({
  filterDrawerTitle: {
    id: 'site_product_grid.filter_drawer_title',
    defaultMessage: 'Product Filters',
  },
});

const FiltersOverflow = styled(Overflow)`
  flex: 1 1 auto;
  overflow: auto;
`;

const AllFiltersWrapper = styled.div`
  padding-bottom: 16px;
`;

const ApplyFiltersWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  padding: 16px;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ApplyFilters = styled(Button).attrs({
  'data-autotag': 'filter-apply',
  variant: 'applyFilters',
})`
  min-width: 248px;
  height: 48px;
  border: none;
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: ${-0.4 / 16}em;
  text-align: center;

  :disabled {
    color: #999;
    background-color: #ddd;
  }

  ${({ buttonStyle }) => buttonStyle};
`;

const getDialogStyle = (additionalStyles) => css`
  background-color: #ffffff;
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.04), inset 1px 0 0 0 #eeeeee;
  width: 280px;
  max-width: calc(100vw - 44px);
  overflow: visible;

  ${additionalStyles}
`;

const OverflowFade = styled.div`
  box-sizing: content-box;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 16px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #ffffff 20%
  );
  pointer-events: none;
`;

// Needs a z-index of 1 because the shadow won't display over the checkmark areas
const OverflowShadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  pointer-events: none;
  z-index: 1;
`;

const getOverlayStyle = (additionalStyles) => css`
  background-color: rgba(0, 0, 0, 0.5);
  will-change: background-color;

  ${additionalStyles}
`;

const CloseButton = styled(Button).attrs({
  variant: 'closeFilterDrawer',
})`
  position: absolute;
  right: 100%;
  width: 44px;
  height: auto;
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-family: inherit;
  line-height: 1;
  letter-spacing: ${-0.3 / 12}em;
  color: #ffffff;
  text-align: center;
  padding: 10px 4px;

  ${({ closeButtonStyle }) => closeButtonStyle};
`;

const TimesIcon = styled(MdClose)`
  font-size: 16px;
  color: inherit;
  vertical-align: middle;
`;

const FilterTagsWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  padding: 13px 16px;

  ${({ wrapperStyle }) => wrapperStyle};
`;

const NoFilterAppliedMessage = styled.p`
  font-size: 16px;
  line-height: ${22 / 16};
  letter-spacing: ${-0.4 / 16}em;
  color: #999999;

  ${({ noFiltersAppliedStyle }) => noFiltersAppliedStyle};
`;

function ApplyFiltersButton({
  buttonStyle,
  children,
  onClickCallback: onClickFromProps,
  ...rest
}) {
  const { applySelectedFilters, isDirty, selectedFilters } =
    useProductFilters();

  const onApplyFilters = useCallback(() => {
    if (onClickFromProps) {
      onClickFromProps(selectedFilters);
      applySelectedFilters();
    } else {
      applySelectedFilters();
    }
  }, [applySelectedFilters, onClickFromProps, selectedFilters]);

  return (
    <ApplyFilters
      onClick={onApplyFilters}
      disabled={!isDirty}
      buttonStyle={buttonStyle}
      {...rest}
    >
      {children || (
        <FormattedMessage
          id="site_product_grid.apply_filters"
          defaultMessage="Apply Filters"
        />
      )}
    </ApplyFilters>
  );
}

ApplyFiltersButton.propTypes = {
  buttonStyle: PropTypes.any,
  children: PropTypes.node,
  /**
   * An additional `onClick` that is only called here, allowing the
   * existing onClick to be overridded for backward compatibility.
   */
  onClickCallback: PropTypes.func,
};

// We will always get categoryFilter from FilterDrawer either from props or from ProductBrowserContext
const FilterDrawerTags = ({
  applyOnClearAll = false,
  as: ElementType = FilterTags,
  baseFilters: baseFiltersFromProps,
  categoryFilter: categoryFilterFromProps,
  noFiltersAppliedStyle,
  wrapperStyle,
  ...rest
}) => {
  const {
    applyFilters,
    filterSettings,
    selectedFilters,
    setFilterSettings,
    setSelectedFilters,
    toggleCollapsibleFilterIsOpen,
  } = useProductFilters();
  const { openFilterDrawersOnClearAll, ...productBrowserCtx } =
    useProductBrowser();
  let baseFilters = baseFiltersFromProps;
  let categoryFilter = categoryFilterFromProps;

  if (productBrowserCtx) {
    const {
      baseFilters: baseFiltersFromCtx,
      categoryFilter: categoryFilterFromCtx,
    } = productBrowserCtx;

    if (!baseFiltersFromProps) {
      baseFilters = baseFiltersFromCtx;
    }
    if (!categoryFilterFromProps) {
      categoryFilter = categoryFilterFromCtx;
    }
  }

  // Check if any of the filterSettings have a base value that we'll
  // need to reset them back to.
  const shouldClearFilterSettings = filterSettings.some(
    ({ getBaseValue }) => typeof getBaseValue === 'function'
  );

  const clearAllFilters = useCallback(() => {
    let baseFilterSettings = filterSettings;
    if (shouldClearFilterSettings) {
      baseFilterSettings = filterSettings.map((filterSetting) =>
        typeof filterSetting.getBaseValue === 'function'
          ? {
              ...filterSetting,
              ...filterSetting.getBaseValue({ filterSetting, selectedFilters }),
            }
          : filterSetting
      );
    }
    if (applyOnClearAll) {
      applyFilters({
        newFilters: baseFilters,
        newFilterSettings: baseFilterSettings,
      });
    } else {
      setSelectedFilters(baseFilters);
      setFilterSettings(baseFilterSettings);
      if (openFilterDrawersOnClearAll) {
        // Open all filter drawers;
        openCustomFilterDrawers(
          toggleCollapsibleFilterIsOpen,
          baseFilterSettings
        );
      } else {
        // Close all filter drawers;
        toggleCollapsibleFilterIsOpen({ type: 'reset' });
      }
    }
  }, [
    applyFilters,
    applyOnClearAll,
    baseFilters,
    filterSettings,
    openFilterDrawersOnClearAll,
    selectedFilters,
    setFilterSettings,
    setSelectedFilters,
    shouldClearFilterSettings,
    toggleCollapsibleFilterIsOpen,
  ]);

  const totalSelectedFilterCount = getTotalFilterCountCustomFilters(
    filterSettings,
    selectedFilters
  );

  return (
    <FilterTagsWrapper wrapperStyle={wrapperStyle}>
      {totalSelectedFilterCount > 0 ? (
        <FilterTags onClearAll={clearAllFilters} {...rest}>
          {categoryFilter && (
            <CategoryFilterTags
              categoryFilter={categoryFilter}
              selectedFilters={selectedFilters}
              {...rest}
            />
          )}
          {filterSettings.map((filterSetting) => {
            const { FilterTagComponent = AggregationFilterTags, field } =
              filterSetting;

            return (
              <React.Fragment key={field}>
                <FilterTagComponent
                  filterSetting={filterSetting}
                  selectedFilters={selectedFilters}
                />
              </React.Fragment>
            );
          })}
        </FilterTags>
      ) : (
        <NoFilterAppliedMessage noFiltersAppliedStyle={noFiltersAppliedStyle}>
          <FormattedMessage
            id="site_product_grid.no_filter_applied"
            defaultMessage="No filters applied"
          />
        </NoFilterAppliedMessage>
      )}
      <Overflow.Indicator direction="up">
        <OverflowShadow />
      </Overflow.Indicator>
    </FilterTagsWrapper>
  );
};

FilterDrawerTags.propTypes = {
  applyOnClearAll: PropTypes.bool,
  as: PropTypes.elementType,
  baseFilters: PropTypes.object,
  categoryFilter: PropTypes.object,
  filterSettings: PropTypes.object,
  noFiltersAppliedStyle: PropTypes.any,
  wrapperStyle: PropTypes.any,
};

const OverflowFilterContent = ({ children, ...rest }) => {
  return (
    <Overflow.Content>
      <AllFiltersWrapper {...rest}>{children}</AllFiltersWrapper>
    </Overflow.Content>
  );
};

OverflowFilterContent.propTypes = {
  children: PropTypes.node,
};

const ApplyFiltersSection = ({
  buttonStyle,
  onClickCallback: onClickFromProps,
  ...rest
}) => (
  <ApplyFiltersWrapper {...rest}>
    <Overflow.Indicator direction="down">
      <OverflowFade />
    </Overflow.Indicator>
    <ApplyFiltersButton
      buttonStyle={buttonStyle}
      onClickCallback={onClickFromProps}
    />
  </ApplyFiltersWrapper>
);

ApplyFiltersSection.propTypes = {
  buttonStyle: PropTypes.any,
  onClickCallback: PropTypes.func,
};

const FilterDrawer = React.forwardRef(
  (
    {
      baseFilters: baseFiltersFromProps,
      categoryFilter: categoryFilterFromProps,
      children,
      closeButtonStyle,
      closeIcon = <TimesIcon />,
      colorSwatches: colorSwatchesFromProps,
      dialogStyle: dialogStyleFromProps,
      filters: filtersFromProps,
      filterSettings: filterSettingsFromProps,
      isOpen: isOpenFromProps,
      onExit: onExitFromProps,
      onSubmit: onSubmitFromProps,
      overlayStyle: overlayStyleFromProps,
      productFilterOptions,
      renderCloseButton,
      ...rest
    },
    ref
  ) => {
    const productBrowserCtx = useProductBrowser();
    const dialogStyle = useMemo(
      () => getDialogStyle(dialogStyleFromProps),
      [dialogStyleFromProps]
    );
    const overlayStyle = useMemo(
      () => getOverlayStyle(overlayStyleFromProps),
      [overlayStyleFromProps]
    );

    let filterSettings = filterSettingsFromProps;
    let baseFilters = baseFiltersFromProps;
    let categoryFilter = categoryFilterFromProps;
    let isOpen = isOpenFromProps;

    if (productBrowserCtx) {
      const {
        filterSettings: filterSettingsFromCtx,
        baseFilters: baseFiltersFromCtx,
        categoryFilter: categoryFilterFromCtx,
        isFilterDrawerOpen: isOpenFromCtx,
      } = productBrowserCtx;
      if (filterSettingsFromProps == null) {
        filterSettings = filterSettingsFromCtx;
      }
      if (baseFiltersFromProps == null) {
        baseFilters = baseFiltersFromCtx;
      }
      if (categoryFilterFromProps == null) {
        categoryFilter = categoryFilterFromCtx;
      }
      if (isOpenFromProps == null) {
        isOpen = isOpenFromCtx;
      }
    }

    const { appliedFilters } = useProductFilters();
    const intl = useIntl();

    const onExit = useCallback(() => {
      if (onExitFromProps) {
        onExitFromProps();
      } else if (productBrowserCtx) {
        productBrowserCtx.setFilterDrawerOpen(false);
      }
    }, [onExitFromProps, productBrowserCtx]);

    const handleSubmit = useCallback(
      ({ newFilters, newFilterSettings }) => {
        if (onSubmitFromProps) {
          onSubmitFromProps(newFilters);
        } else if (productBrowserCtx) {
          productBrowserCtx.setFilters(newFilters);
          productBrowserCtx.setFilterSettings(newFilterSettings);
        }
        onExit();
      },
      [onExit, onSubmitFromProps, productBrowserCtx]
    );

    return (
      <Drawer
        data-autotag="filter-overlay"
        dialogStyle={dialogStyle}
        overlayStyle={overlayStyle}
        isOpen={isOpen}
        onExit={onExit}
        position="right"
        ref={ref}
        title={intl.formatMessage(messages.filterDrawerTitle)}
        {...rest}
      >
        <ProductFilterContext
          appliedFilters={appliedFilters}
          filterSettings={filterSettings}
          onApply={handleSubmit}
        >
          {isOpen &&
            (typeof renderCloseButton === 'function' ? (
              renderCloseButton({ onExit })
            ) : (
              <CloseButton onClick={onExit} closeButtonStyle={closeButtonStyle}>
                <>
                  {closeIcon}
                  <br />
                  <FormattedMessage
                    id="site_product_grid.close_filters"
                    defaultMessage="close"
                  />
                </>
              </CloseButton>
            ))}

          {children == null ? (
            <FiltersOverflow>
              <FilterDrawerTags
                baseFilters={baseFilters}
                categoryFilter={categoryFilter}
              />
              <OverflowFilterContent>
                <ProductFilters
                  categoryFilter={categoryFilter}
                  colorSwatches={colorSwatchesFromProps}
                  {...productFilterOptions}
                />
              </OverflowFilterContent>
              <ApplyFiltersWrapper>
                <Overflow.Indicator direction="down">
                  <OverflowFade />
                </Overflow.Indicator>
                <ApplyFiltersButton />
              </ApplyFiltersWrapper>
            </FiltersOverflow>
          ) : (
            children
          )}
        </ProductFilterContext>
      </Drawer>
    );
  }
);

FilterDrawer.displayName = 'FilterDrawer';

FilterDrawer.propTypes = {
  baseFilters: PropTypes.object,
  categoryFilter: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    totalAggregationCount: PropTypes.number,
    items: PropTypes.array,
  }),
  /**
   * children can either be a function or node. If children is a function,
   * you'll need to render FilterDrawer.Content with FilterDrawer.FilterTags,
   * FilterDrawer.Filters wrapping the filters you want to display, and
   * FilterDrawer.ApplyFilters as FilterDrawer.Content's children.
   * The function is passed the FilterDrawer's: aggregations, baseFilters, and categoryFilter.
   */
  children: PropTypes.node,
  closeButtonStyle: PropTypes.any,
  closeIcon: PropTypes.node,
  colorSwatches: PropTypes.object,
  dialogStyle: PropTypes.any,
  filterSettings: PropTypes.array,
  filters: PropTypes.object,
  isOpen: PropTypes.bool,
  /**
   * Callback function when the filters change.
   */
  onChange: PropTypes.func,
  onExit: PropTypes.func,
  /**
   * Callback function when user clicks "Apply Filters" button.
   */
  onSubmit: PropTypes.func,
  overlayStyle: PropTypes.any,
  /**
   * Options to pass as props to ProductFilters
   */
  productFilterOptions: PropTypes.object,
  /**
   * Function to display any custom close filter drawer button.
   */
  renderCloseButton: PropTypes.func,
};

export default FilterDrawer;

FilterDrawer.Content = FiltersOverflow;
FilterDrawer.FilterTags = FilterDrawerTags;
FilterDrawer.Filters = OverflowFilterContent;
FilterDrawer.ApplyFilters = ApplyFiltersSection;

import { useEffect, useState } from 'react';

import useRetailStoresActions from './useRetailStoresActions';

/* 
options should contain a searchParams object, which should contain a
filterString formatted like key=value, joined by "&"
for example:
region=NA
region=NA&yitty_products=Y

See swagger docs:
https://retailstore-api-retail.techstyle.tech/_swagger/#/RetailStore/RetailstoreController_getRetailStoreList
*/
export const useRetailStoresWithFilter = (options = {}) => {
  const actions = useRetailStoresActions();
  const [retailStores, setRetailStores] = useState([]);
  const optionsString = JSON.stringify(options);

  useEffect(() => {
    const load = async () => {
      const result = await actions.loadRetailStoresWithFilter(options);
      setRetailStores(result.payload);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, optionsString]);
  return retailStores;
};

export default useRetailStoresWithFilter;

import { schema } from '../../../techstyle-shared/redux-core';

import { toDigitString, toFullYear, convertEmptyStringsToNull } from './utils';

export const CustomerDetailError = {
  OTHER: 'OTHER',
};

export const MembershipDetailError = {
  OTHER: 'OTHER',
};

export const OrderDetailError = {
  OTHER: 'OTHER',
};

export const RetailStoresError = {
  OTHER: 'OTHER',
};

export const ForeignMembershipError = {
  OTHER: 'OTHER',
};

export const Address = new schema.Entity(
  'Address',
  {},
  {
    idAttribute: 'addressId',
    processStrategy(input, parent, key) {
      return {
        ...input,
        // Zip/postal codes are not numbers, they are strings that often consist
        // of digits.
        zip: typeof input.zip === 'number' ? input.zip.toString() : input.zip,
      };
    },
  }
);

export const PaymentMethod = new schema.Entity(
  'PaymentMethod',
  {
    address: Address,
  },
  {
    idAttribute(input) {
      return input.creditCardId || input.paymentServiceProviderId;
    },
    processStrategy(input, parent, key) {
      const {
        address1,
        address2,
        address3,
        address4,
        address5,
        address6,
        address7,
        address8,
        addressId,
        addressTypeId,
        city,
        company,
        countryCode,
        email,
        firstName,
        lastName,
        name,
        phone,
        phoneDigits,
        state,
        zip,
        ...paymentMethod
      } = convertEmptyStringsToNull(input);

      // Ensure `expMonth` is a number or null.
      const expMonth =
        paymentMethod.expMonth != null
          ? parseInt(paymentMethod.expMonth, 10)
          : null;

      // ...and add `expMonthString`, the zero-padded 2-digit string or null.
      const expMonthString = toDigitString(expMonth, 2);

      // Ensure `expYear` is a number or null.
      const expYear =
        paymentMethod.expYear != null
          ? parseInt(paymentMethod.expYear, 10)
          : null;

      // ...and add `expYearString`, the zero-padded 2-digit string or null.
      const expYearString = toDigitString(expYear, 2);

      // Add `expFullYear`, the full numeric year.
      const expFullYear = expYear != null ? toFullYear(expYear) : null;

      // Credit card numbers are not numbers, they are strings that consist of
      // digits. To prevent `lastFourDigits` from being something like `15` when
      // the last four digits are `015`, we need to convert and zero-pad this
      // ourselves.
      const lastFourDigits = toDigitString(paymentMethod.lastFourDigits, 4);

      return {
        ...paymentMethod,
        address: {
          address1,
          address2,
          address3,
          address4,
          address5,
          address6,
          address7,
          address8,
          addressId,
          addressTypeId,
          city,
          company,
          countryCode,
          email,
          firstName,
          lastName,
          name,
          phone,
          phoneDigits,
          state,
          zip,
        },
        // Is sometimes a string or number.
        isValidated: Boolean(paymentMethod.isValidated),
        expMonth,
        expMonthString,
        expYear,
        expYearString,
        expFullYear,
        lastFourDigits,
      };
    },
  }
);

export const LinkedProfile = new schema.Entity(
  'LinkedProfile',
  {},
  {
    idAttribute: 'membershipProfileId',
    processStrategy(input, parent, key) {
      const { enableAutoShip, membershipId, ...linkedProfile } = input;

      if (linkedProfile.isCurrent) {
        const {
          ageGroupId,
          hasTakenQuiz,
          primaryPersonalityType,
          productOptionProfileId,
        } = parent;

        Object.assign(linkedProfile, {
          ageGroupId,
          hasTakenQuiz,
          primaryPersonalityType,
          productOptionProfileId,
        });
      }
      return linkedProfile;
    },
  }
);

export const LinkedProfiles = new schema.Object({
  allProfiles: [LinkedProfile],
});

export const CustomerDetail = new schema.Object(
  {},
  {
    processStrategy(input, parent, key) {
      const { datetimeAdded: dateTimeAdded, ...rest } = input;
      return { ...rest, dateTimeAdded };
    },
  }
);

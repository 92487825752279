import React from 'react';

import styled from 'styled-components';

/* 
  SkeletonPulse is a component to be used as a BASE for other skeleton components.
  Essentially it renders the animation of the skeleton.

  SkeletonLine is an example of a component using SkeletonPulse.
*/

const SkeletonPulseWrapper = styled.span`
  height: 100%;
  width: 100%;
  display: inline-block;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const SkeletonPulse = (props) => {
  return <SkeletonPulseWrapper {...props} />;
};

export default SkeletonPulse;

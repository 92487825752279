/* eslint-disable react/prop-types */
import React from 'react';

import ReactIs from 'react-is';
import styled, { CSSProp, StyledComponent } from 'styled-components';

import useTabIndex from '../useTabIndex';

const Wrapper: StyledComponent<React.ElementType, {}> = styled.div<{
  inputWrapperStyle?: CSSProp;
}>`
  flex: 0 0 auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  ${({ inputWrapperStyle }) => inputWrapperStyle};
`;

const CustomInput: StyledComponent<React.ElementType, {}> = styled.span<{
  inputCheckingStyle?: CSSProp;
  inputCheckedStyle?: CSSProp;
  inputUncheckingStyle?: CSSProp;
  inputDisabledStyle?: CSSProp;
  inputFocusStyle?: CSSProp;
  inputStyle?: CSSProp;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  border: 1px solid #999;
  border-radius: 2px;
  background: #fff;
  color: #000;
  // The default margin is intentionally smaller on top so that the resulting
  // vertical alignment looks good with most text's baseline.
  margin: 2px 4px 4px 4px;
  padding: 2px;
  font-size: 10px;

  input:active:not(:checked):not(:disabled) + && {
    ${(props) => props.inputCheckingStyle};
  }

  input:checked + && {
    border-color: #333;
    background: #333;
    color: #fff;
    ${(props) => props.inputCheckedStyle};
  }

  input:active:checked:not(:disabled) + && {
    ${(props) => props.inputUncheckingStyle};
  }

  input:disabled + && {
    ${(props) => props.inputDisabledStyle};
  }

  input:focus + && {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5),
      0 0 0 3px ${(props) => props.theme.colors.focusColor};
    ${(props) => props.inputFocusStyle};
  }

  // Give the inputStyle prop higher specificity than the single className it
  // would get normally, so that this prop takes precedence over styles simply
  // added with styled(Component).
  && {
    ${(props) => props.inputStyle};
  }
`;

// Workaround for `as` causing all the style-only props to be passed along to
// a component that then applies them to a DOM element because it doesn't know
// any better. Without this, the literal `checkmarkStyle` CSS would appear in
// the DOM as an attribute. - BB
function ApplyClassNameToChild({
  className,
  children,
}: {
  className?: string;
  children: React.ReactElement;
}) {
  return React.cloneElement(children, { className });
}

const Checkmark = styled(ApplyClassNameToChild)<{
  checkmarkStyle?: CSSProp;
  checkmarkCheckedStyle?: CSSProp;
  checkmarkCheckingStyle?: CSSProp;
  checkmarkDisabledStyle?: CSSProp;
  checkmarkFocusStyle?: CSSProp;
  checkmarkUncheckingStyle?: CSSProp;
}>`
  flex: 0 0 auto;
  visibility: hidden;

  input:active:not(:checked):not(:disabled) + ${CustomInput} > && {
    visibility: visible;
    opacity: 0.2;
    ${(props) => props.checkmarkCheckingStyle};
  }

  input:checked + ${CustomInput} > && {
    visibility: visible;
    ${(props) => props.checkmarkCheckedStyle};
  }

  input:active:checked:not(:disabled) + ${CustomInput} > && {
    opacity: 0.7;
    ${(props) => props.checkmarkUncheckingStyle};
  }

  input:focus + ${CustomInput} > && {
    ${(props) => props.checkmarkFocusStyle};
  }

  input:disabled + ${CustomInput} > && {
    ${(props) => props.checkmarkDisabledStyle};
  }

  // Give the inputStyle prop higher specificity than the single className it
  // would get normally, so that this prop takes precedence over styles simply
  // added with styled(Component).
  && {
    ${(props) => props.checkmarkStyle};
  }
`;

const HiddenInput: StyledComponent<React.ElementType, {}> = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
  z-index: 1;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export type CheckboxRadioBaseProps = React.ComponentPropsWithRef<'input'> & {
  /**
   * Styles to apply to the checkmark element when the input is checked.
   */
  checkmarkCheckedStyle?: CSSProp;
  /**
   * Styles to apply to the checkmark element when the input is actively being
   * checked.
   */
  checkmarkCheckingStyle?: CSSProp;
  /**
   * Styles to apply to the checkmark element when the input is disabled.
   */
  checkmarkDisabledStyle?: CSSProp;
  /**
   * Styles to apply to the checkmark element when the input is focused.
   */
  checkmarkFocusStyle?: CSSProp;
  /**
   * The icon to render when checked.
   */
  checkmarkIcon?: React.ElementType | React.ComponentType | null | undefined;
  /**
   * Styles to always apply to the checkmark element.
   */
  checkmarkStyle?: CSSProp;
  /**
   * Styles to apply to the checkmark element when the input is actively being
   * unchecked.
   */
  checkmarkUncheckingStyle?: CSSProp;
  /**
   * The class to add to the custom checkbox.
   */
  className?: string;
  disabled?: boolean;
  /**
   * Styles to apply to the custom input element when the input is checked.
   */
  inputCheckedStyle?: CSSProp;
  /**
   * Styles to apply to the custom input element when the input is actively
   * being checked.
   */
  inputCheckingStyle?: CSSProp;
  /**
   * Styles to apply to the custom input element when the input is disabled.
   */
  inputDisabledStyle?: CSSProp;
  /**
   * Styles to apply to the custom input element when the input is focused.
   */
  inputFocusStyle?: CSSProp;
  /**
   * Styles to always apply to the custom input element.
   */
  inputStyle?: CSSProp;
  /**
   * Styles to apply to the custom input element when the input is actively
   * being unchecked.
   */
  inputUncheckingStyle?: CSSProp;
  /**
   * Styles to apply to the custom input wrapper.
   */
  inputWrapperStyle?: CSSProp;
  /**
   * Class name to apply to the root element wrapping the hidden input and the
   * custom checkbox element.
   */
  rootClassName?: string;
  /**
   * Styles to apply to the root element wrapping the hidden input and the
   * custom checkbox element.
   */
  rootStyle?: React.CSSProperties;
  /**
   * Inline styles to apply to the custom checkbox.
   */
  style?: React.CSSProperties;
  /**
   * `tabindex` value to apply to the `<input>` element. If inside an
   * `<Untabbable>` ancestor, it will automatically be set to 0.
   */
  tabIndex?: number;
  /**
   * Whether to render a `checkbox` or `radio` input type.
   */
  type?: 'checkbox' | 'radio';
};

/**
 * A base component for rendering custom checkbox or ratio input elements.
 *
 * Any remaining props not listed will be passed along to the hidden `<input>
 * underlying the custom element.
 */
export const CheckboxRadioBase = React.forwardRef<
  HTMLInputElement,
  CheckboxRadioBaseProps
>(
  (
    {
      // Input box styles.
      style,
      inputStyle,
      inputCheckedStyle,
      inputCheckingStyle,
      inputDisabledStyle,
      inputUncheckingStyle,
      inputFocusStyle,

      // Input wrapper style
      inputWrapperStyle,

      // Checkmark styles.
      checkmarkStyle,
      checkmarkCheckedStyle,
      checkmarkCheckingStyle,
      checkmarkDisabledStyle,
      checkmarkUncheckingStyle,
      checkmarkFocusStyle,

      // Root styles.
      rootClassName,
      rootStyle,

      className,
      tabIndex,
      checkmarkIcon: CheckmarkIcon,
      disabled,
      ...rest
    },
    ref
  ) => {
    tabIndex = useTabIndex(tabIndex);

    return (
      <Wrapper
        className={rootClassName}
        style={rootStyle}
        inputWrapperStyle={inputWrapperStyle}
      >
        <HiddenInput
          data-hidden-input=""
          ref={ref}
          tabIndex={tabIndex}
          disabled={disabled}
          {...rest}
        />
        <CustomInput
          aria-hidden="true"
          data-custom-input=""
          className={className}
          style={style}
          inputStyle={inputStyle}
          inputCheckedStyle={inputCheckedStyle}
          inputCheckingStyle={inputCheckingStyle}
          inputDisabledStyle={inputDisabledStyle}
          inputUncheckingStyle={inputUncheckingStyle}
          inputFocusStyle={inputFocusStyle}
        >
          <Checkmark
            checkmarkStyle={checkmarkStyle}
            checkmarkCheckedStyle={checkmarkCheckedStyle}
            checkmarkCheckingStyle={checkmarkCheckingStyle}
            checkmarkDisabledStyle={checkmarkDisabledStyle}
            checkmarkUncheckingStyle={checkmarkUncheckingStyle}
            checkmarkFocusStyle={checkmarkFocusStyle}
          >
            {ReactIs.isElement(CheckmarkIcon) ? (
              React.cloneElement(CheckmarkIcon as React.ReactElement, {
                'data-checkmark': '',
              })
            ) : (
              // FIXME: JSX element type 'CheckmarkIcon' does not have any construct or call signatures.
              // @ts-expect-error
              <CheckmarkIcon data-checkmark="" />
            )}
          </Checkmark>
        </CustomInput>
      </Wrapper>
    );
  }
);

CheckboxRadioBase.displayName = 'CheckboxRadioBase';

const CheckboxRadioBaseNamespace = Object.assign(CheckboxRadioBase, {
  Wrapper,
  CustomInput,
  Checkmark,
  HiddenInput,
});

export default CheckboxRadioBaseNamespace;

import React, { useMemo, useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { IoIosArrowUp } from 'react-icons/io';
import styled from 'styled-components';

import { createProductListingQueryParams } from '../../../../techstyle-shared/react-products';
import { buildURL } from '../../../../techstyle-shared/redux-core';
import Collapsible, { useCollapsibleState } from '../Collapsible';
import FocusRegion from '../FocusRegion';
import Heading from '../Heading';
import { LabelType, useCategoryLabels } from '../LabelProvider';
import { useProductBrowser } from '../ProductBrowserContext';
import useFocusRegion from '../useFocusRegion';
import { getCategoryTree, categoryPropType } from '../utils/category';

const Wrapper = styled(FocusRegion)`
  position: relative;
  color: #222222;
`;

const CategoryHeading = styled(Heading)`
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: normal;
  font-style: normal;
  line-height: inherit;
`;

const DropdownContent = styled(Collapsible.Content)`
  position: absolute;
  left: 0;
  right: 0px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);

  ${({ dropdownStyle }) => dropdownStyle};
`;

const ArrowIcon = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  margin-left: 6px;
  color: #ffffff;
`;

const ExpandIcon = styled(ArrowIcon)`
  [aria-expanded='true'] & {
    visibility: hidden;
  }
`;

const CollapseIcon = styled(ArrowIcon)`
  [aria-expanded='false'] & {
    visibility: hidden;
  }
`;

// Values from mocks at https://app.zeplin.io/project/5e2887fbefca7a7f42eefeeb/screen/5e87a960305633b84a0c8510 and https://app.zeplin.io/project/5e2887fbefca7a7f42eefeeb/screen/5e4322eec7a3658a4e9aef9c

const CategoryList = styled.ul`
  list-style-type: none;
`;

const CategoryListItem = styled.li``;

const TitleWrapper = styled.span`
  position: relative;
`;

const TitleButton = styled(Collapsible.Button)`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.04), inset 0 -1px 0 0 #f4f4f4;
  background-color: #f7f7f7;
  font-family: inherit;
  font-size: 16px;
  font-weight: inherit;
  padding: 15px 0;
  letter-spacing: ${-0.4 / 16}em;

  &[aria-expanded='true'] {
    box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.04), inset 0 -1px 0 0 #e6e6e6;
    background-color: #333333;
    color: #ffffff;
  }

  ${({ buttonStyle }) => buttonStyle};
`;

const CategoryAnchorTag = styled.a`
  display: flex;
  box-shadow: inset 0 -1px 0 0 #e6e6e6;
  font-size: 14px;
  letter-spacing: ${-0.35 / 14}em;
  color: rgba(34, 34, 34, 1);
  text-align: left;
  padding: 16px 19px;
  text-decoration: none;

  ${CategoryListItem} ${CategoryListItem} & {
    padding-left: 32px;
  }

  ${CategoryListItem} ${CategoryListItem} ${CategoryListItem} & {
    padding-left: 48px;
  }

  &[data-active-category='true'] {
    color: rgba(34, 34, 34, 0.56);
  }

  ${({ linkStyle }) => linkStyle};
`;

function Title({
  activeCategory: activeCategoryFromProps,
  buttonStyle,
  children,
  collapseIcon = IoIosArrowUp,
  expandIcon,
  ...rest
}) {
  const productBrowserCtx = useProductBrowser();
  const categoryLabelCtx = useCategoryLabels();
  let activeCategory = activeCategoryFromProps;

  if (productBrowserCtx) {
    const { activeCategory: activeCategoryFromCtx } = productBrowserCtx;
    if (!activeCategoryFromProps) {
      activeCategory = activeCategoryFromCtx;
    }
  }

  return (
    <CategoryHeading level={1} {...rest}>
      {children || (
        <TitleButton aria-haspopup buttonStyle={buttonStyle}>
          <TitleWrapper>
            {categoryLabelCtx.renderLabel(activeCategory, {
              labelType: LabelType.CATEGORY_DROPDOWN,
            })}
            {expandIcon ? <ExpandIcon as={expandIcon} /> : null}
            {collapseIcon ? <CollapseIcon as={collapseIcon} /> : null}
          </TitleWrapper>
        </TitleButton>
      )}
    </CategoryHeading>
  );
}

Title.propTypes = {
  activeCategory: categoryPropType,
  buttonStyle: PropTypes.any,
  /**
   * Children to render in the Category Title. Defaults to labelProvider and ArrowUpIcon.
   */
  children: PropTypes.node,
  collapseIcon: PropTypes.elementType,
  expandIcon: PropTypes.elementType,
};

function Item({
  isActive,
  category,
  children,
  label,
  linkStyle,
  preserveFilters,
  ...rest
}) {
  const productBrowserCtx = useProductBrowser();
  const context = useCollapsibleState();

  const href = useMemo(() => {
    if (preserveFilters && productBrowserCtx) {
      const {
        filters,
        baseFilters,
        sort: sortOption,
        mySize,
      } = productBrowserCtx;
      const params = createProductListingQueryParams({
        filters:
          typeof preserveFilters === 'function'
            ? preserveFilters(filters, category)
            : filters,
        baseFilters,
        sortOption,
        mySize,
      });
      return buildURL(category.urlPath, params);
    }
    return category.urlPath;
  }, [category, preserveFilters, productBrowserCtx]);

  const handleClick = useCallback(() => {
    context.closeCollapsible();
  }, [context]);

  return (
    <CategoryListItem data-autotag="grid-category-dd-option" {...rest}>
      <CategoryAnchorTag
        data-active-category={isActive}
        href={href}
        linkStyle={linkStyle}
        onClick={handleClick}
      >
        {label}
      </CategoryAnchorTag>
      {children}
    </CategoryListItem>
  );
}

Item.propTypes = {
  category: categoryPropType,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  /**
   * Label from labelProvider.
   */
  label: PropTypes.node,
  linkStyle: PropTypes.any,
  preserveFilters: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

function CategoryDropdownContent({ children, dropdownStyle, ...rest }) {
  const hasFocus = useFocusRegion();
  const collapsibleCtx = Collapsible.useState();

  useEffect(() => {
    if (!hasFocus && collapsibleCtx.isOpen) {
      collapsibleCtx.closeCollapsible();
    }
  }, [hasFocus, collapsibleCtx]);

  return (
    <DropdownContent dropdownStyle={dropdownStyle} {...rest}>
      <CategoryList>{children}</CategoryList>
    </DropdownContent>
  );
}

CategoryDropdownContent.propTypes = {
  children: PropTypes.node,
  dropdownStyle: PropTypes.any,
};

function CategoryDropdown({
  activeCategory: activeCategoryFromProps,
  activeCategoryChecker = (category) => category.productCategoryId,
  defaultOpen = false,
  dropdownStyle,
  preserveFilters = false,
  showActiveSubcategories = true,
  showSiblingCategories = true,
  linkStyle,
  onClose,
  onOpen,
  title,
  titleButtonStyle,
  ...rest
}) {
  const [isOpen, setOpen] = useState(defaultOpen);
  const productBrowserCtx = useProductBrowser();
  const categoryLabelCtx = useCategoryLabels();
  let activeCategory = activeCategoryFromProps;

  const handleOpen = useCallback(() => {
    if (onOpen) {
      onOpen();
    }
    setOpen(true);
  }, [onOpen]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  }, [onClose]);

  if (productBrowserCtx) {
    const { activeCategory: activeCategoryFromCtx } = productBrowserCtx;
    if (!activeCategoryFromProps) {
      activeCategory = activeCategoryFromCtx;
    }
  }

  const activeCategoryTree = useMemo(
    () =>
      activeCategory
        ? getCategoryTree(
            activeCategory,
            showActiveSubcategories,
            showSiblingCategories,
            activeCategoryChecker
          )
        : null,
    [
      activeCategory,
      showActiveSubcategories,
      showSiblingCategories,
      activeCategoryChecker,
    ]
  );

  if (activeCategoryTree == null) {
    // bail out if no activeCategory
    return null;
  }

  const renderCategoryLists = (categories, depth) => {
    return categories.map((category) => {
      const categoryKey = activeCategoryChecker(category);
      const isActive = categoryKey === activeCategoryChecker(activeCategory);

      const subcategories = category.subcategories || [];

      const isTopLevel = depth === 0 && (isActive || category.isAncestor);

      const label = categoryLabelCtx.renderLabel(category, {
        isActive,
        depth,
        isTopLevel,
        labelType: LabelType.CATEGORY_DROPDOWN,
      });

      let children;
      if (subcategories.length) {
        children = (
          <CategoryList>
            {renderCategoryLists(subcategories, depth + 1)}
          </CategoryList>
        );
      }

      return (
        <Item
          key={categoryKey}
          isActive={isActive}
          label={label}
          category={category}
          linkStyle={linkStyle}
          preserveFilters={preserveFilters}
        >
          {children}
        </Item>
      );
    });
  };

  return (
    <Wrapper {...rest}>
      <Collapsible
        defaultOpen={defaultOpen}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {title || (
          <Title
            activeCategory={activeCategory}
            buttonStyle={titleButtonStyle}
          />
        )}
        <CategoryDropdownContent dropdownStyle={dropdownStyle}>
          {renderCategoryLists(activeCategoryTree, 0)}
        </CategoryDropdownContent>
      </Collapsible>
    </Wrapper>
  );
}

CategoryDropdown.propTypes = {
  activeCategory: categoryPropType,
  activeCategoryChecker: PropTypes.func,
  defaultOpen: PropTypes.bool,
  dropdownStyle: PropTypes.any,
  linkStyle: PropTypes.any,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  preserveFilters: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  showActiveSubcategories: PropTypes.bool,
  showSiblingCategories: PropTypes.bool,
  /**
   * Title element. Defaults to rendering Title.
   */
  title: PropTypes.node,
  titleButtonStyle: PropTypes.any,
};

CategoryDropdown.Item = Item;
CategoryDropdown.Title = Title;
CategoryDropdown.List = CategoryList;
CategoryDropdown.ListItem = CategoryListItem;

export default CategoryDropdown;

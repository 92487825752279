import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { DraggableCore } from 'react-draggable';
import styled from 'styled-components';

import {
  useIntl,
  defineMessages,
} from '../../../../techstyle-shared/react-intl';
import useAnimationTimeout from '../useAnimationTimeout';
import useTextSelectionToggle from '../useTextSelectionToggle';

const messages = defineMessages({
  chooseValueMessage: {
    id: 'site_product_grid.choose_value',
    defaultMessage: 'Choose a Value',
  },
});

const ValuePositions = {
  MIN: 'min',
  MAX: 'max',
};

const Point = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: black;
  border-radius: 10px;
  z-index: 1;

  left: calc(${({ percentOfTotal }) => percentOfTotal.toFixed(3)}% - 10px);
  top: -8px;

  ${({ pointStyle }) => pointStyle}
`;

const Rail = styled.div`
  height: 4px;
  background-color: #d9d9d9;
  border-radius: 3px;
  position: relative;

  ${({ railStyle }) => railStyle}
`;

const ValueLine = styled.div`
  position: absolute;
  background-color: black;
  height: 4px;
  border-radius: 3px;
  left: ${({ lowerBound }) => lowerBound}%;
  width: ${({ lowerBound, upperBound }) => `${upperBound - lowerBound}`}%;

  ${({ valueLineStyle }) => valueLineStyle}
`;

const Wrapper = styled.div`
  padding: 10px;
`;

const isRangeValue = (value) => value.min != null && value.max != null;

export default function RangeSlider({
  debounceTimeout = 200,
  max,
  min = 0,
  onChange,
  onInteraction,
  pointStyle,
  railStyle,
  step = 1,
  initialValue = { min, max },
  value: valueFromProps,
  valueLineStyle,
  ...rest
}) {
  if (
    initialValue &&
    isRangeValue(initialValue) &&
    initialValue.max <= initialValue.min
  ) {
    // eslint-disable-next-line
    console.warn(
      'The "max" property of "initialValue" is less than or equal to "min". The component may not work as expected.'
    );
  }

  if (
    valueFromProps &&
    isRangeValue(valueFromProps) &&
    valueFromProps.max <= valueFromProps.min
  ) {
    // eslint-disable-next-line
    console.warn(
      'The "max" property of "value" is less than or equal to "min". The component may not work as expected.'
    );
  }

  const roundValueToStep = (unroundedValue) => {
    const maxStepOffset = (max - min) % step;

    // If the max isn't a multiple of step and our value is close to
    // it, we have to round based on the max offset instead of the step.
    if (maxStepOffset !== 0 && unroundedValue > max - maxStepOffset) {
      const offsetMin = max - maxStepOffset;
      return (
        Math.round((unroundedValue - offsetMin) / maxStepOffset) *
          maxStepOffset +
        offsetMin
      );
    }
    return Math.round((unroundedValue - min) / step) * step + min;
  };

  const [internalValue, setInternalValue] = useState(initialValue);

  const isControlled = valueFromProps != null;

  const value = valueFromProps != null ? valueFromProps : internalValue;

  const sliderRef = useRef();

  const intl = useIntl();

  const [start] = useAnimationTimeout();

  const handleChange = (newValue, position) => {
    // Limit to valid range.
    newValue = getBoundedPointValue(newValue, position);

    const callbackValue = isRangeValue(value)
      ? { ...value, [position]: newValue }
      : newValue;

    if (!isControlled) {
      if (isRangeValue(value)) {
        setInternalValue((internal) => ({ ...internal, [position]: newValue }));
      } else {
        setInternalValue(newValue);
      }
    }

    if (onInteraction) {
      onInteraction(callbackValue);
    }

    if (onChange) {
      start(() => {
        onChange(callbackValue);
      }, debounceTimeout);
    }
  };

  const getUpperBounds = (position) => {
    if (isRangeValue(value)) {
      return position === ValuePositions.MIN ? value.max - step : max;
    } else {
      return max;
    }
  };

  const getLowerBounds = (position) => {
    if (isRangeValue(value)) {
      return position === ValuePositions.MAX ? value.min + step : min;
    } else {
      return min;
    }
  };

  const getBoundedPointValue = (pointValue, position) => {
    const upperBounds = getUpperBounds(position);
    const lowerBounds = getLowerBounds(position);

    if (pointValue > upperBounds) {
      return upperBounds;
    } else if (pointValue < lowerBounds) {
      return lowerBounds;
    } else {
      return pointValue;
    }
  };

  const incrementValue = (position) => {
    const valueByPosition = position ? value[position] : value;
    handleChange(valueByPosition + step, position);
  };

  const decrementValue = (position) => {
    const valueByPosition = position ? value[position] : value;

    let newValue;
    if (valueByPosition === max) {
      // If the slider's range (max - min) isn't a multiple of step, we need
      // to decrement it by a smaller amount to keep values consistent.
      const maxStepOffset = (max - min) % step;
      newValue =
        maxStepOffset === 0
          ? valueByPosition - step
          : valueByPosition - maxStepOffset;
    } else {
      newValue = valueByPosition - step;
    }

    handleChange(newValue, position);
  };

  const getValueFromXPosition = (xPosition) => {
    const { left, width } = sliderRef.current.getBoundingClientRect();

    const range = max - min;

    const positionOnSlider = xPosition - left;

    const percent = Math.floor((positionOnSlider / width) * 100);

    if (percent >= 100) {
      return max;
    }

    if (percent <= 0) {
      return min;
    }

    const unroundedValue = (range * percent) / 100;

    return roundValueToStep(unroundedValue);
  };

  const handleMove = (position) => (event) => {
    let x;
    if (event.touches) {
      x = event.touches[0].pageX;
    } else {
      x = event.pageX;
    }

    const valueFromXCoords = getValueFromXPosition(x);

    handleChange(valueFromXCoords, position);
  };

  // In Safari and some versions of Chrome, the cursor changes to the text
  // cursor when dragging, even if `user-select: none` and `cursor` are
  // specified. One way to fix this is to cancel the `selectstart` event while
  // dragging.
  const { enableTextSelection, disableTextSelection } =
    useTextSelectionToggle();

  const handleKeyDown = (position) => (event) => {
    switch (event.keyCode) {
      case 35: // end
        event.preventDefault();
        handleChange(max, position);
        break;
      case 36: // home
        event.preventDefault();
        handleChange(min, position);
        break;
      case 37: // left arrow
      case 40: // down arrow
        event.preventDefault();
        decrementValue(position);
        break;
      case 38: // up arrow
      case 39: // right arrow
        event.preventDefault();
        incrementValue(position);
        break;
    }
  };

  const getPercentageFromValue = (val) => {
    const percentage = ((val - min) / (max - min)) * 100;

    if (percentage > 100) {
      return 100;
    }

    if (percentage < 0) {
      return 0;
    }

    return percentage;
  };

  const renderValueLine = () => {
    const lowerPercentage = getPercentageFromValue(value.min);
    const upperPercentage = getPercentageFromValue(value.max);

    return (
      <ValueLine
        lowerBound={lowerPercentage}
        upperBound={upperPercentage}
        valueLineStyle={valueLineStyle}
      />
    );
  };

  return (
    <Wrapper {...rest}>
      <Rail ref={sliderRef} railStyle={railStyle}>
        {isRangeValue(value) && renderValueLine()}
        {value.min != null && (
          <DraggableCore
            onDrag={handleMove(ValuePositions.MIN)}
            onStart={disableTextSelection}
            onStop={enableTextSelection}
          >
            <Point
              aria-orientation="horizontal"
              aria-valuemin={min}
              aria-valuenow={value.min}
              aria-valuemax={max}
              aria-label={intl.formatMessage(messages.chooseValueMessage)}
              onKeyDown={handleKeyDown(ValuePositions.MIN)}
              role="slider"
              tabIndex={0}
              value={value.min}
              percentOfTotal={getPercentageFromValue(value.min)}
              pointStyle={pointStyle}
            />
          </DraggableCore>
        )}
        {value.max != null && (
          <DraggableCore
            onDrag={handleMove(ValuePositions.MAX)}
            onStart={disableTextSelection}
            onStop={enableTextSelection}
          >
            <Point
              aria-orientation="horizontal"
              aria-valuemin={min}
              aria-valuenow={value.max}
              aria-valuemax={max}
              aria-label={intl.formatMessage(messages.chooseValueMessage)}
              onKeyDown={handleKeyDown(ValuePositions.MAX)}
              role="slider"
              tabIndex={0}
              value={value.max}
              percentOfTotal={getPercentageFromValue(value.max)}
              pointStyle={pointStyle}
            />
          </DraggableCore>
        )}
        {!isNaN(value) && (
          <DraggableCore onDrag={handleMove(ValuePositions.MIN)}>
            <Point
              aria-orientation="horizontal"
              aria-valuemin={min}
              aria-valuenow={value}
              aria-valuemax={max}
              onKeyDown={handleKeyDown()}
              role="slider"
              tabIndex={0}
              percentOfTotal={getPercentageFromValue(value)}
              pointStyle={pointStyle}
              value={value}
            />
          </DraggableCore>
        )}
      </Rail>
    </Wrapper>
  );
}

RangeSlider.propTypes = {
  /**
   * The timeout, in milliseconds, of the debounce applied to the
   * onChange callback.
   */
  debounceTimeout: PropTypes.number,
  /**
   * The starting value of the component when its state is controlled
   * internally.
   */
  initialValue: PropTypes.oneOfType([
    PropTypes.shape({
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
    }),
    PropTypes.number,
  ]),
  /**
   * The maximum value of the slider.
   */
  max: PropTypes.number.isRequired,
  /**
   * The minimum value of the slider.
   */
  min: PropTypes.number,
  /**
   * A callback called when the slider's value changes, debounced
   * based on the value of debounceTimeout.
   */
  onChange: PropTypes.func,
  /**
   * A callback called when the slider's value changes, with no
   * debouncing applied.
   */
  onInteraction: PropTypes.func,
  /**
   * Styles applied to the value points of the slider.
   */
  pointStyle: PropTypes.any,
  /**
   * Styles applied to the guiding rail that the slider's
   * points slide across.
   */
  railStyle: PropTypes.any,
  /**
   * The distance between valid values for the slider. For example, if
   * the value of this prop is 5, moving a slider point will change its
   * value in increments of 5.
   */
  step: PropTypes.number,
  /**
   * The value of the slider. If a number is passed, it will render one line
   * along a rail. If an object with 'min' and 'max' props is passed, it will
   * display those points with a shaded value line between them.
   */
  value: PropTypes.oneOfType([
    PropTypes.shape({
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
    }),
    PropTypes.number,
  ]),
  /**
   * Styles applied to the shaded value line between two values on
   * the slider. The value line is not rendered if there is only one value point.
   */
  valueLineStyle: PropTypes.any,
};

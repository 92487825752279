import config from 'config';

import { Brand } from '../constants/brand';

export function isYitty() {
  return config.get('public.brand.name') === Brand.YITTY;
}

export function isFabletics() {
  return config.get('public.brand.name') === Brand.FABLETICS;
}

export function isSavage() {
  return config.get('public.brand.name') === Brand.SAVAGE;
}

/**
 * @description Generic util for conditionally deriving values based on the
 * current brand config. This is used to avoid branching logic/ternaries
 * throughout the codebase when a brand specific value is needed.
 * If a brand value is not specified the default value is used.
 * @param {Object} values - An object containing brand values.
 * @param {any} values.default - The default value. Required.
 * @param {any=} values.fabletics - The value for Fabletics.
 * @param {any=} values.savage - The value for Savage.
 * @param {any=} values.yitty - The value for Yitty.
 * @returns {any} The current brand or default value.
 *
 * @example
 * const value = getBrandValue({
 *   // Yitty will return `default` since a brand specific key is not provided.
 *   default: 'default',
 *   fabletics: 'fabletics',
 *   savage: 'savage',
 * });
 */
export function getBrandValue(values) {
  if (isYitty() && typeof values.yitty !== 'undefined') {
    return values.yitty;
  }
  if (isFabletics() && typeof values.fabletics !== 'undefined') {
    return values.fabletics;
  }
  if (isSavage() && typeof values.savage !== 'undefined') {
    return values.savage;
  }
  return values.default;
}

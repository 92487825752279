import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import {
  useRenderTime,
  parseDate,
} from '../../../../techstyle-shared/redux-core';
import { Component as AssetFilter } from '../AssetFilter';

export default function DefaultAssetFilter({ children }) {
  const getTime = useRenderTime();

  const filter = useCallback(
    (parentFilter) => {
      // We don't want to call `getTime()` again for each asset we're filtering,
      // because time advances while we filter. They should all be compared
      // against the same moment in time. We also can't just call `getTime()`
      // here, because right now we're just creating the filter function, not
      // actually applying it yet. The current (ugly) workaround is to rely on
      // `find` and `filter` being synchronous: we can update this `now`
      // reference whenever we start filtering a new array (as detected by the
      // item's index being zero).
      let now;
      return (asset, i, assets) => {
        if (i === 0) {
          now = getTime();
        }
        if (asset.startDateTime) {
          // Disqualify assets that start in the future.
          if (parseDate(asset.startDateTime) > now) {
            return false;
          }
        }
        if (asset.endDateTime) {
          // Disqualify assets that ended in the past.
          if (parseDate(asset.endDateTime) <= now) {
            return false;
          }
        }
        return parentFilter(asset, i, assets);
      };
    },
    [getTime]
  );

  return <AssetFilter filter={filter}>{children}</AssetFilter>;
}

DefaultAssetFilter.propTypes = {
  children: PropTypes.node,
};

import { schema } from '../../../techstyle-shared/redux-core';

const slashRe = /^\/|\/$/g;

export const normalizePath = (path = '') => {
  if (!path) {
    return path;
  }

  const [pathname] = path.split(/[?#]/);
  return '/' + pathname.replace(slashRe, '');
};

export const Rewrite = new schema.Entity(
  'Rewrite',
  {},
  {
    idAttribute: (rewrite) => normalizePath(rewrite.canonicalPath),
    processStrategy: (rewrite) => ({
      ...rewrite,
      canonicalPath: normalizePath(rewrite.canonicalPath),
    }),
  }
);

export const RewriteMapping = new schema.Values(Rewrite);

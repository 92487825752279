import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useIntl } from '../../../../techstyle-shared/react-intl';
import { CuralateSnippet } from '../../../../techstyle-shared/react-marketing';
import { useProductContext } from '../ProductContext';

const CuralateProductWrapper = styled.div``;
export default function CuralateProduct({
  experienceName,
  filterKey = 'productId',
  product: productFromProps,
  ...rest
}) {
  const { locale } = useIntl();
  const productFromContext = useProductContext();
  const product = productFromProps || productFromContext;

  let filter = '';

  if (product) {
    filter =
      filterKey === 'productId'
        ? `${filterKey}:${product.masterProductId}`
        : `${filterKey}:'${product.label}'`;
  }

  useEffect(() => {
    window.crl8.ready(async () => {
      try {
        await window.crl8.destroyExperience(experienceName);
      } catch (error) {
        // no need to handle error here. if there is no matching experience,
        // it will move to next try block.
      }
      try {
        await window.crl8.createExperience(experienceName);
      } catch (error) {
        // no need to handle error here.
      }
    });
  }, [experienceName, filter]);

  useEffect(() => {
    return () => {
      window.crl8.ready(async () => {
        try {
          await window.crl8.destroyExperience(experienceName);
        } catch (error) {
          // no need to handle error here.
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CuralateSnippet />
      <CuralateProductWrapper
        data-testid="curalate-experience"
        data-crl8-container-id={experienceName}
        data-crl8-auto-init="false"
        data-crl8-locale={locale || 'en-US'}
        data-crl8-filter={filter}
        {...rest}
      />
    </>
  );
}

CuralateProduct.propTypes = {
  experienceName: PropTypes.string,
  filterKey: PropTypes.oneOf(['productId', 'title']),
  product: PropTypes.object,
};

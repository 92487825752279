/**
 * Recursively apply the `filter` function to the `navItems` contained in
 * `parentItem` and return a new nav item if applicable. (That is, if the filter
 * ends up having no effect, the same instance will be returned.)
 */
export function filterNavItems(parentItem, filter) {
  let wasFiltered = false;
  const filterAndSetFlag = (...args) => {
    const result = filter(...args);
    if (!result) {
      wasFiltered = true;
    }
    return result;
  };
  if (parentItem.navItems) {
    const newItems = parentItem.navItems
      // Pass an additional arg to the filter: the parent item.
      .filter((...args) => filterAndSetFlag(...args, parentItem))
      .map((navItem) => filterNavItems(navItem, filterAndSetFlag));
    if (wasFiltered) {
      return {
        ...parentItem,
        navItems: newItems,
      };
    }
  }
  return parentItem;
}

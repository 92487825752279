import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import flickityBaseStyles from 'flickity/css/flickity.css';
import flickityFadeStyles from 'flickity-fade/flickity-fade.css';
import flickityFullscreenStyles from 'flickity-fullscreen/fullscreen.css';
import pick from 'object.pick';
import PropTypes from 'prop-types';
import { RemoveScroll } from 'react-remove-scroll';
import styled, { createGlobalStyle } from 'styled-components';

import CarouselContext from '../CarouselContext';
import withThemeProps from '../withThemeProps';

import CarouselSkeletonLoader from './CarouselSkeletonLoader';

// The jsdom was throwing css parse error while running unit tests.
// so `*!` has been replaced in flickityBaseStyles, flickityFadeStyles and
// flickityFullscreenStyles.
const baseStyles =
  typeof flickityBaseStyles === 'string' || flickityBaseStyles instanceof String
    ? flickityBaseStyles.replace(/\*!/g, '*')
    : '';
const fadeStyles =
  typeof flickityFadeStyles === 'string' || flickityFadeStyles instanceof String
    ? flickityFadeStyles.replace(/\*!/g, '*')
    : '';
const fullscreenStyles =
  typeof flickityFullscreenStyles === 'string' ||
  flickityFullscreenStyles instanceof String
    ? flickityFullscreenStyles.replace(/\*!/g, '*')
    : '';

const flickityOptions = [
  'accessibility',
  'adaptiveHeight',
  'arrowShape',
  'asNavFor',
  'autoPlay',
  'bgLazyLoad',
  'cellAlign',
  'cellSelector',
  'contain',
  'dragThreshold',
  'draggable',
  'fade',
  'freeScroll',
  'freeScrollFriction',
  'friction',
  'fullscreen',
  'groupCells',
  'hash',
  'imagesLoaded',
  'initialIndex',
  'lazyLoad',
  'pageDots',
  'pauseAutoPlayOnHover',
  'percentPosition',
  'prevNextButtons',
  'resize',
  'rightToLeft',
  'selectedAttraction',
  'setGallerySize',
  'watchCSS',
  'wrapAround',
];

const Wrapper = styled.div`
  position: relative;

  .flickity-slider {
    -webkit-overflow-scrolling: touch;
  }

  ${(props) => ({ paddingBottom: props.progressBarHeight })};
  ${(props) => props.wrapperStyle};
`;

const Cell = styled.div`
  width: 100%;
  ${(props) => props.cellStyle};
`;

const FlickityStyles = createGlobalStyle`
  ${baseStyles};
  ${(props) => (props.fade ? fadeStyles : '')};
  ${(props) => (props.fullscreen ? fullscreenStyles : '')};
`;

const ProgressBar = styled.div`
  position: absolute;
  width: 100%;
  ${(props) => ({ height: props.progressBarHeight })};
  bottom: 0;
  overflow: hidden;
  background: lightgrey;
  ${(props) => props.progressBarStyle};
`;

const PrimaryProgressBar = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% / ${(props) => props.slideCount});
  height: 100%;
  background: black;
  ${(props) => props.progressIndicatorStyle};
`;

const OverflowProgressBar = styled(PrimaryProgressBar)`
  left: -100%;
`;

const UnderflowProgressBar = styled(PrimaryProgressBar)`
  left: 100%;
`;

const defaultEvents = {};

// This component does all the heavy lifting and the `Carousel` component
// renders this. Since Flickity moved the child DOM nodes out from under React's
// control, the `Carousel` wrapper changes the `key` on the `<Flickity>`
// whenever it detects a change in `children` (based on their keys). That way,
// React won't throw an error when it tries to unmount children that are no
// longer on the parent it expects.
function Flickity({
  children,
  className,
  cellStyle,
  showLoadingSkeleton = false,
  loadingSkeletonDesktopCardCount = 6,
  loadingSkeletonMobileCardCount = 2,
  loadingSkeletonWrapperStyle,
  loadingSkeletonCardStyle,
  events = defaultEvents,
  flickityRef: flickityRefFromProps,
  style,
  wrapperStyle,
  disableProgressBarWhenSingleSlide = false,
  enableProgressBar: enableProgressBarFromProps = false,
  progressBarAutoTag,
  progressBarHeight = 5,
  progressBarStyle,
  progressIndicatorStyle,
  dataAutotagPrefix,
  ...rest
}) {
  const elementRef = useRef();
  const flickityRefUpdater = useRef();
  const [flickityInstance, setFlickityInstance] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const flickitySlides = flickityInstance?.slides;
  const primaryProgressBarRef = useRef(null);
  const overflowProgressBarRef = useRef(null);
  const underflowProgressBarRef = useRef(null);
  const slideCount = React.Children.count(children);
  const [flickitySlideCount, setFlickitySlideCount] = useState(slideCount);
  const enableProgressBar = disableProgressBarWhenSingleSlide
    ? flickitySlideCount > 1 && enableProgressBarFromProps
    : enableProgressBarFromProps;

  const options = useMemo(
    () => pick(rest, flickityOptions),
    // Create a dependency array of all known Flickity options. That way we can
    // detect changes and reinitialize the carousel only when necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    flickityOptions.map((name) => rest[name])
  );

  const context = useMemo(() => {
    return {
      flickity: flickityInstance,
      options,
      notifyImageLoaded: () => {
        if (options.imagesLoaded && flickityInstance) {
          flickityInstance.resize();
        }
      },
    };
  }, [flickityInstance, options]);

  // Keep the `flickityRefFromProps` updater in a ref itself so that it doesn't
  // have to be a dependency to the `useEffect` below that creates/destroys
  // the Flickity instance.
  useEffect(() => {
    flickityRefUpdater.current = (flickity) => {
      setFlickityInstance(flickity);
      if (typeof flickityRefFromProps === 'function') {
        flickityRefFromProps(flickity);
      } else if (flickityRefFromProps) {
        flickityRefFromProps.current = flickity;
      }
    };
  }, [flickityRefFromProps]);

  useEffect(() => {
    if (flickitySlides) {
      // `flickity.slides.length` is based on the amount of groups of slides.
      // This number is more useful than `slideCount` for the progress bar width.
      // e.g. If there are 10 slides and 5 slides are shown at once the progress bar should be
      // 50% width, not 10% width.
      setFlickitySlideCount(flickitySlides.length);
    }
  }, [flickitySlides]);

  useEffect(() => {
    let unloaded = false;
    let flickity;

    const loadFlickity = async () => {
      const { default: Flickity } = await import(
        /* webpackChunkName: "flickity" */ 'flickity'
      );
      const extras = [];
      // Load extra modules only for features we actually use.
      if (options.asNavFor) {
        extras.push(
          import(
            /* webpackChunkName: "flickity-as-nav-for" */ 'flickity-as-nav-for'
          )
        );
      }
      if (options.bgLazyLoad) {
        extras.push(
          import(
            /* webpackChunkName: "flickity-bg-lazyload" */ 'flickity-bg-lazyload'
          )
        );
      }
      if (options.fade) {
        extras.push(
          import(/* webpackChunkName: "flickity-fade" */ 'flickity-fade')
        );
      }
      if (options.fullscreen) {
        extras.push(
          import(
            /* webpackChunkName: "flickity-fullscreen" */ 'flickity-fullscreen'
          )
        );
      }
      if (options.hash) {
        extras.push(
          import(/* webpackChunkName: "flickity-hash" */ 'flickity-hash')
        );
      }
      if (options.imagesLoaded) {
        extras.push(
          import(
            /* webpackChunkName: "flickity-imagesloaded" */ 'flickity-imagesloaded'
          )
        );
      }

      await Promise.all(extras);

      if (!unloaded) {
        flickity = new Flickity(elementRef.current, options);
        const nextButton = flickity?.nextButton?.element;
        const previousButton = flickity?.prevButton?.element;

        if (nextButton && previousButton) {
          nextButton.setAttribute(
            'data-autotag',
            dataAutotagPrefix ? `${dataAutotagPrefix}_next_img_btn` : undefined
          );
          previousButton.setAttribute(
            'data-autotag',
            dataAutotagPrefix
              ? `${dataAutotagPrefix}_previous_img_btn`
              : undefined
          );
        }
        flickityRefUpdater.current(flickity);
      }
    };

    loadFlickity();

    return () => {
      unloaded = true;
      // Destroy on unmount.
      if (flickity) {
        flickity.destroy();
      }
      flickityRefUpdater.current(null);
    };
  }, [options, dataAutotagPrefix]);

  const updateProgressBar = useCallback(
    (progress) => {
      // progress comes from the number of transitions possible? (e.g. 4 swipes for 5 slides).
      // It is floating point decimal number
      // slideCount is the number of slides (width of progress bar is based on number of slides)
      const progressPercent = progress.toFixed(12);

      if (primaryProgressBarRef.current) {
        primaryProgressBarRef.current.style.transform = `translate(calc(${
          progressPercent * 100 * (flickitySlideCount - 1)
        }%))`;
        primaryProgressBarRef.current.style.width = `calc(100% / ${flickitySlideCount})`;
      }

      if (overflowProgressBarRef.current) {
        overflowProgressBarRef.current.style.transform = `translate(calc(${
          progressPercent * 100 * (flickitySlideCount - 1)
        }%))`;
        overflowProgressBarRef.current.style.width = `calc(100% / ${flickitySlideCount})`;
      }

      if (underflowProgressBarRef.current) {
        underflowProgressBarRef.current.style.transform = `translate(calc(${
          progressPercent * 100 * (flickitySlideCount - 1)
        }%))`;
        underflowProgressBarRef.current.style.width = `calc(100% / ${flickitySlideCount})`;
      }
    },
    [flickitySlideCount]
  );

  useEffect(() => {
    flickityInstance &&
      Object.entries(events).forEach(([eventName, eventHandler]) => {
        if (typeof eventHandler === 'function') {
          flickityInstance.on(eventName, eventHandler);
        }
      });

    return () => {
      flickityInstance &&
        Object.entries(events).forEach(([eventName, eventHandler]) => {
          if (typeof eventHandler === 'function') {
            flickityInstance.off(eventName, eventHandler);
          }
        });
    };
  }, [events, flickityInstance]);

  useEffect(() => {
    if (flickityInstance && enableProgressBar) {
      flickityInstance.on('scroll', updateProgressBar);
      // Necessary to initialize width of progress bar based on # of flickity slides
      updateProgressBar(0);
      return () => {
        flickityInstance.off('scroll', updateProgressBar);
      };
    }
  }, [enableProgressBar, flickityInstance, updateProgressBar]);

  useEffect(() => {
    const flickityDragStart = () => {
      setIsDragging(true);
    };

    const flickityDragEnd = () => {
      setIsDragging(false);
    };

    if (flickityInstance) {
      flickityInstance.on('dragStart', flickityDragStart);
      flickityInstance.on('dragEnd', flickityDragEnd);

      return () => {
        flickityInstance.off('dragStart', flickityDragStart);
        flickityInstance.off('dragEnd', flickityDragEnd);
      };
    }
  }, [flickityInstance]);

  const isFlickityLoaded = Boolean(flickityInstance);

  style = useMemo(
    () => ({
      // Hide until Flickity has been loaded and applied its styling.
      visibility: isFlickityLoaded ? undefined : 'hidden',
      height: showLoadingSkeleton && !isFlickityLoaded ? '0' : undefined,
      ...style,
    }),
    [isFlickityLoaded, showLoadingSkeleton, style]
  );

  return (
    <RemoveScroll enabled={isDragging}>
      <FlickityStyles fade={options.fade} fullscreen={options.fullscreen} />
      {!isFlickityLoaded && showLoadingSkeleton && (
        <CarouselSkeletonLoader
          loadingSkeletonDesktopCardCount={loadingSkeletonDesktopCardCount}
          loadingSkeletonMobileCardCount={loadingSkeletonMobileCardCount}
          loadingSkeletonWrapperStyle={loadingSkeletonWrapperStyle}
          loadingSkeletonCardStyle={loadingSkeletonCardStyle}
        />
      )}
      <Wrapper
        data-carousel=""
        className={className}
        ref={elementRef}
        progressBarHeight={enableProgressBar ? progressBarHeight : 0}
        style={style}
        wrapperStyle={wrapperStyle}
        data-autotag={
          dataAutotagPrefix ? `${dataAutotagPrefix}_carousel` : undefined
        }
      >
        {/*
        This is being placed before the cells not because we want it to render
        here, but because it's where it would end up after Flickity initializes
        anyway, since it replaces the cell DOM nodes completely and appends the
        result. So, in order to avoid the progress bar sometimes being rendered
        first and sometimes last, better to just always put it first.

        Do not remove this wrapper div. React adds and removes elements from the
        DOM using `insertBefore`, and since the cells below will be replaced
        with different DOM elements, it's not actually safe to having
        dynamically rendered siblings. Just let the div be empty if
        `enableProgressBar` is false.
          */}
        <div>
          {enableProgressBar ? (
            <ProgressBar
              progressBarHeight={progressBarHeight}
              progressBarStyle={progressBarStyle}
              data-autotag={progressBarAutoTag}
            >
              <PrimaryProgressBar
                ref={primaryProgressBarRef}
                progressIndicatorStyle={progressIndicatorStyle}
                slideCount={slideCount}
              />
              <OverflowProgressBar
                ref={overflowProgressBarRef}
                progressIndicatorStyle={progressIndicatorStyle}
                slideCount={slideCount}
              />
              <UnderflowProgressBar
                ref={underflowProgressBarRef}
                progressIndicatorStyle={progressIndicatorStyle}
                slideCount={slideCount}
              />
            </ProgressBar>
          ) : null}
        </div>
        {React.Children.map(children, (slide) => {
          return (
            <Cell cellStyle={cellStyle} data-carousel-cell="" key={slide.key}>
              <CarouselContext.Provider value={context}>
                {slide}
              </CarouselContext.Provider>
            </Cell>
          );
        })}
      </Wrapper>
    </RemoveScroll>
  );
}

Flickity.propTypes = {
  cellSelector: PropTypes.string,
  cellStyle: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  dataAutotagPrefix: PropTypes.string,
  disableProgressBarWhenSingleSlide: PropTypes.bool,
  enableProgressBar: PropTypes.bool,
  /**
   * A list of event handlers for the various Flickity events, keyed by
   * event type. e.g. `change`, `scroll`, etc.
   * See: https://flickity.metafizzy.co/events.html for the full list.
   */
  events: PropTypes.object,
  flickityRef: PropTypes.any,
  loadingSkeletonCardStyle: PropTypes.any,
  loadingSkeletonDesktopCardCount: PropTypes.number,
  loadingSkeletonMobileCardCount: PropTypes.number,
  loadingSkeletonWrapperStyle: PropTypes.any,
  progressBarAutoTag: PropTypes.string,
  /**
   * The height of the progress bar, either in pixels (numeric) or with CSS
   * units.
   */
  progressBarHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  progressBarStyle: PropTypes.any,
  progressIndicatorStyle: PropTypes.any,
  showLoadingSkeleton: PropTypes.bool,
  style: PropTypes.object,
  wrapperStyle: PropTypes.any,
};

/**
 * @component
 *
 * A carousel component based on [Flickity](https://flickity.metafizzy.co).
 *
 * Each child element is a cell.
 */
export function Carousel({ children, ...rest }) {
  const slides = React.Children.toArray(children);
  const childrenKey = JSON.stringify(slides.map((slide) => slide.key));
  return (
    <Flickity {...rest} key={childrenKey}>
      {slides}
    </Flickity>
  );
}

Carousel.propTypes = {
  /**
   * Styles to pass to each cell element (each item/child is a cell). It will be
   * included along with the base styles and can be anything styled-components
   * supports in its tagged template literals, including strings, objects, and
   * functions.
   */
  cellStyle: PropTypes.any,
  /**
   * Cells to include in the carousel. Each child is a single cell.
   */
  children: PropTypes.node,
  /**
   * Class to apply to the root element.
   */
  className: PropTypes.string,
  flickityRef: PropTypes.any,
  /**
   * See: https://flickity.metafizzy.co/options.html#imagesloaded
   */
  imagesLoaded: PropTypes.bool,
  /**
   * Loading Skeleton props to pass to the CarouselSkeletonLoader component
   */
  loadingSkeletonCardStyle: PropTypes.any,
  loadingSkeletonDesktopCardCount: PropTypes.number,
  loadingSkeletonMobileCardCount: PropTypes.number,
  loadingSkeletonWrapperStyle: PropTypes.any,
  showLoadingSkeleton: PropTypes.bool,
  /**
   * Inline styles to apply to the root element.
   */
  style: PropTypes.object,
  /**
   * Styles to pass to the wrapper element. It will be included along with the
   * base styles and can be anything styled-components supports in its tagged
   * template literals, including strings, objects, and functions.
   */
  wrapperStyle: PropTypes.any,
};

Carousel.defaultProps = {
  cellSelector: '[data-carousel-cell]',
  imagesLoaded: true,
};

export default withThemeProps(Carousel, {
  section: 'carousel',
  defaultVariant: 'default',
});

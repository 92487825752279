import React from 'react';

import dynamic from 'next/dynamic';

import { AdminGuard } from '../../../techstyle-shared/react-admin';

import promosModule, { loadPromos } from './promosModule';

const PromosAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './PromosAdminPanel')
);

const OneHourPromosExpire = dynamic(() =>
  import(/* webpackChunkName: "OneHourPromosExpire" */ './OneHourPromosExpire')
);

export default function promosExtension({
  defaultPromosRequired = false,
  firstHourRefresh = false,
} = {}) {
  return {
    id: 'promos',
    redux: {
      modules: [promosModule],
    },
    app: {
      render(props, children) {
        return (
          <>
            {children}
            <AdminGuard>
              <PromosAdminPanel />
            </AdminGuard>
            {firstHourRefresh ? <OneHourPromosExpire /> : null}
          </>
        );
      },
    },
    page(options) {
      const { promosRequired = defaultPromosRequired } = options;

      return {
        async getInitialProps(ctx) {
          if (promosRequired) {
            await ctx.store.dispatch(loadPromos());
          }
        },
      };
    },
  };
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../Button';
import Heading from '../Heading';
import { mobile } from '../styles';

const Wrapper = styled.div`
  font-size: 14px;
  padding: 32px;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.white};

  ${mobile`
    border: 0;
    padding: 16px;
  `}
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 24px 0;
  background-color: ${({ theme }) => theme.colors.lightMineral};
`;

const Header = styled.div`
  display: flex;
`;

const StyledHeading = styled(Heading).attrs({
  level: 2,
})`
  font-size: 18px;
  font-weight: 700;
  line-height: ${24 / 18};
  letter-spacing: ${0.5 / 18}em;
  flex-grow: 1;

  ${mobile`
    font-size: 16px;
  `}
`;

const HeadingDivider = styled(Divider)`
  ${mobile`
    display: none;
  `}
`;

const Action = styled(Button).attrs({
  variant: 'unstyled',
})`
  font-size: 18px;
  font-weight: 400;
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ContentSection = styled.section`
  padding: 24px 0;

  ${mobile`
    padding: 16px 0;
  `}
`;

const Content = styled.div`
  ${ContentSection} ~ ${ContentSection} {
    border-top: 1px solid ${({ theme }) => theme.colors.lightMineral};
  }
  ${ContentSection}:last-child {
    padding-bottom: 0;
  }

  ${ContentSection}:first-child {
    padding-top: 0;
  }

  ${Divider}:last-child {
    display: none;
  }
`;

export default function AccountBox({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
}

AccountBox.Header = Header;
AccountBox.Heading = StyledHeading;
AccountBox.Action = Action;
AccountBox.Divider = Divider;
AccountBox.HeadingDivider = HeadingDivider;
AccountBox.Content = Content;
AccountBox.ContentSection = ContentSection;

AccountBox.propTypes = {
  children: PropTypes.node,
};

import React from 'react';

import PropTypes from 'prop-types';
import { FaStar, FaRegStar } from 'react-icons/fa';
import styled from 'styled-components';

import {
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from '../../../../techstyle-shared/react-intl';
import Grid from '../Grid';
import OverallFitSlider from '../OverallFitSlider';
import ReviewStars from '../ReviewStars';
import { mobile, desktop } from '../styles';
import useTheme from '../useTheme';

const SummaryCell = styled.div`
  padding: 3.5em 1.25em;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mobile`
    border: none;
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    `}

  ${({ cellsWide, cellsTall }) => ({
    gridColumn: cellsWide && `span ${cellsWide}`,
    gridRow: cellsTall && `span ${cellsTall}`,
  })};

  ${({ cellStyle }) => cellStyle};
`;

const ReviewSummaryWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
  ${mobile`
    margin-bottom: 0;
  `};
`;

const OverallFitWrapper = styled.div`
  width: 100%;
  ${desktop`
    max-width: 440px;
  `};
  ${({ overallFitWrapperStyle }) => overallFitWrapperStyle};
`;

const AverageReview = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 12px;
  ${({ averageReviewStyle }) => averageReviewStyle};
`;

const RecommendedPercentage = styled.div`
  font-size: 20px;
  margin-top: ${({ overallFitAvailable }) =>
    overallFitAvailable ? '0' : '24'}px;
  ${mobile`
    font-size: 14px;
    color: #888888;
    margin-top: 16px;
  `}
  ${({ recommendPercentageStyle }) => recommendPercentageStyle};
`;

const RecommendedPercentageValue = styled.span`
  ${({ percentageValueStyle }) => percentageValueStyle};
`;

const ReviewTitleComponent = styled.div`
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 2em;
  ${desktop`
    display: ${({ showOnMobile }) => (showOnMobile ? 'none' : 'block')};
  `}
  ${mobile`
    display: ${({ showOnMobile }) => (showOnMobile ? 'block' : 'none')};
    padding-bottom: 0.75em;
    font-size: 16px;
  `}
  ${({ reviewTitleStyle }) => reviewTitleStyle};
`;

const gridBreakpoints = [
  { name: 'mobile', minWidth: 0, columns: 1, rowGap: 0, columnGap: 0 },
  { name: 'tablet+', minWidth: 768, columns: 3, rowGap: 32, columnGap: 16 },
];

const RecommendedPercentageMessage = ({
  overallFitAvailable,
  percentageValueStyle,
  recommendPercentageStyle,
  recommendedPercentage,
}) => {
  const intl = useIntl();

  return (
    <RecommendedPercentage
      overallFitAvailable={overallFitAvailable}
      recommendPercentageStyle={recommendPercentageStyle}
    >
      <RecommendedPercentageValue percentageValueStyle={percentageValueStyle}>
        {intl.formatNumber(recommendedPercentage / 100, {
          style: 'percent',
        })}
      </RecommendedPercentageValue>
      <FormattedMessage
        id="site_product_page.reviews_percent_who_recommend"
        defaultMessage="{percent} who purchased recommend it"
        values={{
          percent: '',
        }}
      />
    </RecommendedPercentage>
  );
};

const ReviewsSummary = ({
  averageReview,
  sliderMinPosition = 1,
  sliderMaxPosition = 5,
  sliderPosition,
  reviewCount,
  productName,
  recommendedPercentage,
  cellStyle,
  fitTypeStyle,
  recommendPercentageStyle,
  averageReviewStyle,
  sliderRailStyle,
  sliderPivotStyle,
  overallFitStyle,
  overallFitWrapperStyle,
  percentageValueStyle,
  reviewTitleStyle,
  reviewTitleTextComponent,
  reviewStarProps,
  ...rest
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const overallFitAvailable = reviewCount > 0 && !isNaN(sliderPosition);

  const roundedRating =
    Number.isFinite(averageReview) && averageReview.toFixed(1);

  return (
    <ReviewSummaryWrapper {...rest}>
      <ReviewTitleComponent
        showOnMobile={false}
        reviewTitleStyle={reviewTitleStyle}
        data-autotag="review_summary_title_and_count"
      >
        {reviewTitleTextComponent || (
          <>
            {intl.formatNumber(reviewCount)}{' '}
            <FormattedMessage
              id="site_product_page.reviews_for"
              defaultMessage="{count, plural, one { review} other { reviews}} for {productName}"
              values={{
                count: reviewCount,
                productName,
              }}
            />
          </>
        )}
      </ReviewTitleComponent>

      <Grid breakpoints={gridBreakpoints}>
        <SummaryCell
          cellStyle={cellStyle}
          cellsWide={overallFitAvailable ? 1 : 3}
          data-autotag="review_summary_rating"
        >
          <ReviewTitleComponent
            showOnMobile
            reviewTitleStyle={reviewTitleStyle}
          >
            {reviewTitleTextComponent || (
              <>
                {intl.formatNumber(reviewCount)}{' '}
                <FormattedMessage
                  id="site_product_page.reviews_for"
                  defaultMessage="{count, plural, one { review} other { reviews}} for {productName}"
                  values={{
                    count: reviewCount,
                    productName,
                  }}
                />
              </>
            )}
          </ReviewTitleComponent>

          <AverageReview averageReviewStyle={averageReviewStyle}>
            <FormattedNumber value={roundedRating} />
          </AverageReview>

          <ReviewStars
            rating={averageReview}
            maxRating={5}
            fullIcon={
              <FaStar color={theme.colors.highlightColor} size="1.5em" />
            }
            emptyIcon={
              <FaRegStar color={theme.colors.highlightColor} size="1.5em" />
            }
            {...reviewStarProps}
          />
          {!overallFitAvailable && (
            <RecommendedPercentageMessage
              overallFitAvailable={overallFitAvailable}
              recommendedPercentage={recommendedPercentage}
              recommendPercentageStyle={recommendPercentageStyle}
              percentageValueStyle={percentageValueStyle}
            />
          )}
        </SummaryCell>

        {overallFitAvailable && (
          <SummaryCell cellStyle={cellStyle} cellsWide={2}>
            <OverallFitWrapper overallFitWrapperStyle={overallFitWrapperStyle}>
              <RecommendedPercentageMessage
                overallFitAvailable={overallFitAvailable}
                recommendedPercentage={recommendedPercentage}
                recommendPercentageStyle={recommendPercentageStyle}
                percentageValueStyle={percentageValueStyle}
              />
              <OverallFitSlider
                minPosition={sliderMinPosition}
                maxPosition={sliderMaxPosition}
                sliderPosition={sliderPosition}
                overallFitStyle={overallFitStyle}
                sliderPivotStyle={sliderPivotStyle}
                sliderRailStyle={sliderRailStyle}
                fitTypeStyle={fitTypeStyle}
              />
            </OverallFitWrapper>
          </SummaryCell>
        )}
      </Grid>
    </ReviewSummaryWrapper>
  );
};

RecommendedPercentageMessage.propTypes = {
  overallFitAvailable: PropTypes.bool,
  percentageValueStyle: PropTypes.any,
  recommendPercentageStyle: PropTypes.any,
  recommendedPercentage: PropTypes.number,
};

ReviewsSummary.propTypes = {
  /**
   * The average review of the product to be filled in stars, eg. 3.752
   */
  averageReview: PropTypes.number,
  /**
   * Styles to add brand specific styles to Average Rating text above the stars
   */
  averageReviewStyle: PropTypes.any,
  /**
   * Styles to override default cell style
   */
  cellStyle: PropTypes.any,
  /**
   * Styles to add brand specific styles to the Fit type text
   */
  fitTypeStyle: PropTypes.any,

  /**
   * Styles to add brand specific styles to the OverallFit title above the slider
   */
  overallFitStyle: PropTypes.any,
  /**
   * Styles for OverallFit wrapper.
   */
  overallFitWrapperStyle: PropTypes.any,

  /* Style of the percentage recommended value */
  percentageValueStyle: PropTypes.any,
  /**
   * The name of the product to be shown in review summary section
   */
  productName: PropTypes.string,

  /**
   * Styles to add brand specific styles to the recommended percentage
   */
  recommendPercentageStyle: PropTypes.any,
  /**
   * The number to show how many users recommend the product
   */
  recommendedPercentage: PropTypes.number,
  /**
   * The total number of reviews that customers have given for the product
   */
  reviewCount: PropTypes.number,
  /**
   * Prop that will be spread on the review stars.
   * Accepts any of the props that can be passed to ReviewStars component
   */
  reviewStarProps: PropTypes.object,
  /**
   * Styles to add brand specific styles to the title of the summary section
   */
  reviewTitleStyle: PropTypes.any,
  /**
   * A component to show custom component after the reviewCount in the title of summary section
   * This defaults to 'review for {product name}'
   */
  reviewTitleTextComponent: PropTypes.node,
  /**
   * The max value of the slider (defaults to 1)
   */
  sliderMaxPosition: PropTypes.number,
  /**
   * The min value of the slider (defaults to 5)
   */
  sliderMinPosition: PropTypes.number,
  /**
   * Styles to add brand specific styles to the pivot of the slider of overall outfit component
   */
  sliderPivotStyle: PropTypes.any,
  /**
   * The position of the slider from `minPosition` to `maxPosition`. `minPosition` being 'runs small' and `maxPosition` being 'runs large'.
   */
  sliderPosition: PropTypes.number,
  /**
   * Styles to add brand specific styles to the rail of the slider of overall outfit component
   */
  sliderRailStyle: PropTypes.any,
};

export default ReviewsSummary;

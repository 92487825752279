import React, { useCallback, useEffect, useRef } from 'react';

import styled from 'styled-components';

import BaseButton from '../Button';
import { getNextTabbable, getPrevTabbable } from '../utils/getRelativeTabbable';

// FIXME: Used as a hack since `Button` does not have all necessary props
const Button = styled(BaseButton)``;

type LoadMoreButtonProps = React.ComponentProps<typeof Button> & {
  onClick: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading: boolean;
};

export default function LoadMoreButton({
  children,
  onClick,
  isLoading,
  ...rest
}: React.PropsWithChildren<LoadMoreButtonProps>) {
  const prevIsLoadingRef = useRef(false);
  const referenceTabbableRef = useRef<HTMLElement | null>(null);

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // Store a reference to the tabbable element just above this
      // button, since this button will move once the items are
      // loaded. The reference will be used in onLoadComplete to focus
      // on the first tabbable element where the button used to be, or
      // the button itself, if nothing else loaded.
      referenceTabbableRef.current = getPrevTabbable(
        event.currentTarget
      ) as HTMLElement;

      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  useEffect(() => {
    if (!isLoading && prevIsLoadingRef.current) {
      if (referenceTabbableRef.current) {
        const firstTabbableInsideLoadedContent = getNextTabbable(
          referenceTabbableRef.current
        );
        if (firstTabbableInsideLoadedContent) {
          firstTabbableInsideLoadedContent.focus();
        }
      }

      // Not strictly necessary to reset this, but it could keep unrelated actions
      // that trigger order loading from again forcing the focus to the particular
      // element.
      referenceTabbableRef.current = null;
    }

    prevIsLoadingRef.current = isLoading;
  }, [isLoading]);

  return (
    <Button onClick={handleButtonClick} {...rest}>
      {children}
    </Button>
  );
}

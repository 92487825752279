import { useContext } from 'react';

import { HydrationStatus } from './appModule';
import { HydrationContext } from './AppProvider';

function defaultGetStatus(status) {
  return status === HydrationStatus.COMPLETE;
}

/**
 * Get (and subscribe to) the app's current hydration status. By default it will
 * return a Boolean indicating whether hydration is in the `COMPLETE` state, but
 * you can pass a custom function that accepts the hydration status and returns
 * whatever you like (including the raw status itself).
 */
export default function useHydrationStatus(getStatus = defaultGetStatus) {
  const status = useContext(HydrationContext);
  return getStatus(status);
}

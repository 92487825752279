import { isStandardSelectorVariation } from './utils/sizes';

const getSelectedReviewsSizes = ({
  sizes = [],
  filteredSizes = [],
  otherSelectors = [],
} = {}) => {
  let result = sizes.filter((item) => {
    return filteredSizes.includes(item.label);
  });

  if (otherSelectors.length > 0) {
    result = result.filter((item) => {
      if (!item.attribute) {
        return true;
      }

      for (const attr of otherSelectors) {
        if (
          isStandardSelectorVariation(attr) &&
          (isStandardSelectorVariation(item.attribute) ||
            isStandardSelectorVariation(item.size))
        ) {
          return true;
        } else {
          if (item.attribute.startsWith(attr)) {
            return true;
          }
        }
      }

      return false;
    });
  }

  const sizesToQuery = result.map((item) => item.size);

  return { sizesToQuery };
};

export default getSelectedReviewsSizes;

import React from 'react';

import hoistNonReactStatics from 'hoist-non-react-statics';

export default (registry) =>
  function createPage(Component, options = {}) {
    const pageConfig = registry.page(options);
    const name = Component.displayName || Component.name || 'Page';

    function PageComponent(props) {
      const page = <Component {...props} />;
      const children = pageConfig.render(props, page);
      return children;
    }

    PageComponent.displayName = `createPage(${name})`;

    PageComponent.getInitialProps = pageConfig.enhanceInitialProps(
      async (ctx) => {
        const getExtensionProps = pageConfig.getInitialProps(ctx);
        const getInitialProps = Component.getInitialProps
          ? Component.getInitialProps(ctx)
          : {};

        let promise = Promise.all([getExtensionProps, getInitialProps]);
        // FIXME: https://github.com/zeit/next.js/issues/7599
        // Some requests will hang if the error page itself throws an error.
        // So make sure to catch that here only for the error page.
        if (ctx.pathname === '/_error') {
          promise = promise.catch((err) => {
            // eslint-disable-next-line no-console
            console.error(
              'Error while rendering /pages/_error! The page may be rendered with fallback content.'
            );
            // eslint-disable-next-line no-console
            console.error(err);
            return [];
          });
        }

        const allInitialProps = await promise;

        return Object.assign({}, ...allInitialProps);
      }
    );

    hoistNonReactStatics(PageComponent, Component, { getInitialProps: true });

    return pageConfig.enhance(PageComponent);
  };

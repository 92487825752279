import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import useAccountActions from './useAccountActions';

/**
 *
 * @param {string[]} [vars] - The email preferences to load. If not provided, the
 * `public.emailPreferences.masterList` config value will be used.
 */
export default function useEmailPreferences(vars) {
  const actions = useAccountActions();
  const emailPreferences = useSelector((state) => state.emailPreferences);

  useEffect(() => {
    if (!emailPreferences.networkStatus.isUpToDate) {
      actions.loadEmailPreferences(vars);
    }
  }, [actions, emailPreferences.networkStatus.isUpToDate, vars]);

  return emailPreferences;
}

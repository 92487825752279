import React, { useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext();

export function useProductGridPricingContext() {
  return useContext(Context);
}

export default function ProductGridPricingContext({
  children,
  productGridPricing,
}) {
  const context = useMemo(() => {
    return {
      productGridPricing,
    };
  }, [productGridPricing]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

ProductGridPricingContext.propTypes = {
  children: PropTypes.node,
  productGridPricing: PropTypes.object,
};

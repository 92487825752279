import { useEffect, useState } from 'react';

/**
 * Call `condition` until it returns a truthy status and return the result.
 * Polling is generally frowned upon; use this as a last resort to integrate
 * with third-party APIs that don't expose proper events or methods.
 *
 * Not currently exported; for internal use only.
 */
export default function usePolling({
  condition,
  interval,
  maxPollCount,
  maxReachedStatus = true,
} = {}) {
  const [status, setStatus] = useState(false);
  const [pollCount, setPollCount] = useState(0);

  if (!interval) {
    throw new Error('usePolling: You must supply a positive polling interval.');
  }

  useEffect(() => {
    const maxReached = maxPollCount != null && pollCount >= maxPollCount;
    if (condition && !status && !maxReached) {
      const result = condition();
      setStatus((prevResult) => result);
      const timeoutId = setTimeout(() => {
        setPollCount((prevCount) => prevCount + 1);
      }, interval);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [condition, interval, maxPollCount, pollCount, status]);

  return status;
}

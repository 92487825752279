import React from 'react';

import { Field, Formik, FieldProps } from 'formik';
import styled from 'styled-components';
import { object, SchemaOf, string } from 'yup';

import { useDomain } from '../../../../techstyle-shared/redux-core';
import { FormGrid, FormActions } from '../AccountInfoForm';
import FormField from '../FormField';
import StyledSelect from '../StyledSelect';

const SelectFieldset = styled(StyledSelect)`
  height: 48px;
  flex: 1;
`;
type SelectProps = Omit<React.ComponentProps<typeof StyledSelect>, 'children'>;
type CountryProps = SelectProps & {
  label: string;
  countryList: { [key: string]: string };
};
const Country = ({ label, countryList, ...rest }: CountryProps) => (
  <Field>
    {({ field }: FieldProps) => (
      <SelectFieldset {...rest}>
        <StyledSelect.Legend>{label}</StyledSelect.Legend>
        <StyledSelect.Select
          onChange={field.onChange}
          value={field.value.countryCode}
          name="countryCode"
        >
          <StyledSelect.PlaceHolder>{label}</StyledSelect.PlaceHolder>
          {Object.keys(countryList).map((option) => (
            <StyledSelect.Option value={option} key={option}>
              {countryList[option]}
            </StyledSelect.Option>
          ))}
        </StyledSelect.Select>
        <StyledSelect.Chevron />
      </SelectFieldset>
    )}
  </Field>
);
type AddressFormSchemaProps = {
  firstNameRequired: string;
  lastNameRequired: string;
  address1Required: string;
  cityRequired: string;
  stateRequired?: string;
  zipRequired: string;
  phoneRequired: string;
};
const defaultErrorMessages = {
  firstNameRequired: '',
  lastNameRequired: '',
  address1Required: '',
  cityRequired: '',
  stateRequired: '',
  zipRequired: '',
  phoneRequired: '',
};
interface Address {
  firstName: string;
  lastName: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  countryCode: string;
  phone: string;
}
const getDefaultAddressFormSchema = (
  errorMessages: AddressFormSchemaProps = defaultErrorMessages
) => {
  const addressFormSchema = object({
    firstName: string().required(errorMessages.firstNameRequired),
    lastName: string().required(errorMessages.lastNameRequired),
    address1: string().required(errorMessages.address1Required),
    city: string().required(errorMessages.cityRequired),
    countryCode: string().defined(),
    state: string().when('countryCode', {
      is: 'US',
      then: string().required(errorMessages.stateRequired),
    }),
    zip: string().required(errorMessages.zipRequired),
    phone: string().required(errorMessages.phoneRequired),
  });
  return addressFormSchema;
};
interface AddressFormProps
  extends Omit<
    React.ComponentPropsWithRef<typeof Formik>,
    'initialValues' | 'validationSchema'
  > {
  address: Address;
  children: React.ReactNode | React.ReactNode[];
  validationSchema: SchemaOf<Address>;
}

function Root({
  address = {
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    countryCode: '',
    phone: '',
  },
  children,
  onSubmit,
  validationSchema,
  ...rest
}: AddressFormProps) {
  const { tld } = useDomain();
  const isUS = tld === '.com';
  const {
    firstName,
    lastName,
    company,
    address1,
    address2,
    city,
    state,
    zip,
    countryCode,
    phone,
  } = address;
  const initialValues:
    | Omit<Address, 'company'>
    | Omit<Address, 'countryCode' | 'state'> = isUS
    ? {
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zip,
        countryCode: countryCode || 'US',
        phone,
      }
    : { firstName, lastName, company, address1, address2, city, zip, phone };
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      {...rest}
      initialValues={initialValues}
    >
      {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
    </Formik>
  );
}

export {
  Country,
  FormGrid,
  FormActions,
  FormField,
  Root,
  getDefaultAddressFormSchema,
};

import React, { useMemo } from 'react';

import { FaGlobeAmericas } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { AdminPanel } from '../../../../techstyle-shared/react-admin';
import AdminGeoDetection from '../AdminGeoDetection';
import AdminHighlightToggle from '../AdminHighlightToggle';
import { unindexMessages } from '../utils';

export default function IntlAdminPanel() {
  const { locale, resourceBundles } = useSelector((state) => state.intl);

  const unindexedResourceBundles = useMemo(() => {
    return Object.keys(resourceBundles).map((locale) => {
      const indexedBundles = resourceBundles[locale] || {};
      const unindexedBundles = Object.keys(indexedBundles).reduce(
        (unindexedBundles, groupCode) => {
          const { keyIndex, values, ...rest } = indexedBundles[groupCode];
          const resources = unindexMessages({ keyIndex, values });
          unindexedBundles[groupCode] = { ...rest, resources };
          return unindexedBundles;
        },
        {}
      );
      return [locale, unindexedBundles];
    });
  }, [resourceBundles]);

  return (
    <AdminPanel id="intl" label="Internationalization" icon={FaGlobeAmericas}>
      <AdminPanel.Fields>
        <AdminHighlightToggle />
        <AdminPanel.Field label="Locale:">{locale}</AdminPanel.Field>
        <AdminGeoDetection />
        <AdminPanel.Field type="table" label="Resource bundles:">
          {unindexedResourceBundles.map(([locale, localeBundles]) => {
            return (
              <AdminPanel.DataTable
                key={locale}
                title={locale}
                data={localeBundles}
                // Sort bundle groups and message keys.
                sortKeys={[true, false, true]}
              />
            );
          })}
        </AdminPanel.Field>
      </AdminPanel.Fields>
    </AdminPanel>
  );
}

import { useReducer } from 'react';

export default function useProductListingsReducer({ key, initialProducts }) {
  return useReducer(
    (state, action) => {
      switch (action.type) {
        case 'LOAD_PRODUCTS': {
          if (action.payloadKey !== key) {
            return state;
          }
          // There may be multiple pages of responses in `payload`.
          return action.payload.reduce(
            (newState, payload) => {
              newState.aggregations = payload.aggregations;
              newState.totalCount = payload.total;
              newState.pages[payload.page] = payload.products;
              return newState;
            },
            {
              ...state,
              payloadKey: action.payloadKey,
              pages: {
                ...(state.payloadKey === key ? state.pages : null),
              },
            }
          );
        }
        case 'UPDATE_PRODUCTS': {
          if (action.payloadKey !== key) {
            return state;
          }

          return action.payload.reduce(
            (newState, payload) => {
              newState.pages[payload.page] = payload.products;
              return newState;
            },
            {
              ...state,
              payloadKey: action.payloadKey,
              pages: {
                ...(state.payloadKey === key ? state.pages : null),
              },
            }
          );
        }
        default:
          return state;
      }
    },
    {
      payloadKey: initialProducts ? key : undefined,
      pages: {
        1: initialProducts ? initialProducts.products : undefined,
      },
      totalCount: initialProducts ? initialProducts.total : undefined,
    }
  );
}

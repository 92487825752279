import { useContext } from 'react';

import { ThemeContext } from 'styled-components';

/**
 * Retrieve the current theme object or a subsection of it.
 *
 * If `section` is supplied, it can either be a top-level property name to
 * retrieve from the theme, or a function that accepts the theme as an argument
 * and returns some value from it.
 *
 * If `variant` is supplied, it will further assume that whatever `section`
 * was retrieved has a `variants` property, and retrieve the given `variant`
 * from that object.
 */
export default function useTheme(section, variant) {
  const theme = useContext(ThemeContext);
  if (theme && section) {
    let value;
    if (typeof section === 'string') {
      value = theme[section];
    } else if (typeof section === 'function') {
      value = section(theme);
    }
    if (variant) {
      if (!value || !value.variants) {
        return undefined;
      }
      return value.variants[variant];
    }
    return value;
  }
  return theme;
}

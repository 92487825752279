/**
 * Given a product with image data, find the first image url
 * by image type. If no image type match, find the first default image url
 */
export default function getImageUrl({ product, imageType }) {
  let imageUrl = '';

  if (product?.productImages) {
    imageUrl =
      product.productImages?.[imageType]?.[0]?.sizes?.[0]?.url ||
      product.productImages?.default?.[0]?.sizes?.[0]?.url;
  }

  if (product?.imageSets) {
    imageUrl =
      product.imageSets?.[imageType]?.images?.[0]?.sizes?.[0]?.url ||
      product.imageSets?.default?.images?.[0]?.sizes?.[0]?.url;
  }

  return imageUrl;
}

export function hasEmbroideryImg({ product }) {
  return product?.imageSets?.embroidery !== null;
}

import React from 'react';

import config from 'config';
import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';

import logger from '../logger';
import Script from '../Script';
import useChangedValues from '../useChangedValues';

const debug = logger.extend('GoogleTagManagerSnippet');

function createSetup({ dataLayerName }) {
  const dataLayerString = serialize(dataLayerName);
  return `window[${dataLayerString}] = window[${dataLayerString}] || [];`;
}

function createScript({ containerId, dataLayerName }) {
  const containerString = serialize(containerId);
  const dataLayerString = serialize(dataLayerName);
  return `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),
event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),
dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
f.parentNode.insertBefore(j,f);})(window,document,'script',${dataLayerString},${containerString});
`;
}

function createNoScript({ containerId }) {
  const containerIdString = encodeURIComponent(containerId);
  return `
<iframe src="https://www.googletagmanager.com/ns.html?id=${containerIdString}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;
}

function createPush({ dataLayerName, data }) {
  const dataLayerString = serialize(dataLayerName);
  const dataString = serialize(data);
  // Don't push an empty object.
  if (dataString === '{}') {
    return '';
  }
  return `window[${dataLayerString}].push(${dataString});`;
}

function requireContainerId(containerId, type) {
  if (!containerId) {
    containerId = config.get('public.googleTagManager.containerId');
    if (!containerId) {
      throw new Error(
        `You must specify a containerId to use the '${type}' type.`
      );
    }
  }
  return containerId;
}

/**
 * A component for rendering Google Tag Manager (GTM) snippets. Several
 * different snippet types are available via this component.
 *
 * - The `script` snippet type is the standard `<script>` code for injecting the
 *   GTM JavaScript loader.
 * - The `noscript` snippet type renders a `<noscript>` tag that renders an
 *   `<iframe>`, as recommended in the GTM docs.
 * - The `setup` snippet type creates your data layer array if it doesn't
 *   exist already. You can use this to ensure the data layer is defined high
 *   up on the page, in case GTM itself isn't loaded until later.
 *
 * You can also render the following components as shortcuts for the non-default
 * snippet types:
 *
 * - `<GoogleTagManagerSnippet.Setup>`
 * - `<GoogleTagManagerSnippet.NoScript>`
 */
export default function GoogleTagManagerSnippet({
  containerId,
  data,
  dataLayerName = config.has('public.googleTagManager.dataLayerName')
    ? config.get('public.googleTagManager.dataLayerName')
    : 'dataLayer',
  globalScriptKey,
  type,
}) {
  switch (type) {
    case 'script': {
      containerId = requireContainerId(containerId, type);
      let contents = createScript({ containerId, dataLayerName });
      if (data) {
        contents += '\n' + createPush({ dataLayerName, data });
      }
      return (
        <Script
          globalScriptKey={
            globalScriptKey || [
              'googleTagManager',
              type,
              containerId,
              dataLayerName,
            ]
          }
          dangerouslySetInnerHTML={{ __html: contents }}
        />
      );
    }
    case 'setup': {
      let contents = createSetup({ dataLayerName });
      if (data) {
        contents += '\n' + createPush({ dataLayerName, data });
      }
      return (
        <Script
          globalScriptKey={
            globalScriptKey || ['googleTagManager', type, dataLayerName]
          }
          dangerouslySetInnerHTML={{ __html: contents }}
        />
      );
    }
    case 'noscript':
      containerId = requireContainerId(containerId, type);
      return (
        <noscript
          dangerouslySetInnerHTML={{
            __html: createNoScript({ containerId }),
          }}
        />
      );
    case 'push': {
      const contents = createPush({ dataLayerName, data });
      return contents ? (
        <Script
          globalScriptKey={globalScriptKey}
          dangerouslySetInnerHTML={{ __html: contents }}
        />
      ) : null;
    }
  }
  debug(`Unknown type passed to GoogleTagManagerSnippet: ${type}`);
  return null;
}

GoogleTagManagerSnippet.propTypes = {
  /**
   * The GTM container ID (looks like GTM-XXXXXX). Automatically read from
   * `public.googleTagManager.containerId` if not explicitly passed.
   */
  containerId: PropTypes.string,
  /**
   * Data to push onto the data layer. Only applicable to the `script`, `setup`,
   * and `push` types. When used with `script` and `setup`, the only difference
   * is that a separate `<script>` will not be created in which to push the
   * data.
   *
   * You should only use this if the data needs to be pushed onto the data
   * layer as early as possible, before React begins rendering. Otherwise,
   * use `googleTagManagerEvents` or `useGoogleTagManager` to push data in
   * response to events.
   */
  data: PropTypes.object,
  /**
   * The name of the global data layer array to use. Automatically read from
   * `public.googleTagManager.dataLayerName` if not explicitly passed.
   */
  dataLayerName: PropTypes.string,
  /**
   * Custom `globalScriptKey` to pass to the `Script` component. If not
   * provided, one will be generated for the `script` and `setup` types.
   */
  globalScriptKey: PropTypes.any,
  /**
   * The type of snippet to render.
   */
  type: PropTypes.oneOf(['script', 'setup', 'noscript', 'push']),
};

GoogleTagManagerSnippet.defaultProps = {
  type: 'script',
};

/**
 * A helper component for rendering the `setup` snippet type.
 */
GoogleTagManagerSnippet.Setup = (props) => (
  <GoogleTagManagerSnippet {...props} type="setup" />
);
GoogleTagManagerSnippet.Setup.displayName = 'GoogleTagManagerSnippet.Setup';

/**
 * A helper component for rendering the `noscript` snippet type.
 */
GoogleTagManagerSnippet.NoScript = (props) => (
  <GoogleTagManagerSnippet {...props} type="noscript" />
);
GoogleTagManagerSnippet.NoScript.displayName =
  'GoogleTagManagerSnippet.NoScript';

/**
 * A helper component for rendering the `push` snippet type. It will
 * automatically filter the given values to only those that have changed since
 * last time they were pushed, unless the data object has an `event` key, in
 * which case it will always be pushed.
 */
function GoogleTagManagerSnippetPush({ data, ...rest }) {
  const changedValues = useChangedValues(data);
  const dataToPush = data && data.event ? data : changedValues;
  return <GoogleTagManagerSnippet {...rest} type="push" data={dataToPush} />;
}

GoogleTagManagerSnippetPush.displayName = 'GoogleTagManagerSnippet.Push';
GoogleTagManagerSnippetPush.propTypes = { data: PropTypes.object };

GoogleTagManagerSnippet.Push = GoogleTagManagerSnippetPush;

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getFeature } from './featuresModule';
import useFeatureActions from './useFeatureActions';

export default function useFeature(handle, options) {
  const actions = useFeatureActions();
  const feature = useSelector((state) => getFeature(state, handle));

  useEffect(() => {
    if (handle) {
      actions.loadFeatures(handle, options);
    }
  });

  return feature;
}

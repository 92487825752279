import React, { useLayoutEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { HydrationStatus, setHydrationStatus } from '../appModule';

const useServerSafeLayoutEffect = process.browser ? useLayoutEffect : () => {};

export const HydrationContext = React.createContext();

export default function AppProvider({ children }) {
  const dispatch = useDispatch();
  const hydrationStatus = useSelector((state) => state.app.hydrationStatus);

  useServerSafeLayoutEffect(() => {
    dispatch(setHydrationStatus(HydrationStatus.COMPLETE));
  }, [dispatch]);

  return (
    <HydrationContext.Provider value={hydrationStatus}>
      {children}
    </HydrationContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import StyledSelect from '../StyledSelect';
import {
  getBirthdayYearList,
  getBirthdayDaysList,
  getBirthdayMonthList,
} from '../utils/dates';

const BirthdayInputGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const SelectFieldset = styled(StyledSelect)`
  height: 48px;
  flex: 1;
`;

const Day = ({
  dayMessage,
  birthDay,
  birthMonth,
  birthYear,
  onChange,
  ...rest
}) => (
  <SelectFieldset {...rest}>
    <StyledSelect.Legend>{dayMessage}</StyledSelect.Legend>
    <StyledSelect.Select onChange={onChange} value={birthDay} name="birthDay">
      <StyledSelect.PlaceHolder>DD</StyledSelect.PlaceHolder>
      {getBirthdayDaysList(birthYear, birthMonth).map((option) => (
        <StyledSelect.Option value={option} key={option}>
          {option < 10 ? '0' + String(option) : option}
        </StyledSelect.Option>
      ))}
    </StyledSelect.Select>
    <StyledSelect.Chevron />
  </SelectFieldset>
);

Day.propTypes = {
  birthDay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  birthMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  birthYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dayMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
};

const Month = ({ monthMessage, birthMonth, onChange, ...rest }) => (
  <SelectFieldset {...rest}>
    <StyledSelect.Legend>{monthMessage}</StyledSelect.Legend>
    <StyledSelect.Select
      onChange={onChange}
      value={birthMonth}
      name="birthMonth"
    >
      <StyledSelect.PlaceHolder>MM</StyledSelect.PlaceHolder>
      {getBirthdayMonthList().map((option) => (
        <StyledSelect.Option value={option} key={option}>
          {option < 10 ? '0' + String(option) : option}
        </StyledSelect.Option>
      ))}
    </StyledSelect.Select>
    <StyledSelect.Chevron />
  </SelectFieldset>
);

Month.propTypes = {
  birthMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  monthMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
};

const Year = ({ yearMessage, birthYear, onChange, ...rest }) => (
  <SelectFieldset {...rest}>
    <StyledSelect.Legend>{yearMessage}</StyledSelect.Legend>
    <StyledSelect.Select onChange={onChange} value={birthYear} name="birthYear">
      <StyledSelect.PlaceHolder>YYYY</StyledSelect.PlaceHolder>
      {getBirthdayYearList().map((option) => (
        <StyledSelect.Option value={option} key={option}>
          {option}
        </StyledSelect.Option>
      ))}
    </StyledSelect.Select>
    <StyledSelect.Chevron />
  </SelectFieldset>
);

Year.propTypes = {
  birthYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  yearMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const BirthdayPicker = ({ children, ...rest }) => {
  return <BirthdayInputGroup {...rest}>{children}</BirthdayInputGroup>;
};

BirthdayPicker.Day = Day;
BirthdayPicker.Month = Month;
BirthdayPicker.Year = Year;

BirthdayPicker.propTypes = {
  children: PropTypes.any,
};

export default BirthdayPicker;

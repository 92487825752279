import qs from 'querystring';
import { format } from 'url';

import config from 'config';

import { Router } from '../../techstyle-shared/next-routes';

/**
 * return parsed pathnames (as array) and query (as object) from URL string
 */

export function getParsedPathnamesAndQuery(pathname) {
  const [baseUrl, queryString] = pathname.split(/([?#])/g);
  const pathnames = baseUrl.replace(/^\/|\/$/g, '').split('/');
  const queryObj = qs.parse(queryString);

  return {
    pathnames,
    queryObj,
  };
}

export const getSectionsAndQueryFromPath = (asPath) => {
  if (!asPath) {
    return {};
  }

  const { queryObj, pathnames } = getParsedPathnamesAndQuery(asPath);
  const [root, section, subsection, subsubsection] = pathnames;

  return {
    root,
    section,
    subsection,
    subsubsection,
    ...queryObj,
  };
};

/**
 * Turn a route object (supplied by `next/router`) or Link props (given to
 * `next/link`) into a route URL.
 */
export function toURL(linkOrRoute) {
  // FIXME Query should have ordered keys, for example { a, b } should match { b, a }
  if (!linkOrRoute) {
    return '';
  }
  if (linkOrRoute.href) {
    return format(linkOrRoute.href);
  }
  return format(linkOrRoute);
}

/**
 * Determine if the given `route` is equal to `activeRoute` by serializing them
 * both into URL strings.
 */
export function areRoutesEqual(route, activeRoute) {
  return toURL(route) === toURL(activeRoute);
}

/**
 * remove the junk (queries/params/hash) from the url my dudes
 */

export function urlNoQueryString(url) {
  const regex = /([?#])/g;
  return url.split(regex)[0];
}

export function redirect({
  res,
  url,
  as,
  statusCode = 302,
  options,
  asPush = false,
}) {
  if (res) {
    res.redirect(statusCode, as || format(url));
    res.end();
  } else {
    if (asPush) {
      Router.push(url, as, options);
    } else {
      Router.replace(url, as, options);
    }
  }
}

export function checkForReload(ctx) {
  const reloadPath = config
    .get('public.brand.spaNavigationReloadRoutes')
    .includes(ctx.asPath);
  if (process.browser && reloadPath) {
    window.location = ctx.asPath;
  }
}

export function isMensRoute(categoryPath) {
  const [root, secondNode] = categoryPath ? categoryPath.split('/') : [];
  return [root, secondNode].includes('mens');
}

import config from 'config';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import {
  getInitialMiddleware,
  createBentoAction,
  createAction,
  createCachedSelector,
  createReducer,
  NetworkStatus,
  outOfDateStatus,
  startLoading,
  stopLoading,
  upToDateStatus,
  configureRTKStore,
} from '../../../techstyle-shared/redux-core';

import { OrderDetailError } from './schema';
import { OrderRmaDto } from './types';
import type {
  OrderDetail,
  OrdersResponseDto,
  ReturnsSettingsDto,
  RmaResponseDto,
  LoyaltyHistoryDto,
} from './types';

export const initiateReturnRequest = createAction(
  'accounts/initiateReturnRequest'
);
export const initiateReturnSuccess = createAction(
  'accounts/initiateReturnSuccess'
);
export const initiateReturnFailure = createAction(
  'accounts/initiateReturnFailure'
);

export function initiateReturn(returnData: OrderRmaDto) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: `accounts/me/orders/${returnData.orderId}/rmas`,
      json: returnData,
      actions: [
        initiateReturnRequest,
        initiateReturnSuccess,
        initiateReturnFailure,
      ],
    },
  };
}

/* ------------------------------------
 * Order Details Reducer
 * ------------------------------------ */

type OrderDetailsItem = {
  data?: OrderDetail;
  fetchedDate?: string;
  networkStatus: NetworkStatus;
  error?: any;
};

export type OrderId = number | string;

type OrderDetailsState = Record<OrderId, OrderDetailsItem>;

export const initialOrderDetailsState: OrderDetailsState = {};

export const loadOrderDetailsRequest = createAction(
  'accounts/loadOrderDetailsRequest'
);
export const loadOrderDetailsSuccess = createAction(
  'accounts/loadOrderDetailsSuccess'
);
export const loadOrderDetailsFailure = createAction(
  'accounts/loadOrderDetailsFailure'
);

export const invalidateOrderDetailsAction = createAction<{ orderId: OrderId }>(
  'accounts/invalidateOrderDetails'
);

export const orderDetailsReducer = createReducer(initialOrderDetailsState, {
  [loadOrderDetailsRequest.type]: (state, action) => {
    if (!state[action.meta.orderId]) {
      state[action.meta.orderId] = {
        networkStatus: outOfDateStatus(),
      };
    }

    startLoading(state[action.meta.orderId]);
  },
  [loadOrderDetailsFailure.type]: (state, action) => {
    if (!state[action.meta.orderId]) {
      state[action.meta.orderId] = {
        networkStatus: outOfDateStatus(),
      };
    }
    state[action.meta.orderId].error = OrderDetailError.OTHER;
    state[action.meta.orderId].fetchedDate = action.meta.fetchedDate;
    stopLoading(state[action.meta.orderId]);
  },
  [loadOrderDetailsSuccess.type]: (state, action) => {
    const result = action.payload;
    state[result.orderId] = {
      data: result,
      fetchedDate: action.meta.fetchedDate,
      error: null,
      networkStatus: upToDateStatus(),
    };
  },
  [invalidateOrderDetailsAction.type]: (state, action) => {
    if (state[action.payload.orderId]) {
      state[action.payload.orderId].networkStatus = outOfDateStatus();
    }
  },
  [initiateReturnSuccess.type]: (state, action) => {
    const orderId = action?.meta?.fetchOptions?.json?.orderId;
    if (state[orderId]) {
      state[orderId].networkStatus = outOfDateStatus();
    }
  },
});

export const invalidateOrderDetails = (orderId: OrderId) => {
  return invalidateOrderDetailsAction({ orderId }, {});
};

/* ------------------------------------
 * Return Settings Reducer
 * ------------------------------------ */

type ReturnSettingsState = {
  settings: ReturnsSettingsDto | null;
  networkStatus: NetworkStatus;
};

export const initialReturnSettingsState: ReturnSettingsState = {
  networkStatus: outOfDateStatus(),
  settings: null,
};

export const loadReturnSettingsRequest = createAction(
  'accounts/loadReturnSettingsRequest'
);
export const loadReturnSettingsSuccess = createAction<ReturnsSettingsDto>(
  'accounts/loadReturnSettingsSuccess'
);
export const loadReturnSettingsFailure = createAction(
  'accounts/loadReturnSettingsFailure'
);

export const returnSettingsReducer = createReducer(
  initialReturnSettingsState,
  (builder) =>
    builder
      .addCase(loadReturnSettingsRequest, startLoading)
      .addCase(loadReturnSettingsFailure, stopLoading)
      .addCase(loadReturnSettingsSuccess, (state, action) => {
        const result = action.payload;
        state.settings = result;
        state.networkStatus = upToDateStatus();
      })
);

/* ------------------------------------
 * Typed Module Helpers
 * ------------------------------------ */

function createStore() {
  return configureRTKStore({
    reducer: {
      orderDetails: orderDetailsReducer,
      returnSettings: returnSettingsReducer,
    },
    middleware: getInitialMiddleware(),
  });
}

type ModuleStore = ReturnType<typeof createStore>;

export type AccountsModuleState = ReturnType<ModuleStore['getState']>;
export type AccountsModuleDispatch = ModuleStore['dispatch'];
export type AccountsModuleThunk<ReturnType = void> = (
  dispatch: AccountsModuleDispatch,
  getState: ModuleStore['getState']
) => ReturnType;

export const useModuleSelector: TypedUseSelectorHook<AccountsModuleState> =
  useSelector;
export const useModuleDispatch: () => AccountsModuleDispatch = useDispatch;

/* ------------------------------------
 * Actions
 * ------------------------------------ */

export function loadOrderDetails(orderId: OrderId): AccountsModuleThunk {
  return (dispatch, getState) => {
    const state = getState().orderDetails;
    const order = state[orderId];
    let shouldFetch = false;

    if (order) {
      if (order.networkStatus.isLoading) {
        // Already loading, do nothing.
      } else if (!order.networkStatus.isUpToDate) {
        shouldFetch = true;
      }
    } else {
      shouldFetch = true;
    }

    if (shouldFetch) {
      return dispatch(
        createBentoAction<OrderDetail, any>({
          endpoint: `accounts/me/orders/${orderId}`,
          requestKey: JSON.stringify(['loadOrderDetails', orderId]),
          actions: [
            (payload, meta) =>
              loadOrderDetailsRequest(payload, { ...meta, orderId }),
            (payload, meta) =>
              loadOrderDetailsSuccess(payload, { ...meta, orderId }),
            (payload, meta) =>
              loadOrderDetailsFailure(payload, { ...meta, orderId }),
          ],
        })
      );
    }
  };
}

export const loadOrdersRequest = createAction('accounts/loadOrdersRequest');
export const loadOrdersSuccess = createAction('accounts/loadOrdersSuccess');
export const loadOrdersFailure = createAction('accounts/loadOrdersFailure');

// The API currently accepts -1,0 and 1 as options for the status filter.
// Corresponding to "all" (-1), "non-shipped" (0) and "shipped" (1) orders
export enum ShippingStatusFilter {
  All = -1,
  Pending,
  Shipped,
}

type loadOrdersParams = {
  page?: number;
  pageSize?: number;
  sort?: string;
  dateExpectedMin?: Date;
  dateExpectedMax?: Date;
  orderStatusFilter?: ShippingStatusFilter;
  excludeOrderTypeIds?: Array<number>;
};

export function loadOrders({
  page = 1,
  pageSize = config.get('public.orders.pageSize'),
  sort = 'DESC',
  dateExpectedMin,
  dateExpectedMax,
  orderStatusFilter = ShippingStatusFilter.All,
  excludeOrderTypeIds,
}: loadOrdersParams = {}) {
  return createBentoAction<OrdersResponseDto>({
    endpoint: 'accounts/me/orders',
    requestKey: JSON.stringify(['orders', page, pageSize, sort]),
    searchParams: {
      pageIndex: page,
      recordsPerPage: pageSize,
      order: sort,
      shipped: orderStatusFilter,
      excludeOrderTypeIds: excludeOrderTypeIds?.toString(),
      dateExpectedMin: dateExpectedMin?.toISOString(),
      dateExpectedMax: dateExpectedMax?.toISOString(),
    },
    actions: [loadOrdersRequest, loadOrdersSuccess, loadOrdersFailure],
  });
}

export function loadReturnSettings() {
  return createBentoAction<ReturnsSettingsDto>({
    endpoint: 'settings/returns',
    requestKey: 'loadReturnSettings',
    actions: [
      loadReturnSettingsRequest,
      loadReturnSettingsSuccess,
      loadReturnSettingsFailure,
    ],
  });
}

export const loadRMADetailsRequest = createAction(
  'accounts/loadRMADetailsRequest'
);
export const loadRMADetailsSuccess = createAction<RmaResponseDto>(
  'accounts/loadRMADetailsSuccess'
);
export const loadRMADetailsFailure = createAction(
  'accounts/loadRMADetailsFailure'
);

type RMAId = string | number | null;

export function loadRMADetails({
  rmaId,
  includeExchange = false,
}: {
  rmaId: RMAId;
  includeExchange?: boolean;
}) {
  const endpoint = `accounts/me/orders/rma/${rmaId}${
    includeExchange ? '?includeExchange=true' : ''
  }`;

  return createBentoAction<RmaResponseDto>({
    endpoint,
    method: 'GET',
    requestKey: `loadRMADetails:${endpoint}`,
    actions: [
      loadRMADetailsRequest,
      loadRMADetailsSuccess,
      loadRMADetailsFailure,
    ],
  });
}

/* ------------------------------------
 * Selectors
 * ------------------------------------ */

export const getOrderById = createCachedSelector(
  [
    (state: AccountsModuleState, orderId: OrderId) =>
      state.orderDetails[orderId],
    (state: AccountsModuleState, orderId: OrderId) => orderId,
  ],
  (
    orderDetails,
    orderId
  ): Omit<OrderDetailsItem, 'fetchedDate'> & {
    orderId: OrderId;
    fetchedDate?: string | null;
  } =>
    orderDetails
      ? { ...orderDetails, orderId }
      : {
          orderId,
          fetchedDate: null,
          networkStatus: outOfDateStatus(),
        }
)((state, orderId) => orderId);

// Loyalty History

export const loadLoyaltyHistoryRequest = createAction(
  'accounts/loadLoyaltyHistoryRequest'
);
export const loadLoyaltyHistorySuccess = createAction<LoyaltyHistoryDto>(
  'accounts/loadLoyaltyHistorySuccess'
);
export const loadLoyaltyHistoryFailure = createAction(
  'accounts/loadLoyaltyHistoryFailure'
);

export type loadLoyaltyHistoryParams = {
  page?: number;
  count?: number;
};

export function loadLoyaltyHistory({
  page = 1,
  count = 3,
}: loadLoyaltyHistoryParams = {}) {
  return createBentoAction<LoyaltyHistoryDto>({
    endpoint: 'accounts/me/loyalty/history',
    requestKey: JSON.stringify(['loadLoyaltyHistory', page, count]),
    searchParams: { page, count },
    actions: [
      loadLoyaltyHistoryRequest,
      loadLoyaltyHistorySuccess,
      loadLoyaltyHistoryFailure,
    ],
  });
}

import { createReducer, createSelector } from '@reduxjs/toolkit';
import config from 'config';
import createCachedSelector from 're-reselect';

import createBentoApiClient from './bentoApiClient';
import createAction from './createAction';
import {
  startLoading,
  stopLoading,
  outOfDateStatus,
  upToDateStatus,
} from './invalidation';
import logger from './logger';
import { Session, SessionDetailError } from './schema';
import { parseDate } from './utils';

const debug = logger.extend('bentoApiModule');

export const VisitorStatus = {
  FIRST_TIME_VISITOR: 'FIRST_TIME_VISITOR',
  RETURNING_VISITOR: 'RETURNING_VISITOR',
  LOGGED_IN: 'LOGGED_IN',
};

export const AuthType = {
  ANONYMOUS: 1,
  AUTOLOGIN: 2,
  CREDENTIALS: 4,
  GMS_LOGIN: 8,
};

export const initialState = {
  bentoApi: {
    contentPreview: false,
    requestFailureRate: 0,
    requestFailureType: 503,
    trueClientIp: null,
    // If this is true, we'll send `overrideEnv: true` to certain endpoints to
    // enforce ReCAPTCHA token validation (which is usually skipped in envs like
    // QA).
    overrideReCaptchaEnv: false,
    sessionToken: null,
    secondarySite: false,
  },
  session: {
    authType: AuthType.ANONYMOUS,
    isAdmin: false,
    visitorStatus: VisitorStatus.FIRST_TIME_VISITOR,
    isServerOnly: false,
  },
  sessionDetails: {
    keys: {},
    error: null,
    networkStatus: outOfDateStatus(),
  },
  storeGroup: {},
};

export const setStoreDomain = createAction('bentoApi/setStoreDomain');
export const setTrueClientIp = createAction('bentoApi/setTrueClientIp');
export const setVisitorStatus = createAction('bentoApi/setVisitorStatus');
export const setOverrideReCaptchaEnv = createAction(
  'bentoApi/setOverrideReCaptchaEnv'
);
export const setContentPreview = createAction('bentoApi/setContentPreview');
export const setSecondarySite = createAction('bentoApi/setSecondarySite');

export function initBentoApi() {
  return (dispatch, getState, context) => {
    if (context.isServer && !context.hasPreloadedState) {
      const {
        bentoApi,
        storeDomain,
        serverVisitorStatus,
        ip,
        ipAddressSource,
        isBuilderPreview,
      } = context.req.context;

      dispatch(setStoreDomain(storeDomain));

      if (serverVisitorStatus != null) {
        debug('Found an initial serverVisitorStatus: %o', serverVisitorStatus);
        dispatch(setVisitorStatus(serverVisitorStatus));
      }

      // For now, only set `trueClientIp` if it was an explicit override.
      // TODO: We should check what the API's behavior is when it's set to a
      // potentially bogus value (like 127.0.0.1) vs. not set. It may be
      // better in some situations to send nothing, which is why this is only
      // set when given an explicit override for now.
      if (ip && (ipAddressSource === 'query' || ipAddressSource === 'header')) {
        dispatch(setTrueClientIp(ip));
      }

      const allowContentPreview = config.get(
        'server.bentoApi.allowContentPreview'
      );

      if (
        allowContentPreview &&
        bentoApi?.enableContentPreview &&
        typeof bentoApi.enableContentPreview === 'function'
      ) {
        dispatch(
          setContentPreview(
            bentoApi.enableContentPreview(context.req) ||
              isBuilderPreview ||
              context.req.isBuilderPreview
          )
        );
      }
    }

    const state = getState();
    const { storeDomain } = state.storeGroup;
    const { contentPreview, trueClientIp } = state.bentoApi;
    const secondarySite =
      state.bentoApi.secondarySite ||
      (context.getSecondarySite && context.getSecondarySite(context));

    if (secondarySite !== state.bentoApi.secondarySite) {
      dispatch(setSecondarySite(secondarySite));
    }

    const bentoApiClientArgs = {
      isContentPreview: contentPreview,
      storeDomain,
      trueClientIp,
      req: context.req,
      res: context.res,
      isServer: context.isServer,
      secondarySite,
    };

    // if we're client side, support builder by sending in an authorization header
    if (!context.isServer && state?.bentoApi?.sessionToken) {
      const readSessionToken = () => {
        return state.bentoApi.sessionToken;
      };
      bentoApiClientArgs.isUnsafeAuth = true;
      bentoApiClientArgs.readSessionToken = readSessionToken;
    }

    context.bentoApi = createBentoApiClient(bentoApiClientArgs);

    context.inflight = new Map();

    if (!context.isServer) {
      dispatch(loadSession());
    }
  };
}

export const loadSessionRequest = createAction('bentoApi/loadSessionRequest');
export const loadSessionSuccess = createAction('bentoApi/loadSessionSuccess');
export const loadSessionFailure = createAction('bentoApi/loadSessionFailure');

export const updateSessionDetailRequest = createAction(
  'bentoApi/updateSessionDetailRequest'
);
export const updateSessionDetailSuccess = createAction(
  'bentoApi/updateSessionDetailSuccess'
);
export const updateSessionDetailFailure = createAction(
  'bentoApi/updateSessionDetailFailure'
);

export const loadSessionDetailsRequest = createAction(
  'bentoApi/loadSessionDetailsRequest'
);
export const loadSessionDetailsSuccess = createAction(
  'bentoApi/loadSessionDetailsSuccess'
);
export const loadSessionDetailsFailure = createAction(
  'bentoApi/loadSessionDetailsFailure'
);

export const setRequestFailureRate = createAction(
  'bentoApi/setRequestFailureRate'
);
export const setRequestFailureType = createAction(
  'bentoApi/setRequestFailureType'
);

export const trackSessionQuizStartRequest = createAction(
  'bentoApi/trackSessionQuizStartRequest'
);
export const trackSessionQuizStartSuccess = createAction(
  'bentoApi/trackSessionQuizStartSuccess'
);
export const trackSessionQuizStartFailure = createAction(
  'bentoApi/trackSessionQuizStartFailure'
);

export const validateFreeTrialRequest = createAction(
  'accounts/validateFreeTrialRequest'
);
export const validateFreeTrialSuccess = createAction(
  'accounts/validateFreeTrialSuccess'
);
export const validateFreeTrialFailure = createAction(
  'accounts/validateFreeTrialFailure'
);

export const setSessionToken = createAction('bentoApi/setSessionToken');

/**
 * Get or create a session. The API call is only sent if it is deemed to be
 * necessary:
 *
 * - If there is no existing session.
 * - If there is an existing session, but we're trying to update the `dmgCode`.
 *
 * If no `dmgCode` is provided, it is automatically read from the `cookies`
 * property on `context`.
 */
export function loadSession({
  dmgCode,
  headers: inputHeaders = {},
  searchParams = {},
} = {}) {
  return (dispatch, getState, context) => {
    const anonymousServerSession =
      context.isServer && context.req.context.anonymousServerSession;
    // `serverVisitorStatus` will only be considered if `anonymousServerSession`
    // is true. Otherwise, we expect to detect the real visitor status no matter
    // what.
    const serverVisitorStatus = anonymousServerSession
      ? context.req.context.serverVisitorStatus
      : null;
    const { session } = getState();
    const currentCustomerKey = session.customerKey || null;
    const currentDmgCode = session.dmGatewayCode || null;
    const shouldInitSession =
      session.sessionKey == null || (session.isServerOnly && !context.isServer);

    // Set DMG cookie to result of query param if
    // 1. it doesn't exist 2. query param is present 3. User is logged out
    if (context.req?.query?.dmg && !dmgCode && !session.isLoggedIn) {
      dmgCode = context.req.query.dmg;
      // this set of the cookie allows for backwards compatibility with the CF logic reliant on the DMG cookie
      context.cookies.set('DMG', context.req.query.dmg, { secure: true });
    }

    // leaving this here to allow for a query param override of the DMG
    const shouldSetDmgCode = dmgCode ? dmgCode !== currentDmgCode : false;
    if (dmgCode) {
      searchParams.dmgCode = dmgCode;
    }

    const currentExpiration = session.expiration || null;
    const oneMinute = 1000 * 60;
    const isSessionExpiring = currentExpiration
      ? // This checks if the session is expiring 60s from now.
        parseDate(currentExpiration).getTime() <= Date.now() + oneMinute
      : false;
    let headers;
    if (!anonymousServerSession) {
      if (!process.browser) {
        // When making API calls on the server, the default assumption is that
        // sessions created during SSR will only be used for the lifetime of the
        // request, and the browser will then create a new session for the user
        // when the app boots up. It optimizes for this case and causes all SSR
        // API calls to share a single session.
        // Since we instead support "end to end" sessions where we transfer SSR
        // sessions down to the browser, we need to set this `userRequest` flag
        // to tell the API not to use a shared session.
        searchParams.userRequest = true;
      }
      // These headers are user specific, so it only makes sense to send them
      // if this is a user's actual session, not an anonymous SSR session.
      headers = {
        'x-forwarded-user-agent': process.browser
          ? navigator.userAgent
          : context.req.get('User-Agent'),
        'x-forwarded-uri': process.browser
          ? window.location.pathname + window.location.search
          : context.req.context.url.pathname + context.req.context.url.search,
        'x-forwarded-referrer': process.browser
          ? document.referrer
          : context.req.get('Referer'), // This header is famously misspelled.
        ...inputHeaders,
      };
    }

    if (shouldInitSession || shouldSetDmgCode || isSessionExpiring) {
      return dispatch({
        bentoApi: {
          endpoint: 'sessions',
          searchParams,
          headers,
          sessionRequired: false,
          requestKey: 'session',
          schema: Session,
          actions: [
            loadSessionRequest,
            (payload, meta) => {
              const newCustomerKey = payload.customerKey || null;
              return loadSessionSuccess(payload, {
                ...meta,
                isServer: context.isServer,
                isServerOnly: anonymousServerSession,
                serverVisitorStatus,
                customerDidChange: newCustomerKey !== currentCustomerKey,
              });
            },
            loadSessionFailure,
          ],
        },
      });
    }
  };
}

export function updateSessionDetail(
  payload,
  options = { shouldInvalidateFeatures: false }
) {
  return (dispatch, getState, context) => {
    const anonymousServerSession =
      context.isServer && context.req.context.anonymousServerSession;

    if (anonymousServerSession) {
      return;
    }

    const { name, value } = payload;
    const { sessionId } = getState().session;
    const sessionDetail = getState().sessionDetails.keys[name] || {};
    const {
      value: prevSessionDetailsValue = null,
      sessionId: prevSessionDetailsSessionId,
    } = sessionDetail;

    if (
      prevSessionDetailsValue !== value ||
      prevSessionDetailsSessionId !== sessionId
    ) {
      return dispatch({
        bentoApi: {
          endpoint: 'sessions/detail',
          method: 'POST',
          json: payload,
          actions: [
            (noContent, meta) =>
              updateSessionDetailRequest(payload, {
                ...meta,
                ...options,
              }),
            (noContent, meta) =>
              updateSessionDetailSuccess(payload, {
                ...meta,
                ...options,
                sessionId,
              }),
            (noContent, meta) =>
              updateSessionDetailFailure(payload, {
                ...meta,
                ...options,
              }),
          ],
        },
      });
    }
  };
}

export function loadSessionDetails() {
  return (dispatch, getState, context) => {
    const anonymousServerSession =
      context.isServer && context.req.context.anonymousServerSession;

    if (anonymousServerSession) {
      return;
    }

    const { networkStatus, error } = getState().sessionDetails;
    const { sessionId } = getState().session;

    if (!networkStatus.isUpToDate && error == null) {
      return dispatch({
        bentoApi: {
          endpoint: 'sessions/details',
          requestKey: 'loadSessionDetails',
          actions: [
            loadSessionDetailsRequest,
            (payload, meta) =>
              loadSessionDetailsSuccess(payload, {
                ...meta,
                sessionId,
              }),
            loadSessionDetailsFailure,
          ],
        },
      });
    }
  };
}

export function trackSessionQuizStart() {
  return (dispatch) => {
    return dispatch({
      bentoApi: {
        endpoint: `sessions/quiz/started`,
        method: 'POST',
        actions: [
          trackSessionQuizStartRequest,
          trackSessionQuizStartSuccess,
          trackSessionQuizStartFailure,
        ],
      },
    });
  };
}

export function validateFreeTrial({ trialCode, confirmation }) {
  return {
    bentoApi: {
      method: 'POST',
      endpoint: 'sessions/trial',
      json: {
        trialCode: trialCode,
        confirmation: confirmation,
      },
      loginRequired: false,
      actions: [
        validateFreeTrialRequest,
        validateFreeTrialSuccess,
        validateFreeTrialFailure,
      ],
    },
  };
}

export const sessionReducer = createReducer(initialState.session, {
  [setVisitorStatus]: (state, action) => {
    state.visitorStatus = action.payload;
  },
  [loadSessionSuccess]: (state, action) => {
    const { isServer, isServerOnly, serverVisitorStatus } = action.meta;
    const sessionKey = action.meta.result;
    const session = action.meta.entities.Session[sessionKey];
    let { visitorStatus, isAdmin } = state;

    if (session.customerKey) {
      // The `customerKey` field will be populated with a user ID when logged in.
      visitorStatus = VisitorStatus.LOGGED_IN;
    } else if (session.sessionStatus === 'migrated') {
      // The `sessionStatus` field will be `migrated` when a session token was
      // sent in the request, indicating a previously existing session. However,
      // it's possible to make multiple session API calls over the course of a
      // single browsing session, and we don't want the subsequent calls to
      // change the visitor status from "first time" to "returning" - a user
      // should remain "first time" until they either log in or reload the page.
      // Thus, we should only take `migrated` to mean "returning" if there was
      // no previous session initialized (or if we need to downgrade their
      // status from "logged in" if we observe that `customerKey` is empty).
      if (
        !state.sessionKey ||
        (!isServer && state.isServerOnly) ||
        state.visitorStatus === VisitorStatus.LOGGED_IN
      ) {
        visitorStatus = VisitorStatus.RETURNING_VISITOR;
      }
    } else if (serverVisitorStatus != null) {
      // If this is non-null then it means we're on the server and creating
      // anonymous sessions, and something wants to override it. We put this
      // check after the two above because of those are returning `customerKey`
      // or `sessionStatus` in `anonymousServerSession` mode then something is
      // very wrong, and we should surface the real value. This should already
      // have been initialized in `initBentoApi` above, but we need to make sure
      // further `loadSession` calls don't overwrite it!
      visitorStatus = serverVisitorStatus;
    } else {
      visitorStatus = VisitorStatus.FIRST_TIME_VISITOR;
    }

    return { isAdmin, visitorStatus, isServerOnly, ...session };
  },
  // FIXME: Importing these from `@techstyle/react-accounts` would create a
  // circular dependency, since that depends on `~/techstyle-shared/redux-core`. For now
  // we use the strings directly here, but importing them would be better.
  'accounts/signUpSuccess': (state, action) => {
    if (action.payload.authType != null) {
      state.authType = action.payload.authType;
    } else {
      state.authType = AuthType.CREDENTIALS;
    }
    state.customerKey = action.payload.customer.customerKey;
    state.visitorStatus = VisitorStatus.LOGGED_IN;
  },
  'accounts/logInSuccess': (state, action) => {
    if (action.payload.authType != null) {
      state.authType = action.payload.authType;
    } else {
      state.authType = AuthType.CREDENTIALS;
    }
    state.customerKey = action.payload.customer.customerKey;
    state.visitorStatus = VisitorStatus.LOGGED_IN;
  },
  'admin/azureValidateSuccess': (state, action) => {
    state.isAdmin = true;
  },
  'admin/azureInvalidateSuccess': (state, action) => {
    state.isAdmin = false;
  },
  'admin/oktaVerifySuccess': (state, action) => {
    state.isAdmin = true;
  },
  'admin/oktaLogoutSuccess': (state, action) => {
    state.isAdmin = false;
  },
});

export const getSessionVisitorId = createSelector(
  [(state) => state.session.sessionVisitor],
  (sessionVisitor) => {
    // visitor_id is the same as the sessionId from the sessionVisitorString
    // but NOT the same as sessionId that we get back from /sessions
    if (sessionVisitor) {
      const sessionIdHex = sessionVisitor.split('-', 1)[0];
      if (sessionIdHex) {
        // parse to int from hexadecimal
        return parseInt(sessionIdHex, 16);
      }
    }
    return null;
  }
);

export const getSessionHelpers = createSelector(
  [(state) => state.session.authType, (state) => state.session.visitorStatus],
  (authType, visitorStatus) => {
    // Convenience properties for use instead of interpreting `visitorStatus`.
    const isLoggedIn = visitorStatus === VisitorStatus.LOGGED_IN;
    const isVisitor = !isLoggedIn;
    const isFirstTimeVisitor =
      visitorStatus === VisitorStatus.FIRST_TIME_VISITOR;
    const isReturningVisitor =
      visitorStatus === VisitorStatus.RETURNING_VISITOR;

    const isAutoLoggedIn = authType === AuthType.AUTOLOGIN;
    const isLoggedInWithCredentials = authType === AuthType.CREDENTIALS;
    const isGmsLoggedIn = authType === AuthType.GMS_LOGIN;

    return {
      isLoggedIn,
      isVisitor,
      isFirstTimeVisitor,
      isReturningVisitor,
      isAutoLoggedIn,
      isLoggedInWithCredentials,
      isGmsLoggedIn,
    };
  }
);

export const getSession = createSelector(
  [(state) => state.session, getSessionHelpers],
  (session, sessionHelpers) => {
    return {
      ...session,
      // For backwards compatibility, keep a property named `session` pointing
      // to only the session object.
      session,
      ...sessionHelpers,
    };
  }
);

export const sessionDetailsReducer = createReducer(
  initialState.sessionDetails,
  {
    [updateSessionDetailSuccess]: (state, action) => {
      const { sessionId } = action.meta;
      const { name, value } = action.payload;
      state.keys[name] = {
        name,
        sessionId,
        value,
      };

      if (state.networkStatus.isLoading) {
        // Let's say multiple places are reading and writing session details,
        // and this request/response order occurs:
        //
        // 1. load session details -->
        // 2. update session detail -->
        // 3. <-- session detail updated
        // 4. <-- receive session details
        //
        // Then the details in (4) are not necessarily guaranteed to have the
        // detail value written in step (2), since they were requested before
        // that value was written. Then, in order to not get the state messed up
        // and clobber our written value in the store, we need to know to merge
        // this with any values received in `loadSessionDetailsSuccess`.
        state.keys[name].writtenSinceFetched = true;
      }
    },
    [loadSessionDetailsRequest]: (state, action) => {
      const { fetchedDate } = action.meta;
      state.fetchedDate = fetchedDate;
      startLoading(state);
    },
    [loadSessionDetailsFailure]: (state, action) => {
      state.error = SessionDetailError.OTHER;
      stopLoading(state);
    },
    [loadSessionDetailsSuccess]: (state, action) => {
      // The new value must prefer key values with `writtenSinceFetched` marked,
      // since we can't be sure whether the payload will contain them or not
      // (they could have been written after the details were requested).
      const { sessionId } = action.meta;
      const writtenSinceFetchedKeys = Object.entries(state.keys).reduce(
        (keys, [name, data]) => {
          if (data.writtenSinceFetched) {
            keys[name] = data;
          }
          return keys;
        },
        {}
      );

      state.keys = action.payload.reduce((keys, sessionDetail) => {
        keys[sessionDetail.name] = {
          // We ignore the other properties like dates and ID for now.
          name: sessionDetail.name,
          sessionId,
          value: sessionDetail.value,
        };
        return keys;
      }, {});

      Object.assign(state.keys, writtenSinceFetchedKeys);

      state.networkStatus = upToDateStatus();
    },
  }
);

export const getSessionDetail = createCachedSelector(
  [
    (state, key) => state.sessionDetails.keys[key],
    (state) => state.sessionDetails.fetchedDate,
    (state) => state.sessionDetails.error,
    (state) => state.sessionDetails.networkStatus,
  ],
  (sessionDetail, fetchedDate, error, networkStatus) => ({
    fetchedDate,
    error,
    networkStatus,
    ...sessionDetail,
  })
)((state, key) => key);

export const storeGroupReducer = createReducer(initialState.storeGroup, {
  [setStoreDomain]: (state, action) => {
    state.storeDomain = action.payload;
  },
  [loadSessionSuccess]: (state, action) => {
    const sessionKey = action.meta.result;
    const session = action.meta.entities.Session[sessionKey];
    const storeGroup = action.meta.entities.StoreGroup[session.storeGroup];
    const { storeDomain } = state;
    return { ...storeGroup, storeDomain };
  },
});

export const bentoApiReducer = createReducer(initialState.bentoApi, {
  [setTrueClientIp]: (state, action) => {
    state.trueClientIp = action.payload;
  },
  [setRequestFailureRate]: (state, action) => {
    state.requestFailureRate = action.payload;
  },
  [setRequestFailureType]: (state, action) => {
    state.requestFailureType = action.payload;
  },
  [setOverrideReCaptchaEnv]: (state, action) => {
    state.overrideReCaptchaEnv = action.payload;
  },
  [setSessionToken]: (state, action) => {
    state.sessionToken = action.payload;
  },
  [setContentPreview]: (state, action) => {
    state.contentPreview = action.payload;
  },
  [setSecondarySite]: (state, action) => {
    state.secondarySite = action.payload;
  },
});

export default {
  id: 'bentoApi',
  reducerMap: {
    bentoApi: bentoApiReducer,
    session: sessionReducer,
    sessionDetails: sessionDetailsReducer,
    storeGroup: storeGroupReducer,
  },
  initialActions: [initBentoApi()],
};

import config from 'config';
import { actionChannel, putResolve, take } from 'redux-saga/effects';

import {
  logInSuccess,
  updateMembershipStatusSuccess,
} from '../../../techstyle-shared/react-accounts';
import {
  addMemberPromoSuccess,
  cancelMemberPromoSuccess,
} from '../../../techstyle-shared/react-promos';
import {
  createAction,
  createReducer,
  invalidate,
  outOfDateStatus,
  startLoading,
  stopLoading,
  upToDateStatus,
} from '../../../techstyle-shared/redux-core';

import isEchoCartLineId from './isEchoCartLineId';
import logger from './logger';
import { Cart, convertEmptyStringsToNull } from './schema';

const debug = logger.extend('cartModule');
const TWO_MINUTES = 2 * 60 * 1000;

export const initialState = {
  discounts: [],
  itemCount: 0,
  errorCount: 0,
  items: [],
  quantity: 0,
  networkStatus: outOfDateStatus(),
};

export const loadCartType = 'cart/loadCart';
export const loadCartRequest = createAction('cart/loadCartRequest');
export const loadCartSuccess = createAction(
  'cart/loadCartSuccess',
  (action) => {
    if (action.payload.cartId) {
      // Clean up cart fields; this will only be necessary for carts with IDs
      // since the empty cart object only has minimal fields.
      action.payload = convertEmptyStringsToNull(action.payload);
    }
  }
);
export const loadCartFailure = createAction('cart/loadCartFailure');

export const addItemToCartType = 'cart/addItemToCart';
export const addItemToCartRequest = createAction('cart/addItemToCartRequest');
export const addItemToCartSuccess = createAction('cart/addItemToCartSuccess');
export const addItemToCartFailure = createAction('cart/addItemToCartFailure');

export const addBundleToCartType = 'cart/addBundleToCartType';
export const addBundleToCartRequest = createAction(
  'cart/addBundleToCartRequest'
);
export const addBundleToCartSuccess = createAction(
  'cart/addBundleToCartSuccess'
);
export const addBundleToCartFailure = createAction(
  'cart/addBundleToCartFailure'
);

export const addSubcoMagazineToCartType = 'cart/addSubcoMagazineToCart';
export const addSubcoMagazineToCartRequest = createAction(
  'cart/addSubcoMagazineToCartRequest'
);
export const addSubcoMagazineToCartSuccess = createAction(
  'cart/addSubcoMagazineToCartSuccess'
);
export const addSubcoMagazineToCartFailure = createAction(
  'cart/addSubcoMagazineToCartFailure'
);

export const addLoyaltyItemToCartType = 'cart/addLoyaltyItemToCart';
export const addLoyaltyItemToCartRequest = createAction(
  'cart/addLoyaltyItemToCartRequest'
);
export const addLoyaltyItemToCartSuccess = createAction(
  'cart/addLoyaltyItemToCartSuccess'
);
export const addLoyaltyItemToCartFailure = createAction(
  'cart/addLoyaltyItemToCartFailure'
);

export const removeCartLineItemType = 'cart/removeCartLineItem';
export const removeCartLineItemRequest = createAction(
  'cart/removeCartLineItemRequest'
);
export const removeCartLineItemSuccess = createAction(
  'cart/removeCartLineItemSuccess'
);
export const removeCartLineItemFailure = createAction(
  'cart/removeCartLineItemFailure'
);

export const removeProductFromCartType = 'cart/removeProductFromCart';
export const removeProductFromCartRequest = createAction(
  'cart/removeProductFromCartRequest'
);
export const removeProductFromCartSuccess = createAction(
  'cart/removeProductFromCartSuccess'
);
export const removeProductFromCartFailure = createAction(
  'cart/removeProductFromCartFailure'
);

export const removeBundleFromCartType = 'cart/removeBundleFromCart';
export const removeBundleFromCartRequest = createAction(
  'cart/removeBundleFromCartRequest'
);
export const removeBundleFromCartSuccess = createAction(
  'cart/removeBundleFromCartSuccess'
);
export const removeBundleFromCartFailure = createAction(
  'cart/removeBundleFromCartFailure'
);

export const updateVIPCartStatusType = 'cart/updateVIPCartStatus';
export const updateVIPCartStatusRequest = createAction(
  'cart/updateVIPCartStatusRequest'
);
export const updateVIPCartStatusSuccess = createAction(
  'cart/updateVIPCartStatusSuccess'
);
export const updateVIPCartStatusFailure = createAction(
  'cart/updateVIPCartStatusFailure'
);

export const submitOrderType = 'cart/submitOrder';
export const submitOrderRequest = createAction('cart/submitOrderRequest');
export const submitOrderSuccess = createAction('cart/submitOrderSuccess');
export const submitOrderFailure = createAction('cart/submitOrderFailure');

export const completeOrderType = 'cart/completeOrder';
export const completeOrderRequest = createAction('cart/completeOrderRequest');
export const completeOrderSuccess = createAction('cart/completeOrderSuccess');
export const completeOrderFailure = createAction('cart/completeOrderFailure');

export const updateShippingOptionType = 'cart/updateShippingOption';
export const updateShippingOptionRequest = createAction(
  'cart/updateShippingOptionRequest'
);
export const updateShippingOptionSuccess = createAction(
  'cart/updateShippingOptionSuccess'
);
export const updateShippingOptionFailure = createAction(
  'cart/updateShippingOptionFailure'
);

export const invalidateCart = createAction('cart/invalidateCart');
/**
 * Load user's cart from API
 *
 * @param {Object} params
 * @param {boolean} params.ignoreLinkedCart  Whether to include data about the linked cart
 * @param {boolean} params.forceCreate       Automatically create cart if no cart is found for the user
 * @param {boolean} params.noCache           When true, disables returning cart from the cache
 * @param {boolean} params.recalcCart        When true, triggers full recalculated cart via `getCheckoutCart`
 * @param {Object} options
 * @param {boolean} options.loginRequired    When true, Require logged-in session to load cart
 */
export function loadCart(params = {}, options = {}) {
  return {
    type: loadCartType,
    channel: 'cart',
    payload: (dispatch, getState) => {
      const { cart } = getState();
      const { networkStatus, errorCount = 0, errorDate, isCheckoutCart } = cart;
      const { loginRequired = config.get('public.cart.loginRequired') } =
        options;
      const { recalcCart = config.get('public.cart.shouldRecalcCart') } =
        params;

      if (
        ((!networkStatus.isUpToDate && !networkStatus.isLoading) ||
          (recalcCart && !isCheckoutCart)) &&
        (errorCount <= 3 || (errorDate && new Date() - errorDate > TWO_MINUTES))
      ) {
        const headers = {};
        let searchParams = {};
        if (config.get('public.cart.cacheStrategy') === 'noCache') {
          searchParams.noCache = true;
          headers['cache-control'] = 'no-cache';
        }
        searchParams = { recalcCart, ...searchParams, ...params };

        return dispatch({
          bentoApi: {
            endpoint: 'cart',
            headers,
            requestKey: `loadCart-${JSON.stringify(searchParams)}`,
            searchParams,
            schema: Cart,
            loginRequired,
            actions: [
              loadCartRequest,
              (payload, meta) =>
                loadCartSuccess(payload, {
                  ...meta,
                  ...{
                    isCheckoutCart: recalcCart,
                  },
                }),
              loadCartFailure,
            ],
          },
        });
      } else if (errorCount > 3) {
        debug(
          '"loadCart" hit %s errors at %s',
          errorCount,
          new Date().toISOString()
        );
      }
    },
  };
}

export function addItemToCart(productId, options = {}) {
  const payload = {
    quantity: 1,
    provider: 'techstyle',
    productId,
    ...options,
  };

  return {
    type: addItemToCartType,
    channel: 'cart',
    payload: {
      bentoApi: {
        endpoint: 'cart/items',
        method: 'POST',
        json: payload,
        schema: Cart,
        actions: [
          addItemToCartRequest,
          addItemToCartSuccess,
          addItemToCartFailure,
        ],
      },
    },
  };
}

export function addBundleToCart(setId, componentProductIds, options = {}) {
  const payload = {
    quantity: 1,
    provider: 'techstyle',
    setId,
    componentProductIds,
    ...options,
  };

  return {
    type: addBundleToCartType,
    channel: 'cart',
    payload: {
      bentoApi: {
        endpoint: 'cart/sets',
        method: 'POST',
        json: payload,
        schema: Cart,
        actions: [
          addBundleToCartRequest,
          addBundleToCartSuccess,
          addBundleToCartFailure,
        ],
      },
    },
  };
}

export function addSubcoMagazineToCart(productId) {
  const payload = {
    productId,
    quantity: 1,
  };
  return {
    type: addSubcoMagazineToCartType,
    channel: 'cart',
    payload: {
      bentoApi: {
        endpoint: 'cart/subscription',
        method: 'POST',
        json: payload,
        actions: [
          addSubcoMagazineToCartRequest,
          addSubcoMagazineToCartSuccess,
          addSubcoMagazineToCartFailure,
        ],
      },
    },
  };
}

export function addLoyaltyItemToCart(productId, options = {}) {
  const payload = {
    productId,
    quantity: 1,
    ...options,
  };
  return {
    type: addLoyaltyItemToCartType,
    channel: 'cart',
    payload: {
      bentoApi: {
        endpoint: 'cart/loyalty/items',
        method: 'POST',
        json: payload,
        actions: [
          addLoyaltyItemToCartRequest,
          addLoyaltyItemToCartSuccess,
          addLoyaltyItemToCartFailure,
        ],
      },
    },
  };
}

/**
 * Updated to support ECHO cartLine removal by passing in the `cartLineId`.
 * BENTO `lineId` are numeric, where ECHO is a hash
 */
export function removeCartLineItem(lineId) {
  const endpoint = isEchoCartLineId(lineId)
    ? `cart/cartline/${lineId}`
    : `cart/items/${lineId}`;
  return {
    type: removeCartLineItemType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'DELETE',
        endpoint,
        schema: Cart,
        actions: [
          removeCartLineItemRequest,
          removeCartLineItemSuccess,
          removeCartLineItemFailure,
        ],
      },
    },
  };
}

export function removeProductFromCart(productId) {
  return {
    type: removeProductFromCartType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'DELETE',
        endpoint: `cart/products/${productId}`,
        schema: Cart,
        actions: [
          removeProductFromCartRequest,
          removeProductFromCartSuccess,
          removeProductFromCartFailure,
        ],
      },
    },
  };
}

export function removeBundleFromCart(groupKey) {
  return {
    type: removeBundleFromCartType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'DELETE',
        endpoint: `cart/sets/${groupKey}`,
        schema: Cart,
        actions: [
          removeBundleFromCartRequest,
          removeBundleFromCartSuccess,
          removeBundleFromCartFailure,
        ],
      },
    },
  };
}

export function updateVIPCartStatus(options = { isVIPCart: true }) {
  return {
    type: updateVIPCartStatusType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'POST',
        endpoint: `cart/vip`,
        json: options,
        schema: Cart,
        actions: [
          updateVIPCartStatusRequest,
          updateVIPCartStatusSuccess,
          updateVIPCartStatusFailure,
        ],
      },
    },
  };
}

/**
 * Submit Cart Order. If authorization is needed, you will need to follow up
 * with a `completeOrder`. This is determined by one of these conditions from
 * the response:
 *  - `payload.statusCode === 202`
 *  - `payload.adyenResponseType` is truthy
 *
 * TODO: determine if more needs to happen here for converting orders (lead
 * becomes VIP). See SavageX implementation.
 */
export function submitOrder(params = {}, options = {}) {
  const isAdyen = options.isAdyen || !!params.encryptedCardNumber; // only Adyen orders have this property
  const endpoint = isAdyen ? 'cart/purchase' : 'cart/order/submit';

  return {
    type: submitOrderType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'POST',
        endpoint,
        json: params,
        schema: Cart,
        actions: [
          submitOrderRequest,
          (payload, meta) =>
            submitOrderSuccess(payload, {
              ...meta,
              ...{
                isAdyen,
              },
            }),
          submitOrderFailure,
        ],
      },
    },
  };
}

/**
 * Complete cart order (if authorization was determined to be necessary following
 * a `submitOrder`).
 *
 * TODO: determine if more needs to happen here for converting orders (lead
 * becomes VIP). See SavageX implementation.
 */
export function completeOrder(params = {}, options = { isAdyen: false }) {
  const { isAdyen = false } = options;
  const endpoint = isAdyen ? 'cart/purchase/complete' : 'cart/order/complete';

  return {
    type: completeOrderType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'POST',
        endpoint,
        json: params,
        schema: Cart,
        actions: [
          completeOrderRequest,
          (payload, meta) =>
            completeOrderSuccess(payload, {
              ...meta,
              ...{
                isAdyen,
              },
            }),
          completeOrderFailure,
        ],
      },
    },
  };
}

// Sets the shipping option on the cart and returns a new cart
export function updateShippingOption(shippingOptionId) {
  return {
    type: updateShippingOptionType,
    channel: 'cart',
    payload: {
      bentoApi: {
        method: 'POST',
        endpoint: 'cart/shipping',
        json: { shippingOptionId },
        schema: Cart,
        actions: [
          updateShippingOptionRequest,
          updateShippingOptionSuccess,
          updateShippingOptionFailure,
        ],
      },
    },
  };
}

const updateCartSuccess = (state, action) => {
  const { payload, meta } = action;
  return {
    ...payload,
    itemCount: payload.itemCount || 0,
    quantity: payload.quantity || 0,
    isCheckoutCart: meta.isCheckoutCart,
    networkStatus: upToDateStatus(),
  };
};

export const cartReducer = createReducer(initialState, {
  [invalidateCart]: invalidate,
  [logInSuccess]: invalidate,
  [updateMembershipStatusSuccess]: invalidate,
  [loadCartRequest]: startLoading,
  [loadCartFailure]: (state, action) => {
    const { error } = action.payload;
    state.error = error?.errorCode;
    if (
      state.errorDate &&
      state.errorDate?.getMinutes() <= new Date().getMinutes() - 10
    ) {
      state.errorCount = 0;
    }

    state.errorCount += 1;
    state.errorDate = new Date();
    stopLoading(state);
  },
  [loadCartSuccess]: (state, action) => {
    state.errorCount = 0;
    state.errorDate = null;
    updateCartSuccess(state, action);
  },
  [loadCartSuccess]: updateCartSuccess,
  [addItemToCartRequest]: startLoading,
  [addItemToCartFailure]: stopLoading,
  [addItemToCartSuccess]: updateCartSuccess,
  [addBundleToCartRequest]: startLoading,
  [addBundleToCartFailure]: stopLoading,
  [addBundleToCartSuccess]: updateCartSuccess,
  [addSubcoMagazineToCartSuccess]: updateCartSuccess,
  [addLoyaltyItemToCartSuccess]: updateCartSuccess,
  [removeCartLineItemRequest]: startLoading,
  [removeCartLineItemFailure]: stopLoading,
  [removeCartLineItemSuccess]: updateCartSuccess,
  [removeProductFromCartRequest]: startLoading,
  [removeProductFromCartFailure]: stopLoading,
  [removeProductFromCartSuccess]: updateCartSuccess,
  [removeBundleFromCartRequest]: startLoading,
  [removeBundleFromCartFailure]: stopLoading,
  [removeBundleFromCartSuccess]: updateCartSuccess,
  [updateVIPCartStatusRequest]: startLoading,
  [updateVIPCartStatusFailure]: stopLoading,
  [updateVIPCartStatusSuccess]: updateCartSuccess,
  [updateShippingOptionRequest]: startLoading,
  [updateShippingOptionSuccess]: updateCartSuccess,
  [updateShippingOptionFailure]: stopLoading,
  [submitOrderSuccess]: (state, action) => {
    const { payload, meta } = action;
    const requiresAuthorization = payload?.statusCode === 202 || meta?.isAdyen;

    // If we need to authorize, do nothing. Consumer should fire a `completeOrder`.
    if (requiresAuthorization) {
      return state;
    }

    // If we don't need to authorize, invalidate cart
    return invalidate(state);
  },
  [completeOrderSuccess]: invalidate,
  [addMemberPromoSuccess]: invalidate,
  // The add promo response returns nothing, but the cancel promo response
  // contains the user's new cart. Let's anticipate that DELETE call returning
  // no content at some point.
  [cancelMemberPromoSuccess]: (state, action) => {
    const { payload } = action;
    if (payload && payload.cartId) {
      return updateCartSuccess(state, action);
    } else {
      return invalidate(state);
    }
  },
});

/**
 * Force cart actions to be run serially (one at a time) even if dispatched in
 * parallel, because otherwise API responses can be arrive (both to the API
 * server and back to us) in nondeterministic order, potentially overwriting
 * a less recent response with stale data.
 */
export function* processCartChannel() {
  const channel = yield actionChannel((action) => action.channel === 'cart');
  while (true) {
    const action = yield take(channel);
    try {
      yield putResolve(action.payload);
    } catch (err) {
      // There may be situations where we want to bail out on any buffered
      // actions after this one, but for now just continue processing the
      // queue...
    }
  }
}

export default {
  id: 'cart',
  reducerMap: {
    cart: cartReducer,
  },
  initialActions: [],
  sagas: [processCartChannel],
};

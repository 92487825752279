import {
  loadSession,
  VisitorStatus,
} from '../../../techstyle-shared/redux-core';

import { gmsLogin } from './accountsModule';
import logger from './logger';

const debug = logger.extend('gmsLoginExtension');

export default function gmsLoginExtension() {
  return {
    id: 'gmsLogin',
    redux: {
      modules: [],
    },
    server: process.browser
      ? undefined
      : {
          init() {
            require('./config');
          },
          configure(server) {
            const {
              default: gmsLoginMiddleware,
            } = require('./gmsLoginMiddleware');

            // Abundance of caution: ensure backwards compatibility if someone
            // upgrades `~/techstyle-shared/redux-core` without upgrading
            // `@techstyle/next-server`. TODO: This can be removed when everyone
            // is up to date.
            const addMiddleware = (name, middleware) => {
              if (server.useTracked) {
                server.useTracked(name, middleware);
              } else {
                server.use(middleware);
              }
            };

            addMiddleware('gmsLoginMiddleware', gmsLoginMiddleware());
          },
        },
    app: {
      getInitialProps: {
        enhance(getInitialProps) {
          return async ({ Component, ctx }) => {
            const { admin_id: adminId, admin_token: adminAuthKey } = ctx.query;
            if (adminId && adminAuthKey) {
              await ctx.store.dispatch(loadSession());
              const { visitorStatus } = ctx.store.getState().session;
              if (visitorStatus === VisitorStatus.LOGGED_IN) {
                debug(
                  'Session is already logged in, GMS login params will be ignored.'
                );
              } else {
                const credentials = { adminId, adminAuthKey };
                try {
                  await ctx.store.dispatch(gmsLogin(credentials));
                } catch (err) {
                  debug(
                    '%c!%c Error using GMS login params: %s',
                    'color: red',
                    '',
                    err
                  );
                  debug(
                    'Render will continue as if the params were not supplied.'
                  );
                }
              }
            }
            return getInitialProps({ Component, ctx });
          };
        },
      },
    },
  };
}

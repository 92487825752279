import { createAction } from '../../../techstyle-shared/redux-core';

import logger from './logger';

export const routeChangeStart = createAction('routes/routeChangeStart');
export const routeChangeComplete = createAction('routes/routeChangeComplete');
export const routeChangeError = createAction('routes/routeChangeError');
export const newRouteLoaded = createAction('routes/newRouteLoaded');

const debug = logger.extend('routesModule');

export function initRoutes({ routes }) {
  return (dispatch, getState, context) => {
    context.routeResolvers = context.routeResolvers || [];
    if (routes) {
      context.routeResolvers.push(
        createNextRoutesResolver({ getState, routes })
      );
    }
  };
}

export function createNextRoutesResolver({ routes }) {
  return (href, asPath) => {
    // FIXME: Should custom resolvers (or just some) only kick in when the
    // `href` is given as a string? People may just be using an `href` object to
    // build the URL for them (with `query` etc.) not just because they already
    // know the internal Next.js page and such...
    if (typeof href === 'string') {
      let result = {};

      try {
        result = routes.match(href);
      } catch (err) {
        debug('Error parsing href: %s', href);
      }

      if (result.route) {
        return {
          href: {
            pathname: result.route.page,
            hash: result.parsedUrl.hash || undefined,
            query: result.query,
          },
          asPath: href,
        };
      }
    }
  };
}

export default function createRoutesModule(options) {
  return {
    id: 'routes',
    reducerMap: {},
    initialActions: [initRoutes(options)],
  };
}

import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import scrollToRef from '../scrollToRef';

const Context = React.createContext();

export function useErrorContext() {
  return useContext(Context);
}
export default function ErrorContext({ children }) {
  const [registeredErrors, setRegisteredErrors] = useState([]);
  const [isScrolling, setIsScrolling] = useState(false);
  const firstError = registeredErrors[0];

  const initRegisteredErrors = useCallback(() => {
    setRegisteredErrors([]);
  }, []);

  const addError = useCallback(
    (errorRef, identifier) => {
      const currentRef = registeredErrors.find(
        (error) => error.identifier === identifier
      );
      if (!currentRef) {
        setRegisteredErrors((registeredErrors) => {
          const errors = [...registeredErrors, { errorRef, identifier }];
          // using compareDocumentPosition to sort based on error's DOM position
          // to always get top error first
          return errors.sort((a, b) =>
            a.errorRef.current?.compareDocumentPosition?.(
              b.errorRef.current
            ) === Node.DOCUMENT_POSITION_FOLLOWING
              ? -1
              : 1
          );
        });
      }
    },
    [registeredErrors]
  );

  const removeError = useCallback((identifier) => {
    setRegisteredErrors((registeredErrors) => {
      const refIndex = registeredErrors.findIndex(
        (error) => error.identifier === identifier
      );
      const errors = [...registeredErrors];
      if (refIndex === -1) {
        return registeredErrors;
      } else {
        errors.splice(refIndex, 1);
        return errors;
      }
    });
  }, []);

  const scrollToNextError = useCallback(() => {
    if (firstError && firstError.errorRef.current) {
      scrollToRef(firstError.errorRef, { scrollMode: 'always' });
    }
  }, [firstError]);

  useEffect(() => {
    if (isScrolling) {
      scrollToNextError();
      if (!firstError) {
        setIsScrolling(false);
      }
    }
  }, [firstError, scrollToNextError, isScrolling]);

  const value = useMemo(() => {
    return {
      addError,
      isScrolling,
      firstError,
      initRegisteredErrors,
      removeError,
      scrollToNextError,
      setIsScrolling,
    };
  }, [
    addError,
    isScrolling,
    firstError,
    initRegisteredErrors,
    removeError,
    scrollToNextError,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ErrorContext.propTypes = {
  children: PropTypes.node,
};

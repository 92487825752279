import React from 'react';

import useSectionLevel from '../useSectionLevel';
import withThemeProps from '../withThemeProps';

type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /**
   * The element type to render, defaults to an `h1`, `h2`, `h3` etc. depending
   * on the heading level.
   */
  as?: HeadingLevels;
  /**
   * Override the heading level with a custom value. If given as a function, it
   * will be called with the current level so you can make a decision based on
   * it.
   */
  level?: number | ((value: number) => number);
}

function Heading({ as: ElementType, level, ...rest }: HeadingProps) {
  const currentLevel = useSectionLevel();
  // Allow dynamic level values based on the current level.
  // For example, you could go "up" one level via:
  // `level={currentLevel => currentLevel - 1}`
  if (typeof level === 'function') {
    level = level(currentLevel);
  }
  // Ensure the output level can't be less than 1.
  const headingLevel = Math.max(1, level == null ? currentLevel : level);
  if (ElementType == null) {
    // If `headingLevel` is greater than 6, render an `h6` still because that's
    // the maximum provided by HTML. The real level will still be indicated by
    // `aria-level`.
    ElementType = `h${Math.min(6, headingLevel)}` as HeadingLevels;
  }
  return <ElementType role="heading" aria-level={headingLevel} {...rest} />;
}

Heading.displayName = 'Heading';

export default withThemeProps<HeadingProps>(Heading, {
  section: 'heading',
  defaultVariant: 'primary',
});

import React from 'react';

import styled from 'styled-components';

import BaseHeading from '../Heading';
import { desktop } from '../styles';
import { IconProps } from '../types';

const Link = styled.a`
  font-size: 16px;
  font-weight: 700;
`;

const Heading = styled(BaseHeading).attrs({
  level: 3,
})`
  font-size: 20px;
  text-align: center;
`;

const HelpText = styled.p`
  font-size: 16px;
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
  gap: 24px;

  ${desktop`
    flex-direction: row;
  `}
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;

  ${desktop`
    flex-direction: column;
  `}
`;

const TruckIcon = ({
  color = 'currentColor',
  size = '42',
  ...rest
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="4 0 50 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M41.77 26.323c-1.199 0-2.338.505-3.198 1.419a4.99 4.99 0 0 0-1.34 3.42c0 1.29.47 2.494 1.34 3.419.87.914 2 1.419 3.199 1.419 2.459 0 4.458-2.172 4.458-4.839 0-2.666-2-4.838-4.458-4.838Zm0 7.526c-1.379 0-2.538-1.225-2.538-2.688 0-1.462 1.16-2.688 2.539-2.688 1.36 0 2.459 1.204 2.459 2.688 0 1.484-1.1 2.688-2.46 2.688Zm1.16-23.645a.934.934 0 0 0-.67-.28h-5.127c-.55 0-1 .485-1 1.076v8.817c0 .592.45 1.075 1 1.075h8.136c.55 0 1-.483 1-1.075v-5.903c0-.301-.12-.591-.33-.796l-3.009-2.914Zm1.34 8.538h-6.138v-6.678h3.739l2.399 2.323v4.355Zm-26.91 7.58c-1.2 0-2.339.506-3.198 1.42a4.99 4.99 0 0 0-1.34 3.42c0 1.29.47 2.494 1.34 3.419.87.914 1.999 1.419 3.198 1.419 2.46 0 4.459-2.172 4.459-4.839 0-2.666-2-4.838-4.459-4.838Zm0 7.527c-1.38 0-2.539-1.225-2.539-2.688 0-1.462 1.16-2.688 2.54-2.688 1.359 0 2.458 1.204 2.458 2.688 0 1.484-1.1 2.688-2.459 2.688Zm-6.667-6.204h-2.01v-6.267c0-.591-.45-1.075-.999-1.075-.55 0-1 .484-1 1.075v7.342c0 .592.45 1.076 1 1.076h3.009c.55 0 1-.484 1-1.076 0-.591-.45-1.075-1-1.075Zm5.068-9.267c0-.591-.45-1.075-1-1.075H1c-.55 0-1 .484-1 1.075s.45 1.075 1 1.075H14.76c.55 0 1-.473 1-1.075ZM3.029 14.496l13.762.076c.55 0 1-.473 1.01-1.065.01-.602-.44-1.086-.99-1.086l-13.762-.075h-.01c-.55 0-1 .473-1 1.064-.01.602.44 1.086.99 1.086Zm47.328-2.926L43.2 5.194a.943.943 0 0 0-.64-.248h-8.416v-3.87c0-.592-.45-1.076-1-1.076H7.684c-.55 0-1 .484-1 1.075v7.871c0 .592.45 1.075 1 1.075s1-.483 1-1.075V2.151h23.47v25.494h-8.196c-.55 0-1 .484-1 1.075 0 .592.45 1.076 1 1.076h12.205c.55 0 1-.484 1-1.076 0-.591-.45-1.075-1-1.075h-2.01V7.097h8.058l6.517 5.806-.07 14.72h-1.04c-.55 0-1 .485-1 1.076 0 .591.45 1.075 1 1.075h2.03c.55 0 1-.473 1-1.064l.08-16.301a1.172 1.172 0 0 0-.37-.84Z"
        fill={color}
      />
    </svg>
  );
};

const PrinterIcon = ({
  color = 'currentColor',
  size = '42',
  ...rest
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M41.177 10.462h-4.842v-8.12c0-.741-.585-1.342-1.307-1.342h-24.83c-.722 0-1.307.6-1.307 1.342v8.12H4.05c-1.682 0-3.05 1.405-3.05 3.132v17.223c0 1.727 1.368 3.133 3.05 3.133H8.89v8.388c0 .741.585 1.342 1.307 1.342h24.83c.722 0 1.307-.6 1.307-1.342V33.95h4.841c1.682 0 3.05-1.406 3.05-3.133V13.594c0-1.727-1.368-3.132-3.05-3.132ZM11.505 3.685h22.216v6.777H11.505V3.685Zm22.216 37.31H11.505V25.95h22.216v15.046Zm7.891-10.178c0 .243-.2.448-.435.448h-4.842v-6.659c0-.741-.585-1.342-1.307-1.342h-24.83c-.722 0-1.307.6-1.307 1.342v6.659H4.05a.448.448 0 0 1-.436-.448V13.594c0-.242.2-.447.436-.447h37.126c.237 0 .436.205.436.447v17.223Z"
        fill={color}
        stroke="#fff"
        strokeWidth=".6"
      />
    </svg>
  );
};

const OpenPackageIcon = ({
  color = 'currentColor',
  size = '42',
  ...rest
}: IconProps) => {
  return (
    <svg
      width={size}
      height={50}
      viewBox="8 4 42 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M29.754 53a.497.497 0 0 1-.373-.176.648.648 0 0 1-.155-.427V34.603c0-.16.056-.314.155-.427a.497.497 0 0 1 .373-.176c.14 0 .274.063.373.176a.648.648 0 0 1 .155.427v16.97l12.72-5.976v-10.28c0-.16.056-.313.155-.426a.498.498 0 0 1 .373-.176c.14 0 .274.063.373.176a.649.649 0 0 1 .155.426v10.72a.66.66 0 0 1-.102.356.535.535 0 0 1-.268.218L29.911 52.97a.475.475 0 0 1-.157.03Z"
        fill={color}
        stroke={color}
        strokeWidth=".4"
      />
      <path
        d="M34.451 39.429a.527.527 0 0 1-.397-.19l-4.664-5.368a.672.672 0 0 1-.14-.27.741.741 0 0 1-.013-.316.693.693 0 0 1 .118-.284.574.574 0 0 1 .22-.187l14.66-6.909a.493.493 0 0 1 .337-.032.55.55 0 0 1 .292.196l4.442 5.628c.06.077.105.17.127.27a.74.74 0 0 1 .004.31.688.688 0 0 1-.12.275.573.573 0 0 1-.218.18l-14.44 6.65a.499.499 0 0 1-.208.047Zm-3.67-5.79 3.806 4.382 13.343-6.145-3.633-4.6L30.78 33.64Z"
        fill={color}
        stroke={color}
        strokeWidth=".4"
      />
      <path
        d="M24.999 39.429a.56.56 0 0 1-.223-.047l-15.4-6.651a.602.602 0 0 1-.232-.18.697.697 0 0 1-.124-.584.664.664 0 0 1 .135-.271l4.739-5.627a.591.591 0 0 1 .31-.196.558.558 0 0 1 .36.032L30.2 32.811c.092.04.173.105.236.187s.106.18.125.284a.696.696 0 0 1-.013.315.659.659 0 0 1-.15.27l-4.975 5.369a.6.6 0 0 1-.194.142.562.562 0 0 1-.231.05Zm-14.376-7.554 14.232 6.145 4.059-4.38-14.416-6.364-3.875 4.6Z"
        fill={color}
        stroke={color}
        strokeWidth=".4"
      />
      <path
        d="M29.998 53a.559.559 0 0 1-.172-.028l-15.03-6.359a.577.577 0 0 1-.29-.218.621.621 0 0 1-.112-.356V35.317c0-.16.06-.313.168-.426a.564.564 0 0 1 .408-.176c.152 0 .299.063.407.176a.617.617 0 0 1 .168.426v10.28l13.877 5.977V34.603c0-.16.061-.314.169-.427a.563.563 0 0 1 .407-.176c.153 0 .3.063.407.176a.617.617 0 0 1 .17.427v17.794c0 .16-.061.314-.17.427a.563.563 0 0 1-.407.176ZM44.446 27.214a.545.545 0 0 1-.212-.043l-14.659-6.216a.563.563 0 0 1-.22-.168.592.592 0 0 1-.105-.54.587.587 0 0 1 .14-.243l4.664-4.834a.544.544 0 0 1 .606-.129l14.44 5.99c.083.035.158.09.217.161a.59.59 0 0 1 .116.526.589.589 0 0 1-.127.244l-4.442 5.067a.563.563 0 0 1-.418.185Zm-13.663-7.006 13.515 5.73 3.631-4.14-13.342-5.533-3.804 3.943Z"
        fill={color}
        stroke={color}
        strokeWidth=".4"
      />
      <path
        d="M14.337 27.214a.615.615 0 0 1-.444-.19l-4.738-5.062a.577.577 0 0 1-.14-.522.577.577 0 0 1 .129-.248.6.6 0 0 1 .232-.162l15.402-5.988a.617.617 0 0 1 .646.129l4.975 4.833a.58.58 0 0 1 .163.527.575.575 0 0 1-.125.256.598.598 0 0 1-.236.168l-15.635 6.216a.617.617 0 0 1-.229.043Zm-3.716-5.416 3.875 4.142 14.416-5.73-4.057-3.947-14.234 5.535Z"
        fill={color}
        stroke={color}
        strokeWidth=".4"
      />
      <path
        d="M30.7 6a.7.7 0 1 0-1.4 0h1.4Zm-1.195 10.495a.7.7 0 0 0 .99 0l4.455-4.455a.7.7 0 1 0-.99-.99L30 15.01l-3.96-3.96a.7.7 0 1 0-.99.99l4.455 4.455ZM29.3 6v10h1.4V6h-1.4Z"
        fill={color}
      />
    </svg>
  );
};

const ClosedPackageIcon = ({
  color = 'currentColor',
  size = '42',
  ...rest
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="4 0 56 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="m44.483 17.945-17.556-9.58a2.967 2.967 0 0 0-2.854 0l-17.556 9.58A2.894 2.894 0 0 0 5 20.48v19.038c0 1.054.581 2.026 1.517 2.536l17.556 9.58a2.973 2.973 0 0 0 2.854 0l17.556-9.58A2.894 2.894 0 0 0 46 39.52V20.481a2.894 2.894 0 0 0-1.517-2.536Zm-19.24-7.502a.536.536 0 0 1 .515 0l7.01 3.825-16.907 9.226-7.175-4.017 16.556-9.034Zm-.949 38.596L7.686 39.977a.523.523 0 0 1-.274-.458V21.495l7.835 4.387.006.003 9.041 5.063v18.091Zm-5.977-24.17 16.94-9.243L42.54 19.6l-17.033 9.294-7.191-4.026Zm25.271 14.65c0 .19-.105.366-.274.459L26.706 49.04V30.956l16.882-9.211v17.774Z"
        fill={color}
        stroke="#fff"
        strokeWidth=".4"
      />
      <path
        d="m37.847 29.501-4 2.381a.3.3 0 0 0-.147.258v9.101a.3.3 0 0 0 .453.258l4-2.381a.3.3 0 0 0 .147-.258V29.76a.3.3 0 0 0-.453-.258Z"
        stroke={color}
        strokeWidth="1.4"
      />
      <path
        d="M60 35.7a.7.7 0 1 0 0-1.4v1.4Zm-11.495-1.195a.7.7 0 0 0 0 .99l4.455 4.455a.7.7 0 1 0 .99-.99L49.99 35l3.96-3.96a.7.7 0 1 0-.99-.99l-4.455 4.455ZM60 34.3H49v1.4h11v-1.4Z"
        fill={color}
      />
    </svg>
  );
};

export {
  Link,
  Heading,
  HelpText,
  StepsWrapper,
  StepContainer,
  TruckIcon,
  PrinterIcon,
  OpenPackageIcon,
  ClosedPackageIcon,
};

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.fill || props.color || '#eb4498'};
  vertical-align: top;
`;

const ShoeDazzleLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      // Let `aria-label` take control if present. Consumers are also free to
      // override with their own `aria-labelledby`.
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      {...props}
      viewBox="0 0 576 109"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <title id={titleId}>ShoeDazzle</title>
      <path
        data-starburst=""
        d="M544.39 36.7l31.46-11.31-32.12 9.28 18.8-27.64-20.52 26.39L540.96 0l-1.09 33.41L519.4 6.99l18.75 27.68-32.1-9.35 31.43 11.37-31.46 11.32 32.12-9.29-18.8 27.65 20.52-26.39 1.04 33.41 1.09-33.41 20.49 26.43-18.76-27.68 32.11 9.34z"
      />
      <g data-word="shoe">
        <path d="M47.25 101.97c-4.28 4.39-11.33 6.93-19.64 6.93-15.02 0-25.65-6.59-27.61-18.72h4.27c2.08 9.59 10.64 14.68 23.23 14.68 7.16 0 13.18-2.31 16.63-5.9a10.56 10.56 0 0 0 2.9-8.2c-.24-7.28-6.13-10.98-20.68-13.06-10.29-1.51-22.99-3.23-23.22-15.02 0-8.08 7.63-16.17 22.53-16.17 13.16 0 21.83 5.9 24.37 16.64h-4.4c-2.42-7.98-9.24-12.6-19.87-12.6-10.75 0-18.37 5.2-18.26 12.13.12 6.82 6.36 9.01 19.53 10.86 12.36 1.84 23.79 5.09 24.37 17.1a15.64 15.64 0 0 1-4.15 11.33z" />
        <path d="M104.97 107.52h-4.28V71.6c0-13.07-7.04-20.92-20.1-20.92-12.01 0-21.37 8.89-21.37 20.92v35.92h-4.28V21.56h4.28v36.51c4.27-7.05 12.01-11.56 21.49-11.56 15.25 0 24.26 9.59 24.26 25.08v35.93z" />
        <path d="M166.74 77.94c0 17.1-13.4 30.96-29.81 30.96-16.29 0-29.69-13.87-29.69-30.96 0-17.33 13.4-31.42 29.69-31.42 16.41 0 29.81 14.09 29.81 31.42zm-4.38 0c0-15.13-11.32-27.38-25.42-27.38-13.98 0-25.42 12.25-25.42 27.38 0 14.79 11.44 26.92 25.42 26.92 14.1 0 25.42-12.13 25.42-26.92z" />
        <path d="M171.48 77.71c.12 15.49 11.67 26.92 26.11 26.92 13.98 0 20.34-7.73 23.23-15.59h4.5c-4.16 12.82-13.75 19.87-27.73 19.87-16.98 0-30.38-13.63-30.38-30.96s13.29-31.42 29.69-31.42c17.8 0 29.93 16.17 29.69 31.19h-55.11zm50.49-4.27c-2.08-12.83-12.59-22.54-25.07-22.54-12.59 0-22.99 9.71-24.96 22.54h50.03z" />
      </g>
      <g data-word="dazzle">
        <path d="M286.43 77.94c0 17.1-13.28 30.96-29.68 30.96-16.41 0-29.7-13.87-29.7-30.96 0-17.33 13.29-31.42 29.7-31.42 11.09 0 20.56 6.47 25.42 15.94v-40.9h4.27v56.38zM256.75 50.9c-13.99 0-25.42 12.13-25.42 27.04 0 14.67 11.43 26.69 25.42 26.69 13.97 0 25.42-12.02 25.42-26.69-.01-14.91-11.45-27.04-25.42-27.04z" />
        <path d="M336.36 107.52c-1.04-2.19-1.62-4.96-1.62-8.66V97.7c-4.73 6.7-13.87 10.86-25.65 10.86-10.4 0-21.6-5.2-21.6-16.63 0-13.52 12.59-16.64 25.3-17.79 13.17-1.28 21.96-2.66 21.96-9.94 0-10.98-10.4-13.51-19.18-13.51-10.87 0-18.03 4.5-20.57 12.59h-4.39c2.66-10.62 11.56-16.75 24.96-16.75 14.9 0 23.45 6.47 23.45 17.67v33.62c0 3 .81 7.4 1.73 9.7h-4.39zm-1.62-35.24c-4.62 4.28-13.3 5.09-21.49 5.77-15.25 1.5-21.14 5.55-21.14 13.76 0 9.82 11.08 12.47 16.98 12.47 12.83 0 25.65-5.77 25.65-18.37V72.28z" />
        <path d="M390.83 107.52h-48.64v-4.27l41.82-50.95h-40.55v-4.27h46.09v4.27l-41.82 50.95h43.09v4.27z" />
        <path d="M440.29 107.52h-48.65v-4.27l41.83-50.95h-40.55v-4.27h46.1v4.27l-41.83 50.95h43.1v4.27z" />
        <path d="M464.32 103.59v3.92c-12.83 1.16-20.22-7.27-20.22-20.56V21.56h4.28v65.39c-.01 10.17 4.62 17.11 15.94 16.64z" />
        <path d="M461.07 77.71c.12 15.49 11.66 26.92 26.1 26.92 13.99 0 20.34-7.73 23.23-15.59h4.5c-4.16 12.82-13.75 19.87-27.73 19.87-16.98 0-30.38-13.63-30.38-30.96s13.29-31.42 29.69-31.42c17.8 0 29.93 16.17 29.69 31.19h-55.1zm50.49-4.27c-2.08-12.83-12.59-22.54-25.07-22.54-12.59 0-22.99 9.71-24.96 22.54h50.03z" />
        <path
          data-trademark=""
          d="M516.38 107.93a4.94 4.94 0 1 1 0-9.89 4.95 4.95 0 1 1 0 9.89zm0-8.98a4 4 0 0 0-3.96 4.03 4 4 0 0 0 3.96 4.04 4 4 0 0 0 3.97-4.04 4 4 0 0 0-3.97-4.03zm2.51 6.64h-1.17l-.99-1.9h-1.33v1.88h-1.02v-5.44h2.61c1.15 0 1.88.69 1.88 1.78 0 .79-.45 1.41-1.13 1.66l1.15 2.02zm-1.89-2.85c.43 0 .85-.21.85-.83 0-.6-.33-.83-.85-.83h-1.6v1.66h1.6z"
        />
      </g>
    </Svg>
  );
});

ShoeDazzleLogo.displayName = 'ShoeDazzleLogo';

ShoeDazzleLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default ShoeDazzleLogo;

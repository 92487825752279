import { offsetLimitPagination } from '@apollo/client/utilities';

export default {
  typePolicies: {
    Query: {
      fields: {
        paginatedReviews: offsetLimitPagination([
          'groupCode',
          'rating',
          'sizes',
          'sortBy',
        ]),
      },
    },
    IndividualProduct: {
      keyFields: ['permalink', 'masterProductId'],
    },
    BundleProduct: {
      keyFields: ['permalink', 'masterProductId'],
    },
    // Warehouse inventory for a single SKU was used for all SKUs because of how
    // Apollo caches items with an `id` key.
    Warehouse: {
      keyFields: false,
    },
    Personalization: {
      keyFields: false,
    },
    LocationOption: {
      keyFields: false,
    },
  },
};

import React from 'react';

import config from 'config';

import logger from './logger';
import RouterEvents from './RouterEvents';
import createRoutesModule from './routesModule';

const debug = logger.extend('routesExtension');

let getInitialPropsCount = 1;

export default function routesExtension({ routes, refreshInterval } = {}) {
  return {
    id: 'routes',
    redux: {
      modules: [createRoutesModule({ routes })],
    },
    server: process.browser
      ? {}
      : {
          init() {
            require('./config');
          },
          handler({ handler, app, server }) {
            if (routes) {
              return function getRoutesHandler(req, res, ...args) {
                if (!req.context.isInternalRoute) {
                  const routesHandler = routes.getRequestHandler(app);
                  return routesHandler(req, res, ...args);
                }
                return handler(req, res, ...args);
              };
            }
          },
          configure(server) {
            // Import these here so they don't impact the browser bundle size.
            const {
              default: rewriteMiddleware,
            } = require('./rewriteMiddleware');
            const {
              default: staticRedirectMiddleware,
            } = require('./staticRedirectMiddleware');

            const enableRewrites = config.get('server.rewrites.enabled');

            if (enableRewrites) {
              debug(
                'rewriteMiddleware is enabled (server.rewrites.enabled is %o)',
                enableRewrites
              );
              server.useTracked(
                'rewriteMiddleware',
                rewriteMiddleware({ refreshInterval })
              );
            } else {
              debug(
                'rewriteMiddleware is disabled (server.rewrites.enabled is %o)',
                enableRewrites
              );
            }
            server.useTracked(
              'staticRedirectMiddleware',
              staticRedirectMiddleware()
            );
          },
        },
    app: {
      getInitialProps: ({ Component, ctx }) => {
        if (process.browser) {
          getInitialPropsCount += 1;
        }
        return {};
      },
      render(props, children) {
        return (
          <>
            {children}
            <RouterEvents getInitialPropsCount={getInitialPropsCount} />
          </>
        );
      },
    },
  };
}

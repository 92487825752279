import React, { useContext, useMemo } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext({
  highlightAssets: false,
  filter: () => true,
});

export default function AssetProvider(props) {
  const parent = useContext(Context);

  const {
    children,
    filter = parent.filter,
    highlightAssets = parent.highlightAssets,
  } = props;

  const value = useMemo(() => {
    return { filter, highlightAssets };
  }, [filter, highlightAssets]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

AssetProvider.propTypes = {
  children: PropTypes.node,
  filter: PropTypes.func,
  highlightAssets: PropTypes.bool,
};

AssetProvider.Context = Context;

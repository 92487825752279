import flatMapDeep from 'lodash/flatMapDeep';
import values from 'lodash/values';

import {
  createAction,
  createReducer,
  createCachedSelector,
  isTruthyQueryParameter,
  createSelector,
  outOfDateStatus,
  upToDateStatus,
} from '../../../techstyle-shared/redux-core';

import { ProductsPage } from './schema';

export const initialState = {
  breadcrumbs: {
    categoryIds: {},
  },
  // FIXME: SXF already has a state/reducer named `products`, so we have to
  // avoid clobbering that. Come up with a better name, or make compatible with
  // SXF in the future?
  productsMeta: {
    debugEs: false,
  },
  byo: {
    configs: {},
    networkStatus: outOfDateStatus(),
  },
};

export const setDebugEs = createAction('products/setDebugEs');

export const loadCategoryBreadcrumbsRequest = createAction(
  'products/loadCategoryBreadcrumbsRequest'
);
export const loadCategoryBreadcrumbsSuccess = createAction(
  'products/loadCategoryBreadcrumbsSuccess'
);
export const loadCategoryBreadcrumbsFailure = createAction(
  'products/loadCategoryBreadcrumbsFailure'
);

export const loadProductsRequest = createAction('products/loadProductsRequest');
export const loadProductsSuccess = createAction('products/loadProductsSuccess');
export const loadProductsFailure = createAction('products/loadProductsFailure');

export const loadProductsByIdsRequest = createAction(
  'products/loadProductsByIdsRequest'
);
export const loadProductsByIdsSuccess = createAction(
  'products/loadProductsByIdsSuccess'
);
export const loadProductsByIdsFailure = createAction(
  'products/loadProductsByIdsFailure'
);

export const loadProductRequest = createAction('products/loadProductRequest');
export const loadProductSuccess = createAction('products/loadProductSuccess');
export const loadProductFailure = createAction('products/loadProductFailure');

export const loadProductBundleRequest = createAction(
  'products/loadProductBundleRequest'
);
export const loadProductBundleSuccess = createAction(
  'products/loadProductBundleSuccess'
);
export const loadProductBundleFailure = createAction(
  'products/loadProductBundleFailure'
);

export const loadProductByPermalinkRequest = createAction(
  'products/loadProductByPermalinkRequest'
);
export const loadProductByPermalinkSuccess = createAction(
  'products/loadProductByPermalinkSuccess'
);
export const loadProductByPermalinkFailure = createAction(
  'products/loadProductByPermalinkFailure'
);

export const loadRecommendedProductsRequest = createAction(
  'products/loadRecommendedProductsRequest'
);
export const loadRecommendedProductsSuccess = createAction(
  'products/loadRecommendedProductsSuccess'
);
export const loadRecommendedProductsFailure = createAction(
  'products/loadRecommendedProductsFailure'
);

export const loadCustomerRecommendedProductsV2Request = createAction(
  'products/loadCustomerRecommendedProductsV2Request'
);
export const loadCustomerRecommendedProductsV2Success = createAction(
  'products/loadCustomerRecommendedProductsV2Success'
);
export const loadCustomerRecommendedProductsV2Failure = createAction(
  'products/loadCustomerRecommendedProductsV2Failure'
);

export const loadCustomerRecommendedProductsRequest = createAction(
  'products/loadCustomerRecommendedProductsRequest'
);
export const loadCustomerRecommendedProductsSuccess = createAction(
  'products/loadCustomerRecommendedProductsSuccess'
);
export const loadCustomerRecommendedProductsFailure = createAction(
  'products/loadCustomerRecommendedProductsFailure'
);

export const loadRelatedProductsRequest = createAction(
  'products/loadRelatedProductsRequest'
);
export const loadRelatedProductsSuccess = createAction(
  'products/loadRelatedProductsSuccess'
);
export const loadRelatedProductsFailure = createAction(
  'products/loadRelatedProductsFailure'
);

export const loadProductsByGeoLocationRequest = createAction(
  'products/loadProductsByGeoLocationRequest'
);
export const loadProductsByGeoLocationSuccess = createAction(
  'products/loadProductsByGeoLocationSuccess'
);
export const loadProductsByGeoLocationFailure = createAction(
  'products/loadProductsByGeoLocationFailure'
);

export const loadSubcoItemsRequest = createAction(
  'products/loadSubcoItemsRequest'
);

export const loadSubcoItemsSuccess = createAction(
  'products/loadSubcoItemsSuccess'
);

export const loadSubcoItemsFailure = createAction(
  'products/loadSubcoItemsFailure'
);

export const loadProductPersonalizationOptionsRequest = createAction(
  'products/loadProductPersonalizationOptionsRequest'
);

export const loadProductPersonalizationOptionsSuccess = createAction(
  'products/loadProductPersonalizationOptionsSuccess'
);

export const loadProductPersonalizationOptionsFailure = createAction(
  'products/loadProductPersonalizationOptionsFailure'
);

export const loadCustomizationPreviewRequest = createAction(
  'products/loadCustomizationPreviewRequest'
);

export const loadCustomizationPreviewSuccess = createAction(
  'products/loadCustomizationPreviewSuccess'
);

export const loadCustomizationPreviewFailure = createAction(
  'products/loadCustomizationPreviewFailure'
);

export const loadByoConfigRequest = createAction(
  'products/loadByoConfigRequest'
);

export const loadByoConfigSuccess = createAction(
  'products/loadByoConfigSuccess'
);

export const loadByoConfigFailure = createAction(
  'products/loadByoConfigFailure'
);

export const trackProductClicked = createAction('products/trackProductClicked');
export const trackProductListViewed = createAction(
  'products/trackProductListViewed'
);
export const trackProductViewed = createAction('products/trackProductViewed');
export const trackProductDetailViewed = createAction(
  'products/trackProductDetailViewed'
);
export const trackProductDetailImageViewed = createAction(
  'products/trackProductDetailImageViewed'
);
export const trackProductPriceViewed = createAction(
  'products/trackProductPriceViewed'
);

export function initProducts() {
  return (dispatch, getState, context) => {
    if (context.isServer && !context.hasPreloadedState) {
      const { debugEs } = context.req.query;

      if (isTruthyQueryParameter(debugEs)) {
        dispatch(setDebugEs(true));
      }
    }
  };
}

export function loadProducts(payload, options = {}) {
  return (dispatch, getState, context) => {
    const { apiVersion = 1, productSource } = options;
    const endpoint = apiVersion >= 2 ? `products/v${apiVersion}` : 'products';
    const headers = {};

    if (productSource) {
      headers.psrc = productSource;
    }

    const { debugEs } = getState().productsMeta;
    if (debugEs) {
      payload = {
        debugEs: true,
        debugTier1: true,
        debugBento: true,
        ...payload,
      };
    }

    return dispatch({
      bentoApi: {
        endpoint,
        headers,
        json: payload,
        method: 'POST',
        requestKey: `loadProducts:${JSON.stringify([endpoint, payload])}`,
        schema: ProductsPage,
        actions: [
          loadProductsRequest,
          loadProductsSuccess,
          loadProductsFailure,
        ],
      },
    });
  };
}

export function loadProductsByIds({ masterProductIds, ...options }) {
  if (Array.isArray(masterProductIds) && masterProductIds.length) {
    return {
      bentoApi: {
        endpoint: `products/multiple`,
        searchParams: { masterProductIds: masterProductIds.join(',') },
        // TODO: schema!
        actions: [
          loadProductsByIdsRequest,
          loadProductsByIdsSuccess,
          loadProductsByIdsFailure,
        ],
      },
    };
  }
}

export function loadProduct(masterProductId) {
  return {
    bentoApi: {
      endpoint: `products/${parseInt(masterProductId)}`,
      // TODO: schema!
      actions: [loadProductRequest, loadProductSuccess, loadProductFailure],
    },
  };
}

export function loadProductBundle(masterProductId) {
  return {
    bentoApi: {
      endpoint: `products/sets/${parseInt(masterProductId)}`,
      // TODO: schema!
      actions: [
        loadProductBundleRequest,
        loadProductBundleSuccess,
        loadProductBundleFailure,
      ],
    },
  };
}

export function loadProductByPermalink(permalink) {
  return {
    bentoApi: {
      endpoint: `products/permalink/${permalink}`,
      // TODO: schema!
      actions: [
        loadProductByPermalinkRequest,
        loadProductByPermalinkSuccess,
        loadProductByPermalinkFailure,
      ],
    },
  };
}

export const loadCategoryBreadcrumbs = (categoryId, options = {}) => {
  if (!categoryId) {
    throw new Error(`loadCategoryBreadcrumbs must be called with a categoryId`);
  }

  return {
    bentoApi: {
      endpoint: `categories/${categoryId}/breadcrumbs`,
      sessionRequired: false,
      requestKey: JSON.stringify(['breadcrumbs', categoryId]),
      actions: [
        loadCategoryBreadcrumbsRequest,
        (payload, meta) => {
          return loadCategoryBreadcrumbsSuccess(payload, {
            ...meta,
            categoryId,
          });
        },
        loadCategoryBreadcrumbsFailure,
      ],
    },
  };
};

export function loadRecommendedProducts(masterProductId, options = {}) {
  const {
    algorithm = 'ymal',
    size = 15,
    retailDiscounting = false,
    filterProductTagIds = [],
    filterProductFpls = [],
    params,
  } = options;
  return {
    bentoApi: {
      endpoint: `products/v2/${parseInt(masterProductId)}/recommended`,
      searchParams: {
        algorithm,
        size,
        retailDiscounting,
        filterProductTagIds,
        filterProductFpls,
        ...params,
      },
      method: 'GET',
      requestKey: JSON.stringify([
        'loadRecommendedProducts',
        masterProductId,
        options,
      ]),
      schema: ProductsPage,
      actions: [
        loadRecommendedProductsRequest,
        loadRecommendedProductsSuccess,
        loadRecommendedProductsFailure,
      ],
    },
  };
}

export function loadCustomerRecommendedProducts(options = {}) {
  const {
    algorithm = 'boutique',
    page = 1,
    size = 15,
    membershipBrandIds = [],
    excludeFpls = [],
  } = options;
  return {
    bentoApi: {
      endpoint: `products/v2/customerRecommended`,
      searchParams: {
        algorithm,
        page,
        size,
        membershipBrandIds,
        excludeFpls,
      },
      method: 'GET',
      requestKey: JSON.stringify(['loadCustomerRecommendedProducts', options]),
      schema: ProductsPage,
      actions: [
        loadCustomerRecommendedProductsRequest,
        loadCustomerRecommendedProductsSuccess,
        loadCustomerRecommendedProductsFailure,
      ],
    },
  };
}

export function loadCustomerRecommendedProductsV2(payload = {}) {
  const {
    algorithm = 'boutique',
    page = 1,
    size = 24,
    membershipBrandIds = [],
    aggregations = [],
    aggregationFilter = [],
    sort = [],
    excludeFpls = [],
  } = payload;
  return {
    bentoApi: {
      endpoint: `products/v2/customerRecommended`,
      method: 'POST',
      json: {
        // REMIX-4782: we need to limit the payload from the request
        // so it will only pull the recommended products. Currently that's all we want.
        // This excludes some of the default product fields, so if in the future we want
        // to add them or expand this endpoint they will need to be added.
        algorithm,
        page,
        size,
        membershipBrandIds,
        aggregations,
        aggregationFilter,
        sort,
        excludeFpls,
      },
      requestKey: JSON.stringify([
        'loadCustomerRecommendedProductsV2',
        payload,
      ]),
      schema: ProductsPage,
      actions: [
        loadCustomerRecommendedProductsV2Request,
        loadCustomerRecommendedProductsV2Success,
        loadCustomerRecommendedProductsV2Failure,
      ],
    },
  };
}

export function loadRelatedProducts(options = {}) {
  const { size = 15, retailDiscounting = false } = options;
  return {
    bentoApi: {
      endpoint: `products/relatedProducts`,
      searchParams: { size, retailDiscounting },
      method: 'GET',
      requestKey: 'relatedProducts',
      schema: ProductsPage,
      actions: [
        loadRelatedProductsRequest,
        loadRelatedProductsSuccess,
        loadRelatedProductsFailure,
      ],
    },
  };
}

/* Get products using brand specific logic using geolocation implemented in BentoAPI. Works for JF and SD */
export function loadProductsByGeoLocation(options) {
  return (dispatch, getState, context) => {
    const { zip } = options;
    const payload = { ...options };
    if (!zip) {
      payload.ipAddress = getState().intl.ipAddress;
    }

    return dispatch({
      bentoApi: {
        endpoint: `products/geolocation`,
        json: payload,
        method: 'POST',
        requestKey: JSON.stringify(['productsByGeolocation', payload]),
        schema: ProductsPage,
        actions: [
          loadProductsByGeoLocationRequest,
          loadProductsByGeoLocationSuccess,
          loadProductsByGeoLocationFailure,
        ],
      },
    });
  };
}

export function loadSubcoItems(params = {}) {
  return {
    bentoApi: {
      endpoint: `products/subscriptions`,
      method: 'GET',
      searchParams: params,
      requestKey: JSON.stringify(['getSubscriptionList', params]),
      actions: [
        loadSubcoItemsRequest,
        loadSubcoItemsSuccess,
        loadSubcoItemsFailure,
      ],
    },
  };
}

export function loadProductPersonalizationOptions(params = {}) {
  const { masterProductId } = params;
  return {
    bentoApi: {
      endpoint: `products/${masterProductId}/personalizations`,
      method: 'GET',
      searchParams: params,
      requestKey: JSON.stringify(['getProductPersonalizationOptions', params]),
      actions: [
        loadProductPersonalizationOptionsRequest,
        loadProductPersonalizationOptionsSuccess,
        loadProductPersonalizationOptionsFailure,
      ],
    },
  };
}

export function loadCustomizationPreview(params = {}) {
  const { masterProductId } = params;
  return {
    bentoApi: {
      endpoint: `products/${masterProductId}/embroidery/preview`,
      method: 'GET',
      searchParams: params,
      requestKey: JSON.stringify(['getCustomizationPreview', params]),
      actions: [
        loadCustomizationPreviewRequest,
        loadCustomizationPreviewSuccess,
        loadCustomizationPreviewFailure,
      ],
    },
  };
}

/**
 * @function loadByoConfigs Retrieves Build Your Own bundle configs maintained in Console via `products/byo/config`
 * @param {Object} params Includes optional params for the query
 * @param {String} params.searchByDate The date in which we want to fetch configs by
 * @param {Boolean} params.filterInactiveConfigs Flag passed to filter out inactive products
 * @returns
 */
export const loadByoConfigs = ({
  params = { searchByDate: '', filterInactiveConfigs: false },
  options = { includeFlattenedConfigs: false },
}) => {
  return async (dispatch, getState) => {
    const { networkStatus } = getState().byo;

    if (!networkStatus.isUpToDate) {
      const { includeFlattenedConfigs } = options;

      return dispatch({
        bentoApi: {
          endpoint: 'products/byo/config',
          searchParams: params,
          requestKey: JSON.stringify([
            'loadByoConfigs',
            params.searchByDate,
            params.filterInactiveConfigs,
          ]),
          actions: [
            loadByoConfigRequest,
            (payload, meta) => {
              return loadByoConfigSuccess(payload, {
                ...meta,
                includeFlattenedConfigs,
              });
            },
            loadByoConfigFailure,
          ],
        },
      });
    }
  };
};

export const breadcrumbsReducer = createReducer(initialState.breadcrumbs, {
  [loadCategoryBreadcrumbsSuccess]: (state, action) => {
    state.categoryIds[action.meta.categoryId] = action.payload;
  },
});

export const productsMetaReducer = createReducer(initialState.productsMeta, {
  [setDebugEs]: (state, action) => {
    state.debugEs = action.payload;
  },
});

export const byoReducer = createReducer(initialState.byo, {
  [loadByoConfigSuccess]: (state, action) => {
    action.payload.forEach((config) => {
      state.configs[config.byoProductId] = config;
    });
    if (action.meta.includeFlattenedConfigs) {
      state.flattenedConfigs = flatMapDeep(state.configs, values).filter(
        (config) => config?.byoConfig
      );
    }
    state.networkStatus = upToDateStatus();
  },
});

const getCachedBreadcrumbDataForCategory = createCachedSelector(
  [
    (state, categoryId) => state.breadcrumbs.categoryIds,
    (state, categoryId) => categoryId,
  ],
  (breadcrumbs, categoryId) => {
    if (!breadcrumbs[categoryId]) {
      return null;
    }
    return breadcrumbs[categoryId];
  }
)((state, categoryId) => categoryId);

export function getBreadcrumbDataForCategory(state, categoryId) {
  if (!categoryId) {
    return null;
  }
  return getCachedBreadcrumbDataForCategory(state, categoryId);
}

const getCachedCategoryBreadcrumbHierarchy = createCachedSelector(
  [
    (state, categoryId) => state.breadcrumbs.categoryIds,
    (state, categoryId) => categoryId,
  ],
  (breadcrumbs, categoryId) => {
    if (!breadcrumbs[categoryId]) {
      return null;
    }
    const breadcrumbsForCategory = breadcrumbs[categoryId];
    return breadcrumbsForCategory.ancestors
      ? [
          ...breadcrumbsForCategory.ancestors.slice().reverse(),
          breadcrumbsForCategory,
        ]
      : [breadcrumbsForCategory];
  }
)((state, categoryId) => categoryId);

export function getCategoryBreadcrumbHierarchy(state, categoryId) {
  if (!categoryId) {
    return null;
  }
  return getCachedCategoryBreadcrumbHierarchy(state, categoryId);
}

export const getByoConfigs = createSelector([(state) => state.byo], (byo) => {
  return byo;
});

export default {
  id: 'products',
  reducerMap: {
    breadcrumbs: breadcrumbsReducer,
    productsMeta: productsMetaReducer,
    byo: byoReducer,
  },
  initialActions: [initProducts()],
};

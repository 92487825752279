import { actionChannel, putResolve, take } from 'redux-saga/effects';

import {
  createReducer,
  createAction,
  createSelector,
  loadSessionSuccess,
  invalidate,
  invalidateOnCustomerChange,
  startLoading,
  stopLoading,
  upToDateStatus,
} from '../../../techstyle-shared/redux-core';

import { logInSuccess, signUpSuccess } from './accountsModule';
import { apiGenderMap, Gender } from './constants';
import { LinkedProfiles } from './schema';

const initialState = {
  allProfiles: [],
  membershipProfileId: null,
  defaultGender: Gender.FEMALE,
  networkStatus: upToDateStatus(),
};

export const linkedProfilesActionChannel = 'loadProfiles';

export const invalidateProfiles = createAction('accounts/invalidateProfiles');

export const loadProfilesType = 'accounts/loadProfiles';
export const loadProfilesRequest = createAction('accounts/loadProfilesRequest');
export const loadProfilesSuccess = createAction(
  'accounts/loadProfilesSuccess',
  (action) => {
    const { allProfiles, membershipProfileId } = action.payload;
    action.payload = { allProfiles, membershipProfileId };
  }
);
export const loadProfilesFailure = createAction('accounts/loadProfilesFailure');

export function loadProfiles() {
  return {
    type: loadProfilesType,
    channel: linkedProfilesActionChannel,
    payload: (dispatch, getState) => {
      const { networkStatus } = getState().linkedProfiles;
      if (!networkStatus.isUpToDate && !networkStatus.isLoading) {
        return dispatch({
          bentoApi: {
            endpoint: 'accounts/me/profiles',
            requestKey: 'loadProfiles',
            schema: LinkedProfiles,
            actions: [
              loadProfilesRequest,
              loadProfilesSuccess,
              loadProfilesFailure,
            ],
          },
        });
      }
    },
  };
}

export const updateActiveLinkedProfileByGenderType =
  'accounts/updateActiveLinkedProfileByGender';
export const updateActiveLinkedProfileByGenderRequest = createAction(
  'accounts/updateActiveLinkedProfileByGenderRequest'
);
export const updateActiveLinkedProfileByGenderSuccess = createAction(
  'accounts/updateActiveLinkedProfileByGenderSuccess'
);
export const updateActiveLinkedProfileByGenderFailure = createAction(
  'accounts/updateActiveLinkedProfileByGenderFailure'
);

export const updateActiveLinkedProfileType =
  'accounts/updateActiveLinkedProfile';
export const updateActiveLinkedProfileRequest = createAction(
  'accounts/updateActiveLinkedProfileRequest'
);
export const updateActiveLinkedProfileSuccess = createAction(
  'accounts/updateActiveLinkedProfileSuccess'
);
export const updateActiveLinkedProfileFailure = createAction(
  'accounts/updateActiveLinkedProfileFailure'
);

export function updateActiveLinkedProfileByGender(gender) {
  const genderParam = apiGenderMap[gender];

  if (!genderParam) {
    throw new Error(
      'updateActiveLinkedProfileByGender must be called with `M` or `F` gender key'
    );
  }

  return {
    type: updateActiveLinkedProfileByGenderType,
    channel: linkedProfilesActionChannel,
    payload: {
      bentoApi: {
        method: 'PATCH',
        endpoint: `accounts/me/profiles/${genderParam}/active`,
        schema: LinkedProfiles,
        actions: [
          updateActiveLinkedProfileByGenderRequest,
          updateActiveLinkedProfileByGenderSuccess,
          updateActiveLinkedProfileByGenderFailure,
        ],
      },
    },
  };
}

export function updateActiveLinkedProfile(linkedProfileId) {
  if (!linkedProfileId) {
    throw new Error(
      'updateActiveLinkedProfile must be called with a linked profile ID'
    );
  }

  return {
    type: updateActiveLinkedProfile,
    channel: linkedProfilesActionChannel,
    payload: {
      bentoApi: {
        method: 'PATCH',
        endpoint: `accounts/me/profiles/${linkedProfileId}/active`,
        schema: LinkedProfiles,
        actions: [
          updateActiveLinkedProfileRequest,
          updateActiveLinkedProfileSuccess,
          updateActiveLinkedProfileFailure,
        ],
      },
    },
  };
}

const updateActiveLinkedProfiles = (state, action) => {
  return {
    ...action.payload,
    defaultGender: state.defaultGender,
    networkStatus: upToDateStatus(),
  };
};

export function createLinkedProfilesReducer(defaults) {
  return createReducer(
    {
      ...initialState,
      ...defaults,
    },
    {
      [invalidateProfiles]: invalidate,
      [logInSuccess]: invalidate,
      [signUpSuccess]: invalidate,
      [loadSessionSuccess]: invalidateOnCustomerChange,
      [loadProfilesRequest]: startLoading,
      [loadProfilesFailure]: stopLoading,
      [loadProfilesSuccess]: updateActiveLinkedProfiles,
      [updateActiveLinkedProfileByGenderRequest]: startLoading,
      [updateActiveLinkedProfileByGenderFailure]: stopLoading,
      [updateActiveLinkedProfileByGenderSuccess]: updateActiveLinkedProfiles,
      [updateActiveLinkedProfileRequest]: startLoading,
      [updateActiveLinkedProfileFailure]: stopLoading,
      [updateActiveLinkedProfileSuccess]: updateActiveLinkedProfiles,
    }
  );
}

export const getLinkedProfilesHelpers = createSelector(
  [(state) => state.linkedProfiles],
  (linkedProfiles) => {
    const currentProfile =
      linkedProfiles.allProfiles.find((profile) => profile.isCurrent) || null;

    const currentGender = currentProfile
      ? currentProfile.gender
      : linkedProfiles.defaultGender;

    const hasFemaleProfile = linkedProfiles.allProfiles.some(
      (profile) => profile.gender === Gender.FEMALE
    );

    const hasMaleProfile = linkedProfiles.allProfiles.some(
      (profile) => profile.gender === Gender.MALE
    );

    return {
      currentGender,
      currentProfile,
      hasFemaleProfile,
      hasMaleProfile,
      isFemale: currentGender === Gender.FEMALE,
      isMale: currentGender === Gender.MALE,
    };
  }
);

export const getLinkedProfiles = createSelector(
  [(state) => state.linkedProfiles, getLinkedProfilesHelpers],
  (linkedProfiles, linkedProfilesHelpers) => {
    return {
      ...linkedProfiles,
      ...linkedProfilesHelpers,
    };
  }
);

export function* processLinkedProfilesChannel() {
  const channel = yield actionChannel(
    (action) => action.channel === linkedProfilesActionChannel
  );
  while (true) {
    const action = yield take(channel);
    try {
      yield putResolve(action.payload);
    } catch (err) {
      // There may be situations where we want to bail out on any buffered
      // actions after this one, but for now just continue processing the
      // queue...
    }
  }
}

export default function createLinkedProfilesModule(defaults) {
  return {
    id: 'linkedProfiles',
    reducerMap: {
      linkedProfiles: createLinkedProfilesReducer(defaults),
    },
    sagas: [processLinkedProfilesChannel],
  };
}

export const AfterpayConstants = {
  locale: {
    EN_AU: 'en_AU',
    EN_CA: 'en_CA',
    EN_GB: 'en_GB',
    EN_NZ: 'en_NZ',
    EN_US: 'en_US',
    FR_FR: 'fr_FR',
    FR_CA: 'fr_CA',
    ES_ES: 'es_ES',
    IT_IT: 'it_IT',
  },
  currency: {
    AUD: 'AUD',
    CAD: 'CAD',
    EUR: 'EUR',
    GBP: 'GBP',
    NZD: 'NZD',
    USD: 'USD',
  },
  theme: {
    badge: {
      BLACK_ON_MINT: 'black-on-mint',
      BLACK_ON_WHITE: 'black-on-white',
      MINT_ON_BLACK: 'mint-on-black',
      WHITE_ON_BLACK: 'white-on-black',
    },
    lockup: {
      BLACK: 'black',
      WHITE: 'white',
      MINT: 'mint',
    },
    modal: {
      WHITE: 'white',
    },
    priceTable: {
      BLACK: 'black',
      WHITE: 'white',
    },
  },
  size: {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
  },
  logoType: {
    BADGE: 'badge',
    LOCKUP: 'lockup',
    COMPACT_BADGE: 'compact-badge',
  },
  modalLinkStyle: {
    CIRCLED_INFO_ICON: 'circled-info-icon',
    LEARN_MORE_TEXT: 'learn-more-text',
    MORE_INFO_TEXT: 'more-info-text',
    CIRCLED_QUESTION_ICON: 'circled-question-icon',
    CIRCLED_AFTERPAY_ICON: 'circled-afterpay-icon',
    NONE: 'none',
  },
  placementTypes: {
    PRICE_TABLE: 'price-table',
    PRICE_PARAGRAPH: 'price-paragraph',
  },
  mobileViewLayout: {
    FOUR_BY_ONE: 'four-by-one',
    TWO_BY_TWO: 'two-by-two',
  },
};

import styled from 'styled-components';

import BaseHeading from '../Heading';
import { desktop } from '../styles';

const Heading = styled(BaseHeading)`
  font-size: 20px;
  color: #333;
`;

const Label = styled(BaseHeading).attrs({
  level: 3,
})`
  font-size: 14px;
  color: #333;

  ${desktop`
    font-size: 16px;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
`;

export { Heading, Label, Wrapper };

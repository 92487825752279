/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';

import { FaCircle } from 'react-icons/fa';
import styled from 'styled-components';

import CheckboxRadioBase from '../CheckboxRadioBase';
import { CheckboxRadioBaseProps } from '../CheckboxRadioBase/CheckboxRadioBase';
import { useRadioButtonGroupState } from '../RadioButtonGroup';
import withThemeProps from '../withThemeProps';

const RadioButtonBase = styled(CheckboxRadioBase)`
  border-radius: 50%;
  padding: 3px;
  font-size: 8px;
`;

/**
 * Radio button input with custom rendering.
 */
export const RadioButton = React.forwardRef<
  HTMLInputElement,
  CheckboxRadioBaseProps
>(({ checkmarkIcon = FaCircle, name, onChange, value, ...rest }, ref) => {
  const radioButtonGroupState = useRadioButtonGroupState();

  // Only defined for radio buttons inside radio button groups!
  let isChecked!: boolean | undefined;
  let groupOnChange!: (event: React.ChangeEvent<HTMLInputElement>) => void;
  if (radioButtonGroupState) {
    name = name || radioButtonGroupState.name;
    isChecked = radioButtonGroupState.value === value;
    groupOnChange = radioButtonGroupState.onChange;
  }

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, ...args: any[]) => {
      if (onChange) {
        onChange(event, ...(args as []));
      }
      if (groupOnChange) {
        groupOnChange(event, ...(args as []));
      }
    },
    [onChange, groupOnChange]
  );

  return (
    <RadioButtonBase
      type="radio"
      checkmarkIcon={checkmarkIcon}
      name={name}
      value={value}
      checked={isChecked}
      onChange={handleChange}
      ref={ref}
      {...rest}
    />
  );
});

RadioButton.displayName = 'RadioButton';

export default withThemeProps<CheckboxRadioBaseProps>(RadioButton, {
  section: 'radioButton',
  defaultVariant: 'default',
});

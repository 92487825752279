import { logInFailure } from './src/techstyle-shared/react-accounts';
import { createGoogleTagManagerEvents } from './src/techstyle-shared/react-marketing';

/**
 * TODO: waiting for api response to indicate a user's account was hard cancelled and they can not login.
 *
 */
export default createGoogleTagManagerEvents({
  [logInFailure]: (action, prevState, nextState) => {
    return {
      event: 'trackEvent',
      eventCategory: 'failed login',
      eventAction: 'sign in',
      eventLabel: 'unsuccessful',
    };
  },
});

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Close = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const StyledButton = styled.button.attrs({
  type: 'button',
  'aria-label': 'Close',
})`
  position: absolute;
  top: 0;
  ${(props) => (props.left ? 'left: 0' : 'right: 0')};
  width: 56px;
  height: 56px;
  border: 0;
  line-height: 56px;
  text-align: center;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.theme.closeButton ? props.theme.closeButton.defaultStyle : null};
`;

const CloseButton = ({ children, ...props }) => {
  return (
    <CloseButton.StyledButton {...props}>
      {children || <CloseButton.Close />}
    </CloseButton.StyledButton>
  );
};

CloseButton.defaultProps = {
  left: false,
};

CloseButton.propTypes = {
  /**
   * The content of the close button, usually an icon. If none is provided,
   * the default close icon is rendered.
   */
  children: PropTypes.node,
  /**
   * Move button to the left. Defaults to the right.
   */
  left: PropTypes.bool,
};

CloseButton.StyledButton = StyledButton;
CloseButton.Close = Close;

export default CloseButton;

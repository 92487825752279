import { checkForReload } from '../shared/utils/routing';

export default function pageReloadExtension() {
  return {
    id: 'page',
    page() {
      return {
        getInitialProps: {
          enhance(getInitialProps) {
            return async (ctx) => {
              checkForReload(ctx);
              return getInitialProps(ctx);
            };
          },
        },
      };
    },
  };
}

function isValidNumber(number) {
  return !Number.isNaN(number) && Number.isFinite(number);
}

/**
 * Retrieve the initial filter, sort, and mySize values
 */
// * baseFilters is the same data structure as productJson
export function parseProductListingQueryParams(
  query,
  { baseFilters, sortOptions }
) {
  // * Accept query string object as well as an instance of URLSearchParams
  const urlParams = new URLSearchParams(query);
  const baseAggregationFilter = baseFilters.aggregationFilter || {};
  const aggregationFilter = baseFilters.aggregations.reduce(
    (aggregationFilter, aggregation) => {
      const key = aggregation.field;
      const baseValues = baseAggregationFilter[key] || [];
      const valueString = urlParams.get(key);
      if (valueString) {
        const mergedValues = baseValues.concat(
          valueString.split(',').filter(Boolean)
        );
        const uniqueValues = new Set(mergedValues);
        aggregationFilter[key] = Array.from(uniqueValues);
      } else {
        aggregationFilter[key] = baseValues;
      }
      return aggregationFilter;
    },
    {}
  );

  // TODO: Check whether the page actually supports filterable `categoryIds`.
  const baseCategoryIds = baseFilters.categoryIds || [];
  const mergedCategoryIds = baseCategoryIds.concat(
    (urlParams.get('category_ids') || '')
      .split(',')
      .map((id) => parseInt(id, 10))
      .filter(isValidNumber)
  );
  const uniqueCategoryIds = new Set(mergedCategoryIds);
  const categoryIds = Array.from(uniqueCategoryIds);

  let avgReviewMin = baseFilters.avgReviewMin;
  const avgReviewMinString = urlParams.get('avg_review_min');
  if (avgReviewMinString) {
    const parsedAvgReviewMin = parseFloat(avgReviewMinString);
    // Make sure it's a number and within bounds.
    if (
      isValidNumber(parsedAvgReviewMin) &&
      parsedAvgReviewMin > 0 &&
      parsedAvgReviewMin <= 5
    ) {
      avgReviewMin = parsedAvgReviewMin;
    }
  }

  let vipUnitPriceMinimum = baseFilters.vipUnitPriceMinimum;
  const priceMinString = urlParams.get('price_min');
  if (priceMinString) {
    const parsedPriceMin = parseFloat(priceMinString);
    if (isValidNumber(parsedPriceMin) && parsedPriceMin >= 0) {
      vipUnitPriceMinimum = parsedPriceMin;
    }
  }

  let vipUnitPriceMaximum = baseFilters.vipUnitPriceMaximum;
  const priceMaxString = urlParams.get('price_max');
  if (priceMaxString) {
    const parsedPriceMax = parseFloat(priceMaxString);
    if (isValidNumber(parsedPriceMax) && parsedPriceMax >= 0) {
      vipUnitPriceMaximum = parsedPriceMax;
    }
  }

  let saleUnitPriceMinimum = baseFilters.saleUnitPriceMinimum;
  const salePriceMinString = urlParams.get('sale_price_min');
  if (salePriceMinString) {
    const parsedSalePriceMin = parseFloat(salePriceMinString);
    if (isValidNumber(parsedSalePriceMin) && parsedSalePriceMin >= 0) {
      saleUnitPriceMinimum = parsedSalePriceMin;
    }
  }

  let saleUnitPriceMaximum = baseFilters.saleUnitPriceMaximum;
  const salePriceMaxString = urlParams.get('sale_price_max');
  if (salePriceMaxString) {
    const parsedSalePriceMax = parseFloat(salePriceMaxString);
    if (isValidNumber(parsedSalePriceMax) && parsedSalePriceMax >= 0) {
      saleUnitPriceMaximum = parsedSalePriceMax;
    }
  }

  let defaultTagId = baseFilters.defaultTagId;
  const defaultTagIdString = urlParams.get('defaultTagId');
  if (defaultTagIdString) {
    const parsedDefaultTagId = parseInt(defaultTagIdString, 10);
    if (isValidNumber(parsedDefaultTagId)) {
      defaultTagId = parsedDefaultTagId;
    }
  }

  const sortOption = sortOptions.find(
    (option) => option.value === urlParams.get('sort')
  );

  const initialFilters = {
    ...baseFilters,
    aggregationFilter,
    categoryIds,
    avgReviewMin,
    defaultTagId,
    vipUnitPriceMinimum,
    vipUnitPriceMaximum,
    saleUnitPriceMinimum,
    saleUnitPriceMaximum,
  };

  // * my_size determines whether products are auto filtered by the user's size
  const mySize = urlParams.get('my_size') !== 'false';

  return {
    filters: initialFilters,
    sortOption,
    mySize,
  };
}

/**
 * Creates a URLSearchParams object instance based on the filters and sort
 */
export function createProductListingQueryParams({
  autoAppliedFilters,
  baseFilters,
  filters,
  sortOption,
  mySize = true,
}) {
  const baseAggregationFilter = baseFilters.aggregationFilter || {};

  // TODO: This will also need to be able to handle custom filters once they're implemented
  const {
    aggregationFilter = {},
    categoryIds = [],
    avgReviewMin,
    vipUnitPriceMinimum,
    vipUnitPriceMaximum,
    saleUnitPriceMinimum,
    saleUnitPriceMaximum,
    defaultTagId,
  } = filters;

  const params = {};

  // URLSearchParams instances are ordered, so add the params to this object in
  // the order we wish them to appear in the URL. Currently this is:
  // 1. defaultTagId (added for Curvy page on SXF)
  // 2. category_ids
  // 3. aggregation fields
  // 4. avg_review_min
  // 5. my_size
  // 6. sort
  if (defaultTagId != null) {
    if (defaultTagId !== baseFilters.defaultTagId) {
      params.defaultTagId = defaultTagId.toString();
    }
  }

  if (categoryIds.length) {
    const baseCategoryIds = new Set(baseFilters.categoryIds);
    const paramCategoryIds = categoryIds.filter(
      (value) => !baseCategoryIds.has(value)
    );
    if (paramCategoryIds.length) {
      params.category_ids = paramCategoryIds.sort().join(',');
    }
  }

  baseFilters.aggregations.forEach((aggregation) => {
    const key = aggregation.field;
    const values = aggregationFilter[key];
    // If a filter has been automatically applied (like a saved size), don't
    // include it in the URL params.
    if (values && values.length && !autoAppliedFilters?.[key]) {
      const baseValues = new Set(baseAggregationFilter[key]);
      const paramValues = values.filter((value) => !baseValues.has(value));
      if (paramValues.length) {
        params[key] = paramValues.sort().join(',');
      }
    }
  });

  if (avgReviewMin != null && avgReviewMin !== baseFilters.avgReviewMin) {
    params.avg_review_min = avgReviewMin.toString();
  }

  if (
    vipUnitPriceMinimum != null &&
    vipUnitPriceMinimum !== baseFilters.vipUnitPriceMinimum
  ) {
    params.price_min = vipUnitPriceMinimum.toString();
  }

  if (
    vipUnitPriceMaximum != null &&
    vipUnitPriceMaximum !== baseFilters.vipUnitPriceMaximum
  ) {
    params.price_max = vipUnitPriceMaximum.toString();
  }

  if (
    saleUnitPriceMinimum != null &&
    saleUnitPriceMinimum !== baseFilters.saleUnitPriceMinimum
  ) {
    params.sale_price_min = saleUnitPriceMinimum.toString();
  }

  if (
    saleUnitPriceMaximum != null &&
    saleUnitPriceMaximum !== baseFilters.saleUnitPriceMaximum
  ) {
    params.sale_price_max = saleUnitPriceMaximum.toString();
  }

  // * only include my_size param if falsy (profile size filtering should be disabled)
  if (!mySize) {
    params.my_size = 'false';
  }

  if (sortOption && !sortOption.isDefault) {
    params.sort = sortOption.value;
  }

  return new URLSearchParams(params);
}

import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import useId from '../useId';

const Svg = styled.svg`
  fill: ${(props) => props.fill || props.color || 'black'};
  vertical-align: top;
`;

const DorinaLogo = React.forwardRef((props, ref) => {
  const titleId = useId();
  const hasAriaLabel = props['aria-label'];
  return (
    <Svg
      aria-labelledby={hasAriaLabel ? undefined : titleId}
      ref={ref}
      viewBox="0 0 122 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title id={titleId}>Dorina</title>
      <path d="M0.953857 0.794968H7.53259C10.0815 0.81957 11.9993 1.72987 13.2617 3.57508C13.7229 4.19015 14.0142 4.82982 14.1356 5.5187C14.2327 6.18297 14.2813 7.36391 14.2813 9.01229C14.2813 10.7837 14.2084 12.0138 14.0628 12.7027C13.9899 13.0471 13.8928 13.367 13.7472 13.6376C13.6015 13.9082 13.4316 14.1789 13.2131 14.4741C12.6548 15.2368 11.9022 15.8765 10.9069 16.3931C9.93589 16.9344 8.74638 17.205 7.33839 17.205H0.953857V0.794968ZM3.8184 14.8678H7.24128C8.84348 14.8678 10.0087 14.4003 10.737 13.4408C11.0526 13.0964 11.2468 12.6289 11.3439 12.1122C11.4167 11.571 11.4653 10.5869 11.4653 9.1107C11.4653 7.68374 11.4167 6.67503 11.3439 6.05996C11.2468 5.44489 11.004 4.95284 10.6156 4.55919C9.81452 3.59968 8.69783 3.13223 7.26556 3.18144H3.8184V14.8678Z" />
      <path d="M46.9807 0.794968H54.3363C55.9142 0.794968 57.1765 1.16401 58.1718 1.87749C59.3614 2.68938 59.9925 3.87031 60.0411 5.37108C60.0168 7.60994 58.803 9.1107 56.3997 9.92259L60.7694 17.205H57.3707L53.4866 10.1932H49.8453V17.205H46.9807V0.794968ZM49.8453 7.97898H54.1663C55.2345 7.95437 56.0356 7.68374 56.5696 7.16708C57.1037 6.67503 57.3465 6.08456 57.3465 5.37108C57.3222 4.50999 57.0066 3.89492 56.3997 3.50127C55.9142 3.15683 55.2102 2.98461 54.312 2.98461H49.8453V7.97898Z" />
      <path d="M69.7514 0.794968H72.6159V17.205H69.7514V0.794968Z" />
      <path d="M83.1759 0.794968H85.8705L94.3913 12.4567H94.4399V0.794968H97.3044V17.205H94.6098L86.089 5.5433H86.0404V17.205H83.1759V0.794968Z" />
      <path d="M117.55 13.5392H110.413L108.957 17.205H105.922L112.768 0.794968H115.147L121.993 17.205H118.983L117.55 13.5392ZM116.725 11.325L114.006 4.14095H113.958L111.239 11.325H116.725Z" />
      <path d="M23.5789 9.01229C23.5789 7.09328 23.6517 5.83854 23.7731 5.17426C23.9187 4.53459 24.1129 4.01793 24.4042 3.59969C24.8655 2.837 25.5937 2.17273 26.5891 1.58226C27.5844 0.991794 28.8224 0.671957 30.3032 0.647354C31.8083 0.671957 33.0464 0.967191 34.0417 1.58226C35.037 2.17273 35.741 2.8616 36.178 3.59969C36.4936 3.99333 36.712 4.50999 36.8334 5.17426C36.9548 5.81394 37.0034 7.09328 37.0034 9.01229C37.0034 10.8821 36.9548 12.1614 36.8334 12.8257C36.712 13.49 36.4936 14.0067 36.178 14.4249C35.741 15.1876 35.0127 15.8519 34.0417 16.4177C33.0464 17.0082 31.7841 17.328 30.3032 17.3526C28.8224 17.328 27.5844 17.0082 26.5891 16.4177C25.5695 15.8519 24.8412 15.163 24.4042 14.4249C24.2586 14.1789 24.1372 13.9328 24.0401 13.7114C23.943 13.49 23.8459 13.1948 23.7731 12.8257C23.6517 12.1368 23.5789 10.8821 23.5789 9.01229ZM26.4677 9.01229C26.4677 10.6361 26.5405 11.7186 26.6862 12.2845C26.8075 12.8503 27.0503 13.3178 27.4144 13.6622C27.7057 14.0067 28.1184 14.2773 28.6039 14.4741C29.0895 14.7201 29.6721 14.8432 30.3275 14.8432C31.0072 14.8432 31.5899 14.7201 32.0997 14.4741C32.5852 14.2527 32.9493 13.9821 33.2406 13.6622C33.6048 13.3178 33.8718 12.8503 33.9932 12.2845C34.1145 11.7186 34.1874 10.6115 34.1874 9.01229C34.1874 7.38851 34.1145 6.30599 33.9932 5.71552C33.8475 5.14966 33.6048 4.70681 33.2406 4.36237C32.9493 4.01793 32.5852 3.7473 32.0997 3.52588C31.5899 3.30445 31.0072 3.18144 30.3275 3.18144C29.6478 3.20604 29.0895 3.30445 28.6039 3.52588C28.1184 3.7473 27.7057 4.04254 27.4144 4.36237C27.0503 4.70681 26.8075 5.17426 26.6862 5.71552C26.5162 6.28139 26.4677 7.38851 26.4677 9.01229Z" />
    </Svg>
  );
});

DorinaLogo.displayName = 'DorinaLogo';

DorinaLogo.propTypes = {
  /**
   * An accessible label. If provided, the default `aria-labelledby` pointing
   * to the SVG’s `<title>` will be excluded.
   */
  'aria-label': PropTypes.string,
  /**
   * If provided, render the logo monochromatically in the given color.
   */
  color: PropTypes.string,
  fill: PropTypes.string,
};

export default DorinaLogo;

import React from 'react';

import dynamic from 'next/dynamic';

const PerformanceAdminPanel = dynamic(() =>
  import(/* webpackChunkName: "AdminTools" */ './PerformanceAdminPanel')
);

export default function performanceExtension() {
  return {
    id: 'performance',
    admin: {
      panels: [<PerformanceAdminPanel key="performance" />],
    },
    server: process.browser
      ? undefined
      : {
          init(server) {
            const instrumentServer = require('./instrumentServer').default;
            instrumentServer(server);
          },
          handler({ handler }) {
            const instrumentHandler = require('./instrumentHandler').default;
            return instrumentHandler(handler);
          },
        },
    document: process.browser
      ? undefined
      : {
          getInitialProps: {
            enhance(getInitialProps) {
              return async (ctx) => {
                const { renderPage } = ctx;
                const { performance } = ctx.req;

                // Instrument `renderPage` to capture app render time.
                ctx.renderPage = (...args) => {
                  const entry = {
                    type: 'next',
                    name: 'app.render',
                  };
                  performance.entries.push(entry);

                  const startTime = process.hrtime();
                  const result = renderPage(...args);
                  const endTime = process.hrtime();

                  performance.timings.renderPageStart =
                    performance.getOffset(startTime);
                  performance.timings.renderPageEnd =
                    performance.getOffset(endTime);
                  entry.startTime = performance.timings.renderPageStart;
                  entry.duration = performance.getDuration(startTime, endTime);
                  return result;
                };

                const entry = {
                  type: 'next',
                  name: 'document.getInitialProps',
                };
                performance.entries.push(entry);

                const startTime = process.hrtime();
                const initialProps = await getInitialProps(ctx);
                const endTime = process.hrtime();

                performance.timings.docInitialPropsStart =
                  performance.getOffset(startTime);
                performance.timings.docInitialPropsEnd =
                  performance.getOffset(endTime);
                entry.duration = performance.getDuration(startTime, endTime);
                entry.startTime = performance.timings.docInitialPropsStart;

                return initialProps;
              };
            },
          },
        },
    app: process.browser
      ? undefined
      : {
          getInitialProps: {
            enhance(getInitialProps) {
              return async ({ Component, ctx }) => {
                const { performance } = ctx.req;
                const entry = {
                  type: 'next',
                  name: 'app.getInitialProps',
                };
                performance.entries.push(entry);

                const startTime = process.hrtime();
                const initialProps = await getInitialProps({ Component, ctx });
                const endTime = process.hrtime();

                performance.timings.appInitialPropsStart =
                  performance.getOffset(startTime);
                performance.timings.appInitialPropsEnd =
                  performance.getOffset(endTime);
                entry.duration = performance.getDuration(startTime, endTime);
                entry.startTime = performance.timings.appInitialPropsStart;

                return initialProps;
              };
            },
          },
        },
  };
}

import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  Currency,
  FormattedMessage,
} from '../../../../techstyle-shared/react-intl';
import { PromoType } from '../../../../techstyle-shared/react-promos';
import { useProductContext } from '../ProductContext';
import { useProductGridPricingContext } from '../ProductGridPricingContext/ProductGridPricingContext';

export const bogoPromoTypes = new Set([
  PromoType.BOGO,
  PromoType.BOGO_WITH_DISCOUNT,
]);

const PromoPrice = styled(Currency)`
  color: ${({ theme }) => theme.colors.promoPrice};
  font-size: ${16 / 14}em;
  font-weight: bold;

  ${({ $promoPriceStyle }) => $promoPriceStyle};
`;

const Price = styled(Currency)`
  ${({ $showStrikethrough, theme }) =>
    $showStrikethrough &&
    css`
      color: ${theme.colors.textDisabled};
      text-decoration: line-through;
      font-weight: normal;
    `};

  ${({ $priceStyle }) => $priceStyle};
`;

const HiddenText = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
`;

// this determines the appropriate text for screen readers
// to read when reading the price
const PriceHiddenText = ({
  discountPrice,
  discountGuestPrice,
  shouldShowVipPrice,
  shouldShowRetailPrice,
  defaultUnitPrice,
  retailUnitPrice,
  showStrikeThroughForRetailPrice,
  showStrikeThroughForVipPrice,
}) => {
  if (discountPrice || (shouldShowVipPrice && shouldShowRetailPrice)) {
    return (
      <>
        <FormattedMessage
          id="site_product_grid.price_after_discount"
          defaultMessage="Price after discount:"
        />{' '}
        <Currency amount={discountPrice || defaultUnitPrice} />
      </>
    );
  } else if (discountGuestPrice) {
    return (
      <>
        <FormattedMessage
          id="site_product_grid.price_after_discount"
          defaultMessage="Price after discount:"
        />{' '}
        <Currency amount={discountGuestPrice} />
      </>
    );
  } else if (
    // when there is one price with a strike through
    (shouldShowVipPrice && showStrikeThroughForVipPrice) ||
    (shouldShowRetailPrice && showStrikeThroughForRetailPrice)
  ) {
    return (
      <>
        <FormattedMessage
          id="site_product_grid.price_before_discount"
          defaultMessage="Price before discount:"
        />{' '}
        <Currency
          amount={shouldShowVipPrice ? defaultUnitPrice : retailUnitPrice}
        />
      </>
    );
  }

  return (
    <>
      <FormattedMessage
        id="site_product_grid.current_price"
        defaultMessage="Current price:"
      />{' '}
      <Currency
        amount={shouldShowVipPrice ? defaultUnitPrice : retailUnitPrice}
      />
    </>
  );
};

PriceHiddenText.propTypes = {
  defaultUnitPrice: PropTypes.number,
  discountGuestPrice: PropTypes.number,
  discountPrice: PropTypes.number,
  retailUnitPrice: PropTypes.number,
  shouldShowRetailPrice: PropTypes.bool.isRequired,
  shouldShowVipPrice: PropTypes.bool.isRequired,
  showStrikeThroughForRetailPrice: PropTypes.bool.isRequired,
  showStrikeThroughForVipPrice: PropTypes.bool.isRequired,
};

const ProductPrice = React.forwardRef(
  (
    {
      as: ElementType = 'span',
      className,
      renderHiddenText = false,
      showPromoGuestPrice = false,
      showPromoPrice = true,
      showRetailPrice = false,
      showSalePrice = false,
      showVipPrice = true,
      strikeRegularPrice,
      product: productFromProps,
      promoPriceStyle,
      priceStyle,
      ...rest
    },
    ref
  ) => {
    const productFromContext = useProductContext();
    const productGridPricingContext = useProductGridPricingContext();
    const product = productFromProps || productFromContext;

    // attempt to retrieve pricing from the productGridPricingContext
    // pricing data comes from this only, do not reference product.* for
    // pricing data as it may be out of date if the context is used as the
    // source of truth
    const productPriceInfo =
      productGridPricingContext?.productGridPricing?.[product?.masterProductId];

    const {
      defaultUnitPrice,
      promos = {},
      retailUnitPrice,
      saleUnitPrice,
    } = productPriceInfo || product;

    const { promoPrice, promoTypeId, promoGuestPrice } = promos;

    const shouldShowPromoPrice =
      showPromoPrice && Boolean(promoPrice) && !bogoPromoTypes.has(promoTypeId);
    const shouldShowSalePrice = showSalePrice && Boolean(saleUnitPrice);
    const shouldShowVipPrice =
      (showVipPrice && !(shouldShowPromoPrice && showRetailPrice)) ||
      (!shouldShowPromoPrice && !showRetailPrice && !showPromoGuestPrice);
    const shouldShowRetailPrice =
      (showRetailPrice && !shouldShowVipPrice) ||
      (showRetailPrice && !shouldShowPromoPrice);

    const discountPrice =
      (shouldShowPromoPrice && promoPrice) ||
      (shouldShowSalePrice && saleUnitPrice);

    const discountGuestPrice = showPromoGuestPrice && promoGuestPrice;

    const showStrikeThroughForVipPrice =
      discountPrice || (strikeRegularPrice && !shouldShowRetailPrice);
    const showStrikeThroughForRetailPrice =
      strikeRegularPrice || shouldShowVipPrice || shouldShowPromoPrice;

    return (
      <ElementType ref={ref} className={className} {...rest}>
        {discountPrice ? (
          <PromoPrice
            amount={discountPrice}
            data-autotag="product-promo-price"
            $promoPriceStyle={promoPriceStyle}
            aria-hidden={renderHiddenText}
          />
        ) : null}

        {discountGuestPrice && (
          <PromoPrice
            amount={discountGuestPrice}
            data-autotag="product-promo-guest-price"
            $promoPriceStyle={promoPriceStyle}
            aria-hidden={renderHiddenText}
          />
        )}

        {shouldShowVipPrice && (
          <>
            {discountPrice && ' '}
            <Price
              $showStrikethrough={showStrikeThroughForVipPrice}
              amount={defaultUnitPrice}
              data-autotag="product-vip-price"
              $priceStyle={priceStyle}
              aria-hidden={renderHiddenText}
              {...rest}
            />
          </>
        )}

        {shouldShowRetailPrice && (
          <>
            {(shouldShowPromoPrice || shouldShowVipPrice) && ' '}
            <Price
              $showStrikethrough={showStrikeThroughForRetailPrice}
              amount={retailUnitPrice}
              data-autotag="product-retail-price"
              $priceStyle={priceStyle}
              aria-hidden={renderHiddenText}
              {...rest}
            />
          </>
        )}

        {renderHiddenText && (
          <HiddenText data-testid="hidden-text">
            <PriceHiddenText
              discountPrice={discountPrice}
              shouldShowVipPrice={shouldShowVipPrice}
              shouldShowRetailPrice={shouldShowRetailPrice}
              defaultUnitPrice={defaultUnitPrice}
              retailUnitPrice={retailUnitPrice}
              showStrikeThroughForRetailPrice={showStrikeThroughForRetailPrice}
              showStrikeThroughForVipPrice={showStrikeThroughForVipPrice}
            />
          </HiddenText>
        )}
      </ElementType>
    );
  }
);

ProductPrice.displayName = 'ProductPrice';

ProductPrice.propTypes = {
  /**
   * Element type to render. Defaults to a span.
   */
  as: PropTypes.elementType,
  className: PropTypes.string,
  priceStyle: PropTypes.any,
  /**
   * Product. If none is passed, it will attempt to retrieve from
   * ProductContext.
   */
  product: PropTypes.object,
  promoPriceStyle: PropTypes.any,
  /**
   * Whether or not to render hidden text so that price is better read by screen readers
   */
  renderHiddenText: PropTypes.bool,
  /**
   * Whether to show the discounted promo guest price (for Google Shopping)
   */
  showPromoGuestPrice: PropTypes.bool,
  /**
   * Whether to show the promo price
   */
  showPromoPrice: PropTypes.bool,
  /**
   * Whether to show the retail price
   */
  showRetailPrice: PropTypes.bool,
  /**
   * Whether to show the sale price (from Console)
   */
  showSalePrice: PropTypes.bool,
  /**
   * Whether to show the VIP price
   */
  showVipPrice: PropTypes.bool,
  /**
   * Whether to always render strikethrough even when not showing discounted price
   */
  strikeRegularPrice: PropTypes.bool,
};

export default ProductPrice;

import React from 'react';

import { FaAngleDown } from 'react-icons/fa';
import styled from 'styled-components';

import Collapsible, { useCollapsibleState } from '../Collapsible';

import OrderLineItem, { OrderLineItemType } from './OrderLineItem';

// Toggle Button
interface IconContainerProps {
  $isOpen: boolean;
}

const Button = styled(Collapsible.Button)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  text-transform: capitalize;
`;

const LabelAndIconContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;

const Label = styled.p``;

const IconContainer = styled.div<IconContainerProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  ${({ $isOpen }) => $isOpen && 'transform: rotate(-180deg);'}
`;

const DownChevron = styled(FaAngleDown)`
  height: 10px;
  width: 10px;
`;

type ToggleButtonProps = React.ComponentPropsWithRef<typeof Button> & {
  additionalButtonContent?: React.ReactNode;
  icon?: React.ReactNode;
  label: string;
};

const ToggleButton = (props: ToggleButtonProps) => {
  const { additionalButtonContent, children, icon, label, ...rest } = props;
  const Icon = icon || <DownChevron />;
  const { isOpen } = useCollapsibleState();

  return (
    <Button isOpen={isOpen} {...rest}>
      {children || (
        <>
          <LabelAndIconContainer>
            <Label>{label}</Label>
            <IconContainer $isOpen={isOpen}>{Icon}</IconContainer>
          </LabelAndIconContainer>
          {additionalButtonContent}
        </>
      )}
    </Button>
  );
};
// Toggle Button

// Discount Badge
const Badge = styled.div`
  align-items: center;
  background-color: lightgray;
  display: flex;
  font-size: 12px;
  padding: 4px;
  text-transform: capitalize;
`;
// Discount Badge

// DropdownContent
const Content = styled(Collapsible.Content)<{
  $isOpen: boolean;
}>`
  border: 1px dashed gray;
  display: flex;
  gap: 8px;
  padding: ${({ $isOpen }) => ($isOpen ? '4px 8px' : 'none')};
  margin-bottom: ${({ $isOpen }) => ($isOpen ? '4px' : 'none')};
`;

export type AppliedDiscount = {
  label: 'markdown' | 'discount';
  value?: string | number;
};

type DropdownContentProps = {
  badge?: React.FunctionComponent;
  discounts?: AppliedDiscount[];
  markdownSuffix: string;
} & React.ComponentPropsWithRef<typeof Content>;

const DropdownContent = (props: DropdownContentProps) => {
  const { badge, children, discounts = [], markdownSuffix, ...rest } = props;
  const DiscountBadge = badge || Badge;
  const { isOpen } = useCollapsibleState();

  return (
    <Content $isOpen={isOpen} {...rest}>
      {children ||
        discounts.map(({ label, value }) => (
          <DiscountBadge key={label}>
            {value} {label === 'markdown' && markdownSuffix}
          </DiscountBadge>
        ))}
    </Content>
  );
};
// DropdownContent

// Discount Label
const DiscountLabel = styled.div``;
// Discount Label

// getAppliedDiscounts util
const getAppliedDiscounts = (orderLineItem: OrderLineItemType) => {
  const discounts: AppliedDiscount[] = [];

  if (orderLineItem.markdownPercent) {
    const markdownPercent = Math.abs(
      orderLineItem.markdownPercent * 100
    ).toFixed(0);
    const markdown: AppliedDiscount = {
      label: 'markdown',
      value: `${markdownPercent}%`,
    };

    discounts.push(markdown);
  }

  if (orderLineItem?.discount?.promoLabel) {
    const discount: AppliedDiscount = {
      label: 'discount',
      value: orderLineItem.discount.promoLabel,
    };

    discounts.push(discount);
  }

  return discounts;
};
// getAppliedDiscounts util

// OrderLineItemDiscounts
const Discounts = styled.div``;

type OrderLineItemDiscountsProps = {
  multipleDiscountContent: React.ReactNode;
  singleDiscountContent: React.ReactNode;
} & React.ComponentPropsWithRef<typeof Discounts>;

const OrderLineItemDiscounts = ({
  multipleDiscountContent,
  singleDiscountContent,
  ...rest
}: OrderLineItemDiscountsProps) => {
  const { orderLineItem } = OrderLineItem.useContext();
  const appliedDiscounts = getAppliedDiscounts(orderLineItem);
  const hasMultipleDiscounts = appliedDiscounts.length > 1;

  if (!appliedDiscounts.length) {
    return null;
  }

  return (
    <Discounts {...rest}>
      {hasMultipleDiscounts ? multipleDiscountContent : singleDiscountContent}
    </Discounts>
  );
};
// OrderLineItemDiscounts

OrderLineItemDiscounts.Dropdown = Collapsible;
OrderLineItemDiscounts.DropdownContent = DropdownContent;
OrderLineItemDiscounts.DiscountBadge = Badge;
OrderLineItemDiscounts.DiscountLabel = DiscountLabel;
OrderLineItemDiscounts.ToggleButton = ToggleButton;
OrderLineItemDiscounts.getAppliedDiscounts = getAppliedDiscounts;

export default OrderLineItemDiscounts;

import React, { useCallback, useRef } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Context = React.createContext(() => undefined);

const Wrapper = styled.div`
  /*
   * Why set z-index: 0 on the root element? We want modal/popup/overlay type
   * elements to be able to append themselves to the <body> in order to escape
   * overflow constraints. If the app element has no base z-index, then elements
   * within it that *do* set z-index can potentially appear above the modals,
   * causing the need for modals/developers to figure out what greater z-index
   * to use.
   *
   * In order to avoid z-index wars, we begin a stacking context immediately
   * with the root app element. A z-index of 0 means that elements proceeding
   * it in the DOM don't need a z-index at all in order to cover it.
   */
  position: relative;
  z-index: 0;
`;

/**
 * Delegate the app element context value to another element on the page. This
 * is mostly useful when there are multiple React trees mounted on a page, like
 * in a styleguide.
 */
export function Delegate({ children, to }) {
  const getAppElement = useCallback(() => {
    if (typeof to === 'string') {
      return document.querySelector(to);
    }
    return to;
  }, [to]);

  return <Context.Provider value={getAppElement}>{children}</Context.Provider>;
}

Delegate.displayName = 'AppElement.Delegate';

Delegate.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

/**
 * A component that renders the root node for the main application content.
 * The node immediately establishes a stacking context with `z-index: 0` in
 * order to allow modal/popup/overlay type elements appearing later in the
 * document to render above the application without needing to fight over
 * `z-index`.
 *
 * The root node is made available via a context provider. This is useful for
 * modal components that may need to update its `aria-hidden` state, like `<AppHidden>`.
 * Access it with the function returned from the `useAppElement` hook or
 * `AppElement.Context`.
 *
 * All additional props are passed through to the wrapper element. You can use
 * this to add `className`, `id`, etc.
 *
 * An additional component is provided via `AppElement.Delegate`, for situations
 * where you have an existing root node that you’d prefer to target instead.
 * This is useful in rare circumstances where you have multiple React roots.
 * Use like `<AppElement.Delegate to="#my-root">` or
 * `<AppElement.Delegate to={nodeInstance}>`.
 */
export default function AppElement({ children, ...rest }) {
  const nodeRef = useRef();

  const getAppElement = useCallback(() => {
    return nodeRef.current;
  }, []);

  return (
    <Wrapper ref={nodeRef} {...rest}>
      <Context.Provider value={getAppElement}>{children}</Context.Provider>
    </Wrapper>
  );
}

AppElement.propTypes = {
  children: PropTypes.node,
};

AppElement.Context = Context;
AppElement.Delegate = Delegate;

import styled from 'styled-components';

import { desktop } from '../styles';

const Section = styled.div`
  background-color: #ffffff;
  text-align: left;
  padding: 16px;

  ${desktop`
    padding: 24px;
  `}
`;

export default Section;

import React, { useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

const StickyContext = React.createContext();
const HeightContext = React.createContext(0);

/**
 * A Provider that will collect information about any descendant `<Sticky>`
 * elements and supply a way to measure their height.
 */
export default function StickyProvider({ children }) {
  const stickyMap = useRef(new Map());
  const [height, setHeight] = useState(0);

  const value = useMemo(() => {
    // FIXME: This assumes that all stickies are above the target element we're,
    // interested in, don't change their height between the current scroll
    // position and the target element, and all stack upon each other. These are
    // all bad assumptions, but solve our current simple use case of a single
    // sticky header.
    const getStickyHeight = () => {
      let totalHeight = 0;
      stickyMap.current.forEach((stickyData) => {
        totalHeight += stickyData.getOffsetHeight();
      });
      return totalHeight;
    };

    const updateHeight = () => {
      setHeight(getStickyHeight());
    };

    const registerSticky = (ref, data) => {
      stickyMap.current.set(ref, data);
      updateHeight();
    };

    const unregisterSticky = (ref) => {
      stickyMap.current.delete(ref);
      updateHeight();
    };

    return {
      registerSticky,
      unregisterSticky,
      getStickyHeight,
      updateHeight,
    };
  }, []);

  return (
    <StickyContext.Provider value={value}>
      <HeightContext.Provider value={height}>{children}</HeightContext.Provider>
    </StickyContext.Provider>
  );
}

StickyProvider.propTypes = {
  children: PropTypes.node,
};

StickyProvider.StickyContext = StickyContext;
StickyProvider.HeightContext = HeightContext;

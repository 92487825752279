import { builder } from '@builder.io/react';
import config from 'config';
import { nanoid } from 'nanoid/non-secure';

import {
  trackDmgPageView,
  trackHomePageView,
  trackPromoPickerPageView,
} from './src/acquisition/actions';
import { trackBuilderTestEntered } from './src/builder/actions';
import { getAlgorithmConfigId } from './src/builder/utils/getAlgoConfig';
import {
  trackQuizPageView,
  trackSkipQuiz,
  trackQuizQuestionAnswered,
} from './src/builderQuiz/actions';
import {
  trackCheckoutCTAClick,
  trackShowOutfitDetailsClick,
  trackHideOutfitDetailsClick,
} from './src/cart/actions';
import { trackLoginPageView, trackAccountPageView } from './src/login/actions';
import { trackSpeedySignupPageView } from './src/pdp/actions';
import { trackSearchQuerySubmitted } from './src/search/actions';
import { isFabletics } from './src/shared/utils/brandNameHelper';
import getImageUrl from './src/shared/utils/getImageUrl';
import { snakeToCamel } from './src/shared/utils/helpers';
import logger from './src/shared/utils/logger';
import {
  trackShoppableVideoIntroModalPageView,
  trackVideoPlaybackCompleted,
  trackVideoPlaybackStarted,
} from './src/shoppableVideo/actions';
import { newRouteLoaded } from './src/techstyle-shared/next-routes';
import {
  getMembership,
  getAddresses,
} from './src/techstyle-shared/react-accounts';
import {
  createSegmentEvents,
  loggedInPageView,
} from './src/techstyle-shared/react-marketing';
import {
  getTrackProductDetails,
  productDetailViewed,
  trackProductClicked,
  trackProductViewed,
  trackProductDetailViewed,
  ProductType,
} from './src/techstyle-shared/react-products';
import {
  loadSessionSuccess,
  setHydrationStatus,
  HydrationStatus,
  getSession,
  getSessionVisitorId,
  toPacificDateString,
  parseDate,
} from './src/techstyle-shared/redux-core';

const debug = logger.extend('segmentEvents');

const feature = 'react';

const {
  trackStartChatClick,
  trackReturnCancelClick,
  trackReturnSubmitClick,
  trackCommunicationPreferenceClick,
  trackSizePreferenceClick,
  trackSubmitReviewClick,
  trackVipChatCancelClick,
  trackVipPhoneCancelClick,
  trackVipOnlineCancelClick,
  trackProfileSuggestedAddressClick,
  trackProfileNavLinkClick,
  trackOnlineCancelPageView,
  trackManageMembershipView,
  trackPauseMembershipClick,
  trackPauseBillingSubmit,
  trackRedeemOfferClick,
  trackCancelMemberShipClick,
  trackConfirmPauseMembershipClick,
  trackConfirmRedeemOfferClick,
  trackConfirmCancelMembershipClick,
  trackCancelOrderPopupYesClick,
  trackCancelOrderPopupNoClick,
  trackSnoozeSaveClick,
  trackDiscountSaveClick,
  trackCancelMembershipConfirmed,
  trackCommunicationRegistrationSubmit,
  trackRegistrationFailure,
  trackShoppableVideoFeedPageView,
} = require('./src/account/actions');
const { trackProductAdded } = require('./src/pdp/actions');
const {
  trackProductListSwatchClicked,
  trackProductListFilter,
  trackWishlist,
  trackCompleteRegistration,
  trackPromoClicked,
  trackPromoViewed,
  generatePromoEvent,
  identifyUserOnAction,
  trackTaggstarTestEntered,
  trackCognigyEvent,
} = require('./src/shared/actions');

const addOptionalKey = (key, payload, properties) => {
  if (payload && payload[key]) {
    properties[key] = payload[key];
  }
};

const getIsScrubState = (currentState) =>
  currentState.customerDetails?.isScrubs?.value ||
  currentState.sessionDetails?.keys?.isScrubs?.value ||
  currentState.marketing?.disposition?.experience === 'scrubs';

export default createSegmentEvents(
  ({ getContext, trackEvent, trackPageView, createEvent, identifyUser }) => {
    const identifyOnAction = identifyUser((action, _prevState, nextState) => {
      const addresses = getAddresses(nextState);
      const firstAddress = addresses.addresses[0];
      const membership = getMembership(nextState);
      const { customer, copsSegmentTraits } = nextState;
      const {
        isVip,
        dateTimeAdded,
        dateActivated,
        membershipLevelGroup,
        customerId,
      } = membership;
      const { email, profile = {} } = customer;
      const { birthMonth, birthDay, birthYear } = profile;
      const zipCode = firstAddress ? firstAddress.zip : null;

      const dateActivatedString = dateActivated
        ? toPacificDateString(parseDate(dateActivated), 'YYYY-MM-DD')
        : null;

      const dateTimeAddedString = dateTimeAdded
        ? toPacificDateString(parseDate(dateTimeAdded), 'YYYY-MM-DD')
        : null;

      let birthday = null;

      const membershipBrandId = config.get('public.brand.id');
      const profileBirthdayPickerEnabled = config.get(
        'public.brand.profileBirthdayPickerEnabled'
      );

      if (profileBirthdayPickerEnabled) {
        birthday =
          birthMonth && birthDay && birthYear
            ? `${birthMonth}/${birthDay}/${birthYear}`
            : null;
      }

      const {
        userId: userIdFromProps,
        email: emailFromProps,
        sms: smsFromProps,
      } = action.payload || {};
      const customerIdInfo = customerId ? customerId.toString() : null;

      return {
        userId: userIdFromProps || customerId,
        traits: {
          user_id: userIdFromProps || customerIdInfo,
          email: emailFromProps || email,
          sms: smsFromProps,
          zip_code: zipCode,
          membership_status: membershipLevelGroup,
          membership_brand_id: membershipBrandId,
          ...(dateTimeAddedString &&
            !isVip && {
              user_registered_at: dateTimeAddedString,
            }),
          ...(dateActivatedString &&
            isVip && { activated_date: dateActivatedString }),
          birthday,
          cash_gross_profit_decile_group:
            copsSegmentTraits?.traits?.cash_gross_profit_decile_group_4 ||
            'lowest',
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const pageView = trackPageView((action, _prevState, nextState) => {
      // Attach `segmentCategory` specified by the page.
      const { cookies } = getContext();
      const { isLoggedIn, sessionId, dmGatewayCode } = getSession(nextState);
      const {
        customer: { espId, gender },
        marketing,
        storeGroup: { storeId, storeGroupId },
        customerDetails: { is_yitty: isYitty },
      } = nextState;
      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      const { customerId, membershipLevelGroup } = getMembership(nextState);
      const visitorId = getSessionVisitorId(nextState);
      const membershipBrandId = config.get('public.brand.id');
      const {
        pageInfo,
        requestInfo: { trackingParams = {} },
      } = marketing;
      const referringPageModule = cookies.get('referring_page_selection');

      const url = new URL(window.location);
      const fromMobileApp = url.searchParams.get('fromMobileApp');
      const appAnonId = url.searchParams.get('appAnonId');
      const utmSource = url.searchParams.get('utm_source');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const utmMedium = url.searchParams.get('utm_medium');

      const [primaryPath, pathDmgCode] = url.pathname.split('/').slice(1);
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      const properties = {
        automated_test: Boolean(cookies.get('automated_test')),
        country: countryCodeByStoreGroupId[storeGroupId],
        customer_bucket_group: customerId ? `${(customerId % 20) + 1}` : null,
        customer_id: customerId ? customerId.toString() : null,
        dmg_code: pathDmgCode || dmGatewayCode || null,
        esp_id: espId,
        feature,
        gateway: primaryPath === 'dmg',
        gender,
        is_scrubs: !!isScrubsValue,
        is_yitty: !!isYittyValue,
        loggedin_status: isLoggedIn,
        membership_brand_id: membershipBrandId,
        membership_status: membershipLevelGroup,
        page_name:
          action.payload?.pageName ||
          pageInfo.pageName ||
          window.location.pathname,
        path:
          sessionStorage?.getItem('currentPath') || window.location.pathname,
        referrer:
          sessionStorage?.getItem('prevPath') ||
          document.referrer ||
          window.document.referrer,
        referring_page_module: referringPageModule || null,
        session_id: sessionId ? `${sessionId}` : null,
        store_id: storeId,
        user_status_initial: membershipLevelGroup,
        dmg_entered_on: dmGatewayCode,
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        fromMobileApp,
        appAnonId,
        ...trackingParams,
      };

      ['title', 'customer_gender', 'event'].forEach((key) =>
        addOptionalKey(key, action.payload, properties)
      );

      const returnValues = {
        properties,
        options: {
          anonymousId: `${visitorId}` || undefined,
          integrations: {
            Heap: true,
          },
        },
      };

      addOptionalKey('name', action.payload, returnValues);

      debug('trackPageView event', returnValues);

      return returnValues;
    });

    const productAdded = (action, _prevState, nextState) => {
      const membershipBrandId = config.get('public.brand.id');
      const {
        customer,
        customerDetails: { is_yitty: isYitty },
        storeGroup,
      } = nextState;
      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      const { gender, id, shaEmail } = customer;
      const {
        isBundle,
        product,
        bundleProducts,
        itemQuantity,
        selectionStateByProductIndex,
        productSource,
        cartId,
      } = action.payload;
      const { storeDomain, storeId } = storeGroup;
      const eventName = 'productAdded';
      const availableQuantity =
        selectionStateByProductIndex?.[0]?.selectedSKU?.availableQuantity;
      const imageUrl = getImageUrl({ product });

      const productAddedProperties = {
        bundle_index: null,
        bundle_product_id: null,
        cart_id: cartId,
        customer_gender: gender,
        customer_id: id.toString(),
        event: 'productAdded',
        image_url: imageUrl,
        correlation_id: nanoid(),
        coupon: product.promos?.promoCode,
        is_scrubs: !!isScrubsValue,
        is_yitty: !!isYittyValue,
        inventory_count: availableQuantity,
        list_id: productSource,
        membership_brand_id: membershipBrandId,
        page_hostname: storeDomain,
        price:
          product.promos?.promoPrice ||
          product.saleUnitPrice ||
          product.defaultUnitPrice ||
          product.retailUnitPrice,
        product_id: product.masterProductId.toString(),
        product_tag: product.tagIdList?.join(','),
        quantity: itemQuantity,
        retail_price: product.retailUnitPrice,
        sale_price: product.saleUnitPrice,
        sha_email: shaEmail,
        store_id: storeId,
        tokens: product.tokenRedemptionValue,
        vip_price: product.defaultUnitPrice,
        style_code: product.styleCode,
        style_label: product.styleLabel,
      };

      builder.track('productAdded', productAddedProperties);

      if (isBundle && bundleProducts !== null) {
        return bundleProducts.map((component, index) => {
          const availableQuantity =
            selectionStateByProductIndex?.[index]?.selectedSKU
              ?.availableQuantity;
          return createEvent({
            name: eventName,
            properties: {
              ...productAddedProperties,
              inventory_count: availableQuantity,
              bundle_index: index,
              bundle_product_id: product.masterProductId.toString(),
              product_id: component.masterProductId.toString(),
              style_code: component.styleCode,
              style_label: component.styleLabel,
            },
            options: {
              integrations: {
                Heap: true,
              },
            },
          });
        });
      } else {
        return createEvent({
          name: eventName,
          properties: productAddedProperties,
          options: {
            integrations: {
              Heap: true,
            },
          },
        });
      }
    };

    const promoClicked = ({ payload = {} }, _prevState, nextState) => {
      builder.track('promotionClicked');
      const { cookies } = getContext();
      const { sessionId, isLoggedIn, dmGatewayCode } = getSession(nextState);
      const { membershipLevelGroup } = getMembership(nextState);
      const {
        customer: { gender },
        customerDetails: { isScrubs },
        storeGroup: { storeGroupId },
      } = nextState;

      const membershipBrandId = config.get('public.brand.id');
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      const promoEvent = generatePromoEvent(payload, {
        eventName: 'Promotion Clicked',
        cookies,
        sessionId,
        storeGroupId,
        isLoggedIn,
        membershipBrandId,
        membershipLevelGroup,
        country: countryCodeByStoreGroupId[storeGroupId],
        gender,
        isScrubs,
        dmGatewayCode,
        utmSource,
        utmMedium,
        utmCampaign,
      });
      return createEvent({
        ...promoEvent,
        options: {
          integrations: {
            Heap: true,
          },
        },
      });
    };

    const promoWasSeenMap = new Map();
    const promoViewed = (
      { payload: { itemInfo: promoInfo } = {} },
      _prevState,
      nextState
    ) => {
      const { cookies } = getContext();
      const { sessionId, isLoggedIn, dmGatewayCode } = getSession(nextState);
      const {
        customer: { gender },
        customerDetails: { isScrubs, is_yitty: isYitty },
        storeGroup: { storeGroupId },
      } = nextState;
      const { membershipLevelGroup } = getMembership(nextState);

      const membershipBrandId = config.get('public.brand.id');
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      const {
        label: creativeLabel,
        imageFilename: imageId,
        id: assetId,
      } = promoInfo;

      const name = promoInfo?.container?.name;

      const promoEvent = generatePromoEvent(promoInfo, {
        eventName: 'Promotion Viewed',
        cookies,
        sessionId,
        isLoggedIn,
        storeGroupId,
        membershipBrandId,
        membershipLevelGroup,
        country: countryCodeByStoreGroupId[storeGroupId],
        gender,
        isScrubs,
        isYitty,
        dmGatewayCode,
        utmSource,
        utmMedium,
        utmCampaign,
      });

      const promoTrackingKey = `${creativeLabel}_${name}_${assetId}-${imageId}`;

      if (!promoWasSeenMap.has(promoTrackingKey)) {
        promoWasSeenMap.set(promoTrackingKey, promoEvent);
        return createEvent({
          ...promoEvent,
          options: {
            integrations: {
              Heap: true,
            },
          },
        });
      }
    };

    // If we already have a client-side session when hydration is complete, then we
    // can track the page view at that time.
    const hydrationPageView = (action, prevState, nextState) => {
      const primaryPath = window.location.pathname.split('/').slice(1)[0];
      const pathsToSkip = ['quiz-signup', 'dmg', 'account', 'quiz'];

      if (
        action.payload === HydrationStatus.COMPLETE &&
        nextState.session.sessionKey &&
        !nextState.session.isServerOnly &&
        !pathsToSkip.includes(primaryPath)
      ) {
        return pageView(action, prevState, nextState);
      }
    };

    // We do not want routes to automatically fire a page view event if they are piiauth gated.
    // These segment events should be handled in the component after authentication.
    const newRouteLoadedPageView = (action, prevState, nextState) => {
      const primaryPath = window.location.pathname.split('/').slice(1)[0];
      const pathsToSkip = ['account', 'quiz'];

      if (!pathsToSkip.includes(primaryPath)) {
        return pageView(action, prevState, nextState);
      }
    };

    // Otherwise, wait until we have a client-side session to track the page view.
    // That way we can choose to read `dmGatewayCode` from the session in `pageView`
    // above if desired.
    const sessionPageView = (action, prevState, nextState) => {
      if (
        (prevState.session.isServerOnly || !prevState.session.sessionKey) &&
        !nextState.session.isServerOnly
      ) {
        return pageView(action, prevState, nextState);
      }
    };

    const productClicked = (action, _prevState, nextState) => {
      const { cookies } = getContext();
      const { isLoggedIn, sessionId, dmGatewayCode } = getSession(nextState);
      const { isVip, membershipLevelGroup } = getMembership(nextState);
      const {
        customer: { gender },
        customerDetails: { is_yitty: isYitty },
        storeGroup: { storeGroupId },
      } = nextState;
      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      let productInfo = { ...action.payload, isVip };

      // if product has snake_case fields (ie a constructor sourced product),
      // then update all of its fields to be in camelCase
      if (productInfo.product.item_number) {
        const sanitizedProduct = {};
        for (const key in productInfo.product) {
          sanitizedProduct[snakeToCamel(key)] = productInfo.product[key];
        }
        // point productInfo to a copy of itself with a sanitized product field
        productInfo = { ...productInfo, product: sanitizedProduct };
      }
      const { product } = productInfo;

      const imageUrl = getImageUrl({ product });
      const isBundle = product.productTypeId === ProductType.BUNDLE;
      const productsData = isBundle
        ? product.componentProductIdObjectList?.map(
            (_componentItem, currentComponentIndex) =>
              getTrackProductDetails({ ...productInfo, currentComponentIndex })
          )
        : [getTrackProductDetails(productInfo)];

      const membershipBrandId = config.get('public.brand.id');
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );
      builder.track('productClicked', productsData);
      let productTag = null;
      if (product.tagIdList) {
        productTag = product.tagIdList.join(',');
      } else if (product.tagIds) {
        productTag = product.tagIds.join(',');
      }

      return productsData.map((productData, _position) =>
        createEvent({
          name: 'Product Clicked',
          properties: {
            ...productData,
            algo_id: '',
            algo_test_key: '',
            automated_test: Boolean(cookies.get('automated_test')),
            feature,
            store_group_id: storeGroupId,
            loggedin_status: isLoggedIn,
            membership_brand_id: membershipBrandId,
            session_id: sessionId ? `${sessionId}` : null,
            fpl_id: productInfo.fplId || null,
            global_codes: productInfo.fplCode || null,
            list: productInfo.productSource || null,
            image_url: imageUrl,
            sized_product_id: product.productIdObjectList?.[0]?.productId || '',
            sized_sku: product.itemNumber,
            product_display_reason: '',
            product_tag: productTag,
            quantity: 1,
            membership_status: membershipLevelGroup,
            user_status_initial: membershipLevelGroup,
            country: countryCodeByStoreGroupId[storeGroupId],
            gender,
            is_scrubs: !!isScrubsValue,
            is_yitty: !!isYittyValue,
            dmg_entered_on: dmGatewayCode,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            style_code: product.styleCode,
            style_label: product.styleLabel,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        })
      );
    };

    const startChatClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'profile_start_chat',
        properties: {
          category: 'My Account',
          action: 'Profile',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const returnCancelClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'orders_cancel_rma',
        properties: {
          category: 'My Orders',
          action: 'Orders',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const returnSubmitClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'orders_submit_rma',
        properties: {
          category: 'My Orders',
          action: 'Orders',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const communicationPreferenceClick = trackEvent(
      (action, _prevState, nextState) => {
        return {
          name: 'profile_communication_preference_click',
          properties: {
            category: 'Profile Communication Preference',
            action: 'Profile',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const sizePreferenceClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'profile_size_preference_click',
        properties: {
          category: 'Profile Size Preference',
          action: 'Profile',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const submitReviewClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'review_submit_click',
        properties: {
          category: 'My Account',
          action: 'My Review',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    // GIVEN I am a logged out user on any FL domain
    // AND I am submitting my email address in any of the quiz or speedy signup flows
    // WHEN I submit my email and password information
    // AND my account is unable to be created for any reason and the API returns an error
    const registrationFail = trackEvent(
      ({ payload }, _prevState, nextState) => {
        return {
          name: 'registration_error',
          properties: {
            category: 'My Account',
            action: 'Registration Error',
            session_id:
              nextState.session.sessionId.toString() || 'invalid session id',
            error_status_code: payload?.statusCode || 'invalid code',
            error_message: payload?.errorMessage || 'invalid message',
          },
        };
      }
    );

    const vipChatCancelClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'my_vip_chat_cancel_click',
        properties: {
          category: 'My Account',
          action: 'My VIP',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
      };
    });

    const vipPhoneCancelClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'Profile Start Phone',
        properties: {
          category: 'My Account',
          action: 'My VIP',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const vipOnlineCancelClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'my_vip_online_cancel_click',
        properties: {
          category: 'My Account',
          action: 'My VIP',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const cancelOrderPopupYesClick = trackEvent(
      (action, _prevState, nextState) => {
        return {
          name: 'Cancel Membership Yes',
          properties: {
            category: 'My Account',
            label: '1st Order Cancel',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const cancelOrderPopupNoClick = trackEvent(
      (action, _prevState, nextState) => {
        return {
          name: 'Cancel Membership No',
          properties: {
            category: 'My Account',
            label: '1st Order Cancel',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const profileSuggestedAddressClick = trackEvent(
      (action, _prevState, nextState) => {
        return {
          name: 'profile_suggested_address_click',
          properties: {
            category: 'Payments/Shipping',
            action: 'Payments/Shipping',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const profileNavLinkClick = trackEvent((action, _prevState, nextState) => {
      return {
        name: action.payload.name,
        properties: {
          category: 'Navigation',
          action: 'My Account',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const productsMap = new Map();
    let productsPending = false;
    const wasSeenMap = new Map();
    const productViewed = (action, _prevState, nextState) => {
      const routePath = Object.keys(nextState.dynamicRoutes.routeInfoByPath)[0];
      const { cookies } = getContext();
      const { isLoggedIn, sessionId, dmGatewayCode } = getSession(nextState);
      const { customerId, isVip, membershipLevelGroup } =
        getMembership(nextState);
      const membershipBrandId = config.get('public.brand.id');
      const {
        customer: { gender },
        storeGroup: { storeGroupId },
        customerDetails: {
          isScrubs,
          is_yitty: isYitty,
          shipping_zip: shippingZip,
          utm_term: utmTerm,
        },
      } = nextState;
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      // PDP pages do not apply filters to the product grid and therefore
      // `payloadKey` does not contain an `aggregationFilter`.
      // use `aggregationFilter` for filterable grids and fallback to `payloadKey` for PDPs
      const payloadKey = action.payload.payloadKey;
      let aggregationFilter;

      try {
        aggregationFilter = JSON.parse(payloadKey).aggregationFilter;
      } catch (e) {
        // skip error handling for now and handle further logic in ternary below
      }

      const currentGridKey = aggregationFilter
        ? `${routePath}_${JSON.stringify(aggregationFilter)}`
        : `${action.payload.fplId}_${action.payload.productSource}_${payloadKey}`;

      let products = productsMap.get(currentGridKey);
      let seenProducts = wasSeenMap.get(currentGridKey);

      if (!products) {
        products = [];
        productsMap.set(currentGridKey, products);
      }

      if (!wasSeenMap.has(currentGridKey)) {
        wasSeenMap.clear();
      }
      if (!seenProducts) {
        seenProducts = new Set();
        wasSeenMap.set(currentGridKey, seenProducts);
      }
      if (!seenProducts.has(action.payload.product.masterProductId)) {
        products.push(action.payload);
        seenProducts.add(action.payload.product.masterProductId);
      }
      if (productsPending || !products.length) {
        return [];
      } else {
        productsPending = true;
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            productsPending = false;
            const events = [];
            productsMap.forEach((productInfos, gridKey) => {
              if (productInfos.length === 0) {
                // 'Product List Viewed' event should never have an empty products array
                productsMap.delete(gridKey);
                return;
              }
              const trackProductsViewed = productInfos.map((productInfo) => {
                const { product } = productInfo;
                const imageUrl = getImageUrl({ product });
                const isBundle = product.productTypeId === ProductType.BUNDLE;
                if (isBundle) {
                  return product.componentProductIdObjectList?.map(
                    (componentItem, currentComponentIndex) => {
                      return {
                        ...getTrackProductDetails({
                          ...productInfo,
                          currentComponentIndex,
                          isVip,
                        }),
                        bundle_url: productInfo.href,
                        sku: componentItem.groupCode,
                        name: null,
                        image_url: '',
                        price: null,
                        vip_price: null,
                        sale_price: null,
                        review_rating: null,
                        url: '',
                        product_tag: '',
                        sized_sku: componentItem.groupCode,
                        sized_product_id: '',
                        size: null,
                        quantity: product.componentProductIdObjectList.length,
                        product_display_reason: '',
                        membership_brand_id: membershipBrandId,
                        style_code: componentItem.styleCode,
                        style_label: componentItem.styleLabel,
                      };
                    }
                  );
                }
                return {
                  ...getTrackProductDetails({ ...productInfo, isVip }),
                  image_url: imageUrl,
                  membership_brand_id: membershipBrandId,
                  quantity: 1,
                  product_display_reason: '',
                  product_tag: Array.isArray(product?.tagIdList)
                    ? product?.tagIdList?.join(',')
                    : '',
                  retail_price: product.retailUnitPrice,
                  sized_sku: product.itemNumber,
                  sized_product_id:
                    product.productIdObjectList?.[0]?.productId || '',
                  style_code: product.styleCode,
                  style_label: product.styleLabel,
                };
              });

              let algoId = '';
              const psrc = action.payload?.productSource?.toLowerCase() || '';
              if (psrc.includes('ad_id') && psrc.includes('algo')) {
                const algoIdCustomerDetails = {
                  gender,
                  shippingZip: shippingZip?.value,
                  utmTerm: utmTerm?.value,
                };

                algoId = getAlgorithmConfigId({
                  esIndex: 'ad_id',
                  algoConfigContext: {
                    customerDetails: algoIdCustomerDetails,
                    isScrubs:
                      nextState.marketing?.disposition?.experience === 'scrubs',
                    isFabletics: isFabletics(),
                  },
                });
              }

              let properties = {
                algo_id: algoId,
                algo_test_key: '',
                automated_test: Boolean(cookies.get('automated_test')),
                country: countryCodeByStoreGroupId[storeGroupId],
                dmg_entered_on: dmGatewayCode || null,
                feature,
                fpl_id: products[0].fplIds || null,
                gender: gender || '',
                is_scrubs: !!isScrubs?.value,
                is_yitty: !!isYitty?.value,
                list_id: products[0].productSource || null,
                loggedin_status: isLoggedIn,
                membership_brand_id: membershipBrandId,
                membership_status: membershipLevelGroup,
                products: trackProductsViewed.flat(),
                store_group_id: storeGroupId.toString(),
                session_id: sessionId ? `${sessionId}` : null,
                user_status_initial: membershipLevelGroup,
                utm_campaign: utmCampaign,
                utm_medium: utmMedium,
                utm_source: utmSource,
              };

              if (isLoggedIn) {
                properties = {
                  ...properties,
                  customer_id: customerId?.toString(),
                };
              }

              builder.track('productListViewed', properties);

              events.push(
                createEvent({
                  name: 'Product List Viewed',
                  properties,
                  options: {
                    integrations: {
                      Heap: true,
                    },
                  },
                })
              );
            });
            productsMap.clear();
            resolve(events);
          }, 300);
        });
      }
    };

    const searchQuerySubmitted = trackEvent(
      ({ payload: { query, queryType } }, _prevState, nextState) => {
        const { cookies } = getContext();
        const { isLoggedIn, sessionId, dmGatewayCode } = getSession(nextState);
        const membershipBrandId = config.get('public.brand.id');
        const { membershipLevelGroup } = getMembership(nextState);
        const {
          customer: { gender },
          customerDetails: { is_yitty: isYitty },
          storeGroup: { storeGroupId },
        } = nextState;

        const isScrubsValue = getIsScrubState(nextState);
        const isYittyValue =
          isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
        const url = new URL(window.location);
        const utmSource = url.searchParams.get('utm_source');
        const utmMedium = url.searchParams.get('utm_medium');
        const utmCampaign = url.searchParams.get('utm_campaign');
        const countryCodeByStoreGroupId = config.get(
          'public.intl.countryCodeByStoreGroupId'
        );

        return {
          name: 'Product Searched',
          properties: {
            automated_test: Boolean(cookies.get('automated_test')),
            feature,
            loggedin_status: isLoggedIn,
            membership_brand_id: membershipBrandId,
            query,
            query_type: queryType,
            session_id: sessionId,
            store_group_id: storeGroupId,
            membership_status: membershipLevelGroup,
            user_status_initial: membershipLevelGroup,
            country: countryCodeByStoreGroupId[storeGroupId],
            gender,
            is_scrubs: !!isScrubsValue,
            is_yitty: !!isYittyValue,
            dmg_entered_on: dmGatewayCode,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const completeRegistration = trackEvent((action, _prevState, nextState) => {
      const { cookies } = getContext();
      const { sessionId, dmGatewayCode, isLoggedIn } = getSession(nextState);
      const visitorId = getSessionVisitorId(nextState);
      const membershipBrandId = config.get('public.brand.id');
      const customer = nextState.customer;
      const customerId = customer.id;
      const correlationId = nanoid();
      const {
        customerDetails: { is_yitty: isYitty },
        storeGroup: { storeId, storeGroupId },
      } = nextState;
      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;

      return {
        name: 'completeRegistration',
        properties: {
          automated_test: Boolean(cookies.get('automated_test')),
          category: 'registration',
          correlation_id: correlationId,
          customer_id: customerId.toString(),
          customer_bucket_group: customerId ? (customerId % 20) + 1 : null,
          customer_gender: customer.gender,
          dmg_code: dmGatewayCode || null,
          event: 'completeRegistration',
          is_scrubs: !!isScrubsValue,
          is_yitty: !!isYittyValue,
          feature: 'react',
          loggedin_status: isLoggedIn,
          membership_brand_id: membershipBrandId,
          page_hostname: nextState.storeGroup.storeDomain,
          session_id: sessionId.toString(),
          sha_email: customer.shaEmail,
          store_id: storeId,
          store_group_id: storeGroupId,
          visitor_id: visitorId.toString(),
          visitor_group: visitorId ? `${(visitorId % 20) + 1}` : null,
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const quizQuestionAnswered = trackEvent((action, _prevState, nextState) => {
      const { isLoggedIn, sessionId } = getSession(nextState);
      const { storeId, storeGroupId } = nextState.storeGroup;
      const { cookies } = getContext();

      return {
        name: 'Quiz Answer',
        properties: {
          feature: 'react',
          loggedin_status: isLoggedIn,
          session_id: sessionId,
          store_id: storeId,
          store_group_id: storeGroupId,
          automated_test: Boolean(cookies.get('automated_test')),
          category: 'Quiz',
          label: null,
          quiz_question: null,
          question_number: null,
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const addToWishlist = trackEvent((action, _prevState, nextState) => {
      const { isLoggedIn, sessionId } = getSession(nextState);
      const { cookies } = getContext();

      return {
        name: 'added_to_wishlist',
        properties: {
          category: 'Products',
          action: 'Products',
          session_id: sessionId ? `${sessionId}` : '',
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          store_id: nextState.storeGroup.storeId.toString(),
          automated_test: Boolean(cookies.get('automated_test')),
          loggedin_status: isLoggedIn,
          feature,
          label: 'Added to wishlist',
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const productListFilter = trackEvent((action, _prevState, nextState) => {
      const { isLoggedIn, sessionId, dmGatewayCode } = getSession(nextState);
      const { cookies } = getContext();
      const { membershipLevelGroup } = getMembership(nextState);
      const {
        customer: { gender },
        customerDetails: { is_yitty: isYitty },
        storeGroup: { storeGroupId },
      } = nextState;
      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      const membershipBrandId = config.get('public.brand.id');
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      return {
        name: 'Product List Filtered',
        properties: {
          feature: 'react',
          session_id: sessionId ? `${sessionId}` : '',
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          loggedin_status: isLoggedIn,
          automated_test: Boolean(cookies.get('automated_test')),
          sorts: [],
          membership_brand_id: membershipBrandId,
          membership_status: membershipLevelGroup,
          user_status_initial: membershipLevelGroup,
          country: countryCodeByStoreGroupId[storeGroupId],
          gender,
          is_scrubs: !!isScrubsValue,
          is_yitty: !!isYittyValue,
          dmg_entered_on: dmGatewayCode,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const builderTestEntered = trackEvent((action, prevState, nextState) => {
      const { isLoggedIn, sessionId } = getSession(nextState);
      const { isVip } = getMembership(nextState);

      return {
        name: 'Builder Test Entered',
        properties: {
          session_id: sessionId ? `${sessionId}` : '',
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          loggedin_status: isLoggedIn,
          page_route: action?.payload?.data?.url,
          test_variation_id: action?.payload?.testVariationId,
          test_variation_name: action?.payload?.testVariationName,
          id: action?.payload?.id,
          name: action?.payload?.name,
          is_vip: isVip,
        },
      };
    });

    const taggstarTestEntered = trackEvent((action, prevState, nextState) => {
      const { isLoggedIn, sessionId } = getSession(nextState);
      const { isVip } = getMembership(nextState);

      return {
        name: 'Taggstar Test Entered',
        properties: {
          session_id: sessionId ? `${sessionId}` : '',
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          loggedin_status: isLoggedIn,
          page_route: window.location.href,
          experiment_id: action?.payload?.detail?.experiment?.id || '',
          experiment_group: action?.payload?.detail?.experiment?.group || '',
          is_vip: isVip,
        },
      };
    });

    const productListSwatchClicked = trackEvent(
      (action, _prevState, nextState) => {
        const { cookies } = getContext();
        const { isLoggedIn, sessionId, dmGatewayCode } = getSession(nextState);
        const { membershipLevelGroup } = getMembership(nextState);
        const {
          customer: { gender },
          customerDetails: { is_yitty: isYitty },
          storeGroup: { storeGroupId },
        } = nextState;

        const isScrubsValue = getIsScrubState(nextState);
        const isYittyValue =
          isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
        const membershipBrandId = config.get('public.brand.id');
        const url = new URL(window.location);
        const utmSource = url.searchParams.get('utm_source');
        const utmMedium = url.searchParams.get('utm_medium');
        const utmCampaign = url.searchParams.get('utm_campaign');
        const countryCodeByStoreGroupId = config.get(
          'public.intl.countryCodeByStoreGroupId'
        );

        return {
          name: 'Product List Swatch Clicked',
          properties: {
            feature: 'react',
            session_id: sessionId ? `${sessionId}` : '',
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            loggedin_status: isLoggedIn,
            automated_test: Boolean(cookies.get('automated_test')),
            membership_brand_id: membershipBrandId,
            membership_status: membershipLevelGroup,
            user_status_initial: membershipLevelGroup,
            country: countryCodeByStoreGroupId[storeGroupId],
            gender,
            is_scrubs: !!isScrubsValue,
            is_yitty: !!isYittyValue,
            dmg_entered_on: dmGatewayCode,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const skipQuizClicked = trackEvent((action, _prevState, nextState) => {
      const { sessionId, dmGatewayCode } = getSession(nextState);
      const { cookies } = getContext();

      return {
        name: 'Skip Quiz',
        properties: {
          automated_test: Boolean(cookies.get('automated_test')),
          customer_gender: 'M',
          dmg_code: dmGatewayCode || null,
          feature: 'react',
          loggedin_status: false,
          membership_brand_id: 1,
          name: '/style-quiz/mensquiz/skip-quiz',
          page_name: '/style-quiz/mensquiz/skip-quiz',
          path: '/build-your-profile',
          session_id: sessionId,
          search: '',
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          store_id: nextState.storeGroup.storeId.toString(),
          title: action.payload.title,
          url: window.location.href,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const checkoutCTAClicked = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'checkout_cta_clicked',
        properties: {
          category: 'Mini-Cart',
          action: 'checkout_CTA',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const showOutfitDetailsClick = trackEvent(
      (action, _prevState, nextState) => {
        return {
          name: 'show_outfit_details_click',
          properties: {
            category: 'Mini-Cart',
            action: 'show_outfit_details',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const hideOutfitDetailsClick = trackEvent(
      (action, _prevState, nextState) => {
        return {
          name: 'hide_outfit_details_click',
          properties: {
            category: 'Mini-Cart',
            action: 'hide_outfit_details',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const manageMembershipView = trackEvent((action, _prevState, nextState) => {
      const { isLoggedIn, dmGatewayCode } = getSession(nextState);
      const { membershipLevelGroup } = getMembership(nextState);
      const {
        customer: { gender },
        customerDetails: { is_yitty: isYitty },
        storeGroup: { storeGroupId },
      } = nextState;

      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      const membershipBrandId = config.get('public.brand.id');
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      return {
        name: 'Promotion Viewed',
        properties: {
          category: 'EnhancedEcommerce',
          label: 'Online Cancel',
          name: 'View Save Offers',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          membership_brand_id: membershipBrandId,
          membership_status: membershipLevelGroup,
          user_status_initial: membershipLevelGroup,
          country: countryCodeByStoreGroupId[storeGroupId],
          loggedin_status: isLoggedIn,
          gender,
          is_scrubs: !!isScrubsValue,
          is_yitty: !!isYittyValue,
          dmg_entered_on: dmGatewayCode,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const pauseMembershipClick = trackEvent((action, _prevState, nextState) => {
      const { isLoggedIn, dmGatewayCode } = getSession(nextState);
      const { membershipLevelGroup } = getMembership(nextState);
      const {
        customer: { gender },
        customerDetails: { is_yitty: isYitty },
        storeGroup: { storeGroupId },
      } = nextState;

      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      const membershipBrandId = config.get('public.brand.id');
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      return {
        name: 'Promotion Clicked',
        properties: {
          category: 'EnhancedEcommerce',
          label: 'Online Cancel',
          name: 'Click Pause Membership',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          loggedin_status: isLoggedIn,
          membership_brand_id: membershipBrandId,
          membership_status: membershipLevelGroup,
          user_status_initial: membershipLevelGroup,
          country: countryCodeByStoreGroupId[storeGroupId],
          gender,
          is_scrubs: !!isScrubsValue,
          is_yitty: !!isYittyValue,
          dmg_entered_on: dmGatewayCode,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const pauseBillingSubmit = trackEvent((action, _prevState, nextState) => {
      return {
        name: 'Pause Membership',
        properties: {
          category: 'EnhancedEcommerce',
          label: 'Online Cancel',
          name: 'Click Pause Membership',
          action: 'My VIP',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const redeemOfferClick = trackEvent((action, _prevState, nextState) => {
      const { isLoggedIn, dmGatewayCode } = getSession(nextState);
      const { membershipLevelGroup } = getMembership(nextState);
      const {
        customer: { gender },
        customerDetails: { is_yitty: isYitty },
        storeGroup: { storeGroupId },
      } = nextState;

      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      const membershipBrandId = config.get('public.brand.id');
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmMedium = url.searchParams.get('utm_medium');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      return {
        name: 'Promotion Clicked',
        properties: {
          category: 'EnhancedEcommerce',
          label: 'Online Cancel',
          name: 'Click Discount Offer',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          loggedin_status: isLoggedIn,
          membership_brand_id: membershipBrandId,
          membership_status: membershipLevelGroup,
          user_status_initial: membershipLevelGroup,
          country: countryCodeByStoreGroupId[storeGroupId],
          gender,
          is_scrubs: !!isScrubsValue,
          is_yitty: !!isYittyValue,
          dmg_entered_on: dmGatewayCode,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const cancelMembershipClick = trackEvent(
      (action, _prevState, nextState) => {
        const { isLoggedIn, dmGatewayCode } = getSession(nextState);
        const { membershipLevelGroup } = getMembership(nextState);
        const {
          customer: { gender },
          customerDetails: { is_yitty: isYitty },
          storeGroup: { storeGroupId },
        } = nextState;

        const isScrubsValue = getIsScrubState(nextState);
        const isYittyValue =
          isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
        const membershipBrandId = config.get('public.brand.id');
        const url = new URL(window.location);
        const utmSource = url.searchParams.get('utm_source');
        const utmMedium = url.searchParams.get('utm_medium');
        const utmCampaign = url.searchParams.get('utm_campaign');
        const countryCodeByStoreGroupId = config.get(
          'public.intl.countryCodeByStoreGroupId'
        );

        return {
          name: 'Promotion Clicked',
          properties: {
            category: 'EnhancedEcommerce',
            label: 'Online Cancel',
            name: 'Click Cancel Membership',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            loggedin_status: isLoggedIn,
            membership_brand_id: membershipBrandId,
            membership_status: membershipLevelGroup,
            user_status_initial: membershipLevelGroup,
            country: countryCodeByStoreGroupId[storeGroupId],
            gender,
            is_scrubs: !!isScrubsValue,
            is_yitty: !!isYittyValue,
            dmg_entered_on: dmGatewayCode,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const confirmPauseMembershipClick = trackEvent(
      (action, _prevState, nextState) => {
        const {
          customer: { gender },
          customerDetails: { is_yitty: isYitty },
        } = nextState;
        const isScrubsValue = getIsScrubState(nextState);
        const isYittyValue =
          isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
        const { membershipLevelGroup } = getMembership(nextState);
        const { isLoggedIn, dmGatewayCode } = getSession(nextState);
        const url = new URL(window.location);
        const utmSource = url.searchParams.get('utm_source');
        const utmCampaign = url.searchParams.get('utm_campaign');
        const utmMedium = url.searchParams.get('utm_medium');

        const membershipBrandId = config.get('public.brand.id');
        const countryCodeByStoreGroupId = config.get(
          'public.intl.countryCodeByStoreGroupId'
        );

        return {
          name: 'Button Clicked',
          properties: {
            membership_status: membershipLevelGroup,
            user_status_initial: membershipLevelGroup,
            membership_brand_id: membershipBrandId,
            country:
              countryCodeByStoreGroupId[nextState.storeGroup.storeGroupId],
            loggedin_status: isLoggedIn,
            gender,
            is_scrubs: !!isScrubsValue,
            is_yitty: !!isYittyValue,
            dmg_entered_on: dmGatewayCode,
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_medium: utmMedium,
            category: 'EnhancedEcommerce',
            label: 'Online Cancel',
            name: 'Confirm Pause Membership',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const confirmRedeemOfferClick = trackEvent(
      (action, _prevState, nextState) => {
        const {
          customer: { gender },
          customerDetails: { is_yitty: isYitty },
        } = nextState;
        const isScrubsValue = getIsScrubState(nextState);
        const isYittyValue =
          isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
        const { membershipLevelGroup } = getMembership(nextState);
        const { isLoggedIn, dmGatewayCode } = getSession(nextState);
        const url = new URL(window.location);
        const utmSource = url.searchParams.get('utm_source');
        const utmCampaign = url.searchParams.get('utm_campaign');
        const utmMedium = url.searchParams.get('utm_medium');
        const membershipBrandId = config.get('public.brand.id');
        const countryCodeByStoreGroupId = config.get(
          'public.intl.countryCodeByStoreGroupId'
        );

        return {
          name: 'Button Clicked',
          properties: {
            membership_status: membershipLevelGroup,
            user_status_initial: membershipLevelGroup,
            membership_brand_id: membershipBrandId,
            country:
              countryCodeByStoreGroupId[nextState.storeGroup.storeGroupId],
            loggedin_status: isLoggedIn,
            gender,
            is_scrubs: !!isScrubsValue,
            is_yitty: !!isYittyValue,
            dmg_entered_on: dmGatewayCode,
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_medium: utmMedium,
            category: 'EnhancedEcommerce',
            label: 'Online Cancel',
            name: 'Confirm Discount Offer',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const confirmCancelMembershipClick = trackEvent(
      (action, _prevState, nextState) => {
        const {
          customer: { gender },
          customerDetails: { is_yitty: isYitty },
        } = nextState;
        const isScrubsValue = getIsScrubState(nextState);
        const isYittyValue =
          isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
        const { membershipLevelGroup } = getMembership(nextState);
        const { isLoggedIn, dmGatewayCode } = getSession(nextState);
        const url = new URL(window.location);
        const utmSource = url.searchParams.get('utm_source');
        const utmCampaign = url.searchParams.get('utm_campaign');
        const utmMedium = url.searchParams.get('utm_medium');
        const membershipBrandId = config.get('public.brand.id');
        const countryCodeByStoreGroupId = config.get(
          'public.intl.countryCodeByStoreGroupId'
        );

        return {
          name: 'Button Clicked',
          properties: {
            membership_status: membershipLevelGroup,
            user_status_initial: membershipLevelGroup,
            membership_brand_id: membershipBrandId,
            country:
              countryCodeByStoreGroupId[nextState.storeGroup.storeGroupId],
            loggedin_status: isLoggedIn,
            gender,
            is_scrubs: !!isScrubsValue,
            is_yitty: !!isYittyValue,
            dmg_entered_on: dmGatewayCode,
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_medium: utmMedium,
            category: 'EnhancedEcommerce',
            label: 'Online Cancel',
            name: 'Confirm Cancel Membership',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const snoozeSaveClick = trackEvent((action, _prevState, nextState) => {
      const {
        customer: { gender },
        customerDetails: { is_yitty: isYitty },
      } = nextState;
      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      const { membershipLevelGroup } = getMembership(nextState);
      const { isLoggedIn, dmGatewayCode } = getSession(nextState);
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const utmMedium = url.searchParams.get('utm_medium');
      const membershipBrandId = config.get('public.brand.id');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      return {
        name: 'Snooze Save Click',
        properties: {
          membership_status: membershipLevelGroup,
          user_status_initial: membershipLevelGroup,
          membership_brand_id: membershipBrandId,
          country: countryCodeByStoreGroupId[nextState.storeGroup.storeGroupId],
          loggedin_status: isLoggedIn,
          gender,
          is_scrubs: !!isScrubsValue,
          is_yitty: !!isYittyValue,
          dmg_entered_on: dmGatewayCode,
          utm_source: utmSource,
          utm_campaign: utmCampaign,
          utm_medium: utmMedium,
          category: 'My Account',
          event: 'Snooze Save Click',
          label: 'My VIP',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const discountSaveClick = trackEvent((action, _prevState, nextState) => {
      const {
        customer: { gender },
        customerDetails: { is_yitty: isYitty },
      } = nextState;
      const isScrubsValue = getIsScrubState(nextState);
      const isYittyValue =
        isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
      const { membershipLevelGroup } = getMembership(nextState);
      const { isLoggedIn, dmGatewayCode } = getSession(nextState);
      const url = new URL(window.location);
      const utmSource = url.searchParams.get('utm_source');
      const utmCampaign = url.searchParams.get('utm_campaign');
      const utmMedium = url.searchParams.get('utm_medium');
      const membershipBrandId = config.get('public.brand.id');
      const countryCodeByStoreGroupId = config.get(
        'public.intl.countryCodeByStoreGroupId'
      );

      return {
        name: 'Discount Save Click',
        properties: {
          membership_status: membershipLevelGroup,
          user_status_initial: membershipLevelGroup,
          membership_brand_id: membershipBrandId,
          country: countryCodeByStoreGroupId[nextState.storeGroup.storeGroupId],
          loggedin_status: isLoggedIn,
          gender,
          is_scrubs: !!isScrubsValue,
          is_yitty: !!isYittyValue,
          dmg_entered_on: dmGatewayCode,
          utm_source: utmSource,
          utm_campaign: utmCampaign,
          utm_medium: utmMedium,
          category: 'My Account',
          event: 'Discount Save Click',
          label: 'My VIP',
          session_id: nextState.session.sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          ...action.payload,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const cancelMembershipConfirmed = trackEvent(
      (action, _prevState, nextState) => {
        const {
          customer: { gender },
          customerDetails: { is_yitty: isYitty },
        } = nextState;
        const isScrubsValue = getIsScrubState(nextState);
        const isYittyValue =
          isYitty?.value || nextState.sessionDetails?.keys?.is_yitty?.value;
        const { membershipLevelGroup } = getMembership(nextState);
        const { isLoggedIn, dmGatewayCode } = getSession(nextState);
        const url = new URL(window.location);
        const utmSource = url.searchParams.get('utm_source');
        const utmCampaign = url.searchParams.get('utm_campaign');
        const utmMedium = url.searchParams.get('utm_medium');
        const membershipBrandId = config.get('public.brand.id');
        const countryCodeByStoreGroupId = config.get(
          'public.intl.countryCodeByStoreGroupId'
        );
        return {
          name: 'Cancel Membership Confirmed',
          properties: {
            membership_status: membershipLevelGroup,
            user_status_initial: membershipLevelGroup,
            membership_brand_id: membershipBrandId,
            country:
              countryCodeByStoreGroupId[nextState.storeGroup.storeGroupId],
            loggedin_status: isLoggedIn,
            gender,
            is_scrubs: !!isScrubsValue,
            is_yitty: !!isYittyValue,
            dmg_entered_on: dmGatewayCode,
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_medium: utmMedium,
            category: 'My Account',
            event: 'Cancel Membership Confirmed',
            label: 'My VIP',
            session_id: nextState.session.sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const communicationRegistrationSubmit = trackEvent(
      (action, _prevState, nextState) => {
        const { isLoggedIn, sessionId } = getSession(nextState);
        const { cookies } = getContext();

        return {
          name: 'Communication Registration',
          properties: {
            feature,
            loggedin_status: isLoggedIn,
            session_id: sessionId.toString(),
            store_group_id: nextState.storeGroup.storeGroupId.toString(),
            automated_test: Boolean(cookies.get('automated_test')),
            category: 'Communication Registration',
            label: '',
            ...action.payload,
          },
          options: {
            integrations: {
              Heap: true,
            },
          },
        };
      }
    );

    const cognigyWebchatEvent = trackEvent((action, _prevState, nextState) => {
      const { isLoggedIn, sessionId } = getSession(nextState);
      const { customerId } = getMembership(nextState);

      return {
        name: action.payload.name,
        properties: {
          feature,
          loggedin_status: isLoggedIn,
          session_id: sessionId.toString(),
          store_group_id: nextState.storeGroup.storeGroupId.toString(),
          customer_id: customerId,
          category: 'Cognigy Webchat',
          label: '',
          source: action.payload.source,
          text: action.payload.text,
          route: action.payload.route,
        },
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const trackVideoEvent = trackEvent((action, _prevState, nextState) => {
      const { isLoggedIn, sessionId } = getSession(nextState);
      const { cookies } = getContext();
      const properties = {
        feature,
        loggedin_status: isLoggedIn,
        session_id: sessionId.toString(),
        store_group_id: nextState.storeGroup.storeGroupId.toString(),
        automated_test: Boolean(cookies.get('automated_test')),
        ...action.payload,
      };

      return {
        name: action.name,
        properties,
        options: {
          integrations: {
            Heap: true,
          },
        },
      };
    });

    const videoPlaybackStarted = (action, prevState, nextState) => {
      action.name = 'Video Playback Started';

      return trackVideoEvent(action, prevState, nextState);
    };

    const videoPlaybackCompleted = (action, prevState, nextState) => {
      action.name = 'Video Playback Completed';

      return trackVideoEvent(action, prevState, nextState);
    };

    return {
      [setHydrationStatus]: hydrationPageView,
      [loadSessionSuccess]: sessionPageView,
      [newRouteLoaded]: newRouteLoadedPageView,
      [trackAccountPageView]: pageView,
      [trackBuilderTestEntered]: builderTestEntered,
      [trackTaggstarTestEntered]: taggstarTestEntered,
      [trackSpeedySignupPageView]: pageView,
      [trackPromoPickerPageView]: pageView,
      [trackDmgPageView]: pageView,
      [trackHomePageView]: pageView,
      [trackQuizPageView]: pageView,
      [trackLoginPageView]: pageView,
      [trackOnlineCancelPageView]: pageView,
      [trackShoppableVideoFeedPageView]: pageView,
      [trackShoppableVideoIntroModalPageView]: pageView,
      [trackProductClicked]: productClicked,
      [trackProductViewed]: productViewed,
      [trackProductDetailViewed]: productDetailViewed({
        getContext,
        createEvent,
      }),
      [loggedInPageView]: identifyOnAction,
      [identifyUserOnAction]: identifyOnAction,
      [trackStartChatClick]: startChatClick,
      [trackReturnCancelClick]: returnCancelClick,
      [trackReturnSubmitClick]: returnSubmitClick,
      [trackCommunicationPreferenceClick]: communicationPreferenceClick,
      [trackSizePreferenceClick]: sizePreferenceClick,
      [trackSubmitReviewClick]: submitReviewClick,
      [trackVipChatCancelClick]: vipChatCancelClick,
      [trackVipPhoneCancelClick]: vipPhoneCancelClick,
      [trackVipOnlineCancelClick]: vipOnlineCancelClick,
      [trackProfileSuggestedAddressClick]: profileSuggestedAddressClick,
      [trackProfileNavLinkClick]: profileNavLinkClick,
      [trackQuizQuestionAnswered]: quizQuestionAnswered,
      [trackSearchQuerySubmitted]: searchQuerySubmitted,
      [trackCompleteRegistration]: completeRegistration,
      [trackWishlist]: addToWishlist,
      [trackProductAdded]: productAdded,
      [trackPromoClicked]: promoClicked,
      [trackPromoViewed]: promoViewed,
      [trackProductListFilter]: productListFilter,
      [trackProductListSwatchClicked]: productListSwatchClicked,
      [trackSkipQuiz]: skipQuizClicked,
      [trackCheckoutCTAClick]: checkoutCTAClicked,
      [trackShowOutfitDetailsClick]: showOutfitDetailsClick,
      [trackHideOutfitDetailsClick]: hideOutfitDetailsClick,
      [trackManageMembershipView]: manageMembershipView,
      [trackPauseMembershipClick]: pauseMembershipClick,
      [trackPauseBillingSubmit]: pauseBillingSubmit,
      [trackRedeemOfferClick]: redeemOfferClick,
      [trackCancelMemberShipClick]: cancelMembershipClick,
      [trackConfirmPauseMembershipClick]: confirmPauseMembershipClick,
      [trackConfirmRedeemOfferClick]: confirmRedeemOfferClick,
      [trackConfirmCancelMembershipClick]: confirmCancelMembershipClick,
      [trackCancelOrderPopupYesClick]: cancelOrderPopupYesClick,
      [trackCancelOrderPopupNoClick]: cancelOrderPopupNoClick,
      [trackSnoozeSaveClick]: snoozeSaveClick,
      [trackDiscountSaveClick]: discountSaveClick,
      [trackCancelMembershipConfirmed]: cancelMembershipConfirmed,
      [trackCommunicationRegistrationSubmit]: communicationRegistrationSubmit,
      [trackRegistrationFailure]: registrationFail,
      [trackCognigyEvent]: cognigyWebchatEvent,
      [trackVideoPlaybackStarted]: videoPlaybackStarted,
      [trackVideoPlaybackCompleted]: videoPlaybackCompleted,
    };
  }
);

import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import serialize from 'serialize-javascript';

import { getRaygunUser } from '../marketingModule';
import Script from '../Script';

function RaygunUserIdentification({ user }) {
  return (
    // Why is this done with a `<Script>` instead of `useEffect`? Much like
    // other `<Script>` usage, this is so that we can run this code before the
    // app's JavaScript payload has been downloaded and evaluated. It's possible
    // (and likely) for errors to occur between receiving the document and when
    // this component would normally be mounted (during hydration). If errors do
    // occur there, we'd prefer to have already called `setUser` so that the
    // error report contains accurate user information. If this were done in
    // `useEffect`, the earliest time we'd be able to set user info would be
    // after the app has already mounted.
    <Script
      dangerouslySetInnerHTML={{
        __html: `rg4js("setUser", ${serialize(user)});`,
      }}
    />
  );
}

RaygunUserIdentification.propTypes = {
  /**
   * The user data to pass to Raygun's `setUser` method.
   */
  user: PropTypes.shape({
    identifier: PropTypes.string,
    isAnonymous: PropTypes.bool,
    email: PropTypes.string,
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    uuid: PropTypes.string,
  }),
};

export function mapStateToProps(state) {
  return {
    user: getRaygunUser(state),
  };
}

export default connect(mapStateToProps)(RaygunUserIdentification);
